import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs'
import Icon, { IconType } from '@/components/Icon';
import Config from '@/Config';
import { checkImgExists } from '@/utils';
import { IOrderInfo } from '@/lib/order-builder/models/OrderEntity';
import { formatCurrency } from '@/components/Payment';
import { getIntl, getIntlByPath } from '@/components/App/App';

interface IOrderItemProps {
  data: IOrderInfo;
  goToDetailPage: (storeID: string, orderNumber: string, suborderNumber: string) => void;
  reOrder: (storeID: string, orderNumber: string, suborderNumber: string) => void;
}

const shippingMethodIconTypes = [IconType.PICK_UP_OUTLINED, IconType.DELIVERY_OUTLINED, IconType.BOOK_OUTLINED, IconType.STORE_OUTLINED]

const OrderItem: React.FC<IOrderItemProps> = props => {
  const [imgUrl, setImgUrl] = useState('');

  useEffect(() => {
    checkImgExists(data.storeLogoUrl, (success, url) => {
      if (success) {
        setImgUrl(url);
      } else {
        setImgUrl(Config.defaultStoreImg);
      }
    })
  }, []);

  const {
    data,
    goToDetailPage,
    reOrder,
  } = props;

  return (
    <>
      {
        data.superOrder &&
        data.orderEntities.map(item => {
          return (
            <div className="order-item" key={item.orderNumber}>
              <div onClick={() => goToDetailPage(item.storeID, data.orderNumber, item.orderNumber)}>
                <div className="order-item-top">
                  <div className="order-item-shipping-method">
                    <Icon className="img" type={shippingMethodIconTypes[item.shippingMethod]}/>
                  </div>
                  <div className="title store">{item.storeTitle}</div>
                  <div className="order-state">• {getIntlByPath(`common.${item.orderStatus}`)}</div>
                </div>
                <hr className="hr" />
                <div className="order-item-contemt-single">
                  <div className="store-logo">
                    <div className="image" style={{backgroundImage: `url(${item.storeLogoUrl})`}}></div>
                  </div>
                  <div>
                    <div className="order-item-contemt-single-time">{dayjs(data.orderTime).format('YYYY-MM-DD HH:mmA')}</div>
                    <div className="order-item-contemt-single-reference-id">{getIntl().page.referenceID}: {item.referenceID}</div>
                    <div className="order-item-contemt-total">{getIntl().common.total}: {formatCurrency(item.initialTotal, data.currencyCode)}</div>
                  </div>
                </div>
              </div>
              <div className="order-item-buttom">
                <div className="button"
                  onClick={() => reOrder(data.storeID, data.orderNumber, item.orderNumber)}
                >
                  {getIntl().page.reOrder}
                </div>
              </div>
            </div>
          )
        })
      }

      {
        !data.superOrder &&
        <div className="order-item">
          <div onClick={() => goToDetailPage(data.storeID, data.orderNumber, data.orderNumber)}>
            <div className="order-item-top">
              <div className="order-item-shipping-method">
                <Icon className="img" type={shippingMethodIconTypes[data.shippingMethod]}/>
              </div>
              <div className="title store">{data.storeTitle}</div>
              <div className="order-state">• {getIntlByPath(`common.${data.orderStatus}`)}</div>
            </div>
            <hr className="hr" />
            <div className="order-item-contemt-single">
              <div className="store-logo">
                <div className="image" style={{backgroundImage: `url(${imgUrl})`}}></div>
              </div>
              <div>
                <div className="order-item-contemt-single-time">{dayjs(data.orderTime).format('YYYY-MM-DD HH:mmA')}</div>
                <div className="order-item-contemt-single-reference-id">{getIntl().page.referenceID}: {data.referenceID}</div>
                <div className="order-item-contemt-total">{getIntl().common.total}: {formatCurrency(data.initialTotal, data.currencyCode)}</div>
              </div>
            </div>
          </div>
          <div className="order-item-buttom">
            <div className="button"
              onClick={() => reOrder(data.storeID, data.orderNumber, '')}
            >
              {getIntl().page.reOrder}
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default OrderItem;
