import { IAppIcons } from '@/actions/store-action';
import React from 'react';
import { prefix } from '.';
import Icon, { IconType } from '../Icon';
import Badge from 'antd-mobile/es/badge';
import Constants from '@/constants';
import { getAccessToken } from '@/utils/app';

export interface IBmTabBarItemProps {
  hideTitle?: boolean;
  title?: React.ReactNode;
  iconType: IconType;
  selected: boolean;
  onClick?: () => void;
  appIcon?: IAppIcons;
}

const BmTabBarItem: React.FC<IBmTabBarItemProps> = props => {
  const {
    iconType,
    title = '',
    hideTitle = false,
    selected = false,
    appIcon,
    onClick,
  } = props;

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  }

  return (
    <div className={`${prefix}-item ${selected ? 'selected' : ''}`} onClick={handleClick}>
      <div className="item-icon-wrapper" style={{position: 'relative'}}>
        {
          iconType && iconType === IconType.MEMBERS_OUTLINED && getAccessToken() &&
          <Badge text={Number(localStorage.getItem(Constants.PRIVILEGE_NUMBER))} hot overflowCount={99} />
        }
        {
          appIcon && appIcon.svg &&
          <div className="item-icon" dangerouslySetInnerHTML={{ __html: appIcon.svg }} />
        }
        {
          (!appIcon || !appIcon.svg) &&
          <Icon className="item-icon" type={iconType} />
        }
      </div>
      {
        !hideTitle &&
        <div className="item-title">{title}</div>
      }
    </div>
  );
}

export default BmTabBarItem;
