import React from 'react';
import './index.less';


interface ICountryFlagProps {
  type: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  className?: string;
}

const CountryFlag: React.FC<ICountryFlagProps> = props => {
  const { type, className = '', onClick } = props;

  return (
    <div className={` country-flag ${className}`} onClick={onClick}>
      {
        (type === 'sg' || type === 'jp') &&
        <img src={require(`./flags/${type || 'hk'}.png`)} alt="" />
      }
      {
        type !== 'sg' && type !== 'jp' &&
        <img src={require(`./flags/${type || 'hk'}.svg`)} alt="" />
      }
    </div>
  );
}

export default CountryFlag;
