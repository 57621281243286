import React from 'react';
import { prefix } from '.';
interface SkeletonProps {
  isStorePage?: boolean;
}
const Skeleton: React.FC<SkeletonProps> = props => {
  const { isStorePage } = props;
  return (
    <>
      <div className={`${prefix}-skeleton skeleton ${isStorePage? 'store-page': ''}`}>
        {
          isStorePage && 
          <>
            <div className="skeleton-list">
              <div className="skeleton-list-item"></div>
              <div className="skeleton-list-item"></div>
              <div className="skeleton-list-item"></div>
              <div className="skeleton-list-item"></div>
            </div>
            <div className="skeleton-list-title">
              <div></div>
            </div>
          </>
        }
        <div className="skeleton-store bm-c-store-list">
          <div className="bm-p-stores-by-category-store-list container _1 w-container">
            <div className="skeleton-store-item">
              <div className="skeleton-store-img"></div>
              <div className="skeleton-store-info">
                <div className="store-title"></div>
                <div className="store-nav">
                  <div className="store-nav-item"></div>
                  <div className="store-nav-item second"></div>
                  <div className="store-nav-item"></div>
                </div>
                <div className="store-wrap">
                  <div className="store-wrap-item"></div>
                  <div className="store-wrap-item second"></div>
                  <div className="store-wrap-item"></div>
                </div>
              </div>
            </div>
            <div className="skeleton-store-item">
              <div className="skeleton-store-img"></div>
              <div className="skeleton-store-info">
                <div className="store-title"></div>
                <div className="store-nav">
                  <div className="store-nav-item"></div>
                  <div className="store-nav-item second"></div>
                  <div className="store-nav-item"></div>
                </div>
                <div className="store-wrap">
                  <div className="store-wrap-item"></div>
                  <div className="store-wrap-item second"></div>
                  <div className="store-wrap-item"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Skeleton;

