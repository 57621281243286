import './HomePage.less';
import { RouteComponentProps } from 'react-router-dom';
import withRoute from '@/pages/withRoute';
import HomePage from './HomePage';

export const prefix = 'bm-p-home';

const RouteComponent: React.FC<RouteComponentProps> = props => withRoute({
  pageComponent: HomePage,
  routeProps: props,
  pageTitleI18nKey: '',
});

export default RouteComponent;
