import * as H from 'history';
import { IObject } from '@/components/bm-common';
import { pathToRegexp } from 'path-to-regexp';
import { setPageAnimationType } from '@/components/Transition/PageTransition';
import { TransitionKeys } from '@/components/Transition';

export const routePaths: string[] = [];

interface IGoToRouteParams {
  history: H.History<H.History.PoorMansUnknown>;
  pathname: string;
  clearPaths?: boolean;
  state?: IObject;
  animationType?: TransitionKeys;
  search?: string;
}

interface IGoBackParams extends IGoToRouteParams {
  paths?: string[];
  runGoBack?: boolean; // routePaths有路径，runGoBack===true, 则优先执行goBack();
}

export const popPath = () => routePaths.pop();

export const goToRoute = (params: IGoToRouteParams) => {
  const { pathname: currentPathname } = window.location;
  const {
    history,
    pathname,
    clearPaths = false,
    state,
    animationType,
    search,
  } = params;

  const length = routePaths.length;
  if (length < 1 || routePaths[length - 1] !== currentPathname) {
    routePaths.push(currentPathname);
  }
  if (clearPaths) {
    routePaths.length = 0;
  }
  setPageAnimationType(animationType || TransitionKeys.FADE_FROM_RIGHT);
  history.push({
    pathname,
    state,
    search,
  });
}

export const goBack = (params: IGoBackParams) => {
  const {
    history,
    pathname,
    clearPaths = false,
    state,
    paths,
    runGoBack = false,
    animationType,
    search,
  } = params;

  let isBack = false;
  if (routePaths.length > 0 && runGoBack) {
    isBack = true;
  }

  const lastPathname = routePaths.pop();
  
  if (!isBack && lastPathname && Array.isArray(paths)) {
    for (let i = 0; i < paths.length; i++) {
      const re = pathToRegexp(paths[i]);
      // const tempPath = paths[i];
      const pathMatch = re.exec(lastPathname);
      if (Array.isArray(pathMatch) && pathMatch.length > 0) {
        isBack = true;
        break;
      }
    }
  }

  setPageAnimationType(animationType || TransitionKeys.FADE_BACK_RIGHT);
  if (lastPathname === pathname || isBack) {
    if (clearPaths) {
      routePaths.length = 0;
    }
    history.goBack();
    const href = window.location.href;
    setTimeout(() => {
      if (href === window.location.href) {
        history.push({
          pathname,
          state,
        });
      }
    }, 100)
  } else {
    history.push({
      pathname,
      state,
      search,
    });
  }
}
