import React from 'react';
import { IModifier } from '@/lib/order-builder/utils/api';
import Icon, { IconType } from '@/components/Icon';
import { formatCurrency } from '@/components/Payment';
import { IReducersState } from '@/reducers';
import { getI18nText } from '@/utils/app';
import { getIntl } from '@/components/App/App';

interface IModifierProps {
  data: IModifier;
  chooseSameOption: boolean;
  chooseUpTo: number;
  optionHasSubOption: boolean;
  clickable: boolean;
  listing: IModifier;
  onClick?: (listing: IModifier, data: IModifier, action: 'PLUS' | 'MINUS' | 'OPTIONS' | 'SINGLE') => void;
  countRef: {[modifierID: string]: number};
  reducersState: IReducersState;
  currencyCode: string;
}

const Modifier: React.FC<IModifierProps> = props => {
  const { listing, data, chooseSameOption, chooseUpTo, optionHasSubOption, onClick, countRef, clickable, reducersState, currencyCode } = props;

  const isExist = (data.id in countRef) && countRef[data.id] > 0;

  const handleMinusClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (onClick) {
      onClick(listing, data, 'MINUS');
    }
  }
  const handlePlusClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (onClick && !clickable) {
      onClick(listing, data, 'PLUS');
    }
  }
  const handleOptionsClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (onClick && !clickable) {
      onClick(listing, data, 'OPTIONS');
    }
  }
  const handleSingleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (onClick) {
      onClick(listing, data, 'SINGLE');
    }
  }
  const ITEM_TYPE_SINGE = 1;
  const ITEM_TYPE_MULTIPLE = 2;
  const ITEM_TYPE_OPTIONS = 3;
  const ITEM_TYPE_C_OPTION = 4;
  let itemType = ITEM_TYPE_MULTIPLE;
  if (optionHasSubOption) { // 有二级modifier， options
    itemType = ITEM_TYPE_OPTIONS;
  } else if (!chooseSameOption && chooseUpTo === 1) { // 没有勾选不限数量，并且最多选择1，单选
    itemType = ITEM_TYPE_SINGE;
  } else if (!chooseSameOption) { // 没有勾选不限数量,就只能选1， 单选
    itemType = ITEM_TYPE_C_OPTION;
  } else if (chooseSameOption && chooseUpTo === 1) { // 勾选不限数量 但是必须选择1 ，最多选择1 单选
    itemType = ITEM_TYPE_SINGE;
  } else { // 多选 显示加减
    itemType = ITEM_TYPE_MULTIPLE;
  }
  let buttonsOneOptionModeView;
  let buttonsMultipleModeView;
  let buttonsHasSubOptionModeView;
  switch (itemType) {
  case ITEM_TYPE_OPTIONS:
    buttonsHasSubOptionModeView = (
      <>
        {
          isExist &&
          <div className="q-flex-cart">
            <Icon
              className="q-dec q-dec-cart"
              type={IconType.MINUS_CIRCLE_OUTLINED}
              onClick={handleMinusClick}
            />
            <div className="q-num checkout-process">{countRef[data.id]}</div>
            <Icon
              className="q-inc q-inc-cart"
              type={IconType.PLUS_CIRCLE_OUTLINED}
              onClick={handlePlusClick}
            />
          </div> 
        }
        {
          !isExist &&
          <div className="text-block-218" onClick={handleOptionsClick}>
            {getIntl().page.option}
          </div>
        }
      </>
    );
    break;
  case ITEM_TYPE_SINGE:
    buttonsOneOptionModeView = (
      <div className="item-count">
        {
          isExist &&
          <>
            <Icon className="select-icon checked" type={IconType.CHECKED_OUTLINED}/>
          </>
        }
      </div>
    );
    break;
  case ITEM_TYPE_C_OPTION:
    buttonsMultipleModeView = (
      <div className="item-count">
        {
          isExist &&
          <>
            <Icon className="select-icon checked" type={IconType.CHECKED_OUTLINED}/>
          </>
        }
      </div>
    );
    break;
  default:
    buttonsMultipleModeView = (
      <div className="q-flex-cart">
        {
          isExist &&
          <>
            <Icon
              className="q-dec q-dec-cart"
              type={IconType.MINUS_CIRCLE_OUTLINED}
              onClick={handleMinusClick}
            />  
            <div className="q-num">{countRef[data.id]}</div>
          </>
        }
        <Icon
          className="q-inc q-inc-cart"
          type={IconType.PLUS_CIRCLE_OUTLINED}
          onClick={handlePlusClick}
        />
      </div>
    );
  }
  const price = Number(data.price) || 0;

  const handleItemClick = (e: React.MouseEvent) => {
    if ([ITEM_TYPE_SINGE, ITEM_TYPE_C_OPTION].includes(itemType)) {
      handleSingleClick(e)
    } else if (itemType === ITEM_TYPE_OPTIONS) {
      let canEdit = false;
      const count = countRef[data.id] || 0;
      if (count <= 1) {
        if (clickable) {
          canEdit = count === 1;
        } else {
          canEdit = count <= 1;
        }
      }

      if (onClick && canEdit) {
        onClick(listing, data, 'OPTIONS');
      }
    }
  }
  return (
    <div className={`modifier-block ${isExist ? 'modifier-wrapper-selected' : ''} ${data.soldOut ? 'sold-out-modifier' : ''}`}  onClick={handleItemClick}>  
      <div className="text-block-214">
        {getI18nText(data, 'option_name', reducersState.app.language) || getI18nText(data, 'name', reducersState.app.language) || data.name}
        {
          price !== 0 &&
          <div className="sub-price">+{formatCurrency(data.price, currencyCode)}</div>
        }
      </div>
      {
        !data.soldOut &&
        <>
          {buttonsOneOptionModeView}
          {buttonsMultipleModeView}
          {buttonsHasSubOptionModeView}
        </>
      }
      {
        data.soldOut &&
        <div className="sold-out"> {getIntl().page.soldOut}</div>
      }
    </div>
  );
}

export default Modifier;
