import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import withRoute from '@/pages/withRoute';
import AccountPage from './AccountPage';
import './AccountPage.less';

export const prefix = 'bm-p-account';

const RouteComponent: React.FC<RouteComponentProps> = props => withRoute({
  pageComponent: AccountPage,
  routeProps: props,
  pageTitleI18nKey: '',
});

export default RouteComponent;
