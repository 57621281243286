import './IconsPage.less';
import { RouteComponentProps } from 'react-router-dom';
import withRoute from '@/pages/withRoute';
import IconsPage from './IconsPage';

export const prefix = 'bm-p-icons';

const RouteComponent: React.FC<RouteComponentProps> = props => withRoute({
  pageComponent: IconsPage,
  routeProps: props,
  pageTitleI18nKey: '',
});

export default RouteComponent;
