enum DisplayType {
  /**
   * 折扣
   */
  DISCOUNT = 3,

  /**
 * 服务费
 */
    SERVICE_FEE = 7,

  /**
   * 税
   */
  TAX = 9,


}

export default DisplayType;
