import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import { prefix2 } from '.';
import ActionType from '@/actions/action-type';
import { setScreenColor, getPageBeingAnimated, getIntl } from '@/components/App/App';
import { IStore, IStoreConfig } from '@/actions/store-action';
import paths from '@/routes/paths';
import Icon, { IconType } from '@/components/Icon';
import BmTabBar from '@/components/BmTabBar';
import Status from '@/components/Status';
import { setCanAutoProcurementMethodPopup } from '../MenuListPage/MenuListPage';
import TabsOption from '@/actions/tabs-option';
import { IFavoriteTab, IListing, IFavoriteSection } from '@/lib/order-builder/utils/api';
import MenuNav from '../MenuListPage/MenuNav';
// import MenuList from '@/components/MenuList';
import { IShoppingCartData } from '@/actions/shopping-cart-action';
import { scalePx } from '@/utils';
import ShoppingCartBar from '@/components/ShoppingCartBar';
import { IParseOpeningHoursResult, parseOpeningHours, getCurrentShippingMethod, getI18nText } from '@/utils/app';
import SideBar from '@/components/SideBar/SideBar';
import dayjs from 'dayjs';
import ShippingMethod from '@/lib/order-builder/enums/ShippingMethod';
import MenuList from '@/components/MenuList/MenuList';
import { EShopPageStyled, TemporaryStyled } from './styled';
import { formatCurrency } from '@/components/Payment';
import ModifierPage from '../ModifierPage/NewModifierPage';
import Layout from '@/components/Layout';

export interface IEShopPageProps extends IBasePageProps {
}

export interface IEShopPageState {
  store?: IStore;
  storeConfig?: IStoreConfig;
  menus: IFavoriteTab[];
  loadingData: boolean;
  selectedMenuID: string;
  showMenuNavLeftTag: boolean;
  showMenuNavRightTag: boolean;
  shoppingCartData?: IShoppingCartData;
  storeSlug: string;
  showSideBar: boolean;
  showListingDetail: boolean;
  quantity: number;
  currentListing: IListing | undefined;
  showListingDetailModifier: boolean;
  listingID: string;
}

class EShopPage extends BasePage<IEShopPageProps, IEShopPageState> {

  selectedMenuID = '';

  constructor(props: IEShopPageProps) {
    super(props);

    this.state = {
      menus: [],
      loadingData: true,
      selectedMenuID: '',
      showMenuNavLeftTag: false,
      showMenuNavRightTag: false,
      shoppingCartData: undefined,
      storeSlug: '',
      showSideBar: false,
      showListingDetail: false,
      quantity: 1,
      currentListing: undefined,
      showListingDetailModifier: false,
      listingID: '',
    }
  }

  componentDidMount() {
    this.autoCloseLoading = false;
    
    const { dispatch } = this.props;
    dispatch({
      type: ActionType.LOADING,
      showLoading: true,
      showLoadingBgc: true,
    });
    setScreenColor('#E3E7F1');
    super.componentDidMount();
    this.autoCloseLoading = false;

    const { reducersState } = this.props;
    const { platformAppSettings } = reducersState.app;

    if (platformAppSettings && platformAppSettings.eshopStoreSlug) {
      this.getMenus(platformAppSettings.eshopStoreSlug, platformAppSettings.eshopStoreID);
    }
    const pageWrap = window.document.querySelector('.page-content-wrapper-web');
    if (pageWrap) {
      pageWrap.addEventListener('scroll', this.handleScroll, { passive: true });
    } else {
      window.addEventListener('scroll', this.handleScroll, { passive: true });
    }
  }
  componentWillUnmount() {
    super.componentWillUnmount();
    const pageWrap = window.document.querySelector('.page-content-wrapper-web');
    if (pageWrap) {
      pageWrap.removeEventListener('scroll', this.handleScroll);
    } else {
      window.removeEventListener('scroll', this.handleScroll);
    }
  }

  getMenus = (storeSlug: string, storeID: string) => {
    this.setState({
      storeSlug,
    })
    const { dispatch } = this.props;
    dispatch({
      type: ActionType.QUERY_LISTINGS,
      storeSlug,
      storeID,
      callback: (menus, store, storeConfig) => {
        let selectedMenuID = '';
        if (Array.isArray(menus) && menus.length > 0) {
          selectedMenuID = menus[0].id;
        }
        this.setState({
          menus,
          store,
          storeConfig,
          loadingData: false,
          selectedMenuID,
        });
        
        this.getStoreShoppingCartData(storeSlug, storeConfig);

        this.closeLoading();
      }
    });
  }

  getStoreShoppingCartData = (storeSlug: string, storeConfig: IStoreConfig | undefined) => {
    const { dispatch, reducersState } = this.props;
    dispatch({
      type: ActionType.GET_STORE_SHOPPING_CART_DATA,
      storeSlug,
      callback: params => {
        this.setState({ shoppingCartData: params.shoppingCartData });
        // const { storeConfig } = this.state;

        const { slugRefStorePickupLocations } = reducersState.store;
        const pickupLocations = slugRefStorePickupLocations[storeSlug] || [];
        let date = dayjs();
        if (storeConfig) {
          date = date.add(storeConfig.delayedDeliveryTime, 'day');
        }
        const { orderInfo } = params.shoppingCartData || {};
        dispatch({
          type: ActionType.CHANGE_SHIPPING_METHOD,
          storeSlug,
          data: {
            shippingMethod: storeConfig && storeConfig.pickUp ? ShippingMethod.PICK_UP : ShippingMethod.DELIVERY,
            pickupLocationID: (pickupLocations.length > 0 && pickupLocations[0].id) || undefined,
            pickupLocation: pickupLocations[0] || null,
            deliveryDate: orderInfo && orderInfo.deliveryDate ? dayjs(orderInfo.deliveryDate) : date,
          },
          callback: shoppingCartData => {
            this.setState({ shoppingCartData });
          }
        });
      },
    });
  }

  handleItemClick = (data: IStore) => {
    if (data.isGroup) {
      this.handleGotoRoute({
        path: paths.STORE_GROUP,
        pathParams: {
          storeSlug: data.slug,
        },
      });
    } else {
      setCanAutoProcurementMethodPopup(true);
      this.handleGotoRoute({
        path: paths.MENU_LIST,
        pathParams: {
          storeSlug: data.slug,
        },
      });
    }
  }

  scrollTimer: any = null;

  scrollLeft = (dom: any, number = 0, time?: any) => {
    if (!time) {
      dom.scrollLeft = number;
      return;
    }
    const spacingTime = 20 // 设置循环的间隔时间  值越小消耗性能越高
    let spacingInex = time / spacingTime // 计算循环的次数
    let left = dom.scrollLeft // 获取当前滚动条位置
    const everLeft = (number - left) / spacingInex // 计算每次滑动的距离
    if (this.scrollTimer) {
      clearInterval(this.scrollTimer) // 清除计时器
    }
    this.scrollTimer = setInterval(() => {
      if (spacingInex > 0) {
        spacingInex--
        this.scrollLeft(dom, left += everLeft)
      } else {
        clearInterval(this.scrollTimer) // 清除计时器
      }
    }, spacingTime)
  }

  noNavScrolling = false;

  handleNavScroll = (checkedMenuID: string) => {
    if (checkedMenuID && checkedMenuID !== this.selectedMenuID) {
      this.selectedMenuID = checkedMenuID;
      this.setState({
        selectedMenuID: checkedMenuID,
      })

      const storeMenuNavItem = document.getElementById(`bm-store-menus-nav-item-${checkedMenuID}`);
      const storeMenuNavBox = document.getElementById('bm-store-menus-nav-box');
      if (storeMenuNavItem && storeMenuNavBox) {
        const itemOffsetLeft = storeMenuNavItem.offsetLeft;
        const itemOffsetWidth = storeMenuNavItem.offsetWidth;
        const boxOffsetWidth = storeMenuNavBox.offsetWidth;
        const boxScrollLeft = storeMenuNavBox.scrollLeft;
        const startValue = itemOffsetLeft - boxOffsetWidth - boxScrollLeft;
        const endValue = itemOffsetLeft + itemOffsetWidth - boxOffsetWidth - boxScrollLeft;

        if (startValue > 0 || endValue > 0 || itemOffsetLeft < boxScrollLeft) {
          // storeMenuNavBox.scrollLeft = itemOffsetLeft + (itemOffsetWidth / 2) - (boxOffsetWidth / 2)
          this.scrollLeft(storeMenuNavBox, itemOffsetLeft + (itemOffsetWidth / 2) - (boxOffsetWidth / 2), 300);
        }
      }
    }
  }

  getScrollY = () => {
    const pageWrap = window.document.querySelector('.page-content-wrapper-web');
    let scrollY = window.scrollY;
    if (pageWrap) {
      scrollY = pageWrap.scrollTop;
    }

    return scrollY;
  }
  handleScroll = () => {
    if (getPageBeingAnimated()) {
      return;
    }
    const appPages = document.getElementsByClassName('bm-p-EShopPage');
    if (appPages && appPages.length) {
      const scrollY = this.getScrollY();
      if (this.noNavScrolling) return;
      const basePx = scalePx(560);
      let beforeMenuHide = false;
      let beforeMenuID = '';
      let checkedMenuID = '';
      const newMenus = this.getEffectiveMenus();
      for (let i = 0; i < newMenus.length; i++) {
        const menu = newMenus[i];
        if (i === 0) {
          checkedMenuID = menu.id;
        }
        const menuNavItem = document.getElementById(`bm-menu-panel-${menu.id}`);
        if (menuNavItem) {
          if (beforeMenuID && menuNavItem.offsetTop >= scrollY + basePx && beforeMenuHide) {
            checkedMenuID = beforeMenuID;
            break;
          }

          beforeMenuHide = menuNavItem.offsetTop < scrollY + basePx;
          beforeMenuID = menu.id;

          if (i === newMenus.length - 1 && beforeMenuHide) {
            checkedMenuID = beforeMenuID;
          }
        }
      }
      this.handleNavScroll(checkedMenuID);
    }
  }
  getEffectiveMenus = (currentMenus?: IFavoriteTab[]) => {
    const { menus, storeConfig } = this.state;
    if (!currentMenus) {
      currentMenus = menus;
    }
    const newMenus = this.filterMenu(currentMenus, storeConfig && storeConfig.pickUp ? ShippingMethod.PICK_UP : ShippingMethod.DELIVERY);

    return newMenus;
  }

  filterMenu = (menus: IFavoriteTab[], shippingMethod: ShippingMethod) => {
    const newMenus: IFavoriteTab[] = [];
    const { routeParams, reducersState } = this.props;
    const { submittedOrderInfo } = reducersState.qrcordShopping.orderInfos;
    const listingIDs: string[] = [];

    if (submittedOrderInfo) {
      submittedOrderInfo.orderEntities.forEach(item => {
        if (Array.isArray(item.lineItems)) {
          item.lineItems.forEach(lineItem => {
            if (lineItem.id) {
              listingIDs.push(lineItem.id);
            }
          })
        }
      })
    }

    menus.forEach(menu => {
      const favoriteSections: IFavoriteSection[] = [];
      if (
        !routeParams.orderNumber
      ) {
        menu.favoriteSections.forEach(section => {
          const listings: IListing[] = [];
          section.listings.forEach(item => {
            if (Array.isArray(item.procurementMethod) && item.procurementMethod.includes(shippingMethod)) {
              listings.push(item);
            }
          });

          if (listings.length > 0) {
            favoriteSections.push({
              ...section,
              listings,
            })
          }
        });

        if (favoriteSections.length > 0) {
          newMenus.push({
            ...menu,
            favoriteSections,
          });
        }
      }
    });
    return newMenus;
  }

  handleMenuNavItemClick = (data: IFavoriteTab) => {
    this.handleNavScroll(data.id);
    this.setState({
      selectedMenuID: data.id,
    })
    this.selectedMenuID = data.id;

    const storeMenuNav = document.getElementById(`bm-menu-panel-${data.id}`);
    const pageWrap = window.document.querySelector('.page-content-wrapper-web');
    const navBgBox = window.document.querySelector('.without-line');
    const withoutBgBox = window.document.querySelector('.streched');
   
    let storeMenuBoxHeight = null;
    let  withoutBHeight = null;
    
    if ( navBgBox && withoutBgBox ) {
      storeMenuBoxHeight = navBgBox.clientHeight;
      withoutBHeight = withoutBgBox.clientHeight;
    }
    // if (storeMenuNav) {
    //   if (pageWrap && storeMenuBoxHeight) {
    //     pageWrap.scrollTo(0, storeMenuNav.offsetTop - storeMenuBoxHeight);
    //   } else if (storeMenuBoxHeight){
    //     window.scrollTo(0, storeMenuNav.offsetTop - storeMenuBoxHeight);
    //   }
    // }
    
    if (storeMenuNav && storeMenuBoxHeight && withoutBHeight) {
      if (pageWrap) {
        pageWrap.scrollTo(0, storeMenuNav.offsetTop - storeMenuBoxHeight - withoutBHeight);
      } else {
        window.scrollTo(0, storeMenuNav.offsetTop - storeMenuBoxHeight - withoutBHeight);
      }
    }

    this.noNavScrolling = true;
    setTimeout(() => {
      this.noNavScrolling = false;
    }, 400)
  }

  menuNavScrollTime: any;

  handleMenuNavScroll = (time?: number) => {
    if (this.menuNavScrollTime) {
      clearTimeout(this.menuNavScrollTime)
      this.menuNavScrollTime = undefined;
    }

    this.menuNavScrollTime = setTimeout(() => {
      this.menuNavScrollTime = undefined;
      const storeMenuNavBox = document.getElementById('bm-store-menus-nav-box');
      const storeMenuNavContent = document.getElementById('bm-store-menus-nav-content');

      if (storeMenuNavBox && storeMenuNavContent) {
        let showMenuNavLeftTag = false;
        if (storeMenuNavBox.scrollLeft > 50) {
          showMenuNavLeftTag = true;
        }

        let showMenuNavRightTag = false;
        if (storeMenuNavBox.scrollLeft + storeMenuNavBox.offsetWidth < storeMenuNavContent.offsetWidth - 50) {
          showMenuNavRightTag = true;
        }

        this.setState({
          showMenuNavLeftTag,
          showMenuNavRightTag,
        });
      }
    }, time || 100);
  }

  handlePlusClick = (data: IListing | undefined, quantity = 1 ) => {
    if (!data) return;
    const { dispatch, reducersState } = this.props;
    const { shoppingCartData, storeSlug, storeConfig } = this.state;
    const { listingCountRef = {}, orderInfo } = shoppingCartData || {};
    const { slugRefStorePickupLocations } = reducersState.store;
    const pickupLocations = slugRefStorePickupLocations[storeSlug] || [];
    const currentShippingMethod = getCurrentShippingMethod(orderInfo, storeConfig);

    if (data.modifiers.length > 0) {
      // 跳转到Modifier Page
      // this.handleGotoRoute({
      //   path: paths.MODIFIER,
      //   pathParams: {
      //     listingID: data.id,
      //     storeSlug,
      //   },
      // })
      this.setState({showListingDetailModifier: true, listingID: data.id})
    } else {
      // 如果存在，增加数量 {
      if (listingCountRef[data.id] && listingCountRef[data.id].quantity > 0) {
        dispatch({
          type: ActionType.MODIFY_LINE_ITEM_QUANTITY,
          storeSlug,
          uuid: listingCountRef[data.id].uuids[0],
          operate: 'ADD',
          quantity,
          callback: shoppingCartData => {
            this.setState({ shoppingCartData, showListingDetail: false });
          },
        });
      } else { // 如果不存在，把商品添加到购物车
        let date = dayjs();
        if (storeConfig) {
          date = date.add(storeConfig.delayedDeliveryTime, 'day');
        }
        dispatch({
          type: ActionType.PUSH_LISTING_TO_SHOPPING_CART,
          storeSlug,
          listingID: data.id,
          clearDifferentData: true,
          shippingMethod: currentShippingMethod,
          quantity,
          pickupLocation: (shoppingCartData?.orderData.pickup_location) || (pickupLocations && pickupLocations.length > 0 && pickupLocations[0]) || undefined,
          pickupLocationID: shoppingCartData?.orderData.pickup_location_id || (pickupLocations && pickupLocations.length > 0 && pickupLocations[0].id) || undefined,
          deliveryDate: currentShippingMethod === ShippingMethod.PICK_UP ? date : undefined,
          callback: shoppingCartData => {
            this.setState({ shoppingCartData, showListingDetail: false });
          },
        });
      }
    }
  }

  handleMinusClick = (data: IListing, quantity = 1 ) => {
    if (!data) return;
    const { dispatch } = this.props;
    const { shoppingCartData, storeSlug } = this.state;
    const { listingCountRef = {} } = shoppingCartData || {};

    if (data.modifiers.length > 0) {
      // TODO 弹出对话框，减少
      if (listingCountRef[data.id] && listingCountRef[data.id].quantity > 0) {
        dispatch({
          type: ActionType.MODIFY_LINE_ITEM_QUANTITY,
          storeSlug,
          uuid: listingCountRef[data.id].uuids[0],
          operate: 'MINUS',
          quantity,
          callback: shoppingCartData => {
            this.setState({ shoppingCartData });
          },
        });
      }
    } else {
      if (listingCountRef[data.id] && listingCountRef[data.id].quantity > 0) {
        dispatch({
          type: ActionType.MODIFY_LINE_ITEM_QUANTITY,
          storeSlug,
          uuid: listingCountRef[data.id].uuids[0],
          operate: 'MINUS',
          quantity,
          callback: shoppingCartData => {
            this.setState({ shoppingCartData });
          },
        });
      }
    }
  }

  handleChangeQuantity = (action: 'PLUS' | 'MINUS') => {
    const { quantity } = this.state;
    if (action === 'PLUS') {
      this.setState ({
        quantity: quantity + 1,
      })
    } else {
      if (quantity === 1) return;
      this.setState ({
        quantity: quantity - 1,
      })
    }
  }

  onOpenChange = () => {
    const { reducersState } = this.props;
    const { platformConfig } = reducersState.app;
    if (platformConfig.showSideBar) {
      this.setState({
        showSideBar: !this.state.showSideBar,
      });
    }
  }

  handleShowSideBar = () => {
    this.setState({
      showSideBar: !this.state.showSideBar,
    });
  }

  handlePopupClick = (data: IListing) => {
    if (!data) return;
    // const { storeSlug } = this.state;
    if (data.modifiers.length > 0) {
      // 跳转到Modifier Page
      // this.handleGotoRoute({
      //   path: paths.MODIFIER,
      //   pathParams: {
      //     listingID: data.id,
      //     storeSlug,
      //   },
      // })
      this.setState({showListingDetailModifier: true, listingID: data.id})
    } else {
      // show popup
      this.setState({
        showListingDetail: true,
        currentListing: data,
        quantity: 1,
      })
    }
  }

  handleChangeQuantityTo = (data: IListing, quantityTo: number) => {
    if (!data) return;

    const { shoppingCartData } = this.state;
    const { listingCountRef = {} } = shoppingCartData || {};
    let currentQuantity = 0;

    if(data.id && listingCountRef[data.id].quantity > 0) {
      currentQuantity = listingCountRef[data.id].quantity;
    }

    // add to
    if (quantityTo > currentQuantity) {
      this.handlePlusClick(data, quantityTo - currentQuantity);
    } else { // minus
      this.handleMinusClick(data, currentQuantity - quantityTo);
    }
  }

  handleModifierPage = () => {
    const { reducersState } = this.props;
    const { platformAppSettings } = reducersState.app;

    if (platformAppSettings && platformAppSettings.eshopStoreSlug) {
      this.getMenus(platformAppSettings.eshopStoreSlug, platformAppSettings.eshopStoreID);
    }
    this.setState({showListingDetailModifier: false});
  }
  render() {
    const { reducersState, history } = this.props;
    const {
      loadingData,
      menus,
      selectedMenuID,
      showMenuNavLeftTag,
      showMenuNavRightTag,
      shoppingCartData,
      store,
      storeConfig,
      currentListing,
      showListingDetailModifier,
      listingID,
      storeSlug,
    } = this.state;
    const { openingHours } = store || {};
    const { platformConfig } = reducersState.app;
    const hasMenus = menus.length > 0;

    const { listingCountRef = {} } = shoppingCartData || {};
    let parseOpeningHoursResult: IParseOpeningHoursResult | undefined = undefined;
    if (openingHours) {
      parseOpeningHoursResult = parseOpeningHours(openingHours);
    }

    let isOpen = false;
    let currentDay = 1;
    if (parseOpeningHoursResult) {
      isOpen = parseOpeningHoursResult.isOpen;
      currentDay = parseOpeningHoursResult.currentDay;
    }
    const { showSideBar } = platformConfig;
    const newMenus = this.getEffectiveMenus();
    return (
      <>
        <Layout
          {...this.props}
          className={`${prefix2}`}
          navContent={getIntl().page.eshopTitle}
          navIcon={showSideBar && <Icon className="html-embed-7 w-embed side-bar-icon" type={IconType.MENUS_OUTLINED} />}
          onLeftClick={() => { this.onOpenChange(); }}
        >
          <EShopPageStyled>
            <>
              {
                hasMenus &&
                <div className="nav_bg streched colored w-nav">
                  <div className="overlay-nav w-container">
                    <MenuNav
                      data={newMenus}
                      isFixed={true}
                      selectedKey={selectedMenuID}
                      showMenuNavLeftTag={showMenuNavLeftTag}
                      showMenuNavRightTag={showMenuNavRightTag}
                      onItemClick={this.handleMenuNavItemClick}
                      onScroll={() => this.handleMenuNavScroll()}
                      reducersState={reducersState}
                    />
                  </div>
                </div>
              }
              {
                hasMenus &&
                <div className="give-space">
                  <MenuList
                    data={newMenus}
                    isOpen={isOpen}
                    isGrid={true}
                    currentDay={currentDay}
                    listingCountRef={listingCountRef}
                    onlyShow={storeConfig && !storeConfig.showOrder && storeConfig.showMenu}
                    onPlusClick={this.handlePlusClick}
                    onMinusClick={this.handleMinusClick}
                    hideSubTitle={storeConfig && storeConfig.hideSecondaryMenu}
                    reducersState={reducersState}
                    currencyCode={store && store.currencyCode}
                    onPopupClick={this.handlePopupClick}
                    handleChangeQuantityTo={this.handleChangeQuantityTo}
                  />
                </div>
                
              }
              {
                !loadingData && platformConfig.id && !hasMenus &&
                <div className="no-stores">
                  <Status
                    iconType={IconType.TAG_STORE}
                    title="There are no shops in the city."
                    describe="We are working hard to invite you to join us, please look forward to it!"
                  />
                </div>
              }
              <div className={`modal-wrapper ${this.state.showListingDetail ? 'modal-wrapper-show' : ''}`}>
                <div className="modal-body">
                  <div className="item-image-pop-up" style={{backgroundImage: `url(${(currentListing && currentListing.imageUrl) || '/images/default-store.png'})`}}>
                    <div className="div-block-77">
                      <div className="nav-button-1 close-modal">
                        <div className="html-embed-7 w-embed">
                          <Icon className="circle-back-icon-outlined" type={IconType.LEFT_OUTLINED} onClick={() => { this.setState({ showListingDetail: false })}} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="food-description">
                    <div className="div-block-69">
                      <div className="item-info">
                        <h4 className="heading-3">{getI18nText(currentListing, 'name', reducersState.app.language) || (currentListing && currentListing.name)}</h4>
                        {
                          currentListing && currentListing.description &&
                          <div className="paragraph-2-pop-up" dangerouslySetInnerHTML={{ __html: getI18nText(currentListing, 'description', reducersState.app.language) || currentListing.description}}></div>
                        }
                      </div>
                      <div className="price">
                        {
                          currentListing &&
                          <div className="text-block-6">{formatCurrency(currentListing && currentListing.price, store && store.currencyCode)}</div>
                        }
                      </div>
                    </div>
                    <div className="div-block-89">
                      <div className="q-flex">
                        <div className="q-dec">
                          <Icon
                            className={`item-minus ${this.state.quantity === 1 ? 'disable-minus' : ''}`}
                            type={IconType.MINUS_CIRCLE_OUTLINED}
                            onClick={() => this.handleChangeQuantity('MINUS')}
                          />
                        </div>
                        <div className="q-num">{this.state.quantity}</div>
                        <div className="q-inc">
                          <Icon
                            className="item-plus"
                            type={IconType.PLUS_CIRCLE_OUTLINED}
                            onClick={() => this.handleChangeQuantity('PLUS')}
                          />
                        </div>
                      </div>
                      <div data-w-id="42a1a8d4-0f2a-5b2e-88e6-c4ecee125597" className="add-to-cart-button close-modal" onClick={() => this.handlePlusClick(currentListing, this.state.quantity)}>
                        <div className="text-block-12">{getIntl().page.addShoppingCart}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-w-id="42a1a8d4-0f2a-5b2e-88e6-c4ecee12559a" className="c-popup-close-time close-modal" onClick={() => { this.setState({ showListingDetail: false }); }}></div>
              </div>
              {
                showListingDetailModifier &&
                <div className="modal-wrapper modal-wrapper-show">
                  <div className="modal-body modifier-page">
                    <ModifierPage
                      {...this.props}
                      listingID={listingID.toString()}
                      onClose={() => this.handleModifierPage()}
                      storeSlug = {storeSlug}
                    ></ModifierPage>
                  </div>
                  <div className="c-popup-close-time close-modal" onClick={() => this.setState({showListingDetailModifier: false})}></div>
                </div>
              }
              {
                store && storeConfig && history.location.pathname.includes(paths.ESHOP)  &&
                <ShoppingCartBar
                  store={store}
                  reducersState={reducersState}
                  className="has-tab-bar"
                  resetState={state => this.setState(state)}
                  fromPage="eShop"
                  currencyCode={store.currencyCode}
                />
              }
              {
                platformConfig.showTabs &&
                <BmTabBar
                  selectedKey={TabsOption.ESHOP}
                  reducersState={reducersState}
                />
              }
              {
                showSideBar &&
                <TemporaryStyled>
                  <SideBar
                    showSideBar={this.state.showSideBar}
                    onItemClick={this.handleShowSideBar}
                    reducersState={reducersState}
                  />
                </TemporaryStyled>
              }
            </>
          </EShopPageStyled>
        </Layout>
      </>
    );
  }
}

export default EShopPage;
