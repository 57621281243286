import React, { useState } from 'react';
import Icon, { IconType } from '@/components/Icon';
import { getI18nText, IParseOpeningHoursResult } from '@/utils/app';
import { IStore, IStoreConfig, IStoreImage } from '@/actions/store-action';
import { useHistory } from 'react-router-dom';
import { createRouteUrl } from '@/utils';
import paths from '@/routes/paths';
import { goToRoute } from '@/utils/route-util';
import { IReducersState } from '@/reducers';
import { getIntl, getIntlByPath } from '@/components/App/App';

interface IStoreInfoProps {
  store?: IStore;
  storeImgUrl?: string;
  titleFixed?: boolean;
  storeImages?: IStoreImage[];
  parseOpeningHoursResult?: IParseOpeningHoursResult;
  storeConfig?: IStoreConfig;
  hideBooking?: boolean;
  onItemClick?: () => void;
  reducersState: IReducersState;
}

const StoreInfo: React.FC<IStoreInfoProps> = props => {
  const {
    store,
    storeImgUrl,
    // titleFixed,
    parseOpeningHoursResult,
    storeConfig,
    // storeImages,
    reducersState,
    hideBooking = false,
  } = props;

  const history = useHistory();

  const [showOpenHours, setShowOpenHours] = useState(false);
  const {
    name: title = '',
    slug: storeSlug = '',
    storeScore = 5,
  } = store || {};

  const {
    showBooking,
    showQueueing,
  } = storeConfig || {};

  const handleBookingClick = () => {
    goToRoute({
      history,
      pathname: createRouteUrl(paths.BOOKING_NEW, { storeSlug }),
    });
  }

  const handleQueueingClick = () => {
    goToRoute({
      history,
      pathname: createRouteUrl(paths.QUEUEING_NEW, { storeSlug }),
    });
  }

  const { hidePhoneNumberHyperlink } = reducersState.app.platformConfig;
  const {deviceInfo } = reducersState.app;

  return (
    <>
      <div className="header-image" style={{ backgroundImage: `url(${storeImgUrl})` }}></div>
      <div className="section store-info">
        <div className="container w-container">
          <h1 className="h1" id="bm-store-title" >{store && (getI18nText(store, 'title', reducersState.app.language) || title)}</h1>
          <div className="div-block-2">
            {
              storeConfig && storeConfig.showFeedback &&
              <>
                <div className="div-block">
                  <Icon className="store-msg-icon score-star" type={IconType.STAR_OUTLINED} />
                  <div className="text-block-1">{(Number(storeScore) || 5).toFixed(1)}</div>
                  <div className="text-block-5---subtitle">(+99)<br /></div>
                </div>
                <div className="line-separator"></div>
              </>
            }
            <div className="div-block">
              <Icon className="store-msg-icon" type={IconType.STORE_OUTLINED} showTagColor={true} />
              <div id="open-status" className="current-status">
                {
                  parseOpeningHoursResult && parseOpeningHoursResult.isOpen &&
                  getIntl().page.opening
                }
                {
                  parseOpeningHoursResult && !parseOpeningHoursResult.isOpen &&
                  getIntl().page.closed
                }
              </div>
            </div>
            <div className="line-separator"></div>
            <div className="div-block" onClick={() => setShowOpenHours(true)}>
              <Icon className="store-msg-icon" type={IconType.TIME_OUTLINED} showTagColor={true} />
              <div id="opening-hours" className="current-opening-hours">
                {parseOpeningHoursResult && parseOpeningHoursResult.currentOpeningTimeTitle}
              </div>
              <Icon  className="opening-hours-icon" type={IconType.RIGHT_OUTLINED} />
            </div>
          </div>
          <div className="div-block-2">
            <div className="div-block">
              <Icon className="store-msg-icon" type={IconType.MAP_OUTLINED} />
              <div className="text-block-1">
                {store && (getI18nText(store, 'address1', reducersState.app.language) || store.address)}
              </div>
            </div>
          </div>
          {
            store &&
            <div className="div-block-2">
              <div className="div-block">
                <Icon className="store-msg-icon" type={IconType.TEL_PHONE_OUTLINED} />
                {
                  (hidePhoneNumberHyperlink || (deviceInfo.isNativeApp && !deviceInfo.isAppleApp)) &&
                  <div className="text-block-1">{store.phone}</div>
                }
                {
                  !hidePhoneNumberHyperlink && (!deviceInfo.isNativeApp || deviceInfo.isAppleApp) &&
                  <a href={`tel: ${store.phone}`} className="text-block-1">{store.phone}</a>
                }
              </div>
            </div>
          }
          {
            !hideBooking && (showBooking || showQueueing) &&
            <div className="row">
              {
                showBooking &&
                <div className="col col-sm-1">
                  <div className="booking-button" onClick={handleBookingClick}>
                    <div className="div-block-3">
                      <Icon className="booking-icon" type={IconType.BOOKING_OUTLINED} />
                      <div className="text-block-3">{getIntl().page.booking}</div>
                    </div>
                  </div>
                </div>
              }
              {
                showQueueing &&
                <div className="col col-sm-11">
                  <div className="booking-button" onClick={handleQueueingClick}>
                    <div className="div-block-3">
                      <Icon className="booking-icon queueing-icon" type={IconType.QUEUEING_OUTLINED} />
                      <div className="text-block-3">{getIntl().page.queueing}</div>
                    </div>
                  </div>
                </div>
              }
            </div>
          }
        </div>
      </div>
      <div className={`${showOpenHours ?'modal-wrapper-2-show': ''} bm-c-procurement-method-modal modal-wrapper-2`}>
        <div className="c-popup-close-time close-modal" onClick={() => setShowOpenHours(false)}></div>
        <div className="opening-hours screen">
          <div className="opening-time-titles">
            <h3 className="heading-3 procurement">
              {getIntl().page.openHours}
            </h3>
          </div>
          {
            parseOpeningHoursResult && parseOpeningHoursResult.daysHours.map((item, index) => (
              <div className={`opening-hour-display ${item.isCurrentDay ? 'opening-day' :parseOpeningHoursResult.daysHours.length === index+1? 'lst': ''}`} key={item.key}>
                <h3 className="h3">{getIntlByPath(`common.${item.key}`)}</h3>
                <div className="text-block-2">
                  {
                    item.openingHoursStrs.map(ohsItem => (
                      <div className="si-modal-list-item-hour" key={ohsItem}>{ohsItem}</div>
                    ))
                  }
                  {
                    item.openingTimeTitle === 'N/A' &&
                    getIntl().page.closed
                  }
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </>
  );
}

export default StoreInfo;
