
import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import ActionType from '@/actions/action-type';
import { IPayCard, IGiftCard } from '@/actions/pay-action';
import Icon, { IconType } from '@/components/Icon';
import { parseRouteParams, findNode, minus, sameItemInMultipleArray } from '@/utils';
import { IObject } from '@/components/bm-common';
import { prefix } from '.';
import { setScreenColor, getAppTheme, getIntl } from '@/components/App/App';
import SelectPayMethod from '@/components/SelectPayMethod';
import { PayTypes, IPayMethod } from '@/lib/payment';
import { giftCardToPayMethod, creditCardToPayMethod } from '@/utils/pay';
import { IOrderInfo } from '@/lib/order-builder/models/OrderEntity';

export interface IPayMethodPageProps extends IBasePageProps {
  handleCloseClick: () => void;
  orderNumber?: string;
  storeSlug?: string;
  deferredOrder?: boolean;
  showGooglePay?: boolean;
  onAddcardedClick: () => void;
}
export interface IPayMethodPageState {
  payCards: IPayCard[];
  storeGiftCards: IGiftCard[];
  selectedGiftCard: IGiftCard | undefined; // 当前选中的购物卡
  selectedMethod: string | undefined; // 当前选中的
  orderInfo?: IOrderInfo;
  showPayCardModal?: boolean;
}

class PayMethodPage extends BasePage<IPayMethodPageProps, IPayMethodPageState> {
  routeParams: IObject = {};

  state: IPayMethodPageState = {
    orderInfo: undefined,
    payCards: [],
    storeGiftCards: [],
    selectedGiftCard: undefined,
    selectedMethod: undefined,
  }

  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.lightBackgroundColor) {
      setScreenColor(appTheme.lightBackgroundColor);
    }
    super.componentDidMount();

    this.routeParams = parseRouteParams(this.props);
    const { dispatch, reducersState, storeSlug, orderNumber } = this.props;
    const { fromUrlPayData } = reducersState.pay;

    if (((this.routeParams.orderNumber && this.routeParams.storeSlug) || (orderNumber && storeSlug))) {
      dispatch({
        type: ActionType.QUERY_ORDER_INFO,
        storeSlug: this.routeParams.storeSlug || storeSlug,
        orderNumber: this.routeParams.orderNumber || orderNumber,
        fromUrlPayData,
        callback: (orderInfo, store) => {
          this.setState({ orderInfo });

          if (store) {
            this.loadGiftCards(store.slug, store.id);
          }
        }
      });

      dispatch({
        type: ActionType.QUERY_PAY_CARDS,
        callback: payCards => {
          this.setState({ payCards });
        }
      });

      dispatch({
        type: ActionType.QUERY_CUSTOMER,
        storeSlug: this.routeParams.storeSlug,
      });
    }



    const {payMethods, anonymousGiftCards, keyRefGiftCards } = reducersState.pay;
    let selectedGiftCard: IGiftCard | undefined; // 当前选中的购物卡
    let selectedMethod: string | undefined; // 当前选中的
    const storeGiftCards: IGiftCard[] = [];
    Object.values(keyRefGiftCards).forEach(items => {
      storeGiftCards.push(...items);
    });
    payMethods.forEach(item => {
      if ([PayTypes.STORE_CREDIT, PayTypes.ANONYMOUS_GIFT_CARD].includes(item.type)) {
        let gcItem = findNode<IGiftCard>(anonymousGiftCards, 'id', item.id);
        if (!gcItem) {
          gcItem = findNode<IGiftCard>(storeGiftCards, 'id', item.id);
        }
        if (gcItem) {
          selectedGiftCard = gcItem;
        }
      } else if (item.type === PayTypes.CREDIT_CARD) {
        selectedMethod = `${item.creditCardType}_${item.id}`;
      } else {
        selectedMethod = item.type;
      }
    });

    this.setState({
      selectedMethod,
      selectedGiftCard,
    })
  }

  loadGiftCards = (storeSlug: string, storeID: string) => {
    const { dispatch } = this.props;
    dispatch({
      type: ActionType.QUERY_CUSTOMER,
      storeSlug,
      callback: customer => {
        if (customer) {
          dispatch({
            type: ActionType.QUERY_GIFT_CARDS,
            storeSlug,
            storeID,
            customerID: customer.id,
            callback: storeGiftCards => {
              this.setState({ storeGiftCards });
            }
          });
        }
      }
    });
  }

  handleItemClick = (method: PayTypes, data?: IGiftCard | IPayCard) => {
    
    let { selectedGiftCard } = this.state;
    const { orderInfo } = this.state;
    const { dispatch, reducersState, handleCloseClick, onAddcardedClick } = this.props;
    if (!orderInfo) return;
    let giftCardPayMethods: IPayMethod[] = [];
    let balance = 0;
    if (selectedGiftCard && selectedGiftCard.balance < orderInfo.initialTotal) {
      balance = selectedGiftCard.balance || 0;
      giftCardPayMethods = [giftCardToPayMethod(selectedGiftCard, orderInfo, selectedGiftCard.balance)];
    }

    if (method === PayTypes.CREDIT_CARD) {
      // this.handleGotoRoute({
      //   path: paths.PAY_CARD,
      // });
      onAddcardedClick();

    } else if ([PayTypes.ANONYMOUS_GIFT_CARD, PayTypes.STORE_CREDIT].includes(method)) {
      if (data) {
        const giftCard = data as IGiftCard;
        if (selectedGiftCard && selectedGiftCard.id === giftCard.id) {
          selectedGiftCard = undefined;
        } else {
          selectedGiftCard = giftCard;
        }

        this.setState({ selectedGiftCard });

        const gcPayMethod = giftCardToPayMethod(giftCard, orderInfo, 0)
        if (giftCard.balance >= orderInfo.initialTotal) {
          dispatch({
            type: ActionType.MODIFY_PAY_METHODS,
            payMethods: [gcPayMethod]
          });
          handleCloseClick();
        } else {
          const { payMethods } = reducersState.pay;
          let payMethod: IPayMethod | undefined = undefined;
          for (let i = 0; i < payMethods.length; i++) {
            const tempPayMethod = payMethods[i];
            if (![PayTypes.STORE_CREDIT, PayTypes.ANONYMOUS_GIFT_CARD].includes(tempPayMethod.type)) {
              payMethod = JSON.parse(JSON.stringify(tempPayMethod));
              break;
            }
          }

          if (payMethod) {
            payMethod.amount = minus(orderInfo.initialTotal, Number(gcPayMethod.balance));
            gcPayMethod.amount = gcPayMethod.balance ? Number(gcPayMethod.balance) : 0;
            dispatch({
              type: ActionType.MODIFY_PAY_METHODS,
              payMethods: [gcPayMethod, payMethod],
            });
            handleCloseClick();
          }
        }
      }
    } else if ([PayTypes.VISA, PayTypes.MASTER, PayTypes.AMEX, PayTypes.UNION_PAY].includes(method)) {
      if (data) {
        const creditCard = data as IPayCard;
        this.setState({ selectedMethod: `${creditCard.type}_${creditCard.id}` });

        dispatch({
          type: ActionType.MODIFY_PAY_METHODS,
          payMethods: [
            ...giftCardPayMethods,
            creditCardToPayMethod(creditCard, orderInfo, balance),
          ],
        });
        handleCloseClick();
      }
    } else if (method === PayTypes.APPLE_PAY) {
      this.setState({ selectedMethod: method });
      const { gatewayAppSettings, platformAppSettings, deviceInfo } = reducersState.app;
      if (gatewayAppSettings && platformAppSettings) {
        dispatch({
          type: ActionType.MODIFY_PAY_METHODS,
          payMethods: [
            ...giftCardPayMethods,
            {
              id: PayTypes.APPLE_PAY,
              type: PayTypes.APPLE_PAY,
              countryCode: 'HK',
              currencyCode: orderInfo.currencyCode,
              supportedNetworks: gatewayAppSettings.applePayMethodKeys,
              appMerchantId: deviceInfo.isAppleApp && platformAppSettings.merchantID ? platformAppSettings.merchantID : gatewayAppSettings.merchantID,
              appMerchantDomain: gatewayAppSettings.domain,
              label: platformAppSettings.name,
              amount: minus(orderInfo.initialTotal, balance),
            }
          ],
        });
        handleCloseClick();
      }
    } else if ([PayTypes.ALIPAY, PayTypes.ALIPAY_HK].includes(method)) {
      this.setState({ selectedMethod: method });
      const type = method === PayTypes.ALIPAY ? PayTypes.ALIPAY : PayTypes.ALIPAY_HK;
      dispatch({
        type: ActionType.MODIFY_PAY_METHODS,
        payMethods: [
          ...giftCardPayMethods,
          {
            id: type,
            currencyCode: orderInfo.currencyCode,
            type,
            returnUrl: '',
            amount: minus(orderInfo.initialTotal, balance),
          }
        ],
      });
      handleCloseClick();
    } else if (method === PayTypes.WECHAT_PAY) {
      this.setState({ selectedMethod: method });
      dispatch({
        type: ActionType.MODIFY_PAY_METHODS,
        payMethods: [
          ...giftCardPayMethods,
          {
            id: PayTypes.WECHAT_PAY,
            currencyCode: orderInfo.currencyCode,
            type: PayTypes.WECHAT_PAY,
            returnUrl: '',
            amount: minus(orderInfo.initialTotal, balance),
          }
        ],
      });
      handleCloseClick();
    } else if (method === PayTypes.TAP_GO) {
      this.setState({ selectedMethod: method });
      dispatch({
        type: ActionType.MODIFY_PAY_METHODS,
        payMethods: [
          ...giftCardPayMethods,
          {
            id: PayTypes.TAP_GO,
            currencyCode: orderInfo.currencyCode,
            type: PayTypes.TAP_GO,
            returnUrl: '',
            amount: minus(orderInfo.initialTotal, balance),
          }
        ],
      });
      handleCloseClick();
    } else if (method === PayTypes.DEFERRED_BILLING) {
      this.setState({ selectedMethod: method });
      dispatch({
        type: ActionType.MODIFY_PAY_METHODS,
        payMethods: [
          ...giftCardPayMethods,
          {
            id: PayTypes.DEFERRED_BILLING,
            currencyCode: orderInfo.currencyCode,
            type: PayTypes.DEFERRED_BILLING,
            amount: minus(orderInfo.initialTotal, balance),
          }
        ],
      });
      handleCloseClick();
    } else if (method === PayTypes.CASH_ON_DELIVERY) {
      this.setState({ selectedMethod: method });
      dispatch({
        type: ActionType.MODIFY_PAY_METHODS,
        payMethods: [
          ...giftCardPayMethods,
          {
            id: PayTypes.CASH_ON_DELIVERY,
            currencyCode: orderInfo.currencyCode,
            type: PayTypes.CASH_ON_DELIVERY,
            amount: minus(orderInfo.initialTotal, balance),
          }
        ],
      });
      handleCloseClick();
    } else if (method === PayTypes.GOOGLE_PAY) {
      this.setState({ selectedMethod: method });
      dispatch({
        type: ActionType.MODIFY_PAY_METHODS,
        payMethods: [
          ...giftCardPayMethods,
          {
            id: PayTypes.GOOGLE_PAY,
            currencyCode: orderInfo.currencyCode,
            type: PayTypes.GOOGLE_PAY,
            amount: minus(orderInfo.initialTotal, balance),
            googlePayData: '',
          }
        ],
      });
      handleCloseClick();
    }
  }

  // 处理支持的支付方式, 平台 店铺 个人都支持才能真正支持
  handleSupportPayMethod = (): PayTypes[] => {
    const { reducersState, deferredOrder } = this.props;
    const { platformAppSettings } = reducersState.app;
    const { slugRefStoreConfig } = reducersState.store;
    const currentStoreconfig = slugRefStoreConfig[this.routeParams.storeSlug];


    let canPayMethods: PayTypes[] = [];
    if (platformAppSettings) {
      const platformPayMethodKeys = deferredOrder ? platformAppSettings.deferredInvoicesPayMethodKeys : platformAppSettings.payMethodKeys;
      const storeConfigmethodKeys = currentStoreconfig && currentStoreconfig.payMethodKeys.length > 0 ? currentStoreconfig.payMethodKeys : [PayTypes.VISA, PayTypes.MASTER, PayTypes.AMEX, PayTypes.UNION_PAY];
      canPayMethods =  sameItemInMultipleArray(platformPayMethodKeys, storeConfigmethodKeys);
    }

    return canPayMethods;
  }

  render() {
    const { reducersState, handleCloseClick, showGooglePay } = this.props;
    const { anonymousGiftCards } = reducersState.pay;
    const { slugRefCustomer } = reducersState.user;
    const { payCards, storeGiftCards, selectedGiftCard, selectedMethod, orderInfo } = this.state;

    const canPayMethods = this.handleSupportPayMethod();
    const slugRefCustomerSlug = slugRefCustomer[this.routeParams.storeSlug] || {};
    const allowCompanyAccount = slugRefCustomerSlug.allowCompanyAccount;

    return (
      <div className={`${prefix}`} >
        <div className="c-popup-close-2 close-modal" onClick={handleCloseClick}></div>
        <div className="flex-pop-up widen">
          <div className="top-header no-line">
            <h2 className="heading-2">{getIntl().page.payment}</h2>
            <div className="x-icon close-modal">
              <Icon className="w-embed" type={IconType.CLOSE_OUTLINED} onClick={handleCloseClick} />
            </div>
          </div>
          <div className="subtitle-popup">
            <div className="div-block-499">
              <div className="text-block-94">{getIntl().page.selectPaymentMethod}</div>
            </div>
          </div>
          <SelectPayMethod
            className="page-content newpay-method-page"
            storeGiftCards={storeGiftCards}
            anonymousGiftCards={anonymousGiftCards}
            payCards={payCards}
            selectedGiftCard={selectedGiftCard}
            selectedMethod={selectedMethod}
            onItemClick={this.handleItemClick}
            canPayMethods={canPayMethods}
            allowCompanyAccount={allowCompanyAccount}
            currencyCode={orderInfo ? orderInfo.currencyCode : ''}
            showGooglePay={showGooglePay}
          />
        </div>
      </div>
        
    // </Layout>
    );
  }
}

export default PayMethodPage;
