import React, {useState, useEffect} from 'react';
import Icon, { IconType } from '@/components/Icon';
import ActionType from '@/actions/action-type';
import { IFeedbackTags, IFeedback } from '@/data/Feedback';
import { IDispatch } from '@/actions';
import FeedbackLabel from './FeedbackLabel';
import { getIntl } from '@/components/App/App';
import { langFormat } from '@/utils/app';

interface IFeedbackProps {
  onCancel: () => void;
  dispatch: IDispatch;
  storeTitle: string;
  storeID: string;
  userID: string;
  orderNumber: string;
  platformID: string;
  nowRating: number;
  existence: boolean;
  tag: IFeedbackTags | undefined;
  feedBack: IFeedback | undefined;
}

const Feedback: React.FC<IFeedbackProps> = props => {
  const [rating, setRating] = useState<number>(0);
  const [labels, setLabels] = useState<string[]>([]);

  const {
    onCancel,
    dispatch,
    storeTitle,
    platformID,
    storeID,
    userID,
    orderNumber,
    nowRating,
    existence,
    tag,
    feedBack,
  } = props

  useEffect(() => {
    setRating(nowRating);
    window.addEventListener('click', handleCancel, { passive: true });
    return () => {
      window.removeEventListener('click', handleCancel);
    }
  }, []);

  const handleCancel = (e: MouseEvent) => {
    const div = document.getElementById('loading-data');
    const feedback = document.getElementById('feedback');
    if (div && feedback) {
      const height = (window.innerHeight - feedback.scrollHeight)/2;
      const event = e || window.event;
      if (event.clientY < height || event.clientY > (height + feedback.scrollHeight)) {
        onCancel();
      }
    }
  }

  const handleSelectIcon = (star: number) => {
    if (!existence) {
      setRating(star);
      setLabels([]);
    }
  }

  const handleAddFeedback = () => {
    dispatch({
      type: ActionType.ADD_FEEDBACK,
      platformID,
      storeID,
      userID,
      feedbackTag: labels,
      score: rating,
      orderNumber,
      callback: record => {
        if (record) {
          onCancel()
        }
      }
    })
  }

  return (
    <div>
      <div className="start-loading-data" id="loading-data">
        <div className="loading-div">
          <div className="feedback" id="feedback">
            <div className="feedback-content">
              <div className="feedback-content-title">{langFormat(getIntl().page.feedBackTitle, {storeTitle: storeTitle})}</div>
              <div className="feedback-content-tip">{getIntl().page.feedBackTip}</div>
              <div className="feedback-content-star">
                <Icon className={rating >= 1 ? 'select-rating' : 'unselected-rating'} type={IconType.STAR_OUTLINED} onClick={() => handleSelectIcon(1)}/>
                <Icon className={rating >= 2 ? 'select-rating' : 'unselected-rating'} type={IconType.STAR_OUTLINED} onClick={() => handleSelectIcon(2)}/>
                <Icon className={rating >= 3 ? 'select-rating' : 'unselected-rating'} type={IconType.STAR_OUTLINED} onClick={() => handleSelectIcon(3)}/>
                <Icon className={rating >= 4 ? 'select-rating' : 'unselected-rating'} type={IconType.STAR_OUTLINED} onClick={() => handleSelectIcon(4)}/>
                <Icon className={rating >= 5 ? 'select-rating' : 'unselected-rating'} type={IconType.STAR_OUTLINED} onClick={() => handleSelectIcon(5)}/>
              </div>
              <div>
                <div className="feedback-content-label">
                  {
                    rating > 0 && tag && tag[rating] && tag[rating].map(item => {
                      return <FeedbackLabel onSelect={setLabels} labels={labels} label={item.id} key={item.id} data={item.tag} existence={existence} feedBack={feedBack}/>
                    })
                  }
                </div>
              </div>
              {
                existence && 
                <div className="feedback-content-button" onClick={() => onCancel()}>{getIntl().common.cancel}</div>
              }
              {
                !existence &&
                <button className={`feedback-content-button ${rating===0 && 'disabled'}`} disabled={rating === 0} onClick={() => handleAddFeedback()}>{getIntl().common.continue}</button>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Feedback;
