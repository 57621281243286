enum ActionType {
  /** Store */
  QUERY_STORE = 'QUERY_STORE',
  QUERY_STORE_BY_ID = 'QUERY_STORE_BY_ID',
  SET_STORE = 'SET_STORE',
  SET_ALL_STORES_INFO = 'SET_ALL_STORES_INFO',
  SET_STORE_CONFIG = 'SET_STORE_CONFIG',
  QUERY_STORES = 'QUERY_STORES',
  QUERY_STORE_PICKUP_LOCATIONS = 'QUERY_STORE_PICKUP_LOCATIONS',
  SET_STORE_PICKUP_LOCATIONS = 'SET_STORE_PICKUP_LOCATIONS',
  QUERY_STORES_BY_CATEGORY = 'QUERY_STORES_BY_CATEGORY',
  QUERY_BRANDS_BY_CATEGORY = 'QUERY_BRANDS_BY_CATEGORY',
  QUERY_STORES_CONFIGS = 'QUERY_STORES_CONFIGS',
  SET_STORES_CONFIGS = 'SET_STORES_CONFIGS',
  PUSH_STORES = 'PUSH_STORES',
  PUSH_STORES_BY_CATEGORY = 'PUSH_STORES_BY_CATEGORY',
  PUSH_BRANDS_BY_CATEGORY = 'PUSH_BRANDS_BY_CATEGORY',
  QUERY_GROUP_STORES = 'QUERY_GROUP_STORES',
  SET_GROUP_STORES = 'SET_GROUP_STORES',
  QUERY_RECOMMENDED_STORES = 'QUERY_RECOMMENDED_STORES',
  SET_RECOMMENDED_STORES = 'SET_RECOMMENDED_STORES',
  SEARCH_STORES = 'SEARCH_STORES',
  SET_SEARCH_STORES = 'SET_SEARCH_STORES',
  QUERY_STORE_TABLES = 'QUERY_STORE_TABLES',
  SET_STORE_TABLES = 'SET_STORE_TABLES',
  QUERY_BLOG_LIST = 'QUERY_BLOG_LIST',
  SET_STORE_BLOGS = 'SET_STORE_BLOGS',
  SET_STORE_COMPANY_INFO = 'SET_STORE_COMPANY_INFO',
  SET_STORE_PROMOTIONS = 'SET_STORE_PROMOTIONS',
  QUERY_BLOG = 'QUERY_BLOG',
  QUERY_STORE_IMAGES = 'QUERY_STORE_IMAGES',
  SET_STORE_IMAGES = 'SET_STORE_IMAGES',
  CREATE_STORE = 'CREATE_STORE',
  QUERY_STORES_BY_USER = 'QUERY_STORES_BY_USER',
  UPDATE_STORE = 'UPDATE_STORE',
  QUERY_PLATFORM_STORE_ASSOCIATIONS = 'QUERY_PLATFORM_STORE_ASSOCIATIONS',
  ADD_PLATFORM_STORE_ASSOCIATIONS = 'ADD_PLATFORM_STORE_ASSOCIATIONS',
  UPDATE_PLATFORM_STORE_ASSOCIATIONS = 'UPDATE_PLATFORM_STORE_ASSOCIATIONS',
  CLEAR_STORE = 'CLEAR_STORE',
  UPDATE_STORE_IMAGES = 'UPDATE_STORE_IMAGES',
  UPDATE_STORE_CONFIGS = 'UPDATE_STORE_CONFIGS',
  SEARCH_STORES_LOCATION = 'SEARCH_STORES_LOCATION',
  SET_STORES_FILTER = 'SET_STORES_FILTER',
  QUERY_STORES_BY_FILTER = 'QUERY_STORES_BY_FILTER',
  QUERY_STORES_TOTAL_BY_FILTER = 'QUERY_STORES_TOTAL_BY_FILTER',
  QUERY_STORE_SLUG_BY_RANDOM_CODE = 'QUERY_STORE_SLUG_BY_RANDOM_CODE',
  SET_STORE_SLUG_BY_RANDOM_CODE = 'SET_STORE_SLUG_BY_RANDOM_CODE',

  /** User */
  SIGN_IN = 'SIGN_IN',
  SET_USER_INFO = 'SET_USER_INFO',
  QUERY_CUSTOMER = 'QUERY_CUSTOMER',
  SET_CUSTOMER = 'SET_CUSTOMER',
  PUSH_NOTIFICATION = 'PUSH_NOTIFICATION',
  SIGN_UP_FOR_GUEST = 'SIGN_UP_FOR_GUEST',
  SET_IS_GUEST = 'SET_IS_GUEST',
  CLEAR_IS_GUEST = 'CLEAR_IS_GUEST',
  CLEAR_USER_INFO = 'CLEAR_USER_INFO',
  CLEAR_CUSTOMER = 'CLEAR_CUSTOMER',
  UPDATE_CUSTOMER = 'UPDATE_CUSTOMER',
  QUERY_ADDRESS = 'QUERY_ADDRESS',
  SET_ADDRESS = 'SET_ADDRESS',
  PUSH_ADDRESS = 'PUSH_ADDRESS',
  ADD_ADDRESS = 'ADD_ADDRESS',
  DELETE_ADDRESS = 'DELETE_ADDRESS',
  REMOVE_ADDRESS = 'REMOVE_ADDRESS',
  MODIFY_ADDRESS = 'MODIFY_ADDRESS',
  EDIT_ADDRESS = 'EDIT_ADDRESS',
  QUERY_LOYALTY_STAMP = 'LOYALTY_STAMP',
  SET_LOYALTY_STAMP = 'SET_LOYALTY_STAMP',
  QUERY_CUSTOMER_GROWTH = 'QUERY_CUSTOMER_GROWTH',
  SET_CUSTOMER_GROWTH = 'SET_CUSTOMER_GROWTH',
  CLEAR_CUSTOMER_GROWTH = 'CLEAR_CUSTOMER_GROWTH',
  QUERY_CUSTOMER_PROMOTION = 'QUERY_CUSTOMER_PROMOTION',
  SET_CUSTOMER_PROMOTION = 'SET_CUSTOMER_PROMOTION',
  CLEAR_CUSTOMER_PROMOTION = 'CLEAR_CUSTOMER_PROMOTION',
  START_QUERY_CUSTOMER_GROWTH_TASK_SYNC = 'START_QUERY_CUSTOMER_GROWTH_TASK_SYNC',
  CANCEL_QUERY_CUSTOMER_GROWTH_TASK_SYNC = 'CANCEL_QUERY_CUSTOMER_GROWTH_TASK_SYNC',
  QUERY_ALL_PROMOTION = 'QUERY_ALL_PROMOTION',
  SET_ALL_PROMOTION = 'SET_ALL_PROMOTION',
  REDEEM_PROMOTION = 'REDEEM_PROMOTION',
  CLEAR_ALL_PROMOTION = 'CLEAR_ALL_PROMOTION',

  /** Payment */
  PUSH_PAY_CARD = 'PUSH_PAY_CARD',
  SET_PAY_CARDS = 'SET_PAY_CARDS',
  QUERY_PAY_CARDS = 'QUERY_PAY_CARDS',
  QUERY_PAY_CARD = 'QUERY_PAY_CARD',
  EDIT_PAY_CARD = 'EDIT_PAY_CARD',
  DELETE_PAY_CARD = 'DELETE_PAY_CARD',
  MODIFY_PAY_CARD = 'MODIFY_PAY_CARD',
  REMOVE_PAY_CARD = 'REMOVE_PAY_CARD',
  QUERY_GIFT_CARDS = 'QUERY_GIFT_CARDS',
  PUSH_ANONYMOUS_GIFT_CARDS = 'PUSH_ANONYMOUS_GIFT_CARDS',
  SET_STORE_GIFT_CARDS = 'SET_STORE_GIFT_CARDS',
  QUERY_GIFT_CARD_BALANCE = 'QUERY_GIFT_CARD_BALANCE',
  SET_FROM_URL_PAY_DATA = 'SET_FROM_URL_PAY_DATA',
  PARSE_FROM_URL_PAY_DATA = 'PARSE_FROM_URL_PAY_DATA',
  MODIFY_PAY_METHODS = 'MODIFY_PAY_METHODS',
  GOOGLE_PAY_MERCHANT_INFO =  'GOOGLE_PAY_MERCHANT_INFO',

  /** Order */
  QUERY_ORDER_INFO = 'QUERY_ORDER_INFO',
  QUERY_ORDERS = 'QUERY_ORDERS',
  SET_ORDER = 'SET_ORDER',
  CLEAR_ORDER_INFOS = 'CLEAR_ORDER_INFOS',
  BATCH_SET_ORDERS = 'BATCH_SET_ORDERS',
  PUSH_ORDER_INFOS = 'PUSH_ORDER_INFOS',
  CREATE_ORDER = 'CREATE_ORDER',
  SIGN_IN_AND_CREATE_ORDER = 'SIGN_IN_AND_CREATE_ORDER',
  GUEST_SIGN_UP_AND_CREATE_ORDER = 'GUEST_SIGN_UP_AND_CREATE_ORDER',
  CREATE_EMPTY_ORDER = 'CREATE_EMPTY_ORDER',
  QUERY_STORE_TAX_OPTIONS = 'QUERY_STORE_TAX_OPTIONS',
  SET_STORE_TAX_OPTIONS = 'SET_STORE_TAX_OPTIONS',
  QUERY_STORE_ROUNDING_OPTIONS = 'QUERY_STORE_ROUNDING_OPTIONS',
  SET_STORE_ROUNDING_OPTIONS = 'SET_STORE_ROUNDING_OPTIONS',
  SET_ALL_STORE_ROUNDING_AND_TAX_OPTIONS = 'SET_ALL_STORE_ROUNDING_AND_TAX_OPTIONS',

  /** Shopping Cart */
  ADD_TO_SHOPPING_CART_BY_ORDER = 'ADD_TO_SHOPPING_CART_BY_ORDER',
  PUSH_LISTING_TO_SHOPPING_CART = 'PUSH_LISTING_TO_SHOPPING_CART',
  MODIFY_LINE_ITEM_QUANTITY = 'MODIFY_LINE_ITEM_QUANTITY',
  SET_STORE_SHOPPING_CART = 'SET_STORE_SHOPPING_CART',
  ADD_TO_SHOPPING_CART = 'ADD_TO_SHOPPING_CART',
  CHANGE_SHIPPING_METHOD = 'CHANGE_SHIPPING_METHOD',
  GET_STORE_SHOPPING_CART_DATA = 'GET_STORE_SHOPPING_CART_DATA',
  CLEAR_STORES_SHOPPING_CART = 'CLEAR_STORES_SHOPPING_CART',
  CLEAR_PRE_ADD_MODIFIERS = 'CLEAR_PRE_ADD_MODIFIERS',
  GET_PRE_ADD_MODIFIERS_COUNT = 'GET_PRE_ADD_MODIFIERS_COUNT',
  SET_PRE_ADD_MODIFIERS = 'SET_PRE_ADD_MODIFIERS',
  CLEAR_SHOPPING_CART = 'CLEAR_SHOPPING_CART',
  SET_EXPRESS_ORDER_DATA = 'SET_EXPRESS_ORDER_DATA',
  SET_STORE_SHOPPING_CART_INFO = 'SET_STORE_SHOPPING_CART_INFO',
  SET_SHOPPING_CART = 'SET_SHOPPING_CART',
  SET_SHOPPING_CART_STOP_RENDER = 'SET_SHOPPING_CART_STOP_RENDER',
  GET_EXPRESS_ORDER_DATA = 'GET_EXPRESS_ORDER_DATA',
  SET_EXPRESS_ORDER_FOR_ZERO = 'SET_EXPRESS_ORDER_FOR_ZERO',
  SET_DISCOUNT_VALUE = 'SET_DISCOUNT_VALUE',
  UPDATE_MODIFIERS = 'UPDATE_MODIFIERS',

  /** App */
  QUERY_APP_CONFIG = 'QUERY_APP_CONFIG',
  SET_APP_CONFIG = 'SET_APP_CONFIG',
  SET_NEW_APP_CONFIG = 'SET_NEW_APP_CONFIG',
  LOAD_HOME_DATA = 'LOAD_HOME_DATA',
  SET_HOME_TYPE = 'SET_HOME_TYPE',
  SET_DOMAIN = 'SET_DOMAIN',
  SET_SHOW_SPLASH = 'SET_SHOW_SPLASH',
  LOADING = 'LOADING',
  PAGE_LOADING = 'PAGE_LOADING',
  SET_SCANNABLE_QRCODE = 'SET_SCANNABLE_QRCODE',
  CLEAR_APP_CONFIG = 'CLEAR_APP_CONFIG',
  INIT_COORDINATES = 'INIT_COORDINATES',
  SET_COORDINATES = 'SET_COORDINATES',
  SET_SELECTED_SERVICE_TYPE = 'SET_SELECTED_SERVICE_TYPE',
  SET_LOADING_PLATFORM_CONFIG = 'SET_LOADING_PLATFORM_CONFIG',
  SET_APP_CONFIG_DEVICEINFO = 'SET_APP_CONFIG_DEVICEINFO',

  /** Listing */
  QUERY_LISTINGS = 'QUERY_LISTINGS',
  QUERY_STORES_LISTINGS = 'QUERY_STORES_LISTINGS',
  SET_FAVORITE_TABS = 'SET_FAVORITE_TABS',
  SET_ALL_STORE_FAVORITE_TABS_AND_MODIFIERS = 'SET_ALL_STORE_FAVORITE_TABS_AND_MODIFIERS',
  QUERY_MODIFIERS = 'QUERY_MODIFIERS',
  SET_MODIFIERS = 'SET_MODIFIERS',
  SET_LANGUAGE = 'SET_LANGUAGE',
  ADD_PAY_CARD = 'ADD_PAY_CARD',
  QUERY_MODIFIER_SETS = 'QUERY_MODIFIER_SETS',
  PUSH_MODIFIER_SETS = 'PUSH_MODIFIER_SETS',
  QUERY_MODIFIER_LISTINGS = 'QUERY_MODIFIER_LISTINGS',
  SET_MODIFIER_LISTINGS = 'SET_MODIFIER_LISTINGS',
  SET_FAVORITE_LISTING = 'SET_FAVORITE_LISTING',
  ADD_FAVORITE_LISTING = 'ADD_FAVORITE_LISTING',
  REMOVE_FAVORITE_LISTING = 'REMOVE_FAVORITE_LISTING',
  QUERY_FAVORITE_LISTINGS = 'QUERY_FAVORITE_LISTINGS',
  SET_FAVORITE_LISTING_INFO = 'SET_FAVORITE_LISTING_INFO',
  PUT_FAVORITE_LISTING = 'PUT_FAVORITE_LISTING',
  CLEAR_FAVORITE_LISTING_INFO = 'CLEAR_FAVORITE_LISTING_INFO',

  /** Favorite */
  ADD_FAVORITE = 'ADD_FAVORITE',
  QUERY_FAVORITE_STATUS = 'QUERY_FAVORITE_STATUS',
  QUERY_FAVORITE_LIST = 'QUERY_FAVORITE_LIST',

  /** Feedback */
  QUERY_FEEDBACK_TAGS = 'QUERY_FEEDBACK_TAGS',
  QUERY_FEEDBACK_LIST = 'QUERY_FEEDBACK_LIST',
  ADD_FEEDBACK = 'ADD_FEEDBACK',

  /** Queueing **/
  CREATE_QUEUEING = 'CREATE_QUEUEING',
  QUERY_QUEUEINGS = 'QUERY_QUEUEINGS',
  QUERY_QUEUEING_SETTING = 'QUERY_QUEUEING_SETTING',
  SET_QUEUEING_SETTING = 'SET_QUEUEING_SETTING',
  SET_QUEUEINGS = 'SET_QUEUEINGS',
  CLEAR_QUEUEING = 'CLEAR_QUEUEING',

  /** Booking **/
  QUERY_BOOKINGS = 'QUERY_BOOKINGS',
  QUERY_BOOKINGS_FOR_DATE = 'QUERY_BOOKINGS_FOR_DATE',
  SET_BOOKINGS = 'SET_BOOKINGS',
  CLEAR_BOOKING = 'CLEAR_BOOKING',
  QUERY_BOOKING_SETTING = 'QUERY_BOOKING_SETTING',
  CREATE_BOOKING = 'CREATE_BOOKING',
  CANCELL_BOOKING = 'CANCELL_BOOKING',

  /** Point **/
  QUERY_POINTS = 'QUERY_POINTS',

  /** Qrcode Shopping */
  QUERY_QRCODE_SHOPPING_INVOICE = 'QUERY_QRCODE_SHOPPING_INVOICE',
  MODIFY_QRCODE_SHOPPING_INVOICE = 'MODIFY_QRCODE_SHOPPING_INVOICE',
  START_QRCODE_SHOPPING_INVOICE_TASK_SYNC = 'START_QRCODE_SHOPPING_INVOICE_TASK_SYNC',
  CANCEL_QRCODE_SHOPPING_INVOICE_TASK_SYNC = 'CANCEL_QRCODE_SHOPPING_INVOICE_TASK_SYNC',
  SET_QRCODE_SHOPPING_ORDER_INFOS = 'SET_QRCODE_SHOPPING_ORDER_INFOS',
  SET_QRCODE_SHOPPING_INVOICE_INFO = 'SET_QRCODE_SHOPPING_INVOICE_INFO',
  QUERY_REWARDS = 'QUERY_REWARDS',
  PAY_POINTS = 'PAY_POINTS',

  /** Supplier info */
  SET_SUPPLIER_INFO_STORES = 'SET_SUPPLIER_INFO_STORES',
  QUERY_USER_ASSOCIATE_STORES = 'QUERY_USER_ASSOCIATE_STORES',
  SET_SUPPLIERS_SELECT_KEY = 'SET_SUPPLIERS_SELECT_KEY',
  QUERY_DEFERRED_INVOICES = 'QUERY_DEFERRED_INVOICES',
  SET_DEFERRED_INVOICES = 'SET_DEFERRED_INVOICES',
  CREATE_DEFERRED_INVOICES = 'CREATE_DEFERRED_INVOICES',

  /** Coupons info */
  QUERY_COUPONS = 'QUERY_COUPONS',

  DELETE_DEVICE = 'DELETE_DEVICE',

  /** Purchase Order **/
  QUERY_PURCHASE_ORDERS = 'QUERY_PURCHASE_ORDERS',
  QUERY_PURCHASE_ORDER = 'QUERY_PURCHASE_ORDER',
  CANCEL_PURCHASE_ORDER = 'CANCEL_PURCHASE_ORDER',

  /** Good Received Note **/
  QUERY_GOOD_RECEIVED_NOTES = 'QUERY_GOOD_RECEIVED_NOTES',
  QUERY_GOOD_RECEIVED_NOTE = 'QUERY_GOOD_RECEIVED_NOTE',
  CANCEL_GOOD_RECEIVED_NOTE = 'CANCEL_GOOD_RECEIVED_NOTE',
  RECEIVE_GOOD_RECEIVED_NOTE = 'RECEIVE_GOOD_RECEIVED_NOTE',
  CREATE_GOOD_RECEIVED_NOTE = 'CREATE_GOOD_RECEIVED_NOTE',
  CREATE_AND_RECEIVE_GOOD_RECEIVED_NOTE = 'CREATE_AND_RECEIVE_GOOD_RECEIVED_NOTE',

  /** Return Mote ***/
  QUERY_RETURN_NOTES = 'QUERY_RETURN_NOTES',
  QUERY_RETURN_NOTE = 'QUERY_RETURN_NOTE',
  CANCEL_RETURN_NOTE = 'CANCEL_RETURN_NOTE',
  RECEIVE_RETURN_NOTE = 'RECEIVE_RETURN_NOTE',
  CREATE_RETURN_NOTE = 'CREATE_RETURN_NOTE',

  /** areaCodeData */
  AREACO_DEATA = 'AREACO_DEATA',
}

export default ActionType;
