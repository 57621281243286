import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from '@/components/App';
import store from '@/store';
import * as serviceWorker from './serviceWorker';
import OrderBuilder from './lib/order-builder';
import initReactFastclick from 'react-fastclick';
import '@/data/axios-config';
// import 'lib-flexible';
import './lib-flexible';
import './index.less';
import './antd-mobile.css';
// import 'node_modules/video-react/dist/video-react.css';
import 'video-react/dist/video-react.css';
import { checkStorageVersionAndAction } from './utils/app';
OrderBuilder.setChannel(4);

initReactFastclick();

checkStorageVersionAndAction();

ReactDOM.render(
  <Provider store={store()}>
    <BrowserRouter>
      <React.Suspense fallback={<div />}>
        <App />
      </React.Suspense>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

document.documentElement.addEventListener(
  'touchstart',
  function(event) {
    if (event.touches.length > 1) {
      event.preventDefault();
    }
  },
  false
);

let lastTouchEnd = 0;
document.documentElement.addEventListener(
  'touchend',
  function(event) {
    const now = Date.now();
    if (now - lastTouchEnd <= 100) {
      event.preventDefault();
    }
    lastTouchEnd = now;
  },
  false
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
