import React from 'react';
import Carousel from 'antd-mobile/es/carousel';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import { prefix } from '.';
import { getAppTheme, setScreenColor, getIntl } from '@/components/App/App';
import Layout from '@/components/Layout/Layout';
import BmTabBar from '@/components/BmTabBar';
import TabsOption from '@/actions/tabs-option';
import BrandsCategories from './BrandsCategories';
// import NewsList from '@/components/NewsList/NewsList';
import ActionType from '@/actions/action-type';
import { IBlog } from '@/actions/store-action';
import paths from '@/routes/paths';
import Icon, { IconType } from '@/components/Icon';
import { IObject } from '@/components/bm-common';
import Config from '@/Config';
import NewsList from '@/components/NewsList';

import SideBar from '@/components/SideBar';
import Skeleton from './Skeleton';
import { getAccessToken } from '@/utils/app';
export interface IBrandsPageProps extends IBasePageProps {
  isHomePage?: boolean;
}

export interface IBrandsPageState {
  list: IBlog[] | undefined;
  showSideBar: boolean;
  titleFixed: boolean;
  selectBlogKey: string;
}

class BrandsPage extends BasePage<IBrandsPageProps, IBrandsPageState> {
  constructor(props: IBrandsPageProps) {
    super(props);

    this.state = {
      list: undefined,
      showSideBar: false,
      titleFixed: false,
      selectBlogKey: 'PROMOTIONS',
    };
  }

  componentDidMount() {
    this.autoCloseLoading = false;
    const appTheme = getAppTheme();
    if (appTheme.darkBackgroundColor) {
      setScreenColor(appTheme.darkBackgroundColor);
    }

    super.componentDidMount();
    this.getBlogList('PROMOTIONS');

    const pageWrap = window.document.querySelector('.page-content-wrapper-web');
    if (pageWrap) {
      pageWrap.addEventListener('scroll', this.handleScroll, { passive: true });
    } else {
      window.addEventListener('scroll', this.handleScroll, { passive: true });
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    const pageWrap = window.document.querySelector('.page-content-wrapper-web');
    if (pageWrap) {
      pageWrap.removeEventListener('scroll', this.handleScroll);
    } else {
      window.removeEventListener('scroll', this.handleScroll);
    }
  }

  getBlogList = (type: string) => {
    this.closeLoading();
    const { dispatch, reducersState } = this.props;
    const { platformAppSettings } = reducersState.app;
    if (platformAppSettings && platformAppSettings.storeSlug) {
      dispatch({
        type: ActionType.QUERY_BLOG_LIST,
        contentType: type,
        storeSlug: platformAppSettings.storeSlug,
        callback: list => {
          this.setState({
            list,
          });
        },
      });
    }
  };

  handleGoDetail = (data: IBlog) => {
    const { reducersState } = this.props;
    const { selectBlogKey } = this.state;
    const { platformAppSettings } = reducersState.app;
    if (platformAppSettings) {
      const { storeSlug } = platformAppSettings;
      this.handleGotoRoute({
        path: paths.BLOG_DETAIL,
        pathParams: {
          storeSlug,
          blogID: data.id,
          contentType: selectBlogKey === 'PROMOTIONS' ? 'promotions' : 'news',
        },
      });
    }
  };

  onOpenChange = () => {
    const { reducersState } = this.props;
    const { platformConfig } = reducersState.app;
    if (platformConfig.showSideBar) {
      this.setState({
        showSideBar: !this.state.showSideBar,
      });
    }
  };

  handleShowSideBar = () => {
    this.setState({
      showSideBar: !this.state.showSideBar,
    });
  };

  handleCategoryClick = (categoryID: string, fromPage: string) => {
    this.handleGotoRoute({
      path: paths.STORE_LIST_BY_CATEGORY,
      pathParams: {
        byType: 'all',
        categoryID: '0',
        brandID: categoryID,
        fromPage: fromPage,
      },
    });
  };

  getScrollY = () => {
    const pageWrap = window.document.querySelector('.page-content-wrapper-web');
    let scrollY = window.scrollY;
    if (pageWrap) {
      scrollY = pageWrap.scrollTop;
    }

    return scrollY;
  };

  handleScroll = () => {
    const appTheme = getAppTheme();
    if (appTheme.darkBackgroundColor && window.scrollY <= 0) {
      setScreenColor('#000', appTheme.darkBackgroundColor);
    }
    const { titleFixed } = this.state;
    const storeTitle = document.getElementById('order-type');
    if (storeTitle) {
      if (storeTitle.offsetTop / 2 < this.getScrollY()) {
        if (!titleFixed) {
          this.setState({ titleFixed: true });
        }
      } else if (titleFixed) {
        this.setState({ titleFixed: false });
      }
    }
  };

  handleGotoBands = (categoryID: string, byType: string) => {
    if (categoryID) {
      this.handleGotoRoute({
        path: paths.BRAND_LIST,
        pathParams: {
          byType,
          categoryID,
        },
      });
    } else {
      this.handleGotoRoute({
        path: paths.STORE_LIST_BY_CATEGORY,
        pathParams: {
          byType,
          categoryID: '0',
          brandID: '0',
          fromPage: 'brands',
        },
      });
    }
  };

  findAppTabsIcon = (key: string) => {
    const { reducersState } = this.props;
    const { appIcons } = reducersState.app;
    if (appIcons && appIcons.length > 0) {
      const appIcon = appIcons.find(item => item.iconType === key);
      return appIcon;
    }
  };

  render() {
    const { reducersState } = this.props;
    const {
      platformConfig,
      platformStoreBrands,
      platformStoreCategories, /* showSplash, splashData */
    } = reducersState.app;
    const {
      userInfo
    } = reducersState.user;
    const {
      supportStoreCategory,
      showSideBar,
      platformBanner,
      bannerCarouselTime,
      platformLogo,
      showQueueing,
      validServiceType = [],
      storeSlug
    } = platformConfig;
    const { list, titleFixed, selectBlogKey } = this.state;
    const layoutProps: IObject = {
      className: `${prefix}`,
      // className: `${prefix} ${!list? 'zIndex': ''} `,
    };
    // layoutProps.navContent = (
    //   platformConfig.platformLogo &&
    //   <img  className="nav-logo" src={`${platformConfig.platformLogo}`} width="210" alt=""/>
    // );
    layoutProps.navRightContent = (
      <div className="nav-right-content">
        {
          getAccessToken() && <div className="top-nav-membership">
            <div className="thumbnail-dp">
              <Icon
                type={IconType.DEFAULT_AVATAR_OUTLINED}
                className="avatar-icon"
              />
            </div>
            <div className="member-name">{userInfo?.name}</div>
          </div>
        }
        <Icon className="cart-block" type={IconType.SHOPPING_CART} onClick={() => {
          this.handleGotoRoute({
            path: paths.SHOPPING_CART_BY_FROM,
            pathParams: {
              storeSlug,
              fromPage: 'brands',
            },
          });
        }}/>
      </div>
    );
    if (showSideBar) {
      layoutProps.navIcon = (
        <Icon className="side-bar-icon" type={IconType.MENUS_OUTLINED} />
      );
      layoutProps.onLeftClick = () => {
        this.onOpenChange();
      };
    }

    if (titleFixed) {
      layoutProps.className = `${prefix} menu-nav-fixed`;
    }
    const foodOrderIcon = this.findAppTabsIcon('FOOD_ORDERING_OUTLINED');
    const bookingIcon = this.findAppTabsIcon('BOOKING_SETTING_BRAND');
    const queueingIcon = this.findAppTabsIcon('QUEUEING_SETTING_BRAND');

    let categoryID = '';
    if (
      supportStoreCategory &&
      platformStoreCategories &&
      platformStoreCategories.length > 0
    ) {
      categoryID = platformStoreCategories[0].id;
    }

    // const isShowSplash = showSplash && splashData.length > 0;
    return (
      <>
        <Layout {...this.props} {...layoutProps}>
          {!list && <Skeleton></Skeleton>}
          <div className="box-brands-img">
            {platformBanner.length > 1 && (
              <div className="brands-img">
                <Carousel
                  className="brands-carousel"
                  dots={false}
                  infinite={true}
                  autoplay={bannerCarouselTime !== 0}
                  autoplayInterval={bannerCarouselTime * 1000}
                >
                  {platformBanner.map((item, index) => {
                    // return (<img className="carousel-item" key={index} src={item} alt="" />);
                    return (
                      <div
                        className="carousel-item"
                        key={item}
                        style={{ backgroundImage: `url(${item})` }}
                      ></div>
                    );
                  })}
                </Carousel>
              </div>
            )}
            {platformBanner.length === 0 && (
              <div
                className="brands-img"
                style={{
                  backgroundImage: `url(${
                    platformLogo
                      ? platformLogo
                      : `${Config.cdnUrl}/images/default-store.png`
                  })`,
                }}
              />
            )}
            {platformBanner.length === 1 && (
              <div
                className="brands-img"
                style={{
                  backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.63), hsla(0, 0%, 100%, 0)), url(${platformBanner[0]})`,
                }}
              ></div>
            )}
          </div>

          <div className="store-order-type" id="order-type">
            {validServiceType.includes(
              'food_ordering' || 'booking' || 'queueing'
            ) && (
              <div className="order-category-wrap">
                <div className="order-category-wrap-container w-container">
                  {validServiceType.includes('food_ordering') && (
                    <div
                      className="food-order"
                      onClick={() =>
                        this.handleGotoBands(categoryID, 'food_ordering')
                      }
                    >
                      {foodOrderIcon && foodOrderIcon.svg && (
                        <div
                          className="bm-icon"
                          dangerouslySetInnerHTML={{
                            __html: foodOrderIcon.svg || '',
                          }}
                        />
                      )}
                      {!foodOrderIcon && (
                        <Icon type={IconType.FOOD_ORDERING_OUTLINED} />
                      )}
                      <div className="second-title">
                        {getIntl().page.foodOrdering}
                      </div>
                    </div>
                  )}
                  {validServiceType.includes('booking') && (
                    <div
                      className="food-order booking"
                      onClick={() =>
                        this.handleGotoBands(categoryID, 'booking')
                      }
                    >
                      {bookingIcon && bookingIcon.svg && (
                        <div
                          className="bm-icon"
                          dangerouslySetInnerHTML={{
                            __html: bookingIcon.svg || '',
                          }}
                        />
                      )}
                      {!bookingIcon && (
                        <Icon type={IconType.BOOKING_SETTING_BRAND} />
                      )}
                      <div className="second-title">
                        {getIntl().page.booking}
                      </div>
                    </div>
                  )}
                  {validServiceType.includes('queueing') && (
                    <div
                      className="food-order"
                      onClick={() => {
                        if (showQueueing) {
                          this.handleGotoBands(categoryID, 'queueing');
                        } else {
                          this.handleGotoRoute({
                            path: paths.STORES_BY_BOOKING,
                            pathParams: {
                              serviceType: 2,
                            },
                          });
                        }
                      }}
                    >
                      {queueingIcon && queueingIcon.svg && (
                        <div
                          className="bm-icon"
                          dangerouslySetInnerHTML={{
                            __html: queueingIcon.svg || '',
                          }}
                        />
                      )}
                      {!queueingIcon && (
                        <Icon type={IconType.QUEUEING_SETTING_BRAND} />
                      )}
                      <div className="second-title">
                        {getIntl().page.queueing}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          {supportStoreCategory &&
            platformStoreBrands &&
            platformStoreBrands.length > 0 && (
            <>
              <div className="brands-title w-container">
                <div className="title">
                  {getIntl().page.brands}
                </div>
                <div
                  className="see-all"
                  onClick={() => {
                    this.handleGotoRoute({
                      path: paths.STORE_LIST_BY_CATEGORY,
                      pathParams: {
                        byType: 'all',
                        categoryID: '0',
                        brandID: '0',
                        fromPage: 'brands',
                      },
                    });
                  }}
                >
                  {getIntl().page.seeAll}
                  <Icon
                    className="viewmore-icon"
                    type={IconType.LEFT_ARROW_OUTLINED}
                  />
                </div>
              </div>
              <BrandsCategories
                data={platformStoreBrands}
                reducersState={reducersState}
                onItemClick={this.handleCategoryClick}
              />
            </>
          )}
          <div className="brands-title w-container">
            <div className="title">
              <div
                className={`tab title-item${
                  selectBlogKey === 'PROMOTIONS' ? ' select' : ''
                }`}
                onClick={() => {
                  this.setState({ selectBlogKey: 'PROMOTIONS' });
                  if (selectBlogKey !== 'PROMOTIONS')
                    this.getBlogList('PROMOTIONS');
                }}
              >
                {getIntl().page.promotionsTitle}
              </div>
              <div
                className={`tab title-item${
                  selectBlogKey === 'BLOG' ? ' select' : ''
                }`}
                onClick={() => {
                  this.setState({ selectBlogKey: 'BLOG' });
                  if (selectBlogKey !== 'BLOG') this.getBlogList('BLOG');
                }}
              >
                {getIntl().page.newsTitle}
              </div>
            </div>
            <div
              className="see-all"
              onClick={() => this.handleGotoRoute({ path: paths.NEWS })}
            >
              {getIntl().page.seeAll}
              <Icon
                className="viewmore-icon"
                type={IconType.LEFT_ARROW_OUTLINED}
              />
            </div>
          </div>
          {list && list.length > 0 && (
            <div className="blog-wrap">
              <div className="w-container">
                <NewsList
                  data={list}
                  onItemClick={this.handleGoDetail}
                  reducersState={reducersState}
                />
              </div>
            </div>
          )}
          {list && list.length <= 0 && (
            <div className="no-content-panel">
              <Icon type={IconType.TAG_ITEM} />
              <div className="no-content-info">
                {getIntl().page.noDataTips}
              </div>
            </div>
          )}
        </Layout>
        {platformConfig.showTabs && list && (
          <BmTabBar
            selectedKey={TabsOption.BRANDS}
            reducersState={reducersState}
          />
        )}
        {showSideBar && (
          <SideBar
            showSideBar={this.state.showSideBar}
            onItemClick={this.handleShowSideBar}
            reducersState={reducersState}
          />
        )}
      </>
    );
  }
}

export default BrandsPage;
