import React from 'react';
import dayjs from 'dayjs';
import ChooseDateItem from './ChooseDateItem';
import {
  IWeekBooking,
} from '@/actions/booking-action';

interface IDateProps {
  day: number;
  select: string;
  onClick: (date: string, week: string) => void;
  weekBooking: IWeekBooking;
  language: string;
  minimumStartBookingTime: string;
  minimumStartBookingTimeType: string;
  isCurrentDay: boolean;
}

const Date: React.FC<IDateProps> = props => {
  const {
    day,
    select,
    onClick,
    weekBooking,
    minimumStartBookingTime,
    minimumStartBookingTimeType,
    language,
    isCurrentDay,
  } = props;
  let nowMonth = dayjs().format('MMM');
  let nextMonth = '';
  let showMonth = false;
  let date = dayjs().add(0, 'day');
  if (minimumStartBookingTimeType === '1') {
    date = dayjs().add(Number(minimumStartBookingTime), 'minute');
  } 
  if (minimumStartBookingTimeType === '2') {
    date = dayjs().add(Number(minimumStartBookingTime), 'hour');
  }
  const NextDay = date.format('d');
  const currentDay =  dayjs().format('d');
  return (
    <div className="div-block-103">
      <div className="form-block-2 w-form">
        <form action="" className="form-2">
          <div className="div-block-102">
            {
              Array.from(Array(day), (_item, index) => {
                const date = dayjs().add(index, 'day');
                const week = date.format('d');
                if (
                  (weekBooking[week] && minimumStartBookingTimeType !== '3' && !isCurrentDay && index > 0) // 第二天
                  || (weekBooking[week] && minimumStartBookingTimeType !== '3' && isCurrentDay &&  NextDay === currentDay && index >= 0) // 当天
                  || (weekBooking[week] && minimumStartBookingTimeType !== '3' && (NextDay !== currentDay && index > 0)) // 第二天
                  || (weekBooking[week] && minimumStartBookingTimeType === '3' && Number(minimumStartBookingTime) <= index)
                ) {
                  nextMonth = date.format('MMM');
                  if (nowMonth !== nextMonth) {
                    nowMonth = nextMonth;
                    showMonth = true;
                  } else if ((weekBooking[week] && minimumStartBookingTimeType === '3' && Number(minimumStartBookingTime) === index) || (weekBooking[week] && minimumStartBookingTimeType !== '3' && !isCurrentDay && index === 1)) {
                    showMonth = true;
                  } else {
                    showMonth = false;
                  }
                  if (Math.ceil(dayjs(select).diff(dayjs(), 'hour')/24) === index) {
                    return <ChooseDateItem key={`date-${index}`} className={'select-date'} day={index} onClick={onClick} language={language} showMonth={showMonth} />
                  } else {
                    return <ChooseDateItem key={`date-${index}`} className={'unselect-date'} day={index} onClick={onClick} language={language} showMonth={showMonth} />
                  }
                } else {
                  return <div key={`date-${index}`}></div>
                }
              })
            }
          </div>
        </form>
      </div>
    </div>
  );
}

export default Date;
