import React from 'react';
import { prefix } from '.';
const Skeleton: React.FC = () => (
  <div className={`${prefix}-skeleton`}>
    <div className="skeleton-navbar">
      <div className="skeleton-icon"></div>
      <div className="skeleton-title"></div>
      <div className="skeleton-code"></div>
    </div>
    <div className="skeleton-line-fix"></div>
    <div className="skeleton-member">
      <div className="member-info">
        <div className="member-info-info">
          <div className="member-info-img"></div>
          <div className="info">
            <div className="info-top"></div>
            <div className="info-bottom"></div>
          </div>
        </div>
        <div className="member-code"></div>
      </div>
      <div className="member-img"></div>
    </div>
    <div className="member-total-box">
      <div className="member-total"></div>
      <div className="member-total-bottom"></div>
    </div>
    <div className="skeleton-line"></div>
    <div className="skeleton-use">
      <div className="skeleton-use1">
        <div className="skeleton-use1-left">
          <div className="skeleton-use1-left1"></div>
          <div className="skeleton-use1-left2"></div>
          <div className="skeleton-use1-left3"></div>
        </div>
        <div className="skeleton-use1-right"></div>

      </div>
      <div className="skeleton-use2">
        <div className="skeleton-use2-box">
          <div className="skeleton-use2-box-cir"></div>
          <div className="skeleton-use2-box-span"></div>
        </div>
        <div className="skeleton-use2-span"></div>
        <div className="skeleton-use2-box">
          <div className="skeleton-use2-box-cir"></div>
          <div className="skeleton-use2-box-span"></div>
        </div>
        <div className="skeleton-use2-span"></div>
        <div className="skeleton-use2-box">
          <div className="skeleton-use2-box-cir"></div>
          <div className="skeleton-use2-box-span"></div>
        </div>
      </div>
      <div className="skeleton-use3"></div>
    </div>
    <div className="skeleton-line"></div>
    <div className="skeleton-wrap">
      <div className="skeleton-wrap-img"></div>
      <div className="skeleton-wrap-title"></div>
    </div>
    <div className="skeleton-line"></div>
    <div className="skeleton-wrap">
      <div className="skeleton-wrap-img"></div>
      <div className="skeleton-wrap-title"></div>
    </div>
    <div className="skeleton-line"></div>
    <div className="skeleton-wrap">
      <div className="skeleton-wrap-img"></div>
      <div className="skeleton-wrap-title"></div>
    </div>
    <div className="skeleton-line"></div>
    <div className="skeleton-wrap">
      <div className="skeleton-wrap-img"></div>
      <div className="skeleton-wrap-title"></div>
    </div>
    <div className="skeleton-line"></div>
    <div className="skeleton-wrap">
      <div className="skeleton-wrap-img"></div>
      <div className="skeleton-wrap-title"></div>
    </div>
    <div className="skeleton-line"></div>
    <div className="skeleton-wrap">
      <div className="skeleton-wrap-img"></div>
      <div className="skeleton-wrap-title"></div>
    </div>
    <div className="skeleton-line"></div>
    <div className="skeleton-wrap">
      <div className="skeleton-wrap-img"></div>
      <div className="skeleton-wrap-title"></div>
    </div>
    <div className="skeleton-line"></div>
    <div className="skeleton-wrap">
      <div className="skeleton-wrap-img"></div>
      <div className="skeleton-wrap-title"></div>
    </div>
    <div className="skeleton-footer">
      <div className="skeleton-icon">
        <div className="icon-item"></div>
        <div className="icon-item icon-item-title"></div>
      </div>
      <div className="skeleton-icon">
        <div className="icon-item"></div>
        <div className="icon-item icon-item-title"></div>
      </div>
      <div className="skeleton-icon">
        <div className="icon-item"></div>
        <div className="icon-item icon-item-title"></div>
      </div>
      <div className="skeleton-icon">
        <div className="icon-item"></div>
        <div className="icon-item icon-item-title"></div>
      </div>
      <div className="skeleton-icon">
        <div className="icon-item"></div>
        <div className="icon-item icon-item-title"></div>
      </div>
    </div>
    <div className="skeleton-footer-line"></div>
  </div>
);

export default Skeleton;
