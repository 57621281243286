import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import usePageProps from '@/routes/usePageProps';
import MyStoresPage from '@/pages/MyStoresPage';

const MyStores: React.FC<RouteComponentProps> = props => {
  const pageProps = usePageProps({ props });

  if (pageProps.reducersState.app.loadingPlatformConfig) {
    return null;
  }

  return <MyStoresPage {...pageProps} />
};

export default MyStores;
