import { takeEvery, put, select, call } from 'redux-saga/effects';
import axios from 'axios';
import md5 from 'blueimp-md5';
import Config, { isStaging } from '@/Config';
import Action from '@/actions';
import ActionType from '@/actions/action-type';
import { IReducersState } from '@/reducers';
import { IUserInfo } from '@/actions/user-action';
import { IPayState } from '@/reducers/pay-reducer';
import PayTypes, { creditCardTypeRefPayTypes } from '@/lib/payment/PayTypes';
import {
  getLocalAnonymousGiftCards,
  pushAnonymousGiftCardToLocal,
  parseGiftCardRes,
} from '@/utils/pay';
import {
  IAddPayCard,
  IPayCard,
  IQueryPayCard,
  IQueryPayCards,
  IEditPayCard,
  IDeletePayCard,
  IQueryGiftCards,
  IGiftCard,
  IQueryGiftCardBalance,
  IParseFromUrlPayData,
} from '@/actions/pay-action';
import {
  handleSignUpForGuest,
  handleSignIn,
} from './user-saga';
import { IPayMethod } from '@/lib/payment';
import { createUuid } from '@/lib/order-builder/utils';

function* handleAddPayCard(params: IAddPayCard) {
  const { phoneNumber, dialCode, data, callback } = params;
  let payCard: IPayCard | undefined;
  const state: IReducersState = yield select((state: IReducersState) => state);
  let userInfo: IUserInfo | undefined = undefined;

  try {
    if (!state.user.userInfo && phoneNumber && dialCode) {
      const signUpResult = yield handleSignUpForGuest({
        type: ActionType.SIGN_UP_FOR_GUEST,
        phoneNumber,
        dialCode,
      });

      if (signUpResult && signUpResult.success) {
        userInfo = yield handleSignIn({
          type: ActionType.SIGN_IN,
          username: signUpResult.userEmail,
          password: signUpResult.password,
          loginMethod: 'guest',
          grantType: signUpResult.password,
        });
      }

      if (userInfo) {
        userInfo.avatarUrl = (userInfo.avatarUrl).replace(/\s+/g, '%20');
        yield put<Action>({
          type: ActionType.SET_IS_GUEST,
          isGuest: true,
        });


        yield put<Action>({
          type: ActionType.SET_USER_INFO,
          userInfo,
        });
      }
    }

    const res = yield call(
      axios.post,
      Config.urls.creditCards,
      {
        cvv: data.cvv,
        'exp_month': data.expMonth,
        'exp_year': data.expYear,
        holder: '--',
        number: data.number,
        'store_display_enable': data.displayEnable,
      },
    );

    if (res && res.data && res.data.credit_card && res.data.credit_card.id) {
      const item: any = res.data.credit_card;
      if (creditCardTypeRefPayTypes[item.type]) {
        payCard = {
          cvvVerified: item.cvv_verified || false,
          default: item.default || false,
          expMonth: item.exp_month || '',
          expYear: item.exp_year || '',
          holder: item.holder || '',
          id: item.id,
          last4Digits: item.last_4_digits,
          token: item.token,
          type: creditCardTypeRefPayTypes[item.type],
        };
      }
    }

    if (payCard) {
      yield put<Action>({
        type: ActionType.PUSH_PAY_CARD,
        payCard,
      });
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(payCard);
  }
}

export function* handleQueryPayCards(params: IQueryPayCards) {
  const { callback } = params;
  let payCards: IPayCard[] = [];
  try {
    const payState: IPayState = yield select((state: IReducersState) => state.pay);

    if (payState.payCards) {
      payCards = payState.payCards;
    } else {
      const res = yield call(
        axios.get,
        Config.urls.creditCards,
      );

      if (res && res.data && Array.isArray(res.data.credit_cards)) {

        res.data.credit_cards.forEach((item: any) => {
          if (creditCardTypeRefPayTypes[item.type]) {
            payCards.push({
              cvvVerified: item.cvv_verified || false,
              default: item.default || false,
              expMonth: item.exp_month || '',
              expYear: item.exp_year || '',
              holder: item.holder || '',
              id: `${item.id}`,
              last4Digits: item.last_4_digits,
              token: item.token,
              type: creditCardTypeRefPayTypes[item.type],
            });
          }
        })
        yield put<Action>({
          type: ActionType.SET_PAY_CARDS,
          payCards,
        });
      }
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(payCards);
  }

  return payCards;
}

function* handleQueryPayCard(params: IQueryPayCard) {
  const { cardID, callback } = params;
  let payCard: IPayCard | undefined = undefined;

  try {
    const payCards: IPayCard[] = yield handleQueryPayCards({
      type: ActionType.QUERY_PAY_CARDS,
    });

    if (Array.isArray(payCards)) {
      for (let i = 0; i < payCards.length; i++) {
        const item = payCards[i];
        if (item && Number(item.id) === Number(cardID)) {
          payCard = item;
          break;
        }
      }
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(payCard);
  }

  return payCard;
}

function* handleEditPayCard(params: IEditPayCard) {
  const { cardID, data, callback } = params;
  let payCard: IPayCard | undefined;

  try {
    if (cardID) {
      const res = yield call(
        axios.put,
        `${Config.urls.creditCards}/${cardID}`,
        {
          'exp_month': data.expMonth,
          'exp_year': data.expYear,
          holder: '--',
        },
      );

      if (res && res.data && res.data.credit_card && res.data.credit_card.id) {
        const item: any = res.data.credit_card;
        if (creditCardTypeRefPayTypes[item.type]) {
          payCard = {
            cvvVerified: item.cvv_verified || false,
            default: item.default || false,
            expMonth: item.exp_month || '',
            expYear: item.exp_year || '',
            holder: item.holder || '',
            id: item.id,
            last4Digits: item.last_4_digits,
            token: item.token,
            type: creditCardTypeRefPayTypes[item.type],
          };
        }
      }

      if (payCard) {
        yield put<Action>({
          type: ActionType.MODIFY_PAY_CARD,
          payCard,
        });
      }
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(payCard);
  }
}

function* handleDeletePayCard(params: IDeletePayCard) {
  const { cardID, callback } = params;
  let payCard: IPayCard | undefined;

  try {
    if (cardID) {
      yield call(
        axios.delete,
        `${Config.urls.creditCards}/${cardID}`,
      );
      yield put<Action>({
        type: ActionType.REMOVE_PAY_CARD,
        cardID: cardID,
      });
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(payCard);
  }
}

export function* handleQueryGiftCards(params: IQueryGiftCards) {
  const { storeSlug, storeID, customerID, callback } = params;
  let giftCards: IGiftCard[] = [];
  try {
    const key = `${storeSlug}_${customerID}`;

    const payState: IPayState = yield select((state: IReducersState) => state.pay);

    if (payState.keyRefGiftCards[key]) {
      giftCards = payState.keyRefGiftCards[key];
    } else {
      const res = yield call(
        axios.get,
        Config.urls.getGiftCards.replace('{storeSlug}', storeSlug).replace('{customerID}', customerID),
      );

      if (res && res.gift_card_id) {
        giftCards.push({
          id: res.gift_card_id,
          balance: res.balance,
          number: res.card_number || '',
          expiredAt: res.expired_at || '',
          isGiftCard: true,
          pin: '',
          type: PayTypes.STORE_CREDIT,
        })
      }

      const anonymousGiftCards: IGiftCard[] = [];
      const localAnonymousGiftCards = getLocalAnonymousGiftCards();
      for (let i = 0; i < localAnonymousGiftCards.length; i++) {
        const localAnonymousGiftCard = localAnonymousGiftCards[i];
        const number = localAnonymousGiftCard.number;
        const pin = localAnonymousGiftCard.pin;
        const pinMd5 = md5(pin);
        const res = yield call(
          axios.get,
          Config.urls.getGiftCardBalance.replace('{number}', number).replace('{pin}', pinMd5).replace('{storeID}', storeID),
        );

        const anonymousGiftCard = parseGiftCardRes(res, number, pin);
        if (anonymousGiftCard && anonymousGiftCard.isGiftCard) {
          pushAnonymousGiftCardToLocal({
            number: anonymousGiftCard.number,
            pin: anonymousGiftCard.pin,
          })
          anonymousGiftCards.push(anonymousGiftCard)
        }
      }


      yield put<Action>({
        type: ActionType.PUSH_ANONYMOUS_GIFT_CARDS,
        giftCards: anonymousGiftCards,
      });

      yield put<Action>({
        type: ActionType.SET_STORE_GIFT_CARDS,
        key,
        giftCards,
      });
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(giftCards);
  }
  return giftCards;
}

function* handleQueryGiftCardBalance(params: IQueryGiftCardBalance) {
  const { storeID, number, pin, callback } = params;
  let anonymousGiftCards: IGiftCard | undefined;
  try {
    const pinMd5 = md5(pin);
    const res = yield call(
      axios.get,
      Config.urls.getGiftCardBalance.replace('{number}', number).replace('{pin}', pinMd5).replace('{storeID}', storeID),
    );

    anonymousGiftCards = parseGiftCardRes(res, number, pin);

    if (anonymousGiftCards && anonymousGiftCards.isGiftCard) {
      pushAnonymousGiftCardToLocal({
        number: anonymousGiftCards.number,
        pin: anonymousGiftCards.pin,
      })
      yield put<Action>({
        type: ActionType.PUSH_ANONYMOUS_GIFT_CARDS,
        giftCards: [anonymousGiftCards],
      });
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(anonymousGiftCards);
  }
}

function* handleParseFromUrlPayData(params: IParseFromUrlPayData) {
  const { fromUrlPayData, currencyCode, gatewayAppSettings, platformAppSettings } = params;
  const state: IReducersState = yield select((state: IReducersState) => state);
  const { deviceInfo } = state.app;

  const payMethods: IPayMethod[] = [];
  try {
    let payCards: IPayCard[] | undefined = undefined;
    for (let i = 0; i < fromUrlPayData.length; i++) {
      const item = fromUrlPayData[i];
      if (!item) continue;

      if (item.paymentMethod === 19) {
        const type = item.type === PayTypes.ALIPAY ? PayTypes.ALIPAY : PayTypes.ALIPAY_HK;
        payMethods.push({
          id: type,
          currencyCode,
          type,
          returnUrl: '',
          amount: item.amount,
        })
      } else if (item.paymentMethod === 21) {
        payMethods.push({
          id: PayTypes.TAP_GO,
          currencyCode,
          type: PayTypes.TAP_GO,
          returnUrl: '',
          amount: item.amount,
        })
      } else if (item.paymentMethod === 23) {
        payMethods.push({
          id: PayTypes.GOOGLE_PAY,
          type: PayTypes.GOOGLE_PAY,
          amount: item.amount,
          currencyCode: currencyCode,
          googlePayData: '',
        })
      } else if (item.paymentMethod === 17) {
        payMethods.push({
          id: PayTypes.WECHAT_PAY,
          currencyCode,
          type: PayTypes.WECHAT_PAY,
          returnUrl: '',
          amount: item.amount,
        })
      } else if (item.paymentMethod === 18) {
        payMethods.push({
          id: PayTypes.APPLE_PAY,
          type: PayTypes.APPLE_PAY,
          countryCode: 'HK',
          currencyCode,
          supportedNetworks: gatewayAppSettings.applePayMethodKeys,
          appMerchantId: deviceInfo.isAppleApp && platformAppSettings.merchantID ? platformAppSettings.merchantID : gatewayAppSettings.merchantID,
          appMerchantDomain: gatewayAppSettings.domain,
          label: platformAppSettings.name,
          amount: item.amount,
        })
      } else if (item.paymentMethod === 16) {
        const number = `${item.number || ''}`;
        const last4Digits = number.substring(number.length - 4);
        payMethods.push({
          id: item.number,
          currencyCode,
          type: PayTypes.ANONYMOUS_GIFT_CARD,
          amount: item.amount,
          printedPin: item.printedPin || '',
          number: item.number,
          balance: item.balance,
          last4Digits,
        })
      } else if (item.paymentMethod === 4) {
        if (!Array.isArray(payCards) && !state.user.isGuest) {
          payCards = yield handleQueryPayCards({
            type: ActionType.QUERY_PAY_CARDS,
          });
        }

        if (Array.isArray(payCards)) {
          for (let j = 0; j < payCards.length; j++) {
            const payCard = payCards[j];
            if (String(payCard.id) === String(item.id)) {
              payMethods.push({
                id: item.number,
                currencyCode,
                type: (payCard.type as any) || PayTypes.CREDIT_CARD,
                amount: item.amount,
                last4Digits: payCard.last4Digits,
                token: payCard.token,
                expYear: payCard.expYear,
                expMonth: payCard.expMonth,
                cvv: '',
                holderName: payCard.holder,
              });
              break;
            }
          }
        } else if (state.user.isGuest) {
          const number = `${item.number || ''}`;
          const last4Digits = number.substring(number.length - 4);
          const card: IPayCard = {
            cvvVerified: false,
            default: false,
            expMonth: item.expMonth,
            expYear: item.expYear,
            holder: '',
            id: createUuid(),
            last4Digits: last4Digits,
            token: '',
            type: item.type,
            number: number,
            cvv: item.cvv,
          }
          payMethods.push({
            id: number,
            number,
            currencyCode,
            type: item.type || PayTypes.CREDIT_CARD,
            amount: item.amount,
            last4Digits: last4Digits,
            token: '',
            expMonth: item.expMonth,
            expYear: item.expYear,
            cvv: item.cvv,
            holderName: '',
          });
          yield put<Action>({
            type: ActionType.PUSH_PAY_CARD,
            payCard: card,
          });
        }
      }
    }

    if (payMethods.length > 0) {
      yield put<Action>({
        type: ActionType.MODIFY_PAY_METHODS,
        payMethods,
      });
    }
  } catch (error) {
    console.error(error);
  }
  return payMethods;
}

export function* handleQueryGooglePayMerchantInfo() {
  const id = isStaging ? '12345678901234567890' : 'BCR2DN4T4227JKIU';
  let tag = '';
  let value = '';
  try {
    const resp = yield call(
      axios.get,
      Config.urls.queryGooglePayMerchantInfo.replace('{id}', id)
    );
    if (resp && resp.id) {
      tag = resp.tag;
      value = resp.value;
    }
  } catch (error) {
    console.error(error);
  }
  return {
    tag,
    value,
  }
}

function* paySaga() {
  yield takeEvery(ActionType.ADD_PAY_CARD, handleAddPayCard);
  yield takeEvery(ActionType.QUERY_PAY_CARD, handleQueryPayCard);
  yield takeEvery(ActionType.QUERY_PAY_CARDS, handleQueryPayCards);
  yield takeEvery(ActionType.EDIT_PAY_CARD, handleEditPayCard);
  yield takeEvery(ActionType.DELETE_PAY_CARD, handleDeletePayCard);
  yield takeEvery(ActionType.QUERY_GIFT_CARDS, handleQueryGiftCards);
  yield takeEvery(ActionType.QUERY_GIFT_CARD_BALANCE, handleQueryGiftCardBalance);
  yield takeEvery(ActionType.PARSE_FROM_URL_PAY_DATA, handleParseFromUrlPayData);
}

export default paySaga();
