import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import SignUp from '@/components/SignUp';
import paths from '@/routes/paths';
import Icon, { IconType } from '@/components/Icon';
import { getAppTheme, setScreenColor, getIntl } from '@/components/App/App';
import Layout from '@/components/Layout/Layout';
import { prefix } from '.';

export interface ISignUpPageProps extends IBasePageProps {}

class SignUpPage extends BasePage<ISignUpPageProps> {
  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.primaryColor && appTheme.lightBackgroundColor) {
      setScreenColor(appTheme.primaryColor, appTheme.lightBackgroundColor);
    }

    super.componentDidMount();
  }

  signUpClick = () => {
    this.handleGoHome(true);
  };

  render() {
    return (
      <Layout
        {...this.props} 
        navIcon={<Icon className="html-embed-7 w-embed" type={IconType.LEFT_OUTLINED} />}
        onLeftClick={() =>
          this.handleGoBack({
            path: paths.HOME,
            runGoBack: true,
          })
        }
        navContent={getIntl().page.signup}
        isFooter={false}
        className={prefix}
      >
        <SignUp signUpClick={this.signUpClick} />
      </Layout>
    );
  }
}

export default SignUpPage;
