import dayjs from 'dayjs';
import ShippingMethod from '@/lib/order-builder/enums/ShippingMethod';
import { IStore, IOpeningHour, IStoreConfig } from '@/actions/store-action';
import { IPickerOptionData } from '.';
import cloneDeep from 'lodash/cloneDeep';
import { getIntl } from '../App/App';

interface IGenerateTimeOptionsParams {
  interval: number;
  day: number;
  isCurrentDay: boolean;
  openingHours: IOpeningHour[];
}

interface IGenerateDataParams {
  store: IStore;
  storeConfig: IStoreConfig;
  procurementMethod: ShippingMethod;
}

export const generateTimeOptions = (params: IGenerateTimeOptionsParams) => {
  const { interval, openingHours, day, isCurrentDay } = params;
  const timeOptions: IPickerOptionData[] = [];

  let newopeningHours: IOpeningHour[] = [];
  if (Array.isArray(openingHours) && openingHours.length > 0) {
    newopeningHours = cloneDeep(openingHours).sort((item1, item2) => {
      if (item1.start > item2.start) {
        return 1;
      } else if (item1.start < item2.start) {
        return -1
      }

      return 0;
    })
  }

  // let index = 0;
  for (let i = 0; i < newopeningHours.length; i++) {
    const openingHour = newopeningHours[i];
    if (!openingHour || !openingHour.start || !openingHour.end || openingHour.start.length !== 4 || openingHour.end.length !== 4) {
      continue;
    }

    let dateObj = dayjs();
    dateObj = dateObj.set('second', 0);
    let endDateObj = dayjs();
    endDateObj = endDateObj.set('second', 0);

    const currentHHmm = dateObj.format('HHmm');
    if (isCurrentDay) {
      if (currentHHmm >= openingHour.end) {
        continue;
      }
    }

    if (!isCurrentDay || currentHHmm <= openingHour.start) {
      dateObj = dateObj.set('hour', Number(openingHour.start.substr(0, 2)));
      dateObj = dateObj.set('minute', Number(openingHour.start.substr(2)));
    }

    endDateObj = endDateObj.set('hour', Number(openingHour.end.substr(0, 2)));
    endDateObj = endDateObj.set('minute', Number(openingHour.end.substr(2)));

    dateObj = dateObj.add(interval === 0 ? 15 : interval, 'minute');
    
    if (Number(dateObj.format('mm')) - 30 < 0) {
      dateObj = dateObj.set('minute', 30);
    } else if (Number(dateObj.format('mm')) - 30 > 0 &&  Number(dateObj.format('mm')) - 45 < 0) {
      dateObj = dateObj.set('minute', 45);
    } else if (Number(dateObj.format('mm')) - 45 > 0) {
      dateObj = dateObj.add(1, 'hour');
      dateObj = dateObj.set('minute', 0);
    }

    while(dateObj.isBefore(endDateObj)) {
      const timeFormatKey = dateObj.format('HH:mm:ss');
      const timeFormatLable12 = dateObj.format('h:mm');

      const openingHoursTime: IPickerOptionData = {
        day,
        value: timeFormatKey,
        label: timeFormatLable12,
      }
  
      timeOptions.push(openingHoursTime);

      dateObj = dateObj.add(interval === 0 ? 15 : interval, 'minute');
    }
  }

  return timeOptions;
}

export const generateData = (params: IGenerateDataParams) => {
  const { procurementMethod, store, storeConfig } = params;
  const { openingHours = {} } = store;
  let advanceOrderDate = 1;
  let interval = 0;

  // store.deliveryAdvanceOrderDate > 0 && store.deliveryInterval > 0 && 

  if (procurementMethod === ShippingMethod.PICK_UP) {
    advanceOrderDate = storeConfig.pickUpAdvanceOrderDate || 1;
    interval = storeConfig.pickUpInterval || interval;
  } else if (procurementMethod === ShippingMethod.DELIVERY) {
    advanceOrderDate = storeConfig.deliveryAdvanceOrderDate || 1;
    interval = storeConfig.deliveryInterval || interval;
  }

  // 最小时间间隔为5分钟
  // if (interval < 5) {
  //   advanceOrderDate = 1;
  // }

  interval = Math.abs(interval);

  let date = dayjs();

  if (storeConfig && storeConfig.delayedDeliveryTime) { // 延迟交货
    date = date.add(storeConfig.delayedDeliveryTime, 'day');
  }

  const dateOptions: IPickerOptionData[] = [];
  const dayRefTimeOptions: {[day: string]: IPickerOptionData[]} = {};
  let currentDayTimeOptions: IPickerOptionData[] = [];
  
  currentDayTimeOptions = generateTimeOptions({
    interval,
    isCurrentDay: true,
    day: date.day(),
    openingHours: openingHours[`${date.day()}`] || [],
  });

  for (let i = 0; i < 7; i++) {
    dayRefTimeOptions[i] = generateTimeOptions({
      interval,
      isCurrentDay: false,
      day: i,
      openingHours: openingHours[i] || [],
    });
  }
  
  for (let i = 0; i < advanceOrderDate; i++) {
    const dateDay = date.day();
    let dateTimes = dayRefTimeOptions[dateDay];
    if (i === 0) {
      dateTimes = currentDayTimeOptions;
    }
    if (Array.isArray(dateTimes) && dateTimes.length > 0 && (storeConfig.deliveryTime.includes(String(dateDay)) || storeConfig.deliveryTime.length === 0)) { // 送货时间是否在配置的周期
      const dateFormat = date.format('YYYY-MM-DD');
      const dateOption: IPickerOptionData = {
        day: dateDay,
        value: dateFormat,
        label: dateFormat,
      }
      if (i === 0 && dateOption.day === dayjs().day()) {
        dateOption.label = getIntl().common.today;
      } else if (i === 1 && dateOption.day === (dayjs().add(1, 'day').day())) {
        dateOption.label = getIntl().common.tomorrow;
      }
  
      dateOptions.push(dateOption);
    }
    date = date.add(1, 'day');
  }

  return {
    dateOptions,
    dayRefTimeOptions,
    currentDayTimeOptions,
  };
}

export const generateAmPmData = (timeOptions: IPickerOptionData[]) => {
  let amPmOptions: IPickerOptionData[] = [];
  
  if (timeOptions.length > 0) {
    const firstTimeOptionValue: any = timeOptions[0].value;
    if (firstTimeOptionValue >= '12:00:00') {
      amPmOptions = [
        {
          day: timeOptions[0].day,
          value: 'PM',
          label: 'PM',
        }
      ]
    } else {
      amPmOptions = [
        {
          day: timeOptions[0].day,
          value: 'AM',
          label: 'AM',
        },
        {
          day: timeOptions[0].day,
          value: 'PM',
          label: 'PM',
        },
      ];
    }
  }

  return amPmOptions;
}

interface IValidData {
  newSelectedTime: string | number;
  newSelectedAmPm: string | number;
}

export const getValidDataByTimeOptionValue = (timeOptions: IPickerOptionData[], timeOptionValue: string | number): IValidData => {
  let newSelectedTime: string | number = '';
  if (timeOptionValue && Array.isArray(timeOptions)) {
    for (let i = 0; i < timeOptions.length; i++) {
      const option = timeOptions[i];
      if (option.value >= timeOptionValue) {
        newSelectedTime = option.value;
        break;
      }
    }
  }

  if (!newSelectedTime && Array.isArray(timeOptions) && timeOptions.length > 0) {
    newSelectedTime = timeOptions[0].value;
  }

  let newSelectedAmPm: string | number = '';

  if (newSelectedTime) {
    if (newSelectedTime > '12:00:00') {
      newSelectedAmPm = 'PM';
    } else {
      newSelectedAmPm = 'AM';
    }
  }

  return {
    newSelectedTime,
    newSelectedAmPm,
  };
}
