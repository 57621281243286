import { RouteComponentProps } from 'react-router-dom';
import withRoute from '@/pages/withRoute';
import ExpressOrder from './ExpressOrder';
import './ExpressOrder.less';

export const prefix = 'bm-p-express-order';

const RouteComponent: React.FC<RouteComponentProps> = props => withRoute({
  pageComponent: ExpressOrder,
  routeProps: props,
  pageTitleI18nKey: '',
});
export default RouteComponent;
