enum ShippingMethod {
  /**
   * 外卖
   * procurement_method = 2
   * Take Away
   */
  PICK_UP = 0,

  /**
   * 速递
   * procurement_method = 4
   */
  DELIVERY = 1,

  /**
   * 堂食
   * procurement_method = 1
   */
  DINE_IN = 2,

  /**
   * 堂食（自取）
   * procurement_method = 8
   */
  DINE_IN_UNASSIGNED = 3,

  /**
   * 扫二维码购物
   * procurement_method = 1
   */
  QRCODE_SHOPPING = 1001,
}

export const defaultShippingMethod: ShippingMethod = ShippingMethod.PICK_UP;

export default ShippingMethod;
