import React from 'react';
import { IFavoriteTab } from '@/lib/order-builder/utils/api';
import LazyLoad from 'react-lazyload';
import { IReducersState } from '@/reducers';
import { useSelector } from 'react-redux';
import Config from '@/Config';

interface IMenuGridItemProps {
  data: IFavoriteTab;
  onClick?: (data: IFavoriteTab) => void;
}

const MenuGridItem: React.FC<IMenuGridItemProps> = props => {
  const { data, onClick } = props;
  const reducersState: IReducersState = useSelector<IReducersState, IReducersState>(state => state);
  const { deviceInfo } = reducersState.app;

  const handleClick = () => {
    if (onClick) {
      onClick(data);
    }
  }

  return (
    <div className="menu-card" onClick={handleClick}>
      {
        deviceInfo.inPC &&
        <div className="image-card-upper menu-icon" style={{backgroundImage: `url(${data.mediumImageUrl? data.mediumImageUrl: Config.defaultStoreImg})`}} ></div>
      }
      {
        !deviceInfo.inPC &&
        <LazyLoad once offset={100}>
          <div className="image-card-upper menu-icon" style={{backgroundImage: `url(${data.mediumImageUrl? data.mediumImageUrl: Config.defaultStoreImg})`}} ></div>
        </LazyLoad>
      }
      <div className="div-block-99">
        <h4 className="heading-4">{data.name}</h4>
      </div>
    </div>
  );
}

export default MenuGridItem;
