import React from 'react';
import { prefix } from '.';

const Skeleton: React.FC = () => (
  <div className={`${prefix}-skeleton`} id="skeleton">
    <div className="qrcode-msg">
      <div className="qrcode-msg-item">
        <div className="item-title" />
        <div className="item-con" />
      </div>
      <div className="qrcode-msg-item">
        <div className="item-title" />
        <div className="item-con" />
      </div>
    </div>
    <div className="qrcode-msg">
      <div className="qrcode-msg-item">
        <div className="item-title" />
        <div className="item-con" />
      </div>
      <div className="qrcode-msg-item">
        <div className="item-title" />
        <div className="item-con" />
      </div>
    </div>
  </div>
);

export default Skeleton;
