import { BigNumber } from 'bignumber.js';
import * as PayIcon from './PayIcon';
import CurrencyKV from './CurrencyKV';

export const getPayIcon = PayIcon.getPayIcon;
export const formatCurrency = (amount: number, currencyCode = 'HKD') => {
  if (CurrencyKV[currencyCode]) {
    currencyCode = CurrencyKV[currencyCode];
  }
  if (amount < 0) {
    return `-${currencyCode}${new BigNumber(Math.abs(amount)).toFormat(2)}`;
  }
  return `${currencyCode}${new BigNumber(amount).toFormat(2)}`;
}
export const formatCurrencyCode = (currencyCode = 'HKD') => {
  if (CurrencyKV[currencyCode]) {
    currencyCode = CurrencyKV[currencyCode];
  }
  
  return currencyCode;
}
