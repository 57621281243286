import GoogleMap from './GoogleMap/NewGoogleMap';
import StoreGoogleMap from './StoreGoogleMap/StoreGoogleMap';

export const prefix = 'bm-c-google-map';

export const storeprefix = 'bm-c-store-map';

export interface IGoogleMapAdress {
  name: string;
  vicinity: string;
  lat: string | number;
  lng: string | number;
  areaInfo: IGeoCodeLocation;
  regionInfo: IGeoCodeLocation;
  cityInfo: IGeoCodeLocation;
}

export interface IAddressCenter {
  lat: number;
  lng: number;
}

export interface IGeoCodeLocation {
  long_name: string;
  short_name: string;
  types: string[];
}

export {
  GoogleMap,
  StoreGoogleMap,
};

export default GoogleMap;
