import React, { useEffect, useState } from 'react';
import { prefix } from '.';
import Icon, { IconType } from '@/components/Icon';
import Constants from '@/constants';
import { getIntl } from '../App/App';

interface IMiniProgramTipsProps {
}

const MiniProgramTips: React.FC<IMiniProgramTipsProps> = props => {

  const tips = localStorage.getItem(Constants.MINI_PROGRAM_TIPS)
  const [show, setHalf] = useState(tips);
  let timeLoop: any;
  useEffect(() => {
    if (localStorage.getItem(Constants.MINI_PROGRAM_TIPS)) {
      let countDown = 20
      timeLoop = setInterval(() => {
        if (countDown < 1 || !localStorage.getItem(Constants.MINI_PROGRAM_TIPS)) {
          handleCloseTips();
          clearInterval(timeLoop);
        } else {
          countDown -= 1;
        }
      }, 1000);
    }
  }, []);

  const handleCloseTips = () => {
    setHalf('false');
    localStorage.setItem(Constants.MINI_PROGRAM_TIPS, 'false');
  }
  return (
    <div className={`${prefix} ${show === 'true' ? 'show' : 'hide'}`}>
      <div className="add-tips-wrap" onClick={handleCloseTips}>
        <div className="triangle-up"></div>
        <div className="add-tips">
          <div>{getIntl().common.miniProgramTips}</div>
          <Icon className="close-tip" type={IconType.RIGHT_OUTLINED} />
        </div>
      </div>
    </div>
  );
}

export default MiniProgramTips;
