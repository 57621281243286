import axios from 'axios';
import Config from '@/Config';

export interface ISendEmailParams {
  storeID: string;
  email: string;
  orderNumber?: string;
  templateType: string;
  bookingID?: string;
}

export const sendEmail = async (params: ISendEmailParams) => {
  const {
    email,
    storeID,
    orderNumber,
    templateType,
    bookingID,
  } = params;

  const sendData = {
    'template_type': templateType,
    email,
    'store_id': storeID,
    'order_number': orderNumber,
    'booking_id': bookingID,
  }
  if (bookingID) {
    delete sendData['order_number'];
    sendData['template_type'] = 'booking';
  } else {
    delete sendData['booking_id'];
  }

  try {
    const resp = await axios({
      method: 'post',
      url: Config.urls.sendEmail.replace('{storeID}', storeID),
      data: sendData
    })

    const {
      data,
      success
    } = resp || {};

    const {
      message
    } = data || {};

    return {
      success,
      message,
    };
  } catch (error) {
    console.error(error)
  }
}
