import React from 'react';
import { prefix } from '.';
import dayjs from 'dayjs'
import { IBlog } from '@/actions/store-action';
import { getI18nText } from '@/utils/app';
import { IReducersState } from '@/reducers';

interface INewsPanelProps {
  onItemClick?: (data: IBlog) => void;
  data: IBlog;
  showDate?: boolean;
  reducersState: IReducersState;
}

const NewsPanel: React.FC<INewsPanelProps> = props => {
  const { onItemClick, data, showDate = false, reducersState } = props;
  return (
    <div className={`${prefix}-panel branch-card w-inline-block`} onClick={() => { onItemClick && onItemClick(data) }}>
      <div className="image-card-upper news box-news-image" style={{ backgroundImage: `url(${data.cover})` }}></div>
      <div className="div-block-99 news-msg">
        <h4 className="heading-4 news-title">{getI18nText(data, 'content_title', reducersState.app.language) || data.title}</h4>
        {
          showDate &&
          <div className="text-block-5---subtitle">{dayjs(data.updatedAt).format('YYYY.MM.DD')}</div>
        }
      </div>
    </div>
  );
}

export default NewsPanel;
