import {
  IQueueing,
  IQueueingSetting,
} from '@/actions/queueing-action';

export const parseResQueueing = (record: any) => {
  const queueing: IQueueing = {
    queueingNo: record.ticket_number || '',
    queueTime: record.created_at || '',
    seats: record.people || '',
    status: record.status || '',
    id: record.id || '',
    storeID: record.store_id || '',
    tableID: record.party_size_segment_item_id || '',
    updateAt: record.updated_at || '',
  }

  return queueing;
}

export const parseResQueueingSetting = (records: any) => {
  const queueingSettings: IQueueingSetting[] = [];
  if (Array.isArray(records) && records.length > 0) {
    records.forEach(item => {
      queueingSettings.push({
        max: item.maximum_party_size || 0,
        min: typeof item.minimum_party_size === 'string' ? Number(item.minimum_party_size) || 0 : item.minimum_party_size || 0,
        type: item.label || '',
        id: item.id || '',
      })
    })
  }

  return queueingSettings;
}
