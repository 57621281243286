import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import { prefix } from '.';
import ActionType from '@/actions/action-type';
import StoreList from '@/components/StoreList';
import { getAppTheme, setScreenColor, getIntl } from '@/components/App/App';
import Layout from '@/components/Layout';
import { IStore } from '@/actions/store-action';
import paths from '@/routes/paths';
import Icon, { IconType } from '@/components/Icon';
import Status from '@/components/Status';
import { animateScroll } from 'react-scroll';
import { handleStoreServiceTypesScroll } from '@/utils/app';
import { setCanAutoProcurementMethodPopup } from '@/pages/MenuListPage/MenuListPage';
import { parseRouteParams, getLocalStorageJson, setLocalStorageJson } from '@/utils';
import Constants from '@/constants';
import { setUseLocalCategoryID } from '../CategoryPage/CategoryPage';
import CategoryTypes from './CategoryTypes';

export interface IStoresByCategoryPageProps extends IBasePageProps {
}

export interface IStoresByCategoryPageState {
  pageInfo: {[categoryID: string]: {page: number; lastPage: boolean}};
  stores?: IStore[];
  loadingStores: boolean;
  loadingFirstPage: boolean;
  showQRCodeScan: boolean;
  selectedCategoryID: string;
  store: IStore | undefined;
}

let useLocal = false;

export const setUseLocalBrandID = (use: boolean) => {
  useLocal = use || false;
}

class StoresByCategoryPage extends BasePage<IStoresByCategoryPageProps, IStoresByCategoryPageState> {
  loadingData = false;
  lastPage = false;
  selectedCategoryID = getLocalStorageJson(Constants.BRANDID) || '0';

  constructor(props: IStoresByCategoryPageProps) {
    super(props);
    const routeParams = parseRouteParams(props);

    this.state = {
      pageInfo: {},
      loadingStores: true,
      loadingFirstPage: true,
      showQRCodeScan: false,
      selectedCategoryID: useLocal ? this.selectedCategoryID : routeParams.brandID || '',
      store: undefined,
    }
  }

  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.darkBackgroundColor) {
      setScreenColor(appTheme.darkBackgroundColor);
    }

    super.componentDidMount();

    const { dispatch, reducersState } = this.props;
    const { pageInfo, selectedCategoryID } = this.state;
    const { page = 1 } = pageInfo[selectedCategoryID] || {};
    const { selectStoreSlug } = reducersState.supplierInfo;
    const { platformAppSettings } = reducersState.app;
    dispatch({
      type: ActionType.QUERY_STORES_BY_CATEGORY,
      categoryID: Number(selectedCategoryID) === 0 ? '' : selectedCategoryID,
      page,
      storeType: 'all',
      callback: () => {
        if (platformAppSettings && platformAppSettings.suppliers) {
          dispatch({
            type: ActionType.QUERY_USER_ASSOCIATE_STORES,
            callback: (stores: IStore[]) => {
              let store: IStore | undefined = undefined;
              stores.forEach(item => {
                if (item.slug === selectStoreSlug) {
                  store = item;
                }
              })
              this.setState({
                store,
              });
              this.closeLoading();
            }
          })
        } else {
          this.closeLoading();
        }
        this.setState({
          loadingStores: false,
          loadingFirstPage: false,
        });
      }
    });

    handleStoreServiceTypesScroll(selectedCategoryID);
    
    const pageWrap = window.document.querySelector('.page-content-wrapper-web');
    if (pageWrap) {
      pageWrap.addEventListener('scroll', this.handleScroll, { passive: true });
    } else {
      window.addEventListener('scroll', this.handleScroll, { passive: true });
    }
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleScroll);
    setUseLocalBrandID(false);
    const pageWrap = window.document.querySelector('.page-content-wrapper-web');
    if (pageWrap) {
      pageWrap.removeEventListener('scroll', this.handleScroll);
    } else {
      window.removeEventListener('scroll', this.handleScroll);
    }
  }

  getScrollY = () => {
    const pageWrap = window.document.querySelector('.page-content-wrapper-web');
    let scrollY = window.scrollY;
    if (pageWrap) {
      scrollY = pageWrap.scrollTop;
    }

    return scrollY;
  }

  handleScroll = () => {
    const { pageInfo, selectedCategoryID } = this.state;
    const newCategoryID = Number(selectedCategoryID) === 0 ? '' : selectedCategoryID;
    const { page = 1 } = pageInfo[newCategoryID] || {};
    const { dispatch } = this.props;
    if (!this.lastPage && !this.loadingData) {
      const appPages = document.getElementsByClassName('bm-p-stores-by-brand');
      if (appPages && appPages.length) {
        const appPage = appPages[0];
        const scrollHeight = appPage.scrollHeight;
        const canScrollHeight = scrollHeight - window.innerHeight;
  
        if (canScrollHeight - this.getScrollY() < 5 * 200) {
          this.loadingData = true;
          this.setState({loadingStores: true});
          dispatch({
            type: ActionType.QUERY_STORES_BY_CATEGORY,
            page: page + 1,
            categoryID: newCategoryID,
            storeType: 'all',
            callback: (_stores, page, lastPage) => {
              this.loadingData = false;
              this.lastPage = lastPage;
              this.setState({
                loadingStores: false,
                pageInfo: {
                  ...pageInfo,
                  [newCategoryID]: {
                    page,
                    lastPage,
                  }
                },
              });
            }
          });
        }
      }
    }
  }

  handleItemClick = (data: IStore) => {
    if (data.isGroup) {
      this.handleGotoRoute({
        path: paths.STORE_GROUP,
        pathParams: {
          storeSlug: data.slug,
        },
      });
    } else {
      setCanAutoProcurementMethodPopup(true);
      this.handleGotoRoute({
        path: paths.MENU_LIST,
        pathParams: {
          storeSlug: data.slug,
        },
      });
    }
  }

  handleCategoryClick = (categoryID: string) => {
    const { pageInfo, selectedCategoryID } = this.state;
    if (selectedCategoryID !== categoryID) {
      const { dispatch } = this.props;
      this.openLoading();
      this.loadingData = true;
      this.lastPage = false;
      const newCategoryID = Number(categoryID) === 0 ? '' : categoryID;

      this.setState({
        loadingStores: true,
        selectedCategoryID: categoryID,
        pageInfo: {
          ...pageInfo,
          [categoryID]: {
            lastPage: false,
            page: 1,
          }
        },
      });
      animateScroll.scrollTo(0, {
        duration: 200,
      });
      setLocalStorageJson(Constants.BRANDID, categoryID);
      dispatch({
        type: ActionType.QUERY_STORES_BY_CATEGORY,
        page: 1,
        categoryID: newCategoryID,
        storeType: 'all',
        callback: (_stores, page, lastPage) => {
          this.loadingData = false;
          this.lastPage = lastPage;
          this.setState({
            loadingStores: false,
            pageInfo: {
              ...pageInfo,
              [newCategoryID]: {
                page,
                lastPage,
              }
            },
          });
          this.closeLoading();
        }
      });
    }
  }

  render() {
    const { reducersState } = this.props;
    const {
      loadingStores,
      loadingFirstPage,
      pageInfo,
      selectedCategoryID,
      store,
    } = this.state;
    const { platformConfig, platformStoreBrands } = reducersState.app;
    const { typeRefStoresByCategory } = reducersState.store;
    const newCategoryID = Number(selectedCategoryID) === 0 ? '' : selectedCategoryID;
    const stores = typeRefStoresByCategory['all'] && typeRefStoresByCategory['all'][newCategoryID] ? typeRefStoresByCategory['all'][newCategoryID] || [] : [];
    const { lastPage = false } = pageInfo[newCategoryID] || {};
    const hasStores = stores.length > 0;
    const { validServiceType = [] } = platformConfig;
    

    return (
      <Layout
        {...this.props}
        className={`bm-p-stores bm-p-stores-by-category ${prefix} ${validServiceType.length > 1 ? 'has-service-types' : ''}`}
        navContent={
          <div className="store-name" onClick={() => this.handleGotoRoute({path: paths.SELECT_STORES})}>
            { 
              store &&
              <div className="is-store-name">{store.name}</div>
            }
            {store && <Icon className="down html-embed-7 w-embed"type={IconType.DOWN_OUTLINED} />}
          </div>
        }
        navIcon={<Icon className="html-embed-7 w-embed" type={IconType.LEFT_OUTLINED} />}
        onLeftClick={() => {
          setUseLocalCategoryID(true);
          this.handleGotoRoute({
            path: paths.CATEGORY,
          });
        }}
      >
        <div className="nav_bg nav-blur colored w-nav category-onself">
          {
            platformStoreBrands.length > 0 &&
            <CategoryTypes
              data={platformStoreBrands}
              selected={selectedCategoryID}
              reducersState={reducersState}
              onItemClick={this.handleCategoryClick}
            />
          }
        </div>
        {
          !loadingFirstPage &&
          <>
            {
              hasStores &&
              <div className="space-1">
                <div className="section">
                  <StoreList
                    data={stores}
                    reducersState={reducersState}
                    loading={loadingStores}
                    className={`${prefix}-store-list`}
                    lastPage={lastPage}
                    onItemClick={this.handleItemClick}
                  />
                  {
                    lastPage &&
                    <div className="no-more-content">
                      <span>{getIntl().page.noMoreContent}</span>
                    </div>
                  }
                </div>
              </div>
            }
            {
              !loadingStores && platformConfig.id && !hasStores &&
              <div className="no-stores">
                <Status
                  iconType={IconType.TAG_STORE}
                  title="There are no shops in the city."
                  describe="We are working hard to invite you to join us, please look forward to it!"
                />
              </div>
            }
          </>
        }
      </Layout>
    );
  }
}

export default StoresByCategoryPage;
