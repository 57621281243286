
import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import { IObject } from '@/components/bm-common';
import { getAppTheme, setScreenColor, getIntl } from '@/components/App/App';
import { prefix } from '.';
import ActionType from '@/actions/action-type';
import paths from '@/routes/paths';
import AddressItem from './AddressItem';
import Icon, { IconType } from '@/components/Icon';
import Modal from 'antd-mobile/es/modal';
import InputItem from 'antd-mobile/es/input-item';
import Button from 'antd-mobile/es/button';
import GoogleMap, { IGoogleMapAdress } from '@/lib/google-map';
import { IAddress } from '@/actions/user-action';
import { AddressListPageStyled } from './styled';
import Components from '@/components/SideNavBar/Components';
import SideNavOption from '@/actions/side-nav-option';
import Layout from '@/components/Layout';
import { IShoppingCartData } from '@/actions/shopping-cart-action';
import dayjs from 'dayjs';
import ShippingMethod from '@/lib/order-builder/enums/ShippingMethod';



export interface IAddressListPageProps extends IBasePageProps {}
export interface IAddressListPageState {
  showMap: boolean;
  receiveAddress: IGoogleMapAdress | undefined;
  aptSuite: string;
  receiver: string;
  phone: string;
  editAddress: boolean;
  vicinity: string;
  addressID: string;
  lat: string;
  lng: string;
  currentAddressID: string;
  shoppingCartData?: IShoppingCartData;
}

class AddressListPage extends BasePage<IAddressListPageProps, IAddressListPageState> {
  routeParams: IObject = {};

  state: IAddressListPageState = {
    showMap: false,
    receiveAddress: undefined,
    aptSuite: '',
    receiver: '',
    phone: '',
    editAddress: false,
    vicinity: '',
    addressID: '',
    lat: '',
    lng: '',
    currentAddressID: '',
  }

  componentDidMount() {
    const appTheme = getAppTheme();
    const { dispatch, routeParams } = this.props;
    if (appTheme.lightBackgroundColor) {
      setScreenColor(appTheme.lightBackgroundColor);
    }
    super.componentDidMount();
    this.getAddressList();
    if (routeParams.storeSlug) {
      dispatch({
        type: ActionType.GET_STORE_SHOPPING_CART_DATA,
        storeSlug: routeParams.storeSlug,
        callback: params => {
          const { shoppingCartData } = params;
          this.setState({ shoppingCartData });
          if (shoppingCartData) {
            this.setState({
              currentAddressID: shoppingCartData.orderData.pickup_location_id || '',
            })
          }
        },
      });
    }
  }

  getAddressList = () => {
    const { dispatch } = this.props;
    dispatch({
      type: ActionType.LOADING,
      showLoading: true,
      showLoadingBgc: true,
    });
    dispatch({
      type: ActionType.QUERY_ADDRESS,
      callback: address => {
        if (address) {
          setTimeout(() => {
            dispatch({
              type: ActionType.LOADING,
              showLoading: false,
            })
          }, 400)
        }
      },
    });
  }

  handleAddAddress = () => {
    this.setState({
      showMap: true,
      receiveAddress: undefined,
      aptSuite: '',
      receiver: '',
      phone: '',
      editAddress: false,
      vicinity: '',
      lat: '',
      lng: '',
    });
  }

  chooseLocation = (item: IAddress) => {
    this.setState({
      currentAddressID: item.id,
    })
    const { dispatch, routeParams } = this.props;
    const { shoppingCartData } = this.state;
    dispatch({
      type: ActionType.CHANGE_SHIPPING_METHOD,
      storeSlug: routeParams.storeSlug,
      data: {
        shippingMethod: ShippingMethod.DELIVERY,
        shippingAddressID: item.id,
        orderShippingAddress: item,
        deliveryDate: dayjs(shoppingCartData?.orderData.delivery_date),
      },
      callback: shoppingCartData => {
        console.info('shoppingCartData', shoppingCartData);
      }
    });
    this.handleGoBack({
      path: paths.HOME,
      runGoBack: true,
    })
  }


  handleSaveAddress = () => {
    const { dispatch } = this.props;
    const { receiveAddress, receiver, phone, aptSuite } = this.state;
    if (receiveAddress && receiver && phone) {
      dispatch({
        type: ActionType.LOADING,
        showLoading: true,
      });
      dispatch({
        type: ActionType.ADD_ADDRESS,
        name: receiver,
        phone: phone,
        city: `${receiveAddress.vicinity}${' '}${receiveAddress.name}`,
        address: `${aptSuite}`,
        lng: `${receiveAddress.lng}`,
        lat: `${receiveAddress.lat}`,
        callback: address => {
          dispatch({
            type: ActionType.LOADING,
            showLoading: false,
          });
          this.setState({showMap: false});
        },
      });
    }
  }

  goBackPage = () => {
    const { routeParams } = this.props;
    if (routeParams.storeSlug) {
      this.handleGoBack({
        path: paths.SHOPPING_CART,
        pathParams: {
          storeSlug: routeParams.storeSlug,
        },
        runGoBack: true,
      })
    } else {
      this.handleGoBack({
        path: paths.HOME,
        runGoBack: true,
      })

    }
  }

  handleDeleteAddress = (addressID: string) => {
    const { dispatch } = this.props;

    Modal.alert(getIntl().common.tips, getIntl().page.deleteAddressTips, [
      {
        text: getIntl().common.cancel,
        onPress: () => {},
      },
      {
        text: getIntl().common.confirm,
        onPress: () => {
          dispatch({
            type: ActionType.LOADING,
            showLoading: true,
          });
          dispatch({
            type: ActionType.DELETE_ADDRESS,
            addressID,
            callback: address => {
              if(!address) {
                console.info('success');
              }
              dispatch({
                type: ActionType.LOADING,
                showLoading: false,
              });
            },
          });
        },
      }
    ]);
  }

  handleEidtAddress = (address: IAddress, addressID: string) => {
    this.setState({
      showMap: true,
      editAddress: true,
      aptSuite: address.address1,
      receiver: address.name,
      phone: address.phone,
      vicinity: address.city,
      receiveAddress: undefined,
      addressID,
      lat: address.lat,
      lng: address.lng,
    });
  }

  handeSaveEditAddress = () => {
    const { dispatch } = this.props;
    const { receiveAddress, receiver, phone, aptSuite, lat, lng } = this.state;
    if (receiver && phone) {
      dispatch({
        type: ActionType.LOADING,
        showLoading: true,
      });
      dispatch({
        type: ActionType.EDIT_ADDRESS,
        name: receiver,
        phone: phone,
        city: receiveAddress ? `${receiveAddress.vicinity}${' '}${receiveAddress.name}` : this.state.vicinity,
        address: `${aptSuite}`,
        lng: receiveAddress ? `${receiveAddress.lng}` : lng,
        lat: receiveAddress ? `${receiveAddress.lat}` : lat,
        addressID: this.state.addressID,
        callback: address => {
          dispatch({
            type: ActionType.LOADING,
            showLoading: false,
          });
          this.setState({showMap: false});
        },
      });
    }
  }

  render() {
    const { reducersState } = this.props;
    const { address: addressList } = reducersState.user;
    const { receiveAddress, vicinity, editAddress, lat, lng } = this.state;
    return (
      <Layout
        {...this.props}
        isFooter={false}
        className={prefix}
      >
        <Components
          {...this.props}
          reducersState={reducersState}
          navContent={getIntl().page.address}
          navIcon={<Icon className="html-embed-7 w-embed" type={IconType.LEFT_OUTLINED} />}
          onLeftClick={this.goBackPage}
          sideNavOption={SideNavOption.ADDRESS}
        >
          <AddressListPageStyled className="div-block-547 address">
            <>
              <div className="section address wf-section">
                <div className="add-new-address transaprent" onClick={() => this.handleAddAddress()}>
                  <div className="div-block-499">
                    <Icon className="div-block-498" type={IconType.PLUS_CIRCLE_OUTLINED}/>
                    <div className="text-block-94">{getIntl().page.addAddress}</div>
                  </div>
                </div>
                <div className="div-block-638 address">
                  {
                    addressList && addressList.length > 0 && addressList.map(item => (
                      <AddressItem
                        key={item.id}
                        address={item}
                        onDeleteItemClick={this.handleDeleteAddress}
                        onEditItemClick={this.handleEidtAddress}
                        onSelectClick={this.chooseLocation}
                      />
                    ))
                  }
                </div>
              </div>
            </>
            {
              this.state.showMap && 
              <div className="modal-wrapper-edit-address">
                <div className="c-popup-close-2 close-modal" onClick={() => this.setState({showMap: false})}></div>
                <div className="flex-pop-up-edit edit-address">
                  <div className="top-header widen">
                    <h2 className="heading-2">{getIntl().page.address}</h2>
                    <div className="x-icon close-modal"><Icon className="close-icon close-modal w-embed" type={IconType.CLOSE_OUTLINED}  onClick={() => this.setState({showMap: false})}/></div>
                  </div>
                  <GoogleMap
                    savePlace={(data: IGoogleMapAdress) => {
                      this.setState({
                        receiveAddress: data,
                      })
                    }}
                    detailAddress={
                      <>
                        <div className="div-block-639">
                          {
                            receiveAddress &&
                            <>
                              <div className="text-block-77">{receiveAddress.name}</div>
                              <div className="text-block-69">{receiveAddress.vicinity}</div>
                            </>
                          }
                          {
                            !receiveAddress &&
                            <>
                              <div className="text-block-77">{vicinity.split(' ')[1]}</div>
                              <div className="text-block-69">{vicinity.split(' ')[0]}</div>
                            </>
                          }
                        </div>
                        <div className="div-block-497 wikth-space">
                          <div className="edit-addresses">
                            <div className="div-block-488 absolute">
                              <div className="text-block-77 no-space"> {getIntl().page.aptSuite}</div>
                            </div>
                            <InputItem
                              autoComplete="off"
                              placeholder={getIntl().page.placeholderApt}
                              value={this.state.aptSuite}
                              onChange={value => this.setState({aptSuite: value})}
                              className="text-block-69"
                            />
                          </div>
                          <div className="edit-addresses">
                            <div className="div-block-488 absolute">
                              <div className="text-block-77 no-space"> {getIntl().page.receiver}</div>
                            </div>
                            <div className="text-block-69">
                              <InputItem
                                autoComplete="off"
                                placeholder={getIntl().page.placeholderReceiver}
                                value={this.state.receiver}
                                onChange={value => this.setState({receiver: value})}
                              />
                            </div>
                          </div>
                          <div className="edit-addresses">
                            <div className="div-block-488 absolute">
                              <div className="text-block-77 no-space"> {getIntl().page.phone}</div>
                            </div>
                            <div className="text-block-69">
                              <InputItem
                                autoComplete="off"
                                placeholder={getIntl().page.placeholderPhone}
                                value={this.state.phone}
                                onChange={value => this.setState({phone: value})}
                              />
                            </div>
                          </div>
                        </div>
                        <Button
                          type="primary"
                          className="link-block streched text-block-17"
                          onClick={editAddress ? this.handeSaveEditAddress : this.handleSaveAddress}
                        >
                          {getIntl().page.save}
                        </Button>
                      </>
                    }
                    addressCenter={{
                      lat: Number(lat),
                      lng: Number(lng),
                    }}
                    editAddress={editAddress}
          
                  />
                </div>
              </div>
            }
          </AddressListPageStyled>
        </Components>
      </Layout>
    );
  }
}

export default AddressListPage;
