import styled  from 'styled-components';
export const SettingItemStyled = styled.div`
  .text-block-67._1 {
    display: flex;
    div {
      margin-right: 20px;
    }
  }
  .div-block-546.no-line {
    border-bottom-width: 0px;
  }
  .div-block-546 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 30px 15px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 1px solid  var(--bm-border-color);
    border-radius: 0px;
    font-family: 'Sf pro display', sans-serif;
    color: var(--bm-text-title-color);
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
  }
  .text-block-67 {
    margin-bottom: 0px;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: var(--bm-text-title-color);
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
  }
  .div-block-487 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    color: var(--bm-text-secondary-color);
    cursor: pointer;
  }
  .html-embed-34 {
    position: static;
    left: auto;
    top: auto;
    right: 0%;
    bottom: auto;
    width: 26px;
    margin-right: 0px;
    margin-left: 10px;
    font-size: unset;
    padding: unset;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .div-block-546.no-line {
    border-bottom-width: 0px;
  }

@media screen and (max-width: 991px) {
  .div-block-546.setting {
    padding-bottom: 25px;
  }
  .div-block-546 {
    padding-top: 25px;
    padding-bottom: 10px;
  }
  .html-embed-34 {
    width: 22px;
    margin-left: 0px;
  }
}

@media screen and (max-width: 479px) {
  .div-block-546.setting {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .div-block-546 {
    padding: 20px 0px;
  }
  .text-block-67._1 {
    font-size: 16px;
  }
  .text-block-67 {
    margin-bottom: 0px;
    font-size: 14px;
  }
  .div-block-487 {
    margin-left: 10px;
    margin-right: 10px;
  }
  .html-embed-34 {
    position: relative;
    width: 19px;
    margin-right: 0px;
    margin-left: 0px;
  }
}

`;
