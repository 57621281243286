import styled from 'styled-components';
export const CategoryPageStyled = styled.div`
background-color: var(--bm-light-background-color);
* {
  box-sizing: border-box;
}
padding-bottom: 90px;
.section.homepage-birdymart {
  display: block;
  padding: 20px 0px 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;

  .w-tab-menu {
    overflow: auto;
    white-space: nowrap;
  }
}
.div-block-570 {
  max-width: 940px;
  margin-right: auto;
  margin-left: auto;

  .see-all-wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
    .sell-all {
      display: flex;
      align-items: center;
      color: var(--bm-text-secondary-color);
      font-size: 16px;
      .viewmore-icon {
        font-size: 24px;
        var(--bm-text-secondary-color);
      }
    }
  }
}
.search-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 30px;
  margin-bottom: 0px;
}
.search-2.saved-shortlists {
  width: 100%;
  position: relative;
  left: 0px;
  right: 0px;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-color: var(--bm-border-color-t60);
}
.search-placeholder {
  width: 200px;
  border-radius: 4px;
  color: var(--bm-text-color);
  padding-left: 18px;
}
.html-embed-51 {
  position: absolute;
  left: auto;
  top: auto;
  right: 12px;
  bottom: auto;
  width: 20px;
  height: 20px;
}
.html-embed-51.grey {
  color: var(--bm-text-title-color);
  svg {
    width: 100%;
    height: 100%;
  }
}
.tilte-row._1 {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
.tilte-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.h2 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: 'Sf pro display', sans-serif;
  font-size: 22px;
  font-weight: 600;
}
.h2.black.no-space {
  margin-bottom: 0px;
}
.recent-orders-all {
  font-size: 18px;
  color: var(--bm-text-title-color);
}
.recent-order-items {
  position: relative;
  display: -ms-grid;
  display: grid;
  overflow: visible;
  width: 100%;
  margin-bottom: 40px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  grid-auto-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}
.div-block-561 {
  width: auto;
  margin-right: 0px;
  padding: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: var(--bm-border-color-t60);
  border-radius: 8px;
  background-color: var(--bm-text-disabled-color);
}
.div-block-562 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: var(--bm-text-title-color);
}
.div-block-564 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.heading-4 {
  font-family: 'Sf pro display', sans-serif;
  font-weight: 600;
}
.heading-4.no-margin {
  margin-top: 0px;
  margin-bottom: 0px;
}
.text-block-120 {
  color: var(--bm-text-color);
  font-size: 16px;
}
.div-block-565 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.div-block-563 {
  width: 80px;
  height: 80px;
  margin-right: 10px;
  max-width: 80px;
  min-width: 80px;
  border-radius: 8px;
  background-position: 50% 50%;
  background-size: cover;
}
.div-block-566 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.text-block-220 {
  margin-left: auto;
  padding: 10px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  border-radius: 6px;
  background-color: var(--bm-primary-color);
  color: var(--bm-light-background-color);
  cursor: pointer;
}
.text-block-68 {
  margin-bottom: 0px;
  padding: 0px;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: var(--bm-text-title-color);
  font-size: 18px;
}
.div-block-567 {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 940px;
  margin-top: 70px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
} 
.tabs-3 {
  position: relative;
  top: -70px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  max-width: none;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  padding: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-radius: 8px;
  /* background-color: rgba(242, 243, 248, 0); */
  color: var(--bm-text-title-color);
}
.div-block-569 {
  position: -webkit-sticky;
  position: sticky;
  left: 0%;
  top: 0;
  right: 0%;
  bottom: auto;
  z-index: 1;
  width: 100vw;
  height: 70px;
  margin-top: -70px;
  margin-left: 0px;
  background-color: var(--bm-light-background-color);
}
.tabs-menu-5 {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 70px;
  max-height: none;
  max-width: none;
  margin: 0px 0px 0;
  padding-top: 0px;
  padding-bottom: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  background-color: var(--bm-light-background-color);
}
.tabs-content-1 {
  margin: 0px;
  padding: 20px;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-radius: 8px;
  background-color: var(--bm-text-disabled-color);
}
.w-tab-link {
  position: relative;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  padding: 9px 30px;
  text-align: left;
  cursor: pointer;
  color: var(--bm-text-title-color);
  background-color: var(--bm-light-background-color);
}
.my-priviledge-tab-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  padding-right: 20px;
  padding-left: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  background-color: var(--bm-light-background-color);
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: var(--bm-text-title-color);
  font-size: 16px;
}
.my-priviledge-tab-1.selected {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  margin-bottom: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  border-radius: 40px;
  background-color: var(--bm-primary-color);
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: var(--bm-light-background-color);
  font-size: 16px;
  font-weight: 700;
}
.text-block-200 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  font-size: 16px;
}
.div-block-537 {
  display: block;
  max-width: none;
  margin-right: auto;
  margin-left: auto;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}
.div-block-537.grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}
.div-block-568 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 210px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
}
h2 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 32px;
  line-height: 36px;
  font-weight: bold;
}
.heading-8 {
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  font-size: 36px;
  color: var(--bm-light-background-color);
}
.tab-pane {
  .h2.black {
    padding-left: 20px;
    margin-bottom: 9px;
    font-size: 16px;
  }
}
.div-block-592 {
  width: 100%;
  background-color: var(--bm-text-disabled-color);
  border-radius: 8px;
}
.nav-div-block-592 {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.23);
  height: 40px;
  border: 1px solid var(--bm-light-background-color);
  border-radius: 5px;
  .search-placeholder,
  .html-embed-51.grey {
    color: var(--bm-light-background-color);
  }
  .search-2.saved-shortlists {
    line-height: 40px;
    font-size: 14px;
  }
}
.search-placeholder {
  width: auto;
  height: 50px;
  min-height: 50px;
  line-height: 50px;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.div-block-592 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  padding-bottom: 0;
}
@media screen and (max-width: 991px) {
  .down.html-embed-7 {
    height: 44px;
  }
  .section.homepage-birdymart {
    padding-top: 20px;
  }
  .div-block-570 {
    max-width: 700px;
  }
  .tilte-row {
    margin-bottom: 0px;
  }
  .recent-order-items {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .div-block-561 {
    padding: 16px;
  }
  .div-block-562 {
    margin-bottom: 0px;
  }
  .heading-4 {
    margin-bottom: 5px;
  }
  .text-block-220 {
    padding: 10px;
  }
  .div-block-567 {
    max-width: 700px;
  }
  .tabs-3 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    max-width: 100%;
    margin-bottom: 0px;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .tabs-content-1 {
    overflow: visible;
    margin-top: 0px;
    margin-left: 0px;
    padding: 0px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    background-color: var(--bm-light-background-color);
  }
  .tabs-menu-5 {
    /* top: 70px; */
    width: auto;
    max-width: none;
    min-width: 0px;
    margin-right: 30px;
    margin-left: 0px;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .my-priviledge-tab-1 {
    padding-right: 20px;
    padding-left: 20px;
  }
  .my-priviledge-tab-1.selected {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
  }
  .div-block-537 {
    display: -ms-grid;
    display: grid;
    overflow: visible;
    margin-right: 0px;
    margin-left: 0px;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  .nav-div-block-592 {
    display: none;
  }
  
}

@media screen and (max-width: 767px) {
  .down.html-embed-7 {
    height: 44px;
  }
  .section.homepage-birdymart {
    padding-top: 20px;
    padding-right: 30px;
    padding-left: 30px;
  }
  /* .div-block-570 {
    max-width: none;
  } */
  .div-block-592 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20px;
    padding-bottom: 0;
    /* border-bottom: 1px solid #cacaca; */
  }
  .search-placeholder {
    line-height: 50px;
    height: 50px;
    min-height: 50px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .html-embed-51 {
    width: 25px;
    height: 25px;
  }
  .tilte-row {
    margin-bottom: 20px;
  }
  .tilte-row._1 {
    margin-bottom: 10px;
  }
  .tab-pane {
    .h2.black {
      padding-left: 16px;
      margin-bottom: 19px;
      font-size: 16px;
    }
  }
  .recent-orders-all{
    font-size: 16px;
  }
  .recent-order-items {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  }
  .div-block-567 {
    max-width: none;
  }
  .div-block-569 {
    /* top: 60px; */
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 50px;
    margin-top: -60px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .tabs-3 {
    top: -40px;
    display: block;
    width: 100%;
    max-width: none;
    margin-top: -14px;
    border-radius: 0px;
  }
  .tabs-menu-5 {
    /* top: 60px; */
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 50px;
    max-height: 50px;
    max-width: 727px;
    min-height: 50px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .tabs-content-1 {
    top: 5px;
    margin-bottom: 0px;
  }
  .my-priviledge-tab-1.selected {
    margin-bottom: 0px;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
    font-size: 16px;
  }
  .my-priviledge-tab-1 {
    margin-bottom: 0px;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
    font-size: 18px;
  }
  .tab-pane {
    padding-top: 0px;
  }
  .div-block-537 {
    margin-right: 0px;
    margin-left: 0px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .div-block-537.grid {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .div-block-568 {
    height: 170px;
  }
  .search-2.saved-shortlists {
    margin-right: 0px;
    margin-bottom: 0px;
  }

}

@media screen and (max-width: 479px) {
padding-bottom: 100px;
  .down.html-embed-7 {
    height: 44px;
  }
  .section.homepage-birdymart {
    padding: 20px 20px 20px;
  }
  .div-block-592 {
    margin-bottom: 20px;
    padding-bottom: 0;
  }
  .search-2.saved-shortlists._1 {
    height: 45px;
    min-height: 45px;
  }
  /* .search-2.saved-shortlists {
    width: 100%;
    max-width: 400px;
    margin-right: 0px;
    margin-bottom: 0px;
  } */
  .search-placeholder {
    height: 45px;
    min-height: 45px;
    font-size: 14px;
    line-height: 45px;
    padding-left: 15px;
  }
  .tilte-row {
    margin-bottom: 0px;
  }
  .tab-pane {
    .h2.black {
      margin-bottom: 10px;
      font-size: 14px;
    }
  }

  .recent-orders-all{
    font-size: 14px;
  }
  .recent-order-items {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  }
  .div-block-561 {
    padding: 11px;
  }
  .heading-4 {
    margin-top: 5px;
    margin-bottom: 0px;
    font-size: 16px;
    line-height: 22px;
  }
  h4 {
    font-size: 16px;
  }
  .text-block-120 {
    font-size: 14px;
  }
  .text-block-220 {
    min-width: 0%;
    padding: 10px;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    text-align: center;
  }
  .text-block-68 {
    font-size: 14px;
    font-weight: 500;
  }
  .div-block-569 {
    /* top: 50px; */
    margin-top: -64px;
  }
  .tabs-3 {
    padding: 0px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .tabs-menu-5 {
    position: -webkit-sticky;
    position: sticky;
    /* top: 50px; */
    max-height: 50px;
    max-width: none;
    margin-right: 0px;
    margin-left: 0px;
    padding-top: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .my-priviledge-tab-1.selected {
    padding: 5px 15px;
    font-size: 14px;
  }
  .my-priviledge-tab-1 {
    padding-right: 15px;
    padding-left: 15px;
    font-size: 16px;
  }
  .div-block-568 {
    height: 140px;
  }
  
}


`;
