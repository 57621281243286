import React from 'react';
import { IStoreCategory } from '@/actions/store-action';
import { getI18nText, handleStoreServiceTypesScroll } from '@/utils/app';
// import './StoreCategories.less';
import { IReducersState } from '@/reducers';

export const prefix = 'bm-c-store-categories';

interface IStoreCategoriesProps {
  data: IStoreCategory[];
  reducersState: IReducersState;
  onItemClick?: (type: string) => void;
}

const StoreCategories: React.FC<IStoreCategoriesProps> = props => {
  const {
    data,
    reducersState,
    onItemClick,
  } = props;

  const handleItemClick = (type: string) => {
    handleStoreServiceTypesScroll(type);
    if (onItemClick) {
      onItemClick(type);
    }
  }

  return (
    <div className="div-block-631" id="bm-store-categories-box">
      <div className="div-block-632">
        {
          data.map(item => (
            <div 
              className="div-block-630 w-inline-block"
              key={item.id}
              id={`bm-store-categories-${item.id}`}
              onClick={() => handleItemClick(item.id)}
              style={{backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${item.categoryImage})`}}
            >
              <div className="text-block-230">{getI18nText(item, 'category_name', reducersState.app.language || item.category_name)}</div>
            </div>
          ))
        }
      </div>
    </div>
  );
}

export default StoreCategories;
