import React from 'react';
import { prefix } from '.';
import Icon, { IconType } from '../Icon';

interface IStatusProps {
  iconType?: IconType;
  imgUrl?: string;
  className?: string;
  title?: React.ReactNode;
  describe?: React.ReactNode;
}

const Status: React.FC<IStatusProps> = props => {
  const {
    className = '',
    title,
    describe,
    iconType,
    imgUrl,
  } = props;

  return (
    <div className={`${prefix} ${className}`}>
      <div className={`${prefix}-wrapper`}>
        {
          (iconType || imgUrl) &&
          <div className="status-icon-wrapper">
            {
              iconType &&
              <Icon type={iconType} />
            }
            {
              !iconType && imgUrl &&
              <div className="status-img">
                <img src={imgUrl} alt="" />
              </div>
            }
          </div>
        }
        {
          title &&
          <div className="status-title">{title}</div>
        }
        {
          describe &&
          <div className="status-describe">{describe}</div>
        }
      </div>
    </div>
  );
}

export default Status;
