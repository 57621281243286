enum RegisterMessage {
  ENTER_VERIFY_CODE = 'Please enter a valid verification code',
  PASSWORD_TO_SHORT = 'for your account security, password must not be short than 6, please use a longer one',
  INVALID_AGAIN = 'invalid authorise code or phone, please try again',
  UNKNOWN_STORE = 'unknown store provided!',
  REGISTERED = 'this phone number has been register, you can try to login or reset password if you forget your password',
  INVALID_CODE = 'invalid auth code, please check',
  ENCRYPTION_PASSWORD = 'encrypt user password failed with error',
  LOGIN_ALREADY_IN_USE ='the login is not available,please use another one',
  INVALID_EMAIL_FORMAT = 'format is invalid',
  CREATE_USER_FAIED = 'create user failed with error',
  SERVICE_UNAVAILABLE = 'service temporarily unavailable, please try later',
  FAILED_TO_CREATE_LOGIN = 'create user failed at update user login with error',
  SAVE_CREATE_USER_FAILED= 'create user failed at save phone with error',
  SAVE_CREATE_LINKED_FAILED = 'create user failed at linked customer，create customer failed with error',
  EMAIL_IS_USER = 'is already used',
  CREATE_EMAIL_FAILED = 'create email for user failed with error',
  UPDATE_VERIFY_CODE_FAILED = 'update verify code status failed with error',
  CREATE_PRESENTER_FAILED = 'create user presenter failed',
  REGISTERED_MOBILE = 'this mobile phone number has been registered to an account, please try to login or reset password if you forget your password',
}


export default RegisterMessage;
