import { takeEvery, put, select, call, all } from 'redux-saga/effects';
import axios from 'axios';
import Config from '@/Config';
import Constants from '@/constants';
import paths from '@/routes/paths';
import { choiceStoreID, getDomain, createRouteUrl, setPlatformPayInfo, IPayInfoParams } from '@/utils';
import Action from '@/actions';
import ActionType from '@/actions/action-type';
import { IReducersState } from '@/reducers';
import { IOrderState } from '@/reducers/order-reducer';
import { IOrderInfo, ITaxOption, IOrderEntityJson } from '@/lib/order-builder/models/OrderEntity';
import SaleType from '@/lib/order-builder/enums/SaleType';
import { convertOrderApiData, parseTaxOtionsResp, parseRoundingOtionsResp } from '@/lib/order-builder/utils/api';
import { IStore, IStoreConfig } from '@/actions/store-action';
import { IShoppingCartData } from '@/actions/shopping-cart-action';
import { ICustomer, IUserInfo } from '@/actions/user-action';
import { IObject } from '@/components/bm-common';
import cloneDeep from 'lodash/cloneDeep';
import {
  IQueryOrderInfo,
  IQueryOrders,
  ICreateOrder,
  ISignInAndCreateOrder,
  ICreateOrderTag,
  ICreateOrderCallbackParams,
  IGuestSignUpAndCreateOrder,
  ICreateEmptyOrder,
  IQueryStoreTaxOptions,
  IQueryStoreRoundingOptions,
} from '@/actions/order-action';
import { handleQueryStore, handleQueryStoreByID } from './store-saga';
import {
  handleQueryCustomer,
  handleSignIn,
  handleSignUpForGuest,
  handlePushNotification,
} from './user-saga';
import dayjs from 'dayjs';
import OrderBuilder from '@/lib/order-builder';
import OrderEntity from '@/lib/order-builder/models/OrderEntity';
import { handleQueryStoresListings } from './listing-saga';
import { orderEntityToShoppingCartData } from '@/lib/order-builder/utils';
import { ILineItemJson } from '@/lib/order-builder/models/LineItemEntity';
import { IPayMethod } from '@/lib/payment';
import { IPayCard, IGiftCard } from '@/actions/pay-action';
import PayTypes from '@/lib/payment/PayTypes';
import { handleQueryGiftCards, handleQueryPayCards, handleQueryGooglePayMerchantInfo } from './pay-saga';
import { getStoreRounding } from '@/data/StoreSetting';
import { getAccessToken } from '@/utils/app';

function* handleQueryOrderInfo(params: IQueryOrderInfo) {
  const { refresh = false, orderNumber, fromUrlPayData, storeSlug, storeID, callback } = params;
  let orderInfo: IOrderInfo | undefined = undefined;
  let store: IStore | undefined;
  let storeConfig: IStoreConfig | undefined = undefined;
  let queryStoreResult;
  let merchantInfo = {
    tag: '',
    value: '',
  }
  try {
    let orderData: any;

    const state: IReducersState = yield select((state: IReducersState) => state);
    const { gatewayAppSettings, platformAppSettings, deviceInfo, platformConfig } = state.app;
    const { payMethodKeys } = platformAppSettings || {};
    if (storeID) {
      queryStoreResult = yield handleQueryStoreByID({
        type: ActionType.QUERY_STORE_BY_ID,
        storeID: storeID,
      });
    } else {
      queryStoreResult = yield handleQueryStore({
        type: ActionType.QUERY_STORE,
        storeSlug,
      });
    }


    store = queryStoreResult.store;
    storeConfig = queryStoreResult.storeConfig;

    if (!refresh && state.order.orderNumberRefOrder[orderNumber]) {
      orderData = state.order.orderNumberRefOrder[orderNumber];
    } else {
      let orderRes: any;
      if (store) {
        const parseStoreID = storeID ? storeID : store.id;

        orderRes = yield call(
          axios.get,
          Config.urls.getInvoices.replace('{storeID}', parseStoreID).replace('{orderNumber}', orderNumber),
        );
      }

      if (orderRes && orderRes.success === true && orderRes.invoice) {
        orderData = orderRes.invoice;
        yield put<Action>({
          type: ActionType.SET_ORDER,
          orderNumber,
          order: orderData,
        });
      }
    }

    if (orderData) {
      const storeIDs = OrderBuilder.getStoreIDsByOrderApiData(orderData);
      if (Array.isArray(storeIDs) && storeIDs.length > 0) {
        yield handleQueryStoresListings({
          type: ActionType.QUERY_STORES_LISTINGS,
          storeIDs,
        });
      }
      orderInfo = convertOrderApiData(orderData);
    }

    let payMethods: IPayMethod[] = [];
    if (orderInfo && state.app.gatewayAppSettings && state.app.platformAppSettings && fromUrlPayData) {
      yield put<Action>({
        type: ActionType.SET_FROM_URL_PAY_DATA,
      });
      payMethods = yield put<Action>({
        type: ActionType.PARSE_FROM_URL_PAY_DATA,
        fromUrlPayData,
        currencyCode: orderInfo.currencyCode,
        gatewayAppSettings: state.app.gatewayAppSettings,
        platformAppSettings: state.app.platformAppSettings,
      });
    }
    

    if (storeConfig && orderInfo && payMethods.length < 1 && gatewayAppSettings && platformAppSettings && payMethodKeys) {
      const currentPayMethodKeys = storeConfig.payMethodKeys.filter(key => payMethodKeys.includes(key));
      const isCreates = currentPayMethodKeys.filter(key => ![PayTypes.VISA, PayTypes.MASTER, PayTypes.AMEX, PayTypes.ANONYMOUS_GIFT_CARD, PayTypes.STORE_CREDIT].includes(key));

      
      let onlyOnePayMethodKey: PayTypes | undefined = undefined;
      let onlyOnepaPMethod: IPayMethod | undefined = undefined;
      let payCards: IPayCard[] | undefined = undefined;
      let giftCards: IGiftCard[] | undefined = undefined;
      let creditCard: PayTypes[]| undefined = [];
      let credGiftCards: PayTypes[]| undefined = [];


      if (!Array.isArray(payCards)) {
        payCards = yield handleQueryPayCards({
          type: ActionType.QUERY_PAY_CARDS,
        });
      }

      const queryCustomerResult: any = yield handleQueryCustomer({
        type: ActionType.QUERY_CUSTOMER,
        storeSlug,
        closeRegisterEmail: platformConfig.closeRegisterEmail,
      })
      if (queryCustomerResult && queryCustomerResult.customer && storeID) {
        const customer: ICustomer = queryCustomerResult.customer;
        if (deviceInfo.isNativeApp) {
          yield handlePushNotification({
            type: ActionType.PUSH_NOTIFICATION,
            customerID: customer.id,
          });
        } 
        const queryGiftCards =  yield handleQueryGiftCards({
          type: ActionType.QUERY_GIFT_CARDS,
          storeSlug,
          storeID: storeID,
          customerID: customer.id,
        });
        if (Array.isArray(queryGiftCards)) {
          giftCards = queryGiftCards
        }
      } 
      if (payCards && payCards.length >0) {
        const creditCards = currentPayMethodKeys.filter(key => [PayTypes.VISA, PayTypes.MASTER, PayTypes.AMEX, PayTypes.UNION_PAY].includes(key));
        payCards.forEach(item => {
          creditCard = creditCards.filter(key => item.type === key );
        })
      }
      if (creditCard && creditCard.length === 0 ) {
        payCards = [];
      }

      if (giftCards && giftCards.length >0) {
        const creditCards = currentPayMethodKeys.filter(key => [PayTypes.ANONYMOUS_GIFT_CARD, PayTypes.STORE_CREDIT].includes(key));
        giftCards.forEach(item => {
          credGiftCards = creditCards.filter(key => item.type === key );
        })
      }
      if (credGiftCards && credGiftCards.length === 0 ) {
        credGiftCards = [];
      }

      if (currentPayMethodKeys.length === 1 || (isCreates.length === 1 && payCards && payCards.length === 0 && giftCards && giftCards.length === 0)){
        
        if (currentPayMethodKeys.length === 1) {
          onlyOnePayMethodKey = currentPayMethodKeys[0];
        } else if (isCreates.length ===1){
          onlyOnePayMethodKey = isCreates[0];
        }
        if (onlyOnePayMethodKey) {
          if ([PayTypes.ALIPAY, PayTypes.ALIPAY_HK, PayTypes.TAP_GO].includes(onlyOnePayMethodKey)) {
            onlyOnepaPMethod = {
              id: onlyOnePayMethodKey,
              currencyCode: orderInfo.currencyCode,
              type: onlyOnePayMethodKey as any,
              returnUrl: '',
              amount: orderInfo.initialTotal,
            }
          }else if (PayTypes.APPLE_PAY === onlyOnePayMethodKey) {
            onlyOnepaPMethod = {
              id: onlyOnePayMethodKey,
              type: PayTypes.APPLE_PAY,
              countryCode: 'HK',
              currencyCode: orderInfo.currencyCode,
              supportedNetworks: gatewayAppSettings.applePayMethodKeys,
              appMerchantId: deviceInfo.isAppleApp && platformAppSettings.merchantID ? platformAppSettings.merchantID : gatewayAppSettings.merchantID,
              appMerchantDomain: gatewayAppSettings.domain,
              label: platformAppSettings.name,
              amount: orderInfo.initialTotal,
            }
          }else if ([PayTypes.ANONYMOUS_GIFT_CARD, PayTypes.STORE_CREDIT].includes(onlyOnePayMethodKey) && store) {
            if (giftCards && giftCards.length > 0) {
              const giftCard = giftCards[0];
              onlyOnepaPMethod = {
                id: giftCard.id,
                currencyCode: orderInfo.currencyCode,
                type: PayTypes.ANONYMOUS_GIFT_CARD,
                amount: orderInfo.initialTotal,
                printedPin: giftCard.pin,
                number: orderInfo.orderNumber,
                balance: giftCard.balance,
              }
            }
          }else if ([PayTypes.VISA, PayTypes.MASTER, PayTypes.AMEX, PayTypes.UNION_PAY].includes(onlyOnePayMethodKey)) {
            if (Array.isArray(payCards)) {
              const payCard = payCards[0];
              onlyOnepaPMethod = {
                amount: orderInfo.initialTotal,
                currencyCode: orderInfo.currencyCode,
                cvv: '',
                expMonth: payCard.expMonth,
                expYear: payCard.expYear,
                holderName: payCard.holder,
                id: payCard.id,
                last4Digits: payCard.last4Digits,
                token: payCard.token,
                type: PayTypes.CREDIT_CARD,
                creditCardType: payCard.type || '',
              };
            }
          }
        }
      }

      if(isCreates.length === 0) {
        const creates = currentPayMethodKeys.filter(key => ![PayTypes.VISA, PayTypes.MASTER, PayTypes.AMEX, PayTypes.UNION_PAY].includes(key));

        if (creates.length !== 0) {
          if (giftCards && giftCards.length > 0) {
            const giftCard = giftCards[0];
            onlyOnepaPMethod = {
              id: giftCard.id,
              currencyCode: orderInfo.currencyCode,
              type: PayTypes.ANONYMOUS_GIFT_CARD,
              amount: orderInfo.initialTotal,
              printedPin: giftCard.pin,
              number: orderInfo.orderNumber,
              balance: giftCard.balance,
            }
          }
        } else {
          if (Array.isArray(payCards) && payCards.length === 1) {
            const payCard = payCards[0];
            onlyOnepaPMethod = {
              amount: orderInfo.initialTotal,
              currencyCode: orderInfo.currencyCode,
              cvv: '',
              expMonth: payCard.expMonth,
              expYear: payCard.expYear,
              holderName: payCard.holder,
              id: payCard.id,
              last4Digits: payCard.last4Digits,
              token: payCard.token,
              type: PayTypes.CREDIT_CARD,
              creditCardType: payCard.type || '',
            };
          }
        }
      }
      if (onlyOnepaPMethod) {
        yield put<Action>({
          type: ActionType.MODIFY_PAY_METHODS,
          payMethods: [onlyOnepaPMethod],
        });
      }

    }
    merchantInfo = yield handleQueryGooglePayMerchantInfo()
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(orderInfo, store, storeConfig, merchantInfo);
  }
}


function* handleQueryOrders(params: IQueryOrders) {
  const { refresh = false, pageSize, status, callback } = params;
  const page = params.page || 1;
  let orders: IOrderInfo[] = [];
  let isReqData = false;
  try {
    let orderData: IObject[] = [];

    const orderState: IOrderState = yield select((state: IReducersState) => state.order);
    if (refresh) {
      yield put<Action>({
        type: ActionType.CLEAR_ORDER_INFOS,
      });

      isReqData = true;
    } else {
      const tempOrders = orderState.statusRefOrderInfos[status] || [];
      const start = (page - 1) * pageSize;
      const end = page * pageSize;
      let size: number = tempOrders.length;
      if ( tempOrders.length > 0 ) {
        size = tempOrders.length - 2;
      }
      if (size > start) {
        orders = tempOrders.slice(start, end);
      } else {
        isReqData = true;
      }
    }

    if (isReqData) {
      const resp = yield call(
        axios.get,
        `${Config.urls.getOrders}?page=${page}&per_page=${pageSize}&status=${status}&with_details=true`,
      );
      if (resp && resp.success && Array.isArray(resp.orders)) {
        orderData = resp.orders;
      }
    }

    const numberRefOrders: {
      orderNumber: string;
      order: IObject;
    }[] = [];

    orderData.forEach((item: any) => {
      if (item.number && isReqData) {
        numberRefOrders.push({
          orderNumber: item.number,
          order: item,
        })
        const order = convertOrderApiData(item);
        if (order) {
          orders.push(order);
        }
      }
    });

    if (numberRefOrders.length > 0) {
      yield put<Action>({
        type: ActionType.BATCH_SET_ORDERS,
        data: numberRefOrders,
      });
    }


    if (isReqData && orders.length > 0) {
      yield put<Action>({
        type: ActionType.PUSH_ORDER_INFOS,
        status,
        orderInfos: orders,
      });
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(orders);
  }
}

function* handleCreateOrder(params: ICreateOrder) {
  const {
    storeSlug,
    callback,
    query,
    isSplitOrder = false,
    stopRender = false,
    forExpressOrder = false,
  } = params;

  const state: IReducersState = yield select((state: IReducersState) => state);
  const storeIDRefShoppingCartData = forExpressOrder ? state.cart.storeIDRefExpressOrderData : state.cart.storeIDRefShoppingCartData;

  let success = false;
  let orderConfirmUrl = '';
  let orderNumber = '';
  let newStoreID = '';
  let subtotal = '';
  let token = '';
  let orderInfo;
  let message = '';
  let realStoreSlug = '';

  try {
    let shoppingCartData: IShoppingCartData | undefined = undefined;
    let customer: ICustomer | undefined = undefined;
    let mergeOrder = false;
    // 如果是堂食 即 shippingMethod === 2 不合并订单
    const shoppingCartDatas: IShoppingCartData[] = Object.values(storeIDRefShoppingCartData).filter(item => item.orderData && item.orderData.line_items.length > 0);
    if ((shoppingCartDatas.length > 1 && !isSplitOrder) || forExpressOrder) {
      Object.values(storeIDRefShoppingCartData).forEach(item => {
        if (item.orderInfo.shippingMethod !== 2) {
          mergeOrder = true;
        }
      });
    }

    if (mergeOrder) {
      const { platformConfig, platformAppSettings } = state.app;
      if (platformConfig && platformConfig.storeID) {
        // 合并订单
        const orderEntity = new OrderEntity(platformConfig.storeID, true);

        const { store: platformStore } = yield handleQueryStoreByID({
          type: ActionType.QUERY_STORE_BY_ID,
          storeID: platformConfig.storeID,
        })

        if (platformStore) {
          // orderEntity.setTaxOptions(taxOptions);
          orderEntity.setCurrencyCode(platformStore.currencyCode);
          realStoreSlug = platformStore.slug;
        }

        shoppingCartDatas.forEach(item => {
          orderEntity.insertOrderEntitiesForSuperOrder((cloneDeep(item.orderInfo.orderEntities)));
        });
        orderEntity.checkLineItemsByShippingMethod();
        orderEntity.calculate();
        if (platformAppSettings && platformAppSettings.suppliers) {
          orderEntity.setSaleType(SaleType.PURCHASE_REQUEST);
        }

        newStoreID = platformConfig.storeID;

        shoppingCartData = orderEntityToShoppingCartData(orderEntity);
      }
    } else {
      const queryStoreResult = yield handleQueryStore({
        type: ActionType.QUERY_STORE,
        storeSlug,
      });

      const store: IStore | undefined = queryStoreResult.store;

      realStoreSlug = storeSlug;
      if (shoppingCartDatas.length === 1) {
        shoppingCartData = shoppingCartDatas[0];
        newStoreID = shoppingCartDatas[0].orderData.store_id;
        const { store: newStore } = yield handleQueryStoreByID({
          type: ActionType.QUERY_STORE_BY_ID,
          storeID: newStoreID,
        });
        if (newStore) {
          realStoreSlug = newStore.slug;
        }
      } else if (store) {
        const choiceResult = choiceStoreID(store.id, store.groupParentID);
        newStoreID = choiceResult.storeID;
        if (storeIDRefShoppingCartData[newStoreID]) {
          shoppingCartData = storeIDRefShoppingCartData[newStoreID];
        }
      }
    }
    const { platformConfig, platformAppSettings } = state.app;
    if (shoppingCartData && getAccessToken()) {
      const queryCustomerResult: any = yield handleQueryCustomer({
        type: ActionType.QUERY_CUSTOMER,
        storeSlug: realStoreSlug,
        closeRegisterEmail: platformConfig.closeRegisterEmail,
      });

      customer = queryCustomerResult.customer;

      if (queryCustomerResult.status === 401) {
        yield put<Action>({
          type: ActionType.CLEAR_USER_INFO,
        });
      }
    }
    const { slugRefStore } = state.store;
    if (shoppingCartData && customer) {
      const orderData = shoppingCartData.orderData;
      let newLineItem: ILineItemJson[] = [];
      const newOrderData = cloneDeep(orderData);
      if (mergeOrder && newOrderData.sub_orders && newOrderData.sub_orders.length > 0) {
        const arr: IOrderEntityJson[] = [];
        const customerList: any = [];
        if (forExpressOrder) {
          newOrderData.sub_orders.forEach(item => {
            newLineItem = item.line_items.filter(item => item.quantity !== 0); // 过滤掉数量为0的item及line_items
            item['line_items'] = newLineItem;
            if (newLineItem.length !== 0) {
              arr.push(item);
              newOrderData['sub_orders'] = arr;
            }
          })
        }
        newOrderData.sub_orders.forEach(item => {
          Object.keys(slugRefStore).forEach(key => {
            if (slugRefStore[key].id === item.store_id) {
              customerList.push(
                handleQueryCustomer({
                  type: ActionType.QUERY_CUSTOMER,
                  storeSlug: slugRefStore[key].slug,
                  closeRegisterEmail: platformConfig ? platformConfig.closeRegisterEmail : false,
                  suppliers: platformAppSettings ? platformAppSettings.suppliers : false,
                })
              )
            }
          })
        })
        
        let customerListResps: any = [];
        if (customerList.length > 0) {
          customerListResps = yield all(customerList);
        }
        newOrderData.sub_orders.forEach(item => {
          customerListResps.forEach((cItem: any) => {
            if (String(item.store_id) === String(cItem.customer.storeID)) {
              item['customer_id']= cItem.customer.id;
            }
          })
        })
      }
      if (forExpressOrder) {
        newLineItem = orderData.line_items.filter(item => item.quantity !== 0);
        newOrderData['line_items'] = newLineItem;
      }
      const res = yield call(
        axios.post,
        Config.urls.invoices.replace('{storeID}', newStoreID),
        {
          'failed_interval': 30,
          order: {
            ...newOrderData,
            'customer_id': customer.id,
            'platform_id': platformConfig.platformID,
          }
        },
        {
          headers: {
            'X-I18N-LANG': state.app.language,
          }
        }
      );
      if (res && res.success && res.invoice && res.invoice.number) {
        token = getAccessToken() || '';
        success = true;
        const {
          platform,
          gatewayDomain,
          domain,
          isLocalhost,
          platformHost,
        } = getDomain();
        let gDomain = platform ? domain : gatewayDomain;
        if (isLocalhost) {
          gDomain = platformHost;
        }
        orderNumber = res.invoice.number;
        subtotal = res.invoice.subtotal;
        orderInfo = convertOrderApiData(res.invoice);
        let buyerStoreID = undefined;
        if (platformAppSettings && platformAppSettings.suppliers) {
          buyerStoreID = localStorage.getItem(Constants.SUPPLIERS_SELECT_STORE_ID) || undefined;
        }

        const payInfo: IPayInfoParams = {
          token,
          domain: state.app.platformDomain,
          lang: state.app.language,
          storeID: newStoreID,
          storeSlug: realStoreSlug,
          paymentData: query ? query.paymentData : {},
          user: query ? query.user : null,
          routeStoreSlug: realStoreSlug, //下单时路由上的store slug
          isSplitOrder: isSplitOrder,
          buyerStoreID: buyerStoreID,
          mode: localStorage.getItem(Constants.DEDICATED_QUERY) || undefined,
        }

        const payInfoString = setPlatformPayInfo(payInfo);

        const payInfoUrl = createRouteUrl(
          paths.ORDER_CONFIRM,
          {
            orderNumber: res.invoice.number,
            storeSlug,
            [Constants.PLATFORM]: platform,
            payInfo: payInfoString,
          }
        );

        orderConfirmUrl = `http${isLocalhost ? '' : 's'}://${gDomain}` + payInfoUrl;

        if (isSplitOrder) { // 只能部分删除购物车数据
          yield put<Action>({
            type: ActionType.CLEAR_SHOPPING_CART,
            isSplitOrder: true,
            storeID: newStoreID,
            stopRender,
          });
        } else {
          yield put<Action>({
            type: ActionType.CLEAR_SHOPPING_CART,
            stopRender,
          });
        };
      } else if (res && res.message) {
        message = res.message;
      } else if (res && res.data.message) {
        message = res.data.message;
      }
    }

  } catch (error) {
    console.error(error);
  }

  let orderTime = new Date().getTime();
  if (orderInfo) {
    orderTime = dayjs(orderInfo.orderTime).valueOf();
  }

  const result: ICreateOrderCallbackParams = {
    success,
    storeSlug,
    storeID: newStoreID,
    orderNumber,
    orderConfirmUrl,
    subtotal,
    orderInfo,
    orderTime,
    token,
    message,
  };

  if (callback) {
    callback(result);
  }

  return result;
}

function* handleSignInAndCreateOrder(params: ISignInAndCreateOrder) {
  const { storeSlug, dialCode, phoneNumber, password, callback, isSplitOrder } = params;

  let tag: ICreateOrderTag = 'SIGN_IN_FAILED';
  let userInfo: IUserInfo | undefined = undefined;

  let createOrderResult: ICreateOrderCallbackParams = {
    success: false,
    orderConfirmUrl: '',
    orderNumber: '',
    storeSlug,
    storeID: '',
    token: '',
    orderTime: 0,
    subtotal: '',
    message: '',
  }

  try {
    userInfo = yield handleSignIn({
      type: ActionType.SIGN_IN,
      username: `+${dialCode} ${phoneNumber}`,
      password,
    });

    if (userInfo) {
      createOrderResult = yield handleCreateOrder({
        type: ActionType.CREATE_ORDER,
        storeSlug,
        isSplitOrder,
      });

      if (createOrderResult && createOrderResult.success) {
        tag = 'SUCCESS';
      } else {
        tag = 'FAILED_TO_CREATE_ORDER';
      }
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback({
      ...createOrderResult,
      tag,
    })
  }

  return {
    ...createOrderResult,
    tag,
  };
}

function* handleGuestSignUpAndCreateOrder(params: IGuestSignUpAndCreateOrder) {
  const { storeSlug, phoneNumber, dialCode, paymentData, callback, isSplitOrder } = params;

  let tag: ICreateOrderTag = 'SIGN_IN_FAILED';
  let userInfo: IUserInfo | undefined = undefined;

  const state: IReducersState = yield select((state: IReducersState) => state);
  if (state.user.isGuest && state.user.userInfo) {
    userInfo = state.user.userInfo;
  }

  let createOrderResult: ICreateOrderCallbackParams = {
    success: false,
    orderConfirmUrl: '',
    orderNumber: '',
    storeSlug,
    storeID: '',
    token: '',
    orderTime: 0,
    subtotal: '',
    message: ''
  }

  try {

    if (!userInfo) {
      const signUpResult = yield handleSignUpForGuest({
        type: ActionType.SIGN_UP_FOR_GUEST,
        phoneNumber,
        dialCode,
      });

      if (signUpResult && signUpResult.success) {
        userInfo = yield handleSignIn({
          type: ActionType.SIGN_IN,
          username: signUpResult.userEmail,
          password: signUpResult.password,
          loginMethod: 'guest',
          grantType: signUpResult.password,
        });
      }
    }

    if (userInfo) {
      createOrderResult = yield handleCreateOrder({
        type: ActionType.CREATE_ORDER,
        storeSlug,
        isSplitOrder,
        query: {
          user: 'guest',
          paymentData: paymentData,
        }
      });

      if (createOrderResult && createOrderResult.success) {
        tag = 'SUCCESS';
      } else {
        tag = 'FAILED_TO_CREATE_ORDER';
      }
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback({
      ...createOrderResult,
      tag,
    });
  }
}

export function* handleQueryStoreTaxOptions(params: IQueryStoreTaxOptions) {
  const { storeSlug, callback } = params;

  let taxOptions: ITaxOption[] = [];

  const state: IReducersState = yield select((state: IReducersState) => state);
  const { slugRefTaxOptions } = state.order;

  try {

    if (slugRefTaxOptions[storeSlug]) {
      taxOptions = slugRefTaxOptions[storeSlug];
    } else {
      const queryStoreResult = yield handleQueryStore({
        type: ActionType.QUERY_STORE,
        notRequestStoreConfig: true,
        storeSlug,
      });

      const store: IStore | undefined = queryStoreResult.store;

      if (store) {
        const url = Config.urls.taxOptions.replace('{storeID}', store.id);
        const resp = yield call(axios.get, url);
        taxOptions = parseTaxOtionsResp(resp);
      }

      yield put<Action>({
        type: ActionType.SET_STORE_TAX_OPTIONS,
        storeSlug,
        taxOptions,
      });
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(taxOptions);
  }

  return taxOptions;
}

export function* handleQueryStoreRoundingOptions(params: IQueryStoreRoundingOptions) {
  const { storeSlug, callback } = params;

  let roundingOptions: IObject | undefined;

  const state: IReducersState = yield select((state: IReducersState) => state);
  const { slugRefRoundingOptions } = state.order;

  try {

    if (slugRefRoundingOptions[storeSlug]) {
      roundingOptions = slugRefRoundingOptions[storeSlug] || undefined;
    } else {
      const queryStoreResult = yield handleQueryStore({
        type: ActionType.QUERY_STORE,
        notRequestStoreConfig: true,
        storeSlug,
      });

      const store: IStore | undefined = queryStoreResult.store;

      if (store) {
        const result = yield getStoreRounding(storeSlug);
        if (result) {
          roundingOptions = parseRoundingOtionsResp(result);
        }
      }

      yield put<Action>({
        type: ActionType.SET_STORE_ROUNDING_OPTIONS,
        storeSlug,
        roundingOptions,
      });
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(roundingOptions);
  }

  return roundingOptions;
}

function* handleCreateEmptyOrder (params: ICreateEmptyOrder) {
  const {
    storeSlug,
    amount,
    callback,
  } = params;

  let success = false;
  const orderConfirmUrl = '';
  let orderNumber = '';
  let newStoreID = '';
  let token = '';
  let orderInfo;
  let message = '';
  let subtotal = '';

  const state: IReducersState = yield select((state: IReducersState) => state);

  try {
    const queryStoreResult = yield handleQueryStore({
      type: ActionType.QUERY_STORE,
      storeSlug,
    });

    const store: IStore | undefined = queryStoreResult.store;
    let customer: ICustomer | undefined = undefined;

    if (store) {
      const choiceResult = choiceStoreID(store.id, store.groupParentID);
      newStoreID = choiceResult.storeID;

      if (getAccessToken()) {
        const { platformConfig } = state.app;
        const queryCustomerResult: any = yield handleQueryCustomer({
          type: ActionType.QUERY_CUSTOMER,
          storeSlug,
          closeRegisterEmail: platformConfig.closeRegisterEmail,
        });

        customer = queryCustomerResult.customer;

        if (queryCustomerResult.status === 401) {
          yield put<Action>({
            type: ActionType.CLEAR_USER_INFO,
          });
        }
      }

      if (customer) {
        const data = new OrderEntity(store.id)
        data.setSubtotal(amount);
        data.setInitialTotal(amount);

        const res = yield call(
          axios.post,
          Config.urls.invoices.replace('{storeID}', newStoreID),
          {
            'failed_interval': 30,
            order: {
              'platform_id': state.app.platformConfig.platformID || '',
              'customer_id': customer.id,
              ...data.toJson(),
            }
          }
        );

        if (res && res.success && res.invoice && res.invoice.number) {
          token = getAccessToken() || '';
          success = true;
          orderNumber = res.invoice.number;
          subtotal = res.invoice.subtotal;
          orderInfo = convertOrderApiData(res.invoice);

          yield put<Action>({
            type: ActionType.CLEAR_STORES_SHOPPING_CART,
            storeIDs: [newStoreID],
          });
        } else if (res && res.message) {
          message = res.message;
        }
      }
    }
  } catch (error) {
    console.error(error);
  }

  let orderTime = new Date().getTime();
  if (orderInfo) {
    orderTime = dayjs(orderInfo.orderTime).valueOf();
  }

  const result: ICreateOrderCallbackParams = {
    success,
    storeSlug,
    storeID: newStoreID,
    orderNumber,
    orderConfirmUrl,
    subtotal,
    orderInfo,
    orderTime,
    token,
    message,
  };

  if (callback) {
    callback(orderInfo, result);
  }

  return result;
}

function* orderSaga() {
  yield takeEvery(ActionType.QUERY_ORDER_INFO, handleQueryOrderInfo);
  yield takeEvery(ActionType.QUERY_ORDERS, handleQueryOrders);
  yield takeEvery(ActionType.CREATE_ORDER, handleCreateOrder);
  yield takeEvery(ActionType.SIGN_IN_AND_CREATE_ORDER, handleSignInAndCreateOrder);
  yield takeEvery(ActionType.GUEST_SIGN_UP_AND_CREATE_ORDER, handleGuestSignUpAndCreateOrder);
  yield takeEvery(ActionType.CREATE_EMPTY_ORDER, handleCreateEmptyOrder);
  yield takeEvery(ActionType.QUERY_STORE_TAX_OPTIONS, handleQueryStoreTaxOptions);
  yield takeEvery(ActionType.QUERY_STORE_ROUNDING_OPTIONS, handleQueryStoreRoundingOptions);
}

export default orderSaga();
