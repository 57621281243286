import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import { animateScroll } from 'react-scroll';
import { setScreenColor, getAppTheme, getIntl, getIntlByPath } from '@/components/App/App';
import Icon, { IconType } from '@/components/Icon';
import ModifierListing from './NewModifierListing';
import ModifierListView from './NewModifierListView';
import ActionType from '@/actions/action-type';
import { IModifier, IListing } from '@/lib/order-builder/utils/api';
import paths from '@/routes/paths';
import { setCanAutoProcurementMethodPopup } from '../MenuListPage/MenuListPage';
import { getCurrentShippingMethod } from '@/utils/app';
import { IStoreConfig, IStorePickupLocation } from '@/actions/store-action';
import { IShoppingCartData } from '@/actions/shopping-cart-action';
import Button from 'antd-mobile/es/button';
import Modal from 'antd-mobile/es/modal';
import dayjs from 'dayjs';
import ShippingMethod from '@/lib/order-builder/enums/ShippingMethod';
import { ModifierPageStyled } from './styled';
import ModifierEntity from '@/lib/order-builder/models/ModifierEntity';

export interface IModifierPageProps extends IBasePageProps { 
  listingID?: string;
  listingUuids?: string[];
  onClose?: () => void;
  storeSlug?: string;
  modifierEntries?: ModifierEntity[];
 }

export interface IModifierPageState {
  modifiers: IModifier[];
  listing: IListing | IModifier | undefined;
  countRef: { [modifierID: string]: number };
  selected: string;
  storeConfig?: IStoreConfig;
  shoppingCartData?: IShoppingCartData;
  quantity: number;
  storeSlug: string;
  modifierIDs: string[];
  modifierIDsAll: string[];
  newModifierIDstr: string[];
  loadingData: boolean;
}

class ModifierPage extends BasePage<IModifierPageProps, IModifierPageState> {

  state: IModifierPageState = {
    modifiers: [],
    listing: undefined,
    countRef: {},
    selected: '',
    quantity: 1,
    storeSlug: '',
    modifierIDs: [],
    modifierIDsAll: [],
    newModifierIDstr: [],
    loadingData: true,
  }

  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.lightBackgroundColor) {
      setScreenColor(appTheme.lightBackgroundColor);
    }

    super.componentDidMount();

    // const routeParams = parseRouteParams(this.props);
    const { routeParams, dispatch, listingID, storeSlug, modifierEntries = [] } = this.props;
    const { modifierIDs } = routeParams;
    const propsModifierIDs: string[] = [];
    const modifierCountRef: {
      [path: string]: {
        [modifierID: string]: number;
      };
    } = {};
    this.setState({
      storeSlug: routeParams.storeSlug || storeSlug || '',
    });

    modifierEntries.forEach(modifierEntry => {
      propsModifierIDs.push(modifierEntry.optionID);
      const { modifierEntries: itemModifierEntries } = modifierEntry;
      if (itemModifierEntries.length > 0) {
        const countRef: {[modifierID: string]: number} = {};
        itemModifierEntries.forEach((item => {
          countRef[item.optionID] = item.quantity;
        }));
        modifierCountRef[modifierEntry.optionID] = countRef;
      }
    })
    
    if (Object.keys(modifierCountRef).length > 0) {
      Object.keys(modifierCountRef).forEach(key => {
        const value = modifierCountRef[key];
        dispatch({
          type: ActionType.SET_PRE_ADD_MODIFIERS,
          listingID: routeParams.listingID || listingID || '',
          path: key,
          modifierCountRef: value,
        });
      })
    }
    
    // const modifierIDsStr: string | undefined = routeParams.modifierIDs || undefined;
    let modifierID: string | undefined = undefined;

    // let modifierIDs: string[] = [];
    // if (modifierIDsStr && modifierIDsStr.length > 0) {
    //   modifierIDs = modifierIDsStr.split('-');
    // }
    if (modifierIDs.length > 0) {
      modifierID = modifierIDs[modifierIDs.length - 1];
    }
    this.openLoading();
    dispatch({
      type: ActionType.QUERY_MODIFIERS,
      storeSlug: routeParams.storeSlug || storeSlug || '',
      listingID: routeParams.listingID || listingID || '',
      modifierID,
      callback: (modifiers, listing) => {
        const { countRef } = this.state;
        modifiers.forEach(data => {
          data.children.forEach(item => {
            if (propsModifierIDs.length  > 0) {
              if (propsModifierIDs.includes(item.id)) {
                const currentmModifierEntries = modifierEntries.filter(modifierEntry => `${modifierEntry.optionID}` === `${item.id}`);
                if (currentmModifierEntries.length > 0) {
                  let quantity = 0;
                  currentmModifierEntries.forEach(item => quantity += item.quantity);
                  countRef[item.id] = quantity;
                }
              }
            } else {
              if (item.default) {
                countRef[item.id] = 1;
              }
            }
          })
        })
        this.setState({ modifiers, listing, countRef, loadingData: false });
        this.closeLoading();
      }
    });

    dispatch({
      type: ActionType.GET_STORE_SHOPPING_CART_DATA,
      storeSlug: routeParams.storeSlug || storeSlug || '',
      callback: params => {
        this.setState({ shoppingCartData: params.shoppingCartData, storeConfig: params.storeConfig });
      },
    });
  }

  handleModifierBack = (data: string [], newModifierIDstr: string[]) => {
    const { listingID, dispatch, routeParams } = this.props;
    const { storeSlug } = this.state;
    let modifierID: string | undefined = undefined;
    if (data.length > 0) {
      modifierID = data[data.length - 1];
    } else if (data.length === 0) {
      this.setState({modifierIDs: []});
    }
    this.openLoading();
    
    dispatch({
      type: ActionType.QUERY_MODIFIERS,
      storeSlug: routeParams.storeSlug || storeSlug || '',
      listingID: routeParams.listingID || listingID || '',
      modifierID,
      callback: (modifiers, listing) => {
        this.setState({ modifiers, listing });
        this.closeLoading();
      }
    });
    dispatch({
      type: ActionType.GET_PRE_ADD_MODIFIERS_COUNT,
      listingID: routeParams.listingID || listingID || '',
      path: newModifierIDstr[newModifierIDstr.length-1] || '',
      callback: countRef => {
        this.setState({ countRef });
      }
    });
    dispatch({
      type: ActionType.GET_STORE_SHOPPING_CART_DATA,
      storeSlug: routeParams.storeSlug || storeSlug || '',
      callback: params => {
        this.setState({ shoppingCartData: params.shoppingCartData, storeConfig: params.storeConfig });
      },
    });
  }
  goBack = () => {
    const { modifierIDsAll, newModifierIDstr } = this.state;
    setCanAutoProcurementMethodPopup(false);
    modifierIDsAll.pop();
    newModifierIDstr.pop();
    this.setState({ modifierIDsAll: modifierIDsAll, newModifierIDstr: newModifierIDstr});
    this.handleModifierBack(modifierIDsAll, newModifierIDstr);
  }

  handleLeftClick = () => {
    // const routeParams = parseRouteParams(this.props);
    const { routeParams, dispatch } = this.props;

    // const modifierIDsStr: string | undefined = routeParams.modifierIDs || undefined;
    // let modifierIDs: string[] = [];
    // if (modifierIDsStr && modifierIDsStr.length > 0) {
    //   modifierIDs = modifierIDsStr.split('-');
    // }

    if (routeParams.modifierIDs.length === 0) {
      dispatch({
        type: ActionType.CLEAR_PRE_ADD_MODIFIERS,
      });
    }

    this.goBack();
  }

  handleClick = (data: IModifier) => {
    const modifierCon = document.getElementById(`bm-modifier-panel-${data.id}`);
    const modifierHeader = document.getElementById(`bm-modifier-header-${data.id}`);
    if (modifierCon && modifierHeader) {
      if (modifierCon.style.display === 'none') {
        modifierCon.style.display = 'block';
        modifierHeader.getElementsByClassName('bm-icon')[0].classList.remove('select-icon-hide');
      } else {
        modifierCon.style.display = 'none';
        modifierHeader.getElementsByClassName('bm-icon')[0].classList.add('select-icon-hide');
      }
    }
  }

  // handleModifierData = () => {

  // }
  handleModifierClick = (listing: IModifier, data: IListing | IModifier, action: 'PLUS' | 'MINUS' | 'OPTIONS' | 'SINGLE') => {
    if (!data || !action || data.soldOut) return;

    const { countRef, newModifierIDstr, modifierIDsAll } = this.state;
    const count = countRef[data.id] || 0;

    if (action === 'OPTIONS') {
      // const routeParams = parseRouteParams(this.props);
      const { routeParams, dispatch, listingID } = this.props;
      const { modifierIDsStr } = routeParams;
      const { modifierIDs } = this.state;
      dispatch({
        type: ActionType.SET_PRE_ADD_MODIFIERS,
        listingID: routeParams.listingID  || listingID || '',
        path: modifierIDsStr || newModifierIDstr[newModifierIDstr.length-1],
        modifierCountRef: this.state.countRef,
      });

      let newModifierIDs = modifierIDsStr;
      if (((modifierIDsStr && modifierIDsStr.length > 0) || (modifierIDs && modifierIDs.length > 0))) {
        newModifierIDs = `${modifierIDsStr || modifierIDs[modifierIDs.length-1] }-${data.id}`
      } else {
        newModifierIDs = data.id;
      }
      modifierIDsAll.push(data.id.toString())
      newModifierIDstr.push(newModifierIDs.toString());
      this.setState({modifierIDs: [data.id], modifierIDsAll: modifierIDsAll, newModifierIDstr: newModifierIDstr});
      this.handleModifierBack([data.id.toString()], newModifierIDstr);
    } else if (action === 'PLUS') {
      this.setState({
        countRef: {
          ...countRef,
          [data.id]: count + 1,
        }
      })
    } else if (action === 'MINUS') {
      if (count > 0) {
        const newCountRef = {
          ...countRef,
          [data.id]: count - 1,
        };

        this.setState({
          countRef: newCountRef
        });
      }
    } else if (action === 'SINGLE') {
      const selectedNumber = this.getSelectedNumber();
      if (count > 0) {
        delete countRef[data.id];
      } else {
        if (selectedNumber[listing.id] === 1 && selectedNumber[listing.id] === listing.chooseUpTo) {
          listing.children.forEach(item => {
            delete countRef[item.id]
          })
          countRef[data.id] = 1;
        }
        if (!selectedNumber[listing.id] || selectedNumber[listing.id] < listing.chooseUpTo) {
          countRef[data.id] = 1;
        }

      }

      this.setState({
        countRef: {
          ...countRef,
        }
      });
    }
  }
  addModifierSuccess = () => {
    const { onClose } = this.props;
    if ( onClose ) {
      onClose();
    }
  }
  handleConfirm = () => {
    const { modifiers, storeSlug, countRef, loadingData } = this.state;
    const { shoppingCartData, storeConfig, quantity, modifierIDs, newModifierIDstr } = this.state;
    const { orderInfo } = shoppingCartData || {};
    const { routeParams, dispatch, reducersState, listingID, modifierEntries, listingUuids } = this.props;
    const disableServiceCharge = storeConfig && storeConfig.disableServiceCharge ? true : false;
    const { modifierIDsStr } = routeParams;
    const { slugRefStorePickupLocations } = reducersState.store;
    const pickupLocations = slugRefStorePickupLocations[storeSlug] || [];
    if (loadingData) return;
    const select = this.getSelectedNumber();
    for (let i = 0; i < modifiers.length; i++) {
      const modifier = modifiers[i];
      const modifierItem = document.getElementById(`bm-modifier-item-panel-${modifier.id}`);
      if (select[modifier.id] < modifier.chooseAtLeast || (Object.keys(select).length === 0 && modifier.chooseAtLeast !== 0) || (!select[modifier.id] && modifier.chooseAtLeast !== 0)) {
        if (modifierItem) {
          const pageWrap = window.document.querySelector('.popup-description--item');
          if (pageWrap) {
            pageWrap.scrollTo(0, modifierItem.offsetTop)
          } else {
            animateScroll.scrollTo(modifierItem.offsetTop, {
              duration: 200,
            });
          }
        }
        return;
      }
    }
    dispatch({
      type: ActionType.LOADING,
      showLoading: true,
      showLoadingBgc: true,
    });
    const newmodifierIDs = routeParams.modifierIDs.length > 0? routeParams.modifierIDs: modifierIDs;
    if (newmodifierIDs.length > 0) { // modifier子集
      dispatch({
        type: ActionType.SET_PRE_ADD_MODIFIERS,
        listingID: routeParams.listingID || listingID || '',
        path: modifierIDsStr || newModifierIDstr[newModifierIDstr.length-1],
        modifierCountRef: countRef,
      });
      dispatch({
        type: ActionType.LOADING,
        showLoading: false,
      })
      this.goBack();
    } else {
      dispatch({
        type: ActionType.SET_PRE_ADD_MODIFIERS,
        listingID: routeParams.listingID || listingID || '',
        modifierCountRef: countRef,
      });
      const currentShippingMethod = getCurrentShippingMethod(orderInfo, storeConfig);
      
      if (modifierEntries && listingUuids) {
        dispatch({
          type: ActionType.UPDATE_MODIFIERS,
          storeSlug: routeParams.storeSlug || storeSlug || '',
          listingID: routeParams.listingID || listingID || '',
          listingUuids: listingUuids,
          modifierCountRef: countRef,
          disableServiceCharge,
          shippingMethod: currentShippingMethod,
          callback: shoppingCartData => {
            dispatch({
              type: ActionType.LOADING,
              showLoading: false,
            })
            if (shoppingCartData) {
              this.addModifierSuccess();
            } else {
              console.info('更新modifier失败');
            }
          }
        })
      } else {
        let date = dayjs();
        if (storeConfig) {
          date = date.add(storeConfig.delayedDeliveryTime, 'day');
        }
        let pickupLocation: IStorePickupLocation | undefined = undefined;
        let pickupLocationID: string | undefined = undefined;
        if (shoppingCartData && shoppingCartData.orderData.pickup_location) {
          pickupLocation = shoppingCartData.orderData.pickup_location;
          pickupLocationID = shoppingCartData.orderData.pickup_location_id;
        } else if (pickupLocations && pickupLocations.length > 0) {
          pickupLocation = pickupLocations[0];
          pickupLocationID = pickupLocations[0].id;
        }
        dispatch({
          type: ActionType.PUSH_LISTING_TO_SHOPPING_CART,
          storeSlug: routeParams.storeSlug || storeSlug || '',
          listingID: routeParams.listingID || listingID || '',
          modifierCountRef: countRef,
          shippingMethod: currentShippingMethod,
          disableServiceCharge,
          quantity,
          pickupLocation,
          pickupLocationID,
          deliveryDate: orderInfo && orderInfo.deliveryDate ? dayjs(orderInfo.deliveryDate) : (currentShippingMethod === ShippingMethod.PICK_UP ||  currentShippingMethod === ShippingMethod.DELIVERY ? date : undefined),
          callback: (shoppingCartData, storeIDs) => {
            dispatch({
              type: ActionType.LOADING,
              showLoading: false,
            })
            if (storeIDs && storeIDs.length > 0) {
              Modal.alert('', getIntl().page.differentShippingMethodTips, [
                {
                  text: getIntl().common.cancel,
                  onPress: () => { },
                },
                {
                  text: getIntl().common.continue,
                  onPress: () => {
                    dispatch({
                      type: ActionType.CLEAR_STORES_SHOPPING_CART,
                      storeIDs: storeIDs,
                    });
                    dispatch({
                      type: ActionType.PUSH_LISTING_TO_SHOPPING_CART,
                      storeSlug: routeParams.storeSlug || storeSlug || '',
                      listingID: routeParams.listingID || listingID || '',
                      modifierCountRef: countRef,
                      shippingMethod: currentShippingMethod,
                      disableServiceCharge,
                      quantity,
                      clearDifferentData: true,
                      pickupLocation,
                      pickupLocationID,
                      deliveryDate: orderInfo && orderInfo.deliveryDate ? dayjs(orderInfo.deliveryDate) : (currentShippingMethod === ShippingMethod.PICK_UP ||  currentShippingMethod === ShippingMethod.DELIVERY ? date : undefined),
                      callback: shoppingCartData => {
                        if (shoppingCartData) {
                          this.addModifierSuccess();
                        } else {
                          // TODO
                          console.info('加入购物车失败');
                        }
                      }
                    })
                  },
                }
              ]);
            } else {
              if (shoppingCartData) {
                this.addModifierSuccess();
              } else {
                // TODO
                console.info('加入购物车失败');
              }
            }
          }
        });
      }
    }
  }

  getSelectedNumber = () => {
    const { modifiers, countRef } = this.state;
    const selectedNumber: { [modifierID: string]: number } = {};

    modifiers.forEach(item => {
      let num = 0;
      if (Array.isArray(item.children)) {
        item.children.forEach(cItem => {
          if (countRef[cItem.id] && countRef[cItem.id] > 0) {
            num += countRef[cItem.id];
          }
        })
      }

      if (num > 0) {
        selectedNumber[item.id] = num;
      }
    })

    return selectedNumber;
  }

  handleChangeQuantity = (action: 'PLUS' | 'MINUS') => {
    const { quantity } = this.state;
    if (action === 'PLUS') {
      this.setState({
        quantity: quantity + 1,
      })
    } else {
      if (quantity === 1) return;
      this.setState({
        quantity: quantity - 1,
      })
    }

  }
  
  handleBack = () => {
    const { modifierIDsAll } = this.state;

    if (modifierIDsAll.length > 0) {
      this.goBack();
    } else if (this.props.onClose) {
      this.props.onClose();
    }
  }

  render() {
    const { modifiers, listing, countRef, quantity, modifierIDs, storeSlug } = this.state;
    const { reducersState, routeParams, modifierEntries } = this.props;
    const primaryModifier = (routeParams.routePath === paths.MODIFIER || routeParams.routePath === paths.QRCODE_SHOPPING_MODIFIER ||  modifierIDs.length === 0) && !modifierEntries;
    const { slugRefStore } = reducersState.store;
    const store = slugRefStore[routeParams.storeSlug || storeSlug] || {};

    return (
      <ModifierPageStyled
      >
        {
          listing && listing.imageUrl &&
          <div className="item-image-pop-up" style={{ backgroundImage: `url("${listing.imageUrl}")`}}>
            <div className="div-block-77">
              <div className="nav-button-1 close-modal close-modal-color" onClick={this.handleBack}>
                <Icon className="html-embed-7 w-embed" type={IconType.LEFT_OUTLINED} />
              </div>
            </div>
          </div> 
        }
        {
          listing && !listing.imageUrl &&
          <div className="div-block-77">
            <div className="nav-button-1 close-modal" onClick={this.handleBack}>
              <Icon className="html-embed-7 w-embed" type={IconType.LEFT_OUTLINED} />
            </div>
          </div>
        }
        <div className={`popup-description--item ${listing && !listing.imageUrl? 'popup-item-top': ''}`}>
          <ModifierListing
            data={listing}
            reducersState={reducersState}
            currencyCode={store && store.currencyCode}
          />
          <ModifierListView
            data={modifiers}
            countRef={countRef}
            selectedNumber={this.getSelectedNumber()}
            onItemClick={this.handleClick}
            onModifierClick={this.handleModifierClick}
            reducersState={reducersState}
            currencyCode={store && store.currencyCode}
          />
          <div className="empty-panel"></div>
          <div className="add-to-cart">
            {
              primaryModifier &&
              <div className="q-flex">
                <Icon
                  className={`q-dec ${quantity === 1 ? 'disable-minus' : ''}`}
                  type={IconType.MINUS_CIRCLE_OUTLINED}
                  onClick={() => this.handleChangeQuantity('MINUS')}
                />  
                <div className="q-num">{quantity}</div>
                <Icon
                  className="q-inc"
                  type={IconType.PLUS_CIRCLE_OUTLINED}
                  onClick={() => this.handleChangeQuantity('PLUS')}
                />
              </div>
            }
            <Button className="add-to-cart-button close-modal" onClick={this.handleConfirm} type="primary">
              <div className="text-block-12">{getIntlByPath(`${primaryModifier ? 'page.addShoppingCart' : 'page.ok'}`)}</div>
            </Button>
          </div>
        </div>
      </ModifierPageStyled>
    );
  }
}

export default ModifierPage;
