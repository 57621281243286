import { takeEvery, put, select, call, all } from 'redux-saga/effects';
import axios from 'axios';
import Config from '@/Config';
import Action from '@/actions';
import ActionType from '@/actions/action-type';
import { IReducersState } from '@/reducers';
import { IListingState } from '@/reducers/listing-reducer';
import OrderBuilder from '@/lib/order-builder';
import { IStore, IStoreConfig, IStoreImage, IStorePickupLocation } from '@/actions/store-action';
import {
  IAddFavoriteListing,
  IFavoriteListing,
  IIQueryListingsResult,
  IIQueryModifierListingsResult,
  IQueryFavoriteListings,
  IQueryListings,
  IQueryModifierListings,
  IQueryModifiers,
  IQueryStoresListings,
  IRemoveFavoriteListing,
} from '@/actions/listing-action';
import {
  IFavoriteTab,
  IModifier,
  IListing,
  IFavoriteSection,
  parseRoundingOtionsResp,
  parseTaxOtionsResp,
} from '@/lib/order-builder/utils/api';
import { handleQueryStore, handleQueryStoreByID, handleQueryStoreTables, handleQueryStoreImages, handleQueryStorePickupLocations } from './store-saga';
import { createRecord, deleteRecord, queryRecords } from '@/data/mb-api';
import { IObject } from '@/components/bm-common';
import { parseFavoriteListingRes, parseFavoriteListingsRes, parseListring } from '@/utils/listing-util';
import { getAllStoreInfo } from '@/data/StoreSetting';
import { parseResStore, parseResStoreConfig, parseResStoreImage } from '@/utils/store-util';
import { IRoundingOption, ITaxOption } from '@/lib/order-builder/models/OrderEntity';

interface IHandleQueryListingsResult {
  store?: IStore;
  favoriteTabs: IFavoriteTab[];
  modifiers: IModifier[];
  storeConfig?: IStoreConfig;
}

export function* handleQueryListings(params: IQueryListings) {
  const { storeSlug, storeID, callback } = params;
  const listingState: IListingState = yield select((state: IReducersState) => state.listing);

  let favoriteTabs: IFavoriteTab[] = [];
  let modifiers: IModifier[] = [];
  let store: IStore | undefined;
  let storeConfig: IStoreConfig | undefined;
  try {
    const queryStoreResult = yield handleQueryStore({
      type: ActionType.QUERY_STORE,
      storeSlug,
    });

    store = queryStoreResult.store;
    storeConfig = queryStoreResult.storeConfig;

    let newStoreID = '';
    if (storeID) {
      newStoreID = storeID;
    } else if (store && storeConfig && (storeConfig.showMenu || storeConfig.showOrder)) {
      newStoreID = store.id;
    }

    if (newStoreID) {
      if (!listingState.slugRefFavoriteTabs[storeSlug]) {
        const lisingInfoReqs: any[] = [];
        let reqIndex = -1;
        let menusReqIndex = -1;
        let modifiersReqIndex = -1;

        menusReqIndex = ++reqIndex;
        const menusRes = call(
          axios.get,
          Config.urls.getStoreMenus.replace('{storeID}', newStoreID),
        );
        lisingInfoReqs.push(menusRes);

        modifiersReqIndex = ++reqIndex;
        const modifiersRes = call(
          axios.get,
          Config.urls.getModifierSets.replace('{storeID}', newStoreID),
        );
        lisingInfoReqs.push(modifiersRes);

        ++reqIndex;
        lisingInfoReqs.push(handleQueryStoreImages({
          type: ActionType.QUERY_STORE_IMAGES,
          storeSlug,
        }));
        lisingInfoReqs.push(handleQueryStorePickupLocations({
          type: ActionType.QUERY_STORE_PICKUP_LOCATIONS,
          storeSlug,
        }));

        if (storeConfig?.dineIn) {
          ++reqIndex;
          lisingInfoReqs.push(handleQueryStoreTables({
            type: ActionType.QUERY_STORE_TABLES,
            storeSlug,
          }));
        }

        let lisingData: any[] = [];
        if (lisingInfoReqs.length > 0) {
          lisingData = yield all(lisingInfoReqs);
        }

        if (menusReqIndex > -1 && lisingData.length > menusReqIndex) {
          const tempMenusRes = lisingData[menusReqIndex];
          OrderBuilder.convertFavoriteApiData(tempMenusRes, {storeSlug, storeID: newStoreID});
        }

        if (modifiersReqIndex > -1 && lisingData.length > modifiersReqIndex) {
          const tempModifitesRes = lisingData[modifiersReqIndex];
          OrderBuilder.convertModifierApiData(tempModifitesRes, {storeSlug, storeID: newStoreID});
        }

        favoriteTabs = OrderBuilder.getFavoriteTabs(newStoreID);
        modifiers = OrderBuilder.getModifiers(newStoreID);

        yield put<Action>({
          type: ActionType.SET_FAVORITE_TABS,
          storeSlug,
          favoriteTabs,
        });
        yield put<Action>({
          type: ActionType.SET_MODIFIERS,
          storeSlug,
          modifiers,
        });
      } else {
        favoriteTabs = listingState.slugRefFavoriteTabs[storeSlug];
        modifiers = listingState.slugRefModifiers[storeSlug] || [];
      }
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(favoriteTabs, store, storeConfig);
  }

  const result: IHandleQueryListingsResult = {
    store,
    favoriteTabs,
    modifiers,
    storeConfig,
  }

  return result;
}

export function* handleQueryStoresListings(params: IQueryStoresListings) {
  const { storeIDs, callback } = params;
  const storeIDRefListings: {[storeID: string]: IIQueryListingsResult} = {};
  const stores: IStore[] = [];
  try {
    if (Array.isArray(storeIDs) && storeIDs.length > 0) {
      for (let i = 0; i < storeIDs.length; i++) {
        const storeID = storeIDs[i];
        const { store } = yield handleQueryStoreByID({
          type: ActionType.QUERY_STORE_BY_ID,
          storeID,
        });

        if (store && store.slug) {
          stores.push(store);
          const listingInfo = yield handleQueryListings({
            type: ActionType.QUERY_LISTINGS,
            storeSlug: store.slug,
            storeID: store.id,
          });

          storeIDRefListings[storeID] = listingInfo;
        }
      }
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(storeIDRefListings, stores);
  }

  return {storeIDRefListings, stores}
}

function* handleQueryModifiers(params: IQueryModifiers) {
  const { storeSlug, listingID, modifierID, callback } = params;
  const listingState: IListingState = yield select((state: IReducersState) => state.listing);

  let modifiers: IModifier[] = [];
  let listing: IListing | IModifier | undefined = undefined;
  try {
    let store: IStore | undefined = undefined;

    if (!listingState.slugRefModifiers[storeSlug]) {
      const result = yield handleQueryListings({
        type: ActionType.QUERY_LISTINGS,
        storeSlug,
      });
      if (result) store = result.store;
    }

    if (!store) {
      const queryStoreResult = yield handleQueryStore({
        type: ActionType.QUERY_STORE,
        storeSlug,
      });

      store = queryStoreResult.store;
    }

    if (store) {
      const modifierInfo = OrderBuilder.findModifiers(store.id, listingID, modifierID);
      modifiers = modifierInfo.modifiers;
      const listingList: string[] = [];
      modifiers.map(modifier => (
        modifier.children.forEach(childModifier => {
          if (store && childModifier.listingID) {
            const listings = OrderBuilder.findListing(store.id, childModifier.listingID);
            if (listings.listing) {
              childModifier.soldOut = listings.listing.soldOut;
            } else {
              listingList.push(childModifier.listingID);
            }
          }
        })
      ))
      if (listingList.length > 0) {
        const result: any = yield handleQueryModifierListings({
          type: ActionType.QUERY_MODIFIER_LISTINGS,
          storeID: store.id,
          listingIDs: listingList,
          storeSlug,
        });
        modifiers.map(modifier => (
          modifier.children.forEach(childModifier => {
            if (childModifier.listingID && result[childModifier.listingID]) {
              childModifier.soldOut = result[childModifier.listingID].soldOut;
            }
          })
        ))
      }

      if (modifierID) {
        listing = modifierInfo.modifier
      } else {
        listing = modifierInfo.listing
      }
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(modifiers, listing, storeSlug);
  }
}

export function* handleQueryModifierListings(params: IQueryModifierListings) {
  const { storeID, listingIDs, callback } = params;
  let storeIDRefListings: {[listingID: string]: IIQueryModifierListingsResult } = {};
  let ids = '?';
  listingIDs.forEach(v => {
    ids += `ids[]=${v}&`;
  });

  try {
    const listingInfo = yield call(
      axios.get,
      Config.urls.getListings.replace('{storeID}', storeID).replace('{ids}', ids),
    );
    storeIDRefListings = parseListring(listingInfo.listings)
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(storeIDRefListings);
  }

  return storeIDRefListings
}

export function* handleAddFavoriteListing(params: IAddFavoriteListing) {
  const { storeSlug, favoriteID, favoriteTabID, favoriteSectionID, cartData, callback } = params;

  const state: IReducersState = yield select((state: IReducersState) => state);
  const { platformAppSettings }  = state.app;
  const { slugRefFavoriteTabs, favoriteListingInfo }  = state.listing;
  const { userInfo }  = state.user;
  const { selectStoreId } = state.supplierInfo;
  let store: IStore | undefined = undefined;
  let storeConfig: IStoreConfig | undefined = undefined;
  let favoriteListing: IFavoriteListing | undefined = undefined;
  let favoriteTab: IFavoriteTab | undefined = undefined;
  let favoriteSection: IFavoriteSection | undefined = undefined;
  let listing: IListing | undefined = undefined;
  let favoriteTabs: IFavoriteTab[] = [];

  let success = false;
  let recordID = '';
  try {
    const queryStoreResult = yield handleQueryStore({
      type: ActionType.QUERY_STORE,
      storeSlug,
    });

    store = queryStoreResult.store;
    storeConfig = queryStoreResult.storeConfig;

    if (platformAppSettings && userInfo && store && storeConfig) {
      if (!favoriteListingInfo.loaded || favoriteListingInfo.selectStoreId !== selectStoreId) {
        yield handleQueryFavoriteListings({
          type: ActionType.QUERY_FAVORITE_LISTINGS
        });
      }
      const record: IObject = {
        'platform_id': {
          records: [{ id: platformAppSettings.id }],
        },
        'store_id': {
          records: [{ id: store.id }],
        },
        'user_id': {
          records: [{ id: userInfo.id }],
        },
        'favorite_id': favoriteID,
        'favorite_tab_id': favoriteTabID,
        'favorite_section_id': favoriteSectionID,
        'buyer_store_id': selectStoreId,
      }

      if (cartData) {
        record['cart_date'] = cartData;
      }
  
      const result = yield createRecord({
        storeSlug: platformAppSettings.storeSlug,
        moduleBundleID: 'bm-express-order',
        record
      });

      if (result.success && result.record) {
        success = result.success || false;
        recordID = result.record.id;
        favoriteListing = parseFavoriteListingRes(result.record)

        if (favoriteListing) {
          if (slugRefFavoriteTabs[storeSlug]) {
            favoriteTabs = slugRefFavoriteTabs[storeSlug];
          } else {
            const queryListingsResult: IHandleQueryListingsResult = yield handleQueryListings({
              type: ActionType.QUERY_LISTINGS,
              storeSlug,
            });
  
            if (queryListingsResult && Array.isArray(queryListingsResult.favoriteTabs)) {
              favoriteTabs = queryListingsResult.favoriteTabs;
            }
          }
  
          let favoriteSections: IFavoriteSection[] = [];
          for (let i = 0; i < favoriteTabs.length; i++) {
            const item = favoriteTabs[i];
            if (item && `${item.id}` === favoriteListing.favoriteTabID) {
              favoriteSections = item.favoriteSections;
              favoriteTab = {
                ...item,
                favoriteSections: [],
              };
              break;
            }
          }

          let listings: IListing[] = []; 
          for (let i = 0; i < favoriteSections.length; i++) {
            const item = favoriteSections[i];
            if (item && `${item.id}` === favoriteListing.favoriteSectionID) {
              listings = item.listings;
              favoriteSection = {
                ...item,
                listings: [],
              };
              break;
            }
          }

          for (let i = 0; i < listings.length; i++) {
            const item = listings[i];
            if (item && `${item.favoriteID}` === favoriteListing.favoriteID) {
              listing = item;
              break;
            }
          }
        }
      }
    }

    if (success && store && storeConfig && favoriteListing && favoriteTab && favoriteSection && listing) {
      yield put<Action>({
        type: ActionType.PUT_FAVORITE_LISTING,
        store,
        storeConfig,
        favoriteListing,
        favoriteTab,
        favoriteSection,
        listing,
      });
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(success, recordID)
  }
}

export function* handleRemoveFavoriteListing(params: IRemoveFavoriteListing) {
  const { id, callback } = params;

  const state: IReducersState = yield select((state: IReducersState) => state);
  const { platformAppSettings }  = state.app;
  const { favoriteListingInfo }  = state.listing;
  let success = false;

  try {
    if (platformAppSettings && id) {
      if (!favoriteListingInfo.loaded) {
        yield handleQueryFavoriteListings({
          type: ActionType.QUERY_FAVORITE_LISTINGS
        });
      }

      const result = yield deleteRecord({
        storeSlugs: [platformAppSettings.storeSlug],
        moduleBundleID: 'bm-express-order',
        ids: [id],
      });

      if (result) {
        success = result.success || false;
      }

      if (success) {
        yield put<Action>({
          type: ActionType.CLEAR_FAVORITE_LISTING_INFO,
          id,
        });
      }
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(success)
  }
}

export function* handleQueryFavoriteListings(params: IQueryFavoriteListings) {
  const { callback } = params;

  const state: IReducersState = yield select((state: IReducersState) => state);
  const { platformAppSettings, platformConfig }  = state.app;
  let { favoriteListingInfo }  = state.listing;
  const { selectStoreId } = state.supplierInfo;
  
  try {
    if (platformAppSettings && selectStoreId && (!favoriteListingInfo.loaded || favoriteListingInfo.selectStoreId !== selectStoreId)) {
      let formula = `EQ("$.self.platform_id", "${platformAppSettings.id}")`;
      formula = `${formula}.EQ("$.self.buyer_store_id", "${selectStoreId}")`;
      formula = `${formula}.IS_NULL("$.self.deleted_at")`;
      // const result = yield queryRecords({
      //   storeSlugs: [platformAppSettings.storeSlug],
      //   moduleBundleID: 'bm-express-order',
      //   search: {
      //     formulas: [{ formula }],
      //     perPage: 500,
      //   },
      // });
      let reqIndex = -1;
      let recordReqIndex = -1;
      let storeReqIndex = -1;
      let platformStoreReqIndex = -1;
      let favoriteTabs: IFavoriteTab[] = [];
      let modifiers: IModifier[] = [];
      const reqList: any[] = [];
      reqList.push(queryRecords({
        storeSlugs: [platformAppSettings.storeSlug],
        moduleBundleID: 'bm-express-order',
        search: {
          formulas: [{ formula }],
          perPage: 500,
        },
      }));
      recordReqIndex = ++reqIndex;
      reqList.push(getAllStoreInfo(platformConfig.platformID, selectStoreId ? selectStoreId : ''));
      storeReqIndex = ++reqIndex;

      reqList.push(handleQueryStore({
        type: ActionType.QUERY_STORE,
        storeSlug: platformConfig.storeSlug,
      }))
      platformStoreReqIndex = ++reqIndex;

      const resultList: any[] = yield all(reqList);
      

      favoriteListingInfo = parseFavoriteListingsRes(resultList[recordReqIndex].records);
      const platformStore = resultList[platformStoreReqIndex];

      const storeReq: any = resultList[storeReqIndex].data;
      const storeListingsResps: IHandleQueryListingsResult[] = [];
      let storeListings: IHandleQueryListingsResult;
      const stores: IStore[] = [];
      const allStoresImages: {[key: string]: IStoreImage[]} = {};
      const allStorePickupLocations: {[key: string]: IStorePickupLocation[]} = {};
      const allStoreRoundingOptions: {[key: string]: IRoundingOption} = {};
      const allStoreTaxOptions: {[key: string]: ITaxOption[]} = {};
      const storesConfigs: IStoreConfig[] = [];
      const allStoreFavoriteTabs: {[key: string]: IFavoriteTab[]} = {};
      const allStoreModifiers: {[key: string]: IModifier[]} = {};
      storeReq.forEach((item: any) => {
        const store = parseResStore(item);
        if (store) {
          stores.push(store);
          const storeSlug = store.slug;
          const storeID = store.id;
          OrderBuilder.convertFavoriteApiData(item.favorites, {storeSlug, storeID});
          favoriteTabs = OrderBuilder.getFavoriteTabs(storeID);
          allStoreFavoriteTabs[store.slug] = favoriteTabs;

          OrderBuilder.convertModifierApiData(item.modifier_set_list, {storeSlug, storeID});
          modifiers = OrderBuilder.getModifiers(storeID);
          allStoreModifiers[store.slug] = modifiers;
        }

        const storeImages: IStoreImage[] = [];
        const storeImageReq = item.store_pictures;
        if (Array.isArray(storeImageReq)) {
          storeImageReq.forEach((item: any) => {
            const storeImage = parseResStoreImage(item);
            if (storeImage) {
              storeImages.push(storeImage);
            }
          })
        }
        allStoresImages[item.slug] = storeImages;

        let resStorePickupLocations: any[] = [];
        const storePickupLocations: IStorePickupLocation[] = [];

        if (item.pick_up_locations && Array.isArray(item.pick_up_location)) {
          resStorePickupLocations = item.pick_up_location;
        }

        resStorePickupLocations.forEach((item: any) => {
          if (storePickupLocations) {
            storePickupLocations.push({
              id: item.id,
              name: item.name,
              i18n: item.translation_info,
            })
          }
        })
        allStorePickupLocations[item.slug] = storePickupLocations;

        const roundingOptions = parseRoundingOtionsResp(item.store_module);
        allStoreRoundingOptions[item.slug] = roundingOptions;

        const taxOptions = parseTaxOtionsResp(item.tax_options);
        allStoreTaxOptions[item.slug] = taxOptions;

        const storeConfig = parseResStoreConfig(item.store_config, platformConfig.platformID);
        storesConfigs.push(storeConfig);

        storeListings = {
          store,
          favoriteTabs,
          modifiers,
          storeConfig,
        }
        storeListingsResps.push(storeListings);
      })
      
      yield put<Action>({
        type: ActionType.SET_ALL_STORES_INFO,
        allStores: stores,
        allStoresImages,
        allStorePickupLocations,
        storesConfigs,
      })

      yield put<Action>({
        type: ActionType.SET_ALL_STORE_ROUNDING_AND_TAX_OPTIONS,
        allStoreRoundingOptions,
        allStoreTaxOptions,
      });

      yield put<Action>({
        type: ActionType.SET_ALL_STORE_FAVORITE_TABS_AND_MODIFIERS,
        allStoreFavoriteTabs,
        allStoreModifiers,
      });

      storeListingsResps.forEach(item => {
        if (item && item.store && item.storeConfig) {
          const favoriteStoreID = item.store.id;
          const newFavoriteTabs: IFavoriteTab[] = [];
          let favoriteCount = 0;
          item.favoriteTabs.forEach(favoriteTab => {
            const favoriteTabID = favoriteTab.id;
            const newFavoriteSections: IFavoriteSection[] = [];
            favoriteTab.favoriteSections.forEach(favoriteSection => {
              const favoriteSectionID = favoriteSection.id;
              const newListings: IListing[] = [];
              const key = `${favoriteStoreID}.${favoriteTabID}.${favoriteSectionID}`;
              if (Array.isArray(favoriteListingInfo.refFavoriteIDs[key])) {
                favoriteSection.listings.forEach(listing => {
                  if (favoriteListingInfo.refFavoriteIDs[key].includes(`${listing.favoriteID}`)) {
                    newListings.push(listing);
                    favoriteCount++;
                  }
                });
              }

              if (newListings.length > 0) {
                newFavoriteSections.push({
                  ...favoriteSection,
                  listings: newListings,
                })
              }
            });

            if (newFavoriteSections.length > 0) {
              newFavoriteTabs.push({
                ...favoriteTab,
                favoriteSections: newFavoriteSections,
              })
            }
          });

          if (newFavoriteTabs.length > 0) {
            favoriteListingInfo.storesFavoriteListingTabs.push({
              store: item.store,
              storeConfig: item.storeConfig,
              favoriteTabs: newFavoriteTabs,
              favoriteCount,
            })
          }
        }
      })

      favoriteListingInfo.loaded = true;
      favoriteListingInfo.selectStoreId = selectStoreId;
      favoriteListingInfo.platformStore = platformStore;

      yield put<Action>({
        type: ActionType.SET_FAVORITE_LISTING_INFO,
        favoriteListingInfo,
      });
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(favoriteListingInfo)
  }

  return favoriteListingInfo;
}

function* listingSaga() {
  yield takeEvery(ActionType.QUERY_LISTINGS, handleQueryListings);
  yield takeEvery(ActionType.QUERY_MODIFIERS, handleQueryModifiers);
  yield takeEvery(ActionType.QUERY_STORES_LISTINGS, handleQueryStoresListings);
  yield takeEvery(ActionType.QUERY_MODIFIER_LISTINGS, handleQueryModifierListings);
  yield takeEvery(ActionType.ADD_FAVORITE_LISTING, handleAddFavoriteListing);
  yield takeEvery(ActionType.REMOVE_FAVORITE_LISTING, handleRemoveFavoriteListing);
  yield takeEvery(ActionType.QUERY_FAVORITE_LISTINGS, handleQueryFavoriteListings);
}

export default listingSaga();
