
import React from 'react';
import dayjs from 'dayjs';
import Modal from 'antd-mobile/es/modal';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import Icon, { IconType } from '@/components/Icon';
import Layout from '@/components/Layout/Layout';
import { parseRouteParams, checkImgExists } from '@/utils';
import ActionType from '@/actions/action-type';
import Config from '@/Config';
import paths from '@/routes/paths';
import { IBooking } from '@/actions/booking-action';
import { IStore } from '@/actions/store-action';
import { prefix } from '.';
import Constants from '@/constants';
import { getI18nText } from '@/utils/app';
import { BookingDetailPageStyled } from './styled';
import { getIntl } from '@/components/App/App';

export interface IBookingDetailPageProps extends IBasePageProps {}
export interface IBookingDetailPageState {
  booking: IBooking | undefined;
  store: IStore | undefined;
  logoUrl: string;
  showModal: boolean;
}

const failureStatus = ['cancelled', 'no_show'];
const waitingStatus = 'waiting';

class BookingDetailPage extends BasePage<IBookingDetailPageProps, IBookingDetailPageState> {
  state: IBookingDetailPageState = {
    booking: undefined,
    store: undefined,
    logoUrl: Config.defaultStoreImg,
    showModal: false,
  }

  componentDidMount() {
    super.componentDidMount();

    const {
      dispatch,
      reducersState,
    } = this.props;
    const routeParams = parseRouteParams(this.props);
    const { user } = reducersState;
    const { userInfo } = user;

    if (userInfo) {
      dispatch({
        type: ActionType.QUERY_BOOKINGS,
        formula: `EQ("$.self.id", "${routeParams.bookingID}")`,
        page: 1,
        multi: false,
        id: routeParams.bookingID,
        callback: records => {
          if (records.length > 0 ) {
            dispatch({
              type: ActionType.QUERY_STORE_BY_ID,
              storeID: records[0].storeID,
              callback: store => {
                if (store) {
                  checkImgExists(store.logoUrl, (success, url) => {
                    this.setState({
                      store,
                      logoUrl: success ? url : Config.defaultStoreImg,
                      booking: records[0],
                    });
                  })
                }
              }
            });
          }
        }
      })
    }
  }

  componentWillUnmount() {
    const {
      dispatch,
    } = this.props;

    if (localStorage.getItem(Constants.IS_GUEST) === 'true') {
      dispatch({
        type: ActionType.CLEAR_USER_INFO,
      });
    }
  }

  handleCancelBooking = () => {
    const {
      booking,
      store,
    } = this.state;

    const {
      dispatch,
    } = this.props;

    dispatch({
      type: ActionType.LOADING,
      showLoading: true,
    })
    if (booking && store) {
      dispatch({
        type: ActionType.CANCELL_BOOKING,
        storeSlug: store.slug,
        id: booking.id,
        callback: booking => {
          if (booking) {
            this.setState({
              booking,
              showModal: false,
            });
          }
          dispatch({
            type: ActionType.LOADING,
            showLoading: false,
          })
        }
      })
    }
  }

  render() {
    const {
      booking,
      store,
      logoUrl,
      showModal,
    } = this.state;

    const { reducersState } = this.props;
    const { language } = reducersState.app;
    console.info(123);
    return (
      <Layout
        {...this.props}
        className={prefix}
        navIcon={<Icon className="html-embed-7 w-embed"type={IconType.LEFT_OUTLINED} />}
        onLeftClick={() => this.handleGoBack({
          path: paths.BOOKINGS,
          runGoBack: true,
        })}
        navContent={getIntl().page.bookings}
        isFooter={false}
      >
        <BookingDetailPageStyled>
          {
            booking && store &&
            <div className="section wf-section">
              <div className="container w-container">
                <div className="div-block-543">
                  {
                    failureStatus.includes(booking.status) &&
                    <div className="payment-status-icon _1">
                      <Icon className="image-202" type={IconType.STATUS_FAILURE} />
                      <h1 className="heading_1">{getIntl().page.bookingFailure}</h1>
                    </div>
                  }
                  {
                    !failureStatus.includes(booking.status) &&
                    <div className="payment-status-icon _1">
                      {
                        booking.status === waitingStatus &&
                        <Icon className="image-202" type={IconType.STATUS_WAITING} />
                      }
                      {
                        booking.status !== waitingStatus &&
                        <Icon className="image-202" type={IconType.STATUS_SUCCESS} />
                      }
                      <h1 className="heading_1">
                        {
                          booking.status === waitingStatus &&
                          <>
                            <div>
                              {getIntl().page.bookingSuccessful}
                            </div>
                            <div>
                              {getIntl().page.bookingSuccessfulTips}
                            </div>
                          </>
                        }
                        {
                          booking.status !== waitingStatus &&
                          getIntl().page.bookingSuccess
                        }
                      </h1>
                    </div>
                  }
                  <div className="ticket-wrapper _1">
                    <div className="clipped-3">
                      <div className="html-embed-21 w-embed">
                        <svg className="svg-3">
                          <clipPath id="my-clip-path-3" clipPathUnits="objectBoundingBox"><path d="M0,0 H1 V0.137 C0.999,0.137,0.999,0.137,0.998,0.137 C0.98,0.137,0.965,0.146,0.965,0.158 C0.965,0.17,0.98,0.179,0.998,0.179 C0.999,0.179,0.999,0.179,1,0.179 V0.787 C0.999,0.787,0.999,0.787,0.998,0.787 C0.98,0.787,0.965,0.797,0.965,0.809 C0.965,0.821,0.98,0.831,0.998,0.831 C0.999,0.831,0.999,0.831,1,0.831 V1 H0 V0.831 C0.018,0.83,0.032,0.821,0.032,0.809 C0.032,0.797,0.018,0.787,0,0.787 V0.179 C0.018,0.178,0.032,0.169,0.032,0.158 C0.032,0.147,0.018,0.137,0,0.137 V0"></path></clipPath>
                        </svg>
                      </div>
                      <div className="div-block-457">
                        <div className="div-block-442" style={{backgroundImage: `url(${logoUrl})`}}></div>
                        <div>
                          <div className="text-block-48">{getI18nText(store, 'title', reducersState.app.language) || store.name}</div>
                          <div>
                            <div className="text-block-49">{getI18nText(store, 'address1', reducersState.app.language) || store.address}</div>
                          </div>
                        </div>
                      </div>
                      <div className="line-divider-top"></div>
                      <div className="div-block-444">
                        <div className="div-block-445">
                          <div className="div-block-446">
                            <div className="text-block-60">
                              {
                                language === 'en-US' && dayjs(booking.reservationTime).format('MMM DD')
                              }
                              {
                                language !== 'en-US' && 
                                <>
                                  {dayjs(booking.reservationTime).format('M')}
                                  {getIntl().common.month}
                                  {dayjs(booking.reservationTime).format('D')}
                                  {getIntl().common.day}
                                </>
                              }
                            </div>
                            <div className="text-block-49">{getIntl().page.date}</div>
                          </div>
                          <div className="div-block-446">
                            <div className="text-block-60">{dayjs(booking.reservationTime).format('HH:mm')}</div>
                            <div className="text-block-49">{getIntl().page.time}</div>
                          </div>
                          <div className="div-block-446">
                            <div className="text-block-60">{booking.people}</div>
                            <div className="text-block-49">{getIntl().page.seats}</div>
                          </div>
                        </div>
                        <div className="div-block-447">
                          <div className="div-block-436">
                            <div className="text-block-46 third">{getIntl().page.name}</div>
                            <div className="text-block-46">{booking.customerName}</div>
                          </div>
                          <div className="div-block-436">
                            <div className="text-block-46 third">{getIntl().page.status}</div>
                            <strong className="text-block-46">
                              {
                                booking.status === 'waiting' &&
                                getIntl().page.booked
                              }
                              {
                                booking.status === 'arrived' &&
                                getIntl().page.arrived
                              }
                              {
                                booking.status === 'cancelled' &&
                                getIntl().page.cancelled
                              }
                              {
                                booking.status === 'confirmed' &&
                                getIntl().page.confirmed
                              }
                              {
                                booking.status === 'noSshow' &&
                                getIntl().page.noShow
                              }
                              {
                                booking.status === 'voicemail' &&
                                getIntl().page.voicemail
                              }
                              {
                                booking.status === 'partiallyArrived' &&
                                getIntl().page.partiallyArrived
                              }
                              {
                                booking.status === 'late' &&
                                getIntl().page.late
                              }
                              {
                                booking.status === 'waitingForCon' &&
                                getIntl().page.waitingForCon
                              }
                            </strong>
                          </div>
                          <div className="div-block-436">
                            <div className="text-block-46 third">{getIntl().page.email}</div>
                            <div className="text-block-46">{booking.email}</div>
                          </div>
                          <div className="div-block-436">
                            <div className="text-block-46 third">{getIntl().page.phoneNumber}</div>
                            <div className="text-block-46">{booking.phone}</div>
                          </div>
                          <div className="div-block-436">
                            <div className="text-block-46 third">{getIntl().page.createAt}</div>
                            <div className="text-block-46">{dayjs(booking.createAt).format('YYYY-MM-DD H:mm:ss')}</div>
                          </div>
                          <div className="div-block-436">
                            <div className="text-block-46 third">{getIntl().page.note}</div>
                            <div className="text-block-46">{booking.note}</div>
                          </div>
                        </div>
                      </div>
                      <div className="line-divider _1"></div>
                      <div className="div-block-593">
                        {
                          !failureStatus.includes(booking.status) &&
                          <div className="submit-button-modal close-modal _2 cancel-submit" onClick={() => this.setState({showModal: true})}>
                            <div className="text-block-47">{getIntl().page.cancelBooking}</div>
                          </div>
                        }
                        <div className="submit-button-modal close-modal _2" onClick={() => this.handleGoHome(true)}>
                          <div className="text-block-47">{getIntl().page.backToHome}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </BookingDetailPageStyled>
        {
          <Modal
            className={`${prefix}-modal`}
            visible={showModal}
            transparent
            onClose={() => this.setState({showModal: false})}
            footer={[
              {
                text: getIntl().common.no,
                onPress: () => this.setState({showModal: false})
              },
              {
                text: getIntl().common.yes,
                onPress: () => this.handleCancelBooking()
              }
            ]}
          >
            {getIntl().page.cancelBookingTip}
          </Modal>
        }
      </Layout>
    );
  }
}

export default BookingDetailPage;
