import React from 'react';
import Icon, { IconType } from '../Icon';

interface ISelectPayMethodItemProps {
  title: React.ReactNode;
  icon?: React.ReactNode;
  className?: string;
  checked?: boolean;
  onItemClick?: () => void;
  showCheckIcon?: boolean;
}

const SelectPayMethodItem: React.FC<ISelectPayMethodItemProps> = props => {
  const {
    title,
    icon,
    className = '',
    onItemClick,
    checked = false,
    showCheckIcon = true,
  } = props;

  return (
    <div className={`payment-selection w-radio ${className}`} onClick={onItemClick}>
      {
        showCheckIcon &&
        <Icon
          type={checked ? IconType.SELECTED_OUTLINED : IconType.UN_SELECTED_OUTLINED}
          className={checked ? 'selected-icon button' : 'un-selected-icon'}
        />
      }
      <span className="radio-button-label w-form-label">{title}</span>
      <div className="payment-icons">
        {
          icon &&
          <div className="image-200">{icon}</div>
        }
      </div>
      <div className="image-200">
        { 
          !showCheckIcon &&
          <Icon
            type={IconType.RIGHT_OUTLINED}
            className="html-embed-34 w-embed"
          />
        }
      </div>
    </div>
  );
}

export default SelectPayMethodItem;
