// import {
// } from '@/actions/point-action';
import { convertOrderApiData } from '@/lib/order-builder/utils/api';
import { plus } from '@/lib/order-builder/utils';

export const parseDeferredInvoices = (data: any) => {
  const parseDeferredInvoices: any = {};
  if (Array.isArray(data) && data.length > 0) {
    data.forEach(item => {
      const orders: any[] = [];
      const orderIDs: string[] = [];
      let subTotal = 0;
      let initialTotal = 0;
      let initialTax = 0;
      let initialServiceFee = 0;
      let currencyCode = '';
      item.pos.forEach((_item: any) => {
        const order = convertOrderApiData(_item);
        orders.push(order);
        orderIDs.push(_item.id);
        if (order) {
          currencyCode = order.currencyCode;
          subTotal = plus(subTotal, order.subTotal);
          initialTotal = plus(initialTotal, order.initialTotal);
          initialTax =  plus(initialTax, order.initialTax);
          initialServiceFee =  plus(initialServiceFee, order.initialServiceFee);
        }
      })
      parseDeferredInvoices[item.store_id] = {
        lastInvoiceData: item.last_invoice_data || '',
        supplierStoreID: item.store_id,
        supplierStoreSlug: item.store_slug,
        supplierStoreName: item.supplier_name,
        total: item.total || 0,
        orders: orders,
        orderIDs: orderIDs,
        subTotal: subTotal,
        initialTotal: initialTotal,
        currencyCode: currencyCode,
        initialTax,
        initialServiceFee,
      };
    })
  }

  return parseDeferredInvoices;
}

