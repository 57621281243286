
import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import Layout from '@/components/Layout/Layout';
import ActionType from '@/actions/action-type';
import { IGiftCard } from '@/actions/pay-action';
import { IOrderInfo } from '@/lib/order-builder/models/OrderEntity';
import { createRouteUrl, getDomain, setPlatformPayInfo } from '@/utils';
import { setScreenColor, getAppTheme, getIntl } from '@/components/App/App';
import { IObject } from '@/components/bm-common';
import paths from '@/routes/paths';
import { IStore, IStoreConfig } from '@/actions/store-action';
import { emailSignUpV2 } from '@/data/sign';
import { createUuid } from '@/lib/order-builder/utils'
import Constants from '@/constants';
import { prefix } from '.';
import Icon, { IconType } from '@/components/Icon';
import Skeleton from './Skeleton';
import { Base64 } from 'js-base64';
import { getPlatformIDByDomain } from '@/data/StoreSetting';
import { getAccessToken } from '@/utils/app';


export interface IPayBillPageProps extends IBasePageProps { }
interface IPayBillState {
  amount: string;
  showModal: boolean;
  store: IStore | undefined;
  selectedGiftCard: IGiftCard | undefined; // 当前选中的购物卡
  selectedMethod: string | undefined; // 当前选中的
  hasAmount: boolean;
  paying: boolean;
  showPayCardModal: boolean;
  checking: boolean;
  loadingOrerInfo: boolean;
  initLoading: boolean;
  orderInfo?: IOrderInfo;
  preAmount: string;
  storeConfig: IStoreConfig | undefined;
  payBill: boolean;
  isFromPage?: boolean;
  realDomain?: string;
}

class PayBillPage extends BasePage<IPayBillPageProps, IPayBillState> {
  routeParams: IObject = {};

  state: IPayBillState = {
    amount: '',
    showModal: false,
    selectedGiftCard: undefined,
    selectedMethod: undefined,
    orderInfo: undefined,
    store: undefined,
    hasAmount: false,
    paying: false,
    showPayCardModal: false,
    checking: false,
    loadingOrerInfo: false,
    initLoading: true,
    preAmount: '',
    storeConfig: undefined,
    payBill: false,
    isFromPage: false,
    realDomain: '',
  }

  async componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.primaryColor && appTheme.darkBackgroundColor) {
      setScreenColor(appTheme.primaryColor, appTheme.darkBackgroundColor);
    }
    super.componentDidMount();

    await this.initUser();
    
    this.setState({
      initLoading: true,
    })
  }

  queryStore = async() => {
    const {
      gatewayDomain,
      isLocalhost,
      platformHost,
    } = getDomain();
    const { routeParams, reducersState,  dispatch } = this.props;
    const { platformConfig } = reducersState.app;
    const { storeSlug } = routeParams;
    let platformID = '';
    if (routeParams.domain) {
      platformID = await getPlatformIDByDomain(routeParams.domain);
    }
    dispatch({
      type: ActionType.QUERY_STORE,
      storeSlug,
      currentPlatformID: platformID,
      callback: (_store, storeConfig) => {
        if (storeConfig) {
          this.setState({
            storeConfig,
            payBill: storeConfig.payBill,
          })
          const domain = isLocalhost ? `http://${platformHost}` : `https://${gatewayDomain}`;
          const tempUrl = Base64.encode(JSON.stringify({ payBill: storeConfig.payBill, token: getAccessToken() }));
          window.location.href = `${domain}${createRouteUrl(
            paths.PLATFORM_PAY_BILL,
            { storeSlug, platformID: platformConfig.platformID },
          )}?${Constants.PAYBILLINFO}__${tempUrl}`;
        }
      }
    })
  }

  initUser = async () => {
    const { dispatch, reducersState, routeParams } = this.props;
    const signUpEmail = `${createUuid()}_HR@bindo.com`;

    const { platformAppSettings } = reducersState.app;
    let platformID = 0;
    if (routeParams.domain) {
      platformID = await getPlatformIDByDomain(routeParams.domain);
    }
    platformID = platformID ? platformID : platformAppSettings ? parseInt(platformAppSettings.id) : 0;

    const {
      gatewayDomain,
      isLocalhost,
      platformHost,
    } = getDomain();

    dispatch({
      type: ActionType.LOADING,
      showLoading: true,
    });
    try {
      const resp = await emailSignUpV2({
        userEmail: signUpEmail,
        password: signUpEmail,
        fullName: 'PAY_BILL_USER',
        platformID,
        registerType: 1,
      });

      // 注册完成后不能马上注册, 因为服务器可能会401
      if (resp && resp.success) {
        setTimeout(() => {
          dispatch({
            type: ActionType.SIGN_IN,
            username: signUpEmail,
            password: signUpEmail,
            loginMethod: 'email',
            platformID,
            callback: (success, userInfo) => {
              dispatch({
                type: ActionType.LOADING,
                showLoading: false,
              });
              const domain = isLocalhost ? `http://${platformHost}` : `https://${gatewayDomain}`;
              // const tempUrl = Base64.encode(JSON.stringify({ token: getAccessToken() }));
              const tempUrl = setPlatformPayInfo({
                token: getAccessToken() || '',
                domain: reducersState.app.platformDomain,
                lang: reducersState.app.language,
                storeID: '',
                storeSlug: '',
                routeStoreSlug: '',
                user: 'guest',
              })
              window.location.href = `${domain}${createRouteUrl(
                paths.PLATFORM_PAY_BILL,
                { storeSlug: routeParams.storeSlug, platformID },
              )}?${tempUrl}`;
              this.setState({ initLoading: false });
            }
          });
        }, 1000);
      }
    } catch (error) { }
  }

  handleLeftClick = () => {
    const { routeParams } = this.props;
    const { realDomain } = this.state;

    const { platformHost, isLocalhost } = getDomain(realDomain);
    const protocol = `http${isLocalhost ? '' : 's'}:`;
    window.location.href = `${protocol}//${platformHost}${createRouteUrl(
      paths.MENU_LIST,
      {
        storeSlug: routeParams.storeSlug,
      },
    )}`
  }

  render() {
    const { store, initLoading, isFromPage, storeConfig, payBill } = this.state;
    return (
      <Layout
        {...this.props}
        navContent={getIntl().page.payBill}
        className={prefix}
        navIcon={ isFromPage && <Icon type={IconType.LEFT_OUTLINED} className="html-embed-7 w-embed" />}
        onLeftClick={ isFromPage? this.handleLeftClick: undefined }
        isFooter={false}
      >
        {
          (initLoading || !store) && 
          <Skeleton />
        }
        {
          ((storeConfig && !storeConfig.payBill) || !payBill) &&
          <div>
            <Icon type={IconType.STATUS_FAILURE} />
            <div className="icon-bottom">{getIntl().page.paymentNotOpened}</div>
          </div>
        }
      </Layout>
    );
  }
}

export default PayBillPage;
