import React from 'react';
import validatorIsMobilePhone from 'validator/es/lib/isMobilePhone';
import { IObject } from '@/components/bm-common';
import Icon, { IconType } from '@/components/Icon';
import Panel from './Panel';
import SelectPayMethod from '@/components/SelectPayMethod';
import { PayTypes } from '@/lib/payment';
import { IGiftCard, IPayCard } from '@/actions/pay-action';
import InputItem from 'antd-mobile/es/input-item';
import Button from 'antd-mobile/es/button';
import Modal from 'antd-mobile/es/modal';
import CountryCode from '@/components/CountryCode';
import { IDialCodeGroupItem } from '@/components/DialCodeGroup';
import PayCardModify, { IPayCardInstance, usePayCardModify } from '@/components/PayCardModify';
import { giftCardToPayData } from '@/utils/pay';
import { ICheckoutPageState, ICheckoutPageProps } from './CheckoutPage';
import cloneDeep from 'lodash/cloneDeep';
import ActionType from '@/actions/action-type';
import { sameItemInMultipleArray } from '@/utils';
import CheckoutMethodButton from './CheckoutMethodButton';
import { getIntl } from '@/components/App/App';

interface IGuestCheckoutProps extends ICheckoutPageProps, ICheckoutPageState {
  language: string;
  resetState<K extends keyof ICheckoutPageState>(
      state: ((prevState: Readonly<ICheckoutPageState>) => (Pick<ICheckoutPageState, K> | ICheckoutPageState | null)) | (Pick<ICheckoutPageState, K> | ICheckoutPageState | null),
      callback?: () => void
  ): void;
  onClick: (selected: boolean) => void;
}

interface IGuestCheckoutState {
  phoneNumberInputFocus: boolean;
}

class GuestCheckout extends React.Component<IGuestCheckoutProps, IGuestCheckoutState> {
  routeParams: IObject = {};
  payCardModify: IPayCardInstance | undefined;

  constructor(props: IGuestCheckoutProps) {
    super(props);

    const [payCardModify] = usePayCardModify();
    this.payCardModify = payCardModify;
    this.state = {
      phoneNumberInputFocus: false,
    }
  }

  handlePayMethodClick = (method: PayTypes, data?: IGiftCard | IPayCard) => {
    const paymentData: IObject[] = [];
    
    const {
      // phoneNumber,
      initialTotal,
      resetState,
    } = this.props;
    // if (!phoneNumber) {
    //   const tips = getIntl().common.tips;
    //   const textContent = getIntl().page.signInPhoneNumberPlacehodler;
    //   Modal.alert(tips, textContent, [
    //     {
    //       text: getIntl().common.confirm,
    //       onPress: () => {},
    //     }
    //   ]);
    //   return;
    // }

    let { selectedGiftCard } = this.props;

    let giftCardPaymentData: IObject[] = [];
    let balance = 0;
    let amount = initialTotal;
    if (selectedGiftCard && selectedGiftCard.balance < initialTotal) {
      balance = selectedGiftCard.balance || 0;
      amount = initialTotal - balance;
      giftCardPaymentData.push(giftCardToPayData(selectedGiftCard, initialTotal, balance));
    }

    if (method === PayTypes.CREDIT_CARD) {
      resetState({
        showPayMethod: false,
        showPayCardUI: true,
      })
    } else if ([PayTypes.ANONYMOUS_GIFT_CARD, PayTypes.STORE_CREDIT].includes(method)) {
      if (data) {
        const giftCard = data as IGiftCard;
        if (selectedGiftCard && selectedGiftCard.id === giftCard.id) {
          selectedGiftCard = undefined;
        } else {
          selectedGiftCard = giftCard;
        }

        resetState({ selectedGiftCard });

        if (giftCard.balance >= initialTotal) {
          paymentData.push(giftCardToPayData(giftCard, initialTotal, 0));
          giftCardPaymentData = [];
        }
      }
    } else if ([PayTypes.VISA, PayTypes.MASTER, PayTypes.AMEX, PayTypes.UNION_PAY].includes(method)) {
      if (data) {
        const creditCard = data as IPayCard;
        resetState({ selectedMethod: `${creditCard.type}_${creditCard.id}` });

        paymentData.push({
          amount,
          type: method,
          paymentMethod: 4,
        });
      }
    } else if (method === PayTypes.APPLE_PAY) {
      resetState({ selectedMethod: method });
      paymentData.push({
        amount,
        type: PayTypes.APPLE_PAY,
        paymentMethod: 18,
      });
    } else if ([PayTypes.ALIPAY, PayTypes.ALIPAY_HK].includes(method)) {
      resetState({ selectedMethod: method });
      paymentData.push({
        amount,
        type: method,
        paymentMethod: 19,
      });
    } else if (method === PayTypes.TAP_GO) {
      resetState({ selectedMethod: method });
      paymentData.push({
        amount,
        type: PayTypes.TAP_GO,
        paymentMethod: 21,
      });
    } else if (method === PayTypes.WECHAT_PAY) {
      resetState({ selectedMethod: method });
      paymentData.push({
        amount,
        type: PayTypes.WECHAT_PAY,
        paymentMethod: 17,
      });
    } else if (method === PayTypes.GOOGLE_PAY) {
      resetState({ selectedMethod: method });
      paymentData.push({
        amount,
        type: PayTypes.GOOGLE_PAY,
        paymentMethod: 23,
      });
    }

    if (paymentData.length > 0) {
      paymentData.push(...giftCardPaymentData);
      this.handleCreateOrderForGuest(paymentData);
    }
  }

  handleCreateOrderForGuest = (paymentData?: IObject[]) => {
    const { phoneNumber, dialCodeGroupItem, storeSlug, routeParams, isSplitOrder } = this.props;
    if (dialCodeGroupItem) {
      const number = phoneNumber.replace(/\s+/g, '') || '00000000';
      const { dispatch } = this.props;
      dispatch({
        type: ActionType.LOADING,
        showLoading: true,
        showLoadingBgc: true,
      });

      const params: any = {};

      if (Array.isArray(paymentData) && paymentData.length > 0) {
        params.paymentData = JSON.stringify(paymentData);
      }

      dispatch({
        type: ActionType.GUEST_SIGN_UP_AND_CREATE_ORDER,
        storeSlug,
        phoneNumber: number,
        dialCode: dialCodeGroupItem.dialCode,
        isSplitOrder,
        ...params,
        callback: params => {
          if (params.tag === 'SUCCESS') {
            window.location.href = routeParams.fromPage === 'eShop' ? `${params.orderConfirmUrl}&fromPage=eShop` : params.orderConfirmUrl;
          } else if (params.message && params.message.includes('listing_id')) {
            Modal.alert(getIntl().page.tips, getIntl().page.outOfStock, [
              {
                text: getIntl().page.logInAgain,
                onPress: () => { },
              }
            ]);
            dispatch({
              type: ActionType.LOADING,
              showLoading: false,
            });
          }
          else {
            dispatch({
              type: ActionType.LOADING,
              showLoading: false,
            });
          }
        }
      });
    }
  }

  handleCountryCodeChange = (data: IDialCodeGroupItem) => {
    const { resetState } = this.props;
    resetState({ dialCodeGroupItem: data })
  }

  handleContinueClick = async () => {
    const { initialTotal, anonymousGiftCards, resetState, dispatch } = this.props;
    let { selectedGiftCard } = this.props;
    if (initialTotal <= 0 || !this.payCardModify) return;

    resetState({ adding: true });
    let showModal = false;
    let textContent = '';
    dispatch({
      type: ActionType.LOADING,
      showLoading: true,
      showLoadingBgc: true,
    });
    const result = await this.payCardModify.save();
    dispatch({
      type: ActionType.LOADING,
      showLoading: false,
    });
    if (result) { // 数据提交成功
      if ([PayTypes.STORE_CREDIT, PayTypes.ANONYMOUS_GIFT_CARD].includes(result.type)) {
        const giftCard = cloneDeep(result as IGiftCard);
        const gcPayData = giftCardToPayData(giftCard, initialTotal, 0);
        if (!giftCard.isGiftCard) {
          showModal = true;
          textContent = getIntl().page.giftCardAlreadyBound;
        } else {
          if (initialTotal <= giftCard.balance) {
            this.handleCreateOrderForGuest([gcPayData]);
          } else {
            const newAnonymousGiftCards: IGiftCard[] = [];
            anonymousGiftCards.forEach(item => {
              if (item.number !== giftCard.number) {
                newAnonymousGiftCards.push(item)
              }

            })
            newAnonymousGiftCards.push(giftCard);
            if (selectedGiftCard && selectedGiftCard.number === giftCard.number) {
              selectedGiftCard = giftCard;
            } else {
              selectedGiftCard = giftCard;
            }

            resetState({
              selectedGiftCard,
              anonymousGiftCards: newAnonymousGiftCards,
              showPayCardUI: false,
              showPayMethod: true,
              adding: false,
            });
          }
        }
      } else { // 添加银行卡
        let amount = initialTotal;
        const paymentData: IObject[] = [];
        if (selectedGiftCard) {
          const gcPayData = giftCardToPayData(selectedGiftCard, initialTotal, selectedGiftCard.balance);
          amount = initialTotal - selectedGiftCard.balance;
          paymentData.push(gcPayData);
        }

        paymentData.push({
          id: result.id,
          amount,
          type: result.type,
          paymentMethod: 4,
          number: result.number,
          cvv: result.cvv,
          expMonth: result.expMonth,
          expYear: result.expYear,
        });

        this.handleCreateOrderForGuest(paymentData);
      }
    } else {
      showModal = true;
      textContent = getIntl().page.payCardError;
    }

    if (showModal) {
      const tips = getIntl().common.tips;
      Modal.alert(tips, textContent, [
        {
          text: getIntl().common.confirm,
          onPress: () => {
            resetState({ adding: false });
          },
        }
      ]);
    }
  }

  handleReadyChange = (isReady: boolean) => {
    const { resetState } = this.props;
    resetState({
      isReady,
    })
  }

  handlePhoneNumberChange = (value: string) => {
    const { resetState } = this.props;
    const number = value.replace(/\s+/g, '');
    const isMobilePhone = validatorIsMobilePhone(number);

    resetState({
      phoneNumber: value,
      isMobilePhone,
    });
  }

  // 处理支持的支付方式, 平台 店铺 个人都支持才能真正支持
  handleSupportPayMethod = (): PayTypes[] => {
    const { reducersState, routeParams } = this.props;
    const { platformAppSettings } = reducersState.app;
    const { slugRefStoreConfig } = reducersState.store;
    const currentStoreconfig = slugRefStoreConfig[routeParams.storeSlug];

    let canPayMethods: PayTypes[] = [];
    if (platformAppSettings) {
      const platformPayMethodKeys = platformAppSettings.payMethodKeys;
      const storeConfigmethodKeys = currentStoreconfig && currentStoreconfig.payMethodKeys.length > 0 ? currentStoreconfig.payMethodKeys : [PayTypes.VISA, PayTypes.MASTER, PayTypes.AMEX, PayTypes.UNION_PAY];
      canPayMethods =  sameItemInMultipleArray(platformPayMethodKeys, storeConfigmethodKeys);
    }

    return canPayMethods;
  }

  render() {
    const {
      language,
      anonymousGiftCards,
      storeID,
      payCards,
      selectedGiftCard,
      selectedMethod,
      phoneNumber,
      showPayMethod,
      showPayCardUI,
      isReady,
      adding,
      dialCodeGroupItem,
      showWxMiniProgram,
      dispatch,
      resetState,
      onClick,
      showGooglePay,
    } = this.props;

    const canPayMethods = this.handleSupportPayMethod();

    let continueBtnDisabled = true;
    if (isReady && !adding) {
      continueBtnDisabled = false;
    }

    let dialCode = '';
    if (dialCodeGroupItem) {
      dialCode = dialCodeGroupItem.dialCode;
    }

    const { phoneNumberInputFocus } = this.state;

    return (
      <div className="section-4">
        <div className="container-6 w-container">
          <Panel
            serialNumber={1} 
            title={getIntl().page.chooseCheckoutMethod}
          >
            <CheckoutMethodButton
              type={IconType.GUEST_OUTLINED}
              title={getIntl().page.continueAsGuest}
              onClick={onClick}
            >
            </CheckoutMethodButton>
          </Panel>
          <Panel
            serialNumber={2}
            title={getIntl().page.guestCheckout}
          >
            <div className="div-block-525 _500px">
              <InputItem
                autoComplete="off"
                placeholder={getIntl().page.signInPhoneNumberPlacehodler}
                onChange={this.handlePhoneNumberChange}
                value={phoneNumber}
                type="bankCard"
                className={`phone-number ${phoneNumberInputFocus ? 'pn-focus' : ''}`}
                onFocus={() => this.setState({ phoneNumberInputFocus: true })}
                onBlur={() => this.setState({ phoneNumberInputFocus: false })}
                extra={
                  phoneNumber &&
                  <Icon
                    type={IconType.CLOSE_OUTLINED}
                    className="c-icon-close"
                    onClick={() => resetState({ phoneNumber: '', isMobilePhone: false })}
                  />
                }
              >
                <CountryCode
                  language={language}
                  onChange={this.handleCountryCodeChange}
                />
              </InputItem>
            </div>
          </Panel>
        </div>
        <Panel
          serialNumber={3}
          title={getIntl().page.selectPaymentMethod}
          rightContent={
            showPayCardUI &&
            <span onClick={() => {
              resetState({
                showPayCardUI: false,
                showPayMethod: true,
              });
            }}>
              {getIntl().common.change}
            </span>
          }
        >
          <div className="div-block-525 _500px-vertical">
            {
              showPayMethod &&
              <SelectPayMethod
                className="page-content"
                storeGiftCards={[]}
                anonymousGiftCards={anonymousGiftCards}
                payCards={payCards}
                selectedGiftCard={selectedGiftCard}
                selectedMethod={selectedMethod}
                onItemClick={this.handlePayMethodClick}
                canPayMethods={canPayMethods}
                showWxMiniProgram={showWxMiniProgram}
                showGooglePay={showGooglePay}
              />
            }
            {
              showPayCardUI &&
              <div className="c-pay-card-modify">
                <PayCardModify
                  dispatch={dispatch}
                  storeID={storeID}
                  phoneNumber={phoneNumber.replace(/\s+/g, '')}
                  dialCode={dialCode}
                  isGuest={true}
                  payCardModify={this.payCardModify}
                  onReadyChange={this.handleReadyChange}
                  reducersState={this.props.reducersState}
                />
                <Button
                  type="primary"
                  className="c-input-item"
                  disabled={continueBtnDisabled}
                  onClick={this.handleContinueClick}
                >
                  {getIntl().common.continue}
                </Button>
              </div>
            }
          </div>
        </Panel>
      </div>
    );
  }
}

export default GuestCheckout;
