import produce from 'immer';
import { handleActions } from 'redux-actions';
import ActionType from '@/actions/action-type';
import { IPayMethod } from '@/lib/payment';
import {
  IPayCard,
  IPushPayCard,
  ISetPayCards,
  IModifyPayCard,
  IRemovePayCard,
  IGiftCard,
  IPushAnonymousGiftCards,
  ISetStoreGiftCards,
  IModifyPayMethods,
  ISetFromUrlPayData,
} from '@/actions/pay-action';

export interface IPayState {
  payCards: IPayCard[] | undefined;
  keyRefGiftCards: {[key: string]: IGiftCard[]}; // key: `${storeSlug}_${customerID}`
  anonymousGiftCards: IGiftCard[];
  payMethods: IPayMethod[];
  fromUrlPayData: any[] | undefined; // 从地址中获取支付方式，在查询订单时转为payMethods
}

const initState: IPayState = {
  payCards: undefined,
  keyRefGiftCards: {},
  anonymousGiftCards: [],
  payMethods: [],
  fromUrlPayData: undefined,
};

const actions: any = {};

actions[ActionType.PUSH_PAY_CARD] = (state: IPayState, action: IPushPayCard) => produce(state, draft => {
  if (!Array.isArray(draft.payCards)) {
    draft.payCards = [];
  }

  draft.payCards.push(action.payCard);
});

actions[ActionType.SET_PAY_CARDS] = (state: IPayState, action: ISetPayCards) => produce(state, draft => {
  draft.payCards = action.payCards;
});

actions[ActionType.MODIFY_PAY_CARD] = (state: IPayState, action: IModifyPayCard) => produce(state, draft => {
  if (!Array.isArray(draft.payCards)) {
    draft.payCards = [];
  }

  const newPayCards = draft.payCards;

  draft.payCards = [];
  newPayCards.forEach(item => {
    if (draft.payCards) {
      if (Number(item.id) === Number(action.payCard.id)) {
        draft.payCards.push(action.payCard);
      } else {
        draft.payCards.push(item);
      }
    }
  })
});

actions[ActionType.REMOVE_PAY_CARD] = (state: IPayState, action: IRemovePayCard) => produce(state, draft => {
  if (Array.isArray(draft.payCards)) {
    draft.payCards = draft.payCards.filter(item => Number(item.id) !== Number(action.cardID));
  }
});

actions[ActionType.PUSH_ANONYMOUS_GIFT_CARDS] = (state: IPayState, action: IPushAnonymousGiftCards) => produce(state, draft => {
  const numbers = action.giftCards.map(item => item.number);
  const newAnonymousGiftCards = draft.anonymousGiftCards.filter(item => !numbers.includes(item.number))
  draft.anonymousGiftCards = [...newAnonymousGiftCards, ...action.giftCards];
});

actions[ActionType.SET_STORE_GIFT_CARDS] = (state: IPayState, action: ISetStoreGiftCards) => produce(state, draft => {
  if (!Array.isArray(draft.keyRefGiftCards[action.key])) {
    draft.keyRefGiftCards[action.key] = [];
  }

  draft.keyRefGiftCards[action.key].push(...action.giftCards);
});

actions[ActionType.MODIFY_PAY_METHODS] = (state: IPayState, action: IModifyPayMethods) => produce(state, draft => {
  draft.payMethods = [...action.payMethods];
});

actions[ActionType.SET_FROM_URL_PAY_DATA] = (state: IPayState, action: ISetFromUrlPayData) => produce(state, draft => {
  draft.fromUrlPayData = action.fromUrlPayData;
});

export default handleActions(actions, initState);
