import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import SignIn  from '@/components/SignIn';
import paths from '@/routes/paths';
import Icon, { IconType } from '@/components/Icon';
import { getAppTheme, setScreenColor, getIntl } from '@/components/App/App';
import ActionType from '@/actions/action-type';
import { TransitionKeys } from '@/components/Transition';
import SignMethodType from '@/components/common/userComponents/MethodType';
import SignInBottom from '@/components/SignIn/SignInBottom';
import { prefix } from '.';
import Layout from '@/components/Layout/Layout';


export interface ISignPageProps extends IBasePageProps {}

class SignPage extends BasePage<ISignPageProps> {

  state = {
    signType: SignMethodType.INIT,
  }

  componentDidMount() {
    const appTheme = getAppTheme();
    setTimeout(() => {
      if (appTheme.primaryColor && appTheme.lightBackgroundColor) {
        setScreenColor(appTheme.primaryColor, appTheme.lightBackgroundColor);
      }
    }, 300)

    super.componentDidMount();
  }

  signIn = () => {
    const { dispatch, reducersState } = this.props;
    const { suppliers, showSelectionPage } = reducersState.app.platformAppSettings || {};

    dispatch({
      type: ActionType.CLEAR_CUSTOMER,
    });
    dispatch({
      type: ActionType.CLEAR_CUSTOMER_GROWTH,
    });

    // suppliers 特殊逻辑, 跳转路由不同
    if (suppliers) {
      this.handleGoBack({
        path: paths.SELECT_STORES,
        runGoBack: true,
        animationType: TransitionKeys.FADE_BACK_BOTTOM,
      });
    } else {
      this.handleGoBack({
        path: paths.HOME,
        runGoBack: showSelectionPage ? false : true,
        animationType: showSelectionPage ? undefined : TransitionKeys.FADE_BACK_BOTTOM,
      });
    }
  }

  forgotPassword = () => {
    this.handleGotoRoute({ path: paths.FORGOT_PASSWORD });
  }

  getBackIcon = () => {
    const { reducersState } = this.props;
    const { suppliers } = reducersState.app.platformAppSettings || {};

    if (!suppliers) {
      return <Icon className="html-embed-7 w-embed" type={IconType.LEFT_OUTLINED}/>
    }

    return null;
  }

  getLeftClick = () => {
    const { reducersState } = this.props;
    const { suppliers, showSelectionPage } = reducersState.app.platformAppSettings || {};

    if (!suppliers) {
      return this.handleGoBack({
        path: paths.HOME,
        runGoBack: true,
        animationType: showSelectionPage ? undefined : TransitionKeys.FADE_BACK_BOTTOM,
      });
    }

    return null;
  }

  render () {
    const { reducersState } = this.props;
    const { signType } = this.state;

    const signInMethods = reducersState.app.platformConfig.signInMethods;
    return (
      <Layout
        {...this.props} 
        navIcon={this.getBackIcon()}
        onLeftClick={this.getLeftClick}
        navContent={getIntl().page.signin}
        isFooter={false}
        className={prefix}
      >
        <SignIn
          signInClick={this.signIn}
          forgotClick={this.forgotPassword}
          setSignMethod={type => this.setState({signType: type})}
        >
          {
            signType === SignMethodType.INIT && signInMethods.length !== 1 &&
            <SignInBottom
              className={`${prefix}-bottom`}
            />
          }
        </SignIn>
      </Layout>
    );
  }
}

export default SignPage
