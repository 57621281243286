import React from 'react';
import NewsPanel from './NewsPanel';
import { IBlog } from '@/actions/store-action';
import { IReducersState } from '@/reducers';
import { prefix } from '.';

interface INewsListProps {
  data: IBlog[];
  onItemClick?: (data: IBlog) => void;
  showDate?: boolean;
  reducersState: IReducersState;
  className?: string;
}

const NewsList: React.FC<INewsListProps> = props => {
  const { onItemClick, data, showDate = false, reducersState, className } = props;
  return (
    <>
      <div data-w-tab="Tab 3" className={`tab-pane-tab-3 w-tab-pane w-items ${prefix} ${className}`}>
        <div data-w-id="33aefb9e-dec0-493c-5154-945caeeeb6f2" className="section tinted white open-modal">
          <div className="container menu-w-container">
            <div className="news-true">
              {
                data && data.length > 0 && data.map(item => (
                  <NewsPanel
                    data={item}
                    key={item.id}
                    onItemClick={() => { onItemClick && onItemClick(item) }}
                    showDate={showDate}
                    reducersState={reducersState}
                  />
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewsList;
