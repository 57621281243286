
import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import { IObject } from '@/components/bm-common';
import { getAppTheme, setScreenColor } from '@/components/App/App';
import BmTabBar from '@/components/BmTabBar';
import TabsOption from '@/actions/tabs-option';
import Constants from '@/constants';
import ShoppingCart from '@/pages/ShoppingCartPage';
import Skeleton from './Skeleton';
import paths from '@/routes/paths';

export interface IExpressOrderProps extends IBasePageProps {
  isHomePage?: boolean;
}
export interface IExpressOrderState {
}

class ExpressOrder extends BasePage<IExpressOrderProps, IExpressOrderState> {
  routeParams: IObject = {};

  state: IExpressOrderState = {
  }

  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.primaryColor && appTheme.darkBackgroundColor) {
      setScreenColor(appTheme.primaryColor, appTheme.darkBackgroundColor);
    }
    super.componentDidMount();
    // this.openLoading();
    this.autoCloseLoading = false;
  }
  onLeftClick = () => {
    this.handleGoBack({
      path: paths.HOME,
    })
  }

  render() {
    const { reducersState } = this.props;
    const { platformConfig } = reducersState.app;
    return (
      <>
        <ShoppingCart
          { ...this.state }
          { ...this.props }
          forExpressOrder
          ExpressSkeleton = {<Skeleton onLeftClick={this.onLeftClick}/>}
        />
        {
          platformConfig.showTabs && localStorage.getItem(Constants.ADD_EXPRESS_ORDER) !== 'true' &&
          <BmTabBar
            selectedKey={TabsOption.EXPRESS_ORDER}
            reducersState={reducersState}
          />
        }
      </>
    );
  }
}

export default ExpressOrder;
