import { IStoreCategory } from '@/actions/store-action';
import { IReducersState } from '@/reducers';
import { getI18nText, handleStoreCategoryTypesScroll } from '@/utils/app';
import React from 'react';
import { getIntl } from '@/components/App/App';

export const prefix = 'bm-p-stores-by-category-types';

interface ICategoryTypesProps {
  brandsCategory: string;
  data: IStoreCategory[];
  className?: string;
  selected: string;
  reducersState: IReducersState;
  onItemClick?: (type: string) => void;
}

const CategoryTypes: React.FC<ICategoryTypesProps> = props => {
  const {
    brandsCategory,
    data,
    // className = '',
    selected,
    reducersState,
    onItemClick,
  } = props;

  const handleItemClick = (type: string) => {
    handleStoreCategoryTypesScroll(type);
    if (onItemClick) {
      onItemClick(type);
    }
  }

  return (
    <div className="overlay-nav w-container" >
      <div className="drag-nav-bar w-container category-type" id="bm-store-service-types-box">
        <div className="div-content" id="bm-store-service-types">
          <div
            // id="bm-store-service-types"
            className={`in-page-link ${('0') === selected ? 'current' : ''}`}
            onClick={() => handleItemClick('0')}
          >
            {getIntl().page.all}
          </div>
          {
            data.map(item => {
              if (brandsCategory === item.brandsCategory) {
                return (
                  <div
                    key={item.id}
                    id={`bm-store-service-types-${item.id}`}
                    className={`in-page-link ${item.id === selected ? 'current' : ''}`}
                    onClick={() => handleItemClick(item.id)}
                  >
                    {getI18nText(item, 'category_name', reducersState.app.language)}
                  </div>
                )
              } else {
                return (<div key={item.id}></div>)
              }
            })
          }
        </div>
      </div>
    </div>
  );
}

export default CategoryTypes;
