import React from 'react';
import { prefix } from '.';
import { IStore, IStoreCategory } from '@/actions/store-action';
import StoreListItem from '@/components/StoreList/StoreListItem';
import { IReducersState } from '@/reducers';
import { getFlatternDistance } from '@/utils/app';
import { findNode } from '@/utils';

interface IRecommendedStoresProps {
  data: IStore[];
  className?: string;
  itemClassName?: string;
  reducersState: IReducersState;
  onItemClick?: (data: IStore) => void;
}

const RecommendedStores: React.FC<IRecommendedStoresProps> = props => {
  const {
    data,
    className = '',
    itemClassName = '',
    reducersState,
    onItemClick,
  } = props;

  const { coordinates } = reducersState.app;

  const getStoreCategoryInfo = (store: IStore) => {
    const newStoreCategories: IStoreCategory[] = [];
    if (store && Array.isArray(store.storeCategories)) {
      store.storeCategories.forEach(item => {
        const categoryNode = findNode<IStoreCategory>(reducersState.app.platformStoreBrands, 'id', item);
        if (categoryNode) {
          newStoreCategories.push(categoryNode);
        }
      })
    }
    return newStoreCategories;
  }

  const getDistanceTitle = (store: IStore) => {
    let distanceTitle = '';
    if (coordinates && store.lat && store.lng) {
      const distanceObj = getFlatternDistance(coordinates.latitude, coordinates.longitude, store.lat, store.lng);
      if (distanceObj && distanceObj.distanceTitle) {
        distanceTitle = distanceObj.distanceTitle;
      }
    }
    return distanceTitle;
  }

  return (
    <div className={`${prefix}-recommended-stores ${className}`}>
      <div className="store-list">
        {
          data.map(item => (
            <StoreListItem
              key={item.id}
              store={item}
              className={itemClassName}
              reducersState={reducersState}
              onClick={onItemClick}
              storeCategories={getStoreCategoryInfo(item)}
              distanceTitle={getDistanceTitle(item)}
            />
          ))
        }
        <div><div className="last-space" /></div>
      </div>
    </div>
  );
}

export default RecommendedStores;
