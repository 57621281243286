import { IRoute } from '@/routes';
import paths from '@/routes/paths';
import SignInPage from './SignInPage';
import SignUpPage from './SignUpPage';
import ForgotPasswordPage from './ForgotPasswordPage';
import SelectStoresPage from './SelectStoresPage';
import AccountPage from './AccountPage';
import AccountEditPage from './AccountEditPage';
import PaymentListPage from './PaymentListPage';
import AddCreditCardPage from './AddCreditCardPage';
import SelectSignInPage from './SelectSignInPage';
import AddressListPage from './AddressListPage';

export const mobileRoutes: IRoute[] = [
  {
    path: paths.SIGN_IN,
    component: SignInPage,
  },
  {
    path: paths.SIGN_UP,
    component: SignUpPage,
  },
  {
    path: paths.FORGOT_PASSWORD,
    component: ForgotPasswordPage,
  },
  {
    path: paths.SELECT_STORES,
    component: SelectStoresPage,
  },
  {
    path: paths.ACCOUNT,
    component: AccountPage,
  },
  {
    path: paths.ACCOUNT_EDIT,
    component: AccountEditPage,
  },
  {
    path: paths.ACCOUNT_EDIT_BY_FROM,
    component: AccountEditPage,
  },
  {
    path: paths.PAYMENT_LIST,
    component: PaymentListPage,
  },
  {
    path: paths.ADD_CREDIT_CARD,
    component: AddCreditCardPage,
  },
  {
    path: paths.EDIT_CREDIT_CARD,
    component: AddCreditCardPage,
  },
  {
    path: paths.SELECT_SIGN_IN,
    component: SelectSignInPage,
  },
  {
    path: paths.ADDRESS_LIST,
    component: AddressListPage,
  },
  {
    path: paths.STORE_ADDRESS_LIST,
    component: AddressListPage,
  },
];

export default mobileRoutes;
