import styled from 'styled-components';
export const FavoritePageStyled = styled.div`
  width: 85%;
  margin-top: 80px;
  padding: 40px 0px;
  border-radius: 12px;
  background-color: var(--bm-dark-background-color);
  padding-top: 40px;
  padding-bottom: 40px;
.w-container {
  max-width: unset;
}
.no-content-panel {
  display: flex;
  flex-direction: column;
  // margin-top: 300px;
  justify-content: center;
  align-items: center;

  .bm-icon {
    font-size: 300px;
  }
}
.bm-p-favorite-store-list {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 14px;
  grid-row-gap: 14px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}
.bm-p-favorite-store-list-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 280px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  padding-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-radius: 6px;
  background-color: var(--bm-light-background-color);
  color: var(--bm-text-title-color);
  text-decoration: none;
  cursor: pointer;
  .image-card-upper,
  .store-img {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    width: auto;
    height: 70%;
    max-height: 70%;
    min-height: 70%;
    margin-bottom: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border-radius: 0px;
    background-position: 50% 50%;
    background-size: cover;
  }
  .store-img {
    width: 100%;
    height: 100%;
    max-height: unset;
    min-height: unset;
  }
  .div-block-99 {
    height: 30%;
    max-height: 30%;
    min-height: 30%;
    padding: 0px 15px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
}
.bgimg-size.image-card-upper {
  padding-bottom: 0;
}
.section {
  display: block;
  margin-top: auto;
  margin-bottom: 0px;
  padding: 60px 40px 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
}
.section.address {
  padding-top: 0px;
  padding-bottom: 0px;
}
.loading-data,
.loading-msg {
  text-align: center;
}
@media screen and (max-width: 991px) {
  margin-left: 40px;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: var(--bm-dark-background-color);
  .bm-p-favorite-store-list {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    &-item {
      width: auto;
      margin-bottom: 0px;
    }
    .heading-4 {
      margin-bottom: 5px;
    }
  }
  .section {
    margin-top: 0px;
    padding: 40px 35px 30px;
  }
}
@media screen and (max-width: 767px) {
  width: auto;
  margin-top: 0px;
  margin-left: 0px;
  padding: 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  .bm-p-favorite-store-list {
    display: -ms-grid;
    display: grid;
    margin-top: 0px;
    margin-right: 30px;
    margin-left: 30px;
    grid-auto-columns: 1fr;
    grid-column-gap: 14px;
    grid-row-gap: 14px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
    &-item {
      width: 100%;
      height: 260px;
      margin-bottom: 0px;
    }
    .image-card-upper {
      height: 78%;
      max-height: 70%;
      min-height: 70%;
      border-radius: 0px;
    }
    .store-img {
      width: 100%;
      height: 100%;
    }
    h4 {
      font-family: 'Sf pro display', sans-serif;
      font-weight: 600;
    }
    .heading-4 {
      font-size: 16px;
      line-height: 22px;
    }
  }
  section {
    padding: 83px 25px 20px;
  }
  .section.address {
    padding: 30px 0px;
    background-color: rgba(242, 243, 248, 0);
  }
}
@media screen and (max-width: 479px) {
  .bm-p-favorite-store-list {
    margin-right: 12px;
    margin-left: 12px;
    &-item {
      width: 100%;
      height: 210px;
      margin-right: auto;
      margin-bottom: 0px;
    }
    .image-card-upper {
      height: 78%;
      margin-bottom: 0px;
      border-radius: 0px;
    }
    .store-img {
      width: 100%;
      height: 100%;
    }
    .div-block-99 {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      height: 23%;
      max-height: 30%;
      min-height: 30%;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
    h4 {
      font-size: 16px;
    }
    .heading-4 {
      margin-top: 0px;
      margin-bottom: 0px;
      font-size: 16px;
      line-height: 18px;
    }
  }
  .section {
    padding: 68px 12px 15px;
  }
  .section.address {
    padding-top: 20px;
    padding-right: 0px;
    padding-left: 0px;
  }
}
`;

