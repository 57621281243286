import Config from '@/Config';
import { createRecord, queryRecords } from './mb-api';

export interface IFeedbackTags {
  [feedBackScore: number]: ITag[];
}

interface ITag {
  id: string;
  tag: string;
}

export interface IGetFeedback {
  platformID: string;
  storeID: string;
  userID?: string;
  orderNumber?: string;
  page?: number;
}

export interface IFeedback {
  score: number;
  feedBackTag: string[];
  createdAt: string;
  userName: string;
  avatarUrl: string;
  id: string;
}

export const getFeedbackTags = async (platformID: string) => {
  const result = await queryRecords({
    storeSlugs: [Config.bindoGatewayStoreSlug],
    moduleBundleID: 'bm-feedback-tag',
    search: {
      formulas:[{formula: `EQ("$.self.platform_name", "${platformID}")`}],
      orderAsc: 'ASC',
      orderBy: 'feedback_score',
      page: 1,
      perPage: 1000,
    },
  });

  const feedBackTags: IFeedbackTags = {};
  result.records.forEach((item: any) => {
    const feedBackScore = item.feedback_score;
    if (feedBackTags[feedBackScore]) {
      feedBackTags[feedBackScore].push({id:item.id, tag:item.feedback_tag})
    } else {
      feedBackTags[feedBackScore] = [{id:item.id, tag:item.feedback_tag}];
    }
  });

  return feedBackTags;
}

export const getFeedbacks = async (params: IGetFeedback) => {
  const {
    platformID,
    storeID,
    userID,
    orderNumber,
    page = 1,
  } = params;

  let formula = ''
  if (userID && orderNumber) {
    formula = `.EQ("$.self.order_number", "${orderNumber}").EQ("$.self.user_id", "${userID}")`
  }

  const result = await queryRecords({
    storeSlugs: [Config.bindoGatewayStoreSlug],
    moduleBundleID: 'bm-feedback',
    search: {
      formulas:[{formula: `EQ("$.self.platform_id", "${platformID}").EQ("$.self.store_id", "${storeID}")${formula}`}],
      orderAsc: 'ASC',
      orderBy: 'id',
      page,
      perPage: 20,
    },
  });

  return result;
}

export const addFeedback = async (platformID: string, storeID: string, userID: string, feedbackTag: string[], score: number, orderNumber: string) => {
  const newFeedbackTag: any[] = [];
  feedbackTag.forEach(item => {
    newFeedbackTag.push({id: item});
  })

  const result = await createRecord({
    storeSlug: Config.bindoGatewayStoreSlug,
    moduleBundleID: 'bm-feedback',
    record: {
      'platform_id': {
        records: [{id: platformID}]
      },
      'store_id': {
        records: [{id: storeID}]
      },
      'user_id': {
        records: [{id: userID}]
      },
      'feedback_tag': {
        records: newFeedbackTag
      },
      'order_number': orderNumber,
      'score': score
    },
  });

  let newRecord = undefined;

  if (result.success && result.record) {
    newRecord = result.record;
  }

  return newRecord;
}
