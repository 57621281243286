import React from 'react';
import { prefix } from '.';
import Icon, { IconType } from '@/components/Icon';

interface SkeletonProps {
  onLeftClick: () => void;
}

const Skeleton: React.FC<SkeletonProps> = props => {
  const { onLeftClick } = props;
  return (
    <div className={`${prefix}-skeleton`}>
      <div className="skeleton-bm-c-dining">
        <div className="skeleton-icon">
          <Icon className="circle-back-icon-outlined" type={IconType.LEFT_OUTLINED} onClick={onLeftClick}/>
        </div>
      </div>
      <div className="skeleton-page-content">
        <div className="skeleton-main">
          <div className="skeleton-order-status">
            <div></div>
            <div></div>
          </div>
          <div className="skeleton-ds-content">
            <div className="skeleton-order-tip"></div>
            <div className="skeleton-buttons"></div>
          </div>
          <div className="skeleton-shipping-method">
            <div></div>
            <div></div>
          </div>

          <div className="skeleton-content">
            <div className="skeleton-single-store-order">
              <div className="skeleton-store-name"></div>
              <div className="skeleton-step">
                <div className="steps">
                  <div className="skeleton-steps">
                    <div className="steps1"></div>
                    <div className="steps2"></div>
                    <div className="skeleton1">
                      <div></div>
                      <div></div>
                    </div>
                    <div className="skeleton2">
                      <div></div>
                      <div></div>
                    </div>
                    <div className="skeleton3">
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
                <div className="qrcode"></div>
              </div>
            </div>

            <div className="skeleton-listings-item">
              <div className="item-icon-wrapper1"></div>
              <div className="item-icon-wrapper2">
                <div className="wrapper2-1"></div>
                <div className="wrapper2-2"></div>
                <div className="wrapper2-3"></div>
              </div>
              <div className="item-icon-wrapper3"></div>
            </div>
            <div className="skeleton-listings-item">
              <div className="item-icon-wrapper1"></div>
              <div className="item-icon-wrapper2">
                <div className="wrapper2-1"></div>
                <div className="wrapper2-2"></div>
                <div className="wrapper2-3"></div>
              </div>
              <div className="item-icon-wrapper3"></div>
            </div>
            <div className="line">
              <div></div>
              <div></div>
            </div>
            <div className="line-total">
              <div></div>
              <div></div>
            </div>
          </div>
          <div className="skeleton-transaction">
            <div className="skeleton-line1">
              <div></div>
              <div></div>
            </div>
            <div className="skeleton-line2">
              <div></div>
              <div></div>
            </div>
            <div className="skeleton-line3">
              <div></div>
              <div></div>
            </div>
            <div className="skeleton-line4">
              <div></div>
              <div></div>
            </div>
            <div className="skeleton-line3">
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Skeleton;
