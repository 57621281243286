import cloneDeep from 'lodash/cloneDeep';
import trim from 'lodash/trim';
import { getChannel, setFavoriteTabs, setModifiers } from '../models/Data';
import OrderEntity, { IOrderInfo, ITaxOption, IRoundingOption } from '../models/OrderEntity';
import PurchasableType from '../enums/PurchasableType';
import Config from '@/Config';
import { resizeImage } from '@/utils';
import ShippingMethod from '../enums/ShippingMethod';
import { IObject } from '@/components/bm-common';

export interface IListing {
  id: string;
  name: string;
  aliasName?: string;
  imageUrl: string;
  favoriteID: string;
  favoriteTabID: string;
  favoriteSectionID: string;
  purchasableID: string;
  purchasableType: PurchasableType;
  modifierIDs: string[];
  description: string;
  inventoryTags: string[];
  overwritePriceEnable?: boolean;
  price: number;
  status?: string;
  brand: string;
  soldOut: boolean;
  storeID: string;
  storeSlug: string;
  position: number;
  departmentID: string;
  kitchenDepartmentID: string;
  overwriteLineItemNameEnabled: boolean;
  productID: string;
  categoryID: string;
  procurementMethod: number[];
  modifiers: IModifier[];
  i18n: IObject;
}

export interface IFavoriteSection {
  id: string;
  name: string;
  position: number;
  storeID: string;
  storeSlug: string;
  listings: IListing[];
  i18n: IObject;
}

export interface ISpecialFavorite {
  hasAddition: boolean;
  listingID: string;
}

export interface IFavoriteTab {
  id: string;
  name: string;
  mediumImageUrl: string;
  availableDays: number[];
  availableMembershipLevels: string;
  availableTimeFrom: string;
  availableTimeTo: string;
  permits: number[];
  channels: number[];
  storeID: string;
  storeSlug: string;
  position: number;
  favoriteSections: IFavoriteSection[];
  specialFavorite: ISpecialFavorite;
  i18n: IObject;
}

interface IConvertFavoriteApiParams {
  storeID: string;
  storeSlug?: string;
}

interface IParseFavoriteTabParams extends IConvertFavoriteApiParams {}

interface IParseFavoriteSectionParams extends IParseFavoriteTabParams {}

interface IParseFavoriteParams extends IParseFavoriteSectionParams {
  favoriteTabID: string;
}

export const parseFavorite = (data: any, params: IParseFavoriteParams) => {
  let listing: IListing | undefined = undefined;
  const { storeSlug = '', favoriteTabID } = params || {};

  if (data && data.listing && data.listing.id) {
    const listingData = data.listing;
    const customAttributes = listingData.custom_attributes;

    listing = {
      id: listingData.id,
      name: listingData.name || '',
      aliasName: data.name || '',
      favoriteID: data.id,
      favoriteSectionID: data.favorite_section_id || '',
      favoriteTabID,
      imageUrl: resizeImage({
        url: listingData.image_url,
        type: 'MFIT',
        width: 650,
        height: 536,
        // defaultUrl: Config.defaultLineItemImg,
      }),
      modifierIDs: [],
      description: listingData.description || '',
      inventoryTags: [],
      overwritePriceEnable: data.overwrite_price,
      price: data.overwrite_price ? data.price : listingData.price,
      status: listingData.status,
      brand: listingData.brand,
      soldOut: listingData.sold_out,
      position: data.position || 999999,
      storeID: data.store_id,
      storeSlug,
      productID: listingData.product_id || '',
      categoryID: listingData.category_id || '',
      departmentID: listingData.department_id || '',
      kitchenDepartmentID: listingData.kitchen_department_id || '',
      overwriteLineItemNameEnabled: listingData.overwrite_line_item_name_enabled || false,
      procurementMethod: [],
      purchasableID: data.source_id || listingData.id || '',
      purchasableType: data.source_type || PurchasableType.LISTING,
      modifiers: [],
      i18n: listingData.translation_info,
    }

    const procurementMethodData: number[] = listingData.procurement_method || [];
    if (Array.isArray(procurementMethodData)) {
      if (procurementMethodData.includes(1)) {
        listing.procurementMethod.push(ShippingMethod.DINE_IN);
      }

      if (procurementMethodData.includes(2)) {
        listing.procurementMethod.push(ShippingMethod.PICK_UP);
      }

      if (procurementMethodData.includes(4)) {
        listing.procurementMethod.push(ShippingMethod.DELIVERY);
      }

      if (procurementMethodData.includes(8)) {
        listing.procurementMethod.push(ShippingMethod.DINE_IN_UNASSIGNED);
      }
    }

    if (listing) {
      if (Array.isArray(listingData.modifier_set_ids)) {
        listing.modifierIDs = listingData.modifier_set_ids;
      }
  
      if (customAttributes) {
        if (customAttributes.p_description) {
          listing.description = customAttributes.p_description;
        }
        if (Array.isArray(customAttributes.inventory_tags)) {
          listing.inventoryTags = customAttributes.inventory_tags;
        }
      }
    }
  }

  return listing;
}

const parseFavoriteSection = (data: any, params: IParseFavoriteSectionParams) => {
  let favoriteSection: IFavoriteSection | undefined = undefined;
  let resFavorites: any[] = [];
  const listings: IListing[] = [];
  const { storeSlug = '' } = params || {};

  if (data) {
    favoriteSection = {
      id: data.id,
      name: data.name || '',
      position: data.position || 999999,
      storeID: data.store_id || '',
      storeSlug,
      listings: [],
      i18n: data.translation_info,
    }

    if (Array.isArray(data.favorites)) {
      resFavorites = data.favorites
    }
  }

  if (favoriteSection) {
    resFavorites.forEach(item => {
      const favorite = parseFavorite(item, {
        ...params,
        favoriteTabID: data.favorite_tab_id || '',
      });
      if (favorite) {
        listings.push(favorite);
      }
    });

    favoriteSection.listings = listings.sort((f1, f2) => f1.position - f2.position);
  }

  return favoriteSection;
}

const parseFavoriteTab = (data: any, params: IParseFavoriteTabParams) => {
  let favoriteTab: IFavoriteTab | undefined = undefined;
  let sections: any[] = [];
  const favoriteSections: IFavoriteSection[] = [];
  const { storeSlug = '' } = params || {};

  if (data) {
    favoriteTab = {
      id: data.id || '',
      name: data.name || '',
      mediumImageUrl: resizeImage({
        url: data.medium_image_url,
        type: 'PAD',
        width: 520,
        height: 520,
        defaultUrl: Config.defaultLineItemImg,
      }),
      availableDays: [],
      availableMembershipLevels: data.available_membership_levels || 'ALL',
      availableTimeFrom: data.available_time_from || '',
      availableTimeTo: data.available_time_to || '',
      permits: [],
      channels: [],
      storeID: data.store_id || '',
      storeSlug,
      position: data.position || 999999,
      favoriteSections: [],
      specialFavorite: {
        hasAddition: false,
        listingID: '',
      },
      i18n: data.translation_info,
    }
    
    if (Array.isArray(data.favorite_sections)) {
      sections = data.favorite_sections;
    }

    if (data.favorite && data.favorite.listing_id) {
      favoriteTab.specialFavorite.hasAddition = data.favorite.has_addition || false;
      favoriteTab.specialFavorite.listingID = data.favorite.listing_id || '';
    }

    if (Array.isArray(data.available_days)) {
      // day必须转为数字
      favoriteTab.availableDays = data.available_days.map((day: any) => {
        const newDay = Number(day) - 1;
        return newDay;
      });
    }
    

    if (Array.isArray(data.permits)) {
      favoriteTab.permits = data.permits;
      favoriteTab.channels = data.permits;
    }
  }

  if (favoriteTab) {
    sections.forEach(item => {
      const favoriteSection = parseFavoriteSection(item, params);
      if (favoriteSection && favoriteSection.listings.length > 0) {
        favoriteSections.push(favoriteSection);
      }
    });
    favoriteTab.favoriteSections = favoriteSections.sort((fs1, fs2) => fs1.position - fs2.position);
  }

  return favoriteTab;
}

export const convertFavoriteApiData = (res: any, params: IConvertFavoriteApiParams) => {
  let favoriteTabs: IFavoriteTab[] = [];
  const channel = getChannel();
  const { storeID } = params;

  let resData: any[] = [];
  if (Array.isArray(res)) {
    resData = res;
  }

  resData.forEach(item => {
    let favoriteTab: IFavoriteTab | undefined = undefined;
    const { favorite_tab: ft } = item || {};
    if (ft && !ft.deleted && Array.isArray(ft.permits) && ft.permits.includes(channel)) {
      favoriteTab = parseFavoriteTab(ft, params);
    }

    if (favoriteTab && favoriteTab.favoriteSections.length > 0) {
      favoriteTabs.push(favoriteTab);
    }
  })

  favoriteTabs = favoriteTabs.sort((ft1, ft2) => ft1.position - ft2.position);
  setFavoriteTabs(storeID, favoriteTabs);

  return cloneDeep(favoriteTabs);
}

export interface IModifier {
  id: string;
  name: string;
  optionID: string;
  storeID: string;
  storeSlug: string;
  position: number;
  children: IModifier[];
  channels: number[];
  departmentIDs: string[];
  productIDs: string[];
  enableCreditPool: boolean;
  departmentID: string;
  productID: string;
  categoryID: string;
  modifierIDs: string[];
  soldOut: boolean;
  default: boolean;
  variablePricing: boolean;
  quantity: number;
  listingID: string;
  price: number;
  chooseAtLeast: number;
  chooseSameOption: boolean;
  chooseUpTo: number;
  i18n: IObject;
  imageUrl: string;
}

interface IConvertModifierApiParams {
  storeID: string;
  storeSlug?: string;
}

interface IParseModifierParams extends IConvertModifierApiParams {}
interface IParseModifierOptionParams extends IConvertModifierApiParams {
  channels: number[];
}

const parseModifierOption = (data: any, params: IParseModifierOptionParams) => {
  let modifierOption: IModifier | undefined = undefined;
  const { storeSlug = '', storeID = '', channels } = params || {};

  if (data && !data.deleted) {
    modifierOption = {
      id: data.id || '',
      name: data.option_name || '',
      optionID: data.modifier_set_option_id || '',
      storeID,
      storeSlug,
      position: data.priority || 999999,
      productID: data.product_id || '',
      categoryID: data.category_id || '',
      departmentID: '',
      modifierIDs: [],
      soldOut: data.sold_out || false,
      default: data.default || false,
      variablePricing: data.variable_pricing || false,
      quantity: data.quantity || null,
      listingID: data.listing_id || 0,
      enableCreditPool: data.enable_credit_pool || false,
      children: [],
      productIDs: [],
      departmentIDs: [],
      channels: channels || [],
      price: data.change_in_price || 0,
      chooseAtLeast: data.choose_at_least || 0,
      chooseSameOption: data.choose_same_option || false,
      chooseUpTo: data.choose_up_to || 1,
      i18n: data.translation_info,
      imageUrl: data.image_url || '',
    }
  }

  return modifierOption;
}

const parseModifier = (data: any, params: IParseModifierParams) => {
  let modifier: IModifier | undefined = undefined;
  let options: any[] = [];
  const modifierOptions: IModifier[] = [];
  const { storeSlug = '' } = params || {};

  let channels: number[] = [];
  if (data) {
    modifier = {
      id: data.id || '',
      name: data.title || '',
      optionID: data.modifier_set_option_id || '',
      storeID: data.store_id || '',
      storeSlug,
      position: data.priority || 999999,
      children: [],
      channels: [],
      departmentIDs: [],
      productIDs: [],
      enableCreditPool: false,
      departmentID: '',
      productID: '',
      categoryID: '',
      modifierIDs: [],
      soldOut: false,
      default: false,
      variablePricing: false,
      quantity: 0,
      listingID: '',
      price: 0,
      chooseAtLeast: data.choose_at_least || 0,
      chooseSameOption: data.choose_same_option || false,
      chooseUpTo: data.choose_up_to || 0,
      i18n: data.translation_info,
      imageUrl: data.image_url || '',
    }
    
    if (Array.isArray(data.modifier_set_options)) {
      options = data.modifier_set_options;
    }

    if (Array.isArray(data.channels)) {
      channels = data.channels;
      modifier.channels = channels;
      
    }

    if (Array.isArray(data.department_ids)) {
      modifier.departmentIDs = data.department_ids;
    }

    if (Array.isArray(data.product_ids)) {
      modifier.productIDs = data.product_ids;
    }
  }

  if (modifier) {
    options.forEach(item => {
      const modifierOption = parseModifierOption(item, {...params, channels});
      if (modifierOption) {
        modifierOptions.push(modifierOption);
      }
    });
    modifier.children = modifierOptions.sort((mo1, mo2) => mo1.position - mo2.position);
  }

  return modifier;
}

const findModifiers = (data: IModifier, modifiers: IModifier[]) => {
  const newModifiers: IModifier[] = [];

  if (data && Array.isArray(modifiers)) {
    modifiers.forEach(item => {
      if (
        item.departmentIDs.includes(data.departmentID)
        || item.productIDs.includes(data.productID)
        || data.modifierIDs.includes(item.id)
      ) {
        newModifiers.push(item);
      }
    });
  }

  return newModifiers;
};

const addRefForModifierOption = (modifiers: IModifier[]) => {
  modifiers.forEach(item => {
    item.children.forEach(item => {
      item.children = findModifiers(item, modifiers);
    })
  })
}

export const convertModifierApiData = (res: any, params: IConvertModifierApiParams) => {
  let modifiers: IModifier[] = [];
  const channel = getChannel();
  const { storeID } = params;

  let modifierSets: any[] = [];
  if (res && res.success && Array.isArray(res.modifier_sets)) {
    modifierSets = res.modifier_sets;
  }

  // 用来去重，如果ID重复，会忽略后面的记录
  const modifierIDSet = new Set();
  modifierSets.forEach(item => {
    let modifier: IModifier | undefined = undefined;
    if (item && !modifierIDSet.has(item.id) && Array.isArray(item.channels) && item.channels.includes(channel)) {
      modifier = parseModifier(item, params);
    }

    if (modifier) {
      modifierIDSet.add(modifier.id)
      modifiers.push(modifier);
    }
  })

  modifiers = modifiers.sort((m1, m2) => m1.position - m2.position);
  addRefForModifierOption(modifiers);
  setModifiers(storeID, modifiers);

  return cloneDeep(modifiers);
}


export const convertOrderApiData = (orderData: any) => {
  let data: IOrderInfo | undefined = undefined;
  if (orderData) {
    const orderInfo: IOrderInfo = {
      storeID: orderData.store_id || '',
      storeSlug: orderData.store_slug || '',
      storeTitle: orderData.store_title || '',
      orderNumber: orderData.number || '',
      paidTotal: orderData.paid_total || 0,
      currencyCode: orderData.currency || '',
      state: orderData.state || '',
      invoiceState: orderData.invoice_state || '',
      saleTransactions: [],
      shippingMethod: orderData.shipping_method || 0,
      deliveryDate: orderData.delivery_date || null,
      dineInParty: orderData.party || orderData.first_party_details,
      initialTotal: orderData.initial_total || 0,
      initialServiceFee: orderData.initial_service_fee || 0,
      initialTax: orderData.initial_tax || 0,
      initialRounding: orderData.initial_rounding || 0,
      discountTotal: 0,
      subTotal: orderData.subtotal || 0,
      superOrder: orderData.is_super_order || false,
      orderEntities: [],
      referenceID: orderData.reference_number || '',
      orderStatus: getOrderStatus(orderData) ||  '',
      storeLogoUrl: orderData.store_logo_url || orderData.logo_url || '',
      orderTime: orderData.created_at || '',
      storeName: orderData.store_name || '',
      orderShippingAddress: orderData.order_shipping_address || {},
      note: orderData.note || '',
      pickupLocationID: orderData.pickup_location_id || null,
      pickupLocation: orderData.pickup_location || null,
      parentID: orderData.parent_id || null,
      from: orderData.from || null,
      shippingAddressID: orderData.shipping_address_id || null,
      smartDiscountInfo: orderData.smartDiscountInfo || null,
      orderID: orderData.id || '',
    }

    if (orderData.party) {
      orderInfo.dineInParty = {
        numberOfPeople: orderData.party.number_of_people || 1,
        status: orderData.party.status || 'seated',
        tableID: orderData.party.table_id || '',
        tableName: orderData.party.table_name || '',
        turnTime: orderData.party.turn_time || 90,
      }
    } else if (orderData.first_party_details) {
      orderInfo.dineInParty = {
        numberOfPeople: orderData.first_party_details.number_of_people || 1,
        status: orderData.first_party_details.status || 'seated',
        tableID: orderData.first_party_details.table_id || '',
        tableName: orderData.first_party_details.table_name || '',
        turnTime: orderData.first_party_details.turn_time || 90,
      }
    }

    if (orderInfo.superOrder) {
      let subOrders: any[] = [];
      if (Array.isArray(orderData.sub_orders)) {
        subOrders = orderData.sub_orders;
      } else if (Array.isArray(orderData.sub_order)) {
        subOrders = orderData.sub_order;
      }

      subOrders.forEach(item => {
        let orderEntity = new OrderEntity(item.store_id);
        orderEntity.parseOrderApi(item, orderInfo, 'ORDER');
        orderEntity.calculate();
        orderEntity = OrderEntity.toReceiptObject(orderEntity);
        orderInfo.orderEntities.push(orderEntity);
      })
    } else {
      let orderEntity = new OrderEntity(orderData.store_id);
      orderEntity.parseOrderApi(orderData, orderInfo, 'ORDER');
      orderEntity.calculate();
      orderEntity = OrderEntity.toReceiptObject(orderEntity);
      orderInfo.orderEntities.push(orderEntity);
    }

    const transactions = orderData.sale_transactions;
    if (Array.isArray(transactions)) {
      transactions.forEach((item: any) => {
        const itemExtra = item.extra || {};
        const paymentData = item.payment_data || {};
        if (orderInfo) {
          orderInfo.saleTransactions.push({
            id: `${item.id}`,
            amount: item.amount,
            currencyCode: item.currency,
            paymentMethod: item.payment_method,
            creditCardType: itemExtra.credit_card_type || '',
            last4Digits: itemExtra.last_4_digits || '',
            brn: paymentData.brn || '',
            success: item.success,
          })
        }
      })
    }

    data = orderInfo;
  }

  return data;
}

export const getOrderStatus = (order: any) => {
  if (order.state === 'voided') {
    return order.state;
  }
  if (order.correspondence_state === 'unpaid') {
    return order.correspondence_state;
  } else if (order.correspondence_state === 'paid' && order.state === 'completed') {
    if (order.pickup_status === 0) {
      return 'paid';
    } else if (order.pickup_status === 1) {
      return 'preparing';
    } else if (order.pickup_status === 2) {
      return 'ready';
    }
    return order.state;
  } else if (order.correspondence_state === 'cart') {
    return 'unpaid'
  }
};

export const parseRoundingOtionsResp = (resp: any) => {
  const roundingOption: IRoundingOption = {
    taxLineItemLevelRoundingType: resp.tax_line_item_level_rounding_type || 0,
    lineItemRoundingType: resp.line_item_rounding_type || 0,
    taxRoundingType: resp.tax_rounding_type || 0,
    taxOrderLevelDecimalPoints: resp.tax_order_level_decimal_points || 2,
    discountRoundingType: resp.discount_rounding_type || 0,
    lineItemDecimalPoints: resp.line_item_decimal_points || 0,
    taxOrderLevelRoundingType: resp.tax_order_level_rounding_type || 0,
    discountDecimalPoints: resp.discount_decimal_points || 2,
    taxLineitemLevelDecimalPoints: resp.tax_line_item_level_decimal_points || 2,
    orderRoundingType: resp.rounding_type || 0,
    orderDecimalPoints: resp.decimal_points || 2,
  }

  return roundingOption;
}

export const parseTaxOtionsResp = (resp: any) => {
  const taxOptions: ITaxOption[] = [];

  if (Array.isArray(resp)) {
    resp.forEach(item => {
      let taxOption: any;
      if (item && item.tax_option && item.tax_option.id) {
        taxOption = item.tax_option;
      }

      if (taxOption && taxOption.tax_rate) {
        const option: ITaxOption = {
          id: taxOption.id,
          name: taxOption.name || '',
          storeID: taxOption.store_id || '',
          departmentIDs: [],
          listingIDs: [],
          taxOptionBlackouts: [],
          includeTips: taxOption.include_tips || false,
          includedInPrice: taxOption.included_in_price || false,
          method: taxOption.method || 0,
          primary: taxOption.primary || false,
          priority: taxOption.priority || 0,
          stackToServiceFeeLineItem: taxOption.stack_to_service_fee_line_item || false,
          taxRate: taxOption.tax_rate,
          taxType: taxOption.tax_type || 1,
          threshold: taxOption.threshold || '',
        };

        const {
          department_tax_options: departmentTaxOptions,
          listing_tax_options: listingTaxOptions,
          tax_option_blackouts: taxOptionBlackouts,
        } = taxOption;

        if (Array.isArray(departmentTaxOptions)) {
          departmentTaxOptions.forEach(dtoItem => {
            if (dtoItem && dtoItem.department_id) {
              option.departmentIDs.push(`${dtoItem.department_id}`);
            }
          })
        }

        if (Array.isArray(listingTaxOptions)) {
          listingTaxOptions.forEach(ltoItem => {
            if (ltoItem && ltoItem.listing_id) {
              option.listingIDs.push(`${ltoItem.listing_id}`);
            }
          })
        }

        if (Array.isArray(taxOptionBlackouts)) {
          taxOptionBlackouts.forEach(blackoutItem => {
            const {
              blackout_time_from: blackoutTimeFrom,
              blackout_time_to: blackoutTimeTo,
              store_id: blackoutStoreID,
            } = blackoutItem;
            let timeFrom = '';
            let timeTo = '';

            if (blackoutTimeFrom && trim(blackoutTimeFrom).length === 8) {
              timeFrom = trim(blackoutTimeFrom);
            }

            if (blackoutTimeTo && trim(blackoutTimeTo).length === 8) {
              timeTo = trim(blackoutTimeTo);
            }

            if (blackoutStoreID && timeFrom && timeTo) {
              option.taxOptionBlackouts.push({
                storeID: blackoutStoreID,
                timeFrom,
                timeTo,
              });
            }
          })
        }

        taxOptions.push(option);
      }
    })
  }

  return taxOptions;
}
