import React from 'react';
import { IStoreCategory } from '@/actions/store-action';
import { getI18nText, handleStoreServiceTypesScroll } from '@/utils/app';
// import './BrandsCategories.less';
import './BrandsCategories.less';
import { IReducersState } from '@/reducers';

export const prefix = 'bm-c-brands-categories';

interface IBrandsCategoriesProps {
  data: IStoreCategory[];
  reducersState: IReducersState;
  className?: string;
  onItemClick?: (type: string, fromPage: string) => void;
}

const BrandsCategories: React.FC<IBrandsCategoriesProps> = props => {
  const {
    data,
    className = '',
    reducersState,
    onItemClick,
  } = props;

  const handleItemClick = (type: string, fromPage: string) => {
    handleStoreServiceTypesScroll(type);
    if (onItemClick) {
      onItemClick(type, fromPage);
    }
  }

  return (
    <div id="bm-brands-box" className={`${prefix} ${className}`}>
      <div className="w-container">
        <div className="w-container-item">
          <div id="bm-brands-categories" className={`${prefix}-wrapper`}>
            {
              data.map(item => {
                if (item.brandsCategory === '2') {
                  return (
                    <div
                      key={item.id}
                      id={`bm-brands-categories-${item.id}`}
                      className={`${prefix}-item`}
                      onClick={() => handleItemClick(item.id, 'brands')}
                    >
                      <div className="box-categories-img">
                        <div className="categories-img" style={{ backgroundImage: item.categoryMediumImage ? `url(${item.categoryMediumImage})` : '' }}></div>
                      </div>
                      <div className="categories-title">
                        {getI18nText(item, 'category_name', reducersState.app.language)}
                      </div>
                      <div className="categories-description">
                        {getI18nText(item, 'category_description', reducersState.app.language)}
                      </div>
                    </div>
                  )
                } else {
                  return null;
                }
              })
            }
            {
              data.map(item => {
                if (item.brandsCategory === '2') {
                  return (
                    <div
                      key={item.id}
                      id={`bm-brands-categories-${item.id}`}
                      className={`${prefix}-item`}
                      onClick={() => handleItemClick(item.id, 'brands')}
                    >
                      <div className="box-categories-img">
                        <div className="categories-img" style={{ backgroundImage: item.categoryMediumImage ? `url(${item.categoryMediumImage})` : '' }}></div>
                      </div>
                      <div className="categories-title">
                        {getI18nText(item, 'category_name', reducersState.app.language)}
                      </div>
                      <div className="categories-description">
                        {getI18nText(item, 'category_description', reducersState.app.language)}
                      </div>
                    </div>
                  )
                } else {
                  return null;
                }
              })
            }
          </div>
        </div>
       
      </div>
    </div>
  );
}

export default BrandsCategories;
