
enum TransitionKeys {
  NONE = 'none',
  MOVE_FROM_LEFT = 'move-from-left',
  MOVE_FROM_RIGHT = 'move-from-right',
  MOVE_FROM_TOP = 'move-from-top',
  MOVE_FROM_BOTTOM = 'move-from-bottom',
  FADE_FROM_LEFT = 'fade-from-left',
  FADE_FROM_RIGHT = 'fade-from-right',
  FADE_FROM_TOP = 'fade-from-top',
  FADE_FROM_BOTTOM = 'fade-from-bottom',
  FADE_BACK_LEFT = 'fade-back-left',
  FADE_BACK_RIGHT = 'fade-back-right',
  FADE_BACK_TOP = 'fade-back-top',
  FADE_BACK_BOTTOM = 'fade-back-bottom',
  MATERIAL_FORWARD = 'material-forward',
  MATERIAL_BACKWARD = 'material-backward',
  REVEAL_FORWARD = 'reveal-forward',
  REVEAL_BACKWARD = 'reveal-backward',
};

export default TransitionKeys;
