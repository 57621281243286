import React from 'react';
import StoreMapList from '@/components/StoreList';
import { IReducersState } from '@/reducers';
import { IStore } from '@/actions/store-action';
import Icon, { IconType } from '@/components/Icon';
import { storeprefix } from '..';
import { getIntl } from '@/components/App/App';


interface IStoreMapDetailsProps {
  storeData: IStore | undefined;
  selectedStoreID: string;
  reducersState: IReducersState;
  isFavorite: boolean;
  handleShare: () => void;
  handleAddFavorite: () => void;
  handleRoute: (storeData: IStore) => void;
}

const StoreMapDetails = (props: IStoreMapDetailsProps) => {

  const {
    storeData,
    reducersState,
    handleShare,
    handleAddFavorite,
    isFavorite,
    handleRoute,
  } = props;

  return (
    <div
      className={`${storeprefix}-details`}
    >
      {
        storeData &&
        <StoreMapList
          data={[storeData]}
          reducersState={reducersState}
          itemStyle="LIST_ICON_LEFT"
        />
      }
      {
        storeData &&
        <div className={`${storeprefix}-details-content`} >
          <div className={`${storeprefix}-details-content-item`} onClick={handleShare}>
            <Icon
              type={IconType.SHARE_OUTLINED}
              className={`${storeprefix}-details-item-icon`}
            />
            {getIntl().page.share}
          </div>

          <div className={`${storeprefix}-details-content-item`} onClick={handleAddFavorite}>
            {
              !isFavorite &&
              <Icon
                type={IconType.NOT_FAVORITE_OUTLINED}
                className={`${storeprefix}-details-item-icon`}
              />
            }
            {
              isFavorite &&
              <Icon
                type={IconType.ALREADY_FAVORITE_OUTLINED}
                className={`${storeprefix}-details-item-icon`}
              />
            }
            {getIntl().page.favorite}
          </div>

          <div className={`${storeprefix}-details-content-route`} onClick={() => handleRoute(storeData)}>
            {getIntl().page.route}
          </div>
        </div>
      }
      <div className={`${storeprefix}-details-copy-wrapper`}>
        <input className={`${storeprefix}-details-copy`} id="global-copy" />
      </div>
    </div>
  );
}

export default StoreMapDetails;
