import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import withRoute from '@/pages/withRoute';
import QrcodeShoppingCartPage from './QrcodeShoppingCartPage';
import './QrcodeShoppingCartPage.less';

export const prefix = 'bm-p-qrcode-shopping-cart';

const RouteComponent: React.FC<RouteComponentProps> = props => withRoute({
  pageComponent: QrcodeShoppingCartPage,
  routeProps: props,
  pageTitleI18nKey: '',
});

export default RouteComponent;
