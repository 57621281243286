import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import { getAppTheme, setScreenColor, getIntl } from '@/components/App/App';
import Layout from '@/components/Layout/Layout';
import ActionType from '@/actions/action-type';
import { IStoreImage } from '@/actions/store-action';
import Carousel from 'antd-mobile/es/carousel';
import CarouselItem from '@/pages/MenuListPage/CarouselItem';
import Config from '@/Config';
import paths from '@/routes/paths';
import dayjs from 'dayjs';
import Modal from 'antd-mobile/es/modal';
import { parseQrcodeShoppingOrderInfosCount } from '@/utils/cart';
import Skeleton from './Skeleton';
import { IQrcodeShoppingCartPageProps } from '../QrcodeShoppingCartPage/QrcodeShoppingCartPage';
import Icon, { IconType } from '@/components/Icon';
import { setTaskSyncErrorCallback } from '@/sagas/qrcord-shopping-saga';
import { prefix } from '.';
import { QrcodeShoppingPageStyled } from './styled';
export interface IQrcodeShoppingPageProps extends IBasePageProps {
  isHomePage?: boolean;
}

export interface IQrcodeShoppingPageState {
  storeImages: IStoreImage[];
  storeImgUrl: string;
  orderError: boolean;
}

class QrcodeShoppingPage extends BasePage<IQrcodeShoppingPageProps, IQrcodeShoppingPageState> {
  constructor(props: IQrcodeShoppingPageProps) {
    super(props);

    this.state = {
      storeImages: [],
      storeImgUrl: Config.defaultStoreImg,
      orderError: false
    }
  }

  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.darkBackgroundColor) {
      setScreenColor(appTheme.darkBackgroundColor);
    }

    super.componentDidMount();

    const { dispatch, routeParams } = this.props;
    const { storeSlug, orderNumber, authCode } = routeParams;
    if (storeSlug && orderNumber && authCode) {
      setTaskSyncErrorCallback(data => {
        if (data && data.message && data.message === 'Order Number and Auth Code not match.') {
          this.setState({ orderError: true });
        }
      });
      dispatch({
        type: ActionType.START_QRCODE_SHOPPING_INVOICE_TASK_SYNC,
        storeSlug,
        orderNumber,
        authCode,
      });
    }
    this.getStoreImages(storeSlug);
    this.checkinVoiceState();
  }

  componentDidUpdate(prevProps: IQrcodeShoppingCartPageProps) {
    const { reducersState: prevReducersState } = prevProps;
    const { state: prevState } = prevReducersState.qrcordShopping.invoiceInfo;
    const { reducersState, dispatch } = this.props;
    const { state } = reducersState.qrcordShopping.invoiceInfo;

    if (prevState !== state) {
      if (state === 'voided' || state === 'completed') {
        dispatch({
          type: ActionType.CANCEL_QRCODE_SHOPPING_INVOICE_TASK_SYNC,
        });
      }
      this.checkinVoiceState();
    }
  }

  handleGoOrder = (pathname: string) => {
    const { routeParams, reducersState } = this.props;
    const { invoiceInfo } = reducersState.qrcordShopping;
    if (invoiceInfo.state !== 'voided' && invoiceInfo.state !== 'completed') {
      this.handleGotoRoute({
        path: pathname,
        pathParams: {
          storeSlug: routeParams.storeSlug,
          orderNumber: routeParams.orderNumber,
        },
      });
    } else {
      Modal.alert(getIntl().common.tips, getIntl().page.qrcodeInvalid, [
        {
          text: getIntl().common.confirm,
          onPress: () => { },
        }
      ]);
      return;
    }
  }

  getStoreImages = (storeSlug: string) => {
    const { dispatch } = this.props;
    dispatch({
      type: ActionType.QUERY_STORE_IMAGES,
      storeSlug,
      callback: (storeImages: IStoreImage[]) => {
        const { storeImgUrl } = this.state;
        let newStoreImgUrl = storeImgUrl;
        if (storeImages.length === 1 && (!newStoreImgUrl || newStoreImgUrl === Config.defaultStoreImg)) {
          newStoreImgUrl = storeImages[0].originalUrl || Config.defaultStoreImg;
        }

        if (storeImages.length > 0) {
          this.setState({
            storeImages,
            storeImgUrl: newStoreImgUrl,
          });
        }
      }
    });
  }

  checkinVoiceState = () => {
    const { reducersState, routeParams } = this.props;
    const { invoiceInfo } = reducersState.qrcordShopping;
    const completedState = invoiceInfo.state === 'voided' || invoiceInfo.state === 'completed';

    if (completedState) {
      this.handleGotoRoute({
        path: paths.QRCODE_SHOPPING_DETAIL,
        pathParams: {
          storeSlug: routeParams.storeSlug,
          orderNumber: routeParams.orderNumber,
        },
      });
    }
  }

  handleRetry = () => {
    window.location.reload();
  }

  render() {
    const { storeImages, storeImgUrl, orderError } = this.state;
    const { reducersState } = this.props;
    const { invoiceInfo, orderInfos } = reducersState.qrcordShopping;
    const paresResult = parseQrcodeShoppingOrderInfosCount(invoiceInfo, orderInfos.submittedOrderInfo, orderInfos.pendingOrderInfo, undefined);
    const completedState = invoiceInfo.state === 'voided' || invoiceInfo.state === 'completed';
    const btnClassName = `start-ordering ${!invoiceInfo.orderNumber || invoiceInfo.state === 'voided' || invoiceInfo.state === 'completed' ? 'disable-btn' : ''}`;

    return (
      <Layout
        {...this.props}
        className={prefix}
        isFooter={false}
      >
        <QrcodeShoppingPageStyled>
          {
            storeImages.length < 2 &&
            <div className="background-top with-image" style={{ backgroundImage: `url(${storeImgUrl})` }}></div>
          }
          {
            storeImages.length >= 2 &&
            <Carousel>
              {
                storeImages.map(item => (
                  <CarouselItem key={item.id} url={item.originalUrl} type="IMAGE" />
                ))
              }
            </Carousel>
          }
          <div className="section dine-ine wf-section">
            <div className="div-block-620">
              <div className="div-block-615 dine-in qrcode-msg-wrap">
                {
                  completedState &&
                  <div className="order-completed-wrap">
                    <div className="order-completed">
                      <Icon type={IconType.STATUS_SUCCESS} />
                      <div className="order-completed-text">
                        {getIntl().page.orderCompleted}
                      </div>
                    </div>
                  </div>
                }
                {
                  invoiceInfo.orderNumber &&
                  <div className="div-block-621">
                    <div className="div-block-622 top">
                      <div className="divider-line"></div>
                      <div className="text-block-98 with-space">{getIntl().page.referenceNumber}</div>
                      <div className="text-block-67 bold">{invoiceInfo.orderReferenceNumber}</div>
                    </div>
                    <div className="div-block-622 top">
                      <div className="text-block-98 with-space">{getIntl().page.seatedTableNumber}</div>
                      <div className="text-block-67 bold">{invoiceInfo.tableName}</div>
                    </div>
                    <div className="div-block-622">
                      <div className="text-block-98 with-space">{getIntl().page.seatedTime}</div>
                      <div className="text-block-67 bold">{dayjs(invoiceInfo.seatedAt).format('YYYY-MM-DD HH:mm')}</div>
                      <div className="divider-line"></div>
                    </div>
                    <div className="div-block-622">
                      <div className="text-block-98 with-space">{getIntl().page.headcount}</div>
                      <div className="text-block-67 bold">{invoiceInfo.numberOfPeople}</div>
                    </div>
                  </div>
                }
                {
                  !invoiceInfo.orderNumber && orderError &&
                  <div
                    className={`${prefix}-error-wapper`}
                  >
                    <Icon type={IconType.STATUS_ERROR_OUTLINED} className={`${prefix}-error-wapper-icon`}/>
                    <div className={`${prefix}-error-wapper-title`}>{getIntl().page.orderSynchronized}</div>
                    <div className={`${prefix}-error-wapper-title-extra`}>{getIntl().page.tryAgain}</div>
                    <div className={`${prefix}-error-wapper-button`} onClick={this.handleRetry} >{getIntl().page.retry}</div>
                  </div>
                }
                {
                  !invoiceInfo.orderNumber && !orderError &&
                  <Skeleton />
                }
              </div>
              <div className="div-block-623">
                {
                  invoiceInfo.orderNumber && !completedState && (paresResult.hasPendingItems || paresResult.hasSubmittedItems) && !orderError &&
                  <>
                    <div className={`secondary-order-more ${btnClassName}`}onClick={() => this.handleGoOrder(paths.QRCODE_SHOPPING_MENUS)}>
                      <div className="text-block-17">{getIntl().page.orderNow}</div>
                    </div>
                    <div className={btnClassName} onClick={() => this.handleGoOrder(paths.QRCODE_SHOPPING_DETAIL)}>
                      <div className="text-block-17">{getIntl().page.orderDetail}</div>
                    </div>
                  </>
                }
                {
                  invoiceInfo.orderNumber && !completedState && !paresResult.hasPendingItems && !paresResult.hasSubmittedItems && !orderError &&
                  <div className={`${btnClassName}`}onClick={() => this.handleGoOrder(paths.QRCODE_SHOPPING_MENUS)}>
                    <div className="text-block-17">{getIntl().page.orderNow}</div>
                  </div>
                }
              </div>
            </div>
          </div>
        </QrcodeShoppingPageStyled>
      </Layout>
    );
  }
}

export default QrcodeShoppingPage;
