import React, { useState, useEffect } from 'react';
import InputItem from 'antd-mobile/es/input-item';
import Icon, { IconType } from '@/components/Icon';
import { navPrefix } from '.';
import { getIntl } from '@/components/App/App';

interface ISearchTitleProps {
  searchValue: string;
  onClosed: () => void;
  onCancel: () => void;
  onSearch?: (value: string) => void;
}

const SearchTitle: React.FC<ISearchTitleProps> = props => {
  const {
    onSearch,
    onClosed,
    searchValue,
    onCancel,
  } = props;

  const [searchKey, setSearchkey] = useState<string>(searchValue);

  useEffect(() => {
    setSearchkey(searchValue);
  }, [searchValue])

  const onKeyUp = (evt: React.KeyboardEvent) => {
    const code = evt.charCode || evt.keyCode;
    if (code === 13) { // 点击回车或者在搜索
      onSearch && onSearch(searchKey);
    }
  }

  return (
    <div className={`${navPrefix}`}>
      <div className="nav_bg streched expanded blue w-nav">
        <div className="container fixed-nav expanded w-container">
          <div className="div-block-645">
            <InputItem
              name="searchKey"
              value={searchKey}
              autoComplete="off"
              placeholder={getIntl().common.search}
              onChange={(value: string) =>  setSearchkey(value)}
              onKeyUp={onKeyUp}
              extra={
                searchKey &&
                <Icon
                  type={IconType.CLOSE_OUTLINED}
                  className="card-icon-close"
                  onClick={() => {
                    setSearchkey('');
                    onClosed();
                  }}
                />
              }
            >
              <div className={`${navPrefix}-inputextra`} >
                <Icon
                  type={IconType.SEARCH_OUTLINED}
                  className={`${navPrefix}-search-icon-1 w-embed`}
                  // className={`${navPrefix}-inputextra-icon`}
                />
              </div>
            </InputItem>
            <div className="div-block-646" onClick={onCancel}>
              <div className="text-block-231">{getIntl().common.cancel}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default SearchTitle;

