
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IDispatch } from '@/actions';
import { IReducersState } from '@/reducers';
import { parseRouteParams } from '@/utils';

interface IUsePagePropsParams {
  props: RouteComponentProps;
}

const usePageProps = (params: IUsePagePropsParams) => {
  const dispatch = useDispatch<IDispatch>();
  const reducersState: IReducersState = useSelector<IReducersState, IReducersState>(state => state);
  const routeParams = parseRouteParams(params.props);

  return {
    ...params.props,
    dispatch,
    reducersState,
    routeParams,
  };
}

export default usePageProps;
