import styled from 'styled-components';
export const BookingDetailPageStyled = styled.div`
* {
  box-sizing: border-box;
}
.section {
  display: block;
  margin-top: auto;
  margin-bottom: 0px;
  padding: 60px 40px 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--bm-dark-background-color);
}
.w-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
}
.container {
  position: relative;
  display: block;
  overflow: visible;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
}
.div-block-543 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  padding-top: 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.payment-status-icon {
  margin-bottom: 20px;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}
.payment-status-icon._1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.image-202 {
  max-width: 100%;
  width: 200px;
  margin-bottom: 20px;
  svg {
    width: 100%;
    height: 100%;
  }
}
.heading_1 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: 'Sf pro display', sans-serif;
  font-size: 25px;
  font-weight: 600;
  color: var(--bm-text-secondary-color);
  text-align: center;
}
.clipped-3 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 450px;
  height: 640px;
  min-height: 640px;
  min-width: 450px;
  padding: 22px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 12px;
  background-color: var(--bm-light-background-color);
}
.clipped-3 {
  -webkit-clip-path: url(#my-clip-path-3);
  clip-path: url(#my-clip-path-3);
}
.ticket-wrapper {
  filter: drop-shadow(2px 6px 3px rgba(50, 50, 0, 0.4));
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 90%;
  margin: 40px auto 60px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.ticket-wrapper._1 {
  width: auto;
  margin-top: 40px;
  margin-bottom: 20px;
}
.div-block-457 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  margin-bottom: 20px;
  padding-bottom: 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.line-divider-top {
  position: absolute;
  left: auto;
  top: 16%;
  right: auto;
  bottom: auto;
  width: 90%;
  height: 1px;
  border-bottom: 2px dashed var(--bm-border-color);
}
.div-block-444 {
  position: relative;
  top: -23px;
  padding-bottom: 0px;
}
.line-divider {
  position: absolute;
  left: auto;
  top: auto;
  right: auto;
  bottom: 28%;
  width: 90%;
  height: 1px;
  border-bottom: 2px dashed var(--bm-border-color);
}
.line-divider._1 {
  bottom: 19%;
}
.div-block-593 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.text-block-48 {
  margin-bottom: 5px;
  font-family: 'Sf pro display', sans-serif;
  color: var(--bm-text-title-color);
  font-size: 18px;
  font-weight: 600;
  text-align: left;
}
.text-block-49 {
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: var(--bm-text-secondary-color);
  font-size: 18px;
  text-align: left;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.div-block-442 {
  width: 60px;
  height: 60px;
  margin-right: 20px;
  border-radius: 40px;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
.div-block-445 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 20px;
  padding-bottom: 25px;
  padding-left: 10px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  border-bottom: 2px dashed var(--bm-border-color);
}
.div-block-447 {
  padding-top: 25px;
}
.div-block-446 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.text-block-60 {
  margin-bottom: 15px;
  font-family: 'Sf pro display', sans-serif;
  color: var(--bm-text-title-color);
  font-size: 30px;
  text-align: left;
  font-weight: 600;
}
.text-block-46 {
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: var(--bm-text-title-color);
  font-size: 18px;
}
.text-block-46.third {
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: var(--bm-text-color);
  font-size: 18px;
}
.line-divider._1 {
  bottom: 19%;
}
.line-divider {
  position: absolute;
  left: auto;
  top: auto;
  right: auto;
  bottom: 28%;
  width: 90%;
  height: 1px;
  border-bottom: 2px dashed var(--bm-border-color);
}
.text-block-47 {
  font-family: 'Sf pro display', sans-serif;
  color: var(--bm-light-background-color);
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
}
.submit-button-modal.close-modal._2 {
  width: auto;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.submit-button-modal.close-modal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 48%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color:  var(--bm-primary-color);
  text-decoration: none;
}
.submit-button-modal {
  width: 100%;
  height: 55px;
  padding: 17px;
  border-radius: 8px;
  background-color: var(--bm-primary-color);
  color: var(--bm-light-background-color);
  text-align: center;
}
.close-modal.cancel-submit {
  border: 1px solid var(--bm-text-secondary-color);
  background-color: var(--bm-light-background-color);
  margin-right: 10px;
  .text-block-47 {
    color: var(--bm-text-title-color);
  }
}
.div-block-436 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.html-embed-21 {
  position: absolute;
  z-index: -12;
  display: block;
}

@media screen and (max-width: 991px) {
  .section {
    margin-top: 0px;
    padding: 40px 35px 30px;
  }
  .w-container {
    max-width: 728px;
  }
  .heading_1 {
    font-size: 20px;
  }
  .ticket-wrapper {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 767px) {
  .section {
    padding: 0 25px 20px;
  }
  .div-block-543 {
    padding-top: 0;
  }
  .image-202 {
    max-width: 100%;
    min-width: 100%;
  }
  .heading_1 {
    margin-bottom: 0px;
    font-size: 22px;
  }
  .clipped-3 {
    width: 450px;
    height: 700px;
    min-height: auto;
    min-width: auto;
  }
  .ticket-wrapper {
    width: 90%;
    margin-top: 10px;
    margin-bottom: 0px;
  }
  .text-block-46 {
    font-size: 18px;
  }
  .text-block-46.third {
    font-size: 16px;
  }
  .submit-button-modal.close-modal {
    height: 55px;
  }
  .text-block-47 {
    font-size: 16px;
  }
}
@media screen and (max-width: 479px) {
  .section {
    padding: 0 12px 15px;
  }
  .container {
    margin-top: 0px;
    margin-bottom: 0px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .w-container {
    max-width: none;
  }
  .heading_1 {
    margin-bottom: 10px;
    font-size: 22px;
    line-height: 26px;
  }
  .image-202 {
    max-width: 80%;
    min-width: 80%;
    margin-bottom: 0;
  }
  .ticket-wrapper._1 {
    margin-top: 20px;
    margin-right: 20px;
    margin-left: 20px;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }
  .clipped-3 {
    width: 100%;
    height: 600px;
    min-height: auto;
    min-width: 280px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .div-block-444 {
    top: -11px;
  }
  .line-divider-top {
    left: 12px;
    width: 91%;
    top: 15%;
  }
  .div-block-457 {
    padding-bottom: 10px;
  }
  .div-block-442 {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    min-width: 40px;
  }
  .text-block-48 {
    margin-bottom: 5px;
    font-size: 16px;  
  }
  .text-block-49 {
    font-size: 14px;
  }
  .text-block-60 {
    margin-bottom: 5px;
    font-size: 16px;
  }
  .text-block-46 {
    font-size: 14px;
  }
  .submit-button-modal.close-modal {
    height: 50px;
  }
  .div-block-436 {
    margin-bottom: 15px;
  }
}







`;
