export const platformRoutePrefix = '/p/:platform';

export default {
  HOME:                   '/',
  STORE_LIST:             '/stores', // 店铺列表
  STORE_MAP:              '/stores/map',
  STORE_LIST_BY_CATEGORY: '/stores/by/:byType/category/:categoryID/brand/:brandID/:fromPage', // 店铺列表
  STORE_GROUP:            '/stores/:storeSlug/group', // 店铺组列表
  MENU_LIST:              '/stores/:storeSlug/menus', // 店铺菜品列表
  MENU_LIST_OLD:          '/Public/ChooseShippingMethod/:storeID/:storeSlug/:procurementMethod', // 店铺菜品列表
  MENU_LIST_TO:           '/stores/:storeSlug/menus/:menuID', // 店铺菜品列表
  MENU_LIST_QR_ORDER:     '/sc/:shortcutCode', // 扫码点餐
  MODIFIER:               '/stores/:storeSlug/listings/:listingID/modifiers', // 菜品Modifiers
  SUB_MODIFIER:           '/stores/:storeSlug/listings/:listingID/modifiers/a/:modifierIDs', // Modifers子集
  SUB_MODIFIER_B:         '/stores/:storeSlug/listings/:listingID/modifiers/b/:modifierIDs', // Modifers子集
  SHOPPING_CART:          '/stores/:storeSlug/shopping-cart/pay-info/:payInfo', // 购物车页面
  SHOPPING_CART_BY_FROM:  '/stores/:storeSlug/shopping-cart/:fromPage', //购物车页面，区分来源
  PICKUP_LOCATIONS:       '/stores/:storeSlug/pickup-locations', // 外卖自取地点
  CHECKOUT:               '/stores/:storeSlug/checkout/pay-info/:payInfo', // Checkout页面
  CHECKOUT_BY_FROM:       '/stores/:storeSlug/checkout/:fromPage/pay-info/:payInfo', // Checkout页面，区分来源
  ORDER_DETAIL:           '/stores/:storeSlug/orders/:orderNumber/:suborderNumber/:from',
  // ORDER_CONFIRM:          '/stores/:storeSlug/orders/:orderNumber/select-payment', // 选择支付方式页面
  ORDER_CONFIRM:          '/stores/:storeSlug/orders/:orderNumber/select-payment/pay-info/:payInfo', // 选择支付方式页面
  ORDER_PAY_METHOD:       '/stores/:storeSlug/orders/:orderNumber/payment-method/pay-info/:payInfo', // TODO 改为弹出框，需要移除该路由
  ORDER_PAY_METHOD_BILL:  '/stores/:storeSlug/orders/:orderNumber/payment-method-bill', //直接向店铺支付选择支付方式
  PAY_RESULTS:            '/stores/:storeSlug/orders/:orderNumber/payment-results/pay-info/:payInfo', // 选择支付方式页面
  PAY_CARD:               '/stores/:storeSlug/orders/:orderNumber/payment-card/pay-info/:payInfo', // 添加支付卡
  PAY_BILL:               '/stores/:storeSlug/pay-bill', //直接向店铺支付
  GATEWAY_PAY_BILL:       '/stores/:storeSlug/pay-bill/domain/:domain',
  PLATFORM_PAY_BILL:      '/stores/:platformID/:storeSlug/bill-pay',
  BLOG_DETAIL:            '/stores/:storeSlug/blogs/:blogID/types/:contentType',
  STORES_BY_BOOKING:      '/stores/service/:serviceType',
  SEARCH:                 '/search', // 搜索页面
  SIGN_IN:                '/sign-in', // 登录页面
  SIGN_UP:                '/sign-up', // 注册页面
  SELECT_SIGN_IN:         '/select-sign-in', // 登录页面
  FORGOT_PASSWORD:        '/forgot-password', // 忘记密码
  SELECT_STORES:          '/select-stores', // 选择店铺
  ACCOUNT:                '/account',
  ACCOUNT_EDIT:           '/account/edit',
  ACCOUNT_EDIT_BY_FROM:   '/account/edit/:fromPage',
  SETTING:                '/setting',
  TERMS:                  '/terms',
  PRIVACY:                '/privacy',
  PRIVILEGE:              '/privilege',
  FAVORITE:               '/favorite',
  ORDERS:                 '/orders',
  RECORDS:                '/records',
  POINTS:                 '/points',
  NEW_POINTS:             '/new-points',
  ABOUT_US:               '/about-us',
  NEWS:                   '/news',
  ESHOP:                  '/eshop', // 店铺列表
  MEMBERSHIP:             '/membership', //会员
  ADDRESS_LIST:           '/address-list', // 收货地址列表
  STORE_ADDRESS_LIST:     '/stores/:storeSlug/address-list', // 收货地址列表
  MY_ID:                  '/my-id',
  BRANDS:                 '/brands',
  BRAND_LIST:             '/by/:byType/category/:categoryID/brands',
  CATEGORY:               '/category',
  STORE_LIST_BY_BRAND:    '/category/:categoryID/brand/:brandID',
  PAYMENT_LIST:           '/payment-list',
  ADD_CREDIT_CARD:        '/add-credit-card',
  EDIT_CREDIT_CARD:       '/add-credit-card/:cardID',
  QUEUEINGS:              '/queueings',
  QUEUEING_DETAIL:        '/queueings/:queueingID',
  QUEUEING_NEW:           '/stores/:storeSlug/queueings/new',
  BOOKINGS:               '/bookings',
  BOOKING_DETAIL:         '/bookings/:bookingID',
  BOOKING_NEW:            '/bookings/stores/:storeSlug/new',
  ADMIN_STORES:           '/admin/stores',
  // ADMIN_STORE_NEW:        '/admin/stores/new',
  ADMIN_STORE_EDIT:       '/admin/stores/:storeSlug/edit',
  STORE_ASSOCIATION:      '/admin/stores/:storeSlug/association',
  STORE_SETTING:          '/admin/stores/:storeSlug/setting',
  QRCODE_SHOPPING:        '/qrcode-shopping/:authCode/stores/:storeSlug/orders/:orderNumber',
  QRCODE_SHOPPING_MENUS:  '/qrcode-shopping/:authCode/stores/:storeSlug/orders/:orderNumber/menus',
  QRCODE_SHOPPING_CART:   '/qrcode-shopping/:authCode/stores/:storeSlug/orders/:orderNumber/cart',
  QRCODE_SHOPPING_DETAIL: '/qrcode-shopping/:authCode/stores/:storeSlug/orders/:orderNumber/detail',
  QRCODE_SHOPPING_RESULT: '/qrcode-shopping/:authCode/stores/:storeSlug/orders/:orderNumber/result',
  QRCODE_SHOPPING_MODIFIER:       '/qrcode-shopping/:authCode/stores/:storeSlug/orders/:orderNumber/listings/:listingID/modifiers',
  QRCODE_SHOPPING_SUB_MODIFIER:   '/qrcode-shopping/:authCode/stores/:storeSlug/orders/:orderNumber/listings/:listingID/modifiers/a/:modifierIDs',
  QRCODE_SHOPPING_SUB_MODIFIER_B: '/qrcode-shopping/:authCode/stores/:storeSlug/orders/:orderNumber/listings/:listingID/modifiers/b/:modifierIDs',
  INVOICE:                '/invoice/:storeSlug/:orderNumber/:storeID',    //发票开具
  INVOICE_TYPE:           '/invoice/:storeSlug/:orderNumber/:storeID/invoiceType/:invoiceType',    //发票状态
  INVOICE_SUBMIT:         '/invoice/:storeSlug/:orderNumber/:storeID/invoiceSubmit/:invoiceType',    //发票状态
  INVOICE_DOWNLOAD:       '/invoice/:storeSlug/:orderNumber/:storeID/invoicePDF', //发票下载
  INVOICE_SUBMITTYPE:     '/invoice/:storeSlug/:orderNumber/:storeID/submittedType/:invoiceType',    //发票状态
  /** Test Pages */
  ICON_LIST:              '/icons',
  DEVICE_INFO:            '/device-info',
  GOOGLE_MAP:             '/google-map',
  /** Special */
  PAY_NOORDER_RESULTS:    '/payment-results',     // 特殊添加的

  /** express order */
  EXPRESS_ORDER:          '/stores/:storeSlug/express-order/pay-info/:payInfo',

  /** purchase order */
  PURCHASE_ORDER:         '/stores/:storeSlug/purchase-order',
  PURCHASE_ORDER_DETAIL:  '/stores/:storeSlug/purchase-order/:purchaseOrderID',
  GOOD_RECEIVED_NOTES:    '/stores/:storeSlug/good-received-notes',
  GOOD_RECEIVED_NOTE_DETAIL:    '/stores/:storeSlug/good-received-note/:receiveOrderID',
  RETURN_NOTES:           '/stores/:storeSlug/return-notes',
  RETURN_NOTE_DETAIL:    '/stores/:storeSlug/return-notes/:receiveOrderID',

  /** deferred billing */
  DEFERRED_INVOICES:      '/stores/:storeSlug/deferred-invoices',
  DEFERRED_ORDER_DETAIL:  '/stores/:storeID/:timeType/deferred-order-detail/:orderNumber',
}
