enum HomeType {
  STORE_LIST = 'STORE_LIST',
  STORE_GROUP = 'STORE_GROUP',
  MENU_GRID = 'MENU_GRID',
  MENU_LIST = 'MENU_LIST',
  BRANDS = 'BRANDS',
  CATEGORY = 'CATEGORY',
  MEMBER = 'MEMBER',
}

export default HomeType;
