import React from 'react';
import { useDispatch } from 'react-redux';
import Toast from 'antd-mobile/es/toast';
import Icon, { IconType } from '@/components/Icon';
import { facebookLogin } from '@/utils/facebookWEB';
import { IDispatch } from '@/actions';
import { faceBookSignIn, getGalaxyMe } from '@/data/sign';
import ActionType from '@/actions/action-type';
import {prefix} from '.';
import Constants from '@/constants';
import { getIntl } from '@/components/App/App';

interface ISignMethodProps {
  className?: string;
  signInClick?: () => void;
}

const FaceBookSignIn: React.FC<ISignMethodProps> = props => {

  const {
    className = '',
    signInClick = () => {},
  } = props;

  const dispatch = useDispatch<IDispatch>();

  const handleClick = async () => {

    dispatch({
      type: ActionType.LOADING,
      showLoading: true,
    });

    const resp = await facebookLogin();
    if (resp && resp.authResponse && resp.authResponse.accessToken) {
      const facebookUser = await faceBookSignIn({
        faceBooKToken: resp.authResponse.accessToken,
      });

      if (facebookUser && !facebookUser.error) {
        const userInfo = await getGalaxyMe();
        if (userInfo) {
          dispatch({
            type: ActionType.SET_USER_INFO,
            userInfo,
          });
          localStorage.setItem(Constants.USER_INFO, JSON.stringify(userInfo));
        }

        dispatch({
          type: ActionType.LOADING,
          showLoading: false,
        });
        signInClick();
      } else {
        Toast.info(getIntl().page.signInErrorMsg);
      }
    }
    dispatch({
      type: ActionType.LOADING,
      showLoading: false,
    });
  }

  return (
    <div className={`${prefix}-facebook-button ${className}`} onClick={handleClick}>
      <Icon
        type={IconType.FACEBOOK_OUTLINED}
        className={`${prefix}-facebook-button-icon`}
      />
      <span>
        {getIntl().page.facebookSignin}
      </span>
    </div>
  )
}
export default FaceBookSignIn;
