import React from 'react';
import { prefix } from '../';
import { ICreateViewParams } from '.';
import DotsAndBtn from '../DotsAndBtn';
import VideoPlayer from '@/components/VideoPlayer';
import { resizeImage } from '@/utils';
import { getI18nText } from '@/utils/app';

interface IVideoViewProps extends ICreateViewParams {}

class VideoView extends React.Component<IVideoViewProps> {
  render() {
    const {
      data,
      count,
      index,
      deviceInfo,
      showItemBtns,
      onDoneClick,
      onNextClick,
      language
    } = this.props;

    const imgUrl = resizeImage({
      url: data.imageUrl,
      type: 'FILL',
      width: 750,
      height: 750,
    });

    let showVideo = true;
    if (deviceInfo.dingTalk) {
      showVideo = false;
    }

    return (
      <div className={`${prefix}-item`}>
        {
          showVideo &&
          <>
            <VideoPlayer
              className="item-video"
              // src="http://media.w3.org/2010/05/bunny/movie.mp4"
              src={data.videoUrl}
            />
            <div className="image-item-content-wrapper">
              <div className="itme-space">&nbsp;</div>
              <div className="image-item-content" style={{ textAlign: data.alignment }}>
                {
                  data.title &&
                  <div className="image-item-title">{getI18nText(data, 'title', language) || data.title}</div>
                }
                {
                  data.describe &&
                  <div className="image-item-describe">{getI18nText(data, 'text', language) || data.describe}</div>
                }
              </div>
            </div>
          </>
        }
        {
          !showVideo &&
          <>
            <div className="logo-item-img-wrapper">
              {
                imgUrl &&
                <img src={imgUrl} alt="" />
              }
            </div>
            <div className="logo-item-content-wrapper">
              <div className="logo-item-content">
                {
                  data.title &&
                  <div className="logo-item-title">{getI18nText(data, 'title', language) || data.title}</div>
                }
                {
                  data.describe &&
                  <div className="logo-item-describe">{getI18nText(data, 'text', language) || data.describe}</div>
                }
              </div>
            </div>
          </>
        }
        {
          showItemBtns &&
          <DotsAndBtn
            count={count}
            index={index}
            onNextClick={onNextClick}
            onDoneClick={onDoneClick}
          />
        }
      </div>
    );
  }
  
}

export default VideoView;
