import { IRoute } from '@/routes';
import paths from '@/routes/paths';
import CategoryPage from './CategoryPage';
import StoresByBrandPage from './StoresByBrandPage';

export const mobileRoutes: IRoute[] = [
  {
    path: paths.CATEGORY,
    component: CategoryPage,
  },
  {
    path: paths.STORE_LIST_BY_BRAND,
    component: StoresByBrandPage,
  },
];

export default mobileRoutes;
