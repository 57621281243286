import { IRoute } from '@/routes';
import paths from '@/routes/paths';
import DeferredInvoicesPage from './DeferredInvoicesPage';
import DeferredOrderDetailPage from './DeferredOrderDetailPage';


export const mobileRoutes: IRoute[] = [
  {
    path: paths.DEFERRED_INVOICES,
    component: DeferredInvoicesPage,
  },
  {
    path: paths.DEFERRED_ORDER_DETAIL,
    component: DeferredOrderDetailPage,
  },
];

export default mobileRoutes;
