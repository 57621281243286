import produce from 'immer';
import { handleActions } from 'redux-actions';
import ActionType from '@/actions/action-type';
import {
  IStore,
} from '@/actions/store-action';
import {
  ISetSupplierInfoStores,
  ISetSuppliersSelectKey,
} from '@/actions/supplier-info-action';
import Constants from '@/constants';

export interface ISupplierInfoState {
  userAssociateStoreMap: Map<string, IStore>;
  selectStoreSlug: string;
  selectStoreId: string | number;
}

const initState: ISupplierInfoState = {
  userAssociateStoreMap: new Map(),
  selectStoreSlug: localStorage.getItem(Constants.SUPPLIERS_SELECT_STORE_SLUG) || '',
  selectStoreId: localStorage.getItem(Constants.SUPPLIERS_SELECT_STORE_ID) || '',
};

const actions: any = {};

actions[ActionType.SET_SUPPLIER_INFO_STORES] = (state: ISupplierInfoState, action: ISetSupplierInfoStores) => produce(state, draft => {
  const tempStroes = action.stores;
  const storeMap = new Map();
  if (tempStroes.length > 0) {
    for (let i = 0; i < tempStroes.length; i++) {
      const item = tempStroes[i];
      storeMap.set(item.slug, item);
    }
  }
  draft.userAssociateStoreMap = storeMap;
});

actions[ActionType.SET_SUPPLIERS_SELECT_KEY] = (state: ISupplierInfoState, action: ISetSuppliersSelectKey) => produce(state, draft => {
  draft.selectStoreSlug = action.selectStoreSlug;
  draft.selectStoreId = action.selectStoreId;
});

export default handleActions(actions, initState);
