import React from 'react';
import { IStore, IStoreCategory, IStoreConfig } from '@/actions/store-action';
import LazyLoad from 'react-lazyload';
import Icon, { IconType } from '@/components/Icon';
import StoreTags from './StoreTags';
import { IReducersState } from '@/reducers';
import { getI18nText } from '@/utils/app';
import { prefix as parentPrefix } from '.';

interface IStoreListIconLeftProps {
  store: IStore;
  reducersState: IReducersState;
  storeCategories: IStoreCategory[];
  distanceTitle: string;
  className?: string;
  // onClick?: (store: IStore) => void;
}

const StoreListIconLeft: React.FC<IStoreListIconLeftProps> = props => {
  const { store, reducersState, storeCategories, distanceTitle, className = '' } = props;

  const storeConfig: IStoreConfig = reducersState.store.slugRefStoreConfig[store.slug];
  const { deviceInfo } = reducersState.app;
  // const handleClick = () => {
  //   if (onClick) {
  //     onClick(store);
  //   }
  // }

  const prefix = `${parentPrefix}-icon-left`;

  return (
    <div className={`${prefix} ${className}`}>
      <div className={`${prefix}-wapper`}>
        {
          deviceInfo.inPC &&
          <img src={store.imgUrl} alt="" className={`${prefix}-img`}/>
        }
        {
          !deviceInfo.inPC &&
          <LazyLoad once offset={100}>
            <img src={store.imgUrl} alt="" className={`${prefix}-img`}/>
          </LazyLoad>
        }
        <div className={`${prefix}-content`}>
          <div className="store-name">
            {getI18nText(store, 'title', reducersState.app.language) || store.name}
          </div>
          <div className="store-other-info">
            <div className="store-score">
              <Icon type={IconType.STAR_OUTLINED} />
              <span>{(Number(store.storeScore) || 5).toFixed(1)}</span>
            </div>
            {
              storeCategories && storeCategories.length > 0 &&
              storeCategories.slice(0, 1).map(item => {
                return [
                  <div key={`space-line-${item.id}`} className="splace-line" />,
                  <div key={`category-${item.id}`} className="store-category">
                    {getI18nText(item, 'category_name', reducersState.app.language)}
                  </div>
                ]
              })
            }
            {
              distanceTitle && false &&
              <>
                <div className="splace-line" />
                <div className="store-category">{distanceTitle}</div>
              </>
            }
          </div>
          <StoreTags
            store={store}
            storeConfig={storeConfig}
          />
        </div>
      </div>
      <div className={`${prefix}-line`} ></div>
    </div>
  );
}

export default StoreListIconLeft;
