import axios from 'axios';
import Config from '@/Config';

export interface ISendEmailParams {
  storeID: string;
  orderNumber?: string;
  name: string;
}
export interface ISendEmailParamsPost {
  storeID: string;
  orderNumber?: string;
  data: {};
}
export const getInvoiceMessage = async (params: ISendEmailParams) => {
  const {
    storeID,
    orderNumber,
  } = params;

  try {
    const resp = await axios({
      method: 'get',
      url: Config.urls.getInvoiceMessage.replace('{storeID}', storeID).replace('{orderNumber}', (orderNumber as any)),
    })
    window.console.log(resp)
    const {
      invoice,
      success,
    } = resp || {};

    if(success){
      const storeInvoice = invoice.store_invoice || {};
      const subTotal = invoice.sub_total || {};
      const invoiceDate = storeInvoice.invoice_date || {};
      const invoiceStatus = invoice.invoice_status || '';
      const orderID = storeInvoice.order_id || '';
      return {
        success,
        storeInvoice,
        invoice,
        subTotal,
        invoiceDate,
        invoiceStatus,
        orderID,
      };
    }
    else{
      const message = resp.data.message;
      const status = resp.status;
      return {
        status,
        message,
      };
    }
  } catch (error) {
    window.console.log(error)
  }
}


export const searchCompany = async (params: ISendEmailParams) => {
  const {
    name,
    storeID,
  } = params;

  try {
    const resp = await axios({
      method: 'get',
      url: Config.urls.searchCompany.replace('{name}', name).replace('{storeID}', storeID)
    })
    const {
      res,
      success,
    } = resp || {};

    const company = res || {};
    return {
      success,
      company
    };
  } catch (error) {
    window.console.log(error)
  }


}


export const postInvoiceMessage = async (params: ISendEmailParamsPost) => {
  const {
    storeID,
    data,
  } = params;

  try {
    const resp = await axios({
      method: 'post',
      data: data,
      url: Config.urls.postInvoiceMessage.replace('{storeID}', storeID),
    })
    const {
      result,
      success,
    } = resp || {};
    window.console.log(success);
    const code = resp.code || '';
    let message = '';
    if(resp && resp.data){
      message = resp.data.message;
    }
    return {
      success,
      result,
      code,
      message,
    };
  } catch (error) {
    window.console.log(error)
  }
}
