enum Channels {
  Web = 1,
  POS = 2,
  PUBLIC = 3,
  RESTAURANT_APP = 4,
  KIOSK = 8,
  PAYMENT_APP = 16,
}

export default Channels;
