import React, {useEffect, useState} from 'react';
import { prefix } from '.';
import { IObject } from '@/components/bm-common';
import { IPlatformCustomPage } from '@/actions/app-action';
import { resizeImage } from '@/utils';
import { IReducersState } from '@/reducers';
import { getI18nText } from '@/utils/app';
import ActionType from '@/actions/action-type';
import { IDispatch } from '@/actions';

interface ICustomPageProps {
  records: IPlatformCustomPage[];
  reducersState: IReducersState;
  dispatch: IDispatch;
}

const CustomPage: React.FC<ICustomPageProps> = props => {
  const {
    records,
    reducersState,
    dispatch
  } = props;
  
  let loaded = false;
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    let showLoading = false;
    if (records && records.length > 0) {
      records.forEach((item: IPlatformCustomPage) => {
        if (item.images && item.images.length > 0) {
          showLoading = true;
          return;
        }
      })
    }
    if (showLoading && !loaded) {
      dispatch({
        type: ActionType.LOADING,
        showLoading: true,
      });
    }
  }, [])


  return (
    <div className={`tab-pane w-tab-pane w--tab-active ${prefix}`}>
      <div className="section cms wf-section">
        <div className="div-block-537">
          {
            records && records.length > 0 && records.map((item: IObject) => {
              if (item.images && item.images.length > 0) {
                return(
                  item.images.map((url: string, index: number) => {
                    return (
                      <img
                        className={`${prefix}-img`}
                        key={index}
                        src={resizeImage({
                          url,
                          type: 'DEFAULT',
                        })}
                        onLoad={() => {
                          if (loading) {
                            dispatch({
                              type: ActionType.LOADING,
                              showLoading: false,
                            });
                            setLoading(false)
                            loaded=true;
                          }
                        } }
                        alt=""
                      />
                    )
                  })
                )
              } else {
                if (item.content && item.content.length > 0) {
                  return (
                    <div className={`${prefix}-content`} dangerouslySetInnerHTML={{
                      __html: getI18nText(item, 'content', reducersState.app.language) || (item.content) || '',
                    }} />
                  )
                }
                return undefined
              }
            })
          }
        </div>
      </div>
    </div>
  );
}

export default CustomPage;
