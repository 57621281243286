import React, { useState, useEffect } from 'react';
import InputItem from 'antd-mobile/es/input-item';
import Button from 'antd-mobile/es/button';
import Modal from 'antd-mobile/es/modal';
import Toast from 'antd-mobile/es/toast';
import { useSelector, useDispatch } from 'react-redux';
import { IReducersState } from '@/reducers';
import SignUserName from '@/components/common/userComponents/NewUserName';
import { emailSignUpWithCode } from '@/data/sign';
import { IDispatch } from '@/actions';
import ActionType from '@/actions/action-type';
import Icon, { IconType } from '@/components/Icon';
import NoticeUser from './NoticeUser';
import { prefix } from '.';
// import DatePicker from 'antd-mobile/es/date-picker';
// import List from 'antd-mobile/es/list';
import dayjs from 'dayjs';
import { Popup } from '@/components/Modal';
import RegisterContent from '@/actions/register-content';
import { IDialCodeGroupItem } from '@/components/DialCodeGroup';
import ReceiveInformation from './ReceiveInformation';
import { sendEmailVertifyCode } from '@/data/sign';
import RegisterMessage from '@/actions/register-message';
import { getIntl } from '../App/App';
import { langFormat } from '@/utils/app';

interface IEmailRetrieveProps {
  signUpClick?: () => void;
  className?: string;
}

const SignUpEmail: React.FC<IEmailRetrieveProps> = props => {
  const { className = '', signUpClick = () => {} } = props;

  const reducersState: IReducersState = useSelector<
    IReducersState,
    IReducersState
  >(state => state);

  const dispatch = useDispatch<IDispatch>();

  const { language, platformConfig } = reducersState.app;
  const { registerContent, registrationRequired, birthdayHiddenDayEnable, closeRegisterEmail } = platformConfig;

  const [email, setEmail] = useState<string>('');
  const [password, setPasswod] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [fullName, setFullName] = useState<string>('');
  const [selected, setSelected] = useState<boolean>(false);
  const [verfityEmail, setVerfityEmail] = useState<boolean>(false);
  const [loaing, setLoaing] = useState<boolean>(false);
  const [date, setDate] = useState<Date>();
  const [showGenderList, setShowGenderList] = useState<boolean>(false);
  const [gender, setGender] = useState<string>('');
  const [genderText, setGenderText] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [areaCodeData, setAreaCodeData] = useState<IDialCodeGroupItem>();
  const [received, setReceived] = useState<boolean>(true);
  const [showCountDown, setShowCountDown] = useState<boolean>(false);
  const [verifyCodeCountdown, setVerifyCodeCountdown] = useState<number>(60);
  const [verifyCode, setVerifyCode] = useState<string>('');
  const [verifyCount, setVerifyCount] = useState<number>(0);
  const [showPinMsg, setShowPinMsg] = useState<boolean>(false);
  const [day, setDay] = useState<string>('');
  const [month, setMonth] = useState<string>('');
  const [year, setYear] = useState<string>('');
  const [dayErr, setDayErr] = useState<boolean>(false);
  const [monthErr, setMonthErr] = useState<boolean>(false);
  const [yearErr, setYearErr] = useState<boolean>(false);

  const isRegistrationRequired =
    registrationRequired && registrationRequired.length > 0;
  const showAcceptPromotion =
    registerContent &&
    registerContent.length > 0 &&
    registerContent.includes(RegisterContent.ACCEPT_PROMOTION);

  let timeLoop: any;
  const SETVERIFYCODETIME = 'VERIFY_CODE_CLICK_TIME_SIGN_UP';

  useEffect(() => {
    if (date) {
      if (date > new Date()) {
        if (Number(year) > new Date().getFullYear()) {
          setYearErr(true);
        } else if (Number(month) - 1 > new Date().getMonth()) {
          setMonthErr(true);
        } else {
          setMonthErr(false);
          setDayErr(true);
        }
      } else {
        setMonthErr(false);
        setDayErr(false);
      }
    }
  }, [date]);

  const handleItemClick = (data: IDialCodeGroupItem) => {
    setAreaCodeData(data);
  };

  const signUpEmail = async () => {
    if (!email || loaing) return;
    const phone = phoneNumber.replace(/\s/g, '');
    if (
      isRegistrationRequired &&
      registrationRequired.includes(RegisterContent.BIRTHDAY) &&
      registerContent.includes(RegisterContent.BIRTHDAY) &&
      !date
    )
      return;
    if (
      isRegistrationRequired &&
      registrationRequired.includes(RegisterContent.GENDER) &&
      registerContent.includes(RegisterContent.GENDER) &&
      !gender
    )
      return;
    if (
      isRegistrationRequired &&
      registrationRequired.includes(RegisterContent.PHONE) &&
      registerContent.includes(RegisterContent.PHONE) &&
      !phone
    )
      return;
    setLoaing(true);
    dispatch({
      type: ActionType.LOADING,
      showLoading: true,
      showLoadingBgc: true,
    });
    try {
      const resp = await emailSignUpWithCode({
        userEmail: email,
        password,
        autCode: verifyCode,
        fullName,
        gender,
        dateOfBirth: date ? getShowDate(date) : '',
        phone: phone && areaCodeData ? `+${areaCodeData.dialCode} ${phone}` : '',
        acceptInformation: showAcceptPromotion ? received : false,
        auth: 1,
        language,
        closeRegisterEmail,
      });


      if (resp && !resp.success) {
        setLoaing(false);
        dispatch({
          type: ActionType.LOADING,
          showLoading: false,
        });

        let message = resp.message;

        if (resp.message === RegisterMessage.ENTER_VERIFY_CODE) {
          message = getIntl().page.enterVerifyCode;
        } else if (resp.message === RegisterMessage.PASSWORD_TO_SHORT) {
          // message = getIntl().page.passwordTooShort;
        } else if (resp.message === RegisterMessage.INVALID_AGAIN) {
          message = getIntl().page.invalidAgain;
        } else if (resp.message === RegisterMessage.UNKNOWN_STORE) {
          message = getIntl().page.unknownStore;
        } else if (resp.message === RegisterMessage.REGISTERED) {
          message = getIntl().page.registered;
        } else if (resp.message === RegisterMessage.ENCRYPTION_PASSWORD) {
          message = getIntl().page.encryptionPassword;
        } else if (resp.message === RegisterMessage.LOGIN_ALREADY_IN_USE) {
          message = getIntl().page.loginAlreadyInUse;
        } else if (
          resp.message.includes(RegisterMessage.INVALID_EMAIL_FORMAT)
        ) {
          message = langFormat(getIntl().page.invalidEmailFormat,
            {
              failed: message.substr(
                message.indexOf(':') + 1,
                message.indexOf('.com') - 3
              ),
            }
          );
        } else if (resp.message === RegisterMessage.CREATE_USER_FAIED) {
          message = getIntl().page.createUserFailed;
        } else if (resp.message === RegisterMessage.SERVICE_UNAVAILABLE) {
          message = getIntl().page.serviceUnavailable;
        } else if (resp.message === RegisterMessage.FAILED_TO_CREATE_LOGIN) {
          message = getIntl().page.failedToCreateLogin;
        } else if (resp.message === RegisterMessage.SAVE_CREATE_USER_FAILED) {
          message = getIntl().page.saveCreateUserFailed;
        } else if (
          resp.message.includes(RegisterMessage.SAVE_CREATE_LINKED_FAILED)
        ) {
          message = langFormat(getIntl().page.saveCreateLinkedFailed,
            { failed: message.substr(message.indexOf('error:') + 6) }
          );
        } else if (resp.message.includes(RegisterMessage.EMAIL_IS_USER)) {
          message = langFormat(getIntl().page.emailIsUser,
            {
              failed: message.substr(
                message.indexOf(':') + 1,
                message.indexOf('.com') - 3
              ),
            }
          );
        } else if (resp.message.includes(RegisterMessage.CREATE_EMAIL_FAILED)) {
          message = langFormat(getIntl().page.createEmailFailed,
            { failed: message.substr(message.indexOf('error:') + 6) }
          );
        } else if (
          resp.message.includes(RegisterMessage.UPDATE_VERIFY_CODE_FAILED)
        ) {
          message = langFormat(getIntl().page.updateVerifyCodeFailed,
            { failed: message.substr(message.indexOf('error:') + 6) }
          );
        } else if (resp.message === RegisterMessage.CREATE_PRESENTER_FAILED) {
          message = getIntl().page.createPresenterFailed;
        }

        Modal.alert('', message, [
          {
            text: getIntl().common.confirm,
            onPress: () => {},
          },
        ]);
      }

      if (resp && resp.success) {
        dispatch({
          type: ActionType.SIGN_IN,
          username: `${email}`,
          password: password,
          loginMethod: 'email',
          callback: (success, userInfo) => {
            if (success) {
              signUpClick();
            } else {
              Toast.info(getIntl().page.signInErrorMsg);
            }

            dispatch({
              type: ActionType.LOADING,
              showLoading: false,
            });
          },
        });
      }
    } catch (error) {
      setLoaing(false);
      dispatch({
        type: ActionType.LOADING,
        showLoading: false,
      });
    }
  };

  // const debounceFunction = (fn: Function, delay=3000) => {
  //   let timeLoop: any = null;
  //   return () => {
  //     if (timeLoop) {
  //       clearTimeout(timeLoop);
  //     };
  //     timeLoop = setTimeout(() => fn(), delay);
  //   }
  // }

  const extraPasswordComponent = () => {
    return (
      <div className={`${prefix}-mobile-verify-password-icon`}>
        {!showPassword && password && (
          <div onClick={() => setShowPassword(true)}>
            <Icon
              type={IconType.EYE_CLOSED_OUTLINED}
              className="card-icon-close"
            />
          </div>
        )}
        {showPassword && password && (
          <div onClick={() => setShowPassword(false)}>
            <Icon
              type={IconType.EYE_OPEN_OUTLINED}
              className="card-icon-close"
            />
          </div>
        )}
      </div>
    );
  };

  const getShowDate = (value: any) => {
    const dateValue = platformConfig.birthdayHiddenDayEnable
      ? dayjs(value).format('YYYY-MM')
      : dayjs(value).format('YYYY-MM-DD');
    return dateValue;
  };

  const chooseGender = (value: string, text: string) => {
    setGender(value);
    setGenderText(text);
    setShowGenderList(false);
  }

  const genderList = [
    {
      value: 1,
      text: getIntl().page.male,
    },
    {
      value: 2,
      text: getIntl().page.female,
    },
    {
      value: 3,
      text: getIntl().page.other,
    },
  ];

  const clearCountDown = () => {
    clearInterval(timeLoop);
    localStorage.removeItem(SETVERIFYCODETIME);
    setShowCountDown(false);
  };

  const sendVerifyCodeClick = () => {
    setShowCountDown(true);
    const nowTime = new Date();
    localStorage.setItem(SETVERIFYCODETIME, `${nowTime.getTime()}`);
    let countDown = verifyCodeCountdown;
    timeLoop = setInterval(() => {
      if (countDown < 1) {
        clearCountDown();
        setVerifyCodeCountdown(60);
      } else {
        countDown -= 1;
        setVerifyCodeCountdown(countDown);
      }
    }, 1000);
  };

  const extraVerifyComponent = () => {
    return (
      <div>
        {!verfityEmail && verifyCount === 0 && !showCountDown && (
          <div className={`${prefix}-mobile-verify-send-disabled`}>
            {getIntl().page.sendCode}
          </div>
        )}
        {verfityEmail && verifyCount === 0 && !showCountDown && (
          <div
            className={`${prefix}-mobile-verify-send`}
            onClick={sendVerifyCode}
          >
            {getIntl().page.sendCode}
          </div>
        )}
        {showCountDown && (
          <div className={`${prefix}-mobile-verify-send-disabled`}>
            {langFormat(getIntl().page.resendCodeTimer, { time: verifyCodeCountdown })}
          </div>
        )}
        {verifyCount !== 0 && !showCountDown && (
          <div
            className={`${prefix}-mobile-verify-send`}
            onClick={sendVerifyCode}
          >
            {getIntl().page.resendCode}
          </div>
        )}
      </div>
    );
  };

  const sendVerifyCode = async () => {
    if (!email || !verfityEmail) return;
    try {
      sendVerifyCodeClick();
      setVerifyCount(verifyCount + 1);
      const resp = await sendEmailVertifyCode({
        email: email,
        language: language,
        action: 'signup',
      });

      if (resp && !resp.success) {
        clearCountDown();
        Modal.alert('', resp.message, [
          {
            text: getIntl().common.confirm,
            onPress: () => {},
          },
        ]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onPinFieldClick = () => {
    setShowPinMsg(true);
  };

  const handleDayBlur = () => {
    if (Number(day) === 0 || Number(day) > 31) {
      setDayErr(true);
    } else {
      setDayErr(false);
      if (day && month) {
        handleCheckMonth();
      }
      if (!monthErr && !yearErr && month && year) {
        handleSetDate();
      }
    }
  };

  const handleMonthBlur = () => {
    if (Number(month) === 0 || Number(month) > 12) {
      setMonthErr(true);
    } else {
      setMonthErr(false);
      if (day && month) {
        handleCheckMonth();
      }
      if (!dayErr && !yearErr && month && year) {
        handleSetDate();
      }
    }
  };

  const handleYearBlur = () => {
    if (
      Number(year) === 0 ||
      Number(year) < 1892 ||
      Number(year) > new Date().getFullYear()
    ) {
      setYearErr(true);
    } else {
      setYearErr(false);
      if (day && month && year) {
        handleCheckMonth();
      }
      if (!dayErr && !monthErr && month && year) {
        handleSetDate();
      }
    }
  };

  const handleCheckMonth = () => {
    // convert string to number
    const dayEntry = Number(day);
    const monthEntry = Number(month);
    const yearEntry = Number(year);

    // check month
    switch (monthEntry) {
    case 2: // Feb
      if (dayEntry && monthEntry && yearEntry) {
        // check leap year
        if (
          (yearEntry % 4 === 0 && yearEntry % 100) ||
          yearEntry % 400 === 0
        ) {
          dayEntry > 29 ? setDayErr(true) : setDayErr(false);
        } else {
          dayEntry > 28 ? setDayErr(true) : setDayErr(false);
        }
      } else {
        dayEntry > 29 ? setDayErr(true) : setDayErr(false); // if no year entered, allow 29th
      }
      break;
    case 4:
    case 6:
    case 9:
    case 11: // Apr, Jun, Sep, Nov
      dayEntry > 30 ? setDayErr(true) : setDayErr(false);
      break;
    default:
      dayEntry > 31 ? setDayErr(true) : setDayErr(false);
    }
  };

  const handleSetDate = () => {
    if (birthdayHiddenDayEnable === true) {
      setDate(new Date(Number(year), Number(month) - 1)); // month convert to month integer of date object
    } else {
      if (day && month && year) {
        setDate(new Date(Number(year), Number(month) - 1, Number(day))); // month convert to month integer of date object
      }
    }
  };

  return (
    <div className={`${prefix}-email ${className}`}>
      <div className={`${prefix}-username`}>
        <SignUserName
          language={language}
          value={fullName}
          onChange={setFullName}
          type="USERNAME"
        />
      </div>

      <SignUserName
        language={language}
        value={email}
        onChange={setEmail}
        verfityEmail={(value: boolean) => setVerfityEmail(value)}
      />

      <div className={`${prefix}-mobile-verify`}>
        <InputItem
          name="verify"
          autoComplete="off"
          placeholder={getIntl().page.verifyCode}
          onChange={setVerifyCode}
          value={verifyCode}
          type="number"
          extra={extraVerifyComponent()}
          maxLength={6}
        />
      </div>

      <div className={`${prefix}-mobile-password`}>
        <InputItem
          name="password"
          autoComplete="off"
          placeholder={getIntl().common.password}
          onChange={setPasswod}
          onFocus={onPinFieldClick}
          value={password}
          type={showPassword ? 'text' : 'password'}
          extra={extraPasswordComponent()}
        />
        {showPinMsg && password.length < 6 && (
          <div className="password-check">
            {getIntl().page.passwordLength}
          </div>
        )}
      </div>

      {registerContent &&
      registerContent.length > 0 &&
      registerContent.includes(RegisterContent.PHONE) && (
        <div className={`${prefix}-mobile-password`}>
          <SignUserName
            language={language}
            value={phoneNumber}
            onChange={setPhoneNumber}
            onItemClick={(data: IDialCodeGroupItem) => handleItemClick(data)}
            showIcon={true}
            type="PHONE"
            areaCodeData={areaCodeData}
          />
        </div>
      )}
      {registerContent &&
      registerContent.length > 0 &&
      registerContent.includes(RegisterContent.BIRTHDAY) && (
        <div className={`${prefix}-mobile-date`}>
          <div className="birthday-title">
            {getIntl().page.birthday}
          </div>
          <div className="date-pick-wrap">
            {!birthdayHiddenDayEnable && (
              <InputItem
                name="day"
                type="number"
                autoComplete="off"
                value={day}
                placeholder={getIntl().common.dayCapitals}
                maxLength={2}
                onChange={setDay}
                onBlur={handleDayBlur}
                error={dayErr}
                className="text-field"
              />
            )}
            <InputItem
              name="month"
              type="number"
              autoComplete="off"
              value={month}
              placeholder={getIntl().common.monthCapitals}
              maxLength={2}
              onChange={setMonth}
              onBlur={handleMonthBlur}
              error={monthErr}
              className="text-field"
            />
            <InputItem
              name="year"
              type="number"
              autoComplete="off"
              value={year}
              placeholder={getIntl().common.yearCapitals}
              maxLength={4}
              onChange={setYear}
              onBlur={handleYearBlur}
              error={yearErr}
              className="text-field"
            />
          </div>
          <div className="date-pick-msg">
            {getIntl().page.registerDobMsg}
          </div>
        </div>
      )}
      {/* {
        registerContent && registerContent.length > 0 && registerContent.includes(RegisterContent.BIRTHDAY) &&
        <div className={`${prefix}-mobile-date`}>
          <div className="date-pick-wrap">
            <DatePicker
              mode={birthdayHiddenDayEnable ? 'month' : 'date'}
              title={getIntl().page.selectDate}
              extra={getIntl().page.birthday}
              locale={locale}
              value={date}
              minDate={new Date(1900, 0, 1)}
              maxDate={new Date()}
              onChange={setDate}
              format={(value: Date) => {
                return getShowDate(value);
              }}
            >
              <List.Item className={`${date ? 'selected' : ''}`}></List.Item>
            </DatePicker>
          </div>
          <div className="date-pick-msg">{getIntl().page.registerDobMsg}</div>
        </div>
      } */}
      {registerContent &&
      registerContent.length > 0 &&
      registerContent.includes(RegisterContent.GENDER) && (
        <>
          <div
            className={`${prefix}-mobile-gender`}
            onClick={() => setShowGenderList(true)}
          >
            <div className={`gender-name ${genderText ? 'selected' : ''}`}>
              {genderText
                ? genderText
                : getIntl().page.gender}
            </div>
            <Icon type={IconType.DOWN_OUTLINED} className="down-icon" />
          </div>
          {
            <Popup
              visible={showGenderList}
              title={getIntl().page.chooseGender}
              onClose={() => setShowGenderList(false)}
              className={`${prefix}-gender-list`}
            >
              <div className="gender-list">
                {genderList.map(item => (
                  <div
                    className="gender-wrap"
                    key={item.value}
                    onClick={() =>
                      chooseGender(String(item.value), item.text)
                    }
                  >
                    <div className="gender">{item.text}</div>
                    {gender && item.value === Number(gender) && (
                      <Icon
                        type={IconType.CHECKED_OUTLINED}
                        className="checked"
                      />
                    )}
                  </div>
                ))}
              </div>
            </Popup>
          }
        </>
      )}

      {registerContent &&
      registerContent.length > 0 &&
      registerContent.includes(RegisterContent.ACCEPT_PROMOTION) && (
        <ReceiveInformation
          selectd={received}
          onSelect={setReceived}
        />
      )}

      <NoticeUser selectd={selected} onSelect={setSelected} />

      {
        <Button
          type="primary"
          className={`${prefix}-signin-button`}
          onClick={signUpEmail}
          disabled={
            !email ||
            !password ||
            password.length < 6 ||
            !verifyCode ||
            !fullName ||
            !selected ||
            !verfityEmail ||
            (isRegistrationRequired &&
              registrationRequired.includes(RegisterContent.BIRTHDAY) &&
              registerContent.includes(RegisterContent.BIRTHDAY) &&
              !date) ||
            dayErr ||
            monthErr ||
            yearErr ||
            (isRegistrationRequired &&
              registrationRequired.includes(RegisterContent.GENDER) &&
              registerContent.includes(RegisterContent.GENDER) &&
              !gender) ||
            (isRegistrationRequired &&
              registrationRequired.includes(RegisterContent.PHONE) &&
              registerContent.includes(RegisterContent.PHONE) &&
              !phoneNumber)
          }
        >
          {getIntl().page.signup}
        </Button>
      }
    </div>
  );
};
export default SignUpEmail;
