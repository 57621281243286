enum SaleType {
  /**
   * Register sale, Use it on ***offline mode***
   */
  REGISTER_SALE = 'register_sale',
  /**
   * Invoice sale, Use it on ***online mode***
   */
  INVOICE_SALE = 'invoice_sale',
    /**
   * purchase_request, Use it on ***Express Order***
   */
  PURCHASE_REQUEST = 'purchase_request',
}

export default SaleType;
