import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import withRoute from '@/pages/withRoute';
import BrandListPage from './BrandListPage';
import './BrandListPage.less';

export const prefix = 'bm-p-brand-list';

const RouteComponent: React.FC<RouteComponentProps> = props => withRoute({
  pageComponent: BrandListPage,
  routeProps: props,
  pageTitleI18nKey: '',
});

export default RouteComponent;
