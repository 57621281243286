import { RouteComponentProps } from 'react-router-dom';
import withRoute from '@/pages/withRoute';
import MembershipPage from './MembershipPage';
import './MembershipPage.less';

export const prefix = 'bm-p-membership';

const RouteComponent: React.FC<RouteComponentProps> = props => withRoute({
  pageComponent: MembershipPage,
  routeProps: props,
  pageTitleI18nKey: '',
});
export default RouteComponent;
