import ServerEnv from './constants/ServerEnv';

let serverEnv: string = process.env.REACT_APP_SERVER_ENV || '';
export const isUpgrade = false;
export const upgradeToV = 'v2';
export const appVersion = '3.0.1';
export const deviceType = '4';
export const deviceID = '123456';
const appType = 'bindo-mobile';

if (serverEnv === ServerEnv.DEVELOPMENT) {
  serverEnv = ServerEnv.STAGING;
  serverEnv = ServerEnv.ALPHA;
  // serverEnv = ServerEnv.PRODUCTION;
}

const isProduction = serverEnv === ServerEnv.PRODUCTION;
export const isAlpha = serverEnv === ServerEnv.ALPHA;
export const isStaging = serverEnv === ServerEnv.STAGING;

// if (isStaging || isAlpha) {
//   const VConsole = require('vconsole')
//   new VConsole();
// }
  
let storageVersion = 'v1';
let clientID = '2x9bfv9okp25jzwvrnzi0it';
let clientSecret = 'aoug6tdt76lyopsbu5mr1ug3b';
let mianApi = 'https://main-stg.bindo.co/api';
let galaxyUri = 'https://main-stg.bindo.co/api/v5/galaxy3';
let gatewayV4 = 'https://main-stg.bindo.co/gateway/v4';
let mianApiV4 = 'https://main-stg.bindo.co/api/v4';
let mianApiV2 = 'https://main-stg.bindo.co/api/v2';
let gatewayApiV4 = 'https://gateway-stg.bindo.co/api/v4';
let mianApiV5 = 'https://main-stg.bindo.co/api/v5';
let baseApiUrl = 'https://api-stg.bindo.co';
let mbDoamin = 'hq-stg.bindo.com';
let appMerchantDomain = 'gateway.stg.gobindo.com';
// let defaultPageDomain = 'alpha.stg.gobindo.com';
let defaultPageDomain = 'birdymart.stg.gobindo.com';
let domainSuffix = '.stg.gobindo.com';
let cookieDomain = 'stg.gobindo.com';
let bindoCom = 'https://stg.bindo.co';
let bindoGatewayStoreSlug = '95e';
let apiV2 = 'https://stg.bindo.co/api/v2';
let apiV4 = 'https://stg.bindo.co/api/v4';
let faceBookAppID = '538294720284884';
const devMainV5 = 'https://dev-main.bindo.io/api/v5';
let cdnUrl = '';
let gatewayV2 = 'https://gateway.trybindo.com/v2';
let bindoHq = 'https://pay-stg.wonder.app';

if (serverEnv === ServerEnv.PRODUCTION) {
  // appType += 'production';
  clientID = 'dc2zhqpvisyqct1f36nolapx5i1z8e8';
  clientSecret = 'ttvp5vzgty6qevi55qmfu7fqsgtfhax';
  mbDoamin = 'hq.bindo.com';
  appMerchantDomain = 'gateway.gobindo.com';
  defaultPageDomain = 'alpha.gobindo.com';
  domainSuffix = '.gobindo.com';
  cookieDomain = 'gobindo.com';
  mianApi = 'https://main.bindo.co/api';
  galaxyUri = 'https://main.bindo.co/api/v5/galaxy3';
  gatewayV4 = 'https://main.bindo.co/gateway/v4';
  mianApiV4 = 'https://main.bindo.co/api/v4';
  mianApiV2 = 'https://main.bindo.co/api//v2';
  gatewayApiV4 = 'https://gateway.bindo.co/api/v4';
  mianApiV5 = 'https://main.bindo.co/api/v5';
  baseApiUrl = 'https://api2.bindo.co'
  bindoCom = 'https://bindo.co';
  bindoGatewayStoreSlug = 'y27';
  apiV2 = 'https://bindo.co/api/v2';
  apiV4 = 'https://bindo.co/api/v4';
  cdnUrl = 'https://cdn-gobindo-alpha.bindo.co';
  gatewayV2 = 'https://gateway.bindo.com/v2';
  bindoHq = 'https://pay.wonder.app';
  storageVersion = 'v1';
} else if (serverEnv === ServerEnv.ALPHA) {
  // appType += 'alpha';
  clientID = 'dc2zhqpvisyqct1f36nolapx5i1z8e8';
  clientSecret = 'ttvp5vzgty6qevi55qmfu7fqsgtfhax';
  mbDoamin = 'hq-alpha.bindo.com';
  appMerchantDomain = 'gateway.alpha.gobindo.com';
  defaultPageDomain = 'birdymart.alpha.gobindo.com';
  domainSuffix = '.alpha.gobindo.com';
  cookieDomain = 'alpha.gobindo.com';
  mianApi = 'https://main-alpha.bindo.co/api';
  galaxyUri = 'https://main-alpha.bindo.co/api/v5/galaxy3';
  gatewayV4 = 'https://main-alpha.bindo.co/gateway/v4';
  mianApiV4 = 'https://main-alpha.bindo.co/api/v4';
  mianApiV2 = 'https://main-alpha.bindo.co/api/v2';
  gatewayApiV4 = 'https://gateway-alpha.bindo.co/api/v4';
  mianApiV5 = 'https://main-alpha.bindo.co/api/v5';
  baseApiUrl = 'https://api2-alpha.bindo.co';
  bindoGatewayStoreSlug = 'y27';
  bindoCom = 'https://alpha.bindo.co';
  apiV2 = 'https://alpha.bindo.co/api/v2';
  apiV4 = 'https://alpha.bindo.co/api/v4';
  cdnUrl = 'https://cdn-gobindo-alpha-alpha.bindo.co';
  gatewayV2 = 'https://gateway-alpha.bindo.com/v2';
  bindoHq = 'https://pay-alpha.wonder.app';
  storageVersion = 'v1';
} else if (serverEnv === ServerEnv.STAGING) {
  // defaultPageDomain = 'birdymart.stg.gobindo.com';
  // appType += 'staging';
  // defaultPageDomain = 'birdymart.stg.gobindo.com';
  cdnUrl = 'https://cdn-gobindo-alpha-stg.bindo.co';
} else {
  // appType += 'development';
  faceBookAppID = '1006222003152641';
}

if (process.env.REACT_APP_SERVER_ENV === ServerEnv.DEVELOPMENT) {
  cdnUrl = '';
}

const Config = {
  mianApi,
  galaxy: galaxyUri,
  clientID,
  clientSecret,
  mbDoamin,
  appMerchantDomain,
  defaultPageDomain,
  domainSuffix,
  cookieDomain,
  bindoGatewayStoreSlug,
  defaultStoreImg: `${cdnUrl}/images/default-store.png`,
  defaultLineItemImg: `${cdnUrl}/images/default-line-item.png`,
  defaultCouponImg: `${cdnUrl}/images/default-coupon-bg.png`,
  isAlpha,
  isProduction,
  isStaging,
  userAppType: appType,
  userAppVersion: appVersion,
  userDeviceType: deviceType,
  userDeviceID: deviceID,
  gatewayV4,
  baseApiUrl,
  faceBookAppID,
  cdnUrl,
  storageVersion,
  bindoHq,
  urls: {
    /** Store */
    queryStore:             `${mianApiV4}/public/store_slugs/{storeSlug}?client_id=${clientID}&client_secret=${clientSecret}`,
    queryStoreByID:         `${mianApiV4}/public/stores/{storeID}?client_id=${clientID}&client_secret=${clientSecret}`,
    queryStores:            `${mianApiV5}/marketplace/advance_store_search?domain={domain}`,
    queryGroupStores:       `${mianApiV4}/groups/{storeID}/members`,
    queryRecommendedStores: `${mianApiV5}/shop/recommendation?platform_id={platformID}`,
    queryStoreImages:       `${bindoCom}/api/v4/stores/{storeID}/pictures`,
    getStoreTables:         `${gatewayApiV4}/store_slugs/{storeSlug}/tables?client_id=${clientID}&client_secret=${clientSecret}`,
    updataStorePicture:     `${bindoCom}/api/v1/crm/stores/{storeID}/pictures`,
    getPickupLocations:     `${mianApiV4}/public/store_slugs/{storeSlug}/pickup_locations`,
    checkPlatform:          `${mianApiV5}/public/platform/check`,
    queryPlatform:          `${mianApi}/module_builder/platform/mobile_home`,
    queryAllStoreInfo:      `${mianApi}/module_builder/platform/mobile_store_configs`,

    /** User */
    signIn:             `${bindoCom}/api/v2/login`,
    signUp:             `${bindoCom}/api/v2/signup`,
    customer:           `${mianApiV4}/store_slugs/{storeSlug}/customer/current`,
    // me:                 `${baseApiUrl}/me`,
    me:                 `${mianApiV4}/me`,
    sendSMSCode:        `${mianApiV5}/send_sms_code`,
    sendEmailVerify:    `${mianApiV5}/email_verification_code`,
    restPassword:       `${mianApiV5}/reset_phone_password`,
    restPasswordEmail:  `${mianApiV5}/forgot_password?identifier={email}&client_id=${clientID}&client_secret=${clientSecret}&platform_id={platformID}&aut_code={authCode}`,
    forgotPasswordEmail:`${mianApiV2}/forgot_password?aut_code={authCode}`,
    signUpPhone:        `${mianApiV5}/phone_signup`,
    signUpEmailV2:      `${apiV2}/signup`,
    signUpEmailWithCode:`${mianApiV5}/email_signup_with_code`,
    fbSignIn:           `${bindoCom}/api/v2/fb_login`,
    appleSignCallback:  `${mianApiV5}/social_oauth/apple/callback`,
    appleSignIn:        `${mianApiV5}/social_oauth/apple/login`,
    address:            `${mianApiV4}/user/addresses`,
    loyaltyStamp:       `${gatewayApiV4}/stores/{storeID}/loyalty_stamp`,
    customerGrowth:     `${mianApiV4}/stores/{storeID}/customers/{customerID}/growth`,
    earnPoints:         `${mianApiV4}/customers/{customerID}/push_order_msg`,
    signUpEmail:        `${mianApiV5}/email_signup`,
    customerPromotion:  `${mianApiV4}/customer/{customerID}/customer_promotion?platform_id={platformID}`, // &date_start=2021-03-21 03:43:52&date_end=2021-04-26

    /** E-coupon */
    queryPromotions:     `${mianApiV4}/store/{storeID}/promotions?platform_id={platformID}`,
    redeemPromotions:    `${mianApiV4}/store/{storeID}/customer/{customerID}/exchange_coupons`,

    /** Payment */
    creditCards:        `${mianApiV4}/credit_cards`,
    payInvoice:         `${gatewayV4}/stores/{storeID}/invoices/{orderNumber}/pay`,
    applePaySession:    `${gatewayV4}/stores/{storeID}/apple_pay_session`,
    getGiftCards:       `${gatewayV4}/store_slugs/{storeSlug}/customers/{customerID}/gift_cards`,
    getGiftCardBalance: `${gatewayV4}/gift_card_balance?number={number}&printed_pin={pin}&store_id={storeID}`,
    voidTransactions:   `${gatewayV4}/stores/{storeID}/invoices/{orderNumber}/transactions/void`,
    queryGooglePayMerchantInfo: `${mianApi}/b2c/app/payment_link/google_pay/public_key/{id}`,

    /** Order */
    getInvoices:  `${gatewayV4}/stores/{storeID}/invoices/{orderNumber}?includes_void_line_items=true`,
    invoices:     `${gatewayV4}/stores/{storeID}/invoices`,
    checkOrder:   `${gatewayV4}/stores/{storeID}/invoices/{orderNumber}/check`,
    getOrders:    `${gatewayV4}/marketplace/user/orders`,
    sendEmail:    `${gatewayV4}/stores/{storeID}/email_template/send`,
    taxOptions:   `${apiV4}/stores/{storeID}/tax_options`,
    getCoupons:   `${mianApiV4}/stores/{storeID}/coupons/validate`,

    /** Listing */
    getStoreMenus:        `${mianApiV4}/stores/{storeID}/favorite_tabs?page=1&per_page=999&with_details=true`,
    getModifierSets:      `${mianApiV4}/stores/{storeID}/modifier_sets?page=1&per_page=999`,
    getListings:          `${mianApiV4}/stores/{storeID}/listings/{ids}`,
    getFavoriteListings:  `${mianApiV4}/express_order?user_id={userID}&platform_id={platformID}`,

    /** Invoice */
    getInvoiceMessage:   `${devMainV5}/stores/{storeID}/invoice_platform/{orderNumber}`,
    searchCompany:       `${devMainV5}/stores/{storeID}/invoice_platform/search_company_name?name={name}`,
    // searchCompany:       `${devMainV5}/stores/7039/invoice_platform/search_company_name?name={name}`,
    postInvoiceMessage:  `${devMainV5}/stores/{storeID}/invoice_platform`,
    createQueueing:      `${mianApiV4}/stores/{storeID}/parties`,

    /** deferred billing */
    queryDeferredInvoices:`${gatewayV4}/stores/{storeID}/get_deferred_order`,
    createInvoice:        `${gatewayV4}/stores/{storeID}/invoices_deferred_order`,

    /** Qrcode Shopping */
    qrShopingCustomerOrder:       `${gatewayApiV4}/stores/{storeID}/invoices/{orderNumber}/customer_order?auth_code={authCode}&mobile_order=true`,
    qrShopingAmendmentsOrder:     `${gatewayApiV4}/stores/{storeID}/order_amendments?auth_code={authCode}&order_number={orderNumber}`,
    postQrShopingAmendmentsOrder: `${gatewayApiV4}/stores/{storeID}/order_amendments`,
    /** Point **/
    getRewards:          `${mianApiV4}/stores/{storeID}/customers/{customerID}/rewards`,
    redeem:              `${mianApiV4}/stores/{storeID}/customers/{customerID}/rewards/{rewardID}/redeem`,

    deviceStatus:        `${mianApiV5}/device_status?user_id={userID}&device_id={deviceID}`,
    getNotificationPlatform:  `${mianApiV4}/stores/{storeID}/notification_platform`,
    postNotificationPlatform: `${mianApiV4}/stores/{storeID}/notification_platform/device_register`,

    /** Purchase Order **/
    getPurchaseOrder:             `${gatewayV2}/stores/{storeID}/purchase_orders/{purchaseOrderID}`,
    purchaseOrderCancel:          `${gatewayV2}/stores/{storeID}/purchase_orders/{purchaseOrderID}/cancel`,

    /** Receive Orders **/
    getReceiveOrders:         `${gatewayV2}/stores/{storeID}/receive_orders?note_type[]={noteType}&order_asc=desc&order_by=id&page={page}&per_page=10`,
    getReceiveOrder:          `${gatewayV2}/stores/{storeID}/receive_orders/{receiveOrderID}`,
    cancelReceiveOrder:       `${gatewayV2}/stores/{storeID}/receive_orders/{receiveOrderID}/cancel`,
    receiveReceiveOrder:      `${gatewayV2}/stores/{storeID}/receive_orders/{receiveOrderID}/receive`,
    createReceiveOrder:       `${gatewayV2}/stores/{storeID}/receive_orders`,
  },
};

export default Config;
