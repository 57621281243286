
import React from 'react';
import AntdIcon from 'antd-mobile/es/icon';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import Icon, { IconType } from '@/components/Icon';
// import Layout from '@/components/Layout';
import BookingItem from './BookingItem';
import ActionType from '@/actions/action-type';
import { IBooking } from '@/actions/booking-action';
import paths from '@/routes/paths';
import { prefix } from '.';
import { getAppTheme, setScreenColor, getIntl } from '@/components/App/App';
import Components from '@/components/SideNavBar/Components';
import SideNavOption from '@/actions/side-nav-option';
import { BookingsPageStyled } from './styled';
import Layout from '@/components/Layout';

export interface IBookingsPageProps extends IBasePageProps {}
export interface IBookingsPageState {
  bookings: IBooking[] | undefined;
  page: number;
  loading: boolean;
}

class BookingsPage extends BasePage<IBookingsPageProps, IBookingsPageState> {
  lastPage = false;
  loadingData = false;
  state: IBookingsPageState = {
    bookings: undefined,
    page: 1,
    loading: false,
  }

  componentDidMount() {
    this.getBookings(1);
    const appTheme = getAppTheme();
    if (appTheme.lightBackgroundColor) {
      setScreenColor(appTheme.lightBackgroundColor);
    }

    super.componentDidMount();
    
    const pageWrap = window.document.querySelector('.page-content-wrapper-web');
    if (pageWrap) {
      pageWrap.addEventListener('scroll', this.handleScroll, { passive: true });
    } else {
      window.addEventListener('scroll', this.handleScroll, { passive: true });
    }
  }

  componentWillUnmount() {
    const pageWrap = window.document.querySelector('.page-content-wrapper-web');
    if (pageWrap) {
      pageWrap.removeEventListener('scroll', this.handleScroll);
    } else {
      window.removeEventListener('scroll', this.handleScroll);
    }
  }

  getScrollY = () => {
    const pageWrap = window.document.querySelector('.page-content-wrapper-web');
    let scrollY = window.scrollY;
    if (pageWrap) {
      scrollY = pageWrap.scrollTop;
    }

    return scrollY;
  }

  handleScroll = () => {
    const { page } = this.state;
    if (!this.lastPage && !this.loadingData) {
      const appPages = document.getElementsByClassName('bm-p-bookings');
      if (appPages && appPages.length) {
        const appPage = appPages[0];
        const scrollHeight = appPage.scrollHeight;
        const canScrollHeight = scrollHeight - window.innerHeight;
        if (canScrollHeight - this.getScrollY() < 3 * 200) {
          this.loadingData = true
          this.setState({loading: true});
          this.getBookings(page + 1, true);
        }
      }
    }
  }

  getBookings = (page: number, isAdd = false,) => {
    const {
      dispatch,
      reducersState,
    } = this.props;
    const { user } = reducersState;
    const { userInfo } = user;

    if (!isAdd) {
      dispatch({
        type: ActionType.LOADING,
        showLoading: true,
      })
    }

    if (userInfo) {
      dispatch({
        type: ActionType.QUERY_BOOKINGS,
        formula: `EQ("$.self.linked_source_id", "${userInfo.id}").EQ("$.self.booking_type", "2")`,
        page,
        multi: true,
        id: '',
        callback: newBookings => {
          if (newBookings) {
            let { bookings } = this.state;
            if (isAdd && bookings) {
              bookings = bookings.concat(newBookings);
            } else {
              bookings = newBookings;
            }
            if (newBookings.length < 10) this.lastPage = true;
      
            if (newBookings) this.loadingData = false;
            this.setState({
              bookings,
              loading: false,
              page,
            })
          }
          dispatch({
            type: ActionType.LOADING,
            showLoading: false,
          })
        }
      })
    }
  }

  handleClick = (id: string) => {
    this.handleGotoRoute({
      path: paths.BOOKING_DETAIL,
      pathParams: {
        bookingID: id,
      },
    });
  }

  render() {
    console.info(1233)
    const {
      bookings,
      loading,
    } = this.state;

    const {
      dispatch,
      reducersState,
    } = this.props;
    const { language } = reducersState.app;
    return (
      <Layout
        {...this.props}
        isFooter={false}
        className={prefix}
      >
        <Components
          {...this.props}
          reducersState={reducersState}
          onLeftClick={() => this.handleGoBack({
            path: paths.ACCOUNT,
            runGoBack: true,
          })}
          navContent={getIntl().page.myBookings}
          navIcon={<Icon type={IconType.LEFT_OUTLINED} className="html-embed-7 w-embed"/>}
          sideNavOption={SideNavOption.BOOKING}
          whiteBanner={false}
        >
          <BookingsPageStyled className="div-block-547 booking">
            <div className="section booking-1 wf-section">
              <div className="div-block-638">
                {
                  bookings &&
                  bookings.map(booking => {
                    return <BookingItem key={`${booking.storeID}_${booking.id}`} data={booking} goToDetailPage={this.handleClick} dispatch={dispatch} language={language} />
                  })
                }
              </div>
            </div>
            {
              bookings && loading &&
              <div className="loading-data">
                <AntdIcon type="loading" />
                <div className="loading-msg">
                  {getIntl().common.loading}
                </div>
              </div>
            }
            {
              bookings && bookings.length === 0 &&
              <div className={'no-bookings'}>
                <div>
                  <Icon className="img" type={IconType.TAG_ITEM}/>
                  <div className="no-bookings-tip">
                    {getIntl().page.noBookingTip}
                  </div>
                </div>
              </div>
            }
          </BookingsPageStyled>
        </Components>
      </Layout>
    );
  }
}

export default BookingsPage;
