import { takeEvery, put, select, call, all } from 'redux-saga/effects';
import axios from 'axios';
import Config from '@/Config';
import Action from '@/actions';
import ActionType from '@/actions/action-type';
import { IReducersState } from '@/reducers';
import { IStoreState } from '@/reducers/store-reducer';
import { parseResStore, parseResStoreConfig, parseResStoreImage, parseResPlatformStore, parseResStoreCategories } from '@/utils/store-util';
import { getStoreBlogList, getStoreConfig, createStore, getStoresByUser, getStoresConfigs, UpdateRecord, getPlatformStoreAssociations, QueryUserByEmail, getStoreBrands } from '@/data/StoreSetting';
import { signUpUser } from '@/data/sign';
import {
  IQueryStore,
  IStore,
  IQueryStoreByID,
  IQueryStores,
  IQueryGroupStores,
  IQueryRecommendedStores,
  ISearchStores,
  IQueryStoreTables,
  IStoreTable,
  IQueryBlogList,
  IBlog,
  IStoreConfig,
  IQueryBlog,
  IQueryStoreImages,
  IStoreImage,
  ICreateStore,
  IStoreInput,
  IQueryStoresByUser,
  IUpdateStore,
  IADDPlatformStoreAssociations,
  IQueryPlatformStoreAssociations,
  IUpdatePlatformStoreAssociations,
  IPlatformStore,
  IQueryStoresConfigs,
  IUpdateStoreImages,
  IUpdateStoreConfigs,
  IQueryStoresByCategory,
  IAdvancePlaceStores,
  IQueryStoresByFilter,
  IQueryBrandsByCategory,
  IStoreCategory,
  StoreFilterMode,
  IQueryStoresTotalByFilter,
  IStorePickupLocation,
  IQueryStorePickupLocations,
  IQueryStoreSlugByRandomCode,
  IStoreMsg,
} from '@/actions/store-action';
import { getCoordinates } from '@/utils/app';
import { IObject } from '@/components/bm-common';
import { createRecord, queryRecords } from '@/data/mb-api';

export function* handleQueryStore(params: IQueryStore) {
  const { storeSlug, notRequestStoreConfig = false, callback, currentPlatformID } = params;
  const state: IReducersState = yield select((state: IReducersState) => state);
  let store: IStore | undefined = undefined;
  let storeConfig: IStoreConfig | undefined = undefined;
  let platformID = '';
  const storeInfoReqs: any[] = [];
  let reqIndex = -1;
  if (currentPlatformID) {
    platformID = currentPlatformID;
  } else if (state.app.platformAppSettings) {
    platformID = state.app.platformAppSettings.id;
  }

  try {
    // 先从slugRefStore中获取
    if (storeSlug && state.store.slugRefStore[storeSlug]) {
      store = state.store.slugRefStore[storeSlug];
    }

    if (storeSlug && state.store.slugRefStoreConfig[storeSlug]) {
      storeConfig = state.store.slugRefStoreConfig[storeSlug];
    }

    let storeReqIndex = -1;
    if (storeSlug && !store) {
      storeReqIndex = ++reqIndex;
      const storeRes = call(
        axios.get,
        Config.urls.queryStore.replace('{storeSlug}', storeSlug),
      );

      storeInfoReqs.push(storeRes);
    }

    let storeConfigReqIndex = -1;
    if (!notRequestStoreConfig && !storeConfig && platformID) {
      storeConfigReqIndex = ++reqIndex;
      const storeConfigRes = getStoreConfig({platformID, storeSlug});
      storeInfoReqs.push(storeConfigRes);
    }

    let storeInfoData: any[] = [];
    if (storeInfoReqs.length > 0) {
      storeInfoData = yield all(storeInfoReqs);
    }

    if (storeReqIndex > -1 && storeInfoData.length > storeReqIndex) {
      const tempStoreRes = storeInfoData[storeReqIndex];
      if (tempStoreRes && tempStoreRes.store) {
        store = parseResStore(tempStoreRes.store);
        if (store) {
          yield put<Action>({
            type: ActionType.SET_STORE,
            storeSlug,
            store,
          });
        }
      }
    }

    if (storeConfigReqIndex > -1 && storeInfoData.length > storeConfigReqIndex) {
      storeConfig = parseResStoreConfig(storeInfoData[storeConfigReqIndex], platformID);
      yield put<Action>({
        type: ActionType.SET_STORE_CONFIG,
        storeSlug,
        storeConfig,
      });
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(store, storeConfig);
  }

  return {
    store,
    storeConfig,
  };
}

export function* handleQueryStoreByID(params: IQueryStoreByID) {
  const { storeID, notRequestStoreConfig = false, callback } = params;
  const state: IReducersState = yield select((state: IReducersState) => state);
  let store: IStore | undefined = undefined;
  let storeConfig: IStoreConfig | undefined = undefined;
  let platformID = '';

  if (state.app.platformAppSettings) {
    platformID = state.app.platformAppSettings.id;
  }

  try {
    // 先从slugRefStore中获取
    const stores = Object.values(state.store.slugRefStore);
    for (let i = 0; i < stores.length; i++) {
      const s = stores[i];
      if (storeID && s && String(s.id) === String(storeID)) {
        store = s;
        break;
      }
    }

    if (!store) {
      const res = yield call(
        axios.get,
        Config.urls.queryStoreByID.replace('{storeID}', storeID),
      );

      if (res && res.store && res.store.id === Number(storeID)) {
        store = parseResStore(res.store);
      }

      if (!notRequestStoreConfig && !storeConfig && platformID && store) {
        const storeConfigRes = yield getStoreConfig({platformID, storeID: store.id});
        storeConfig = parseResStoreConfig(storeConfigRes, platformID);
        yield put<Action>({
          type: ActionType.SET_STORE_CONFIG,
          storeSlug: store.slug,
          storeConfig,
        });
      }

      if (store) {
        yield put<Action>({
          type: ActionType.SET_STORE,
          storeSlug: store.slug,
          store,
        });
      }
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(store, storeConfig);
  }

  return { store, storeConfig };
}

export function* handleQueryStoresConfigs(params: IQueryStoresConfigs) {
  const { stores } = params;
  const state: IReducersState = yield select((state: IReducersState) => state);
  let platformID = '';

  if (state.app.platformAppSettings) {
    platformID = state.app.platformAppSettings.id;
  }
  const storeIDs: string[] = [];
  stores.forEach(item => {
    if (!state.store.slugRefStoreConfig[item.slug]) {
      storeIDs.push(item.id);
    }
  })

  try {
    if (platformID && storeIDs.length > 0) {
      const storesConfigsRes: any[] = yield getStoresConfigs({platformID, storeIDs});

      const storesConfigs: IStoreConfig[] = [];
      storesConfigsRes.forEach(item => {
        storesConfigs.push(parseResStoreConfig(item, platformID))
      })
      if (storesConfigsRes.length > 0) {
        yield put<Action>({
          type: ActionType.SET_STORES_CONFIGS,
          storesConfigs,
        });
      }
    }
  } catch (error) {
    console.error(error);
  }
}

export function* handleQueryStores(params: IQueryStores) {
  let { page } = params;
  const { serviceType = 'browse', callback } = params;
  const state: IReducersState = yield select((state: IReducersState) => state);
  const { typeRefStores } = state.store;
  if (page < 1) page = 1;

  let queryStores: IStore[] = [];
  let lastPage = false;
  try {
    const isExist = (typeRefStores[serviceType] || []).length < ((page - 1) * 10 + 1);
    // const {} = state.app.platformConfig;
    if (isExist) {
      const res = yield call(
        axios.get,
        `${Config.urls.queryStores.replace('{domain}', state.app.platformDomain)}&optional=0&${serviceType}=1&page=${page}`,
      );

      let resStores: any[] = [];
      if (res && Array.isArray(res.stores)) {
        resStores = res.stores;
      }

      if (resStores.length < 1) {
        lastPage = true;
      }

      const storeIDs: string[] = [];
      resStores.forEach(item => {
        const store = parseResStore(item);
        if (store) {
          storeIDs.push(store.id);
          queryStores.push(store)
        }
      });
    }

    if (isExist && queryStores.length > 0) {
      yield put<Action>({
        type: ActionType.QUERY_STORES_CONFIGS,
        stores: queryStores,
      });
      yield put<Action>({
        type: ActionType.PUSH_STORES,
        serviceType,
        stores: queryStores,
      });
    } else {
      queryStores = typeRefStores[serviceType] || [];
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(queryStores, page, lastPage);
  }

  return queryStores;
}

export function* handleQueryStoresByCategory(params: IQueryStoresByCategory) {
  let { page } = params;
  const { categoryID = '', pageType, callback, storeType } = params;
  const state: IReducersState = yield select((state: IReducersState) => state);
  const { typeRefStoresByCategory } = state.store;
  if (page < 1) page = 1;
  let queryStores: IStore[] = [];
  let lastPage = false;

  const searchParams: IObject = {
    domain: state.app.platformDomain,
    optional: 0,
    page,
    browse: 1,
  };

  if (pageType) {
    searchParams.brands = categoryID;
  } else {
    searchParams.category = categoryID;
  }

  if (storeType) {
    searchParams[`${storeType}`] = 1;
  }


  try {
    const isExist = (typeRefStoresByCategory[`${storeType}`] ? typeRefStoresByCategory[`${storeType}`][categoryID] || [] : []).length < ((page - 1) * 10 + 1);
    // const {} = state.app.platformConfig;
    if (isExist) {
      // const res = yield call(
      //   axios.get,
      //   `${Config.urls.queryStores.replace('{domain}', state.app.platformDomain)}&optional=0&category=${categoryID}&browse=1&page=${page}`,
      // );

      const res = yield axios.get(`${Config.urls.queryStores.replace('?domain={domain}', '')}`, {
        params: {
          ...searchParams,
        }
      })

      let resStores: any[] = [];
      if (res && Array.isArray(res.stores)) {
        resStores = res.stores;
      }

      if (resStores.length < 1) {
        lastPage = true;
      }

      const storeIDs: string[] = [];
      resStores.forEach(item => {
        const store = parseResStore(item);
        if (store) {
          storeIDs.push(store.id);
          queryStores.push(store)
        }
      });
    }

    if (isExist && queryStores.length > 0) {
      yield put<Action>({
        type: ActionType.QUERY_STORES_CONFIGS,
        stores: queryStores,
      });
      yield put<Action>({
        type: ActionType.PUSH_STORES_BY_CATEGORY,
        categoryID,
        stores: queryStores,
        storeType,
      });
    } else {
      queryStores = typeRefStoresByCategory[`${storeType}`] ? typeRefStoresByCategory[`${storeType}`][categoryID] || [] : [];
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(queryStores, page, lastPage);
  }

  return queryStores;
}

export function* handleQueryBrandsByCategory(params: IQueryBrandsByCategory) {
  const { categoryID, callback } = params;
  let storeBrands: IStoreCategory[] = [];
  const state: IReducersState = yield select((state: IReducersState) => state);
  const { typeRefBrand } = state.store;
  storeBrands = typeRefBrand[categoryID];
  try {
    if (!storeBrands || storeBrands.length === 0) {
      const res = yield getStoreBrands(categoryID);
      storeBrands = parseResStoreCategories(res);
      yield put<Action>({
        type: ActionType.PUSH_BRANDS_BY_CATEGORY,
        categoryID,
        brands: storeBrands,
      });
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(storeBrands);
  }
}

export function* handleQueryGroupStores(params: IQueryGroupStores) {
  const { storeSlug, callback } = params;
  const storeState: IStoreState = yield select((state: IReducersState) => state.store);

  let queryStores: IStore[] = [];
  let store: IStore | undefined;
  try {
    if (Array.isArray(storeState.slugRefGroupStores[storeSlug])) {
      queryStores = storeState.slugRefGroupStores[storeSlug];
    } else {
      const queryStoreResult = yield handleQueryStore({
        type: ActionType.QUERY_STORE,
        storeSlug,
      });

      store = queryStoreResult.store;

      if (store && store.id) {
        const res = yield call(
          axios.get,
          Config.urls.queryGroupStores.replace('{storeID}', `${store.id}`),
        );

        let resStores: any[] = [];
        if (res && res.group && Array.isArray(res.group.stores)) {
          resStores = res.group.stores;
        }

        resStores.forEach(item => {
          const store = parseResStore(item);
          if (store) {
            queryStores.push(store)
          }
        })
      }

      yield put<Action>({
        type: ActionType.QUERY_STORES_CONFIGS,
        stores: queryStores,
      });
      yield put<Action>({
        type: ActionType.SET_GROUP_STORES,
        storeSlug,
        stores: queryStores,
      });
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(queryStores, store);
  }

  return queryStores;
}

function* handleQueryRecommendedStores(params: IQueryRecommendedStores) {
  const { callback } = params;
  const state: IReducersState = yield select((state: IReducersState) => state);
  const platformAppSettings = state.app.platformAppSettings;
  let platformID = '0';

  if (platformAppSettings) {
    platformID = platformAppSettings.id;
  }

  let recommendedStores: IStore[] = [];
  try {
    if (state.store.recommendedStores) {
      recommendedStores = state.store.recommendedStores;
    } else if (platformID && platformID !== '0') {
      const res = yield call(
        axios.get,
        Config.urls.queryRecommendedStores.replace('{platformID}', platformID),
      );

      let resRecommendStores: any[] = [];
      if (res && Array.isArray(res.recommend_store)) {
        resRecommendStores = res.recommend_store;
      }

      resRecommendStores.forEach(item => {
        const store = parseResStore(item);
        if (store) {
          recommendedStores.push(store)
        }
      });

      yield put<Action>({
        type: ActionType.SET_RECOMMENDED_STORES,
        stores: recommendedStores,
      });
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(recommendedStores);
  }
}

function* handleSearchStores(params: ISearchStores) {
  const { searchKey, callback } = params;
  const state: IReducersState = yield select((state: IReducersState) => state);
  let searchStores: IStore[] = [];

  const key = `${state.app.platformDomain || ''}_${searchKey || ''}`;
  try {
    if (state.store.searchKeyRefStores[key]) {
      searchStores = state.store.searchKeyRefStores[key].stores;
    } else {
      const res = yield call(
        axios.get,
        `${Config.urls.queryStores.replace('{domain}', state.app.platformDomain)}&title=${searchKey}`
      );

      let resStores: any[] = [];
      if (res && Array.isArray(res.stores)) {
        resStores = res.stores;
      }

      resStores.forEach(item => {
        const store = parseResStore(item);
        if (store) {
          searchStores.push(store)
        }
      })

      yield put<Action>({
        type: ActionType.SET_SEARCH_STORES,
        key,
        stores: searchStores,
      });

      yield put<Action>({
        type: ActionType.QUERY_STORES_CONFIGS,
        stores: searchStores,
      });
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(searchStores)
  }

  return searchStores;
}


export function* handleQueryStoreTables(params: IQueryStoreTables) {
  const { storeSlug, callback } = params;
  const storeState: IStoreState = yield select((state: IReducersState) => state.store);
  let storeTables: IStoreTable[] | undefined = undefined;

  try {
    // 先从slugRefStore中获取
    if (Array.isArray(storeState.slugRefTables[storeSlug])) {
      storeTables = storeState.slugRefTables[storeSlug];
    }

    if (!Array.isArray(storeTables)) {
      storeTables = [];
      const res = yield call(
        axios.get,
        Config.urls.getStoreTables.replace('{storeSlug}', storeSlug),
      );

      let resTables: any[] = [];

      if (res && Array.isArray(res.tables)) {
        resTables = res.tables;
      }

      resTables.forEach(item => {
        if (storeTables) {
          storeTables.push({
            id: item.id,
            name: item.name,
            uuid: item.uuid,
            turnTime: 90,
            numberOfPeople: 1,
          })
        }
      })

      if (Array.isArray(storeTables)) {
        yield put<Action>({
          type: ActionType.SET_STORE_TABLES,
          storeSlug,
          tables: storeTables,
        });
      }
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(storeTables || []);
  }

  return storeTables || [];
}

function* handleQueryBlogList (params: IQueryBlogList) {
  const { contentType, storeSlug, callback } = params;
  const storeState: IStoreState = yield select((state: IReducersState) => state.store);
  let list: IBlog[] | undefined = undefined;

  try {
    // if (contentType === 'BLOG' && Array.isArray(storeState.slugRefBlogs[storeSlug])) {
    //   list = storeState.slugRefBlogs[storeSlug];
    // }
    if (contentType === 'COMPANY_INFO' && Array.isArray(storeState.slugRefCompanyInfo[storeSlug])) {
      list = storeState.slugRefCompanyInfo[storeSlug];
    }

    // if (contentType === 'PROMOTIONS' && Array.isArray(storeState.slugRefPromotions[storeSlug])) {
    //   list = storeState.slugRefPromotions[storeSlug];
    // }

    if (!list) {
      list = yield getStoreBlogList({storeSlug, contentType});
      // if (contentType === 'BLOG') {
      //   if (Array.isArray(list)) {
      //     yield put<Action>({
      //       type: ActionType.SET_STORE_BLOGS,
      //       storeSlug,
      //       blogs: list,
      //     });
      //   }
      // } else
      if (contentType === 'COMPANY_INFO') {
        if (Array.isArray(list)) {
          yield put<Action>({
            type: ActionType.SET_STORE_COMPANY_INFO,
            storeSlug,
            companyInfo: list,
          });
        }
      }
      // else if (contentType === 'PROMOTIONS') {
      //   if (Array.isArray(list)) {
      //     yield put<Action>({
      //       type: ActionType.SET_STORE_PROMOTIONS,
      //       storeSlug,
      //       companyInfo: list,
      //     });
      //   }
      // }
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(list || []);
  }

  return list || []
}

function* handleQueryBlog (params: IQueryBlog) {
  const { contentType, storeSlug, callback, id } = params;
  let blog: IBlog | undefined = undefined;

  try {
    if (id) {
      const list: IBlog[] = yield handleQueryBlogList({
        type: ActionType.QUERY_BLOG_LIST,
        contentType,
        storeSlug,
      });

      if (Array.isArray(list) && list.length > 0) {
        for (let i = 0; i < list.length; i++) {
          const tempBlog = list[i];
          if (tempBlog && String(tempBlog.id) === String(id)) {
            blog = tempBlog;
            break;
          }
        }
      }
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(blog);
  }

  return blog;
}

export function* handleQueryStoreImages (params: IQueryStoreImages) {
  const { storeSlug, callback } = params;
  let storeImages: IStoreImage[] = [];
  const storeState: IStoreState = yield select((state: IReducersState) => state.store);

  try {
    if (Array.isArray(storeState.slugRefStoreImages[storeSlug])) {
      storeImages = storeState.slugRefStoreImages[storeSlug];
    } else {
      const storeResp = yield handleQueryStore({
        type: ActionType.QUERY_STORE,
        storeSlug,
      });

      let store: IStore | undefined = undefined;
      if (storeResp) {
        store = storeResp.store;
      }

      if (store) {
        const res = yield call(
          axios.get,
          Config.urls.queryStoreImages.replace('{storeID}', store.id),
        );

        if (Array.isArray(res)) {
          res.forEach(item => {
            const storeImage = parseResStoreImage(item);
            if (storeImage) {
              storeImages.push(storeImage);
            }
          })
        }
      }

      yield put<Action>({
        type: ActionType.SET_STORE_IMAGES,
        storeSlug,
        storeImages,
      });
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(storeImages);
  }

  return storeImages;
}

function* handleCreateStore (params: ICreateStore) {
  const {
    title,
    businessMode,
    countryCode,
    callingCode,
    phone,
    zipcode,
    address1,
    city,
    callback,
    userID: IDUser,
    storeID: IDStore,
    password,
    fullName,
    email,
    lat,
    lng,
    files,
  } = params;

  let storeID = IDStore;
  let userID = IDUser;
  let newRecord = undefined;
  const state: IReducersState = yield select((state: IReducersState) => state);
  try {
    if (!IDUser) {
      const record = yield QueryUserByEmail(email);
      if (Array.isArray(record) && record.length > 0) {
        userID = record[0].user_id;
      }
      if (!userID) {
        userID = yield signUpUser({password: password, phone, fullName, userEmail: email, userName: email})
      }
    }

    if (userID) {
      const store: IStoreInput = {
        title,
        businessMode,
        countryCode,
        callingCode,
        phone,
        zipcode,
        address1,
        city,
        lat,
        lng,
      }

      if (!lat || !lng) {
        delete store['lat'];
        delete store['lng'];
      }

      const record = yield createStore(store);
      if (record && record.storeData && record.storeData.id) {
        storeID = record.storeData.id;
      }
    }
    if (userID && storeID) {
      const record = {
        'store_id': {
          records: [{id: storeID}]
        },
        'user_id': userID,
        'associate_type': {
          records: [{id: '1'}]
        }
      }
      const userInfo = state.user.userInfo;
      if (userInfo && userInfo.id !== userID) {
        const result = yield createRecord({
          storeSlug: Config.bindoGatewayStoreSlug,
          moduleBundleID: Config.isStaging ? '4hq4tpoy' : 'associad',
          record,
        });
        newRecord = result.record;
      }
      yield handleUpdataStoreImages({
        type: ActionType.UPDATE_STORE_IMAGES,
        storeID,
        originalUrl: '',
        files,
        notRequestStoreConfig: true,
      })
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(userID, storeID, newRecord);
  }
}

function* handleQueryStoresByUser (params: IQueryStoresByUser) {
  const {
    userID,
    storeName,
    page,
    perPage,
    callback,
  } = params;
  const storeIDs: string[] = [];

  try {
    let formula = `EQ("$.self.user_id", "${userID}").EQ("$.self.associate_type", "1")`
    if (storeName) {
      formula = `${formula}.CONTAIN("$.self.store_name", "${storeName}")`
    }
    const record = yield getStoresByUser(formula, page, perPage);
    if (Array.isArray(record)) {
      record.forEach(item => {
        if (item.store_id) {
          storeIDs.push(item.store_id);
        }
      })
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(storeIDs);
  }
}

function* handleUpdataStore (params: IUpdateStore) {
  const {
    storeID,
    callingCode,
    phone,
    storeName,
    countryCode,
    address,
    zipcode,
    open,
    callback,
    city,
    lat,
    lng,
    dineIn,
    dineInUnassigned,
    pickUp,
    delivery,
    booking,
    queueing,
  } = params;

  let id = '';
  let record: any

  try {
    if (open) {
      record = {'open': open}
    } else {
      record = {
        'country_code': Config.isStaging ? countryCode : {records: [{iso: countryCode}]},
        'calling_code': callingCode,
        'phone': phone,
        'zipcode': zipcode,
        'address1': address,
        'title': storeName,
        'city': city,
        'lat': lat,
        'lng': lng,
        'dine_in': dineIn,
        'dine_in_unassigned': dineInUnassigned,
        'pickup': pickUp,
        'delivery': delivery,
        'bookings': booking,
        'queueings': queueing,
      }
      if (!city || !lat || !lng) {
        delete record['city'];
        delete record['lat'];
        delete record['lng'];
      }
    }
    const result = yield UpdateRecord(storeID, record, Config.bindoGatewayStoreSlug, Config.isStaging ? 'f2u43n1r' : 'storeadm');
    if (result && result.id) {
      id = result.id;
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(id);
  }
}

function* handleQueryPlatformStoreAssociations (params: IQueryPlatformStoreAssociations) {
  const {
    storeID,
    platformID,
    callback,
  } = params;
  let platformStore: IPlatformStore | undefined;

  try {
    const records = yield getPlatformStoreAssociations(platformID, storeID);
    if (Array.isArray(records) && records.length > 0) {
      platformStore = parseResPlatformStore(records[0]);
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(platformStore);
  }
}

function* handleAddPlatformStoreAssociations (params: IADDPlatformStoreAssociations) {
  const {
    storeID,
    platformID,
    status,
    environment,
    callback,
  } = params;
  let platformStore: IPlatformStore | undefined;

  try {
    const record = {
      'platform_id': {
        records: [{id: platformID}]
      },
      'store_id': {
        records: [{id: storeID}]
      },
      'store_audit': status,
      'environment': environment,
    }

    const result1 = yield createRecord({
      storeSlug: Config.bindoGatewayStoreSlug,
      moduleBundleID: 'bm-platform-stores',
      record,
    });
    const platformStoreRes = result1.record;

    const result2 = yield createRecord({
      storeSlug: Config.bindoGatewayStoreSlug,
      moduleBundleID: 'bm-store-configures',
      record: {'platform_id': {records: [{id: platformID}]}, 'store_id': {records: [{id: storeID}]}},
    });
    const storeConfig = parseResStoreConfig(result2.record, platformID);
    yield put<Action>({
      type: ActionType.SET_STORE_CONFIG,
      storeSlug: storeConfig.storeSlug,
      storeConfig,
    });
    platformStore = parseResPlatformStore(platformStoreRes);
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(platformStore);
  }
}

function* handleUpdatePlatformStoreAssociations (params: IUpdatePlatformStoreAssociations) {
  const {
    id,
    status,
    environment,
    callback,
  } = params;
  let platformStore: IPlatformStore | undefined;

  try {
    const record = {
      'store_audit': status,
      'environment': environment,
    }
    const result = yield UpdateRecord(id, record, Config.bindoGatewayStoreSlug, 'bm-platform-stores');
    platformStore = parseResPlatformStore(result);
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(platformStore);
  }
}

function* handleUpdataStoreImages (params: IUpdateStoreImages) {
  const {
    storeID,
    originalUrl,
    files,
    notRequestStoreConfig,
    callback,
  } = params;

  let newStore: IStore | undefined = undefined;
  const state: IReducersState = yield select((state: IReducersState) => state);
  const platformID = state.app.platformConfig.platformID;
  try {
    if (files.length > 0 && files[0].url && files[0].url !== originalUrl) {
      const file = files[files.length - 1].file;
      const formData = new FormData();
      formData.append('store_picture[image]', file);
      formData.append('store_picture[primary]', 'undefined');
      const picture = formData;
      const res = yield axios({
        headers: { 'Content-Type' : 'multipart/form-data' },
        method: 'POST',
        url:  Config.urls.updataStorePicture.replace('{storeID}', storeID),
        data: picture,
      });
      if (res && res.store_picture && res.store_picture.id) {
        yield axios({
          headers: { 'Content-Type' : 'multipart/form-data' },
          method: 'PUT',
          url:  Config.urls.updataStorePicture.replace('{storeID}', storeID).concat(`/${res.store_picture.id}/primary`),
        });
      }
    }

    const result = yield call(
      axios.get,
      Config.urls.queryStoreByID.replace('{storeID}', storeID),
    );
    if (result) {
      newStore = parseResStore(result.store);
      if (newStore) {
        yield put<Action>({
          type: ActionType.SET_STORE,
          storeSlug: newStore.slug,
          store: newStore,
        });
        if (!notRequestStoreConfig) {
          const storeConfigRes = yield getStoreConfig({platformID, storeID});
          const storeConfig = parseResStoreConfig(storeConfigRes, platformID);
          yield put<Action>({
            type: ActionType.SET_STORE_CONFIG,
            storeSlug: newStore.slug,
            storeConfig,
          });
        }
      }
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(newStore);
  }
}

function* handleUpdateStoreConfigs (params: IUpdateStoreConfigs) {
  const {
    storeID,
    platformID,
    storeConfig,
    callback,
    showOrder,
    foodpanda,
    deliveroo,
    showFeedback,
    showNews,
    showAboutUs,
    showSelectProcedureMethod,
    showMenu,
    showFapiao,
    pickupAdvanceOrderDate,
    pickupInterval,
    deliveryAdvanceOrderDate,
    deliveryInterval,
    deliveryMiles,
    deliveryFee,
    deliveryDesc,
    deliveryMinAmount,
  } = params;
  let storesConfigs: IStoreConfig | undefined = undefined;
  try {
    const record = {
      'platform_id': {records: [{id: platformID}]},
      'store_id': {records: [{id: storeID}]},
      'foodpanda': foodpanda,
      'deliveroo': deliveroo,
      'order': showOrder,
      'feedback': showFeedback,
      'news': showNews,
      'about_us': showAboutUs,
      'auto_show_procurement_method': showSelectProcedureMethod,
      'menu': showMenu,
      'fapiao': showFapiao,
      'pickup_advance_order_date': Number(pickupAdvanceOrderDate) || undefined,
      'pickup_interval': pickupInterval,
      'delivery_advance_order_date': Number(deliveryAdvanceOrderDate) || undefined,
      'delivery_interval': deliveryInterval || undefined,
      'delivery_miles': Number(deliveryMiles) || undefined,
      'delivery_fee': Number(deliveryFee) || undefined,
      'delivery_min_amount': Number(deliveryMinAmount) || undefined,
      'delivery_desc': deliveryDesc,
    }
    if (!pickupAdvanceOrderDate) {
      delete record['pickup_advance_order_date'];
    }
    if (!pickupInterval) {
      delete record['pickup_interval'];
    }
    if (!deliveryAdvanceOrderDate) {
      delete record['delivery_advance_order_date'];
    }
    if (!deliveryInterval) {
      delete record['delivery_interval'];
    }
    if (!deliveryMiles) {
      delete record['delivery_miles'];
    }
    if (!deliveryFee) {
      delete record['delivery_fee'];
    }
    if (!deliveryMinAmount) {
      delete record['delivery_min_amount'];
    }
    if (!storeConfig || !storeConfig.id) {
      const result = yield createRecord({
        storeSlug: Config.bindoGatewayStoreSlug,
        moduleBundleID: 'bm-store-configures',
        record,
      });
      storesConfigs = parseResStoreConfig(result.record, platformID);
    } else {
      const result = yield UpdateRecord(storeConfig.id, record, Config.bindoGatewayStoreSlug, 'bm-store-configures');
      storesConfigs = parseResStoreConfig(result, platformID);
    }
    yield put<Action>({
      type: ActionType.SET_STORE_CONFIG,
      storeSlug: storesConfigs.storeSlug,
      storeConfig: storesConfigs,
    });
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(storesConfigs);
  }
}

function* handleSearchStoresByLocation (params: IAdvancePlaceStores) {
  const {
    lat,
    lng,
    radius = '3',
    callback,
  } = params;
  const state: IReducersState = yield select((state: IReducersState) => state);
  let searchStores: IStore[] = [];
  const key = `${state.app.platformDomain || ''}_lat=${lat || ''}lng=${lng || ''}`;
  try {
    if (state.store.searchKeyRefStores[key]) {
      searchStores = state.store.searchKeyRefStores[key].stores;
    } else {
      const res = yield call(
        axios.get,
        `${Config.urls.queryStores.replace('{domain}', state.app.platformDomain)}&lat=${lat}&lng=${lng}&radius=${radius}&optional=1`,
      );

      let resStores: any[] = [];
      if (res && Array.isArray(res.stores)) {
        resStores = res.stores;
      }

      resStores.forEach(item => {
        const store = parseResStore(item);
        if (store) {
          searchStores.push(store)
        }
      });

      yield put<Action>({
        type: ActionType.SET_SEARCH_STORES,
        key,
        stores: searchStores,
      });

      yield put<Action>({
        type: ActionType.QUERY_STORES_CONFIGS,
        stores: searchStores,
      });
    }
  } catch (error) {
    console.error(error);
  }


  if (callback) {
    callback(searchStores);
  }
}

function* handleQueryStoresByFilter (params: IQueryStoresByFilter) {
  const state: IReducersState = yield select((state: IReducersState) => state);
  const { filterRefStores } = state.store;
  const { category, radius, prices, brands, district } = filterRefStores;
  const { selectedStoreServiceType = 'browse', coordinates } = state.app;
  let { page } = params;
  const { callback, mode, categoryID, replace, storeType } = params;
  if (page < 1) page = 1;
  const queryStores: IStore[] = [];
  let lastPage = false;
  let searchParams: IObject = {
    domain: state.app.platformDomain,
    optional: 0,
    page,
  };

  searchParams[selectedStoreServiceType] = 1;
  const categoryParams = [...category].join();
  if (categoryParams) {
    searchParams.category = categoryParams;
  }

  const brandsParams = [...brands].join();
  if (brandsParams) {
    searchParams.brands = brandsParams;
  }

  const districtParams = [...district].join();
  if (districtParams) {
    searchParams.district = districtParams;
  }

  if (prices[0]) {
    searchParams['min_avg_consumption'] = prices[0];
    searchParams['max_avg_consumption'] = prices[1];
  }

  if (radius && coordinates) {
    const coords = yield getCoordinates();
    if (coords) {
      const { latitude, longitude } = coords;
      searchParams.optional = 1;
      searchParams = {
        ...searchParams,
        radius,
        lat: latitude,
        lng: longitude
      }
    }
  }

  const { typeRefBrand } = state.store;
  const brandStores: IStoreCategory[] = typeRefBrand[selectedStoreServiceType] ? typeRefBrand[selectedStoreServiceType] : [];

  try {
    const isExist = brandStores.length < ((page - 1) * 10 + 1);
    if (isExist) {
      const res = yield axios.get(`${Config.urls.queryStores.replace('?domain={domain}', '')}`, {
        params: {
          ...searchParams,
        }
      })

      let resStores: any[] = [];
      if (res && Array.isArray(res.stores)) {
        resStores = res.stores;
      }

      if (resStores.length < 1) {
        lastPage = true;
      }

      const storeIDs: string[] = [];
      resStores.forEach(item => {
        const store = parseResStore(item);
        if (store) {
          storeIDs.push(store.id);
          queryStores.push(store)
        }
      });
    }

    if (isExist && queryStores.length > 0) {
      yield put<Action>({
        type: ActionType.QUERY_STORES_CONFIGS,
        stores: queryStores,
      });
    }

    if (mode && mode === StoreFilterMode.CATEGORY) {
      let newCategoryID = '';
      let modePushCategory = ''
      if (categoryID) {
        newCategoryID = categoryID;
      }

      if (replace) {
        modePushCategory = 'replace';
      }

      yield put<Action>({
        type: ActionType.PUSH_STORES_BY_CATEGORY,
        categoryID: newCategoryID,
        stores: queryStores,
        mode: modePushCategory,
        storeType,
      });
    } else {
      yield put<Action>({
        type: ActionType.PUSH_STORES,
        serviceType: selectedStoreServiceType,
        stores: queryStores,
        mode: 'replace',
      });
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(queryStores, page, lastPage);
  }

  return queryStores;
}

function* handleQueryStoresTotalByFilter (params: IQueryStoresTotalByFilter) {
  const state: IReducersState = yield select((state: IReducersState) => state);
  const { filterRefStores } = state.store;
  const { category, radius, prices, brands, district } = filterRefStores;
  const { selectedStoreServiceType = 'browse', coordinates } = state.app;
  const { callback } = params;
  let { page = '1' } = params;
  let total = 0;

  if (page < 1) page = 1;
  // const queryStores: IStore[] = [];
  let searchParams: IObject = {
    domain: state.app.platformDomain,
    optional: 0,
    page,
    'per_page': 1,
  };

  searchParams[selectedStoreServiceType] = 1;
  const categoryParams = [...category].join();
  if (categoryParams) {
    searchParams.category = categoryParams;
  }

  const brandsParams = [...brands].join();
  if (brandsParams) {
    searchParams.brands = brandsParams;
  }

  const districtParams = [...district].join();
  if (districtParams) {
    searchParams.district = districtParams;
  }

  if (prices[0]) {
    searchParams['min_avg_consumption'] = prices[0];
    searchParams['max_avg_consumption'] = prices[1];
  }

  if (radius && coordinates) {
    const coords = yield getCoordinates();
    if (coords) {
      const { latitude, longitude } = coords;
      searchParams.optional = 1;
      searchParams = {
        ...searchParams,
        radius,
        lat: latitude,
        lng: longitude
      }
    }
  };

  try {
    const { headers } = yield axios.get(`${Config.urls.queryStores.replace('?domain={domain}', '')}`, {
      params: {
        ...searchParams,
      },
      headers: {
        'X-USE-ALL-RESPONSE': true,
      }
    });

    if (headers && headers.link) {
      if (typeof headers.link === 'string') {
        const linkObj = JSON.parse(headers.link);
        if (linkObj) {
          total = linkObj['total_entries'];
        }
      }
    };
  } catch (errpr) {}

  if (callback) {
    callback(total);
  }

  return total;
}

export function* handleQueryStorePickupLocations (params: IQueryStorePickupLocations) {
  const state: IReducersState = yield select((state: IReducersState) => state);
  const { slugRefStorePickupLocations } = state.store;
  const { storeSlug, callback } = params;
  
  let storePickupLocations: IStorePickupLocation[] = [];

  try {
    // 先从slugRefStore中获取
    if (Array.isArray(slugRefStorePickupLocations[storeSlug])) {
      storePickupLocations= slugRefStorePickupLocations[storeSlug];
    }

    if (!Array.isArray(slugRefStorePickupLocations[storeSlug])) {
      storePickupLocations = [];
      const res = yield call(
        axios.get,
        Config.urls.getPickupLocations.replace('{storeSlug}', storeSlug),
      );

      let resTables: any[] = [];

      if (res && Array.isArray(res.pickup_locations)) {
        resTables = res.pickup_locations;
      }

      resTables.forEach(item => {
        if (storePickupLocations) {
          storePickupLocations.push({
            id: item.id,
            name: item.name,
            i18n: item.translation_info,
          })
        }
      })

      if (Array.isArray(storePickupLocations)) {
        yield put<Action>({
          type: ActionType.SET_STORE_PICKUP_LOCATIONS,
          storeSlug,
          storePickupLocations,
        });
      }
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(storePickupLocations);
  }

  return storePickupLocations;
}

export function* handleQueryStoreSlug(params: IQueryStoreSlugByRandomCode) {
  const { number, callback } = params;

  const state: IReducersState = yield select((state: IReducersState) => state);
  const { platformAppSettings }  = state.app;
  let isActive = false;
  let storeSlug = '';
  let storeMsg: IStoreMsg | undefined = undefined;
  let recordID = '';
  
  try {
    if (number && state.store.numberRefStores[number]) {
      storeMsg = state.store.numberRefStores[number];
      isActive = storeMsg.status === '1';
      storeSlug = storeMsg.storeSlug;
      recordID = storeMsg.recordID;
    } else {
      if (platformAppSettings) {
        const formula = `EQ("$.self.number", "${number}")`;
        const result = yield queryRecords({
          storeSlugs: [platformAppSettings.storeSlug],
          moduleBundleID: 'bm-activate-qrcode',
          search: {
            formulas: [{ formula }],
            perPage: 50,
          },
        });
        const { records } = result;
        if (records.length > 0) {
          storeSlug = records[0].store_slug;
          recordID = records[0].record_id;
          if (records[0].status === '1') {
            isActive = true;
          }
          storeMsg = {
            storeSlug: records[0].store_slug,
            status: records[0].status,
            recordID: records[0].record_id,
          }
          yield put<Action>({
            type: ActionType.SET_STORE_SLUG_BY_RANDOM_CODE,
            number,
            storeMsg,
          });
        }
      }
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(isActive, storeSlug, recordID)
  }

  return storeMsg;
}


function* storeSaga() {
  yield takeEvery(ActionType.QUERY_STORE, handleQueryStore);
  yield takeEvery(ActionType.QUERY_STORE_BY_ID, handleQueryStoreByID);
  yield takeEvery(ActionType.QUERY_STORES, handleQueryStores);
  yield takeEvery(ActionType.QUERY_STORE_PICKUP_LOCATIONS, handleQueryStorePickupLocations);
  yield takeEvery(ActionType.QUERY_STORES_BY_CATEGORY, handleQueryStoresByCategory);
  yield takeEvery(ActionType.QUERY_BRANDS_BY_CATEGORY, handleQueryBrandsByCategory);
  yield takeEvery(ActionType.QUERY_GROUP_STORES, handleQueryGroupStores);
  yield takeEvery(ActionType.QUERY_RECOMMENDED_STORES, handleQueryRecommendedStores);
  yield takeEvery(ActionType.SEARCH_STORES, handleSearchStores);
  yield takeEvery(ActionType.QUERY_STORE_TABLES, handleQueryStoreTables);
  yield takeEvery(ActionType.QUERY_BLOG_LIST, handleQueryBlogList);
  yield takeEvery(ActionType.QUERY_BLOG, handleQueryBlog);
  yield takeEvery(ActionType.QUERY_STORE_IMAGES, handleQueryStoreImages);
  yield takeEvery(ActionType.CREATE_STORE, handleCreateStore);
  yield takeEvery(ActionType.QUERY_STORES_BY_USER, handleQueryStoresByUser);
  yield takeEvery(ActionType.UPDATE_STORE, handleUpdataStore);
  yield takeEvery(ActionType.QUERY_PLATFORM_STORE_ASSOCIATIONS, handleQueryPlatformStoreAssociations);
  yield takeEvery(ActionType.ADD_PLATFORM_STORE_ASSOCIATIONS, handleAddPlatformStoreAssociations);
  yield takeEvery(ActionType.UPDATE_PLATFORM_STORE_ASSOCIATIONS, handleUpdatePlatformStoreAssociations);
  yield takeEvery(ActionType.QUERY_STORES_CONFIGS, handleQueryStoresConfigs);
  yield takeEvery(ActionType.UPDATE_STORE_IMAGES, handleUpdataStoreImages);
  yield takeEvery(ActionType.UPDATE_STORE_CONFIGS, handleUpdateStoreConfigs);
  yield takeEvery(ActionType.SEARCH_STORES_LOCATION, handleSearchStoresByLocation);
  yield takeEvery(ActionType.QUERY_STORES_BY_FILTER, handleQueryStoresByFilter);
  yield takeEvery(ActionType.QUERY_STORES_TOTAL_BY_FILTER, handleQueryStoresTotalByFilter);
  yield takeEvery(ActionType.QUERY_STORE_SLUG_BY_RANDOM_CODE, handleQueryStoreSlug);
}

export default storeSaga();
