import React, { useState, useEffect } from 'react';
import { IFeedback } from '@/data/Feedback';

interface IFeedbackProps {
  onSelect: React.Dispatch<React.SetStateAction<string[]>>;
  labels: string[];
  label: string;
  data: string;
  existence: boolean;
  feedBack: IFeedback | undefined;
}

const FeedbackLabel: React.FC<IFeedbackProps> = props => {

  const [select, setSelect] = useState<boolean>(false);

  useEffect(() => {
    if (feedBack && feedBack.feedBackTag.includes(label)) {
      setSelect(true);
    }
  }, [])

  const {
    label,
    labels,
    data,
    onSelect,
    existence,
    feedBack,
  } = props;
  

  const handleSelect = (value: string) => {
    if (!existence) {
      if (labels.includes(value)) {
        const newLabels: string[] = [];
        labels.forEach(item => {
          if (item !== value) {
            newLabels.push(item);
          }
          onSelect(newLabels);
        })
        
      } else {
        labels.push(value)
        onSelect(labels);
      }
      setSelect(!select)
    }
  }

  return (
    <div className={`feedback-content-label-item ${select ? 'select-label' : 'unselected-label'}`} onClick={() => handleSelect(label)}>
      {data}
    </div>
  );
}

export default FeedbackLabel;
