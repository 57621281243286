import React from 'react';
import dayjs from 'dayjs';
import { formatCurrency } from '@/components/Payment';
import { IOrderInfo } from '@/lib/order-builder/models/OrderEntity';
import { getIntlByPath } from '@/components/App/App';

interface IBookingItemProps {
  data: IOrderInfo;
  goToDetailPage: (storeSlug: string, orderNumber: string, suborderNumber: string) => void;
  language: string;
}

const OrderItem: React.FC<IBookingItemProps> = props => {
  const {
    goToDetailPage,
    data,
    language,
  } = props

  const orderItems: any = [];
  data.orderEntities.forEach(item => {
    orderItems.push(
      <div className="order-preview-block open-modal"  key={item.orderNumber} onClick={() => goToDetailPage(data.storeSlug, data.orderNumber, item.orderNumber)}>
        <div className="image-block restaurant" style={{backgroundImage: `url(${item.storeLogoUrl || '/images/default-store.png'})`}}></div>
        <div className="order-detail-preview">
          <div className="item-info with-space">
            <div className="text-block-212">#{item.referenceID}</div>
            <div className="heading-4-1">{item.storeTitle}</div>
          </div>
          <div className="div-block-544">
            <div className="text-block-213">{formatCurrency(data.initialTotal, data.currencyCode)}</div>
            <div className="line-separator"></div>
            <div className="text-block-213">{dayjs(data.orderTime).format(language === 'en-US' ? 'DD MMM YYYY' : 'DD/M/YYYY')}</div>
          </div>
          <div className="order-status-prev">{getIntlByPath(`common.${item.orderStatus || (item.invoiceState === 'partial_paid'? 'partialPaid': item.invoiceState)}`)}</div>
        </div>
      </div>
    )
  });
  return (
    orderItems
  );
}

export default OrderItem;
