import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import withRoute from '@/pages/withRoute';
import QrcodeShoppingPage from './QrcodeShoppingPage';
import './QrcodeShoppingPage.less';

export const prefix = 'bm-p-qrcode-shopping';

const RouteComponent: React.FC<RouteComponentProps> = props => withRoute({
  pageComponent: QrcodeShoppingPage,
  routeProps: props,
  pageTitleI18nKey: '',
});

export default RouteComponent;
