import React, { useEffect, useState } from 'react';
import { IBasePageProps } from '@/pages/BasePage';
import ActionType from '@/actions/action-type';
import HomeType from '@/actions/home-type';
import { IFavoriteTab } from '@/lib/order-builder/utils/api';
import { IStore, IStoreConfig } from '@/actions/store-action';
import StoresPage from '../../StoresPage';
import StoreGroupPage from '../../StoreGroupPage';
import MenuListPage from '../../MenuListPage';
import MenuGridPage from '../../MenuGridPage';
// import BrandsPage from '@/pages/brand-list/BrandsPage/BrandsPage';
import CategoryPage from '@/pages/category/CategoryPage/CategoryPage';
import MembershipPage from '@/pages/member-page/MembershipPage';
import ExpressOrder from '@/pages/express-order/ExpressOrder/ExpressOrder';
import Constants from '@/constants';
import BrandsPage from '@/pages/brand-list/BrandsPage/BrandsPage';

const HomePage: React.FC<IBasePageProps> = props => {
  const { dispatch, reducersState } = props;

  const [loadData, setLoadData] = useState(false);
  const [homeType, setHomeType] = useState<HomeType>(reducersState.app.homeType);
  const [menus, setMenus] = useState<IFavoriteTab[]>();
  const [store, setStore] = useState<IStore | undefined>(undefined);
  const [storeConfig, setStoreConfig] = useState<IStoreConfig | undefined>(undefined);
  const [groupStores, setGroupStores] = useState<IStore[]>([]);

  useEffect(() => {
    dispatch({
      type: ActionType.LOADING,
      showLoading: true,
    });
    dispatch({
      type: ActionType.LOAD_HOME_DATA,
      callback: params => {
        setHomeType(params.homeType);
        setMenus(params.menus);
        setStore(params.store);
        setStoreConfig(params.storeConfig);
        setGroupStores(params.groupStores);
        setLoadData(true);
      }
    })
  }, []);

  if (!loadData) {
    return null;
  }

  return (
    <>
      {
        homeType === HomeType.CATEGORY && localStorage.getItem(Constants.ADD_EXPRESS_ORDER) !== 'true' &&
        <ExpressOrder
          {...props}
          isHomePage={true}
        />
      }
      {
        homeType === HomeType.CATEGORY && localStorage.getItem(Constants.ADD_EXPRESS_ORDER) === 'true' &&
        <CategoryPage
          {...props}
          isHomePage={true}
        />
      }
      {
        homeType === HomeType.BRANDS &&
        <BrandsPage
          {...props}
          isHomePage={true}
        />
      }
      {
        homeType === HomeType.MEMBER &&
        <MembershipPage
          {...props}
        />
      }
      {
        homeType === HomeType.STORE_LIST &&
        <StoresPage
          {...props}
          isHomePage={true}
        />
      }
      {
        homeType === HomeType.STORE_GROUP &&
        <StoreGroupPage
          {...props}
          groupStore={store}
          stores={groupStores}
          isHomePage={true}
        />
      }
      {
        homeType === HomeType.MENU_LIST &&
        <MenuListPage
          {...props}
          menus={menus}
          store={store}
          storeConfig={storeConfig}
          isHomePage={true}
        />
      }
      {
        homeType === HomeType.MENU_GRID &&
        <MenuGridPage
          {...props}
          menus={menus}
          store={store}
          isHomePage={true}
        />
      }
    </>
  )
}

export default HomePage;

