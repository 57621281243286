import React from 'react';

interface IDownIcon {
  className?: string;
  onClick?: () => void;
}

const DownIcon: React.FC<IDownIcon> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <svg width="1em" height="0.25em" viewBox="0 0 36 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 3.04233C0 1.48104 1.44577 0.321283 2.96988 0.659973L17.7469 3.94376C17.9136 3.9808 18.0864 3.9808 18.2531 3.94376L33.0301 0.659973C34.5542 0.321283 36 1.48104 36 3.04233C36 4.18617 35.2056 5.17654 34.0889 5.42468L18.3903 8.91327C18.1332 8.97039 17.8668 8.97039 17.6097 8.91327L1.91106 5.42468C0.794451 5.17654 0 4.18617 0 3.04233Z" fill="#EAEAEA"/>
      </svg>
    </div>
  );
}

export default DownIcon;
