import React from 'react';
import { prefix } from '.';

interface IDataEntryWrapperProps {
  title?: React.ReactNode;
  errorMsg?: React.ReactNode;
  className?: string;
}

const DataEntryWrapper: React.FC<IDataEntryWrapperProps> = props => {
  const { className, title, children, errorMsg } = props;

  return (
    <div className={`${prefix} ${className || ''}`}>
      {
        title &&
        <div className="dew-title">{title}</div>
      }
      <div>{children}</div>
      {
        errorMsg &&
        <div className="error-msg">{errorMsg}</div>
      }
    </div>
  );
}

export default DataEntryWrapper;
