import React, { useEffect, useState } from 'react';
import InputItem from 'antd-mobile/es/input-item';
import Icon, { IconType } from '@/components/Icon';
import { formatCurrency } from '@/components/Payment';
import { lineItem, IReceiveOrder } from '@/actions/good-received-note-actions';
import { getIntl } from '@/components/App/App';
import { IStore } from '@/actions/store-action';

interface INewGoodsReceivedNoteItemProps {
  lineItem: lineItem;
  returnNote: IReceiveOrder;
  store: IStore;
  handleSetQuantity: (id: string, value: number) => void;
}

const NewReturnNoteItem: React.FC<INewGoodsReceivedNoteItemProps> = props => {
  const {
    lineItem,
    store,
    handleSetQuantity,
    returnNote
  } = props;

  useEffect(() => {
    setQuantity(lineItem.qtyRemaining.toString());
  }, [lineItem.qtyRemaining]);

  const handleMinusClick = (id: string) => {
    if (returnNote) {
      let qtyRemaining = 0;
      returnNote.purchaseOrders.forEach(purchaseOrder => {
        purchaseOrder.lineItems.forEach(item => {
          if (item.id === id) {
            if (item.qtyRemaining - 1 >= 0) {
              qtyRemaining = item.qtyRemaining - 1;
            } else {
              qtyRemaining = item.qtyRemaining;
            }
          }
        })
      })
      handleSetQuantity(id, qtyRemaining);
    }
  }

  const handlePlusClick = (id: string) => {
    if (returnNote) {
      let qtyRemaining = 0;
      returnNote.purchaseOrders.forEach(purchaseOrder => {
        purchaseOrder.lineItems.forEach(item => {
          if (item.id === id) {
            if (item.qtyRemaining + 1 <= item.qtyReceivedPO) {
              qtyRemaining = item.qtyRemaining + 1;
            } else {
              qtyRemaining = item.qtyReceivedPO;
            }
          }
        })
      })
      handleSetQuantity(id, qtyRemaining);
    }
  }

  const handleBlur = (id: string, value: string) => {
    let qtyRemaining = parseInt(value);
    if (isNaN(qtyRemaining) || qtyRemaining > lineItem.qtyReceivedPO) {
      qtyRemaining = lineItem.qtyReceivedPO;
    }
    handleSetQuantity(id, qtyRemaining);
  }

  const [quantity, setQuantity] = useState<string>(lineItem.qtyRemaining.toString());

  return (
    <div className="purchase-order-detail-line-item" key={lineItem.id}>
      <div className="purchase-order-detail-line-item-img" style={{ backgroundImage: `url(${lineItem.imageUrl})`}}></div>
      <div className="purchase-order-detail-line-item-info">
        <div className="purchase-order-detail-line-item-info-line">
          <div className="purchase-order-detail-line-item-info-line-name">{lineItem.name}</div>
          <div className="purchase-order-detail-line-item-info-line-quantity">
            <div className="item-count">
              <Icon
                className="item-minus"
                type={IconType.MINUS_CIRCLE_OUTLINED}
                onClick={() => handleMinusClick(lineItem.id)}
              />
              <div className="count">
                <InputItem
                  type="bankCard"
                  maxLength={4}
                  onChange={setQuantity}
                  value={quantity}
                  onBlur={value => handleBlur(lineItem.id, value || '0')}
                />
              </div>
              <Icon
                className="item-plus"
                type={IconType.PLUS_CIRCLE_OUTLINED}
                onClick={() => handlePlusClick(lineItem.id)}
              />
            </div>
          </div>
        </div>
        <div className="purchase-order-detail-line-item-info-line">
          <div className="purchase-order-detail-line-item-info-line-code">{getIntl().page.orderQuantity}: {lineItem.qtyReceivedPO}</div>
        </div>
        <div className="purchase-order-detail-line-item-info-line">
          <div className="purchase-order-detail-line-item-info-line-price">{formatCurrency(lineItem.cost, store.currencyCode)}</div>
          <div className="purchase-order-detail-line-item-info-line-total">{formatCurrency(lineItem.total, store.currencyCode)}</div>
        </div>
      </div>
    </div>
  );
}

export default NewReturnNoteItem;
