// import './DeviceInfoPage.less';
import { RouteComponentProps } from 'react-router-dom';
import withRoute from '@/pages/withRoute';
import DeviceInfoPage from './DeviceInfoPage';

export const prefix = 'bm-p-device-info';

const RouteComponent: React.FC<RouteComponentProps> = props => withRoute({
  pageComponent: DeviceInfoPage,
  routeProps: props,
  pageTitleI18nKey: '',
});

export default RouteComponent;
