import styled from 'styled-components';
export const StoreGroupPageStyled = styled.div`
.group-grid {
  .branch-card {
    overflow: hidden;
    width: 47%;
    height: 266px;
    margin-right: 0px;
    margin-bottom: 30px;
    margin-left: 0px;
    padding-bottom: 15px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    border-radius: 6px;
    background-color: var(--bm-light-background-color);
    color: var(--bm-text-title-color);
    text-decoration: none;
    cursor: pointer;
  }
  .heading-4 {
    font-family: 'Sf pro display', sans-serif;
    font-weight: 600;
  }
  .div-block-99 {
    height: 23%;
    min-height: 23%;
    padding: 0px 15px;
  }
  .image-card-upper,
  .lazyload-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    width: auto;
    height: 89%;
    margin-bottom: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    border-radius: 0px;
    background-position: 50% 50%;
    background-size: cover;
  }
  .lazyload-wrapper {
    .image-card-upper {
      height: 100%;
      width: 100%;
    }
  }
  @media screen and (max-width: 991px) {
    .branch-card {
      width: 47%;
      margin-bottom: 30px;
    }
    .heading-4 {
      margin-bottom: 5px;
    }
  }

  @media screen and (max-width: 767px) {
    .group-grid-item {
      width: 100%;
    }
    .branch-card {
      width: 100%;
      margin-bottom: 15px;
    }
    h4 {
      font-family: 'Sf pro display', sans-serif;
      font-weight: 600;
    }
    .image-card-upper,
    .lazyload-wrapper  {
      border-radius: 0px;
    }
    .lazyload-wrapper {
      .image-card-upper {
        height: 100%;
      }
    }
  }
  @media screen and (max-width: 479px) {
    .branch-card {
      width: 100%;
      height: 200px;
      margin-right: auto;
      margin-bottom: 15px;
    }
    .div-block-99 {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      height: 23%;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
    .heading-4 {
      margin-top: 5px;
      margin-bottom: 0px;
      font-size: 16px;
      line-height: 22px;
    }
  }
}

`;
