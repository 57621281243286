import produce from 'immer';
import { handleActions } from 'redux-actions';
import ActionType from '@/actions/action-type';
import {
  IInvoiceInfo,
  ISetQRSInvoiceInfo,
  ISetQRSOrderInfos,
  IStartQRSInvoiceTask,
} from '@/actions/qrcord-shopping-action';
import { IOrderInfo } from '@/lib/order-builder/models/OrderEntity';

export interface IQrcordShoppingState {
  storeSlug: string;
  orderNumber: string;
  authCode: string;
  invoiceInfo: IInvoiceInfo;
  orderInfos: {
    submittedOrderInfo?: IOrderInfo;
    pendingOrderInfo?: IOrderInfo;
  };
}

export const initInvoiceInfo = (): IInvoiceInfo => ({
  numberOfPeople: 0,
  orderNumber: '',
  orderReferenceNumber: '',
  seatedAt: '',
  tableName: '',
  tableSplit: '',
  state: 'unknow',
  status: '',
  tableID: '',
  turnTime: 0,
  currencyCode: '',
  pendingUpdatedAt: '',
  submittedUpdatedAt: '',
})

const initState: IQrcordShoppingState = {
  storeSlug: '',
  orderNumber: '',
  authCode: '',
  invoiceInfo: initInvoiceInfo(),
  orderInfos: {},
};

const actions: any = {};

actions[ActionType.START_QRCODE_SHOPPING_INVOICE_TASK_SYNC] = (state: IQrcordShoppingState, action: IStartQRSInvoiceTask) => produce(state, draft => {
  draft.storeSlug = action.storeSlug;
  draft.orderNumber = action.orderNumber;
  draft.authCode = action.authCode;
});

actions[ActionType.SET_QRCODE_SHOPPING_ORDER_INFOS] = (state: IQrcordShoppingState, action: ISetQRSOrderInfos) => produce(state, draft => {
  if (action.pendingOrderInfo) {
    draft.orderInfos.pendingOrderInfo = action.pendingOrderInfo;
  }
  if (action.submittedOrderInfo) {
    draft.orderInfos.submittedOrderInfo = action.submittedOrderInfo;
  }
  if (action.submittedOrderInfo) {
    draft.orderInfos.submittedOrderInfo = action.submittedOrderInfo;
  }
});

actions[ActionType.SET_QRCODE_SHOPPING_INVOICE_INFO] = (state: IQrcordShoppingState, action: ISetQRSInvoiceInfo) => produce(state, draft => {
  draft.invoiceInfo = action.invoiceInfo;
});

export default handleActions(actions, initState);
