import './ProcurementMethod.less';
import { PickerData } from 'antd-mobile/es/picker/PropsType';
import OrderEntity, { IOrderInfo, ISetShippingMethodParams } from '@/lib/order-builder/models/OrderEntity';
import { IStore, IStoreConfig, IStoreTable } from '@/actions/store-action';
import { IParseOpeningHoursResult } from '@/utils/app';
import IconType from '../Icon/IconType';
import ShippingMethod from '@/lib/order-builder/enums/ShippingMethod';
import * as H from 'history';

export const prefix = 'bm-c-procurement-method';

export { default } from './ProcurementMethod';

export interface IPickerOptionData extends PickerData {
  day: number;
}

export interface IProcurementMethodProps {
  orderInfo?: IOrderInfo;
  store?: IStore;
  storeConfig?: IStoreConfig;
  id?: string;
  className?: string;
  storeTables?: IStoreTable[];
  parseOpeningHoursResult?: IParseOpeningHoursResult;
  icon?: IconType;
  defaultShowProcurementMethod?: boolean;
  currentShippingMethod: ShippingMethod;
  onChange?: (data: ISetShippingMethodParams, store?: IStore) => void;
  onPopupShow?: (show: boolean) => void;
  disableChangeMethod?: boolean;
  orderData?: OrderEntity;
  hideChangeMethod?: boolean;
  showChange?: boolean;
  history: H.History<H.History.PoorMansUnknown>;
}

export const initProcurementMethodInstance = (): IProcurementMethodInstance => ({
  showPopup: () => {},
});

export interface IProcurementMethodInstance {
  showPopup: (show: boolean) => void;
}

export const useProcurementMethod = (): [IProcurementMethodInstance] => {
  const instance: IProcurementMethodInstance = initProcurementMethodInstance();
  return [instance];
}
