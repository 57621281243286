import React from 'react';
import GoogleMapReact, { MapOptions } from 'google-map-react';
import Toast from 'antd-mobile/es/toast';
import Modal from 'antd-mobile/es/modal';
import GoogleMapConfig from '../GoogleMapConfig';
import Icon, { IconType } from '@/components/Icon';
import ActionType from '@/actions/action-type';
import { IStore } from '@/actions/store-action';
import { IReducersState } from '@/reducers';
import { IDispatch } from '@/actions';
import GoogleMapMarker from '../common/GoogleMapMarker';
import StoreMapList from '@/components/StoreList';
import StoreMapDetails from './StoreMapDetails';
import StoreGoogleMapRoute from './StoreGoogleMapRoute';
import { parseOpeningHours, IParseOpeningHoursResult, getCoordinates } from '@/utils/app';
import { copyToClipboard, createRouteUrl } from '@/utils'
import paths from '@/routes/paths';
import { cancelFavorite } from '@/data/StoreSetting';
import { storeprefix } from '..';
import './storemap.less';
import { getIntl } from '@/components/App/App';

export enum MapTypes {
  APPLEMAP = 'APPLEMAP',
  GOOGLEMAP = 'GOOGLEMAP',
  GAODEMAP = 'GAODEMAP',
  CURRENTMAP = 'CURRENTMAP'
}

export interface IStoreGoogleMapProps {
  reducersState: IReducersState;
  dispatch: IDispatch;
}

interface IStoreGoogleMapState {
  mapInstance: any;
  mapApi: any;
  zoom: number;
  userLat: number;
  userLng: number;
  currentCenterLat: number | string;
  currentCenterLng: number | string;
  nearStores: IStore[];
  loadingStores: boolean;
  selectedStoreID: string;
  isFavorite: boolean;
  favoriteID: string;
  directionsService: any;
  directionsRender: any;
  showRoute: boolean;
  startMarker: any;
}

class StoreGoogleMap extends React.Component<IStoreGoogleMapProps, IStoreGoogleMapState> {

  disabled = false;

  constructor (props: IStoreGoogleMapProps) {
    super(props);

    this.state = {
      mapInstance: null,
      mapApi: null,
      zoom: 15,
      userLat:  22.319,
      userLng: 114.169,
      currentCenterLat: '',
      currentCenterLng: '',
      nearStores: [],
      loadingStores: false,
      selectedStoreID: '',
      isFavorite: false,
      favoriteID: '',
      directionsService: null,
      directionsRender: null,
      showRoute: false,
      startMarker: null,
    }
  }

  mapOptions: MapOptions = {
    fullscreenControl: false,
    zoomControl: false,
    scaleControl: false,
    rotateControl: false,
  }

  componentDidMount () {
    this.addPositionMarker();
    this.searchAdvancePlaceStores();
  }

  handleApiLoaded = (map: any, googlemaps: any) => {

    if (!map || !googlemaps) return;

    const directionsService = new googlemaps.DirectionsService();
    const directionsRender = new googlemaps.DirectionsRenderer({
      polylineOptions: {
        strokeColor: '#9DC5FF',
        strokeWeight: 6
      },
      map,
      suppressMarkers: true,
    });

    this.setState({
      mapInstance: map,
      mapApi: googlemaps,
      directionsService,
      directionsRender,
    });
  }

  // 定位按钮
  addPositionMarker = async () => {
    const { mapInstance } = this.state;
    const coords = await getCoordinates();
    if (coords && mapInstance) {
      const { latitude, longitude } = coords;
      this.setState({
        userLat: latitude,
        userLng: longitude,
        currentCenterLat: latitude,
        currentCenterLng: longitude,
        zoom: 15,
      }, mapInstance.setCenter({
        lat: latitude,
        lng: longitude,
      }))
    }
  }

  // 获取当前位置附近店铺列表
  searchAdvancePlaceStores = async () => {

    const {
      dispatch,
    } = this.props;

    const {
      userLat,
      userLng,
      currentCenterLat,
      currentCenterLng,
    } = this.state;

    dispatch({
      type: ActionType.SEARCH_STORES_LOCATION,
      lat: currentCenterLat || userLat,
      lng: currentCenterLng || userLng,
      callback: (stores: any) => {
        this.setState({
          nearStores: stores,
          loadingStores: false,
        });
      }
    });
  }

  handleStoreMarkerClick = (store: IStore) => {
    const { selectedStoreID } = this.state;
    let newKey = '';
    if (selectedStoreID !== store.id) {
      newKey = store.id;
    }

    this.setState({
      selectedStoreID: newKey,
    });

    if (newKey) {
      this.getFavoriteStatus(store.slug);
    }
  }

  getStoreMakerClass = (store: IStore) => {
    const { openingHours } = store;
    let parseOpeningHoursResult: IParseOpeningHoursResult | undefined = undefined;
    if (openingHours) {
      parseOpeningHoursResult = parseOpeningHours(openingHours);
    }
    if(parseOpeningHoursResult && parseOpeningHoursResult.isOpen) {
      return `${storeprefix}-marker`;
    }
    return `${storeprefix}-marker-closed`
  }

  getGoogleMapClass = () => {
    const {
      showRoute,
      selectedStoreID,
    } = this.state;

    if (showRoute) {
      return `${storeprefix}-map height100`;
    }

    if (selectedStoreID) {
      return `${storeprefix}-map height70`;
    }

    return `${storeprefix}-map`;
  }

  getSelectedStore = () => {
    const {
      nearStores,
      selectedStoreID,
    } = this.state;

    if (!selectedStoreID) return;
    for (let i = 0 ; i < nearStores.length; i++) {
      const storeItem = nearStores[i];
      if (selectedStoreID === storeItem.id) return storeItem;
    }
  }

  getFavoriteStatus = async (storeSlug: string) => {
    const { reducersState, dispatch } = this.props;
    const { platformAppSettings } = reducersState.app;
    const { userInfo } = reducersState.user;
    if (platformAppSettings && userInfo) {
      dispatch({
        type: ActionType.QUERY_FAVORITE_STATUS,
        storeSlug,
        platformID: platformAppSettings.id,
        userID: userInfo.id,
        callback: id => {
          if (id) {
            this.setState({
              isFavorite: true,
              favoriteID: id,
            });
          } else {
            this.setState({
              isFavorite: false,
            })
          }
        }
      });
    }
  }

  handleShare = () => {
    const selectedStore = this.getSelectedStore();

    if (!selectedStore) return;

    const returnUrl = window.location.origin + createRouteUrl(
      paths.MENU_LIST,
      {
        storeSlug: selectedStore.slug,
      },
    );

    copyToClipboard({
      txt: returnUrl,
      success: () => Toast.info('Copy successful'),
      failure: () => Toast.fail('Copy failed'),
    });
  }

  handleAddFavorite = async() => {
    const { dispatch, reducersState } = this.props;
    const { platformAppSettings } = reducersState.app;
    const { userInfo } = reducersState.user;

    const selectedStore = this.getSelectedStore();

    if (!selectedStore) return;

    if (!userInfo) {
      Modal.alert(getIntl().common.tips, getIntl().page.signInTips, [
        {
          text: getIntl().common.confirm,
          onPress: () => {},
        }
      ]);
      return;
    }

    if (!this.disabled) {
      this.disabled = true;
      if (this.state.isFavorite) {
        const res = await cancelFavorite(this.state.favoriteID);
        if (res) {
          this.setState({
            isFavorite: false,
          })
          this.disabled = false;
        }
      } else {
        if (platformAppSettings && userInfo) {
          dispatch({
            type: ActionType.ADD_FAVORITE,
            storeSlug: selectedStore.slug,
            platformID: platformAppSettings.id,
            userID: userInfo.id,
            callback: id => {
              if (id) {
                this.setState({
                  isFavorite: true,
                  favoriteID: id,
                });
              }
              this.disabled = false;
            }
          });
        }
      }
    }
  }

  handleRoute = (storeData: IStore) => {
    this.setState({
      showRoute: true,
    });
  }

  googleMapRoute = (storeData: IStore, lat?: number, lng?: number) => {
    const {
      userLat,
      userLng,
      mapApi,
      startMarker,
      mapInstance,
      directionsService,
      directionsRender,
    } = this.state;

    this.handleClearRoute();

    if (directionsService) {

      const routeData = {
        origin: {
          lat: lat || userLat,
          lng: lng || userLng,
        },
        destination: {
          lat: storeData.lat,
          lng: storeData.lng,
        },
        travelMode: 'WALKING',
      };

      if (startMarker === null) {
        const marker = new mapApi.Marker({
          position: routeData.origin,
          map: mapInstance,
          draggable: true,
          animation: mapApi.Animation.DROP,
        });
        this.setState({
          startMarker: marker,
        })
        mapApi.event.addListener(marker, 'dragend', (event: any) => {
          this.googleStartMarkerDragend(storeData, event);
        })
      }

      directionsService.route(routeData, (response: any, status: string) => {
        if (status === 'OK') {
          directionsRender.setMap(mapInstance);
          directionsRender.setDirections(response);
        } else {
          window.alert('Directions request failed due to ' + status);
        }
      });
    }
  }

  googleStartMarkerDragend = (storeData: IStore, event: any) => {
    this.googleMapRoute(storeData, event.latLng.lat(), event.latLng.lng());
  }

  handleClearRoute  = () => {
    const {
      directionsRender,
    } = this.state;

    directionsRender.setMap(null);
  }

  handleOpenOtherMap = (type: MapTypes) => {

    const selectedStore = this.getSelectedStore();
    if (!selectedStore) return;

    if (type === MapTypes.APPLEMAP) {
      const url = `maps://maps.google.com/maps?daddr=${selectedStore.lat},${selectedStore.lng}&amp;ll=`;
      window.open(url);
    }

    if (type === MapTypes.GOOGLEMAP) {
      const url = `https://maps.google.com/maps?daddr=${selectedStore.lat},${selectedStore.lng}&amp;ll=`;
      window.open(url);
    }

    if (type === MapTypes.GAODEMAP) {
      const url = `https://uri.amap.com/marker?position=${selectedStore.lng},${selectedStore.lat}&name=`;
      window.open(url);
    }

    if (type === MapTypes.CURRENTMAP) {
      this.googleMapRoute(selectedStore);
    }
  }

  handleCancelRoute = () => {
    this.setState({
      showRoute: false,
    });
    this.handleClearRoute();
    this.setState({
      startMarker: null,
    });
    this.setMapOnAll(null);
  }

  setMapOnAll = (map: any) => {
    const { startMarker } = this.state;
    if (startMarker) {
      startMarker.setMap(map);
    }
  }

  render () {
    const {
      userLng,
      userLat,
      zoom,
      nearStores,
      currentCenterLat,
      currentCenterLng,
      selectedStoreID,
      isFavorite,
      showRoute,
    } = this.state;

    const {
      reducersState,
    } = this.props;

    return (
      <>
        {
          <div
            className={`${storeprefix}-content`}
          >
            <div className={this.getGoogleMapClass()}>
              {
                <div
                  className={`${storeprefix}-show-location`}
                  onClick={this.addPositionMarker}
                >
                  <Icon
                    type={IconType.POSITION_OUTLINED}
                    className={`${storeprefix}-show-location-icon`}
                  />
                </div>
              }
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: GoogleMapConfig.key,
                  libraries: ['places, geometry'],
                }}
                center={{
                  lat: userLat,
                  lng: userLng
                }}
                defaultZoom={15}
                onGoogleApiLoaded={({map, maps}) => this.handleApiLoaded(map, maps)}
                yesIWantToUseGoogleMapApiInternals
                options={this.mapOptions}
                zoom={zoom}
                onZoomAnimationEnd={(zoom: number) => this.setState({ zoom })}
                onDrag={(map: any) => {
                  this.setState({
                    currentCenterLat: map.getCenter().lat(),
                    currentCenterLng: map.getCenter().lng(),
                  });
                }}
                onDragEnd={(map: any) => {
                  this.setState({
                    currentCenterLat: map.getCenter().lat(),
                    currentCenterLng: map.getCenter().lng(),
                  });
                }}
              >
                {
                  nearStores.length > 0 &&
                  nearStores.map(item => (
                    <GoogleMapMarker
                      key={item.id}
                      store={item}
                      lat={item.lat}
                      lng={item.lng}
                      iconType={IconType.MAP_OUTLINED}
                      className={this.getStoreMakerClass(item)}
                      onClick={this.handleStoreMarkerClick}
                      selectedStoreID={selectedStoreID}
                    />
                  ))
                }
                {
                  <GoogleMapMarker
                    lat={currentCenterLat || userLat}
                    lng={currentCenterLng || userLng}
                    className={`${storeprefix}-marker`}
                    iconType={IconType.GOOGLE_LOCALTION_OUTLINED}
                  />
                }
              </GoogleMapReact>
              {
                !showRoute &&
                <div className={`${storeprefix}-search-here`} onClick={this.searchAdvancePlaceStores}>
                  {getIntl().common.searchHere}
                </div>
              }
            </div>
            {
              !showRoute &&
              <div className={`${storeprefix}-space`}>
                <div className={`${storeprefix}-space-shape`}></div>
              </div>
            }
            {
              !selectedStoreID && !showRoute &&
              <StoreMapList
                data={nearStores}
                reducersState={reducersState}
                itemStyle="LIST_ICON_LEFT"
              />
            }
            {
              selectedStoreID && !showRoute &&
              <StoreMapDetails
                reducersState={reducersState}
                selectedStoreID={selectedStoreID}
                storeData={this.getSelectedStore()}
                handleShare={this.handleShare}
                handleAddFavorite={this.handleAddFavorite}
                isFavorite={isFavorite}
                handleRoute={this.handleRoute}
              />
            }
            {
              showRoute &&
              <StoreGoogleMapRoute
                handleOpenOtherMap={this.handleOpenOtherMap}
                onCancel={this.handleCancelRoute}
              />
            }
          </div>
        }
      </>
    );
  }
}

export default StoreGoogleMap;

