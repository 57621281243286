import React from 'react';
import InputItem from 'antd-mobile/es/input-item';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import Icon, { IconType } from '@/components/Icon';
import { getAppTheme, setScreenColor, getIntl, getIntlByPath } from '@/components/App/App';
import { createRouteUrl, parseRouteParams } from '@/utils';
import { goToRoute } from '@/utils/route-util';
import { IStore, IPlatformStore } from '@/actions/store-action';
import ActionType from '@/actions/action-type';
import { Popup } from '@/components/Modal';
import Layout from '@/components/Layout';
import paths from '@/routes/paths';
import Config from '@/Config';
import { prefix } from '.';

export interface IStoreAssociationPageProps extends IBasePageProps {}
export interface IStoreAssociationPageState {
  store: IStore | undefined;
  status: string;
  environment: string;
  showStatus: boolean;
  showEnvironment: boolean;
  statusList: Ioption;
  environmentList: Ioption;
  platformStore: IPlatformStore | undefined;
}

interface Ioption {
  [value: string]: string;
}

class StoreAssociationPage extends BasePage<IStoreAssociationPageProps, IStoreAssociationPageState> {
  state: IStoreAssociationPageState = {
    store: undefined,
    status: '',
    environment: '',
    showStatus: false,
    showEnvironment: false,
    statusList: {},
    environmentList: {},
    platformStore: undefined,
  }

  environmentStgList = [
    {value: 'stg', label: 'STG'},
    {value: 'all', label: 'All'},
  ]
  componentDidMount () {
    const appTheme = getAppTheme();
    if (appTheme.lightBackgroundColor) {
      setScreenColor(appTheme.lightBackgroundColor);
    }
    super.componentDidMount();
    const routeParams = parseRouteParams(this.props);
    const { dispatch, reducersState, history } = this.props;
    const { platformConfig } = reducersState.app;
    const { userInfo } = reducersState.user;
    if (userInfo && userInfo.staff) {
      dispatch({
        type: ActionType.LOADING,
        showLoading: true,
      })
      dispatch({
        type: ActionType.QUERY_STORE,
        storeSlug: routeParams.storeSlug,
        callback: store => {
          if (store && platformConfig) {
            dispatch({
              type: ActionType.QUERY_PLATFORM_STORE_ASSOCIATIONS,
              storeID: store.id,
              platformID: platformConfig.platformID,
              callback: record => {
                let environmentList: Ioption = {}
                if (Config.isStaging) {
                  environmentList = {
                    'stg': 'STG',
                    'all': 'All',
                  }
                } else {
                  environmentList = {
                    'alpha': 'Alpha',
                    'prod': 'Prod',
                  }
                }
                const statusList = {
                  '0': 'valid',
                  '1': 'invalid',
                  '2': 'approval',
                }
                this.setState({
                  store,
                  statusList,
                  environmentList,
                  platformStore: record,
                  status: record ? record.status : '',
                  environment: record ? record.environment : '',
                })
                dispatch({
                  type: ActionType.LOADING,
                  showLoading: false,
                })
              }
            })
          }
        }
      })
    } else {
      goToRoute({
        history,
        pathname: createRouteUrl(
          paths.ACCOUNT,
        ),
      });
    }
  }

  handleLeftClick = () => {
    const {
      history,
    } = this.props;
    const routeParams = parseRouteParams(this.props);
    goToRoute({
      history,
      pathname: createRouteUrl(
        paths.ADMIN_STORE_EDIT,
        {
          storeSlug: routeParams.storeSlug,
        }
      ),
    });
  }

  handleSuccess = (record?: IPlatformStore) => {
    const { dispatch } = this.props;
    if (record) {
      this.handleLeftClick();
    }
    dispatch({
      type: ActionType.LOADING,
      showLoading: false,
    })
  }

  handleSave = () => {
    const {
      store,
      status,
      environment,
      platformStore,
    } = this.state;

    const { reducersState, dispatch } = this.props;
    const { platformConfig } = reducersState.app;
    if (store && platformConfig && status && environment) {
      dispatch({
        type: ActionType.LOADING,
        showLoading: true,
      })
      if (platformStore) {
        dispatch({
          type: ActionType.UPDATE_PLATFORM_STORE_ASSOCIATIONS,
          id: platformStore.id,
          status,
          environment,
          callback: record => this.handleSuccess(record)
        })
      } else {
        dispatch({
          type: ActionType.ADD_PLATFORM_STORE_ASSOCIATIONS,
          platformID: platformConfig.platformID,
          storeID: store.id,
          status: status,
          environment: environment,
          callback: record => this.handleSuccess(record)
        })
      }
    }
  }

  render() {
    const {
      reducersState,
    } = this.props;
    const {
      store,
      status,
      environment,
      showEnvironment,
      showStatus,
      statusList,
      environmentList,
    } = this.state;

    const { platformConfig } = reducersState.app;
    
    return(
      <Layout
        {...this.props}
        className={prefix}
        navIcon={<Icon className="back-icon" type={IconType.LEFT_OUTLINED} />}
        navContent={getIntl().page.storeAssociad}
        onLeftClick={this.handleLeftClick}
        isFooter={false}
      >
        {
          store && platformConfig &&
          <div>
            <div className="input-line">
              <div className="title">{getIntl().page.storeName}</div>
              <div className="store-name-input">
                <InputItem
                  value={store.name}
                  disabled={true}
                />
              </div>
            </div>
            <div className="input-line">
              <div className="title">{getIntl().page.storeID}</div>
              <div className="store-id-input">
                <InputItem
                  value={store.id}
                  disabled={true}
                />
              </div>
            </div>
            <div className="input-line">
              <div className="title">{getIntl().page.platformName}</div>
              <div className="store-id-input">
                <InputItem
                  value={platformConfig.platformName}
                  disabled={true}
                />
              </div>
            </div>
            <div className="input-line">
              <div className="title">{getIntl().page.status}</div>
              <div className="shop-type-input" onClick={() => this.setState({showStatus: true})}>
                <div className="shop-type">
                  {
                    status && statusList[status] &&
                    getIntlByPath(`page.${statusList[status]}`)
                  }
                </div>
                <Icon
                  type={IconType.RIGHT_OUTLINED}
                  className="right-icon"
                />
              </div>
            </div>
            <div className="input-line">
              <div className="title">{getIntl().page.environment}</div>
              <div className="shop-type-input" onClick={() => this.setState({showEnvironment: true})}>
                <div className="shop-type">
                  {environmentList[environment]}
                </div>
                <Icon
                  type={IconType.RIGHT_OUTLINED}
                  className="right-icon"
                />
              </div>
            </div>
            <div className="bottom-botton">
              <div className={`save-botton ${status && environment ? '' : 'disabled'}`} onClick={() => this.handleSave()}>
                {getIntl().common.save}
              </div>
            </div>
          </div>
        }
        <Popup
          visible={showStatus}
          title={getIntl().page.status}
          onClose={() => this.setState({showStatus: false})}
          className={prefix}
        >
          <div className="shop-type-list">
            {
              Object.keys(statusList).map(item => (
                <div className="shop-type-wrap" key={item} onClick={() => this.setState({status: item})}>
                  <div className="shop-type">
                    {getIntlByPath(`page.${statusList[item]}`)}
                  </div>
                  {
                    item === status &&
                    <Icon
                      type={IconType.CHECKED_OUTLINED}
                      className="checked"
                    />
                  }
                </div>
              ))
            }
          </div>
        </Popup>
        <Popup
          visible={showEnvironment}
          title={getIntl().page.environment}
          onClose={() => this.setState({showEnvironment: false})}
          className={prefix}
        >
          <div className="shop-type-list">
            {
              Object.keys(environmentList).map(item => (
                <div className="shop-type-wrap" key={item} onClick={() => this.setState({environment: item})}>
                  <div className="shop-type">
                    {environmentList[item]}
                  </div>
                  {
                    item === environment &&
                    <Icon
                      type={IconType.CHECKED_OUTLINED}
                      className="checked"
                    />
                  }
                </div>
              ))
            }
          </div>
        </Popup>
      </Layout>
    )
  }
}

export default StoreAssociationPage;
