import { RouteComponentProps } from 'react-router-dom';
import withRoute from '@/pages/withRoute';
import SelectStoresPage from './SelectStoresPage';
// import './SelectStoresPage.less';

export const prefix = 'bm-p-select-stores';

const RouteComponent: React.FC<RouteComponentProps> = props => withRoute({
  pageComponent: SelectStoresPage,
  routeProps: props,
  pageTitleI18nKey: '',
});
export default RouteComponent;
