import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import Layout from '@/components/Layout/Layout';
import paths from '@/routes/paths';
import SearchTittle from './SearchNav';
import SearchContent from '@/components/SearchContent';
import SearchResult from '@/components/SearchContent/SearchResult'
import Constants from '@/constants';
import { prefix } from '.';
import { IObject } from '@/components/bm-common';
import { IStore } from '@/actions/store-action';
import ActionType from '@/actions/action-type';
import { getAppTheme, setScreenColor } from '@/components/App/App';
import { setCanAutoProcurementMethodPopup } from '../MenuListPage/MenuListPage';
import { SearchPageStyled } from './styled';


export interface ISearchPageProps extends IBasePageProps {}

interface ISearchPageState {
  searchKey: string;
  historyData: string[];
  searchStores: IStore[];
  loadingStores: boolean;
  showSearchResult: boolean;
}

class SearchPage extends BasePage<ISearchPageProps, ISearchPageState> {

  state: ISearchPageState = {
    searchKey: '',
    historyData: [],
    searchStores: [],
    loadingStores: false,
    showSearchResult: false,
  }

  componentDidMount () {
    const appTheme = getAppTheme();
    if (appTheme.darkBackgroundColor) {
      setScreenColor(appTheme.darkBackgroundColor);
    }
    super.componentDidMount();

    this.setState({
      historyData: this.getCurrentHistoryData(),
    })
  }

  handleSearch = (value: string) => {
    const searchHistoryList = this.getCurrentHistoryData();
    searchHistoryList.unshift(value);
    const newSearchHistoryList = Array.from(new Set(searchHistoryList));
    this.runSearch(value);
    this.setCurrentHistoryData(newSearchHistoryList);
    this.setState({
      searchKey: value,
      historyData: newSearchHistoryList,
    });
  }

  getCurrentHistoryData = () => {
    let searchHistoryList: string[] = [];
    let userInfoString = localStorage.getItem(Constants.USER_INFO);
    const platformID = localStorage.getItem(Constants.PLATFORM_ID);

    if (!userInfoString) {
      userInfoString = '{"id": "BINDO_GUEST_USER"}';
    }
    if (userInfoString) {
      const userInfo = JSON.parse(userInfoString);
      if (userInfo && userInfo.id) {
        const searchHistoryString = localStorage.getItem(Constants.SEARCH_HISTORY);
        if (searchHistoryString && platformID) {
          const searchHistoryData: IObject = JSON.parse(searchHistoryString);
          const platfromHistoryData: IObject = searchHistoryData[String(platformID)];
          if (platfromHistoryData && Array.isArray(platfromHistoryData[String(userInfo.id)])) {
            searchHistoryList = platfromHistoryData[String(userInfo.id)];
          }
        }
      }
    }
    return searchHistoryList;
  }

  setCurrentHistoryData = (values: string[]) => {
    let userInfoString = localStorage.getItem(Constants.USER_INFO);
    let searchHistoryData: IObject = {};
    const platformID = localStorage.getItem(Constants.PLATFORM_ID);

    if (!userInfoString) {
      userInfoString = '{"id": "BINDO_GUEST_USER"}';
    }
    if (userInfoString && platformID) {
      const userInfo = JSON.parse(userInfoString);
      if (userInfo.id) {
        const searchHistoryString = localStorage.getItem(Constants.SEARCH_HISTORY);
        if (searchHistoryString) {
          const tempSearchHistoryData: IObject = JSON.parse(searchHistoryString);
          if (!tempSearchHistoryData[String(platformID)]) {
            searchHistoryData = {};
          } else {
            searchHistoryData = tempSearchHistoryData;
          }
        }

        searchHistoryData = {
          ...searchHistoryData,
          [platformID]: {
            ...searchHistoryData[String(platformID)],
            [userInfo.id]: values,
          },
        };
        const newSearchHistoryString = JSON.stringify(searchHistoryData);
        localStorage.setItem(Constants.SEARCH_HISTORY, newSearchHistoryString);
      }
    }
  }

  runSearch = async (searchKey: string) => {
    const { dispatch } = this.props;
    this.setState({
      loadingStores: true,
      showSearchResult: true,
    });
    dispatch({
      type: ActionType.SEARCH_STORES,
      searchKey,
      callback: (stores: any) => {
        this.setState({
          searchStores: stores,
          loadingStores: false,
        });
      }
    });
  }

  handleItemClick = (data: IStore) => {
    setCanAutoProcurementMethodPopup(true);
    this.handleGotoRoute({
      path: paths.MENU_LIST,
      pathParams: {
        storeSlug: data.slug,
      },
    });
  }

  onHistoryItemClick = (searchKey: string) => {
    this.setState({
      searchKey,
    })
    this.runSearch(searchKey);
  }

  onCancle = () => {
    this.handleGoBack({
      path: paths.HOME,
      runGoBack: true,
    });
  }

  handleClearHistory = () => {
    this.setCurrentHistoryData([]);
  }


  render () {

    const { reducersState } = this.props;

    const { searchKey, historyData, searchStores, loadingStores, showSearchResult } = this.state;

    return (
      <Layout
        {...this.props}
        navBar={
          <SearchTittle
            searchValue={searchKey}
            onSearch={this.handleSearch}
            onCancel={this.onCancle}
            onClosed={() => this.setState({
              showSearchResult: false,
            })}
          />
        }
        className={prefix}
      >
        <SearchPageStyled>
          <div className="section _1 wf-section">
            <div className="w-container">
              {
                !showSearchResult && historyData.length > 0 && !loadingStores &&
                <SearchContent
                  historyData={historyData}
                  onHistoryItemClick={this.onHistoryItemClick}
                  onClear={this.handleClearHistory}
                />
              }
              {
                showSearchResult && historyData.length > 0 &&
                <SearchResult
                  data={searchStores}
                  reducersState={reducersState}
                  loading={loadingStores}
                  onItemClick={this.handleItemClick}
                />
              }
            </div>
          </div>
        </SearchPageStyled>
      </Layout>
    );
  }
}

export default SearchPage;
