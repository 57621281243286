import { IRoute } from '@/routes';
import paths from '@/routes/paths';
import OrdersPage from './OrdersPage';
import OrderDetail from './OrderDetail';

export const mobileRoutes: IRoute[] = [
  {
    path: paths.ORDERS,
    component: OrdersPage,
  },
  {
    path: paths.ORDER_DETAIL,
    component: OrderDetail,
  },
];

export default mobileRoutes;
