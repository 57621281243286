import React from 'react';
import AntdIcon from 'antd-mobile/es/icon';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import Icon, { IconType } from '@/components/Icon';
import { getAppTheme, setScreenColor, getIntl } from '@/components/App/App';
import Layout from '@/components/Layout';
import { goToRoute } from '@/utils/route-util';
import { createRouteUrl } from '@/utils';
import paths from '@/routes/paths';
import ActionType from '@/actions/action-type';
import { prefix, navPrefix } from '.';
import StoreItem from './StoreItem';
import SearchNav from './SearchNav';

export interface IMyStoresPageProps extends IBasePageProps {}
export interface IMyStoresPageState {
  storeIDs: string[];
  searchStoreIDs: string[];
  page: number;
  isSearchNav: boolean;
  search: boolean;
  loading: boolean;
}

class MyStoresPage extends BasePage<IMyStoresPageProps, IMyStoresPageState> {
  lastPage = false;
  loadingData = false;
  state: IMyStoresPageState = {
    storeIDs: [],
    page: 1,
    isSearchNav: false,
    loading: false,
    search: false,
    searchStoreIDs: [],
  }

  componentDidMount () {
    const appTheme = getAppTheme();
    if (appTheme.lightBackgroundColor && appTheme.darkBackgroundColor) {
      setScreenColor(appTheme.lightBackgroundColor, appTheme.darkBackgroundColor);
    }
    super.componentDidMount();
    const { reducersState, history } = this.props;
    const { userInfo } = reducersState.user;
    if (userInfo && userInfo.staff) {
      this.handleGetStoresByUser(1, 10);
    } else {
      goToRoute({
        history,
        pathname: createRouteUrl(
          paths.ACCOUNT,
        ),
      });
    }

    const pageWrap = window.document.querySelector('.page-content-wrapper-web');
    if (pageWrap) {
      pageWrap.addEventListener('scroll', this.handleScroll, { passive: true });
    } else {
      window.addEventListener('scroll', this.handleScroll, { passive: true });
    }
  }

  componentWillUnmount() {
    const pageWrap = window.document.querySelector('.page-content-wrapper-web');
    if (pageWrap) {
      pageWrap.removeEventListener('scroll', this.handleScroll);
    } else {
      window.removeEventListener('scroll', this.handleScroll);
    }
  }

  getScrollY = () => {
    const pageWrap = window.document.querySelector('.page-content-wrapper-web');
    let scrollY = window.scrollY;
    if (pageWrap) {
      scrollY = pageWrap.scrollTop;
    }

    return scrollY;
  }

  handleScroll = () => {
    const { page } = this.state;
    if (!this.lastPage && !this.loadingData) {
      const appPages = document.getElementsByClassName('bm-p-my-stores');
      if (appPages && appPages.length) {
        const appPage = appPages[0];
        const scrollHeight = appPage.scrollHeight;
        const canScrollHeight = scrollHeight - window.innerHeight;
        if (canScrollHeight - this.getScrollY() < 1250) {
          this.loadingData = true
          this.setState({loading: true});
          this.handleGetStoresByUser(page + 1, 10, true);
        }
      }
    }
  }

  handleGetStoresByUser = (page: number, perPage: number, isAdd = false, storeName?: string, isSearch = false) => {
    const {
      reducersState,
      dispatch,
    } = this.props;

    const { userInfo } = reducersState.user;
    if (userInfo) {
      if (!isAdd) {
        dispatch({
          type: ActionType.LOADING,
          showLoading: true,
        })
      }
      dispatch({
        type: ActionType.QUERY_STORES_BY_USER,
        userID: userInfo.id,
        storeName,
        page,
        perPage,
        callback: newstoreIDs => {
          let { storeIDs } = this.state;
          if (isAdd) {
            newstoreIDs.forEach(item => {
              if (!storeIDs.includes(item)) {
                storeIDs.push(item);
              }
            })
          } else {
            storeIDs = newstoreIDs;
          }
          if (newstoreIDs.length < perPage) {
            this.lastPage = true;
          }
          this.loadingData = false;
          if (isSearch) {
            this.setState({
              searchStoreIDs: storeIDs,
            })
          } else {
            this.setState({
              storeIDs,
              page,
            })
          }
          dispatch({
            type: ActionType.LOADING,
            showLoading: false,
          })
        }
      })
    }
  }

  // handleCreateStore = () => {
  //   const {
  //     history,
  //   } = this.props;
  //   goToRoute({
  //     history,
  //     pathname: createRouteUrl(
  //       paths.ADMIN_STORE_NEW,
  //     ),
  //   });
  // };

  handleStoreEdit = (storeSlug: string) => {
    const {
      history,
    } = this.props;
    goToRoute({
      history,
      pathname: createRouteUrl(
        paths.ADMIN_STORE_EDIT,
        {
          storeSlug,
        }
      ),
    });
  }

  handleLeftClick = () => {
    const {
      history,
    } = this.props;
    goToRoute({
      history,
      pathname: createRouteUrl(
        paths.ACCOUNT,
      ),
    });
  }

  handleSearch = (value: string) => {
    this.loadingData = true
    this.setState({loading: true, search: true});
    this.handleGetStoresByUser(1, 50, false, value, true);
  }

  render() {
    const {
      dispatch,
    } = this.props;

    const {
      search,
      storeIDs,
      isSearchNav,
      searchStoreIDs,
    } = this.state;

    return(
      <Layout
        {...this.props}
        className={`${prefix} ${isSearchNav ? navPrefix: ''}`}
        navBar={
          <div>
            {
              isSearchNav &&
              <SearchNav
                onSearch={this.handleSearch}
                onCancel={() => this.setState({isSearchNav: false, searchStoreIDs: [], search: false})}
              />
            }
            {
              !isSearchNav &&
              <div className={`${prefix}-nav`}>
                <Icon className="back-icon" type={IconType.LEFT_OUTLINED} onClick={this.handleLeftClick} />
                <div className={`${prefix}-nav-title`}>{getIntl().page.stores}</div>
                <Icon className="right-icon-search" type={IconType.SEARCH_OUTLINED} onClick={() => this.setState({isSearchNav: true, searchStoreIDs: [], search: false})} />
              </div>
            }
          </div>
        }
        isFooter={false}
      >
        {
          isSearchNav && search &&
          <div className="result">
            <div className="result-rectangle"></div>
            <span className="result-data">{`${searchStoreIDs.length} ${getIntl().page.results}`}</span>
          </div>
        }
        <div className="store-list">
          {
            !isSearchNav &&
            storeIDs.map(item => {
              return <StoreItem key={item} storeID={item} dispatch={dispatch} onClick={this.handleStoreEdit} />
            })
          }
          {
            isSearchNav &&
            searchStoreIDs.map(item => {
              return <StoreItem key={item} storeID={item} dispatch={dispatch} onClick={this.handleStoreEdit} />
            })
          }
        </div>
        {
          this.loadingData && !isSearchNav &&
          <div className="loading-data">
            <AntdIcon type="loading" />
            <div className="loading-msg">
              {getIntl().common.loading}
            </div>
          </div>
        }
        {/* {
          !isSearchNav &&
          <div className="bottom-botton">
            <div className="create-store-botton" onClick={() => this.handleCreateStore()}>
              {getIntl().page.createStore}
            </div>
          </div>
        } */}
      </Layout>
    )
  }
}

export default MyStoresPage;
