import {
  ITimeSegment,
  ITable,
  ITimeSegmentsQuota,
  IBookingSetting,
  IBooking,
  INumberQuota,
} from '@/actions/booking-action';
import dayjs from 'dayjs';
import parseInt from 'lodash/parseInt';


export const parseResTimeSegments = (resTimeSegments: any) => {
  const timeSegments: ITimeSegment[] = []
  if (Array.isArray(resTimeSegments)) {
    resTimeSegments.forEach(item => {
      timeSegments.push({
        start: item.start || '',
        end: item.end || '',
        lastBookingTime: item.last_booking_time || 0,
        id: item.id || '',
        title: item.title || '',
        i18n: item.__i18n,
      })
    })
  }
  return timeSegments;
}

export const parseResTable = (resTables: any) => {
  const tables: ITable[] = []
  if (Array.isArray(resTables)) {
    resTables.forEach(item => {
      tables.push({
        max: item.maximum_party_size || 0,
        min: typeof item.minimum_party_size === 'string' ? Number(item.minimum_party_size) || 0 : item.minimum_party_size || 0,
        tableTypeID: item.id+'' || '',
        timeSegmentIDs: [],
      })
    })
  }
  return tables;
}

export const parseTimeSegmentsQuota = (resTimeSegmentsQuotas: any) => {
  const timeSegmentsQuotas: ITimeSegmentsQuota[] = []
  if (Array.isArray(resTimeSegmentsQuotas)) {
    resTimeSegmentsQuotas.forEach(item => {
      timeSegmentsQuotas.push({
        ...item,
        duration: typeof item.duration === 'string' ? parseInt(item.duration) : item.duration || 0,
        tableTypeID: item.table_type_id || '',
        timeSegmentID: item.time_segment_id || '',
        availableQuota: item.available_quota || 0,
      })
    })
  }
  return timeSegmentsQuotas;
}

export const parseNumberQuota = (resNumberQuota: any) => {
  const numberQuota: INumberQuota[] = [];
  if (Array.isArray(resNumberQuota)) {
    resNumberQuota.forEach(item => {
      numberQuota.push({
        ...item,
        timeSegmentID: item.time_segment_id,
      })
    })
  }
  return numberQuota;
}

export const parseBookingSetting = (resBookingSetting: any) => {
  if (resBookingSetting) {
    const timeSegmentsQuotas: IBookingSetting = {
      bookingDay: typeof resBookingSetting.available_booking_days === 'string' ? parseInt(resBookingSetting.available_booking_days) : resBookingSetting.available_booking_days || 0,
      minuteIntervalType: resBookingSetting.minute_interval_type || '',
      id: resBookingSetting.id || '',
      confirmationMode: resBookingSetting.confirmation_mode,
      minimumStartBookingTime: resBookingSetting.minimum_start_booking_time || '0',
      minimumStartBookingTimeType: resBookingSetting.minimum_start_booking_time_type || '0',
    }
    return timeSegmentsQuotas;
  } else {
    return undefined;
  }
}

export const parseResBooking = (record: any) => {
  const booking: IBooking = {
    bookingNo: record.id || '',
    reservationTime: record.reservation_time || '',
    people: record.people || '',
    status: record.status || '',
    id: record.id || '',
    storeID: record.store_id || '',
    storeName: record.store_name || '',
    customerName: record.customer_name || '',
    phone: record.phone || '',
    email: record.email || '',
    note: record.note || '',
    createAt: record.created_at || '',
    turnTime: typeof record.turn_time === 'string' ? parseInt(record.turn_time) : record.turn_time || 0,
  }
  return booking;
}

export const filterItems = (items: any[], dateTime: dayjs.Dayjs) => {
  const compareItems = (item1: any, item2: any) => {
    if (!item1.date_range && item2.date_range) {
      return item2;
    } else if (item1.date_range && item2.date_range) {
      if ((item1.priority > item2.priority) || (!item1.priority && item2.priority)) {
        return item2;
      }
    } else if (item1.date_range) {
      return item1;
    }

    if (!item1.days_of_week && item2.days_of_week) {
      return item2;
    } else if (item1.days_of_week && item2.days_of_week) {
      if ((item1.priority > item2.priority) || (!item1.priority && item2.priority)) {
        return item2;
      }
    } else if (item1.days_of_week) {
      return item1;
    }


    if ((item1.priority > item2.priority) || (!item1.priority && item2.priority)) {
      return item2;
    }

    return item1;
  }

  const itemsObjs: any = {};
  const pushItem =  (item: any, key: any) => {
    if (itemsObjs[key]) {
      itemsObjs[key] = compareItems(itemsObjs[key], item);
    } else {
      itemsObjs[key] = item;
    }
  }

  const dateStr = dateTime.format('YYYY-MM-DD');
  const day = dateTime.day().toString();

  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    const key = item.start_time + item.end_time + item.table_type_id;
    if (item.date_range && item.date_range.length === 2) {
      if (!dayjs(item.date_range[0]).isAfter(dayjs(dateStr), 'date') && !dayjs(dateStr).isAfter(dayjs(item.date_range[1]), 'date')) {
        pushItem(item, key);
      }
    } else if (item.days_of_week && item.days_of_week.length > 0) {
      if (item.days_of_week.indexOf(day) > -1) {
        pushItem(item, key);
      }
    } else {
      pushItem(item, key);
    }
  }

  const newItems: any[] = Object.values(itemsObjs);

  return newItems;
}
