import React from 'react';
import MenuGridItem from './MenuGridItem';
import { IFavoriteTab } from '@/lib/order-builder/utils/api';
import { ListingCountRef } from '@/actions/shopping-cart-action';

interface IMenuGridProps {
  data: IFavoriteTab[];
  listingCountRef: ListingCountRef;
  isOpen: boolean;
  currentDay: number;
  onItemClick?: (data: IFavoriteTab) => void;
}

const MenuGrid: React.FC<IMenuGridProps> = props => {
  const {
    data,
    onItemClick,
  } = props;

  return (
    <div className="div-block-629 grid">
      {
        data.map(item => (
          <MenuGridItem
            key={item.id}
            data={item}
            onClick={onItemClick}
          />
        ))
      }
    </div>
  );
}

export default MenuGrid;
