import React, { useState } from 'react';
import { prefix } from '.';
import OrderEntity from '@/lib/order-builder/models/OrderEntity';
import { IObject } from '@/components/bm-common';
import axios from 'axios';
import InputItem from 'antd-mobile/es/input-item';
import Button from 'antd-mobile/es/button';
import { IReducersState } from '@/reducers';
import Config from '@/Config';
import { getIntl } from '../App/App';

interface ISmartDiscountEngineProps {
  data: OrderEntity;
  storeSlug: string;
  reducersState: IReducersState;
  handlePromotionCode?: (storeID: string, discountScript: string[], promotionCode: string, promotionName: string, membershipLevelID: string, cancel?: boolean) => void;
}

interface IPriceStatistic {
  code: string;
  display_name: string;
  display_price: number;
  display_type: number;
}

const SmartDiscountEngine: React.FC<ISmartDiscountEngineProps> = props => {
  const {
    data,
    storeSlug,
    reducersState,
    handlePromotionCode,
  } = props;

  const { userInfo } = reducersState.user;
  const [value, setValue] = useState(data.smartDiscountInfo ? data.smartDiscountInfo.promotionCode : '');
  const [hasError, setHasError] = useState(false);
  const [isUsed, setIsUsed] = useState(data.smartDiscountInfo && data.smartDiscountInfo.promotionCode ? true : false);
  const [discountScript, setDiscountScript] = useState(data.smartDiscountInfo ? data.smartDiscountInfo.discountScript : ['']);
  const [promotionName, setPromotionName] = useState('');

  const handleChange = async (value: string) => {
    if (!value) {
      setValue('');
      setHasError(false)
      return;
    }
    setValue(value);
    try {
      const res: IObject = await axios({
        method: 'POST',
        url: Config.urls.getCoupons.replace('{storeID}', `${data.storeID}`),
        data: {
          'customer_id': userInfo ? Number(userInfo.id) : null,
          'code': value,
          'initial_total': 10
        },
      })

      if (res.discount) {
        let discountScript: string[] = [];
        const { custom_attributes: customAttributes = '', name = '', id} = res.discount;
        const parseRecord = JSON.parse(customAttributes);
        if (parseRecord && parseRecord.is_smart_discount === 1 && parseRecord.script) {
          let script: string = parseRecord.script;
          script = script.replace('#discount_id#', id);
          discountScript = [script];
        }
        setHasError(false);
        setDiscountScript(discountScript);
        setPromotionName(name);
      } else {
        setHasError(true)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const goCalculate = async () => {
    if (!handlePromotionCode) return;
    if (isUsed) {
      handlePromotionCode(data.storeID, discountScript, value, promotionName, '', true)
      setIsUsed(false)
    } else {
      try {
        let membershipLevelID = '';
        const result: IObject = await axios.get(`${Config.urls.customer.replace('{storeSlug}', storeSlug)}`);
  
        if (result && result.customer) {
          membershipLevelID = result.customer.membership_level_id;
        }
        if (handlePromotionCode) {
          handlePromotionCode(data.storeID, discountScript, value, promotionName, membershipLevelID)
        }
      } catch (error) {
        console.error(error)
      }
      setIsUsed(true)
    }
  }

  return (
    <div className={`${prefix}-discount`}>
      <InputItem
        disabled={isUsed}
        error={hasError}
        clear={true}
        value={value}
        onChange={handleChange}
        placeholder={getIntl().page.enterPromotionCode}
      />
      <Button
        disabled={hasError || !value}
        type={isUsed ? 'warning' : 'primary'}
        className="pay-btn bm-btn-md"
        onClick={() => goCalculate()}
      >
        {
          isUsed ? getIntl().page.signCancel : getIntl().page.useNow
        }
      </Button>
    </div>
  )
}

export default SmartDiscountEngine
