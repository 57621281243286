import axios from 'axios';
import Constants from '@/constants';
import Config from '@/Config';
import { getAccessToken } from '@/utils/app';

export const payInvoice = async (storeID: string, orderNumber: string, order: any) => {
  const stringUserInfo = localStorage.getItem(Constants.USER_INFO);
  let userInfo = null;
  if (stringUserInfo) {
    userInfo = JSON.parse(stringUserInfo);
  }
  const url = userInfo && userInfo.email ? `${Config.urls.payInvoice.replace('{storeID}', storeID).replace('{orderNumber}', orderNumber)}?auto_send_email_receipt=true&email_receipt_to=${userInfo.email}` : Config.urls.payInvoice.replace('{storeID}', storeID).replace('{orderNumber}', orderNumber);
  try {
    const resp = await axios({
      method: 'post',
      url,
      data: {
        ...order,
      }
    });

    return resp;

  } catch (error) {
    throw error
  }
}


export const checkOrderStatu = async (storeID: string, orderNumber: string) => {
  const tempurl = Config.urls.checkOrder.replace('{storeID}', storeID).replace('{orderNumber}', orderNumber);
  const date = new Date();
  try {
    const resp = await axios({
      method: 'get',
      url: `${tempurl}?time=${date.getTime()}`,
    });

    return resp;
  } catch (error) {
    throw error
  }
}


export const getApplePaySession = async (storeID: string, data: any) => {
  const token = getAccessToken();
  const tempurl = Config.urls.applePaySession.replace('{storeID}', storeID);

  if (!token) return;

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', tempurl);
    xhr.onload = function load() {
      if (this.status >= 200 && this.status < 300) {
        resolve(JSON.parse(xhr.response));
      } else {
        reject(
          {
            status: this.status,
            message: xhr.statusText,
          },
        );
      }
    };
    xhr.onerror = function error() {
      reject(
        {
          status: this.status,
          message: xhr.statusText,
        },
      );
    };
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('X-USER-ACCESS-TOKEN', token);
    xhr.send(JSON.stringify(data));
  })
}

export const transactionsVoid = async (storeID: string, orderNumber: string, ids: string[]) => {

  // const ids = [];
  // order.sale_transactions.forEach(item => {
  //   ids.push(item.id)
  // });

  const tempurl = Config.urls.voidTransactions.replace('{storeID}', storeID).replace('{orderNumber}', orderNumber);
  try {
    const resp = axios({
      method: 'get',
      url: tempurl,
      data: {
        'transactions_to_be_voided': ids
      },
    });

    return resp

  } catch (error) {
    throw error
  }

}
