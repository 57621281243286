import React from 'react';
import { ComponentsStyled } from './styled';
import BmNavBar from './BmNavBar';
import SideNavBar from '.';
import SideNavOption from '@/actions/side-nav-option';
import { IReducersState } from '@/reducers';
interface SideNavBarProps {
  onLeftClick?: React.MouseEventHandler<HTMLDivElement>;
  navIcon?:  React.ReactNode;
  navContent?: React.ReactNode;
  className?: string;
  sideNavOption: SideNavOption;
  reducersState: IReducersState;
  whiteBanner?: boolean;
  whiteBannerContent?: React.ReactNode;
  navClassName?: string;
}
const Components: React.FC<SideNavBarProps> = props => {
  const { navIcon, onLeftClick, navContent, className, children, sideNavOption, reducersState, whiteBanner, whiteBannerContent, navClassName } = props;
  return (
    <ComponentsStyled className="components-phone">
      <div className={className}>
        <BmNavBar
          onLeftClick={onLeftClick}
          navIcon={navIcon}
          navContent={navContent}
          className={navClassName}
        ></BmNavBar>
        {
          whiteBanner &&
          <div className="white-banner">
            {whiteBannerContent}
          </div>
        }
        
        <div className="whole">
          <div className="member-wrapper">
            <SideNavBar reducersState={reducersState} sideNavOption={sideNavOption}></SideNavBar>
            {children}
          </div>
        </div>
      </div>
    </ComponentsStyled>
  )
}
export default Components;

