import { takeEvery, select, call, put } from 'redux-saga/effects';
import axios from 'axios';
import Config from '@/Config';
import Action from '@/actions';
import ActionType from '@/actions/action-type';
import { IReducersState } from '@/reducers';
import {
  IQueryPoints,
  IPoint,
  IQueryRewards,
  IPayPoints,
  IReward,
} from '@/actions/point-action';
import { ICustomer, ICustomerGrowth } from '@/actions/user-action';
import { getPoints } from '@/data/Point';
import { parseResPoints, parseResRewards } from '@/utils/point-util';
import { handleQueryCustomer } from './user-saga';
import { parseResCustomerMembership } from '@/utils/store-util';

function* handleQueryPoints(params: IQueryPoints) {
  const {
    callback,
    page,
  } = params;
  let points: IPoint[] = [];
  let storeCustomer: ICustomer | undefined = undefined;
  const state: IReducersState = yield select((state: IReducersState) => state);
  try {
    const queryCustomerResult: any = yield handleQueryCustomer({
      type: ActionType.QUERY_CUSTOMER,
      storeSlug: state.app.platformConfig.storeSlug,
    });
    storeCustomer = queryCustomerResult.customer;
    if (storeCustomer) {
      const formula = `EQ("$.self.customer_id", "${storeCustomer.id}").NEQ("$.self.earn_stamp", "0")`;
      const result = yield getPoints(formula, page);
      points = parseResPoints(result);
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(points);
  }
}

function* handleQueryRewards(params: IQueryRewards) {
  const {
    storeID,
    customerID,
    callback,
  } = params;

  let rewards: IReward[] = [];

  try {
    const res = yield call(
      axios.get,
      Config.urls.getRewards.replace('{storeID}', storeID).replace('{customerID}', customerID),
    );
    rewards = parseResRewards(res)
  } catch (error) {
    console.error(error);
  }
  if (callback) {
    callback(rewards);
  }
}

function* handlePayPoints(params: IPayPoints) {
  const {
    storeID,
    customerID,
    rewardID,
    storeSlug,
    callback,
  } = params;
  let success = false;
  let loyaltyStamp: ICustomerGrowth | undefined = undefined;

  try {
    const res = yield call(
      axios.post,
      Config.urls.redeem.replace('{storeID}', storeID).replace('{customerID}', customerID).replace('{rewardID}', rewardID),
    );
    if (res && res.success) {
      success = res.success;

      const result = yield call(
        axios.get,
        Config.urls.customerGrowth.replace('{storeID}', storeID).replace('{customerID}', customerID),
      );

      if (result && result.growthValues) {
        loyaltyStamp = parseResCustomerMembership(result.growthValues);
        if (loyaltyStamp) {
          yield put<Action>({
            type: ActionType.SET_CUSTOMER_GROWTH,
            storeSlug,
            storeID,
            loyaltyStamp,
          });
        }
      }
    }
  } catch (error) {
    console.error(error);
  }
  if (callback) {
    callback(loyaltyStamp, success);
  }
}

function* watchPay() {
  yield takeEvery(ActionType.QUERY_POINTS, handleQueryPoints);
  yield takeEvery(ActionType.QUERY_REWARDS, handleQueryRewards);
  yield takeEvery(ActionType.PAY_POINTS, handlePayPoints);
}

export default watchPay();
