import React from 'react';
import { NavbarStyled } from './styled';
// import { TransitionKeys } from '../Transition';
interface SideNavBarProps {
  onLeftClick?: React.MouseEventHandler<HTMLDivElement>;
  navIcon?:  React.ReactNode;
  navContent?: React.ReactNode;
  rigthIcon?: React.ReactNode;
  onRightClick?: React.MouseEventHandler<HTMLDivElement>;
  rigthContent?: React.ReactNode;
  style?: any;
  prefix?: string;
  leftContent?: React.ReactNode;
  className?: string;
}
const BmNavBar: React.FC<SideNavBarProps> = props => {
  const { 
    navIcon, 
    onLeftClick, 
    navContent, 
    rigthContent, 
    rigthIcon, 
    onRightClick, 
    style, 
    prefix, 
    leftContent,
    className,
  } = props;
  return (
    <NavbarStyled style={style} className={`nav-bar ${className? className: ''}`}>
      <div className={` nav_bg streched without-line w-nav ${prefix}`} style={style}>
        <div className="container-stretched_1 both w-container">
          <div className="div-block-7 open-modal" onClick={onLeftClick}
          >
            {navIcon || leftContent}
          </div>
          <div className="scanner_block" onClick={onRightClick}>
            {rigthIcon}
            {
              rigthContent &&
              <div className="text-block-215">{rigthContent}</div>
            }
          </div>
        </div>
        <div className="container fixed-nav w-container">
          <h1 className="nav-bar-title">{navContent}</h1>
        </div>
      </div>
    </NavbarStyled>
  )
}
export default BmNavBar;

