import axios from 'axios';
import Config from '@/Config';
import { ISplash } from '@/actions/app-action';
import { parseResBlog } from '@/utils/store-util';
import { IBlog, IStoreInput } from '@/actions/store-action';
import { resizeImage } from '@/utils';
import { createRecord, deleteRecord, queryRecords, queryRelationRecords, updateRecord } from './mb-api';
import { getAccessToken } from '@/utils/app';
import { IObject } from '@/components/bm-common';

interface IGetAppThemeParams {
  storeSlug: string;
  relationID: string;
}

export interface IGetStoresConfigsParams {
  platformID: string;
  storeIDs?: string[];
  storeSlugs?: string[];
}

export interface IGetStoresConfigParams {
  platformID: string;
  storeID?: string;
  storeSlug?: string;
}

export interface IGetStoreBlogListParams {
  contentType: string;
  storeID?: string;
  storeSlug?: string;
}

export const getPlatform = async (domain: string, allReq?: IObject) => {

  try {
    const resp = await axios({
      method: 'post',
      url: Config.urls.queryPlatform,
      data: {
        domain,
        'get_data_key_info_map': allReq,
      }
    });
    const {
      data,
    } = resp || {};

    return {
      data,
    };
  } catch (error) {
    console.error(error)
  }
}

export const getAllStoreInfo = async (platformID: string, buyerStoreID: string | number) => {

  try {
    const resp = await axios({
      method: 'post',
      url: Config.urls.queryAllStoreInfo,
      data: {
        'platform_id': Number(platformID),
        'buyer_store_id': Number(buyerStoreID),
      }
    });
    const {
      data,
    } = resp || [];

    return {
      data,
    };
  } catch (error) {
    console.error(error)
  }
}

export const getAppTheme = async (params: IGetAppThemeParams) => {
  const {
    // storeSlug,
    relationID,
  } = params;

  const result = await queryRelationRecords({
    id: relationID,
    field: 'theme_id',
    moduleBundleID: 'bm-platform',
    storeSlugs: [Config.bindoGatewayStoreSlug],
  });

  let record: any = undefined;

  if (result.success && result.records.length > 0) {
    record = result.records[0];
  }

  return record;
}

export const getAppSettings = async (domain: string) => {
  const result = await queryRecords({
    storeSlugs: [Config.bindoGatewayStoreSlug],
    moduleBundleID: 'bm-platform',
    search: {
      formulas: [{ formula: `EQ("$.self.domain", "${domain}")` }],
    },
  });

  let data: any = undefined;

  if (result.records.length > 0) {
    data = result.records[0]
  }

  return data;
}

export const getSplashData = async (storeSlug: string, storeID: string) => {
  const result = await queryRecords({
    storeSlugs: [storeSlug],
    moduleBundleID: 'bm-splash',
    search: {
      formulas: [{ formula: `EQ("$.self.store_id", "${storeID}")` }],
      orderAsc: 'ASC',
      orderBy: 'sort',
    },
  });

  const splashData: ISplash[] = [];
  result.records.forEach(item => {
    const splash: ISplash = {
      id: item.id,
      platformID: item.platform_id,
      storeID: item.store_id,
      title: item.title || '',
      describe: item.text || '',
      imageUrl: resizeImage({
        url: item.splash_image || '',
        type: 'DEFAULT',
      }),
      videoUrl: '',
      type: 'LOGO',
      i18n: item.__i18n || {},
      alignment: item.alignment || 'left',
    }

    if (item.style === 'IMAGE' && splash.imageUrl) {
      splash.type = 'IMAGE';
    }

    if (item.style === 'VIDEO') {
      if (Array.isArray(item.video) && item.video.length > 0) {
        splash.videoUrl = item.video[0];
        splash.type = 'VIDEO'
      } else if (splash.imageUrl) {
        splash.type = 'IMAGE';
      }
    }

    splashData.push(splash);
  });

  return splashData;
}

export const addFavoriteStore = async (platformID: string, storeID: string, userID: string) => {
  const result = await createRecord({
    storeSlug: Config.bindoGatewayStoreSlug,
    moduleBundleID: 'bm-favorite',
    record: {
      'platform_id': {
        records: [{ id: platformID }]
      },
      'store_id': {
        records: [{ id: storeID }]
      },
      'user_id': {
        records: [{ id: userID }]
      }
    },
  });

  return result.record;
}

export const getFavoriteStatus = async (platformID: string, storeID: string, userID: string) => {
  const result = await queryRecords({
    storeSlugs: [Config.bindoGatewayStoreSlug],
    moduleBundleID: 'bm-favorite',
    search: {
      formulas: [{ formula: `EQ("$.self.platform_id", "${platformID}").EQ("$.self.store_id", "${storeID}").EQ("$.self.user_id", "${userID}")` }],
    },
  });

  return result.records;
}

export const cancelFavorite = async (favoriteID: string) => {
  const result = await deleteRecord({
    storeSlugs: [Config.bindoGatewayStoreSlug],
    moduleBundleID: 'bm-favorite',
    ids: [favoriteID]
  });

  return result.success;
}

export const getFavoriteList = async (platformID: string, userID: string) => {
  const result = await queryRecords({
    storeSlugs: [Config.bindoGatewayStoreSlug],
    moduleBundleID: 'bm-favorite',
    search: {
      formulas: [{ formula: `EQ("$.self.platform_id", "${platformID}").EQ("$.self.user_id", "${userID}")` }],
    },
  });

  return result.records;
}

export const getStoreBlogList = async (params: IGetStoreBlogListParams) => {

  const { storeID, storeSlug, contentType } = params;

  let formulasString = '';

  if (storeID) {
    formulasString = `EQ("$.self.store_id", "${storeID}").EQ("$.self.content_type", "${contentType}")`;
  }

  if (storeSlug) {
    formulasString = `EQ("$.self.store_slug", "${storeSlug}").EQ("$.self.content_type", "${contentType}")`;
  }

  const result = await queryRecords({
    storeSlugs: [Config.bindoGatewayStoreSlug],
    moduleBundleID: 'bm-cms-contents',
    search: {
      formulas: [{ formula: formulasString }],
    },
  });

  const blogList: IBlog[] = [];
  result.records.forEach((item: any) => {
    const blog = parseResBlog(item);
    if (blog && blog.status !== '1') {
      blogList.push(blog);
    }
  })

  return blogList;
}

export const getStoresConfigs = async (params: IGetStoresConfigsParams) => {
  const { platformID, storeIDs, storeSlugs } = params;

  let formulasString = '';

  if (storeIDs && storeIDs.length > 0) {
    formulasString = `EQ("$.self.platform_id", "${platformID}").IN("$.self.store_id", ["${storeIDs.join('","')}"])`;
  }

  if (storeSlugs && storeSlugs.length > 0) {
    formulasString = `EQ("$.self.platform_id", "${platformID}").IN("$.self.store_slug", ["${storeSlugs.join('","')}"])`
  }

  const result = await queryRecords({
    storeSlugs: [Config.bindoGatewayStoreSlug],
    moduleBundleID: 'bm-store-configures',
    search: {
      formulas: [{ formula: formulasString }],
    },
  });

  return result.records;
}

export const getStoreConfig = async (params: IGetStoresConfigParams) => {
  const { platformID, storeID, storeSlug } = params;
  const storesConfigsRes: any = await getStoresConfigs({
    platformID,
    storeIDs: storeID ? [storeID] : [],
    storeSlugs: storeSlug ? [storeSlug] : [],
  });
  let storeConfigRes: any = undefined;
  if (Array.isArray(storesConfigsRes) && storesConfigsRes.length > 0) {
    storeConfigRes = storesConfigsRes[0];
  }

  return storeConfigRes;
}

export const createStore = async (store: IStoreInput) => {

  let success = false;
  let storeData = undefined;

  try {
    let config = {};
    const token = getAccessToken();
    if (token) {
      config = {
        'headers': {
          'X-USER-ACCESS-TOKEN': token,
        }
      };
    }

    const res = await axios.post(
      Config.galaxy,
      {
        'operationName': null,
        'query': `
          mutation($storeSlug: String!, $store: StoreInput!) {
            storeCreate(storeSlug: $storeSlug, store: $store) {
              success
              store {
                id
              }
            }
          }
        `,
        'variables': {
          storeSlug: Config.bindoGatewayStoreSlug,
          store,
        },
        'fetchPolicy': 'no-cache',
      },
      config,
    );


    if (res && res.data && res.data.storeCreate) {
      success = res.data.storeCreate.success;
      storeData = res.data.storeCreate.store;
    }
  } catch (error) {
    console.error(error);
  }

  return { success, storeData };
}

export const getStoresByUser = async (formula: string, page: number, perPage: number) => {
  const result = await queryRecords({
    storeSlugs: [Config.bindoGatewayStoreSlug],
    moduleBundleID: Config.isStaging ? '4hq4tpoy' : 'associad',
    search: {
      formulas: [{ formula: formula }],
      page: page || 1,
      perPage: perPage || 10,
    },
  });

  return result.records;
}

export const QueryUserByEmail = async (email: string) => {
  const result = await queryRecords({
    storeSlugs: [Config.bindoGatewayStoreSlug],
    moduleBundleID: Config.isStaging ? '8seq8hou' : 'rh53on3y',
    search: {
      formulas: [{ formula: `EQ("$.self.email", "${email}").IS_NULL("$.self.platform_id")` }],
      page: 1,
      perPage: 10,
    },
  });

  return result.records;
}

export const UpdateRecord = async (id: string, record: any, storeSlug: string, moduleBundleID: string) => {
  const result = await updateRecord({
    id,
    storeSlug: storeSlug,
    moduleBundleID,
    record,
  });

  return result.record;
}

export const getPlatformStoreAssociations = async (platformID: string, storeID: string) => {
  const result = await queryRecords({
    storeSlugs: [Config.bindoGatewayStoreSlug],
    moduleBundleID: 'bm-platform-stores',
    search: {
      formulas: [{ formula: `EQ("$.self.platform_id", "${platformID}").EQ("$.self.store_id", "${storeID}")` }],
    },
  });

  return result.records;
}

export const UpdatePlatformStoreAssociations = async (id: string, record: any) => {
  const result = await updateRecord({
    id,
    storeSlug: Config.bindoGatewayStoreSlug,
    moduleBundleID: 'bm-platform-stores',
    record,
  });

  return result.record;
}

export const getStoreCategories = async (platformID: string, moduleBundleID: string) => {
  const result = await queryRecords({
    storeSlugs: [Config.bindoGatewayStoreSlug],
    moduleBundleID,
    search: {
      formulas: [{ formula: `EQ("$.self.platform_id", "${platformID}")` }],
      orderAsc: 'ASC',
      orderBy: 'sort',
    },
  });

  return result.records;
}

export const getStoreBrands = async (relationID: string) => {
  const result = await queryRelationRecords({
    id: relationID,
    field: 'category_content',
    moduleBundleID: 'bm-store-category',
    storeSlugs: [Config.bindoGatewayStoreSlug],
    search: {
      orderAsc: 'ASC',
      orderBy: 'sort',
    }
  });

  return result.records;
}


export const getAppStoreMembershipLevel = async (storeSlug: string) => {
  const result = await queryRecords({
    storeSlugs: [storeSlug],
    moduleBundleID: 'bm-membership-level',
  });

  return result.records;
}

export const getStoreRounding = async (storeSlug: string) => {

  let config = {};
  const token = getAccessToken();
  if (token) {
    config = {
      'headers': {
        'X-USER-ACCESS-TOKEN': token,
      }
    };
  }
  const res = await axios.post(Config.galaxy, {
    'operationName': null,
    'query': `
      query ($storeSlug: String!, $moduleBundleID: String!) {
        setting(storeSlug: $storeSlug, moduleBundleID: $moduleBundleID)
      }
    `,
    'variables': {
      moduleBundleID: Config.isStaging ? 'zj924r10' : '914spubi',
      storeSlug,
    }
  }, config);

  return res && res.data && res.data.setting;
}

export const getAppIcons = async (platformID: string) => {
  const result = await queryRecords({
    storeSlugs: [Config.bindoGatewayStoreSlug],
    moduleBundleID: 'bm-mobile-icon',
    search: {
      formulas: [{ formula: `EQ("$.self.platform_id", "${platformID}")` }],
    },
  });

  return result.records;
}

export const getPlatformIDByDomain = async (domain: string) => {
  let platformID = null;
  try {
    const platformData = await axios.post(Config.urls.checkPlatform, {
      domain,
    })
    if (platformData && platformData.data && platformData.data.platform) {
      platformID = platformData.data.platform;
    };
  } catch (error) {

  }
  return platformID;
}

export const getPlatformCustomPages = async (platformID: string) => {
  const result = await queryRecords({
    storeSlugs: [Config.bindoGatewayStoreSlug],
    moduleBundleID: 'bm-customs-page',
    search: {
      formulas: [{ formula: `EQ("$.self.platform_id", "${platformID}")` }],
    },
  });

  return result.records;
}

export const queryUserStoreAssociates = async () => {
  let userStoreAssociates: any[] = [];
  try {
    let config = {};
    const token = getAccessToken();
    if (token) {
      config = {
        'headers': {
          'X-USER-ACCESS-TOKEN': token,
        }
      };
    }

    const res = await axios.post(Config.galaxy, {
      'operationName': null,
      'query': `
        query($skipped: Boolean) {
          userStoreAssociates(skipped: $skipped) {
            userID
            associateTypes {
              id
              name
              functionSwitchForStore {
                addExpressOrder
                expressOrderViewOnly
              }
            }
            store {
              id
              slug
              title
              parentID
              chain
              currency
              address1
              logoUrl
              originalUrl
              translationInfo
              # description
              # phone
              # summary
              # logo,
            }
          }
        }
      `,
    }, config);

    if (res && res.data && Array.isArray(res.data.userStoreAssociates)) {
      userStoreAssociates = res.data.userStoreAssociates;
    }
  } catch (error) {

  }
  
  return userStoreAssociates;
}

export const getStoreLocation = async (platformID: string, moduleBundleID: string) => {
  const result = await queryRecords({
    storeSlugs: [Config.bindoGatewayStoreSlug],
    moduleBundleID,
    search: {
      formulas: [{ formula: `EQ("$.self.platform_id", "${platformID}")` }],
      orderAsc: 'ASC',
      orderBy: 'sort',
    },
  });

  return result.records;
}
