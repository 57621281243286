import React from 'react';
import { prefix } from '.';
import MenuPanel from './MenuPanel';
import { IFavoriteTab, IListing } from '@/lib/order-builder/utils/api';
import dayjs from 'dayjs';
import { ListingCountRef } from '@/actions/shopping-cart-action';
import { IReducersState } from '@/reducers';

interface IMenuListProps {
  data: IFavoriteTab[];
  listingCountRef: ListingCountRef;
  isOpen: boolean;
  isGrid?: boolean;
  currentDay: number;
  className?: string;
  itemClassName?: string;
  onlyShow?: boolean; // 只用于显示菜单信息，屏蔽所有操作功能
  onPlusClick?: (data: IListing) => void;
  onMinusClick?: (data: IListing) => void;
  hideSubTitle?: boolean;
  reducersState: IReducersState;
  menuDisplayMethod?: string;
  currencyCode?: string;
  onPopupClick?: (data: IListing) => void;
  handleChangeQuantityTo?: (data: IListing, quantity: number) => void;
}

const MenuList: React.FC<IMenuListProps> = props => {
  const {
    data,
    // loading = false,
    className = '',
    listingCountRef,
    isOpen,
    isGrid,
    currentDay,
    // itemClassName,
    onlyShow = false,
    onPlusClick,
    onMinusClick,
    hideSubTitle,
    reducersState,
    menuDisplayMethod = '',
    currencyCode,
    onPopupClick,
    handleChangeQuantityTo,
  } = props;

  const currentHHmmss: string = dayjs().format('HH:mm:ss');

  return (
    <div id="burritos" className={`${prefix} ${className} burritos`}>
      {
        data.map(item => item && item.favoriteSections.length > 0 && (
          <MenuPanel
            key={item.id}
            data={item}
            isOpen={isOpen}
            isGrid={isGrid}
            currentDay={currentDay}
            currentHHmmss={currentHHmmss}
            listingCountRef={listingCountRef}
            onlyShow={onlyShow}
            onPlusClick={onPlusClick}
            onMinusClick={onMinusClick}
            hideSubTitle={hideSubTitle}
            reducersState={reducersState}
            menuDisplayMethod={menuDisplayMethod}
            currencyCode={currencyCode}
            onPopupClick={onPopupClick}
            handleChangeQuantityTo={handleChangeQuantityTo}
          />
        ))
      }
    </div>
  );
}

export default MenuList;
