import React from 'react';
import dayjs from 'dayjs';
import { ISaleTransaction } from '@/lib/order-builder/models/OrderEntity';
import ShippingMethod from '@/lib/order-builder/enums/ShippingMethod';
import { getPayIcon } from '@/components/Payment';
import { getIntl } from '@/components/App/App';

interface IOrderInformationProps {
  method: ShippingMethod;
  orderNumber: string;
  referenceID: string;
  orderTime: string;
  deliveryDate: string | dayjs.Dayjs;
  saleTransactions: ISaleTransaction[];
}

const OrderInformation: React.FC<IOrderInformationProps> = props => {
  const {
    method,
    orderNumber,
    referenceID,
    orderTime,
    deliveryDate,
    saleTransactions,
  } = props
  return (
    <div className="order-summary-block">
      <div className="div-block-493 with-margin">
        <div className="div-block-484">
          <div className="text-block-98">{getIntl().page.orderNumber}</div>
          <div className="text-block-68">{orderNumber}</div>
        </div>
        <div className="div-block-484">
          <div className="text-block-98">{getIntl().page.referenceID}</div>
          <div className="text-block-68">{referenceID}</div>
        </div>
        <div className="div-block-484">
          <div className="text-block-98">{getIntl().page.orderTime}</div>
          <div className="text-block-68">{dayjs(orderTime).format('YYYY-MM-DD HH:mmA')}</div>
        </div>
        <div className="div-block-484">
          <div className="text-block-98">
            {
              (method === ShippingMethod.DINE_IN || method === ShippingMethod.DINE_IN_UNASSIGNED) &&
              getIntl().page.dineIn
            }
            {
              (method === ShippingMethod.PICK_UP) &&
              getIntl().page.takeAway
            }
            {
              (method === ShippingMethod.DELIVERY) &&
              getIntl().page.delivery
            }
          </div>
          <div className="text-block-68">
            {
              (method === ShippingMethod.DINE_IN || method === ShippingMethod.DINE_IN_UNASSIGNED) &&
              getIntl().page.immediately
            }
            {
              (method === ShippingMethod.PICK_UP || method === ShippingMethod.DELIVERY) &&
              dayjs(deliveryDate).format('YYYY-MM-DD HH:mmA')
            }
          </div>
        </div>
        <div className="div-block-484 no-space">
          <div className="text-block-98">{getIntl().page.payment}</div>
          <div className="text-block-68">
            {
              saleTransactions && saleTransactions.length > 0 && saleTransactions.map(item => {
                const { payIcon, payTitle } = getPayIcon(item.paymentMethod, item.creditCardType, 'pi-card', item.id);
                return (
                  <div className="payment" key={item.id}>
                    <div className="icon image-199">
                      {payIcon}
                    </div>
                    <div className="text-block-67">{payTitle} {item.last4Digits}</div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderInformation;
