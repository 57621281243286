
import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import { IObject } from '@/components/bm-common';
import { getAppTheme, setScreenColor, getIntl } from '@/components/App/App';
import Layout from '@/components/Layout/Layout';
import { prefix } from '.';
import ActionType from '@/actions/action-type';
import { IStore } from '@/actions/store-action';
import paths from '@/routes/paths';
import Icon, { IconType } from '@/components/Icon';
import StoreInfo from '@/pages/express-order/ExpressOrder/StoreInfo';
import dayjs from 'dayjs';
import Config from '@/Config';

export interface IAboutUsPageProps extends IBasePageProps { }
export interface IAboutUsPageState {
  list: any[];
  loading: boolean;
  selectStore: IStore | undefined; //选择的store
  invoices: any;
  selectedTimeType: string;
  dateStart: string;
  dateEnd: string;
}
const today = dayjs(new Date()).format('YYYY-MM-DD');
const week = dayjs(new Date()).subtract(6, 'day').format('YYYY-MM-DD');
const month = dayjs(new Date()).startOf('month').format('YYYY-MM-DD');
const lastMonth = dayjs(month).subtract(1, 'day').format('YYYY-MM-DD');

class AboutUsPage extends BasePage<IAboutUsPageProps, IAboutUsPageState> {
  routeParams: IObject = {};

  state: IAboutUsPageState = {
    loading: true,
    selectStore: undefined,
    invoices: {},
    selectedTimeType: 'all',
    dateStart: '',
    dateEnd: '',
    list: [
      {
        tabName: getIntl().page.all,
        dateStart: '',
        dateEnd: '',
        timeType: 'all',
      },
      {
        tabName: getIntl().common.today,
        dateStart: today,
        dateEnd: '',
        timeType: 'today',
      },
      {
        tabName: getIntl().page.thisWeek,
        dateStart: week,
        dateEnd: today,
        timeType: 'week',
      },
      {
        tabName: getIntl().page.thisMonth,
        dateStart: month,
        dateEnd: today,
        timeType: 'month',
      },
      {
        tabName: getIntl().page.monthAgo,
        dateStart: '',
        dateEnd: lastMonth,
        timeType: 'year',
      }
    ],
  }

  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.lightBackgroundColor) {
      setScreenColor(appTheme.lightBackgroundColor);
    }
    super.componentDidMount();
    const { dispatch, reducersState } = this.props;
    const { selectStoreId } = reducersState.supplierInfo;
    if (selectStoreId) {
      dispatch({
        type: ActionType.QUERY_STORE_BY_ID,
        storeID: selectStoreId.toString(),
        callback: (store, storeConfig) => {
          if (store) {
            this.setState({ selectStore: store })
          }
        }
      });
      dispatch({
        type: ActionType.QUERY_DEFERRED_INVOICES,
        storeID: selectStoreId.toString(),
        timeType: this.state.selectedTimeType,
        callback: (invoices: any) => {
          this.setState({
            invoices: invoices,
          })
        }
      });
      
    }
  }

  changeTab = (item: any) => {
    this.setState({
      dateStart: item.dateStart,
      dateEnd: item.dateEnd,
      selectedTimeType: item.timeType,
    })
    const { dispatch, reducersState } = this.props;
    const { selectStoreId } = reducersState.supplierInfo;
    if (item.timeType !== this.state.selectedTimeType) {
      dispatch({
        type: ActionType.QUERY_DEFERRED_INVOICES,
        storeID: selectStoreId.toString(),
        dateStart: item.dateStart,
        dateEnd: item.dateEnd,
        timeType: item.timeType,
        callback: (invoices: any) => {
          this.setState({
            invoices: invoices,
          })
        }
      });
    }
  }

  handleGoDetail = (storeID: string, orderNumber: string) => {
    this.handleGotoRoute({
      path: paths.DEFERRED_ORDER_DETAIL,
      pathParams: {
        storeID: storeID,
        timeType: this.state.selectedTimeType,
        orderNumber: orderNumber,
      },
    })
  }


  render() {
    const { reducersState } = this.props;
    const { selectStore, invoices, selectedTimeType, list } = this.state;
    return (
      <>
        <Layout
          {...this.props}
          className={prefix}
          navContent={getIntl().page.deferredInvoices}
          navIcon={
            <Icon className="back-icon" type={IconType.LEFT_OUTLINED} />
          }
          onLeftClick={() => {
            this.handleGotoRoute({
              path: paths.HOME,
            });
          }}
          isFooter={false}
        >
          <div className="background-top red" />
          <div className="section birdymart wf-section">
            <div className="container top w-container">
              <div className="div-block-485 _1">
                <div className="left-block _58">
                  {
                    Object.values(invoices).map((item: any) => (
                      <div className="po-history-block gap" key={item.supplierStoreID}>
                        <div className="div-block-652 head">
                          <div className="deffered-invoices-supplier">{item.supplierStoreName}</div>
                          <div className="div-block-652 _100">
                            <div className="div-block-653">
                              <div className="price-cart">
                                <div className="text-block-6 _18 space">{getIntl().common.total}</div>
                                <div className="text-block-223">{item.total} {getIntl().page.invoices}</div>
                              </div>
                              <div className="price-cart">
                                <div className="text-block-6 _18 space">{getIntl().page.lastInvoiceDate}</div>
                                <div className="text-block-223">{dayjs(item.lastInvoiceData).format('YYYY-MM-DD')}</div>
                              </div>
                            </div>
                            <div className="link-block-10 w-inline-block">
                              <div className="primary-payment-confirmation-buttons red short payall">
                                <div className="text-block-17" onClick={() => this.handleGoDetail(item.supplierStoreID, 'all')}>{getIntl().page.payAll}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="div-block-572">
                          {
                            item.orders.map((_item: any) => (
                              <div className="div-block-561 non-color w-inline-block" key={_item.orderNumber} onClick={() => this.handleGoDetail(_item.storeID, _item.orderNumber)}>
                                <div className="div-block-652">
                                  <h4 className="heading-4-1">{_item.orderNumber}</h4>
                                  <div className="text-block-91-copy">{getIntl().page.viewDetails}</div>
                                </div>
                                <div className="text-block-98 space">{dayjs(_item.orderTime).format('YYYY-MM-DD H:mm:ss')}</div>
                                {
                                  _item.orderEntities[0].lineItems.map((jitem: any, index: number) => {
                                    if (index === 0) {
                                      return (
                                        <div className="div-block-482-copy with-space">
                                          <div className="div-block-481-copy" style={{ backgroundImage: `url(${jitem.imageUrl ? jitem.imageUrl : Config.defaultLineItemImg })`}}></div>
                                          <div className="div-block-578">
                                            <div className="item-info checkout-process">
                                              <div className="div-block-652">
                                                <h4 className="heading-4-1 checkout-process">{jitem.name}</h4>
                                                <div className="_107">X {jitem.quantity}</div>
                                              </div>
                                              <div className="div-block-652">
                                                {
                                                  _item.orderEntities[0].lineItems.length - 1 > 0 &&
                                                  <div className="text-block-223">+ {_item.orderEntities[0].lineItems.length - 1} {getIntl().page.othersProducts}</div>
                                                }
                                              </div>
                                            </div>
                                            <div className="price-cart">
                                              <div className="text-block-6 _18 rightalign">{getIntl().common.total}:&nbsp;{_item.initialTotal}</div>
                                            </div>
                                          </div>
                                        </div> )
                                    } else {
                                      return ''
                                    }
                                  })
                                }
                              </div>
                            ))
                          }
                        </div>
                      </div>

                    ))
                  }

                </div>
                <div className="right-block-birdymart">
                  <div className="info-tool">
                    {
                      selectStore &&
                      <StoreInfo
                        store={selectStore}
                        reducersState={reducersState}
                        canChangeStore={false}
                      />
                    }
                    <div className="div-block-510 no-shadow-tablet margin">
                      <div className="div-block-574">
                        <div className="quick-filter-group">
                          {
                            list.map(item => (
                              <div className={`status grey ${selectedTimeType === item.timeType ? 'selected' : ''}`} key={item.timeType} onClick={() => this.changeTab(item)}>{item.tabName}</div>
                            ))
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {
            Object.values(invoices).length <= 0 &&
            <div className="no-content-panel">
              <Icon type={IconType.TAG_ITEM} />
              <div className="no-content-info">
                {getIntl().page.noDataTips}
              </div>
            </div>
          }
        </Layout>
      </>
    );
  }
}

export default AboutUsPage;
