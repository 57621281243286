import React from 'react';
import { prefix } from '.';
import { IReducersState } from '@/reducers';
import { IBasePageProps } from '@/pages/BasePage';
import Splash from '../Splash';
import paths from '@/routes/paths';
import { handleScroll } from '../App/App';
import Footer from '@/components/common/Footer';
import BmNavBar from '../SideNavBar/BmNavBar';

interface ILayoutProps extends IBasePageProps {
  reducersState: IReducersState;
  prefixCls?: string;
  className?: string;
  navBar?: React.ReactNode;
  navIcon?: React.ReactNode;
  navContent?: React.ReactNode;
  navLeftContent?: React.ReactNode;
  navRightContent?: React.ReactNode;
  bottomNav?: React.ReactNode;
  onLeftClick?: React.MouseEventHandler<HTMLDivElement>;
  isFooter?: boolean;
}

const noSplashPaths: string[] = [
  paths.QRCODE_SHOPPING,
  paths.QRCODE_SHOPPING_CART,
  paths.QRCODE_SHOPPING_DETAIL,
  paths.QRCODE_SHOPPING_MENUS,
  paths.QRCODE_SHOPPING_RESULT,
];

const Layout: React.FC<ILayoutProps> = props => {
  const {
    className = '',
    children,
    navIcon,
    navBar,
    navContent,
    navLeftContent,
    navRightContent,
    bottomNav,
    reducersState,
    routeParams,
    dispatch,
    onLeftClick,
    isFooter = true,
  } = props;

  const { showSplash, splashData, deviceInfo } = reducersState.app;
  const isShowSplash = !deviceInfo.inPC && showSplash && splashData.length > 0 && !noSplashPaths.includes(routeParams.routePath);
  return (
    <>
      <div className={`${prefix} ${className} ${isShowSplash ? 'show-splash' : ''} ${(deviceInfo.inPC || deviceInfo.inPad) ? 'web' : 'phone'}`}>
        { navBar }
        {
          (!navBar && (navContent || navRightContent || navIcon || navLeftContent))&&
          // <NavBar
          //   prefixCls={prefixCls}
          //   mode="dark"
          //   icon={navIcon}
          //   onLeftClick={onLeftClick}
          //   className="bm-navbar-fixed"
          //   leftContent={navLeftContent}
          //   rightContent={navRightContent}
          // >{navContent}</NavBar>
          <BmNavBar
            onLeftClick={onLeftClick}
            navIcon={navIcon}
            navContent={navContent}
            rigthContent={navRightContent}
            leftContent={navLeftContent}
          ></BmNavBar>
        }
        <div className={`page-content-wrapper ${(deviceInfo.inPC || deviceInfo.inPad) ? 'page-content-wrapper-web' : ''}`} onScroll={handleScroll}>
          <div className="pc-header"/>
          <div className="page-content">
            {children}
          </div>
          {
            isFooter &&
            <div className="pc-footer">
              <Footer reducersState= {reducersState}/> 
            </div>
          }
        </div>
        {bottomNav}
      </div>
      {
        isShowSplash &&
        <Splash
          data={splashData}
          reducersState={reducersState}
          dispatch={dispatch}
        />
      }
    </>
  );
}

export default Layout;
