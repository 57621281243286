import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import { prefix } from '.';
import ActionType from '@/actions/action-type';
import MenuGrid from '@/components/MenuGrid/MenuGrid';
import { getAppTheme, setScreenColor, getIntl } from '@/components/App/App';
import Layout from '@/components/Layout/Layout';
import { IStore, IStoreConfig } from '@/actions/store-action';
import { parseRouteParams, pxToRem } from '@/utils';
import Icon, { IconType } from '@/components/Icon';
import { IFavoriteTab } from '@/lib/order-builder/utils/api';
import ShoppingCartBar from '@/components/ShoppingCartBar';
import { parseOpeningHours, IParseOpeningHoursResult } from '@/utils/app';
import PanelBar from '../StoresPage/PanelBar';
import { IObject } from '@/components/bm-common';
import BmTabBar from '@/components/BmTabBar';
import paths from '@/routes/paths';
import { IShoppingCartData } from '@/actions/shopping-cart-action';
import MiniProgramTips from '@/components/MiniProgramTips';
import { setCanAutoProcurementMethodPopup } from '../MenuListPage/MenuListPage';
import TabsOption from '@/actions/tabs-option';
import { MenuGridPageStyled } from './styled';

export interface IMenuGridPageProps extends IBasePageProps {
  menus?: IFavoriteTab[];
  store?: IStore;
  isHomePage?: boolean;
  storeConfig?: IStoreConfig;
}

export interface IMenuGridPageState {
  page: number;
  store: IStore | undefined;
  menus: IFavoriteTab[];
  height: string;
  minHeight: string;
  maxHeight: string;
  menuNavFixed: boolean;
  selectedMenuID: string;
  shoppingCartData?: IShoppingCartData;
  storeSlug: string;
  storeConfig: IStoreConfig | undefined;
}

class MenuGridPage extends BasePage<IMenuGridPageProps, IMenuGridPageState> {
  loadingData = false;

  constructor(props: IMenuGridPageProps) {
    super(props);
    const routeParams = parseRouteParams(props);
    const { store, menus, storeConfig } = props;
    let storeSlug = '';
    if (routeParams && routeParams.storeSlug) {
      storeSlug = routeParams.storeSlug;
    } else if (store) {
      storeSlug = store.slug;
    }

    let selectedMenuID = '';
    if (menus && menus.length > 0) {
      selectedMenuID = menus[0].id;
    }

    this.state = {
      page: 1,
      store,
      menus: menus || [],
      storeConfig,
      height: pxToRem(332),
      minHeight: pxToRem(88),
      maxHeight: pxToRem(332),
      menuNavFixed: false,
      selectedMenuID,
      shoppingCartData: undefined,
      storeSlug,
    }
  }

  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.darkBackgroundColor) {
      setScreenColor(appTheme.darkBackgroundColor);
    }

    super.componentDidMount();
    const { isHomePage = false } = this.props;
    const { storeSlug, store } = this.state;

    if (!isHomePage && storeSlug) {
      this.getMenus(storeSlug);
    } else {
      this.closeLoading();
    }

    if (isHomePage && store && store.slug) {
      this.getStoreShoppingCartData(store.slug);
    }
  }

  getMenus = (storeSlug: string) => {
    const { dispatch } = this.props;
    dispatch({
      type: ActionType.QUERY_LISTINGS,
      storeSlug,
      callback: (menus, store, storeConfig) => {
        this.setState({ store, storeConfig });

        if (store) {
          this.getStoreShoppingCartData(storeSlug);
        }

        let selectedMenuID = '';
        if (menus.length > 0) {
          selectedMenuID = menus[0].id;
        }
        this.setState({
          menus,
          selectedMenuID,
        });

        this.closeLoading();
      }
    });
  }

  getStoreShoppingCartData = (storeSlug: string) => {
    const { dispatch } = this.props;
    dispatch({
      type: ActionType.GET_STORE_SHOPPING_CART_DATA,
      storeSlug,
      resetDineInTable: true,
      callback: params => {
        this.setState({ shoppingCartData: params.shoppingCartData });
      },
    });
  }

  handleItemClick = (data: IFavoriteTab) => {
    setCanAutoProcurementMethodPopup(true);
    this.handleGotoRoute({
      path: paths.MENU_LIST_TO,
      pathParams: {
        storeSlug: data.storeSlug,
        menuID: data.id,
      },
    });
  }

  render() {
    const { reducersState, isHomePage = false } = this.props;
    const {
      store,
      menus,
      shoppingCartData,
      storeConfig,
    } = this.state;
    const { openingHours } = store || {};
    const { listingCountRef = {} } = shoppingCartData || {};
    const { platformConfig, deviceInfo } = reducersState.app;

    let parseOpeningHoursResult: IParseOpeningHoursResult | undefined = undefined;
    if (openingHours) {
      parseOpeningHoursResult = parseOpeningHours(openingHours);
    }

    let isOpen = false;
    let currentDay = 1;
    if (parseOpeningHoursResult) {
      isOpen = parseOpeningHoursResult.isOpen;
      currentDay = parseOpeningHoursResult.currentDay;
    }

    const layoutProps: IObject = {};

    if (isHomePage) {
      if (store) {
        layoutProps.navContent = store.name;
      }
    } else {
      layoutProps.navIcon = <Icon className="circle-back-icon-outlined" type={IconType.LEFT_OUTLINED} />;
      layoutProps.onLeftClick = () => this.handleGoBack({
        path: paths.STORE_LIST,
        runGoBack: true,
      });
    }

    return (
      <>
        <Layout
          {...this.props}
          {...layoutProps}
          className={prefix}
        >
          { deviceInfo.weChatMiniProgram && isHomePage &&
            <MiniProgramTips />
          }
          <MenuGridPageStyled>
            <div className="section _1 wf-section">
              <div className="div-block-634">
                <PanelBar
                  title={getIntl().page.allMenus}
                />
                {
                  parseOpeningHoursResult &&
                  <MenuGrid
                    data={menus}
                    isOpen={isOpen}
                    currentDay={currentDay}
                    listingCountRef={listingCountRef}
                    onItemClick={this.handleItemClick}
                  />
                }
              </div>
            </div>
          </MenuGridPageStyled>
        </Layout>
        {
          store && storeConfig && storeConfig.showOrder &&
          <ShoppingCartBar
            store={store}
            reducersState={reducersState}
            className={isHomePage ? 'has-tab-bar' : ''}
            currencyCode={store.currencyCode}
          />
        }
        {
          isHomePage && platformConfig.showTabs &&
          <BmTabBar
            selectedKey={TabsOption.MENUS}
            reducersState={reducersState}
          />
        }
      </>
    );
  }
}

export default MenuGridPage;
