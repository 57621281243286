import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
  ITimeSegmentsQuota,
  IBooking,
  INumberQuota,
} from '@/actions/booking-action';
import { ITimeTimeSegmentID } from './Time';
import { filterItems } from '@/utils/booking-util';
interface IChooseTimeItemProps {
  data: ITimeTimeSegmentID;
  className: string;
  onClick: (data: string, timeSegmentID: string, duration: number) => void;
  bookings: IBooking[];
  timeSegmentsQuotas: ITimeSegmentsQuota[];
  selectDay: string;
  numberQuota: INumberQuota[];
  allBookings: IBooking[];
  peopleNumber: number;
}

const ChooseTimeItem: React.FC<IChooseTimeItemProps> = props => {
  const [timeDisable, setTimeDisable] = useState('');
  const [turnTime, setTurnTime] = useState(0);
  const {
    data,
    className,
    onClick,
    timeSegmentsQuotas,
    bookings,
    selectDay,
    numberQuota,
    allBookings,
    peopleNumber,
  } = props

  useEffect(() => {
    let duration = 0;
    let availableQuota = 0;
    let times = 0;
    let maxPeople = -1;
    let isFull = true;
    let people = 0;
    const dayTime = dayjs(selectDay + data.time);
    setTimeDisable('');

    filterItems(timeSegmentsQuotas, dayTime).forEach(item => {
      if (item.tableTypeID === data.tableTypeID && item.timeSegmentID === data.timeSegmentID) {
        duration = item.duration;
        availableQuota = item.availableQuota;
      }
    })
    filterItems(numberQuota, dayTime).forEach(item => {
      if (item.timeSegmentID === data.timeSegmentID) {
        maxPeople = item.max_people;
      }
    })

    if (maxPeople !== -1) {
      allBookings.forEach(item => {
        if (dayjs(item.reservationTime).isSame(dayTime)) {
          people = people + item.people;
        } else {
          if (dayjs(item.reservationTime).isBefore(dayTime) && dayjs(item.reservationTime).add(item.turnTime, 'm').isAfter(dayTime)) {
            people = people + item.people;
          }
          if (dayjs(item.reservationTime).isAfter(dayTime) && dayjs(item.reservationTime).isBefore(dayTime.add(duration, 'm'))) {
            people = people + item.people;
          }
        }
      })
      if (maxPeople >= people + peopleNumber) {
        isFull = false;
      }
      if (isFull) {
        setTimeDisable('time-disable');
        onClick('', data.timeSegmentID, turnTime);
      }
    }
    bookings.forEach(item => {
      if (dayjs(item.reservationTime).isSame(dayTime)) {
        times = times + 1;
      } else {
        if (dayjs(item.reservationTime).isBefore(dayTime) && dayjs(item.reservationTime).add(item.turnTime, 'm').isAfter(dayTime)) {
          times = times + 1;
        }
        if (dayjs(item.reservationTime).isAfter(dayTime) && dayjs(item.reservationTime).isBefore(dayTime.add(duration, 'm'))) {
          times = times + 1;
        }
      }
    })
    if (times >= availableQuota) {
      setTimeDisable('time-disable');
      if (className === 'select-date') {
        onClick('', data.timeSegmentID, turnTime);
      }
    }
    setTurnTime(duration);
  }, [selectDay, bookings, peopleNumber])

  return (
    <label className="radio-button-time w-radio"
      onClick={() => {
        if (!timeDisable) {
          onClick(data.time, data.timeSegmentID, turnTime);
        }
      }}
    >
      <span className={`label-1b w-form-label  ${timeDisable? timeDisable: className}`}>{data.time}</span>
    </label>
  );
}

export default ChooseTimeItem;
