import  styled from 'styled-components';
export const UsePointsPageStyled =styled.div`
  box-sizing: border-box;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 85%;
  max-width: 85%;
  margin-top: 70px;
  margin-bottom: 30px;
  padding: 0px 30px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-radius: 8px;
  background-color: var(--bm-border-color);
  * {
    box-sizing: border-box;
  }
  .tabs-menu-4 {
    position: -webkit-sticky;
    position: sticky;
    top: 70px;
    z-index: 9900000000;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    max-height: none;
    max-width: 200px;
    margin-top: 0px;
    margin-right: 40px;
    margin-left: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .w-tab-link {
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-decoration: none;
    padding: 9px 30px;
    text-align: left;
    cursor: pointer;
  }
  .my-priviledge-tab {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20px;
    padding-right: 20px;
    padding-left: 20px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    background-color: transparent;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: var(--bm-text-title-color);
    font-size: 16px;
  }
  .my-priviledge-tab.selected {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    border-radius: 40px;
    background-color: var(--bm-primary-color);
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: var(--bm-light-background-color);
    font-size: 16px;
    font-weight: 700;
  }
  .w-tab-content {
    position: relative;
    display: block;
    overflow: hidden;
  }
  .tabs-content {
    margin-top: 30px;
    margin-bottom: 20px;
    margin-left: auto;
    padding: 0px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border-radius: 0px;
  }
  .w--tab-active,
  .w-tab-pane {
    display: block;
  }
  .section {
    display: block;
    margin-top: auto;
    margin-bottom: 0px;
    padding: 60px 40px 40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: unset;
  } 
  .section.points-redeem {
    position: relative;
    display: block;
    margin-top: 0px;
    margin-bottom: 40px;
    padding: 0px;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  .div-block-537 {
    display: block;
    max-width: none;
    margin-right: auto;
    margin-left: auto;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  .div-block-537.with-space {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20px;
    padding-bottom: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    border-bottom: 1px solid var(--bm-border-color);
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: var(--bm-text-title-color);
  } 
  .div-block-597 {
    display: -ms-grid;
    display: grid;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
    width: 100%;
    color: var(--bm-text-title-color);
  }
  .submit-button-modal {
    width: 100%;
    height: 55px;
    padding: 17px;
    border-radius: 8px;
    background-color: var(--bm-primary-color);
    color: var(--bm-light-background-color);
    text-align: center;
  }
  .submit-button-modal.sticky {
    position: -webkit-sticky;
    position: sticky;
    margin-top: 20px;
  }
  .submit-button-modal.disabled {
    opacity: .6;
  }
  .div-block-594 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 5px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
  }
  .h2 {
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: 'Sf pro display', sans-serif;
    font-size: 24px;
    font-weight: 600;
  }
  .h2.black.regular {
    margin-bottom: 0px;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    font-weight: 400;
  }
  .text-block-102 {
    margin-right: 0px;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    font-size: 18px;
    font-weight: 700;
  }
  .text-block-102.regular {
    font-size: 24px;
    font-weight: 400;
  }
  .text-block-91 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: var(--bm-text-color);
    font-size: 18px;
    text-align: center;
  }
  .text-block-91.with-margin {
    margin-bottom: 5px;
  }
  .div-block-595 {
    position: relative;
    margin-top: 10px;
    padding-left: 20px;
  }
  .insert-reward-manually {
    font-size: 55px;
    line-height: 60px;
  }
  .div-block-596 {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    height: 55px;
    margin-right: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
  } 
  .text-block-67 {
    margin-bottom: 0px;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: var(--bm-text-title-color);
  }
  .text-block-67.reward {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 0px 30px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border-style: solid;
    border-width: 1px;
    border-color: #96a6cf;
    border-radius: 4px;
    background-color: #e3e7f1;
    color: var(--bm-text-title-color);
    text-align: center;
  }
  .div-block-545 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 0px;
    padding: 20px 15px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    border-radius: 6px;
    background-color: #e3e7f1;
    color: #a39d9d;
  }
  .div-block-545.with-space {
    margin-bottom: 20px;
  }
  .div-block-598 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .html-embed-54 {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    color: var(--bm-text-title-color);
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .section.cms {
    position: relative;
    display: block;
    margin-top: 0px;
    margin-bottom: 40px;
    padding: 0px;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  img {
    width: 100%;
  }
  .privilege {
    display: flex;
  }

  
  
@media screen and (max-width: 991px) {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  max-width: 74%;
  margin-bottom: 0px;
  padding-right: 20px;
  padding-left: 20px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  .tabs-menu-4 {
    top: 70px;
    width: auto;
    max-width: none;
    min-width: 0px;
    margin-right: 30px;
    margin-left: 0px;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .tabs-menu-4.center {
    margin-right: 25px;
  }
  .my-priviledge-tab {
    padding-right: 20px;
    padding-left: 20px;
  }
  .my-priviledge-tab.selected {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
  }
  .tabs-content {
    overflow: visible;
    margin-top: 0px;
    margin-left: 0px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .section {
    margin-top: 0px;
    padding: 40px 35px 30px;
  }
  .section.points-redeem {
    width: auto;
    min-width: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .div-block-537 {
    display: -ms-grid;
    display: grid;
    overflow: visible;
    margin-right: 0px;
    margin-left: 0px;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  .div-block-537.with-space {
    margin-bottom: 15px;
    padding-bottom: 15px;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .div-block-594 {
    width: 100%;
    margin-bottom: 0px;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }
  .text-block-67.reward {
    padding-right: 20px;
    padding-left: 20px;
  }
  .div-block-545 {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .div-block-545.with-space {
    margin-bottom: 0px;
  }
  .section.cms {
    width: auto;
    min-width: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  display: block;
  width: 100%;
  max-width: none;
  border-radius: 0px;
  margin-top: 40px;
  background-color: var(--bm-dark-background-color);
  .tabs-menu-4 {
    top: 60px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-height: 50px;
    max-width: 727px;
    min-height: 50px;
    margin-right: 20px;
    margin-left: 20px;
    padding-top: 0px;
    padding-bottom: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .privilege {
    display: none;
  }
  .tabs-menu-4.center {
    margin-right: 0px;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .my-priviledge-tab {
    margin-bottom: 0px;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
    font-size: 18px;
  }
  .my-priviledge-tab.selected {
    margin-bottom: 0px;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
    font-size: 16px;
  }
  .tabs-content {
    margin-bottom: 0px;
  }
  .tab-pane {
    padding-top: 0px;
  }
  .section {
    padding: 83px 25px 20px;
  }
  .section.points-redeem {
    margin-right: 0px;
    padding: 20px;
    border-radius: 8px;
    background-color: var(--bm-light-background-color);
  }
  .div-block-537 {
    margin-right: 0px;
    margin-left: 0px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .h2 {
    font-size: 20px;
  }
  .h2.black {
    font-size: 20px;
  }
  .div-block-545 {
    width: auto;
    margin-right: -20px;
    margin-left: -20px;
    padding-right: 40px;
    padding-left: 40px;
    border-radius: 0px;
  }
  .div-block-545.with-space {
    margin-right: 0px;
    margin-left: 0px;
    border-radius: 4px;
    background-color: #e3e7f1;
  }
  .section.cms {
    margin-top: 20px;
    margin-right: 0px;
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media screen and (max-width: 479px) {
  padding-right: 12px;
  padding-left: 12px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-color: var(--bm-dark-background-color);
  margin-top: 30px;
  .tabs-menu-4 {
    position: -webkit-sticky;
    position: sticky;
    top: 50px;
    max-height: 50px;
    max-width: none;
    margin-right: 0px;
    margin-left: 0px;
    padding-top: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;  
  }
  .tabs-menu-4.center {
    margin-right: 0px;
  }
  .my-priviledge-tab {
    padding-right: 15px;
    padding-left: 15px;
    font-size: 16px;
  }
  .my-priviledge-tab.selected{
    padding: 5px 15px;
    font-size: 14px;
  }
  .section {
    padding: 68px 12px 15px;
  }
  .section.points-redeem {
    padding: 20px;
  }
  .h2 {
    font-size: 18px;
  }
  .h2.black.regular {
    line-height: 24px;  
  }
  .h2.black {
    margin-bottom: 10px;
    font-size: 18px;
  }
  .text-block-102 {
    margin-right: 0px;
    font-size: 14px;
  }
  .text-block-67 {
    margin-bottom: 0px;
    font-size: 14px;
  }
  .div-block-596 {
    height: 45px;
  }
  .div-block-545 {
    margin-left: -20px;
    padding: 15px 27px;
  }
  .section.cms {
    padding-right: 0px;
    padding-left: 0px;
  }
}
`;
