import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import { animateScroll } from 'react-scroll';
import { prefix } from '.';
import { setScreenColor, getAppTheme, getIntl, getIntlByPath } from '@/components/App/App';
import Icon, { IconType } from '@/components/Icon';
import Layout from '@/components/Layout';
import ModifierListing from './ModifierListing';
import ModifierListView from './ModifierListView';
import { scalePx } from '@/utils';
import ActionType from '@/actions/action-type';
import { IModifier, IListing } from '@/lib/order-builder/utils/api';
import paths from '@/routes/paths';
import { setCanAutoProcurementMethodPopup } from '../MenuListPage/MenuListPage';
import { getCurrentShippingMethod } from '@/utils/app';
import { IStoreConfig, IStorePickupLocation } from '@/actions/store-action';
import { IShoppingCartData } from '@/actions/shopping-cart-action';
import Button from 'antd-mobile/es/button';
import Modal from 'antd-mobile/es/modal';
import dayjs from 'dayjs';
import ShippingMethod from '@/lib/order-builder/enums/ShippingMethod';

export interface IModifierPageProps extends IBasePageProps { }

export interface IModifierPageState {
  modifiers: IModifier[];
  listing: IListing | IModifier | undefined;
  countRef: { [modifierID: string]: number };
  selected: string;
  storeConfig?: IStoreConfig;
  shoppingCartData?: IShoppingCartData;
  orderNumber?: string;
  quantity: number;
  storeSlug: string;
}

class ModifierPage extends BasePage<IModifierPageProps, IModifierPageState> {

  state: IModifierPageState = {
    modifiers: [],
    listing: undefined,
    countRef: {},
    selected: '',
    orderNumber: '',
    quantity: 1,
    storeSlug: '',
  }

  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.lightBackgroundColor) {
      setScreenColor(appTheme.lightBackgroundColor);
    }

    super.componentDidMount();

    // const routeParams = parseRouteParams(this.props);
    const { routeParams, dispatch } = this.props;
    const { modifierIDs, modifierIDsStr } = routeParams;
    this.setState({
      orderNumber: routeParams.orderNumber,
      storeSlug: routeParams.storeSlug,
    });

    // const modifierIDsStr: string | undefined = routeParams.modifierIDs || undefined;
    let modifierID: string | undefined = undefined;

    // let modifierIDs: string[] = [];
    // if (modifierIDsStr && modifierIDsStr.length > 0) {
    //   modifierIDs = modifierIDsStr.split('-');
    // }

    if (modifierIDs.length > 0) {
      modifierID = modifierIDs[modifierIDs.length - 1];
    }
    this.openLoading();
    dispatch({
      type: ActionType.QUERY_MODIFIERS,
      storeSlug: routeParams.storeSlug,
      listingID: routeParams.listingID,
      modifierID,
      callback: (modifiers, listing) => {
        this.setState({ modifiers, listing });
        this.closeLoading();
      }
    });

    dispatch({
      type: ActionType.GET_PRE_ADD_MODIFIERS_COUNT,
      listingID: routeParams.listingID,
      path: modifierIDsStr,
      callback: countRef => {
        this.setState({ countRef });
      }
    });
    dispatch({
      type: ActionType.GET_STORE_SHOPPING_CART_DATA,
      storeSlug: routeParams.storeSlug,
      callback: params => {
        this.setState({ shoppingCartData: params.shoppingCartData, storeConfig: params.storeConfig });
      },
    });
  }

  goBack = () => {
    const { orderNumber } = this.state;
    setCanAutoProcurementMethodPopup(false);
    this.handleGoBack({
      path: orderNumber ? paths.QRCODE_SHOPPING_MENUS : paths.MENU_LIST,
      runGoBack: true,
    })
  }

  handleLeftClick = () => {
    // const routeParams = parseRouteParams(this.props);
    const { routeParams, dispatch } = this.props;

    // const modifierIDsStr: string | undefined = routeParams.modifierIDs || undefined;
    // let modifierIDs: string[] = [];
    // if (modifierIDsStr && modifierIDsStr.length > 0) {
    //   modifierIDs = modifierIDsStr.split('-');
    // }

    if (routeParams.modifierIDs.length === 0) {
      dispatch({
        type: ActionType.CLEAR_PRE_ADD_MODIFIERS,
      });
    }

    this.goBack();
  }

  handleClick = (data: IModifier) => {
    const modifierCon = document.getElementById(`bm-modifier-panel-${data.id}`);
    const modifierHeader = document.getElementById(`bm-modifier-header-${data.id}`);
    if (modifierCon && modifierHeader) {
      if (modifierCon.style.display === 'none') {
        modifierCon.style.display = 'block';
        modifierHeader.getElementsByClassName('bm-icon')[0].classList.remove('select-icon-hide');
      } else {
        modifierCon.style.display = 'none';
        modifierHeader.getElementsByClassName('bm-icon')[0].classList.add('select-icon-hide');
      }
    }
  }
  handleModifierClick = (listing: IModifier, data: IListing | IModifier, action: 'PLUS' | 'MINUS' | 'OPTIONS' | 'SINGLE') => {
    if (!data || !action || data.soldOut) return;

    const { countRef, orderNumber } = this.state;
    const count = countRef[data.id] || 0;

    if (action === 'OPTIONS') {
      // const routeParams = parseRouteParams(this.props);
      const { routeParams, dispatch } = this.props;
      const { modifierIDs, modifierIDsStr } = routeParams;
      dispatch({
        type: ActionType.SET_PRE_ADD_MODIFIERS,
        listingID: routeParams.listingID,
        path: modifierIDsStr,
        modifierCountRef: this.state.countRef,
      });

      // let modifierIDs: string = routeParams.modifierIDs || '';
      let newModifierIDs = modifierIDsStr;
      if (modifierIDsStr && modifierIDsStr.length > 0) {
        newModifierIDs = `${modifierIDsStr}-${data.id}`
      } else {
        newModifierIDs = data.id;
      }
      // const modifierCount = String(modifierIDs).split('-').length;
      let subModifierPath = modifierIDs.length % 2 === 1 ? paths.SUB_MODIFIER : paths.SUB_MODIFIER_B;
      if (orderNumber) {
        subModifierPath = modifierIDs.length % 2 === 1 ? paths.QRCODE_SHOPPING_SUB_MODIFIER : paths.QRCODE_SHOPPING_SUB_MODIFIER_B;
      }
      this.handleGotoRoute({
        path: subModifierPath,
        pathParams: {
          listingID: routeParams.listingID,
          modifierIDs: newModifierIDs,
        },
      })
    } else if (action === 'PLUS') {
      this.setState({
        countRef: {
          ...countRef,
          [data.id]: count + 1,
        }
      })
    } else if (action === 'MINUS') {
      if (count > 0) {
        const newCountRef = {
          ...countRef,
          [data.id]: count - 1,
        };

        this.setState({
          countRef: newCountRef
        });
      }
    } else if (action === 'SINGLE') {
      const selectedNumber = this.getSelectedNumber();
      if (count > 0) {
        delete countRef[data.id];
      } else {
        if (selectedNumber[listing.id] === 1 && selectedNumber[listing.id] === listing.chooseUpTo) {
          listing.children.forEach(item => {
            delete countRef[item.id]
          })
          countRef[data.id] = 1;
        }
        if (!selectedNumber[listing.id] || selectedNumber[listing.id] < listing.chooseUpTo) {
          countRef[data.id] = 1;
        }

      }

      this.setState({
        countRef: {
          ...countRef,
        }
      });
    }
  }
  addModifierSuccess = () => {
    const { orderNumber } = this.state;
    const { routeParams } = this.props;
    if (orderNumber) {
      this.handleGoBack({
        path: paths.QRCODE_SHOPPING_MENUS,
        runGoBack: true,
        pathParams: {
          storeSlug: routeParams.storeSlug,
          orderNumber: routeParams.orderNumber,
        },
      });
    } else {
      this.handleGoBack({
        runGoBack: true,
        path: paths.MENU_LIST,
        pathParams: { storeSlug: routeParams.storeSlug },
      });
    }
  }
  handleConfirm = () => {
    const { modifiers } = this.state;
    const { shoppingCartData, storeConfig, quantity } = this.state;
    const { orderInfo } = shoppingCartData || {};
    const { routeParams, dispatch, reducersState } = this.props;
    const { modifierIDs, modifierIDsStr, storeSlug } = routeParams;
    const { slugRefStorePickupLocations } = reducersState.store;
    const pickupLocations = slugRefStorePickupLocations[storeSlug] || [];
    const select = this.getSelectedNumber();
    for (let i = 0; i < modifiers.length; i++) {
      const modifier = modifiers[i];
      const modifierItem = document.getElementById(`bm-modifier-item-panel-${modifier.id}`);
      if (select[modifier.id] < modifier.chooseAtLeast || (Object.keys(select).length === 0 && modifier.chooseAtLeast !== 0) || (!select[modifier.id] && modifier.chooseAtLeast !== 0)) {
        if (modifierItem) {
          const pageWrap = window.document.querySelector('.page-content-wrapper-web');
          if (pageWrap) {
            pageWrap.scrollTo(0, modifierItem.offsetTop - scalePx(88))
          } else {
            animateScroll.scrollTo(modifierItem.offsetTop - scalePx(88), {
              duration: 200,
            });
          }
        }
        return;
      }
    }
    dispatch({
      type: ActionType.LOADING,
      showLoading: true,
      showLoadingBgc: true,
    });
    // const routeParams = parseRouteParams(this.props);
    // const modifierIDsStr: string | undefined = routeParams.modifierIDs || undefined;

    // let modifierIDs: string[] = [];
    // if (modifierIDsStr && modifierIDsStr.length > 0) {
    //   modifierIDs = modifierIDsStr.split('-');
    // }
    if (modifierIDs.length > 0) { // modifier子集
      dispatch({
        type: ActionType.SET_PRE_ADD_MODIFIERS,
        listingID: routeParams.listingID,
        path: modifierIDsStr,
        modifierCountRef: this.state.countRef,
      });
      dispatch({
        type: ActionType.LOADING,
        showLoading: false,
      })
      this.goBack();
    } else {
      dispatch({
        type: ActionType.SET_PRE_ADD_MODIFIERS,
        listingID: routeParams.listingID,
        modifierCountRef: this.state.countRef,
      });
      const currentShippingMethod = getCurrentShippingMethod(orderInfo, storeConfig);
      let date = dayjs();
      if (storeConfig) {
        date = date.add(storeConfig.delayedDeliveryTime, 'day');
      }
      let pickupLocation: IStorePickupLocation | undefined = undefined;
      let pickupLocationID: string | undefined = undefined;
      if (shoppingCartData && shoppingCartData.orderData.pickup_location) {
        pickupLocation = shoppingCartData.orderData.pickup_location;
        pickupLocationID = shoppingCartData.orderData.pickup_location_id;
      } else if (pickupLocations && pickupLocations.length > 0) {
        pickupLocation = pickupLocations[0];
        pickupLocationID = pickupLocations[0].id;
      }
      dispatch({
        type: ActionType.PUSH_LISTING_TO_SHOPPING_CART,
        storeSlug: routeParams.storeSlug,
        listingID: routeParams.listingID,
        modifierCountRef: this.state.countRef,
        shippingMethod: currentShippingMethod,
        quantity,
        pickupLocation,
        pickupLocationID,
        deliveryDate: orderInfo && orderInfo.deliveryDate ? dayjs(orderInfo.deliveryDate) : (currentShippingMethod === ShippingMethod.PICK_UP ||  currentShippingMethod === ShippingMethod.DELIVERY ? date : undefined),
        callback: (shoppingCartData, storeIDs) => {
          dispatch({
            type: ActionType.LOADING,
            showLoading: false,
          })
          if (storeIDs && storeIDs.length > 0) {
            Modal.alert('', getIntl().page.differentShippingMethodTips, [
              {
                text: getIntl().common.cancel,
                onPress: () => { },
              },
              {
                text: getIntl().common.continue,
                onPress: () => {
                  dispatch({
                    type: ActionType.CLEAR_STORES_SHOPPING_CART,
                    storeIDs: storeIDs,
                  });
                  dispatch({
                    type: ActionType.PUSH_LISTING_TO_SHOPPING_CART,
                    storeSlug: routeParams.storeSlug,
                    listingID: routeParams.listingID,
                    modifierCountRef: this.state.countRef,
                    shippingMethod: currentShippingMethod,
                    quantity,
                    clearDifferentData: true,
                    pickupLocation,
                    pickupLocationID,
                    deliveryDate: orderInfo && orderInfo.deliveryDate ? dayjs(orderInfo.deliveryDate) : (currentShippingMethod === ShippingMethod.PICK_UP ||  currentShippingMethod === ShippingMethod.DELIVERY ? date : undefined),
                    callback: shoppingCartData => {
                      if (shoppingCartData) {
                        this.addModifierSuccess();
                      } else {
                        // TODO
                        console.info('加入购物车失败');
                      }
                    }
                  })
                },
              }
            ]);
          } else {
            if (shoppingCartData) {
              this.addModifierSuccess();
            } else {
              // TODO
              console.info('加入购物车失败');
            }
          }
        }
      });
    }
  }

  getSelectedNumber = () => {
    const { modifiers, countRef } = this.state;
    const selectedNumber: { [modifierID: string]: number } = {};

    modifiers.forEach(item => {
      let num = 0;
      if (Array.isArray(item.children)) {
        item.children.forEach(cItem => {
          if (countRef[cItem.id] && countRef[cItem.id] > 0) {
            num += countRef[cItem.id];
          }
        })
      }

      if (num > 0) {
        selectedNumber[item.id] = num;
      }
    })

    return selectedNumber;
  }

  handleChangeQuantity = (action: 'PLUS' | 'MINUS') => {
    const { quantity } = this.state;
    if (action === 'PLUS') {
      this.setState({
        quantity: quantity + 1,
      })
    } else {
      if (quantity === 1) return;
      this.setState({
        quantity: quantity - 1,
      })
    }

  }

  render() {
    const { modifiers, listing, countRef, quantity } = this.state;
    const { reducersState, routeParams } = this.props;
    const primaryModifier = routeParams.routePath === paths.MODIFIER || routeParams.routePath === paths.QRCODE_SHOPPING_MODIFIER;
    const { slugRefStore } = reducersState.store;
    const store = slugRefStore[routeParams.storeSlug] || {};
    return (
      <Layout
        {...this.props}
        className={prefix}
        navIcon={<Icon className="back-icon" type={IconType.LEFT_OUTLINED} />}
        onLeftClick={this.handleLeftClick}
      >
        <ModifierListing
          data={listing}
          reducersState={reducersState}
          currencyCode={store && store.currencyCode}
        />
        <div className={`modifier-content ${primaryModifier ? 'primary-modifier-content' : ''}`}>
          <ModifierListView
            data={modifiers}
            countRef={countRef}
            selectedNumber={this.getSelectedNumber()}
            onItemClick={this.handleClick}
            onModifierClick={this.handleModifierClick}
            reducersState={reducersState}
            currencyCode={store && store.currencyCode}
          />
        </div>
        <div className="listing-action-wrap">
          {
            primaryModifier &&
            <div className="item-action">
              <Icon
                className={`item-minus ${quantity === 1 ? 'disable-minus' : ''}`}
                type={IconType.MINUS_CIRCLE_OUTLINED}
                onClick={() => this.handleChangeQuantity('MINUS')}
              />
              <div className="count">
                {quantity}
              </div>
              <Icon
                className="item-plus"
                type={IconType.PLUS_CIRCLE_OUTLINED}
                onClick={() => this.handleChangeQuantity('PLUS')}
              />
            </div>
          }
          <Button type="primary" className="add-to-cart" onClick={this.handleConfirm}>
            {getIntlByPath(`${primaryModifier ? 'page.addShoppingCart' : 'page.ok'}`)}
          </Button>
        </div>
      </Layout>
    );
  }
}

export default ModifierPage;
