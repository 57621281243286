enum PurchasableType {
  LISTING = 'Listing',
  DISCOUNT = 'Discount',
  VOUCHER_DISCOUNT =  'VoucherDiscount',
  TAX =  'Tax',
  DELIVERY = 'Delivery',
  CHARGE = 'Charge',
  STORE_CREDIT = 'StoreCredit',
  LOYALTY_REDEEM = 'LoyaltyRedeem',
  GIFT_CARD = 'GiftCard',
  OCTOPUS = 'OctopusValue',
  SERVICE_FEE = 'ServiceFee',
  TIPS = 'Tips',
  ROUNDING = 'Rounding',
  DEPOSIT = 'Deposit',
  DEPOSIT_REDEEM = 'DepositRedeem',
  DEPOSIT_FORFEIT = 'DepositForfeit',
};

export default PurchasableType;
