import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IDispatch } from '@/actions';
import { IReducersState } from '@/reducers';
import ActionType from '@/actions/action-type';
import { IObject } from '@/components/bm-common';
import { goToRoute, goBack } from '@/utils/route-util';
import { createRouteUrl, IRouteParams } from '@/utils';
import paths from '@/routes/paths';
import { TransitionKeys } from '@/components/Transition';

export interface IBasePageProps extends RouteComponentProps {
  dispatch: IDispatch;
  reducersState: IReducersState;
  routeParams: IRouteParams;
}

interface IHandleGotoRouteParams {
  path: string;
  pathParams?: IObject;
  clearPaths?: boolean;
  historyState?: IObject;
  animationType?: TransitionKeys;
  search?: string;
}

interface IHandleGoBackParams extends IHandleGotoRouteParams {
  paths?: string[];
  runGoBack?: boolean;
}

class BasePage<P extends IBasePageProps, S = {}, SS = {}> extends React.Component<P, S, SS> {
  pathname = '';
  autoCloseLoading = true;

  componentDidMount() {
    this.autoCloseLoading = true;
    const { pathname } = window.location;
    this.pathname = pathname || '';

    this.hidePageLoading();

    if (this.autoCloseLoading) {
      setTimeout(() => {
        if (this.autoCloseLoading) {
          this.closeLoading();
        }
      }, 5000);
    }
  }

  componentWillUnmount() {
    this.autoCloseLoading = false;
    // pageRecoverableData[this.pathname] = {
    //   scrollY: window.scrollY,
    // };
  }

  hidePageLoading = () => {
    const { reducersState, dispatch } = this.props;

    if (reducersState.app.showPageLoading) {
      dispatch({
        type: ActionType.PAGE_LOADING,
        showPageLoading: false,
      })
    }
  }

  handleGoHome = (clearPaths = false) => {
    this.handleGotoRoute({
      path: paths.HOME,
      clearPaths,
    });
  }

  handleGotoRoute = (params: IHandleGotoRouteParams) => {
    const { history } = this.props;
    const {
      path,
      pathParams,
      clearPaths = false,
      historyState,
      animationType,
      search,
    } = params;
    goToRoute({
      history,
      pathname: createRouteUrl(path, pathParams, this.props),
      clearPaths,
      state: historyState,
      animationType,
      search: search,
    });
  }

  handleGoBack = (params: IHandleGoBackParams) => {
    const { history } = this.props;
    const {
      path,
      pathParams,
      clearPaths = false,
      historyState,
      paths,
      runGoBack,
      animationType,
      search,
    } = params;
    goBack({
      history,
      pathname: createRouteUrl(path, pathParams, this.props),
      clearPaths,
      state: historyState,
      paths,
      runGoBack,
      animationType,
      search,
    });
  }

  openLoading = () => {
    const { dispatch } = this.props;
    dispatch({
      type: ActionType.LOADING,
      showLoading: true,
    });
  }

  closeLoading = () => {
    const { dispatch } = this.props;
    dispatch({
      type: ActionType.LOADING,
      showLoading: false,
    });
  }
}

export default BasePage;
