import React from 'react';
import InputItem from 'antd-mobile/es/input-item';
import TextareaItem from 'antd-mobile/es/textarea-item';
import Modal from 'antd-mobile/es/modal';
import dayjs from 'dayjs';
import Constants from '@/constants';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import Icon, { IconType } from '@/components/Icon';
import { parseRouteParams } from '@/utils';
import { IDialCodeGroupItem } from '@/components/DialCodeGroup';
import SignUserName from '@/components/common/userComponents/NewUserName';
import { getAppTheme, setScreenColor, getIntl } from '@/components/App/App';
import ActionType from '@/actions/action-type';
import { ICustomer } from '@/actions/user-action';
import paths from '@/routes/paths';
import {
  ITable,
  IWeekBooking,
  ITimeSegmentsQuota,
  IBookingSetting,
  IBooking,
  INumberQuota,
} from '@/actions/booking-action';
import Adult from './Adult';
import Child from './Child';
import ChooseDate from './Date';
import Time, { ITimeTimeSegmentID } from './Time';
import { prefix } from '.';
import { popPath } from '@/utils/route-util';
import { setCanAutoProcurementMethodPopup } from '@/pages/MenuListPage/MenuListPage';
import { getI18nText } from '@/utils/app';
import Layout from '@/components/Layout';
import { IObject } from '@/components/bm-common';

export interface IBookingPageProps extends IBasePageProps {}
export interface IBookingPageState {
  phoneNumber: string;
  areaCodeData: IDialCodeGroupItem | undefined;
  showCountDown: boolean;
  customer: ICustomer | undefined;
  areaCode: string;
  name: string;
  email: string;
  note: string | undefined;
  tables: ITable[];
  useTimeMap: IWeekBooking | undefined;
  timeSegmentsQuotas: ITimeSegmentsQuota[];
  bookingSetting: IBookingSetting | undefined;
  adult: number;
  child: number;
  maxPeople: number;
  date: string;
  week: string;
  time: string;
  timeSegmentIDs: string[];
  timeSegmentID: string;
  tableTypeID: string;
  duration: number;
  emailError: boolean;
  phoneError: boolean;
  dateBookings: IBooking[];
  showModal: boolean;
  isCurrentDay: boolean;
  numberQuota: INumberQuota[];
}

interface PhoneChang {
  phone?: string;
  areaCodeData?: IDialCodeGroupItem;
}

class BookingPage extends BasePage<IBookingPageProps, IBookingPageState> {
  state: IBookingPageState = {
    phoneNumber: '',
    areaCodeData: undefined,
    showCountDown: false,
    customer: undefined,
    areaCode: '852',
    name: '',
    email: '',
    note: '',
    tables: [],
    useTimeMap: undefined,
    timeSegmentsQuotas: [],
    bookingSetting: undefined,
    adult: 0,
    child: 0,
    maxPeople: 0,
    date: '',
    week: '',
    timeSegmentIDs: [],
    time: '',
    timeSegmentID: '',
    tableTypeID: '',
    duration: 0,
    emailError: true,
    phoneError: true,
    dateBookings: [],
    showModal: false,
    isCurrentDay: false,
    numberQuota: [],
  }
  

  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.lightBackgroundColor) {
      setScreenColor(appTheme.lightBackgroundColor);
    }
    super.componentDidMount();
    const { dispatch, reducersState } = this.props;
    const { platformConfig } = reducersState.app;
    const { closeRegisterEmail } = platformConfig;

    const routeParams = parseRouteParams(this.props);
    const { userInfo } = reducersState.user;
    dispatch({
      type: ActionType.LOADING,
      showLoading: true,
    })
    
    dispatch({
      type: ActionType.QUERY_BOOKING_SETTING,
      storeSlug: routeParams.storeSlug,
      callback: (tables, useTimeMap, timeSegmentsQuotas, bookingSetting, numberQuota) => {
        let max = 0;
        tables.forEach(item => {
          if (item.max > max) {
            max=item.max
          }
        })

        if (userInfo && localStorage.getItem(Constants.IS_GUEST) === 'false') {
          dispatch({
            type: ActionType.QUERY_CUSTOMER,
            storeSlug: routeParams.storeSlug,
            closeRegisterEmail,
            callback: customer => {
              let areaCode = '';
              let name = '';
              let phoneNumber = '';
              let email = '';
              
              if (customer) {
                name = customer.name || '';
                email = customer.email || '';
                phoneNumber = customer.phone || '';
                if (customer.phone) {
                  const phones = customer.phone.split(' ');
                  if (phones.length > 1) {
                    areaCode = phones[0].replace('+', '');
                    phoneNumber = phones[1]
                  }
                }
              }
              phoneNumber = this.numberArrange(phoneNumber, ' ', 4);
              this.handleEmailChang(email);
              this.setState({
                customer: customer,
                name,
                phoneNumber,
                areaCode,
                tables,
                useTimeMap,
                timeSegmentsQuotas,
                bookingSetting,
                maxPeople: max,
                numberQuota: numberQuota,
              });
            }
          })
        } else {
          this.setState({
            tables,
            useTimeMap,
            timeSegmentsQuotas,
            bookingSetting,
            maxPeople: max,
            numberQuota: numberQuota,
          })
        }
        if (tables.length === 0 && Object.keys(useTimeMap).length === 0 && timeSegmentsQuotas.length === 0 && (bookingSetting && !bookingSetting.id)) {
          Modal.alert(
            getIntl().common.tips,
            getIntl().page.noOpenBooking,
            [
              {
                text: getIntl().common.confirm,
                onPress: () => this.handleGoBack({
                  path: paths.MENU_LIST,
                  runGoBack: true,
                }),
              }
            ]
          );
        }
        if (useTimeMap && bookingSetting && bookingSetting.id) {
          this.handleClickPeople(1, 0);
        }
        let date = dayjs().add(0, 'day');
        if (bookingSetting && bookingSetting.minimumStartBookingTimeType === '1') {
          date = dayjs().add(Number(bookingSetting.minimumStartBookingTime), 'minute');
        } 
        if (bookingSetting && bookingSetting.minimumStartBookingTimeType === '2') {
          date = dayjs().add(Number(bookingSetting.minimumStartBookingTime), 'hour');
        }
        const week = date.format('d');
        let showDate = dayjs().add(1, 'day');
        const { tableTypeID } = this.state;
        if (dayjs().format('d') !== week) {
          showDate = dayjs().add(1, 'day');
        } else if (useTimeMap[week]) {
          const times: ITimeTimeSegmentID[] = [];
          useTimeMap[week].forEach(item => {
            const selectDay = dayjs().format('YYYY-MM-DD');
            let start = dayjs(selectDay + item.start);
            const end = dayjs(selectDay + item.end);
            const now = date;
            while (start.isBefore(end)) {
              if (now.date() === start.date()) {
                if (start.isAfter(now)) {
                  times.push({
                    time: start.format('HH:mm'),
                    timeSegmentID: item.timeSegmentID,
                    tableTypeID,
                    title: item.title,
                    i18n: item.i18n,
                  });
                }
              } else {
                times.push({
                  time: start.format('HH:mm'),
                  timeSegmentID: item.timeSegmentID,
                  tableTypeID,
                  title: item.title,
                  i18n: item.i18n,
                });
              }
              if (bookingSetting) {
                start = start.add(parseInt(bookingSetting.minuteIntervalType), 'm');
              }
            }
          })
          if (times.length > 0) {
            showDate = dayjs().add(0, 'day'); // 当天
            this.setState({
              isCurrentDay: true,
            })
          } else {
            this.setState({
              isCurrentDay: false,
            })
            showDate = dayjs().add(1, 'day'); // 隔天
          }
        }
        if (bookingSetting && bookingSetting.minimumStartBookingTimeType === '3') {
          showDate = dayjs().add(Number(bookingSetting.minimumStartBookingTime), 'day');
        }
        this.onClickDate(showDate.format('YYYY-MM-DD'), showDate.format('d'));
      }
    })
  }

  numberArrange = (number: string, symbol = '-', interval = 4) => {
    let newnumber = '';
    let count = 0;
    if (number) {
      if (number.length % interval === 0) {
        count = number.length / interval;
      } else {
        count = number.length / interval + 1;
      }
      for (let index = 0; index < count; index++) {
        if ((index + 1) * interval < number.length) {
          newnumber = `${newnumber + number.slice(index * interval, (index + 1) * interval)  }${symbol}`;
        } else {
          newnumber += number.slice(index * interval, number.length);
        }
      }
    }
    
    return newnumber;
  };

  onClickConfirm = () => {
    const {
      phoneNumber,
      areaCodeData,
      email,
      name,
      adult,
      child,
      date,
      time,
      emailError,
      phoneError,
      note,
      duration,
      bookingSetting,
    } = this.state;

    const {
      dispatch,
      reducersState,
    } = this.props
    const { userInfo } = reducersState.user;

    dispatch({
      type: ActionType.LOADING,
      showLoading: true,
    })

    const routeParams = parseRouteParams(this.props);
    const dialCode = areaCodeData ? areaCodeData.dialCode : '';


    if (name && phoneNumber && email && dialCode && !emailError && !phoneError) {
      dispatch({
        type: ActionType.CREATE_BOOKING,
        storeSlug: routeParams.storeSlug,
        cover: adult + child,
        userInfo,
        name,
        email,
        phone: phoneNumber.replace(/ /g, ''),
        dialCode,
        date: dayjs(date+ time).format('YYYY-MM-DDTHH:mm:ssZ'),
        note: note || '',
        duration,
        callback: booking => {
          if (booking && booking.id) {
            popPath();
            this.handleGotoRoute({
              path: paths.BOOKING_DETAIL,
              pathParams: { 
                bookingID: booking.id,
                confirmationMode: bookingSetting && bookingSetting.confirmationMode
              },
            })
          } else {
            if (localStorage.getItem(Constants.IS_GUEST) === 'true') {
              dispatch({
                type: ActionType.CLEAR_USER_INFO,
              });
            }
            Modal.alert(
              getIntl().common.tips,
              getIntl().page.bookingFull
            );
          }
          dispatch({
            type: ActionType.LOADING,
            showLoading: false,
          })
        }
      })
    } else {
      dispatch({
        type: ActionType.LOADING,
        showLoading: false,
      })
    }
  }

  handleClickPeople = (adult: number, child: number) => {
    const {
      tables,
    } = this.state
    let timeSegmentIDs: string[] = [];
    let tableTypeID = '';
    const people = adult + child;
    
    tables.forEach(item => {
      if (people <= item.max && people >= item.min) {
        timeSegmentIDs = item.timeSegmentIDs;
        tableTypeID = item.tableTypeID;
      }
    })
    this.setState({
      adult,
      child,
      timeSegmentIDs,
      tableTypeID,
    })
  }

  onClickDate = (date: string, week: string) => {
    const {
      adult,
      child,
    } = this.state;

    const routeParams = parseRouteParams(this.props);
    const {dispatch} = this.props;
    dispatch({
      type: ActionType.QUERY_BOOKINGS_FOR_DATE,
      formula: `GTE("$.self.reservation_time", "${dayjs(date).toISOString()}").LTE("$.self.reservation_time", "${dayjs(date).add(1, 'day').toISOString()}").NOT_IN("$.self.status", ["no_show", "cancelled"])`,
      storeSlug: routeParams.storeSlug,
      callback: bookings => {
        this.setState({
          adult,
          child,
          date,
          week,
          dateBookings: bookings,
        })
      }
    })
  }

  onClickTime = (time: string, timeSegmentID: string, duration: number) => {
    this.setState({
      time,
      timeSegmentID,
      duration,
    })
  }

  handleEmailChang = (value: string) => {
    const reg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
    const veriftyRes = reg.test(value);

    this.setState({
      email: value,
      emailError: !veriftyRes,
    })
  }

  handlePhoneChang = (data: PhoneChang) => {
    const {
      areaCode,
      phoneNumber,
      areaCodeData,
    } = this.state;

    const newPhone = data.phone || data.phone === '' ? data.phone : phoneNumber;
    const newValue = newPhone.replace(/\s*/g, '');
    const newAreaCodeData = data.areaCodeData ? data.areaCodeData : areaCodeData;
    const newAreaCode = data.areaCodeData ? data.areaCodeData.dialCode : areaCode;
    let phoneError = false;

    if (newAreaCode === '86') {
      phoneError = newValue.length !== 11
    }
    if (newAreaCode === '852') {
      phoneError = newValue.length !== 8
    }

    this.setState({
      phoneNumber: newPhone,
      phoneError: phoneError,
      areaCodeData: newAreaCodeData,
      areaCode: newAreaCode,
    })
  }

  render() {
    const {
      routeParams,
      reducersState,
    } = this.props;

    const {
      phoneNumber,
      name,
      email,
      areaCode,
      maxPeople,
      adult,
      child,
      bookingSetting,
      date,
      useTimeMap,
      week,
      timeSegmentIDs,
      time,
      emailError,
      phoneError,
      areaCodeData,
      timeSegmentsQuotas,
      tableTypeID,
      dateBookings,
      tables,
      showModal,
      isCurrentDay,
      numberQuota,
    } = this.state;

    const { language } = reducersState.app;
    const { slugRefStoreConfig } = reducersState.store;
    const storeConfig = slugRefStoreConfig[routeParams.storeSlug];
    
    const layoutProps: IObject = {
      className: prefix,
      navContent: getIntl().page.booking,
    };

    if (routeParams.mode !== 'dedicated') {
      layoutProps.navIcon=<Icon className="html-embed-7 w-embed" type={IconType.LEFT_OUTLINED} />;
      layoutProps.onLeftClick= () => {
        setCanAutoProcurementMethodPopup(false);
        this.handleGoBack({
          path: paths.MENU_LIST,
          runGoBack: true,
        })
      }
    }


    return (
      <Layout
        {...this.props}
        {...layoutProps}
        isFooter={false}
      >
        {
          useTimeMap && bookingSetting && bookingSetting.id && date &&
          <div className="section booking">
            <div className="container booking w-container">
              <h4 className="heading-4-2">
                {getIntl().page.coverNo}
              </h4>
              <Adult
                max={maxPeople}
                adult={adult}
                child={child}
                onClick={this.handleClickPeople}
              />
            </div>
            {
              false &&
              <div className="container booking w-container">
                <h4 className="heading-4-2">
                  {getIntl().page.child}
                </h4>
                <Child
                  max={maxPeople}
                  adult={adult}
                  child={child}
                  onClick={this.handleClickPeople}
                />
              </div>
            }
            <div className="container booking w-container">
              <h4 className="heading-4-2">
                {getIntl().page.chooseDate}
              </h4>
              <ChooseDate isCurrentDay={isCurrentDay} day={bookingSetting.bookingDay} minimumStartBookingTime={bookingSetting.minimumStartBookingTime} minimumStartBookingTimeType={bookingSetting.minimumStartBookingTimeType} select={date} onClick={this.onClickDate} weekBooking={useTimeMap} language={language} />
            </div>
            <div className="container booking w-container">
              <div className="is-aviailble">
                <h4 className="heading-4-2">
                  {getIntl().page.chooseTime}
                </h4>
                <div className="booking-is-aviailble"><div><Icon type={IconType.UN_SELECTED_SQUARE_OUTLINED} className="aviailble" /><div>{getIntl().page.available}</div></div><div><Icon type={IconType.UN_SELECTED_SQUARE_OUTLINED} className="unaviailble" /><div>{getIntl().page.unavailable}</div></div></div>
              </div>
              <Time
                week={week}
                select={time}
                selectDay={date}
                weekBooking={useTimeMap}
                timeSegmentIDs={timeSegmentIDs}
                minuteIntervalType={bookingSetting.minuteIntervalType}
                minimumStartBookingTime={bookingSetting.minimumStartBookingTime}
                minimumStartBookingTimeType={bookingSetting.minimumStartBookingTimeType}
                timeSegmentsQuotas={timeSegmentsQuotas}
                tableTypeID={tableTypeID}
                bookings={dateBookings}
                tables={tables}
                onClick={this.onClickTime}
                reducersState={reducersState}
                numberQuota={numberQuota}
                peopleNumber={adult + child}
              />
            </div>
            <div className="container booking no-line w-container">
              <h4 className="heading-4-2">
                {getIntl().page.contactDetail}
              </h4>
              <div className="div-block-103">
                <div className="form-block-2 w-form">
                  <form className="form-2">
                    <InputItem
                      className="text-field w-input"
                      placeholder={getIntl().page.namePlaceholder}
                      value={name}
                      onChange={value => this.setState({name: value})}
                    />
                    <SignUserName
                      language={language}
                      value={phoneNumber}
                      onChange={value => this.handlePhoneChang({phone: value})}
                      onItemClick={(data: IDialCodeGroupItem) => this.handlePhoneChang({areaCodeData: data})}
                      showIcon={true}
                      type="PHONE"
                      areaCodeData={areaCodeData || {areaCodes: null, dialCode: areaCode, iso2: '', name: '', priority: 0}}
                      areaCode={areaCode}
                    />
                    <InputItem
                      placeholder={getIntl().page.signInEmailPlacehodler}
                      value={email}
                      error={emailError}
                      onChange={value => this.handleEmailChang(value)}
                      className="text-field w-input"
                    />
                    <h4 className="heading-4-2">{getIntl().page.note}</h4>
                    <TextareaItem
                      placeholder={getIntl().page.notePlaceholder}
                      rows={3}
                      className="textarea w-input"
                      // className="TextareaItem"
                      onChange={value => this.setState({note: value})}
                    />
                  </form>
                </div>
              </div>
            </div>
            <div className="container booking no-line w-container">
              <div className={`submit-button-booking open-modal ${adult + child > 0 && date && time && name && !phoneError && !emailError ? '': 'disabled'}`}
                onClick={() => {
                  if (adult + child > 0 && date && time && name && !phoneError && !emailError) {
                    if (storeConfig && storeConfig.bookingFee) {
                      this.setState({showModal: true})
                    } else {
                      this.onClickConfirm()
                    }
                  }
                }}
              >
                <div className="submit-text">
                  {getIntl().common.confirm}
                </div>
              </div>
            </div>
          </div>
        }
        <Modal
          className="modal-body-left"
          transparent
          visible={showModal}
          title={getIntl().page.depositeRequired}
          footer={
            [
              {
                text: getIntl().page.ok,
                onPress: () => {this.setState({showModal: false}); this.onClickConfirm();},
              }
            ]
          }
        >
          {getI18nText(storeConfig, 'booking_tips', reducersState.app.language)}
        </Modal>
      </Layout>
    );
  }
}

export default BookingPage;
