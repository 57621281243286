import { RouteComponentProps } from 'react-router-dom';
import withRoute from '@/pages/withRoute';
import SelectSignInPage from './SelectSignInPage';

export const prefix = 'bm-p-select-signin';

const RouteComponent: React.FC<RouteComponentProps> = props => withRoute({
  pageComponent: SelectSignInPage,
  routeProps: props,
  pageTitleI18nKey: '',
});
export default RouteComponent;
