import React from 'react';
import { prefix } from '.';

const Skeleton: React.FC = () => (
  <div className={`${prefix}-skeleton`}>
    <div className="skeleton-content">
      <div className="content-box1">
        <div className="content-box2-div1"></div>
        <div></div>
        <div></div>
      </div>
      <div className="content-box2">
        <div></div>
      </div>
      <div className="content-box3">
        <div className="content-box3-left"></div>
        <div className="content-box3-right"></div>
      </div>
      <div className="content-box4">
        <div className="content-box4-div1">
          <div></div>
        </div>
        <div className="content-box4-div2">
          <div className="content-box4-div2-left">
            <div className="content-div1-left"></div>
            <div className="content-div1-right"></div>
          </div>
          <div className="content-box4-div2-right"></div>
        </div>
      </div>
      <div className="content-box5">
        <div className="content-box5-left"></div>
        <div className="content-box5-right"></div>
      </div>
      <div className="content-box5">
        <div className="content-box5-left"></div>
        <div className="content-box5-right"></div>
      </div>
    </div>
  </div>
);

export default Skeleton;
