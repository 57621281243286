import { RouteComponentProps } from 'react-router-dom';
import withRoute from '@/pages/withRoute';
import MyIDPage from './MyIDPage';
// import './MyIDPage.less';

export const prefix = 'bm-p-my-id';

const RouteComponent: React.FC<RouteComponentProps> = props => withRoute({
  pageComponent: MyIDPage,
  routeProps: props,
  pageTitleI18nKey: '',
});
export default RouteComponent;
