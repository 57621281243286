import { RouteComponentProps } from 'react-router-dom';
import withRoute from '@/pages/withRoute';
import FavoritePage from './FavoritePage';

export const prefix = 'bm-p-favorite';

const RouteComponent: React.FC<RouteComponentProps> = props => withRoute({
  pageComponent: FavoritePage,
  routeProps: props,
  pageTitleI18nKey: '',
});
export default RouteComponent;
