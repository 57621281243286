import { prefix } from '.';
import React, { useState, useEffect } from 'react';
import SideNavItem from './SideNavItem';
import { IReducersState } from '@/reducers';
import AccountOption from '@/actions/account-option';
import Icon, { IconType } from '@/components/Icon';
import paths from '@/routes/paths';
import { goToRoute, } from '@/utils/route-util';
import { createRouteUrl } from '@/utils';
import { useHistory } from 'react-router-dom';
import { IObject } from '../bm-common';
import Constants from '@/constants';
import { IDispatch } from '@/actions';
import { useDispatch } from 'react-redux';
import ActionType from '@/actions/action-type';
import { getI18nText, getAccessToken, langFormat } from '@/utils/app';
import { SideNavStyled } from './styled';
import SideNavOption from '@/actions/side-nav-option';
import { getIntl } from '../App/App';

interface SideNavBarProps {
  reducersState: IReducersState;
  sideNavOption: SideNavOption;
}
const SideNavBar: React.FC<SideNavBarProps> = props => {
  const dispatch = useDispatch<IDispatch>();
  const { reducersState, sideNavOption } = props;
  const { platformConfig, language, platformAppSettings } = reducersState.app;
  const { slugRefCustomerGrowth } = reducersState.user;
  const { suppliers }= platformAppSettings || {};
  const { accountOptions, storeSlug, storeID, abbreviation  } = platformConfig;
  const history = useHistory();
  const [membershipLevel, setMembershipLevel] = useState<string>('');
  const [membershipLevelColor, setMembershipLevelColor] = useState<string>('');
  const [selectedCurrentKey, setSelectedKey] = useState<SideNavOption>(sideNavOption);
  const stringUserInfo = localStorage.getItem(Constants.USER_INFO);
  let userInfo = null;
  if (stringUserInfo) {
    userInfo = JSON.parse(stringUserInfo);
  }
  useEffect(() => {
    if (getAccessToken()) {
      dispatch({
        type: ActionType.CANCEL_QUERY_CUSTOMER_GROWTH_TASK_SYNC,
      });
      handleloadMembership();
    }
  }, []);
  const handleloadMembership = () => {
    dispatch({
      type: ActionType.QUERY_CUSTOMER,
      storeSlug,
      callback: customer => {
        if (customer) {
          dispatch({
            type: ActionType.QUERY_CUSTOMER_GROWTH,
            storeSlug,
            storeID,
            customerID: customer.id,
            callback: membershipData => {
              if (membershipData && membershipData.currentMembershipLevel) {
                setMembershipLevel(getI18nText(membershipData.currentMembershipLevel, 'abbreviation', language));
                setMembershipLevelColor(membershipData.currentMembershipLevel.membershipLevelColor)
              }
            }
          });
        }
      }
    });
  }
  const handleItemClick = (
    params: {
      path: string;
      pathParams?: IObject;
      sideNavOption?: SideNavOption;
    }
  ) => { 
    const { path, pathParams } = params;
    setSelectedKey(sideNavOption);
    const pathname = createRouteUrl(
      path,
      pathParams,
    );
    goToRoute({
      history,
      pathname,
      clearPaths: true,
      // animationType: TransitionKeys.NONE,
    });
  }
  const membershipData = slugRefCustomerGrowth[storeSlug];
  let currenMembershipLevel = '';
  let currentMembershipLevelColor = '';
  if( membershipData) {
    currenMembershipLevel = (getI18nText(membershipData.currentMembershipLevel, 'abbreviation', language));
    currentMembershipLevelColor = (membershipData.currentMembershipLevel.membershipLevelColor)
  }
  const handleGotoAboutUs = () => {
    const { reducersState } = props;
    const { platformConfig } = reducersState.app;
    dispatch({
      type: ActionType.LOADING,
      showLoading: true,
      showLoadingBgc: true,
    });
    dispatch({
      type: ActionType.QUERY_BLOG_LIST,
      contentType: 'COMPANY_INFO',
      storeSlug: platformConfig.storeSlug,
      callback: blogs => {
        if (blogs.length === 1) {
          handleItemClick({
            path: paths.BLOG_DETAIL,
            pathParams: {
              storeSlug: platformConfig.storeSlug,
              blogID: blogs[0].id,
              contentType: 'about-us',
            },
            sideNavOption: SideNavOption.BLOGS_ABOUT_AS,
          })
        } else {
          handleItemClick({ path: paths.ABOUT_US });
        }
        setTimeout(() => {
          dispatch({
            type: ActionType.LOADING,
            showLoading: false,
          })
        }, 500)
      }
    })
  }
  return (
    <SideNavStyled className={`${prefix}`}>
      <div className="sticky-member-side-nav">
        <div className="profile-block-side-nav">
          {
            !getAccessToken() &&
            <div className="avatar-icon-wrap">
              <Icon
                type={IconType.DEFAULT_AVATAR_OUTLINED}
                className="avatar-icon"
              />
            </div>
          }
          {
            getAccessToken() &&
            <div className="profile-img" style={{ backgroundImage: `url(${userInfo.avatarUrl})` }}></div>
          }
          
          <div className="profile-member">
            <div className="text-block-15">{userInfo && userInfo.name}</div>
            <div className="member-status" style={{ backgroundColor: membershipLevelColor || currentMembershipLevelColor }}>{membershipLevel || currenMembershipLevel}</div>
          </div>
        </div>
        {
          accountOptions.includes(AccountOption.PRIVILEGES) &&
          <SideNavItem
            iconType={IconType.PRIVILEGE_OUTLINED}
            msg={getIntl().page.myPrivilege}
            selected={selectedCurrentKey === SideNavOption.PRIVILEGE}
            onItemClick={() => handleItemClick({
              path: paths.PRIVILEGE,
              sideNavOption: SideNavOption.PRIVILEGE,
            })}
          ></SideNavItem>
        }
        {
          (accountOptions.includes(AccountOption.RECORDS) ||  platformConfig.showRecordsTab || platformConfig.showOrderTab) &&
          <SideNavItem
            iconType={IconType.RECORDS_OUTLINED}
            msg={getIntl().page.myRecords}
            selected={selectedCurrentKey === SideNavOption.RECORDS}
            onItemClick={() => handleItemClick({
              path: paths.RECORDS,
              sideNavOption: SideNavOption.RECORDS,
            })}
          />
        }
        {
          accountOptions.includes(AccountOption.PAYMENT) &&
          <SideNavItem
            iconType={IconType.PAYMENT_OUTLINED}
            msg={getIntl().page.myPayments}
            selected={selectedCurrentKey === SideNavOption.PAYMENT_LIST}
            onItemClick={() => handleItemClick({
              path: paths.PAYMENT_LIST,
              sideNavOption: SideNavOption.PAYMENT_LIST,
            })}
          />
        }
        {
          accountOptions.includes(AccountOption.SETTING) &&
          <SideNavItem
            iconType={IconType.SETTING_OUTLINED}
            msg={getIntl().page.setting}
            selected={selectedCurrentKey === SideNavOption.SETTING}
            onItemClick={() => handleItemClick({
              path: paths.SETTING,
              sideNavOption: SideNavOption.SETTING,
            })}
          />
        }
        {
          accountOptions.includes(AccountOption.ABOUT_US) &&
          <SideNavItem
            iconType={IconType.ABOUT_US_OUTLINED}
            msg={langFormat(getIntl().page.aboutTGG, { abbreviation })}
            selected={selectedCurrentKey === SideNavOption.BLOGS_ABOUT_AS}
            onItemClick={() => handleGotoAboutUs()}
          />
        }
        {
          accountOptions.includes(AccountOption.BOOKING) &&
          <SideNavItem
            iconType={IconType.BOOKING_SETTING}
            msg={getIntl().page.myBookings}
            selected={selectedCurrentKey === SideNavOption.BOOKING}
            onItemClick={() => handleItemClick({
              path: paths.BOOKINGS,
              sideNavOption: SideNavOption.BOOKING,
            })}
          />
        }
        {
          accountOptions.includes(AccountOption.ADDRESS) &&
          <SideNavItem
            iconType={IconType.ADDRESS_OUTLINED}
            msg={getIntl().page.myAddress}
            selected={selectedCurrentKey === SideNavOption.ADDRESS}
            onItemClick={() => handleItemClick({
              path: paths.ADDRESS_LIST,
              sideNavOption: SideNavOption.BOOKING,
            })}
          />
        }
        {
          accountOptions.includes(AccountOption.FAVORITE) &&
          <SideNavItem
            iconType={IconType.FAVORITE_SETTING}
            msg={getIntl().page.myFavorites}
            selected={selectedCurrentKey === SideNavOption.FAVORITE}
            onItemClick={() => handleItemClick({
              path: paths.FAVORITE,
              sideNavOption: SideNavOption.FAVORITE,
            })}
          />
        }
        {
          !suppliers &&
          <SideNavItem
            iconType={IconType.PRIVILEGE_OUTLINED}
            msg={getIntl().page.usePoints}
            selected={selectedCurrentKey === SideNavOption.POINTS}
            onItemClick={() => handleItemClick({
              path: paths.POINTS,
              sideNavOption: SideNavOption.POINTS,
            })}
          ></SideNavItem>
        }
        {/* {
          accountOptions.includes(AccountOption.QUEUING) &&
          <SideNavItem
            iconType={IconType.QUEUEING_SETTING}
            msg="page.myQueueings"
            onItemClick={() => handleItemClick({
              path: paths.QUEUEINGS,
            })}
          />
        } */}
        
      </div>
    </SideNavStyled>
  )
}
export default SideNavBar;

