import React from 'react';
import { prefix } from '.';

interface SkeletonProps {
  type: string;
}

const Skeleton: React.FC<SkeletonProps> = props => {
  const { type } = props;
  return (
    <div className={`${prefix}-skeleton`}>
      {
        type === 'order' &&
        <div className="order">
          <div className="order-item">
            <div className="item-logo"></div>
            <div className="item-info">
              <div className="bottom">
                <div className="bottom-total"></div>
                <div className="bottom-button"></div>
              </div>
              <div className="center"></div>
              <div className="top">
                <div className="topbox"></div>
                <div className="bar"></div>
                <div className="topbox"></div>
              </div>
            </div>
          </div>
          <div className="order-item">
            <div className="item-logo"></div>
            <div className="item-info">
              <div className="bottom">
                <div className="bottom-total"></div>
                <div className="bottom-button"></div>
              </div>
              <div className="center"></div>
              <div className="top">
                <div className="topbox"></div>
                <div className="bar"></div>
                <div className="topbox"></div>
              </div>
            </div>
          </div>
        </div>
      }
      {
        type === 'booking' &&
        <div className="booking">
          <div className="box1"></div>
          <div className="box2"></div>
          <div className="box3"></div>
          <div className="box4"></div>
          <div className="box5">
            <div className="box5-top">
              <div></div>
            </div>
            <div className="box5-center">
              <div>
                <div className="center-top"></div>
                <div className="center-bottom"></div>
              </div>
              <div>
                <div className="center-top"></div>
                <div className="center-bottom"></div>
              </div>
              <div>
                <div className="center-top"></div>
                <div className="center-bottom"></div>
              </div>
            </div>
            <div className="box5-buttom-box top">
              <div></div>
              <div></div>
            </div>
            <div className="box5-buttom-box">
              <div></div>
              <div></div>
            </div>
            <div className="box5-buttom-box">
              <div></div>
              <div></div>
            </div>
            <div className="box5-buttom-box">
              <div></div>
              <div></div>
            </div>
            <div className="box5-buttom-box">
              <div></div>
              <div></div>
            </div>
            <div className="box5-buttom-box">
              <div></div>
              <div></div>
            </div>
            <div className="box5-buttom-box">
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      }
      {
        type === 'point' &&
        <div className="point">
          <div className="box1">
            <div></div>
            <div></div>
          </div>
          <div className="box2">
            <div>
              <div className="left"></div>
              <div className="right"></div>
            </div>
          </div>
          <div className="box2">
            <div>
              <div className="left"></div>
              <div className="right"></div>
            </div>
          </div>
          <div className="box1">
            <div></div>
            <div></div>
          </div>
          <div className="box2">
            <div>
              <div className="left"></div>
              <div className="right"></div>
            </div>
          </div>
          <div className="box2">
            <div>
              <div className="left"></div>
              <div className="right"></div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default Skeleton;
