import Constants from '@/constants';
import { getLocalStorageJson, getSessionStorageJson, setLocalStorageJson, setSessionStorageJson } from '.';
import { IDeviceInfo } from './app';

interface ISplashInitializedParams {
  deviceInfo: IDeviceInfo;
  platformDomain: string;
}

export const setSplashInitialized = (params: ISplashInitializedParams) => {
  const { deviceInfo, platformDomain } = params;

  if (deviceInfo.isNativeApp) {
    localStorage.setItem(`${Constants.INITIALIZED_SPLASH}-${platformDomain}`, 'YES');
  } else {
    sessionStorage.setItem(`${Constants.INITIALIZED_SPLASH}-${platformDomain}`, 'YES');
  }
}

export const isSplashInitialized = (params: ISplashInitializedParams) => {
  const { deviceInfo, platformDomain } = params;

  let initializedSplashStatus  = '';
  if (deviceInfo.isNativeApp) {
    initializedSplashStatus = localStorage.getItem(`${Constants.INITIALIZED_SPLASH}-${platformDomain}`) || '';
  } else {
    initializedSplashStatus = sessionStorage.getItem(`${Constants.INITIALIZED_SPLASH}-${platformDomain}`) || '';
  }

  return initializedSplashStatus === 'YES';
}

export const getSplashStorageData = (params: ISplashInitializedParams) => {
  const { deviceInfo, platformDomain } = params;

  let splashData: any = null;
  if (deviceInfo.isNativeApp) {
    splashData = getLocalStorageJson(`${Constants.SPLASH_DATA}-${platformDomain}`);
  } else {
    splashData = getSessionStorageJson(`${Constants.SPLASH_DATA}-${platformDomain}`);
  }

  return splashData;
}

export const setSplashStorageData = (params: ISplashInitializedParams, data: any) => {
  const { deviceInfo, platformDomain } = params;

  if (data && data.splashData) {
    if (deviceInfo.isNativeApp) {
      setLocalStorageJson(`${Constants.SPLASH_DATA}-${platformDomain}`, data);
    } else {
      setSessionStorageJson(`${Constants.SPLASH_DATA}-${platformDomain}`, data);
    }
  }
}
