import styled  from 'styled-components';
export const BlogDetailPageStyled = styled.div`
width: 85%;
margin-top: 70px;
padding: 80px 0px;
border-radius: 12px;
background-color: var(--bm-light-background-color);
.section.settings {
  padding: 0px 80px;
}
.blog-title {
  font-size: 26px;
  color: var(--bm-text-title-color);
  font-weight: 500;
  margin-bottom: 20px;
}
.section {
  display: block;
  margin-top: auto;
  margin-bottom: 0px;
  padding: 100px 40px 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.div-block-550 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: var(--bm-text-title-color);
}
.bm-p-blog-detail-con {
  white-space: pre-wrap;
  word-break: break-word;
}
.bm-p-blog-detail-con {
  width: 100%;
  font-size: 16px;
  img {
    width: 100%;
  }
}
@media screen and (max-width: 991px) {
  margin-left: 40px;
  padding-top: 30px;
  padding-bottom: 30px;
  .section.settings {
    padding-right: 30px;
    padding-left: 30px;
  }
  .section {
    margin-top: 0px;
    padding: 84px 35px 30px;
  }
}

@media screen and (max-width: 767px) {
    width: auto;
    margin-top: 0px;
    margin-left: 0px;
    padding-top: 10px;
    padding-right: 20px;
    padding-left: 20px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  .section.settings {
    margin-right: 0px;
    margin-left: 0px;
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .section {
    padding: 66px 25px 20px;
  }
}
@media screen and (max-width: 479px) {
  .section.settings {
    padding-top: 0px;
  }
  .section {
    padding: 68px 12px 15px;
  }
  .bm-p-blog-detail-con {
    font-size: 14px;
  }
}
`;

export const NewOrStyled = styled.div`
.w-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
}
.container {
  position: relative;
  display: block;
  overflow: visible;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
}
.div-block-549 {
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: var(--bm-text-title-color);
}
a {
  word-break: break-all;
}
h2 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 32px;
  line-height: 36px;
  font-weight: bold;
}
.heading-2 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: 'Sf pro display', sans-serif;
  font-size: 28px;
  font-weight: 600;
  text-align: center;
}
.heading-2.with-space {
  margin-bottom: 20px;
  text-align: center;
  word-break: break-word;
}
img {
  height: auto;
  margin-bottom: 0px;
  width: 100%;
}
video {
  width: 100%;
}
.bm-p-blog-detail-con {
  img {
    width: 100% !important;
  }
}
@media screen and (max-width: 991px) {
  .w-container {
    max-width: 728px;
  }
}

@media screen and (max-width: 767px) {
  .section {
    padding: 20px 25px 20px;
  }
  .heading-2 {
    font-size: 27px;
  }
  .heading-2.with-space {
    margin-bottom: 10px;
  }
}


@media screen and (max-width: 991px) {
  .section {
    margin-top: 0px;
    padding: 40px 35px 30px;
  }
  .section {
    display: block;
    margin-top: auto;
    margin-bottom: 0px;
    padding: 30px 40px 40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0);
  }
}

`;

