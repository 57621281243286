
import React, {
  useRef,
  // useState,
  useEffect,
} from 'react';
import Icon, { IconType } from '@/components/Icon';
import { prefix } from '..';


interface IMapSearchBoxProps {
  mapApi: any;
  mapInstance: any;
  // addPlace: Function;
  nearSearch: Function;
}

const MapSearchBox: React.FC<IMapSearchBoxProps>= props => {

  const {
    mapApi,
    mapInstance,
    // addPlace = () => {},
    nearSearch = () => {},
  } = props;


  const searchInput: any = useRef(null);
  const searchBox: any = useRef(null);

  useEffect(() => {
    if (mapApi && searchBox) {
      searchBox.current = new mapApi.places.SearchBox(searchInput.current);
      searchBox.current.addListener('places_changed', handlesearch);
      searchBox.current.bindTo('bounds', mapInstance);
    };

    return () => {
      if (mapApi) {
        searchBox.current = null;
        mapApi.event.clearInstanceListeners(searchBox);
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapApi]);

  const handlesearch = () => {
    // const searchUrl = `https://maps.googleapis.com/maps/api/place/textsearch/json?query=${searchKey}&key=${GoogleMapConfig.key}`;

    const selected = searchBox.current.getPlaces();

    const { 0: place } = selected;

    if (!place.geometry) return;

    // if (place.geometry.viewport) {
    //   mapInstance.fitBounds(place.geometry.viewport);
    // } else {
    //   mapInstance.setCenter(place.geometry.location);
    // }

    if (place.geometry.location) {
      mapInstance.setCenter(place.geometry.location);
    }

    nearSearch(place);
    searchInput.current.blur();
  }

  const clearSearchBox = () => {
    searchInput.current.value = '';
    nearSearch();
  }

  return (
    <div className="add-new-address">
      <input
        className="div-block-499"
        ref={searchInput}
        // onFocus={clearSearchBox}
      />
      <Icon
        type={IconType.CLOSE_OUTLINED}
        className={`${prefix}-search-wapper-icon`}
        onClick={clearSearchBox}
      />
    </div>
  );
}
export default MapSearchBox;
