import React from 'react';
import AntdIcon from 'antd-mobile/es/icon';
import { formatCurrency } from '@/components/Payment';
import { prefix } from '.';
import PayInfoItem from './PayInfoItem';
import Icon, { IconType } from '../Icon';
import { IOrderInfo, ISaleTransaction } from '@/lib/order-builder/models/OrderEntity';
import { getIntl } from '../App/App';

interface IPayInfoProps {
  title: React.ReactNode;
  showBarTotal?: boolean;
  className?: string;
  orderInfo?: IOrderInfo;
  saleTransactions?: ISaleTransaction[];
  showItemLeftIcon?: boolean;
  showBalance?: boolean;
  onItemClick?: () => void;
  onAddPayMethod?: () => void;
  onDeleteClick?: () => void;
  referenceID?: number;
  payResultsPage?: boolean;
  isPayBillPage?: boolean;
}

const PayInfo: React.FC<IPayInfoProps> = props => {
  const {
    title,
    orderInfo,
    saleTransactions,
    className = '',
    showBarTotal = false,
    showItemLeftIcon = false,
    showBalance = false,
    referenceID,
    onItemClick,
    onAddPayMethod,
    onDeleteClick,
    payResultsPage = false,
    isPayBillPage = false,
  } = props;

  return (
    <div className={`${prefix} ${className}`}>
      {
        referenceID &&
        <div className="reference-msg">
          <div className="reference-id">
            {referenceID}
          </div>
          <div className="reference-title" >
            {getIntl().page.referenceID}
          </div>
          <div className="left-circle"/>
          <div className="right-circle"/>
        </div>
      }
      {/* <div className={referenceID ? 'pi-bar reference' : 'pi-bar'}> */}
      <div className={referenceID ? 'pi-bar reference' : 'pi-bar check-out-info restaurant-info '}>
        <div className="store-title text-block-67 bold">
          {
            (!orderInfo || (orderInfo.superOrder && payResultsPage)) ? (isPayBillPage ? title : ' ') : title
          }
        </div>
        {
          showBarTotal && orderInfo &&
          <div className="store-price item-price">
            {getIntl().common.total}
            : {formatCurrency(orderInfo.initialTotal, orderInfo.currencyCode)}
          </div>
        }
      </div>
      {
        saleTransactions && saleTransactions.length > 0 && saleTransactions.map(item => {
          return (
            <PayInfoItem
              key={item.id}
              data={item}
              showLeftIcon={showItemLeftIcon}
              showBalance={showBalance}
              onDelete={onDeleteClick}
              onItemClick={onItemClick}
            />
          )
        })
      }
      {
        saleTransactions && saleTransactions.length === 0 && onAddPayMethod &&
        <div className="add-pay-method" onClick={onAddPayMethod}>
          <Icon type={IconType.PLUS_OUTLINED}  />
          {getIntl().page.addPayMethod}
        </div>
      }
      {
        !saleTransactions &&
        <div className="pi-loading">
          <AntdIcon type="loading" size="lg" color="red" />
        </div>
      }
    </div>
  );
}

export default PayInfo;
