
import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import Icon, { IconType } from '@/components/Icon';
import { IObject } from '@/components/bm-common';
import { getAppTheme, setScreenColor, getIntl } from '@/components/App/App';
import { prefix } from '.';
import ActionType from '@/actions/action-type';
import paths from '@/routes/paths';
import Config from '@/Config';
import Toast from 'antd-mobile/es/toast';
import { getI18nText, languageKeys } from '@/utils/app';
import Components from '@/components/SideNavBar/Components';
import SideNavOption from '@/actions/side-nav-option';
import SettingItem from '@/components/SettingItem';
import { SettingPageStyled } from './styled';
import Popup from '@/components/Modal/Popup';
import BmNavBar from '@/components/SideNavBar/BmNavBar';
import Layout from '@/components/Layout';

export interface ISettingPageProps extends IBasePageProps { }
export interface ISettingPageState {
  showLanguage: boolean;
  showTerms: boolean;
  termsData: string | undefined;
  termsTitle: string;
}

class SettingPage extends BasePage<ISettingPageProps, ISettingPageState> {
  routeParams: IObject = {};

  state: ISettingPageState = {
    showLanguage: false,
    showTerms: false,
    termsData: '',
    termsTitle: '',
  }

  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.lightBackgroundColor) {
      setScreenColor(appTheme.lightBackgroundColor);
    }

    super.componentDidMount();
  }

  handleItemClick = (item: string) => {
    const { reducersState, dispatch } = this.props;
    const { platformAppSettings } = reducersState.app;
    let privacyData = '';
    let conditionsData = '';
    if (platformAppSettings) {
      privacyData = platformAppSettings.privacyStatement || '';
      conditionsData = platformAppSettings.generalConditions || '';
    }

    if (item === 'privacy') {
      this.setState({
        showTerms: true,
        termsData: getI18nText(platformAppSettings, 'privacy_statement', reducersState.app.language) || privacyData,
        termsTitle: getIntl().page.privacy,
      })
    }
    if (item === 'terms') {
      this.setState({
        showTerms: true,
        termsData: getI18nText(platformAppSettings, 'general_conditions', reducersState.app.language) || conditionsData,
        termsTitle: getIntl().page.terms
      })
    }
    if (item === 'language') {
      this.setShowLanguage(true);
    }
    if (item === 'password') {
      this.handleGotoRoute({
        path: paths.FORGOT_PASSWORD,
      });
    }
    if (item === 'device') {
      this.handleGotoRoute({
        path: paths.DEVICE_INFO,
      });
    }
    if (item === 'clearCache') {
      dispatch({
        type: ActionType.CLEAR_SHOPPING_CART,
      });
      dispatch({
        type: ActionType.CLEAR_STORE,
      });
      dispatch({
        type: ActionType.CLEAR_APP_CONFIG,
      });
      Toast.info(getIntl().page.clearCacheSuccess)
    }
  }
  handleChangeLanuage = (lang: string) => {
    const { dispatch } = this.props;
    dispatch({ type: ActionType.SET_LANGUAGE, language: lang });
  };
  setShowLanguage = (type: boolean) => {
    this.setState({
      showLanguage: type,
    })
  }

  render() {
    const languageList = [
      {
        id: 1,
        text: 'English',
        value: languageKeys.EN_US,
      },
      {
        id: 2,
        text: '简体中文',
        value: languageKeys.ZH_CN,
      },
      {
        id: 3,
        text: '繁體中文',
        value: languageKeys.ZH_HK,
      }
    ];
    const { reducersState } = this.props;
    const { language, deviceInfo } = reducersState.app;
    const { userInfo } = reducersState.user;

    return (
      <Layout
        {...this.props}
        isFooter={false}
        className={prefix}
      >
        <Components 
          {...this.props}
          reducersState={reducersState}
          onLeftClick={() => this.handleGoBack({ path: paths.HOME, runGoBack: true })}
          navContent={getIntl().page.setting}
          navIcon={<Icon  className="html-embed-7 w-embed" type={IconType.LEFT_OUTLINED} />}
          sideNavOption={SideNavOption.SETTING}
          className={`${prefix}-new`}
        >
          <SettingPageStyled className="div-block-547 payment">
            <div className="section settings">
              <SettingItem
                msg="page.clearCache"
                onItemClick={() => this.handleItemClick('clearCache')}
              />
              {
                !deviceInfo.weChatMiniProgram && userInfo &&
                <SettingItem
                  msg="page.changePassword"
                  onItemClick={() => this.handleItemClick('password')}
                />
              }
              {
                !deviceInfo.weChatMiniProgram &&
                <SettingItem
                  msg="page.language"
                  onItemClick={() => this.handleItemClick('language')}
                />
              }
              <SettingItem
                msg="page.terms"
                onItemClick={() => this.handleItemClick('terms')}
              />
              <SettingItem
                msg="page.privacy"
                onItemClick={() => this.handleItemClick('privacy')}
              />
              {
                Config.isStaging &&
                <SettingItem
                  msg="page.deviceInfo"
                  onItemClick={() => this.handleItemClick('device')}
                />
              }
            </div>
            <Popup
              visible={this.state.showLanguage}
              title={getIntl().page.chooseLanguage}
              onClose={() => this.setShowLanguage(false)}
              className={`${prefix}-gender-list`}
            >
              <div className="gender-list">
                {
                  languageList.map(item => (
                    <div className="gender-wrap" key={item.value} onClick={() => this.handleChangeLanuage(item.value)}>
                      <div className="gender">
                        {item.text}
                      </div>
                      {
                        language === item.value &&
                        <Icon
                          type={IconType.CHECKED_OUTLINED}
                          className="checked"
                        />
                      }
                    </div>
                  ))
                }
              </div>
            </Popup>
            <Popup
              visible={this.state.showTerms}
              title={
                <BmNavBar
                  onLeftClick={() => this.setState({ showTerms: false })}
                  navIcon={<Icon type={IconType.LEFT_OUTLINED} className="html-embed-7 w-embed"/>}
                  navContent={this.state.termsTitle}
                ></BmNavBar> 
              }
              showIcon={false}
              onClose={() => this.setState({ showTerms: false })}
              className="terms-condition"
            >
              <div className="div-block-549" dangerouslySetInnerHTML={{
                __html: this.state.termsData ? this.state.termsData : '',
              }}/>
            </Popup>
          </SettingPageStyled>
        </Components>
      </Layout>
    );
  }
}

export default SettingPage;
