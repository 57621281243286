import React, { useState, useEffect } from 'react';
import Icon, { IconType } from '@/components/Icon';
import { prefix, IProcurementMethodProps } from '.';
import { IObject } from '../bm-common';
import ShippingMethod from '@/lib/order-builder/enums/ShippingMethod';
import ProcurementMethodPopupContent from './ProcurementMethodPopupContent';
import dayjs from 'dayjs';
import { getIntl } from '../App/App';
import { langFormat } from '@/utils/app';

const ProcurementMethod: React.FC<IProcurementMethodProps> = props => {
  const {
    id,
    className = '',
    orderInfo,
    store,
    parseOpeningHoursResult,
    icon,
    currentShippingMethod,
    defaultShowProcurementMethod = false,
    onPopupShow,
    storeConfig,
    disableChangeMethod = false,
    orderData,
    hideChangeMethod = false,
    showChange = false,
  } = props;

  // const { dineInParty, deliveryDate } = orderInfo || {};
  let dineInParty;
  let deliveryDate;
  if (orderData) {
    dineInParty = orderData.dineInParty;
    deliveryDate = orderData.deliveryDate;
  } else if (orderInfo) {
    dineInParty = orderInfo.dineInParty;
    deliveryDate = orderInfo.deliveryDate;
  }

  const [showProcurementMethod, setShowProcurementMethod] = useState(defaultShowProcurementMethod);
  const [procurementMethod, setProcurementMethod] = useState<ShippingMethod>(currentShippingMethod);

  const handlePopupShow = (show: boolean) => {
    setShowProcurementMethod(show);
    if (onPopupShow) {
      onPopupShow(show);
    }
  }

  useEffect(() => {
    setProcurementMethod(currentShippingMethod);
  }, [currentShippingMethod]);

  const rootProps: IObject = {};
  if (id) {
    rootProps.id = id;
  }

  let shippingMethodNote: any = '';
  if (!parseOpeningHoursResult) {
    shippingMethodNote = '';
  } else if (!parseOpeningHoursResult.isOpen && storeConfig && storeConfig.pickUpAdvanceOrderDate === 1 && storeConfig.deliveryAdvanceOrderDate === 1) {
    shippingMethodNote = getIntl().page.closed;
  } else if (ShippingMethod.DINE_IN_UNASSIGNED === currentShippingMethod) {
    shippingMethodNote = getIntl().page.immediately;
  } else if (ShippingMethod.DINE_IN === currentShippingMethod) {
    if (dineInParty) {
      shippingMethodNote = langFormat(getIntl().page.tableNumber, { tableNumber: dineInParty.tableName });
    } else {
      shippingMethodNote = getIntl().page.enterTableNumber;
    }
  } else if ([ShippingMethod.PICK_UP, ShippingMethod.DELIVERY].includes(currentShippingMethod)) {
    if (deliveryDate) {
      const date = dayjs();
      const dateTime = dayjs(deliveryDate);
      let dateFormat: any = dateTime.format('YYYY-MM-DD');
      if (dateFormat === date.format('YYYY-MM-DD')) {
        dateFormat = getIntl().common.today;
      } else if (dateFormat === date.add(1, 'day').format('YYYY-MM-DD')) {
        dateFormat = getIntl().common.tomorrow;
      }
      const timeFormat = dateTime.format('h:mmA');
      if (currentShippingMethod === ShippingMethod.PICK_UP) {
        shippingMethodNote = storeConfig && storeConfig.pickUpInterval === 0 ? dateFormat : `${dateFormat} ${timeFormat}`;
      } else if (currentShippingMethod === ShippingMethod.DELIVERY) {
        shippingMethodNote = storeConfig && storeConfig.deliveryInterval === 0 ? dateFormat : `${dateFormat} ${timeFormat}`;
      }
    } else {
      if (storeConfig && storeConfig.delayedDeliveryTime !== 0) {
        shippingMethodNote = dayjs().add(storeConfig.delayedDeliveryTime, 'day').format('YYYY-MM-DD');
      } else if ((!parseOpeningHoursResult.isOpen && storeConfig && storeConfig.pickUpAdvanceOrderDate > 1) || (storeConfig?.hideNowPickUpTime)) {
        shippingMethodNote = getIntl().page.pickUpTime;
      } else {
        shippingMethodNote = getIntl().page.immediately;
      }
    }
  }
  return (
    <>
      <div {...rootProps} className={`${prefix} ${className}`}  onClick={() => {
        if (store && parseOpeningHoursResult && !disableChangeMethod) {
          handlePopupShow(true);
        }
      }}>
        <div className="container-horizontal top w-container">
          <div className="nav-time-picker open-modal">
            <div className={`${prefix}-name`}>
              {
                store && currentShippingMethod === ShippingMethod.PICK_UP &&
                getIntl().page.takeAway
              }
              {
                store && [ShippingMethod.DINE_IN, ShippingMethod.DINE_IN_UNASSIGNED].includes(currentShippingMethod) &&
                getIntl().page.dineIn
              }
              {
                store && currentShippingMethod === ShippingMethod.DELIVERY &&
                getIntl().page.delivery
              }
            </div>
            <div className={`${prefix}-note`}>
              <span className={`${prefix}-note-text`}>{shippingMethodNote}</span>
              {
                !disableChangeMethod && showChange &&
                <Icon className={`${prefix}-icon`} type={icon || IconType.RIGHT_OUTLINED} />
              }
            </div>
          </div>
        </div>
      </div>
      {
        showProcurementMethod &&
        <div className={`${prefix}-modal modal-wrapper-2 modal-wrapper-2-show`}>
          <div className="c-popup-close-time close-modal" onClick={() => handlePopupShow(false)}></div>
          {
            <ProcurementMethodPopupContent
              {...props}
              showProcurementMethod={showProcurementMethod}
              procurementMethod={procurementMethod}
              setProcurementMethod={setProcurementMethod}
              setPopupShow={handlePopupShow}
              hideChangeMethod={hideChangeMethod}
            />
          }
        </div>
      }
    </>
  );
}

export default ProcurementMethod;
