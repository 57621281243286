import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import { getAppTheme, setScreenColor, getIntl } from '@/components/App/App';
import Layout from '@/components/Layout';
import { prefix } from '.';
import Icon, { IconType } from '@/components/Icon';
import paths from '@/routes/paths';
import Button from 'antd-mobile/es/button';

export interface IQrcodeShoppingResultPageProps extends IBasePageProps {
}

export interface IQrcodeShoppingResultPageState {
}

class QrcodeShoppingResultPage extends BasePage<IQrcodeShoppingResultPageProps, IQrcodeShoppingResultPageState> {
  constructor(props: IQrcodeShoppingResultPageProps) {
    super(props);

    this.state = {
    }
  }

  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.darkBackgroundColor) {
      setScreenColor(appTheme.darkBackgroundColor);
    }

    super.componentDidMount();
  }

  handleBackHome = () => {
    const { routeParams } = this.props;
    this.handleGotoRoute({
      path: paths.QRCODE_SHOPPING,
      clearPaths: true,
      pathParams: {
        storeSlug: routeParams.storeSlug,
        orderNumber: routeParams.orderNumber,
      },
    });
  }
  handleOrderMore = () => {
    const { routeParams } = this.props;
    this.handleGotoRoute({
      path: paths.QRCODE_SHOPPING_MENUS,
      pathParams: {
        storeSlug: routeParams.storeSlug,
        orderNumber: routeParams.orderNumber,
      },
    });
  }
  handleOrderDetail = () => {
    const { routeParams } = this.props;
    this.handleGotoRoute({
      path: paths.QRCODE_SHOPPING_DETAIL,
      pathParams: {
        storeSlug: routeParams.storeSlug,
        orderNumber: routeParams.orderNumber,
      },
    });
  }

  render() {
    return (
      <Layout
        {...this.props}
        className={prefix}
        navContent={getIntl().page.qrcodeShoppingResultTitle}
      >
        <div className="pr-icon">
          <Icon type={IconType.STATUS_SUCCESS} />
        </div>
        <div className="pr-msg">
          {getIntl().page.submitSuccess}
        </div>
        <div className="pr-btns">
          <Button type="primary" className="order-more-btn bm-btn-lg" onClick={this.handleOrderDetail}>
            {getIntl().page.orderDetail}
          </Button>
          <Button  type="ghost"  className="back-home bm-btn-lg" onClick={this.handleBackHome}>
            {getIntl().page.backHome}
          </Button>
        </div>
      </Layout>
    );
  }
}

export default QrcodeShoppingResultPage;
