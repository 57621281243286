import React from 'react';
import Icon, { IconType } from '@/components/Icon';
import { prefix } from '.'
import { getIntl } from '../App/App';


interface IReceiveInformationProps {
  selectd: boolean;
  onSelect?: (data: boolean) => void;
  className?: string;
}

const ReceiveInformation: React.FC<IReceiveInformationProps> = props => {

  const {
    className = '',
    selectd,
    onSelect = () => { },
  } = props;



  const iconType = selectd ? IconType.CHECKED_OUTLINED : IconType.UN_SELECTED_OUTLINED;

  return (
    <div className={`${prefix}-notice-user ${className}`} >
      <Icon
        type={iconType}
        className={`${prefix}-notice-user-icon`}
        onClick={() => onSelect(!selectd)}
      />
      <div className={`${prefix}-notice-user-content`}>
        <span>{getIntl().page.receiveInformation}</span>
      </div>
    </div>
  )
}
export default ReceiveInformation;
