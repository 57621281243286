import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IReducersState } from '@/reducers';
import FaceBook from '../common/userComponents/FaceBookSignIn';
import Apple from '../common/userComponents/AppleSignIn';
// import Wechat from '../common/userComponents/WechatSignIn';
import { IDispatch } from '@/actions';
import supportMethod from '@/actions/sign-in-method';
import ActionType from '@/actions/action-type';
// import ServerEnv from '@/constants/ServerEnv';

interface IOtherSignInProps {
  signInClick?: () => void;
  className?: string;
  forgotClick?: () => void;
}

const OtherSignIn: React.FC<IOtherSignInProps> = props => {
  const { className = '', signInClick = () => {} } = props;

  // const serverEnv: string = process.env.REACT_APP_SERVER_ENV || '';
  const dispatch = useDispatch<IDispatch>();
  const reducersState: IReducersState = useSelector<
    IReducersState,
    IReducersState
  >(state => state);
  const supportSignMethod = reducersState.app.platformConfig.signInMethods;
  const { isAppleApp } = reducersState.app.deviceInfo;
  // const isAppleApp  = reducersState.app.deviceInfo.isAppleApp;
  // const isStaging = serverEnv === ServerEnv.STAGING;

  const otherSignInClick = () => {
    dispatch({
      type: ActionType.CLEAR_CUSTOMER,
    });
    dispatch({
      type: ActionType.CLEAR_CUSTOMER_GROWTH,
    });

    signInClick();
  };

  const showOtherLoginMethods = (methodType: supportMethod) => {
    if (methodType === supportMethod.APPLE) {
      return isAppleApp && supportSignMethod.includes(supportMethod.APPLE);
    }

    if (methodType === supportMethod.FACEBOOK) {
      return supportSignMethod.includes(supportMethod.FACEBOOK);
    }

    return false;
  };

  return (
    <div className={`other-signin ${className}`}>
      {
        (showOtherLoginMethods(supportMethod.FACEBOOK) || showOtherLoginMethods(supportMethod.APPLE)) &&
        <div className="div-block-522">
          <div className="line"></div>
          <div className="text-block-5---subtitle">
            Or
          </div>
          <div className="line"></div>
        </div>
      }
      {showOtherLoginMethods(supportMethod.FACEBOOK) && (
        <FaceBook signInClick={otherSignInClick} />
      )}
      {/* {
        <Wechat
          signInClick={otherSignInClick}
        />
      } */}
      {showOtherLoginMethods(supportMethod.APPLE) && (
        <Apple signInClick={otherSignInClick} />
      )}
    </div>
  );
};
export default OtherSignIn;
