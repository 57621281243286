// import DiningStyle from '@/components/DiningStyle';
import React from 'react';

const Skeleton: React.FC = () => (
  <div className="skeleton-co-page-content">
    <div className="style-b-color"></div>
    <div className="skeleton-co-content-panel">
      <div className="skeleton-co-content-store-title"></div>
      <div className="skeleton-co-content-line" />
      <div className="skeleton-co-content-pick-up-msg-wrap">
        <div className="shipping-method-wrap">
          <div className="skeleton-co-content-shipping-method-title"></div>
          <div className="skeleton-co-content-shipping-method-time"></div>
        </div>
        <div className="pick-up-wrap">
          <div className="pick-up"></div>
          <div className="pick-up-msg">
            <div className="icon"></div>
            <div className="address-wrap">
              <div className="pick-up-address"></div>
              <div className="pick-up-address-detail"></div>
            </div>
          </div>
        </div>
        <div className="skeleton-co-content-line" />
      </div>
      <div className="skeleton-co-content-listing">
        <div className="skeleton-co-content-listing-img" />
        <div className="skeleton-co-content-listing-info">
          <div className="skeleton-co-content-listing-info-l1">
            <div className="info-l1-item1" />
            <div className="info-l1-item2" />
          </div>
          <div className="skeleton-co-content-listing-info-l2" />
          <div className="skeleton-co-content-listing-info-l3" />
        </div>
      </div>
      <div className="skeleton-co-content-line" />
      <div className="skeleton-co-content-listing">
        <div className="skeleton-co-content-listing-img" />
        <div className="skeleton-co-content-listing-info">
          <div className="skeleton-co-content-listing-info-l1">
            <div className="info-l1-item1" />
            <div className="info-l1-item2" />
          </div>
          <div className="skeleton-co-content-listing-info-l2" />
          <div className="skeleton-co-content-listing-info-l3" />
        </div>
      </div>
      <div className="skeleton-co-content-line" />
      <div className="skeleton-co-content-listing-total">
        <div className="skeleton-co-content-listing-total-box">
          <div className="listing-total-item1" />
          <div className="listing-total-item2" />
        </div>
        <div className="skeleton-co-content-listing-total-box">
          <div className="listing-total-item3" />
          <div className="listing-total-item4" />
        </div>
      </div>
    </div>
    <div className="skeleton-co-content-panel">
      <div className="skeleton-co-pay-method-title" />
      <div className="skeleton-co-content-line line-2" />
      <div className="skeleton-co-pay-method-info">
        <div className="skeleton-co-pay-method-info-left">
          <div className="skeleton-co-pay-method-info-item1" />
          <div className="skeleton-co-pay-method-info-item2" />
        </div>
        <div className="skeleton-co-pay-method-info-item3" />
      </div>
    </div>
  </div>
);

export default Skeleton;
