import LineItemEntity from '@/lib/order-builder/models/LineItemEntity';
import { ShareType } from '@/components/BottomBar';
import axios from 'axios';
import { IReducersState } from '@/reducers';
import { IStore } from '@/actions/store-action';
import dayjs from 'dayjs';
import { getI18nText, langFormat } from './app';
import { getIntl } from '@/components/App/App';

import { formatCurrency } from '@/components/Payment';

export interface IShareCartInfo {
  data: IShareData[];
  storeId: string;
  phone?: string;
  customer?: string;
  email?: string;
  referenceID?: string;
}

interface IShareData {
  records: LineItemEntity[];
  storeName: string;
  deliveryDate?: string;
  total?: number;
  orderTime?: string;
  remark?: string;
}

const parsingData = (flag: string, data: IShareData, language: string) => {
  const {
    records,
  } = data;

  let text = encodeURIComponent(`${flag}${flag}`);

  records.forEach((item: LineItemEntity) => {
    const name = getI18nText(item, 'name', language) || item.name;
    if (Number(item.quantity) !== 0) {
      const content = `${item.quantity} x ${name} - ${formatCurrency(item.price, item.currencyCode)} ${flag}`;

      text += encodeURIComponent(content);
    }
  })

  return text;
}

export const getTemplate = (type: string, params: IShareCartInfo, reducersState: IReducersState, selectStore: IStore | undefined) => {
  const {
    data,
    phone,
    customer,
    // storeId,
  } = params;

  let content = '';

  if (data.length > 0) {
    data.forEach((item: IShareData) => {
      const { storeName, total, orderTime } = item;
      const { language } = reducersState.app;
      const deliverOn = getIntl().common.deliverOn;
      // const oderIntl = intl.formatMessage({id: 'common.order'});
      const birdymart = getIntl().common.birdymart;
      const flag = '\n';
      // if (type === ShareType.Email) {
      //   flag = '<br/>';
      // }

      content += encodeURIComponent(`${birdymart} ${flag}`);
      content += encodeURIComponent(`${storeName} ${flag}`);

      if (selectStore) {
        content += encodeURIComponent(`${getIntl().common.customer} ${getI18nText(selectStore, 'title', language) || selectStore.name} ${flag}`)
      }
      content += encodeURIComponent(`${deliverOn} ${item.deliveryDate ? dayjs(item.deliveryDate).format('YYYY-MM-DD') : ''}`);
      content += parsingData(flag, item, language);
      content += encodeURIComponent(`${flag}${flag}`);
      if (item.remark) {
        content += encodeURIComponent(`${getIntl().page.remark}: ${item.remark} ${flag}`);
      }
      content += encodeURIComponent(`${getIntl().common.total}: - ${formatCurrency(total || 0, item.records[0].currencyCode)} ${flag}`);
      const shallOrderBy = langFormat(getIntl().common.shallOrderBy, {customer: `${customer || ''}`, phone: `${(phone) || ''}`, deliveryDate: `${orderTime ? dayjs(orderTime).format('YYYY-MM-DD') : ''}`});
      content += encodeURIComponent(`${flag}${flag} ${shallOrderBy} ${flag}${flag}`);
      
    })
  }
  return content
}

export const shareShoppingCartInfo = (params: IShareCartInfo, type: string, reducersState: IReducersState, selectStore: IStore | undefined) => {
  const {
    email,
    referenceID = '',
  } = params;

  const content = getTemplate(type, params, reducersState, selectStore);

  switch (type) {
  case ShareType.WHTAS_APP: 
    ShareToWhtasApp(content)
    break;
  case ShareType.Email:
    ShareToEmail(decodeURIComponent(content), referenceID, email);
    break;
  default:
    break;
  }

}

const ShareToWhtasApp = (content: string) => {
  const newContent = `text=${content}`;

  window.location.href = `https://api.whatsapp.com/send?${newContent}`;
}

export const ShareToEmail = async (emailContent: string, referenceID: string, email: string| undefined) => {
  const content = emailContent.replace(/\n/g, '<br/>');
  console.info('content==', content);
  if (!email) return;
  const data = new FormData();
  data.append('from', 'Bindo <noreply@email.bindo.com>');
  data.append('to', email);
  data.append('subject', referenceID ? `${langFormat(getIntl().common.subjectCon, { referenceID: referenceID })}` : getIntl().common.subjectTo);
  data.append('html', content);
  // const data = {
  //   html: content,
  //   to: [email],
  //   from: 'noreply@bindo.com',
  // }
  const result = await axios({
    method: 'POST',
    url: 'https://api.mailgun.net/v3/email.bindo.com/messages',
    // url: 'https://a.alpha.bindo.site/api/email',
    data: data,
    headers: {
      // 'Access-Control-Allow-0rigin': '*',
      // 'Access-Control-Allow-Credentials': 'true',
      // 'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,OPTIONS',
      // 'Access-Control-Allow-Headers': 'Origin,Authorization,Access-Control-Allow-Origin,Content-Type',
      'Authorization': 'Basic YXBpOmtleS03MDYyMjZjNmM3MWJkNzJkODVkMzg4ZDA5OTg1ZWZjOA==',
      'Content-Type' : 'multipart/form-data',
    }
  });

  return result;
}
