
import React from 'react';
import Button from 'antd-mobile/es/button';
import Modal from 'antd-mobile/es/modal';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import ActionType from '@/actions/action-type';
import { IPayCard, IGiftCard } from '@/actions/pay-action';
import Icon, { IconType } from '@/components/Icon';
import { parseRouteParams, minus } from '@/utils';
import { IObject } from '@/components/bm-common';
import { getAppTheme, setScreenColor, getIntl } from '@/components/App/App';
import PayCardModify, { usePayCardModify, IPayCardInstance } from '@/components/PayCardModify';
import { PayTypes, IPayMethod } from '@/lib/payment';
import { giftCardToPayMethod, creditCardToPayMethod } from '@/utils/pay';
import { prefix } from '.';
import { IOrderInfo } from '@/lib/order-builder/models/OrderEntity';
import { IStore } from '@/actions/store-action';

export interface IPayCardPageProps extends IBasePageProps {
  onCancel: () => void;
}
export interface IPayCardPageState {
  orderInfo?: IOrderInfo;
  store?: IStore;
  payCards?: IPayCard[];
  cardIconType: IconType;
  cardNum: string;
  cardError: boolean;
  cardErrorMsg: string;
  openEye: boolean;
  isReady: boolean;
  adding: boolean;
}

class PayCardPage extends BasePage<IPayCardPageProps, IPayCardPageState> {
  routeParams: IObject = {};
  payCardModify: IPayCardInstance | undefined;
  
  constructor(props: IPayCardPageProps) {
    super(props);

    const [payCardModify] = usePayCardModify();
    this.payCardModify = payCardModify;
  }

  state: IPayCardPageState = {
    orderInfo: undefined,
    store: undefined,
    payCards: [],
    cardIconType: IconType.CREDIT_CARD,
    cardNum: '',
    cardError: false,
    cardErrorMsg: '',
    openEye: false,
    isReady: false,
    adding: false,
  }

  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.lightBackgroundColor) {
      setScreenColor(appTheme.lightBackgroundColor);
    }
    
    super.componentDidMount();

    this.routeParams = parseRouteParams(this.props);
    const { dispatch, reducersState } = this.props;
    const { fromUrlPayData } = reducersState.pay;

    if (this.routeParams.orderNumber && this.routeParams.storeSlug) {
      dispatch({
        type: ActionType.QUERY_ORDER_INFO,
        storeSlug: this.routeParams.storeSlug,
        orderNumber: this.routeParams.orderNumber,
        fromUrlPayData,
        callback: (orderInfo, store) => {
          this.setState({ orderInfo, store });
        }
      });

      dispatch({
        type: ActionType.QUERY_PAY_CARDS,
        callback: payCards => {
          this.setState({ payCards });
        }
      });
    }
  }

  handleSave = async () => {
    const { orderInfo } = this.state;
    if (!orderInfo || !this.payCardModify) return;

    const { dispatch, history, reducersState, onCancel } = this.props;
    this.setState({ adding: true });
    let showModal = false;
    let textContent = '';
    const result = await this.payCardModify.save();
    if (result) { // 数据提交成功
      if ([PayTypes.STORE_CREDIT, PayTypes.ANONYMOUS_GIFT_CARD].includes(result.type)) {
        const giftCard = result as IGiftCard;
        const gcPayMethod = giftCardToPayMethod(giftCard, orderInfo, 0);
        if (!giftCard.isGiftCard) {
          showModal = true;
          textContent = getIntl().page.giftCardAlreadyBound;
        } else {
          if (orderInfo.initialTotal <= giftCard.balance) {
            dispatch({
              type: ActionType.MODIFY_PAY_METHODS,
              payMethods: [gcPayMethod],
            });
            onCancel();
          } else {
            gcPayMethod.amount = Number(gcPayMethod.balance);
            const { payMethods } = reducersState.pay;
            let payMethod: IPayMethod | undefined = undefined;
            for (let i = 0; i < payMethods.length; i++) {
              const tempPayMethod = payMethods[i];
              if (![PayTypes.STORE_CREDIT, PayTypes.ANONYMOUS_GIFT_CARD].includes(tempPayMethod.type)) {
                payMethod = JSON.parse(JSON.stringify(tempPayMethod));;
                break;
              }
            }
  
            if (payMethod) {
              payMethod.amount = minus(orderInfo.initialTotal, Number(gcPayMethod.amount));
              dispatch({
                type: ActionType.MODIFY_PAY_METHODS,
                payMethods: [gcPayMethod, payMethod],
              });
              onCancel();
            } else {
              dispatch({
                type: ActionType.MODIFY_PAY_METHODS,
                payMethods: [gcPayMethod],
              });
              history.goBack();
            }
          }
        }
      } else { // 添加银行卡
        const creditCard = result as IPayCard;

        const { payMethods } = reducersState.pay;
        let payMethod: IPayMethod | undefined = undefined;
        const newPayMethods: IPayMethod[] = [];
        for (let i = 0; i < payMethods.length; i++) {
          const tempPayMethod = payMethods[i];
          if ([PayTypes.STORE_CREDIT, PayTypes.ANONYMOUS_GIFT_CARD].includes(tempPayMethod.type)) {
            payMethod = JSON.parse(JSON.stringify(tempPayMethod));
            if (payMethod) newPayMethods.push(payMethod);
            break;
          }
        }

        if (payMethod) {
          const ccPayMethod = creditCardToPayMethod(creditCard, orderInfo, Number(payMethod.amount));
          if (ccPayMethod.amount === 0) {
            ccPayMethod.amount = orderInfo.initialTotal;
            newPayMethods.length = 0;
          }
          newPayMethods.push(ccPayMethod);
        } else {
          newPayMethods.push(creditCardToPayMethod(creditCard, orderInfo, 0));
        }

        dispatch({
          type: ActionType.MODIFY_PAY_METHODS,
          payMethods: newPayMethods,
        });
        onCancel();
      }
    } else {
      onCancel();
      showModal = true;
      textContent = getIntl().page.payCardError;
    }

    if (showModal) {
      const tips = getIntl().common.tips;
      Modal.alert(tips, textContent, [
        {
          text: getIntl().common.confirm,
          onPress: () => {
            this.setState({ adding: false });
          },
        }
      ]);
    }
  }

  handleReadyChange = (isReady: boolean) => {
    this.setState({
      isReady,
    })
  }

  render() {
    const { dispatch, reducersState, onCancel } = this.props;
    const {
      adding,
      isReady,
      store,
    } = this.state;

    const { id: storeID = '' } = store || {};

    return (
      <div className={prefix}>
        <div className="c-popup-close-2 close-modal" onClick={onCancel}></div>
        <div className="flex-pop-up widen">
          <div className="top-header no-line">
            <h2 className="heading-2">{getIntl().page.paymentCardTitle}</h2>
            <div className="x-icon close-modal">
              <Icon className="w-embed" type={IconType.CLOSE_OUTLINED} onClick={onCancel} />
            </div>
          </div>
          <div className="form-3">
            <PayCardModify
              dispatch={dispatch}
              storeID={storeID}
              payCardModify={this.payCardModify}
              onReadyChange={this.handleReadyChange}
              reducersState={reducersState}
            />
            <Button type="primary" className="log-in-button" disabled={!isReady || adding} onClick={this.handleSave}>
              {getIntl().common.save}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default PayCardPage;
