import React from 'react';
import dayjs from 'dayjs';
import InputItem from 'antd-mobile/es/input-item';
import AntdIcon from 'antd-mobile/es/icon';
import ActionType from '@/actions/action-type';
import Modal from 'antd-mobile/es/modal';
import { parseRouteParams } from '@/utils';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import { sendEmail } from '@/data/orders';
import Icon, { IconType } from '@/components/Icon';
// import Layout from '@/components/Layout'
import paths from '@/routes/paths';
import { IOrderInfo } from '@/lib/order-builder/models/OrderEntity';
import ShippingMethod from '@/lib/order-builder/enums/ShippingMethod';
import { IFeedbackTags, IFeedback } from '@/data/Feedback';
import SingleStoreOrder from './SingleStoreOrder';
import Feedback from './Feedback';
import OrderInformation from './OrderInformation';
import { prefix } from '.';
import { getAppTheme, setScreenColor, getIntl, getIntlByPath } from '@/components/App/App';
import { getInvoiceMessage } from '@/data/Invoice';
import { formatCurrency } from '@/components/Payment';
import Toast from 'antd-mobile/es/toast';
import Skeleton from './Skeleton';
import { OrderDetailStyled } from './styled';
import Layout from '@/components/Layout/Layout';
import { getAccessToken, IParseOpeningHoursResult, parseOpeningHours, getCurrentShippingMethod } from '@/utils/app';

export interface OrderDetailPageProps extends IBasePageProps {};

interface IOrderDetailPageState {
  order: IOrderInfo | undefined;
  showModal: boolean;
  emailErr: boolean;
  email: string;
  sendEmail: boolean;
  sentSuccess: boolean;
  showFoodBack: boolean;
  feedBackTag: IFeedbackTags | undefined;
  existence: boolean;
  feedBack: IFeedback | undefined;
  canFeedback: boolean;
  fapiao: boolean;
  rating: number;
}

class OrderDetailPage extends BasePage<OrderDetailPageProps, IOrderDetailPageState> {
  
  token = getAccessToken();

  state: IOrderDetailPageState = {
    order: undefined,
    showModal: false,
    emailErr: false,
    email: '',
    sendEmail: false,
    sentSuccess: false,
    showFoodBack: false,
    feedBackTag: undefined,
    existence: false,
    feedBack: undefined,
    canFeedback: false,
    fapiao: false,
    rating: 0,
  }

  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.primaryColor && appTheme.darkBackgroundColor) {
      setScreenColor(appTheme.primaryColor, appTheme.darkBackgroundColor);
    }
    this.hidePageLoading();

    const { dispatch } = this.props;
    const routeParams = parseRouteParams(this.props);
    dispatch({
      type: ActionType.QUERY_ORDER_INFO,
      storeSlug: routeParams.storeSlug,
      orderNumber: routeParams.orderNumber,
      callback: (orderInfo, store, storeConfig) => {
        this.setState({
          order: orderInfo,
          canFeedback: storeConfig ? storeConfig.showFeedback : false,
          fapiao: storeConfig ? storeConfig.fapiao : false,
        });
      }
    });
  }

  handleCloseModal = () => {
    this.setState({
      showModal: false,
      email: '',
      showFoodBack: false,
    })
  }

  handleReOrder = () => {
    const { dispatch, reducersState } = this.props;
    const { order } = this.state;
    const routeParams = parseRouteParams(this.props);
    const { slugRefStore, slugRefStoreConfig } = reducersState.store;
    const store = slugRefStore[routeParams.storeSlug];
    const storeConfig = slugRefStoreConfig[routeParams.storeSlug];
    const { openingHours } = store || {};
    let parseOpeningHoursResult: IParseOpeningHoursResult | undefined = undefined;
    if (openingHours) {
      parseOpeningHoursResult = parseOpeningHours(openingHours);
    }
    const currentShippingMethod = getCurrentShippingMethod(order, storeConfig);
    let isOpen = false;
    if (parseOpeningHoursResult) {
      isOpen = parseOpeningHoursResult.isOpen || false;
      if (currentShippingMethod === ShippingMethod.PICK_UP && storeConfig && storeConfig.pickUpAdvanceOrderDate > 1) {
        isOpen = true;
      } else if (currentShippingMethod === ShippingMethod.DELIVERY && storeConfig && storeConfig.deliveryAdvanceOrderDate > 1) {
        isOpen = true;
      }
    }
    if (order && isOpen) {
      dispatch({
        type: ActionType.ADD_TO_SHOPPING_CART_BY_ORDER,
        storeID: order.storeID,
        orderNumber: order.orderNumber,
        suborderNumber: order.orderNumber !== routeParams.suborderNumber ? routeParams.suborderNumber : '',
        callback: (storeID, shoppingCartData) => {
          if (shoppingCartData && storeID) {
            this.handleGotoRoute({
              path: paths.SHOPPING_CART,
              pathParams: {
                storeSlug: routeParams.storeSlug,
              },
            });
          }
        }
      })
    } else if (!isOpen) {
      Modal.alert(getIntl().common.tips, getIntl().page.closed, [
        {
          text: getIntl().page.ok,
          onPress: () => {
          },
        },
      ]);
    }
  }
  
  handleGoInvoice = async () => {
    const { dispatch } = this.props;
    dispatch({
      type: ActionType.LOADING,
      showLoading: true,
    })
    const { order } = this.state;
    if(order){
      const storeID = order.storeID;
      const orderNumber = order.orderNumber;
      const state = await getInvoiceMessage({storeID, orderNumber, name:''});
      if(state?.status === 400){
        Toast.fail(state.message);
        return false;
      }
      this.handleGotoRoute({
        path: paths.INVOICE_TYPE,
        pathParams: {
          storeID:storeID,
          invoiceType:state?.invoiceStatus
        }
      });
    }
    dispatch({
      type: ActionType.LOADING,
      showLoading: false,
    })
  }

  handleShowModal = (storeID: string, orderNumber: string) => {
    const {
      reducersState,
    } = this.props

    const { order } = this.state;

    if (reducersState.user.isGuest) {
      this.setState({
        showModal: true
      })
    } else if (order && reducersState.user.userInfo && reducersState.user.userInfo.email) {
      this.handleSendEmail(storeID, orderNumber, reducersState.user.userInfo.email)
    } else {
      Modal.alert(getIntl().common.tips, getIntl().page.userNoEmail, [
        {
          text: getIntl().common.cancel,
          onPress: () => {},
        },
        {
          text: getIntl().common.confirm,
          onPress: () => {
            this.handleGotoRoute({
              path: paths.ACCOUNT_EDIT,
            });
          },
        }
      ]);
    }
  }

  handleUserBlur = (value: string) => {
    const reg = /^([a-zA-Z]|[0-9])(\w|-|.)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
    const veriftyRes = reg.test(value);

    this.setState({
      emailErr: !veriftyRes,
      email: value
    });
    return veriftyRes;
  }

  handleChang = (value: string) => {
    this.setState({
      email: value,
      emailErr: false,
    })
  }

  handleSendEmail = async (storeID: string, orderNumber: string, email: string) => {
    let sentSuccess = false;
    let showModal = true;
    this.setState({ sendEmail: true });
    const state = await sendEmail({storeID, orderNumber, templateType: 'email_receipt', email});
    
    if (state && state.success) {
      sentSuccess = true;
      showModal = false;
    }
    this.setState({
      sendEmail: false,
      sentSuccess,
      email,
      showModal,
    });
  }

  handleShowFoodBack = (platformID: string, storeID: string, userID: string, orderNumber: string) => {
    const { 
      dispatch,
    } = this.props
    const {
      order,
    } = this.state;

    if (order) {
      dispatch({
        type: ActionType.LOADING,
        showLoading: true,
      })
      dispatch({
        type: ActionType.QUERY_FEEDBACK_TAGS,
        platformID,
        storeID,
        userID,
        orderNumber,
        callback: (feedBackTag, existence, feedBack) => {
          this.setState({
            feedBackTag,
            existence,
            feedBack,
            rating: feedBack ? feedBack.score : 0,
            showFoodBack: true,
          })
          dispatch({
            type: ActionType.LOADING,
            showLoading: false,
          })
        }
      })
    }
  }

  handleLeftClick = () => {
    const { sentSuccess } = this.state;
    const routeParams = parseRouteParams(this.props);
    if (sentSuccess) {
      this.setState({
        showModal: false,
        sentSuccess: false,
        email: '',
      })
    } else {
      this.handleGoBack({
        path: routeParams.from === 'orders' ? paths.ORDERS : paths.RECORDS,
        runGoBack: true,
      })
    }
  }

  render() {
    const {
      order,
      showModal,
      emailErr,
      email,
      sendEmail,
      sentSuccess,
      showFoodBack,
      feedBackTag,
      existence,
      feedBack,
      rating,
      canFeedback,
      fapiao,
    } = this.state

    const { 
      dispatch,
      reducersState,
    } = this.props

    const { app, user } = reducersState;
    const { userInfo } = user
    const { platformAppSettings } = app;
    const platformID = platformAppSettings ? platformAppSettings.id : '37';
    const userID = userInfo ? userInfo.id : '';
    const routeParams = parseRouteParams(this.props);
    let orderInfo: any = '';
    order && order.orderEntities.forEach(item => {
      if (item.orderNumber === routeParams.suborderNumber) {
        const deliveryDate = dayjs(item.deliveryDate || order.orderTime);
        let showTime = true;
        if (deliveryDate.format('HH:mm:ss') === '00:00:00') {
          showTime = false;
        }

        const date  =  dayjs().diff(deliveryDate, 'day') === 0 ? (`${getIntl().common.today} ${showTime ? deliveryDate.format('HH:mmA') : ''}`) : (showTime ? deliveryDate.format('MM-DD HH:mmA') : deliveryDate.format('MM-DD'));
        const invalidData =  date=== 'Invalid Date';

        orderInfo = (
          <div>
            <div className="section no-padding">
              <div className="container top w-container">
                <div className="div-block-485 payment-confirmation">
                  <div className="left-block payment-confirmation">
                    <div className="order-block">
                      <SingleStoreOrder data={item} key={item.id} reducersState={reducersState} />
                      <div className="div-block-493">
                        <div className="div-block-484">
                          <div className="text-block-98">{getIntl().common.subtotal}</div>
                          <div className="text-block-68">{formatCurrency(item.subtotal, order.currencyCode)}</div>
                        </div>
                        {
                          item.discountTotal !== 0 &&
                          <div className="div-block-484">
                            <div className="text-block-98">
                              {getIntl().common.discount}
                            </div>
                            <div className="text-block-68">
                              -{formatCurrency(item.discountTotal, order.currencyCode)}
                            </div>
                          </div>
                        }
                        {
                          item.initialServiceFee !== 0 &&
                          <div className="div-block-484">
                            <div className="text-block-98">{getIntl().common.serviceFee}</div>
                            <div className="text-block-68">{formatCurrency(item.initialServiceFee, order.currencyCode)}</div>
                          </div>
                        }
                        {
                          item.initialTax !== 0 &&
                          <div className="div-block-484">
                            <div className="text-block-98">{getIntl().common.taxFee}</div>
                            <div className="text-block-68">{formatCurrency(item.initialTax, order.currencyCode)}</div>
                          </div>
                        }
                        {
                          item.initialRounding !== 0 &&
                          <div className="div-block-484">
                            <div className="text-block-98">{getIntl().common.rounding}</div>
                            <div className="text-block-68">{formatCurrency(item.initialRounding, order.currencyCode)}</div>
                          </div>
                        }
                        <div className="div-block-484">
                          <div className="text-block-67 bold">{getIntl().common.total}</div>
                          <div className="text-block-68 bold">{formatCurrency(item.initialTotal, order.currencyCode)}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="right-block rounded-all">
                    {
                      order && order.from !== 2 &&
                      <div className="delivery-info-block">
                        <div className="order-confirmation-info">
                          <div className="div-block-506">
                            <div className="text-block-101">{getIntlByPath(`common.${item.orderStatus}`)}</div>
                            <div className="text-block-102">#{item.referenceID}</div>
                          </div>
                          {
                            item.orderStatus !== 'unpaid' &&
                            <div className="text-block-67 grey">
                              {getIntlByPath(`page.${item.orderStatus}Tip`)}
                            </div>
                          }
                          <div className="div-block-507">
                            {
                              order.from === 4 &&
                              <div className="secondary-payment-confirmation-buttons-copy" onClick={this.handleReOrder}>
                                <div className="text-block-17">{getIntl().page.reOrder}</div>
                              </div>
                            }
                            {
                              fapiao &&
                              <div className="primary-payment-confirmation-buttons" onClick={this.handleGoInvoice}>
                                <div className="text-block-17">{getIntl().page.invoice}</div>
                              </div>
                            }
                            {
                              canFeedback &&
                              <div className="primary-payment-confirmation-buttons"
                                onClick={() => this.handleShowFoodBack(platformID, item.storeID, userID, item.orderNumber)}
                              >
                                <div className="text-block-17">{getIntl().page.feedback}</div>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    }
                    {
                      order && order.from !== 2 && !invalidData &&
                      <div className="order-confirmation-info-padding">
                        <div className="order-flex bold">
                          <div className="time-name">{getIntl().page.takeAwayTime}</div>
                          <div className="time">
                            {
                              (item.shippingMethod === ShippingMethod.PICK_UP || item.shippingMethod === ShippingMethod.DELIVERY) &&
                              date
                            }
                            {
                              (item.shippingMethod === ShippingMethod.DINE_IN || item.shippingMethod === ShippingMethod.DINE_IN_UNASSIGNED) &&
                              getIntl().page.immediately
                            }
                          </div>
                        </div>
                        {
                          item.shippingMethod === ShippingMethod.DELIVERY && order.orderShippingAddress.address_1 &&
                          <div>
                            <div className="div-block-483"></div>
                            <div className="order-flex">
                              <div className="icon">
                                <Icon type={IconType.MAP_OUTLINED} />
                              </div>
                              <div>
                                <div className="text-block-67 bold">
                                  {order.orderShippingAddress.address_1}
                                </div>
                                <div className="customer">
                                  <div className="customer-name">
                                    {order.orderShippingAddress.full_name}
                                  </div>
                                  <div className="customer-phone">
                                    {order.orderShippingAddress.phone}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                    }
                    
                    <OrderInformation
                      method={item.shippingMethod}
                      orderNumber={item.orderNumber}
                      referenceID={item.referenceID}
                      orderTime={order.orderTime}
                      deliveryDate={item.deliveryDate || order.orderTime}
                      saleTransactions={order.saleTransactions}
                    />
                    {/* <div className="send-email" onClick={() => this.handleShowModal(item.storeID, item.orderNumber)}>
                      {getIntl().page.emailReceipt}
                    </div> */}
                  </div>
                </div>
                {/* <div className="send-email" onClick={() => this.handleShowModal(item.storeID, item.orderNumber)}>
                  {getIntl().page.emailReceipt}
                </div> */}
              </div>
              <Modal
                visible={showModal}
                className={`${prefix}-modal`}
                transparent
                onClose={this.handleCloseModal}
                title={getIntl().page.emailReceipt}
                footer={[
                  {
                    text: getIntl().common.cancel,
                    onPress: this.handleCloseModal
                  },
                  {
                    text: getIntl().common.send,
                    onPress: () => { 
                      const correct = this.handleUserBlur(email);
                      if (correct) {
                        this.handleSendEmail(item.storeID, item.orderNumber, email);
                      }
                    }
                  }
                ]}
              >
                <div className="text-tip">{getIntl().page.guestTip}</div>
                <div className="input">
                  <InputItem
                    className={emailErr ? 'error' : ''}
                    value={email}
                    placeholder={getIntl().page.enterEmail}
                    onChange={this.handleChang}
                    extra={
                      email &&
                      <Icon
                        type={IconType.CLOSE_OUTLINED}
                        className="card-icon-close"
                        onClick={() => this.handleChang('')}
                      />
                    }
                  />
                  {
                    emailErr &&
                    <div className="message">{getIntl().page.emailError}</div>
                  }
                </div>
              </Modal>
            </div>
          </div>
        )
      }
    })

    return (
      <Layout
        {...this.props}
        className={prefix}
        navLeftContent={<Icon className="html-embed-7 w-embed left-circle" type={IconType.LEFT_OUTLINED} />}
        navContent={sentSuccess ? getIntl().page.emailReceipt : order && order.from === 2? getIntl().page.paymentConfirmation: ''}
        onLeftClick={this.handleLeftClick}
        isFooter={false}
      >
        {
          !order &&
          <Skeleton onLeftClick={this.handleLeftClick} />
        }
        <OrderDetailStyled>
          <div className={`${prefix}-new bm-p-new-order-detail`}>
            <div className="background-top with-bg-v2"></div>
            {
              !sentSuccess && order &&
              <>{orderInfo}</>        
            }

            {
              sendEmail &&
              <div className="start-loading-data">
                <div className="loading-div">
                  <div>
                    <AntdIcon type="loading" />
                    <div className="loading-msg">
                      {getIntl().page.sendEmail}
                    </div>
                  </div>
                </div>
              </div>
            }

            {
              sentSuccess &&
              <div className="success">
                <div>
                  <div className="success-icon">
                    <Icon type={IconType.STATUS_SUCCESS}/>
                  </div>
                  <div className="sent-success">
                    {getIntl().page.sentSuccess}
                  </div>
                  <div className="sent-success-tip">
                    {getIntl().page.emailAddress}<label>{` "${email}".`}</label>
                  </div>
                </div>
              </div>
            }
            {
              showFoodBack && order &&
              <Feedback
                onCancel={this.handleCloseModal}
                dispatch={dispatch}
                storeTitle={order.storeTitle}
                platformID={platformID}
                storeID={order.storeID}
                userID={userID}
                orderNumber={order.orderNumber}
                tag={feedBackTag}
                existence={existence}
                feedBack={feedBack}
                nowRating={rating}
              />
            }  
          </div>
        </OrderDetailStyled>
      </Layout>
    )
  };
};

export default OrderDetailPage;
