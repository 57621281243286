export default {
  invoice: 'Invoice',
  enterprise: 'Enterprise',
  personal: 'Personal',
  invoiceName: 'Name',
  invoiceEmail: 'E-mail',
  invoiceType: 'Invoice Type',
  otherInfo: 'Other Info',
  companyName: 'Company Name',
  taxID: 'Tax ID',
  beneficiaryBank: 'Beneficiary Bank',
  bankAccount :'Bank Account',
  enterpriseAddress: 'Enterprise Address',
  enterprisePhone: 'Enterprise Phone',
  confirm: 'Confirm',
  submitted1: 'Invoice has been submitted',
  submitted2: 'Please checj the phone / mailbox later or scan the',
  submitted3: 'invoicing QR code again to download invoice',
  invoicing1: 'Invoicing',
  invoicing2: 'The invoicing information was submittedsuccessfully',
  invoicing3: 'and is being invoiced. Please wait ...',
  failed1: 'Failed to submit application',
  failed2: 'Your invoicing application has failed,please re-invoice',
  invoiceQR1: 'QR code was invalid',
  invoiceQR2: 'Please contact the merchant: ',
  invoiceWait1: 'Order are being synchronized',
  invoiceWait2: 'The invoice order is being synchronized,please',
  invoiceWait3: 'invoice layer',
  payer: 'Payer',
  payee: 'Payee',
  invoiceAmount: 'Invoice Amount',
  issueTime: 'Issue Time',
  invoiceCode: 'Invoice Code',
  invoiceNumber: 'Invoice Number',
  downloadInvoice: 'Download Invoice（PDF）',
  rebill: 'Rebill',
  viewDetails: 'Details',
}
