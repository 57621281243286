import React from 'react';
import Steps from 'antd-mobile/es/steps'
import dayjs from 'dayjs'
import Icon, { IconType } from '@/components/Icon';
import OrderEntity from '@/lib/order-builder/models/OrderEntity';
import ListingsItem from '@/components/ListingsPanel/ListingsItem';
import { IReducersState } from '@/reducers';
import { getI18nText, stringToBase64QrCode } from '@/utils/app';
import ShippingMethod from '@/lib/order-builder/enums/ShippingMethod';
import { getIntl } from '@/components/App/App';

interface ISingleStoreOrderProps {
  data: OrderEntity;
  reducersState: IReducersState;
}

const orderStates: {[key: string]: number} = {
  paid: 0,
  preparing: 1,
  ready: 2,
  completed: 3,
};

const SingleStoreOrder: React.FC<ISingleStoreOrderProps> = props => {
  const {
    data,
    reducersState,
  } = props;

  const Paid = getIntl().common.paid;
  const Preparing = getIntl().common.preparing;
  const Ready = getIntl().common.ready;

  const isPaid = <Steps.Step title={Paid} className="adjustment-icon" description={dayjs(data.updatedAt).format('HH:mm')} icon={<Icon className="time" type={IconType.TIME_SOLID_OUTLINED} />} />
  const isPreparing = <Steps.Step title={Preparing} className="adjustment-icon" description={dayjs(data.updatedAt).format('HH:mm')} icon={<Icon className="time" type={IconType.TIME_SOLID_OUTLINED} />} />
  const isReady = <Steps.Step title={Ready} className="adjustment-icon" description={dayjs(data.updatedAt).format('HH:mm')} icon={<Icon className="time" type={IconType.TIME_SOLID_OUTLINED} />} />
  const noPaid = <Steps.Step title={Paid} status={orderStates[data.orderStatus] > 0 ? 'wait' : 'process'} icon={<Icon className="origin" type={IconType.UN_SELECTED_OUTLINED} />} />
  const noPreparing = <Steps.Step title={Preparing} status={orderStates[data.orderStatus] > 1 ? 'wait' : 'process'} icon={<Icon className="origin" type={IconType.UN_SELECTED_OUTLINED} />} />
  const noReady = <Steps.Step title={Ready} status={'process'} icon={<Icon className="origin" type={IconType.UN_SELECTED_OUTLINED} />} />

  const { orderNumber } = data
  const orderInfo = reducersState.order.orderNumberRefOrder[orderNumber];
  let from = undefined;

  if (orderInfo && orderInfo.from) {
    from = orderInfo.from;
  }

  return (
    <div>
      <div className="check-out-info restaurant-info text-block-67 bold">
        <div>{data.storeTitle}</div>
      </div>
      {
        from && from !== 2 &&
        <>
          {
            data.pickupLocation && data.shippingMethod === ShippingMethod.PICK_UP && 
            <div className="tinted-background"> 
              <div className="div-block-508">
                <div className="text-block-67">{getIntl().page.pickupAddress}</div>
                <div className="address-fields no-line">
                  <Icon type={IconType.MAP_OUTLINED} className="html-embed-25 w-embed" />
                  <div className="text-block-69">{getI18nText(data.pickupLocation, 'name', reducersState.app.language) || (data.pickupLocation && data.pickupLocation.name)}</div>
                </div>
              </div>
            </div>
          }
          {
            data.orderStatus !== 'completed' &&
            <div className="div-block-534">
              <div className="div-block-530">
                <Steps size={'pointer'} direction="horizontal">
                  {
                    data.orderStatus === 'paid' ? isPaid : noPaid
                  }
                  {
                    data.orderStatus === 'preparing' ? isPreparing : noPreparing
                  }
                  {
                    data.orderStatus === 'ready' ? isReady : noReady
                  }
                </Steps>
              </div>
              <div className="div-block-533"  style={{backgroundImage: `url(${stringToBase64QrCode(data.referenceID)})`}}></div>
            </div>
          }
        </>
      }
      {
        data.lineItems.map(item => {
          return (
            <div className="bm-c-listings-panel store-listings-panel" key={item.uuid || item.id || ''}>
              <ListingsItem
                data={item}
                showRightTopCount={true}
                reducersState={reducersState}
              />
            </div>
          );
        })
      }
      {
        data.note &&
        <>
          <div className="div-block-483"></div>
          <div className="remark-block-tablet">
            <div className="text-block-90">{getIntl().page.remark}</div>
            <div className="div-block-502">
              <div className="remark-note">{data.note}</div>
            </div>
          </div>
        </>
      }
    </div>
  );
}

export default SingleStoreOrder;
