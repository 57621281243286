import React, { useState, useEffect } from 'react';
import StoreList, { FootStyle } from '../StoreList';
import { IStore } from '@/actions/store-action';
import { IReducersState } from '@/reducers';
import { prefix } from '.';
import { SelectStoresStyled } from './styled';
import { getIntl } from '../App/App';
import Config from '@/Config';
interface ISelectStoresProps {
  data: IStore[];
  reducersState: IReducersState;
  loading: boolean;
  onItemClick?: (data: IStore) => void;
}

const SelectStores: React.FC<ISelectStoresProps> = props => {
  const {
    data,
    loading,
    reducersState,
    onItemClick = () => {},
  } = props;

  const [selectStore, setSelectStore] = useState<IStore | undefined>(undefined);

  const { userAssociateStoreMap, selectStoreSlug } = reducersState.supplierInfo;
  const { userInfo } = reducersState.user;

  useEffect(() => {
    getSelectStore();
  }, [reducersState, selectStoreSlug]);

  const getSelectStore = () => {
    if (selectStoreSlug && userAssociateStoreMap.has(selectStoreSlug)) {
      setSelectStore(userAssociateStoreMap.get(selectStoreSlug));
    }
  }
  return (
    <SelectStoresStyled>
      <div className="nav_bg streched with-space w-nav">
        <div className="container fixed-nav _1 w-container">
          <h6 className="heading-6">{getIntl().page.supplierOrderfor}</h6>
          <div className="profile-img top" style={{backgroundImage: `url(${selectStore && selectStore.imgUrl})`}}></div>
        </div>
      </div>
      {
        data.length > 0 &&
        <div className="section _1">
          <StoreList
            data={data}
            reducersState={reducersState}
            loading={loading}
            onItemClick={onItemClick}
            className={`${prefix}-store-list`}
            itemClassName={`${prefix}-store-list-item`}
            footStyle={FootStyle.STORE_ADDRESS}
          />
        </div>
      }
      {
        data.length === 0 &&
        <div className="section _1">
          <div className="default-bg-wrap"><img className="default-bg" src={`${Config.cdnUrl}/images/select-store-icon.png`} alt="" /></div>
          <div className="default-con">
            {
              userInfo &&
              <div className="user-id">{getIntl().page.buyerUserID}<span>{userInfo.id}</span></div>
            }
            <div className="user-tips">{getIntl().page.buyerUserTips}<br/><span>{getIntl().common.birdymart}</span>{getIntl().page.buyerUserContact}</div>
          </div>
        </div>
      }
    </SelectStoresStyled>
  )
}

export default SelectStores;
