import styled from 'styled-components';
export const AddressListPageStyled = styled.div`
.top-header.widen{
  box-sizing: border-box;
}
width: 85%;
margin-top: 50px;
padding: 80px 0px;
/* border-radius: 12px; */
min-height: 80%;
background-color: var(--bm-dark-background-color);
padding-top: 40px;
padding-bottom: 40px;
.section {
  display: block;
  margin-top: auto;
  margin-bottom: 0px;
  padding: 60px 40px 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0);
}
.section.address {
  padding-top: 0px;
  padding-bottom: 0px;
}
.add-new-address {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  padding: 10px 0px;
  border-radius: 0px;
  box-shadow: none;
  color: var(--bm-text-title-color);
  cursor: text;
  height: 20px;
  input {
    width: 100%;
    border: none;
    font-size: 18px;
  }
}
.add-new-address.transaprent {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 390px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 8px;
  background-color: var(--bm-light-background-color);
  cursor: pointer;
  box-sizing: border-box;
    min-height: 60px;
}

.div-block-638 {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 14px;
  grid-row-gap: 14px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}
.div-block-499 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.div-block-498 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30px;
  height: 25px;
  margin-right: 10px;
  padding: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--bm-primary-color);
  svg {
    width: 100%;
  }
}
.text-block-94 {
  margin-bottom: 0px;
  margin-left: 0px;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
}
/* .html-embed-37 {
  width: 12px;
  height: 15px;
  color: var(--bm-primary-color);
  svg {
    width: 100%;
  }
} */
.choose-existing-address {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px 0px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 0px 0px 1px;
  border-color: #000 #000 var(--bm-border-color);
  color: var(--bm-text-title-color);
  cursor: auto;
  box-sizing: border-box;
}
.choose-existing-address._1 {
  padding: 20px;
  border-radius: 8px;
  background-color: var(--bm-light-background-color);
}
.html-embed-25 {
  width: 50px;
  height: 25px;
  margin-right: 10px;
  text-align: center;
  color: var(--bm-text-color);
  min-width: 30px;
  svg {
    width: 100%;
  }
}
.div-block-488 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.div-block-487 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: auto;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  color: var(--bm-text-title-color);
  cursor: pointer;
}
.html-embed-41 {
  position: static;
  left: auto;
  top: auto;
  right: 0%;
  bottom: auto;
  width: 20px;
  height: auto;
  margin-right: 5px;
  margin-left: 10px;
  color: var(--bm-text-color);
  cursor: pointer;
}
.modal-wrapper-edit-address {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 999999999;
  overflow: auto;
  padding: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-perspective: 850px;
  perspective: 850px;
  cursor: default;
  -o-object-fit: none;
  object-fit: none;
  opacity: 1;
  display: flex;
  background-color: var(--bm-modal-b-color);
}
.c-popup-close-2 {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 5;
  display: block;
  cursor: pointer;
}
.flex-pop-up-edit.edit-address {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-height: 900px;
  overflow: scroll;
  padding-bottom: 30px;
  /* top: 0; */
}
.flex-pop-up-edit {
  position: relative;
  z-index: 10;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  width: 600px;
  height: auto;
  margin: auto;
  padding: 0px 0px 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border: 0px solid transparent;
  border-radius: 6px;
  background-color: var(--bm-light-background-color);
  box-shadow: 0 9px 50px -14px #000;
  color: var(--bm-text-title-color);
  text-align: center;
}
.top-header {
  display: none;
  height: 80px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid var(--bm-border-color);
}
.top-header.widen {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.popup-body-content {
  margin-bottom: 0px;
  padding-right: 20px;
  padding-left: 20px;
}
.heading-2 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: 'Sf pro display', sans-serif;
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  color: var(--bm-text-title-color);
}
.x-icon.close-modal {
  display: none;
  margin-left: auto;
}
.x-icon {
  display: none;
  width: 20px;
  color: var(--bm-text-title-color);
}
.close-icon {
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 20;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 25px;
  cursor: pointer;
  svg {
    width: 100%;
  }
}
.bm-c-google-map-show-location {
  top: 160px;
  right: 40px;
  background-color: var(--bm-light-background-color);
  position: absolute;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  z-index: 4;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 0;
}
.bm-c-google-map-search {
  position: relative;
  height: 330px;
  margin-right: -20px;
  margin-bottom: 20px;
  margin-left: -20px;
  height: 100vh;
  width: unset;
}
.div-block-519 {
  position: relative;
  height: 330px;
  margin-right: -20px;
  margin-bottom: 20px;
  margin-left: -20px;
}
.div-block-639 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  text-align: start;
}
.div-block-497 {
  padding: 0px;
  background-color: var(--bm-light-background-color);
}
.link-block {
  position: relative;
  left: auto;
  top: 0px;
  right: 0%;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: 55px;
  margin-top: 20px;
  margin-right: 0px;
  margin-left: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-color: var(--bm-primary-color);
  color:  var(--bm-light-background-color);
  text-decoration: none;
}
.text-block-17 {
  font-family: 'Sf pro display', sans-serif;
  font-size: 18px;
  font-weight: 600;
}
.edit-addresses {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 23px 0px 20px 110px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 0px 0px 1px;
  border-color: #000 #000 #eaeaea;
  color: var(--bm-text-title-color);
  cursor: auto;
}
/* .div-block-488 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: unset;
} */
.div-block-488.absolute {
  position: absolute;
  left: 0px;
  margin-right: 20px;
}
.text-block-77 {
  margin-right: auto;
  margin-bottom: 5px;
  margin-left: 0px;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  line-height: 20px;
}
.text-block-77.no-space {
  margin-bottom: 0px;
}
.text-block-69 {
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  font-size: 16px;
}
.am-list-item.am-input-item {
  min-height: unset;
  height: unset;
  width: unset;
  border: 0;
  margin-left: 0;
}
.add-new-address {
  .bm-c-google-map-search-wapper-icon {
    position: unset;
    height: 20px;
    padding: 0;
    margin-top: 0;
    font-size: 21px;
  }
}
.am-list-item .am-input-control input {
  font-size: 16px;
}
.close-icon.close-modal {
  width: 30px;
}
.choosing-address {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px 0px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 0px 0px 1px;
  cursor: auto;
  border-bottom: 1px solid #999;
  .bm-icon,
  .div-block-488,
  .text-block-49 {
    color: var(--bm-text-secondary-color);
  }
}
.am-swipe-content {
  background-color: transparent;
}
.flex-pop-up-edit.edit-address {
  max-height: 777px;
}
.am-swipe-btn-text {
  padding: 15px;
}
.am-swipe {
  .am-swipe-actions {
    display: none;
    height: 100%;
    .am-swipe-btn {
      height: 100%;
    }
  }
}


    

@media screen and (max-width: 991px) {
  margin-left: 40px;
  padding-top: 30px;
  padding-bottom: 30px;
  .section {
    margin-top: 0px;
    padding: 40px 35px 30px;
  }
  .div-block-638 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .add-new-address {
    border-radius: 0px;
  }
  .add-new-address.transaprent {
    width: auto;
  }
  .choose-existing-address._1 {
    margin-right: 0px;
    margin-left: 0px;
  }
  .html-embed-41 {
    height: 15px;
  }
  .c-popup-close-2 {
    cursor: pointer;
  }
  .edit-addresses {
    margin-right: 20px;
    margin-left: 20px;
  }
  .choosing-address {
    margin-right: 20px;
    margin-left: 20px;
  }
  
}

@media screen and (max-width: 767px) {
  width: auto;
  margin-top: 0px;
  margin-left: 0px;
  padding-top: 0px;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  .section {
    padding: 83px 25px 20px;
  }
  .section.address {
    padding-right: 0px;
    padding-left: 0px;
    background-color: var(--bm-dark-background-color);
  }
  .div-block-638.address {
    display: block;
    background-color: var(--bm-light-background-color);
  }
  .choose-existing-address._1 {
    margin-left: 20px;
    border-radius: 0px;
  }
  .text-block-77 {
    margin-right: 0px;
    margin-left: 0px;
    font-size: 15px;
  }
  .modal-wrapper-edit-address {
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
    background-color: rgba(255, 255, 255, 0);
  }
  .c-popup-close-2 {
    background-color: var(--bm-modal-b-color);
    cursor: pointer;
  }
  .c-popup-close-2.close-modal {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    background-color: var(--bm-modal-b-color);
  }
  .flex-pop-up-edit {
    z-index: 800;
    width: 100vw;
    height: auto;
    margin-bottom: 0px;
    padding-bottom: 20px;
    border-radius: 0px;
    box-shadow: none;
  }
  .flex-pop-up-edit.edit-address {
    height: auto;
    padding-bottom: 20px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
  }
  .popup-body-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .top-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 60px;
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .top-header.widen {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 80px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .heading-2 {
    font-size: 27px;
  }
  .x-icon.close-modal {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-left: 0px;
  }
  .close-icon {
    position: relative;
    top: 40px;
    right: 40px;
    display: none;
    margin-left: auto;
  }
  .close-icon.close-modal {
    top: 0px;
    right: 0px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .add-new-address {
    margin-bottom: 0px;
    padding-left: 0px;
    border-radius: 12px;
  }
  .add-new-address.transaprent {
    width: 100vw;
    margin-top: 0px;
    margin-bottom: 10px;
    border-radius: 0px;
  }
  .bm-c-google-map-search-group {
    margin-bottom: 0;
  }
  .div-block-519 {
    margin-bottom: 0px;
  }
  .div-block-639 {
    margin-right: -20px;
    margin-left: -20px;
    padding-left: 20px;
    background-color: var(--bm-light-background-color);
  }
  .link-block {
    left: auto;
    top: -22%;
    right: -5%;
    bottom: 0%;
  }
  .link-block.streched {
    width: auto;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }
  .div-block-497 {
    border-radius: 12px;  
  }
  .div-block-497.wikth-space {
    margin-bottom: 20px;
  }
  .text-block-17 {
    font-size: 18px;
  }
  .edit-addresses {
    margin-right: -20px;
    margin-left: 0px;
  }
  .choosing-address {
    margin-right: 0px;
    margin-left: 0px;
  }
}
@media screen and (max-width: 479px) {
  .am-swipe {
    .am-swipe-actions {
      display: block;
    }
  }
  padding-top: 50px;
  .section {
    padding: 68px 12px 15px;
  }
  .section.address {
    padding-right: 0px;
    padding-left: 0px;
  }
  .div-block-498 {
    width: 24px;
    height: 20px;
    margin-right: 5px;
    padding: 4px;
  }
  .choose-existing-address {
    padding: 13px;
  }
  .choose-existing-address._1 {
    margin-left: 13px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 0px;
  }
  .html-embed-25 {
    width: 40px;
    height: 15px;
    margin-right: 5px;
  }
  .div-block-487 {
    margin-left: 10px;
    width: 40px;
  }
  .div-block-487-edit {
    margin-left: auto;
  }
  .html-embed-41 {
    /* position: absolute; */
    right: 13px;
    margin-right: 0px;
  }
  .text-block-69 {
    font-size: 14px;
  }
  .text-block-77 {
    margin-bottom: 0px;
  }
  .modal-wrapper-edit-address {
    height: 100vh;
    min-height: 100vh;
    padding: 0px;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
    background-color: hsla(0, 0%, 100%, 0);
  }
  .c-popup-close-2 {
    display: block;
  }
  .c-popup-close-2.close-modal {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .flex-pop-up-edit {
    position: absolute;
    left: 0%;
    top: auto;
    right: 0%;
    bottom: 0%;
    width: 100vw;
    height: auto;
    min-height: auto;
    min-width: 100vw;
    margin-top: auto;
    padding: 0px 0px 15px;
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .flex-pop-up-edit.edit-address {
    height: 95vh;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  .top-header {
    margin-right: 0px;
    margin-bottom: 10px;
    margin-left: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .top-header.widen {
    height: 80px;
    padding-top: 20px;
    padding-bottom: 10px;
  }
  .popup-body-content {
    height: auto;
    min-height: auto;
    padding-right: 12px;
    padding-left: 12px;
    padding-bottom: 0;
  }
  .x-icon.close-modal {
    width: 15px;
  }
  .heading-2 {
    font-size: 22px;
    font-weight: 600;
  }
  .add-new-address {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .add-new-address.transaprent {
    padding: 15px 13px;
  }
  .close-icon {
    svg {
      width: 29px;
      height: 25px;
    }
  }
  .bm-c-google-map-show-location {
    top: 200px;
    right: 39px;
    width: 35px;
    height: 35px;
  }
  .bm-c-google-map-search-group {
    height: 250px;
  }
  .div-block-519 {
    height: 250px;
  }
  .div-block-639 {
    margin-right: -12px;
    margin-left: -12px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 13px;
  }
  .div-block-497 {
    overflow: scroll;
    height: auto;
    margin-right: -12px;
    margin-left: -12px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border-radius: 0px;
  }
  .link-block {
    left: auto;
    top: 0%;
    right: 0%;
    bottom: 0%;
    width: auto;
  }
  .link-block.streched {
    height: 50px;
  }
  .text-block-17 {
    font-size: 16px;
  }
  .text-block-77.no-space {
    margin-left: 20px;
  }
  .edit-addresses {
    margin-right: 0px;
    padding: 13px 13px 13px 109px;
  }
  .am-list-item .am-input-control input {
    font-size: 14px;
  }
  .choosing-address {
    padding: 13px;
    .bm-icon {
      width: 18px;
    }
  } 

}

`;
