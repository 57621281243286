import styled from 'styled-components';

export const SignInStyled = styled.div`
  .bm-c-sign-in {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100vh;
    margin-top: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-image: url('https://assets.website-files.com/605173a784082e077698cb03/60dc2f106da262131702eec5_pexels-photo-1640777.jpeg');
    background-position: 50% 50%;
    background-size: cover;
    &-div-block-520 {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: 500px;
      height: 600px;
      padding: 20px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      border-radius: 8px;
      background-color: hsla(0, 0%, 100%, 0.83);
      box-sizing: border-box;
    }
    &-other-signin {
      &-div-block-522 {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        margin-bottom: 20px;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-align-self: stretch;
        -ms-flex-item-align: stretch;
        align-self: stretch;
      }
      &-line {
        position: static;
        left: 0%;
        top: 0%;
        right: auto;
        bottom: 0%;
        width: 45%;
        height: 1px;
        -webkit-align-self: center;
        -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
        background-color: #a39d9d;
      }
      &-text-block-5---subtitle {
        margin-right: 10px;
        margin-bottom: 0px;
        margin-left: 0px;
        font-family: 'Fontsfree net sfprodisplay', sans-serif;
        color: #999;
        font-size: 16px;
        text-align: left;
      }
    }
    @media screen and (max-width: 991px) {
      &-other-signin {
        &-text-block-5---subtitle {
          font-size: 14px;
        }
      }
    }
    @media screen and (max-width: 767px) {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      height: 95vh;
      padding-bottom: 20px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      background-image: none;
      background-size: auto;
      &-div-block-520 {
        width: 100%;
        height: 100%;
        min-height: 100%;
        min-width: 100%;
        border-radius: 0px;
        background-color: #fff;
      }
      &-other-signin {
        &-text-block-5---subtitle {
          margin-bottom: 0px;
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
    @media screen and (max-width: 479px) {
      height: 95vh;
      padding-bottom: 0px;
      &-div-block-520 {
        width: auto;
      }
      &-other-signin {
        &-text-block-5---subtitle {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
`;
export const SignInMobileStyled = styled.div`
  .signin-button {
  }
`;

export const SignInBottomStyled = styled.div`
  margin-right: 10px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-top: 20px;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: ${props => props.theme.textColor};
  font-size: 16px;
  text-align: left;
  .bottom-signup {
    color: ${props => props.theme.primaryColor};
  }
`;
