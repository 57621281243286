import React, { useEffect, useState } from 'react';
import { formatCurrency } from '../Payment';
import Icon, { IconType } from '../Icon';
import { IMenuItemProps } from './MenuItem';
import { getI18nText } from '@/utils/app';
import InputItem from 'antd-mobile/es/input-item';
import { getIntl } from '../App/App';

const MenuItemForItem: React.FC<IMenuItemProps> = props => {
  const {
    data,
    parentViewID,
    currencyCode,
    listingCountRef = {},
    className = '',
    isOpen,
    onlyShow = false,
    onPlusClick,
    onMinusClick,
    reducersState,
    onPopupClick,
    handleChangeQuantityTo,
  } = props;

  let initQuantity = 0;

  if (data && listingCountRef[data.id] && listingCountRef[data.id].quantity > 0) {
    initQuantity = listingCountRef[data.id].quantity;
  }

  const [quantity, setQuantity] = useState<string>('0');

  useEffect(() => {
    setQuantity(initQuantity.toString());
  }, [initQuantity]);

  const handleMinusClick = () => {
    if (onMinusClick) {
      onMinusClick(data);
    }
  }

  const handlePlusClick = () => {
    if (onPlusClick) {
      onPlusClick(data);
    }
  }

  const handlePopupClick = () => {
    if (onPopupClick) {
      onPopupClick(data);
    }
  }

  const handleCloseKeyboard = () => {
    let changeQuantity = parseInt(quantity);
    if (handleChangeQuantityTo) {
      if (isNaN(changeQuantity)) {
        changeQuantity = 0;
      }
      handleChangeQuantityTo(data, changeQuantity);
    }
  }
  const soldOut = data.soldOut || false;

  return (
    <div className={`item-wrapper-eshop ${className}`}>
      <div className="item-img-wrapper" style={{backgroundImage: 'url(/images/default-store.png)'}}> <div className="div-block-527" style={{backgroundImage: `url(${data.imageUrl})`}} onClick={isOpen && !soldOut ? handlePopupClick: undefined }/></div>
      <div className={`item-info-wrapper-eshop ${(!isOpen || soldOut || (data.status && data.status === 'Suspend') || (data.status && data.status === 'Deactivate')) ? 'sold-out' : ''}`}>
        <div className="item-info" onClick={isOpen && !soldOut && data.status !== 'Suspend' && data.status !== 'Deactivate' ? handlePopupClick: undefined }>
          <h4 className="heading-4-1">{data.aliasName || getI18nText(data, 'name', reducersState.app.language) || data.name}</h4>
          <div className="describe" dangerouslySetInnerHTML={{ __html: getI18nText(data, 'description', reducersState.app.language) || data.description}}></div>
        </div>
        <div className="price-eshop bottom">
          <div className="text-block-6">{formatCurrency(data.price, currencyCode)}</div>
          <div className="q-flex-cart">
            {
              !isOpen &&
              <div className="text-block-6">{getIntl().page.lineItemClosed}</div>
            }
            {
              isOpen && soldOut &&
              <div className="text-block-6">{getIntl().page.soldOut}</div>
            }
            {
              isOpen && data.status && data.status === 'Suspend' &&
              <div className="text-block-6">{getIntl().page.suspend}</div>
            }
            {
              isOpen && data.status && data.status === 'Deactivate' &&
              <div className="text-block-6">{getIntl().page.deactivate}</div>
            }
            {
              !onlyShow && isOpen && !soldOut && data.status !== 'Suspend' && data.status !== 'Deactivate' &&
              <>
                {
                  listingCountRef[data.id] && listingCountRef[data.id].quantity > 0 &&
                  <>
                    <Icon
                      className={`bm-icon q-dec-cart checkout-process item-minus minus-circle-outlined bm-test-minus-${parentViewID}-${data.id}`}
                      type={IconType.MINUS_CIRCLE_OUTLINED}
                      onClick={handleMinusClick}
                    />
                    
                    <div className={`count bm-test-count-${parentViewID}-${data.id}`}>
                      <InputItem
                        type="bankCard"
                        maxLength={2}
                        value={quantity}
                        onBlur={handleCloseKeyboard}
                        onChange={setQuantity}
                      />
                      {/* {listingCountRef[data.id].quantity} */}
                    </div>
                  </>
                }
                <Icon
                  className={`bm-icon item-plus checkout-process q-inc-cart plus-circle-outlined bm-test-plus-${parentViewID}-${data.id}`}
                  type={IconType.PLUS_CIRCLE_OUTLINED}
                  onClick={handlePlusClick}
                />
              </>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default MenuItemForItem;
