import { IRoute } from '@/routes';
import paths from '@/routes/paths';
import ExpressOrder from './ExpressOrder';

export const expressOrderRoutes: IRoute[] = [
  {
    path: paths.EXPRESS_ORDER,
    component: ExpressOrder,
  },
];

export default expressOrderRoutes;
