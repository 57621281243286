import styled from 'styled-components';
export const PrivilegePageStyled = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 101;
  background-color: var(--bm-border-color);
  overflow: scroll;
  .side-nav {
    position: relative;
    width: 20%;
    max-width: 20%;
    margin-right: 40PX;
    margin-left: 0PX;
    background-color: rgba(0, 69, 144, 0);
  }
  .whole {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 1600PX;
    margin-right: auto;
    margin-left: auto;
    padding-top: 70PX;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-right: 30PX;
    padding-left: 30PX;
  }
  .white-banner {
    position: fixed;
    z-index: 2;
    display: none;
    width: 100%;
    height: 70PX;
    max-height: 70PX;
    border-bottom: 1PX solid #eaeaea;
    background-color: var(--bm-light-background-color);
  }
  .member-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 1200PX;
    max-width: 1200PX;
  }
  .tabs-2 {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 85%;
    max-width: 85%;
    margin-top: 30PX;
    margin-bottom: 30PX;
    padding: 0PX 30PX;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    border-radius: 12PX;
    background-color: var(--bm-dark-background-color);
  }
  .tabs-menu-4 {
    position: -webkit-sticky;
    position: sticky;
    top: 70PX;
    z-index: 99;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    max-height: none;
    max-width: 200PX;
    margin-top: 0PX;
    margin-right: 40PX;
    margin-left: auto;
    padding-top: 20PX;
    padding-bottom: 20PX;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .my-priviledge-tab.w--current {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20PX;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    border-radius: 40PX;
    background-color: var(--bm-primary-color);
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: var(--bm-light-background-color);
    font-size: 16PX;
    font-weight: 700;
  }
  .w-tab-link {
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-decoration: none;
    padding: 9PX 30PX;
    text-align: left;
    cursor: pointer;
    color: var(--bm-text-color);
  }
  .my-priviledge-tab {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20PX;
    padding-right: 20PX;
    padding-left: 20PX;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    background-color: hsla(0, 0%, 86.7%, 0);
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: var(--bm-text-color);
    font-size: 16PX;
  }
  .text-block-200 {
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
  }
  .w-tab-content {
    position: relative;
    display: block;
    overflow: hidden;
  }
  .tabs-content {
    margin-top: 30PX;
    margin-bottom: 20PX;
    margin-left: auto;
    padding: 0PX;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border-radius: 0PX;
    background-color: hsla(0, 0%, 100%, 0);
  }
  .section.cms {
    display: block;
    margin-top: 0PX;
    margin-bottom: 40PX;
    padding: 0PX;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  .section {
    display: block;
    margin-top: auto;
    margin-bottom: 0PX;
    padding: 100PX 40PX 40PX;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0);
  }
  .div-block-537 {
    display: block;
    max-width: none;
    margin-right: auto;
    margin-left: auto;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  .item-wrapper.coupon {
    margin-bottom: 20PX;
  }
  .item-wrapper {
    display: block;
    border-radius: 8PX;
    background-color: var(--bm-light-background-color);
  }
  .coupon-block.open-modal {
    overflow: hidden;
    margin-bottom: 0PX;
    padding: 20PX 20PX 20PX 0PX;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    border-bottom-width: 0PX;
    border-radius: 8PX;
    background-color: #fff;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    background-color: var(--bm-light-background-color);
  }
  .coupon-block {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 0PX;
    padding-top: 40PX;
    padding-bottom: 40PX;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 1PX solid #f5f5f5;
    cursor: pointer;
  }
  .image-block.coupon {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    min-width: 140PX;
    margin-top: -20PX;
    margin-right: 15PX;
    margin-bottom: -20PX;
    padding-top: 0PX;
    background-repeat: no-repeat;
  }
  .image-block {
    overflow: hidden;
    width: 120PX;
    height: 120PX;
    min-width: 120PX;
    border-radius: 6PX;
    background-position: 50% 50%;
    background-size: cover;
  }
  .status-veil {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 30%;
    margin-top: auto;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: rgba(0, 69, 144, 0.6);
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: var(--bm-light-background-color);
    font-size: 22PX;
  }
  .item-info-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 70%;
    min-width: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .item-info.with-space {
    margin-bottom: 10PX;
  }
  .item-info {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 100%;
    margin-bottom: 0PX;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .price-cart {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 100%;
    margin-top: auto;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .heading-4-1 {
    margin-top: 0PX;
    margin-bottom: 10PX;
    font-family: 'Sf pro display', sans-serif;
    color: var(--bm-text-title-color);
    font-size: 18PX;
    font-weight: 600;
    text-align: left;
  }
  h4 {
    margin-top: 10PX;
    margin-bottom: 10PX;
    font-size: 18PX;
    line-height: 24PX;
    font-weight: bold;
  }
  .paragraph-2.less-space {
    margin-bottom: 5PX;
  }
  .paragraph-2 {
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: var(--bm-text-title-color);
    font-size: 14PX;
    line-height: 18PX;
  }
  p {
    margin-bottom: 10PX;
    font-size: 16PX;
  }
  .text-block-91 {
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: var(--bm-text-title-color);
    font-size: 18PX;
  }
  .text-block-6 {
    max-width: none;
    margin-top: 0PX;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    font-family: 'Sf pro display', sans-serif;
    color: var(--bm-text-title-color);
    font-size: 18PX;
    font-weight: 600;
  }
  .nav-button-1 {
    display: none;
    max-height: 32PX;
    max-width: 32PX;
    min-height: 32PX;
    min-width: 32PX;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: solid;
    border-width: 1PX;
    border-color: hsla(0, 0%, 100%, 0.4);
    border-radius: 80PX;
    background-color: var(--bm-light-background-color);
    color: var(--bm-text-title-color);
    div,
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .modal-body.coupon {
    overflow: scroll;
  }
  .item-image-pop-up-copy {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    z-index: 800000;
    width: 100%;
    height: 300PX;
    max-width: none;
    min-width: auto;
    background-color: transparent;
    background-position: 50% 50%;
    background-size: cover;
  }
  .popup-description.coupon.coupon {
    height: auto;
    margin-top: 290PX;
    border-radius: 0PX;
    background-color: var(--bm-dark-background-color);
  }
  .popup-description.coupon {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .popup-description {
    position: relative;
    box-sizing: border-box;
    z-index: 9999999;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    margin-top: 280px;
    padding: 20PX;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-radius: 16PX;
    background-color: var(--bm-light-background-color);
  }
  .div-block-540 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20PX;
    padding: 21PX;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    border-radius: 8PX;
    background-color: var(--bm-light-background-color);
  }
  .div-block-538 {
    width: 100%;
    height: 100%;
    border-radius: 8PX;
    background-color: var(--bm-light-background-color);
    background-position: 50% 50%;
    background-size: 100%;
    background-repeat: no-repeat;
  }
  .qr-code-block {
    position: relative;
    top: 0PX;
    width: 250PX;
    height: 250PX;
    min-height: 250PX;
    min-width: 250PX;
    margin: -237PX auto 30PX;
    padding: 8PX;
    border-style: solid;
    border-width: 2PX;
    border-color: #eaeaea;
    border-radius: 8PX;
  }
  .qr-code-cover {
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 8PX;
    font-size: 32PX;
    background-color: rgba(0, 0, 0, 0.35);
    text-align: center;
    align-items: center;
    .selected-outlined {
      background-color: var(--bm-light-background-color);
      border-radius: 50%;
      padding: 0;
      font-size: 70PX;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .heading-4-1.bigger {
    margin-right: auto;
    margin-left: auto;
    font-size: 23PX;
  }
  .div-block-484 {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .text-block-68.bold {
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    font-weight: 700;
  }
  .text-block-68.bold.color {
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: var(--bm-error-color);
    font-size: 18PX;
  }
  .text-block-98 {
    color: var(--bm-text-title-color);
  }
  .div-block-540 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20PX;
    padding: 21PX;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    border-radius: 8PX;
    background-color: var(--bm-light-background-color);
  }
  .rich-text-block {
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
  }
  h4 {
    margin-top: 10PX;
    margin-bottom: 10PX;
    font-size: 18PX;
    line-height: 24PX;
    font-weight: bold;
  }
  p {
    margin-top: 0;
    margin-bottom: 10PX;
  }
  .w-richtext ol,
  .w-richtext ul {
    overflow: hidden;
  }
  .c-popup-close.close-modal {
    background-color: var(--bm-modal-b-color);
  }
  .modal-body {
    position: relative;
    z-index: 10;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    width: 600PX;
    height: 90%;
    padding: 0PX;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    border-radius: 12PX;
    background-color: var(--bm-light-background-color);
    box-shadow: 0 9PX 50PX -14PX transparent;
    transform: unset;
  }
  .modal-wrapper {
    position: fixed;
    left: 0PX;
    top: 0PX;
    right: 0PX;
    bottom: 0PX;
    z-index: 99999999999;
    display: flex;
    padding: 40PX;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-perspective: 850PX;
    perspective: 850PX;
    cursor: default;
    opacity: 1;
  }
  .modal-body.coupon {
    opacity: 1;
    background-color: var(--bm-dark-background-color);
  }
  .text-block-98 {
    margin-right: auto;
    margin-bottom: 0PX;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    font-size: 18PX;
    font-weight: 400;
  }
  .card {
    .use-point {
      font-size: 32PX;
      line-height: 36PX;

      .available-points-wrap {
        border-bottom: 2PX solid var(--bm-border-color);
      }

      .available-points {
        display: flex;
        justify-content: space-between;
        padding-bottom: 14PX;
        font-size: 24PX;
        color: var(--bm-text-color);
      }

      .available-balance {
        display: flex;
        justify-content: space-between;
        padding-bottom: 14PX;
        font-weight: 500;
      }

      .reward-status {
        margin-top: 30PX;
        font-weight: 500;
      }
      .reward-content {
        margin-top: 12PX;
        display: flex;
        padding: 16PX 32PX;
        justify-content: space-between;
        background-color: var(--bm-dark-background-color);
        border: 2PX solid var(--bm-border-color);
        border-radius: 8PX;
        .reward-content-item-title {
          font-size: 20PX;
          line-height: 26PX;
          color: var(--bm-text-secondary-color);
        }
        .reward-item {
          font-size: 24PX;
          line-height: 30PX;
          color: var(--bm-text-color);
          margin-top: 16PX;
        }
        .reward-content-item:not(:first-child) {
          text-align: right;
        }
      }

      .reward-rule {
        margin-top: 32PX;
        font-size: 24PX;
        line-height: 28PX;
        color: var(--bm-skeleton-color);
      }

      .min-spending {
        margin-top: 4PX;
        font-size: 24PX;
        line-height: 28PX;
        color: var(--bm-skeleton-color);
        padding-bottom: 30PX;
        border-bottom: 2PX solid var(--bm-border-color);
      }

      .reward {
        font-weight: 500;
        margin-top: 24PX;
      }

      .reward-select-point {
        margin-top: 16PX;
        font-size: 22PX;
        line-height: 28PX;
      }

      .reward-input {
        padding-bottom: 14PX;
        border-bottom: 2PX solid var(--bm-border-color);

        .currency-symbol {
          margin-top: 14PX;
          margin-right: 16PX;
        }

        .total-points {
          padding: 10PX 0;
          font-size: 24PX;
          color: var(--bm-text-color);
        }

        .amount {
          font-weight: bold;
          font-size: 112PX;
          line-height: 112PX;
          height: 112PX;
        }
      }

      .choose {
        display: grid;
        margin-top: 10PX;
        justify-content: space-between;
        grid-template-columns: repeat(auto-fill, 186PX);

        .choose-item {
          width: 186PX;
          height: 86PX;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #e3e7f1;
          border: 2PX solid #96a6cf;
          border-radius: 8PX;
          margin-top: 22PX;
        }
      }
    }

    .point-history {
      font-weight: 500;
      padding-bottom: 42PX;

      .recent-acitivities {
        margin: 0 34PX;
        padding: 24PX 0 8PX 0;
        font-size: 32PX;
        line-height: 36PX;
      }

      .points-item {
        margin: 20PX 18PX 0 18PX;
        padding: 0 20PX;
        height: 86PX;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: fade(#e3e7f1, 25%);
        font-size: 24PX;
        line-height: 28PX;

        .points-item-date {
          display: flex;
          align-items: center;

          .bm-icon {
            margin-right: 14PX;
          }
        }
      }
    }
  }

  .card:first-child {
    margin-bottom: 32PX;
  }
  .bottom-botton {
    width: 100%;
    padding: 17PX;
    border-radius: 8PX;
    background-color: var(--bm-primary-color-tap);
    text-align: center;
    box-sizing: border-box;
    .pay-with-point {
      color: var(--bm-light-background-color);
      font-family: 'Sf pro display', sans-serif;
      font-size: 18PX;
      font-weight: 700;
      text-decoration: none;
    }
  }
  @media screen and (max-width: 991PX) {
    .side-nav {
      width: 25%;
      max-width: none;
      margin-right: 20PX;
      margin-left: 0PX;
    }
    .member-wrapper {
      width: auto;
      max-width: none;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }
    .tabs-2 {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: auto;
      max-width: 74%;
      margin-bottom: 0PX;
      padding-right: 20PX;
      padding-left: 20PX;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }
    .tabs-menu-4 {
      top: 70PX;
      width: auto;
      max-width: none;
      min-width: 0PX;
      margin-right: 30PX;
      margin-left: 0PX;
      -webkit-align-self: flex-start;
      -ms-flex-item-align: start;
      align-self: flex-start;
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
    }
    .my-priviledge-tab.w--current {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-align-self: stretch;
      -ms-flex-item-align: stretch;
      align-self: stretch;
    }
    .my-priviledge-tab {
      padding-right: 20PX;
      padding-left: 20PX;
    }
    .tabs-content {
      overflow: visible;
      margin-top: 0PX;
      margin-left: 0PX;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }
    .section.cms {
      width: auto;
      min-width: auto;
      margin-top: 20PX;
      margin-bottom: 20PX;
    }
    .section {
      margin-top: 0PX;
      padding: 84PX 35PX 30PX;
    }
    .div-block-537 {
      display: -ms-grid;
      display: grid;
      overflow: visible;
      margin-right: 0PX;
      margin-left: 0PX;
      grid-auto-columns: 1fr;
      grid-column-gap: 16PX;
      grid-row-gap: 16PX;
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
      -ms-grid-rows: auto auto;
      grid-template-rows: auto auto;
    }
    .item-wrapper.coupon {
      margin-bottom: 0PX;
    }
    .coupon-block.open-modal {
      margin-bottom: 0PX;
      padding-top: 10PX;
      padding-right: 10PX;
      padding-bottom: 10PX;
    }
    .image-block.coupon {
      width: 25%;
      min-width: auto;
      margin-top: -10PX;
      margin-right: 10PX;
      margin-bottom: -10PX;
    }
    .image-block {
      width: 80PX;
      height: 80PX;
      min-width: 80PX;
    }
    .status-veil {
      font-size: 14PX;
    }
    .item-info-wrapper {
      max-width: 80%;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }
    .item-info {
      max-width: 90%;
    }
    .heading-4-1 {
      margin-bottom: 10PX;
      font-size: 16PX;
    }
    .text-block-6 {
      font-size: 14PX;
    }
    .popup-description.coupon.coupon {
      border-radius: 0PX;
    }
    .div-block-540.no-space {
      margin-bottom: 0PX;
    }
    .heading-4-1 {
      margin-bottom: 10PX;
      font-size: 16PX;
    }
    .div-block-540.no-space {
      margin-bottom: 0PX;
    }
  }

  @media screen and (max-width: 767PX) {
    .whole {
      background-color: rgba(242, 243, 248, 0);
      padding-top: 60PX;
      padding-right: 0PX;
      padding-left: 0PX;
    }
    .white-banner {
      top: 60PX;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      height: 50PX;
      max-height: 50PX;
    }
    .side-nav {
      display: none;
    }
    .tabs-2 {
      display: block;
      width: 100%;
      max-width: none;
      margin-top: 0PX;
      border-radius: 0PX;
      background-color: rgba(242, 243, 248, 0);
    }
    .tabs-menu-4 {
      top: 60PX;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      max-height: 50PX;
      max-width: 727PX;
      min-height: 50PX;
      margin-right: 20PX;
      margin-left: 20PX;
      padding-top: 0PX;
      padding-bottom: 0PX;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .my-priviledge-tab.w--current {
      margin-bottom: 0PX;
      -webkit-align-self: auto;
      -ms-flex-item-align: auto;
      -ms-grid-row-align: auto;
      align-self: auto;
      font-size: 16PX;
    }
    .my-priviledge-tab {
      margin-bottom: 0PX;
      -webkit-align-self: auto;
      -ms-flex-item-align: auto;
      -ms-grid-row-align: auto;
      align-self: auto;
      font-size: 18PX;
    }
    .tabs-content {
      margin-bottom: 0PX;
    }
    .tab-pane {
      padding-top: 0PX;
    }
    .section.cms {
      margin-right: 0PX;
      padding-right: 30PX;
      padding-left: 30PX;
    }
    .section {
      padding: 66PX 25PX 20PX;
    }
    .div-block-537 {
      margin-right: 0PX;
      margin-left: 0PX;
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
    }
    .item-wrapper {
      width: 100%;
    }
    .coupon-block.open-modal {
      border-bottom-width: 1PX;
    }
    .coupon-block {
      margin-bottom: 0PX;
      padding-top: 20PX;
      padding-bottom: 20PX;
    }
    .paragraph-2 {
      font-size: 14PX;
    }
    .heading-4-1 {
      margin-bottom: 10PX;
      font-size: 18PX;
    }
    h4 {
      font-family: 'Sf pro display', sans-serif;
      font-weight: 600;
    }
    .text-block-6 {
      font-size: 14PX;
    }
    .item-image-pop-up-copy {
      z-index: auto;
    }
    .nav-button-1.close-modal {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      cursor: pointer;
    }
    .popup-description.coupon {
      height: auto;
      padding-right: 20PX;
      padding-left: 20PX;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }
    .popup-description {
      z-index: 999;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      padding-right: 0PX;
      padding-bottom: 0PX;
      padding-left: 0PX;
      border-radius: 0PX;
    }
    .heading-4-1 {
      margin-bottom: 10PX;
      font-size: 18PX;
    }
    .modal-body.coupon {
      overflow: visible;
      opacity: 1;
      width: 100%;
    }
    .modal-body {
      width: auto;
      height: 100vh;
      min-height: 100vh;
      border-radius: 0PX;
    }
    .modal-wrapper {
      padding: 0PX;
    }
  }

  @media screen and (max-width: 479PX) {
    .modal-body {
      width: auto;
      height: 100%;
      min-height: auto;
      min-width: auto;
      padding: 20PX 0PX 0PX;
      border-radius: 0PX;
    }
    .whole {
      max-width: none;
      margin-right: 0PX;
      margin-left: 0PX;
      padding-top: 50PX;
    }
    .white-banner {
      position: fixed;
      top: 50PX;
      display: block;
      height: 50PX;
      max-height: 50PX;
    }
    .tabs-2 {
      padding-right: 12PX;
      padding-left: 12PX;
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      box-sizing: border-box;
      background-color: var(--bm-dark-background-color);
    }
    .tabs-menu-4 {
      position: -webkit-sticky;
      position: sticky;
      top: 50PX;
      max-height: 50PX;
      max-width: none;
      margin-right: 0PX;
      margin-left: 0PX;
      padding-top: 0PX;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .my-priviledge-tab.w--current {
      padding: 5PX 15PX;
      font-size: 14PX;
    }
    .my-priviledge-tab {
      padding-right: 15PX;
      padding-left: 15PX;
      font-size: 16PX;
    }
    .section.cms {
      padding-right: 0PX;
      padding-left: 0PX;
    }
    .section {
      padding: 68PX 12PX 15PX;
    }
    .coupon-block {
      margin-bottom: 0PX;
      padding-top: 25PX;
      padding-bottom: 25PX;
    }
    .image-block.coupon {
      width: 35%;
    }
    .image-block {
      width: 72PX;
      height: 72PX;
      min-width: auto;
    }
    .status-veil {
      text-align: center;
    }
    .item-info-wrapper {
      max-width: 73%;
      min-width: auto;
    }
    .item-info {
      overflow: visible;
      max-height: none;
      max-width: 90%;
    }
    .price-cart {
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
    }
    .heading-4-1 {
      margin-top: 0PX;
      margin-bottom: 15PX;
      font-size: 16PX;
      line-height: 16PX;
    }
    h4 {
      font-size: 16PX;
    }
    .paragraph-2.less-space {
      -webkit-align-self: stretch;
      -ms-flex-item-align: stretch;
      -ms-grid-row-align: stretch;
      align-self: stretch;
    }
    .paragraph-2 {
      margin-bottom: 5PX;
      font-size: 12PX;
      line-height: 14PX;
    }
    p {
      font-size: 14PX;
    }
    .text-block-91 {
      -webkit-align-self: flex-start;
      -ms-flex-item-align: start;
      align-self: flex-start;
      font-size: 14PX;
    }
    .text-block-6 {
      -webkit-align-self: flex-end;
      -ms-flex-item-align: end;
      align-self: flex-end;
      font-family: 'Sf pro display', sans-serif;
      font-size: 14PX;
      font-weight: 600;
    }
    .div-block-77 {
      padding-top: 10PX;
      margin-top: 15PX;
      margin-right: 17PX;
      margin-left: 17PX;
    }
    .popup-description {
      position: relative;
      z-index: 9000;
      width: 100vw;
      height: 100%;
      padding-right: 0PX;
      padding-bottom: 0PX;
      padding-left: 0PX;
      border-bottom-left-radius: 0PX;
      border-bottom-right-radius: 0PX;
    }
    .div-block-540 {
      margin-bottom: 15PX;
      padding: 15PX;
    }
    .qr-code-block {
      width: 210PX;
      height: 210PX;
      min-height: 210PX;
      min-width: 210PX;
      margin-top: -165PX;
      margin-bottom: 20PX;
    }
    .heading-4-1.bigger {
      margin-bottom: 10PX;
      font-size: 18PX;
    }
    .heading-4-1 {
      margin-top: 0PX;
      margin-bottom: 15PX;
      font-size: 16PX;
      line-height: 16PX;
    }
    h4 {
      font-size: 16PX;
    }
    .text-block-98 {
      font-size: 14PX;
    }
    .text-block-68 {
      font-size: 14PX;
    }
    .div-block-540 {
      margin-bottom: 15PX;
      padding: 15PX;
    }
    .modal-wrapper {
      display: flex;
      height: 100%;
      padding: 0PX;
    }
  }
`;
