import { IObject } from '@/components/bm-common';
import Config from '@/Config';
import axios from 'axios';
import { getAccessToken } from '@/utils/app';

interface ICreateRecordParams {
  storeSlug: string;
  moduleBundleID: string;
  record: IObject;
}

export const createRecord = async (params: ICreateRecordParams, ignoreToken?: boolean) => {
  let success = false;
  let record: IObject = {};

  try {
    let config = {};
    const token = getAccessToken();
    if (token && !ignoreToken) {
      config = {
        'headers': {
          'X-USER-ACCESS-TOKEN': token,
        }
      };
    }

    const res = await axios.post(
      Config.galaxy,
      {
        'operationName': null,
        'query': `
          mutation($storeSlug: String!, $moduleBundleID: String!, $record: Map!) {
            recordCreate(storeSlug: $storeSlug, moduleBundleID: $moduleBundleID, record: $record)
          }
        `,
        'variables': params,
        'fetchPolicy': 'no-cache',
      },
      config,
    );

    if (res && res.data && res.data.recordCreate) {
      success = true;
      record = res.data.recordCreate;
    }
    
  } catch (error) {
    console.error(error);
  }

  return { success, record };
}

interface IUpdateRecordParams {
  id: string;
  storeSlug: string;
  moduleBundleID: string;
  record: IObject;
}

export const updateRecord = async (params: IUpdateRecordParams, ignoreToken?: boolean) => {
  const { id, storeSlug, moduleBundleID, record } = params;

  let success = false;
  let newRecord: IObject | undefined = undefined;

  try {
    let config = {};
    const token = getAccessToken();
    if (token && !ignoreToken) {
      config = {
        'headers': {
          'X-USER-ACCESS-TOKEN': token,
        }
      };
    }

    const res = await axios.post(
      Config.galaxy,
      {
        'operationName': null,
        'query': `
          mutation ($storeSlug: String!, $moduleBundleID: String!, $id: UID!, $record: Map!) {
            recordUpdate(storeSlug: $storeSlug, moduleBundleID: $moduleBundleID, id: $id, record: $record)
          }
        `,
        'variables': {
          id,
          storeSlug: storeSlug,
          moduleBundleID,
          record,
        },
      },
      config,
    );

    if (res && res.data && res.data.recordUpdate) {
      success = true;
      newRecord = res.data.recordUpdate;
    }
  } catch (error) {
    console.error(error);
  }

  return { success, record: newRecord };
}

interface IDeleteRecordParams {
  storeSlugs: string[];
  moduleBundleID: string;
  ids: string[];
}

export const deleteRecord = async (params: IDeleteRecordParams) => {
  let success = false;

  try {

    let config = {};
    const token = getAccessToken();
    if (token) {
      config = {
        'headers': {
          'X-USER-ACCESS-TOKEN': token,
        }
      };
    }

    const res = await axios.post(
      Config.galaxy,
      {
        'operationName': null,
        'query': `
          mutation($storeSlugs: [String!], $moduleBundleID: String!, $ids: [UID!]) {
            recordDelete(storeSlugs: $storeSlugs, moduleBundleID: $moduleBundleID, ids: $ids) {
              success
            }
          }
        `,
        'variables': params,
        'fetchPolicy': 'no-cache',
      },
      config,
    );

    if (res && res.data && res.data.recordDelete) {
      success = res.data.recordDelete.success;
    }
  } catch (error) {
    console.error(error)
  }

  return { success };
}

interface IMbApiSearch {
  includeDeleted?: boolean;
  includeTotal?: boolean;
  orderAsc?: 'DESC' | 'ASC';
  orderBy?: string;
  page?: number;
  perPage?: number;
  formulas?: {
    formula: string;
  }[];
}

interface IQueryRecordsParams {
  moduleBundleID: string;
  storeSlugs: string[];
  search?: IMbApiSearch;
}

export const queryRecords = async (params: IQueryRecordsParams) => {
  const { storeSlugs, moduleBundleID, search } = params;

  const apiSearch: IMbApiSearch = {
    includeDeleted: false,
    includeTotal: false,
    orderAsc: 'DESC',
    orderBy: 'id',
    page: 1,
    perPage: 50,
    ...(search || {}),
  }

  let records: IObject[] = [];
  let success = false;
  let pageInfo = {
    total: 0,
    pageSize: 0,
    totalPages: 0,
    current: 0,
  }

  let config = {};
  const token = getAccessToken();
  if (token) {
    config = {
      'headers': {
        'X-USER-ACCESS-TOKEN': token,
      }
    };
  }

  try {
    const res = await axios.post(
      Config.galaxy,
      {
        'operationName': null,
        'query': `
          query ($storeSlugs: [String!], $moduleBundleID: String!, $search: SearchInput!, $viewID: String) {
            records(storeSlugs: $storeSlugs, moduleBundleID: $moduleBundleID, search: $search, viewID: $viewID) {
              records
              pageInfo {
                total
                pageSize
                totalPages
                current
                totalGroupByStores
              }
            }
          }
        `,
        'variables': { storeSlugs, moduleBundleID, search: apiSearch },
        'fetchPolicy': 'no-cache',
      },
      config,
    );
  
    if (res && res.data && res.data.records && Array.isArray(res.data.records.records)) {
      success = true;
      records = res.data.records.records
      pageInfo = res.data.records.pageInfo || pageInfo;
    }
  } catch (error) {
    console.error(error)
  }

  return {
    success,
    records,
    pageInfo,
  };
}

interface IQueryRelationRecordsParams {
  id: string | null;
  ids?: string[];
  field: string;
  moduleBundleID: string;
  storeSlugs: string[];
  search?: IMbApiSearch;
}

export const queryRelationRecords = async (params: IQueryRelationRecordsParams) => {
  const { id, field, moduleBundleID, storeSlugs, search, ids } = params;

  const apiSearch: IMbApiSearch = {
    includeDeleted: false,
    includeTotal: false,
    orderAsc: 'DESC',
    orderBy: 'id',
    page: 1,
    perPage: 50,
    ...(search || {}),
  }

  let records: IObject[] = [];
  let success = false;

  try {

    const res = await axios.post(Config.galaxy, {
      'operationName': null,
      'query': `
        query($storeSlugs: [String!], $moduleBundleID: String!, $id: UID, $ids: [UID!], $field: String!, $search: SearchInput!) {
          relationRecords(storeSlugs: $storeSlugs, moduleBundleID: $moduleBundleID, id: $id, ids: $ids, field: $field, search: $search) {
            records
          }
        }
      `,
      'variables': { id, field, ids: ids || [], moduleBundleID, storeSlugs, search: apiSearch },
    });
    if (res && res.data && res.data.relationRecords && Array.isArray(res.data.relationRecords.records)) {
      success = true;
      records = res.data.relationRecords.records;
    }
  } catch (error) {
    console.error(error);
  }

  return {
    success,
    records,
  };
}

interface IQuerygetPhoneIsoParams {
  areaCodeId: string;
  storeSlugs: string[];
}

export const getPhoneIso = async (params: IQuerygetPhoneIsoParams) => {
  const { areaCodeId, storeSlugs } = params;

  let moduleBundleID: string;
  if ( Config.isStaging ) {
    moduleBundleID = 'bull6yl5';
  } else {
    moduleBundleID = 'uew3xuv2';
  }

  let success = false;
  let record: IObject = {};

  try {
    const res = await axios.post(
      Config.galaxy,
      {
        'operationName': null,
        'query': `
          query ($storeSlugs: [String!], $moduleBundleID: String!, $id: UID!) {
             record(storeSlugs: $storeSlugs, moduleBundleID: $moduleBundleID, id: $id)
          }
        `,
        'variables': {
          id: areaCodeId,
          moduleBundleID,
          storeSlugs,
        },
      },
    );
    if (res && res.data && res.data.record) {
      success = true;
      record = res.data.record;
    }
  } catch (error) {
    console.error(error);
  }
  return { success, record };
}
