import { checkApple } from '@/utils';
import { IWindowApple } from '@/lib/payment';
import Config from '@/Config';

export const appleSignInSDK = () => {
  if (checkApple()) {

    const {
      AppleID,
    } = window as IWindowApple;

    AppleID.auth.init({
      clientId: 'com.bindo.mobile.www',
      scope: 'name email',
      redirectURI:
       Config.urls.appleSignCallback,
      // 'https://main.bindo.io/api/v5/social_oauth/apple/callback',
      state: window.location.href,
    });

    try {
      AppleID.auth.signIn();
    } catch (e) {
      console.error(e)
    }
  }
}
