import React from 'react';
import dayjs from 'dayjs';
import ActionType from '@/actions/action-type';
import Modal from 'antd-mobile/es/modal';
import { parseRouteParams } from '@/utils';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import Icon, { IconType } from '@/components/Icon';
import paths from '@/routes/paths';
import { getI18nText, getAccessToken, stringToBase64QrCode, IParseOpeningHoursResult, parseOpeningHours, getCurrentShippingMethod } from '@/utils/app';
import { IOrderInfo } from '@/lib/order-builder/models/OrderEntity';
import ShippingMethod from '@/lib/order-builder/enums/ShippingMethod';
import SingleStoreOrder from './SingleStoreOrder';
import OrderInformation from './OrderInformation';
import { prefix } from '.';
import { getAppTheme, setScreenColor, getIntl, getIntlByPath } from '@/components/App/App';
import { formatCurrency } from '@/components/Payment';
import Skeleton from './Skeleton';
import Layout from '@/components/Layout/Layout';

export interface OrderDetailPageProps extends IBasePageProps {};

interface IOrderDetailPageState {
  order: IOrderInfo | undefined;
}

class OrderDetailPage extends BasePage<OrderDetailPageProps, IOrderDetailPageState> {
  
  token = getAccessToken();

  state: IOrderDetailPageState = {
    order: undefined,
  }

  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.primaryColor && appTheme.darkBackgroundColor) {
      setScreenColor(appTheme.primaryColor, appTheme.darkBackgroundColor);
    }
    super.componentDidMount();
    this.autoCloseLoading = false;
    const { dispatch } = this.props;
    const routeParams = parseRouteParams(this.props);
    dispatch({
      type: ActionType.QUERY_ORDER_INFO,
      storeSlug: routeParams.storeSlug,
      orderNumber: routeParams.orderNumber,
      callback: (orderInfo, store, storeConfig) => {
        this.setState({
          order: orderInfo,
        });
      }
    });
  }

  handleReOrder = () => {
    const { dispatch, reducersState } = this.props;
    const { order } = this.state;
    const routeParams = parseRouteParams(this.props);
    const { slugRefStore, slugRefStoreConfig } = reducersState.store;
    const store = slugRefStore[routeParams.storeSlug];
    const storeConfig = slugRefStoreConfig[routeParams.storeSlug];
    const { openingHours } = store || {};
    let parseOpeningHoursResult: IParseOpeningHoursResult | undefined = undefined;
    if (openingHours) {
      parseOpeningHoursResult = parseOpeningHours(openingHours);
    }
    const currentShippingMethod = getCurrentShippingMethod(order, storeConfig);
    let isOpen = false;
    if (parseOpeningHoursResult) {
      isOpen = parseOpeningHoursResult.isOpen || false;
      if (currentShippingMethod === ShippingMethod.PICK_UP && storeConfig && storeConfig.pickUpAdvanceOrderDate > 1) {
        isOpen = true;
      } else if (currentShippingMethod === ShippingMethod.DELIVERY && storeConfig && storeConfig.deliveryAdvanceOrderDate > 1) {
        isOpen = true;
      }
    }

    if (order && isOpen) {
      dispatch({
        type: ActionType.ADD_TO_SHOPPING_CART_BY_ORDER,
        storeID: order.storeID,
        orderNumber: order.orderNumber,
        suborderNumber: order.orderNumber !== routeParams.suborderNumber ? routeParams.suborderNumber : '',
        callback: (storeID, shoppingCartData) => {
          if (shoppingCartData && storeID) {
            this.handleGotoRoute({
              path: paths.SHOPPING_CART,
              pathParams: {
                storeSlug: routeParams.storeSlug,
              },
            });
          }
        }
      })
    } else if (!isOpen) {
      Modal.alert(getIntl().common.tips, getIntl().page.closed, [
        {
          text: getIntl().page.ok,
          onPress: () => {
          },
        },
      ]);
    }
  }

  handleLeftClick = () => {
    const routeParams = parseRouteParams(this.props);
    this.handleGoBack({
      path: routeParams.from === 'orders' ? paths.ORDERS : paths.RECORDS,
      runGoBack: true,
    })
  }

  render() {
    const {
      order,
    } = this.state

    const { 
      reducersState,
    } = this.props

    const routeParams = parseRouteParams(this.props);
    let orderInfo: any = '';
    order && order.orderEntities.forEach(item => {
      if (item.orderNumber === routeParams.suborderNumber) {
        const deliveryDate = dayjs(item.deliveryDate || order.orderTime);
        let showTime = true;
        if (deliveryDate.format('HH:mm:ss') === '00:00:00') {
          showTime = false;
        }
        const date  =  dayjs().diff(deliveryDate, 'day') === 0 ? (`${getIntl().common.today} ${showTime ? deliveryDate.format('HH:mmA') : ''}`) : (showTime ? deliveryDate.format('MM-DD HH:mmA') : deliveryDate.format('MM-DD'));
        const invalidData = date === 'Invalid Date';
        
        orderInfo = (
          <div>
            {
              order && order.from !== 2 &&
              <div className="background-top with-bg"></div>
            }
            
            <div className="section no-padding">
              <div className="container top w-container">
                {
                  order && order.from !== 2 &&
                  <div className="qr-code-display">
                    <div className="text-block-77 white">{getIntl().page.showQRTip}</div>
                    <div className="div-block-505">
                      <img src={stringToBase64QrCode(item.referenceID)} alt=""  className="div-block-504"/>
                    </div>
                  </div>
                }
                <div className="div-block-485 payment-confirmation">
                  <div className="left-block payment-confirmation">
                    <div className="order-block">
                      <SingleStoreOrder data={item} key={item.id} reducersState={reducersState} />
                      <div className="div-block-483"></div>
                      <div className="div-block-493">
                        <div className="div-block-484">
                          <div className="text-block-98">{getIntl().common.subtotal}</div>
                          <div className="text-block-68">{formatCurrency(item.subtotal, order.currencyCode)}</div>
                        </div>
                        {
                          item.discountTotal !== 0 &&
                          <div className="div-block-484">
                            <div className="text-block-98">
                              {getIntl().common.discount}
                            </div>
                            <div className="text-block-68">
                              -{formatCurrency(item.discountTotal, order.currencyCode)}
                            </div>
                          </div>
                        }
                        {
                          item.initialServiceFee !== 0 &&
                          <div className="div-block-484">
                            <div className="text-block-98">{getIntl().common.serviceFee}</div>
                            <div className="text-block-68">{formatCurrency(item.initialServiceFee, order.currencyCode)}</div>
                          </div>
                        }
                        {
                          item.initialTax !== 0 &&
                          <div className="div-block-484">
                            <div className="text-block-98">{getIntl().common.taxFee}</div>
                            <div className="text-block-68">{formatCurrency(item.initialTax, order.currencyCode)}</div>
                          </div>
                        }
                        {
                          item.initialRounding !== 0 &&
                          <div className="div-block-484">
                            <div className="text-block-98">{getIntl().common.rounding}</div>
                            <div className="text-block-68">{formatCurrency(item.initialRounding, order.currencyCode)}</div>
                          </div>
                        }
                        <div className="div-block-484">
                          <div className="text-block-67 bold">{getIntl().common.total}</div>
                          <div className="text-block-68 bold">{formatCurrency(item.initialTotal, order.currencyCode)}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="right-block rounded-all">
                    <div className="delivery-info-block">
                      <div className="order-confirmation-info">
                        <div className="div-block-506">
                          <div className="text-block-101">{getIntlByPath(`common.${item.orderStatus}`)}</div>
                          <div className="text-block-102">#{item.referenceID}</div>
                        </div>
                        {
                          item.orderStatus !== 'unpaid' &&
                          <div className="text-block-67 grey">{getIntlByPath(`page.${item.orderStatus}Tip`)}</div>
                        }
                        {
                          order && order.from === 4 &&
                          <div className="div-block-507">
                            <div className="secondary-payment-confirmation-buttons-copy" onClick={this.handleReOrder}>
                              <div className="text-block-17">{getIntl().page.reOrder}</div>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                    {
                      !invalidData &&
                      <div className="order-confirmation-info-padding">
                        <div className="order-flex bold">
                          <div className="time-name">{getIntl().page.takeAwayTime}</div>
                          <div className="time">
                            {
                              (item.shippingMethod === ShippingMethod.PICK_UP || item.shippingMethod === ShippingMethod.DELIVERY) &&
                              date
                            }
                            {
                              (item.shippingMethod === ShippingMethod.DINE_IN || item.shippingMethod === ShippingMethod.DINE_IN_UNASSIGNED) &&
                              getIntl().page.immediately
                            }
                          </div>
                        </div>
                        {
                          item.shippingMethod === ShippingMethod.DELIVERY &&  (order.orderShippingAddress.city || order.orderShippingAddress.address_1) &&
                          <div>
                            <div className="div-block-483"></div>
                            <div className="order-flex">
                              <div className="icon">
                                <Icon type={IconType.MAP_OUTLINED} />
                              </div>
                              <div>
                                <div className="text-block-67 bold">
                                  {order.orderShippingAddress.city || ''}{order.orderShippingAddress.address_1}
                                </div>
                                <div className="customer">
                                  <div className="customer-name">
                                    {order.orderShippingAddress.full_name}
                                  </div>
                                  <div className="customer-phone">
                                    {order.orderShippingAddress.phone}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                        {
                          item.shippingMethod === ShippingMethod.PICK_UP && order.pickupLocation &&
                          <div >
                            <div className="div-block-483"></div>
                            <div className="order-flex">
                              <div className="delivery-address">
                                <div className="address">
                                  {getI18nText(order.pickupLocation, 'name', reducersState.app.language)}
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                    }
                    {
                      order && order.from !== 2 &&
                      <OrderInformation
                        method={item.shippingMethod}
                        orderNumber={item.orderNumber}
                        referenceID={item.referenceID}
                        orderTime={order.orderTime}
                        deliveryDate={item.deliveryDate || order.orderTime}
                        saleTransactions={order.saleTransactions}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) 
      }
    })
    return (
      <Layout
        {...this.props}
        className={prefix}
        navLeftContent={<Icon className="html-embed-7 w-embed left-circle" type={IconType.LEFT_OUTLINED} />}
        onLeftClick={this.handleLeftClick}
        navContent={order && order.from === 2? getIntl().page.paymentConfirmation: ''}
      >
        { 
          !order &&
          <Skeleton onLeftClick={this.handleLeftClick} />
        }
        {orderInfo}
      </Layout>
    )
  };
};

export default OrderDetailPage;
