import React, {useState, useEffect} from 'react';
import dayjs from 'dayjs';
import { getI18nText } from '@/utils/app';
import { IBooking } from '@/actions/booking-action'
import { IDispatch } from '@/actions';
import { checkImgExists } from '@/utils';
import Config from '@/Config';
import ActionType from '@/actions/action-type';
import { getIntl } from '@/components/App/App';

interface IBookingItemProps {
  data: IBooking;
  goToDetailPage: (id: string) => void;
  dispatch: IDispatch;
  language: string;
}

const BookingItem: React.FC<IBookingItemProps> = props => {

  const [imgUrl, setImgUrl] = useState('');
  const [storeName, setStoreName] = useState('');
  const failureStatus = ['cancelled', 'no_show'];

  const {
    data,
    goToDetailPage,
    dispatch,
    language,
  } = props;

  useEffect(() => {
    dispatch({
      type: ActionType.QUERY_STORE_BY_ID,
      storeID: data.storeID,
      callback: store => {
        if (store) {
          checkImgExists(store.logoUrl, (success, url) => {
            if (success) {
              setImgUrl(url);
            } else {
              setImgUrl(Config.defaultStoreImg);
            }
          })
          setStoreName(getI18nText(store, 'title', language) || store.name);
        }
      }
    }); 
  }, []);

  return (
    <div className="div-block-625" onClick={() => goToDetailPage(data.id)}>
      <div className="div-block-636"  style={{backgroundImage: `url(${imgUrl})`}}></div>
      <div className="div-block-637">
        <div className="div-block-484 no-space">
          <div className="text-block-104 no-space">{storeName}</div>
          <div className="div-block-635 confirmed">
            <div className="dot"></div>
            {
              failureStatus.includes(data.status) &&
              <div className="failure"> {getIntl().common.failure}</div>
            }
            {
              !failureStatus.includes(data.status) &&
              <div> {getIntl().common.confirm}</div>
            }
          </div>
        </div>
        <div className="div-block-564 margin">
          <div className="div-block-544">
            <div className="text-block-67">
              {
                language === 'en-US' && dayjs(data.reservationTime).format('MMM DD')
              }
              {
                language !== 'en-US' && 
                <>
                  {dayjs(data.reservationTime).format('M')}
                  {getIntl().common.month}
                  {dayjs(data.reservationTime).format('D')}
                  {getIntl().common.day}
                </>
              }
              <span className="text-span-4">（{dayjs(data.reservationTime).format('ddd')}）</span>
            </div>
            <div className="line-separator"></div>
            <div className="text-block-67">{dayjs(data.reservationTime).format('HH:mm')}</div>
            <div className="line-separator"></div>
            <div className="text-block-67">{getIntl().page.seats} {data.people}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookingItem;
