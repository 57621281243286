import produce from 'immer';
import { handleActions } from 'redux-actions';
import ActionType from '@/actions/action-type';
import {
  ISetQueueingSetting, 
  ISetQueueings,
} from '@/actions/queueing-action';
import {
  IQueueingSetting,
  IQueueing,
} from '@/actions/queueing-action';

export interface IQueueingState {
  slugRefQueueingSetting: {[storeSlug: string]: IQueueingSetting[]};
  IDRefQueueing: {[id: string]: IQueueing};
  pageRefQueueings: {[page: number]: IQueueing[]};
}

const initState: IQueueingState = {
  slugRefQueueingSetting: {},
  IDRefQueueing: {},
  pageRefQueueings: {},
};

const actions: any = {};

actions[ActionType.SET_QUEUEING_SETTING] = (state: IQueueingState, action: ISetQueueingSetting) => produce(state, draft => {
  draft.slugRefQueueingSetting[action.storeSlug] = action.queueingSetting;
});

actions[ActionType.SET_QUEUEINGS] = (state: IQueueingState, action: ISetQueueings) => produce(state, draft => {
  if (action.page) {
    draft.pageRefQueueings[action.page] = action.queueings;
  } else if (action.isAdd) {
    if (draft.pageRefQueueings[1]) {
      draft.pageRefQueueings[1].unshift(action.queueings[0]);
    }
  } else {
    draft.IDRefQueueing[action.queueings[0].id] = action.queueings[0];
  }
});

actions[ActionType.CLEAR_QUEUEING] = (state: IQueueingState) => produce(state, draft => {
  draft.pageRefQueueings = {};
  draft.IDRefQueueing = {};
});

export default handleActions(actions, initState);
