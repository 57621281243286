import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import withRoute from '@/pages/withRoute';
import AccountEditPage from './AccountEditPage';
// import './AccountEditPage.less';

export const prefix = 'bm-p-account-edit';

const RouteComponent: React.FC<RouteComponentProps> = props => withRoute({
  pageComponent: AccountEditPage,
  routeProps: props,
  pageTitleI18nKey: '',
});

export default RouteComponent;
