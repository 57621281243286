
import React from 'react';
import AntdIcon from 'antd-mobile/es/icon';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import Icon, { IconType } from '@/components/Icon';
import Layout from '@/components/Layout';
import QueueingItem from './QueueingItem';
import ActionType from '@/actions/action-type';
import { IQueueing } from '@/actions/queueing-action';
import paths from '@/routes/paths';
import { prefix } from '.';
import { getAppTheme, setScreenColor, getIntl } from '@/components/App/App';

export interface IQueueingsPageProps extends IBasePageProps {}
export interface IQueueingsPageState {
  queueings: IQueueing[] | undefined;
  page: number;
  loading: boolean;
}

class QueueingsPage extends BasePage<IQueueingsPageProps, IQueueingsPageState> {
  lastPage = false;
  loadingData = false;
  state: IQueueingsPageState = {
    queueings: undefined,
    page: 1,
    loading: false,
  }

  componentDidMount() {
    this.getQueueings(1);
    const appTheme = getAppTheme();
    if (appTheme.lightBackgroundColor) {
      setScreenColor(appTheme.lightBackgroundColor);
    }

    super.componentDidMount();
    const pageWrap = window.document.querySelector('.page-content-wrapper-web');
    if (pageWrap) {
      pageWrap.addEventListener('scroll', this.handleScroll, { passive: true });
    } else {
      window.addEventListener('scroll', this.handleScroll, { passive: true });
    }
  }

  componentWillUnmount() {
    const pageWrap = window.document.querySelector('.page-content-wrapper-web');
    if (pageWrap) {
      pageWrap.removeEventListener('scroll', this.handleScroll);
    } else {
      window.removeEventListener('scroll', this.handleScroll);
    }
  }

  getScrollY = () => {
    const pageWrap = window.document.querySelector('.page-content-wrapper-web');
    let scrollY = window.scrollY;
    if (pageWrap) {
      scrollY = pageWrap.scrollTop;
    }

    return scrollY;
  }

  handleScroll = () => {
    const { page } = this.state;
    if (!this.lastPage && !this.loadingData) {
      const appPages = document.getElementsByClassName('bm-p-queueings');
      if (appPages && appPages.length) {
        const appPage = appPages[0];
        const scrollHeight = appPage.scrollHeight;
        const canScrollHeight = scrollHeight - window.innerHeight;
        if (canScrollHeight - this.getScrollY() < 3 * 200) {
          this.loadingData = true
          this.setState({loading: true});
          this.getQueueings(page + 1, true);
        }
      }
    }
  }

  getQueueings = (page: number, isAdd = false,) => {
    const {
      dispatch,
      reducersState,
    } = this.props;
    const { user } = reducersState;
    const { userInfo } = user;
    if (!isAdd) {
      dispatch({
        type: ActionType.LOADING,
        showLoading: true,
      })
    }
    if (userInfo) {
      dispatch({
        type: ActionType.QUERY_QUEUEINGS,
        multi: true,
        formula: `EQ("$.self.linked_source_id", "${userInfo.id}").EQ("$.self.booking_type", "4")`,
        page,
        id: '',
        callback: newQueueings => {
          let { queueings } = this.state;
          if (isAdd && queueings) {
            queueings = queueings.concat(newQueueings);
          } else {
            queueings = newQueueings;
          }
          if (newQueueings.length < 10) this.lastPage = true;

          if (newQueueings) this.loadingData = false;
          this.setState({
            queueings,
            loading: false,
            page,
          })
          dispatch({
            type: ActionType.LOADING,
            showLoading: false,
          })
        }
      })
    }
  }

  handleClick = (id: string) => {
    this.handleGotoRoute({
      path: paths.QUEUEING_DETAIL,
      pathParams: {
        queueingID: id,
      },
    });
  }

  render() {
    const {
      queueings,
      loading,
    } = this.state;

    const {
      dispatch,
    } = this.props;
    return (
      <Layout
        {...this.props}
        className={prefix}
        navIcon={<Icon className="back-icon" type={IconType.LEFT_OUTLINED} />}
        onLeftClick={() => this.handleGoBack({
          path: paths.ACCOUNT,
          runGoBack: true,
        })}
        navContent={getIntl().page.myQueueings}
      >
        {
          queueings &&
          queueings.map(queueing => {
            return <QueueingItem key={`${queueing.storeID}_${queueing.id}`} data={queueing} goToDetailPage={this.handleClick} dispatch={dispatch} />
          })
        }
        {
          queueings && loading &&
          <div className="loading-data">
            <AntdIcon type="loading" />
            <div className="loading-msg">
              {getIntl().common.loading}
            </div>
          </div>
        }
        {
          queueings && queueings.length === 0 &&
          <div className={'no-queueings'}>
            <div>
              <Icon className="img" type={IconType.TAG_ITEM}/>
              <div className="no-queueings-tip">
                {getIntl().page.noQueueingTip}
              </div>
            </div>
          </div>
        }
      </Layout>
    );
  }
}

export default QueueingsPage;
