import React from 'react';
import { formatCurrency } from '@/components/Payment';
import { IModifier, IListing } from '@/lib/order-builder/utils/api';
import { getI18nText } from '@/utils/app';
import { IReducersState } from '@/reducers';

interface IModifierListingProps {
  data: IListing | IModifier | any;
  reducersState: IReducersState;
  currencyCode: string;
}

const ModifierListing: React.FC<IModifierListingProps> = props => {
  const { data, reducersState, currencyCode } = props;
  const { name, price, description } = data || {};
  return (
    <div className="modifier-item">
      {
        data && data.imageUrl &&
        <div className="item-img-wrapper">
          <div className="item-img" style={{ backgroundImage: `url("${data.imageUrl}")`}}></div>
        </div>
      }
      <div className="item-content">
        <div className="title">
          {data && (getI18nText(data, 'name', reducersState.app.language) || name)}
        </div>
        <div className="describe">
          {data && (getI18nText(data, 'description', reducersState.app.language) || description)}
        </div>
        {
          price &&
          <div className="price">+{formatCurrency(price, currencyCode)}</div>
        }
      </div>
    </div>
  );
}

export default ModifierListing;
