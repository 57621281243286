import React, { useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';
import dayjs from 'dayjs';
import { checkImgExists } from '@/utils';
import Config from '@/Config';
import {
  IFeedback,
  IFeedbackTags,
} from '@/data/Feedback';
import StarShow from '../StarShow';


interface IFeedbackListItemProps {
  feedback: IFeedback;
  feedbackTags: IFeedbackTags;
}

const FeedbackListItem: React.FC<IFeedbackListItemProps> = props => {
  const {
    feedback,
    feedbackTags,
  } = props;

  const [imgUrl, setImgUrl] = useState('');

  useEffect(() => {
    checkImgExists(feedback.avatarUrl, (success, url) => {
      if (success) {
        setImgUrl(url);
      } else {
        setImgUrl(Config.defaultStoreImg);
      }
    })
  }, []);

  return (
    <div className="feedback-item">
      <div className="user-avatar-img-wrapper">
        <LazyLoad once offset={100}>
          <div className="user-avatar-img" style={{backgroundImage: `url(${imgUrl})`}} />
        </LazyLoad>
      </div>
      <div className="item-content">
        <div className="item-content-top">
          <div className="item-name-date">
            <div className="user-name">{feedback.userName}</div>
            <div className="feedback-creatd-at">{dayjs(feedback.createdAt).format('YYYY-MM-DD')}</div>
          </div>
          
          <div className="item-stars">
            <StarShow
              score={feedback.score}
              starItem={'star-item'}
              halfStar={'half-star'}
            />
          </div>
        </div>
        <div className="item-content-bottom">
          <div className="feedback-content-label">
            {
              feedbackTags[feedback.score] && feedbackTags[feedback.score].map(item => {
                if (feedback.feedBackTag.includes(item.id)) {
                  return (
                    <div className="label-item" key={`${feedback.id}-${item.id}`}>
                      {item.tag}
                    </div>
                  )
                } else {
                  return <div key={`${feedback.id}-${item.id}`}></div>
                }
              })
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeedbackListItem;
