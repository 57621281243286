import { IObject } from '@/components/bm-common';

import enUS from './en-US';
import zhHK from './zh-HK';
import zhCN from './zh-CN';

const langsPacks: {[lang: string]: IObject} = {
  'zh-CN': zhCN,
  'zh-HK': zhHK,
  'en-US': enUS,
}

export default langsPacks;
