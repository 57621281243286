import LineItemEntity, { ILineItemJson, ITaxOptionEntrie } from './LineItemEntity';
import ModifierEntity, { IModifierJson } from './ModifierEntity';
import { IModifier, IListing } from '../utils/api';
import Context from '../Context';
import cloneDeep from 'lodash/cloneDeep';
import { IObject } from '@/components/bm-common';
import ShippingMethod, { defaultShippingMethod } from '../enums/ShippingMethod';
import { plus, times, minus } from '../utils';
import SaleType from '../enums/SaleType';
import Channels from '../enums/Channels';
import * as Data from './Data';
import { getOrderStatus } from '../utils/api'
import dayjs from 'dayjs';
import PurchasableType from '../enums/PurchasableType';
import { ListingCountRef, ISetDiscountValue } from '@/actions/shopping-cart-action';
import { IStorePickupLocation } from '@/actions/store-action';
import { OrderBuilder } from '@bindo/order-builder/lib/order-builder-bundle-min';
import DisplayType from '../enums/DisplayType';
import pick from 'lodash/pick';

const purchasableTypeForLineItemCount = [
  PurchasableType.TAX,
  PurchasableType.DELIVERY,
  PurchasableType.SERVICE_FEE,
  PurchasableType.ROUNDING,
  PurchasableType.TIPS,
];

const purchableTypesForOrderSubtotal = [
  PurchasableType.TAX,
  PurchasableType.SERVICE_FEE,
  PurchasableType.ROUNDING,
];

export interface IOrderEntityJson {
  store_id: string; // 订单所属店铺的StoreID
  customer_id: string; // 订单所属店铺的顾客ID
  from: Channels; // 下单渠道
  sale_type: SaleType; // 销售类别
  due_date: string; // 截止日期 默认为7天，YYYY-MM-DD
  shipping_method: ShippingMethod; // ShippingMethod.DELIVERY
  delivery_date?: string; // 交货日期，默认为下单时间 ShippingMethod.PICK_UP 和 ShippingMethod.DELIVERY显示
  party?: {
    number_of_people: number;
    status: string;
    table_id: string;
    table_name: string;
    turn_time: number;
  };
  line_items: ILineItemJson[];  // 超级订单的LineItems等于子订单LineItem的集合
  voided_line_items: ILineItemJson[]; // 无效，被移除的LineItems
  invalid_line_items: ILineItemJson[]; // 无效，和就餐方式不一致、过期的LineItems
  number_of_items: number; // 除TAX，DELIVERY，SERVICE_FEE，ROUNDING，Tips之外LineItem的数量
  number_of_listing_items: number; // PurchasableType.LISTING类型的LineItem的数量
  discount_total: number;
  tax_base_adjustment: number;
  taxable_amount: number;
  initial_tax: number;
  initial_normal_tax: number;
  initial_included_in_price_tax: number;
  initial_service_fee: number;
  subtotal: number;
  initial_total: number;
  initial_product_total: number;
  initial_included_in_price_tax_for_service_fee: number;
  initial_redeem_deposits: number;
  initial_tips: number;
  initial_tax_rounding: number;
  initial_rounding: number;
  initial_service_fee_rounding: number;
  initial_provided_in_request: boolean;
  sub_orders?: IOrderEntityJson[];
  note: string;
  pickup_location_id?: string;
  pickup_location?: IStorePickupLocation | null;
  shipping_address_id?: string;
  smart_discount_info: ISmartDiscountInfo | null;
  // leave_reference_number_blank: boolean;
}

interface ISmartDiscountInfo {
  discountScript: string[];
  promotionName: string;
  promotionCode: string;
  discountValue: number;
  membershipLevelID: string;
  taxValue: number;
  serviceFeeValue: number;
}


export interface IDineInParty {
  numberOfPeople: number;
  status: string;
  tableID: string;
  tableName: string;
  turnTime: number;
}

export interface ISetShippingMethodParams {
  shippingMethod: ShippingMethod;
  deliveryDate?: dayjs.Dayjs;
  dineInParty?: IDineInParty;
  pickupLocationID?: string;
  pickupLocation?: IStorePickupLocation;
  shippingAddressID?: string;
  orderShippingAddress?: IObject;
}

export interface IBaseInfo {
  modifiersInfos: IModifierInfo[];
}

export interface IModifierInfo extends IBaseInfo {
  id: string;
  quantity: number;

}

export interface ILineItemInfo extends IBaseInfo {
  listingID: string;
}

export interface ICustomItemInfo {
  name: string;
  price: number;
}
interface IAddLineItemParams {
  storeID?: string;
  lineItemInfo: ILineItemInfo;
  listing: IListing;
  quantity: number;
  taxOptions: ITaxOption[];
  roundingOption: IRoundingOption;
  customItemInfo?: ICustomItemInfo;
};

interface IParseModifiersInfosParams {
  modifiersInfos: IModifierInfo[];
  modifiers: IModifier[];
  lineItemEntity?: LineItemEntity;
  modifierEntity?: ModifierEntity;
  lineItemQuantity?: number;
}

export interface ISaleTransaction {
  id: string;
  amount: number;
  currencyCode: string;
  paymentMethod: number;
  creditCardType: string;
  last4Digits: string;
  balance?: number;
  brn?: string;
  success?: boolean;
}

export interface IOrderInfo {
  storeID: string;
  storeSlug: string;
  storeTitle: string; // 店名
  orderNumber: string;
  paidTotal: number;  // 已支付总金额
  invoiceState: string; // 支付类型
  currencyCode: string; // 货币符号
  shippingMethod: ShippingMethod; // 就餐方式：0: Pick Up(外面)，1: Delivery(速递)，2: DineIn(堂食)，3: DineInUnassigned(堂食)
  deliveryDate: string | null;
  dineInParty: IDineInParty | null;
  saleTransactions: ISaleTransaction[];
  initialTotal: number; // 订单总金额
  initialTax: number; // 税收
  initialServiceFee: number; // 服务费
  discountTotal: number; // 服务费
  initialRounding: number; // 除整
  subTotal: number; // 订单小计
  superOrder: boolean; // true: 多店/超级订单，false: 单店/普通订单
  orderEntities: OrderEntity[]; // 订单列表项，以店为单位
  referenceID: number;
  state: string;
  orderStatus: string;
  storeLogoUrl: string;
  orderTime: string;
  storeName: string;
  orderShippingAddress: IObject;
  note: string;
  pickupLocationID: string | undefined;
  pickupLocation: IStorePickupLocation | undefined;
  parentID: number | undefined;
  from: number | null;
  shippingAddressID: string | undefined;
  smartDiscountInfo: ISmartDiscountInfo | null;
  orderID?: string;
}

interface ITaxOptionBlackout {
  storeID: string;
  timeFrom: string;
  timeTo: string;
}

/**
 * 优先判断部门，如果匹配，则使用，
 * 再匹配Listing,如果匹配，则使用，
 *
 * if(如果部门和Listing匹配上) {则再判断是否被排除} else {如果没有匹配上，则取默认}
 *
 */
export interface ITaxOption {
  id: string;
  name: string;
  storeID: string;
  departmentIDs: string[]; // 规定某些部门可以使用该税收配置
  listingIDs: string[]; // 规定某些Listing可以使用该税收配置
  taxOptionBlackouts: ITaxOptionBlackout[];
  includeTips: boolean;
  includedInPrice: boolean;
  method: string; //
  primary: boolean; // 默认税收，如果有部门或listing不能匹配，则取默认税收
  priority: string; // 优先级排序，升序
  stackToServiceFeeLineItem: boolean;
  taxRate: string; // 税率
  taxType: string; // 1: 税收Tax (Default)， 2： 服务费ServiceFee， 3： BottleDeposit
  threshold: string;
}

export interface IRoundingOption {
  taxLineItemLevelRoundingType: number;
  lineItemRoundingType: number;
  taxRoundingType: number;
  taxOrderLevelDecimalPoints: number;
  discountRoundingType: number;
  lineItemDecimalPoints: number;
  taxOrderLevelRoundingType: number;
  discountDecimalPoints: number;
  taxLineitemLevelDecimalPoints: number;
  orderRoundingType: number;
  orderDecimalPoints: number;
}

/**
 * Order Entity
 */
class OrderEntity {

  constructor(storeID: string, isSuperOrder = false) {
    this.storeID = storeID;
    this.isSuperOrder = isSuperOrder;
  }

  context = new Context();

  isSuperOrder = false;
  subOrders: OrderEntity[] = [];

  id = ''; // 解析订单接口时使用
  storeID = '';
  storeSlug = ''; // 解析订单接口时使用
  storeTitle = ''; // 解析订单接口时使用
  invoiceState = ''; // 解析订单接口时使用
  orderStatus = ''; // 解析订单接口时使用
  referenceID = ''; // 解析订单接口时使用
  storeLogoUrl = ''; // 解析订单接口时使用
  storeName = ''; // 解析订单接口时使用
  updatedAt = ''; // 解析订单接口时使用

  customerName?: string;
  deliveryDate?: dayjs.Dayjs;
  dineInParty?: IDineInParty;
  pickupLocationID?: string;
  pickupLocation?: IStorePickupLocation;
  parentID?: number;
  shippingAddressID?: string;
  orderShippingAddress: IObject | undefined;

  from = Data.getChannel() || Channels.RESTAURANT_APP;
  saleType = SaleType.REGISTER_SALE;
  dueDate = dayjs(new Date()).format('YYYY-MM-DD');
  shippingMethod = defaultShippingMethod;
  lineItems: LineItemEntity[] = [];
  invalidLineItems: LineItemEntity[] = [];
  // voidedLineItems: LineItemEntity[] = [];
  lineItemsCount = 0;
  listingLineItemCount = 0;
  discountTotal = 0;
  taxBaseAdjustment = 0;
  taxableAmount = 0;
  initialTax = 0;
  initialNormalTax = 0;
  initialIncludedInPriceTax = 0;
  initialServiceFee = 0;
  subtotal = 0;
  initialTotal = 0;
  // initialProductTotal = 0;
  initialIncludedInPriceTaxForServiceFee = 0;
  initialRedeemDeposits = 0;
  initialTips = 0;
  initialTaxRounding = 0;
  initialRounding = 0;
  initialServiceFeeRounding = 0;
  initialProvidedInRequest = true;
  note = '';
  orderNumber = '';
  smartDiscountInfo: ISmartDiscountInfo | null = null;
  roundingOption: IRoundingOption | undefined;
  
  // leaveReferenceNumberBlank = false;


  setCurrencyCode = (currencyCode: string) => {
    if (this.context) {
      this.context.setCurrencyCode(currencyCode);
    }

    if (Array.isArray(this.lineItems)) {
      this.lineItems.forEach(item => {
        item.setCurrencyCode(currencyCode);
      })
    }
  }

  setSaleType = (saleType: SaleType) => {
    if (this.isSuperOrder) {
      const newSaleType = saleType || SaleType.REGISTER_SALE;
      this.saleType = newSaleType;
      this.subOrders.forEach(order => {
        order.setSaleType(newSaleType)
      })
    } else {
      this.saleType = saleType || SaleType.REGISTER_SALE;
    }
  }

  setShippingMethod = (params: ISetShippingMethodParams) => {
    this.shippingMethod = params.shippingMethod;

    if ([ShippingMethod.DINE_IN].includes(params.shippingMethod)) {
      this.dineInParty = params.dineInParty;
    } else if ([ShippingMethod.PICK_UP].includes(params.shippingMethod)) {
      this.deliveryDate = params.deliveryDate;
      this.pickupLocationID = params.pickupLocationID;
      this.pickupLocation  = params.pickupLocation;
    } else if ([ShippingMethod.DELIVERY].includes(params.shippingMethod)) {
      this.deliveryDate = params.deliveryDate;
      this.shippingAddressID = params.shippingAddressID;
      this.orderShippingAddress = params.orderShippingAddress || {};
    }
  }

  setPickupLocation = (id: string) => {
    this.pickupLocationID = id;
  }

  setTaxOptions = (taxOptions: ITaxOption[]) => {
    this.context.setTaxOptions(taxOptions);
  }


  setRoundingOptions = (roundingOptions: IRoundingOption) => {
    if (roundingOptions) {
      this.roundingOption = roundingOptions;
    }
  }

  checkLineItemsByShippingMethod = () => {
    if (this.isSuperOrder && Array.isArray(this.subOrders)) {
      this.subOrders.forEach(subOrder => {
        subOrder.checkLineItemsByShippingMethod();
      });
    }

    const allLineItems = [
      ...this.lineItems,
      ...this.invalidLineItems,
    ];

    const lineItems: LineItemEntity[] = [];
    const invalidLineItems: LineItemEntity[] = [];
    allLineItems.forEach(item => {
      let procurementMethod: ShippingMethod[] = [];
      const listing = Data.getListing(item.storeID, item.id || '');

      if (listing && Array.isArray(listing.procurementMethod)) {
        procurementMethod = listing.procurementMethod;
      }

      if (procurementMethod.length <= 0 || procurementMethod.includes(this.shippingMethod) || this.shippingMethod === ShippingMethod.QRCODE_SHOPPING) {
        lineItems.push(item);
      } else {
        invalidLineItems.push(item);
      }
    });

    this.lineItems = lineItems;
    this.invalidLineItems = invalidLineItems;
  }

  parseModifiersInfos = (params: IParseModifiersInfosParams) => {
    const { modifiers, modifiersInfos, lineItemEntity, modifierEntity, lineItemQuantity = 1 } = params;
    const modifierKV: {[id: string]: IModifier} = {};

    modifiers.forEach(item => {
      if (item && Array.isArray(item.children)) {
        item.children.forEach(cItem => {
          modifierKV[`${cItem.id}`] = cItem;
        })
      }
    });

    modifiersInfos.forEach(item => {
      let modifier: IModifier | undefined = undefined;
      let cModifiers: IModifier[] = [];
      let cModifiersInfos: IModifierInfo[] = [];
      const quantity = item.quantity || 1;
      if (modifierKV[`${item.id}`]) {
        modifier = modifierKV[`${item.id}`];

        if (Array.isArray(item.modifiersInfos)) {
          cModifiersInfos = item.modifiersInfos;
        }
      }

      if (modifier) {
        if (Array.isArray(modifier.children)) {
          cModifiers = modifier.children;
        }

        const newModifierEntity = new ModifierEntity();
        newModifierEntity.initForModifier(modifier, quantity);
        if (lineItemEntity) {
          if (modifier.listingID) {
            if (quantity > 1) {
              for (let i = 1; i <= quantity; i++) {
                newModifierEntity.initForModifier(modifier, 1);
                const lineItem = new LineItemEntity(this.context);
                lineItem.initForModifier(modifier, lineItemEntity);
                lineItem.setQuantity(1)
                lineItem.modifierEntries.push(newModifierEntity);
                newModifierEntity.repairQuantity(lineItem.quantity);
                lineItem.resetTaxOptionEntries();
                this.lineItems.push(lineItem)
              }
            } else {
              const lineItem = new LineItemEntity(this.context);
              lineItem.initForModifier(modifier, lineItemEntity);
              lineItem.setQuantity(lineItemQuantity)
              lineItem.modifierEntries.push(newModifierEntity);
              newModifierEntity.repairQuantity(lineItem.quantity);
              lineItem.resetTaxOptionEntries();
              this.lineItems.push(lineItem);
            }
          } else {
            lineItemEntity.modifierEntries.push(newModifierEntity);
            newModifierEntity.repairQuantity(lineItemEntity.quantity);
          }
        } else if (modifierEntity) {
          modifierEntity.modifierEntries.push(newModifierEntity);
          newModifierEntity.repairQuantity(modifierEntity.quantity);
        }

        if (cModifiersInfos.length > 0 && cModifiers.length > 0) {
          this.parseModifiersInfos({
            modifiers: cModifiers,
            modifiersInfos: cModifiersInfos,
            modifierEntity: newModifierEntity,
            lineItemQuantity,
          });
        }
      }
    });
  }

  addLineItem = (params: IAddLineItemParams) => {
    const { lineItemInfo, listing, storeID, quantity, taxOptions, roundingOption } = params;
    if (!lineItemInfo || !lineItemInfo.listingID || !listing) return;
    if (this.isSuperOrder && storeID) {
      let orderEntity: OrderEntity | undefined = undefined;
      for (let i = 0; i < this.subOrders.length; i++) {
        if (String(this.subOrders[i].storeID) === String(storeID)) {
          orderEntity = this.subOrders[i];
        }
      }

      if (!orderEntity) {
        orderEntity = new OrderEntity(storeID);
        orderEntity.setTaxOptions(taxOptions);
        orderEntity.setCurrencyCode(this.context.currencyCode);
        this.subOrders.push(orderEntity);
      }

      if (orderEntity) {
        orderEntity.addLineItem({
          lineItemInfo,
          listing,
          quantity,
          taxOptions,
          roundingOption,
        });
      }
    } else if (!this.isSuperOrder) {
      const lineItem = new LineItemEntity(this.context);
      lineItem.initForListing(listing);

      lineItem.setQuantity(quantity)
      lineItem.resetTaxOptionEntries();
      this.lineItems.push(lineItem);

      const modifiers = listing.modifiers || [];
      const modifiersInfos = lineItemInfo.modifiersInfos || [];
      if (modifiers.length > 0 && modifiersInfos.length > 0) {
        this.parseModifiersInfos({
          modifiers,
          modifiersInfos,
          lineItemEntity: lineItem,
          lineItemQuantity: quantity || 1,
        });
      }
    }
  }

  initBySuperOrderEntityJson = (superOrderEntityJson: IOrderEntityJson, storeIDRefTaxOptions: { [storeID: string]: ITaxOption[] }) => {
    this.subOrders = [];

    let orderEntityJson: IOrderEntityJson[] = [];
    if (superOrderEntityJson) {
      this.storeID = superOrderEntityJson.store_id || this.storeID;
      this.from = superOrderEntityJson.from || this.from;
      this.saleType = superOrderEntityJson.sale_type || this.saleType;
      this.shippingMethod = superOrderEntityJson.shipping_method || this.shippingMethod;

      if (Array.isArray(superOrderEntityJson.sub_orders)) {
        orderEntityJson = superOrderEntityJson.sub_orders;
      }
    }

    orderEntityJson.forEach(item => {
      const orderEntity = new OrderEntity(item.store_id);
      orderEntity.setTaxOptions(storeIDRefTaxOptions[item.store_id] || []);
      orderEntity.setCurrencyCode(this.context.currencyCode);
      orderEntity.initByOrderEntityJson(item, storeIDRefTaxOptions);

      this.subOrders.push(orderEntity);
    })
  }

  initByOrderEntityJson = (orderEntityJson: IOrderEntityJson, storeIDRefTaxOptions: { [storeID: string]: ITaxOption[] }, storePickupLocations?: IStorePickupLocation[]) => {
    this.lineItems = [];

    if (orderEntityJson && orderEntityJson.party) {
      this.dineInParty = {
        numberOfPeople: orderEntityJson.party.number_of_people,
        status: orderEntityJson.party.status,
        tableID: orderEntityJson.party.table_id,
        tableName: orderEntityJson.party.table_name,
        turnTime: orderEntityJson.party.turn_time,
      };
    }

    if (this.isSuperOrder) {
      this.initBySuperOrderEntityJson(orderEntityJson, storeIDRefTaxOptions);
    } else {
      let listingLineItems: ILineItemJson[] = [];
      let listingInvalidLineItems: ILineItemJson[] = [];
      if (orderEntityJson) {
        this.storeID = orderEntityJson.store_id || this.storeID;
        this.from = orderEntityJson.from || this.from;
        this.saleType = orderEntityJson.sale_type || this.saleType;
        this.shippingMethod = orderEntityJson.shipping_method || this.shippingMethod;
        this.note = orderEntityJson.note || this.note;
        this.deliveryDate = dayjs(orderEntityJson.delivery_date) || this.deliveryDate;
        this.shippingAddressID = orderEntityJson.shipping_address_id || this.shippingAddressID;
        this.smartDiscountInfo = orderEntityJson.smart_discount_info || this.smartDiscountInfo;
        // this.pickupLocationID = orderEntityJson.pickup_location_id || this.pickupLocationID;
        // this.pickupLocation = orderEntityJson.pickup_location || this.pickupLocation;


        if (Array.isArray(orderEntityJson.line_items)) {
          listingLineItems = orderEntityJson.line_items;
        }

        if (Array.isArray(orderEntityJson.invalid_line_items)) {
          listingInvalidLineItems = orderEntityJson.invalid_line_items;
        }
      }

      if (storePickupLocations && storePickupLocations.length>0) {
        this.pickupLocationID = storePickupLocations[0].id || this.pickupLocationID;
        this.pickupLocation = storePickupLocations[0] || this.pickupLocation;
      }
      listingLineItems.forEach(item => {
        const lineItemEntity = new LineItemEntity(this.context);
        lineItemEntity.initForLineItemJson(item);
        lineItemEntity.resetTaxOptionEntries();
        this.lineItems.push(lineItemEntity);
      });

      listingInvalidLineItems.forEach(item => {
        const lineItemEntity = new LineItemEntity(this.context);
        lineItemEntity.initForLineItemJson(item);
        lineItemEntity.resetTaxOptionEntries();
        this.invalidLineItems.push(lineItemEntity);
      });
    }
  }

  insertOrderEntitiesForSuperOrder = (orderEntities: OrderEntity[]) => {
    if (this.isSuperOrder) {
      for (let i = 0; i < orderEntities.length; i++) {
        const orderEntity = orderEntities[i];
        if (orderEntity.lineItems.length > 0) {
          if (orderEntity.isSuperOrder) {
            orderEntity.subOrders.forEach(item => {
              this.subOrders.push(item);
            })
          } else {
            this.subOrders.push(orderEntity);
          }
        }
      }
    }
  }

  static getStoreIDsByOrderEntityJson = (orderEntityJson: IOrderEntityJson) => {
    const storeIDSet = new Set<string>();
    if (orderEntityJson) {
      if (Array.isArray(orderEntityJson.sub_orders)) {
        orderEntityJson.sub_orders.forEach(item => {
          if (item && item.store_id) {
            storeIDSet.add(item.store_id);
          }
        })
      }

      if (orderEntityJson.store_id) {
        storeIDSet.add(orderEntityJson.store_id);
      }
    }

    return [...storeIDSet];
  }

  initByOrderApiData = (invoice: any, type: 'ORDER' | 'NEXT_ORDER') => {
    if (invoice && invoice.id && invoice.store_id) {
      const orderInfo: IOrderInfo = {
        storeID: invoice.store_id || '',
        storeSlug: invoice.store_slug || '',
        storeTitle: invoice.store_title || '',
        orderNumber: invoice.number || '',
        paidTotal: invoice.paid_total || 0,
        invoiceState: invoice.invoice_state || '',
        currencyCode: invoice.currency || '',
        state: invoice.state || '',
        saleTransactions: [],
        shippingMethod: invoice.shipping_method || defaultShippingMethod,
        deliveryDate: invoice.delivery_date || null,
        dineInParty: invoice.party || invoice.first_party_details || null,
        initialTotal: invoice.initial_total || 0,
        subTotal: invoice.subtotal || 0,
        initialServiceFee: Number(invoice.initial_service_fee || 0),
        initialTax: Number(invoice.initial_tax || 0),
        initialRounding: Number(invoice.initial_rounding || 0),
        discountTotal: 0,
        superOrder: invoice.is_super_order || false,
        orderEntities: [],
        referenceID: invoice.reference_number || '',
        orderStatus: getOrderStatus(invoice) ||  '',
        storeLogoUrl: invoice.store_logo_url || '',
        orderTime: invoice.created_at || '',
        storeName: invoice.store_name || '',
        orderShippingAddress: invoice.order_shipping_address || {},
        note: invoice.note || '',
        pickupLocationID: invoice.pickup_location_id || null,
        pickupLocation: invoice.pickup_location || null,
        parentID: invoice.parent_id || null,
        from: invoice.from || null,
        shippingAddressID: invoice.shipping_address_id || null,
        smartDiscountInfo: null,
      }

      if (invoice.party) {
        orderInfo.dineInParty = {
          numberOfPeople: invoice.party.number_of_people || 1,
          status: invoice.party.status || 'seated',
          tableID: invoice.party.table_id || '',
          tableName: invoice.party.table_name || '',
          turnTime: invoice.party.turn_time || 90,
        }
      }

      if (invoice.first_party_details) {
        orderInfo.dineInParty = {
          numberOfPeople: invoice.first_party_details.number_of_people || 1,
          status: invoice.first_party_details.status || 'seated',
          tableID: invoice.first_party_details.table_id || '',
          tableName: invoice.first_party_details.table_name || '',
          turnTime: invoice.first_party_details.turn_time || 90,
        }
      }
      this.dineInParty = orderInfo.dineInParty ? orderInfo.dineInParty : undefined;

      this.parseOrderApi(invoice, orderInfo, type);

      if (orderInfo.superOrder) {
        const subOrders: OrderEntity[] = [];
        let resSubOrders: any[] = [];
        if (Array.isArray(invoice.sub_orders)) {
          resSubOrders = invoice.sub_orders;
        } else if (Array.isArray(invoice.sub_order)) {
          resSubOrders = invoice.sub_order;
        }

        resSubOrders.forEach(item => {
          const orderEntity = new OrderEntity(item.store_id);
          orderEntity.parseOrderApi(item, orderInfo, type);
          subOrders.push(orderEntity);
        })

        this.subOrders = subOrders;
      }
    }
  }

  static getStoreIDsByOrderApiData = (invoice: any) => {
    const storeIDSet = new Set<string>();
    if (invoice && invoice.id && invoice.store_id) {
      storeIDSet.add(invoice.store_id);

      if (invoice.is_super_order) {
        let resSubOrders: any[] = [];
        if (Array.isArray(invoice.sub_orders)) {
          resSubOrders = invoice.sub_orders;
        } else if (Array.isArray(invoice.sub_order)) {
          resSubOrders = invoice.sub_order;
        }

        resSubOrders.forEach(item => {
          if (item && item.store_id) {
            storeIDSet.add(item.store_id);
          }
        })
      }
    }

    return [...storeIDSet];
  }

  toSuperOrderEntityJson = (): IOrderEntityJson => {
    const lineItemJsons: ILineItemJson[] = [];

    const subOrders: IOrderEntityJson[] = [];
    let shippingMethod: ShippingMethod = this.shippingMethod;
    let deliveryDate = null;
    let pickupLocation;
    let pickupLocationID;
    let shippingAddressID;
    this.subOrders.forEach(subOrder => {
      const orderEntityJson: IOrderEntityJson = subOrder.toJson();
      const orderEntityJson2: IOrderEntityJson = subOrder.toJson();
      deliveryDate = orderEntityJson.delivery_date;
      shippingMethod = orderEntityJson.shipping_method;
      pickupLocation = orderEntityJson.pickup_location;
      pickupLocationID = orderEntityJson.pickup_location_id;
      shippingAddressID = orderEntityJson.shipping_address_id;

      subOrders.push(orderEntityJson);
      lineItemJsons.push(...orderEntityJson2.line_items);
    });

    const superOrderEntityJson: any = {
      'store_id': this.storeID,
      'from': this.from,
      'sale_type': this.saleType,
      'shipping_method': shippingMethod,
      'line_items': lineItemJsons,
      'sub_orders': subOrders,
      'subtotal': this.subtotal,
      'initial_total': this.initialTotal,
      'initial_provided_in_request': this.initialProvidedInRequest,
      'delivery_date': deliveryDate,
      'pickup_location': pickupLocation,
      'pickup_location_id': pickupLocationID,
      'shipping_address_id': shippingAddressID,
    }

    return superOrderEntityJson;
  }

  toOrderEntityJson = (): IOrderEntityJson => {

    const lineItemJsons: ILineItemJson[] = [];
    const invalidLineItemJsons: ILineItemJson[] = [];

    this.lineItems.forEach(item => {
      item.setShippingMethod(this.shippingMethod);
      lineItemJsons.push(item.toJson());
    });

    this.invalidLineItems.forEach(item => {
      item.setShippingMethod(this.shippingMethod);
      invalidLineItemJsons.push(item.toJson());
    });
    
    const orderEntityJson: IOrderEntityJson = {
      'store_id': this.storeID,
      'from': this.from,
      'sale_type': this.saleType,
      'due_date': this.dueDate,
      'shipping_method': this.shippingMethod,
      'line_items': lineItemJsons,
      'voided_line_items': [],
      'invalid_line_items': invalidLineItemJsons,
      'number_of_items': this.lineItemsCount,
      'number_of_listing_items': this.listingLineItemCount,
      'discount_total': this.discountTotal,
      'tax_base_adjustment': this.taxBaseAdjustment,
      'taxable_amount': this.taxableAmount,
      'initial_tax': this.initialTax,
      'initial_normal_tax': this.initialNormalTax,
      'initial_included_in_price_tax': this.initialIncludedInPriceTax,
      'initial_service_fee': this.initialServiceFee,
      'subtotal': this.subtotal,
      'initial_total': this.initialTotal,
      'initial_product_total': this.initialTotal,
      'initial_included_in_price_tax_for_service_fee': this.initialIncludedInPriceTaxForServiceFee,
      'initial_redeem_deposits': this.initialRedeemDeposits,
      'initial_tips': this.initialTips,
      'initial_tax_rounding': this.initialTaxRounding,
      'initial_rounding': this.initialRounding,
      'initial_service_fee_rounding': this.initialServiceFeeRounding,
      'initial_provided_in_request': this.initialProvidedInRequest,
      'note': this.note,
      'pickup_location_id': this.pickupLocationID,
      'pickup_location': this.pickupLocation,
      'customer_id': '',
      'shipping_address_id': this.shippingAddressID,
      'smart_discount_info': this.smartDiscountInfo || null,
      // 'leave_reference_number_blank': this.leaveReferenceNumberBlank,
    }

    if ([ShippingMethod.DELIVERY, ShippingMethod.PICK_UP].includes(this.shippingMethod)) {
      orderEntityJson['delivery_date'] = dayjs(this.deliveryDate).format();
    } else if ([ShippingMethod.DINE_IN].includes(this.shippingMethod)) {
      if (this.dineInParty) {
        orderEntityJson['party'] = {
          'number_of_people': this.dineInParty.numberOfPeople,
          'status': this.dineInParty.status || 'seated',
          'table_id': this.dineInParty.tableID || '',
          'table_name': this.dineInParty.tableName || '',
          'turn_time': this.dineInParty.turnTime || 90,
        };
      }
    } else if (ShippingMethod.DINE_IN_UNASSIGNED === this.shippingMethod) {
      orderEntityJson['delivery_date'] = dayjs(this.deliveryDate).format();
    }
    if (this.shippingMethod !== ShippingMethod.DELIVERY) {
      delete orderEntityJson['shipping_address_id'];
    }

    return orderEntityJson;
  }

  toJson = () => {
    if (this.isSuperOrder) {
      return this.toSuperOrderEntityJson();
    } else {
      return this.toOrderEntityJson();
    }
  }

  repairGroupLineItemQuantity = (groupID: number, uuid: string, quantity: number) => {
    const lineItems = this.lineItems;
    for (let i = 0; i < lineItems.length; i++) {
      const lineItem = lineItems[i];
      if (lineItem.uuid !== uuid && lineItem.groupID === groupID) {
        lineItem.quantity = quantity * lineItem.unitQuantity;
        lineItem.repairModifierQuantity();
      }
    }
  }

  addLineItemQuantity = (uuid: string, lineItemQuantity = 1) => {
    if (this.isSuperOrder) {
      this.subOrders.forEach(subOrder => {
        subOrder.addLineItemQuantity(uuid);
      })
    } else {
      const lineItems = this.lineItems;
      let groupID: number | undefined = undefined;
      let quantity = 0;
      for (let i = 0; i < lineItems.length; i++) {
        const lineItem = lineItems[i];
        if (lineItem.uuid === uuid) {
          lineItem.quantity += lineItemQuantity;
          lineItem.repairModifierQuantity();
          groupID = lineItem.groupID;
          quantity = lineItem.quantity;
          break;
        }
      }

      if (groupID && quantity > 0) {
        this.repairGroupLineItemQuantity(groupID, uuid, quantity)
      }
    }
  }

  minusLineItemQuantity = (uuid: string, lineItemQuantity = 1, forExpressOrder?: boolean) => {
    if (this.isSuperOrder) {
      this.subOrders.forEach(subOrder => {
        subOrder.minusLineItemQuantity(uuid, lineItemQuantity, forExpressOrder);
      })
    } else {
      let lineItems = this.lineItems;
      let newLineItem: LineItemEntity[] = [];
      let groupID: number | undefined = undefined;
      let quantity = 0;
      let isRemoveGroup = false;
      for (let i = 0; i < lineItems.length; i++) {
        const lineItem = lineItems[i];
        if (lineItem.uuid === uuid) {
          if (lineItem.quantity > lineItemQuantity || forExpressOrder) {
            if (lineItem.quantity > 0) {
              lineItem.quantity -= lineItemQuantity
            }
            lineItem.repairModifierQuantity();
            newLineItem.push(lineItem)
            groupID = lineItem.groupID;
            quantity = lineItem.quantity;
          } else {
            isRemoveGroup = true;
            groupID = lineItem.groupID;
          }
        } else {
          newLineItem.push(lineItem)
        }
      }

      if (isRemoveGroup) {
        lineItems = newLineItem;
        newLineItem = [];
        for (let i = 0; i < lineItems.length; i++) {
          const lineItem = lineItems[i];
          if (lineItem.groupID !== groupID) {
            newLineItem.push(lineItem)
          }
        }
      }

      this.lineItems = newLineItem;

      if (groupID && quantity > 0) {
        this.repairGroupLineItemQuantity(groupID, uuid, quantity)
      }
    }
  }

  zeroLineItemsQuantity = () => {
    if (this.isSuperOrder) {
      this.subOrders.forEach(subOrder => {
        subOrder.zeroLineItemsQuantity();
      })
    } else {
      const lineItems = this.lineItems;
      for (let i = 0; i < lineItems.length; i++) {
        const lineItem = lineItems[i];
        lineItem.quantity = 0;
        this.repairGroupLineItemQuantity(lineItem.groupID, lineItem.uuid, 0);
      }
    }
  }

  /**
   * 用来解析订单接口数据
   * @param data 订单接口返回的invoice
   */
  parseOrderApi = (data: IObject, orderInfo: IOrderInfo, type: 'ORDER' | 'NEXT_ORDER') => {
    this.lineItems = [];
    let listingLineItems: any[] = [];
    if (data) {
      this.id = '';
      this.storeID = data.store_id || '';
      this.storeSlug = data.store_slug || '';
      this.storeTitle = data.store_title || '';
      this.customerName = data.customer_name || '';
      this.invoiceState = data.invoice_state || '';
      this.orderStatus = getOrderStatus(data) || '';
      this.referenceID = data.reference_number || '';
      this.storeLogoUrl = data.store_logo_url || data.logo_url || '';
      this.initialTotal = data.initial_total || 0;
      this.initialServiceFee = Number(data.initial_service_fee || 0);
      this.initialTax = Number(data.initial_tax || 0);
      this.initialRounding = Number(data.initial_rounding || 0);
      this.storeName = data.store_name || '';
      this.subtotal = data.subtotal || 0;
      this.updatedAt = data.updated_at || '';
      this.from = data.from || this.from;
      this.saleType = data.sale_type || this.saleType;
      this.shippingMethod = data.shipping_method || this.shippingMethod;
      this.orderNumber = data.number || '';
      this.context.setCurrencyCode(orderInfo.currencyCode);
      this.pickupLocationID = data.pickup_location_id || null;
      this.pickupLocation = data.pickup_location || null;
      this.note = data.note || '';
      this.deliveryDate = dayjs(data.delivery_date) || null;
      this.shippingAddressID = data.shipping_address_id;
      this.orderShippingAddress = data.order_shipping_address || {};
      this.smartDiscountInfo = null;

      if (type === 'ORDER') {
        this.id = data.id || '';
      }

      if (!this.isSuperOrder) {
        if (Array.isArray(data.listing_line_items)) {
          listingLineItems = data.listing_line_items;
        }

        if (listingLineItems.length < 1 && Array.isArray(data.voided_line_items)) {
          listingLineItems = data.voided_line_items;
        }
      }
    }

    listingLineItems.forEach(item => {
      const lineItemEntity = new LineItemEntity(this.context);
      lineItemEntity.initForOrder(item, orderInfo, type);
      if (type === 'NEXT_ORDER') {
        lineItemEntity.setDiscountEntries([]);
      }
      // lineItemEntity.resetTaxOptionEntries();
      this.lineItems.push(lineItemEntity);
    });
  }

  static toReceiptObjectForLineItems = (lineItems: LineItemEntity[]) => {
    const newLineItems: LineItemEntity[] = [];
    const modifierLineItems: LineItemEntity[] = [];
    const lineItemsKV: {[groupID: string]: LineItemEntity} = {};

    lineItems.forEach(item => {
      if (item && (item.favoriteTabID || (item.shippingMethod === ShippingMethod.QRCODE_SHOPPING && !item.modifierSetOptionID))) {
        newLineItems.push(item);
        lineItemsKV[item.groupID] = item;
      } else {
        modifierLineItems.push(item);
      }
    });

    modifierLineItems.forEach(item => {
      const lineItem = lineItemsKV[item.groupID];
      if (lineItem) {
        lineItem.modifierEntries.push(...item.modifierEntries);
      }
    });

    newLineItems.forEach(lineItem => {
      let newSubtotal = times(lineItem.quantity, lineItem.price);
      if (Array.isArray(lineItem.modifierEntries)) {
        lineItem.modifierEntries.forEach(item => {
          newSubtotal = plus(newSubtotal, item.subtotal || 0)
        });
      }

      lineItem.subtotal = newSubtotal;
      lineItem.total = minus(newSubtotal, lineItem.discountTotal);
    });

    return newLineItems;
  }

  static toReceiptObject = (data: OrderEntity) => {
    const orderEntity = cloneDeep(data);

    orderEntity.lineItems = OrderEntity.toReceiptObjectForLineItems(orderEntity.lineItems);
    orderEntity.invalidLineItems = OrderEntity.toReceiptObjectForLineItems(orderEntity.invalidLineItems);

    return orderEntity;
  }

  toReceiptOrderEntity = () => {
    return OrderEntity.toReceiptObject(this);
  }

  toOrderInfo = (): IOrderInfo => {
    const orderEntities: any[] = [];

    if (this.isSuperOrder) {
      this.subOrders.forEach(subOrder => {
        const orderInfo: IOrderInfo = subOrder.toOrderInfo();
        orderEntities.push(...orderInfo.orderEntities);
      });
    } else {
      orderEntities.push(this.toReceiptOrderEntity());
    }

    const data: IOrderInfo = {
      storeID: this.storeID,
      storeSlug: this.storeSlug,
      storeTitle: this.storeTitle,
      orderNumber: '',
      paidTotal: 0,
      invoiceState: this.invoiceState || '',
      currencyCode: this.context.currencyCode,
      state: this.invoiceState || '',
      saleTransactions: [],
      shippingMethod: this.shippingMethod,
      deliveryDate: null,
      dineInParty: null,
      initialTotal: this.initialTotal,
      subTotal: this.subtotal,
      initialServiceFee: this.initialServiceFee,
      initialTax: this.initialTax,
      discountTotal: this.discountTotal,
      initialRounding: this.initialRounding,
      superOrder: false,
      orderEntities,
      referenceID: 0,
      orderStatus: '',
      storeLogoUrl: '',
      orderTime: '',
      storeName: '',
      orderShippingAddress: this.orderShippingAddress || {},
      note: '',
      pickupLocationID: this.pickupLocationID,
      pickupLocation: this.pickupLocation,
      parentID: this.parentID,
      from: null,
      shippingAddressID: this.shippingAddressID,
      smartDiscountInfo: null,
    }

    if (this.deliveryDate) {
      data.deliveryDate = dayjs(this.deliveryDate).format();
    }

    if (this.dineInParty) {
      data.dineInParty = this.dineInParty;
    }

    return data;
  }

  getSuperOrderListingCountRef = () => {
    let listingCountRef: ListingCountRef = {};

    for (let i = 0; i < this.subOrders.length; i++) {
      const subOrder = this.subOrders[i];
      listingCountRef = {
        ...listingCountRef,
        ...subOrder.getListingCountRef(),
      }
    }

    return listingCountRef;
  }

  getListingCountRef = () => {
    let listingCountRef: ListingCountRef = {};

    if (this.isSuperOrder) {
      listingCountRef = this.getSuperOrderListingCountRef();
    } else {
      const orderEntity = this.toReceiptOrderEntity();

      orderEntity.lineItems.forEach(item => {
        if (item.id && item.favoriteID) {
          if (!listingCountRef[item.id]) {
            listingCountRef[item.id] = {
              quantity: item.quantity,
              uuids: [item.uuid],
            };
          } else {
            listingCountRef[item.id].quantity += item.quantity;
            listingCountRef[item.id].uuids.push(item.uuid)
          }
        }
      });
    }

    return listingCountRef;
  }

  calculateSuperOrder = () => {
    /** 计算总计和小计 */
    const decimal = this.context.orderLevelDecimalPlaces;
    let subtotal = 0;
    let initialTotal = 0;

    this.subOrders.forEach(subOrder => {
      subOrder.calculate();

      subtotal = plus(subtotal, subOrder.subtotal, decimal);
      initialTotal = plus(initialTotal, subOrder.initialTotal, decimal);
    });

    this.subtotal = subtotal;
    this.initialTotal = initialTotal;
  }

  getModifierEntries = (modifierEntries: IModifierJson[]) => {
    const newModifierEntries: IModifierJson[] = [];

    const recursiveCombine = (entries: IModifierJson[]) => {
      entries.forEach(item => {
        if (Array.isArray(item.modifier_entries) && item.modifier_entries.length > 0) {
          recursiveCombine(item.modifier_entries);
        } else {
          newModifierEntries.push(item);
        }
      })
    }

    recursiveCombine(modifierEntries);

    return newModifierEntries
  }

  calculateDiscount = () => {
    const items: IObject[] = [];
    let discountValue = 0;
    let taxValue = 0;
    let serviceFeeValue = 0;
    this.lineItems.forEach(item => {
      const itemJson = item.toJson();
      const orderItem = {
        'listing_id': item.id,
        'quantity': item.quantity,
        'discount_entries': item.discountEntries,
        'uuid': item.uuid,
        'line_item_name': item.name,
        'exempt_discount': false,
        'remove_auto_discount': false,
        'custom_field': {},
        'listing_barcode': '',
        'price': item.price || 0,
        'product_id': item.productID || '',
        'purchasable_type': item.purchasableType,
        'department_id': item.departmentID,
        'category_id': item.categoryID,
        'tax_option': item.taxOptionEntries,
        'modifier_entries': this.getModifierEntries(itemJson.modifier_entries),
        'brand': item.brand || '',
      }
      items.push(orderItem);
    })
    const order = {
      'line_items': items,
      'created_at': this.dueDate,
      'customer': {
        membershipLevelID: this.smartDiscountInfo && this.smartDiscountInfo.membershipLevelID ? this.smartDiscountInfo.membershipLevelID : '',
      },
    }
    const orderBuilder = new OrderBuilder(order);
    orderBuilder.initSmartDiscount(this.smartDiscountInfo && this.smartDiscountInfo.discountScript ? this.smartDiscountInfo.discountScript : []);
    let roundingOptions = {};
    if (this.roundingOption) {
      roundingOptions = {
        'tax_lineitem_level_rounding_type': Number(this.roundingOption.taxLineItemLevelRoundingType),
        'line_item_rounding_type': Number(this.roundingOption.lineItemRoundingType),
        'tax_rounding_type': Number(this.roundingOption.taxRoundingType),
        'tax_order_level_decimal_points': Number(this.roundingOption.taxOrderLevelDecimalPoints),
        'discount_rounding_type': Number(this.roundingOption.discountRoundingType),
        'line_item_decimal_points': Number(this.roundingOption.lineItemDecimalPoints),
        'order_rounding_type': Number(this.roundingOption.orderRoundingType),
        'order_decimal_points': Number(this.roundingOption.orderDecimalPoints),
        'tax_order_level_rounding_type': Number(this.roundingOption.taxOrderLevelRoundingType),
        'discount_decimal_points': Number(this.roundingOption.discountDecimalPoints),
        'tax_lineitem_level_decimal_points': Number(this.roundingOption.taxLineitemLevelDecimalPoints),
      };
    }
    const calculate = roundingOptions !== '{}' ? orderBuilder.setConfig({ roundingOptions }).orderCalculate() : null;
    if (calculate && calculate.line_items && Array.isArray(order.line_items)) {
      const { price_statistic: priceStatistic = [], line_items: lineItems = [] } = calculate;
      priceStatistic.forEach((item: any) => {
        if (item.display_type === DisplayType.DISCOUNT && item.code === 'discount') {
          discountValue = item.display_price;
        }
        if (item.display_type === DisplayType.TAX) {
          taxValue = item.display_price;
        }
        if (item.display_type === DisplayType.SERVICE_FEE) {
          serviceFeeValue = item.display_price;
        }
      })
      lineItems.forEach((item: any) => {
        this.lineItems.forEach(lineItem => {
          if (item.uuid === lineItem.uuid) {
            const { smart_discount_entries: smartDiscountEntries = [], tax_option: taxAndServiceFeeOption = []} = item
            const discountEntries: any[] = [];
            if (Array.isArray(smartDiscountEntries)) {
              smartDiscountEntries.forEach(discount => {
                if (discount.discount_id) {
                  discountEntries.push({
                    'amount': Math.abs(discount.amount) || 0,
                    'discount_amount': Math.abs(discount.org_total_amount) || 0,
                    'discount_id': discount.discount_id || '',
                    'discount_name': discount.discount_name || '',
                  })
                }
              })
            }
            lineItem.setDiscountEntries(discountEntries);
            const taxOptions: ITaxOptionEntrie[] = [];
            const taxAndServiceFeeOptions: any = [];
            if (Array.isArray(taxAndServiceFeeOption)) {
              taxAndServiceFeeOption.forEach(tax => {
                if (String(item.listing_id) === String(tax.line_item_id)) {
                  if (tax.tax_type === 2) {
                    tax.amount = item.tax_fees.service_fee;
                  }
                  const newTax: any = pick(tax, [
                    'id',
                    'name',
                    'amount',
                    'tax_rate',
                    'tax_option_id',
                    'line_item_id',
                    'primary',
                    'tax_type',
                    'method',
                    'threshold',
                    'priority',
                    'included_in_price',
                    'uuid',
                    'tax_base',
                  ])
                  if (tax.tax_type === 2) {
                    tax.amount = item.tax_fees.service_fee;
                    newTax.amount = item.tax_fees.service_fee;
                  }
                  if (tax.tax_type === 1) {
                    tax.amount = item.tax_fees.tax_amount;
                    newTax.amount = item.tax_fees.tax_amount;
                    taxOptions.push(tax);
                  }
                  taxAndServiceFeeOptions.push(newTax);

                }
              })
              if (this.shippingMethod === ShippingMethod.DINE_IN || this.shippingMethod === ShippingMethod.QRCODE_SHOPPING) {
                lineItem.setTaxOptionEntries(taxAndServiceFeeOptions); // 堂食 有税和服务费
              } else {
                lineItem.setTaxOptionEntries(taxOptions); // 非堂食 只有税，没有服务费
              }
            }
          }
        })
      })
    }
    const smartDiscountInfo: any = {
      ...this.smartDiscountInfo,
      discountValue,
      taxValue,
      serviceFeeValue,
    }
    this.smartDiscountInfo = smartDiscountInfo;
    this.initialRounding = calculate.initial_rounding;
  }

  calculateOrder = () => {
    /** 统计LineItems数量 */
    this.lineItemsCount = 0;
    this.listingLineItemCount = 0;

    /** 计算总计和小计 */
    const decimal = this.context.orderLevelDecimalPlaces;
    let subtotal = 0;
    let discountTotal = 0;
    this.initialNormalTax = 0;
    // this.initialServiceFee = 0;
    let initialTax = 0;
    let initialServiceFee = 0;

    this.lineItems.forEach(item => {
      if (item.purchasableType === PurchasableType.LISTING) {
        this.listingLineItemCount += 1;
      }
      if (!item.purchasableType || !purchasableTypeForLineItemCount.includes(item.purchasableType)) {
        this.lineItemsCount += 1;
      }

      item.calculate(this.context);
      if (!item.purchasableType || !purchableTypesForOrderSubtotal.includes(item.purchasableType)) {
        subtotal = plus(subtotal, item.subtotal, decimal);
      }

      discountTotal = plus(discountTotal, item.discountTotal, decimal);
      initialTax = plus(initialTax, item.taxAmount, decimal);
      initialServiceFee = plus(initialServiceFee, item.serviceFee, decimal);
    });

    this.subtotal = subtotal;
    this.taxableAmount = subtotal;
    this.discountTotal = discountTotal;
    if (this.shippingMethod !== ShippingMethod.QRCODE_SHOPPING) {
      this.initialTax = initialTax;
      this.initialServiceFee = initialServiceFee;
    }
    let initialTotal = plus(subtotal, this.initialTax, decimal);
    initialTotal = plus(initialTotal, this.initialServiceFee, decimal);
    initialTotal = minus(initialTotal, Math.abs(discountTotal), decimal);
    this.initialTotal = plus(initialTotal, this.initialRounding, decimal);
  }

  calculate = () => {
    if (this.isSuperOrder) {
      this.calculateSuperOrder();
    } else {
      this.calculateOrder();
    }
  }

  setSmartDiscountInfo = (params: ISetDiscountValue) => {
    const {
      promotionCode,
      discountScript,
      membershipLevelID,
      promotionName,
      cancel = false
    } = params;
    let smartDiscountInfo;
    if (!cancel) {
      smartDiscountInfo = {
        promotionCode,
        discountScript,
        promotionName,
        membershipLevelID,
        discountValue: this.smartDiscountInfo ? this.smartDiscountInfo.discountValue : 0,
        serviceFeeValue: this.smartDiscountInfo ? this.smartDiscountInfo.serviceFeeValue : 0,
        taxValue: this.smartDiscountInfo ? this.smartDiscountInfo.taxValue : 0,
      }
    } else {
      smartDiscountInfo = null;
      this.lineItems.forEach(item => item.setDiscountEntries([]))
    }
    
    this.smartDiscountInfo = smartDiscountInfo;
  }

  setSubtotal = (subtotal: number) => {
    this.subtotal = subtotal;
  }

  setInitialTotal = (total: number) => {
    this.initialTotal = total;
  }

  removeLineItems = (listingUuids: string[]) => {
    const lineItems = this.lineItems;
    const newLineItems = [];
    for (let i = 0; i < lineItems.length; i++) {
      const lineItem = lineItems[i];
      const { modifierEntries } = lineItem;
      if (listingUuids.includes(`${lineItem.uuid}`)) {
        continue;
      }

      let isLineItemModifier = false;

      if (lineItem.lineItemType === 'MODIFIER') {
        const uuids = modifierEntries.map(item => item.uuid);
        for (let j = 0; j < uuids.length; j++) {
          const uuid = uuids[j];
          if (listingUuids.includes(`${uuid}`)) {
            isLineItemModifier = true;
            break;
          }
        }
      };

      if (isLineItemModifier) continue;

      newLineItems.push(lineItem);
    }

    this.lineItems = newLineItems;
  }
}

export default OrderEntity;
