import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import { getAppTheme, setScreenColor, getIntl, getIntlByPath } from '@/components/App/App';
import Layout from '@/components/Layout/Layout';
import { prefix } from '.';
import LineItemEntity from '@/lib/order-builder/models/LineItemEntity';
import ActionType from '@/actions/action-type';
import { IShoppingCartData } from '@/actions/shopping-cart-action';
import { IStore, IStoreConfig } from '@/actions/store-action';
import { parseQrcodeShoppingOrderInfosCount } from '@/utils/cart';
import { IOrderInfo } from '@/lib/order-builder/models/OrderEntity';
import ListingsPanel from '@/components/ListingsPanel';
import Icon, { IconType } from '@/components/Icon';
import { setCanAutoProcurementMethodPopup } from '@/pages/MenuListPage/MenuListPage';
import paths from '@/routes/paths';
import cloneDeep from 'lodash/cloneDeep';
import ScanCodeInfo from '@/components/ScanCodeInfo/ScanCodeInfo';
import Button from 'antd-mobile/es/button';
import Modal from 'antd-mobile/es/modal';
import { QrcodeShoppingCartPageStyled } from './styled';
import { langFormat } from '@/utils/app';

export interface IQrcodeShoppingCartPageProps extends IBasePageProps {
}

export interface IQrcodeShoppingCartPageState {
  storeSlug: string;
  shoppingCartData?: IShoppingCartData;
  loadedData: boolean;
  store?: IStore;
  storeConfig?: IStoreConfig;
  isOrderDetail?: boolean;
  result: string;
}

class QrcodeShoppingCartPage extends BasePage<IQrcodeShoppingCartPageProps, IQrcodeShoppingCartPageState> {
  constructor(props: IQrcodeShoppingCartPageProps) {
    super(props);

    this.state = {
      storeSlug: '',
      loadedData: false,
      store: undefined,
      result: '',
    }
  }

  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.darkBackgroundColor) {
      setScreenColor(appTheme.darkBackgroundColor);
    }

    super.componentDidMount();
    const { dispatch, routeParams } = this.props;
    const { storeSlug, orderNumber, authCode } = routeParams;
    const isOrderDetail = routeParams.routePath === paths.QRCODE_SHOPPING_DETAIL;
    this.setState({
      storeSlug,
      isOrderDetail,
    })

    if (storeSlug && orderNumber && authCode) {
      dispatch({
        type: ActionType.START_QRCODE_SHOPPING_INVOICE_TASK_SYNC,
        storeSlug,
        orderNumber,
        authCode,
      });
    }

    const { reducersState } = this.props;
    const { invoiceInfo } = reducersState.qrcordShopping;
    this.closeLoading();
    if (!invoiceInfo.orderNumber) {
      this.openLoading();
    }

    if (!isOrderDetail) {
      dispatch({
        type: ActionType.GET_STORE_SHOPPING_CART_DATA,
        storeSlug,
        callback: params => {
          this.setState({ shoppingCartData: params.shoppingCartData, loadedData: true });
        },
      });
    }
  }

  componentDidUpdate(prevProps: IQrcodeShoppingCartPageProps) {
    const { reducersState: prevReducersState } = prevProps;
    const { state: prevState } = prevReducersState.qrcordShopping.invoiceInfo;
    const { showLoading: prevShowLoading } = prevReducersState.app;
    const { reducersState, dispatch } = this.props;
    const { state } = reducersState.qrcordShopping.invoiceInfo;
    const { showLoading } = reducersState.app;

    if (prevState !== state || prevShowLoading !== showLoading) {
      if ((state === 'voided' || state === 'completed') && !showLoading) {
        this.qrcodeInvalidTips();
        dispatch({
          type: ActionType.CANCEL_QRCODE_SHOPPING_INVOICE_TASK_SYNC,
        });
      }
    }
  }

  qrcodeInvalidTips = () => {
    Modal.alert(getIntl().common.tips, getIntl().page.qrcodeInvalid, [
      {
        text: getIntl().common.confirm,
        onPress: () => { },
      }
    ]);
  }
  clearShoppingCart = () => {
    let { shoppingCartData } = this.state;
    let orderInfo: IOrderInfo | undefined = undefined;
    if (shoppingCartData) {
      orderInfo = shoppingCartData.orderInfo;
    }
    if (shoppingCartData && orderInfo) {
      shoppingCartData = cloneDeep(shoppingCartData);
      const { dispatch } = this.props;
      dispatch({
        type: ActionType.CLEAR_STORES_SHOPPING_CART,
        storeIDs: [shoppingCartData.orderInfo.storeID],
      });

      shoppingCartData.orderInfo.orderEntities = [];
      shoppingCartData.listingCountRef = {};

      this.setState({
        shoppingCartData,
      })
    }
  }

  handlePlusClick = (data: LineItemEntity) => {
    const { dispatch } = this.props;
    const { storeSlug } = this.state;

    dispatch({
      type: ActionType.MODIFY_LINE_ITEM_QUANTITY,
      storeSlug,
      uuid: data.uuid,
      operate: 'ADD',
      callback: shoppingCartData => {
        this.setState({ shoppingCartData });
      },
    });
  }
  handleMinusClick = (data: LineItemEntity) => {
    const { dispatch } = this.props;
    const { storeSlug } = this.state;
    dispatch({
      type: ActionType.MODIFY_LINE_ITEM_QUANTITY,
      storeSlug,
      uuid: data.uuid,
      operate: 'MINUS',
      callback: shoppingCartData => {
        this.setState({ shoppingCartData });
      },
    });
  }

  handleSumbit = () => {
    const { dispatch, routeParams, reducersState } = this.props;
    const { storeSlug } = this.state;
    const { invoiceInfo } = reducersState.qrcordShopping;
    if (invoiceInfo.state === 'voided' || invoiceInfo.state === 'completed') {
      this.qrcodeInvalidTips();
      return;
    }
    this.openLoading();
    dispatch({
      type: ActionType.MODIFY_QRCODE_SHOPPING_INVOICE,
      callback: result => {
        this.setState({ result });
        if (result === 'SUCCESS') {
          this.handleGotoRoute({
            path: paths.QRCODE_SHOPPING_RESULT,
            pathParams: {
              storeSlug,
              orderNumber: routeParams.orderNumber || '',
            },
          });
        }
        this.closeLoading();
      },
    });
  }

  render() {
    const { routeParams } = this.props;
    const { loadedData, shoppingCartData, storeSlug, isOrderDetail } = this.state;
    let orderInfo: IOrderInfo | undefined = undefined;
    if (shoppingCartData) {
      orderInfo = shoppingCartData.orderInfo;
    }
    const { reducersState } = this.props;
    const { invoiceInfo, orderInfos } = reducersState.qrcordShopping;
    let title;
    if (isOrderDetail) {
      title = getIntl().page.orderDetail
    } else {
      title = getIntl().page.shoppingCart
    }
    const paresResult = parseQrcodeShoppingOrderInfosCount(invoiceInfo, orderInfos.submittedOrderInfo, orderInfos.pendingOrderInfo, orderInfo);
    const completedState = invoiceInfo.state === 'voided' || invoiceInfo.state === 'completed';

    return (
      <Layout
        {...this.props}
        className={`${prefix} bm-p-shopping-cart`}
        navIcon={!completedState && <Icon  className="html-embed-7 w-embed" type={IconType.LEFT_OUTLINED} />}
        onLeftClick={() => {
          setCanAutoProcurementMethodPopup(false);
          this.handleGoBack({
            path: paths.QRCODE_SHOPPING_MENUS,
            pathParams: {
              storeSlug,
              orderNumber: routeParams.orderNumber,
            },
            runGoBack: true,
          })
        }}
        navContent={title}
        navRightContent={!isOrderDetail && <Icon className="html-embed-7 w-embed" type={IconType.RECYCLE_BIN_OUTLINED} onClick={this.clearShoppingCart} />}
        isFooter={false}
        // navRightContent={!isOrderDetail && <Icon className="html-embed-7 w-embed" type={IconType.RECYCLE_BIN_OUTLINED} onClick={this.clearShoppingCart} />}
      >
        <QrcodeShoppingCartPageStyled>
          {
            (paresResult.hasNewItems || paresResult.hasSubmittedItems || paresResult.hasPendingItems) &&
            <div className="background-top"></div>
          }
          <div className="section no-padding wf-section">
            <div className="container top w-container">
              <div className="div-block-485">
                <div className="left-block">
                  {
                    !isOrderDetail && paresResult.hasNewItems && orderInfo && orderInfo.orderEntities.map(item => (
                      <ListingsPanel
                        key={item.id}
                        data={item}
                        showStoreTitle={orderInfo && orderInfo.superOrder}
                        className="store-listings-panel"
                        showRightTopCount={false}
                        onPlusClick={this.handlePlusClick}
                        onMinusClick={this.handleMinusClick}
                        itemType={getIntl().page.newItems}
                        reducersState={reducersState}
                        confirmPage={true}
                      />
                    ))
                  }
                  {
                    paresResult.hasPendingItems && orderInfos.pendingOrderInfo && orderInfos.pendingOrderInfo.orderEntities.map(item => (
                      <ListingsPanel
                        key={item.id}
                        data={item}
                        showStoreTitle={orderInfo && orderInfo.superOrder}
                        className="store-listings-panel"
                        showRightTopCount={true}
                        itemType={getIntl().page.pendingItems}
                        itemTypeTime={invoiceInfo.pendingUpdatedAt}
                        reducersState={reducersState}
                        confirmPage={true}
                      />
                    ))
                  }
                  {
                    paresResult.hasSubmittedItems && orderInfos.submittedOrderInfo && orderInfos.submittedOrderInfo.orderEntities.map(item => (
                      <ListingsPanel
                        key={item.id}
                        data={item}
                        showStoreTitle={orderInfo && orderInfo.superOrder}
                        className="store-listings-panel"
                        showRightTopCount={true}
                        itemType={getIntl().page.submittedItems}
                        itemTypeTime={invoiceInfo.submittedUpdatedAt}
                        reducersState={reducersState}
                        confirmPage={true}
                      />
                    ))
                  }
                </div>
                <div className="right-block last">
                  <div className="check-out show">
                    <div className="order-summary-block with-spacing">
                      <div className="div-block-493 no-line _1">
                        <div className="div-block-484">
                          <div className="text-block-98">{getIntl().common.subtotal}</div>
                          <div className="text-block-68">{paresResult.subTotal}</div>
                        </div>
                        {
                          paresResult.serviceFeeNumber > 0 &&
                          <div className="div-block-484">
                            <div className="text-block-98">{getIntl().common.serviceFee}</div>
                            <div className="text-block-68">{paresResult.serviceFee}</div>
                          </div>
                        }
                        {
                          paresResult.taxFeeNumber > 0 &&
                          <div className="div-block-484">
                            <div className="text-block-98">{getIntl().common.taxFee}</div>
                            <div className="text-block-68">{paresResult.taxFee}</div>
                          </div>
                        }
                        {
                          paresResult.discountTotalNumber > 0 &&
                          <div className="div-block-484">
                            <div className="text-block-98">{getIntl().common.discount}</div>
                            <div className="text-block-68">-{paresResult.discountTotal}</div>
                          </div>
                        }
                        <div className="div-block-484 no-space ">
                          <div className="text-block-98 bold">{getIntl().common.total}</div>
                          <div className="text-block-68 bold">{paresResult.initialTotal}</div>
                        </div>
                      </div>
                    </div>
                    <ScanCodeInfo invoiceInfo={invoiceInfo} />
                    {
                      loadedData && paresResult.lineItemCount > 0 &&
                      <div className="div-block-510 hiddedn">
                        <div className="text-block-90 less-spacing">{getIntl().common.total}</div>
                        <div className="text-block-91">
                          {langFormat(getIntlByPath(`page.${paresResult.lineItemCount <= 1 ?'nowItemsCounts': 'itemsCount'}`), { count: paresResult.lineItemCount })}
                        </div>
                        <Button type="primary" 
                          className={`pay-now-block ${invoiceInfo.state === 'voided' || invoiceInfo.state === 'completed' || !paresResult.hasNewItems ? 'disable-btn' : ''}`}
                          onClick={() => {
                            if (paresResult.hasNewItems) {
                              this.handleSumbit();
                            }
                          }}
                        >
                          <div className="text-block-17"> {getIntl().common.submit}</div>
                        </Button>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          {
            loadedData && paresResult.lineItemCount > 0 &&
            <div className="make-payment">
              <div className="bottom-nav cart-process wf-section">
                <div className="container bottom w-container">
                  <div className="checkout-tablet">
                    <div>
                      <div className="text-block-90 less-spacing">{getIntl().common.total}: {paresResult.initialTotal}</div>
                      <div className="text-block-91">
                        {langFormat(getIntlByPath(`page.${paresResult.lineItemCount <= 1 ?'nowItemsCounts': 'itemsCount'}`), { count: paresResult.lineItemCount })}
                      </div>
                    </div>
                    <Button type="primary" 
                      className={`pay-now-block ${invoiceInfo.state === 'voided' || invoiceInfo.state === 'completed' || !paresResult.hasNewItems ? 'disable-btn' : ''}`}
                      onClick={() => {
                        if (paresResult.hasNewItems) {
                          this.handleSumbit();
                        }
                      }}
                    >
                      <div className="text-block-17"> {getIntl().common.submit}</div>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          }
        </QrcodeShoppingCartPageStyled>
      </Layout>
    );
  }
}

export default QrcodeShoppingCartPage;
