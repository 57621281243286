
import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import Icon, { IconType } from '@/components/Icon';
import { IObject } from '@/components/bm-common';
import { getAppTheme, setScreenColor, getIntl } from '@/components/App/App';
import { prefix } from '.';
import ActionType from '@/actions/action-type';
import {  parseRouteParams } from '@/utils';
import { IBlog } from '@/actions/store-action';
// import dayjs from 'dayjs';
import paths from '@/routes/paths';
import { getI18nText } from '@/utils/app';
import SideNavOption from '@/actions/side-nav-option';
import Components from '@/components/SideNavBar/Components';
import { BlogDetailPageStyled, NewOrStyled } from './styled';
import Layout from '@/components/Layout/Layout';

export interface IBlogDetailPageProps extends IBasePageProps {}
export interface IBlogDetailPageState {
  showLanguage: boolean;
  showTerms: boolean;
  termsData: string | undefined;
  termsTitle: string;
  blog?: IBlog;
}
let title = '';
class BlogDetailPage extends BasePage<IBlogDetailPageProps, IBlogDetailPageState> {
  routeParams: IObject = {};


  state: IBlogDetailPageState = {
    showLanguage: false,
    showTerms: false,
    termsData: '',
    termsTitle: '',
    blog: undefined,
  }

  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.lightBackgroundColor) {
      setScreenColor(appTheme.lightBackgroundColor);
    }
    const { dispatch } = this.props;
    this.routeParams = parseRouteParams(this.props);
    let contentType = 'BLOG';
    if (this.routeParams.contentType === 'news') {
      contentType = 'BLOG';
      title = getIntl().page.newsTitle;
    }
    if (this.routeParams.contentType === 'about-us') {
      contentType = 'COMPANY_INFO';
      title = getIntl().page.aboutUsTitle;
    }

    if (this.routeParams.contentType === 'promotions') {
      contentType = 'PROMOTIONS';
      title = getIntl().page.promotionsTitle;
    }
    dispatch({
      type: ActionType.QUERY_BLOG,
      contentType,
      id: this.routeParams.blogID,
      storeSlug: this.routeParams.storeSlug,
      callback: blog => {
        this.setState({
          blog,
        })
      }
    })
    super.componentDidMount();
  }


  render() {
    const { blog } = this.state;
    const { reducersState } = this.props;
    return (
      <>
        {
          this.routeParams.contentType === 'about-us' &&
          <Layout
            {...this.props}
            className={prefix}
            isFooter={false}
          >
            <Components 
              {...this.props}
              reducersState={reducersState}
              onLeftClick={() => this.handleGoBack({
                path: paths.HOME,
                runGoBack: true,
              })}
              navContent={title}
              navIcon={<Icon  className="html-embed-7 w-embed" type={IconType.LEFT_OUTLINED} />}
              sideNavOption={SideNavOption.BLOGS_ABOUT_AS}
            >
              <BlogDetailPageStyled className="div-block-547 payment">
                <div className="section settings">
                  <div className="div-block-550">
                    {
                      blog &&
                      <>
                        <div className="blog-title">
                          {getI18nText(blog, 'content_title', reducersState.app.language) || blog.title}
                        </div>
                        {/* <div className="blog-time">{dayjs(blog.updatedAt).format('YYYY.MM.DD')}</div> */}
                        <div className={`${prefix}-con`} dangerouslySetInnerHTML={{
                          __html: getI18nText(blog, 'content_body', reducersState.app.language) || blog.body,
                        }}/>
                      </>
                    }
                  </div>
                </div>
              </BlogDetailPageStyled>
            </Components>
          </Layout>
        }
        {
          this.routeParams.contentType !== 'about-us' &&
          <Layout
            {...this.props}
            className={prefix}
            navContent={<div>{title}</div>}
            navIcon={<Icon className="html-embed-7 w-embed" type={IconType.LEFT_OUTLINED} />}
            onLeftClick={() => this.handleGoBack({
              path: paths.HOME,
              runGoBack: true,
            })}
            isFooter={false}
          >
            <NewOrStyled>
              <div className="section wf-section">
                <div className="container w-container">
                  <div className="div-block-549">
                    {
                      blog &&
                      <>
                        <h2 className="heading-2 with-space">{getI18nText(blog, 'content_title', reducersState.app.language) || blog.title}</h2>
                        <div className={`${prefix}-con`} dangerouslySetInnerHTML={{
                          __html: getI18nText(blog, 'content_body', reducersState.app.language) || blog.body,
                        }}/>
                      </>
                    }
                  </div>
                </div>
              </div>
            </NewOrStyled>
          </Layout>
        }
      </>
    );
  }
}

export default BlogDetailPage;
