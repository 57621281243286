import { takeEvery } from 'redux-saga/effects';
import ActionType from '@/actions/action-type';
import {
  IQueryFeedbackTags,
  IAddFeedback,
  IQueryFeedbackList,
} from '@/actions/app-action';
import Config from '@/Config';
import {
  IFeedback,
  IFeedbackTags,
  getFeedbackTags,
  getFeedbacks,
  addFeedback,
} from '@/data/Feedback';

function* handleQueryFeedbackTags(params: IQueryFeedbackTags) {
  const {
    platformID,
    storeID,
    userID,
    orderNumber,
    callback,
  } = params;

  let existence = false;
  let feedBack: IFeedback | undefined = undefined;
  let feedBackTags: IFeedbackTags | undefined = undefined;

  try {
    feedBackTags = yield getFeedbackTags(platformID);
    const result = yield getFeedbacks({platformID, storeID, userID, orderNumber});
    const feedBacks = result.records;
    if (feedBacks && feedBacks.length > 0) {
      existence = true;
      feedBack = {
        feedBackTag: feedBacks[0].feedback_tag || [],
        score: feedBacks[0].score || 0,
        createdAt: feedBacks[0].created_at || '',
        userName: feedBacks[0].user_name || '',
        avatarUrl: feedBacks[0].user_name || '',
        id: feedBacks[0].id || '',
      }
    }
    
    if (callback) {
      callback(feedBackTags, existence, feedBack);
    }

  } catch (error) {
    console.error(error);
  }
}

function* handleAddFeedback(params: IAddFeedback) {
  const {
    platformID,
    storeID,
    userID,
    orderNumber,
    score,
    feedbackTag,
    callback,
  } = params

  try {
    const record = yield addFeedback(platformID, storeID, userID, feedbackTag, score, orderNumber)
    if (callback) {
      callback(record);
    }

  } catch (error) {
    console.error(error);
  }
}

function* handleQueryFeedbackList(params: IQueryFeedbackList) {
  const {
    platformID,
    storeID,
    callback,
    page,
  } = params;
  const feedbacks: IFeedback[] = [];
  let feedbackTag: IFeedbackTags = {};
  let total = 0;
  try {
    const result = yield getFeedbacks({platformID, storeID, page})
    const records = result.records;
    total = result.pageInfo.total;
    feedbackTag = yield getFeedbackTags(platformID);
    if (Array.isArray(records) && records.length > 0) {
      records.forEach(item => {
        feedbacks.push({
          feedBackTag: item.feedback_tag || [],
          score: item.score || 0,
          createdAt: item.created_at || '',
          userName: item.user_name || '',
          avatarUrl: `https://cdn${Config.isStaging ? '-stg': ''}.bindo.co/user_avatars/${item.user_id}/medium/${item.user_avatar_url}`,
          id: item.id || '',
        })
      })
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(feedbackTag, feedbacks, total)
  }
}

function* feedbackSaga() {
  yield takeEvery(ActionType.QUERY_FEEDBACK_TAGS, handleQueryFeedbackTags);
  yield takeEvery(ActionType.ADD_FEEDBACK, handleAddFeedback);
  yield takeEvery(ActionType.QUERY_FEEDBACK_LIST, handleQueryFeedbackList);
}

export default feedbackSaga();
