import React from 'react';
import validatorIsMobilePhone from 'validator/es/lib/isMobilePhone';
import { IObject } from '@/components/bm-common';
import Icon, { IconType } from '@/components/Icon';
import InputItem from 'antd-mobile/es/input-item';
import Button from 'antd-mobile/es/button';
import ActionType from '@/actions/action-type';
import CountryCode, { prefix } from '@/components/CountryCode';
import { IDialCodeGroupItem } from '@/components/DialCodeGroup';
import Panel from './Panel';
import { ICheckoutPageState, ICheckoutPageProps } from './CheckoutPage';
import { goToRoute } from '@/utils/route-util';
import paths from '@/routes/paths';
import Toast from 'antd-mobile/es/toast';
import CheckoutMethodButton from './CheckoutMethodButton';
import SignInBottom from '@/components/SignIn/SignInBottom';
import { getIntl } from '@/components/App/App';

export interface IUserCheckoutProps extends ICheckoutPageProps, ICheckoutPageState {
  language: string;
  resetState<K extends keyof ICheckoutPageState>(
      state: ((prevState: Readonly<ICheckoutPageState>) => (Pick<ICheckoutPageState, K> | ICheckoutPageState | null)) | (Pick<ICheckoutPageState, K> | ICheckoutPageState | null),
      callback?: () => void
  ): void;
  onClick: (selected: boolean) => void;
}

interface IUserCheckoutState {
  phoneNumberInputFocus: boolean;
  passwordInputFocus: boolean;
}

class UserCheckout extends React.Component<IUserCheckoutProps, IUserCheckoutState> {
  routeParams: IObject = {};
  
  state: IUserCheckoutState = {
    phoneNumberInputFocus: false,
    passwordInputFocus: false,
  }

  handleCreateOrderForMobilePhone = async () => {
    const {
      storeSlug,
      dialCodeGroupItem,
      phoneNumber,
      isMobilePhone,
      password,
      routeParams,
      dispatch,
      resetState,
      isSplitOrder,
    } = this.props;

    if (isMobilePhone && phoneNumber && password && dialCodeGroupItem) {
      const number = phoneNumber.replace(/\s+/g, '');
      resetState({ signingIn: true });
      dispatch({
        type: ActionType.LOADING,
        showLoading: true,
        showLoadingBgc: true,
      });

      dispatch({
        type: ActionType.SIGN_IN_AND_CREATE_ORDER,
        storeSlug,
        dialCode: dialCodeGroupItem.dialCode,
        phoneNumber: number,
        password,
        isSplitOrder,
        callback: params => {
          if (params.tag === 'SUCCESS') {
            window.location.href = routeParams.fromPage === 'eShop' ? `${params.orderConfirmUrl}&fromPage=eShop` : params.orderConfirmUrl;
          } else {
            if (params.message.includes('"sold_out":true')) {
              const arr = params.message.replace(/'/g, '"')
              const obj = JSON.parse(arr)
              const productName = obj[0].message.split(' ').slice(0, -3).join(' ')
              Toast.info(productName + getIntl().page.alreadySoldOut);
            } else {
              Toast.info(getIntl().page.signInErrorMsg);
            }
            resetState({ signingIn: false });
            dispatch({
              type: ActionType.LOADING,
              showLoading: false,
            });
          }
        }
      });
    }
  }

  handleCountryCodeChange = (data: IDialCodeGroupItem) => {
    const { resetState } = this.props;
    resetState({ dialCodeGroupItem: data })
  }

  handlePhoneNumberChange = (value: string) => {
    const { resetState } = this.props;
    const number = value.replace(/\s+/g, '');
    const isMobilePhone = validatorIsMobilePhone(number);

    resetState({
      phoneNumber: value,
      isMobilePhone,
    });
  }

  forgotPassword = () => {
    const { history } = this.props;
    goToRoute({
      history,
      pathname: paths.FORGOT_PASSWORD,
      clearPaths: false,
    });
  }

  render() {
    const {
      language,
      phoneNumber,
      isMobilePhone,
      password,
      signingIn,
      resetState,
      onClick,
    } = this.props;

    let signInBtnDisabled = true;
    if (!signingIn && isMobilePhone && password) {
      signInBtnDisabled = false;
    }

    const { phoneNumberInputFocus, passwordInputFocus } = this.state;

    return (
      <div className="section-4">
        <div className="container-6 w-container">
          <Panel
            serialNumber={1} 
            title={getIntl().page.chooseCheckoutMethod}
          >
            <CheckoutMethodButton
              type={IconType.PHONE_OUTLINED}
              title={getIntl().page.mobileSignIn}
              onClick={onClick}
            >
            </CheckoutMethodButton>
            <div className="checkout-sigin">
              <SignInBottom className={`${prefix}-signin-bottom`} />
            </div>
          </Panel>
          <Panel
            serialNumber={2} 
            title={getIntl().page.mobileSignIn}
          >
            <div className="div-block-525 _500px">
              <div className="sign-in">
                <InputItem
                  autoComplete="off"
                  placeholder={getIntl().page.signInPhoneNumberPlacehodler}
                  onChange={this.handlePhoneNumberChange}
                  value={phoneNumber}
                  type="bankCard"
                  className={`phone-number ${phoneNumberInputFocus ? 'pn-focus' : ''}`}
                  onFocus={() => this.setState({ phoneNumberInputFocus: true })}
                  onBlur={() => this.setState({ phoneNumberInputFocus: false })}
                  extra={
                    phoneNumber &&
                    <Icon
                      type={IconType.CLOSE_OUTLINED}
                      className="c-icon-close"
                      onClick={() => resetState({ phoneNumber: '', isMobilePhone: false })}
                    />
                  }
                >
                  <CountryCode
                    language={language}
                    onChange={this.handleCountryCodeChange}
                  />
                </InputItem>
                <InputItem
                  autoComplete="new-password"
                  placeholder={getIntl().common.password}
                  onChange={value => resetState({ password: value })}
                  value={password}
                  type="password"
                  className={`password ${passwordInputFocus ? 'password-focus' : ''}`}
                  onFocus={() => this.setState({ passwordInputFocus: true })}
                  onBlur={() => this.setState({ passwordInputFocus: false })}
                  extra={
                    <>
                      { password &&
                      <Icon
                        type={IconType.CLOSE_OUTLINED}
                        className="c-icon-close"
                        onClick={() => resetState({password: ''})}
                      />}
                      {
                        !password &&
                        <div className="forgot-password" onClick={() => { this.forgotPassword()}}>
                          {`${getIntl().page.forgot}?`}
                        </div>
                      }
                    </>
                  }
                >
                </InputItem>
                <Button type="primary" className="c-input-item" disabled={signInBtnDisabled} onClick={this.handleCreateOrderForMobilePhone}>
                  {getIntl().page.signin}
                </Button>
                <SignInBottom className={`${prefix}-signin-bottom`} />
              </div>
            </div>

          </Panel>
        </div>
      </div>
    );
  }
}

export default UserCheckout;
