import { payInvoice } from '../../payment-data';
import { handleBuildOrderPayment } from '../../Utils';
import { IWechatOfficialAccountPayMethod } from '../..';
import checkOrderStatus from '../../useCheckPay';

interface IWechatOfficialAccountPayParams {
  storeID: string;
  orderNumber: string;
  payData: IWechatOfficialAccountPayMethod;
  payment: any;
}

const UseWechatOfficialAccountPay = (
  params: IWechatOfficialAccountPayParams
) => {
  const {
    storeID,
    orderNumber,
    payment,
    payData,
  } = params;

  const { amount, code, appid } = payData;

  const wechatPay = async () => {
    const newPayment = {
      wechatpay: [
        {
          amount,
          'official_account': {
            code: code,
          },
        }
      ]
    };
    const order = handleBuildOrderPayment(newPayment, payment);
    try {
      const resp = await payInvoice(storeID, orderNumber, order);
      
      const { 
        status,
        order: fulfilOrder,
        success,
      } = resp || {};

      let timeStamp = '';
      let nonceStr = '';
      let payPackage = '';
      let signType = '';
      let paySign = '';

      if (success && status === 'ok' && Array.isArray(fulfilOrder.sale_transactions)) {
        fulfilOrder.sale_transactions.forEach((item: any) => {
          if (item.payment_method === 17 && item.is_pending) {
            let response = item.acquirer_response_body;
            if (typeof response !== 'object') {
              response = JSON.parse(item.acquirer_response_body);
            }
            if (response.wechat_pay && response.wechat_pay.official_account) {
              const data = response.wechat_pay.official_account;
              timeStamp = data.time_stamp;
              nonceStr = data.nonce_str;
              payPackage = data.resultPackge;
              signType = data.sign_type;
              paySign = data.pay_sign;
            }
          }
        });
      }

      if (appid && timeStamp && nonceStr && payPackage && signType && paySign) {
        const { WeixinJSBridge } = window;
        WeixinJSBridge.invoke(
          'getBrandWCPayRequest',
          {
            appId: appid,
            timeStamp,
            nonceStr,
            package: payPackage,
            signType,
            paySign,
          },
          function (res: any) {
            const { err_msg: errMsg } = res;
            if (errMsg === 'get_brand_wcpay_request:ok') {
              checkOrderStatus({storeID, orderNumber, callback: (order: any) => {
                return {
                  order
                };
              }})
            }
            if (errMsg === 'get_brand_wcpay_request:cancel') {
              return {
                status: 400,
              };
            }
          }
        );
      } else {
        return {
          status: 500,
        };
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };
  return {
    wechatPay,
  };
};
export default UseWechatOfficialAccountPay;
