
import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import Icon, { IconType } from '@/components/Icon';
import { IObject } from '@/components/bm-common';
import { getAppTheme, setScreenColor, getIntl } from '@/components/App/App';
import Layout from '@/components/Layout';
import { prefix } from '.';
import paths from '@/routes/paths';
import { getI18nText } from '@/utils/app';

export interface IPrivacyPageProps extends IBasePageProps { }
export interface IPrivacyPageState {
}

class PrivacyPage extends BasePage<IPrivacyPageProps, IPrivacyPageState> {
  routeParams: IObject = {};

  state: IPrivacyPageState = {
  }

  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.lightBackgroundColor) {
      setScreenColor(appTheme.lightBackgroundColor);
    }

    super.componentDidMount();
  }


  render() {
    const { reducersState } = this.props;
    const { platformAppSettings } = reducersState.app;
    const privacyData = getI18nText(platformAppSettings, 'privacy_statement', reducersState.app.language) || (platformAppSettings && platformAppSettings.privacyStatement) || '';

    return (
      <Layout
        {...this.props}
        className={prefix}
        navIcon={<Icon className="back-icon" type={IconType.LEFT_OUTLINED} />}
        onLeftClick={() => this.handleGoBack({ path: paths.HOME, runGoBack: true })}
        navContent={getIntl().page.privacy}
      >
        <div className="terms-condition-content" dangerouslySetInnerHTML={{
          __html: privacyData,
        }} />
      </Layout>
    );
  }
}

export default PrivacyPage;
