import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Icon, { IconType } from '@/components/Icon';
import { IReducersState } from '@/reducers';
import { prefix } from '.'
import Popup from '../Modal/Popup';
import { getI18nText } from '@/utils/app';
import BmNavBar from '../SideNavBar/BmNavBar';
import { getIntl } from '../App/App';


interface INoticeUserProps {
  selectd: boolean;
  onSelect?: (data: boolean) => void;
  className?: string;
}

const NoticeUser: React.FC<INoticeUserProps> = props => {

  const {
    className = '',
    selectd,
    onSelect = () => { },
  } = props;

  const [showTerms, setShowTerms] = useState<boolean>(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState<boolean>(false);
  const reducersState: IReducersState = useSelector<IReducersState, IReducersState>(state => state);
  const { platformAppSettings } = reducersState.app;


  const iconType = selectd ? IconType.CHECKED_OUTLINED : IconType.UN_SELECTED_OUTLINED;

  return (
    <div className={`${prefix}-notice-user ${className}`} >
      <Icon
        type={iconType}
        className={`${prefix}-notice-user-icon`}
        onClick={() => onSelect(!selectd)}
      />
      <div className={`${prefix}-notice-user-content`}>
        <span>{getIntl().page.iAgree}</span>
        <span>&nbsp;</span>
        <span
          className={`${prefix}-notice-user-font`}
          onClick={() => setShowPrivacyPolicy(true)}
        >
          {getIntl().page.privacyPolicy}
        </span>
        <span> & </span>
        <span
          className={`${prefix}-notice-user-font`}
          onClick={() => setShowTerms(true)}
        >
          {getIntl().page.termsandConditions}
        </span>
      </div>
      <Popup
        visible={showTerms}
        title={
          <BmNavBar
            onLeftClick={() => setShowTerms(false)}
            navIcon={<Icon type={IconType.LEFT_OUTLINED} className="html-embed-7 w-embed"/>}
            navContent={getIntl().page.terms}
          ></BmNavBar>
        }
        showIcon={false}
        onClose={() => setShowTerms(false)}
        className="terms-condition"
      >
        <div className="div-block-549" dangerouslySetInnerHTML={{
          __html: getI18nText(platformAppSettings, 'general_conditions', reducersState.app.language) || (platformAppSettings && platformAppSettings.generalConditions) || '',
        }}/>
      </Popup>
      <Popup
        visible={showPrivacyPolicy}
        title={
          <BmNavBar
            onLeftClick={() => setShowPrivacyPolicy(false)}
            navIcon={<Icon type={IconType.LEFT_OUTLINED} className="html-embed-7 w-embed"/>}
            navContent={getIntl().page.privacy}
          ></BmNavBar>
        }
        showIcon={false}
        onClose={() => setShowPrivacyPolicy(false)}
        className="terms-condition"
      >
        <div className="div-block-549" dangerouslySetInnerHTML={{
          __html: getI18nText(platformAppSettings, 'privacy_statement', reducersState.app.language) || (platformAppSettings && platformAppSettings.privacyStatement) || '',
        }} />
      </Popup>
    </div>
  )
}
export default NoticeUser;
