import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import BigNumber from 'bignumber.js';
import TextareaItem from 'antd-mobile/es/textarea-item';
import { IStore } from '@/actions/store-action';
import { formatCurrency } from '@/components/Payment';
import { lineItem, IReceiveOrder, purchaseOrder } from '@/actions/good-received-note-actions';
import { IPurchaseOrderDetail } from '@/actions/purchase-order-action';
import NewGoodsReceivedNoteItem from './NewGoodsReceivedNoteItem';
import NewReturnNoteItem from './NewReturnNoteItem';
import { getIntl } from '@/components/App/App';


interface IReceiveModalProps {
  purchaseOrderDetail: IPurchaseOrderDetail;
  store: IStore;
  name: string;
  handleCreateReceive: (goodReceivedNote: IReceiveOrder) => void;
  handleCreate: (goodReceivedNote: IReceiveOrder) => void;
  handleCreateRN: (goodReceivedNote: IReceiveOrder) => void;
  onCancel: () => void;
  isGRN: boolean;
}

const ReceiveModal: React.FC<IReceiveModalProps> = props => {
  const {
    name,
    store,
    purchaseOrderDetail,
    handleCreateReceive,
    handleCreate,
    onCancel,
    handleCreateRN,
    isGRN,
  } = props;
  const [goodReceivedNote, setGoodReceivedNote] = useState<IReceiveOrder>();
  const [quantity, setSelectQuantity] = useState<number>(0);
  useEffect(() => {
    setGoodReceivedNote(addPurchaseOrders(isGRN));
    getSelectQuantity();
  }, [name]);

  const getSelectQuantity = () => {
    let quantity = 0;
    if (goodReceivedNote) {
      goodReceivedNote.purchaseOrders.forEach(item => {
        item.lineItems.forEach(_item => {
          if (isGRN) {
            quantity += _item.qtyReceive;
          } else {
            quantity += _item.qtyRemaining;
          }
        })
      })
      setSelectQuantity(quantity)
    }
  }

  const addPurchaseOrders = (isGRN: boolean) => {
    const purchaseOrders: purchaseOrder[] = [];
    const purchaseOrderIDs: string[] = [];
    const images: {[id: string]: string} = {};
    
    if (purchaseOrderDetail && store) {
      const receiveOrder: IReceiveOrder = {
        id: '',
        createdAt: '',
        createdBy: '',
        expectedReceiveDate: purchaseOrderDetail.expectDeliveryDate,
        receiveDate: '',
        noteType: 1,
        number: '',
        purchaseOrders: [],
        purchaseOrderIDs: [],
        state: '',
        remarks: '',
        referenceNumber: '',
        supplierName: '',
        supplierID: '',
        qtyPOTotal: 0,
        qtyGRN: 0,
        qtyRN: 0,
        qtyGRNReceived: 0,
        subtotalGRN: 0,
        totalGRN: 0,
        serviceFee: 0,
        isInitial: false,
      };
      const lineItems: lineItem[] = [];
      let qtyPOTotal = new BigNumber(0);
      let totalGRN = new BigNumber(0);
      let subtotalGRN = new BigNumber(0);
      purchaseOrderDetail.lineItems.forEach(item => {
        images[item.id] = item.imageUrl;
      })
      if (purchaseOrderDetail.purchaseOrderRes && Array.isArray(purchaseOrderDetail.purchaseOrderRes['purchase_items'])) {
        purchaseOrderDetail.purchaseOrderRes['purchase_items'].forEach(purchaseItem => {
          const poReceived = Number(purchaseItem['qty_received_in_display_unit']);
          const poQty = Number(purchaseItem['quantity_in_display_unit']);
          const cost = Number(purchaseItem['cost_in_display_unit']);
          const qtyReceivedPO = !isNaN(poReceived) && poReceived > 0 ? poReceived : 0;
          const qtyPO = !isNaN(poQty) && poQty > 0 ? poQty : 0;
          const remaining = poQty - poReceived;
          const ratio = Number(purchaseItem['display_unit_ratio']);
          qtyPOTotal = qtyPOTotal.plus(new BigNumber(remaining));
          subtotalGRN = subtotalGRN.plus(new BigNumber(cost).times(new BigNumber(remaining)));
          totalGRN = totalGRN.plus(subtotalGRN);
          if ((purchaseItem['state'] !== 'fulfilled' && isGRN) || !isGRN) {
            lineItems.push({
              id: purchaseItem['source_id'],
              name: purchaseItem['name'],
              qtyReceived: 0,
              qtyRemaining: !isNaN(remaining) && isGRN ? remaining : 0,
              cost: cost,
              qtyReceivedPO: qtyReceivedPO,
              qtyPO: qtyPO,
              qtyReceive: 0,
              imageUrl: images[purchaseItem['id']],
              purchaseOrderID: purchaseOrderDetail.id,
              purchaseItem: purchaseItem,
              receiveOrderItemID: 0,
              unit: purchaseItem['display_unit'],
              displayUnitRatio: !isNaN(ratio) && ratio > 0 ? ratio : 1,
              displayUnitID: purchaseItem['display_unit_id'],
              purchaseItemID: purchaseItem['id'],
              remaining: !isNaN(remaining) && isGRN ? remaining : 0,
              total: 0,
            })
          }
        })
      }
      purchaseOrders.push({
        id: purchaseOrderDetail.id,
        number: purchaseOrderDetail.number,
        state: purchaseOrderDetail.state,
        lineItems: lineItems,
        currency: purchaseOrderDetail.currency || store.currencyCode,
      })
      purchaseOrderIDs.push(purchaseOrderDetail.id);
      const newReceiveOrder: IReceiveOrder = {
        ...receiveOrder,
        qtyPOTotal: qtyPOTotal.toNumber(),
        totalGRN: totalGRN.toNumber(),
        subtotalGRN: subtotalGRN.toNumber(),
        purchaseOrders,
        purchaseOrderIDs,
        supplierID: purchaseOrderDetail.supplierID,
        supplierName: purchaseOrderDetail.supplierName,
        isInitial: false,
      };
    
      return newReceiveOrder;
    }
  }

  const handleSetQuantity = (id: string, value: number) => {
    if (goodReceivedNote) {
      const newGoodReceivedNote = {...goodReceivedNote};
      newGoodReceivedNote.purchaseOrders.forEach(purchaseOrder => {
        purchaseOrder.lineItems.forEach(item => {
          if (item.id === id) {
            item.qtyReceive = value;
            item.qtyRemaining = item.remaining - value;
            item.total = new BigNumber(value).times(new BigNumber(item.cost)).toNumber();
          }
        })
      })
      setGoodReceivedNote(newGoodReceivedNote);
      getSelectQuantity();
    }
  }

  const handleSetRNQuantity = (id: string, value: number) => {
    if (goodReceivedNote) {
      const newGoodReceivedNote = {...goodReceivedNote};
      newGoodReceivedNote.purchaseOrders.forEach(purchaseOrder => {
        purchaseOrder.lineItems.forEach(item => {
          if (item.id === id) {
            item.qtyRemaining = value;
            item.total = new BigNumber(value).times(new BigNumber(item.cost)).toNumber();
          }
        })
      })
      setGoodReceivedNote(newGoodReceivedNote);
      getSelectQuantity();
    }
  }

  const handleFulfillAll = () => {
    if (goodReceivedNote) {
      const newGoodReceivedNote = {...goodReceivedNote};
      newGoodReceivedNote.purchaseOrders.forEach(purchaseOrder => {
        purchaseOrder.lineItems.forEach(item => {
          if (isGRN) {
            item.qtyReceive = item.remaining;
            item.total = new BigNumber(item.qtyReceive).times(new BigNumber(item.cost)).toNumber();
          } else {
            item.qtyRemaining = item.qtyReceivedPO;
            item.total = new BigNumber(item.qtyRemaining).times(new BigNumber(item.cost)).toNumber();
          }
        })
      })
      setGoodReceivedNote(newGoodReceivedNote);
      getSelectQuantity();
    }
  }

  return (
    <>
      {
        goodReceivedNote &&
        <div className="modal-wrapper-2 show-filter-modal">
          <div className="c-popup-close close-modal" onClick={() => onCancel()}></div>
          <div className="flex-pop-up widen">
            <div className="purchase-order-detail-section">
              <div className="purchase-order-detail-data-tab">
                <div className="purchase-order-detail-data-tab-supplier-name">{isGRN ? getIntl().page.newGoodsReceivedNote : getIntl().page.newReturnNote}</div>
                <div className="purchase-order-detail-data-tab-information-line">
                  <div className="purchase-order-detail-data-tab-information-line-title">{getIntl().page.supplierName}</div>
                  <div className="purchase-order-detail-data-tab-information-line-data">{goodReceivedNote.supplierName}</div>
                </div>
                <div className="purchase-order-detail-data-tab-information-line">
                  <div className="purchase-order-detail-data-tab-information-line-title">{getIntl().page.accountManager}</div>
                  <div className="purchase-order-detail-data-tab-information-line-data">{name}</div>
                </div>
                <div className="purchase-order-detail-data-tab-information-line">
                  <div className="purchase-order-detail-data-tab-information-line-title">{getIntl().page.expectedDeliveryDate}</div>
                  <div className="purchase-order-detail-data-tab-information-line-data">{goodReceivedNote.expectedReceiveDate ? dayjs(goodReceivedNote.expectedReceiveDate).format('YYYY-MM-DD') : 'N/A'}</div>
                </div>
                <div className="purchase-order-detail-data-tab-information-line">
                  <div className="purchase-order-detail-data-tab-information-line-title">{getIntl().page.itemQuantity}</div>
                  <div className="purchase-order-detail-data-tab-information-line-data">{goodReceivedNote.qtyPOTotal}</div>
                </div>
                {/* <div className="purchase-order-detail-data-tab-information-line">
                  <div className="purchase-order-detail-data-tab-information-line-title">{getIntl().page.paymentMethod}</div>
                  <div className="purchase-order-detail-data-tab-information-line-data payment-method">{purchaseOrderDetail.paymentMethod ? purchaseOrderDetail.paymentMethod.replace('_', ' ') : 'N/A'}</div>
                </div> */}
                <div className="purchase-order-detail-data-tab-information-line">
                  <div className="purchase-order-detail-data-tab-information-line-title">{getIntl().common.subtotal}</div>
                  <div className="purchase-order-detail-data-tab-information-line-data">{formatCurrency(goodReceivedNote.subtotalGRN, store.currencyCode)}</div>
                </div>
                <div className="purchase-order-detail-data-tab-information-line">
                  <div className="purchase-order-detail-data-tab-information-line-title">{getIntl().common.total}</div>
                  <div className="purchase-order-detail-data-tab-information-line-data">{formatCurrency(goodReceivedNote.totalGRN, store.currencyCode)}</div>
                </div>
              </div>
              <div className="purchase-order-detail-line-item-card">
                <div className="purchase-order-detail-line-item-card-top">
                  <div className="purchase-order-detail-line-item-card-top-title">{getIntl().page.deliveredQuantity}</div>
                  <div className="purchase-order-detail-line-item-card-top-button" onClick={handleFulfillAll}>{getIntl().page.fulfillAll}</div>
                </div>
                {
                  isGRN &&
                  goodReceivedNote.purchaseOrders.map(purchaseOrder => {
                    return purchaseOrder.lineItems.map(item => {
                      return (
                        <NewGoodsReceivedNoteItem
                          key={item.id}
                          lineItem={item}
                          goodReceivedNote={goodReceivedNote}
                          store={store}
                          handleSetQuantity={handleSetQuantity}
                        />
                      )
                    })
                  })
                }
                {
                  !isGRN &&
                  goodReceivedNote.purchaseOrders.map(purchaseOrder => {
                    return purchaseOrder.lineItems.map(item => {
                      return (
                        <NewReturnNoteItem
                          key={item.id}
                          lineItem={item}
                          returnNote={goodReceivedNote}
                          store={store}
                          handleSetQuantity={handleSetRNQuantity}
                        />
                      )
                    })
                  })
                }
                <div className="purchase-order-detail-remarks-title">{getIntl().page.remark}</div>
                <TextareaItem
                  className="purchase-order-detail-remarks-input"
                  placeholder={getIntl().page.remarkPlaceholder}
                  rows={3}
                  onChange={vlaue => setGoodReceivedNote({...goodReceivedNote, remarks: vlaue || ''})}
                  value={goodReceivedNote.remarks}
                />
              </div>
              <div className="purchase-order-detail-receive-bottom">
                <div className={`purchase-order-detail-receive-button create-receive ${quantity === 0 ? 'disable-create' : ''}`} onClick={() => {
                  if (quantity === 0) return;
                  isGRN ? handleCreateReceive(goodReceivedNote) : handleCreateRN(goodReceivedNote)}
                }>{isGRN ? getIntl().page.createReceive : getIntl().page.create}</div>
                {
                  isGRN &&
                  <div className={`purchase-order-detail-receive-button create ${quantity === 0 ? 'disable-create' : ''}`} onClick={() => {
                    if (quantity === 0) return;
                    isGRN ? handleCreate(goodReceivedNote) : handleCreateRN(goodReceivedNote)}
                  }>{getIntl().page.create}</div>
                }
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default ReceiveModal;
