import React, { useState } from 'react';
import InputItem, { InputItemProps } from 'antd-mobile/es/input-item';
import { InputItemStyled } from './styled';
import Icon, { IconType } from '@/components/Icon';
import CountryCode from '@/components/CountryCode';
import { IDialCodeGroupItem } from '@/components/DialCodeGroup';
import { prefix } from '.';
import { IReducersState } from '@/reducers';
import { useSelector } from 'react-redux';
import { getIntl } from '@/components/App/App';

interface IUserNamedProps {
  value: string;
  language: string;
  onChange?: (value: string) => void;
  onItemClick?: (data: IDialCodeGroupItem) => void;
  showIcon?: boolean;
  type?: string;
  areaCode?: string;
  areaCodeData?: IDialCodeGroupItem;
  verfityPhone?: (value: boolean) => void;
  verfityEmail?: (value: boolean) => void;
  disabled?: boolean;
}

const UserName: React.FC<IUserNamedProps> = props => {
  const {
    language,
    value,
    onChange = () => {},
    onItemClick = () => {},
    showIcon = false,
    type = 'text',
    areaCode,
    areaCodeData,
    verfityPhone = (value: boolean) => {},
    verfityEmail = (value: boolean) => {},
    disabled,
  } = props;

  const [emailErr, setEmailErr] = useState<boolean>(false);
  const [phoneNumberErr, setPhoneNumberErr] = useState<boolean>(false);
  const reducersState: IReducersState = useSelector<IReducersState, IReducersState>(state => state);
  const { areaCodeDatas } = reducersState.app;

  const inputProps: InputItemProps = {
    type: 'text',
    placeholder: getIntl().page.signInEmailPlacehodler,
  };

  if (type === 'PHONE') {
    inputProps.type = 'bankCard';
    inputProps.placeholder = getIntl().page.signInPhoneNumberPlacehodler;
  }
  if (type === 'USERNAME') {
    inputProps.placeholder = getIntl().page.usernamePlaceholder;
  }

  const vertifyPhoneNumer = (data?: IDialCodeGroupItem) => {
    const verfityData = data ? data : areaCodeDatas ? areaCodeDatas : areaCodeData;
    if (verfityData) {
      const newValue = value.replace(/\s*/g, '');
      if (verfityData.dialCode === '86') {
        if (newValue.length !== 11) {
          setPhoneNumberErr(true);
          verfityPhone(true);
          return;
        }
      } else if (verfityData.dialCode === '852') {
        if (newValue.length !== 8) {
          setPhoneNumberErr(true);
          verfityPhone(true);
          return;
        }
      }
    }
    setPhoneNumberErr(false);
    verfityPhone(false);
  };

  const handleUserBlur = () => {
    if (type === 'text') {
      const reg = /^([A-Za-z0-9_+-.])+@([A-Za-z0-9\-.])+\.([A-Za-z]{2,22})$/;
      const veriftyRes = reg.test(value);
      setEmailErr(!veriftyRes);
      verfityEmail(veriftyRes);
    }

    if (type === 'PHONE') {
      vertifyPhoneNumer();
    }
  };

  const handleChange = (value: string) => {
    if (phoneNumberErr) {
      setPhoneNumberErr(false);
    }
    onChange(value);
  };

  const handleItemClick = (data: IDialCodeGroupItem) => {
    if (type === 'PHONE') {
      vertifyPhoneNumer(data);
      onItemClick(data);
    }
  };

  return (
    <InputItemStyled className={`${prefix}-div-block-106`}>
      <InputItem
        name="username"
        autoComplete="off"
        placeholder={inputProps.placeholder}
        onChange={handleChange}
        value={value}
        type={inputProps.type}
        onBlur={handleUserBlur}
        error={emailErr || phoneNumberErr}
        extra={
          value &&
          !disabled && (
            <Icon
              type={IconType.CLOSE_OUTLINED}
              className="card-icon-close"
              onClick={() => onChange('')}
            />
          )
        }
        disabled={disabled}
        className="text-field"
      >
        {showIcon && (
          <div className="inputextra">
            <CountryCode
              language={language}
              onChange={handleItemClick}
              areaCode={areaCode}
              disabled={disabled && areaCode ? true : false}
            />
          </div>
        )}
      </InputItem>
    </InputItemStyled>
  );
};
export default UserName;
