import React from 'react';
import Config from '@/Config';
import dayjs from 'dayjs';
import { IReducersState } from '@/reducers';
import { getI18nText } from '@/utils/app';
import { getIntl } from '@/components/App/App';
import { BigNumber } from 'bignumber.js';

interface ICouponsItemProps {
  record: any;
  reducersState: IReducersState;
  showStatus: boolean;
  redeem: boolean;
  onClick: () => void;
}

const CouponsItem: React.FC<ICouponsItemProps> = props => {
  const {
    record,
    reducersState,
    onClick,
    showStatus,
    redeem,
  } = props;

  let imgBarBg = 'var(--bm-primary-color-t60)';

  if (record.status === 'used') {
    imgBarBg = 'var(--bm-error-color-t60)'
  }
  let status = getIntl().page.useNow
  if (record.status === 'used') {
    status = getIntl().page.couponUsed
  } else if (record.status === 'expired') {
    status = getIntl().page.couponExpired
  }
  const { platformConfig } = reducersState.app;
  const { abbreviation, ecouponsRedemption } = platformConfig;
  
  return (
    <div className="item-wrapper coupon" onClick={onClick} key={record.promotionCodeID}>
      <div className="coupon-block open-modal">
        <div className="image-block coupon" style={{ backgroundImage: `url(${record.img ? record.img : Config.defaultCouponImg})`}}>
          {
            showStatus &&
            <div className="status-veil expired" style={{backgroundColor: imgBarBg}}>{status}</div>
          }
        </div>
        <div className="item-info-wrapper">
          <div className="item-info with-space">
            <h4 className="heading-4-1">{getI18nText(record, 'activity_name', reducersState.app.language) || getI18nText(record, 'name', reducersState.app.language) || record.activityName}</h4>
            <p className="paragraph-2 less-space">{getI18nText(record, 'description', reducersState.app.language) || record.descriptionName}</p>
            <p className="paragraph-2 less-space">
              {redeem ? getIntl().page.redemptionPeriod : getIntl().page.couponExpiryDate}
              <span className="text-span">
                {record.status === 'created' && ` ${dayjs(record.expiredAt).format('YYYY-MM-DD')}`}
                {record.status === 'used' && <span style={{color: 'var(--bm-error-color)'}}>{getIntl().page.couponUsed}</span>}
                {record.status === 'expired' && <span style={{color: 'var(--bm-error-color)'}}>{getIntl().page.couponExpired}</span>}
              </span>
            </p>
          </div>
          {
            record.points !== 0 && ecouponsRedemption === 'LOYALTY_POINTS' &&
            <div className="price-cart">
              <div className="text-block-91">
                {getIntl().page.redeemWidth}
              </div>
              <div className="text-block-6">{new BigNumber(record.points).toFormat()} {getIntl().page.points}</div>
            </div>
          }
          {
            record.storeCredit !== 0 && ecouponsRedemption === 'STORE_CREDIT' &&
            <div className="price-cart">
              <div className="text-block-91">
                {getIntl().page.redeemWidth}
              </div>
              <div className="text-block-6">{new BigNumber(record.storeCredit).toFormat()} {abbreviation} {getIntl().page.dDollars}</div>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default CouponsItem
