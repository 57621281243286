const Constants = {
  LANGUAGE: 'LANGUAGE',
  ACCESS_TOKEN: 'ACCESS_TOKEN',
  ANONYMOUS_GIFT_CARD_KEY: 'ANONYMOUS_GIFT_CARD_KEY',
  SEARCH_HISTORY: 'SEARCH_HISTORY',
  USER_INFO: 'USER_INFO',
  SHOPPING_CART_DATA: 'SHOPPING_CART_DATA',
  INITIALIZED_SPLASH: 'INITIALIZED_SPLASH',
  SPLASH_DATA: 'SPLASH_DATA',
  NITIALIZED_SIGN_IN_TIPS: 'NITIALIZED_SIGN_IN_TIPS',
  PLATFORM_ID: 'PLATFORM_ID',
  APP_CONFIG: 'APP_CONFIG',
  APP_CONFIG_HASH_DATA: 'APP_CONFIG_HASH_DATA',
  IS_GUEST: 'IS_GUEST',
  PLATFORM: 'p',
  MINI_PROGRAM_TIPS: 'MINI_PROGRAM_TIPS',
  AUTO_POPPED_PROCUREMENT_METHOD: 'AUTO_POPPED_PROCUREMENT_METHOD',
  UUID: 'UUID',
  BRANDID: 'BRANDID',
  CATEGORYID: 'CATEGORYID',
  ADD_EXPRESS_ORDER: 'ADD_EXPRESS_ORDER',
  SUPPLIERS_SELECT_STORE_SLUG: 'SUPPLIERS_SELECT_STORE_SLUG',
  SUPPLIERS_SELECT_STORE_ID: 'SUPPLIERS_SELECT_STORE_ID',
  PLATFORMPAYINFO: 'PLATFORMPAYINFO',
  PRIVILEGE_NUMBER: 'PRIVILEGE_NUMBER',
  PAYBILLINFO: 'PAYBILLINFO',
  PAYMENT_DATA: 'PAYMENT_DATA',
  STORAGE_VERSION: 'STORAGE_VERSION',
  DEDICATED_QUERY: 'DEDICATED_QUERY',
};

export default Constants;
