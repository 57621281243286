import styled from 'styled-components';
export const  QrcodeShoppingCartPageStyled = styled.div`
.check-out-info.with-space-1 {
  margin-bottom: 20px;
  padding-top: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.text-block-67.bold {
  color: var(--bm-text-color);
}
.left-block {
  margin-top: 0;
}
.container.top {
  z-index: 2;
}
.right-block {
  position: -webkit-sticky;
  position: sticky;
  left: auto;
  top: 40px;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40%;
  height: auto;
  margin-top: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}
.check-out {
  position: -webkit-sticky;
  position: sticky;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: auto;
  max-width: 100%;
  min-width: 100%;
  margin-top: 0;
  margin-right: 0px;
  margin-bottom: 0px;
  padding: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-radius: 12px;
  box-shadow: none;
}
.order-summary-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: auto;
  max-width: 100%;
  min-width: 100%;
  margin-right: 0px;
  margin-bottom: 30px;
  padding: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-radius: 8px;
  background-color: var(--bm-light-background-color);
  /* box-shadow: 0 1px 7px 0 rgba(58, 58, 58, 0.39); */
  box-shadow: 0 1px 7px 0 var(--bm-shadow-color-t50);
  box-sizing: border-box;
}
.order-block{
  box-shadow: 0 1px 7px 0 var(--bm-shadow-color-t50);
}
.order-summary-block.with-spacing {
  margin-bottom: 20px;
}
.div-block-493 {
  padding-top: 20px;
  padding-bottom: 20px;
  /* border-bottom: 1px solid #eaeaea; */
  color: var(--bm-text-title-color);
}
.div-block-493.no-line {
  padding-bottom: 0px;
  border-bottom-width: 0px;
}
.div-block-493.no-line._1 {
  padding-top: 0px;
  padding-bottom: 0px;
}
.div-block-484 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 15px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.text-block-68 {
  margin-bottom: 0px;
  padding: 0px;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: var(--bm-text-title-color);
  font-size: 18px;
}
.text-block-68.bold {
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  font-weight: 700;
}
.text-block-98 {
  margin-right: 0px;
  color: var(--bm-text-color);
  margin-right: auto;
  margin-bottom: 0px;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  font-size: 18px;
  font-weight: 400;
}
.text-block-98.bold {
  color: var(--bm-text-title-color);
  font-weight: 700;
}
.div-block-484.no-space {
  margin-bottom: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.am-button-primary.disable-btn {
  opacity: 0.4;
}
.div-block-510 {
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 8px;
  background-color: var(--bm-light-background-color);
  box-shadow: 1px 1px 10px 0 var(--bm-shadow-color-t50);
}
.text-block-91 {
  font-size: 18px;
}
.background-top {
  height: 200px;
}
.div-block-485 {
  margin-top: 20px;
}

/* .text-block-90.less-spacing {
  margin-bottom: 5px;
  font-size: 20px;
}
.text-block-90 {
  margin-bottom: 10px;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: var(--bm-text-title-color);
  font-size: 18px;
  font-weight: 700;
}
.text-block-91 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: var(--bm-text-color);
  font-size: 18px;
  text-align: center;
} */
@media screen and (max-width: 991px) {
  .left-block {
    margin-top: 0px;
  }
  .check-out-info.with-space-1 {
    margin-right: 0px;
    margin-left: 0px;
  }
  .right-block {
    position: static;
    overflow: hidden;
    width: 100%;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  .right-block.last {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }
  .check-out {
    display: none;
    margin-bottom: 0px;
    padding: 0px;
    border-radius: 0px;
    box-shadow: none;
  }
  .check-out.show {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 0px;
  }
  .order-summary-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 0px;
    padding: 0px;
  }
  .order-summary-block.with-spacing {
    padding: 20px;
  }
  .div-block-510.hiddedn {
    display: none;
  }
  
}
@media screen and (max-width: 767px) {
  .text-block-98 {
    max-width: 40%;
    margin-right: 0px;
  }
  .div-block-485 {
    margin-top: 10px;
  }
  
}

@media screen and (max-width: 479px) {
  .check-out-info.with-space-1 {
    margin-bottom: 13px;
    padding-right: 0px;
    padding-left: 0px;  
  }
  .order-summary-block {
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  .order-summary-block.with-spacing {
    margin-bottom: 10px;
    padding: 13px;
  }
  .div-block-484 {
    margin-bottom: 10px;
  }
  .text-block-68 {
    font-size: 14px;
  }
  .text-block-98 {
    font-size: 14px;
    text-align: left;
  }
  .text-block-91 {
    font-size: 16px;
  }
  .background-top  {
    height: 135px;
  }
  .div-block-485 {
    margin-top: 0;
  }


}


`;
