import React from 'react';
import ModifierEntity from '@/lib/order-builder/models/ModifierEntity';
import { IReducersState } from '@/reducers';
import { getI18nText } from '@/utils/app';

interface IModifierItemProps {
  data: ModifierEntity;
  className?: string;
  reducersState: IReducersState;
}

const ModifierItem: React.FC<IModifierItemProps> = props => {
  const { data, className, reducersState } = props;

  return (
    <div className={`modifier-item ${className || ''}`}>
      <div>{`${data.quantity}@ ${getI18nText(data, 'option_name', reducersState.app.language) || getI18nText(data, 'name', reducersState.app.language) || data.name}`}</div>
      {
        data.modifierEntries && data.modifierEntries.map(item => (
          <ModifierItem
            key={item.uuid || item.id || ''}
            data={item}
            className="mi-indent"
            reducersState={reducersState}
          />
        ))
      }
    </div>
  );
}

export default ModifierItem;
