import React from 'react';
import { IDialCodeGroup, IDialCodeGroupItem } from '@/components/DialCodeGroup';
import CountryFlag from '@/components/common/CountryFlags';
import { modalPrefix } from '.';

interface IStoreListItemProps {
  data: IDialCodeGroup;
  className?: string;
  onClick?: (data: IDialCodeGroupItem) => void;
  searchKey?: string;
}

const CountryCodeItem: React.FC<IStoreListItemProps> = props => {
  const { data, className = '', onClick, searchKey } = props;

  const handleClick = (data: IDialCodeGroupItem) => {
    if (onClick) {
      onClick(data);
    }
  }

  const getData = () => {
    const newDataItem: IDialCodeGroupItem[] = [];
    const dataItem: IDialCodeGroupItem[] = data.data;

    dataItem.forEach(item => {
      if (searchKey) {
        const tempKey = searchKey.toLocaleLowerCase();
        const itemName = item.name || ''
        if (itemName.toLocaleLowerCase().indexOf(tempKey) > -1) {
          newDataItem.push(item);
        }
      } else {
        newDataItem.push(item);
      }
    });

    let dataKey = data.key;
    if (newDataItem.length < 1) {
      dataKey = '';
    }

    return {
      dataKey,
      newDataItem,
    }
  }

  return (
    <div className={`${modalPrefix}-item ${className}`}>
      <div className="pop-up-section">
        {
          getData().dataKey &&
          <h4 className="heading-4-3">{ data.key }</h4>
        }
      </div>
      {
        getData().newDataItem.map(item =>
          <div className="pop-up-section white"
            key={item.iso2}
            onClick={() => handleClick(item)}
          >
            <div className="div-block-108">
              {
                item && item.iso2 &&
                <CountryFlag
                  type={`${item.iso2}`}
                />
              }
              <div className="text-block-21">
                { item.name }
              </div>
            </div>
            <div className="text-block-21">{ `+${item.dialCode}` }</div>
          </div>
        )
      }
    </div>
  );
}

export default CountryCodeItem;
