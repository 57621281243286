import { takeEvery, select, put, call } from 'redux-saga/effects';
import dayjs from 'dayjs';
import axios from 'axios';
import Config from '@/Config';
import ActionType from '@/actions/action-type';
import { IBookingState } from '@/reducers/booking-reducer';
import { IReducersState } from '@/reducers';
import Action from '@/actions';
import {
  IQueryBookings,
  IBooking,
  IQueryBookingSetting,
  ICreateQueueing,
  IWeekBooking,
  ICancelledBooking,
  IQueryBookingsForDate,
  ITable,
  ITimeSegmentsQuota,
  IBookingSetting,
  INumberQuota,
} from '@/actions/booking-action';
import {
  getBookings,
  getSettings,
  getRecords,
  createBooking,
  CancelledBooking,
  createCustomer,
} from '@/data/Booking';
import {
  parseResTimeSegments,
  parseTimeSegmentsQuota,
  parseResTable,
  parseBookingSetting,
  parseNumberQuota,
} from '@/utils/booking-util';
import { ICustomer, IUserInfo } from '@/actions/user-action';
import {
  handleSignUpForGuest,
  handleUpdataCustomer,
  handleSignIn,
  handlePushNotification,
} from './user-saga';
import {
  handleQueryStore,
} from './store-saga';
import Constants from '@/constants';
import { IStore } from '@/actions/store-action';
import { queryRelationRecords } from '@/data/mb-api';

function* handleQueryBookings(params: IQueryBookings) {
  const {
    formula,
    callback,
    page,
    multi,
    id,
  } = params;

  let bookings: IBooking[] = [];
  let ids: string[] = [];
  const BookingState: IBookingState = yield select((state: IReducersState) => state.booking);

  try {
    if (multi) {
      if (BookingState.pageRefBookings[page]) {
        ids = BookingState.pageRefBookings[page];
        ids.forEach(item => {
          if (BookingState.IDRefBooking[item]) {
            bookings.push(BookingState.IDRefBooking[item])
          }
        })
      } else {
        bookings = yield getBookings(formula, page);
        yield put<Action>({
          type: ActionType.SET_BOOKINGS,
          page,
          bookings,
        });
      }
    } else {
      if (BookingState.IDRefBooking[id]) {
        bookings = [BookingState.IDRefBooking[id]];
      } else {
        bookings = yield getBookings(formula, page);
        if (bookings.length > 0) {
          yield put<Action>({
            type: ActionType.SET_BOOKINGS,
            bookings,
          });
        }
      }
    }
  } catch (error) {
    console.error(error);
  }
  if (callback) {
    callback(bookings);
  }
}

const handleGetSettings = async (storeSlug: string) => {
  let tables: ITable[] = [];
  let timeSegmentsQuotas: ITimeSegmentsQuota[] = [];
  let numberQuota: INumberQuota[] = [];
  let bookingSetting: IBookingSetting | undefined = undefined;
  const setting = await getSettings(storeSlug);
  bookingSetting = parseBookingSetting(setting);
  let RelationRecords: any = '';
  if (bookingSetting && bookingSetting.id) {
    const result = await queryRelationRecords({
      id: bookingSetting.id,
      storeSlugs: [storeSlug],
      field: 'time_segments_quota',
      moduleBundleID: 'reservation_quota',
    })
    RelationRecords = result.records;
    const resp = await queryRelationRecords({
      id: bookingSetting.id,
      storeSlugs: [storeSlug],
      field: 'number_quota',
      moduleBundleID: 'reservation_quota',
    })
    numberQuota = parseNumberQuota(resp.records);
  }
  timeSegmentsQuotas = parseTimeSegmentsQuota(RelationRecords);
  const tableType: string[] = [];
  if (Array.isArray(RelationRecords)) {
    RelationRecords.forEach(item => {
      if (!tableType.includes(item.table_type_id)) {
        tableType.push(item.table_type_id);
      }
    })
  }
  let formula = '';
  tableType.forEach((item, index) => {
    if (index === 0) {
      formula = `${formula}AND(Cond().EQ("$.self.id", ${item})`;
    } else {
      formula = `${formula}.OR(Cond().EQ("$.self.id", ${item}))`;
    }
    if (index === tableType.length-1) {
      formula = `${formula})`;
    }
  })
  if (formula) {
    const table = await getRecords(storeSlug, 'party_size_segment_items', 1, formula);
    tables = parseResTable(table);
    tables.forEach(table => {
      timeSegmentsQuotas.forEach(item => {
        if (item.tableTypeID === table.tableTypeID) {
          table.timeSegmentIDs.push(item.timeSegmentID);
        }
      })
    })
  }
  return {tables, timeSegmentsQuotas, bookingSetting, numberQuota}
}

function* handleGetUseTimeMap(storeSlug: string) {
  let store: IStore | undefined = undefined;
  const useTimeMap: IWeekBooking = {};
  const disableTimeMap: IWeekBooking = {};
  const mealTimeRecords = yield getRecords(storeSlug, 'store_time_segments')
  const queryStoreResult = yield handleQueryStore({
    type: ActionType.QUERY_STORE,
    storeSlug,
  })
  const storeTimeSegments = parseResTimeSegments(mealTimeRecords);
  store = queryStoreResult.store;
  if (store) {
    const openingHours = store.openingHours;
    Object.keys(openingHours).forEach(key => {
      openingHours[key].forEach(item => {
        if (disableTimeMap[key]) {
          disableTimeMap[key].push({start: `${item.start.substr(0, 2)}:${item.start.substr(2)}`, end: `${item.end.substr(0, 2)}:${item.end.substr(2)}`, timeSegmentID: '', title: '', i18n: {}})
        } else {
          disableTimeMap[key] = [{start: `${item.start.substr(0, 2)}:${item.start.substr(2)}`, end: `${item.end.substr(0, 2)}:${item.end.substr(2)}`, timeSegmentID: '', title: '', i18n: {}}]
        }
      })
    })
  }
  const ymd = dayjs().format('YYYY-MM-DD')
  storeTimeSegments.sort((a, b) => {
    return dayjs(ymd + a.start).hour() - dayjs(ymd + b.start).hour();
  })

  storeTimeSegments.forEach(item => {
    const lastBookingTime = item.lastBookingTime;
    Object.keys(disableTimeMap).forEach(key => {
      disableTimeMap[key].forEach(time => {
        let start = item.start;
        let end = item.end;

        if (dayjs(ymd + time.start).isAfter(dayjs(ymd + item.start))) {
          start = time.start;
        }
        if (dayjs(ymd + time.end).isBefore(dayjs(ymd + item.end))) {
          end = time.end;
        }
        if (lastBookingTime) {
          end = dayjs(ymd + end).add(lastBookingTime, 'm').format('HH:mm:ss');
        }
        if (start !== end) {
          if (Array.isArray(useTimeMap[key])) {
            useTimeMap[key].push({start: start, end: end, timeSegmentID: item.id, title: item.title, i18n: item.i18n});
          } else {
            useTimeMap[key] = [{start: start, end: end, timeSegmentID: item.id, title: item.title, i18n: item.i18n}];
          }
        }
      })
    })
  })

  return useTimeMap;
}

function* handleQueryBookingSetting(params: IQueryBookingSetting) {
  const { callback, storeSlug } = params
  let useTimeMap: IWeekBooking = {};
  let tables: ITable[] = [];
  let timeSegmentsQuotas: ITimeSegmentsQuota[] = [];
  let numberQuota: INumberQuota[] = []
  let bookingSetting: IBookingSetting | undefined = undefined;
  try {
    Promise.all(
      [handleGetSettings(storeSlug), yield handleGetUseTimeMap(storeSlug)]
    ).then(([setting, mealTimeRecords]) => {
      tables = setting.tables;
      timeSegmentsQuotas = setting.timeSegmentsQuotas;
      bookingSetting = setting.bookingSetting;
      numberQuota = setting.numberQuota;
      useTimeMap = mealTimeRecords;

      if (callback) {
        callback(tables, useTimeMap, timeSegmentsQuotas, bookingSetting, numberQuota)
      }
    })
  } catch (error) {
    if (callback) {
      callback(tables, useTimeMap, timeSegmentsQuotas, bookingSetting, numberQuota)
    }
    console.error(error);
  }
}

function* handleCreateBooking(params: ICreateQueueing) {
  const {
    cover,
    userInfo,
    date,
    storeSlug,
    phone,
    dialCode,
    email,
    name,
    note,
    duration,
    callback,
  } = params;

  let newUserID = userInfo ? userInfo.id : '';
  let newuserInfo: IUserInfo | undefined = undefined;
  let customer: ICustomer | undefined = undefined;
  let newcustomer: ICustomer | undefined = undefined;
  let booking: IBooking | undefined = undefined;
  const newPhone = `+${dialCode} ${phone}`;

  const guest = localStorage.getItem(Constants.IS_GUEST) === 'true' || localStorage.getItem(Constants.IS_GUEST) === null;
  try {
    if (guest) {
      const signUpResult = yield handleSignUpForGuest({
        type: ActionType.SIGN_UP_FOR_GUEST,
        dialCode,
        phoneNumber: phone,
      });

      if (signUpResult && signUpResult.success) {
        newuserInfo = yield handleSignIn({
          type: ActionType.SIGN_IN,
          username: signUpResult.userEmail,
          password: signUpResult.password,
          loginMethod: 'guest',
          grantType: signUpResult.password,
        });
      }

      if (newuserInfo) {
        newUserID = newuserInfo.id;
      }

      const res = yield call(
        axios.get,
        Config.urls.customer.replace('{storeSlug}', storeSlug),
      );

      if (res && res.customer) {
        customer = {
          id: res.customer.id,
          name: res.customer.name,
          phone: res.customer.phone,
          email: res.customer.email,
          storeID: res.customer.store_id,
          storeSlug,
          membershipLevelID: res.customer.membership_level_id,
          customerCode: res.customer.customer_code,
          allowCompanyAccount: res.allow_company_house_account,
        };
      }

      if (customer) {
        newcustomer = yield handleUpdataCustomer({
          type: ActionType.UPDATE_CUSTOMER,
          name,
          phone: newPhone,
          email,
          storeSlug,
        })
      }
    } else if (userInfo) {
      if (!userInfo.phone || userInfo.phone === newPhone) {
        newcustomer = yield handleUpdataCustomer({
          type: ActionType.UPDATE_CUSTOMER,
          name,
          phone: newPhone,
          email,
          storeSlug,
        })
      } else {
        const { store }= yield handleQueryStore({
          type: ActionType.QUERY_STORE,
          storeSlug,
        })
        if (store) {
          newcustomer = yield createCustomer(storeSlug, store.id, name, newPhone, email, userInfo.id)
        }
      }
    }

    if (newcustomer && newUserID) {
      yield handlePushNotification({
        type: ActionType.PUSH_NOTIFICATION,
        customerID: newcustomer.id,
      });
      booking = yield createBooking(storeSlug, cover, newcustomer.id, date, newcustomer.storeID, note, duration);
      if (booking && !guest) {
        yield put<Action>({
          type: ActionType.SET_BOOKINGS,
          isAdd: true,
          bookings: [booking],
        });
      }
    }
  } catch (error){
    console.error(error);
  }
  if (callback) {
    callback(booking);
  }
}

function* handleCancelledBooking(params: ICancelledBooking) {
  const {
    storeSlug,
    id,
    callback,
  } = params;

  let booking: IBooking | undefined = undefined;
  try {
    booking = yield CancelledBooking(storeSlug, id);
    if (booking) {
      yield put<Action>({
        type: ActionType.SET_BOOKINGS,
        bookings: [booking],
      });
    }

  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(booking);
  }
}

function* handleQueryBookingsForDate(params: IQueryBookingsForDate) {
  const {
    formula,
    callback,
    storeSlug,
  } = params;

  let bookings: IBooking[] = [];

  try {
    const { store }= yield handleQueryStore({
      type: ActionType.QUERY_STORE,
      storeSlug,
    })

    if (store) {
      const newFormula = `${formula}.EQ("$.self.store_id", "${store.id}")`
      bookings = yield getBookings(newFormula, 1, 99);
    }
  } catch (error) {
    console.error(error);
  }
  if (callback) {
    callback(bookings);
  }
}

function* watchPay() {
  yield takeEvery(ActionType.QUERY_BOOKINGS, handleQueryBookings);
  yield takeEvery(ActionType.QUERY_BOOKING_SETTING, handleQueryBookingSetting);
  yield takeEvery(ActionType.CREATE_BOOKING, handleCreateBooking);
  yield takeEvery(ActionType.CANCELL_BOOKING, handleCancelledBooking);
  yield takeEvery(ActionType.QUERY_BOOKINGS_FOR_DATE, handleQueryBookingsForDate);
}

export default watchPay();
