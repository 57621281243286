import { ITaxOption } from './models/OrderEntity';

class Context {
  orderLevelDecimalPlaces = 2;
  lineItemLevelDecimalPlaces = 2;
  taxDecimalPlaces = 2;
  currencyCode = '';
  taxOptions: ITaxOption[] = [];

  setCurrencyCode = (currencyCode: string) => {
    this.currencyCode = currencyCode;
  }

  setTaxOptions = (taxOptions: ITaxOption[]) => {
    this.taxOptions = [...taxOptions];
  }
}

export default Context;
