import produce from 'immer';
import { handleActions } from 'redux-actions';
import ActionType from '@/actions/action-type';
import {
  IStore,
  ISetStore,
  IPushStores,
  ISetGroupStores,
  ISetRecommendedStores,
  ISetSearchStores,
  IStoreTable,
  ISetStoreTables,
  ISetStoreBlogs,
  IBlog,
  ISetStoreCompanyInfo,
  IStoreConfig,
  ISetStoreConfig,
  IStoreImage,
  ISetStoreImages,
  IClearStore,
  ISetStoresConfigs,
  IPushStoresByCategory,
  IStoreCategory,
  IPushBrandsByCategory,
  IStorePickupLocation,
  ISetStorePickupLocations,
  IStoreMsg,
  ISetStoreSlugByRandomCode,
  ISetAllStoresInfo,
} from '@/actions/store-action';

export interface IStoreState {
  typeRefStores: {[type: string]: IStore[]};
  typeRefStoresByCategory: {[type: string]: {[type: string]: IStore[]}};
  typeRefBrand: {[type: string]: IStoreCategory[]};
  slugRefStore: {[storeSlug: string]: IStore};
  slugRefStoreConfig: {[storeSlug: string]: IStoreConfig};
  slugRefGroupStores: {[storeSlug: string]: IStore[]};
  slugRefStorePickupLocations: {[storeSlug: string]: IStorePickupLocation[]};
  recommendedStores?: IStore[];
  searchKeyRefStores: {
    [key: string]: {
      key: string;
      time: number;
      stores: IStore[];
    };
  };
  slugRefTables: {[storeSlug: string]: IStoreTable[]};
  slugRefBlogs: {[storeSlug: string]: IBlog[]};
  slugRefCompanyInfo: {[storeSlug: string]: IBlog[]};
  slugRefPromotions: {[storeSlug: string]: IBlog[]};
  slugRefStoreImages: {[storeSlug: string]: IStoreImage[]};
  filterRefStores: {
    category: Set<string>;
    brands: Set<string>;
    prices: number[];
    radius: number;
    district: Set<string>;
  };
  numberRefStores: {[number: string]: IStoreMsg};
}

const initState: IStoreState = {
  typeRefStores: {},
  typeRefStoresByCategory: {},
  typeRefBrand: {},
  slugRefStore: {},
  slugRefStoreConfig: {},
  slugRefStorePickupLocations: {},
  slugRefGroupStores: {},
  recommendedStores: undefined,
  searchKeyRefStores: {},
  slugRefTables: {},
  slugRefBlogs: {},
  slugRefCompanyInfo: {},
  slugRefPromotions: {},
  slugRefStoreImages: {},
  filterRefStores: {
    category: new Set(),
    brands: new Set(),
    prices: [],
    radius: 0,
    district: new Set(),
  },
  numberRefStores: {},
};

const actions: any = {};

actions[ActionType.SET_STORE] = (state: IStoreState, action: ISetStore) => produce(state, draft => {
  draft.slugRefStore[action.storeSlug] = action.store;
});

actions[ActionType.SET_ALL_STORES_INFO] = (state: IStoreState, action: ISetAllStoresInfo) => produce(state, draft => {
  if (action.allStores.length > 0) {
    action.allStores.forEach(item => {
      draft.slugRefStore[item.slug] = item;
    })
  }
  Object.keys(action.allStoresImages).forEach(key => {
    draft.slugRefStoreImages[key] = action.allStoresImages[key];
  })
  Object.keys(action.allStorePickupLocations).forEach(key => {
    draft.slugRefStorePickupLocations[key] = action.allStorePickupLocations[key];
  })
  if (action.storesConfigs.length > 0) {
    action.storesConfigs.forEach(item => {
      draft.slugRefStoreConfig[item.storeSlug] = item;
    })
  }
});

actions[ActionType.SET_STORE_CONFIG] = (state: IStoreState, action: ISetStoreConfig) => produce(state, draft => {
  draft.slugRefStoreConfig[action.storeSlug] = action.storeConfig;
});

actions[ActionType.SET_STORE_PICKUP_LOCATIONS] = (state: IStoreState, action: ISetStorePickupLocations) => produce(state, draft => {
  draft.slugRefStorePickupLocations[action.storeSlug] = action.storePickupLocations;
});

actions[ActionType.SET_STORES_CONFIGS] = (state: IStoreState, action: ISetStoresConfigs) => produce(state, draft => {
  action.storesConfigs.forEach(item => {
    draft.slugRefStoreConfig[item.storeSlug] = item;
  })
});

actions[ActionType.PUSH_STORES] = (state: IStoreState, action: IPushStores) => produce(state, draft => {
  if (action.mode === 'replace') {
    draft.typeRefStores[action.serviceType] = [...action.stores];
  } else {
    if (!draft.typeRefStores[action.serviceType]) {
      draft.typeRefStores[action.serviceType] = [];
    }
    draft.typeRefStores[action.serviceType].push(...action.stores);
    action.stores.forEach(store => {
      if (store && store.slug) {
        draft.slugRefStore[store.slug] = store;
      }
    });
  }
});

actions[ActionType.PUSH_STORES_BY_CATEGORY] = (state: IStoreState, action: IPushStoresByCategory) => produce(state, draft => {
  if (!draft.typeRefStoresByCategory[`${action.storeType}`] || action.mode === 'replace') {
    draft.typeRefStoresByCategory[`${action.storeType}`] = {[`${action.categoryID}`]: []};
  }
  const a = draft.typeRefStoresByCategory[`${action.storeType}`][`${action.categoryID}`] || [];
  a.push(...action.stores);
  draft.typeRefStoresByCategory[`${action.storeType}`] = {
    ...draft.typeRefStoresByCategory[`${action.storeType}`],
    [`${action.categoryID}`]: a,
  }

  action.stores.forEach(store => {
    if (store && store.slug) {
      draft.slugRefStore[store.slug] = store;
    }
  });
});

actions[ActionType.PUSH_BRANDS_BY_CATEGORY] = (state: IStoreState, action: IPushBrandsByCategory) => produce(state, draft => {
  if (!draft.typeRefBrand[action.categoryID]) {
    draft.typeRefBrand[action.categoryID] = [];
  }
  draft.typeRefBrand[action.categoryID].push(...action.brands);
});

actions[ActionType.SET_GROUP_STORES] = (state: IStoreState, action: ISetGroupStores) => produce(state, draft => {
  draft.slugRefGroupStores[action.storeSlug] = action.stores;
  action.stores.forEach(store => {
    if (store && store.slug) {
      draft.slugRefStore[store.slug] = store;
    }
  });
});

actions[ActionType.SET_RECOMMENDED_STORES] = (state: IStoreState, action: ISetRecommendedStores) => produce(state, draft => {
  draft.recommendedStores = action.stores;
  action.stores.forEach(store => {
    if (store && store.slug) {
      draft.slugRefStore[store.slug] = store;
    }
  });
});

actions[ActionType.SET_SEARCH_STORES] = (state: IStoreState, action: ISetSearchStores) => produce(state, draft => {
  const searchStores = Object.values(draft.searchKeyRefStores);
  // 最多存储50个搜索历史记录
  const maxCount = 50;
  if (searchStores.length >= maxCount) {
    searchStores.sort((item1, item2) => item1.time - item2.time);
    searchStores.length = maxCount - 1;

    draft.searchKeyRefStores = {};

    searchStores.forEach(item => {
      draft.searchKeyRefStores[item.key] = item;
    })
  }

  draft.searchKeyRefStores[action.key] = {
    key: action.key,
    time: new Date().getTime(),
    stores: action.stores,
  };

  action.stores.forEach(store => {
    if (store && store.slug) {
      draft.slugRefStore[store.slug] = store;
    }
  });
});

actions[ActionType.SET_STORE_TABLES] = (state: IStoreState, action: ISetStoreTables) => produce(state, draft => {
  draft.slugRefTables[action.storeSlug] = action.tables;
});

actions[ActionType.SET_STORE_BLOGS] = (state: IStoreState, action: ISetStoreBlogs) => produce(state, draft => {
  draft.slugRefBlogs[action.storeSlug] = action.blogs;
});

actions[ActionType.SET_STORE_COMPANY_INFO] = (state: IStoreState, action: ISetStoreCompanyInfo) => produce(state, draft => {
  draft.slugRefCompanyInfo[action.storeSlug] = action.companyInfo;
});

actions[ActionType.SET_STORE_PROMOTIONS] = (state: IStoreState, action: ISetStoreCompanyInfo) => produce(state, draft => {
  draft.slugRefPromotions[action.storeSlug] = action.companyInfo;
});

actions[ActionType.SET_STORE_IMAGES] = (state: IStoreState, action: ISetStoreImages) => produce(state, draft => {
  draft.slugRefStoreImages[action.storeSlug] = action.storeImages;
});
actions[ActionType.SET_STORE_SLUG_BY_RANDOM_CODE] = (state: IStoreState, action: ISetStoreSlugByRandomCode) => produce(state, draft => {
  draft.numberRefStores[action.number] = action.storeMsg;
});

actions[ActionType.CLEAR_STORE] = (state: IStoreState, action: IClearStore) => produce(state, draft => {
  draft.typeRefStores = {};
  draft.slugRefStore = {};
  draft.slugRefStoreConfig = {};
  draft.slugRefStoreImages = {};
  draft.slugRefGroupStores = {};
  draft.searchKeyRefStores = {};
  draft.slugRefTables = {};
  draft.slugRefCompanyInfo = {};
  draft.slugRefBlogs = {};
});

actions[ActionType.SET_STORES_FILTER] = (state: IStoreState, payload: any) => produce(state, (draft: any) => {
  const { filterRefStores } = draft;
  const { params = [] } = payload;
  params.forEach((param: any) => {
    Object.keys(param).forEach(key => {
      filterRefStores[key] = param[key];
    });
  })
});

export default handleActions(actions, initState);
