// import { lazy } from 'react';
import { IRoute } from '@/routes';
import paths from '@/routes/paths';
// import Home from './home';
import Stores from './stores';
import StoreGroup from './store-group';
import Menus from './menus';
import Modifier from './modifier';
import Checkout from './checkout';
import ShoppingCart from './shopping-cart';
import Search from './search';
import MyStores from './my-stores';
// import CreateStore from './create-store';
import StoreEdit from './store-edit';
import StoreAssociation from './store-association';
import StoreConfigures from './store-configures';
import EShop from './eshop';
import StoresByCategory from './stores_by_category';
import StoresByBooking from './stores-by-booking';
import StoreMap from './stores-map';

export const mobileRoutes: IRoute[] = [
  {
    path: paths.STORE_LIST,
    // component: lazy(() => import(/* webpackChunkName: 'bm-mobile' */ './stores')),
    component: Stores,
  },
  {
    path: paths.STORE_LIST_BY_CATEGORY,
    // component: lazy(() => import(/* webpackChunkName: 'bm-mobile' */ './stores_by_category')),
    component: StoresByCategory,
  },
  {
    path: paths.STORE_GROUP,
    // component: lazy(() => import(/* webpackChunkName: 'bm-mobile' */ './store-group')),
    component: StoreGroup,
  },
  {
    path: paths.MENU_LIST,
    // component: lazy(() => import(/* webpackChunkName: 'bm-mobile' */ './menus')),
    component: Menus,
  },
  {
    path: paths.MENU_LIST_OLD,
    // component: lazy(() => import(/* webpackChunkName: 'bm-mobile' */ './menus')),
    component: Menus,
  },
  {
    path: paths.MENU_LIST_TO,
    // component: lazy(() => import(/* webpackChunkName: 'bm-mobile' */ './menus')),
    component: Menus,
  },
  {
    path: paths.MENU_LIST_QR_ORDER,
    // component: lazy(() => import(/* webpackChunkName: 'bm-mobile' */ './menus')),
    component: Menus,
  },
  {
    path: paths.MODIFIER,
    // component: lazy(() => import(/* webpackChunkName: 'bm-mobile' */ './modifier')),
    component: Modifier,
  },
  {
    path: paths.SUB_MODIFIER,
    // component: lazy(() => import(/* webpackChunkName: 'bm-mobile' */ './modifier')),
    component: Modifier,
  },
  {
    path: paths.SUB_MODIFIER_B,
    // component: lazy(() => import(/* webpackChunkName: 'bm-mobile' */ './modifier')),
    component: Modifier,
  },
  {
    path: paths.CHECKOUT,
    // component: lazy(() => import(/* webpackChunkName: 'bm-mobile' */ './checkout')),
    component: Checkout,
  },
  {
    path: paths.CHECKOUT_BY_FROM,
    // component: lazy(() => import(/* webpackChunkName: 'bm-mobile' */ './checkout')),
    component: Checkout,
  },
  {
    path: paths.SHOPPING_CART,
    // component: lazy(() => import(/* webpackChunkName: 'bm-mobile' */ './shopping-cart')),
    component: ShoppingCart,
  },
  {
    path: paths.SHOPPING_CART_BY_FROM,
    // component: lazy(() => import(/* webpackChunkName: 'bm-mobile' */ './shopping-cart')),
    component: ShoppingCart,
  },
  {
    path: paths.SEARCH,
    // component: lazy(() => import(/* webpackChunkName: 'bm-mobile' */ './search')),
    component: Search,
  },
  {
    path: paths.ADMIN_STORES,
    // component: lazy(() => import(/* webpackChunkName: 'bm-mobile' */ './my-stores')),
    component: MyStores,
  },
  // {
  //   path: paths.ADMIN_STORE_NEW,
  //   // component: lazy(() => import(/* webpackChunkName: 'bm-mobile' */ './create-store')),
  //   component: CreateStore,
  // },
  {
    path: paths.ADMIN_STORE_EDIT,
    // component: lazy(() => import(/* webpackChunkName: 'bm-mobile' */ './store-edit')),
    component: StoreEdit,
  },
  {
    path: paths.STORE_ASSOCIATION,
    // component: lazy(() => import(/* webpackChunkName: 'bm-mobile' */ './store-association')),
    component: StoreAssociation,
  },
  {
    path: paths.ESHOP,
    // component: lazy(() => import(/* webpackChunkName: 'bm-mobile' */ './eshop')),
    component: EShop,
  },
  {
    path: paths.STORE_SETTING,
    // component: lazy(() => import(/* webpackChunkName: 'bm-mobile' */ './shopping')),
    component: StoreConfigures,
  },
  {
    path: paths.STORES_BY_BOOKING,
    // component: lazy(() => import(/* webpackChunkName: 'bm-mobile' */ './stores-by-booking')),
    component: StoresByBooking,
  },
  {
    path: paths.STORE_MAP,
    component: StoreMap,
  },
];

export default mobileRoutes;
