import React from 'react';
import { ISplash } from '@/actions/app-action';
import LogoView from './LogoView';
import ImageView from './ImageView';
import VideoView from './VideoView';
import { IDeviceInfo } from '@/utils/app';

const views: any = {
  'LOGO': LogoView,
  'IMAGE': ImageView,
  'VIDEO': VideoView,
}

export interface ICreateViewParams {
  data: ISplash;
  count: number;
  index: number;
  deviceInfo: IDeviceInfo;
  showItemBtns: boolean;
  language: string;
  onNextClick?: (num: number) => void;
  onDoneClick?: () => void;
}

export const createView = (params: ICreateViewParams) => {
  const {
    data,
  } = params;

  const { id, type } = data;

  const viewProps = {
    key: id,
    ...params,
  };

  let viewNode: React.ReactNode | null = null;
  if (views[type]) {
    const ViewItem = views[type];
    viewNode = <ViewItem {...viewProps} />
  }

  return viewNode;
}
