import { IRoute } from '@/routes';
import paths from '@/routes/paths';
import UsePointsPage from './UsePointsPage';

export const gatewayRoutes: IRoute[] = [
  {
    path: paths.POINTS,
    component: UsePointsPage,
  },
];

export default gatewayRoutes;
