import './StoreList.less';

export const prefix = 'bm-c-store-list';

export enum FootStyle {
  STORE_DEFAULT = 'STORE_DEFAULT',
  STORE_ADDRESS = 'STORE_ADDRESS',
}

export { default } from './StoreList';
