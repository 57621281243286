import styled from 'styled-components';

export const CheckoutPageStyled = styled.div`
  * {
    box-sizing: border-box;
  }
  width: 100%;
  height: 100%;
  overflow: scroll;
  .section-3 {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    overflow: inherit;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-image: url("https://assets.website-files.com/605173a784082e077698cb03/60dc2f106da262131702eec5_pexels-photo-1640777.jpeg");
    background-position: 50% 50%;
    background-size: cover;
  }
  .div-block-556 {
    width: 100%;
    bottom: auto;
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: hsla(0, 0%, 100%, 0.46);
  }
  .w-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 940px;
  }
  .div-block-4 {
    position: relative;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    max-width: 100%;
    min-height: auto;
    margin-right: 0px;
    padding: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .text-block-214.black {
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: var(--bm-text-title-color);
  }
  .text-block-214 {
    margin-right: 5px;
    font-size: 18px;
  }
  .div-block-520 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 500px;
    height: 600px;
    padding: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-radius: 8px;
    background-color: hsla(0, 0%, 100%, 0.83);
    box-sizing: border-box;
  }
  .w-inline-block {
    max-width: 100%;
    display: inline-block;
  }
  .sign-in-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 60px;
    margin-bottom: 20px;
    padding: 15px 20px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 4px;
    background-color: var(--bm-primary-color);
    color: var(--bm-light-background-color);
    text-decoration: none;
  }
  .html-embed-43 {
    width: 33px;
    margin-right: 9px;
    padding-top: 0px;
    padding-left: 0px;
    text-align: center;
    white-space: normal;
    padding: 0;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .text-block-105 {
    font-family: 'Sf pro display', sans-serif;
    font-size: 16px;
  }
  .container-5 {
    position: relative;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
  }
@media screen and (max-width: 991px) {
  .w-container {
    max-width: 728px;
  }
}

@media screen and (max-width: 767px) {
  .section-3 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 95vh;
    padding-top: 0px;
    padding-bottom: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    background-image: none;
    background-size: auto;
  }
  .w-container {
    max-width: 728px;
  }
  .text-block-214.black {
    font-size: 18px;
  }
  .text-block-214 {
    font-size: 16px;
  }
  .div-block-520.not-100 {
    height: auto;
    min-height: auto;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .div-block-520 {
    width: 100%;
    height: 100%;
    min-height: 100%;
    min-width: 100%;
    border-radius: 0px;
    background-color: var(--bm-light-background-color);
    margin: unset;
    transform: unset;
  }
  .text-block-105 {
    font-size: 14px;
  }
  .div-block-556 {
    position: static;
    padding: 20px;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
    background-color: var(--bm-checkout-backgroud-color);
  }
}
@media screen and (max-width: 479px) {
  .section-3 {
    height: 95vh;
    padding-bottom: 0px;
  }
  .div-block-556 {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .container-5 {
    position: relative;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
  }
  .w-container {
    max-width: none;
  }
  .div-block-4 {
    max-width: 100%;
    padding-top: 0px;
  }
  .text-block-214.black {
    font-size: 16px;
  }
  .text-block-214 {
    font-size: 16px;
  }
  .div-block-520 {
    width: auto;
  }
  .sign-in-block {
    text-decoration: none;
  }
  .text-block-105 {
    font-size: 14px;
  }
}
`;
export const CheckoutStyled = styled.div`
  overflow: scroll;
  width: 100%;
  height: 100%;
  .am-list-item {
    .am-input-extra {
      overflow: initial;
    }
  }
`;

export const GuestCheckoutStyled = styled.div`
  * {
    box-sizing: border-box;
  }
  overflow: scroll;
  background-color: var(--bm-dark-background-color);
  .am-list-item {
    background-color: var(--bm-light-background-color);
    width: 100%;
  }
  .section-4 {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 43px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: var(--bm-light-background-color);
  }
  .w-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 940px;
  }
  .div-block-558 {
    margin-bottom: 40px;
    padding: 50px;
    border-radius: 8px;
    background-color: var(--bm-light-background-color);
    box-shadow: 1px 1px 16px -12px var(--bm-shadow-color-t60);
  }
  .div-block-4.with-space {
    margin-bottom: 20px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
  }
  .div-block-4 {
    position: relative;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    max-width: 100%;
    min-height: auto;
    margin-right: 0px;
    padding: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .text-block-214.black {
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: var(--bm-text-title-color);
  }
  .div-block-525 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .div-block-525._500px {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 600px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .mobile-signin-noti-block.no-space {
    margin-bottom: 0px;
  }
  .mobile-signin-noti-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 70%;
    height: 60px;
    margin-bottom: 20px;
    padding: 15px 20px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: var(--bm-primary-color);
    border-radius: 4px;
    background-color: var(--bm-light-background-color);
    color: var(--bm-primary-color);
  }
  .html-embed-43 {
    width: 33px;
    margin-right: 9px;
    padding-top: 0px;
    padding-left: 0px;
    text-align: center;
    white-space: normal;
    padding: 0;
    svg {
      width: 100%;
      height: 100%;
      color: var(--bm-primary-color);
    }
  }
  .text-block-105,
  .forgot-password {
    font-family: 'Sf pro display', sans-serif;
    font-size: 16px;
  }
  .alternative-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 30%;
    height: 60px;
    margin-right: 0px;
    margin-left: 10px;
    padding: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 4px;
    background-color: var(--bm-primary-color);
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: var(--bm-light-background-color);
    cursor: pointer;
  }
  .text-block-214 {
    margin-right: 5px;
    font-size: 18px;
  }
  .am-input-control input {
    margin-bottom: 0px;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    font-weight: 400;
    font-size: 16px;
  }
  .am-list-line .am-input-control input::-webkit-input-placeholder {
    font-size: unset;
  }
  .div-block-525._500px-vertical {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 600px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .bm-c-country-code-areacode-dialcode {
    font-size: 16px;
  }
  .bm-c-country-code-down-icon,
  .close-outlined {
    width: 33px;
    height: 25px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .text-block-214.bold {
    font-weight: 700;
    cursor: pointer;
  }
  .text-block-214 {
    margin-right: 5px;
    font-size: 18px;
  }
  .cc-column {
    display: flex;
    .cc-exp-date {
      padding-right: 10px;
    }
  }
  .sign-in {
    width: 100%;
  }
  .am-list-item:not(:last-child) .am-list-line {
    border-bottom: unset;
  }
  .am-list-line::after {
    height: 0!important;
  }
  .am-list-item {
    margin-bottom: 20px;
  }
  @media screen and (max-width: 991px) {
    .w-container {
      max-width: 728px;
    }
  }

  @media screen and (max-width: 767px) {
    background-color: var(--bm-light-background-color);
    .section-4 {
      padding-top: 0px;
    }
    .container-6 {
      max-width: none;
      -webkit-align-self: stretch;
      -ms-flex-item-align: stretch;
      -ms-grid-row-align: stretch;
      align-self: stretch;
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
    }
    .div-block-558 {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      margin-bottom: 20px;
      padding: 0px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      background-color: rgba(255, 255, 255, 0);
      box-shadow: none;
    }
    .div-block-4.with-space {
      width: 100vw;
      margin-left: 0px;
      padding: 15px 20px;
      -webkit-align-self: stretch;
      -ms-flex-item-align: stretch;
      -ms-grid-row-align: stretch;
      align-self: stretch;
      background-color: var(--bm-checkout-backgroud-color);
    }
    .text-block-214.black {
      font-size: 18px;
    }
    .text-block-214 {
      font-size: 16px;
    }
    .div-block-525._500px {
      width: auto;
      margin-right: 20px;
      margin-left: 20px;
    }
    .text-block-105,
    .forgot-password {
      font-size: 14px;
    }
    .alternative-block {
      width: 40%;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
    .am-input-control input {
      font-size: 14px;
    }
    .div-block-525._500px-vertical {
      width: auto;
      margin-right: 20px;
      margin-left: 20px;
    }
    .text-block-214 {
      font-size: 16px;
    }
    .cc-column {
      display: block;
      .cc-exp-date {
        padding-right: 0;
      }
    }
    .checkout-sigin {
      margin-left: 20px;
    }
  }
  @media screen and (max-width: 479px) {
    .w-container {
      max-width: none;
    }
    .div-block-4 {
      max-width: 100%;
      padding-top: 0px;
    }
    .text-block-214 {
      font-size: 16px;
    }
    .text-block-214.black {
      font-size: 16px;
    }
    .mobile-signin-noti-block {
      height: 50px;
      padding-right: 15px;
      padding-left: 15px;
    }
    .text-block-105,
    .forgot-password {
      font-size: 14px;
    }
    .alternative-block {
      height: 50px;
    }
    .am-list-item.am-input-item {
      height: 50px;
    }
    .bm-c-country-code-areacode-dialcode {
      font-size: 14px;
    }
    .bm-c-country-code-down-icon {
      margin-left: 0;
    }


  }
`;

