import styled from 'styled-components';

export const PayResultsPageStyled = styled.div`
* {
  box-sizing: border-box;
}
.section {
  display: block;
  margin-top: auto;
  margin-bottom: 0px;
  padding: 0 40px 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: unset;
}
.w-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
}
.container {
  position: relative;
  display: block;
  overflow: visible;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
}
.div-block-543 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.payment-status-icon._1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 100%;
}
.wrapper-2 {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 90%;
  margin: 40px auto 60px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  background-color: transparent;
}
.image-202 {
  max-width: 100%;
  svg {
    width: 100%;
    height: 100%;
  }
}
h1 {
  font-size: 38px;
line-height: 44px;
  margin-top: 20px;
}
.heading_1 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: 'Sf pro display', sans-serif;
  font-size: 25px;
  font-weight: 600;
  color: var(--bm-primary-color);
}
.div-block-554 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 450px;
  min-width: 450px;
  margin-top: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}
.div-block-456 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  color: var(--bm-text-title-color);
}
.div-block-436 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.div-block-436.no-space {
  margin-bottom: 0px;
}
.text-block-46,
.text-block-46.third  {
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: var(--bm-text-title-color);
  font-size: 18px;
}
.div-block-484.no-space {
  margin-bottom: 0px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.image-199 {
  margin-right: 10px;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  >div {
    padding: 0;
  }
  svg {
    width: 100%;
    height: 100%;
  }
}
.text-block-67 {
  margin-bottom: 0px;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: var(--bm-text-title-color);;
  font-size: 18px;
  font-weight: 400;
}
.secondary-payment-confirmation-buttons-copy {
  position: relative;
  left: auto;
  top: 0px;
  right: 0%;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: 55px;
  margin-right: 20px;
  padding: 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-radius: 8px;
  background-color: transparent;
  text-decoration: none;
  cursor: pointer;
}
.secondary-payment-confirmation-buttons-copy.blue.with-space {
  margin-right: 20px;
}
.secondary-payment-confirmation-buttons-copy.blue {
  width: 48%;
  margin-right: 0px;
  color: var(--bm-primary-color);
  border: 1px solid var(--bm-primary-color);
}
.submit-button-modal {
  width: 100%;
  height: 55px;
  padding: 17px;
  border-radius: 8px;
  .share {
    font-size: 26px;
  }
}
.submit-button-modal.close-modal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 48%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
}
.text-block-47 {
  font-family: 'Sf pro display', sans-serif;
  color: var(--bm-light-background-color);
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
}
.text-block-47.secondary {
  color: var(--bm-primary-color);
}
.text-block-46.third {
  display: flex;
}
.wrapper-4 {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 90%;
  margin: 0px auto 60px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}
.ticket-wrapper-4 {
  filter: drop-shadow(2px 6px 3px rgba(50, 50, 0, 0.4));
}
.ticket-wrapper-4 {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 90%;
  margin: 40px auto 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}
.reference-id {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 40px;
  padding-bottom: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  border-bottom: 1px dashed var(--bm-border-color);
}
.text-block-226.less-margin {
  margin-top: 0px;
}
.text-block-226 {
  margin-top: 10px;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  font-size: 20px;
}
.clipped-4 {
  position: relative;
  width: 450px;
  padding: 22px;
  border-radius: 12px;
  background-color: var(--bm-light-background-color);
  display: block;
}
.clipped-4 {
  -webkit-clip-path: url(#my-clip-path-4);
  clip-path: url(#my-clip-path-4);
}
.html-embed-21 {
  position: absolute;
  z-index: -12;
  display: block;
}
.svg-4 {
  position: absolute;
  width: 0;
  height: 0;
}
.payment-status-icon {
  margin-bottom: 20px;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}
.image-202.smaller {
  width: 30%;
  max-width: 30%;
  min-width: 30%;
}
.pi-loading {
  text-align: center;
}
.image-199 {
  >div {
    height: 33px;
    width: 33px;
  }
}
.div-block-436.no-space {
  line-height: 33px;
}
.am-button-ghost {
  position: sticky !important;
}
@media screen and (max-width: 991px) {
  .section {
    margin-top: 0px;
    padding: 30px 35px 30px;
  }
  .w-container {
    max-width: 728px;
  }
  .heading_1 {
    font-size: 20px;
  }
  .secondary-payment-confirmation-buttons-copy {
    position: static;
    top: 0px;
    right: 0%;
    margin-right: 20px;
  }
  .wrapper-4 {
    margin-bottom: 30px;
  }
  .ticket-wrapper-4 {
    margin-bottom: 30px;
  }
  .text-block-226 {
    font-size: 16px;
  }
}

@media screen and (max-width: 767px) {
  .section {
    padding: 25px 25px 20px;
  }
  .image-202 {
    max-width: 100%;
    min-width: 100%;
  }
  .heading_1 {
    margin-bottom: 0px;
    font-size: 18px;
  }
  .text-block-46 {
    font-size: 18px;
  }
  .text-block-46.third {
    font-size: 16px;
  }
  .secondary-payment-confirmation-buttons-copy {
    left: auto;
    top: -22%;
    right: -5%;
    bottom: 0%;
  }
  .submit-button-modal.close-modal {
    height: 55px;
  }
  .text-block-47.secondary_blue {
    font-size: 16px;
  }
  .wrapper-4 {
    width: 90%;
    margin-top: 10px;
    margin-bottom: 0px;
  }
  .ticket-wrapper-4 {
    width: auto;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .reference-id {
    margin-bottom: 40px;
    .heading_1 {
      margin-bottom: 0px;
      font-size: 22px;
    }
  }
  .clipped-4 {
    width: 450px;
  }
  .payment-status-icon {
    margin-bottom: 40px;
  }
  .image-202.smaller {
    width: 40%;
    max-width: 40%;
    min-width: 40%;
  }
}

@media screen and (max-width: 479px) {
  .section {
    padding: 20px 12px 15px;
  }
  .w-container {
    max-width: none;
  }
  .container {
    margin-top: 0px;
    margin-bottom: 0px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .payment-status-icon {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .image-202 {
    max-width: 80%;
    min-width: 80%;
    margin-bottom: 10px;
  }
  .heading_1 {
    margin-bottom: 10px;
    font-size: 18px;
  }
  .div-block-554 {
    width: auto;
    min-width: 280px;
    -webkit-align-self: stretch;
    -ms-flex-item-alicgn: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }
  .div-block-436 {
    margin-bottom: 10px;
  }
  .text-block-46 {
    font-size: 14px;
  }
  .text-block-67 {
    margin-bottom: 0px;
    font-size: 14px;
  }
  .secondary-payment-confirmation-buttons-copy {
    left: auto;
    top: 0%;
    right: 0%;
    bottom: 0%;
    width: auto;
    height: auto;
    margin-right: 15px;
    padding: 10px 20px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
  .secondary-payment-confirmation-buttons-copy.blue {
    height: 50px;
    text-align: center;
  }
  .submit-button-modal.close-modal {
    height: 50px;
  }
  .image-199 {
    >div {
      height: 21px;
      width: 30px;
    }
  }
  .div-block-436.no-space {
    line-height: 21px;
  }
  .wrapper-4 {
    width: auto;
    margin-right: 0px;
    margin-left: 0px;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }
  .ticket-wrapper-4 {
    margin-right: 0px;
    margin-left: 0px;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }
  .reference-id {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 0px;
    margin-bottom: 26px;
    margin-left: 0px;
    // padding-top: 10px;
    padding-bottom: 12px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    .heading_1 {
      margin-bottom: 10px;
      font-size: 22px;
      line-height: 26px;
    }
  }
  .text-block-226 {
    margin-top: 0px;
    font-size: 16px;
  }
  .clipped-4 {
    width: 100%;
    min-width: 100%;
    // padding-top: 10px;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
}

`;
