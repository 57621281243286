import React, { useState } from 'react';
import { prefix } from '.';
import Icon, { IconType } from '../Icon';
import { useHistory } from 'react-router-dom';
import { createRouteUrl, setBodyScrollable } from '@/utils';
import paths from '@/routes/paths';
import { IReducersState } from '@/reducers';
import { parseShoppingCartData } from '@/utils/cart';
import { formatCurrency } from '../Payment';
import { IStore } from '@/actions/store-action';
import { useDispatch } from 'react-redux';
import { IDispatch } from '@/actions';
import ActionType from '@/actions/action-type';
import ListingsPanel from '../ListingsPanel';
import LineItemEntity from '@/lib/order-builder/models/LineItemEntity';
import { IOrderInfo } from '@/lib/order-builder/models/OrderEntity';
import { goToRoute } from '@/utils/route-util';
import { plus } from '@/lib/order-builder/utils';
import { getIntl } from '../App/App';

interface IMenuListProps {
  className?: string;
  store?: IStore;
  reducersState: IReducersState;
  resetState?: (state: any) => void;
  orderNumber?: string;
  authCode?: string;
  fromPage?: string;
  platformStoreID?: string;
  platformStoreSlug?: string;
  currencyCode?: string;
}

const ShoppingCartBar: React.FC<IMenuListProps> = props => {
  const {
    className = '',
    store,
    reducersState,
    resetState,
    orderNumber,
    authCode,
    fromPage,
    platformStoreSlug,
    platformStoreID,
    currencyCode,
  } = props;
  let storeSlug = '';
  let storeID = '';
  if (store) {
    storeSlug = store.slug;
    storeID = store.id;
  } else if (platformStoreID && platformStoreSlug) {
    storeSlug = platformStoreSlug;
    storeID = platformStoreID;
  }
  const { storeIDRefShoppingCartData } = reducersState.cart;

  const [shoppingCartPanel, setShoppingCartPanel] = useState<boolean>(false);

  const setShowShoppingCartPanel = (show: boolean) => {
    setBodyScrollable(!show);
    setShoppingCartPanel(show)
  }

  const history = useHistory();
  const dispatch = useDispatch<IDispatch>();

  const handleClick = () => {
    if (orderNumber) {
      goToRoute({
        history,
        pathname: createRouteUrl(paths.QRCODE_SHOPPING_CART, { storeSlug, orderNumber, authCode }),
      });
    } else if (fromPage && fromPage === 'eShop') {
      goToRoute({
        history,
        pathname: createRouteUrl(paths.SHOPPING_CART_BY_FROM, { storeSlug, fromPage: 'eShop' }),
      });
    } else {
      goToRoute({
        history,
        pathname: createRouteUrl(paths.SHOPPING_CART, { storeSlug }),
      });
    }
  }


  const handlePlusClick = (data: LineItemEntity) => {
    dispatch({
      type: ActionType.MODIFY_LINE_ITEM_QUANTITY,
      storeSlug,
      uuid: data.uuid,
      operate: 'ADD',
      callback: shoppingCartData => {
        if (resetState) {
          resetState({ shoppingCartData });
        }
      },
    });
  }

  const handleMinusClick = (data: LineItemEntity) => {
    dispatch({
      type: ActionType.MODIFY_LINE_ITEM_QUANTITY,
      storeSlug,
      uuid: data.uuid,
      operate: 'MINUS',
      callback: shoppingCartData => {
        if (resetState) {
          resetState({ shoppingCartData });
        }
      },
    });
  }

  const clearShoppingCart = () => {
    if (resetState) {
      resetState({ shoppingCartData: undefined });
    }

    dispatch({
      type: ActionType.CLEAR_SHOPPING_CART,
    });

    setShowShoppingCartPanel(false)
  }

  let lineItemCount = 0;
  let initialTotal = 0;
  const orderInfos: IOrderInfo[] = [];
  Object.values(storeIDRefShoppingCartData).forEach(item => {
    if (item.orderInfo.shippingMethod === 2) {
      if (String(storeID) === String(item.orderInfo.storeID)) {
        const parseResult = parseShoppingCartData(item);
        lineItemCount += parseResult.lineItemCount;
        initialTotal = plus(initialTotal, parseResult.initialTotal);
        orderInfos.push(item.orderInfo);
      }
    } else {
      const parseResult = parseShoppingCartData(item);
      lineItemCount += parseResult.lineItemCount;
      initialTotal = plus(initialTotal, parseResult.initialTotal);
      orderInfos.push(item.orderInfo);
    }
  });

  if (lineItemCount < 1) {
    return null;
  }

  const listingsPanelViews: any = [];
  if (shoppingCartPanel && orderInfos.length > 0) {
    orderInfos.forEach(orderInfo => {
      orderInfo.orderEntities.forEach(item => {
        listingsPanelViews.push(
          <ListingsPanel
            key={item.id}
            data={item}
            showStoreTitle={orderInfo && orderInfo.superOrder}
            className="store-listings-panel"
            showRightTopCount={false}
            onPlusClick={handlePlusClick}
            onMinusClick={handleMinusClick}
            reducersState={reducersState}
          />
        )
      })
    })
  }

  return (
    <>
      <div className={`div-block-97 ${prefix} ${className}`} id="shopping-cart-bar">
        <div className="float-block">
          <div className="container w-container" onClick={handleClick}>
            <div className="add-cart-float">
              <div className="total-price-display">
                <div className="cart-icon">
                  <div className="quanity">{lineItemCount}</div>
                  <div className="html-embed-13 w-embed">
                    <Icon className="cart-icon" type={IconType.SHOPPING_CART_OUTLINED} />
                  </div>
                </div>
                <div className="text-block-6">{formatCurrency(initialTotal, currencyCode)}</div>
              </div>
              <div className="check-out-block w-inline-block">
                <div className="text-block-17">{getIntl().page.viewShoppingCart}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        shoppingCartPanel &&
        <div className={`${prefix}-popup ${shoppingCartPanel ? 'show' : ''}`} onClick={() => setShowShoppingCartPanel(false)}>
          <div className={`${prefix}-popup-wrap`} onClick={e => e.stopPropagation()}>
            <div className="scb-popup-bar">
              <div className="scb-popup-bar-left">已选商品</div>
              <div className="scb-popup-bar-right" onClick={clearShoppingCart}>
                <Icon type={IconType.RECYCLE_BIN_OUTLINED} />
                <div className="clear-cart">清空购物车</div>
              </div>
            </div>
            <div className="product-list">
              {listingsPanelViews}
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default ShoppingCartBar;
