import styled, { css } from 'styled-components';

const signInBlock = css`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 60px;
  margin-bottom: 20px;
  padding: 15px 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  background-color: ${props => props.theme.primaryColor};
  color: var(--bm-light-background-color);
  text-decoration: none;
`;

const inputStyle = css`
  border: 1px solid rgba(0, 69, 144, 0.09) !important;
  border-radius: 8px !important;
  background-color: var(--bm-light-background-color) !important;
  font-size: 16px;
`;

export const SignMethodStyled = styled.div`
  display: flex;
  .item-button-main {
    ${signInBlock};
    width: 100%;
    box-sizing: border-box;
    .icon {
      margin-right: 8px;
      padding-top: 0px;
      padding-left: 0px;
      text-align: center;
      white-space: normal;
    }

    .text {
      font-family: 'Sf pro display', sans-serif;
      font-size: 18px;
    }

    &.selected {
      background-color: ${props => props.theme.lightBackgroundColor};
      color: ${props => props.theme.primaryColor};
      border: 1px solid ${props => props.theme.primaryColor};
    }

    .bm-icon-init svg {
      width: 22px;
      height: 22px;
      fill: ${props => props.theme.lightBackgroundColor};
    }

    .bm-icon-selected svg {
      fill: ${props => props.theme.primaryColor};
    }
  }
  .button-back {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 30%;
    height: 60px;
    margin-right: 0px;
    margin-left: 10px;
    padding: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 4px;
    background-color: ${props => props.theme.primaryColor};
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: var(--bm-light-background-color);
    cursor: pointer;
    font-size: 16px;
  }
  @media screen and (max-width: 767px) {
    .button-back {
      width: 40%;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      font-size: 14px;
    }
  }
  @media screen and (max-width: 479px) {
    .item-button-main {
      text-decoration: none;
      .text {
        font-size: 16px;
      }
    }
    /* .item-button-main,
    .button-back {
      height: 50px;
    } */
  }
`;
export const InputItemStyled = styled.div`
  .text-field {
    ${inputStyle}
    .inputextra {
      display: flex;
      height: 100%;
      align-items: center;
      color: #333;
      font-size: 16px;
      font-weight: 600;
    }
  }
  .am-input-extra {
    margin-left: 32px;
    max-height: 40px;
    overflow: unset;
  }
  .card-icon-close {
    font-size: 40px;
    color: var(--bm-text-color);
  }
`;
export const PasswordStyled = styled(InputItemStyled)`
  margin-top: 32px;
  margin-bottom: 20px;
  .am-input-extra {
    margin-left: 32px;
    max-height: 40px;
  }

  .card-icon-close {
    font-size: 40px;
    color: ${props => props.theme.textColor};
  }
  .forgot {
    color: ${props => props.theme.primaryColor};
    font-size: 16px;
  }
`;

export const IWantSignInStyled = styled.div`
  width: 100%;
  height: 100%;
  .i-want-sign-in {
    .am-input-label.am-input-label-5 {
      width: 100px;
    }
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    margin-top: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-image: url('https://assets.website-files.com/605173a784082e077698cb03/60dc2f106da262131702eec5_pexels-photo-1640777.jpeg');
    background-position: 50% 50%;
    background-size: cover;
    .div-block-520 {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: 500px;
      height: 600px;
      padding: 20px 20px 60px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      border-radius: 8px;
      background-color: hsla(0, 0%, 100%, 0.83);
      box-sizing: border-box;
      overflow-y: auto;
      >div:first-child {
        >div:last-child {
          margin-top: 0;
        }
      }
    }
    .other-signin {
      .div-block-522 {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        margin-bottom: 20px;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-align-self: stretch;
        -ms-flex-item-align: stretch;
        align-self: stretch;
      }
      .line {
        position: static;
        left: 0%;
        top: 0%;
        right: auto;
        bottom: 0%;
        width: 45%;
        height: 1px;
        -webkit-align-self: center;
        -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
        background-color: #a39d9d;
      }
      .text-block-5---subtitle {
        margin-right: 10px;
        margin-bottom: 0px;
        margin-left: 0px;
        font-family: 'Fontsfree net sfprodisplay', sans-serif;
        color: #999;
        font-size: 16px;
        text-align: left;
      }
    }
    .item-button-main-text {
      font-size: 16px;
    }
    .am-list-line .am-input-control input::-webkit-input-placeholder {
      font-size: unset;
    }
    .am-input-control {
      input {
        font-size: 16px;
      }
    }
    .text-field {
      display: block;
      width: 100%;
      padding: 8px 12px;
      height: 60px;
      min-height: 60px;
      padding-right: 20px;
      padding-left: 20px;
      border-style: solid;
      border-radius: 8px;
      font-family: 'Fontsfree net sfprodisplay', sans-serif;
      font-size: 16px;
      font-weight: 400;
      box-sizing: border-box;
    }
    .am-list-line {
      height: 100%;
    }
    .am-input-error-extra {
      display: none;
    }
    .am-list-item {
      .am-list-line {
        padding-right: 0;
      }
    }
    .close-outlined {
      width: 30px;
    }
    .am-input-extra {
      margin-right: 10px;
    }
    .birthday-title,
    .date-pick-msg,
    .bm-c-sign-up-notice-user-content,
    .gender-name,
    .password-check {
      font-size: 16px;
    }
    .am-list-item:not(:last-child) .am-list-line {
      border-bottom: none;
    }
    .bm-c-sign-up-mobile-gender.text-field {
      display: flex;
    }
    @media screen and (max-width: 991px) {
      .other-signin {
        .text-block-5---subtitle {
          font-size: 14px;
        }
      }
      .item-button-main-text {
        font-size: 14PX;
      }
    }
    @media screen and (max-width: 767px) {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      height: 95vh;
      padding-bottom: 20px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      background-image: none;
      background-size: auto;
      .am-input-control {
        input {
          font-size: 14px;
        }
      }
      .div-block-520 {
        width: 100%;
        height: 100%;
        min-height: 100%;
        min-width: 100%;
        border-radius: 0px;
        background-color: var(--bm-light-background-color);
      }
      .other-signin {
        .text-block-5---subtitle {
          margin-bottom: 0px;
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
    @media screen and (max-width: 479px) {
      height: 95vh;
      padding-bottom: 0px;
      .div-block-520 {
        width: auto;
      }
      .other-signin {
        .text-block-5---subtitle {
          font-size: 12px;
          line-height: 16px;
        }
      }
      .text-field {
        height: 50px;
        min-height: 50px;
      }
      .am-button {
        height: 50px;
        line-height: 50px;
      }
      .forgot {
        font-size: 14px;
      }
      .birthday-title,
      .date-pick-msg,
      .bm-c-sign-up-notice-user-content,
      .gender-name,
      .password-check {
        font-size: 14px;
      }
    }
  }
`;
