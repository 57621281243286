import './PayCardModify.less';
import { IPayCard, IGiftCard } from '@/actions/pay-action';

export const prefix = 'bm-c-pay-card';

export { default } from './PayCardModify';

export declare type InternalNamePath = (string | number)[];
export declare type NamePath = string | number | InternalNamePath;

export interface IPayCardInstance {
  save: () => Promise<IGiftCard | IPayCard | undefined>;
}

export const initPayCard = (): IPayCardInstance => ({
  save: () => Promise.resolve(undefined),
});

export const usePayCardModify = (): [IPayCardInstance] => {
  const instance: IPayCardInstance = initPayCard();
  return [instance];
}
