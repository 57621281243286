import {
  IPayOrderParam, IPayOrderResult,
} from '.';
import {
  UseCreditCardPay,
  UseApplePay,
  UseAlipay,
  UseStoreCreditPay,
  UseTapGoPay,
  UseWechatMiniProgramPay,
  UseCreditCardPay3DS,
  UseWechatMWebPay,
  UseCashPay,
  UseDeferredPay,
  UseAppleNativePay,
  UseWechatOfficialAccountPay,
  UseGooglePay,
} from './PayAction';
import PayTypes from './PayTypes';
import { IPayMethod, ICreditCardMethod, IMasterMethod, IVisaMethod, IAmexMethod, IUnionPayMethod } from '.';
import { IObject } from '@/components/bm-common';
import { handle3DSMethod } from './Utils';
import { getDeviceInfo } from '@/utils/app';

const usePayment = (params: IPayOrderParam) => {

  const {
    storeID,
    orderNumber,
    payData,
  } = params;

  /**
  * 调起支付
  * 存在混合支付的情况, 区别构建对待
  */
  const runPayOrder = async (): Promise<IPayOrderResult> => {
    // 生成支付方式数组 && 对应支付数据
    const paymentTypes: PayTypes[] = [];
    const payDataObj: IObject = {};

    payData.forEach((item: IPayMethod) => {
      paymentTypes.push(item.type);
      payDataObj[item.type] = item;
    })

    let tempPayment;
    // 混合支付 step1. 取出gift card 支付信息
    if (paymentTypes.length > 1) {
      const {
        getStoreCreditPayment,
      } = UseStoreCreditPay({ storeID, orderNumber, payDataObj, paymentTypes });

      const {
        storeCreditPayment,
      } = getStoreCreditPayment();

      tempPayment = storeCreditPayment;
    }

    const newParams = {
      ...params,
      payment: tempPayment,
    }

    if (
      (paymentTypes.includes(PayTypes.STORE_CREDIT) ||
      paymentTypes.includes(PayTypes.ANONYMOUS_GIFT_CARD)) &&
      paymentTypes.length === 1
    ) {
      const {
        storeCreditPay,
      } = UseStoreCreditPay({storeID, orderNumber, payDataObj, paymentTypes});
      const resp = await storeCreditPay();
      return resp;
    }


    /**
     * visa master amex 其实都是credit card
     */
    if (paymentTypes.includes(PayTypes.CREDIT_CARD)) {

      const payCreditCardData: ICreditCardMethod = payDataObj[PayTypes.CREDIT_CARD];

      const use3DS = handle3DSMethod(payCreditCardData);
      // 使用3ds支付
      if (use3DS) {
        const {
          creditCardPay,
        } = UseCreditCardPay3DS({
          ...newParams,
          payData: payCreditCardData,
        });

        const resp = await creditCardPay();
        return resp;
      }

      const {
        creditCardPay,
      } = UseCreditCardPay({
        ...newParams,
        payData: payCreditCardData,
      });

      const resp = await creditCardPay();
      return resp;
    }

    if (paymentTypes.includes(PayTypes.UNION_PAY)) {

      const payCreditCardData: IUnionPayMethod = payDataObj[PayTypes.UNION_PAY];

      const use3DS = handle3DSMethod(payCreditCardData);
      // 使用3ds支付
      if (use3DS) {
        const {
          creditCardPay,
        } = UseCreditCardPay3DS({
          ...newParams,
          payData: payCreditCardData,
        });

        const resp = await creditCardPay();
        return resp;
      }

      const {
        creditCardPay,
      } = UseCreditCardPay({
        ...newParams,
        payData: payCreditCardData,
      });

      const resp = await creditCardPay();
      return resp;
    }

    if (paymentTypes.includes(PayTypes.MASTER)) {

      const payCreditCardData: IMasterMethod = payDataObj[PayTypes.MASTER];

      const use3DS = handle3DSMethod(payCreditCardData);
      // 使用3ds支付
      if (use3DS) {
        const {
          creditCardPay,
        } = UseCreditCardPay3DS({
          ...newParams,
          payData: payCreditCardData,
        });

        const resp = await creditCardPay();
        return resp;
      }

      const {
        creditCardPay,
      } = UseCreditCardPay({
        ...newParams,
        payData: payCreditCardData,
      });

      const resp = await creditCardPay();
      return resp;
    }

    if (paymentTypes.includes(PayTypes.AMEX)) {

      const payCreditCardData: IAmexMethod = payDataObj[PayTypes.AMEX];

      const use3DS = handle3DSMethod(payCreditCardData);
      // 使用3ds支付
      if (use3DS) {
        const {
          creditCardPay,
        } = UseCreditCardPay3DS({
          ...newParams,
          payData: payCreditCardData,
        });

        const resp = await creditCardPay();
        return resp;
      }

      const {
        creditCardPay,
      } = UseCreditCardPay({
        ...newParams,
        payData: payCreditCardData,
      });

      const resp = await creditCardPay();
      return resp;
    }

    if (paymentTypes.includes(PayTypes.VISA)) {

      const payCreditCardData: IVisaMethod = payDataObj[PayTypes.VISA];

      const use3DS = handle3DSMethod(payCreditCardData);
      // 使用3ds支付
      if (use3DS) {
        const {
          creditCardPay,
        } = UseCreditCardPay3DS({
          ...newParams,
          payData: payCreditCardData,
        });

        const resp = await creditCardPay();
        return resp;
      }

      const {
        creditCardPay,
      } = UseCreditCardPay({
        ...newParams,
        payData: payCreditCardData,
      });

      const resp = await creditCardPay();
      return resp;
    }

    if (paymentTypes.includes(PayTypes.APPLE_PAY)) {

      const payApplePayData = payDataObj[PayTypes.APPLE_PAY];

      const { isAppleApp } = payApplePayData.deviceInfo || {};

      if (isAppleApp) {
        const { appleNativePay } = UseAppleNativePay({
          ...newParams,
          payData: payApplePayData,
        });
        const resp = await appleNativePay();
        return resp;
      } else {
        const {
          applePay,
        } = UseApplePay({
          ...newParams,
          payData: payApplePayData,
        });
        const resp = await applePay();
        return resp;
      }
    }

    if (
      paymentTypes.includes(PayTypes.ALIPAY_HK) ||
      paymentTypes.includes(PayTypes.ALIPAY)
    ) {

      const payAliayData = payDataObj[PayTypes.ALIPAY_HK] || payDataObj[PayTypes.ALIPAY];

      const {
        aliPay,
      } = UseAlipay({
        ...newParams,
        payData: payAliayData,
        paymentTypes,
      });
      const resp = await aliPay();
      return resp;
    }

    if (paymentTypes.includes(PayTypes.TAP_GO)) {

      const payTapGoPayData = payDataObj[PayTypes.TAP_GO];

      const {
        tapGoPay,
      } = UseTapGoPay({
        ...newParams,
        payData: payTapGoPayData,
      });
      const resp = await tapGoPay();
      return resp;
    }

    if (paymentTypes.includes(PayTypes.WECHAT_PAY)) {

      const payWechatPayData = payDataObj[PayTypes.WECHAT_PAY];

      const deviceInfo = getDeviceInfo();
      let finalWechatPay = async (): Promise<{status: number} | undefined> => {
        return;
      };

      if ((deviceInfo.inPhone || deviceInfo.inPad) && !deviceInfo.weChatMiniProgram) {
        if(deviceInfo.isWeChat) {
          const {
            wechatPay,
          } = UseWechatOfficialAccountPay({
            ...newParams,
            payData: payWechatPayData,
          });
          finalWechatPay = wechatPay;
        } else {
          const {
            wechatPay,
          } = UseWechatMWebPay({
            ...newParams,
            payData: payWechatPayData,
          });
          finalWechatPay = wechatPay;
        }
      } else if (deviceInfo.weChatMiniProgram) {
        const {
          wechatPay,
        } = UseWechatMiniProgramPay({
          ...newParams,
          payData: payWechatPayData,
        });
        finalWechatPay = wechatPay;
      }

      const resp = await finalWechatPay();
      return resp;
    }

    if (paymentTypes.includes(PayTypes.CASH_ON_DELIVERY)) {

      const payCashPayData = payDataObj[PayTypes.CASH_ON_DELIVERY];

      const {
        cashPay,
      } = UseCashPay({
        ...newParams,
        payData: payCashPayData,
      });
      const resp =  await cashPay();
      return resp;
    }

    if (paymentTypes.includes(PayTypes.DEFERRED_BILLING)) {

      const payCashPayData = payDataObj[PayTypes.DEFERRED_BILLING];

      const {
        deferredPay,
      } = UseDeferredPay({
        ...newParams,
        payData: payCashPayData,
      });
      const resp =  await deferredPay();
      return resp;
    };

    if (paymentTypes.includes(PayTypes.GOOGLE_PAY)) {

      const payGooglePayData = payDataObj[PayTypes.GOOGLE_PAY];

      const {
        googlePay,
      } = UseGooglePay({
        ...newParams,
        payData: payGooglePayData,
      });
      const resp =  await googlePay();
      return resp;
    };
  };

  return {
    runPayOrder,
  }
}

export default usePayment;
