import { IRoute } from '@/routes';
import paths from '@/routes/paths';
import BrandListPage from './BrandListPage';
import BrandsPage from './BrandsPage';

export const gatewayRoutes: IRoute[] = [
  {
    path: paths.BRAND_LIST,
    component: BrandListPage,
  },
  {
    path: paths.BRANDS,
    component: BrandsPage,
  },
];

export default gatewayRoutes;
