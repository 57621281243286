import { IRoute } from '@/routes';
import paths from '@/routes/paths';
import BookingDetailPage from './BookingDetailPage';
import BookingPage from './BookingPage';
import BookingsPage from './BookingsPage'

export const mobileRoutes: IRoute[] = [
  {
    path: paths.BOOKING_NEW,
    component: BookingPage,
  },
  {
    path: paths.BOOKINGS,
    component: BookingsPage,
  },
  {
    path: paths.BOOKING_DETAIL,
    component: BookingDetailPage,
  }
];

export default mobileRoutes;
