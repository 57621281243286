import styled from 'styled-components';
export const MyIDPageStyled = styled.div`
* {
  box-sizing: border-box;
}
height: 100%;
section {
  display: block;
  margin-top: auto;
  margin-bottom: 0px;
  padding: 60px 40px 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.section.cms {
  position: relative;
  display: block;
  padding: 0px;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  padding-bottom: 200px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  margin-bottom: -200px;
}
.container {
  position: relative;
  display: block;
  overflow: visible;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
}
.div-block-606 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 80%;
  height: auto;
  margin-right: auto;
  margin-left: auto;
  padding: 21px 60px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.div-block-609 {
  padding-top: 10px;
  padding-bottom: 10px;
  color: var(--bm-text-title-color);
  text-decoration: none;
}
.text-block-91 {
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: var(--bm-text-color);
  font-size: 18px;
  text-align: center;
}
.w-inline-block {
  max-width: 100%;
  display: inline-block;
}
.my-id-cart {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: auto;
  height: 350px;
  margin-bottom: 60px;
  padding: 25px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.92);
  box-shadow: 8px 8px 13px 0 #bebebe;
}
.div-block-608 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  padding: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
}
.h2 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: 'Sf pro display', sans-serif;
  font-size: 24px;
  font-weight: 600;
}
.h2.black-1 {
  margin-bottom: 0px;
  color: var(--bm-text-title-color);
}
.w-inline-block {
  max-width: 100%;
  display: inline-block;
}
.div-block-468-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  text-align: -webkit-center;
}
.div-block-607 {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 120px;
  margin-top: auto;
  margin-right: -25px;
  margin-left: 0px;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  background-color: var(--bm-primary-color);
}
.dp_big {
  width: 70px;
  height: 70px;
  margin-right: 20px;
  border-radius: 100px;
  background-size: cover;
}
.div-block-465 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
}
.div_block200 {
  margin-bottom: 5px;
  font-family: 'Sf pro display', sans-serif;
  color: var(--bm-shadow-color-t50);
  font-size: 18px;
  font-weight: 600;
}
.profile_birthday {
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: var(--bm-text-title-color);
  font-size: 16px;
  font-weight: 400;
}
.my-id-qr-wrapper {
  position: absolute;
  left: auto;
  top: -141%;
  right: 25px;
  bottom: auto;
  display: block;
  margin-right: auto;
  margin-left: auto;
  padding: 13px;
  border-style: solid;
  border-width: 2px;
  border-color: var(--bm-text-disabled-color);
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0);
}
.my-id-qr-code {
  width: 230px;
  height: 230px;
  margin-right: 0px;
  margin-left: 0px;
  background-color: var(--bm-light-background-color);
  background-position: 50% 50%;
  background-size: 90%;
  background-repeat: no-repeat;
}
.bold-text-6 {
  color: var(--bm-text-title-color);
  margin-left: 5px;
}
.text-block-91 {
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  font-size: 18px;
  text-align: center;
}
.text-block-91.bold {
  color: var(--bm-primary-color);
  font-weight: 700;
}
.default-avatar-outlined {
  color: var(--bm-light-background-color);
}

@media screen and (max-width: 991px) {
  .section {
    margin-top: 0px;
    padding: 40px 35px 30px;
  }
  .section.cms {
    width: auto;
    min-width: auto;
  }
  .w-container {
    max-width: 728px;
  }
  .div-block-606 {
    width: auto;
  }
}

@media screen and (max-width: 767px) {
  .section {
    padding: 83px 25px 20px;
  }
  .section.cms {
    margin-right: 0px;
    padding-right: 30px;
    padding-left: 30px;
  }
  .div-block-606 {
    padding-right: 0px;
    padding-left: 0px;
  }
  .div-block-606 {
    width: auto;
  }
  .div-block-608 {
    margin-bottom: 20px;
    padding: 20px 7px;
    background-color: var(--bm-light-background-color);
  }
  .my-id-cart {
    height: auto;
    margin-bottom: 40px;
    padding: 30px 17px;
    border-radius: 6px;
    box-shadow: none;
  }
  .div-block-607 {
    position: static;
    width: auto;
    height: auto;
    margin-right: 0px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    background-color: var(--bm-light-background-color);
  }
  .div-block-468-copy {
    margin-bottom: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .h2 {
    font-size: 20px;
  }
  .h2.black-1 {
    font-size: 20px;
  }
  .my-id-qr-code {
    width: 250px;
    height: 250px;
  }
  .my-id-qr-wrapper {
    position: static;
    top: -91%;
    padding: 5px;
    border-radius: 8px;
  }
  .dp_big.my-id {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
  }
  .div-block-465.my-id {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

}

@media screen and (max-width: 479px) {
  .section {
    padding: 68px 12px 15px;
  }
  .section.cms {
    padding-right: 0px;
    padding-left: 0px;
  }
  .w-container {
    max-width: none;
  }
  .container {
    margin-top: 0px;
    margin-bottom: 0px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .div-block-606 {
    padding-right: 13px;
    padding-left: 13px;
  }
  .div-block-608 {
    margin-bottom: 10px;
    padding: 10px;
  }
  .my-id-cart {
    margin-bottom: 20px;
    padding: 20px 10px;
  }
  .text-block-91 {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    font-size: 16px;
  }
  .text-block-91.streched {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }
  .h2 {
    font-size: 18px;
  }
  .h2.black-1 {
    margin-bottom: 0px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
  .dp_big {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
  .dp_big.my-id {
    width: 80px;
    height: 80px;
    min-height: 80px;
    min-width: 80px;
    margin-bottom: 5px;
  }
  .div_block200 {
    margin-bottom: 0px;
    font-size: 16px;
  }
  .profile_birthday {
    font-size: 14px;
  }
  .my-id-qr-wrapper {
    padding: 6px;
  }
  .my-id-qr-code {
    width: 200px;
    height: 200px;
  }
  .text-block-91 {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    font-size: 16px;
  }
}


`;
