import mobileRoutes from './mobile';
// import invoiceRoutes from './invoice'
import pagesRoutes from '@/pages';

export interface IRoute {
  path: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: any;
  name?: string;
  exact?: boolean;
}

const routes: IRoute[] = [
  // ...invoiceRoutes,
  ...mobileRoutes,
  ...pagesRoutes,
  // {
  //   path: '(.*)',
  //   load: () => import('./not-found'),
  // },
];

export default routes;
