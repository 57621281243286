import { RouteComponentProps } from 'react-router-dom';
import withRoute from '@/pages/withRoute';
import OrderDetail from './OrderDetail';
import './OrderDetail.less';

export const prefix = 'bm-p-order';

const RouteComponent: React.FC<RouteComponentProps> = props => withRoute({
  pageComponent: OrderDetail,
  routeProps: props,
  pageTitleI18nKey: '',
});

export default RouteComponent;
