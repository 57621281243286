
import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import Icon, { IconType } from '@/components/Icon';
// import { checkImgExists } from '@/utils';
import { IObject } from '@/components/bm-common';
import { getAppTheme, setScreenColor, getIntl } from '@/components/App/App';
import paths from '@/routes/paths';
import Layout from '@/components/Layout/Layout';
import { prefix } from '.';
import BmTabBar from '@/components/BmTabBar';
import ActionType from '@/actions/action-type';
import AccountOption from '@/actions/account-option';
import { getI18nText, toWxMiniProgram, stringToBase64QrCode, getAccessToken, langFormat } from '@/utils/app';
import Constants from '@/constants';
import { TransitionKeys } from '@/components/Transition';
import TabsOption from '@/actions/tabs-option';
// import { IPlatformStoreMembership } from '@/actions/store-action';
import { ICustomerGrowth, ILoyaltyStamp } from '@/actions/user-action';
import SideBar from '@/components/SideBar';
import QRCodeScan from '@/components/QRCodeScan';
import { earnPoints } from '@/data/Point';
import { parseScanUrl, getDomain } from '@/utils';
import Modal from 'antd-mobile/es/modal';
import { goToRoute } from '@/utils/route-util';
import Config from '@/Config';
import Skeleton from './Skeleton';
import MemberIconContent from '@/actions/member-icon-content';
import { formatCurrency } from '@/components/Payment';
import Badge from 'antd-mobile/es/badge';
import dayjs from 'dayjs';
import BigNumber from 'bignumber.js';


export interface IMembershipPageProps extends IBasePageProps { }
export interface IMembershipPageState {
  showQRCode: boolean;
  memberImage: string;
  loyaltyStamp: ILoyaltyStamp | undefined;
  currencyCode: string;
  showSideBar: boolean;
  membershipData: ICustomerGrowth | undefined;
  showQRCodeScan: boolean;
  updateLoyaltyStamp: boolean;
}

class MembershipPage extends BasePage<IMembershipPageProps, IMembershipPageState> {
  routeParams: IObject = {};

  state: IMembershipPageState = {
    showQRCode: false,
    memberImage: `${Config.cdnUrl}/images/default-member.jpg`,
    loyaltyStamp: undefined,
    currencyCode: 'HKD',
    showSideBar: false,
    membershipData: undefined,
    showQRCodeScan: false,
    updateLoyaltyStamp: false,
  }

  componentDidMount() {
    super.componentDidMount();
    const { dispatch, reducersState } = this.props;
    const { platformConfig } = reducersState.app;
    const { storeSlug } = platformConfig;
    const appTheme = getAppTheme();
    if (appTheme.primaryColor && platformConfig.colorInversion) {
      setScreenColor(appTheme.primaryColor, '#E3E7F1');
    } else {
      setScreenColor('#E3E7F1');
    }
    if (getAccessToken()) {
      dispatch({
        type: ActionType.START_QUERY_CUSTOMER_GROWTH_TASK_SYNC,
      });
    }

    (window as any).scanResult = (scanStr: string) => {
      this.handleScan(scanStr);
    }

    (window as any).processScannedCode = (scanStr: string) => {
      this.handleScan(scanStr);
    }

    dispatch({
      type: ActionType.QUERY_STORE,
      storeSlug,
      callback: store => {
        if (store) {
          this.setState({
            currencyCode: store.currencyCode,
          });
        }
      }
    })
  }

  componentWillUnmount() {
    delete (window as any).scanResult;
    delete (window as any).processScannedCode;
    super.componentWillUnmount();
    const { dispatch } = this.props;
    dispatch({
      type: ActionType.CANCEL_QUERY_CUSTOMER_GROWTH_TASK_SYNC,
    });
  }

  handleScan = (value = '') => {
    const { history } = this.props;
    const info = parseScanUrl(value);
    if (value.startsWith('http') && !info.searchParams.order_number) {
      if (info.isSameEvn && info.isSamePlatform && info.platformRoute && info.pathname !== window.location.pathname && history && !info.search) {
        goToRoute({
          history,
          pathname: info.platformRoute,
        });
      } else if (info.isSameEvn && info.isSamePlatform && info.platformRoute && info.pathname === window.location.pathname) {
        this.handleScanSuccess(value);
      } else {
        window.location.href = value;
      }
    } else {
      this.handleScanSuccess(value);
    }
  }

  loadMembership = () => {
    const { dispatch, reducersState } = this.props;
    const { platformConfig } = reducersState.app;
    const { storeSlug, storeID, closeRegisterEmail } = platformConfig;
    const { updateLoyaltyStamp } = this.state;

    dispatch({
      type: ActionType.QUERY_CUSTOMER,
      storeSlug,
      closeRegisterEmail,
      callback: customer => {
        if (customer) {
          dispatch({
            type: ActionType.QUERY_CUSTOMER_GROWTH,
            storeSlug,
            storeID,
            customerID: customer.id,
            updateLoyaltyStamp,
            callback: membershipData => {
              console.info('membershipData', membershipData);
            }
          });
        }
      }
    });
  }

  SignIn = () => {
    const { reducersState } = this.props;
    const { deviceInfo } = reducersState.app;
    if (deviceInfo.weChatMiniProgram) {
      toWxMiniProgram({
        miniProgramPath: '/pages/authorization/authorization',
        data: {
          wxPageType: 'ACCOUNT',
        },
      });
    } else {
      this.handleGotoRoute({
        path: paths.SIGN_IN,
        animationType: TransitionKeys.FADE_FROM_BOTTOM,
      })
    }
  }

  SignUp = () => {
    const { reducersState } = this.props;
    const { deviceInfo } = reducersState.app;
    if (deviceInfo.weChatMiniProgram) {
      toWxMiniProgram({
        miniProgramPath: '/pages/authorization/authorization',
        data: {
          wxPageType: 'ACCOUNT',
        },
      });
    } else {
      this.handleGotoRoute({
        path: paths.SIGN_UP,
        animationType: TransitionKeys.FADE_FROM_BOTTOM,
      })
    }
  }

  signOut = () => {
    const { dispatch } = this.props;
    dispatch({
      type: ActionType.CLEAR_USER_INFO,
    });
    dispatch({
      type: ActionType.CLEAR_CUSTOMER,
    });
    dispatch({
      type: ActionType.CLEAR_IS_GUEST,
    });
    dispatch({
      type: ActionType.CLEAR_QUEUEING,
    });
    dispatch({
      type: ActionType.CLEAR_BOOKING,
    });
    dispatch({
      type: ActionType.CLEAR_CUSTOMER_GROWTH,
    });
    localStorage.removeItem(Constants.LANGUAGE);
  }
  handleGoAccountEdit = () => {
    const token = getAccessToken();
    if (!token) {
      this.SignIn();
      return;
    }

    this.handleGotoRoute({
      path: paths.ACCOUNT_EDIT_BY_FROM,
      pathParams: {
        fromPage: 'membership',
      },
    });
  }
  handleItemClick = (pathname: string) => {
    const token = getAccessToken();
    if (!token && pathname !== paths.SETTING && pathname !== paths.NEWS && pathname !== paths.ABOUT_US) {
      this.SignIn();
      return;
    }
    this.handleGotoRoute({ path: pathname });
  }

  handleGotoAboutUs = () => {
    const { reducersState, dispatch } = this.props;
    const { platformConfig } = reducersState.app;
    this.openLoading()
    dispatch({
      type: ActionType.QUERY_BLOG_LIST,
      contentType: 'COMPANY_INFO',
      storeSlug: platformConfig.storeSlug,
      callback: blogs => {
        if (blogs.length === 1) {
          this.handleGotoRoute({
            path: paths.BLOG_DETAIL,
            pathParams: {
              storeSlug: platformConfig.storeSlug,
              blogID: blogs[0].id,
              contentType: 'about-us',
            }
          })
        } else {
          this.handleGotoRoute({ path: paths.ABOUT_US });
        }
        dispatch({
          type: ActionType.LOADING,
          showLoading: false,
        })
      }
    })
  }

  handleChange = () => {
    this.setState({
      showQRCode: !this.state.showQRCode,
    })
  }

  onOpenChange = () => {
    const { reducersState } = this.props;
    const { platformConfig } = reducersState.app;
    if (platformConfig.showSideBar) {
      this.setState({
        showSideBar: !this.state.showSideBar,
      });
    }
  }

  handleShowSideBar = () => {
    this.setState({
      showSideBar: !this.state.showSideBar,
    });
  }

  findAppTabsIcon = (key: string) => {
    const { reducersState } = this.props;
    const { appIcons } = reducersState.app;
    if (appIcons && appIcons.length > 0) {
      const appIcon = appIcons.find(item => item.iconType === key);
      return appIcon;
    }
  }

  handleScanClick = () => {
    const { reducersState } = this.props;
    const { deviceInfo } = reducersState.app;

    const bindoUtils: any = (window as any).bindo_utils;
    if (deviceInfo.isAndroid && bindoUtils && bindoUtils.openCameraScan) {
      bindoUtils.openCameraScan();
    } else if (deviceInfo.isAppleApp && (window as any).webkit && (window as any).webkit.messageHandlers && (window as any).webkit.messageHandlers) {
      (window as any).webkit.messageHandlers.openCodeScanner.postMessage('openCodeScanner');
    } else {
      this.setState({ showQRCodeScan: true });
    }
  }

  handleScanSuccess = async(value: string) => {
    const parseResult = parseScanUrl(value);
    const { reducersState } = this.props;
    if (parseResult.searchParams && parseResult.searchParams.order_number) {
      const token = getAccessToken();
      if (!token) {
        this.setState({ showQRCodeScan: false });
        Modal.alert(getIntl().common.tips, getIntl().page.signInTips, [
          {
            text: getIntl().page.ok,
            onPress: () => {
            },
          }
        ]);
        return;
      }
      const { slugRefCustomerGrowth } = reducersState.user;
      const { platformConfig } = reducersState.app;
      const { storeSlug } = platformConfig;
      const membershipData = slugRefCustomerGrowth[storeSlug];
      const { customerID } = membershipData || {};

      const state = await earnPoints(customerID, parseResult.searchParams.order_number);
      if (state && state.success) {
        this.setState({
          showQRCodeScan: false,
          updateLoyaltyStamp: true,
        }, () => {
          this.loadMembership();
          Modal.alert('', getIntl().page.earnPointsSuccess, [
            {
              text: getIntl().page.ok,
              onPress: () => {
              },
            }
          ]);
        });

      } else {
        this.setState({ showQRCodeScan: false });
        Modal.alert(getIntl().common.tips, getIntl().page.earnPointsFail, [
          {
            text: getIntl().page.ok,
            onPress: () => {
            },
          }
        ]);
      }
    }
  }

  handleScanFail = () => {
    this.setState({ showQRCodeScan: false });
  }

  render() {
    const {  showQRCode, showQRCodeScan, currencyCode, memberImage } = this.state;
    const { reducersState, history } = this.props;
    const { platformConfig, deviceInfo } = reducersState.app;
    const { accountOptions, showSideBar, storeSlug, birthdayHiddenDayEnable, platformLogoSvg, memberIconStyle, abbreviation, birthdayHiddenYearEnable } = platformConfig;
    const stringUserInfo = localStorage.getItem(Constants.USER_INFO);
    let userInfo = null;
    let dateOfBirth = '';
    if (stringUserInfo) {
      userInfo = JSON.parse(stringUserInfo);
    }
    dateOfBirth = userInfo ? userInfo.dateOfBirth : '';
    if (dateOfBirth && birthdayHiddenDayEnable) {
      dateOfBirth = dayjs(dateOfBirth).format('YYYY-MM');
    }
    if (dateOfBirth && birthdayHiddenYearEnable) {
      dateOfBirth = reducersState.app.language === 'zh-CN' || reducersState.app.language === 'zh-HK' ? `${dayjs(dateOfBirth).format('M')}月` : dayjs(dateOfBirth).format('MMM');
    }
    const token = getAccessToken();
    const { slugRefCustomerGrowth } = reducersState.user;
    const membershipData = slugRefCustomerGrowth[storeSlug];
    const { customerID, customerCode } = membershipData || {};
    const currentLevelText = membershipData && membershipData.currentMembershipLevel.i18n && (getI18nText(membershipData.currentMembershipLevel, 'title', reducersState.app.language) || membershipData.currentMembershipLevel.title);
    const { pathname } = history.location;
    const { platform } = getDomain();

    return (
      <>
        <Layout
          {...this.props}
          className={`${prefix} ${platformConfig.colorInversion ? 'membership-primary' : ''} `}
          navContent={getIntl().page.membersTitle}
          navIcon={showSideBar && <Icon className="side-bar-icon" type={IconType.MENUS_OUTLINED} />}
          onLeftClick={() => { this.onOpenChange(); }}
          navRightContent={
            <>
              <div className="nav-right" onClick={this.handleScanClick} >
                <Icon className="html-embed-7 w-embed" type={IconType.SCAN_OUTLINED} />
              </div>
            </>
          }
        >
          <div className="section membershipSection">
            <div className="container w-container">
              {
                !membershipData && token &&
            <Skeleton />
              }
              {
                !token &&
                <>
                  <div className="tablet-view-profile">
                    <div className="profile-block-not-member">
                      <div className="div-block-468 not-login-yet">
                        <div className="dp_big not-login-yet">
                          <Icon
                            type={IconType.DEFAULT_AVATAR_OUTLINED}
                            className="avatar-icon"
                          />
                        </div>
                        <div className="div-block-465">
                          <div className="div-block-465 not-yet-login">
                            <div className="div_block200" onClick={this.SignIn}>{getIntl().page.signin}</div>
                            <div className="div-block-513"></div>
                            <div className="div_block200" onClick={this.SignUp}>{getIntl().page.register}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              }
              {
                token && userInfo &&
                <>
                  <div className="tablet-view-profile">
                    <div className="profile-block-tablet-view">
                      <div className="div-block-468" onClick={this.handleGoAccountEdit}>
                        <div className="dp_big">
                          {
                            userInfo.avatarUrl &&
                            <div className="avatar" style={{ backgroundImage: `url(${userInfo.avatarUrl})` }} />
                          }
                          {
                            !userInfo.avatarUrl &&
                            <Icon
                              type={IconType.DEFAULT_AVATAR_OUTLINED}
                              className="avatar-icon"
                            />
                          }
                        </div>
                        <div className="div-block-465">
                          <div className="profile_name">{userInfo.name}</div>
                          {
                            userInfo.dateOfBirth &&
                              <div className="profile_birthday">
                                {langFormat(getIntl().page.dateOfBirth, { date: dateOfBirth })}
                              </div>
                          }
                        </div>
                      </div>
                      <div className="div-block-479" onClick={this.handleChange}>
                        {
                          !showQRCode && <Icon className="html-embed-29 w-embed" type={IconType.QR_CODE_OUTLINED}/>
                        }
                        {
                          showQRCode && <Icon className="html-embed-33 w-embed" type={IconType.MEMBER_CARD_OUTLINED}/>
                        }
                      </div>
                    </div>
                  </div>

                  <div className="top_profile-part">
                    <div className="card-name-sm">
                      <div className="card-name" style={{ color: membershipData && membershipData.currentMembershipLevel ? membershipData.currentMembershipLevel.membershipLevelColor : '#939394' }}>
                        <Icon
                          type={IconType.MEMBER_TAG_OUTLINED}
                          className="member-tag"
                        />
                        {currentLevelText}
                      </div>
                      <div className="accumulated-spending">
                        {langFormat(getIntl().page.accumulatedSpendings, { amount: formatCurrency(Number(membershipData && membershipData.accumulatedSpending) || 0, currencyCode) })}
                      </div>
                    </div>
                    <div className="div-block-469">
                      <div className="profile-block">
                        <div className="div-block-468" onClick={this.handleGoAccountEdit}>
                          <div className="dp_big">
                            {
                              userInfo.avatarUrl &&
                            <div className="avatar" style={{ backgroundImage: `url(${userInfo.avatarUrl})` }} />
                            }
                            {
                              !userInfo.avatarUrl &&
                            <Icon
                              type={IconType.DEFAULT_AVATAR_OUTLINED}
                              className="avatar-icon"
                            />
                            }
                          </div>
                          <div className="div-block-465">
                            <div className="profile_name">{userInfo.name}</div>
                            {
                              userInfo.dateOfBirth &&
                              <div className="profile_birthday">
                                {langFormat(getIntl().page.dateOfBirth, { date: dateOfBirth })}
                              </div>
                            }
                          </div>
                        </div>
                        <div className="div-block-479" onClick={this.handleChange}>
                          {
                            !showQRCode && <Icon className="html-embed-29 w-embed" type={IconType.QR_CODE_OUTLINED}/>
                          }
                          {
                            showQRCode && <Icon className="html-embed-33 w-embed" type={IconType.MEMBER_CARD_OUTLINED}/>
                          }
                        </div>
                      </div>
                      <div className="div-block-475">
                        <div className="div-block-474">
                          <div className="spend_now_button">
                            <div className="text-block-65" onClick={() => this.handleItemClick(paths.POINTS)}>{getIntl().page.spend}</div>
                          </div>
                          <div className="text-block-62">{getIntl().page.currentAmount}</div>
                          <div className="text-block-63">{
                            membershipData && (new BigNumber(Number(membershipData.myLoyaltyStamp)).toFormat(0) || 0)
                          }</div>
                          {
                            platformConfig.showStoreCreditBalance &&
                            <div className="loyalty-stamp-bottom">
                              <span className="loyalty-stamp-span">{(membershipData && new BigNumber(membershipData.storeCredits).toFormat()) || 0}</span> {abbreviation} {getIntl().page.dDollars}
                            </div>
                          }
                        </div>
                        {
                          membershipData && membershipData.currentMembershipLevel && membershipData.currentMembershipLevel.membershipExpire && !platformConfig.hideMemberExpiryDate &&
                          <div className="expire-on">
                            {langFormat(getIntl().page.membershipExpire, { date: dayjs(membershipData.currentMembershipLevel.membershipExpire).format(reducersState.app.language === 'zh-CN' ? 'YYYY-MM-DD' : 'D/M/YYYY') })}
                          </div>
                        }

                        {/* <div className="text_block_100">500 points will expire on 01 Oct, 2020</div> */}
                        {
                          membershipData && membershipData.memberShipLevels &&  membershipData.memberShipLevels.length > 1 && memberIconStyle === MemberIconContent.PROGRESS_BAR &&
                          <div className="div-block-472">
                            {
                              membershipData.memberShipLevels.map(item => (
                                <div className="membership-level" key={item.id}>
                                  <div className="progress-bar-wrap">
                                    <div className="progress-bar" style={{ width: item.percent }} />
                                  </div>
                                  <Icon
                                    type={IconType.MEMBER_OUTLINED}
                                    logoSvg={platformLogoSvg}
                                    className={`${item.isCurrentLevel ? 'current-icon' : 'membership-icon'}`}
                                    lightColor={item.membershipLevelLightColor}
                                    darkColor={item.membershipLevelColor}
                                    tag={item.id}
                                    key={item.id}
                                  />
                                  <div className="membership-name">
                                    {getI18nText(item, 'abbreviation', reducersState.app.language) || item.title}
                                  </div>
                                </div>
                              ))
                            }
                          </div>
                        }
                        {
                          membershipData && membershipData.nextLevel && membershipData.currentMembershipLevel && !membershipData.currentMembershipLevel.hideTheGrowthPoint &&
                          <div className="text_block_100">
                            {getIntl().page.spendMoney}
                            &nbsp;
                            <strong className="bold-text">
                              {formatCurrency(Number(membershipData && membershipData.nextLevel && membershipData.nextLevel.spend) || 0, currencyCode)}
                            </strong>
                            &nbsp;
                            {getIntl().page.upgradedTo}
                            &nbsp;
                            <span className="bold-text">
                              {getI18nText(membershipData.nextLevel, 'title', reducersState.app.language)}
                            </span>
                          </div>
                        }
                        {
                          membershipData && membershipData.isMaxLevel && membershipData.currentMembershipLevel && !membershipData.currentMembershipLevel.hideTheGrowthPoint &&
                          <div className="text_block_100">
                            {getIntl().page.highestLevelTips}
                          </div>
                        }
                      </div>
                    </div>
                    <div className={`div-block-466 ${memberIconStyle !== MemberIconContent.PROGRESS_BAR ? 'no-div-block-466': ''}`}>
                      {
                        !showQRCode && 
                        <>
                          <img  className="card_interface" onClick={this.handleChange} src={membershipData ? membershipData.currentMembershipLevel.image : memberImage} alt="" />
                          {
                            membershipData && membershipData.currentMembershipLevel &&  membershipData.currentMembershipLevel.membershipExpire && !platformConfig.hideMemberExpiryDate &&
                            <div className="expire">{getIntl().page.expiryDate}: { dayjs(membershipData.currentMembershipLevel.membershipExpire).format(reducersState.app.language === 'zh-CN' ? 'YYYY-MM-DD' : 'D/M/YYYY')} </div>
                          }
                        </>
                      }
                      {
                        showQRCode && <img  className="qr_code_profile_interface" onClick={this.handleChange} src={stringToBase64QrCode(customerCode ? `CID-${customerCode}` : `CID-${customerID}`)} alt="" />
                      }
                      <div className="card-name-lg">
                        <div className="card-name" style={{ color: membershipData && membershipData.currentMembershipLevel ? membershipData.currentMembershipLevel.membershipLevelColor : '#939394' }}>
                          <Icon
                            type={IconType.MEMBER_TAG_OUTLINED}
                            className="member-tag"
                          />
                          {currentLevelText}
                        </div>
                        <div className="accumulated-spending">
                          {langFormat(getIntl().page.accumulatedSpendings, { amount: formatCurrency(Number(membershipData && membershipData.accumulatedSpending) || 0, currencyCode) })}
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </>
              }
              <div className={`bottom_profile_part ${!token? 'no-token': ''}`}>
                {
                  !token &&
                  <div className="text-block-103">{getIntl().page.becomeMemberTip}</div>
                }
                <div className="div-block-478">
                  {
                    accountOptions.includes(AccountOption.PRIVILEGES) &&
                    <div className="option_block" onClick={() => this.handleItemClick(paths.PRIVILEGE)}>
                      <Icon className="html-embed-30 w-embed" type={IconType.PRIVILEGE_OUTLINED}/>
                      <div className="text-block-66">
                        {getIntl().page.myPrivilege}
                        {
                          getAccessToken() &&
                          <Badge text={Number(localStorage.getItem(Constants.PRIVILEGE_NUMBER))} overflowCount={99}/>
                        }
                      </div>
                      <Icon className="html-embed-31 w-embed" type={IconType.RIGHT_OUTLINED}/>
                    </div>
                  }
                  {
                    accountOptions.includes(AccountOption.BOOKING) &&
                    <div className="option_block" onClick={() => this.handleItemClick(paths.BOOKINGS)}>
                      <Icon className="html-embed-30 w-embed" type={IconType.BOOKING_SETTING}/>
                      <div className="text-block-66">{getIntl().page.myBookings}</div>
                      <Icon className="html-embed-31 w-embed" type={IconType.RIGHT_OUTLINED}/>
                    </div>
                  }
                  {
                    accountOptions.includes(AccountOption.QUEUING) &&
                    <div className="option_block" onClick={() => this.handleItemClick(paths.QUEUEINGS)}>
                      <Icon className="html-embed-30 w-embed" type={IconType.QUEUEING_SETTING}/>
                      <div className="text-block-66">{getIntl().page.myQueueings}</div>
                      <Icon className="html-embed-31 w-embed" type={IconType.RIGHT_OUTLINED}/>
                    </div>
                  }
                  {
                    accountOptions.includes(AccountOption.RECORDS) &&
                    <div className="option_block" onClick={() => this.handleItemClick(paths.RECORDS)}>
                      <Icon className="html-embed-30 w-embed" type={IconType.RECORD_OUTLINED}/>
                      <div className="text-block-66">{getIntl().page.myRecords}</div>
                      <Icon className="html-embed-31 w-embed" type={IconType.RIGHT_OUTLINED}/>
                    </div>
                  }
                  {
                    accountOptions.includes(AccountOption.FAVORITE) &&
                    <div className="option_block" onClick={() => this.handleItemClick(paths.FAVORITE)}>
                      <Icon className="html-embed-30 w-embed" type={IconType.FAVORITE_SETTING}/>
                      <div className="text-block-66">{getIntl().page.myFavorites}</div>
                      <Icon className="html-embed-31 w-embed" type={IconType.RIGHT_OUTLINED}/>
                    </div>
                  }
                  {
                    accountOptions.includes(AccountOption.ADDRESS) &&
                    <div className="option_block" onClick={() => this.handleItemClick(paths.ADDRESS_LIST)}>
                      <Icon className="html-embed-30 w-embed" type={IconType.ADDRESS_OUTLINED}/>
                      <div className="text-block-66">{getIntl().page.myAddress}</div>
                      <Icon className="html-embed-31 w-embed" type={IconType.RIGHT_OUTLINED}/>
                    </div>
                  }
                  {
                    !deviceInfo.weChatMiniProgram && accountOptions.includes(AccountOption.PAYMENT) &&
                    <div className="option_block" onClick={() => this.handleItemClick(paths.PAYMENT_LIST)}>
                      <Icon className="html-embed-30 w-embed" type={IconType.PAYMENT_OUTLINED}/>
                      <div className="text-block-66">{getIntl().page.myPayments}</div>
                      <Icon className="html-embed-31 w-embed" type={IconType.RIGHT_OUTLINED}/>
                    </div>
                  }
                  {
                    accountOptions.includes(AccountOption.SCAN_CODE) &&
                    <div className="option_block" onClick={() => this.handleScanClick()}>
                      <Icon className="html-embed-30 w-embed" type={IconType.SCAN_OUTLINED}/>
                      <div className="text-block-66">{getIntl().page.scanner}</div>
                      <Icon className="html-embed-31 w-embed" type={IconType.RIGHT_OUTLINED}/>
                    </div>
                  }
                  {
                    accountOptions.includes(AccountOption.NEWS) &&
                    <div className="option_block" onClick={() => this.handleItemClick(paths.NEWS)}>
                      <Icon className="html-embed-30 w-embed" type={IconType.NEWS_OUTLINED}/>
                      <div className="text-block-66">{getIntl().page.newsTitle}</div>
                      <Icon className="html-embed-31 w-embed" type={IconType.RIGHT_OUTLINED}/>
                    </div>
                  }
                  {
                    accountOptions.includes(AccountOption.ABOUT_US) &&
                    <div className="option_block" onClick={() => this.handleGotoAboutUs()}>
                      <Icon className="html-embed-30 w-embed" type={IconType.DOLLAR_OUTLINED}/>
                      <div className="text-block-66">{langFormat(getIntl().page.aboutTGG, { abbreviation })}</div>
                      <Icon className="html-embed-31 w-embed" type={IconType.RIGHT_OUTLINED}/>
                    </div>
                  }
                  {
                    accountOptions.includes(AccountOption.ADMIN) && userInfo && userInfo.staff === true &&
                    <div className="option_block" onClick={() => this.handleItemClick(paths.ADMIN_STORES)}>
                      <Icon className="html-embed-30 w-embed" type={IconType.BINDO_SETTING}/>
                      <div className="text-block-66">{getIntl().page.bindo}</div>
                      <Icon className="html-embed-31 w-embed" type={IconType.RIGHT_OUTLINED}/>
                    </div>
                  }
                  {
                    accountOptions.includes(AccountOption.SETTING) &&
                    <div className={`option_block ${!token? ' no-line': ''}`} onClick={() => this.handleItemClick(paths.SETTING)}>
                      <Icon className="html-embed-30 w-embed" type={IconType.SETTING2_OUTLINED}/>
                      <div className="text-block-66">{getIntl().page.setting}</div>
                      <Icon className="html-embed-31 w-embed" type={IconType.RIGHT_OUTLINED}/>
                    </div>
                  }
                  {
                    token &&
                    <div className="option_block no-line" onClick={this.signOut}>
                      <Icon className="html-embed-30 w-embed" type={IconType.LOGOUT_OUTLINED}/>
                      <div className="text-block-66">{getIntl().page.signOut}</div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </Layout>
        {
          platformConfig.showTabs && (pathname.includes(paths.MEMBERSHIP) || pathname === paths.HOME || pathname === `/p/${platform}/` || pathname === `/p/${platform}`) &&
          <BmTabBar
            selectedKey={TabsOption.MEMBERS_AREA}
            reducersState={reducersState}
          />
        }
        {
          showSideBar &&
          <SideBar
            showSideBar={this.state.showSideBar}
            onItemClick={this.handleShowSideBar}
            reducersState={reducersState}
            currentData={true}
          />
        }
        {
          showQRCodeScan &&
          <QRCodeScan
            history={history}
            onSuccess={this.handleScanSuccess}
            onFail={this.handleScanFail}
          />
        }
      </>
    );
  }
}

export default MembershipPage;
