
import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import Icon, { IconType } from '@/components/Icon';
import { IObject } from '@/components/bm-common';
import { getAppTheme, setScreenColor, getIntl } from '@/components/App/App';
import Layout from '@/components/Layout';
import { prefix } from '.';
import paths from '@/routes/paths';
import { getI18nText } from '@/utils/app';

export interface ITermsPageProps extends IBasePageProps { }
export interface ITermsPageState {
}

class TermsPage extends BasePage<ITermsPageProps, ITermsPageState> {
  routeParams: IObject = {};

  state: ITermsPageState = {
  }

  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.lightBackgroundColor) {
      setScreenColor(appTheme.lightBackgroundColor);
    }

    super.componentDidMount();
  }


  render() {
    const { reducersState } = this.props;
    const { platformAppSettings } = reducersState.app;
    const generalConditions = getI18nText(platformAppSettings, 'general_conditions', reducersState.app.language) || (platformAppSettings && platformAppSettings.generalConditions) || '';

    return (
      <Layout
        {...this.props}
        className={prefix}
        navIcon={<Icon className="back-icon" type={IconType.LEFT_OUTLINED} />}
        onLeftClick={() => this.handleGoBack({ path: paths.HOME, runGoBack: true })}
        navContent={getIntl().page.terms}
      >
        <div className="terms-condition-content" dangerouslySetInnerHTML={{
          __html: generalConditions,
        }} />
      </Layout>
    );
  }
}

export default TermsPage;
