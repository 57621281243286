import { createUuid, plus, times, minus, taxOptionToTaxOptionEntrie } from '../utils';
import Context from '../Context';
import { IListing, IModifier } from '../utils/api';
import ModifierEntity, { IModifierJson } from './ModifierEntity';
import SaleType from '../enums/SaleType';
import { IOrderInfo } from './OrderEntity';
import PurchasableType from '../enums/PurchasableType';
import ShippingMethod, { defaultShippingMethod } from '../enums/ShippingMethod';
import * as Data from './Data';
import { resizeImage } from '@/utils';
import { IObject } from '@/components/bm-common';

export interface ILineItemJson {
  id: string | null;
  uuid: string;
  shipping_method: ShippingMethod;
  department_id: string;
  favorite_id: string;
  favorite_tab_id: string;
  favorite_section_id: string;
  purchasable_id: string;
  purchasable_type: PurchasableType | null;
  label: string;
  price: number;
  brand: string;
  quantity: number;
  quantity_allow_decimal: number;
  group_id: number;
  modifier_entries: IModifierJson[];
  tax_option_entries: any[];
  tax_base_adjustment_amount: number;
  taxable_amount: number;
  tax_amount: number;
  add_on_tax_amount: number;
  inclusive_tax_amount: number;
  service_fee: number;
  discount_entries: any[];
  discount_total: number;
  subtotal: number;
  total: number;
  procurement_method: ShippingMethod[];
  kitchen_department_id: string;
  image_url: string;
  modifier_set_option_id: string;
  unit_quantity: number;
  store_id: string;
  sale_type: SaleType;
  i18n: IObject;
  product_id: string;
  category_id: string;
}

export interface ITaxOptionEntrie {
  id: string;
  name: string;
  amount: string;
  tax_rate: string;
  tax_option_id: string;
  line_item_id: string;
  primary: boolean;
  tax_type: string;
  method: string;
  threshold: string;
  priority: string;
  included_in_price: boolean;
  uuid: string;
  tax_base: string;
}

/**
 * Listing Line Item
 */
class LineItemEntity {
  constructor(context: Context) {
    if (context) {
      this.context = context;
      this.currencyCode = context.currencyCode;
    }
  }

  context = new Context();

  id: string | null = null;

  uuid: string = createUuid();

  lineItemType: 'LISTING' | 'MODIFIER' = 'LISTING';

  departmentID = '';

  favoriteID = '';

  favoriteTabID = '';

  favoriteSectionID = '';

  purchasableID = '';

  purchasableType: PurchasableType | null = null;

  name = '';

  price = 0;

  unitPrice = 0;

  quantity = 1;

  quantityAllowDecimal = 2;

  unitQuantity = 1;

  groupID: number = Math.ceil((Math.random()) * 1000000000);

  modifierEntries: ModifierEntity[] = [];

  taxOptionEntries: ITaxOptionEntrie[] = [];

  taxBaseAdjustmentAmount = 0;

  taxableAmount = 0;

  taxAmount = 0;

  addOnTaxAmount = 0;

  inclusiveTaxAmount = 0;

  serviceFee = 0;

  discountEntries: any[] = [];

  discountTotal = 0;

  subtotal = 0;

  total = 0;

  procurementMethod: ShippingMethod[] = [];

  kitchenDepartmentID = '';

  imageUrl = '';

  modifierSetOptionID = '';

  storeID = '';

  brand = '';

  saleType = '';

  currencyCode = '';

  i18n: IObject = {};

  productID = '';

  categoryID = '';

  shippingMethod = defaultShippingMethod;

  setQuantity = (quantity: number) => {
    this.quantity = quantity;
  }

  setCurrencyCode = (currencyCode: string) => {
    this.currencyCode = currencyCode;
  }

  setShippingMethod = (shippingMethod: ShippingMethod) => {
    this.shippingMethod = shippingMethod;
  }

  setDiscountEntries = (discountEntries: any[]) => {
    this.discountEntries = discountEntries;
  }

  setTaxOptionEntries = (taxOptionEntries: ITaxOptionEntrie[]) => {
    this.taxOptionEntries = taxOptionEntries;
  }

  init = () => {
    this.id = null;
    this.uuid = createUuid();
    this.departmentID = '';
    this.favoriteID = '';
    this.favoriteTabID = '';
    this.favoriteSectionID = '';
    this.purchasableID = '';
    this.purchasableType = null;
    this.name = '';
    this.price = 0;
    this.brand = '';
    this.quantity = 1;
    this.groupID = Math.ceil((Math.random()) * 1000000000);
    this.imageUrl = '';
    this.kitchenDepartmentID = '';
    this.procurementMethod = [];
    this.discountEntries = [];
    this.serviceFee = 0;
    this.modifierEntries = [];
    this.taxOptionEntries = [];
    this.modifierSetOptionID = '';
    this.i18n = {};
    this.productID = '';
  }

  initForListing = (listing: IListing) => {
    if (!listing) throw new Error('Listing is required.');

    this.init();

    this.id = listing.id;
    this.lineItemType = 'LISTING';
    this.favoriteID = listing.favoriteID;
    this.favoriteTabID = listing.favoriteTabID;
    this.favoriteSectionID = listing.favoriteSectionID;
    this.purchasableID = listing.purchasableID;
    this.purchasableType = listing.purchasableType;

    this.departmentID = listing.departmentID;
    this.name = listing.name;
    this.price = listing.price || 0;
    this.imageUrl = listing.imageUrl || '';
    this.kitchenDepartmentID = listing.kitchenDepartmentID || '';
    this.brand = listing.brand || '';
    this.storeID = listing.storeID;
    this.i18n = listing.i18n;
    this.productID = listing.productID;
    this.categoryID = listing.categoryID;

    if (Array.isArray(listing.procurementMethod)) {
      this.procurementMethod = listing.procurementMethod;
    }
  }

  initForModifier = (modifier: IModifier, lineItem: LineItemEntity) => {
    if (!modifier) throw new Error('Modifier is required.');
    if (!lineItem) throw new Error('Line Item is required.');

    this.init();

    this.id = lineItem.id;
    this.lineItemType = 'MODIFIER';
    this.groupID = lineItem.groupID;
    // this.favoriteID = lineItem.favoriteID;
    // this.favoriteTabID = lineItem.favoriteTabID;
    // this.favoriteSectionID = lineItem.favoriteSectionID;
    this.purchasableID = modifier.listingID;
    this.purchasableType = lineItem.purchasableType;

    this.departmentID = lineItem.departmentID;
    this.name = modifier.name;
    this.price = modifier.price || 0;
    this.imageUrl = '';
    this.kitchenDepartmentID = lineItem.kitchenDepartmentID || '';
    this.storeID = lineItem.storeID;
    this.productID = lineItem.productID;
    this.categoryID = lineItem.categoryID;

    this.procurementMethod = lineItem.procurementMethod;
    this.modifierSetOptionID = modifier.id;
  }

  initForOrder = (data: any, orderInfo: IOrderInfo, type: 'ORDER' | 'NEXT_ORDER') => {
    if (!data) throw new Error('LineItem is required.');
    if (!orderInfo) throw new Error('OrderInfo is required.');

    this.init();

    let modifierEntries: any[] = [];
    if (data) {
      this.id = '';
      this.storeID = data.store_id || '';
      this.name = data.label || '';
      this.imageUrl = resizeImage({
        url: data.image_url,
        type: 'HEIGHT',
        height: 396,
      }) || '';
      this.quantity = data.quantity || 0;
      if (data['original_price'] && orderInfo.from !== 2) {
        this.price = data['original_price'] || 0;
      } else {
        this.price = data.price || 0;
      }
      this.total = data.total || 0;
      this.taxAmount = Number(data.tax_amount || 0);
      this.discountTotal = data.discount_total || 0;
      this.purchasableType = data.purchasable_type || '';
      this.purchasableID = data.purchasable_id || '';
      this.departmentID = data.department_id || '';
      this.groupID = data.group_id;
      this.favoriteID = data.favorite_id || '';
      this.favoriteTabID = data.favorite_tab_id || '';
      this.favoriteSectionID = data.favorite_section_id || '';
      this.taxOptionEntries = data.tax_option_entries || [];
      this.currencyCode = orderInfo.currencyCode;
      this.lineItemType = (data.favorite_tab_id || (orderInfo.shippingMethod === ShippingMethod.QRCODE_SHOPPING && !data.modifier_set_option_id)) ? 'LISTING' : 'MODIFIER';
      this.i18n = data.translation_info;
      this.productID = data.product_id || '';
      this.categoryID = data.category_id || '';
      this.discountEntries = data.discount_entries || [];
      if (type === 'ORDER') {
        this.id = data.id || '';
      }

      if (type === 'NEXT_ORDER') {
        this.id = data.purchasable_id || '';
      }

      if (Array.isArray(data.modifier_entries)) {
        modifierEntries = data.modifier_entries;
      }
      if (this.id && this.storeID) {
        const listing = Data.getListing(this.storeID, data.purchasable_id || this.id || '');
        if (listing && !this.imageUrl) {
          this.imageUrl = listing.imageUrl;
        }
        if (listing && !data.favorite_tab_id && !data.modifier_set_option_id) {
          this.lineItemType = listing.favoriteTabID ? 'LISTING' : this.lineItemType;
          this.favoriteTabID = listing.favoriteTabID || '';
        }
      }
    }

    modifierEntries.forEach(item => {
      const modifierEntity = new ModifierEntity();
      modifierEntity.initForOrder(item, this.purchasableID);
      this.modifierEntries.push(modifierEntity);
    });

    if (
      (this.modifierEntries.length > 0 && orderInfo.shippingMethod !== ShippingMethod.QRCODE_SHOPPING) ||
      (this.modifierEntries.length > 0 && orderInfo.shippingMethod === ShippingMethod.QRCODE_SHOPPING && data.modifier_set_option_id)
    ) {
      this.modifierSetOptionID = this.modifierEntries[0].optionID;
    }
  }

  initForLineItemJson = (lineItemJson: ILineItemJson) => {
    if (!lineItemJson) throw new Error('LineItemJson is required.');

    this.init();

    let modifierEntries: IModifierJson[] = [];
    if (lineItemJson) {
      this.id = lineItemJson.id;
      this.storeID = lineItemJson.store_id || '';
      this.name = lineItemJson.label || '';
      this.imageUrl = resizeImage({
        url: lineItemJson.image_url,
        type: 'HEIGHT',
        height: 396,
      }) || '';
      this.price = lineItemJson.price || 0;
      this.brand = lineItemJson.brand || '';
      this.quantity = lineItemJson.quantity || 0;
      this.total = 0;
      this.purchasableType = lineItemJson.purchasable_type || null;
      this.purchasableID = lineItemJson.purchasable_id || '';
      this.groupID = lineItemJson.group_id;
      this.favoriteID = lineItemJson.favorite_id || '';
      this.favoriteTabID = lineItemJson.favorite_tab_id || '';
      this.departmentID = lineItemJson.department_id || '';
      this.favoriteSectionID = lineItemJson.favorite_section_id || '';
      this.procurementMethod = lineItemJson.procurement_method || [];
      this.kitchenDepartmentID = lineItemJson.kitchen_department_id || '';
      this.modifierSetOptionID = lineItemJson.modifier_set_option_id || '';
      this.i18n = lineItemJson.i18n || {};
      this.productID = lineItemJson.product_id || '';
      this.categoryID = lineItemJson.category_id || '';

      if (!lineItemJson.favorite_id) {
        this.lineItemType = 'MODIFIER';
      }

      if (Array.isArray(lineItemJson.modifier_entries)) {
        modifierEntries = lineItemJson.modifier_entries;
      }
    }

    modifierEntries.forEach(item => {
      const modifierEntity = new ModifierEntity();
      modifierEntity.initForLineItemJson(item, this);
      this.modifierEntries.push(modifierEntity);
    });
  }

  toJson = (): ILineItemJson => {
    const modifierEntries: IModifierJson[] = [];
    this.modifierEntries.forEach(item => modifierEntries.push(item.toJson()));
    const tempTaxOptionList: ITaxOptionEntrie[] = [];
    this.taxOptionEntries.forEach(item => {
      const newItem = {
        ...item,
        uuid: createUuid(),
      }
      tempTaxOptionList.push(newItem);
    });
    this.taxOptionEntries = [...tempTaxOptionList];
    const lineItemJson: ILineItemJson = {
      'id': this.id,
      'uuid': createUuid(),
      'shipping_method': this.shippingMethod,
      'department_id': this.departmentID,
      'favorite_id': this.favoriteID,
      'favorite_tab_id': this.favoriteTabID,
      'favorite_section_id': this.favoriteSectionID,
      'purchasable_id': this.purchasableID,
      'purchasable_type': this.purchasableType,
      'label': this.name,
      'price': this.price,
      'brand': this.brand,
      'quantity': this.quantity,
      'quantity_allow_decimal': this.quantityAllowDecimal,
      'group_id': this.groupID,
      'modifier_entries': modifierEntries,
      'tax_option_entries': this.taxOptionEntries,
      'tax_base_adjustment_amount': this.taxBaseAdjustmentAmount,
      'taxable_amount': this.taxableAmount,
      'tax_amount': this.taxAmount,
      'add_on_tax_amount': this.addOnTaxAmount,
      'inclusive_tax_amount': this.inclusiveTaxAmount,
      'service_fee': this.serviceFee,
      'discount_entries': this.discountEntries,
      'discount_total': this.discountTotal,
      'subtotal': this.subtotal,
      'total': this.total,
      'procurement_method': this.procurementMethod,
      'kitchen_department_id': this.kitchenDepartmentID,
      'image_url': this.imageUrl,
      'modifier_set_option_id': this.modifierSetOptionID,
      'unit_quantity': this.unitQuantity,
      'store_id': this.storeID,
      'sale_type': SaleType.REGISTER_SALE,
      'i18n': this.i18n,
      'product_id': this.productID,
      'category_id': this.categoryID,
    }

    if (this.lineItemType === 'LISTING') {
      delete (lineItemJson as any).modifier_set_option_id;
      delete (lineItemJson as any).unit_quantity;
    } else {
      delete (lineItemJson as any).favorite_id;
      delete (lineItemJson as any).favorite_tab_id;
      delete (lineItemJson as any).favorite_section_id;
    }

    return lineItemJson;
  }

  repairModifierQuantity = () => {
    this.modifierEntries.forEach(
      item => item.repairQuantity(this.quantity)
    );
  }

  resetTaxOptionEntries = () => {
    if (!this.context || !this.id) return;
    const { taxOptions } = this.context;

    const taxOptionEntries: ITaxOptionEntrie[] = [];
    const defaultTaxOptionEntries: ITaxOptionEntrie[] = [];
    let firstTaxOptionEntry: ITaxOptionEntrie | undefined = undefined;
    taxOptions.forEach(option => {
      const taxOptionEntrie = taxOptionToTaxOptionEntrie(option, this);
      if (taxOptionEntrie) {
        if (option.departmentIDs.includes(`${this.departmentID}`)) {
          taxOptionEntries.push(taxOptionEntrie);
        } else if (option.listingIDs.includes(`${this.id}`)) {
          taxOptionEntries.push(taxOptionEntrie);
        }
      }

      if (taxOptionEntrie && taxOptionEntrie.primary) {
        if (!firstTaxOptionEntry) {
          firstTaxOptionEntry = taxOptionEntrie;
        }
        if (option.storeID && option.storeID === this.storeID) {
          defaultTaxOptionEntries.push(taxOptionEntrie);
        }
      }
    });

    if (taxOptionEntries.length < 1) {
      if (defaultTaxOptionEntries.length > 0) {
        this.taxOptionEntries = defaultTaxOptionEntries;
      } else if (firstTaxOptionEntry) {
        this.taxOptionEntries = [firstTaxOptionEntry];
      }
    } else {
      this.taxOptionEntries = taxOptionEntries;
    }
  }

  calculate = (context: Context) => {
    /** 计算总计和小计 */
    this.price = Number(this.price) || 0;
    this.unitPrice = Number(this.unitPrice) || 0;
    this.quantity = Number(this.quantity) || 0;
    // this.discountTotal = Number(this.discountTotal) || 0;

    const decimal = context.lineItemLevelDecimalPlaces;
    let subtotal = 0;
    let discountTotal = 0;
    let taxTotal = 0;
    let serviceFeeTotal = 0;

    this.modifierEntries.forEach(item => {
      item.calculate(context);
      subtotal = plus(subtotal, item.subtotal, decimal);
    });

    let lineItemSubtotal = 0;
    if (this.lineItemType === 'LISTING') {
      lineItemSubtotal = times(this.quantity, this.price || this.unitPrice, decimal);
    }

    subtotal = plus(subtotal, lineItemSubtotal, decimal);

    this.subtotal = subtotal;

    this.taxableAmount = subtotal;

    if (this.discountEntries.length > 0) {
      this.discountEntries.forEach(discount => {
        const { amount = 0 } = discount;
        discountTotal = plus(discountTotal, Math.abs(amount), decimal)
      })
    }
    this.discountTotal = discountTotal;
    if (this.taxOptionEntries.length > 0) {
      this.taxOptionEntries.forEach(tax => {
        if (Number(tax.tax_type) === 1) {
          taxTotal = plus(taxTotal, Number(tax.amount), decimal);
        }
        if (Number(tax.tax_type) === 2) {
          serviceFeeTotal = plus(serviceFeeTotal, Number(tax.amount), decimal);
        }
      })
    }
    this.taxAmount = taxTotal;
    this.serviceFee = serviceFeeTotal;
    /** total = 小计 - 折扣 */
    let total = minus(subtotal, this.discountTotal, decimal);
    total = plus(total, this.taxAmount, decimal);
    total = plus(total, this.serviceFee, decimal);

    if (total < 0) total = 0;
    this.total = Number(total.toFixed(decimal));
  }
}

export default LineItemEntity;
