import React, { useState, useEffect } from 'react';
import Icon, { IconType } from '@/components/Icon';
import { IPickerOptionData, IProcurementMethodProps } from '.';
import { IObject } from '../bm-common';
import InputItem from 'antd-mobile/es/input-item';
import { IReducersState } from '@/reducers';
import { useSelector } from 'react-redux';
import ShippingMethod from '@/lib/order-builder/enums/ShippingMethod';
import trim from 'lodash/trim';
import { IStoreTable } from '@/actions/store-action';
import dayjs from 'dayjs';
import PickerView from 'antd-mobile/es/picker-view';
import { generateAmPmData, generateData, getValidDataByTimeOptionValue } from './Utils';
import QRCodeScan from '../QRCodeScan';
import { parseScanUrl, findNode } from '@/utils';
import { goToRoute } from '@/utils/route-util';
import { getIntl } from '../App/App';

interface IProcurementMethodPopupContentProps extends IProcurementMethodProps {
  procurementMethod: ShippingMethod;
  setProcurementMethod: React.Dispatch<React.SetStateAction<ShippingMethod>>;
  setPopupShow: (show: boolean) => void;
  showProcurementMethod?: boolean;
}

const ProcurementMethodPopupContent: React.FC<IProcurementMethodPopupContentProps> = props => {
  const {
    id,
    store,
    storeConfig,
    storeTables,
    procurementMethod,
    orderInfo,
    setProcurementMethod,
    setPopupShow,
    onChange,
    parseOpeningHoursResult,
    hideChangeMethod,
    history,
  } = props;

  const { dineInParty, deliveryDate } = orderInfo || {};

  const getDateDefaultValue = () => {
    let defaultSelectedDate = '';
    let defaultSelectedTime = '';
    let defaultSelectedAmPm = '';
    if ([ShippingMethod.PICK_UP, ShippingMethod.DELIVERY].includes(procurementMethod) && deliveryDate && deliveryDate.length > 10) {
      const tempDate = dayjs(deliveryDate);
      defaultSelectedDate = tempDate.format('YYYY-MM-DD');
      defaultSelectedTime = tempDate.format('HH:mm:ss');
      if (defaultSelectedTime >= '12:00:00') {
        defaultSelectedAmPm = 'PM';
      } else {
        defaultSelectedAmPm = 'AM';
      }
    }

    return {
      defaultSelectedDate,
      defaultSelectedTime,
      defaultSelectedAmPm,
    };
  }

  const reducersState: IReducersState = useSelector<IReducersState, IReducersState>(state => state);
  const { deviceInfo } = reducersState.app;
  const [dateOptions, setDateOptions] = useState<IPickerOptionData[]>([]);
  const [timeOptions, setTimeOptions] = useState<IPickerOptionData[]>([]);
  const [amPmOptions, setAmPmOptions] = useState<IPickerOptionData[]>([]);
  const [currentDayTimeOptions, setCurrentDayTimeOptions] = useState<IPickerOptionData[]>([]);
  const [dayRefTimeOptions, setDayRefTimeOptions] = useState<{ [day: number]: IPickerOptionData[] }>({});
  const [selectedDate, setSelectedDate] = useState<string | number>(() => getDateDefaultValue().defaultSelectedDate);
  const [selectedTime, setSelectedTime] = useState<string | number>(() => getDateDefaultValue().defaultSelectedTime);
  const [selectedAmPm, setSelectedAmPm] = useState<string | number>(() => getDateDefaultValue().defaultSelectedAmPm);
  const [tableName, setTableName] = useState<string>(() => {
    let defaultTableName = '';
    if ([ShippingMethod.DINE_IN].includes(procurementMethod) && dineInParty) {
      defaultTableName = dineInParty.tableName || '';
    }
    return defaultTableName;
  });
  const [tableNameError, setTableNameError] = useState<boolean>(false);
  const [showQRCodeScan, setShowQRCodeScan] = useState<boolean>(false);
  const [timeType, chooseTimeType] = useState<string>('');

  let confirmDisable = true;
  if (ShippingMethod.DINE_IN_UNASSIGNED === procurementMethod && selectedDate && selectedTime && (parseOpeningHoursResult && parseOpeningHoursResult.isOpen)) {
    confirmDisable = false;
  } else if ([ShippingMethod.DINE_IN].includes(procurementMethod) && tableName !== undefined && tableName.length > 0 && !tableNameError && (parseOpeningHoursResult && parseOpeningHoursResult.isOpen)) {
    confirmDisable = false;
  } else if ([ShippingMethod.PICK_UP, ShippingMethod.DELIVERY].includes(procurementMethod) && selectedDate && selectedTime) {
    confirmDisable = false;
  }

  useEffect(() => {
    if (store && storeConfig && [ShippingMethod.PICK_UP, ShippingMethod.DELIVERY].includes(procurementMethod)) {
      const {
        dateOptions,
        dayRefTimeOptions,
        currentDayTimeOptions,
      } = generateData({
        procurementMethod,
        store,
        storeConfig,
      });

      const {
        defaultSelectedDate,
      } = getDateDefaultValue();
      const dateFormat = dayjs().format('YYYY-MM-DD');
      const dateNode = findNode<IPickerOptionData>(dateOptions, 'value', defaultSelectedDate);

      let timeOptions: IPickerOptionData[] = [];
      if (dateFormat === defaultSelectedDate && currentDayTimeOptions.length > 0) {
        timeOptions = currentDayTimeOptions;
      } else if (dateNode && Array.isArray(dayRefTimeOptions[dateNode.day])) {
        timeOptions = dayRefTimeOptions[dateNode.day];
      }

      if (deliveryDate && dateOptions.length > 0 && timeOptions.length > 0) {
        const laterDate = `${dateOptions[0].value} ${timeOptions[0].value}`;
        if (dayjs(deliveryDate).isBefore(dayjs(laterDate)) && !storeConfig.hideNowPickUpTime) {
          chooseTimeType('asap');
        } else {
          chooseTimeType('later');
        }
      }

      setDayRefTimeOptions(dayRefTimeOptions);
      setCurrentDayTimeOptions(currentDayTimeOptions);
      setDateOptions(dateOptions);
      setTimeOptions(timeOptions);
      setAmPmOptions(generateAmPmData(timeOptions));
    }
  }, []);

  const handleProcurementMethodChange = (pm: ShippingMethod) => {
    if (store && storeConfig && [ShippingMethod.PICK_UP, ShippingMethod.DELIVERY].includes(pm)) {
      const {
        dateOptions,
        dayRefTimeOptions,
        currentDayTimeOptions,
      } = generateData({
        procurementMethod: pm,
        store,
        storeConfig,
      });

      let selectedDate: any = '';
      let selectedTime: any = '';
      let selectedAmPm: any = '';
      let timeOptions: IPickerOptionData[] = [];
      if (dateOptions.length > 0) {
        selectedDate = dateOptions[0].value;
      }

      if ([ShippingMethod.PICK_UP, ShippingMethod.DELIVERY].includes(procurementMethod) && deliveryDate && deliveryDate.length > 10) {
        const tempDate = dayjs(deliveryDate);
        selectedDate = tempDate.format('YYYY-MM-DD');
        selectedTime = tempDate.format('HH:mm:ss');
        if (selectedTime >= '12:00:00') {
          selectedAmPm = 'PM';
        } else {
          selectedAmPm = 'AM';
        }
      }

      const nextDay = dayjs().day() + 1 > 6 ? 0 : dayjs().day() + 1;

      if (currentDayTimeOptions.length > 0) {
        timeOptions = currentDayTimeOptions;
      } else if (procurementMethod === ShippingMethod.PICK_UP && storeConfig.pickUpAdvanceOrderDate > 1) {
        timeOptions = dayRefTimeOptions[nextDay]
      } else if (procurementMethod === ShippingMethod.DELIVERY && storeConfig.deliveryAdvanceOrderDate > 1) {
        timeOptions = dayRefTimeOptions[nextDay]
      }

      const { newSelectedTime, newSelectedAmPm } = getValidDataByTimeOptionValue(timeOptions, '')

      setSelectedDate(selectedDate);
      setDateOptions(dateOptions);
      setDayRefTimeOptions(dayRefTimeOptions);
      setCurrentDayTimeOptions(currentDayTimeOptions);
      setSelectedTime(selectedTime ? selectedTime : newSelectedTime);
      setTimeOptions(timeOptions);
      setSelectedAmPm(selectedAmPm ? selectedAmPm : newSelectedAmPm);
      setAmPmOptions(generateAmPmData(timeOptions));
    }

    // setProcurementMethod(pm)
  }

  useEffect(() => {
    handleProcurementMethodChange(procurementMethod);
  }, [procurementMethod]);

  const rootProps: IObject = {};
  if (id) {
    rootProps.id = id;
  }

  const handleConfirm = () => {
    if (!onChange) {
      setPopupShow(false);
      return;
    }

    if (!confirmDisable) {
      if (ShippingMethod.DINE_IN_UNASSIGNED === procurementMethod) {
        onChange({
          shippingMethod: procurementMethod,
        }, store);
      } else if ([ShippingMethod.DINE_IN].includes(procurementMethod)) {
        const talbe = findNode<IStoreTable>(storeTables, 'name', tableName);
        if (talbe) {
          onChange({
            shippingMethod: procurementMethod,
            dineInParty: {
              numberOfPeople: talbe.numberOfPeople || 1,
              status: 'seated',
              tableID: talbe.id || '',
              tableName: talbe.name || '',
              turnTime: talbe.turnTime || 90,
            },
          }, store);
        }
      } else if ([ShippingMethod.PICK_UP, ShippingMethod.DELIVERY].includes(procurementMethod)) {
        const deliveryDate = timeType === 'asap' ? dayjs() : dayjs(`${selectedDate} ${selectedTime}`, { format: 'YYYY-MM-DD HH:mm:ss' });
        onChange({
          shippingMethod: procurementMethod,
          deliveryDate,
        }, store);
      }
      setPopupShow(false);
    }
  }

  const handleTableNumberChange = (value?: string) => {
    const newValue = trim(value || '');
    const talbe = findNode<IStoreTable>(storeTables, 'name', newValue);
    setTableName(newValue || '');
    if (!newValue || talbe) {
      setTableNameError(false);
    } else {
      setTableNameError(true)
    }
  }

  const handleDateChange = (value: any[]) => {

    if (Array.isArray(value) && value.length > 0) {
      const selectedDate = value[0];
      const dateNode = findNode<IPickerOptionData>(dateOptions, 'value', selectedDate);

      const dateFormat = dayjs().format('YYYY-MM-DD');

      let timeOptions: IPickerOptionData[] = [];
      if (dateFormat === selectedDate && currentDayTimeOptions.length > 0) {
        timeOptions = currentDayTimeOptions;
      } else if (dateNode && Array.isArray(dayRefTimeOptions[dateNode.day])) {
        timeOptions = dayRefTimeOptions[dateNode.day];
      }

      setTimeOptions(timeOptions);
      setAmPmOptions(generateAmPmData(timeOptions))
      setSelectedDate(selectedDate);

      const { newSelectedTime, newSelectedAmPm } = getValidDataByTimeOptionValue(timeOptions, selectedTime)
      setSelectedTime(newSelectedTime);
      setSelectedAmPm(newSelectedAmPm);
    }
  }

  const handleTimeChange = (value: any[]) => {
    if (Array.isArray(value) && value.length > 0) {
      const newSelectedTime: string | number = value[0];
      let newSelectedAmPm: string | number = '';
      if (newSelectedTime) {
        if (newSelectedTime >= '12:00:00') {
          newSelectedAmPm = 'PM';
        } else {
          newSelectedAmPm = 'AM';
        }

        setSelectedTime(newSelectedTime);
        setSelectedAmPm(newSelectedAmPm);
      }
    }
  }

  const handleAmPmChange = (value: any[]) => {
    if (Array.isArray(value) && value.length > 0) {
      const newSelectedAmPm: string | number = value[0];
      let newSelectedTime: string | number = '';

      if (selectedAmPm !== newSelectedAmPm) {
        if (newSelectedAmPm === 'AM') {
          for (let i = 0; i < timeOptions.length; i++) {
            const timeOption = timeOptions[i];
            if (timeOption.value < '12:00:00') {
              newSelectedTime = timeOption.value;
              break;
            }
          }
        } else {
          for (let i = 0; i < timeOptions.length; i++) {
            const timeOption = timeOptions[i];
            if (timeOption.value >= '12:00:00') {
              newSelectedTime = timeOption.value;
              break;
            }
          }
        }
        setSelectedTime(newSelectedTime);
        setSelectedAmPm(newSelectedAmPm);
      }
    }
  }

  let currentDateLabel: any = '';
  let currentTimeLabel: any = '';
  let currentAmPmLabel: any = '';
  if ([ShippingMethod.PICK_UP, ShippingMethod.DELIVERY].includes(procurementMethod)) {
    const currentDateNode = findNode<IPickerOptionData>(dateOptions, 'value', selectedDate);
    if (currentDateNode) {
      currentDateLabel = currentDateNode.label;
    }

    const currentTimeNode = findNode<IPickerOptionData>(timeOptions, 'value', selectedTime);
    if (currentTimeNode) {
      currentTimeLabel = currentTimeNode.label;
    }

    if (/^\d{2}.*/.test(currentTimeLabel) || /^\d{1}.*/.test(currentTimeLabel)) {
      currentAmPmLabel = selectedAmPm;
    }
  }

  const handleScanSuccess = (value: string) => {
    // if (/^-?\d+$/.test(value)) {
    //   handleTableNumberChange(`${Math.abs(Number(value))}`)
    // }
    let storeTable: IStoreTable | undefined;
    if (value && onChange) {
      const parseResult = parseScanUrl(value);
      if (parseResult && parseResult.searchParams && parseResult.searchParams.uuid) {
        storeTable = findNode<IStoreTable>(storeTables, 'uuid', parseResult.searchParams.uuid);
      } else if (parseResult && parseResult.searchParams && parseResult.searchParams.table_number) {
        storeTable = findNode<IStoreTable>(storeTables, 'uuid', parseResult.searchParams.table_number);
      }
    }

    if (storeTable && onChange) {
      onChange({
        shippingMethod: ShippingMethod.DINE_IN,
        dineInParty: {
          numberOfPeople: storeTable.numberOfPeople,
          status: '',
          tableID: storeTable.id,
          tableName: storeTable.name,
          turnTime: storeTable.turnTime,
        },
      })
      setTableName(storeTable.name);
    }

    setShowQRCodeScan(false);
  }

  const handleScan = (value = '') => {
    const info = parseScanUrl(value);
    if (value.startsWith('http') && !info.searchParams.order_number) {
      if (info.isSameEvn && info.isSamePlatform && info.pathname !== window.location.pathname  && info.platformRoute && history && !info.search) { // 同一个平台、同一个环境，但是不同页面路径
        goToRoute({
          history,
          pathname: info.platformRoute,
        });
      } else if (info.isSameEvn && info.isSamePlatform && (info.pathname === window.location.pathname || info.searchParams.table_number) && info.platformRoute) { // 当前页面
        handleScanSuccess(value);
      } else {
        window.location.href = value;
      }
    } else {
      handleScanSuccess(value);
    }
  }

  (window as any).scanResult = (scanStr: string) => {
    handleScan(scanStr);
  }

  const handleScanClick = () => {
    const bindoUtils: any = (window as any).bindo_utils;
    if (deviceInfo.isAndroid && bindoUtils && bindoUtils.openCameraScan) {
      bindoUtils.openCameraScan();
    } else if (deviceInfo.isAppleApp && (window as any).webkit && (window as any).webkit.messageHandlers && (window as any).webkit.messageHandlers) {
      (window as any).webkit.messageHandlers.openCodeScanner.postMessage('openCodeScanner');
    } else {
      setShowQRCodeScan(true)
    }
  }

  (window as any).processScannedCode = (scanStr: string) => {
    handleScan(scanStr);
  }

  return (
    <>
      {/* <div className="time-modal">
        <div className="form-block w-form">
          <form id="wf-form-Procurement-Method" name="wf-form-Procurement-Method" data-name="Procurement Method" className="form">
            <div className="div-block-76">
              <h3 className="heading-3 procurement">{getIntl().page.procurementMethod}</h3>
            </div>
            {
              storeConfig && storeConfig.shippingMethods.length > 1 && !hideChangeMethod &&
              <div className="columns w-row">
                {
                  (storeConfig.shippingMethods.includes(ShippingMethod.DINE_IN) || storeConfig.shippingMethods.includes(ShippingMethod.DINE_IN_UNASSIGNED)) &&
                  <div className="column-4 w-col w-col-4">
                    <label id="dine-in" className="radio-field-parent w-radio">
                      <input type="radio" data-name="Radio Options" id="node" name="Radio-Options" value="1" className="w-form-formradioinput actual-radio-button w-radio-input" />
                      <div  className="html-procurement-icon-1 w-embed"></div>
                      <span data-w-id="0ee6d91d-f3d4-a79c-83b1-4ce4eb08fece" className="radio-field-label w-form-label">{getIntl().page.dineIn}</span>
                    </label>
                  </div>
                }
                {
                  storeConfig.shippingMethods.includes(ShippingMethod.PICK_UP) &&
                  <div className="column-6 w-col w-col-4">
                    <label id="take-away"className="radio-field-parent w-radio">
                      <div className="html-procurement-icon-2 w-embed"></div>
                      <input type="radio" data-name="Radio Options" id="node" name="Radio-Options" value="1" className="w-form-formradioinput actual-radio-button w-radio-input" />
                      <span data-w-id="93ed9dba-66d0-3341-dc28-c3a7184e762c" className="radio-field-label w-form-label">{getIntl().page.takeAway}</span>
                    </label>
                  </div>
                }
                {
                  storeConfig.shippingMethods.includes(ShippingMethod.DELIVERY) &&
                  <div className="column-6 w-col w-col-4">
                    <label id="take-away"className="radio-field-parent w-radio">
                      <div className="html-procurement-icon-2 w-embed"></div>
                      <input type="radio" data-name="Radio Options" id="node" name="Radio-Options" value="1" className="w-form-formradioinput actual-radio-button w-radio-input" />
                      <span data-w-id="93ed9dba-66d0-3341-dc28-c3a7184e762c" className="radio-field-label w-form-label">{getIntl().page.delivery}</span>
                    </label>
                  </div>
                }
              </div>
            }
          </form>
        </div>
      </div> */}
      <div className="time-modal">
        {
          storeConfig && storeConfig.shippingMethods.length > 1 && !hideChangeMethod &&
          <div className="pm-panel">
            <div className="panel-title">{getIntl().page.procurementMethod}</div>
            <div className="pm-box">
              {
                (storeConfig.shippingMethods.includes(ShippingMethod.DINE_IN) || storeConfig.shippingMethods.includes(ShippingMethod.DINE_IN_UNASSIGNED)) &&
                <div
                  className={`pm-item ${[ShippingMethod.DINE_IN, ShippingMethod.DINE_IN_UNASSIGNED].includes(procurementMethod) ? 'selected' : ''}`}
                  onClick={() => setProcurementMethod(ShippingMethod.DINE_IN)}
                >
                  <Icon type={IconType.STORE_OUTLINED} />
                  <div className="pm-item-title">{getIntl().page.dineIn}</div>
                </div>
              }
              {
                storeConfig.shippingMethods.includes(ShippingMethod.PICK_UP) &&
                <div
                  className={`pm-item ${procurementMethod === ShippingMethod.PICK_UP ? 'selected' : ''}`}
                  onClick={() => setProcurementMethod(ShippingMethod.PICK_UP)}
                >
                  <Icon type={IconType.PICK_UP_OUTLINED} />
                  <div className="pm-item-title">{getIntl().page.takeAway}</div>
                </div>
              }
              {
                storeConfig.shippingMethods.includes(ShippingMethod.DELIVERY) &&
                <div
                  className={`pm-item ${procurementMethod === ShippingMethod.DELIVERY ? 'selected' : ''}`}
                  onClick={() => setProcurementMethod(ShippingMethod.DELIVERY)}
                >
                  <Icon type={IconType.DELIVERY_OUTLINED} />
                  <div className="pm-item-title">{getIntl().page.delivery}</div>
                </div>
              }
            </div>
          </div>
        }
        {
          [ShippingMethod.DINE_IN].includes(procurementMethod) &&
          <>
            <div className="panel-space" />
            <div className="pm-panel">
              <div className="panel-title">{getIntl().page.confirmTableNumber}</div>
              <div className="ctn-tips">{getIntl().page.confirmTableNumberTips}</div>
              <InputItem
                autoComplete="off"
                placeholder={getIntl().page.enterTableNumber}
                onChange={handleTableNumberChange}
                value={tableName}
                className={`table-number ${tableNameError ? 'table-number-error' : ''}`}
                extra={
                  <Icon
                    type={IconType.SCAN_OUTLINED}
                    className="c-icon-scan"
                    onClick={handleScanClick}
                  />
                }
              />
              {
                tableNameError &&
                <div className="table-number-error-msg">
                  {getIntl().page.tableNumberNotExist}
                </div>
              }
            </div>
          </>
        }

        {
          [ShippingMethod.PICK_UP, ShippingMethod.DELIVERY].includes(procurementMethod) &&
          <>
            <div className="panel-space" />
            <div className="choose-time-type-wrap">
              {
                !storeConfig?.hideNowPickUpTime && 
                <div className={`choose-time-type ${((parseOpeningHoursResult && !parseOpeningHoursResult.isOpen) || (storeConfig && storeConfig.delayedDeliveryTime > 0)) ? 'choose-time-type-disable' : ''}`} 
                  onClick={() => {
                    (parseOpeningHoursResult && parseOpeningHoursResult.isOpen && storeConfig && storeConfig.delayedDeliveryTime === 0) && !confirmDisable && chooseTimeType('asap');
                  }}
                >
                  <Icon type={timeType === 'asap' ? IconType.SELECTED_OUTLINED : IconType.UN_SELECTED_OUTLINED} />
                  {getIntl().page.asap}
                </div>
              }
              <div className={`choose-time-type ${confirmDisable ? 'choose-time-type-disable' : ''}`} onClick={() => !confirmDisable && chooseTimeType('later')}>
                <Icon type={timeType === 'later' ? IconType.SELECTED_OUTLINED : IconType.UN_SELECTED_OUTLINED} />
                {
                  ShippingMethod.DELIVERY === procurementMethod &&
                  (confirmDisable ? getIntl().page.notSchedule : getIntl().page.canSchedule)
                }
                {
                  ShippingMethod.PICK_UP === procurementMethod &&
                  (confirmDisable ? getIntl().page.notSchedule : getIntl().page.pickUpTime)
                }
              </div>
            </div>
            {
              timeType === 'later' &&
              <div className="pm-panel">
                <div className="panel-title">
                  {getIntl().page.chooseTime}
                  <div className="pm-time-info">{storeConfig && storeConfig.pickUpInterval === 0 ? `${currentDateLabel}` : `${currentDateLabel} ${currentTimeLabel}${currentAmPmLabel}`}</div>
                </div>
                <div className="bm-c-choose-time ct-list-panel">
                  <div className="ct-list ct-item-day">
                    <PickerView
                      data={dateOptions}
                      value={[selectedDate]}
                      cols={1}
                      cascade={false}
                      onChange={handleDateChange}
                    />
                  </div>
                  {
                    storeConfig && (storeConfig.pickUpInterval !== 0 || storeConfig.deliveryInterval !== 0) &&
                    <>
                      <div className="ct-list ct-item-time">
                        <PickerView
                          data={timeOptions}
                          value={[selectedTime]}
                          cols={1}
                          cascade={false}
                          onChange={handleTimeChange}
                        />
                      </div>
                      <div className="ct-list ct-item-ap">
                        <PickerView
                          data={amPmOptions}
                          value={[selectedAmPm]}
                          cols={1}
                          cascade={false}
                          onChange={handleAmPmChange}
                        />
                      </div>
                    </>
                  }
                </div>
              </div>
            }
          </>
        }
        <div className={`confirm-btn ${confirmDisable ? 'btn-disable' : ''}`} onClick={handleConfirm}>
          {getIntl().common.confirm}
        </div>
      </div>
      {
        showQRCodeScan &&
        <QRCodeScan
          prohibitHyperlink={true}
          onSuccess={handleScanSuccess}
          onFail={() => setShowQRCodeScan(false)}
        />
      }
    </>
  );
}

export default ProcurementMethodPopupContent;
