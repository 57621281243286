
import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import Icon, { IconType } from '@/components/Icon';
import { IObject } from '@/components/bm-common';
import { getAppTheme, setScreenColor, getIntl } from '@/components/App/App';
import Layout from '@/components/Layout';
import { prefix } from '.';
import paths from '@/routes/paths';
import ActionType from '@/actions/action-type';
import { IStorePickupLocation } from '@/actions/store-action';
import ShippingMethod from '@/lib/order-builder/enums/ShippingMethod';
import { getI18nText } from '@/utils/app';
import { IShoppingCartData } from '@/actions/shopping-cart-action';
import dayjs from 'dayjs';

export interface IPickupLocationsPageProps extends IBasePageProps { }
export interface IPickupLocationsPageState {
  showLanguage: boolean;
  showTerms: boolean;
  termsData: string | undefined;
  termsTitle: string;
  pickupLocations: IStorePickupLocation[];
  currentLocationID: string;
  shoppingCartData?: IShoppingCartData;
}

class PickupLocationsPage extends BasePage<IPickupLocationsPageProps, IPickupLocationsPageState> {
  routeParams: IObject = {};

  state: IPickupLocationsPageState = {
    showLanguage: false,
    showTerms: false,
    termsData: '',
    termsTitle: '',
    pickupLocations: [],
    currentLocationID: '',
  }

  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.lightBackgroundColor) {
      setScreenColor(appTheme.lightBackgroundColor);
    }

    super.componentDidMount();
    const { dispatch, routeParams } = this.props;
    this.getStorePickupLocations();
    dispatch({
      type: ActionType.GET_STORE_SHOPPING_CART_DATA,
      storeSlug: routeParams.storeSlug,
      callback: params => {
        const { shoppingCartData } = params;
        this.setState({ shoppingCartData });
        if (shoppingCartData) {
          this.setState({
            currentLocationID: shoppingCartData.orderData.pickup_location_id || '',
          })
        }
      },
    });

  }

  getStorePickupLocations = () => {
    const { dispatch, routeParams } = this.props;
    dispatch({
      type: ActionType.QUERY_STORE_PICKUP_LOCATIONS,
      storeSlug: routeParams.storeSlug,
      callback: pickupLocations => {
        this.setState({ 
          pickupLocations,
          currentLocationID: pickupLocations && pickupLocations.length > 0 ? pickupLocations && pickupLocations[0].id : '',
        });
      },
    });
  }
  chooseLocation = (item: IStorePickupLocation) => {
    this.setState({
      currentLocationID: item.id,
    })
    const { dispatch, routeParams } = this.props;
    const { shoppingCartData } = this.state;
    dispatch({
      type: ActionType.CHANGE_SHIPPING_METHOD,
      storeSlug: routeParams.storeSlug,
      data: {
        shippingMethod: ShippingMethod.PICK_UP,
        pickupLocationID: item.id,
        pickupLocation: item,
        deliveryDate: dayjs(shoppingCartData?.orderData.delivery_date),
      },
      callback: shoppingCartData => {
        console.info('shoppingCartData', shoppingCartData);
      }
    });
    this.handleGoBack({
      path: paths.HOME,
      runGoBack: true,
    })
  }

  render() {
    const { pickupLocations, currentLocationID } = this.state;
    const { reducersState } = this.props;
    return (
      <Layout
        {...this.props}
        className={prefix}
        navIcon={<Icon className="back-icon" type={IconType.LEFT_OUTLINED} />}
        onLeftClick={() => this.handleGoBack({ path: paths.HOME, runGoBack: true })}
        navContent={getIntl().page.chooseLocation}
        isFooter={false}
      >
        {
          pickupLocations.length > 0 && pickupLocations.map(item => (
            <div className="address-item" key={item.id} onClick={() => { this.chooseLocation(item) }}>
              <div className="address">
                {getI18nText(item, 'name', reducersState.app.language) || item.name}
              </div>
              <Icon type={currentLocationID === item.id ? IconType.CHECKED_OUTLINED : IconType.UN_SELECTED_OUTLINED} />
            </div>
          ))
        }
      </Layout>
    );
  }
}

export default PickupLocationsPage;
