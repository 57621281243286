import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import Layout from '@/components/Layout';
import Icon, { IconType } from '@/components/Icon';
import Toast from 'antd-mobile/es/toast';
import { prefix } from '.';
import InputItem from 'antd-mobile/es/input-item';
import { copyToClipboard } from '@/utils'

export interface IIconsPageProps extends IBasePageProps {}

interface IIconsPageState {
  inputValue: string;
}

interface IIconsData {
  key: string;
  value: IconType;
  title: string;
}

class IconsPage extends BasePage<IIconsPageProps, IIconsPageState> {

  constructor(props: IIconsPageProps) {
    super(props);

    this.state = {
      inputValue: '',
    }
  }

  handleCopy = (txt: string) => {
    copyToClipboard({
      txt,
      success: () => Toast.info('Copy successful'),
      failure: () => Toast.fail('Copy failed'),
    });
    ;
  }

  filterIcons = (inputValue: string) => {
    const searchKey = (inputValue || '').trim();
    const icons: IIconsData[] = [];
    Object.keys(IconType).forEach(key => {
      const title = key.toLocaleUpperCase().replace('_OUTLINED', '');
      let show = true;
      if (searchKey && searchKey.length > 0) {
        if (!title.includes(searchKey.toLocaleUpperCase())) {
          show = false;
        }
      }

      if (show) {
        icons.push({
          key,
          value: (IconType as any)[key],
          title,
        });
      }
    });

    return icons;
  }

  render () {
    const { inputValue } = this.state;

    return (
      <Layout
        {...this.props}
        navIcon={<Icon className="icons-back" type={IconType.LEFT_OUTLINED} />}
        navContent="Icons"
        onLeftClick={() => this.handleGoHome(true)}
        className={prefix}
        isFooter={false}
      >
        <div className={`${prefix}-content`}>
          <InputItem
            autoComplete="off"
            placeholder="输入图标名称"
            value={inputValue}
            clear
            onChange={(value: any) => this.setState({ inputValue: value})}
          />
          <div className="icon-list">
            {
              this.filterIcons(inputValue).map(item => {
                // const value = (IconType as any)[key];
                return (
                  <div key={item.value} className={`${prefix}-item`}>
                    <div className="icon-img" onClick={() => this.handleCopy(`<Icon type={IconType.${item.key}} />`)}>
                      <Icon
                        type={item.value}
                        lightColor="#F1D35E"
                        darkColor="#E4A83F"
                      />
                    </div>
                    <span className="icon-title" onClick={() => this.handleCopy(item.key)}>
                      {item.title}
                    </span>
                  </div>
                );
              })
            }
          </div>
        </div>
        <div className={`${prefix}-copy-wrapper`}>
          <input className={`${prefix}-copy`} id="global-copy" />
        </div>
      </Layout>
    );
  }
}

export default IconsPage
