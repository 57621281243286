import React from 'react';
import StoreFunction from '@/actions/store-function';
import { IStoreConfig } from '@/actions/store-action';
import { lineToHump } from '@/utils';
import { getIntlByPath } from '@/components/App/App';

interface IMenuFunctionsProps {
  storeConfig: IStoreConfig;
  selectedKey?: StoreFunction;
  onItemClick?: (key: StoreFunction) => void;
}

interface IMenuFunctionsItemProps extends IMenuFunctionsProps {
  funcKey: StoreFunction;
}

const MenuFunctionsItem: React.FC<IMenuFunctionsItemProps> = props => {
  const { funcKey, selectedKey, onItemClick } = props;
  const handleClick = (key: StoreFunction) => {
    if (onItemClick) {
      onItemClick(key)
    }
  }
  return (
    <div className="tab-link-tab-2 w-inline-block w-tab-link" onClick={() => handleClick(funcKey)}>
      <h3 className="heading-5">
        {getIntlByPath(`page.${lineToHump(funcKey.toLocaleLowerCase())}Func`)}
      </h3>
      {
        selectedKey === funcKey &&
        <div className="underline"></div>
      }
    </div>
  );
}

const MenuFunctions: React.FC<IMenuFunctionsProps> = props => {
  const { storeConfig } = props;
  return (
    <div className="tabs-menu w-tab-menu">
      {
        storeConfig.showOrder &&
        <MenuFunctionsItem {...props} funcKey={StoreFunction.ORDER} />
      }
      {
        !storeConfig.showOrder && storeConfig.showMenu &&
        <MenuFunctionsItem {...props} funcKey={StoreFunction.MENU} />
      }
      {
        storeConfig.showBooking && false &&
        <MenuFunctionsItem {...props} funcKey={StoreFunction.BOOKING} />
      }
      {
        storeConfig.showQueueing && false &&
        <MenuFunctionsItem {...props} funcKey={StoreFunction.QUEUEING} />
      }
      {
        storeConfig.showFeedback &&
        <MenuFunctionsItem {...props} funcKey={StoreFunction.FEEDBACK} />
      }
      {
        storeConfig.showNews &&
        <MenuFunctionsItem {...props} funcKey={StoreFunction.NEWS} />
      }
      {
        storeConfig.showAboutUs &&
        <MenuFunctionsItem {...props} funcKey={StoreFunction.ABOUT_US} />
      }
    </div>
  );
}

export default MenuFunctions;
