import React from 'react';
import Button from 'antd-mobile/es/button';
import Modal from 'antd-mobile/es/modal';
import Icon, { IconType } from '../Icon';
import { IPayCard, IGiftCard } from '@/actions/pay-action';
import PayCardModify, { usePayCardModify, IPayCardInstance } from '@/components/PayCardModify';
import { giftCardToPayMethod, creditCardToPayMethod } from '@/utils/pay';
import ActionType from '@/actions/action-type';
import { IDispatch } from '@/actions';
import { PayTypes, IPayMethod } from '@/lib/payment';
import { IStore } from '@/actions/store-action';
import { IOrderInfo } from '@/lib/order-builder/models/OrderEntity';
import { minus } from '@/utils';
import { IReducersState } from '@/reducers';
import { prefix } from '.';
import { getIntl } from '../App/App';

interface IPayCardModalProps {
  visible: boolean;
  dispatch: IDispatch;
  amount: string;
  reducersState: IReducersState;
  className?: string;
  isGuest?: boolean;
  onCancel?: () => void;
  onAddcarded?: () => void;
  store?: IStore;
}

interface IPayCardModalState {
  isReady: boolean;
  adding: boolean;
}

class PayCardModal extends React.Component<IPayCardModalProps, IPayCardModalState> {
  payCardModify: IPayCardInstance | undefined;

  constructor(props: IPayCardModalProps) {
    super(props);

    const [payCardModify] = usePayCardModify();
    this.payCardModify = payCardModify;
  }

  state: IPayCardModalState = {
    isReady: false,
    adding: false,
  }

  handleReadyChange = (isReady: boolean) => {
    this.setState({ isReady: true });
  }

  handleSave = async () => {
    const {
      amount,
      store,
      dispatch,
      reducersState,
      onAddcarded = () => {},
      onCancel = () => {},
    } = this.props;
    const amountNum = parseFloat(amount);
    const orderInfo = {
      currencyCode: store ? store.currencyCode : 'HKD',
      initialTotal: amountNum,
    };
    if (!this.payCardModify) return;
    this.setState({ adding: true });
    let showModal = false;
    let textContent = '';

    const result = await this.payCardModify.save();
    if (result) { // 数据提交成功
      if ([PayTypes.STORE_CREDIT, PayTypes.ANONYMOUS_GIFT_CARD].includes(result.type)) {
        const giftCard = result as IGiftCard;
        const gcPayMethod = giftCardToPayMethod(giftCard, orderInfo as IOrderInfo, 0);
        if (!giftCard.isGiftCard) {
          showModal = true;
          textContent = getIntl().page.giftCardAlreadyBound;
        } else {
          if (orderInfo.initialTotal <= giftCard.balance) {
            dispatch({
              type: ActionType.MODIFY_PAY_METHODS,
              payMethods: [gcPayMethod],
            });
            onAddcarded();
          } else {
            gcPayMethod.amount = Number(gcPayMethod.balance);
            const { payMethods } = reducersState.pay;
            let payMethod: IPayMethod | undefined = undefined;
            for (let i = 0; i < payMethods.length; i++) {
              const tempPayMethod = payMethods[i];
              if (![PayTypes.STORE_CREDIT, PayTypes.ANONYMOUS_GIFT_CARD].includes(tempPayMethod.type)) {
                payMethod = JSON.parse(JSON.stringify(tempPayMethod));;
                break;
              }
            }

            if (payMethod) {
              payMethod.amount = minus(orderInfo.initialTotal, Number(gcPayMethod.amount));
              dispatch({
                type: ActionType.MODIFY_PAY_METHODS,
                payMethods: [gcPayMethod, payMethod],
              });
              onAddcarded();
            } else {
              dispatch({
                type: ActionType.MODIFY_PAY_METHODS,
                payMethods: [gcPayMethod],
              });
              onCancel();
            }
          }
        }
      } else { // 添加银行卡
        const creditCard = result as IPayCard;

        const { payMethods } = reducersState.pay;
        let payMethod: IPayMethod | undefined = undefined;
        const newPayMethods: IPayMethod[] = [];
        for (let i = 0; i < payMethods.length; i++) {
          const tempPayMethod = payMethods[i];
          if ([PayTypes.STORE_CREDIT, PayTypes.ANONYMOUS_GIFT_CARD].includes(tempPayMethod.type)) {
            payMethod = JSON.parse(JSON.stringify(tempPayMethod));
            if (payMethod) newPayMethods.push(payMethod);
            break;
          }
        }

        if (payMethod) {
          const ccPayMethod = creditCardToPayMethod(creditCard, orderInfo as IOrderInfo, Number(payMethod.amount));
          if (ccPayMethod.amount === 0) {
            ccPayMethod.amount = orderInfo.initialTotal;
            newPayMethods.length = 0;
          }
          newPayMethods.push(ccPayMethod);
        } else {
          newPayMethods.push(creditCardToPayMethod(creditCard, orderInfo as IOrderInfo, 0));
        }

        dispatch({
          type: ActionType.MODIFY_PAY_METHODS,
          payMethods: newPayMethods,
        });

        onAddcarded();
      }
    } else {
      showModal = true;
      textContent = getIntl().page.payCardError;
    }

    if (showModal) {
      const tips = getIntl().common.tips;
      Modal.alert(tips, textContent, [
        {
          text: getIntl().common.confirm,
          onPress: () => {
            this.setState({ adding: false })
          },
        }
      ]);
    }
  }

  render () {
    const { visible, store, dispatch, onCancel, reducersState, isGuest } = this.props;
    const { isReady, adding } = this.state;
    const { id = '' } =  store || {};

    return (
      <div className={` bm-p-pay-card ${prefix} ${visible ? 'visible' : 'hide'}`}>
        <div className="c-popup-close-2 close-modal" onClick={onCancel}></div>
        <div className="flex-pop-up widen">
          <div className="top-header no-line">
            <h2 className="heading-2">{getIntl().page.paymentCardTitle}</h2>
            <div className="x-icon close-modal">
              <Icon className="w-embed" type={IconType.CLOSE_OUTLINED} onClick={onCancel} />
            </div>
          </div>
          <div className="form-3">
            <PayCardModify
              dispatch={dispatch}
              storeID={id}
              payCardModify={this.payCardModify}
              onReadyChange={this.handleReadyChange}
              isGuest={isGuest}
              reducersState={reducersState}
            />
            <Button type="primary" className="log-in-button" disabled={!isReady || adding} onClick={this.handleSave}>
              {getIntl().common.save}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
export default PayCardModal;
