import React from 'react';
// import Icon, { IconType } from '@/components/Icon';
import { IModifier } from '@/lib/order-builder/utils/api';
import  Modifier from './NewModifier';
import ModifierHeader from './NewModifierHeader';
import { IReducersState } from '@/reducers';

interface IModifierListViewProps {
  data: IModifier[];
  countRef: {[modifierID: string]: number};
  selectedNumber: {[modifierID: string]: number};
  onItemClick: (data: IModifier) => void;
  onModifierClick?: (listing: IModifier, data: IModifier, action: 'PLUS' | 'MINUS' | 'OPTIONS' | 'SINGLE') => void;
  reducersState: IReducersState;
  currencyCode: string;
}

const ModifierListView: React.FC<IModifierListViewProps> = props => {
  const {
    data,
    onItemClick,
    onModifierClick,
    countRef,
    selectedNumber,
    reducersState,
    currencyCode,
  } = props;
  return (
    <>
      {
        data.map(item => (
          <div className="div-block-553" key={item.id} id={`bm-modifier-item-panel-${item.id}`}>
            <ModifierHeader
              data={item}
              onItemClick={onItemClick}
              reducersState={reducersState}
            />
            <div className="dropdown-list-1" id={`bm-modifier-panel-${item.id}`}>
              { 
                item.children.map((_item => (
                  <Modifier
                    key={_item.id}
                    chooseSameOption={item.chooseSameOption}
                    chooseUpTo={item.chooseUpTo}
                    optionHasSubOption={_item.children.length > 0 ? true : false}
                    data={_item}
                    clickable={(item.chooseUpTo > 0 && selectedNumber[item.id] - item.chooseUpTo >= 0)}
                    onClick={onModifierClick}
                    countRef={countRef}
                    listing={item}
                    reducersState={reducersState}
                    currencyCode={currencyCode}
                  />
                )))
              }
            </div>
          </div>
        ))
      }
    </>
  );
}

export default ModifierListView;
