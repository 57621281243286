import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import Layout from '@/components/Layout/Layout';
import paths from '@/routes/paths';
import { prefix } from '.';
import { getAppTheme, setScreenColor, getIntl } from '@/components/App/App';
import { TransitionKeys } from '@/components/Transition';
import SignMethodType from '@/components/common/userComponents/MethodType';
import Icon, { IconType } from '@/components/Icon';
import { resizeImage } from '@/utils';
import { SelectSignInPageStyled } from './styled';

export interface ISelectSignInPageProps extends IBasePageProps { }

class SelectSignInPage extends BasePage<ISelectSignInPageProps> {

  state = {
    signType: SignMethodType.INIT,
  }

  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.darkBackgroundColor) {
      setScreenColor(appTheme.darkBackgroundColor);
    }

    super.componentDidMount();
  }

  handleGoPage = (pathname: string) => {
    this.handleGotoRoute({
      path: pathname,
      animationType: TransitionKeys.MOVE_FROM_RIGHT,
    })
  }

  render() {
    const { reducersState } = this.props;
    const { selectPageBackground } = reducersState.app.platformAppSettings || {};
    const { selectPageLogin } = reducersState.app.platformAppSettings || {};
    const { memberMigration } = reducersState.app.platformConfig;
    return (
      <Layout
        {...this.props}
        className={prefix}
        isFooter={false}
      >
        <SelectSignInPageStyled>
          <div className="with-bg" style={{ backgroundImage: `url(${resizeImage({url: selectPageBackground || '', type: 'DEFAULT'})})`}}>
            <div className="section-2 wf-section">
              <div className="container strech w-container">
                <div className="div-block-613">
                  <img src={resizeImage({url: selectPageLogin || '', type: 'DEFAULT'})} alt=""  className="image-204" loading="lazy" width="390" sizes="() 70vw, () 351px, 390px"/>
                  <div className="div-block-612">
                    <div className="sign-up w-inline-block" onClick={() => { this.handleGoPage(paths.SIGN_UP)}}>
                      <div className="text-block-17">{getIntl().page.registerAsMember}</div>
                    </div>
                    <div className="sign-up w-inline-block" onClick={() => { this.handleGoPage(paths.SIGN_IN)}}>
                      <div className="text-block-17">{getIntl().page.loginUser}</div>
                    </div>
                  </div>
                  {
                    memberMigration &&
                    <div>
                      <div className="text-block-91 streched">{getIntl().page.selectPageextra}</div>
                      <div className="div-block-609 w-inline-block" onClick={() => this.handleGoPage(paths.FORGOT_PASSWORD) }>
                        <div className="text-block-91 bold-red">
                          {getIntl().page.placeClickHere}
                          <Icon
                            type={IconType.LEFT_ARROW_OUTLINED}
                            className="left-arrow-icon"
                          />
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </SelectSignInPageStyled>
      </Layout>
    );
  }
}

export default SelectSignInPage
