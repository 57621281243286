import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import Layout from '@/components/Layout';
import Icon, { IconType } from '@/components/Icon';
import { createRouteUrl } from '@/utils';
import paths from '@/routes/paths';
import { StoreGoogleMap } from '@/lib/google-map';
import { prefix } from '.';

export interface IGooglePageProps extends IBasePageProps {
}


class GoogleMapPage extends BasePage<IGooglePageProps> {

  handleGoBack = () => {
    const { history } = this.props;
    history.push({
      pathname: createRouteUrl(
        paths.HOME,
      ),
    })
  }

  render () {
    const { reducersState, dispatch } = this.props;
    return (
      <Layout
        {...this.props}
        navIcon={<Icon className="googlemap-back" type={IconType.LEFT_OUTLINED} />}
        navContent="Google map"
        onLeftClick={this.handleGoBack}
        className={prefix}
      >
        {
          <StoreGoogleMap
            dispatch={dispatch}
            reducersState={reducersState}
          />
        }
      </Layout>
    );
  }
}

export default GoogleMapPage;

