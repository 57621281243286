import React from 'react';
import { useDispatch } from 'react-redux';
import { IStoreCategory, IStoreLocation } from '@/actions/store-action';
import { IReducersState } from '@/reducers';
import { getI18nText } from '@/utils/app';
import { lineToHump } from '@/utils';
import { IDispatch } from '@/actions';
import ActionType from '@/actions/action-type';
import { StoreFilterModalContentType } from '@/actions/store-action';

import { prefix } from '.';
import { getIntlByPath, getIntl } from '../App/App';

interface IStoreFilterModalContentProps {
  title: string | React.ReactElement;
  type: StoreFilterModalContentType;
  data: IStoreCategory[] | string[] | number[][] | IStoreLocation[];
  reducersState: IReducersState;
  setStoreFilter: Function;
  onTypeClick?: (type: string) => void;
  className?: string;
  onClick?: () => void;
}

const StoreFilterModalContent: React.FC<IStoreFilterModalContentProps> = props => {
  const {
    className = '',
    title,
    data = [],
    type,
    reducersState,
    setStoreFilter = () => {},
    onTypeClick = () => {},
    onClick,
  } = props;

  const dispatch = useDispatch<IDispatch>();
  const { filterRefStores } = reducersState.store;
  const { selectedStoreServiceType } = reducersState.app;

  const {
    prices,
    category,
    brands,
    district,
  } = filterRefStores;

  const handleServiceType = (type: string) => {
    onTypeClick(type);
    dispatch({
      type: ActionType.SET_SELECTED_SERVICE_TYPE,
      selectedServiceType: type,
    });
    onClick && onClick();
  }

  const handleCategory = (tag: string) => {
    const newCategory = new Set([...category]);
    if (newCategory.has(tag)) {
      newCategory.delete(tag);
    } else {
      newCategory.add(tag);
    }
    setStoreFilter({
      'category': newCategory,
    });
  }

  const handleBrands = (tag: string) => {
    const newBrands = new Set([...brands]);
    if (newBrands.has(tag)) {
      newBrands.delete(tag);
    } else {
      newBrands.add(tag);
    }
    setStoreFilter({
      'brands': newBrands,
    });
  }

  const handleDistrict = (tag: string) => {
    const newDistrict  = new Set([...district]);
    if (newDistrict.has(tag)) {
      newDistrict.delete(tag);
    } else {
      newDistrict.add(tag);
    }
    setStoreFilter({
      'district': newDistrict,
    });
  }

  const handlePrice = (prices: number[]) => {
    setStoreFilter({
      'prices': prices,
    });
  }

  return (
    <div className={`${prefix}-content ${className}`}>
      {
        data.length > 0 &&
        <h4 className="heading-4-1">{title}</h4>
      }
      <div className="filter-wrapper">
        <div className="div-block-101">
          {
            data.length > 0 && type === StoreFilterModalContentType.TYPE &&
            (data as string[]).map((item, index) => (
              <div
                key={`${item}`}
                className={`filter-block ${selectedStoreServiceType === item ? 'current' : ''}`}
                onClick={() => handleServiceType(item)}
              >
                {getIntlByPath(`page.${lineToHump(item.replace('is_', ''))}`)}
              </div>
            ))
          }
          {
            data.length > 0 && type === StoreFilterModalContentType.BRANDS &&
            (data as IStoreCategory[]).map((item, index) => (
              <div
                key={`${item.id}`}
                className={`filter-block ${brands.has(item.id) ? 'current' : ''}`}
                onClick={() => handleBrands(item.id)}
              >
                {getI18nText(item, 'category_name', reducersState.app.language)}
              </div>
            ))
          }
          {
            data.length > 0 && type === StoreFilterModalContentType.CATEGORY &&
            (data as IStoreCategory[]).map(item => (
              <div
                key={`${item.id}`}
                className={`filter-block ${category.has(item.id) ? 'current' : ''}`}
                onClick={() => handleCategory(item.id)}
              >
                {getI18nText(item, 'category_name', reducersState.app.language)}
              </div>
            ))
          }
          {
            data.length > 0 && type === StoreFilterModalContentType.DISTRICT &&
            (data as IStoreLocation[]).map(item => (
              <div
                key={`${item.id}`}
                className={`filter-block ${district.has(item.id) ? 'current' : ''}`}
                onClick={() => handleDistrict(item.id)}
              >
                {getI18nText(item, 'district', reducersState.app.language) || item.district}
              </div>
            ))
          }
          {
            data.length > 0 && type === StoreFilterModalContentType.PRICE &&
            (data as number[][] ).map((item, index) => (
              <div
                key={`${item}_${index}`}
                className={`filter-block ${item[0] === prices[0] ? 'current' : ''}`}
                onClick={() => handlePrice(item)}
              >
                {
                  item.length > 0 &&
                  <div>
                    <span>{item[0]}</span>
                    ~
                    <span>{item[1]}</span>
                  </div>
                }
                {
                  item.length === 0 &&
                  getIntl().common.any
                }
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}
export default StoreFilterModalContent;
