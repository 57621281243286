import React from 'react';
import { useLocation, Switch } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
// import { pathToRegexp } from 'path-to-regexp';
import * as H from 'history';
// import routes from '@/routes';
import TransitionKeys from './Keys';

import './PageTransition.less';
import { getPageRecoverableData, setPageBeingAnimated } from '../App/App';

// const FORWARD = 'forward';
// const BACKWORD = 'backward';

// const locationKeys: string[] = [];
// let oldLocation: H.Location<H.History.PoorMansUnknown> | undefined = undefined;
let transitionDomRect: DOMRect | undefined = undefined;
let pageAnimationType: string = TransitionKeys.FADE_FROM_RIGHT;

export const setPageAnimationType = (type: string) => {
  pageAnimationType = type;
}

export const getTransitionConfig = (_location: H.Location<H.History.PoorMansUnknown>) => {
  const config = {
    forward: TransitionKeys.MOVE_FROM_RIGHT,
    backward: TransitionKeys.MOVE_FROM_LEFT,
  };
  // const matchedRoute = routes.find(config => {
  //   const re = pathToRegexp(config.path);
  //   const match2 = re.exec(location.pathname);
  //   return Array.isArray(match2) && match2.length > 0;
  // });

  // if (matchedRoute && matchedRoute.transition) {
  //   config.forward = matchedRoute.transition.forward || config.forward;
  //   config.backward = matchedRoute.transition.backward || config.backward;
  // }

  return config;
};

// const getPageAction = (location: H.Location<H.History.PoorMansUnknown>) => {
//   let pageAction = FORWARD;
//   const length = locationKeys.length;
//   const key = location.pathname;

//   if (key && key !== locationKeys[length - 1]) {
//     if (length === 0) {
//       locationKeys.push(key);
//     } else if (key === locationKeys[length - 2]) {
//       locationKeys.pop();
//       pageAction = BACKWORD;
//     } else {
//       locationKeys.push(key);
//       pageAction = FORWARD;
//     }
//   }

//   return pageAction;
// }
interface IPageTransitionProps {
  className?: string;
}

const PageTransition: React.FC<IPageTransitionProps> = props => {
  const { children, className } = props;
  const location = useLocation();
  // const pageAction = getPageAction(location);
  
  let classNames = '';
  const actionKey = pageAnimationType;
  // if (pageAction === FORWARD) {
  //   actionKey = getTransitionConfig(location).forward;
  // } else if(pageAction === BACKWORD && oldLocation) {
  //   actionKey = getTransitionConfig(oldLocation).backward;
  // }

  if (actionKey) {
    classNames = `bm-c-animate-${actionKey}`;
  }

  // oldLocation = location;

  const handleEnter = (node: any) => {
    setPageBeingAnimated(true);
    if (!transitionDomRect) return;
    if (actionKey === TransitionKeys.MATERIAL_FORWARD) {
      node.style.transform = `translate3d(0, ${transitionDomRect.top}px, 0)`;
      node.style.width = 'auto';
      node.style.left = `${transitionDomRect.left}px`;
      node.style.right = `${transitionDomRect.right}px`;
      node.style.height = `${transitionDomRect.height}px`;
      node.style.minHeight = 'auto';
    } else if (actionKey === TransitionKeys.REVEAL_FORWARD) {
      node.style.transform = `translate3d(0, ${transitionDomRect.top}px, 0)`;
      node.style.left = `${transitionDomRect.left}px`;
      node.style.width = `${transitionDomRect.width}px`;
      node.style.height = `${transitionDomRect.height}px`;
      node.style.borderRadius = `${transitionDomRect.width * 2}px`;
      node.style.minHeight = 'auto';
    }
  }

  const handleEntering = (node: any) => {
    if (!transitionDomRect) return;
    if (actionKey === TransitionKeys.MATERIAL_FORWARD) {
      node.style.transform = 'translate3d(0, 0, 0)';
      node.style.width = 'auto';
      node.style.left = 0;
      node.style.right = 0;
      node.style.height = '100%';
      node.style.minHeight = 'auto';
    } else if (actionKey === TransitionKeys.REVEAL_FORWARD) {
      node.style.transform = 'translate3d(0, 0, 0)';
      node.style.left = 0;
      node.style.width = '100%';
      node.style.height = '100%';
      node.style.minHeight = 'auto';
      node.style.borderRadius = 0;
    }
  }

  const handleEntered = (node: any) => {
    setPageBeingAnimated(false);
    const { pathname } = window.location;
    const {
      scrollY = 0,
    } = getPageRecoverableData(pathname);
    if (pathname === '/' || pathname === '/stores' || pathname.includes('/stores/by/') || pathname.includes('/menus')) {
      window.scroll(0, scrollY);
    } else {
      window.scroll(0, 0);
    }
    if (!transitionDomRect) return;
    if (actionKey === TransitionKeys.MATERIAL_FORWARD) {
      node.style.transform = null;
      node.style.width = null;
      node.style.left = null;
      node.style.right = null;
      node.style.height = null;
      node.style.minHeight = null;
    } else if (actionKey === TransitionKeys.REVEAL_FORWARD) {
      node.style.transform = null;
      node.style.left = null;
      node.style.width = null;
      node.style.height = null;
      node.style.minHeight = null;
      node.style.borderRadius = null;
    }
  }

  return (
    <TransitionGroup
      className={`router-wrapper ${className || ''}`}
      childFactory={(child: any) => React.cloneElement(child, {classNames})}
    >
      <CSSTransition
        timeout={500}
        key={location.pathname}
        onEnter={handleEnter}
        onEntering={handleEntering}
        onEntered={handleEntered}
      >
        <Switch location={location}>{children}</Switch>
      </CSSTransition>
    </TransitionGroup>
  )
}

export default PageTransition;

export const setDomRect = (domRect: DOMRect | undefined) => {
  transitionDomRect = domRect;
}
