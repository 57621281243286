import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import withRoute from '@/pages/withRoute';
import StoresByBrandPage from './StoresByBrandPage';
import './StoresByBrandPage.less';

export const prefix = 'bm-p-stores-by-brand';

const RouteComponent: React.FC<RouteComponentProps> = props => withRoute({
  pageComponent: StoresByBrandPage,
  routeProps: props,
  pageTitleI18nKey: '',
});

export default RouteComponent;
