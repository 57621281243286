import React from 'react';
import Icon, { IconType } from '@/components/Icon';
interface SideNavItemProps {
  msg: React.ReactNode;
  onItemClick: () => void;
  iconType?: IconType;
  selected: boolean;
}

const SideNavItem: React.FC<SideNavItemProps> = props => {
  const { iconType, msg, onItemClick, selected } = props;
  return (
    <div  className={`link-block-8 w-inline-block  ${selected? 'w--current': ''}`} onClick={onItemClick}>
      {
        iconType &&
        <Icon type={iconType} className="html-embed-30 w-embed"/>
      }
      <div>{msg}</div>
    </div>
  )
}
export default SideNavItem;
