import { RouteComponentProps } from 'react-router-dom';
import withRoute from '@/pages/withRoute';
import PayResultsWithNoOrderInfoPage from './PayResultsWithNoOrderInfoPage';
import './PayResultsWithNoOrderInfoPage.less';

export const prefix = 'bm-p-pay-no-order-results';

const RouteComponent: React.FC<RouteComponentProps> = props => withRoute({
  pageComponent: PayResultsWithNoOrderInfoPage,
  routeProps: props,
  pageTitleI18nKey: '',
});
export default RouteComponent;
