import styled from 'styled-components';
export const DeviceInfoPageStyled = styled.div`
.section {
  display: block;
  margin-top: auto;
  margin-bottom: 0px;
  padding: 100px 40px 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.div-block-549 {
  color: var(--bm-text-title-color);
}
.device-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 25px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-bottom: 1px solid var(--bm-border-color);
  font-size: 16px;
}
.text-block-214 {
  margin-right: 5px;
  font-size: 16px;
}
.section.cms {
  position: relative;
  display: block;
  margin-top: 0px;
  margin-bottom: 40px;
  padding: 0px;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}
@media screen and (max-width: 991px) {
  .section {
    margin-top: 0px;
    padding: 84px 35px 30px;
  }
  .section.cms {
    width: auto;
    min-width: auto;
    margin-top: 20px;
    margin-bottom: 20px;  
  }
}

@media screen and (max-width: 767px) {
  .section {
    padding: 66px 25px 20px;
  }
  .device-info {
    padding: 15px;
  }
  .text-block-214 {
    font-size: 14px;
  }
  .section.cms {
    margin-top: 57px;
    margin-right: 0px;
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media screen and (max-width: 479px) {
  .section {
    padding: 68px 12px 15px;
  }
  .section.cms {
    padding-right: 0px;
    padding-left: 0px;
  }
}
`;
