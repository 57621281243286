
import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import { IObject } from '@/components/bm-common';
import { getAppTheme, setScreenColor, getIntl } from '@/components/App/App';
import Layout from '@/components/Layout/Layout';
import { prefix } from '.';
import BmTabBar from '@/components/BmTabBar';
import ActionType from '@/actions/action-type';
import Constants from '@/constants';
import TabsOption from '@/actions/tabs-option';
import Icon, { IconType } from '@/components/Icon';
import SideBar from '@/components/SideBar';
import AccountOption from '@/actions/account-option';
import { toWxMiniProgram, stringToBase64QrCode, getAccessToken } from '@/utils/app';
import { TransitionKeys } from '@/components/Transition';
import QRCodeScan from '@/components/QRCodeScan';
import { earnPoints } from '@/data/Point';
import { parseScanUrl, findNode } from '@/utils';
import Modal from 'antd-mobile/es/modal';
import { IPlatformCustomPage } from '@/actions/app-action';
import { resizeImage } from '@/utils';
import paths from '@/routes/paths';
import { goToRoute } from '@/utils/route-util';
import { MyIDPageStyled } from './styled';

export interface IMyIDPageProps extends IBasePageProps { }
export interface IMyIDPageState {
  showQRCode: boolean;
  customerID: string;
  showSideBar: boolean;
  customerCode?: string;
  showQRCodeScan: boolean;
  updateLoyaltyStamp: boolean;
}

class MyIDPage extends BasePage<IMyIDPageProps, IMyIDPageState> {
  routeParams: IObject = {};

  state: IMyIDPageState = {
    showQRCode: false,
    customerID: '',
    showSideBar: false,
    showQRCodeScan: false,
    updateLoyaltyStamp: false,
  }

  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.lightBackgroundColor) {
      setScreenColor('#E3E7F1');
    }
    super.componentDidMount();
    if (getAccessToken()) {
      this.loadMembership();
    }

    (window as any).scanResult = (scanStr: string) => {
      this.handleScan(scanStr);
    }
    (window as any).processScannedCode = (scanStr: string) => {
      this.handleScan(scanStr);
    }
  }

  componentWillUnmount() {
    delete (window as any).scanResult;
    delete (window as any).processScannedCode;
    super.componentWillUnmount();
  }

  handleScan = (value = '') => {
    const { history } = this.props;
    const parseResult = parseScanUrl(value);
    if (value.startsWith('http') && !parseResult.searchParams.order_number) {
      const info = parseScanUrl(value);
      if (info.isSameEvn && info.isSamePlatform && info.platformRoute && history) {
        goToRoute({
          history,
          pathname: info.platformRoute,
        });
      } else {
        window.location.href = value;
      }
    } else {
      this.handleScanSuccess(value);
    }
  }

  loadMembership = () => {
    const { dispatch, reducersState } = this.props;
    const { platformConfig } = reducersState.app;
    const { storeSlug, storeID } = platformConfig;
    const { updateLoyaltyStamp } = this.state;

    dispatch({
      type: ActionType.QUERY_CUSTOMER,
      storeSlug,
      callback: customer => {
        if (customer) {
          this.setState({
            customerID: customer.id,
            customerCode: customer.customerCode,
          })
          if (!platformConfig.showSideBar) {
            dispatch({
              type: ActionType.QUERY_CUSTOMER_GROWTH,
              storeSlug,
              storeID,
              customerID: customer.id,
              updateLoyaltyStamp,
              callback: membershipData => {
              }
            });
          }
        }
      }
    });
  }

  onOpenChange = () => {
    const { reducersState } = this.props;
    const { platformConfig } = reducersState.app;
    if (platformConfig.showSideBar) {
      this.setState({
        showSideBar: !this.state.showSideBar,
      });
    }
  }

  handleShowSideBar = () => {
    this.setState({
      showSideBar: !this.state.showSideBar,
    });
  }
  handleGoMemberGuide = () => {
    this.handleGotoRoute({
      path: paths.POINTS,
    });
  }

  SignIn = () => {
    const { reducersState } = this.props;
    const { deviceInfo } = reducersState.app;
    if (deviceInfo.weChatMiniProgram) {
      toWxMiniProgram({
        miniProgramPath: '/pages/authorization/authorization',
        data: {
          wxPageType: 'ACCOUNT',
        },
      });
    } else {
      this.handleGotoRoute({
        path: paths.SIGN_IN,
        animationType: TransitionKeys.FADE_FROM_BOTTOM,
      })
    }
  }

  handleScanClick = () => {
    const token = getAccessToken();
    if (!token) {
      this.SignIn();
      return;
    }

    const { reducersState } = this.props;
    const { deviceInfo } = reducersState.app;

    const bindoUtils: any = (window as any).bindo_utils;
    if (deviceInfo.isAndroid && bindoUtils && bindoUtils.openCameraScan) {
      bindoUtils.openCameraScan();
    } else if (deviceInfo.isAppleApp && (window as any).webkit && (window as any).webkit.messageHandlers && (window as any).webkit.messageHandlers) {
      (window as any).webkit.messageHandlers.openCodeScanner.postMessage('openCodeScanner');
    } else {
      this.setState({ showQRCodeScan: true });
    }
  }

  handleScanSuccess = async(value: string) => {
    const parseResult = parseScanUrl(value);
    if (parseResult.searchParams && parseResult.searchParams.order_number) {
      const state = await earnPoints(this.state.customerID, parseResult.searchParams.order_number);
      if (state && state.success) {
        this.setState({
          showQRCodeScan: false,
          updateLoyaltyStamp: true,
        }, () => {
          this.loadMembership();
          Modal.alert('', getIntl().page.earnPointsSuccess, [
            {
              text: getIntl().page.ok,
              onPress: () => {
              },
            }
          ]);
        });
      } else {
        this.setState({ showQRCodeScan: false });
        Modal.alert(getIntl().common.tips, getIntl().page.earnPointsFail, [
          {
            text: getIntl().page.ok,
            onPress: () => {},
          }
        ]);
      }
    }
  }

  handleScanFail = () => {
    this.setState({ showQRCodeScan: false });
  }

  render() {
    const { reducersState, history } = this.props;
    const { platformConfig, platformCustomPages } = reducersState.app;
    const stringUserInfo = localStorage.getItem(Constants.USER_INFO);
    let userInfo = null;
    if (stringUserInfo) {
      userInfo = JSON.parse(stringUserInfo);
    }
    const { showSideBar, accountOptions } = platformConfig;
    const { customerID, customerCode, showQRCodeScan } = this.state;
    const backgroundRecord = findNode<IPlatformCustomPage>(platformCustomPages, 'type', 'BACKGROUND');
    let backgroundUrl = '';
    if (backgroundRecord && backgroundRecord.images.length > 0) {
      backgroundUrl = resizeImage({
        url: backgroundRecord.images[0],
        type: 'DEFAULT',
      })
    }
    return (
      <>
        <Layout
          {...this.props}
          className={`${prefix} ${platformConfig.colorInversion ? 'my-id-primary' : ''}`}
          navContent={getIntl().page.myIDTitle}
          navIcon={showSideBar && <Icon className="side-bar-icon html-embed-7 w-embed" type={IconType.MENUS_OUTLINED} />}
          onLeftClick={() => { this.onOpenChange(); }}
          navRightContent={
            <>
              {
                accountOptions.includes(AccountOption.SCAN_CODE) &&
                <div className="nav-right" onClick={this.handleScanClick} >
                  <Icon className="html-embed-7 w-embed side-bar-icon" type={IconType.SCAN_OUTLINED} />
                </div>
              }
            </>
          }
        >
          <MyIDPageStyled>
            <div className="section cms wf-section" style={{ backgroundImage: `url(${backgroundUrl})`}}>
              <div className="container w-container">
                <div className="div-block-606">
                  <div className="div-block-608">
                    <h2 className="h2 black-1">{getIntl().page.scanYourQRCode}</h2>
                  </div>
                  {
                    userInfo &&
                    <div className="my-id-cart">
                      <div className="div-block-468-copy w-inline-block">
                        {
                          userInfo.avatarUrl &&
                          <div className="dp_big my-id" style={{ backgroundImage: `url(${userInfo.avatarUrl})` }}></div>
                        }
                        {
                          !userInfo.avatarUrl &&
                          <div className="dp_big my-id default-avatar-outlined">
                            <Icon
                              type={IconType.DEFAULT_AVATAR_OUTLINED}
                              className="avatar-icon"
                            />
                          </div>
                        }
                        <div className="div-block-465 my-id">
                          <div className="div_block200">{userInfo.name}</div>
                          {/* <div className="profile_birthday"></div> */}
                        </div>
                      </div>
                      <div className="div-block-607">
                        <div className="my-id-qr-wrapper">
                          <div className="my-id-qr-code" style={{ backgroundImage: `url(${stringToBase64QrCode(customerCode ? `CID-${customerCode}` : `CID-${customerID}`)})`}}></div>
                        </div>
                      </div>
                    </div>
                  }
                  <div className="text-block-91 streched">
                    {getIntl().page.knowMore}
                    <strong className="bold-text-6">{getIntl().page.dollars}</strong>
                  </div>
                  <div className="div-block-609 w-inline-block">
                    <div className="text-block-91 bold" onClick={() => this.handleGoMemberGuide()}>{getIntl().page.clickHere}</div>
                  </div>
                </div>
              </div>
            </div>
          </MyIDPageStyled>
        </Layout>
        {
          platformConfig.showTabs &&
          <BmTabBar
            selectedKey={TabsOption.MY_ID}
            reducersState={reducersState}
          />
        }
        {
          showSideBar &&
          <SideBar
            showSideBar={this.state.showSideBar}
            onItemClick={this.handleShowSideBar}
            reducersState={reducersState}
          />
        }
        {
          showQRCodeScan &&
          <QRCodeScan
            history={history}
            onSuccess={this.handleScanSuccess}
            onFail={this.handleScanFail}
          />
        }
      </>
    );
  }
}

export default MyIDPage;
