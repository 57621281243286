import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import { prefix } from '.';
import ActionType from '@/actions/action-type';
import StoreList from '@/components/StoreList';
import { getAppTheme, setScreenColor } from '@/components/App/App';
import Layout from '@/components/Layout/Layout';
import { IStore } from '@/actions/store-action';
import { parseRouteParams } from '@/utils';
import paths from '@/routes/paths';
import Icon, { IconType } from '@/components/Icon';
import { IObject } from '@/components/bm-common';
import BmTabBar from '@/components/BmTabBar';
import ShoppingCartBar from '@/components/ShoppingCartBar';
import MiniProgramTips from '@/components/MiniProgramTips';
import { setCanAutoProcurementMethodPopup } from '../MenuListPage/MenuListPage';
import TabsOption from '@/actions/tabs-option';
import { StoreGroupPageStyled } from './styled';

export interface IStoreGroupPageProps extends IBasePageProps {
  isHomePage?: boolean;
  groupStore?: IStore;
  stores?: IStore[];
}

export interface IStoreGroupPageState {
  storeSlug: string;
  loadingStores: boolean;
  groupStore: IStore | undefined;
  stores: IStore[];
}

class StoreGroupPage extends BasePage<IStoreGroupPageProps, IStoreGroupPageState> {
  constructor(props: IStoreGroupPageProps) {
    super(props);
    const { groupStore, stores = [], isHomePage = false } = props;
    const routeParams = parseRouteParams(props);
    let storeSlug = '';
    if (routeParams && routeParams.storeSlug) {
      storeSlug = routeParams.storeSlug;
    } else if (groupStore) {
      storeSlug = groupStore.slug;
    }

    if (storeSlug) {
      this.getStoreShoppingCartData(storeSlug);
    }

    this.state = {
      storeSlug,
      loadingStores: !isHomePage,
      groupStore,
      stores,
    }
  }

  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.darkBackgroundColor) {
      setScreenColor(appTheme.darkBackgroundColor);
    }

    super.componentDidMount();

    const { isHomePage = false, dispatch } = this.props;
    const { storeSlug } = this.state;

    if (!isHomePage && storeSlug) {
      dispatch({
        type: ActionType.QUERY_GROUP_STORES,
        storeSlug,
        callback: (stores, groupStore) => {
          this.setState({
            loadingStores: false,
            stores,
            groupStore,
          });
          this.closeLoading();
        }
      });
    } else {
      this.closeLoading();
    }
  }

  getStoreShoppingCartData = (storeSlug: string) => {
    const { dispatch } = this.props;
    dispatch({
      type: ActionType.GET_STORE_SHOPPING_CART_DATA,
      storeSlug,
    });
  }

  handleItemClick = (data: IStore) => {
    setCanAutoProcurementMethodPopup(true);
    this.handleGotoRoute({
      path: paths.MENU_LIST,
      pathParams: {
        storeSlug: data.slug,
      },
    });
  }

  render() {
    const { isHomePage, reducersState } = this.props;
    const { loadingStores, stores, groupStore } = this.state;
    const { name = '' } = groupStore || {};
    const { platformConfig, deviceInfo } = reducersState.app;

    const layoutProps: IObject = {};

    layoutProps.navContent = name;
    if (!isHomePage) {
      layoutProps.navIcon = <Icon className="html-embed-7 w-embed left-circle" type={IconType.LEFT_OUTLINED} />;
      layoutProps.onLeftClick = () => this.handleGoBack({
        path: paths.HOME,
      });
    }

    return (
      <>
        <Layout
          {...this.props}
          {...layoutProps}
          className={prefix}
          isFooter={false}
        >
          { deviceInfo.weChatMiniProgram && isHomePage &&
            <MiniProgramTips />
          }
          <StoreGroupPageStyled>
            <StoreList
              data={stores}
              loading={loadingStores}
              reducersState={reducersState}
              itemStyle="GROUP_GRID"
              onItemClick={this.handleItemClick}
            />
          </StoreGroupPageStyled>
        </Layout>
        {
          groupStore &&
          <ShoppingCartBar
            store={groupStore}
            reducersState={reducersState}
            className={isHomePage ? 'has-tab-bar' : ''}
            currencyCode={groupStore.currencyCode}
          />
        }
        {
          isHomePage && platformConfig.showTabs &&
          <BmTabBar
            selectedKey={TabsOption.STORE}
            reducersState={reducersState}
          />
        }
      </>
    );
  }
}

export default StoreGroupPage;
