import Config from '@/Config';
import axios from 'axios';
import { queryRecords } from './mb-api';

export const getPoints = async (formula: string, page = 1, perPage = 20) => {
  const result = await queryRecords({
    storeSlugs: [Config.bindoGatewayStoreSlug],
    moduleBundleID: 'bm-loyalty-histories',
    search: {
      formulas:[{formula: formula}],
      page,
      perPage,
    },
  });

  return result.records;
}

export const earnPoints = async (customerID: string, orderNumber: string) => {

  let success = false;
  try {
    const resp = await axios({
      method: 'post',
      url: Config.urls.earnPoints.replace('{customerID}', customerID),
      data: {
        'order_number': orderNumber,
      }
    });

    if (resp.success) {
      success = true;
    }

    const {
      data,
    } = resp || {};

    const {
      message
    } = data || {};

    return {
      success,
      message,
    };
  } catch (error) {
    console.error(error)
  }
}
