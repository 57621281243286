import React from 'react';
import { IBasePageProps } from '@/pages/BasePage';
import OrderDetailPage from '../OrderDetailPage';
import NewOrderDetailPage from '../NewOrderDetailPage';

const OrderDetail: React.FC<IBasePageProps> = props => {
  const { reducersState } = props;
  const { platformAppSettings } = reducersState.app;

  if (!platformAppSettings) {
    return null;
  }
  return (
    <>
      {
        platformAppSettings.orderStyle === '0' &&
        <OrderDetailPage {...props}/>
      }
      {
        platformAppSettings.orderStyle === '1' &&
        <NewOrderDetailPage {...props}/>
      }
    </>
  )
}

export default OrderDetail;

