import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import ForgotPasword  from '@/components/ForgotPasword';
import paths from '@/routes/paths';
import Icon, { IconType } from '@/components/Icon';
import { getAppTheme, setScreenColor, getIntl } from '@/components/App/App';
import { prefix } from '.';
import Layout from '@/components/Layout/Layout';

export interface IForgotPasswordPageProps extends IBasePageProps {}

class ForgotPasswordPage extends BasePage<IForgotPasswordPageProps> {

  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.primaryColor && appTheme.lightBackgroundColor) {
      setScreenColor(appTheme.primaryColor, appTheme.lightBackgroundColor);
    }
    super.componentDidMount();
  }

  restPassword = () => {
    this.handleGoBack({
      path: paths.SIGN_IN,
      runGoBack: true,
    });
  }

  render () {
    return (
      <Layout
        {...this.props}
        navIcon={<Icon className="sign-in-back" type={IconType.LEFT_OUTLINED} />}
        navContent={getIntl().page.forgotPassword}
        onLeftClick={() => this.handleGoBack({
          path: paths.SETTING,
          runGoBack: true,
        })}
        className={prefix}
        isFooter={false}
      >
        <ForgotPasword
          restInClick={this.restPassword}
        />
      </Layout>
    );
  }
}

export default ForgotPasswordPage;

