import { takeEvery, call, take, race, fork, select, put } from 'redux-saga/effects';
import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import Config from '@/Config';
import ActionType from '@/actions/action-type';
import {
  IQueryQRSInvoice, IQueryQRSModifyInvoice,
} from '@/actions/qrcord-shopping-action';
import { handleQueryStore } from './store-saga';
import { IReducersState } from '@/reducers';
import ShippingMethod from '@/lib/order-builder/enums/ShippingMethod';
import { initInvoiceInfo } from '@/reducers/qrcord-shopping-reducer';
import OrderBuilder from '@/lib/order-builder';
import { handleQueryStoresListings } from './listing-saga';
import { IShoppingCartData } from '@/actions/shopping-cart-action';
import Action from '@/actions';
import { IOrderInfo, ITaxOption } from '@/lib/order-builder/models/OrderEntity';
import { IObject } from '@/components/bm-common';
import LineItemEntity from '@/lib/order-builder/models/LineItemEntity';
import { handleQueryStoreTaxOptions, handleQueryStoreRoundingOptions } from './order-saga';

let taskSyncErrorCallback: (data: IObject) => void | undefined;

export const setTaskSyncErrorCallback = (callback: (data: IObject) => void) => {
  taskSyncErrorCallback = callback;
}

const getLineItemsSnapshot = (lineItems: LineItemEntity[]) => {
  const lineItemsSnapshot: IObject[] = [];
  if (Array.isArray(lineItems)) {
    lineItems.forEach(item => {
      lineItemsSnapshot.push({
        id: item.id,
        price: item.price,
        quantity: item.quantity,
        subtotal: item.subtotal,
        total: item.total,
        imageUrl: item.imageUrl,
        name: item.name,
      })
    });
  }

  return lineItemsSnapshot;
}

const getOrderInfoSnapshot = (orderInfo?: IOrderInfo) => {
  const snapshot: IObject = {};
  if (orderInfo) {
    snapshot.storeID = orderInfo.storeID;
    snapshot.storeSlug = orderInfo.storeSlug;
    snapshot.initialTotal = orderInfo.initialTotal;
    snapshot.subTotal = orderInfo.subTotal;

    if (orderInfo.orderEntities && orderInfo.orderEntities.length > 0) {
      snapshot.orderEntitiesLength = orderInfo.orderEntities.length;

      snapshot.orderEntities = [];
      orderInfo.orderEntities.forEach(item => {
        snapshot.orderEntities.push({
          subtotal: item.subtotal,
          initialTotal: item.initialTotal,
          lineItemsSnapshot: getLineItemsSnapshot(item.lineItems),
        })
      })
    }
  }

  return snapshot;
}

export function* handleQueryQRSInvoice(params: IQueryQRSInvoice) {
  const {
    storeSlug,
    orderNumber,
    authCode,
    // 校验更新数据，如果为True，校验数据是否一致，如果一致，则不更新数据
    /**
     * 校验更新数据
     * true: 校验数据不一致，则更新数据
     * false: 直接更新数据
     */
    validationUpdate = false,
  } = params;

  const state: IReducersState = yield select((state: IReducersState) => state);
  const { invoiceInfo: oldInvoiceInfo, orderInfos } = state.qrcordShopping;

  try {
    const { store } = yield handleQueryStore({
      type: ActionType.QUERY_STORE,
      storeSlug,
    })

    if (store && store.id) {
      const storeID: string = store.id;
      const customerOrderRes = yield call(
        axios.get,
        Config.urls.qrShopingCustomerOrder.replace('{storeID}', storeID).replace('{orderNumber}', orderNumber).replace('{authCode}', authCode),
      );

      const { data } = customerOrderRes || {};
      const { message } = data || {};

      if (message === 'Order Number and Auth Code not match.') {
        if (taskSyncErrorCallback) {
          taskSyncErrorCallback({message: 'Order Number and Auth Code not match.'});
        }

        yield put<Action>({
          type: ActionType.CANCEL_QRCODE_SHOPPING_INVOICE_TASK_SYNC,
        });
      }

      if (customerOrderRes && customerOrderRes.invoice) {
        const submittedOrderData: any = customerOrderRes.invoice;
        const invoiceInfo = initInvoiceInfo();
        if (submittedOrderData.first_party_details) {
          invoiceInfo.numberOfPeople = submittedOrderData.first_party_details.number_of_people || 0;
          invoiceInfo.orderNumber = submittedOrderData.first_party_details.order_number || orderNumber;
          invoiceInfo.orderReferenceNumber = submittedOrderData.first_party_details.order_reference_number || '';
          invoiceInfo.seatedAt = submittedOrderData.first_party_details.seated_at || '';
          invoiceInfo.tableName = submittedOrderData.first_party_details.table_name || '';
          invoiceInfo.tableID = submittedOrderData.first_party_details.table_id || '';
          invoiceInfo.turnTime = submittedOrderData.first_party_details.turn_time || '';
          invoiceInfo.tableSplit = submittedOrderData.first_party_details.table_split || '';
          invoiceInfo.state = submittedOrderData.state || invoiceInfo.state;
          invoiceInfo.status = submittedOrderData.first_party_details.status || '';
          invoiceInfo.submittedUpdatedAt = submittedOrderData.first_party_details.updated_at || '';
          invoiceInfo.currencyCode = store.currencyCode;
        }

        submittedOrderData['store_id'] = storeID;
        submittedOrderData['store_slug'] = storeSlug;
        submittedOrderData['store_title'] = store.name;
        submittedOrderData.number = store.orderNumber;
        submittedOrderData['paid_total'] = 0;
        submittedOrderData.currency = store.currencyCode;
        submittedOrderData.storeName = store.name;
        submittedOrderData['shipping_method'] = ShippingMethod.QRCODE_SHOPPING;
        submittedOrderData['reference_number'] = invoiceInfo.orderReferenceNumber;
        submittedOrderData['favorite_id'] = 'qrcode_shopping'; // 扫码购物设置特殊值
        submittedOrderData.party = {
          numberOfPeople: invoiceInfo.numberOfPeople,
          status: invoiceInfo.status,
          tableID: invoiceInfo.tableID,
          tableName: invoiceInfo.tableName,
          turnTime: invoiceInfo.turnTime,
        };

        if (!Array.isArray(submittedOrderData.listing_line_items)) {
          submittedOrderData['listing_line_items'] = [];
        }

        submittedOrderData['listing_line_items'].forEach((item: any) => {
          if (item && item.updated_at && item.updated_at > invoiceInfo.submittedUpdatedAt) {
            invoiceInfo.submittedUpdatedAt = item.updated_at;
          }
        })

        const storeIDs = OrderBuilder.getStoreIDsByOrderApiData(submittedOrderData);
        if (Array.isArray(storeIDs) && storeIDs.length > 0) {
          yield handleQueryStoresListings({
            type: ActionType.QUERY_STORES_LISTINGS,
            storeIDs,
          });
        }

        const taxOptions: ITaxOption[] = yield handleQueryStoreTaxOptions({
          type: ActionType.QUERY_STORE_TAX_OPTIONS,
          storeSlug: store.slug,
        });

        const roundingOption: any = yield handleQueryStoreRoundingOptions({
          type: ActionType.QUERY_STORE_ROUNDING_OPTIONS,
          storeSlug: store.slug,
        });

        let shoppingCartData: IShoppingCartData = OrderBuilder.initStoreOrderEntityByOrderApiData({
          orderData: submittedOrderData,
          type: 'NEXT_ORDER',
          taxOptions,
          roundingOption,
        });
        const submittedOrderInfo = shoppingCartData.orderInfo;

        const amendmentsOrderRes = yield call(
          axios.get,
          Config.urls.qrShopingAmendmentsOrder.replace('{storeID}', storeID).replace('{orderNumber}', orderNumber).replace('{authCode}', authCode),
        );

        const orderAmendmentItems: any[] = [];

        if (amendmentsOrderRes && Array.isArray(amendmentsOrderRes.order_amendments)) {
          const orderAmendments: any[] = amendmentsOrderRes.order_amendments
          orderAmendments.forEach(item => {
            if (item.state !== 'merged' && Array.isArray(item.order_amendment_items)) {
              orderAmendmentItems.push(...item.order_amendment_items);
            }

            if (item.updated_at && item.updated_at > invoiceInfo.pendingUpdatedAt) {
              invoiceInfo.pendingUpdatedAt = item.updated_at;
            }
          })
        }

        const pendingOrderData = cloneDeep(submittedOrderData);
        pendingOrderData['initial_service_fee'] = 0;
        pendingOrderData['initial_tax'] = 0;
        pendingOrderData['listing_line_items'] = orderAmendmentItems;

        shoppingCartData = OrderBuilder.initStoreOrderEntityByOrderApiData({
          orderData: pendingOrderData,
          type: 'NEXT_ORDER',
          taxOptions,
          roundingOption,
        });
        const pendingOrderInfo = shoppingCartData.orderInfo;

        const submittedOrderInfoSnapshot = getOrderInfoSnapshot(submittedOrderInfo);
        const oldSubmittedOrderInfoSnapshot = getOrderInfoSnapshot(orderInfos.submittedOrderInfo);
        const pendingOrderInfoSnapshot = getOrderInfoSnapshot(pendingOrderInfo);
        const oldPendingOrderInfoSnapshot = getOrderInfoSnapshot(orderInfos.pendingOrderInfo);

        if (
          !isEqual(submittedOrderInfoSnapshot, oldSubmittedOrderInfoSnapshot)
          || !isEqual(pendingOrderInfoSnapshot, oldPendingOrderInfoSnapshot)
        ) {
          yield put<Action>({
            type: ActionType.SET_QRCODE_SHOPPING_ORDER_INFOS,
            submittedOrderInfo,
            pendingOrderInfo,
          });
        }

        if (!isEqual(invoiceInfo, oldInvoiceInfo)) {
          yield put<Action>({
            type: ActionType.SET_QRCODE_SHOPPING_INVOICE_INFO,
            invoiceInfo,
          });
        }
      }
    }

    if (validationUpdate) {

    }

  } catch (error) {
    console.error(error);
  }
}

export function* handleQueryQRSModifyInvoice(params: IQueryQRSModifyInvoice) {
  const { callback } = params;
  const state: IReducersState = yield select((state: IReducersState) => state);
  const { storeSlug, orderNumber, authCode } = state.qrcordShopping;
  const { storeIDRefShoppingCartData } = state.cart;

  let result: 'SUCCESS' | 'FAILED' | 'SHOPPING_CART_EMPTY' = 'SHOPPING_CART_EMPTY';

  if (!storeSlug || !orderNumber || !authCode) {
    if (callback) callback(result);
  }

  try {
    const orderAmendmentItems: any[] = [];

    const { store } = yield handleQueryStore({
      type: ActionType.QUERY_STORE,
      storeSlug,
    })

    let shoppingCartData: IShoppingCartData | undefined;
    if (store && store.id) {
      shoppingCartData = storeIDRefShoppingCartData[store.id];
    }

    if (shoppingCartData && shoppingCartData.orderInfo) {
      const orderData = shoppingCartData.orderData;

      if (orderData && Array.isArray(orderData.line_items)) {
        orderData.line_items.forEach(item => {
          const newItem: any = pick(item, [
            'discount_entries',
            'label',
            'modifier_entries',
            'price',
            'purchasable_id',
            'purchasable_type',
            'quantity',
            'image_url',
            'group_id',
            'service_fee',
            'tax_option_entries',
            'subtotal',
            'modifier_set_option_id',
          ]);
          if (item.modifier_entries.length > 0) {
            newItem['original_price'] = newItem.price;
            newItem.price = newItem.subtotal / item.quantity;
          }


          orderAmendmentItems.push(newItem)
        })
      }
    }

    if (orderAmendmentItems.length > 0) {
      const res = yield call(
        axios.post,
        Config.urls.postQrShopingAmendmentsOrder.replace('{storeID}', store.id),
        {
          'auth_code': authCode,
          'order_number': orderNumber,
          'order_amendment': {
            'note': '',
            'from': 'bindo-mobile',
            'order_amendment_items': orderAmendmentItems,
          },
        },
      );

      if (res && res.order_amendment && res.order_amendment.id) {
        // 清空购物车
        yield put<Action>({
          type: ActionType.CLEAR_STORES_SHOPPING_CART,
          storeIDs: [store.id],
        });
        // 自动同步一次数据
        yield handleQueryQRSInvoice({
          type: ActionType.QUERY_QRCODE_SHOPPING_INVOICE,
          storeSlug,
          orderNumber,
          authCode,
        });
        result = 'SUCCESS';
      }
    }
  } catch (error) {
    result = 'FAILED';
    console.error(error);
  }

  if (callback) callback(result);
}

const delay = (millis: number) => new Promise(resolve => setTimeout(resolve, millis));

function* runQRSInvoiceTask() {
  while(true) {
    try {
      const state: IReducersState = yield select((state: IReducersState) => state);
      const { storeSlug, orderNumber, authCode } = state.qrcordShopping;
      yield handleQueryQRSInvoice({
        type: ActionType.QUERY_QRCODE_SHOPPING_INVOICE,
        storeSlug,
        orderNumber,
        authCode,
        validationUpdate: true,
      });
      yield call(delay, 10000);
    } catch (error) {
      return;
    }
  }
}

export function* watchQRSInvoiceTask() {
  while (true) {
    yield take(ActionType.START_QRCODE_SHOPPING_INVOICE_TASK_SYNC);
    yield race([
      call(runQRSInvoiceTask),
      take(ActionType.CANCEL_QRCODE_SHOPPING_INVOICE_TASK_SYNC),
    ]);
  }
}

function* qrcordShoppingSaga() {
  yield takeEvery(ActionType.QUERY_QRCODE_SHOPPING_INVOICE, handleQueryQRSInvoice);
  yield takeEvery(ActionType.MODIFY_QRCODE_SHOPPING_INVOICE, handleQueryQRSModifyInvoice);
  yield fork(watchQRSInvoiceTask);
}

export default qrcordShoppingSaga();
