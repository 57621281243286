import styled  from 'styled-components';
export const SettingPageStyled = styled.div`
  width: 85%;
  margin-top: 70px;
  padding: 80px 0px;
  border-radius: 12px;
  background-color: var(--bm-light-background-color);
  .bm-p-privilege {
    background-color: var(--bm-border-color);
    overflow: scroll;
  }
  .div-block-547 {
    width: 85%;
    margin-top: 30px;
    padding: 80px 0px;
    border-radius: 12px;
  }
  .section.settings {
    padding: 0px 80px;
  }
  .section {
    display: block;
    margin-top: auto;
    margin-bottom: 0px;
    padding: 100px 40px 40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0);
  }


  @media screen and (max-width: 991px) {
    margin-left: 40px;
    padding-top: 30px;
    padding-bottom: 30px;
    .div-block-547.payment {
      margin-left: 40px;
      padding-top: 30px;
      padding-bottom: 30px;
    }
    .div-block-547 {
      padding-top: 40px;
      padding-bottom: 40px;
    }
    .section.settings {
      padding-right: 30px;
      padding-left: 30px;
    }
    .section {
      margin-top: 0px;
      padding: 84px 35px 30px;
    }
  }

  @media screen and (max-width: 767px) {
    width: auto;
    margin-top: 0px;
    margin-left: 0px;
    padding-top: 0px;
    padding-right: 20px;
    padding-left: 20px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    .div-block-547.payment {
      width: auto;
      margin-top: 0px;
      margin-left: 0px;
      padding-top: 0px;
      padding-right: 20px;
      padding-left: 20px;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }
    .section.settings {
      margin-right: 0px;
      margin-left: 0px;
      padding-top: 0px;
      padding-right: 0px;
      padding-left: 0px;
      background-color: rgba(255, 255, 255, 0);
    }
    .section {
      padding: 66px 25px 20px;
    }
  }

  @media screen and (max-width: 479px) {
    padding-top: var(--bm-safe-area-top);
    .section.settings {
      padding-top: 0px;
    }
    .section {
      padding: 68px 12px 15px;
    }
  }


`;
