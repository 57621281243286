import styled from 'styled-components';
export const SearchPageStyled = styled.div`
.section {
  display: block;
  margin-top: auto;
  margin-bottom: 0px;
  padding: calc(120px + var(--bm-safe-area-top)) 40px 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--bm-dark-background-color);
}
.w-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
}
.bm-c-search-result-store-list  {
  display: -ms-grid;
  display: grid;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}
.bm-c-search-result {
  .bm-c-store-list {
    display: grid;
  }
  .w-container {
    margin-left: unset;
    margin-right: unset;
    max-width: 940px;
  }
  &-store-list-item {
    width: auto;
    margin-right: unset;
  }
}
.html-embed-32 {
  width: 28px;
  height: 28px;
  padding: 0;
  svg {
    width: 100%;
  }
}
.scanner_block {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 90000000000;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 10px;
  padding: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-radius: 40px;
  cursor: pointer;
}
.h2 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: 'Sf pro display', sans-serif;
  font-size: 24px;
  font-weight: 600;
}
.h2.black-1 {
  margin-bottom: 0px;
  color: var(--bm-text-title-color);
}
.tilte-row {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--bm-text-title-color);
}
.div-block-648 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  margin-bottom: 40px;
}
.search-history-keywords {
  margin-right: 20px;
  padding: 10px 15px;
  border-radius: 6px;
  background-color: var(--bm-light-background-color);
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: var(--bm-text-title-color);
  font-size: 16px;
}
.tilte-row {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--bm-text-title-color);
}

@media screen and (max-width: 991px) {
  .section {
    margin-top: 0px;
    padding: calc(120px + var(--bm-safe-area-top)) 35px 30px;
  }
  .w-container {
    max-width: 728px;
  }
  .tilte-row {
    margin-bottom: 0px;
  }
  .tilte-row {
    margin-bottom: 0px;
  }
}


@media screen and (max-width: 767px) {
  .section {
    padding: 83px 25px 20px;
  }
  .section._1 {
    padding-top: calc(100px + var(--bm-safe-area-top));
    padding-right: 30px;
    padding-left: 30px;
  }
  .scanner_block {
    left: auto;
    top: 0%;
    right: 0%;
    bottom: 0%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .h2 {
    font-size: 20px;
  }
  .h2.black-1 {
    font-size: 20px;
  }
  .tilte-row {
    margin-bottom: 20px;
  }
  .search-history-keywords {
    margin-top: 10px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
  .div-block-648 {
    margin-top: 10px;
    padding-top: 0px;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .tilte-row {
    margin-bottom: 20px;  
  }
  .tilte-row.with-line {
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
  .bm-c-search-result-store-list {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 479px) {
  .bm-c-search-result-store-list {
    grid-template-columns: unset;
  }
  .section {
    padding: 68px 12px 15px;
  }
  .section._1 {
    padding-top: calc(100px + var(--bm-safe-area-top));
  }
  .w-container {
    max-width: none;
  }
  .tilte-row {
    margin-bottom: 0px;
  }
  .h2 {
    font-size: 18px;
  }
  .h2.black-1 {
    margin-bottom: 0px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
  .html-embed-32 {
    width: 25px;
    height: 25px;
  }
  .tilte-row {
    margin-bottom: 0px;
  }
}

`;
