import React from 'react';
import { prefix } from '.';
import Icon, { IconType } from '@/components/Icon';
const Skeleton: React.FC = () => (
  <div className={`${prefix}-skeleton`}>
    <Icon type={IconType.MENUS_OUTLINED} />
    <div className={`${prefix}-skeleton-img`}></div>
    <div className="skeleton-nav">
      <div className="skeleton-category-wrap">
        <div className="food-order">
          <div className="bm-icon-item"></div>
          <div className="second-title"></div>
        </div>
        <div className="food-order booking">
          <div className="bm-icon-item"></div>
          <div className="second-title"></div>
        </div>
        {/* <div className="food-order">
          <div className="bm-icon"></div>
          <div className="second-title"></div>
        </div> */}
      </div>
    </div>
    <div className="skeleton-line"></div>
    <div className="skeleton-blog-wrap w-container">
      <div className="left"></div>
      <div className="right"></div>
    </div>
    <div className="skeleton-brands-categories w-container">
      <div className="skeleton-wrapper">
        <div className="skeleton-wrapper-box">
          <div className="skeleton-item" >
            <div className="skeleton-categories-img"></div>
            <div className="skeleton-categories-title"></div>
            <div className="skeleton-categories-description"></div>
            <div className="skeleton-categories-description-item"></div>
          </div>
          <div className="skeleton-item" >
            <div className="skeleton-categories-img"></div>
            <div className="skeleton-categories-title"></div>
            <div className="skeleton-categories-description"></div>
            <div className="skeleton-categories-description-item"></div>
          </div>
        </div>
      </div>
    </div>
    <div className="skeleton-brands-title w-container">
      <div className="brands-title-item"></div>
      <div className="brands-title-item"></div>
      <div className="brands-title-item"></div>
    </div>
    <div className="skeleton-brands-categories  w-container">
      <div className="skeleton-wrapper">
        <div className="skeleton-item" >
          <div className="skeleton-categories-img"></div>
          <div className="skeleton-categories-title info"></div>
        </div>
        <div className="skeleton-item" >
          <div className="skeleton-categories-img"></div>
          <div className="skeleton-categories-title info"></div>
        </div>
      </div>
    </div>

    <div className="skeleton-footer w-container ">
      <div className="skeleton-icon">
        <div className="icon-item"></div>
        <div className="icon-item icon-item-title"></div>
      </div>
      <div className="skeleton-icon">
        <div className="icon-item"></div>
        <div className="icon-item icon-item-title"></div>
      </div>
      <div className="skeleton-icon">
        <div className="icon-item"></div>
        <div className="icon-item icon-item-title"></div>
      </div>
      <div className="skeleton-icon">
        <div className="icon-item"></div>
        <div className="icon-item icon-item-title"></div>
      </div>
      <div className="skeleton-icon">
        <div className="icon-item"></div>
        <div className="icon-item icon-item-title"></div>
      </div>
    </div>
    <div className="skeleton-footer-line"></div>

  </div>
);

export default Skeleton;
