import { IObject } from '@/components/bm-common';

enum PayTypes {
  VISA = 'VISA',
  MASTER = 'MASTER',
  AMEX = 'AMEX',
  APPLE_PAY = 'APPLE_PAY',
  ALIPAY = 'ALIPAY',
  ALIPAYCN = 'ALIPAYCN',
  ALIPAY_HK = 'ALIPAY_HK',
  ALIPAYHK = 'ALIPAYHK',
  TAP_GO = 'TAP_GO',
  WECHAT_PAY = 'WECHAT_PAY',
  CREDIT_CARD = 'CREDIT_CARD',
  STORE_CREDIT = 'STORE_CREDIT',
  ANONYMOUS_GIFT_CARD = 'ANONYMOUS_GIFT_CARD',
  JCB = 'JCB',
  UNION_PAY = 'UNION_PAY',
  DEFERRED_BILLING = 'DEFERRED_BILLING',
  CASH_ON_DELIVERY = 'CASH_ON_DELIVERY',
  GOOGLE_PAY = 'GOOGLE_PAY',
};

export const creditCardTypeRefPayTypes: {[key: string]: PayTypes} = {
  'Visa': PayTypes.VISA,
  'Amex': PayTypes.AMEX,
  'Master': PayTypes.MASTER,
  'UnionPay': PayTypes.UNION_PAY,
}

enum WechatPayMode {
  MWEB = 'MWEB',                // h5调起微信app
  MINIPROGRAM = 'MINIPROGRAM',  // 微信小程序内使用微信支付
}

export const payTypeRefNumber: IObject = {
  [PayTypes.AMEX]: 4,
  [PayTypes.VISA]: 4,
  [PayTypes.MASTER]: 4,
  [PayTypes.CREDIT_CARD]: 4,
  [PayTypes.ANONYMOUS_GIFT_CARD]: 16,
  [PayTypes.STORE_CREDIT]: 16,
  [PayTypes.WECHAT_PAY]: 17,
  [PayTypes.APPLE_PAY]: 18,
  [PayTypes.ALIPAY]: 19,
  [PayTypes.ALIPAY_HK]: 19,
  [PayTypes.TAP_GO]: 21,
  [PayTypes.GOOGLE_PAY]: 23,
}

export {
  PayTypes,
  WechatPayMode,
};

export default PayTypes;
