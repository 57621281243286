import React, { useState } from 'react';
import Icon, { IconType } from '@/components/Icon';
import './index.less';
import { createRouteUrl } from '@/utils';
import { goToRoute } from '@/utils/route-util';
import { useHistory } from 'react-router-dom';
import paths from '@/routes/paths';
import { IObject } from '@/components/bm-common';
import { IReducersState } from '@/reducers';
import { useDispatch } from 'react-redux';
import { IDispatch } from '@/actions';
import ActionType from '@/actions/action-type';
// import { SettingPageStyled } from '@/pages/member-page/SettingPage/styled';
import Popup from '@/components/Modal/Popup';
import BmNavBar from '@/components/SideNavBar/BmNavBar';
import { getI18nText } from '@/utils/app';
import { getIntl } from '@/components/App/App';


interface IFooterProps {
  reducersState: IReducersState;
}

const Footer: React.FC<IFooterProps> = props => {
  const history = useHistory();
  const dispatch = useDispatch<IDispatch>();
  const [showTerms, setShowTerms] = useState(false);
  const { reducersState } = props;
  const { platformAppSettings, platformConfig } = reducersState.app || {};
  const { suppliers } = platformAppSettings || {};
  const { storeSlug } = platformConfig;
  let conditionsData = '';
  if (platformAppSettings) {
    conditionsData = platformAppSettings.generalConditions || '';
  }
  const termsData = getI18nText(platformAppSettings, 'general_conditions', reducersState.app.language) || conditionsData;
  const handleClick = (path: string, pathParams?: IObject) => {
    goToRoute({
      history,
      pathname: createRouteUrl(path, pathParams),
      clearPaths: false,
    });
  }
  const handleGotoAboutUs = () => {
    const { platformConfig } = reducersState.app;
    dispatch({
      type: ActionType.LOADING,
      showLoading: true,
      showLoadingBgc: true,
    });
    dispatch({
      type: ActionType.QUERY_BLOG_LIST,
      contentType: 'COMPANY_INFO',
      storeSlug: platformConfig.storeSlug,
      callback: blogs => {
        if (blogs.length === 1) {
          handleClick(
            paths.BLOG_DETAIL,
            {
              storeSlug: platformConfig.storeSlug,
              blogID: blogs[0].id,
              contentType: 'about-us',
            }
          )
        } else {
          handleClick(paths.ABOUT_US);
        }
        setTimeout(() => {
          dispatch({
            type: ActionType.LOADING,
            showLoading: false,
          })
        }, 500)
      }
    })
  }
  return (
    <div className="footer">
      <div className="footer-container footer-07">
        <div className="container-2">
          <div className="footer-horizontal">
            {/* {
              !suppliers && platformConfig.platformLogo &&
              <div className="footer-block">
                <img src={`${platformConfig.platformLogo}`} width="210" alt="" className="logo-flat"/>
              </div>
            } */}
            <div className="footer-link-wrap-short">
              <div aria-current="page" className="footer-link-centre w--current" onClick={() => handleClick(paths.HOME)}>{getIntl().page.homeTitle}</div>
              {
                suppliers &&
                <div className="footer-link-centre" 
                  onClick={() => handleClick(
                    paths.EXPRESS_ORDER,
                    { storeSlug },
                  )}
                >
                  {getIntl().page.expressOrderTitle}
                </div>
              }
              {
                !suppliers &&
                <div className="footer-link-centre" 
                  onClick={() => handleClick(
                    paths.STORE_LIST_BY_CATEGORY,
                    {
                      byType: 'all',
                      categoryID: '0',
                      brandID: '0',
                      fromPage: 'brands',
                    },
                  )}
                >{getIntl().page.allStores}</div>
              }
              <div className="footer-link-centre" onClick={() => handleGotoAboutUs()}>{getIntl().page.aboutUsTitle}</div>
              {
                termsData &&
                <div className="footer-link-centre" onClick={() => setShowTerms(true)} >{getIntl().page.terms}</div>
              }
            </div>
            {/* <div className="footer-block">
              {
                suppliers &&
                <div className="text-rights-short">Copyright © 2021. Birdymart.</div>
              }
              {
                !suppliers &&
                <div className="text-rights-short">Copyright © 2021. Bindo One Market.</div>
              }
            </div> */}
          </div>
        </div>
      </div>
      {
        termsData && 
        <Popup
          visible={showTerms}
          title={
            <BmNavBar
              onLeftClick={() => setShowTerms(false)}
              navIcon={<Icon type={IconType.LEFT_OUTLINED} className="html-embed-7 w-embed"/>}
              navContent={getIntl().page.terms}
            ></BmNavBar> 
          }
          showIcon={false}
          onClose={() => setShowTerms(false)}
          className="footer-poup"
        >
          <div className="div-block-549" dangerouslySetInnerHTML={{
            __html: termsData ? termsData : '',
          }}/>
        </Popup>
        
      }
    </div>
  );
}

export default Footer;
