import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import usePageProps from '@/routes/usePageProps';
import StoreConfiguresPage from '@/pages/StoreConfiguresPage';

const StoreConfigures: React.FC<RouteComponentProps> = props => {
  const pageProps = usePageProps({ props });

  if (pageProps.reducersState.app.loadingPlatformConfig) {
    return null;
  }

  return <StoreConfiguresPage {...pageProps} />
};

export default StoreConfigures;
