import styled from 'styled-components';
export const NewsPageStyled = styled.div`
  * {
    box-sizing: border-box;
  }
  .div-block-604 {
    position: relative;
  }
  .w-tabs {
    position: relative;
  }
  .tabs-menu-6 {
    position: -webkit-sticky;
    position: sticky;
    z-index: 8;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 940px;
    height: 70px;
    min-height: 70px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: var(--bm-light-background-color);
    top: 0;
  }
  .w-tab-content {
    position: relative;
    display: block;
    overflow: hidden;
  }
  .w-tab-pane {
    position: relative;
    display: none;
  }
  .w--tab-active {
    display: block;
  }
  .section {
    display: block;
    margin-top: auto;
    margin-bottom: 0px;
    padding: 60px 40px 40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: var(--bm-dark-background-color);
  }
  .section._1 {
    padding-top: 90px;
    padding-bottom: 100px;
  }
  .w-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 940px;
  }
  .container {
    position: relative;
    display: block;
    overflow: visible;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
  }
  .container._1 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
  }
  .w-inline-block {
    max-width: 100%;
    display: inline-block;
  }
  .tab-2 {
    margin-right: 10px;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: var(--bm-text-color);
    font-size: 20px;
  }
  .w-tab-link {
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-decoration: none;
    padding: 9px 30px;
    text-align: left;
    cursor: pointer;
    color: var(--bm-text-title-color);
    background-color: var(--bm-light-background-color);;
  }
  .tab-2.selected {
    margin-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 20px;
    background-color: var(--bm-primary-color);
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: var(--bm-light-background-color);
    font-size: 20px;
    font-weight: 700;
  }
  .w-tab-link {
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-decoration: none;
    padding: 9px 30px;
    text-align: left;
    cursor: pointer;
    color: var(--bm-text-title-color);
  }
  .branch-card {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    width: 47%;
    height: auto;
    margin-right: 0px;
    margin-bottom: 30px;
    margin-left: 0px;
    padding-bottom: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    border-radius: 6px;
    background-color: var(--bm-light-background-color);
    color: var(--bm-text-title-color);
    text-decoration: none;
    cursor: pointer;
    box-shadow: none;
  }
  .branch-card.w-inline-block {
    width: 100%;
    margin-bottom: 0px;
  }
  .container._1 {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  .image-card-upper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    width: auto;
    height: 200PX;
    margin-bottom: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    border-radius: 0px;
    background-position: 50% 50%;
    background-size: cover;
  }
  .div-block-99 {
    height: auto;
    padding: 15px;
  }
  h4 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
  }
  .heading-4 {
    font-family: 'Sf pro display', sans-serif;
    font-weight: 600;
    word-wrap: break-word;
  }
  .background-strip-white {
    position: fixed;
    width: 100%;
    height: 70px;
    background-color: var(--bm-light-background-color);
    z-index: 99;
  }


  @media screen and (max-width: 991px) {
    .tabs-menu-6 {
      width: auto;
      max-width: 728px;
      padding-left: 0px;
    }
    .section {
      margin-top: 0px;
      padding: 40px 35px 30px;
    }
    .w-container {
      max-width: 728px;
    }
    .branch-card {
      width: 47%;
      margin-bottom: 30px;
    }
  }

  @media screen and (max-width: 767px) {
    .tabs-menu-6 {
      height: 60px;
      max-width: none;
      min-height: 60px;
      padding-right: 30px;
      padding-left: 30px;
    }
    .section {
      padding: 83px 25px 20px;
    }
    .section._1 {
      padding-top: 100px;
      padding-right: 30px;
      padding-left: 30px;
    }
    .container._1 {
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
    }
    .tab-2 {
      margin-right: 0px;
      padding: 10px 20px;
      font-size: 16px;
    }
    .tab-2.selected {
      font-size: 16px;
    }
    .branch-card {
      width: 100%;
      // height: 300px;
      margin-bottom: 15px;
    }
    .text-block-5---subtitle {
      margin-bottom: 0px;
      font-size: 14px;
      line-height: 16px;
    }
    h4 {
      font-family: 'Sf pro display', sans-serif;
      font-weight: 600;
    }
    .image-card-upper {
      border-radius: 0px;
    }
    .background-strip-white {
      height: 60px;
    }
    .float-navigation {
      margin-bottom: 30px;
      padding-right: 0px;
      padding-left: 0px;
    }

  }
  @media screen and (max-width: 479px) {
    .tabs-menu-6 {
      height: 50px;
      min-height: 50px;
      padding-right: 16px;
      padding-left: 16px;
    }
    .section {
      padding: 68px 12px 15px;
    }
    .section._1 {
      padding-top: 71px;
    }
    .w-container {
      max-width: none;
    }
    .container {
      margin-top: 0px;
      margin-bottom: 0px;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }
    .branch-card {
      width: 100%;
      // height: 200px;
      margin-right: auto;
      margin-bottom: 10px;
    }
    .image-card-upper {
      margin-bottom: 0px;
      border-radius: 0px;
    }
    .div-block-99 {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      // height: 23%;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
    h4 {
      font-size: 16px;
    }
    .heading-4 {
      margin-top: 0px;
      margin-bottom: 0px;
      font-size: 16px;
      line-height: 22px;
    }
    .text-block-5---subtitle {
      font-size: 12px;
      line-height: 16px;
    }
    .background-strip-white {
      height: 50px;
    }
  }

`;
