import produce from 'immer';
import { handleActions } from 'redux-actions';
import Constants from '@/constants';
import ActionType from '@/actions/action-type';
import {
  IUserInfo,
  ISetUserInfo,
  ICustomer,
  ISetCustomer,
  ISetISGuest,
  IClearUserInfo,
  IClearCustomer,
  IClearISGuest,
  ICustomerGrowth,
  ISetCustomerGrowth,
  IClearCustomerGrowth,
  IAddress,
  ISetAddress,
  IPushAddress,
  IRemoveAddress,
  IModifyAddress,
  ICustomerPromotion,
  ISetCustomerPromotion,
  ISetAllPromotion,
} from '@/actions/user-action';
import { removeAccessToken } from '@/utils/app';

export interface IUserState {
  isGuest: boolean;
  userInfo?: IUserInfo;
  slugRefCustomer: {[storeSlug: string]: ICustomer};
  slugRefCustomerGrowth: {[storeSlug: string]: ICustomerGrowth};
  address: IAddress[];
  slugRefCustomerPromotion: {[storeSlug: string]: ICustomerPromotion[]};
  slugRefAllPromotion: {[storeSlug: string]: ICustomerPromotion[]};
};

const initState: IUserState = {
  isGuest: false,
  userInfo: undefined,
  slugRefCustomer: {},
  slugRefCustomerGrowth: {},
  address: [],
  slugRefCustomerPromotion: {},
  slugRefAllPromotion: {},
};

const actions: any = {};

actions[ActionType.SET_USER_INFO] = (state: IUserState, action: ISetUserInfo) => produce(state, draft => {
  draft.userInfo = action.userInfo;
});

actions[ActionType.SET_CUSTOMER] = (state: IUserState, action: ISetCustomer) => produce(state, draft => {
  draft.slugRefCustomer[action.storeSlug] = action.customer;
});

actions[ActionType.SET_CUSTOMER_GROWTH] = (state: IUserState, action: ISetCustomerGrowth) => produce(state, draft => {
  draft.slugRefCustomerGrowth[action.storeSlug] = action.loyaltyStamp;
});

actions[ActionType.CLEAR_CUSTOMER_GROWTH] = (state: IUserState, action: IClearCustomerGrowth) => produce(state, draft => {
  draft.slugRefCustomerGrowth = {};
});

actions[ActionType.CLEAR_CUSTOMER] = (state: IUserState, action: IClearCustomer) => produce(state, draft => {
  draft.slugRefCustomer = {};
});

actions[ActionType.SET_IS_GUEST] = (state: IUserState, action: ISetISGuest) => produce(state, draft => {
  draft.isGuest = action.isGuest;
});

actions[ActionType.CLEAR_IS_GUEST] = (state: IUserState, action: IClearISGuest) => produce(state, draft => {
  draft.isGuest = false;
  localStorage.removeItem(Constants.IS_GUEST);
});
actions[ActionType.CLEAR_USER_INFO] = (state: IUserState, action: IClearUserInfo) => produce(state, draft => {
  removeAccessToken();
  localStorage.removeItem(Constants.USER_INFO);
  localStorage.removeItem(Constants.IS_GUEST);
  localStorage.removeItem(Constants.MINI_PROGRAM_TIPS);
  localStorage.removeItem(Constants.SUPPLIERS_SELECT_STORE_SLUG);
  localStorage.removeItem(Constants.SUPPLIERS_SELECT_STORE_ID);
  localStorage.removeItem(Constants.ADD_EXPRESS_ORDER);
  localStorage.removeItem(Constants.PRIVILEGE_NUMBER);
  draft.userInfo = undefined;
  draft.isGuest = false;
});

actions[ActionType.SET_ADDRESS] = (state: IUserState, action: ISetAddress) => produce(state, draft => {
  draft.address = action.address;
});

actions[ActionType.PUSH_ADDRESS] = (state: IUserState, action: IPushAddress) => produce(state, draft => {
  if (!Array.isArray(draft.address)) {
    draft.address = [];
  }

  draft.address.unshift(action.address);
});

actions[ActionType.REMOVE_ADDRESS] = (state: IUserState, action: IRemoveAddress) => produce(state, draft => {
  if (Array.isArray(draft.address)) {
    draft.address = draft.address.filter(item => Number(item.id) !== Number(action.addressID));
  }
});

actions[ActionType.MODIFY_ADDRESS] = (state: IUserState, action: IModifyAddress) => produce(state, draft => {
  if (!Array.isArray(draft.address)) {
    draft.address = [];
  }

  const newAddressList = draft.address;

  draft.address = [];
  newAddressList.forEach(item => {
    if (draft.address) {
      if (Number(item.id) === Number(action.address.id)) {
        draft.address.push(action.address);
      } else {
        draft.address.push(item);
      }
    }
  })
});

actions[ActionType.SET_CUSTOMER_PROMOTION] = (state: IUserState, action: ISetCustomerPromotion) => produce(state, draft => {
  if (draft.slugRefCustomerPromotion[action.available]) {
    draft.slugRefCustomerPromotion[action.available].push(...action.promotions);
  } else {
    draft.slugRefCustomerPromotion[action.available] = action.promotions;
  }
});

actions[ActionType.CLEAR_CUSTOMER_PROMOTION] = (state: IUserState, action: ISetCustomerPromotion) => produce(state, draft => {
  draft.slugRefCustomerPromotion = {}
});

actions[ActionType.SET_ALL_PROMOTION] = (state: IUserState, action: ISetAllPromotion) => produce(state, draft => {
  if (draft.slugRefAllPromotion[action.storeSlug]) {
    draft.slugRefAllPromotion[action.storeSlug].push(...action.promotions);
  } else {
    draft.slugRefAllPromotion[action.storeSlug] = action.promotions;
  }
});

actions[ActionType.CLEAR_ALL_PROMOTION] = (state: IUserState, action: ISetAllPromotion) => produce(state, draft => {
  draft.slugRefAllPromotion = {}
});

export default handleActions(actions, initState);
