import {
  IPoint,
  IReward,
} from '@/actions/point-action';

export const parseResPoints = (records: any) => {
  const parseResPoints: IPoint[] = [];
  if (Array.isArray(records) && records.length > 0) {
    records.forEach(item => {
      parseResPoints.push({
        id: item.id || '',
        createdAt: item.created_at,
        amount: item.earn_stamp || 0,
      })
    })
  }

  return parseResPoints;
}

export const parseResRewards = (records: any) => {
  const parseResPoints: IReward[] = [];
  if (Array.isArray(records) && records.length > 0) {
    records.forEach(item => {
      parseResPoints.push({
        id: item.id || '',
        integral: item.stamp_required,
        loyalty: Number(item.store_credit) || 0,
      })
    })
  }

  return parseResPoints;
}
