
import React from 'react';
import parseInt from 'lodash/parseInt';
import InputItem from 'antd-mobile/es/input-item';
import Modal from 'antd-mobile/es/modal';
import dayjs from 'dayjs';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import ActionType from '@/actions/action-type';
import Config from '@/Config';
import { parseRouteParams, checkImgExists } from '@/utils';
import paths from '@/routes/paths';
import Icon, { IconType } from '@/components/Icon';
import { IStore } from '@/actions/store-action';
// import Layout from '@/components/Layout';
import Constants from '@/constants';
import { createUuid } from '@/lib/order-builder/utils'
import { IQueueing, IQueueingSetting } from '@/actions/queueing-action';
import { prefix } from '.';
import { setCanAutoProcurementMethodPopup } from '@/pages/MenuListPage/MenuListPage';
import Layout from '@/components/Layout';
import { getIntl } from '@/components/App/App';

export interface IQueueingPageProps extends IBasePageProps {}
export interface IQueueingPageState {
  disabled: boolean;
  success: boolean;
  cover: number;
  store: IStore | undefined;
  record: IQueueing | undefined;
  logoUrl: string;
  queueingSetting: IQueueingSetting[];
  id: string;
  queueings: IQueueings | undefined;
  refreshTime: string;
}

export interface IQueueings {
  [id: string]: {
    queueing: IQueueing[];
    callingqQueueing: IQueueing | undefined;
  };
}

class QueueingPage extends BasePage<IQueueingPageProps, IQueueingPageState> {
  state: IQueueingPageState = {
    disabled: false,
    success: false,
    cover: 0,
    store: undefined,
    record: undefined,
    logoUrl: Config.defaultStoreImg,
    queueingSetting: [],
    id: '',
    queueings: undefined,
    refreshTime: '',
  }

  background = [{left: '#3EC5FF', right: '#1C8BBB', color: '#85DAFF'}, {left: '#AB68EC', right: '#883AD3', color: '#E0A2FF'}, {left: '#9EFFB3', right: '#76B74B', color: '#D0FFDA'}, {left: '#FF9C60', right: '#E97834', color: '#FFC29E'}];
  uuid = localStorage.getItem(Constants.UUID) || '';
  componentDidMount() {
    super.componentDidMount();
    this.autoCloseLoading = false;
    const {
      dispatch,
    } = this.props;
    const routeParams = parseRouteParams(this.props);
    dispatch({
      type: ActionType.LOADING,
      showLoading: true,
    })
    if (!this.uuid) {
      this.uuid = createUuid();
      localStorage.setItem(Constants.UUID, this.uuid);
    }
    
    dispatch({
      type: ActionType.QUERY_STORE,
      storeSlug: routeParams.storeSlug,
      callback: store => {
        dispatch({
          type: ActionType.QUERY_QUEUEING_SETTING,
          storeSlug: routeParams.storeSlug,
          callback: queueingSetting => {
            if (store) {
              this.queryQueueings(store.id, queueingSetting);
              checkImgExists(store.logoUrl, (success, url) => {
                this.setState({
                  store,
                  logoUrl: success ? url : Config.defaultStoreImg,
                  queueingSetting,
                });
              })
            }
            dispatch({
              type: ActionType.LOADING,
              showLoading: false,
            })
            if (queueingSetting.length === 0) {
              setTimeout(() => {
                Modal.alert(getIntl().common.tips, getIntl().page.noQueue, [
                  {
                    text: getIntl().common.confirm,
                    onPress: () => this.handleGoBack({
                      path: paths.MENU_LIST,
                      runGoBack: true,
                    }),
                  },
                ]);
              }, 600)
            }
          }
        })
      }
    });
  }

  handleChang = (value: string) => {
    const { queueingSetting } = this.state;
    let correct = false;
    let max = 0;
    let id = ''
    const people = value ? parseInt(value) : 0;
    for (let i = 0;i<queueingSetting.length; i++) {
      if (people <= queueingSetting[i].max && people >= queueingSetting[i].min) {
        correct = true;
        id = queueingSetting[i].id;
        break;
      } else if (max < queueingSetting[i].max) {
        max = queueingSetting[i].max;
        id = queueingSetting[i].id;
      }
    }
    const input = document.getElementById('queueing-input');
    if (input) {
      if (people > 0) {
        this.setState({disabled: true});
        input.style.display = 'none';
      } else {
        this.setState({disabled: false});
        input.style.display = 'block';
      }
    }
    this.setState({
      cover: !correct && people !== 0 ? max : people,
      id,
    })
  }

  handleClickContinue = () => {
    const { cover, id, queueingSetting, store } = this.state;
    const {
      dispatch,
      reducersState,
    } = this.props
    const { userInfo } = reducersState.user
    this.setState({record: undefined})
    if (cover > 0) {
      dispatch({
        type: ActionType.LOADING,
        showLoading: true,
      })
      const routeParams = parseRouteParams(this.props);
      dispatch({
        type: ActionType.CREATE_QUEUEING,
        storeSlug: routeParams.storeSlug,
        cover,
        id,
        userID: userInfo ? userInfo.id : '',
        uuid: this.uuid,
        callback: record => {
          if (record && store) {
            this.queryQueueings(store.id, queueingSetting);
            this.setState({
              record,
              cover: 0,
            });
          } else {
            dispatch({
              type: ActionType.LOADING,
              showLoading: false,
            })
          }
        }
      }); 
    }
  }

  handleRefresh = () => {
    const { queueingSetting, store } = this.state;
    const { dispatch } = this.props;
    if (store) {
      dispatch({
        type: ActionType.LOADING,
        showLoading: true,
      })
      this.queryQueueings(store.id, queueingSetting)
    }
  }

  queryQueueings = (storeID: string, queueingSetting: IQueueingSetting[]) => {
    const { dispatch, reducersState } = this.props;
    const { userInfo, isGuest } = reducersState.user;
    const day = dayjs();
    const formula = `EQ("$.self.booking_type", "4").EQ("$.self.store_id", "${storeID}").IN("$.self.status", ["waiting", "calling"])`;
    dispatch({
      type: ActionType.QUERY_QUEUEINGS,
      multi: true,
      page: 1,
      perPage: 500,
      id: '',
      formula: `${formula}.BETWEEN("$.self.created_at", "${day.format('YYYY-MM-DD')}T00:00:00+08:00", "${day.format('YYYY-MM-DD')}T23:59:59+08:00")`,
      callback: records => {
        dispatch({
          type: ActionType.QUERY_QUEUEINGS,
          multi: true,
          page: 1,
          perPage: 10,
          id: '',
          formula: `${formula}.BETWEEN("$.self.created_at", "${day.subtract(3, 'hour').format('YYYY-MM-DD')}T${day.subtract(3, 'hour').format('HH:mm:ss')}+08:00", "${day.format('YYYY-MM-DD')}T${day.format('HH:mm:ss')}+08:00")${!isGuest && userInfo && userInfo.id ? `.EQ("$.self.linked_source_id", "${userInfo.id}"` : `.EQ("$.self.device_uuid", "${this.uuid}"`})`,
          callback: results => {
            const queueings: IQueueings = {}
            queueingSetting.forEach(item => {
              queueings[item.id] = {
                queueing: [],
                callingqQueueing: undefined,
              };
            })
            records.forEach(item => {
              queueings[item.tableID].queueing.push(item);
              if (item.status === 'calling') {
                queueings[item.tableID].callingqQueueing = item;
              }
            })
            this.setState({
              queueings,
              refreshTime: dayjs().format('HH:mm:ss'),
              record: results.length > 0 ? results[0] : undefined,
              success: true,
            })
            
            dispatch({
              type: ActionType.LOADING,
              showLoading: false,
            })
          }
        })
      }
    })
  }

  render() {
    const {
      disabled,
      success,
      store,
      record,
      logoUrl,
      cover,
      queueingSetting,
      queueings,
      refreshTime,
    } = this.state;

    return (
      <Layout
        {...this.props}
        className={prefix}
        navIcon={<Icon className="html-embed-7 w-embed" type={IconType.LEFT_OUTLINED} />}
        onLeftClick={() => {
          setCanAutoProcurementMethodPopup(false);
          this.handleGoBack({
            path: paths.MENU_LIST,
            runGoBack: true,
          })
        }}
        navContent={getIntl().page.queueing}
        isFooter={false}
      >
        <div className="html-embed-21-1 w-embed queueing-show">
          <svg width="0" height="0">
            <clipPath id="my-clip-path-1" clipPathUnits="objectBoundingBox">
              <path d="M0,0 H1 V0.219 C0.984,0.219,0.97,0.232,0.97,0.249 C0.97,0.265,0.984,0.278,1,0.278 V1 H0 V0.278 C0.016,0.278,0.03,0.265,0.03,0.249 C0.03,0.232,0.016,0.219,0,0.219 V0"></path>
            </clipPath>
          </svg>
        </div>
        {
          store &&
          <div className="section booking tinted">
            <div className="container booking w-container">
              <div className="wrapper">
                <div className="div-block-450">
                  <Icon className="html-embed-20 w-embed" type={IconType.REFRESH_OUTLINED} onClick={() => this.handleRefresh()} />
                </div>
                <h3 className="heading-7">{getIntl().page.callingNumber}</h3>
                <div className="text-block-46 third">{getIntl().page.latestUpdate}{refreshTime}</div>
              </div>
              <div className="div-block-449">
                {
                  queueings && queueingSetting.length > 0 && queueingSetting.map((item, index) => {
                    return (
                      <div className="ticket-display-block" key={item.id}>
                        <h4 className="heading-4-4">{item.min}-{item.max}{getIntl().page.people}</h4>
                        <div className="div-block-451" style={{background: this.background[index % 4].right}}>
                          <div className="div-block-452">
                            <h1 className="calling-char tinted">{item.type}</h1>
                            <div className="calling-char">{queueings[item.id] && queueings[item.id].callingqQueueing ? queueings[item.id].callingqQueueing?.queueingNo.replace(`${item.type}`, '') : ''}</div>
                          </div>
                          <div className="div-block-453">
                            <div className="text-block-50">{queueings[item.id] && queueings[item.id].queueing.length > 0 ? dayjs(queueings[item.id].queueing[0].queueTime).format('HH:mm') : ''}</div>
                            <div className="text-block-50">{queueings[item.id] && queueings[item.id].queueing.length > 0 ? queueings[item.id].queueing[0].queueingNo[0]+' '+queueings[item.id].queueing[0].queueingNo.substr(1) : ''}</div>
                          </div>
                          <div className="strip" style={{background: this.background[index % 4].left}}></div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
            {
              success &&
              <div className="container booking no-line w-container">
                <div className="ticket-wrapper-1">
                  <div className={`clipped shorten ${record ? 'no-justify-content': '' }`}>
                    <div className="div-block-443">
                      <div className="div-block-442" style={{backgroundImage: `url(${logoUrl})`}}></div>
                      <div>
                        <div className="text-block-48">{store.name}</div>
                        <div>
                          <div className="text-block-49">{store.address}</div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="line-divider-top _1"></div>
                    {
                      !record &&
                      <div>
                        <div>
                          <h4 className="heading-4-4">
                            {getIntl().page.coverNo}:
                          </h4>
                          <div className="input-people">
                            <div className="queueing-placeholder" id="queueing-input">{getIntl().page.enterPeople}</div>
                            <InputItem
                              onChange={value => this.handleChang(value)}
                              type={'number'}
                              value={ cover ? cover.toString() : ''}
                              className="div-block-455"
                            />
                          </div>
                          <div className="text-block-52">
                            {getIntl().page.queueingTip}
                          </div>
                        </div>
                      </div>
                    }
                    {
                      !record &&
                      <div className={`text-block-47  submit-button-modal close-modal not-active w-inline-block ${disabled ? '': 'opacity'}`} 
                        onClick={this.handleClickContinue}
                      >
                        {getIntl().common.continue}
                      </div>
                    }
                    {
                      record &&
                      <div>
                        <div className="queueing-success-user">
                          <div className="queueing-success-user-number">{record.queueingNo}</div>
                          <div className="queueing-success-user-number-tip">{getIntl().page.yourNumber}</div>
                          <div className="line-divider-top _1 queueing-record-divider"></div>
                        </div>
                        <div className="queueing-success-time">
                          <div className="queueing-success-time-title">{getIntl().page.queueTimes}</div>
                          <div className="queueing-success-time-data">{dayjs(record.queueTime).format('YYYY-MM-DD HH:mm:ss')}</div>
                        </div>
                        <div className="queueing-success-seats">
                          <div className="queueing-success-seats-title">{getIntl().page.seats}</div>
                          <div className="queueing-success-seats-data">{record.seats}</div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
                <div className="ticket-wrapper-2"></div>
              </div>
            }
           
          </div>
        }
      </Layout>
    );
  }
}

export default QueueingPage;
