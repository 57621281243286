import React, { useState, useEffect } from 'react';
import Icon, { IconType } from '@/components/Icon';
import { prefix } from '.';

interface IStarShowProps {
  starItem: string;
  halfStar?: string;
  score: number;
  isWholeShow?: boolean;
}

const StarShow: React.FC<IStarShowProps> = props => {
  const [star, setStar] = useState(0);
  const [half, setHalf] = useState(false);

  const {
    starItem = '',
    halfStar,
    score,
    isWholeShow = false,
  } = props;

  useEffect(() => {
    const integer = Math.ceil(score);
    if (integer === score) {
      setStar(integer);
    } else {
      setStar(integer);
      setHalf(true);
    }
  }, [])
  
  return (
    <div className={prefix}>
      <div className="star-top">
        {
          !isWholeShow && 
          <div>
            {
              star >= 1 &&
              <Icon className={starItem} type={IconType.STAR_OUTLINED} />
            }
            {
              star >= 2 &&
              <Icon className={starItem} type={IconType.STAR_OUTLINED} />
            }
            {
              star >= 3 &&
              <Icon className={starItem} type={IconType.STAR_OUTLINED} />
            }
            {
              star >= 4 &&
              <Icon className={starItem} type={IconType.STAR_OUTLINED} />
            }
            {
              star >= 5 &&
              <Icon className={starItem} type={IconType.STAR_OUTLINED} />
            }
          </div>
        }
        
        {
          isWholeShow &&
          <div>
            <Icon className={starItem} type={IconType.STAR_OUTLINED} />
            <Icon className={starItem} type={IconType.STAR_OUTLINED} />
            <Icon className={starItem} type={IconType.STAR_OUTLINED} />
            <Icon className={starItem} type={IconType.STAR_OUTLINED} />
            <Icon className={starItem} type={IconType.STAR_OUTLINED} />
          </div>
        }
      </div>
      <div className="star-bottom">
        {
          star >= 1 &&
          <Icon className={`${starItem} ${star === 1 && half ? halfStar : ''}`} type={IconType.STAR_OUTLINED} />
        }
        {
          star >= 2 &&
          <Icon className={`${starItem} ${star === 2 && half ? halfStar : ''}`} type={IconType.STAR_OUTLINED} />
        }
        {
          star >= 3 &&
          <Icon className={`${starItem} ${star === 3 && half ? halfStar : ''}`} type={IconType.STAR_OUTLINED} />
        }
        {
          star >= 4 &&
          <Icon className={`${starItem} ${star === 4 && half ? halfStar : ''}`} type={IconType.STAR_OUTLINED} />
        }
        {
          star >= 5 &&
          <Icon className={`${starItem} ${star === 5 && half ? halfStar : ''}`} type={IconType.STAR_OUTLINED} />
        }
      </div>
    </div>
  );
}

export default StarShow;
