import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import { prefix } from '.';
import ActionType from '@/actions/action-type';
import StoreList from '@/components/StoreList';
import { getAppTheme, setScreenColor, getIntl } from '@/components/App/App';
import Layout from '@/components/Layout/Layout';
import { IStore } from '@/actions/store-action';
import paths from '@/routes/paths';
import Icon, { IconType } from '@/components/Icon';
import RecommendedStores from './RecommendedStores';
import PanelBar from './PanelBar';
import BmTabBar from '@/components/BmTabBar';
import Status from '@/components/Status';
import QRCodeScan from '@/components/QRCodeScan';
import Config from '@/Config';
import MiniProgramTips from '@/components/MiniProgramTips';
import ServiceTypes from './ServiceTypes';
import { animateScroll } from 'react-scroll';
import { handleStoreServiceTypesScroll } from '@/utils/app';
import { setCanAutoProcurementMethodPopup } from '../MenuListPage/MenuListPage';
import TabsOption from '@/actions/tabs-option';
import StoreCategories from './StoreCategories';
import SideBar from '@/components/SideBar';
import StoreMapfilter from './StoreMapfilter';
import { parseScanUrl } from '@/utils';
import { goToRoute } from '@/utils/route-util';
import Skeleton from '../StoresByCategoryPage/Skeleton';
import { StoresPageStyled } from './styled';

export interface IStoresPageProps extends IBasePageProps {
  isHomePage?: boolean;
}

export interface IStoresPageState {
  page: number;
  stores?: IStore[];
  loadingStores: boolean;
  loadingFirstPage: boolean;
  recommendedStores: IStore[];
  lastPage: boolean;
  showQRCodeScan: boolean;
  selectedServiceType: string;
  showSideBar: boolean;
}

class StoresPage extends BasePage<IStoresPageProps, IStoresPageState> {
  loadingData = false;
  lastPage = false;

  constructor(props: IStoresPageProps) {
    super(props);
    const { isHomePage = false, reducersState } = props;

    this.state = {
      page: 1,
      loadingStores: !isHomePage,
      loadingFirstPage: !isHomePage,
      recommendedStores: [],
      lastPage: false,
      showQRCodeScan: false,
      selectedServiceType: reducersState.app.selectedStoreServiceType,
      showSideBar: false,
    }
  }

  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.darkBackgroundColor) {
      setScreenColor(appTheme.darkBackgroundColor);
    }

    super.componentDidMount();

    const { isHomePage, dispatch } = this.props;
    const { page, selectedServiceType } = this.state;

    dispatch({
      type: ActionType.QUERY_RECOMMENDED_STORES,
      callback: recommendedStores => {
        this.setState({
          recommendedStores,
        });
      }
    });

    if (!isHomePage) {
      dispatch({
        type: ActionType.QUERY_STORES,
        serviceType: selectedServiceType,
        page,
        callback: () => {
          this.setState({ loadingStores: false, loadingFirstPage: false });
          this.closeLoading();
        }
      });
    } else {
      this.closeLoading();
    }

    (window as any).scanResult = (scanStr: string) => {
      this.handleScan(scanStr);
    }

    (window as any).processScannedCode = (scanStr: string) => {
      this.handleScan(scanStr);
    }

    handleStoreServiceTypesScroll(selectedServiceType);

    const pageWrap = window.document.querySelector('.page-content-wrapper-web');
    if (pageWrap) {
      pageWrap.addEventListener('scroll', this.handleScroll, { passive: true });
    } else {
      window.addEventListener('scroll', this.handleScroll, { passive: true });
    }
  }
  
  componentWillUnmount () {
    delete (window as any).scanResult;
    delete (window as any).processScannedCode;
    const pageWrap = window.document.querySelector('.page-content-wrapper-web');
    if (pageWrap) {
      pageWrap.removeEventListener('scroll', this.handleScroll);
    } else {
      window.removeEventListener('scroll', this.handleScroll);
    }
    super.componentWillUnmount();
  }

  handleScan = (value = '') => {
    const { history } = this.props;
    const parseResult = parseScanUrl(value);
    if (value.startsWith('http') && !parseResult.searchParams.order_number) {
      const info = parseScanUrl(value);
      if (info.isSameEvn && info.isSamePlatform && info.platformRoute && history) {
        goToRoute({
          history,
          pathname: info.platformRoute,
        });
      } else {
        window.location.href = value;
      }
    } else {
      this.handleScanSuccess(value);
    }
  }

  getScrollY = () => {
    const pageWrap = window.document.querySelector('.page-content-wrapper-web');
    let scrollY = window.scrollY;
    if (pageWrap) {
      scrollY = pageWrap.scrollTop;
    }

    return scrollY;
  }

  handleScroll = () => {
    const { page, selectedServiceType } = this.state;
    const { dispatch } = this.props;
    if (!this.lastPage && !this.loadingData) {
      const appPages = document.getElementsByClassName('bm-p-stores');
      if (appPages && appPages.length) {
        const appPage = appPages[0];
        const scrollHeight = appPage.scrollHeight;
        const canScrollHeight = scrollHeight - window.innerHeight;

        if (canScrollHeight - this.getScrollY() < 5 * 200 && !this.loadingData) {
          this.loadingData = true;
          this.setState({ loadingStores: true });
          dispatch({
            type: ActionType.QUERY_STORES,
            page: page + 1,
            serviceType: selectedServiceType,
            callback: (_stores, page, lastPage) => {
              this.loadingData = false;
              this.lastPage = lastPage;
              this.setState({ loadingStores: false, page, lastPage });
            }
          });
        }
      }
    }
  }

  handleItemClick = (data: IStore) => {
    if (data.isGroup) {
      this.handleGotoRoute({
        path: paths.STORE_GROUP,
        pathParams: {
          storeSlug: data.slug,
        },
      });
    } else {
      setCanAutoProcurementMethodPopup(true);
      this.handleGotoRoute({
        path: paths.MENU_LIST,
        pathParams: {
          storeSlug: data.slug,
        },
      });
    }
  }

  handleCategoryClick = (categoryID: string) => {
    this.handleGotoRoute({
      path: paths.STORE_LIST_BY_CATEGORY,
      pathParams: {
        byType: 'all',
        brandID: categoryID,
        categoryID: '0',
        fromPage: 'stores'
      },
    });
  }

  handleSearchClick = () => {
    this.handleGotoRoute({
      path: paths.SEARCH
    });
  }

  handleScanClick = () => {
    const { reducersState } = this.props;
    const { deviceInfo } = reducersState.app;

    const bindoUtils: any = (window as any).bindo_utils;
    if (deviceInfo.isAndroid && bindoUtils && bindoUtils.openCameraScan) {
      bindoUtils.openCameraScan();
    } else if (deviceInfo.isAppleApp && (window as any).webkit && (window as any).webkit.messageHandlers && (window as any).webkit.messageHandlers) {
      (window as any).webkit.messageHandlers.openCodeScanner.postMessage('openCodeScanner');
    } else {
      this.setState({ showQRCodeScan: true });
    }
  }

  handleScanSuccess = (value: string) => {
    this.setState({ showQRCodeScan: false });
  }

  handleScanFail = () => {
    this.setState({ showQRCodeScan: false })
  }

  handleServiceTypeClick = (type: string) => {
    const { dispatch } = this.props;
    this.openLoading();
    this.loadingData = true;
    this.lastPage = false;

    this.setState({
      loadingStores: true,
      lastPage: false,
      page: 1,
      selectedServiceType: type,
    });

    animateScroll.scrollTo(0, {
      duration: 200,
    });

    dispatch({
      type: ActionType.QUERY_STORES,
      page: 1,
      serviceType: type,
      callback: (_stores, page, lastPage) => {
        this.loadingData = false;
        this.lastPage = lastPage;
        this.setState({ loadingStores: false, page, lastPage });
        this.closeLoading();
      }
    });

    dispatch({
      type: ActionType.SET_SELECTED_SERVICE_TYPE,
      selectedServiceType: type,
    });
  }
  onOpenChange = () => {
    const { reducersState } = this.props;
    const { platformConfig } = reducersState.app;
    if (platformConfig.showSideBar) {
      this.setState({
        showSideBar: !this.state.showSideBar,
      });
    }
  }

  handleShowSideBar = () => {
    this.setState({
      showSideBar: !this.state.showSideBar,
    });
  }

  handleGoStoreMap = () => {
    this.handleGotoRoute({
      path: paths.STORE_MAP,
      pathParams: {},
    });
  }

  storeFilterTypeClick = (type: string) => {
    this.setState({
      selectedServiceType: type,
    });

    animateScroll.scrollTo(0, {
      duration: 200,
    });

  }

  handleStoreFilter = () => {

    this.loadingData = true;
    this.lastPage = false;

    this.setState({
      loadingStores: true,
      lastPage: false,
      page: 1,
    });

    const { dispatch } = this.props;

    dispatch({
      type: ActionType.LOADING,
      showLoading: true,
    });

    dispatch({
      type: ActionType.QUERY_STORES_BY_FILTER,
      page: 1,
      callback: (_stores, page, lastPage) => {
        this.loadingData = false;
        this.lastPage = lastPage;
        this.setState({ loadingStores: false, page, lastPage });
        this.closeLoading();
        dispatch({
          type: ActionType.LOADING,
          showLoading: false,
        });
      }
    });
  }

  render() {
    const { reducersState, isHomePage, history } = this.props;
    const {
      loadingStores,
      recommendedStores,
      loadingFirstPage,
      lastPage,
      showQRCodeScan,
      selectedServiceType,
    } = this.state;
    const { platformConfig, scannableQRCode, deviceInfo, platformStoreBrands } = reducersState.app;
    const { typeRefStores } = reducersState.store;
    const stores = typeRefStores[selectedServiceType] || [];
    const hasStores = stores.length > 0;
    const { validServiceType = [], supportStoreCategory, showSideBar, filterEnabled, mapEnabled } = platformConfig;
    return (
      <>
        <Layout
          {...this.props}
          className={`${prefix} ${validServiceType.length > 1 ? 'has-service-types' : ''}`}
          navIcon={showSideBar && <Icon className="html-embed-7 w-embed side-bar-icon " type={IconType.MENUS_OUTLINED} />}
          onLeftClick={this.onOpenChange}
          navRightContent={
            <div className="nav-right-content">
              {
                scannableQRCode && !Config.isProduction &&
                <Icon type={IconType.SCAN_OUTLINED}  className="html-embed-7 w-embed" onClick={this.handleScanClick} />
              }
              <Icon type={IconType.SEARCH_OUTLINED} className="html-embed-7 w-embed" onClick={this.handleSearchClick}/>
            </div>
          }
        >
          {
            !hasStores &&  loadingStores &&
            <Skeleton isStorePage = { history.location.pathname === paths.STORE_LIST }/>
          }
          {deviceInfo.weChatMiniProgram && isHomePage &&
            <MiniProgramTips />
          }
          <StoresPageStyled>
            <div className="div-block-604">
              {
                validServiceType.length > 1 &&
                <div className="background-strip-white">
                  <ServiceTypes
                    data={validServiceType}
                    selected={selectedServiceType}
                    onItemClick={this.handleServiceTypeClick}
                  />
                </div>
              }
              <div className="w-tabs">
                <div className="w-tab-content">
                  <div className= {`section _1 wf-section ${validServiceType.length === 0? 'wf-sectionmargin': ''}`}>
                    <div className="container w-container">
                      {
                        supportStoreCategory && platformStoreBrands && platformStoreBrands.length > 0 &&
                        <StoreCategories
                          data={platformStoreBrands}
                          reducersState={reducersState}
                          onItemClick={this.handleCategoryClick}
                        />
                      }
                      {
                        !loadingFirstPage &&
                        <>
                          {
                            hasStores &&
                            <>
                              {
                                recommendedStores && recommendedStores.length > 0 &&
                                <>
                                  <PanelBar
                                    title={getIntl().page.featured}
                                  />
                                  <RecommendedStores
                                    data={recommendedStores}
                                    reducersState={reducersState}
                                    onItemClick={this.handleItemClick}
                                  />
                                </>
                              }
                              <PanelBar
                                title={getIntl().page.allStores}
                              />
                              <StoreList
                                data={stores}
                                reducersState={reducersState}
                                loading={loadingStores}
                                className={`${prefix}-store-list`}
                                lastPage={lastPage}
                                onItemClick={this.handleItemClick}
                              />
                              {
                                lastPage &&
                                <div className="no-more-content">
                                  <span>{getIntl().page.noMoreContent}</span>
                                </div>
                              }
                            </>
                          }
                          {
                            !loadingStores && platformConfig.id && !hasStores &&
                            <div className="no-stores">
                              <Status
                                iconType={IconType.TAG_STORE}
                                title="There are no shops in the city."
                                describe="We are working hard to invite you to join us, please look forward to it!"
                              />
                            </div>
                          }
                        </>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </StoresPageStyled>
        </Layout>
        {
          ((isHomePage && platformConfig.showTabs) || platformConfig.showStoreTab) &&
          <BmTabBar
            selectedKey={TabsOption.STORE}
            reducersState={reducersState}
          />
        }
        {
          showQRCodeScan &&
          <QRCodeScan
            history={history}
            onSuccess={this.handleScanSuccess}
            onFail={this.handleScanFail}
          />
        }
        {
          showSideBar &&
          <SideBar
            showSideBar={this.state.showSideBar}
            onItemClick={this.handleShowSideBar}
            reducersState={reducersState}
          />
        }
        {
          <StoreMapfilter
            handleGoStoreMap={this.handleGoStoreMap}
            confirmStoreFilter={this.handleStoreFilter}
            onTypeClick={this.storeFilterTypeClick}
            mapEnabled={mapEnabled}
            filterEnabled={filterEnabled}
          />
        }
      </>
    );
  }
}

export default StoresPage;
