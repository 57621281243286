import React from 'react';
import { animateScroll } from 'react-scroll';
import Icon, { IconType } from '@/components/Icon';
import { createRouteUrl } from '@/utils';
import AntdIcon from 'antd-mobile/es/icon';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import Layout from '@/components/Layout'
import ActionType from '@/actions/action-type';
import { IOrderInfo } from '@/lib/order-builder/models/OrderEntity';
import BmTabBar from '@/components/BmTabBar';
import paths from '@/routes/paths';
import OredrItem from './OrderItem';
import { prefix } from '.';
import { getAppTheme, setScreenColor, getIntl } from '@/components/App/App';
import { goToRoute } from '@/utils/route-util';
import TabsOption from '@/actions/tabs-option';
import { getAccessToken } from '@/utils/app';

export interface IOrdersPageProps extends IBasePageProps {}

interface IOrdersPageState {
  orders: IOrderInfo[] | undefined;
  selectedKey: 'all' | 'post_sale' | 'completed';
  loading: boolean;
  page: number;
}

class OrderListPage extends BasePage<IOrdersPageProps, IOrdersPageState> {
  lastPage = false;
  loadingData = false;
  perPage = 10;
  token = getAccessToken();
  state: IOrdersPageState = {
    orders: undefined,
    selectedKey: 'all',
    loading: false,
    page: 1,
  }

  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.darkBackgroundColor) {
      setScreenColor(appTheme.darkBackgroundColor);
    }
    super.componentDidMount();
    this.autoCloseLoading = false;

    this.getOrders(1, 'all', false, true);
    const pageWrap = window.document.querySelector('.page-content-wrapper-web');
    if (pageWrap) {
      pageWrap.addEventListener('scroll', this.handleScroll, { passive: true });
    } else {
      window.addEventListener('scroll', this.handleScroll, { passive: true });
    }
  }

  componentWillUnmount() {
    const pageWrap = window.document.querySelector('.page-content-wrapper-web');
    if (pageWrap) {
      pageWrap.removeEventListener('scroll', this.handleScroll);
    } else {
      window.removeEventListener('scroll', this.handleScroll);
    }
  }

  getScrollY = () => {
    const pageWrap = window.document.querySelector('.page-content-wrapper-web');
    let scrollY = window.scrollY;
    if (pageWrap) {
      scrollY = pageWrap.scrollTop;
    }

    return scrollY;
  }

  handleScroll = () => {
    const { page, selectedKey } = this.state;
    if (!this.lastPage && !this.loadingData) {
      const appPages = document.getElementsByClassName('bm-p-orders');
      if (appPages && appPages.length) {
        const appPage = appPages[0];
        const scrollHeight = appPage.scrollHeight;
        const canScrollHeight = scrollHeight - window.innerHeight;
        if (canScrollHeight - this.getScrollY() < 3 * 200) {
          this.loadingData = true
          this.setState({loading: true});
          this.getOrders(page + 1, selectedKey, true);
        }
      }
    }
  }

  getOrders = async (page: number, status: 'all' | 'post_sale' | 'completed', isAdd = false, refresh = false) => {
    if (!this.token) {
      this.setState({
        orders: [],
        loading: false,
        page,
      })
      return;
    }

    const { dispatch } = this.props;

    if (page === 1) {
      dispatch({
        type: ActionType.LOADING,
        showLoading: true,
      })
    }

    dispatch({
      type: ActionType.QUERY_ORDERS,
      page,
      pageSize: this.perPage,
      status,
      refresh,
      callback: newOrders => {
        let { orders } = this.state;
        if (isAdd && orders) {
          orders = orders.concat(newOrders);
        } else {
          orders = newOrders;
        }
    
        if (newOrders.length < this.perPage) this.lastPage = true;
    
        if (newOrders) this.loadingData = false;
    
        this.setState({
          orders: orders,
          loading: false,
          page,
        })
        dispatch({
          type: ActionType.LOADING,
          showLoading: false,
        })
      }
    });
  }

  handleOnClick = (status: 'all' | 'post_sale' | 'completed') => {
    this.setState({
      selectedKey: status,
      orders: undefined,
    })
    animateScroll.scrollToTop()
    this.getOrders(1, status);
    this.lastPage = false;
    this.loadingData = true;
  }

  handleGoDetail = (storeID: string, orderNumber: string, suborderNumber: string) => {
    const { 
      history,
      dispatch,
    } = this.props;

    dispatch({
      type: ActionType.QUERY_STORE_BY_ID,
      storeID,
      callback: store => {
        if (store) {
          goToRoute({
            history,
            pathname: createRouteUrl(
              paths.ORDER_DETAIL,
              {
                storeSlug: store.slug,
                orderNumber,
                suborderNumber,
                from: 'records',
              }
            ),
          });
        }
      }
    });
  }

  handleReOrder = (storeID: string, orderNumber: string, suborderNumber: string) => {
    const { dispatch, history } = this.props;

    if (storeID && orderNumber) {
      dispatch({
        type: ActionType.ADD_TO_SHOPPING_CART_BY_ORDER,
        storeID,
        orderNumber,
        suborderNumber,
        callback: (storeID, shoppingCartData) => {
          if (shoppingCartData && storeID) {
            dispatch({
              type: ActionType.QUERY_STORE_BY_ID,
              storeID,
              callback: store => {
                if (store) {
                  goToRoute({
                    history,
                    pathname: createRouteUrl(
                      paths.SHOPPING_CART,
                      {
                        storeSlug: store.slug,
                      }
                    ),
                  });
                }
              }
            });
          }
        }
      })
    }
  }

  render() {
    const { reducersState } = this.props;
    const { 
      orders,
      selectedKey,
      loading,
    } = this.state;
    const { platformConfig } = reducersState.app;

    return (
      <>
        <Layout
          {...this.props}
          className={prefix}
          navContent={getIntl().page.ordersTitle}
        >
          <div className={'navbar-buttom'}>
            <div
              onClick={() => this.handleOnClick('all')}
              className={`order-type ${selectedKey === 'all' ? 'selected' : ''}`}
            >
              {getIntl().page.allOrders}
            </div>
            <div
              onClick={() => this.handleOnClick('post_sale')}
              className={`order-type ${selectedKey === 'post_sale' ? 'selected' : ''}`}
            >
              {getIntl().page.current}
            </div>
            <div
              onClick={() => this.handleOnClick('completed')}
              className={`order-type ${selectedKey === 'completed' ? 'selected' : ''}`}
            >
              {getIntl().page.completed}
            </div>
          </div>
          {
            orders && orders.map(item => {
              if (!item.parentID) {
                return <OredrItem data={item} key={item.orderNumber} goToDetailPage={this.handleGoDetail} reOrder={this.handleReOrder} />
              } else {
                return <div key={item.orderNumber}></div>
              }
            })
          }

          {
            orders && loading &&
            <div className="loading-data">
              <AntdIcon type="loading" />
              <div className="loading-msg">
                {getIntl().common.loading}
              </div>
            </div>
          }

          {
            (!this.token || (orders && orders.length === 0)) &&
            <div className={'no-orders'}>
              <div>
                <div className={'img'}>
                  <Icon className={'img'} type={IconType.TAG_ITEM}/>
                </div>
                <div className={'no-order'}>
                  {getIntl().page.noOrders}
                </div>
                <div className={'no-order-tip'}>
                  {getIntl().page.noOrdersTip}
                  <br />
                  {
                    !this.token &&
                    getIntl().page.pleaseSignIn
                  }
                </div>
              </div>
            </div>
          }
        </Layout>
        {
          platformConfig.showTabs &&
          <BmTabBar
            selectedKey={TabsOption.ORDER}
            reducersState={reducersState}
          />
        }
      </>
    );
  }
}

export default OrderListPage;
