/**
 * store credits pay
 * auth: Joshua
 * 2020/07/16
 */
import { payInvoice } from '../payment-data';
import PAYMENTTYPES from '../PayTypes';
import {
  handleBuildOrderPayment,
} from '../Utils'
import { PayTypes, IPayOrderResult } from '..';
import { IObject } from '@/components/bm-common';


interface IStoreCreditPayParams {
  storeID: string;
  orderNumber: string;
  payDataObj: IObject;
  paymentTypes: PayTypes[];
}

const UseStoreCreditPay = (params: IStoreCreditPayParams) => {

  const {
    storeID,
    orderNumber,
    payDataObj,
    paymentTypes,
  } = params;

  const getStoreCreditPayment = () => {

    const payData = payDataObj[PAYMENTTYPES.STORE_CREDIT] || payDataObj[PAYMENTTYPES.ANONYMOUS_GIFT_CARD];

    let newPayment;

    if (payData) {
      const {
        amount,
        printedPin = '',
        number = '',
      } = payData || {};

      if (paymentTypes.includes(PAYMENTTYPES.STORE_CREDIT)) {// 用户绑定的gift card
        // 正常构建PAYMENTTYPE.STORE_CREDITS的数据结构
        newPayment = {
          'store_credits': [
            {
              amount,
            }
          ],
        };
      } else if (paymentTypes.includes(PAYMENTTYPES.ANONYMOUS_GIFT_CARD)) {// 用户输入的新gift card
        newPayment = {
          'store_credits': [
            {
              amount,
              'printed_pin': printedPin,
              number: number,
            }
          ],
        }
      }
    }
    return {
      storeCreditPayment: newPayment,
    }
  };

  const storeCreditPay = async (): Promise<IPayOrderResult> => {
    // paymentTypes含有一个元素且是PAYMENTTYPES, 可以进行单独支付
    if (
      paymentTypes.length === 1
      && (paymentTypes.includes(PAYMENTTYPES.STORE_CREDIT) || paymentTypes.includes(PAYMENTTYPES.ANONYMOUS_GIFT_CARD))
    ) {
      const {
        storeCreditPayment,
      } = getStoreCreditPayment();
      try {
        if (!storeCreditPayment) throw new Error('Store credits pay faild!')
        const order = handleBuildOrderPayment(storeCreditPayment)

        const resp = await payInvoice(storeID, orderNumber, order);

        const {
          order: resOrder,
          data,
        } = resp || {};

        if (resOrder) {
          return {
            order: resOrder,
          }
        } else {
          return {
            status: 500,
            message: data.message || ''
          }
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  };

  return {
    storeCreditPay,
    getStoreCreditPayment,
  }
}
export default UseStoreCreditPay;
