import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import { prefix } from '.';
import ActionType from '@/actions/action-type';
import StoreList from '@/components/StoreList';
import { getAppTheme, setScreenColor, getIntl, getIntlByPath } from '@/components/App/App';
import Layout from '@/components/Layout';
import { IStore } from '@/actions/store-action';
import paths from '@/routes/paths';
import Icon, { IconType } from '@/components/Icon';
import Status from '@/components/Status';
import { animateScroll } from 'react-scroll';
// import { handleStoreServiceTypesScroll } from '@/utils/app';
import { setCanAutoProcurementMethodPopup } from '../MenuListPage/MenuListPage';
import { parseRouteParams } from '@/utils';

export interface IStoresByBookingPageProps extends IBasePageProps {
}

export interface IStoresByBookingPageState {
  stores?: IStore[];
  loadingStores: boolean;
  loadingFirstPage: boolean;
  showQRCodeScan: boolean;
  selectedCategoryID: string;
  lastPage: boolean;
  page: number;
  selectedServiceType: string;
}

class StoresByBookingPage extends BasePage<IStoresByBookingPageProps, IStoresByBookingPageState> {
  loadingData = false;
  lastPage = false;

  constructor(props: IStoresByBookingPageProps) {
    super(props);
    const routeParams = parseRouteParams(props);
    const { reducersState } = props;
    this.state = {
      loadingStores: true,
      loadingFirstPage: true,
      showQRCodeScan: false,
      selectedCategoryID: routeParams.categoryID || '',
      lastPage: false,
      page: 1,
      selectedServiceType: reducersState.app.selectedStoreServiceType,
    }
  }

  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.darkBackgroundColor) {
      setScreenColor(appTheme.darkBackgroundColor);
    }

    super.componentDidMount();
    const routeParams = parseRouteParams(this.props);

    this.handleServiceTypeClick(Number(routeParams.serviceType) === 1 ? 'booking' : 'queueing');
    const pageWrap = window.document.querySelector('.page-content-wrapper-web');
    if (pageWrap) {
      pageWrap.addEventListener('scroll', this.handleScroll, { passive: true });
    } else {
      window.addEventListener('scroll', this.handleScroll, { passive: true });
    }

  }
  
  componentWillUnmount () {
    const pageWrap = window.document.querySelector('.page-content-wrapper-web');
    if (pageWrap) {
      pageWrap.removeEventListener('scroll', this.handleScroll);
    } else {
      window.removeEventListener('scroll', this.handleScroll);
    }
  }

  getScrollY = () => {
    const pageWrap = window.document.querySelector('.page-content-wrapper-web');
    let scrollY = window.scrollY;
    if (pageWrap) {
      scrollY = pageWrap.scrollTop;
    }

    return scrollY;
  }

  handleScroll = () => {
    const { page, selectedServiceType } = this.state;
    const { dispatch } = this.props;
    if (!this.lastPage && !this.loadingData) {
      const appPages = document.getElementsByClassName('bm-p-stores-by-booking');
      if (appPages && appPages.length) {
        const appPage = appPages[0];
        const scrollHeight = appPage.scrollHeight;
        const canScrollHeight = scrollHeight - window.innerHeight;

        if (canScrollHeight - this.getScrollY() < 5 * 200) {
          this.loadingData = true;
          this.setState({ loadingStores: true });
          dispatch({
            type: ActionType.QUERY_STORES,
            page: page + 1,
            serviceType: selectedServiceType,
            callback: (_stores, page, lastPage) => {
              this.loadingData = false;
              this.lastPage = lastPage;
              this.setState({ loadingStores: false, page, lastPage });
            }
          });
        }
      }
    }
  }


  handleServiceTypeClick = (type: string) => {
    const { dispatch } = this.props;
    this.openLoading();
    this.loadingData = true;
    this.lastPage = false;

    this.setState({
      loadingStores: true,
      lastPage: false,
      page: 1,
      selectedServiceType: type,
    });

    animateScroll.scrollTo(0, {
      duration: 200,
    });

    dispatch({
      type: ActionType.QUERY_STORES,
      page: 1,
      serviceType: type,
      callback: (_stores, page, lastPage) => {
        this.loadingData = false;
        this.lastPage = lastPage;
        this.setState({ loadingStores: false, page, lastPage, loadingFirstPage: false });
        this.closeLoading();
      }
    });

    dispatch({
      type: ActionType.SET_SELECTED_SERVICE_TYPE,
      selectedServiceType: type,
    });
  }

  handleItemClick = (data: IStore) => {
    if (data.isGroup) {
      this.handleGotoRoute({
        path: paths.STORE_GROUP,
        pathParams: {
          storeSlug: data.slug,
        },
      });
    } else {
      const routeParams = parseRouteParams(this.props);
      setCanAutoProcurementMethodPopup(true);
      this.handleGotoRoute({
        path: routeParams.serviceType === '1' ? paths.BOOKING_NEW : paths.QUEUEING_NEW,
        pathParams: {
          storeSlug: data.slug,
        },
      });
    }
  }

  render() {
    const { reducersState } = this.props;
    const {
      loadingStores,
      loadingFirstPage,
      lastPage,
      selectedServiceType,
    } = this.state;
    const { platformConfig } = reducersState.app;
    const { typeRefStores } = reducersState.store;
    const stores = typeRefStores[selectedServiceType] || [];
    
    const hasStores = stores.length > 0;
    const routeParams = parseRouteParams(this.props);
    return (
      <Layout
        {...this.props}
        className={`${prefix} bm-p-stores`}
        navContent={<span>{getIntlByPath(Number(routeParams.serviceType) === 1 ? 'page.booking' : 'page.queueing')}</span>}
        navIcon={<Icon className="back-icon" type={IconType.LEFT_OUTLINED} />}
        onLeftClick={() => this.handleGoBack({
          path: paths.HOME,
        })}
      >
        {
          !loadingFirstPage &&
          <>
            {
              hasStores &&
              <>
                <StoreList
                  data={stores}
                  reducersState={reducersState}
                  loading={loadingStores}
                  className={`${prefix}-store-list`}
                  lastPage={lastPage}
                  onItemClick={this.handleItemClick}
                />
                {
                  lastPage &&
                  <div className="no-more-content">
                    <span>{getIntl().page.noMoreContent}</span>
                  </div>
                }
              </>
            }
            {
              !loadingStores && platformConfig.id && !hasStores &&
              <div className="no-stores">
                <Status
                  iconType={IconType.COMING_SOON_OUTLINED}
                  className="coming-soon"
                  title={<span>{getIntl().page.comingSoon}</span>}
                />
              </div>
            }
          </>
        }
      </Layout>
    );
  }
}

export default StoresByBookingPage;
