import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import { prefix } from '.';
import { IObject } from '@/components/bm-common';
import { setScreenColor, getAppTheme, getIntl } from '@/components/App/App';
import Icon, { IconType } from '@/components/Icon';
import { IGiftCard, IPayCard } from '@/actions/pay-action';
import ActionType from '@/actions/action-type';
import { parseRouteParams, getPlatformPayInfo } from '@/utils';
import { IDialCodeGroupItem } from '@/components/DialCodeGroup';
import cloneDeep from 'lodash/cloneDeep';
import GuestCheckout from './GuestCheckout';
import UserCheckout from './UserCheckout';
import paths from '@/routes/paths';
import SignInBottom from '@/components/SignIn/SignInBottom';
import { plus } from '@/lib/order-builder/utils';
import Constants from '@/constants';
import { CheckoutPageStyled, CheckoutStyled, GuestCheckoutStyled } from './styled';
import Layout from '@/components/Layout';
import { IWindowGoogle } from '@/lib/payment';
import { isStaging } from '@/Config';
import Script from 'react-load-script';

export type ICheckoutMethod = 'GUEST' | 'MOBILE_SIGN_IN' | undefined;

export interface ICheckoutPageProps extends IBasePageProps {}

export interface ICheckoutPageState {
  storeID: string;
  storeSlug: string;
  payCards: IPayCard[];
  anonymousGiftCards: IGiftCard[];
  selectedGiftCard: IGiftCard | undefined; // 当前选中的购物卡
  selectedMethod: string | undefined; // 当前选中的
  checkoutMethod: ICheckoutMethod;
  phoneNumber: string;
  isMobilePhone: boolean;
  password: string;
  signingIn: boolean;
  dialCodeGroupItem?: IDialCodeGroupItem;
  showPayMethod: boolean;
  showPayCardUI: boolean;
  isReady: boolean;
  adding: boolean;
  initialTotal: number; // 需要支付的金额
  showWxMiniProgram: boolean;
  isSplitOrder?: boolean;
  showGooglePay?: boolean;
}

class CheckoutPage extends BasePage<ICheckoutPageProps, ICheckoutPageState> {
  routeParams: IObject = {};

  constructor(props: ICheckoutPageProps) {
    super(props);
    const { reducersState } = props;

    this.state = {
      storeID: '',
      storeSlug: '',
      payCards: [],
      anonymousGiftCards: [],
      selectedGiftCard: undefined,
      selectedMethod: undefined,
      checkoutMethod: undefined,
      phoneNumber: '',
      password: '',
      signingIn: false,
      dialCodeGroupItem: undefined,
      showPayMethod: true,
      showPayCardUI: false,
      isReady: false,
      isMobilePhone: false,
      initialTotal: 0,
      adding: false,
      showWxMiniProgram: reducersState.app.deviceInfo.weChatMiniProgram,
      showGooglePay: false,
    }
  }

  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.lightBackgroundColor) {
      setScreenColor(appTheme.lightBackgroundColor);
    }

    super.componentDidMount();

    const routeParams = parseRouteParams(this.props);
    const { dispatch, reducersState } = this.props;
    const { anonymousGiftCards } = reducersState.pay;
    this.setState({
      storeSlug: routeParams.storeSlug,
      anonymousGiftCards: cloneDeep(anonymousGiftCards),
    });
    const { payInfo } = routeParams;
    let payStoreSlug = routeParams.storeSlug; // 下单时真正的storeSlug

    if (payInfo && payInfo.startsWith(Constants.PLATFORMPAYINFO)) {
      const payInfoObj = getPlatformPayInfo(payInfo);
      payStoreSlug = payInfoObj.storeSlug;
      this.setState({
        isSplitOrder: payInfoObj.isSplitOrder || false,
      })
    }

    dispatch({
      type: ActionType.GET_STORE_SHOPPING_CART_DATA,
      storeSlug: payStoreSlug,
      orderNumber: routeParams.orderNumber,
      callback: params => {
        const {storeID, allShoppingCartData} = params;
        let initialTotal = 0;

        // 如果shopping method === 2 代表堂食订单, 不进行合单, 使用当前店铺订单
        allShoppingCartData.forEach(item => {
          if (item.orderInfo.shippingMethod === 2) {
            if (String(item.orderInfo.storeID) === storeID) {
              initialTotal = plus(initialTotal, item.orderData.initial_total);
            }
          } else {
            initialTotal = plus(initialTotal, item.orderData.initial_total);
          }
        })


        // allShoppingCartData.forEach(item => {
        //   initialTotal = plus(initialTotal, item.orderData.initial_total);
        // })

        this.setState({ storeID, initialTotal });
      },
    });
  }

  googlePayShowOrHidden = () => {
    const { reducersState } = this.props;
    const { gatewayAppSettings } = reducersState.app;
    const { googlePayMethodKeys = [] } = gatewayAppSettings || {};
    const { google } = window as IWindowGoogle;
    const paymentsClient = new google.payments.api.PaymentsClient({
      environment: isStaging ? 'TEST' : 'PRODUCTION',
    });
    if (paymentsClient) {
      paymentsClient
        .isReadyToPay(
          Object.assign({}, {
            apiVersion: 2,
            apiVersionMinor: 0,
          }, {
            allowedPaymentMethods: [
              {
                type: 'CARD',
                parameters: {
                  allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                  allowedCardNetworks: googlePayMethodKeys,
                },
              }
            ]
          })
        )
        .then((response: any) => {
          if (response.result) {
            this.setState({ showGooglePay: true });
          }
        })
        .catch((err: any) => {
          console.error(err);
        });
    }
  };

  render() {
    const { reducersState } = this.props;
    const { language } = reducersState.app;
    const { checkoutMethod } = this.state;
    const { routeParams } = this.props;

    return (
      <Layout
        {...this.props}
        className={prefix}
        navIcon={<Icon className="html-embed-7 w-embed"  type={IconType.LEFT_OUTLINED} />}
        onLeftClick={() => {
          if (routeParams.fromPage === 'eShop') {
            this.handleGoBack({ path: paths.SHOPPING_CART_BY_FROM, pathParams: { storeSlug: routeParams.storeSlug, fromPage: 'eShop' } });
          } else {
            this.handleGoBack({ path: paths.SHOPPING_CART });
          }
        }}
        navContent={getIntl().page.checkoutTitle}
        isFooter={false}
      >
        <Script url="https://pay.google.com/gp/p/js/pay.js" onLoad={this.googlePayShowOrHidden} />
        <CheckoutStyled>
          {
            !checkoutMethod &&
            <CheckoutPageStyled>
              <div className="section-3"> 
                <div className="div-block-556">
                  <div className="container-5 w-container">
                    <div className="div-block-4">
                      <div className="text-block-214 black">1. {getIntl().page.chooseCheckoutMethod}</div>
                    </div>
                  </div>
                </div>
                <div className="div-block-520 not-100">
                  <div>
                    {
                      // (!checkoutMethod || checkoutMethod === 'GUEST') &&
                      <div className="sign-in-block w-inline-block" onClick={() => {this.setState({checkoutMethod: 'GUEST'})}}>
                        <Icon className="html-embed-43 w-embed" type={IconType.GUEST_OUTLINED} />
                        <div className="text-block-105">{getIntl().page.continueAsGuest}</div>
                      </div>
                    }
                    {
                      // (!checkoutMethod || checkoutMethod === 'MOBILE_SIGN_IN') &&
                      <div className="sign-in-block w-inline-block" onClick={() => {this.setState({checkoutMethod: 'MOBILE_SIGN_IN'})}}>
                        <Icon className="html-embed-43 w-embed" type={IconType.PHONE_OUTLINED} />
                        <div className="text-block-105">{getIntl().page.mobileSignIn}</div>
                      </div>
                    }
                    <SignInBottom className={`${prefix}-signin-bottom`} />
                  </div>
                </div>
              </div>
            </CheckoutPageStyled>
          }
          {
            checkoutMethod === 'GUEST' &&
            <GuestCheckoutStyled>
              <GuestCheckout
                language={language}
                { ...this.state }
                { ...this.props }
                resetState={(data: any) => this.setState(data)}
                onClick={selected => this.setState({checkoutMethod: selected ? 'GUEST' : undefined})}
              />
            </GuestCheckoutStyled>
          }
          {
            checkoutMethod === 'MOBILE_SIGN_IN' &&
            <GuestCheckoutStyled>
              <UserCheckout
                language={language}
                { ...this.state }
                { ...this.props }
                resetState={(data: any) => this.setState(data)}
                onClick={selected => this.setState({checkoutMethod: selected ? 'MOBILE_SIGN_IN' : undefined})}
              />
            </GuestCheckoutStyled>
          }
        </CheckoutStyled>
      </Layout>
    );
  }
}

export default CheckoutPage;
