import { IRoute } from '@/routes';
import paths from '@/routes/paths';
import QueueingDetailPage from './QueueingDetailPage';
import QueueingPage from './QueueingPage';
import BookingsPage from './QueueingsPage'

export const mobileRoutes: IRoute[] = [
  {
    path: paths.QUEUEING_NEW,
    component: QueueingPage,
  },
  {
    path: paths.QUEUEINGS,
    component: BookingsPage,
  },
  {
    path: paths.QUEUEING_DETAIL,
    component: QueueingDetailPage,
  }
];

export default mobileRoutes;
