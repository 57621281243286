import styled from 'styled-components';
export const PayBillPageStyled = styled.div`
.section {
  display: block;
  margin-top: auto;
  margin-bottom: 0px;
  padding: 60px 40px 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
}
.w-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
}
.container {
  position: relative;
  display: block;
  overflow: visible;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
}
.container.narr0w {
  max-width: 800px;
}
.columns-3 {
  overflow: hidden;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.w-container .w-row {
  margin-left: 0;
  margin-right: 0;
}
.div-block-615 {
  margin-top: 40px;
  padding: 30px;
  border-radius: 4px;
  background-color: var(--bm-light-background-color);
}
.w-col {
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-right: 10px;
  background-color: var(--bm-light-background-color);
}
.people-info {
  margin-top: 30px;
  padding: 20px 30px;
  border-style: solid;
  border-width: 0px;
  border-radius: 4px;
  background-color: var(--bm-light-background-color);
  color: var(--bm-text-title-color);
}
.text-block-104 {
  margin-right: auto;
  margin-bottom: 10px;
  margin-left: auto;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  }
._107 {
  margin-bottom: 0px;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: var(--bm-text-title-color);
  font-size: 16px;
  font-weight: 400;
}
.div-block-616 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 60px;
  margin-top: 30px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid var(--bm-border-color-t60);
  /* border-bottom: 1px solid var(--bm-shadow-color); */
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: var(--bm-text-title-color);
}
.div-block-617 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: none;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}
.text-block-67 {
  margin-bottom: 0px;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: var(--bm-text-title-color);
  font-size: 18px;
  font-weight: 400;
}
.text-block-67.bold {
  margin-bottom: 0px;
  padding: 0px;
  font-family: 'Sf pro display', sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-transform: none;
}
.text-block-227 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: 0%;
  font-size: 37px;
  line-height: 27px;
}
.text-block-228 {
  margin-top: 0px;
  margin-left: 40px;
  font-size: 37px;
  input {
    font-size: 40px;
  }
}
.text-block-228.inactive {
  margin-left: 80px;
  line-height: 40px;
}
.float-block-pay-bill.show-all {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 0vh;
}
.div-block-617.more-space {
    padding-top: 20px;
    padding-bottom: 20px;
    color: var(--bm-text-title-color);
    cursor: pointer;
}
.float-block-pay-bill {
  position: -webkit-sticky;
  position: sticky;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 2%;
  z-index: 9000;
  display: none;
  height: auto;
  margin-top: -9vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  background-color: transparent;
}
.div-block-617 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: none;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}
.text-block-67 {
  margin-bottom: 0px;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: var(--bm-text-title-color);
  font-size: 18px;
  font-weight: 400;
}
.text-block-67.bold {
  margin-bottom: 0px;
  padding: 0px;
  font-family: 'Sf pro display', sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-transform: none;
}
.float-navigation {
  display: block;
  margin-bottom: 0px;
}
.floating-big-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 800px;
  height: 65px;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 9px;
  color: var(--bm-light-background-color);
  text-decoration: none;
}
.text-block-17 {
  font-family: 'Sf pro display', sans-serif;
  font-size: 18px;
  font-weight: 600;
}
.div-block-616 {
  .am-list-item {
    background-color: var(--bm-light-background-color);
    border: none;
    min-height: unset;
  }
}

.section {
  background-color: none;
}
.floating-big-btn {
  a {
    display: block;
    width: 100%;
  }
}
.bm-c-pay-bill-method-modal.hide {
  -webkit-transition: top .5s ease;
  -o-transition: top .5s ease;
  transition: top .5s ease;
}
.bm-c-pay-bill-method-modal.hide,
.bm-c-pay-card-modal.hide {
  display: none;
}
.bm-c-pay-bill-method-modal.visible,
.bm-c-pay-card-modal.visible {
 display: flex;
}
.bm-p-pay-bill-content-pay-info-wapper {
  margin-top: unset;
}
.div-block-615-none {
  margin-top: 40px;
}
.am-list-item.am-input-item {
  height: unset;
}
@media screen and (max-width: 991px) {
  .section {
    margin-top: 0px;
    padding: 40px 35px 30px;
  }
  .w-container {
    max-width: 728px;
  }
  .div-block-615 {
    padding-top: 20px;
    padding-bottom: 10px;
  }
  .people-info {
    margin-top: 0px;
    padding: 10px 20px;
  }
  .div-block-617 {
    display: block;
    overflow: visible;
    margin-right: 0px;
    margin-left: 0px;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  .div-block-616 {
    height: 65px;
    margin-top: 15px;
  }
  .text-block-227 {
    font-size: 26px;
  }
  .text-block-228.inactive {
    margin-left: 55px;
    font-size: 27px;
    input {
      font-size: 27px;
    }
  }
  .div-block-617 {
    display: block;
    overflow: visible;
    margin-right: 0px;
    margin-left: 0px;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  .div-block-617.more-space {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .float-block-pay-bill {
    position: -webkit-sticky;
    position: sticky;
    display: none;
    margin-top: 0vh;
  }
  .float-block-pay-bill.show-all {
    margin-bottom: 40px;
    padding-right: 35px;
    padding-left: 35px;
  }
  .float-navigation {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }
  .floating-big-btn {
    width: 100%;
    height: 55px;
    margin-right: 0px;
    margin-left: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: var(--bm-light-background-color);
  }
}

@media screen and (max-width: 767px) {
  .section {
    padding: 83px 25px 20px;
  }
  .section.pay-bill {
    padding-top: 0;
    padding-right: 0px;
    padding-left: 0px;
  }
  .w-row, .w-container .w-row {
    margin-left: 0;
    margin-right: 0;
  }
  .div-block-615 {
    margin-top: 20px;
    padding-bottom: 20px;
  }
  .div-block-615.less-space {
    padding-bottom: 20px;
  }
  .w-col {
    width: 100%;
    left: auto;
    right: auto;
  }
  .column-10 {
    padding-right: 0px;
    padding-left: 0px;
  }
  .people-info {
    margin-top: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .text-block-104 {
    font-size: 15px;
  }
  .div-block-617 {
    margin-right: 0px;
    margin-left: 0px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .div-block-616 {
    height: 60px;
  }
  .text-block-67.bold {
    font-size: 18px;
  }
  .text-block-227 {
    font-size: 24px;
  }
  .text-block-228.inactive {
    font-size: 24px;
    input {
      font-size: 24px;
    }
  }
  .div-block-617 {
    margin-right: 0px;
    margin-left: 0px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .float-block-pay-bill {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .text-block-67.bold {
    font-size: 18px;
  }
  .float-navigation {
    margin-bottom: 30px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .text-block-17 {
    font-size: 18px;
  }
  .div-block-615-none {
    margin-top: 20px;
  }
}
@media screen and (max-width: 479px) {
  .section {
    padding: 68px 12px 15px;
  }
  .w-container {
    max-width: none;
  }
  .container {
    margin-top: 0px;
    margin-bottom: 0px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .div-block-615 {
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .div-block-615.less-space {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .w-col {
    width: 100%;
  }
  .text-block-104 {
    margin-bottom: 5px;
    line-height: 15px;
  }
  ._107 {
    margin-bottom: 0px;
    font-size: 14px;
  }
  .div-block-616 {
    height: 55px;
  }
  .text-block-67 {
    margin-bottom: 0px;
    font-size: 14px;
  }
  .text-block-67.bold {
    font-size: 16px;
  }
  .text-block-227 {
    font-size: 22px;
  }
  .text-block-228.inactive {
    font-size: 23px;
    input {
      font-size: 23px;
    }
  }
  .div-block-617.more-space {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .float-block-pay-bill.show-all {
    padding-right: 30px;
    padding-left: 30px; 
  }
  .float-navigation {
    margin-bottom: 30px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .text-block-67 {
    margin-bottom: 0px;
    font-size: 14px;
  }
  .text-block-67.bold {
    font-size: 16px;
  }
  .floating-big-btn {
    height: 50px;
    border-radius: 6px;
  }
  .div-block-615-none {
    margin-top: 10px;
  }


}

`;
