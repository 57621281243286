import React from 'react';
import { IStore } from '@/actions/store-action';
import LazyLoad from 'react-lazyload';
import { getI18nText } from '@/utils/app';
import { IReducersState } from '@/reducers';

interface IStoreGroupGridItemProps {
  store: IStore;
  className?: string;
  onClick?: (data: IStore) => void;
  reducersState: IReducersState;
}

const StoreGroupGridItem: React.FC<IStoreGroupGridItemProps> = props => {
  const { store, className = '', onClick, reducersState } = props;

  const handleClick = () => {
    if (onClick) {
      onClick(store);
    }
  }
  const { deviceInfo } = reducersState.app;

  return (
    <div className={`group-grid-item branch-card ${className}`} onClick={handleClick}>
      {
        deviceInfo.inPC &&
        <div className="image-card-upper" style={{backgroundImage: `url(${store.imgUrl})`}}></div>
      }
      {
        !deviceInfo.inPC &&
        <LazyLoad once offset={100}>
          <div className="image-card-upper" style={{backgroundImage: `url(${store.imgUrl})`}}></div>
        </LazyLoad>
      }
      <div className="div-block-99">
        <h4 className="heading-4">{getI18nText(store, 'title', reducersState.app.language) || store.name}</h4>
      </div>
    </div>
  );
}

export default StoreGroupGridItem;
