import React from 'react';
import PeopleItem from './PeopleItem';

interface IAdiltProps {
  max: number;
  adult: number;
  child: number;
  onClick: (nadult: number, child: number) => void;
}

const Adult: React.FC<IAdiltProps> = props => {
  const {
    max,
    adult,
    child,
    onClick,
  } = props;

  const onClickPeople = (people: number) => {
    const surplus = max - people;
    let setNumber = child;
    if (surplus < child) {
      setNumber = surplus;
    }
    onClick(people, setNumber);
  }

  return (
    <div className="div-block-103">
      <div className="form-block-2 w-form">
        <form className="form-2" >
          <div className="div-block-102">
            {
              Array.from(Array(max), (_item, index) => {
                if (index + 1=== adult) {
                  return (
                    <PeopleItem 
                      key={`adult-${index}`}
                      className={'select'}
                      people={index+1}
                      onClick={onClickPeople}
                      select={true}
                    />
                  )
                } else {
                  return (
                    <PeopleItem
                      key={`adult-${index}`}
                      className={'unselect'} 
                      people={index+1} 
                      onClick={onClickPeople} 
                      select={false} 
                    />
                  ) 
                }
              })
            }
          </div>
        </form>
      </div>
    </div>
  );
}

export default Adult;
