import React from 'react';
import { prefix } from '../';
import { ICreateViewParams } from '.';
import { resizeImage } from '@/utils';
import DotsAndBtn from '../DotsAndBtn';
import { getI18nText } from '@/utils/app';

interface ILogoViewProps extends ICreateViewParams {
}

const LogoView: React.FC<ILogoViewProps> = props => {
  const {
    data,
    count,
    index,
    showItemBtns,
    onDoneClick,
    onNextClick,
    language,
  } = props;

  const imgUrl = resizeImage({
    url: data.imageUrl,
    type: 'FILL',
    width: 750,
    height: 750,
  })
  
  return (
    <div className={`${prefix}-item`}>
      <div className="logo-item-img-wrapper">
        {
          imgUrl &&
          <img src={imgUrl} alt="" />
        }
      </div>
      <div className="logo-item-content-wrapper">
        <div className="logo-item-content" style={{ textAlign: data.alignment }}>
          {
            data.title &&
            <div className="logo-item-title">{getI18nText(data, 'title', language) || data.title}</div>
          }
          {
            data.describe &&
            <div className="logo-item-describe">{getI18nText(data, 'text', language) || data.describe}</div>
          }
        </div>
      </div>
      {
        showItemBtns &&
        <DotsAndBtn
          count={count}
          index={index}
          onNextClick={onNextClick}
          onDoneClick={onDoneClick}
        />
      }
    </div>
  );
}

export default LogoView;
