import {
  ICheckOrderstatusParams, IPayOrderResult,
} from './index';
import { checkOrderStatu } from './payment-data';

/**
   * 轮询订单状态
  */
// const checkOrderStatusv1 = async (params: ICheckOrderstatusParams): Promise<IPayOrderResult> => {

//   const {
//     storeID,
//     orderNumber,
//     interval = 2000,
//     count = 150,
//     callback
//   } = params;

//   let timesRun = 0;
//   return new Promise ( async (resolve, reject) => {
//     const checkLoop = setInterval( async () => {
//       const checkResp = await checkOrderStatu(storeID, orderNumber);

//       const {
//         order,
//       } = checkResp || {};

//       timesRun += 1;
//       if (timesRun === count) {
//         clearInterval(checkLoop);
//         resolve({
//           status: 504,
//           message: 'Time out',
//           order,
//         });
//         if(callback) {
//           callback({
//             status: 500,
//           });
//         }
//       }

//       const {
//         state,
//         correspondence_state: correspondenceState,
//       } = order || {};

//       if (state === 'completed' && correspondenceState === 'paid') {
//         clearInterval(checkLoop);
//         resolve({
//           status: 200,
//           order,
//         });
//         if(callback) {
//           callback({
//             status: 200,
//             order,
//           });
//         }
//       }

// if (status !== 'ok') {
//   clearInterval(checkLoop);
//   resolve({
//     status,
//     order,
//   });
//   if(callback) {
//     callback({
//       status: 500,
//       order,
//     });
//   }
// }
//     }, interval)
//   });
// }
let timeLoop: any;
let timesRun = 0;
const checkLoop = () => {
  timeLoop = setInterval(() => {
    timesRun += 1;
  }, 1000);
}

const checkOrderStatus = async (params: ICheckOrderstatusParams): Promise<IPayOrderResult> => {
  const {
    storeID,
    orderNumber,
    callback,
    timeLimit = 300,
  } = params;

  if (!timeLoop) {
    checkLoop();
  }

  if (timesRun >= timeLimit) {
    clearInterval(timeLoop);
    return {
      status: 504,
      message: 'Time out',
    }
  }

  const checkResp = await checkOrderStatu(storeID, orderNumber);

  const {
    order,
  } = checkResp || {};

  // 接口抛错的情况
  if (!checkResp || !order) {
    callback && callback({
      message: 'Api error'
    })
    return;
  }

  const {
    state,
    correspondence_state: correspondenceState,
    failed_transactions: failedTransactions,
  } = order || {};

  if (state === 'completed' && correspondenceState === 'paid') {
    if(callback){
      callback({
        order,
      });
    }
    return { order }
  } else if (Array.isArray(failedTransactions) && failedTransactions.length > 0){
    if(callback){
      callback({
        order,
        status: 424 // 支付check到失败
      });
    }
    return {
      order,
      status: 424 // 支付check到失败
    }
  } else {
    await checkOrderStatus(params);
  }
}

export default checkOrderStatus;
