import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
  IWeekBooking,
  ITimeSegmentsQuota,
  IBooking,
  ITable,
  INumberQuota,
} from '@/actions/booking-action';
import ChooseTimeItem from './ChooseTimeItem';
import parseInt from 'lodash/parseInt';
import { getI18nText } from '@/utils/app';
import { IObject } from '@/components/bm-common';
import { IReducersState } from '@/reducers';

interface IAdiltProps {
  week: string;
  select: string;
  selectDay: string;
  weekBooking: IWeekBooking;
  timeSegmentIDs: string[];
  minuteIntervalType: string;
  minimumStartBookingTime: string;
  minimumStartBookingTimeType: string;
  onClick: (time: string, timeSegmentID: string, duration: number) => void;
  timeSegmentsQuotas: ITimeSegmentsQuota[];
  bookings: IBooking[];
  tables: ITable[];
  tableTypeID: string;
  reducersState: IReducersState;
  numberQuota: INumberQuota[];
  peopleNumber: number;
}

export interface ITimeTimeSegmentID {
  time: string;
  timeSegmentID: string;
  tableTypeID: string;
  title: string;
  i18n: IObject;
}

const Child: React.FC<IAdiltProps> = props => {
  const [useBookings, setSUseBookings] = useState<IBooking[]>([]);
  const [newtimes, setNewTimes] = useState<any[]>([]);
  const [timeSegmentID, setTimeSegmentID] = useState<string>('');
  const [showtimes, setShow] = useState<any[]>([]);

  const {
    week,
    select,
    selectDay,
    weekBooking,
    timeSegmentIDs,
    minuteIntervalType,
    minimumStartBookingTime,
    minimumStartBookingTimeType,
    tables,
    onClick,
    tableTypeID,
    bookings,
    timeSegmentsQuotas,
    reducersState,
    numberQuota,
    peopleNumber,
  } = props;

  useEffect(() => {
    let table: ITable | undefined = undefined
    tables.forEach(item => {
      if (item.tableTypeID === tableTypeID) {
        table = item;
      }
    })
    const newBookings = bookings.filter(item => table && item.people <= table.max && item.people >= table.min)
    setSUseBookings(newBookings);
    const useTime = weekBooking[week];
    const times: ITimeTimeSegmentID[] = [];
    if (useTime) {
      useTime.forEach(item => {
        
        if (timeSegmentIDs.includes(item.timeSegmentID)) {
          if (minuteIntervalType) {
            let now = dayjs();
            if (minimumStartBookingTimeType === '1') {
              now = dayjs().add(Number(minimumStartBookingTime), 'minute');
            } else if (minimumStartBookingTimeType === '2') {
              now = dayjs().add(Number(minimumStartBookingTime), 'hour');
            }
            let start = dayjs(selectDay + item.start);
            const end = dayjs(selectDay + item.end);
            while (start.isBefore(end)) {
              if (now.date() === start.date()) {
                if (start.isAfter(now)) {
                  times.push({
                    time: start.format('HH:mm'),
                    timeSegmentID: item.timeSegmentID,
                    tableTypeID,
                    title: item.title,
                    i18n: item.i18n,
                  });
                }
              } else {
                times.push({
                  time: start.format('HH:mm'),
                  timeSegmentID: item.timeSegmentID,
                  tableTypeID,
                  title: item.title,
                  i18n: item.i18n,
                });
              }
              start = start.add(parseInt(minuteIntervalType), 'm');
            }
          }
        }
      })
      const obj: any = {};
      times.forEach(item => {
        if (!obj[item.title]) {
          obj[item.title] = {
            title: item.title,
            timeSegmentID: item.timeSegmentID,
            children: [],
            time: item.time,
            i18n: item.i18n,
          }
        }
        obj[item.title].children.push(item);
      })
      const data: any[] = Object.values(obj); 
      setNewTimes(data);
      if (data && data.length > 0) {
        setTimeSegmentID(data[0].timeSegmentID);
        setShow(time(data, data[0].timeSegmentID));
      }
    } else {
      if (minuteIntervalType) {
        let start = dayjs();
        const end = dayjs(start.format('YYYY-MM-DD')).add(1, 'day');
        while (start.isBefore(end)) {
          times.push({
            time: start.format('HH:mm'),
            timeSegmentID: '',
            tableTypeID,
            title: '',
            i18n: {},
          });
          start = start.add(parseInt(minuteIntervalType), 'm');
        }
      }
      if (times && times.length > 0) {
        setShow([{children: times}]);
      }
    }
  }, [selectDay, timeSegmentIDs, tableTypeID, bookings]);

  const onItemClick = (item: any) => {
    setTimeSegmentID(item.timeSegmentID);
    setShow(time(newtimes, item.timeSegmentID));
  }
  const time = (data: any, newtimeSegmentID: string) => {
    const timearr = data.filter((item: any) => item.timeSegmentID === newtimeSegmentID);
    return timearr;
  }

  return (
    <div className="div-block-103">
      <div className="form-block-2 w-form">
        <form action="" className="form-2">
          <div className="div-block-105">
            {
              newtimes.map(item => {
                return <label className="radio-button-time w-radio" key={item.title}  onClick={() => onItemClick(item)} >
                  <span  className={`label-1a w-form-label ${timeSegmentID === item.timeSegmentID ? 'selected-title' : ''}`}>
                    {getI18nText(item, 'title', reducersState.app.language) || item.title}
                  </span>
                </label>
              })
            }
          </div>
          <div className="div-block-102">
            {
              showtimes.map(_item => (
                _item.children.map((item: any) => {
                  return <ChooseTimeItem key={`${week}-${item.time}`} className={dayjs(selectDay + select).isSame(dayjs(selectDay + item.time)) ? 'select-date' : 'unselect-date'} data={item} onClick={onClick} bookings={useBookings} timeSegmentsQuotas={timeSegmentsQuotas} selectDay={selectDay} numberQuota={numberQuota} allBookings={bookings} peopleNumber={peopleNumber} />
                })
              ))
            }
          </div>
        </form>
      </div>
    </div>
  );
}

export default Child;
