import React from 'react';

interface IPanelProps {
  title: React.ReactNode;
  serialNumber: number;
  rightContent?: React.ReactNode;
}

const Panel: React.FC<IPanelProps> = props => {
  const {
    title,
    serialNumber = 1,
    children,
    rightContent,
  } = props;

  return (
    <div className="div-block-558">
      <div className="div-block-4 with-space">
        <div className="text-block-214 black">{serialNumber}. {title}</div>
        {
          rightContent &&
          <div className="text-block-214 bold">{rightContent}</div>
        }
      </div>
      {children}
    </div>
  );
}

export default Panel;
