import React from 'react';
import { prefix } from '.';

const Skeleton: React.FC = () => (
  <div className={`${prefix}-skeleton`}>
    <div className={`${prefix}-skeleton-img`}></div>
    <div className={`${prefix}-skeleton-info`}>
      <div className="title" />
      <div className="describe">
        <div className="describe-item" />
        <div className="describe-item" />
        <div className="describe-item" />
      </div>
      <div className="action">
        <div className="action-item" />
        <div className="action-item" />
      </div>
    </div>
    <div className={`${prefix}-skeleton-action-nav`}>
      <div className="action-nav-item" />
      <div className="action-nav-item" />
      <div className="action-nav-item" />
      <div className="action-nav-item" />
    </div>
    <div className={`${prefix}-skeleton-menu-nav`}>
      <div className="skeleton-menu-nav-wrapper">
        <div className="skeleton-menu-nav-item" />
        <div className="skeleton-menu-nav-item" />
        <div className="skeleton-menu-nav-item" />
        <div className="skeleton-menu-nav-item" />
        <div className="skeleton-menu-nav-item" />
        <div className="skeleton-menu-nav-item" />
        <div className="skeleton-menu-nav-item" />
        <div className="skeleton-menu-nav-item" />
        <div className="skeleton-menu-nav-item" />
        <div className="skeleton-menu-nav-item" />
      </div>
    </div>
    <div className={`${prefix}-skeleton-menu-list`}>
      <div className="tab-title" />
      <div className="menu-item">
        <div className="menu-item-icon" />
        <div className="menu-item-content">
          <div className="menu-item-content-1" />
          <div className="menu-item-content-2" />
          <div className="menu-item-content-3" />
        </div>
      </div>
      <div className="menu-item-space" />
      <div className="menu-item">
        <div className="menu-item-icon" />
        <div className="menu-item-content">
          <div className="menu-item-content-1" />
          <div className="menu-item-content-2" />
          <div className="menu-item-content-3" />
        </div>
      </div>
      <div className="menu-item-space" />
      <div className="menu-item">
        <div className="menu-item-icon" />
        <div className="menu-item-content">
          <div className="menu-item-content-1" />
          <div className="menu-item-content-2" />
          <div className="menu-item-content-3" />
        </div>
      </div>
      <div className="menu-item-space" />
      <div className="menu-item">
        <div className="menu-item-icon" />
        <div className="menu-item-content">
          <div className="menu-item-content-1" />
          <div className="menu-item-content-2" />
          <div className="menu-item-content-3" />
        </div>
      </div>
    </div>
  </div>
);

export default Skeleton;
