import axios from 'axios';
import Config from '@/Config';
import Constants from '@/constants';
import { IUserInfo } from '@/actions/user-action';
import { getDomain, createRouteUrl } from '@/utils';
import paths from '@/routes/paths';
import { setAccessToken, getAccessToken, removeAccessToken } from '@/utils/app';

const getPlatformID = () => {
  return localStorage.getItem(Constants.PLATFORM_ID) || 37;
}

export interface ISignInParams {
  username: string;
  password: string;
  loginMethod?: string;
  grantType?: string;
  platformID?: number;
}

export const signIn = async (params: ISignInParams) => {
  const {
    username,
    password,
    loginMethod = 'phone',
    grantType = 'password',
    platformID = getPlatformID(),
  } = params;

  const resp = await axios({
    method: 'post',
    url:  Config.urls.signIn,
    data: {
      'client_id': Config.clientID,
      'client_secret': Config.clientSecret,
      'username': username,
      'password': password,
      'platform_id': platformID,
      'grant_type': grantType,
      'loginMethod': loginMethod,
    }
  });

  const {
    user,
    data,
  } = resp || {}


  if (user && user.access_token) {
    setAccessToken(user.access_token);
    if (loginMethod === 'guest') {
      localStorage.setItem(Constants.IS_GUEST, 'true');
    } else {
      localStorage.setItem(Constants.IS_GUEST, 'false');
    }
    return user;
  } else { // sign in failed
    if (data && data.message) {
      return {
        error: true,
        message: data.message,
      }
    }
  }
};

export interface ISignUpParams {
  password: string;
  phone: string;
  fullName: string;
  userName: string;
  userEmail: string;
  platformID?: number;
  registerType?: number;
}

export const signUp = async (params: ISignUpParams) => {
  const {
    phone,
    password,
    fullName,
    userName,
    userEmail,
    platformID = getPlatformID(),
    registerType = 0,
  } = params;

  let success = false;

  const resp = await axios({
    method: 'post',
    url:  Config.urls.signUp,
    data: {
      'client_id': Config.clientID,
      'client_secret': Config.clientSecret,
      'user': {
        'phone': phone,
        'password': password,
        'username': userName,
        'full_name': fullName,
        'user_email': userEmail,
        'platform_id': platformID,
        'customer_registration_type': registerType,
      },
    }
  });

  if (resp && resp.user && resp.user.id) {
    success = true;
  }

  return success;
};

export interface ISenSmsCodeParams {
  phone: string;
  action?: string;
  platformID?: number;
  storeID?: number;
}

export const sendSmsCode = async (params: ISenSmsCodeParams) => {
  const {
    phone,
    action = 'pwdreset',
    platformID = getPlatformID(),
  } = params;

  let success = false;
  try {
    const resp = await axios({
      method: 'post',
      url: Config.urls.sendSMSCode,
      data: {
        'client_id': Config.clientID,
        'client_secret': Config.clientSecret,
        phone,
        action,
        'platform_id': platformID,
      }
    });

    if (resp.success) {
      success = true;
    }

    const {
      data,
    } = resp || {};

    const {
      message
    } = data || {};

    return {
      success,
      message,
    };
  } catch (error) {
    console.error(error)
  }
}

interface IRestPasswordParams {
  autCode: string;
  phone: string;
  password: string;
  platformID?: number;
}

export const restPasswprd = async (params: IRestPasswordParams) => {
  const {
    phone,
    autCode,
    password,
    platformID = getPlatformID(),
  } = params;

  let success = false;
  try {
    const resp = await axios({
      method: 'post',
      url: Config.urls.restPassword,
      data: {
        'client_id': Config.clientID,
        'client_secret': Config.clientSecret,
        'platform_id': platformID,
        'aut_code': autCode,
        phone,
        password,
      }
    });

    if (resp.success) {
      success = true;
    }

    const {
      data,
    } = resp || {};

    const {
      message
    } = data || {};

    return {
      success,
      message,
    };
  } catch (error) {
    console.error(error)
  }
}

interface IRestPasswordEmailParams {
  email: string;
  language: string;
  authCode?: string;
  platformID?: number;
}

export const restPasswordEmail = async (params: IRestPasswordEmailParams) => {
  const {
    email,
    language,
    authCode,
  } = params;

  const {
    platformDomain,
    isLocalhost,
    platformHost,
  } = getDomain();

  const protocol = `http${isLocalhost ? '' : 's'}:`;
  const SignInPaths = `${protocol}//${platformHost}${createRouteUrl(
    paths.SIGN_IN,
    {},
  )}`;

  let success = false;
  try {
    // const url = Config.urls.restPasswordEmail.replace('{email}', email).replace('{platformID}', `${platformID}`).replace('{authCode}', `${authCode}`);
    const url = Config.urls.forgotPasswordEmail.replace('{authCode}', `${authCode}`);
    const resp = await axios({
      method: 'post',
      url,
      data: {
        'client_id': Config.clientID,
        'client_secret': Config.clientSecret,
        'platform_domain': platformDomain, // 当前平台域名（用来取主题）
        identifier: email,
        domain: Config.mbDoamin, // MB的域名，去MB找回密码
        lang: language,
        direct: SignInPaths, // 找回密码成功之后跳转回来的地址
      }
    });

    if (!resp.data) {
      success = true;
    }

    const {
      data,
    } = resp || {};

    const {
      message
    } = data || {};

    return {
      success,
      message,
    };

  } catch (error) {
    console.error(error);
  }
}

export interface IPutMeParams {
  name?: string;
  gender?: string;
  email?: string;
  phone?: string;
  dateOfBirth?: string;
  avatar?: any;
}

export const PutMe = async (params: IPutMeParams) => {
  const {
    name,
    gender,
    email,
    phone,
    dateOfBirth,
    avatar,
  } = params;
  let userInfo: IUserInfo | undefined = undefined;
  let data: any;
  if (phone && email) {
    data = {
      'full_name': name,
      gender,
      phone,
      email,
      'date_of_birth': dateOfBirth,
    }
  } else if (phone) {
    data = {
      'full_name': name,
      gender,
      phone,
      'date_of_birth': dateOfBirth,
    }
  } else if (email) {
    data = {
      'full_name': name,
      gender,
      email,
      'date_of_birth': dateOfBirth,
    }
  }
  try {
    const resp = await axios({
      headers: { 'Content-Type' : avatar ? 'multipart/form-data' : 'application/json' },
      method: 'put',
      url:  Config.urls.me,
      data: avatar ? avatar : data,
    });

    if (resp && resp.data && resp.data.user && resp.data.user.id) {
      const user = resp.data.user;

      userInfo = {
        id: user.id,
        name: user.full_name,
        gender: user.gender || '',
        email: user.email || '',
        phone: user.phone || '',
        phoneConfirmed: user.phone_confirmed || false,
        dateOfBirth: user.date_of_birth || '',
        avatarUrl: user.avatar_url || '',
      }
    } else {
      removeAccessToken();
    }
  } catch (error) {
    console.error(error)
  }

  return userInfo;
}


interface IPhoneSignUp {
  autCode: string;
  phone: string;
  password: string;
  fullName: string;
  platformID?: number;
  gender?: string;
  dateOfBirth?: string;
  email?: string;
  acceptInformation?: boolean;
  closeRegisterEmail?: boolean;
}

export const phoneSignUp = async (params: IPhoneSignUp) => {
  const {
    phone,
    autCode,
    password,
    fullName,
    platformID = getPlatformID(),
    gender,
    dateOfBirth,
    email,
    acceptInformation = false,
    closeRegisterEmail = false,
  } = params;

  let success = false;
  try {
    const resp = await axios({
      method: 'post',
      url: `${Config.urls.signUpPhone}?close_register_mail=${closeRegisterEmail}`,
      data: {
        'client_id': Config.clientID,
        'client_secret': Config.clientSecret,
        'platform_id': platformID,
        'aut_code': autCode,
        'full_name': fullName,
        'date_of_birth': dateOfBirth,
        'accept_promotional_information': acceptInformation,
        phone,
        password,
        gender,
        email,
      }
    });

    if (resp.user) {
      success = true;
    }

    const {
      data,
    } = resp || {};

    const {
      message
    } = data || {};

    return {
      success,
      message,
    };
  } catch (error) {
    console.error(error)
  }
}

interface IEmailSignUp {
  userEmail: string;
  password: string;
  fullName: string;
  platformID?: number;
  gender?: string;
  dateOfBirth?: string;
  phone?: string;
  acceptInformation?: boolean;
  registerType?: number;
}
export const emailSignUpV2 = async (params: IEmailSignUp) => {
  const {
    userEmail,
    password,
    fullName,
    platformID = getPlatformID(),
    dateOfBirth,
    gender,
    phone,
    acceptInformation = false,
    registerType = 0,
  } = params;

  let success = false;
  try {
    const resp = await axios({
      method: 'post',
      url: Config.urls.signUpEmail,
      data: {
        'client_id': Config.clientID,
        'client_secret': Config.clientSecret,
        user: {
          'platform_id': platformID,
          'full_name': fullName,
          'user_email': userEmail,
          'date_of_birth': dateOfBirth,
          'accept_promotional_information': acceptInformation,
          'customer_registration_type': registerType,
          password,
          gender,
          phone,
        }
      }
    });

    if (resp.user) {
      success = true;
    }
    const {
      data,
    } = resp || {};

    const {
      message
    } = data || {};

    return {
      success,
      message,
    };
  } catch (error) {
    console.error(error)
  }
}

interface IFaceBookSignInParams {
  faceBooKToken: string;
}

export const faceBookSignIn = async (params: IFaceBookSignInParams) => {

  const {
    faceBooKToken,
  } = params;

  try {
    const resp = await axios({
      method: 'post',
      url: Config.urls.fbSignIn,
      data: {
        'client_id': Config.clientID,
        'client_secret': Config.clientSecret,
        'fb_token': faceBooKToken,
      }
    });

    const {
      user,
      data,
    } = resp || {}

    if (user && user.access_token) {
      setAccessToken(user.access_token);
      localStorage.setItem(Constants.USER_INFO, JSON.stringify(user));
      localStorage.setItem(Constants.IS_GUEST, 'false');
      return user;
    } else { // sign in failed
      if (data && data.message) {
        return {
          error: true,
          message: data.message,
        }
      }
    }
  } catch (error) {
    console.error(error)
  }
}

interface IAppleUserID {
  appleUserID: string;
}

export const appleSignIn = async (params: IAppleUserID) => {
  const {
    appleUserID,
  } = params;

  try {
    const resp = await axios({
      method: 'post',
      url: Config.urls.appleSignIn,
      data: {
        appid: '69FUKXJ2K8',
        'key_id': '33QZF97VH8',
        'team_id': '69FUKXJ2K8',
        'bundle_id': 'com.bindo.mobile',
        'third_party_id': appleUserID,
        user: appleUserID,

      }
    });

    const {
      user,
      data,
    } = resp || {}

    if (user && user.access_token) {
      setAccessToken(user.access_token);
      localStorage.setItem(Constants.USER_INFO, JSON.stringify(user));
      localStorage.setItem(Constants.IS_GUEST, 'false');
      return user;
    } else { // sign in failed
      if (data && data.message) {
        return {
          error: true,
          message: data.message,
        }
      }
    }
  } catch (error) {
    console.error(error)
  }
}
/**
 * grapql query me
 */
export const getGalaxyMe = async () => {
  let userInfo: IUserInfo | undefined = undefined;
  try {
    let config = {};
    const token = getAccessToken();
    if (token) {
      config = {
        'headers': {
          'X-USER-ACCESS-TOKEN': token,
        }
      };
    }

    const resp = await axios.post(Config.galaxy, {
      'operationName': null,
      'query': `
        query($skipped: Boolean) {
          me(skipped: $skipped) {
            id
            fullName
            phone
            email
            staff
            admin
            accessToken
            gender
            phoneConfirmed
            dateOfBirth
            avatarUrl
          }
        }
      `,
    },
    config,
    );

    if (resp && resp.data && resp.data.me && resp.data.me.id) {
      const user = resp.data.me;

      userInfo = {
        id: user.id,
        name: user.fullName,
        gender: user.gender || '',
        email: user.email || '',
        phone: user.phone || '',
        phoneConfirmed: user.phoneConfirmed || false,
        dateOfBirth: user.dateOfBirth || '',
        avatarUrl: user.avatarUrl || '',
        staff: user.staff,
        admin: user.admin,
      }
    } else {
      removeAccessToken();
    }
    return userInfo;
  } catch (error) {
    console.error(error);
  }
}

export const signUpUser = async (params: ISignUpParams) => {
  const {
    phone,
    password,
    fullName,
    userName,
    userEmail,
  } = params;

  const resp = await axios({
    method: 'post',
    url:  Config.urls.signUp,
    data: {
      'client_id': Config.clientID,
      'client_secret': Config.clientSecret,
      'user': {
        'phone': phone,
        'password': password,
        'username': userName,
        'full_name': fullName,
        'user_email': userEmail,
      },
    }
  });
  let user = undefined;
  if (resp && resp.user && resp.user.id) {
    user = resp.user.id;
  }
  return user;
};

export interface ISenEmailVerfityCodeParams {
  email: string;
  language: string;
  action?: string;
  platformID?: number;
  storeID?: number;
}

export const sendEmailVertifyCode = async (params: ISenEmailVerfityCodeParams) => {
  const {
    email,
    language,
    action,
    platformID = getPlatformID(),
  } = params;

  let success = false;
  try {
    const resp = await axios({
      method: 'post',
      url: Config.urls.sendEmailVerify,
      data: {
        'client_id': Config.clientID,
        'client_secret': Config.clientSecret,
        'user_email': email,
        'platform_id': platformID,
        'action': action,
      },
      headers: {
        'X-I18N-LANG': language,
      },
    });

    if (resp.success) {
      success = true;
    }

    const {
      data,
    } = resp || {};

    const {
      message
    } = data || {};

    return {
      success,
      message,
    };
  } catch (error) {
    console.error(error)
  }
}

interface IEmailSignUpWithEmailCode {
  userEmail: string;
  password: string;
  fullName: string;
  platformID?: number;
  gender?: string;
  dateOfBirth?: string;
  phone?: string;
  acceptInformation?: boolean;
  autCode: string;
  auth: number;
  language: string;
  closeRegisterEmail?: boolean;
}
export const emailSignUpWithCode = async (params: IEmailSignUpWithEmailCode) => {
  const {
    userEmail,
    password,
    fullName,
    platformID = getPlatformID(),
    dateOfBirth,
    gender,
    phone,
    acceptInformation = false,
    autCode,
    auth,
    language,
    closeRegisterEmail = false,
  } = params;

  let success = false;

  try {
    const resp = await axios({
      method: 'post',
      url: `${Config.urls.signUpEmailWithCode}?close_register_mail=${closeRegisterEmail}`,
      data: {
        'client_id': Config.clientID,
        'client_secret': Config.clientSecret,
        user: {
          'platform_id': platformID,
          'full_name': fullName,
          'user_email': userEmail,
          'date_of_birth': dateOfBirth,
          'accept_promotional_information': acceptInformation,
          password,
          gender,
          phone,
          'aut_code': autCode,
        },
        auth: auth,
      },
      headers: {
        'X-I18N-LANG': language,
      },
    });

    if (resp.user) {
      success = true;
    }
    const {
      data,
    } = resp || {};

    const {
      message
    } = data || {};

    return {
      success,
      message,
    };
  } catch (error) {
    console.error(error)
  }
}

