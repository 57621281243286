import { takeEvery, call } from 'redux-saga/effects';
import axios from 'axios';
import Config from '@/Config';
import ActionType from '@/actions/action-type';
import {
  IPurchaseOrder,
  IPurchaseOrderDetail,
  ICancelPurchaseOrder,
  IQueryPurchaseOrder,
  IQueryPurchaseOrders,
} from '@/actions/purchase-order-action';
import {
  parseResPurchaseOrders,
  parseResPurchaseOrderDetail,
} from '@/utils/purchase-order-util';
import { 
  queryRecords,
  queryRelationRecords,
} from '@/data/mb-api';


function* handleCancelPurchaseOrder(params: ICancelPurchaseOrder) {
  const { purchaseOrderDetail, callback } = params;
  let purchaseOrder: IPurchaseOrderDetail | undefined = undefined;

  try {
    const res = yield call(
      axios.put,
      Config.urls.purchaseOrderCancel.replace('{storeID}', purchaseOrderDetail.storeID).replace('{purchaseOrderID}', purchaseOrderDetail.id),
    );

    if (res && res.purchase_order && res.purchase_order.id) {
      purchaseOrder = {
        ...purchaseOrderDetail,
        state: res.purchase_order.state,
      }
    }
  } catch (error) {
    console.error(error);
  }
  if (callback) {
    callback(purchaseOrder);
  }
}

function* handleQueryPurchaseOrders(params: IQueryPurchaseOrders) {
  const { storeSlug, page, formula, callback } = params;
  let purchaseOrders: IPurchaseOrder[] = [];

  try {
    const result = yield queryRecords({
      storeSlugs: [storeSlug],
      moduleBundleID: '0g13w1b1',
      search: {
        perPage: 10,
        page,
        formulas: [{formula}]
      },
    });
    const { records } = result;
    purchaseOrders = parseResPurchaseOrders(records)
  } catch (error) {
    console.error(error);
  }
  if (callback) {
    callback(purchaseOrders)
  }
}

function* handleQueryPurchaseOrder(params: IQueryPurchaseOrder) {
  const { storeID, PurchaseOrderID, callback, storeSlug } = params;
  let purchaseOrder: IPurchaseOrderDetail | undefined = undefined;
  let supplier: any = '';
  const listingIDs: string[] = [];
  const images: {[id: string]: any} = {};

  try {
    const res = yield call(
      axios.get,
      Config.urls.getPurchaseOrder.replace('{storeID}', storeID).replace('{purchaseOrderID}', PurchaseOrderID),
    );
    if (res && res.purchase_order) {
      const purchaseItems = res.purchase_order.purchase_items;
      if (purchaseItems && Array.isArray(purchaseItems)) {
        purchaseItems.forEach(item => {
          if (item.source_type === 'Listing') {
            listingIDs.push(item.source_id);
          }
        })
      }
      if (listingIDs.length > 0) {
        const relationRecords = yield queryRelationRecords({id: null, ids: listingIDs, field: 'product_graphics', moduleBundleID: 'vf19alji', storeSlugs: [storeSlug], search: {
          orderAsc: 'ASC',
          orderBy: 'id',
          perPage: listingIDs.length * 10,
          page: 1,
          formulas: [{formula: 'EQ("$.self.default", 1)'}],
        }})
        if (relationRecords && relationRecords['records'] && Array.isArray(relationRecords['records'])) {
          relationRecords['records'].forEach(item => {
            if (item['default'] === true && item['deleted'] === false && item['product_id'] && item['record_image']) {
              images[item['product_id']] = item['record_image']
            }
          })
        }
      }
      if (res.purchase_order.supplier_id) {
        const suppliers = yield queryRecords({moduleBundleID: '1ur2rtix', storeSlugs: [storeSlug], search: {
          formulas: [{formula: `EQ("$.self.id", ${res.purchase_order.supplier_id})`}],
        }});
        if (suppliers && suppliers['records'] && Array.isArray(suppliers['records']) && suppliers['records'].length > 0) {
          supplier = suppliers['records'][0];
        }
      }
      purchaseOrder = parseResPurchaseOrderDetail(res.purchase_order, images, supplier);
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(purchaseOrder);
  }
}

function* paySaga() {
  yield takeEvery(ActionType.CANCEL_PURCHASE_ORDER, handleCancelPurchaseOrder);
  yield takeEvery(ActionType.QUERY_PURCHASE_ORDERS, handleQueryPurchaseOrders);
  yield takeEvery(ActionType.QUERY_PURCHASE_ORDER, handleQueryPurchaseOrder);
}

export default paySaga();
