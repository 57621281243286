import React, { ReactElement } from 'react';
import GoogleMapReact, { MapOptions } from 'google-map-react';
// import {fitBounds} from 'google-map-react/utils';
import { getCoordinates } from '@/utils/app'
import MapSearchBox from './NewMapSearchBox';
import GoogleMapConfig from '../GoogleMapConfig';
import GoogleMapMarker from '../common/GoogleMapMarker';
import GoogleMapList from './NewGoogleMapList';
import GoogleMapEditSuite from './GoogleMapEditSuite';
import Icon, { IconType } from '@/components/Icon';
import './googlemap.less';

import { prefix, IGoogleMapAdress, IAddressCenter, IGeoCodeLocation } from '..';

export interface IGoogleMapProps {
  detailAddress: ReactElement;
  addressCenter?: IAddressCenter;
  editAddress?: boolean;
  savePlace?: (params: IGoogleMapAdress) => void;
}

interface IGoogleMapState {
  mapInstance: any;
  mapApi: any;
  // places: any[];
  placesService: any | null;
  nearPlaces: any[];
  editSuite: boolean;
  editPlace: any;
  zoom: number;
  mapGeocoder: any | null;
  cityInfo: IGeoCodeLocation | any;
  areaInfo: IGeoCodeLocation | any;
  regionInfo: IGeoCodeLocation | any;
  userLat: number;
  userLng: number;
}

class GoogleMap extends React.Component<IGoogleMapProps, IGoogleMapState> {

  constructor (props: IGoogleMapProps) {
    super(props);

    const {
      editAddress = false,
      addressCenter,
    } = props;
    this.state = {
      mapInstance: null,
      mapApi: null,
      // places: [],
      placesService: null,
      mapGeocoder: null,
      nearPlaces: [],
      editSuite: editAddress,
      editPlace: null,
      zoom: 15,
      cityInfo: null,
      areaInfo: null,
      regionInfo: null,
      userLat:  addressCenter && addressCenter.lat ? addressCenter.lat : 22.319,
      userLng:  addressCenter && addressCenter.lng ? addressCenter.lng :114.169,
    }
  }

  mapOptions: MapOptions = {
    fullscreenControl: false,
    zoomControl: false,
    scaleControl: false,
    rotateControl: false,
  }

  // mapCenter = {
  //   lat: 22.319,
  //   lng: 114.169,
  // }

  componentDidMount () {
    this.addPositionMarker();
  }

  handleApiLoaded = (map: any, googlemaps: any) => {

    if (!map || !googlemaps) return;

    const placesService = new googlemaps.places.PlacesService(map);

    const mapGeocoder = new googlemaps.Geocoder();

    this.setState({
      mapInstance: map,
      mapApi: googlemaps,
      placesService,
      mapGeocoder,
    });
  }

  nearSearch = (searchPlace?: any) => {
    const {
      placesService,
      mapGeocoder,
    } = this.state;

    this.setState({
      editSuite: false,
    })

    if (!searchPlace) {
      this.setState({
        nearPlaces: [],
      });
      return;
    }

    try {
      const searchCenter = {
        lat: searchPlace.geometry.location.lat(),
        lng: searchPlace.geometry.location.lng(),
      };

      if (placesService !== null) {
        placesService.nearbySearch({
          location: searchCenter,
          radius: 500
        }, (result: any[]) => {
          if (Array.isArray(result)) {
            result.splice(0, 1);
            result.unshift(searchPlace);
          }

          this.setState({
            nearPlaces: result,
            zoom: 17,
          })
        });
      }

      if (mapGeocoder !== null) {
        mapGeocoder.geocode({latLng: searchCenter}, (results: any, status: string) => {
          if (status === 'OK' && Array.isArray(results) && results.length > 0) {
            let geoCodeInfo;

            for (let i = 0; i < results.length; i++) {
              if (results[i].types[0] === 'locality') {
                geoCodeInfo = results[i];
                break;
              }
            }

            if (geoCodeInfo) {
              let cityInfo;
              let areaInfo;
              let regionInfo;

              for (let i = 0; i < geoCodeInfo.address_components.length; i++) {
                const addressComponent = geoCodeInfo.address_components[i];
                // get city
                if (addressComponent.types[0] === 'locality') {
                  cityInfo = addressComponent;
                }

                //get administrative_area_level_1
                if (addressComponent.types[0] === 'administrative_area_level_1') {
                  regionInfo = addressComponent;
                }

                //get area
                if (addressComponent.types[0] === 'country') {
                  areaInfo = addressComponent;
                }
              }
              this.setState({
                areaInfo,
                regionInfo,
                cityInfo,
              })
            }
          }
        });
      }


    } catch (error) {
      console.error(error)
    }
  }

  editSuiteClick = (editPlace: any) => {

    const {
      savePlace = () => {},
    } = this.props;

    this.setState({
      editSuite: true,
      editPlace,
      nearPlaces: [editPlace],
    });

    const {
      areaInfo,
      regionInfo,
      cityInfo
    } = this.state;

    const googleMapAddress: IGoogleMapAdress = {
      name: editPlace.name,
      vicinity: editPlace.formatted_address || editPlace.vicinity,
      lat: editPlace.geometry.location.lat(),
      lng: editPlace.geometry.location.lng(),
      areaInfo,
      regionInfo,
      cityInfo,
    }

    savePlace(googleMapAddress);
  }

  restZoom = () => {
    const {
      mapApi,
      // mapInstance,
    } = this.state;

    if (!mapApi) return;
    // const bounds = new mapApi.LatLngBounds();

    // const zoomValue = mapInstance.getZoom();
  }

  // 定位按钮
  addPositionMarker = async () => {
    const { mapInstance } = this.state;

    const coords = await getCoordinates();
    if (coords && mapInstance) {
      const { latitude, longitude } = coords;
      this.setState({
        userLat: latitude,
        userLng: longitude,
        zoom: 15,
      }, mapInstance.setCenter({
        lat: latitude,
        lng: longitude,
      }))
    }
  }

  render () {
    const {
      mapInstance,
      mapApi,
      nearPlaces,
      editSuite,
      zoom,
      userLng,
      userLat,
    } = this.state;

    const {
      detailAddress,
    } = this.props;

    return (

      <>
        <div className="popup-body-content">
          {
            <MapSearchBox
              mapInstance={mapInstance}
              mapApi={mapApi}
              // addPlace={this.addPlace}
              nearSearch={this.nearSearch}
            />
          }
          {
            <div
              className={`${prefix}-show-location`}
            >
              <Icon
                type={IconType.POSITION_OUTLINED}
                className={`${prefix}-show-location-icon`}
                onClick={this.addPositionMarker}
              />
            </div>
          }
          <div className={(nearPlaces.length > 0 || editSuite) ? 'div-block-519' : `${prefix}-search`}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: GoogleMapConfig.key,
                libraries: ['places, geometry'],
              }}
              center={{
                lat: userLat,
                lng: userLng
              }}
              defaultZoom={15}
              onGoogleApiLoaded={({map, maps}) => this.handleApiLoaded(map, maps)}
              yesIWantToUseGoogleMapApiInternals
              options={this.mapOptions}
              zoom={zoom}
              onZoomAnimationEnd={(zoom: number) => this.setState({ zoom })}
            >
              {
                nearPlaces.length > 0 &&
                nearPlaces.map((place: any) => (
                  <GoogleMapMarker
                    key={place.place_id}
                    lat={place.geometry.location.lat()}
                    lng={place.geometry.location.lng()}
                    className="html-embed-42 w-embed"
                    iconType={IconType.MAP_OUTLINED}
                  />
                ))
              }
              {
                nearPlaces.length < 1 &&
                <GoogleMapMarker
                  lat={userLat}
                  lng={userLng}
                  className="html-embed-42 w-embed"
                  iconType={IconType.MAP_OUTLINED}
                />
              }
            </GoogleMapReact>
          </div>
          {
            editSuite &&
            <GoogleMapEditSuite
              detailAddress={detailAddress}
            />
          }
          {
            !editSuite &&
            <GoogleMapList
              places={nearPlaces}
              editSuiteClick={editPlace => this.editSuiteClick(editPlace)}
            />
          }
        </div>
        
      </>
    );
  }
}

export default GoogleMap;

