import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import { getAppTheme, setScreenColor, getIntl } from '@/components/App/App';
import Icon, { IconType } from '@/components/Icon';
import { IPoint, IReward } from '@/actions/point-action';
import { ICustomerGrowth } from '@/actions/user-action';
import ActionType from '@/actions/action-type';
import paths from '@/routes/paths';
import UsePoints from './UsePoints';
import AboutPoints from './AboutPoints';
import RedeemSuccess from './RedeemSuccess';
import { prefix } from '.';
import CustomPage from '@/components/CustomPage';
import { IPlatformCustomPage } from '@/actions/app-action';
import LoyaltyOption from '@/actions/loyalty-option';
import Components from '@/components/SideNavBar/Components';
import SideNavOption from '@/actions/side-nav-option';
import { UsePointsPageStyled } from './styled';
import Layout from '@/components/Layout';
import { PrivilegePageNavStyled } from '@/pages/member-page/PrivilegePage/styled';
import { getAccessToken, langFormat } from '@/utils/app';
import Modal from 'antd-mobile/es/modal';
import { findNode } from '@/utils';

interface IUseRewardPageProps extends IBasePageProps {}

interface IUseRewardPageState {
  selectedKey: string;
  loading: boolean;
  pointPage: number;
  points: IPoint[] | undefined;
  rewards: IReward[];
  membershipData: ICustomerGrowth | undefined;
  disabled: boolean;
  reward: IReward | undefined;
  currencyCode: string;
  customerID: string;
  success: boolean;
}

class UseRewardPage extends BasePage<IUseRewardPageProps, IUseRewardPageState> {
  pointLastPage = false;
  loadingData = false;
  token = getAccessToken();
  state: IUseRewardPageState = {
    selectedKey: 'usePoint',
    loading: false,
    pointPage: 1,
    points: undefined,
    rewards: [],
    disabled: false,
    membershipData: undefined,
    reward: undefined,
    currencyCode: '',
    customerID: '',
    success: false,
  }

  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.darkBackgroundColor && appTheme.primaryColor) {
      setScreenColor(appTheme.primaryColor, appTheme.darkBackgroundColor);
    }

    super.componentDidMount();
    this.autoCloseLoading = false;
    const { reducersState } = this.props;
    const { userInfo, isGuest } = reducersState.user;
    const { platformConfig } = reducersState.app;
    const { loyaltyOptions } = platformConfig;
    if (loyaltyOptions.includes(LoyaltyOption.LOYALTY)) {
      this.setState({
        selectedKey: 'usePoint'
      });
    } else if (loyaltyOptions.includes(LoyaltyOption.MEMBERSHIP_GUIDE)) {
      this.setState({
        selectedKey: 'aboutPoint'
      });
    }
    if (userInfo && userInfo.id && !isGuest && loyaltyOptions.includes(LoyaltyOption.LOYALTY)) {
      this.getRewards();
      this.getPoints(1);
    }

    const pageWrap = window.document.querySelector('.page-content-wrapper-web');
    if (pageWrap) {
      pageWrap.addEventListener('scroll', this.handleScroll, { passive: true });
    } else {
      window.addEventListener('scroll', this.handleScroll, { passive: true });
    }
  }

  componentWillUnmount() {
    const pageWrap = window.document.querySelector('.page-content-wrapper-web');
    if (pageWrap) {
      pageWrap.removeEventListener('scroll', this.handleScroll);
    } else {
      window.removeEventListener('scroll', this.handleScroll);
    }
  }

  getScrollY = () => {
    const pageWrap = window.document.querySelector('.page-content-wrapper-web');
    let scrollY = window.scrollY;
    if (pageWrap) {
      scrollY = pageWrap.scrollTop;
    }

    return scrollY;
  }

  handleScroll = () => {
    const { pointPage, selectedKey } = this.state;
    if (!this.pointLastPage && !this.loadingData) {
      const appPages = document.getElementsByClassName('bm-p-use-points');
      if (appPages && appPages.length) {
        const appPage = appPages[0];
        const scrollHeight = appPage.scrollHeight;
        const canScrollHeight = scrollHeight - window.innerHeight;
        if (selectedKey === 'usePoint' && !this.pointLastPage) {
          if (canScrollHeight - this.getScrollY() < 10 * 50) {
            this.loadingData = true
            this.setState({loading: true});
            this.getPoints(pointPage + 1, true);
          }
        }
      }
    }
  }

  getRewards = () => {
    const {
      dispatch,
      reducersState,
    } = this.props;
    const { platformConfig } = reducersState.app;
    const { storeSlug, storeID, closeRegisterEmail } = platformConfig;
    dispatch({
      type: ActionType.QUERY_CUSTOMER,
      storeSlug,
      closeRegisterEmail,
      callback: customer => {
        if (customer) {
          dispatch({
            type: ActionType.QUERY_CUSTOMER_GROWTH,
            storeSlug,
            storeID,
            customerID: customer.id,
            callback: membershipData => {
              dispatch({
                type: ActionType.QUERY_REWARDS,
                storeID,
                customerID: customer.id,
                callback: rewards => {
                  dispatch({
                    type: ActionType.QUERY_STORE,
                    storeSlug,
                    callback: store => {
                      if (store) {
                        this.setState({
                          membershipData,
                          rewards,
                          currencyCode: store.currencyCode,
                          customerID: customer.id,
                        })
                      }
                    }
                  })
                }
              })
            }
          });
        }
      }
    })
  }

  getPoints = (page: number, isAdd = false) => {
    const { dispatch } = this.props;
    if (page === 1) {
      dispatch({
        type: ActionType.LOADING,
        showLoading: true,
        showLoadingBgc: true,
      })
    }
    dispatch({
      type: ActionType.QUERY_POINTS,
      page,
      callback: records => {
        let { points } = this.state;
        if (isAdd && points) {
          points = points.concat(records);
        } else {
          points = records;
        }

        if (records.length < 20) this.pointLastPage = true;
        if (records) this.loadingData = false;

        this.setState({
          points,
          loading: false,
          pointPage: page,
        });
        setTimeout(() => {
          dispatch({
            type: ActionType.LOADING,
            showLoading: false,
          })
        }, 300)
      }
    })
  }

  handleNavBarButton = (key: string) => {
    this.setState({
      selectedKey: key,
    })
  }

  handleClick = (reward: IReward) => {
    this.setState({reward});
  }

  handleGoBackMember = () => {
    this.handleGoBack({
      path: paths.MEMBERSHIP,
      runGoBack: true,
    });
  }

  handlePayPoint = () => {
    const { reducersState, dispatch } = this.props;
    const { platformConfig } = reducersState.app;
    const { storeSlug, storeID, abbreviation } = platformConfig;
    const { reward, customerID, membershipData } =this.state;
    if (reward && membershipData && Number(membershipData.myLoyaltyStamp) >= reward.loyalty) {
      
      Modal.alert('', langFormat(getIntl().page.usePointTip, { name : abbreviation }), [
        {
          text: getIntl().page.usePointRedeem,
          onPress: () => {
            this.openLoading();
            dispatch({
              type: ActionType.PAY_POINTS,
              storeID,
              customerID,
              storeSlug,
              rewardID: reward.id,
              callback: (membershipData, success) => {
                this.setState({ membershipData, success });
                this.getPoints(1);
                this.closeLoading();
              }
            })
          },
        },
        {
          text: getIntl().page.signCancel,
          onPress: () => { },
        }
      ]);
    }
  }

  render () {
    const {
      selectedKey,
      membershipData,
      points,
      rewards,
      currencyCode,
      reward,
      success,
    } = this.state;
    const { reducersState } = this.props;
    const { platformStoreMembership, language, platformConfig, platformCustomPages } = reducersState.app;
    const { abbreviation, platformLogoSvg } = platformConfig;
    const customPagesRecord = findNode<IPlatformCustomPage>(platformCustomPages, 'type', 'MEMBER_DETAILS');
    const usePointsRecord = findNode<IPlatformCustomPage>(platformCustomPages, 'type', 'MEMBERSHIP_RULES');
    let title = '';
    if (platformConfig.loyaltyOptions.length === 1 && platformConfig.loyaltyOptions.includes(LoyaltyOption.LOYALTY)) {
      title = getIntl().page.aboutPoints;
    } else if (platformConfig.loyaltyOptions.length === 1 && platformConfig.loyaltyOptions.includes(LoyaltyOption.MEMBERSHIP_GUIDE)) {
      title = getIntl().page.membershipGuide;
    } else {
      title = getIntl().page.usePoints;
    }
    
    return(
      <Layout
        {...this.props}
        isFooter ={false}
        className={prefix}
      >
        <Components
          reducersState={reducersState}
          onLeftClick={this.handleGoBackMember}
          navContent={title}
          navIcon={<Icon type={IconType.LEFT_OUTLINED} className="html-embed-7 w-embed"/>}
          sideNavOption={SideNavOption.POINTS}
          whiteBanner={platformConfig.loyaltyOptions.length > 1 && !success }
          whiteBannerContent={
            platformConfig.loyaltyOptions.length > 1 && !success &&
            <PrivilegePageNavStyled>
              <div className="tabs-menu-4 center w-tab-menu">
                {
                  platformConfig.loyaltyOptions.includes(LoyaltyOption.LOYALTY) && 
                  <div className={`my-priviledge-tab w-inline-block w-tab-link ${selectedKey === 'usePoint' ? ' w--current' : ''}`} 
                    onClick={() => this.handleNavBarButton('usePoint')}
                  >
                    <div className="text-block-200">
                      {getIntl().page.aboutPoints}
                    </div>
                  </div>
                }
                {
                  platformConfig.loyaltyOptions.includes(LoyaltyOption.MEMBERSHIP_GUIDE) && 
                  <div className={`my-priviledge-tab w-inline-block w-tab-link ${selectedKey === 'aboutPoint' ? 'w--current' : ''}`} 
                    onClick={() => this.handleNavBarButton('aboutPoint')}
                  >
                    <div className="text-block-200">
                      {getIntl().page.membershipGuide}
                    </div>
                  </div>
                }
              </div>
            </PrivilegePageNavStyled>
          }
        >
          {
            !success &&
            <UsePointsPageStyled className={`tabs-2 w-tabs ${platformConfig.loyaltyOptions.length === 1 ? 'banner-content': ''}`}>
              {
                platformConfig.loyaltyOptions.length > 1 &&
                <PrivilegePageNavStyled className="privilege">
                  <div className="tabs-menu-4 center w-tab-menu">
                    {
                      platformConfig.loyaltyOptions.includes(LoyaltyOption.LOYALTY) && 
                      <div className={`my-priviledge-tab w-inline-block w-tab-link ${selectedKey === 'usePoint' ? 'w--current' : ''}`} 
                        onClick={() => this.handleNavBarButton('usePoint')}
                      >
                        <div className="text-block-200">
                          {getIntl().page.aboutPoints}
                        </div>
                      </div>
                    }
                    {
                      platformConfig.loyaltyOptions.includes(LoyaltyOption.MEMBERSHIP_GUIDE) && 
                      <div className={`my-priviledge-tab w-inline-block w-tab-link ${selectedKey === 'aboutPoint' ? 'w--current' : ''}`} 
                        onClick={() => this.handleNavBarButton('aboutPoint')}
                      >
                        <div className="text-block-200">
                          {getIntl().page.membershipGuide}
                        </div>
                      </div>
                    }
                  </div>
                </PrivilegePageNavStyled>
              }
              <div className="tabs-content w-tab-content">
                {
                  selectedKey === 'usePoint' && 
                  <UsePoints platformStoreMembership={platformStoreMembership} membershipData={membershipData} points={points} currencyCode={currencyCode} rewards={rewards} reward={reward} handleClick={this.handleClick} handlePayPoint={this.handlePayPoint} language={language} abbreviation={abbreviation} records={usePointsRecord ? [usePointsRecord]: []} reducersState={reducersState}/>
                }
                {
                  selectedKey === 'aboutPoint' && !customPagesRecord &&
                  <AboutPoints platformStoreMembership={platformStoreMembership} language={language} abbreviation={abbreviation} platformLogoSvg={platformLogoSvg} currencyCode={currencyCode} />
                }
                {
                  selectedKey === 'aboutPoint' && customPagesRecord &&
                  <CustomPage 
                    {...this.props}
                    records={[customPagesRecord]}
                  />
                }
              </div>
            </UsePointsPageStyled>
          }
          {
            success && reward && membershipData &&
            <RedeemSuccess membershipData={membershipData} reward={reward} currencyCode={currencyCode} abbreviation={abbreviation} />
          }
        </Components>
      </Layout>
    )
  }
}

export default UseRewardPage;
