import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import Icon, { IconType } from '@/components/Icon';
import { IObject } from '@/components/bm-common';
import { getAppTheme, setScreenColor, getIntl } from '@/components/App/App';
import { prefix } from '.';
import ActionType from '@/actions/action-type';
import { IStore } from '@/actions/store-action';
import StoreList from '@/components/StoreList';
import paths from '@/routes/paths';
import { setCanAutoProcurementMethodPopup } from '@/pages/MenuListPage/MenuListPage';
import Components from '@/components/SideNavBar/Components';
import SideNavOption from '@/actions/side-nav-option';
import { FavoritePageStyled } from './styled';
import Layout from '@/components/Layout';


export interface IFavoritePageProps extends IBasePageProps {}
export interface IFavoritePageState {
  favoriteList: IStore[];
  loadingStores: boolean;
}

class FavoritePage extends BasePage<IFavoritePageProps, IFavoritePageState> {
  routeParams: IObject = {};

  state: IFavoritePageState = {
    favoriteList: [],
    loadingStores: true,
  }

  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.darkBackgroundColor) {
      setScreenColor(appTheme.darkBackgroundColor);
    }
    const { dispatch } = this.props;
    const { reducersState } = this.props;
    const { platformAppSettings } = reducersState.app;
    const { userInfo } = reducersState.user;
    // this.setState({
    //   loadingStores: true,
    // });
    if (platformAppSettings && userInfo) {
      dispatch({
        type: ActionType.QUERY_FAVORITE_LIST,
        platformID: platformAppSettings.id,
        userID: userInfo.id,
        callback: favoriteList => {
          this.setState({
            favoriteList: favoriteList,
            loadingStores: false,
          })
        }
      });
    }
 
    super.componentDidMount();
  }

  handleItemClick = (data: IStore) => {
    setCanAutoProcurementMethodPopup(true);
    this.handleGotoRoute({
      path: paths.MENU_LIST,
      pathParams: {
        storeSlug: data.slug,
      },
    })
  }

  render() {
    const { favoriteList, loadingStores } = this.state;
    const { reducersState } = this.props;
    return (
      <Layout
        {...this.props}
        isFooter={false}
        className={prefix}
      >
        <Components
          {...this.props}
          reducersState={reducersState}
          onLeftClick={() => this.handleGoBack({
            path: paths.ACCOUNT,
            runGoBack: true,
          })}
          navContent={getIntl().page.myFavorites}
          navIcon={<Icon type={IconType.LEFT_OUTLINED} className="html-embed-7 w-embed"/>}
          sideNavOption={SideNavOption.FAVORITE}
        >
          <FavoritePageStyled>
            <div className="section address wf-section">
              <>
                {
                  (loadingStores || favoriteList.length > 0) &&
                  <StoreList
                    data={favoriteList}
                    reducersState={reducersState}
                    loading={loadingStores}
                    onItemClick={this.handleItemClick}
                    className={`${prefix}-store-list`}
                    itemClassName={`${prefix}-store-list-item`}
                  />
                }
              </>
              {
                !loadingStores && favoriteList.length <= 0 &&
                <div className="no-content-panel">
                  <Icon type={IconType.TAG_ITEM} />
                  <div className="no-content-info">
                    {getIntl().page.favoriteListEmpty}
                  </div>
                </div>
              }
            </div>
          </FavoritePageStyled>
        </Components>
      </Layout>
    );
  }
}

export default FavoritePage;
