import dayjs from 'dayjs';
import wx from 'weixin-js-sdk-ts';
import { IObject } from '@/components/bm-common';
import { IAppTheme } from '@/actions/app-action';
import { IOpeningHour, IStoreConfig } from '@/actions/store-action';
import {
  lineToHump, pxToRem, getDomain,
} from '.';
import { IOrderInfo } from '@/lib/order-builder/models/OrderEntity';
import ShippingMethod, { defaultShippingMethod } from '@/lib/order-builder/enums/ShippingMethod';
import { IShoppingCartData } from '@/actions/shopping-cart-action';
import Constants from '@/constants';
import Config from '@/Config';
import locales from '@/locales';
import langsPacks from '@/locales/langsPacks';
import cloneDeep from 'lodash/cloneDeep';

interface IDayHours {
  key: string;
  day: number;
  isCurrentDay: boolean;
  openingTimeTitle: string;
  openingHoursStrs: string[];
  openingHours: IOpeningHour[];
}

export interface IParseOpeningHoursResult {
  isOpen: boolean;
  currentDay: number;
  currentOpeningTimeTitle: string;
  daysHours: IDayHours[];
}

const dayKeys: string[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

export const parseOpeningHours = (openingHours?: {[key: string]: IOpeningHour[]}): IParseOpeningHoursResult => {
  const date = dayjs();
  const currentDay = date.day();
  const currentHourMinute: string = date.format('HHmm');
  const parseOpeningHoursResult: IParseOpeningHoursResult = {
    isOpen: false,
    currentDay,
    daysHours: [],
    currentOpeningTimeTitle: '',
  };

  [1, 2, 3, 4, 5, 6, 0].forEach((day, index) => {
    const dayHours: IDayHours = {
      key: dayKeys[index],
      day,
      isCurrentDay: day === currentDay,
      openingTimeTitle: 'N/A',
      openingHoursStrs: [],
      openingHours: [],
    }

    let dayHoursData: IOpeningHour[] | undefined = undefined;
    if (openingHours) {
      dayHoursData = openingHours[day];
    }
    if (Array.isArray(dayHoursData)) {
      const tempDay = dayjs();
      const timeTitles: string[] = [];
      dayHoursData.forEach(item => {
        if (item && item.start && item.end) {
          dayHours.openingHours.push(item);
          // 格式化时间
          const startTime = tempDay.hour(Number(item.start.substr(0, 2))).minute(Number(item.start.substr(2))).format('hh:mmA');
          const endTime = tempDay.hour(Number(item.end.substr(0, 2))).minute(Number(item.end.substr(2))).format('hh:mmA');
          dayHours.openingHoursStrs.push(`${startTime} - ${endTime}`);

          if (timeTitles.length < 2) {
            timeTitles.push(`${startTime} ~ ${endTime}`);
          }

          if (day === currentDay && item.start < currentHourMinute && currentHourMinute < item.end) {
            parseOpeningHoursResult.isOpen = true;
          }
        }
      });

      if (timeTitles.length > 0) {
        dayHours.openingTimeTitle = timeTitles.join(', ');
      }
    }

    if (day === currentDay) {
      parseOpeningHoursResult.currentOpeningTimeTitle = dayHours.openingTimeTitle;
    }

    parseOpeningHoursResult.daysHours.push(dayHours);
  });

  return parseOpeningHoursResult;
}

export const getCurrentShippingMethod = (orderInfo?: IOrderInfo, storeConfig?: IStoreConfig) => {
  const { shippingMethod } = orderInfo || {};

  let currentShippingMethod = defaultShippingMethod;
  if (storeConfig) {
    if (shippingMethod !== undefined && storeConfig.shippingMethods.includes(shippingMethod)) {
      currentShippingMethod = shippingMethod;
    } else if (storeConfig.shippingMethods.includes(ShippingMethod.DINE_IN)) {
      currentShippingMethod = ShippingMethod.DINE_IN;
    } else if (storeConfig.shippingMethods.includes(ShippingMethod.DINE_IN_UNASSIGNED)) {
      currentShippingMethod = ShippingMethod.DINE_IN_UNASSIGNED;
    } else if (storeConfig.shippingMethods.includes(ShippingMethod.PICK_UP)) {
      currentShippingMethod = ShippingMethod.PICK_UP;
    } else if (storeConfig.shippingMethods.includes(ShippingMethod.DELIVERY)) {
      currentShippingMethod = ShippingMethod.DELIVERY;
    }
  } else if (shippingMethod !== undefined) {
    currentShippingMethod = shippingMethod;
  }

  return currentShippingMethod;
}

/** 获取当前位置的经纬度信息 */
export const getCoordinates = (): Promise<Coordinates | undefined> => {
  return new Promise(resolve => {
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(pos => {
        resolve(pos.coords);
      }, error => {
        resolve(undefined)
      }, {
        enableHighAccuracy: true,
        // maximumAge: 0,
        timeout : 15000,
      });
    } else {
      resolve(undefined);
    }
  })
}

const getRad = (d: number) => {
  return d * Math.PI / 180.0;
}

const EARTH_RADIUS = 6378137.0;    //单位M
// const EARTH_RADIUS = 6378.137;    //单位KM

/** 默认地球是一个椭球，计算两点之间的距离更精准一些 */
export const getFlatternDistance = (lat1: number, lng1: number, lat2: number, lng2: number) => {
  const f = getRad((lat1 + lat2)/2);
  const g = getRad((lat1 - lat2)/2);
  const l = getRad((lng1 - lng2)/2);

  let sg = Math.sin(g);
  let sl = Math.sin(l);
  let sf = Math.sin(f);

  let s = 0;
  let c = 0;
  let w = 0;
  let r = 0;
  let d = 0;
  let h1 = 0;
  let h2 = 0;
  const a = EARTH_RADIUS;
  const fl = 1 / 298.257;

  sg = sg*sg;
  sl = sl*sl;
  sf = sf*sf;

  s = sg*(1-sl) + (1-sf)*sl;
  c = (1-sg)*(1-sl) + sf*sl;

  w = Math.atan(Math.sqrt(s/c));
  r = Math.sqrt(s*c)/w;
  d = 2*w*a;
  h1 = (3*r -1)/2/c;
  h2 = (3*r +1)/2/s;

  const distance = d*(1 + fl*(h1*sf*(1-sg) - h2*(1-sf)*sg));
  const distanceNum = Math.round(distance);
  let distanceTitle = '';
  if (distanceNum > 1000) {
    distanceTitle = `${(distanceNum / 1000).toFixed(1)}km`;
  } else {
    distanceTitle = `${distanceNum}m`
  }
  return {
    distance,
    distanceTitle,
  }
}

export const checkIsWxMiniProgram = (callback: (tag: boolean, wx: any) => void) => {
  const ua = navigator.userAgent.toLowerCase();

  const microMsg: any = ua.match(/MicroMessenger/i);

  // eslint-disable-next-line
  if (microMsg == 'micromessenger') {
    wx.miniProgram.getEnv((res: any) => {
      if (res.miniprogram) {
        callback(true, wx);
      } else {
        callback(false, wx);
      }
    });
  } else {
    callback(false, wx);
  }
}

export const isWeChatMiniProgram = (deviceInfo: IDeviceInfo): Promise<boolean> => {
  return new Promise(resolve => {
    if (deviceInfo.isWeChat) {
      wx.miniProgram.getEnv((res: any) => {
        if (res.miniprogram) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    } else {
      resolve(false);
    }
  })
}

interface IToWxMiniProgramParams {
  miniProgramPath: string;
  data?: any;
}

export const toWxMiniProgram = (params: IToWxMiniProgramParams) => {
  wx.miniProgram.navigateTo({
    url: params.miniProgramPath,
  });

  if (params.data) {
    wx.miniProgram.postMessage({
      data: params.data,
    });
  }
}

const themeKeysRef: {
  [key: string]: any;
} = {
  'primary_color': '@bm-primary-color',
  'primary_tap_color': '@bm-primary-color-tap',
  'dark_background_color': '@bm-dark-background-color',
  'light_background_color': '@bm-light-background-color',
  'text_color': '@bm-text-color',
  'title_color': '@bm-text-title-color',
  'secondary_text_color': '@bm-text-secondary-color',
  'disabled_text_color': '@bm-text-disabled-color',
  'error_color': '@bm-error-color',
  'warning_color': '@bm-warning-color',
  'border_color': '@bm-border-color',
  'book_color': '@bm-tag-book-color',
  'delivery_color': '@bm-tag-delivery-color',
  'safe_area_top': '@bm-safe-area-top',
};

export const parseThemeContent = (themeData: IObject, deviceInfo: IDeviceInfo) => {
  const tempAppTheme: IObject = {};
  const lessTheme: IObject = {};
  const appTheme: IAppTheme = tempAppTheme;

  if (deviceInfo.isAndroid && (window as any).bindo_utils) {
    let statusBarHeight = (window as any).bindo_utils.getDeviceStatusBarHeight();
    if (typeof statusBarHeight === 'number') {
      statusBarHeight = pxToRem(statusBarHeight);
    }
    lessTheme[themeKeysRef['safe_area_top']] = statusBarHeight;
  }

  if (themeData) {
    Object.keys(themeKeysRef).forEach(key => {
      const value = themeData[key];
      if (value && typeof value === 'string' && value.length > 0) {
        lessTheme[themeKeysRef[key]] = value;
        tempAppTheme[lineToHump(key)] = value;
      }
    });
  }

  return {
    lessTheme,
    appTheme,
  }
}

export interface IDeviceInfo {
  // 按屏幕区分
  inPC: boolean;
  inPad: boolean;
  inPhone: boolean;

  // 按系统区分
  isAndroid: boolean;
  isApple: boolean;
  isWindows: boolean;
  isWeChat: boolean;

  // 按终端区分
  iPhone: boolean;
  iPad: boolean;
  iMac: boolean;
  androidPhone: boolean;
  androidPad: boolean;
  weChatMiniProgram: boolean;
  dingTalk: boolean;

  // 按包裹的壳区分
  isAppleApp: boolean;

  // 是否在原生应用壳中
  isNativeApp: boolean;

  isQQBrowser: boolean;
  deviceID: string;
  devicePushToken: string;
}

export const getDeviceInfo = (): IDeviceInfo => {
  const deviceInfo: IDeviceInfo = {
    inPC: false,
    inPad: false,
    inPhone: false,
    isAndroid: false,
    isApple: false,
    isWindows: false,
    isWeChat: false,

    iPhone: false,
    iPad: false,
    iMac: false,

    isAppleApp: false,

    androidPhone: false,
    androidPad: false,

    weChatMiniProgram: false,
    dingTalk: false,

    isNativeApp: false,

    isQQBrowser: false,
    deviceID: '',
    devicePushToken: '',
  };
  const userAgent = navigator.userAgent
  deviceInfo.isAndroid = /(Android)/i.test(userAgent);
  deviceInfo.isApple = /(Mac OS X)/i.test(userAgent);
  deviceInfo.isWindows = /(Windows)/i.test(userAgent);
  deviceInfo.isWeChat = /(MicroMessenger)/i.test(userAgent);
  deviceInfo.dingTalk = /(DingTalk)/i.test(userAgent);
  deviceInfo.isQQBrowser = /(QBWebview)/i.test(userAgent);

  if (deviceInfo.isAndroid) {
    if (/(PAD)/i.test(userAgent)) {
      deviceInfo.androidPad = true;
    } else {
      deviceInfo.androidPhone = true;
    }
  } else if (deviceInfo.isApple) {
    if (/(iPhone|iPod)/i.test(userAgent)) {
      deviceInfo.iPhone = true;
    } else if (/(iPad)/i.test(userAgent)) {
      deviceInfo.iPad = true;
    } else {
      deviceInfo.iMac = true;
    }

    if ((deviceInfo.iPhone || deviceInfo.iPad) && !deviceInfo.isWeChat && !deviceInfo.dingTalk) {
      deviceInfo.isAppleApp = !/(Safari)/i.test(userAgent);
    }
  }

  const hasSafariText = /(Safari\/)/i.test(userAgent);
  const hasBuildText = /(Build\/)/i.test(`${userAgent}`);
  if (!hasSafariText || (hasSafariText && hasBuildText)) {
    deviceInfo.isNativeApp = true;
  }

  deviceInfo.inPC = deviceInfo.isWindows || deviceInfo.iMac;
  deviceInfo.inPad = deviceInfo.iPad || deviceInfo.androidPad;
  deviceInfo.inPhone = deviceInfo.iPhone || deviceInfo.androidPhone;

  return deviceInfo;
}

interface INavigateToMiniProgramParams {
  appID: string;
  path?: string;
  extraData?: IObject;
}

export const navigateToMiniProgram = (params: INavigateToMiniProgramParams) => {
  checkIsWxMiniProgram((tag, wx) => {
    if (tag) {
      wx.miniProgram.navigateToMiniProgram({
        appId: params.appID,
      });
    } else {
    }
  });
}

export const getI18nText = (data: IObject | undefined, fieldName: string, lang: string) => {
  let i18nTitle = '';

  if (data && data.i18n && data.i18n[fieldName]) {
    i18nTitle = data.i18n[fieldName][lang] || '';
  }

  if (!i18nTitle && data && lang === 'en-US') {
    i18nTitle = data[fieldName] || '';
  }

  if (i18nTitle === 'null') {
    i18nTitle = '';
  }

  if (typeof i18nTitle === 'string') {
    i18nTitle = i18nTitle.replace(/(^\s*)|(\s*$)/g, '');
  }

  return i18nTitle;
}

let serviceTypeScrollTimer: any = undefined;

const scrollLeftFunc = (dom: any, number = 0, time?: any) => {
  if (!time) {
    dom.scrollLeft = number;
    return;
  }
  const spacingTime = 20 // 设置循环的间隔时间  值越小消耗性能越高
  let spacingInex = time / spacingTime // 计算循环的次数
  let left = dom.scrollLeft // 获取当前滚动条位置
  const everLeft = (number - left) / spacingInex // 计算每次滑动的距离
  if (serviceTypeScrollTimer) {
    clearInterval(serviceTypeScrollTimer) // 清除计时器
  }
  serviceTypeScrollTimer = setInterval(() => {
    if (spacingInex > 0) {
      spacingInex--
      scrollLeftFunc(dom, left += everLeft)
    } else {
      clearInterval(serviceTypeScrollTimer) // 清除计时器
    }
  }, spacingTime)
}

export const handleStoreServiceTypesScroll = (serviceType: string) => {
  if (serviceType) {
    const serviceTypeDom = document.getElementById(`bm-store-service-types-${serviceType}`);
    const storeServiceTypesBox = document.getElementById('bm-store-service-types-box');
    const storeServiceTypes = document.getElementById('bm-store-service-types');
    if (serviceTypeDom && storeServiceTypesBox && storeServiceTypes) {
      const itemOffsetLeft = serviceTypeDom.offsetLeft;
      const itemOffsetWidth = serviceTypeDom.offsetWidth;
      const boxOffsetWidth = storeServiceTypesBox.offsetWidth;
      const scrollLeft = itemOffsetLeft + (itemOffsetWidth / 2) - (boxOffsetWidth / 2);
      if (scrollLeft > 0) {
        scrollLeftFunc(storeServiceTypes, scrollLeft, 200);
      } else {
        storeServiceTypes.scrollLeft = 0;
      }
    }
  }
}

export const handleStoreCategoryTypesScroll = (serviceType: string) => {
  if (serviceType) {
    const serviceTypeDom = document.getElementById(`bm-store-service-types-${serviceType}`);
    const storeServiceTypesBox = document.getElementById('bm-store-service-types-box');
    const storeServiceTypes = document.getElementById('bm-store-service-types');
    if (serviceTypeDom && storeServiceTypesBox && storeServiceTypes) {
      const itemOffsetLeft = serviceTypeDom.offsetLeft;
      const itemOffsetWidth = serviceTypeDom.offsetWidth;
      const boxOffsetWidth = storeServiceTypesBox.offsetWidth;
      const scrollLeft = itemOffsetLeft + (itemOffsetWidth / 2) - (boxOffsetWidth / 2);
      if (scrollLeft > 0) {
        scrollLeftFunc(storeServiceTypes, scrollLeft, 200);
      } else {
        storeServiceTypes.scrollLeft = 0;
      }
    }
  }
}

export const getDifferentShippingMethodStore = (storeID: string, storeIDRefShoppingCartData: {[storeID: string]: IShoppingCartData},  shippingMethod?: ShippingMethod, ) => {
  const arr: string[] = [];
  Object.keys(storeIDRefShoppingCartData).forEach(key => {
    if (storeID !== key && storeIDRefShoppingCartData[key].orderData.shipping_method !== shippingMethod) {
      arr.push(key);
    }
  })
  return arr;
}

export const stringToBase64QrCode = (value: any) => {
  const qr = require('qr-image');
  let size = 5;
  let margin = 2;
  let qrStr = value;
  if (typeof value === 'string') {
    qrStr = value;
  } else {
    size = Math.round(value.size / 21) || 5;
    margin = Math.round(value.margin / 10) || 2;
    qrStr = value.text;
  }

  const codeStr = qr.imageSync(qrStr, { type: 'png', size, margin });
  const base64Qr = `data:image/png;base64,${codeStr.toString('base64')}`;

  return base64Qr;
}

export const setAccessToken = (token: string) => {
  const hostname = window.location.hostname;
  if (Config.appMerchantDomain === hostname) {
    sessionStorage.setItem(Constants.ACCESS_TOKEN, token);
  } else {
    localStorage.setItem(Constants.ACCESS_TOKEN, token);
  }
}

export const getAccessToken = () => {
  const hostname = window.location.hostname;
  let token = '';
  if (Config.appMerchantDomain === hostname) {
    token = sessionStorage.getItem(Constants.ACCESS_TOKEN) || '';
  } else {
    token = localStorage.getItem(Constants.ACCESS_TOKEN) || '';
  }

  return token;
}

export const removeAccessToken = () => {
  sessionStorage.removeItem(Constants.ACCESS_TOKEN);
  localStorage.removeItem(Constants.ACCESS_TOKEN);
}

export interface ILanguage {
  lang: string;
  is_default: boolean;
  name: string;
  icon: string;
  sign: string;
}

const langRef: IObject = {
  en: 'en-US',
  zh: 'zh-CN',
}

export const langRefKey: IObject = {
  'en-US': 'en',
  'zh-CN': 'zh',
}

export const languageKeys = {
  EN_US: 'en-US',
  ZH_CN: 'zh-CN',
  ZH_HK: 'zh-HK',
}

export const defaultLanguages: ILanguage[] = [
  { lang: 'en-US', name: 'English', icon: 'gb', sign: 'en', 'is_default': true },
  { lang: 'zh-CN', name: '简体中文', icon: 'cn', sign: 'zh_cn', 'is_default': false },
  { lang: 'zh-HK', name: '繁體中文', icon: 'hk', sign: 'zh_hk', 'is_default': false },
];

export const getDefaultLanguage = (lang?: any) => {
  const defaultLanguage = localStorage.getItem(Constants.LANGUAGE) || lang || (defaultLanguages.map(item => item.lang).includes(navigator.language) && navigator.language) || 'en';

  return langRef[defaultLanguage] || defaultLanguage;
}

const coverDefaultLocales = (locales: IObject, langPacks: IObject, langKey: string) => {
  const newLocales: IObject = {};
  if (langPacks && langPacks[langKey]) {
    Object.keys(langPacks[langKey]).forEach(key => {
      if (langPacks[langKey][key]) {
        newLocales[key] = langPacks[langKey][key];
      }
    })
  }

  locales[langKey] = {
    ...(locales[langKey] || {}),
    ...newLocales,
  }
}

export const getLangPacks = (lang: string) => {

  const newLocales = cloneDeep(locales);

  lang = lang || 'en-US';
  if (langsPacks && !langsPacks[lang]) {
    const langs = lang.split('-');
    if (langs.length > 0 && langsPacks[langs[0]]) {
      lang = langs[0];
    }
  }

  if (langsPacks && langsPacks[lang]) {
    const langPacks = langsPacks[lang];

    coverDefaultLocales(newLocales, langPacks, 'common');
    coverDefaultLocales(newLocales, langPacks, 'invoice');
    coverDefaultLocales(newLocales, langPacks, 'page');
    coverDefaultLocales(newLocales, langPacks, 'pay');
  }

  return newLocales;
}

export const langFormat = (text: string, obj: IObject) => {

  try {
    for (const key in obj) {
      text = (text || '').replace(`{${key}}`, obj[key] || '');
    }
  } catch (error) {

  }

  return text;
}

/**
 * 检测本地数据存储，当版本不一致时，会移除本地存储
 */
export const checkStorageVersionAndAction = () => {
  const storageVersion = localStorage.getItem(Constants.STORAGE_VERSION);
  const {
    platformDomain,
    gatewayDomain,
  } = getDomain();
  if (storageVersion !== Config.storageVersion) {
    localStorage.removeItem(`${Constants.APP_CONFIG_HASH_DATA}-${platformDomain}`);
    localStorage.removeItem(`${Constants.APP_CONFIG_HASH_DATA}-${gatewayDomain}`);
    localStorage.removeItem(`${Constants.APP_CONFIG}-${platformDomain}`);
  }

  localStorage.setItem(Constants.STORAGE_VERSION, Config.storageVersion);
}

export const checkIsWeChatBrowser = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.indexOf('micromessenger') !== -1;
};
