enum AccountOption {
  INFOTRMATION = 'INFOTRMATION',
  PAYMENT = 'PAYMENT',
  ADDRESS = 'ADDRESS',
  FAVORITE = 'FAVORITE',
  QUEUING = 'QUEUING',
  BOOKING = 'BOOKING',
  SETTING = 'SETTING',
  NEWS = 'NEWS',
  ABOUT_US = 'ABOUT_US',
  RECORDS = 'RECORDS',
  SCAN_CODE = 'SCAN_CODE',
  ADMIN = 'ADMIN',
  PRIVILEGES = 'PRIVILEGES',
  PAYMENT_LIST = 'PAYMENT_LIST',
}

export default AccountOption;
