import React from 'react';
import { getIntl } from '../App/App';

interface IDotsAndBtnProps {
  index: number;
  count: number;
  onNextClick?: (num: number) => void;
  onDoneClick?: () => void;
}

const DotsAndBtn: React.FC<IDotsAndBtnProps> = props => {
  const {
    index,
    count = 0,
    onNextClick,
    onDoneClick,
  } = props;

  const handleNextClick = () => {
    if (onNextClick) {
      onNextClick(index);
    }
  }
  
  const isLastDot = index === count - 1;
  const list: number[] = Array.from(Array(count), (_item, index) => index);

  return (
    <div className="btns-wrapper">
      {
        isLastDot &&
        <div className="splash-done-btn" onClick={onDoneClick}>
          {getIntl().page.splashDone}
        </div>
      }
      {
        !isLastDot &&
        <>
          <ul className="splash-dots">
            {
              list.map(item => (
                <li key={item} className={item === index ? 'dot-selected' : ''} />
              ))
            }
          </ul>
          <div className="splash-next-btn" onClick={handleNextClick}>
            {getIntl().common.next}
          </div>
        </>
      }
    </div>
  );
}

export default DotsAndBtn;
