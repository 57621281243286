import React from 'react';
import InputItem from 'antd-mobile/es/input-item';
import { IGiftCard, IAddPayCardData, IPayCard } from '@/actions/pay-action';
import validatorIsCreditCard from 'validator/es/lib/isCreditCard';
import { prefix, IPayCardInstance } from '.';
import Icon, { IconType } from '../Icon';
import DataEntryWrapper from '../DataEntryWrapper';
import { IDispatch } from '@/actions';
import ActionType from '@/actions/action-type';
import { PayTypes } from '@/lib/payment';
// import Modal from 'antd-mobile/es/modal';
import Constants from '@/constants';
import { IReducersState } from '@/reducers';
import { createUuid } from '@/lib/order-builder/utils';
import { getAccessToken } from '@/utils/app';
import { getIntl } from '../App/App';

const payCardType: {[key: string]: string} = {
  VISA: 'VISA',
  AMEX: 'AMEX',
  MASTER: 'MASTER',
  JCB: 'JCB',
  UNION_PAY: 'UNION_PAY',
};

interface IPayCardModifyProps {
  storeID?: string;
  payCardModify?: IPayCardInstance;
  className?: string;
  phoneNumber?: string;
  dialCode?: string;
  isGuest?: boolean;
  cardID?: string;
  dispatch: IDispatch;
  onReadyChange?: (isReady: boolean) => void;
  deleteCard?: boolean;
  reducersState: IReducersState;
}

export interface IPayCardModifyState {
  cardIconType: IconType;
  cardNum: string;
  cardTypeError: boolean;
  openEye: boolean;
  expYear: string;
  expMonth: string;
  cvv: string;
  isGiftCard: boolean;
  expDateFocus: boolean;
  isSelected: boolean;
  pin: string;
  cardID: string;
  payCards: IPayCard[];
  cardType: string;
  isCorrectCard?: boolean;
}

class PayCardModify extends React.Component<IPayCardModifyProps, IPayCardModifyState> {
  state: IPayCardModifyState = {
    cardIconType: IconType.CREDIT_CARD,
    cardNum: '',
    cardTypeError: false,
    openEye: false,
    expMonth: '',
    expYear: '',
    cvv: '',
    isGiftCard: false,
    expDateFocus: false,
    isSelected: false,
    pin: '',
    cardID: '',
    payCards: [],
    cardType: '',
    isCorrectCard: false,
  }

  componentDidMount() {
    const { cardID, dispatch } = this.props;
    if (cardID) {
      dispatch({
        type: ActionType.QUERY_PAY_CARD,
        cardID,
        callback: payCard => {
          if (payCard) {
            // this.handleCardNumChange(payCard.)
            this.setState({
              cardID,
              expMonth: payCard.expMonth,
              expYear: payCard.expYear,
              ...this.getHideCardNum(payCard),
            });
          }
        }
      });
    }

    // 游客模式无token, get create cards 报错
    if (getAccessToken()) {
      dispatch({
        type: ActionType.QUERY_PAY_CARDS,
        callback: payCards => {
          this.setState({ payCards });
        }
      });
    }
  }

  getHideCardNum = (payCard: IPayCard) => {
    let { cardIconType, cardNum, cardType } = this.state;
    if (payCard.type === PayTypes.VISA) { /* 以4开头的是VISA(13-16位) */
      cardIconType = IconType.VISA_CARD;
      cardNum = '4'.padEnd(12, '*');
    } else if (payCard.type === PayTypes.MASTER) { /* 以51-55开头的是MASTER(16位) */
      cardIconType = IconType.MASTER_CARD;
      cardNum = '5'.padEnd(12, '*');
      cardType = payCardType.MASTER;
    } else if (payCard.type === PayTypes.AMEX) { /* 以34,37开头的是AMEX(15位) */
      cardIconType = IconType.AE_CARD;
      cardNum = '3'.padEnd(11, '*');
      cardType = payCardType.AEMX;
    } else if (payCard.type === PayTypes.JCB) { /* 以31,32开头的是JCB(15-16位) */
      cardIconType = IconType.JCB_CARD;
      cardNum = '3'.padEnd(12, '*');
      cardType = payCardType.JCB;
    } else if (payCard.type === PayTypes.UNION_PAY) { /* 以62开头的是UnionPay(13-19位) */
      cardIconType = IconType.UNION_PAY;
      cardNum = '4'.padEnd(12, '*');
    }

    if (cardNum && payCard.last4Digits) {
      cardNum = `${cardNum}${payCard.last4Digits}`;
    } else if (payCard.last4Digits) {
      cardNum = payCard.last4Digits.padStart(15, '*');
    }

    const { onReadyChange } = this.props;

    if (onReadyChange) {
      let isReady = false;
      const isExpDate = this.isExpDate(`${payCard.expMonth}${payCard.expYear}`);
      if (payCard.id && cardNum.length >= 12 && isExpDate) {
        isReady = true;
      }
      onReadyChange(isReady);
    }

    return {
      cardNum,
      cardIconType,
      cardType,
    }
  }

  isExpDate = (expDate: string) => {
    return !!expDate && expDate.length === 4;
  }

  validateCard = (number: string, pin: string, expDate: string, isSelected: boolean) => {
    const { cardID, cardNum } = this.state;
    let isReady = false;
    let isCorrectCard = false;
    const isCvv = !!pin && [3, 4].includes(pin.length);
    const isExpDate = this.isExpDate(expDate);
    const numberStr = `${number}`;
    const isCreditCard = validatorIsCreditCard(`${numberStr}`);
    if (cardID && cardNum.length >= 12) {
      if (isExpDate) isReady = true;
    } else if (/^4\d*/.test(numberStr)) { /* 以4开头的是VISA(13-16位) */
      if (numberStr.length >=13 && numberStr.length <= 16) {
        isReady = isCvv && isExpDate && isCreditCard;
        isCorrectCard = isCreditCard;
      }
    } else if (/^5[1-5]\d*/.test(numberStr)) { /* 以51-55开头的是MASTER(16位) */
      if (numberStr.length === 16) {
        isReady = isCvv && isExpDate && isCreditCard;
        isCorrectCard = isCreditCard;
      }
    } else if (/^3[4|7]\d*/.test(numberStr)) { /* 以34,37开头的是AMEX(15位) */
      if (numberStr.length === 15) {
        isReady = isCvv && isExpDate && isCreditCard;
        isCorrectCard = isCreditCard;
      }
    } else if (/^3[1|2]\d*/.test(numberStr)) { /* 以31,32开头的是JCB(15-16位) */
      if (numberStr.length >=15 && numberStr.length <= 16) {
        isReady = isCvv && isExpDate && isCreditCard;
        isCorrectCard = isCreditCard;
      }
    } else if (/^24\d*/.test(numberStr) && pin) { /* 以24开头的是Gift Card(20位) */
      if (numberStr.length === 16 || numberStr.length === 20) {
        isReady = true;
        isCorrectCard = true;
      }
    } else if (/^62\d*/.test(numberStr)) { /* 以62开头的是UnionPay(13-19位) */
      if (numberStr.length >=13 && numberStr.length <= 19) {
        isReady = isCvv && isExpDate;
        isCorrectCard = true;
      }
    }
    this.setState({
      isCorrectCard,
    })

    return isReady;
  }

  repairCardNumber = (value: string) => value.replace(/\s+/g, '')
  repairExpDate = (value: string) => value.replace(/\s*\/\s*/g, '')

  handleScrollTo = () => {
    const { reducersState } = this.props;
    const { deviceInfo } = reducersState.app;
    setTimeout(() => {
      if (document.activeElement && deviceInfo.isNativeApp && !deviceInfo.isAppleApp) {
        document.activeElement.scrollIntoView();
      }
    }, 400)
  }
  handleCardNumChange = (value: string) => {
    const { storeID, onReadyChange } = this.props;
    const replaceValue = this.repairCardNumber(value);
    let isGiftCard = false;
    let {
      cardIconType,
      cardNum,
      cardTypeError,
      cardType,
    } = this.state;
    const { expMonth, expYear, pin, cvv, isSelected } = this.state;

    cardTypeError = false;

    if (/^4\d*/.test(`${replaceValue}`)) { /* 以4开头的是VISA(13-16位) */
      cardIconType = IconType.VISA_CARD;
      cardType = payCardType.VISA;
      if (replaceValue.length <= 16) {
        cardNum = value;
      }
    } else if (/^5[1-5]\d*/.test(`${replaceValue}`)) { /* 以51-55开头的是MASTER(16位) */
      cardIconType = IconType.MASTER_CARD;
      cardType = payCardType.MASTER;
      if (replaceValue.length <= 16) {
        cardNum = value;
      }
    } else if (/^3[4|7]\d*/.test(`${replaceValue}`)) { /* 以34,37开头的是AMEX(15位) */
      cardIconType = IconType.AE_CARD;
      cardType = payCardType.AMEX;
      if (replaceValue.length <= 15) {
        cardNum = value;
      }
    } else if (/^3[1|2]\d*/.test(`${replaceValue}`)) { /* 以31,32开头的是JCB(15-16位) */
      cardIconType = IconType.JCB_CARD;
      cardType = payCardType.JCB;
      if (replaceValue.length <= 16) {
        cardNum = value;
      }
    } else if (/^24\d*/.test(`${replaceValue}`) && storeID) { /* 以24开头的是Gift Card(20位) */
      cardIconType = IconType.GIFT_CARD;
      isGiftCard = true;
      if (replaceValue.length <= 20) {
        cardNum = value;
      }
    } else if (/^62\d*/.test(`${replaceValue}`)) { /* 以62开头的是UnionPay(13-19位) */
      cardIconType = IconType.UNION_PAY;
      cardType = payCardType.UNION_PAY;
      if (replaceValue.length <= 19) {
        cardNum = value;
      }
    } else {
      cardIconType = IconType.CREDIT_CARD;
      if (replaceValue.length <= 2) {
        cardNum = value;
      }

      if (replaceValue.length < 2) {
        cardTypeError = false;
      } else {
        cardTypeError = true;
      }
    }

    this.setState({
      cardIconType,
      cardNum,
      cardTypeError,
      isGiftCard,
      cardType,
      // cardErrorMsg,
    });

    if (onReadyChange) {
      onReadyChange(this.validateCard(
        this.repairCardNumber(cardNum),
        isGiftCard ? pin : cvv,
        `${expMonth}${expYear}`,
        isSelected,
      ));
    }
  }

  handleCardNumClose = () => {
    this.setState({
      cardIconType: IconType.CREDIT_CARD,
      cardNum: '',
      cardTypeError: false,
    })
  }

  handleExpDateChange = (value: string) => {
    const expMonth = value.substr(0, 2);
    const expYear = value.substr(2, 2);

    if (expMonth === '' || (/^[01]/.test(expMonth) && Number(expMonth) < 13)) {
      this.setState({
        expMonth,
        expYear,
      });

      const { onReadyChange } = this.props;
      const { cardNum, isSelected, pin, cvv, isGiftCard } = this.state;
      if (onReadyChange) {
        onReadyChange(this.validateCard(
          this.repairCardNumber(cardNum),
          isGiftCard ? pin : cvv,
          `${expMonth}${expYear}`,
          isSelected,
        ));
      }
    }
  }

  handleExpDateFocus = () => {
    this.handleScrollTo();
    this.setState({ expDateFocus: true });
  }

  handleExpDateBlur = () => {
    this.setState({ expDateFocus: false });
  }

  handleCvvChange = (value: string) => {
    this.setState({
      cvv: value,
    });

    const { onReadyChange } = this.props;
    const { cardNum, expMonth, expYear, isSelected } = this.state;
    if (onReadyChange) {
      onReadyChange(this.validateCard(
        this.repairCardNumber(cardNum),
        value,
        `${expMonth}${expYear}`,
        isSelected,
      ));
    }
  }

  handlePinChange = (value: string) => {
    this.setState({
      pin: value,
    });

    const { onReadyChange } = this.props;
    const { cardNum, expMonth, expYear, isSelected } = this.state;
    if (onReadyChange) {
      onReadyChange(this.validateCard(
        this.repairCardNumber(cardNum),
        value,
        `${expMonth}${expYear}`,
        isSelected,
      ));
    }
  }

  validateGiftCard = (number: string, pin: string): Promise<IGiftCard | undefined> => {
    const { storeID, dispatch } = this.props;

    return new Promise(resolve => {
      if (storeID) {
        dispatch({
          type: ActionType.QUERY_GIFT_CARD_BALANCE,
          number,
          pin,
          storeID,
          callback: storeGiftCards => {
            resolve(storeGiftCards);
          }
        });
      } else {
        resolve();
      }
    })
  }

  addPayCard = (data: IAddPayCardData): Promise<IPayCard | undefined> => {
    const { isGuest, phoneNumber, dialCode, dispatch } = this.props;
    let guestPhoneNumber: string | undefined = undefined;

    if (isGuest && dialCode) {
      guestPhoneNumber = phoneNumber || '00000000';
    }
    return new Promise(resolve => {
      dispatch({
        type: ActionType.ADD_PAY_CARD,
        data,
        phoneNumber: guestPhoneNumber,
        dialCode: dialCode,
        callback: payCard => {
          resolve(payCard);
        }
      });
    })
  }

  editPayCard = (cardID: string, data: IAddPayCardData): Promise<IPayCard | undefined> => {
    const { dispatch } = this.props;

    return new Promise(resolve => {
      dispatch({
        type: ActionType.EDIT_PAY_CARD,
        cardID,
        data,
        callback: payCard => {
          resolve(payCard);
        }
      });
    })
  }

  deletePayCard = (cardID: string): Promise<IPayCard | undefined> => {
    const { dispatch } = this.props;

    return new Promise(resolve => {
      dispatch({
        type: ActionType.DELETE_PAY_CARD,
        cardID,
        callback: payCard => {
          resolve(payCard);
        }
      });
    })
  }

  handleSave = async () => {
    const { cardID, deleteCard } = this.props;
    const {
      isGiftCard,
      cardNum,
      pin,
      expMonth,
      expYear,
      cvv,
    } = this.state;

    const { reducersState } = this.props;
    const isGuest = this.props.isGuest || reducersState.user.isGuest;

    let result: IGiftCard | IPayCard | undefined;

    const newCardNum = this.repairCardNumber(cardNum);
    try {
      if (isGiftCard) {
        result = await this.validateGiftCard(newCardNum, pin);
      } else if (cardID && deleteCard) {
        result = await this.deletePayCard(cardID);
      } else if (cardID) {
        result = await this.editPayCard(cardID, {
          cvv: pin || '',
          expMonth,
          expYear,
          number: newCardNum,
          displayEnable: isGuest ? false : (this.state.isSelected ? true : false),
        });
      } else {
        const { payCards, cardType } = this.state;
        let flag = true;
        if (getAccessToken() && localStorage.getItem(Constants.IS_GUEST) !== 'true') {
          payCards.forEach(item => {
            if (Number(item.last4Digits) === Number(newCardNum.substring(newCardNum.length - 4)) && item.type === cardType && item.expMonth === expMonth && item.expYear === expYear) {
              flag = false;
            }
          })
        }

        let type: any = PayTypes.VISA;
        if ([PayTypes.MASTER, PayTypes.AMEX, PayTypes.UNION_PAY].includes(cardType as any)) {
          type = cardType;
        }

        if (flag) {
          const last4Digits = newCardNum.substring(newCardNum.length - 4);
          if (isGuest) {
            result = {
              number: newCardNum,
              cvv,
              id: createUuid(),
              type,
              cvvVerified: false,
              default: false,
              expMonth,
              expYear,
              holder: '',
              token: '',
              last4Digits,
            }
          } else {
            result = await this.addPayCard({
              cvv,
              expMonth,
              expYear,
              number: newCardNum,
              displayEnable: isGuest ? false : (this.state.isSelected ? true : false),
            });
          }

          // result = await this.addPayCard({
          //   cvv: pin || '',
          //   expMonth,
          //   expYear,
          //   number: newCardNum,
          //   displayEnable: isGuest ? false : (this.state.isSelected ? true : false),
          // });
        }
      }
    } catch (error) {
      console.error(error);
    }

    return result;
  }

  handleBindoCcCheck = (isSelected: boolean) => {
    this.setState({ isSelected });

    const { onReadyChange } = this.props;
    const { cardNum, expMonth, expYear, cvv } = this.state;
    if (onReadyChange) {
      onReadyChange(this.validateCard(
        this.repairCardNumber(cardNum),
        cvv,
        `${expMonth}${expYear}`,
        isSelected,
      ));
    }
  }

  render() {
    const {
      payCardModify,
      className = '',
      isGuest,
    } = this.props;

    if (payCardModify) {
      payCardModify.save = this.handleSave;
    }

    const {
      cardIconType,
      cardNum,
      cardTypeError,
      openEye,
      expMonth,
      expYear,
      cvv,
      pin,
      expDateFocus,
      isSelected,
      cardID,
      isCorrectCard,
    } = this.state;

    let extra: any;

    if (cardNum && !cardID) {
      extra = (
        <Icon
          type={IconType.CLOSE_OUTLINED}
          className="card-icon-close"
          onClick={this.handleCardNumClose}
        />
      );
    }

    const showGiftCard = cardIconType === IconType.GIFT_CARD;

    return (
      <div className={`${prefix} ${className}`}>
        <DataEntryWrapper
          title={getIntl().page.cardNumber}
          errorMsg={cardTypeError ? getIntl().page.payCardTypeError : !cardID && cardNum && !showGiftCard && !isCorrectCard ? getIntl().page.payCardError : ''}
        >
          <InputItem
            type="bankCard"
            extra={extra}
            name="number"
            autoComplete="off"
            disabled={cardID ? true : false}
            value={cardNum}
            placeholder={getIntl().page.placeholderCardNumber}
            onChange={this.handleCardNumChange}
            onFocus={this.handleScrollTo}
          >
            <Icon type={cardIconType} className="card-icon" />
          </InputItem>
        </DataEntryWrapper>
        {
          showGiftCard &&
          <DataEntryWrapper title={cardIconType === IconType.GIFT_CARD ? getIntl().page.pin : getIntl().page.cvvOrPin}>
            <InputItem
              type={openEye ? 'text' : 'password'}
              value={pin}
              name="cvv"
              onChange={this.handlePinChange}
              onFocus={this.handleScrollTo}
              extra={
                pin.length > 0 && cardIconType === IconType.GIFT_CARD && <Icon
                  type={openEye ? IconType.EYE_OPEN_OUTLINED : IconType.EYE_CLOSED_OUTLINED}
                  className="card-icon-eye"
                  onClick={() => {
                    this.setState({openEye: !openEye})
                  }}
                />
              }
            />
          </DataEntryWrapper>
        }
        {
          !showGiftCard &&
          <div className="cc-column">
            <DataEntryWrapper
              title={getIntl().page.expDate}
              className="cc-exp-date"
            >
              <InputItem
                type={expDateFocus ? 'number' : 'text'}
                autoComplete="off"
                value={expYear.length > 0 ? `${expMonth} / ${expYear}` : `${expMonth}${expYear}`}
                name="expDate"
                onChange={this.handleExpDateChange}
                onFocus={this.handleExpDateFocus}
                placeholder="MM/YY"
                extra={
                  ( this.state.expMonth || this.state.expYear ) &&
                  <Icon
                    type={IconType.CLOSE_OUTLINED}
                    className="card-icon-close"
                    onClick={() => this.handleExpDateChange('')}
                  />
                }
              />
            </DataEntryWrapper>
            <DataEntryWrapper
              title={getIntl().page.cvv}
              className="cc-cvv"
            >
              <InputItem
                type="number"
                value={cvv}
                name="cvv"
                maxLength={4}
                onChange={this.handleCvvChange}
                onFocus={this.handleScrollTo}
                placeholder="CVV"
                disabled={cardID ? true : false}
                extra={
                  this.state.cvv && !cardID &&
                  <Icon
                    type={IconType.CLOSE_OUTLINED}
                    className="card-icon-close"
                    onClick={() => this.setState({ cvv: '' })}
                  />
                }
              />
            </DataEntryWrapper>
          </div>
        }
        {
          !showGiftCard && !isGuest &&
          <div className="confirm-bindo-cc">
            <Icon
              className={`confirm-bindo-cc-icon ${isSelected ? 'selected' : ''}`}
              type={isSelected ? IconType.SELECTED_OUTLINED : IconType.UN_SELECTED_OUTLINED}
              onClick={() => this.handleBindoCcCheck(isSelected ? false : true)}
            />
            <span className="confirm-bindo-cc-text">
              {getIntl().page.confirmBindCreditCard}
            </span>
          </div>
        }
      </div>
    );
  }
}

export default PayCardModify;
