
import React from 'react';
import dayjs from 'dayjs';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import Icon, { IconType } from '@/components/Icon';
import Layout from '@/components/Layout';
import ActionType from '@/actions/action-type';
import Config from '@/Config';
import { parseRouteParams, checkImgExists } from '@/utils';
import { IStore } from '@/actions/store-action';
import { IQueueing } from '@/actions/queueing-action';
import { prefix } from '.';
import paths from '@/routes/paths';
import { getIntl } from '@/components/App/App';

export interface IQueueingDetailPageProps extends IBasePageProps {
  
}
export interface IQueueingDetailPageState {
  store: IStore | undefined;
  logoUrl: string;
  record: IQueueing | undefined;
  calling: string;
}

class QueueingDetailPage extends BasePage<IQueueingDetailPageProps, IQueueingDetailPageState> {
  state: IQueueingDetailPageState = {
    store: undefined,
    logoUrl: Config.defaultStoreImg,
    record: undefined,
    calling: '',
  }

  componentDidMount() {
    const {
      dispatch,
      reducersState,
    } = this.props;
    const routeParams = parseRouteParams(this.props);
    const { user } = reducersState;
    const { userInfo } = user;

    super.componentDidMount();

    if (userInfo) {
      dispatch({
        type: ActionType.QUERY_QUEUEINGS,
        formula: `EQ("$.self.id", "${routeParams.queueingID}")`,
        multi: false,
        page: 1,
        id: routeParams.queueingID,
        callback: (records, record, calling) => {
          if (record) {
            dispatch({
              type: ActionType.QUERY_STORE_BY_ID,
              storeID: record.storeID,
              callback: store => {
                if (store) {
                  checkImgExists(store.logoUrl, (success, url) => {
                    this.setState({
                      store,
                      logoUrl: success ? url : Config.defaultStoreImg,
                      record,
                      calling: calling || '',
                    });
                  })
                }
              }
            });
          }
        }
      })
    }
  }

  render() {
    const {
      record,
      calling,
      store,
      logoUrl,
    } = this.state
    return (
      <Layout
        {...this.props}
        className={prefix}
        navIcon={<Icon className="back-icon" type={IconType.LEFT_OUTLINED} />}
        onLeftClick={() => this.handleGoBack({
          path: paths.QUEUEINGS,
          runGoBack: true,
        })}
        navContent={getIntl().page.queueing}
      >
        {
          record && store &&
          <div>
            {
              record.status !== 'no_show' && calling &&
              <div className="queueing-current">
                <div className="queueing-current-tip">{getIntl().page.callingNumber}:</div>
                <div className="queueing-current-number">{calling}</div>
              </div>
            }
            <div className="queueing-card">
              <div className="queueing-store">
                <div className="queueing-store-logo">
                  <div className="image" style={{backgroundImage: `url(${logoUrl})`}} />
                </div>
                <div className="queueing-store-information">
                  <div className="queueing-store-title">{store.name}</div>
                  <div className="queueing-store-address">{store.address}</div>
                </div>
              </div>
              {
                record.status !== 'no_show' &&
                <div>
                  <div className="queueing-card-gap">
                    <hr className="queueing-card-gap-hr"/>
                  </div>
                  <div className="queueing-user">
                    <div className="queueing-user-number">{record.queueingNo}</div>
                    <div className="queueing-user-number-tip">{getIntl().page.yourNumber}</div>
                  </div>
                </div>
              }
              {
                record.status === 'no_show' &&
                <div>
                  <div className="queueing-expired-gap">&nbsp;</div>
                  <div className="queueing-expired">
                    <div className="queueing-expired-number">{record.queueingNo}</div>
                    <div className="queueing-expired-number-tip">{getIntl().page.yourNumber}</div>
                    <div className="queueing-expired-sign">
                      <div className="queueing-expired-sign-text">{getIntl().page.expired}</div>
                    </div>
                  </div>
                </div>
              }
              <div className="queueing-time">
                <div className="queueing-time-title">{getIntl().page.queueTimes}</div>
                <div className="queueing-time-data">{dayjs(record.queueTime).format('YYYY-MM-DD HH:mm:ss')}</div>
              </div>
              <div className="queueing-seats">
                <div className="queueing-seats-title">{getIntl().page.seats}</div>
                <div className="queueing-seats-data">{record.seats}</div>
              </div>
            </div>
          </div>
        }
      </Layout>
    );
  }
}

export default QueueingDetailPage;
