import React, { useState } from 'react';
import { IconType } from '../Icon';
import SignMethodType from '@/components/common/userComponents/MethodType';
import {SignMethod, IWantSignInStyled} from '../common/userComponents';
import MobileRetrieve from './PhoneRetrieve';
import EmailRetrieve from './EmailRetrieve';
import { IReducersState } from '@/reducers';
import { useSelector } from 'react-redux';
import supportUpMethod from '@/actions/sign-up-method';
import { getIntl } from '../App/App';

interface IForgotPasswordProps {
  restInClick?: () => void;
  className?: string;
}

const ForgotPassword: React.FC<IForgotPasswordProps> = props => {

  const {
    className = '',
    restInClick,
  } = props;

  const reducersState: IReducersState = useSelector<IReducersState, IReducersState>(state => state);
  const supportSignUpMethod = reducersState.app.platformConfig.signUpMethods;

  let signTypeInit: SignMethodType = SignMethodType.INIT;

  if (supportSignUpMethod.length === 1) {
    if (supportSignUpMethod.includes(supportUpMethod.PHONE)) {
      signTypeInit = SignMethodType.MOBILE;
    } else if (supportSignUpMethod.includes(supportUpMethod.EMAIL)){
      signTypeInit = SignMethodType.EMAIL;
    }
  }

  const [signType, setSignType] = useState<SignMethodType>(signTypeInit);

  return (
    <IWantSignInStyled>
      <div className={`i-want-sign-in ${className}`} >
        <div className="div-block-520">
          <div>
            {
              (signType === SignMethodType.MOBILE || signType === SignMethodType.INIT) &&
              supportSignUpMethod.includes(supportUpMethod.PHONE) &&
              supportSignUpMethod.length !== 1 &&
              <SignMethod
                label={getIntl().page.mobileRetrieve}
                iconType={IconType.PHONE_OUTLINED}
                signType={signType}
                onClick={() => setSignType(SignMethodType.MOBILE)}
                onBack={() => setSignType(SignMethodType.INIT)}
              />
            }
            {
              (signType === SignMethodType.EMAIL || signType === SignMethodType.INIT) &&
              supportSignUpMethod.includes(supportUpMethod.EMAIL) &&
              supportSignUpMethod.length !== 1 &&
              <SignMethod
                label={getIntl().page.emailRetrieve}
                iconType={IconType.EMAIL_OUTLINED}
                signType={signType}
                onClick={() => setSignType(SignMethodType.EMAIL)}
                onBack={() => setSignType(SignMethodType.INIT)}
              />
            }
            {
              (signType === SignMethodType.MOBILE) &&
              <MobileRetrieve
                restInClick={restInClick}
              />
            }
            {
              (signType === SignMethodType.EMAIL) &&
              <EmailRetrieve
                restInClick={restInClick}
              />
            }
          </div>
        </div>
      </div>
    </IWantSignInStyled>
  )
}
export default ForgotPassword;
