import { IRoute } from '@/routes';
import paths from '@/routes/paths';
import PurchaseOrder from './PurchaseOrder';
import PurchaseOrderDetail from './PurchaseOrderDetail';
import GoodReceivedNotes from './GoodReceivedNotes';
import GoodReceivedNoteDetail from './GoodReceivedNoteDetail';
import ReturnNotes from './ReturnNotes';
import ReturnNoteDetail from './ReturnNoteDetail';

export const purchaseOrderRoutes: IRoute[] = [
  {
    path: paths.PURCHASE_ORDER,
    component: PurchaseOrder,
  },
  {
    path: paths.PURCHASE_ORDER_DETAIL,
    component: PurchaseOrderDetail,
  },
  {
    path: paths.GOOD_RECEIVED_NOTES,
    component: GoodReceivedNotes,
  },
  {
    path: paths.GOOD_RECEIVED_NOTE_DETAIL,
    component: GoodReceivedNoteDetail,
  },
  {
    path: paths.RETURN_NOTES,
    component: ReturnNotes,
  },
  {
    path: paths.RETURN_NOTE_DETAIL,
    component: ReturnNoteDetail,
  },
];

export default purchaseOrderRoutes;
