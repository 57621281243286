import produce from 'immer';
import { handleActions } from 'redux-actions';
import ActionType from '@/actions/action-type';
import { IObject } from '@/components/bm-common';
import { IOrderInfo, ITaxOption } from '@/lib/order-builder/models/OrderEntity';
import {
  ISetOrder,
  IBatchSetOrders,
  IPushOrderInfosAction,
  ISetStoreTaxOptions,
  ISetStoreRoundingOptions,
  ISetAllStoreRoundingAndTaxOptions,
} from '@/actions/order-action';

export interface IOrderState {
  orderNumberRefOrder: {[orderNumber: string]: IObject};
  statusRefOrderInfos: {[status: string]: IOrderInfo[]};
  slugRefTaxOptions: {[storeSlug: string]: ITaxOption[]};
  slugRefRoundingOptions: {[storeSlug: string]: IObject};
}

const initState: IOrderState = {
  orderNumberRefOrder: {},
  statusRefOrderInfos: {},
  slugRefTaxOptions: {},
  slugRefRoundingOptions: {},
};

const actions: any = {};

actions[ActionType.SET_ORDER] = (state: IOrderState, action: ISetOrder) => produce(state, draft => {
  draft.orderNumberRefOrder[action.orderNumber] = action.order;
});

actions[ActionType.CLEAR_ORDER_INFOS] = (state: IOrderState) => produce(state, draft => {
  draft.statusRefOrderInfos = {};
});

actions[ActionType.BATCH_SET_ORDERS] = (state: IOrderState, action: IBatchSetOrders) => produce(state, draft => {
  if (Array.isArray(action.data)) {
    action.data.forEach(item => {
      draft.orderNumberRefOrder[item.orderNumber] = item.order;
    })
  }
});

actions[ActionType.PUSH_ORDER_INFOS] = (state: IOrderState, action: IPushOrderInfosAction) => produce(state, draft => {
  if (!draft.statusRefOrderInfos[action.status]) {
    draft.statusRefOrderInfos[action.status] = [];
  }

  draft.statusRefOrderInfos[action.status].push(...action.orderInfos);
});

actions[ActionType.SET_STORE_ROUNDING_OPTIONS] = (state: IOrderState, action: ISetStoreRoundingOptions) => produce(state, draft => {
  draft.slugRefRoundingOptions[action.storeSlug] = action.roundingOptions;
});

actions[ActionType.SET_ALL_STORE_ROUNDING_AND_TAX_OPTIONS] = (state: IOrderState, action: ISetAllStoreRoundingAndTaxOptions) => produce(state, draft => {
  Object.keys(action.allStoreRoundingOptions).forEach(key => {
    draft.slugRefRoundingOptions[key] = action.allStoreRoundingOptions[key];
  })
  Object.keys(action.allStoreTaxOptions).forEach(key => {
    draft.slugRefTaxOptions[key] = action.allStoreTaxOptions[key];
  })
});

actions[ActionType.SET_STORE_TAX_OPTIONS] = (state: IOrderState, action: ISetStoreTaxOptions) => produce(state, draft => {
  draft.slugRefTaxOptions[action.storeSlug] = action.taxOptions;
});

export default handleActions(actions, initState);
