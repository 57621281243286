import { handleBuildOrderPayment } from '../Utils';
import { payInvoice } from '../payment-data';
import { IPayOrderResult, IGoogleMethod } from '..';

interface IUseGooglePayParams {
  storeID: string;
  orderNumber: string;
  payData: IGoogleMethod;
  payment: any;
}

const UseGooglePay = (params: IUseGooglePayParams) => {
  const {
    storeID,
    orderNumber,
    payment,
    payData,
  } = params;

  const { googlePayData, amount } = payData;

  const newGooglePay = googlePayData;
  if (newGooglePay) {
    newGooglePay.token.paymentMethodData.info.assuranceDetails = {
      accountVerified: true,
      cardHolderAuthenticated: false,
    };
  }

  const googlePay = async (): Promise<IPayOrderResult> => {
    const newPayment: any = { 
      'google_pay': [
        {
          amount,
          ...newGooglePay,
        }
      ]
    };

    const order = handleBuildOrderPayment(newPayment, payment);
    try {
      const resp = await payInvoice(storeID, orderNumber, order);
      const {
        status,
        order: fulfilOrder,
        success,
      } = resp || {};

      if (success && status === 'ok' && fulfilOrder.sale_transactions && fulfilOrder.sale_transactions.length > 0) {
        return {
          status: 200,
          order: fulfilOrder,
        };
      } else {
        return {
          status: 500,
          ...resp,
        };
      }
    } catch (error) {
      throw error;
    }
  };
  return {
    googlePay,
  };
};
export default UseGooglePay;
