import React, { useState, useEffect } from 'react';
import InputItem from 'antd-mobile/es/input-item';
import Button from 'antd-mobile/es/button';
import Modal from 'antd-mobile/es/modal';
import { useSelector } from 'react-redux';
import { IReducersState } from '@/reducers';
import { IDialCodeGroupItem } from '@/components/DialCodeGroup';
import { sendSmsCode, restPasswprd } from '@/data/sign';
import Icon, { IconType } from '@/components/Icon';
import SignUserName from '../common/userComponents/NewUserName'
import { minus } from '@/utils';
import { prefix } from '.';
import { getIntl } from '../App/App';


interface IPhoneRetrieveProps {
  restInClick?: () => void;
  className?: string;
}

const PhoneRetrieve: React.FC<IPhoneRetrieveProps> = props => {

  const {
    className = '',
    restInClick = () => {},
  } = props;

  const reducersState: IReducersState = useSelector<IReducersState, IReducersState>(state => state);

  const { language } = reducersState.app;

  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [verifyCode, setVerifyCode] = useState<string>('');
  const [password, setPasswod] = useState<string>('');
  const [areaCodeData, setAreaCodeData] = useState<IDialCodeGroupItem>();
  const [verifyCodeCountdown, setVerifyCodeCountdown] = useState<number>(60);
  const [showCountDown, setShowCountDown] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [verfityPhone, setVerfityPhone] = useState<boolean>(false);
  const [showPinMsg, setShowPinMsg] = useState<boolean>(false);
  let timeLoop: any;
  const SETVERIFYCODETIME = 'VERIFY_CODE_CLICK_TIME';

  useEffect(() => {
    const verifyClickTime = localStorage.getItem(SETVERIFYCODETIME) || 0;
    const nowTime = new Date().getTime();
    const minusTime = Math.floor(minus(nowTime, verifyClickTime as number)/1000)
    if (minusTime < 60) {
      setVerifyCodeCountdown(60-minusTime);
      sendVerifyCodeClick();
    }
  }, []);

  const sendVerifyCode = async () => {
    if (!phoneNumber || !areaCodeData || phoneNumber.length < 8) return;
    try {
      sendVerifyCodeClick();
      const resp = await sendSmsCode({
        phone: `+${areaCodeData.dialCode} ${phoneNumber.replace(/\s/g, '')}`,
      });

      if (resp && !resp.success) {
        clearCountDown();
        Modal.alert('', resp.message, [
          {
            text: getIntl().common.confirm,
            onPress: () => {},
          }
        ]);
      }

    } catch (error) {
      console.error(error)
    }
  }

  const restPassword = async () => {
    if (!phoneNumber || !areaCodeData || phoneNumber.length < 8) return;
    try {
      const resp = await restPasswprd({
        phone: `+${areaCodeData.dialCode} ${phoneNumber.replace(/\s/g, '')}`,
        password,
        autCode: verifyCode,
      });

      if (resp && !resp.success) {
        clearCountDown();
        Modal.alert('', resp.message, [
          {
            text: getIntl().common.confirm,
            onPress: () => {},
          }
        ]);
      }
      if (resp && resp.success){
        const message = <Icon type={IconType.STATUS_SUCCESS} />
        const info = getIntl().page.changePasswordSuccessfully;
        Modal.alert('', <>{message}<br/>{info}</>, [
          {
            text: getIntl().common.confirm,
            onPress: () => {
              restInClick();
            },
          }
        ]);
      }
    } catch (error) {}
  }

  const sendVerifyCodeClick = () => {
    setShowCountDown(true);
    const nowTime = new Date();
    localStorage.setItem(SETVERIFYCODETIME, `${nowTime.getTime()}`);
    let countDown = verifyCodeCountdown;
    timeLoop = setInterval(() => {
      if (countDown < 1) {
        clearCountDown();
        setVerifyCodeCountdown(60);
      } else {
        countDown -= 1;
        setVerifyCodeCountdown(countDown);
      }
    }, 1000);
  }

  const handleItemClick = (data: IDialCodeGroupItem) => {
    setAreaCodeData(data);
  }

  const clearCountDown = () => {
    clearInterval(timeLoop);
    localStorage.removeItem(SETVERIFYCODETIME);
    setShowCountDown(false);
  }

  const extraVerifyComponent = () => {
    return (
      <div className={`${prefix}-mobile-verify-send`}>
        {
          !showCountDown &&
          <div onClick={sendVerifyCode}>
            {getIntl().common.send}
          </div>
        }
        {
          showCountDown &&
          <div>
            {`${verifyCodeCountdown}s`}
          </div>
        }
      </div>
    )
  }

  const extraPasswordComponent = () => {
    return (
      <div className={`${prefix}-mobile-verify-password-icon`}>
        {
          !showPassword && password &&
          <div onClick={() => setShowPassword(true)}>
            <Icon
              type={IconType.EYE_CLOSED_OUTLINED}
              className="card-icon-close"
            />
          </div>
        }
        {
          showPassword && password &&
          <div onClick={() => setShowPassword(false)} >
            <Icon
              type={IconType.EYE_OPEN_OUTLINED}
              className="card-icon-close"
            />
          </div>
        }
      </div>
    )
  }

  const onPinFieldClick = () => {
    setShowPinMsg(true);
  }

  return (
    <div className={`${prefix}-mobile ${className}`} >
      <SignUserName
        language={language}
        value={phoneNumber}
        onChange={setPhoneNumber}
        onItemClick={(data: IDialCodeGroupItem) => handleItemClick(data)}
        showIcon={true}
        type="PHONE"
        areaCodeData={areaCodeData}
        verfityPhone={(value: boolean) => setVerfityPhone(value)}
      />
      <div className={`${prefix}-mobile-verify`}>
        <InputItem
          name="verify"
          autoComplete="off"
          placeholder={getIntl().page.verifyCode}
          onChange={setVerifyCode}
          value={verifyCode}
          type="number"
          extra={
            extraVerifyComponent()
          }
          maxLength={6}
        />
      </div>

      <div className={`${prefix}-mobile-password`}>
        <InputItem
          name="password"
          autoComplete="off"
          placeholder={getIntl().page.newPassword}
          onChange={setPasswod}
          onFocus={onPinFieldClick}
          value={password}
          type={showPassword ? 'text' : 'password'}
          extra={
            extraPasswordComponent()
          }
        />
        {
          showPinMsg && password.length < 6 &&
          <div className="password-check">{getIntl().page.passwordLength}</div>
        }
      </div>

      {
        <Button
          type="primary"
          className={`${prefix}-signin-button`}
          onClick={restPassword}
          disabled={ !phoneNumber || !password || password.length < 6 || !verifyCode || verfityPhone}
        >
          {getIntl().common.send}
        </Button>
      }
    </div>
  )
}
export default PhoneRetrieve;
