import styled from 'styled-components';
export const SelectSignInPageStyled = styled.div`
* {
  box-sizing: border-box;
}
.with-bg {
  background-position: 50% 100%;
  background-size: cover;
  height: 100vh;
}
.section-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  margin-top: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-position: 50% 50%;
  background-size: cover;
}
.w-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
  flex: unset;
}
.div-block-613 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: -145px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}
.image-204 {
  margin-right: auto;
  margin-left: auto;
}
.div-block-612 {
  width: auto;
  /* margin: 20px 0px 40px; */
  margin: 0 20px 40px 20px;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}
.w-inline-block {
  max-width: 100%;
  display: inline-block;
}
.sign-up {
  position: relative;
  left: auto;
  top: 0px;
  right: 0%;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 55px;
  margin-top: 20px;
  margin-right: 0px;
  margin-left: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 3px solid #000;
  border-radius: 40px;
  background-color: var(--bm-primary-color);
  color: var(--bm-light-background-color);
  text-decoration: none;
}
.text-block-91 {
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: var(--bm-text-color);
  font-size: 18px;
  text-align: center;
  svg {
    font-size: 18px;
  }
}
.text-block-17 {
  font-family: 'Sf pro display', sans-serif;
  font-size: 18px;
  font-weight: 600;
}
.text-block-91.bold-red {
  color: var(--bm-primary-color);
  font-weight: 700;
}
.div-block-609 {
  display: block;
  margin-right: auto;
  margin-left: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  color: var(--bm-text-title-color);
  text-decoration: none;
}
@media screen and (max-width: 991px) {
  .w-container {
    max-width: 728px;
  }
  .sign-up {
    position: static;
    top: 0px;
    right: 0%;
    width: auto;
    margin-top: 20px;
    margin-right: 0px;
    padding: 20px;
    color: var(--bm-light-background-color);
  }

}

@media screen and (max-width: 767px) {
  .section-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 95vh;
    padding-bottom: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    background-image: none;
    background-size: auto;
  } 
  .image-204 {
    max-width: 90%;
  }
  .text-block-17 {
    font-size: 18px;
  }
  .sign-up {
    left: auto;
    top: -22%;
    right: -5%;
    bottom: 0%;
  }
}
@media screen and (max-width: 479px) {
  .section-2 {
    height: 95vh;
    padding-bottom: 0px;
  }
  .w-container {
    max-width: none;
  }
  .container {
    margin-top: 0px;
    margin-bottom: 0px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .container.strech {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .div-block-613 {
    margin-top: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .image-204 {
    max-width: 70%;
  }
  .div-block-612 {
    margin-right: 20px;
    margin-left: 20px;
  }
  .sign-up {
    left: auto;
    top: 0%;
    right: 0%;
    bottom: 0%;
    width: auto;
    height: 50px;
    padding: 9px;
  }
  .text-block-17 {
    font-size: 16px;
  }
  .text-block-91 {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    font-size: 16px;
    .svg {
      font-size: 16px;
    }
  }
  .text-block-91.streched {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }
  .text-block-91 {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    font-size: 16px;
  }
}

`;
