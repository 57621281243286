import { RouteComponentProps } from 'react-router-dom';
import withRoute from '@/pages/withRoute';
import PurchaseOrder from './PurchaseOrder';
import './PurchaseOrder.less';

export const prefix = 'bm-p-purchase-order';

const RouteComponent: React.FC<RouteComponentProps> = props => withRoute({
  pageComponent: PurchaseOrder,
  routeProps: props,
  pageTitleI18nKey: '',
});
export default RouteComponent;
