import { IRoute } from '@/routes';
import paths from '@/routes/paths';
import QrcodeShoppingPage from './QrcodeShoppingPage';
import QrcodeShoppingCartPage from './QrcodeShoppingCartPage';
import Menus from '@/routes/mobile/menus';
import QrcodeShoppingResultPage from './QrcodeShoppingResultPage';
import ModifierPage from '@/routes/mobile/modifier';

export const gatewayRoutes: IRoute[] = [
  {
    path: paths.QRCODE_SHOPPING,
    component: QrcodeShoppingPage,
  },
  {
    path: paths.QRCODE_SHOPPING_MENUS,
    component: Menus,
  },
  {
    path: paths.QRCODE_SHOPPING_CART,
    component: QrcodeShoppingCartPage,
  },
  {
    path: paths.QRCODE_SHOPPING_RESULT,
    component: QrcodeShoppingResultPage,
  },
  {
    path: paths.QRCODE_SHOPPING_DETAIL,
    component: QrcodeShoppingCartPage,
  },
  {
    path: paths.QRCODE_SHOPPING_MODIFIER,
    component: ModifierPage,
  },
  {
    path: paths.QRCODE_SHOPPING_SUB_MODIFIER,
    component: ModifierPage,
  },
  {
    path: paths.QRCODE_SHOPPING_SUB_MODIFIER_B,
    component: ModifierPage,
  },
];

export default gatewayRoutes;
