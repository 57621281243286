import React, { useState } from 'react';
import InputItem from 'antd-mobile/es/input-item';
import Icon, { IconType } from '../Icon';
import { modalPrefix } from '.';
import { IDialCodeGroup, IDialCodeGroupItem } from '@/components/DialCodeGroup';
import CountryCodeItem from './CountryCodeItem';
import { getIntl } from '../App/App';

interface ICountryCodeModalProps {
  data: IDialCodeGroup[];
  className?: string;
  visible: boolean;
  onItemClick?: (data: IDialCodeGroupItem) => void;
  onCancel?: () => void;
}

const CountryCodeModal: React.FC<ICountryCodeModalProps> = props => {

  const [searchKey, setSearchKey] = useState<string>('');

  const {
    data,
    className = '',
    visible = false,
    onItemClick,
    onCancel = () => {},
  } = props;

  const handleAreaCodeChange = (data: IDialCodeGroupItem) => {
    if (onItemClick) {
      onItemClick(data);
    }
  }

  return (
    <div className={`modal-wrapper-2 ${modalPrefix} ${className} ${visible ? 'visible' : 'hide'}`}
      // style={{`${visible}`? }}
      // style={{transform: `${visible ?'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)': 'translate3d(0px, 600PX, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)'}`, transformStyle: 'preserve-3d', opacity: 1, display: `${visible? 'flex': 'none' }`}}
    >
      <div className="c-popup-close close-modal" onClick={() => onCancel()}></div>
      <div className="flex-pop-up widen fixed">
        <div className="fixed-block">
          <div className="top-header">
            <h3 className="heading-4-1 procurement">
              {getIntl().page.areaCodeTitle}
            </h3>
            <Icon className="html-embed-22 w-embed" type={IconType.CLOSE_OUTLINED} onClick={() => onCancel()} />
          </div>
          <div className="div-block-400">
            <InputItem
              className="search-bar"
              name="search"
              value={searchKey}
              autoComplete="off"
              placeholder={getIntl().common.search}
              onChange={setSearchKey}
              extra={
                searchKey &&
                <Icon
                  type={IconType.CLOSE_OUTLINED}
                  className="card-icon-close"
                  onClick={() => setSearchKey('')}
                />
              }
            >
              <div className={`${modalPrefix}-inputextra`} >
                <Icon
                  type={IconType.SEARCH_OUTLINED}
                  className={`${modalPrefix}-inputextra-icon`}
                />
              </div>
            </InputItem>
          </div>
        </div>
        {
          data.map(item => (
            <CountryCodeItem
              key={item.key}
              data={item}
              onClick={handleAreaCodeChange}
              searchKey={searchKey}
            />
          ))
        }
      </div>
    </div>
  )
}
export default CountryCodeModal;
