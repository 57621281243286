import React from 'react';

interface IPeopleItemProps {
  className: string;
  people: number;
  onClick: (people: number) => void;
  select: boolean;
}

const PeopleItem: React.FC<IPeopleItemProps> = props => {
  const {
    className,
    people,
    onClick,
    // select,
  } = props;

  return (
    <div className="radio-block">
      <div className="icon-people" onClick={() => onClick(people)}>
        <div className={`icon-head ${className}`}></div>
        <div className={`icon-body ${className}`}>{people}</div>
      </div>
    </div>
  );
}

export default PeopleItem;
