import React from 'react';
import { useHistory } from 'react-router-dom';
import { createRouteUrl } from '@/utils';
import paths from '@/routes/paths';
import { goToRoute } from '@/utils/route-util';
import { SignInBottomStyled } from './styled';
import { getIntl } from '../App/App';

interface ISignInBottomProps {
  className?: string;
}

const SignInBottom: React.FC<ISignInBottomProps> = props => {

  const history = useHistory();

  const termsClick = () => {
    goToRoute({
      history,
      pathname: createRouteUrl(paths.SIGN_UP),
    });
  };

  return (
    <SignInBottomStyled>
      <span>{getIntl().page.haveAccount}</span>
      &nbsp;
      <span className="bottom-signup" onClick={termsClick}>
        {getIntl().page.signUpNow}
      </span>
    </SignInBottomStyled>
  );
};
export default SignInBottom;
