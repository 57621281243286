import React from 'react';
import { formatCurrency } from '../Payment';
import Icon, { IconType } from '../Icon';
import { IListing } from '@/lib/order-builder/utils/api';
import { ListingCountRef } from '@/actions/shopping-cart-action';
import { IReducersState } from '@/reducers';
import { getI18nText } from '@/utils/app';
import Config from '@/Config';
import { getIntl } from '../App/App';

export interface IMenuItemProps {
  data: IListing;
  parentViewID: string;
  isOpen: boolean;
  listingCountRef: ListingCountRef;
  currencyCode?: string;
  className?: string;
  itemClassName?: string;
  onlyShow?: boolean; // 只用于显示菜单信息，屏蔽所有操作功能
  onPlusClick?: (data: IListing) => void;
  onMinusClick?: (data: IListing) => void;
  reducersState: IReducersState;
  onPopupClick?: (data: IListing) => void;
  handleChangeQuantityTo?: (data: IListing, quantity: number) => void;
}

const MenuItem: React.FC<IMenuItemProps> = props => {
  const {
    data,
    parentViewID,
    currencyCode,
    listingCountRef = {},
    className = '',
    isOpen,
    onlyShow = false,
    onPlusClick,
    onMinusClick,
    reducersState,
  } = props;

  const handleMinusClick = () => {
    if (onMinusClick) {
      onMinusClick(data);
    }
  }

  const handlePlusClick = () => {
    if (onPlusClick) {
      onPlusClick(data);
    }
  }

  const soldOut = data.soldOut || false;
  return (
    <div className={`item-wrapper cms ${className} new-menu-item`}>
      <div className="container w-container">
        <div className="div-12 open-modal">
          <div className="image-open" style={{ backgroundImage: `url("${data.imageUrl || Config.defaultStoreImg}")` }} ></div>
          <div className="item-info-wrapper">
            <div className="item-info">
              <h4 className="heading-4-1">{data.aliasName || getI18nText(data, 'name', reducersState.app.language) || data.name}</h4>
              <div className="describe" dangerouslySetInnerHTML={{ __html: getI18nText(data, 'description', reducersState.app.language) || data.description}}></div>
            </div>
            <div className="price">
              <div className="text-block-6">{formatCurrency(data.price, currencyCode)}</div>
              {
                !isOpen &&
                <div className="sold-out">{getIntl().page.lineItemClosed}</div>
              }
              {
                isOpen && soldOut &&
                <div className="sold-out">{getIntl().page.soldOut}</div>
              }
              {
                isOpen && data.status && data.status === 'Suspend' &&
                <div className="sold-out">{getIntl().page.suspend}</div>
              }
              {
                isOpen && data.status && data.status === 'Deactivate' &&
                <div className="sold-out">{getIntl().page.deactivate}</div>
              }
              {
                !onlyShow && isOpen && !soldOut && data.status !== 'Suspend' && data.status !== 'Deactivate' &&
                <div className="q-flex">
                  {
                    listingCountRef[data.id] && listingCountRef[data.id].quantity > 0 &&
                    <>
                      <Icon
                        className={`q-dec disable-minus bm-test-minus-${parentViewID}-${data.id}`}
                        type={IconType.MINUS_CIRCLE_OUTLINED}
                        onClick={handleMinusClick}
                      />
                      <div className={`q-num bm-test-count-${parentViewID}-${data.id}`}>
                        {listingCountRef[data.id].quantity}
                      </div>
                    </>
                  }
                  <Icon
                    className={`q-inc bm-test-plus-${parentViewID}-${data.id}`}
                    type={IconType.PLUS_CIRCLE_OUTLINED}
                    onClick={handlePlusClick}
                  />
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MenuItem;
