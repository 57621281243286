
import React from 'react';
import Button from 'antd-mobile/es/button';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import { IPayCard } from '@/actions/pay-action';
import Icon, { IconType } from '@/components/Icon';
import { IObject } from '@/components/bm-common';
import { getAppTheme, setScreenColor, getIntl } from '@/components/App/App';
import PayCardModify, { usePayCardModify, IPayCardInstance } from '@/components/PayCardModify';
import { prefix } from '.';
import { IOrderInfo } from '@/lib/order-builder/models/OrderEntity';
import Modal from 'antd-mobile/es/modal';
import ActionType from '@/actions/action-type';
import { AddCreditCardPageStyled } from './styled';

export interface IAddCreditCardPageProps extends IBasePageProps {
  onCancel: () => void;
  cardID?: string;
  handleData: () => void;
}
export interface IAddCreditCardPageState {
  orderInfo?: IOrderInfo;
  payCards?: IPayCard[];
  cardIconType: IconType;
  cardNum: string;
  cardError: boolean;
  cardErrorMsg: string;
  openEye: boolean;
  isReady: boolean;
  adding: boolean;
  deleteCard: boolean;
}

class AddCreditCardPage extends BasePage<IAddCreditCardPageProps, IAddCreditCardPageState> {
  routeParams: IObject = {};
  payCardModify: IPayCardInstance | undefined;
  disabled = false;
  
  constructor(props: IAddCreditCardPageProps) {
    super(props);

    const [payCardModify] = usePayCardModify();
    this.payCardModify = payCardModify;
  }

  state: IAddCreditCardPageState = {
    orderInfo: undefined,
    payCards: [],
    cardIconType: IconType.CREDIT_CARD,
    cardNum: '',
    cardError: false,
    cardErrorMsg: '',
    openEye: false,
    isReady: false,
    adding: false,
    deleteCard: false,
  }

  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.lightBackgroundColor) {
      setScreenColor(appTheme.lightBackgroundColor);
    }
    
    super.componentDidMount();
  }

  handleSave = async () => {
    if (!this.payCardModify) return;
    if (!this.disabled) {
      this.disabled = true;
      const { dispatch, handleData, onCancel } = this.props;
      dispatch({
        type: ActionType.LOADING,
        showLoading: true,
      });
      const result = await this.payCardModify.save();
      if (result) {
        handleData();
      } else {
        onCancel();
        Modal.alert(getIntl().common.tips, getIntl().page.failure, [
          {
            text: getIntl().common.confirm,
            onPress: () => {},
          }
        ]);
      }
      this.disabled = false;
      dispatch({
        type: ActionType.LOADING,
        showLoading: false,
      });
    }
   
  }

  handleDelete = () => {
    const { dispatch, handleData } = this.props;
    Modal.alert(getIntl().common.tips, getIntl().page.deleteTips, [
      {
        text: getIntl().common.cancel,
        onPress: () => {},
      },
      {
        text: getIntl().common.confirm,
        onPress: () => {
          this.setState({
            deleteCard: true,
          }, async() => {
            if (!this.payCardModify) return;
            dispatch({
              type: ActionType.LOADING,
              showLoading: true,
            });
            const result = await this.payCardModify.save();
            if (!result) {
              handleData();
            }
            dispatch({
              type: ActionType.LOADING,
              showLoading: false,
            });
          });
        },
      }
    ]);
  }

  handleReadyChange = (isReady: boolean) => {
    this.setState({
      isReady,
    })
  }

  render() {
    console.info(1);
    const { dispatch, reducersState, cardID, onCancel } = this.props;
    const {
      adding,
      isReady,
    } = this.state;

    return (
      <AddCreditCardPageStyled className={`${prefix} bm-p-pay-card`}>
        <div className="c-popup-close-2 close-modal" onClick={onCancel}></div>
        <div className="flex-pop-up widen">
          <div className="top-header no-line">
            {
              cardID &&
              <div className="x-icon close-modal">
                <Icon className="w-embed" type={IconType.CLOSE_OUTLINED} onClick={onCancel} />
              </div>
            }
            <h2 className="heading-2 middle">{getIntl().page.paymentCardTitle}</h2>
            {
              cardID &&
              <div className="delete-text" onClick={this.handleDelete}>{getIntl().page.delete}</div>
            }
            {
              !cardID &&
              <div className="x-icon close-modal right">
                <Icon className="w-embed" type={IconType.CLOSE_OUTLINED} onClick={onCancel} />
              </div>
            }
            
          </div>
          <div className="form-3">
            <PayCardModify
              dispatch={dispatch}
              cardID={cardID}
              deleteCard={this.state.deleteCard}
              payCardModify={this.payCardModify}
              onReadyChange={this.handleReadyChange}
              reducersState={reducersState}
            />
            <Button type="primary" className="log-in-button save-card" disabled={!isReady || adding} onClick={this.handleSave}>
              {getIntl().common.save}
            </Button>
          </div>
        </div>
      </AddCreditCardPageStyled>
    );
  }
}

export default AddCreditCardPage;
