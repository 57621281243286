import React, { useState } from 'react';
import InputItem from 'antd-mobile/es/input-item';
import Icon, { IconType } from '@/components/Icon';
import { navPrefix } from '.';
import { getIntl } from '@/components/App/App';

interface ISearchTitleProps {
  onCancel: () => void;
  onSearch?: (value: string) => void;
}

const SearchTitle: React.FC<ISearchTitleProps> = props => {
  const {
    onSearch,
    onCancel,
  } = props;

  const [searchKey, setSearchkey] = useState<string>('');

  const onKeyUp = (evt: React.KeyboardEvent) => {
    const code = evt.charCode || evt.keyCode;
    if (code === 13) { // 点击回车或者在搜索
      onSearch && onSearch(searchKey);
    }
  }

  return (
    <div className={`${navPrefix}-nav`}>
      <div className={`${navPrefix}-input`} >
        <InputItem
          name="searchKey"
          value={searchKey}
          autoComplete="off"
          placeholder={getIntl().common.search}
          onChange={(value: string) =>  setSearchkey(value)}
          onKeyUp={onKeyUp}
          extra={
            searchKey &&
            <Icon
              type={IconType.CLOSE_OUTLINED}
              className="card-icon-close"
              onClick={() => {
                setSearchkey('');
              }}
            />
          }
        >
          <div className={`${navPrefix}-inputextra`} >
            <Icon
              type={IconType.SEARCH_OUTLINED}
              className={`${navPrefix}-inputextra-icon`}
            />
          </div>
        </InputItem>
      </div>
      <div className={`${navPrefix}-button`} onClick={onCancel}>
        {getIntl().common.cancel}
      </div>
    </div>
  )
}
export default SearchTitle;
