enum SideNavOption {
  USE_POINTS = 'USE_POINTS',
  POINTS = 'POINTS',
  PRIVILEGE = 'PRIVILEGE',
  RECORDS = 'RECORDS',
  PAYMENT_LIST = 'PAYMENT_LIST',
  SETTING = 'SETTING',
  BLOGS_ABOUT_AS = 'BLOGS_ABOUT_AS',
  BOOKING = 'BOOKING',
  ADDRESS = 'ADDRESS',
  FAVORITE = 'FAVORITE',
}

export default SideNavOption;
