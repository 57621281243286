import styled from 'styled-components';

export const EShopPageStyled = styled.div`
  .modal-wrapper {
    z-index: 9999999;
  }
  .text-block-5 {
    margin-top: 12px;
  }
  .nav_bg.streched.colored.w-nav {
    padding-top: 0;
  }
  .nav_bg {
    position: -webkit-sticky;
    position: sticky;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    height: 52px;
  }
  .container.fixed-nav {
    background-color: var(--bm-primary-color);
  }
  .nav_bg.streched {
    position: -webkit-sticky;
    position: sticky;
    z-index: 9800000;
    height: auto;
    max-height: 70px;
    min-height: 70px;
    border-bottom: 0px solid rgba(0, 0, 0, 0.1);
    background-image: none;
    background-size: auto;
  }
  .nav_bg.streched.colored {
    position: -webkit-sticky;
    position: sticky;
    max-height: none;
    position: fixed;
    top: calc(var(--bm-safe-area-top) + 70PX);
  }
  .container-stretched_1 {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    max-height: 70px;
    max-width: none;
    min-height: 70px;
    margin-right: 60px;
    margin-left: 60px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .left {
    font-size: unset;
    padding: unset;
    width: 31px;
    height: 22px;
    color: var(--bm-light-background-color);
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .overlay-nav {
    position: static;
    z-index: 90000;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    max-height: none;
    max-width: 100vw;
    min-height: auto;
    min-width: 100vw;
    margin-right: 0px;
    margin-left: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: var(--bm-light-background-color);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  }
  .submenus {
    display: block;
    margin-top: 0px;
    margin-bottom: 40px;
    padding: 0px;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  .section.tinted.grey {
    background-color: unset;
  }
  .div-block-527{
    height: 200px;
    margin-bottom: 20px;
    border-radius: 4px;
    background-position: 0px 0px;
    background-size: cover;
    cursor: pointer;
  }
  .item-img-wrapper {
    height: 200px;
    margin-bottom: 20px;
    border-radius: 4px;
    cursor: pointer;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .item-info-wrapper-eshop {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: none;
    min-width: auto;
    margin-right: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .price-eshop.bottom {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 30px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 30px;
  }
  .text-block-6 {
    max-width: none;
    margin-top: 0;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    font-family: 'Sf pro display', sans-serif;
    color: var(--bm-text-title-color);
    font-size: 18px;
    font-weight: 600;
  }
  .describe {
    font-size: 18PX;
    color: var(--bm-text-secondary-color);
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
    p {
      margin: 0;
    }
  }
  .describe-description {
    overflow: unset;
    display: unset;
    p {
      margin: 0;
    }
  }
  .heading-4-1 {
    margin-top: 0px;
    margin-bottom: 10px;
    font-family: 'Sf pro display', sans-serif;
    color: var(--bm-text-title-color);
    font-size: 22px;
    font-weight: 600;
    text-align: left;
    overflow: hidden;
    display: -webkit-box;
  }
  .overlay-nav {
    padding-left: unset;
  }
  .item-wrapper  {
    background-color: var(--bm-light-background-color);
  }
  .bm-icon {
    padding: 0;
  }
  .bm-c-menu-list {
    background-color: unset;
    .menu-panel {
      padding: 0;
    }
  }
  .w-container-container.eshop {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  .w-container-container {
    position: relative;
    display: block;
    overflow: visible;
    margin-left: auto;
    margin-right: auto;
    max-width: 940px;
  }

  .item-wrapper {
    display: block;
    border-radius: 8px;
    background-color: var(--bm-light-background-color);
  }
  .item-wrapper-eshop {
    background-color: var(--bm-light-background-color);
    .am-list-item {
      background-color: unset;
    }
    .am-list-item .am-input-control input {
      font-size: 18px;
      color: var(--bm-text-title-color);
    }
    .q-flex-cart {
      display: flex;
      width: auto;
      height: auto;
      min-width: auto;
      margin-right: 0;
      justify-content: flex-end;
      align-items: center;
      flex: 0 0 auto;
      cursor: pointer;
    }
    svg {
      width: 100%;
      height: 100%;
    }
    .q-inc-cart,
    .q-dec-cart {
      display: flex;
      width: 30px;
      height: 30px;
      margin-right: 10px;
      justify-content: center;
      align-items: center;
      color: var(--bm-primary-color-t30);
    }
    .count {
      display: flex;
      width: 50px;
      height: 45px;
      margin-bottom: 0;
      padding-right: 0;
      justify-content: center;
      align-items: center;
      border-width: 0;
      background-color: transparent;
      font-family: 'Sf pro display', sans-serif;
      font-size: 18px;
      font-weight: 600;
      text-align: center;
    }
    .am-list-item.am-input-item {
      padding: 0;
      border: hidden;
    }
    .am-list-line {
      background-color: unset;
      align-items: center;
    }
    .am-list-item .am-list-line {
      padding: 0;
    }
    .am-list-item .am-list-line {
      position: relative;
      display: flex;
      flex: 1 1;
    }
    .am-list-line {
      .am-input-control {
        input {
          text-align: center;
        }
      }
    } 
    .q-inc-cart {
      color: var(--bm-primary-color);
      margin-right: 0;
    }
    .item-info-wrapper-eshop.sold-out {
      opacity: .6;
    }
  }
  .give-space {
    padding-top: 87px;
  }
  .submenus-title {
    font-size: 18px;
    margin-bottom: 8px;
    color: #999;
  }
  .item-info {
    width: 100%;
  }
  .div-block-89 {
    position: -webkit-sticky;
    position: fixed;
    left: 0;
    top: auto;
    right: 0;
    bottom: 0;
    padding: 16PX;
    padding: 16PX 16PX calc(var(--bm-safe-area-top) + 20PX);
    -webkit-box-shadow: 1PX 1PX 3PX 0 #aaa;
    box-shadow: 1PX 1PX 3PX 0 #aaa;
  }

  
  @media screen and (max-width: 991px) {
    .container-stretched_1 {
      margin-right: 30px;
      margin-left: 30px;
    }
    .submenus {
      width: auto;
      min-width: auto;
      margin-bottom: 20px;
    }
    .div-block-527,
    .item-img-wrapper {
      height: 140px;
    }
    .item-info-wrapper-eshop {
      margin-right: 10px;
      margin-bottom: 10px;
      margin-left: 10px;
    }
    .price-eshop.bottom {
      margin-top: 10px;
    }
    .text-block-6 {
      font-size: 14px;
    }
    .describe {
      font-size: 16PX;
    }
    .w-container-container {
      max-width: 728px;
    } 
    .item-wrapper-eshop {
      .item-wrapper {
        .q-inc-cart,
        .q-dec-cart {
          width: 40px;
          height: 40px;
        }
        .bm-c-listings-panel .count {
          font-size: 14px;
        }
        .am-list-line {
          .am-input-control {
            input {
              font-size: 15px;
            }
          }
        } 
        .q-dec-cart.checkout-process,
        .q-inc-cart.checkout-process {
          width: 30px;
          height: 30px;
        }
      }
      .price-eshop.bottom {
        height: 30px;
      }
    }
    .text-block-6 {
      height: 30px;
      /* line-height: 30px; */
    } 
    .heading-4-1 {
      margin-bottom: 10px;
      font-size: 16px;
      line-height: 16px;
      height: 16px;
    }
  }

  @media screen and (max-width: 767px) {
    .submenus-title {
      font-size: 16px;
    }
    .nav_bg.streched {
      max-height: none;
      max-width: none;
      min-height: 60px;
    }
    .nav_bg.streched.colored {
      top: calc(var(--bm-safe-area-top) + 60PX);
    }
    .container-stretched_1 {
      z-index: 800;
      max-height: 60px;
      min-height: 60px;
    }
    .left {
      width: 30PX;
      height: 19PX;
      color: var(--bm-light-background-color);
    }
    .overlay-nav {
      width: 100vw;
      max-width: none;
      min-width: auto;
      padding-right: 25px;
      padding-left: 25px;
    }
    .give-space {
      padding-top: 74px;
    }
    .submenus  {
      margin-right: 0px;
      padding-right: 30px;
      padding-left: 30px;
    }
    .item-info {
      max-width: unset;
    }
    .describe {
      font-size: 15PX;
    }
    .item-wrapper {
      width: 100%;
    }
    .w-container-container.eshop {
      -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
    }
    .q-flex-cart {
      -webkit-box-flex: 0;
      -webkit-flex: 0 auto;
      -ms-flex: 0 auto;
      flex: 0 auto;
    }
    .item-wrapper-eshop { 
      .am-list-item .am-input-control input {
        font-size: 15px;
      }
    }
    .overlay-nav {
      padding-left: unset;
    }
    .heading-4-1 {
      margin-bottom: 10px;
      font-size: 18px;
      height: 18px;
      line-height: 18px;
    }
  }
  @media screen and (max-width: 479px) {
    .submenus-title {
      font-size: 14px;
    }
    .item-wrapper-eshop {
      padding-bottom: 12px;
    }
    .heading-4-1 {
      margin-top: 0px;
      margin-bottom: 10px;
      font-size: 16px;
      line-height: 30px;
      height: 30px;
    }
    .nav_bg.streched {
      max-height: 50px;
      min-height: 50px;
    }
    .nav_bg.streched.colored {
      position: fixed;
      top: calc(var(--bm-safe-area-top) + 50PX);
    }
    .container-stretched_1 {
      max-height: 50px;
      min-height: 50px;
      margin-right: 16px;
      margin-left: 17px;
    }
    .div-block-7.open-modal {
      position: relative;
      top: 0px;
      z-index: 90000000;
      margin-left: 0px;
      -webkit-box-flex: 0;
      -webkit-flex: 0 auto;
      -ms-flex: 0 auto;
      flex: 0 auto;
    }
    .overlay-nav {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: auto;
      max-height: none;
      min-height: auto;
      padding: 0px;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .give-space {
      padding-top: 56px;
    }
    .submenus  {
      padding-right: 12px;
      padding-left: 12px;
    }
    .div-block-527,
    .item-img-wrapper {
      height: 120px;
    }
    .item-info-wrapper-eshop {
      max-width: 100%;
      min-width: auto;
    }
    .item-info {
      overflow: visible;
      max-height: none;
      max-width: 100%;
    }
    .price-eshop.bottom {
      min-width: 100%;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
    .text-block-6 {
      -webkit-align-self: flex-end;
      -ms-flex-item-align: end;
      align-self: flex-end;
      font-family: 'Sf pro display', sans-serif;
      font-size: 14px;
      font-weight: 600;
      height: 30px;
      line-height: 30px;
    }
    #shopping-cart-bar {
      .text-block-6 {
        -webkit-align-self: unset;
        -ms-flex-item-align: unset;
        align-self: unset;
        font-size: 14px;
        font-weight: 600;
        line-height: 50px;
      }
    }
    .describe {
      font-size: 14PX;
    }
    .item-wrapper-eshop {
      .am-list-item .am-input-control input {
        font-size: 14px;
      }
      .price-eshop.bottom {
        height: 25px;
      }
      .q-flex-cart {
        margin-right: 0;
      }
      .q-inc-cart.checkout-process,
      .q-dec-cart.checkout-process {
        width: 25px;
        height: 25px;
        margin-right: 0;
        margin-left: 0;
      }
      .q-inc-cart {
        width: 32px;
        height: 32px;
        margin-right: 5px;
      }
      .count {
        width: 22px;
        padding-right: 0;
        padding-left: 0;
        font-size: 14px;
        height: 30px;
      }
      .am-list-line {
        .am-input-control {
          input {
            font-size: 14px;
          }
        }
      }
    }
  }
`
;
export const TemporaryStyled = styled.div`
  .side-bar-mask {
    position: fixed;
    z-index: 9999999;
  }
  .side-bar-content {
    position: fixed;
    z-index: 9999999;
  }
`;
