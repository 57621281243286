import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Slider from 'antd-mobile/es/slider';
import { IReducersState } from '@/reducers';
import Icon, { IconType } from '../Icon';
import { IDispatch } from '@/actions';
import StoreFilterModalContent from './StoreFilterModalContent';
import ActionType from '@/actions/action-type';
import { StoreFilterModalContentType } from '@/actions/store-action';
import SpinnerLoading from '@/components/common/loading-spinner';
import { prefix } from '.';
import { IObject } from '../bm-common';
import { getIntl } from '../App/App';
import { langFormat } from '@/utils/app';

interface IStoreFilterModalProps {
  visible: boolean;
  confirmStoreFilter: () => void;
  initData?: IObject;
  onTypeClick?: (type: string) => void;
  className?: string;
  onCancel?: () => void;
}

const StoreFilter: React.FC<IStoreFilterModalProps> = props => {

  const {
    className = '',
    visible = false,
    onCancel = () => {},
    confirmStoreFilter = () => {},
    onTypeClick,
    initData,
  } = props;

  const dispatch = useDispatch<IDispatch>();
  const reducersState: IReducersState = useSelector<IReducersState, IReducersState>(state => state);
  const [first, setFirst] = useState(false);
  const { platformStoreBrands, platformConfig, platformStoreLocations } = reducersState.app;
  const { validServiceType = [], filters } = platformConfig;
  const decimalPoint = [
    [],
    [1, 100],
    [101, 200],
    [201, 500],
  ];

  const [hiddenType, sethiddenType] = useState<boolean>(false);
  // const [queryStoreTotalDelay, setQueryStoreTotalDelay] = useState<number>(0);
  const [storesTotal, setStoresTotal] = useState<number | null>(null);
  const [storesTotalQuerying, setStoresTotalQuerying] = useState<boolean>(false);

  useEffect(() => {
    if (initData && initData[StoreFilterModalContentType.TYPE]) {
      const initType = initData[StoreFilterModalContentType.TYPE];
      if (initType === 'food_ordering') {
        dispatch({
          type: ActionType.SET_SELECTED_SERVICE_TYPE,
          selectedServiceType: initType,
        });
        sethiddenType(true);
        // sethiddenBrands(true);
      } else if (initType === 'booking') {
        dispatch({
          type: ActionType.SET_SELECTED_SERVICE_TYPE,
          selectedServiceType: initType,
        });
        sethiddenType(true);
        // sethiddenBrands(true);
      } else if (initType === 'queueing') {
        dispatch({
          type: ActionType.SET_SELECTED_SERVICE_TYPE,
          selectedServiceType: initType,
        });
        sethiddenType(true);
        // sethiddenBrands(true);
      } else if (initType === 'brands' && !first) {
        dispatch({
          type: ActionType.SET_SELECTED_SERVICE_TYPE,
          selectedServiceType: 'browse',
        });
        // sethiddenCategory(true);
      }
    }
  }, [initData]);

  useEffect(() => {
    setInitFilterData();
  }, [initData && initData.categoryID]);

  useEffect(() => {
    return () => {
      dispatch({
        type: ActionType.SET_STORES_FILTER,
        params: [
          {
            category: new Set(),
            brands: new Set(),
            prices: [],
            radius: 0,
            district: new Set(),
          }
        ]
      })
    }
  }, []);

  const setStoreFilter = (params: any) => {
    dispatch({
      type: ActionType.SET_STORES_FILTER,
      params: [
        {
          ...params,
        }
      ],
    });
    handleQueryStoreTotal();
  }

  const setInitFilterData = () => {
    const tempList: string[] = [];

    if (initData && initData.categoryID) {
      tempList.push(initData.categoryID);
    }

    if (tempList.length < 1) return;

    if (initData && initData[StoreFilterModalContentType.TYPE]) {
      const initType = initData[StoreFilterModalContentType.TYPE];
      if (initType === 'brands') {
        dispatch({
          type: ActionType.SET_STORES_FILTER,
          params: [
            {
              brands: new Set(tempList),
              // category: new Set(tempList),
            }
          ]
        })
      } else if (
        initType === 'food_ordering' ||
        initType === 'booking' ||
        initType === 'queueing'
      ) {
        dispatch({
          type: ActionType.SET_STORES_FILTER,
          params: [
            {
              // brands: new Set(tempList),
              category: new Set(tempList),
            }
          ]
        })
      }
    }
  }

  const confirmButton = () => {
    confirmStoreFilter();
    onCancel();
  }

  const handleQueryStoreTotal = () => {
    setStoresTotalQuerying(true);
    // const nowDate = new Date().getTime();
    // if (nowDate - queryStoreTotalDelay < delay) return;
    // setQueryStoreTotalDelay(nowDate);
    dispatch({
      type: ActionType.QUERY_STORES_TOTAL_BY_FILTER,
      page: 1,
      callback: total => {
        setStoresTotal(total);
        setStoresTotalQuerying(false);
      }
    });
  }

  return (
    <div className={`${className} modal-wrapper-2  ${visible ? 'show-filter-modal' : ''}`}>
      <div className="c-popup-close close-modal" onClick={() => onCancel()}></div>
      <div className="flex-pop-up widen">
        <>
          <div className="top-header widen">
            <h2 className="heading-2">{getIntl().page.filters}</h2>
            <Icon className="x-icon close-modal" type={IconType.CLOSE_OUTLINED} onClick={() => onCancel()}/>
          </div>
          <div className="popup-body-content">
            {
              filters.includes(StoreFilterModalContentType.TYPE) && !hiddenType &&
              <StoreFilterModalContent
                title={getIntl().common.type}
                reducersState={reducersState}
                data={validServiceType}
                type={StoreFilterModalContentType.TYPE}
                setStoreFilter={setStoreFilter}
                onTypeClick={onTypeClick}
                onClick={() => setFirst(true)}
              />
            }
            {
              filters.includes(StoreFilterModalContentType.CATEGORY) &&
              // !hiddenCategory &&
              <StoreFilterModalContent
                title={getIntl().page.categoryTitle}
                reducersState={reducersState}
                data={platformStoreBrands.filter(item => item.brandsCategory === '1')}
                type={StoreFilterModalContentType.CATEGORY}
                setStoreFilter={setStoreFilter}
              />
            }
            {
              filters.includes(StoreFilterModalContentType.BRANDS) &&
              // !hiddenBrands &&
              <StoreFilterModalContent
                title={getIntl().page.brands}
                reducersState={reducersState}
                data={platformStoreBrands.filter(item => item.brandsCategory === '2')}
                type={StoreFilterModalContentType.BRANDS}
                setStoreFilter={setStoreFilter}
              />
            }
            {
              filters.includes(StoreFilterModalContentType.DISTRICT) &&
              <StoreFilterModalContent
                title={getIntl().page.district}
                reducersState={reducersState}
                data={platformStoreLocations}
                type={StoreFilterModalContentType.DISTRICT}
                setStoreFilter={setStoreFilter}
              />
            }
            {
              filters.includes(StoreFilterModalContentType.DISTANCE) &&
              <div
                className={`${prefix}-content`}
              >
                <h4 className="heading-4-1">
                  {getIntl().page.distance}
                </h4>
                <div className={`${prefix}-content-slider`}>
                  <Slider
                    max={5}
                    marks={{
                      0: '0km',
                      1: '1km',
                      2: '2km',
                      3: '3km',
                      4: '4km',
                      5: '5km'
                    }}
                    handleStyle={{display: 'none'}}
                    onChange={data => {
                      setStoreFilter({
                        'radius': data,
                      })
                    }}
                  />
                </div>
              </div>
            }
            {
              filters.includes(StoreFilterModalContentType.PRICE) &&
              <StoreFilterModalContent
                className={`${prefix}-content-price`}
                title={getIntl().page.price}
                reducersState={reducersState}
                data={decimalPoint}
                type={StoreFilterModalContentType.PRICE}
                setStoreFilter={setStoreFilter}
              />
            }
          </div>
          <div className="submir-button close-modal widen" onClick={confirmButton}>
            {
              storesTotal === null &&
              getIntl().common.confirm
            }
            {
              storesTotal !== null &&
              <>
                {langFormat(getIntl().page.showStoreTotal, { total: storesTotal })}
                {
                  storesTotalQuerying &&
                  <SpinnerLoading className="spinner-loading"/>
                }
              </>
            }
          </div>
        </>
      </div>
    </div>
  )
}

const MemoStoreFilterModal = React.memo(StoreFilter, (preProps, nextProps) => {
  const {
    initData: preData = {},
  }= preProps;

  const {
    initData: nextData = {},
  } = nextProps;

  if (preData[StoreFilterModalContentType.TYPE] !== nextData[StoreFilterModalContentType.TYPE]) {

  }
  return false;
});

export default MemoStoreFilterModal;
