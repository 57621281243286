import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { IDispatch } from '@/actions';
import Icon, { IconType } from '@/components/Icon';
import { appleSignInSDK } from '@/utils/appleLogin'
import {prefix} from '.';
import ActionType from '@/actions/action-type';
import { IObject } from '@/components/bm-common';
import { getGalaxyMe } from '@/data/sign';
import Constants from '@/constants';
import { setAccessToken } from '@/utils/app';
import { getIntl } from '@/components/App/App';

interface IAppleSignInProps {
  className?: string;
  signInClick?: () => void;
}

const AppleSignIn: React.FC<IAppleSignInProps> = props => {

  const dispatch = useDispatch<IDispatch>();

  const {
    className = '',
    signInClick = () => {},
  } = props;

  useEffect(() => {
    try {
      const result: IObject = {};
      const searchString = window.location.search.replace('?', ''); // 'user=xxxxxx'
      const searchList = searchString.split('=') || [];
      result[searchList[0]] = searchList[1];
      handleAppleSignIn(result);
    } catch (e) {}
  }, []);

  const handleClick = () => {
    appleSignInSDK();
  }

  const handleAppleSignIn = async (params: IObject) => {
    const {
      access_token: accessToken,
    } = params || {};

    if (accessToken) {
      dispatch({
        type: ActionType.LOADING,
        showLoading: true,
      });


      setAccessToken(accessToken);

      const userInfo = await getGalaxyMe();
      if (userInfo) {
        userInfo.avatarUrl = (userInfo.avatarUrl).replace(/\s+/g, '%20');
        localStorage.setItem(Constants.USER_INFO, JSON.stringify(userInfo));
        localStorage.setItem(Constants.IS_GUEST, 'false');

        dispatch({
          type: ActionType.SET_USER_INFO,
          userInfo,
        });
      }

      dispatch({
        type: ActionType.LOADING,
        showLoading: false,
      });
      signInClick();
      dispatch({
        type: ActionType.LOADING,
        showLoading: false,
      });
    }
  }

  return (
    <div className={`${prefix}-apple-button ${className}`} onClick={handleClick}>
      <Icon
        type={IconType.APPLE_OUTLINED}
        className={`${prefix}-apple-button-icon`}
      />
      <span>
        {getIntl().page.appleSignin}
      </span>
    </div>
  )
}
export default AppleSignIn;
