import React from 'react';
import dayjs from 'dayjs'
import { BigNumber } from 'bignumber.js';
import { IPoint, IReward } from '@/actions/point-action';
import { ICustomerGrowth } from '@/actions/user-action';
// import CurrencyKV from '@/components/Payment/CurrencyKV';
import Icon, { IconType } from '@/components/Icon';
import { IPlatformStoreMembership } from '@/actions/store-action';
import { getI18nText, langFormat } from '@/utils/app';
import { IPlatformCustomPage } from '@/actions/app-action';
import { IObject } from '@/components/bm-common';
import { IReducersState } from '@/reducers';
import { getIntl } from '@/components/App/App';

interface IUsePointsProps {
  membershipData: ICustomerGrowth | undefined;
  rewards: IReward[];
  points: IPoint[] | undefined;
  currencyCode: string;
  reward: IReward | undefined;
  handleClick: (amount: IReward) => void;
  handlePayPoint: () => void;
  platformStoreMembership: IPlatformStoreMembership[];
  language: string;
  abbreviation: string;
  records: IPlatformCustomPage[];
  reducersState: IReducersState;
}

const UsePoints: React.FC<IUsePointsProps> = props => {
  const {
    membershipData,
    rewards,
    // currencyCode = 'HKD',
    reward,
    handleClick,
    points,
    handlePayPoint,
    platformStoreMembership,
    language,
    abbreviation,
    records,
    reducersState,
  } = props;

  return (
    <div className="tab-pane w-tab-pane">
      <div className="section points-redeem wf-section">
        {
          membershipData &&
          <div className="div-block-537 with-space">
            <div className="div-block-594">
              <h2 className="h2 black regular">{abbreviation} {getIntl().page.dollarsBalance}</h2>
              <div className="text-block-102 regular">{new BigNumber(membershipData.storeCredits).toFormat()}</div>
            </div>
            <div className="div-block-594">
              <div className="text-block-91">{getIntl().page.currentAmount}</div>
              <div className="text-block-91">{new BigNumber(membershipData.myLoyaltyStamp).toFormat()}</div>
            </div>
          </div>
        }
        <div className="div-block-537 with-space">
          {
            records && records.length > 0 && records.map((item: IObject) => {
              if ((item.content && item.content.length > 0) || ( item.i18n && JSON.stringify(item.i18n) !== '{}')) {
                return (
                  <>
                    <div style={{width: '100%'}} className="reward-p"  dangerouslySetInnerHTML={{
                      __html: getI18nText(item, 'content', reducersState.app.language) || (item.content) || '',
                    }} />
                    <div className="div-block-594">
                      <h2 className="h2 black regular">{`${abbreviation} Dollar`}</h2>
                    </div>
                  </>
                )
              }else {
                return ''
              }
            })
          }
        </div>
        
        {
          (!records || records.length === 0) &&
          <>
            <div className="div-block-537 with-space">
              <div className="div-block-594">
                <h2 className="h2 black regular">{getIntl().page.rewardStatus}</h2>
              </div>
              <div className="reward-content">
                <div className="reward-content-item">
                  <div className="reward-content-item-title">
                    {getIntl().page.members}
                  </div>
                  {
                    platformStoreMembership.length > 0 && platformStoreMembership.map((item, index) => (
                      <div key={item.id} className="reward-item">
                        {abbreviation} {getI18nText(platformStoreMembership[index], 'abbreviation', language)}
                      </div>
                    ))
                  }
                </div>
                <div className="reward-content-item">
                  <div className="reward-content-item-title">
                    {getIntl().page.everySpending}
                  </div>
                  {
                    ['HK$20', 'HK$20', 'HK$20'].map((item, index) => (
                      <div key={`${item+index}`} className="reward-item">
                        {item}
                      </div>
                    ))
                  }
                </div>
                <div className="reward-content-item">
                  <div className="reward-content-item-title">
                    {getIntl().page.point}
                  </div>
                  {
                    [1, 2, 3].map(item => (
                      <div key={item} className="reward-item">
                        {item}
                      </div>
                    ))
                  }
                </div>
              </div>
              <div className="text-block-91 with-margin">{langFormat(getIntl().page.pointsEqDollar, { dollar: `${abbreviation} Dollar` })}</div>
              <div className="text-block-91">{langFormat(getIntl().page.dollarEqPoints, { dollar: `${abbreviation} Dollar` })}</div>
            </div>
            <div className="div-block-537 with-space">
              <div className="div-block-594">
                <h2 className="h2 black regular">{getIntl().page.Reward}</h2>
              </div>
              <div className="text-block-91 with-margin">{getIntl().page.enterPoint}</div>
              <div className="text-block-91 with-margin">{langFormat(getIntl().page.minimumSpending, { dollar: `${abbreviation} Dollar` })}</div>
              <div className="div-block-594">
                <h2 className="h2 black regular">{`${abbreviation} Dollar`}</h2>
              </div>
            </div>
          </>
        }
        <div className="div-block-537 with-space">
          {
            membershipData && Number( membershipData.myLoyaltyStamp ) === 0 &&
            <div className="text-block-91 with-margin">{getIntl().page.noPointsAvailable}</div>
          }
          {
            reward &&
            <div className="div-block-595">
              <div className="insert-reward-manually">{reward.loyalty}</div>
            </div>
          }
          <div className="div-block-597">
            {
              rewards.map(item => {
                return (
                  <div className="div-block-596"  key={item.id} onClick={() => handleClick(item)} >
                    <div className="text-block-67 reward">{item.loyalty}</div>
                  </div>
                )
              })
            }  
          </div>
          <div className={`submit-button-modal sticky ${reward && membershipData && Number(membershipData.myLoyaltyStamp) >= reward.loyalty? '' : 'disabled'}`} onClick={() => handlePayPoint()}>
            <div className="text-block-47">{getIntl().page.payPoint}</div>
          </div>
        </div>
      </div>
      {
        points &&
        <div className="section points-redeem wf-section">
          <div className="div-block-537">
            <div className="div-block-594">
              <h2 className="h2 black regular">{getIntl().page.recentAcitivities}</h2>
            </div>
            {
              points.map(item => {
                return (
                  <div className="div-block-545 with-space" key={item.id}>
                    <div className="div-block-598">
                      <Icon type={IconType.DATE_OUTLINED} className="html-embed-54 w-embed" />
                      <div className="text-block-67">{dayjs(item.createdAt).format('DD/MM/YYYY')}</div>
                    </div>
                    <div className="text-block-67">{item.amount > 0 ? '+': ''}{item.amount}&nbsp;{getIntl().page.points}</div>
                  </div>
                )
              })
            }

          </div>
        </div>
      }
    </div>

    
  );
}

export default UsePoints;
