import styled from 'styled-components';
export const  AccountEditSyled= styled.div`
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: scroll;
`;

export const AccountEditPageStyled = styled.div`
  height: 100%;
  .bm-p-account-edit {
    height: 100%;
    background-color: var(--bm-light-background-color);
  }
  .section {
    display: block;
    margin-top: auto;
    margin-bottom: 0px;
    padding: 100px 40px 40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .section.profile {
    padding-top: 40px;
  }
  .w-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 940px;
  }
  .container {
    position: relative;
    display: block;
    overflow: visible;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
  }
  .account-block {
    width: 70%;
    margin-right: auto;
    margin-left: auto;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: var(--bm-text-title-color);
  }
  .date-picker {
    width: 100%;
    >div {
      width: 100%;
      .am-list-extra {
        text-align: inherit;
        font-size: 24px;
      }
    }
  }
  .avatar {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: var(--bm-primary-color-t12);
    background-size: cover;
  }
  .edit-icon-wrap {
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,.5);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .camera-outlined {
    width: 30px;
    height: 30px;
    svg {
      width: 100%;
      height: 100%;
      color: var(--bm-light-background-color);
    }
  }
  .am-image-picker-list {
    padding: 0;
    margin: 0;
    .am-flexbox {
      margin: 0;
      .am-flexbox-item {
        margin: 0;
      }
    }
    .am-image-picker-upload-btn {
      border: none;
      background-color: transparent;
    }
  }
  .am-image-picker-list .am-image-picker-upload-btn::before, 
  .am-image-picker-list .am-image-picker-upload-btn::after {
    background-color: transparent;
  }
  .div-block-555 {
    width: 120px;
    height: 120px;
    margin-right: auto;
    margin-bottom: 60px;
    margin-left: auto;
    border-radius: 50%;
    background-position: 50% 50%, 0px 0px, 50% 50%;
    background-size: 35%, auto, cover;
    background-repeat: no-repeat, repeat, repeat; 
    position: relative;
    background-color: var(--bm-primary-color-t12);
    .default-avatar-outlined {
      display: none;
    }
  }
  .text-block-98 {
    color: var(--bm-text-secondary-color);
    margin-right: auto;
    margin-bottom: 0px;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    font-size: 18px;
    font-weight: 400;
  }
  .account-info {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 30px;
    padding: 20px 0px;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    border-bottom: 1px solid var(--bm-border-color);
    font-size: 16px;
    cursor: text;
    justify-content: space-between;
    .bm-c-user-components-div-block-106 {
      width: 100%;
    }
    .bm-c-user-components-div-block-106 > div {
      width: 100%;
    }
    .text-field {
      border: none !important;
      padding: 0;
    }
    .am-list-item:not(:last-child) .am-list-line::after {
      height: 0;
    }
  }
  .text-block-214 {
    margin-right: 5px;
    font-size: 18px;
    width: 80%;
  }
  .text-block-216 {
    font-size: 16px;
    color: var(--bm-text-title-color);
  }
  .right-icon {
    width: 25px;
    height: 25px;
    color: var(--bm-text-secondary-color);
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .am-list-item {
    .am-list-line{
      padding: 0;
    }
  }
  .am-list-item.am-input-error .am-input-error-extra {
    height: 0;
  }
  .close-outlined {
    width: 30px;
    height: 30px;
  }
  .secondary-color {
    color: var(--bm-text-secondary-color);
  }
  .am-image-picker-list .am-image-picker-item .am-image-picker-item-content {
    border-radius: 50%;
  }
  .am-list-item.am-input-error .am-input-control input {
    color: var(--bm-text-title-color);
  }
  .am-image-picker,
  .am-flexbox.am-flexbox-align-center {
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .am-list-item {
    background-color: transparent;
    border: none;
    color: var(--bm-text-title-color);
    padding: 0;
    width: 100%;
    input {
      width: 100%;
    }
  }
  .am-list-item .am-input-control,
  .am-textarea-control textarea {
    font-size: unset;
  }
  .am-input-control input {
    font-size: 18px;
  }
  .am-input-control input::-webkit-input-placeholder {
    font-size: 18px;
  }

  @media screen and (max-width: 991px) {
    .section {
      margin-top: 0px;
      padding: 84px 35px 30px;
    }
    .w-container {
      max-width: 728px;
    }
    .account-block {
      width: 80%;
    }
    .div-block-555 {
      width: 100px;
      height: 100px;
    }
  }
  @media screen and (max-width: 767px) {
    .section {
      padding: 66px 25px 20px;
    }
    .section.profile {
      padding-top: 89px;
    }
    .account-block {
      width: 100%;
    }
    .div-block-555 {
      margin-bottom: 40px;
    }
    .account-info {
      padding: 15px 0px;
    }
    .text-block-214 {
      font-size: 16px;
    }
    .close-outlined {
      width: 25px;
      height: 30px;
    }
  }
  @media screen and (max-width: 479px) {
  .date-picker {
    >div {
      .am-list-extra {
        font-size: 18px;
      }
    }
  }
    .section {
        padding: 68px 12px 15px;
    }
    .section.profile {
      padding-top: 70px;
    }
    .w-container {
      max-width: none;
    }
    .container {
      margin-top: 0px;
      margin-bottom: 0px;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }
    .text-block-98 {
      font-size: 14px;
    }
    .account-info {
      margin-bottom: 20px;
    }
    .text-block-214 {
      font-size: 16px;
    }
    .text-block-216 {
      font-size: 14px;
    }
    .close-outlined {
      width: 20px;
      height: 20px;
    }
    .div-block-555 {
      width: 70px;
      height: 70px;
      margin-bottom: 20px;
    }
  }

`;
