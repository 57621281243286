import React from 'react';
import dayjs from 'dayjs';
import { Modal, Popover } from 'antd-mobile';
import AntdIcon from 'antd-mobile/es/icon';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import Icon, { IconType } from '@/components/Icon';
import { IObject } from '@/components/bm-common';
import { getAppTheme, setScreenColor, getIntl } from '@/components/App/App';
import Layout from '@/components/Layout';
import { IStore } from '@/actions/store-action';
import { IPurchaseOrderDetail } from '@/actions/purchase-order-action';
import { IReceiveOrder } from '@/actions/good-received-note-actions';
import { formatCurrency } from '@/components/Payment';
import paths from '@/routes/paths';
import BottomBar from '@/components/BottomBar';
import { IShareCartInfo } from '@/utils/share-util';
import OrderEntity from '@/lib/order-builder/models/OrderEntity';
import PurchasableType from '@/lib/order-builder/enums/PurchasableType';
import { prefix } from '.';
import ActionType from '@/actions/action-type';
import { parseRouteParams } from '@/utils';
import ReceiveModal from './ReceiveModal';

export interface IPurchaseOrderDetailsPageProps extends IBasePageProps { }
export interface IPurchaseOrderDetailsPageState {
  store: IStore | undefined;
  purchaseOrderDetail: IPurchaseOrderDetail | undefined;
  showReceive: boolean;
  isGRN: boolean;
  showBottomBar: boolean;
  shareData: IShareCartInfo; //分享的数据
}

class PurchaseOrderDetailsPage extends BasePage<IPurchaseOrderDetailsPageProps, IPurchaseOrderDetailsPageState> {
  routeParams: IObject = {};
  state: IPurchaseOrderDetailsPageState = {
    store: undefined,
    purchaseOrderDetail: undefined,
    showReceive: false,
    isGRN: false,
    showBottomBar: false,
    shareData: {
      data: [],
      storeId: '',
    },
  }

  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.darkBackgroundColor) {
      setScreenColor(appTheme.darkBackgroundColor);
    }

    super.componentDidMount();
    this.openLoading();
    this.getPurchaseOrderDetail();
  }

  handleCancel = () => {
    const { dispatch } = this.props;
    const { purchaseOrderDetail } = this.state;
    if (purchaseOrderDetail) {
      Modal.alert(getIntl().page.alert, getIntl().page.purchaseOrderCancelTip, [
        {
          text: getIntl().common.no,
          onPress: () => {},
        },
        {
          text: getIntl().common.yes,
          onPress: () => {
            dispatch({
              type: ActionType.LOADING,
              showLoading: true,
              showLoadingBgc: true,
            });
            dispatch({
              type: ActionType.CANCEL_PURCHASE_ORDER,
              purchaseOrderDetail: purchaseOrderDetail,
              callback: purchaseOrderDetail => {
                this.closeLoading();
                this.setState({purchaseOrderDetail: purchaseOrderDetail});
              }
            })
          },
        }
      ]);
    }
  }

  getPurchaseOrderDetail = () => {
    const { dispatch } = this.props;
    const routeParams = parseRouteParams(this.props);
    dispatch({
      type: ActionType.QUERY_STORE,
      storeSlug: routeParams.storeSlug,
      callback: (store, storeConfig) => {
        if (store) {
          dispatch({
            type: ActionType.QUERY_PURCHASE_ORDER,
            storeID: store.id,
            storeSlug: store.slug,
            PurchaseOrderID: routeParams.purchaseOrderID,
            callback: purchaseOrderDetail => {
              this.setState({
                store: store,
                purchaseOrderDetail,
              })
              this.closeLoading();
            }
          });
        } else {
          this.closeLoading();
        }
      }
    });
  }

  handleShowReceive = (isGRN: boolean) => {
    this.setState({showReceive: true, isGRN})
  }

  handleCreateRN = (receiveOrder: IReceiveOrder) => {
    const { dispatch } = this.props;
    const { store } = this.state;
    if (store && receiveOrder) {
      this.setState({showReceive: false})
      dispatch({
        type: ActionType.LOADING,
        showLoading: true,
        showLoadingBgc: true,
      });
      dispatch({
        type: ActionType.CREATE_RETURN_NOTE,
        returnNote: receiveOrder,
        storeID: store.id,
        callback: record => {
          if (record && record.id) {
            this.handleGotoRoute({
              path: paths.RETURN_NOTE_DETAIL,
              pathParams: {
                receiveOrderID: record.id,
              },
            });
          }
          this.closeLoading();
        }
      })
    }
  }

  handleCreate = (receiveOrder: IReceiveOrder) => {
    const { dispatch } = this.props;
    const { store } = this.state;
    if (store && receiveOrder) {
      this.setState({showReceive: false})
      dispatch({
        type: ActionType.LOADING,
        showLoading: true,
        showLoadingBgc: true,
      });
      dispatch({
        type: ActionType.CREATE_GOOD_RECEIVED_NOTE,
        goodReceivedNote: receiveOrder,
        storeID: store.id,
        callback: record => {
          if (record && record.id) {
            this.handleGotoRoute({
              path: paths.GOOD_RECEIVED_NOTE_DETAIL,
              pathParams: {
                receiveOrderID: record.id,
              },
            });
          }
          this.closeLoading();
        }
      })
    }
  }

  handleCreateReceive = (receiveOrder: IReceiveOrder) => {
    const { dispatch } = this.props;
    const { store } = this.state;
    if (store && receiveOrder) {
      this.setState({showReceive: false})
      dispatch({
        type: ActionType.LOADING,
        showLoading: true,
        showLoadingBgc: true,
      });
      dispatch({
        type: ActionType.CREATE_AND_RECEIVE_GOOD_RECEIVED_NOTE,
        goodReceivedNote: receiveOrder,
        storeID: store.id,
        callback: record => {
          if (record && record.id) {
            this.handleGotoRoute({
              path: paths.GOOD_RECEIVED_NOTE_DETAIL,
              pathParams: {
                receiveOrderID: record.id,
              },
            });
          }
          this.closeLoading();
        }
      })
    }
  }

  handleShare = () => {
    const { showBottomBar } = this.state;
    this.setState({ showBottomBar: !showBottomBar })
  }

  handleShareVia = () => {
    const { reducersState } = this.props;
    const { store, purchaseOrderDetail } = this.state;
    if (store && purchaseOrderDetail) {
      const { userInfo } = reducersState.user;
      const orderEntity = new OrderEntity(store.id);
      purchaseOrderDetail.lineItems.forEach(item => {
        orderEntity.setCurrencyCode(store.currencyCode);
        orderEntity.addLineItem({
          lineItemInfo: {
            listingID: item.id,
            modifiersInfos: [],
          },
          listing: {
            id: item.id,
            name: item.name,
            imageUrl: item.imageUrl,
            favoriteID: '',
            favoriteTabID: '',
            favoriteSectionID: '',
            purchasableID: item.id,
            purchasableType: PurchasableType.LISTING,
            modifierIDs: [],
            description: '',
            inventoryTags: [],
            price: item.supplierPrice,
            brand: '',
            soldOut: false,
            storeID: store.id,
            storeSlug: store.slug,
            position: 0,
            departmentID: '',
            kitchenDepartmentID: '',
            overwriteLineItemNameEnabled: false,
            productID: '',
            categoryID: '',
            procurementMethod: [],
            modifiers: [],
            i18n: {},
          },
          quantity: item.quantity,
          taxOptions: [],
          roundingOption: {
            taxLineItemLevelRoundingType: 0,
            lineItemRoundingType: 0,
            taxRoundingType: 0,
            taxOrderLevelDecimalPoints: 0,
            discountRoundingType: 0,
            lineItemDecimalPoints: 0,
            taxOrderLevelRoundingType: 0,
            discountDecimalPoints: 0,
            taxLineitemLevelDecimalPoints: 0,
            orderRoundingType: 0,
            orderDecimalPoints: 0,
          }
        })
      })
      
      const numberStr = purchaseOrderDetail.number.indexOf('(') !== -1 ? purchaseOrderDetail.number.split('(')[0] : '';

      this.setState({
        showBottomBar: true,
        shareData: {
          storeId: store.id,
          data: [
            {
              records: orderEntity.lineItems,
              storeName: purchaseOrderDetail.supplierName,
              deliveryDate: purchaseOrderDetail.expectDeliveryDate,
              total: purchaseOrderDetail.total || 0,
              orderTime: purchaseOrderDetail.createAt,
              remark: purchaseOrderDetail.remarks,
            }
          ],
          customer: userInfo ? userInfo.name : '',
          email: userInfo ? userInfo.email : '',
          referenceID: numberStr || '',
        }
      })
    }
    
  }
  
  render() {
    const { reducersState } = this.props;
    const { purchaseOrderDetail, store, showReceive, isGRN, showBottomBar, shareData } = this.state;
    const { userInfo } = reducersState.user;

    return (
      <Layout
        {...this.props}
        className={prefix}
        navIcon={<Icon className="back-icon" type={IconType.LEFT_OUTLINED} />}
        onLeftClick={() => {
          window.scrollTo(0, 0);
          this.handleGoBack({ path: paths.PURCHASE_ORDER, runGoBack: true });
        }}
        navContent={getIntl().page.purchaseOrderDetail}
        navRightContent={
          <Popover
            overlay={
              <div className={prefix}>
                <div className="purchase-order-detail-popover-button" onClick={this.handleCancel}>{getIntl().common.cancel}</div>
                <div className="purchase-order-detail-popover-button" onClick={this.handleShareVia}>{getIntl().page.shareVia}</div>
              </div>
            }
            align={{
              overflow: { adjustY: 0, adjustX: 0 },
            }}
          >
            <AntdIcon type="ellipsis" />
          </Popover>
        }
        bottomNav={
          purchaseOrderDetail && purchaseOrderDetail.state !== 'voided' && purchaseOrderDetail.state !== 'canceled' &&
          <div className="purchase-order-detail-bottom">
            {
              ['partially_fulfilled', 'fulfilled'].includes(purchaseOrderDetail.state) &&
              <div className="purchase-order-detail-button return-button" onClick={() => this.handleShowReceive(false)}>{getIntl().page.return}</div>
            }
            {
              purchaseOrderDetail.state !== 'fulfilled' &&
              <div className={`purchase-order-detail-button receive-button${['partially_fulfilled', 'fulfilled'].includes(purchaseOrderDetail.state) ? ' interval' : ''}`} onClick={() => this.handleShowReceive(true)}>{getIntl().page.receive}</div>
            }
          </div>
        }
      >
        {
          purchaseOrderDetail && store &&
          <div className="purchase-order-detail">
            <div className="purchase-order-detail-style-b-color"></div>
            <div className="purchase-order-detail-section">
              <div className="purchase-order-detail-content">
                <div className="content-panel-wrap">
                  <div className="purchase-order-detail-data">
                    <div className="purchase-order-detail-data-tab">
                      <div className="purchase-order-detail-data-tab-number">{purchaseOrderDetail.number}</div>
                      <div className="purchase-order-detail-data-tab-supplier-name">{purchaseOrderDetail.supplierName}</div>
                      <div className="purchase-order-detail-data-tab-information-line">
                        <div className="purchase-order-detail-data-tab-information-line-title">{getIntl().page.status}</div>
                        {
                          (purchaseOrderDetail.state === 'voided' || purchaseOrderDetail.state === 'canceled') &&
                          <div className="purchase-order-detail-data-tab-information-line-data canceled">{getIntl().common.voided}</div>
                        }
                        {
                          purchaseOrderDetail.state !== 'voided' && purchaseOrderDetail.state !== 'canceled' && purchaseOrderDetail.paymentState === 'paid' &&
                          <div className="purchase-order-detail-data-tab-information-line-data paid">{getIntl().common.paid}</div>
                        }
                        {
                          purchaseOrderDetail.state !== 'voided' && purchaseOrderDetail.state !== 'canceled' && purchaseOrderDetail.paymentState !== 'paid' &&
                          <div className="purchase-order-detail-data-tab-information-line-data deferred">{getIntl().common.deferred}</div>
                        }
                      </div>
                      <div className="purchase-order-detail-data-tab-information-line">
                        <div className="purchase-order-detail-data-tab-information-line-title">{getIntl().page.accountManager}</div>
                        <div className="purchase-order-detail-data-tab-information-line-data">{purchaseOrderDetail.createBy}</div>
                      </div>
                      <div className="purchase-order-detail-data-tab-information-line">
                        <div className="purchase-order-detail-data-tab-information-line-title">{getIntl().page.expectedDeliveryDate}</div>
                        <div className="purchase-order-detail-data-tab-information-line-data">{purchaseOrderDetail.expectDeliveryDate ? dayjs(purchaseOrderDetail.expectDeliveryDate).format('YYYY-MM-DD') : 'N/A'}</div>
                      </div>
                      <div className="purchase-order-detail-data-tab-information-line">
                        <div className="purchase-order-detail-data-tab-information-line-title">{getIntl().page.itemQuantity}</div>
                        <div className="purchase-order-detail-data-tab-information-line-data">{purchaseOrderDetail.itemQuantity}</div>
                      </div>
                      {/* <div className="purchase-order-detail-data-tab-information-line">
                        <div className="purchase-order-detail-data-tab-information-line-title">{getIntl().page.paymentMethod}</div>
                        <div className="purchase-order-detail-data-tab-information-line-data payment-method">{purchaseOrderDetail.paymentMethod ? purchaseOrderDetail.paymentMethod.replace('_', ' ') : 'N/A'}</div>
                      </div> */}
                      <div className="purchase-order-detail-data-tab-information-line">
                        <div className="purchase-order-detail-data-tab-information-line-title">{getIntl().common.subtotal}</div>
                        <div className="purchase-order-detail-data-tab-information-line-data">{formatCurrency(purchaseOrderDetail.subtotal, store.currencyCode)}</div>
                      </div>
                      <div className="purchase-order-detail-data-tab-information-line">
                        <div className="purchase-order-detail-data-tab-information-line-title">{getIntl().common.total}</div>
                        <div className="purchase-order-detail-data-tab-information-line-data">{formatCurrency(purchaseOrderDetail.total, store.currencyCode)}</div>
                      </div>
                      {
                        purchaseOrderDetail && purchaseOrderDetail.state !== 'voided' && purchaseOrderDetail.state !== 'canceled' &&
                        <div className="purchase-order-detail-data-tab-bottom">
                          {
                            ['partially_fulfilled', 'fulfilled'].includes(purchaseOrderDetail.state) &&
                            <div className="purchase-order-detail-button return-button" onClick={() => this.handleShowReceive(false)}>{getIntl().page.return}</div>
                          }
                          {
                            purchaseOrderDetail.state !== 'fulfilled' &&
                            <div className="purchase-order-detail-data-tab-button receive-button" onClick={() => this.handleShowReceive(true)}>{getIntl().page.receive}</div>
                          }
                        </div>
                      }
                    </div>
                  </div>
                </div>

                <div className="purchase-order-detail-line-item-card">
                  {
                    purchaseOrderDetail.lineItems.map(item => {
                      return (
                        <div className="purchase-order-detail-line-item" key={item.id}>
                          <div className="purchase-order-detail-line-item-img" style={{ backgroundImage: `url(${item.imageUrl})`}}></div>
                          <div className="purchase-order-detail-line-item-info">
                            <div className="purchase-order-detail-line-item-info-line">
                              <div className="purchase-order-detail-line-item-info-line-name">{item.name}</div>
                              <div className="purchase-order-detail-line-item-info-line-quantity">{getIntl().page.qty} x{item.quantity}</div>
                            </div>
                            <div className="purchase-order-detail-line-item-info-line">
                              <div className="purchase-order-detail-line-item-info-line-code">{getIntl().page.code}{item.code.join(',')}</div>
                            </div>
                            <div className="purchase-order-detail-line-item-info-line">
                              <div className="purchase-order-detail-line-item-info-line-price">{formatCurrency(item.supplierPrice, store.currencyCode)}{`${item.unit ? `/${item.unit}` : ''}`}</div>
                              <div className="purchase-order-detail-line-item-info-line-total">{formatCurrency(item.total, store.currencyCode)}</div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }
                  <div className="purchase-order-detail-remarks-title">{getIntl().page.remark}</div>
                  <div className="purchase-order-detail-remarks">{purchaseOrderDetail.remarks}</div>
                </div>
              </div>
            </div>
            {
              purchaseOrderDetail && purchaseOrderDetail.state !== 'voided' && purchaseOrderDetail.state !== 'canceled' &&
              <div className="purchase-order-detail-bottom-ipad">
                {
                  ['partially_fulfilled', 'fulfilled'].includes(purchaseOrderDetail.state) &&
                  <div className="purchase-order-detail-button return-button" onClick={() => this.handleShowReceive(false)}>{getIntl().page.return}</div>
                }
                {
                  purchaseOrderDetail.state !== 'fulfilled' &&
                  <div className={`purchase-order-detail-button receive-button${['partially_fulfilled', 'fulfilled'].includes(purchaseOrderDetail.state) ? ' interval' : ''}`} onClick={() => this.handleShowReceive(true)}>{getIntl().page.receive}</div>
                }
              </div>
            }
          </div>
        }
        {
          store && purchaseOrderDetail && userInfo && showReceive &&
          <ReceiveModal
            store={store}
            name={userInfo.name}
            purchaseOrderDetail={purchaseOrderDetail}
            handleCreateReceive={this.handleCreateReceive}
            handleCreate={this.handleCreate}
            handleCreateRN={this.handleCreateRN}
            onCancel={() => this.setState({showReceive: false})}
            isGRN={isGRN}
          />
        }
        {
          showBottomBar &&
          <BottomBar
            showBottomBar={showBottomBar}
            onClick={this.handleShare}
            data={shareData}
            reducersState={reducersState}
            selectStore={store}
          />
        }
      </Layout>
    );
  }
}

export default PurchaseOrderDetailsPage;
