import { takeEvery, put, select, all  } from 'redux-saga/effects';
import Action from '@/actions';
import ActionType from '@/actions/action-type';
import { IReducersState } from '@/reducers';
import {
  IQueryAppConfig,
  IAppSettings,
  IAppTheme,
  ISplash,
  ILoadHomeData,
  IPlatformCustomPage,
} from '@/actions/app-action';
import { PayTypes } from '@/lib/payment';
import { IAppState } from '@/reducers/app-reducer';
import { IObject } from '@/components/bm-common';
import Constants from '@/constants';
import {
  getLocalStorageJson,
  modifyFavicon,
  resizeImage,
  setLocalStorageJson,
} from '@/utils';
import {
  getPlatform,
} from '@/data/StoreSetting';
import cloneDeep from 'lodash/cloneDeep';
import { parseThemeContent, isWeChatMiniProgram, getCoordinates, getAccessToken, getDefaultLanguage } from '@/utils/app';
import { getGalaxyMe } from '@/data/sign';
import {
  IStore,
  IStoreConfig,
  IPlatformConfig,
  IStoreCategory,
  IPlatformStoreMembership,
  IAppIcons,
  IStoreLocation,
} from '@/actions/store-action';
import { IFavoriteTab } from '@/lib/order-builder/utils/api';
import { handleQueryStores, handleQueryGroupStores } from './store-saga';
import HomeType from '@/actions/home-type';
import { handleQueryListings } from './listing-saga';
import {
  parseResStoreConfig,
  createDefaultPlatformConfig,
  parseResStoreCategories,
  parseResStoreMembership,
  parseResAppIcons,
  parseStoreLocation,
  parsePlatformCustomPages,
  parseSplash,
} from '@/utils/store-util';
import TabsOption from '@/actions/tabs-option';
import ShippingMethod from '@/lib/order-builder/enums/ShippingMethod';
import { getSplashStorageData, isSplashInitialized, setSplashInitialized, setSplashStorageData } from '@/utils/splash-util';
import { statusBarHeight, getDeviceID, getDevicePushToken } from '@/components/App/App';
import { getIso2ItemFromCode, IDialCodeGroupItem } from '@/components/DialCodeGroup';

enum ServiceTypeKey {
  BROWSE = 'BROWSE',
  DINE_IN = 'DINE_IN',
  TAKE_AWAY = 'TAKE_AWAY',
  BOOKING = 'BOOKING',
  QUEUEING = 'QUEUEING',
  IS_DELIVERY = 'IS_DELIVERY',
  FOOD_ORDERING = 'FOOD_ORDERING',
};

export const initAppSettings = (): IAppSettings => ({
  id: '',
  name: '',
  domain: '',
  storeID: '',
  storeSlug: '',
  merchantID: '',
  applePayMethodKeys: ['visa', 'mastercard', 'amex'],
  googlePayMethodKeys: ['VISA', 'mastercard', 'AMEX'],
  payMethodKeys: [
    PayTypes.ALIPAY,
    PayTypes.ALIPAYHK,
    PayTypes.APPLE_PAY,
    PayTypes.VISA,
    PayTypes.MASTER,
    PayTypes.TAP_GO,
    PayTypes.STORE_CREDIT,
    PayTypes.AMEX,
    PayTypes.ANONYMOUS_GIFT_CARD,
  ],
  deferredInvoicesPayMethodKeys: [],
  privacyStatement: '',
  generalConditions: '',
  faviconUrl: '',
  splashTime: 0,
  firstScreenMenuLayout: 'MENU_LIST',
  serviceTypes: [],
  eshopStoreID: '',
  eshopStoreSlug: '',
  supportStoreCategory: false,
  safetyVerification: false,
  suppliers: false,
  selectPageBackground: '',
  enableCvvAuthentication: false,
  selectPageLogin: '',
  showSelectionPage: false,
  loginInLogo: '',
  i18n: {},
  orderStyle: '0',
  registeredErrorTips: '',
  loginErrorTips: '',
});
const initAreaCodeDatas = (): IDialCodeGroupItem => ({
  iso2: '',
  dialCode: '',
  priority: 0,
  areaCodes: null,
  name: '',
});

const paymentMethodsKV: IObject = {
  VISA: 'visa',
  MASTER: 'mastercard',
  AMEX: 'amex',
  DISCOVER: 'discover',
  JCB: 'jcb',
  UNION_PAY: 'chinaUnionPay',
}

const googlePayPaymentMethodsKV: IObject = {
  VISA: 'VISA',
  MASTER: 'MASTERCARD',
  AMEX: 'AMEX',
  DISCOVER: 'DISCOVER',
  JCB: 'JCB',
}

const resetTabsOption = (resTabs: TabsOption[], option: TabsOption, platformConfig: IPlatformConfig, tabKey: string) => {
  if (
    Array.isArray(resTabs)
    && option
    && platformConfig
    && resTabs.includes(option)
    && tabKey
    && platformConfig.tabsOptions.length < 6
  ) {
    platformConfig[tabKey] = true;
    platformConfig.tabsOptions.push(option);
  }
}

const parseAppSettings = (settingData: any, platformConfig?: IPlatformConfig) => {
  const appSettings: IAppSettings = initAppSettings();

  if (settingData) {
    appSettings.id = settingData.id;
    // 用于支付Apple Pay label
    appSettings.name = settingData.name || '';
    appSettings.domain = settingData.domain;
    // TODO 调试好后，使用真实数据
    // appSettings.storeID = settingData.store_id || '';
    appSettings.storeID = settingData.store_id;
    appSettings.storeSlug = settingData.__store_slug || settingData.store_slug || '';
    appSettings.theme = settingData.theme_id || '';
    appSettings.merchantID = settingData.merchant_id || '';
    appSettings.privacyStatement = settingData.privacy_statement || '';
    appSettings.generalConditions = settingData.general_conditions || '';
    appSettings.faviconUrl = resizeImage({
      url: settingData.icons_favicon || '',
      type: 'DEFAULT',
    });
    appSettings.splashTime = settingData.splash_time || 0;
    appSettings.firstScreenMenuLayout = settingData.enter_screen || 'MENU_LIST';
    appSettings.eshopStoreID = settingData.eshop || '';
    appSettings.eshopStoreSlug = settingData.eshop_store_slug || '';
    appSettings.supportStoreCategory = settingData.store_category || false;
    appSettings.safetyVerification = settingData.safety_verification || false;
    appSettings.safetyVerificationAmount = settingData.safety_verification_amount;
    appSettings.suppliers = settingData.suppliers || false;
    appSettings.selectPageBackground = settingData.choose_background || '';
    appSettings.enableCvvAuthentication = settingData.enable_cvv_authentication || false;
    appSettings.selectPageLogin = settingData.choose_logo || '';
    appSettings.showSelectionPage = settingData.show_selection_page || false;
    appSettings.loginInLogo = resizeImage({
      url: settingData.login_logo,
      height: 360,
      type: 'HEIGHT'
    });

    appSettings.i18n = settingData.__i18n;
    appSettings.orderStyle = settingData.order_style || '0';

    const paymentMethods: string[] = settingData.payment_methods || [];
    const deferredInvoicesPaymentMethods: string[] = settingData.deferred_invoices_payment_methods || [];
    const applePayMethodKeys: string[] = [];
    const googlePayMethodKeys: string[] = [];
    const payMethodKeys: PayTypes[] = [];
    const deferredInvoicesPayMethodKeys: PayTypes[] = [];
    if (Array.isArray(paymentMethods)) {
      paymentMethods.forEach((pmItem: any) => {
        if (pmItem) {
          payMethodKeys.push(pmItem);
        }
        if (paymentMethodsKV[pmItem]) {
          applePayMethodKeys.push(paymentMethodsKV[pmItem]);
        }
        if (googlePayPaymentMethodsKV[pmItem]) {
          googlePayMethodKeys.push(googlePayPaymentMethodsKV[pmItem]);
        }
      })
    }

    if(Array.isArray(deferredInvoicesPaymentMethods)) {
      deferredInvoicesPaymentMethods.forEach((pmItem: any) => {
        if (pmItem) {
          deferredInvoicesPayMethodKeys.push(pmItem);
        }
      })
    }

    if (applePayMethodKeys.length > 0) {
      appSettings.applePayMethodKeys = applePayMethodKeys;
    }

    if (googlePayMethodKeys.length > 0) {
      appSettings.googlePayMethodKeys = googlePayMethodKeys;
    }

    if (payMethodKeys.length > 0) {
      appSettings.payMethodKeys = payMethodKeys;
    }

    if (deferredInvoicesPayMethodKeys.length > 0) {
      appSettings.deferredInvoicesPayMethodKeys = deferredInvoicesPayMethodKeys;
    }

    if (Array.isArray(settingData.service_type)) {
      appSettings.serviceTypes = settingData.service_type;
    }


    if (platformConfig) {
      let resTabs: TabsOption[] = [];
      if (Array.isArray(settingData.sign_in)) {
        platformConfig.signInMethods = settingData.sign_in
      }

      if (Array.isArray(settingData.sign_up)) {
        platformConfig.signUpMethods = settingData.sign_up
      }

      if (Array.isArray(settingData.account)) {
        platformConfig.accountOptions = settingData.account
      }

      if (Array.isArray(settingData.tab_bar)) {
        resTabs = settingData.tab_bar;
      }

      if (Array.isArray(settingData.sidebar_seting)) {
        platformConfig.sidebarOptions = settingData.sidebar_seting;
      }

      if (Array.isArray(settingData.filter)) {
        platformConfig.filters = settingData.filter;
      }

      if (Array.isArray(settingData.register_content)) {
        platformConfig.registerContent = settingData.register_content;
      }

      if (Array.isArray(settingData.registration_required)) {
        platformConfig.registrationRequired = settingData.registration_required;
      }

      if (Array.isArray(settingData.records)) {
        platformConfig.recordsOptions = settingData.records;
      }

      if (Array.isArray(settingData.loyalty)) {
        platformConfig.loyaltyOptions = settingData.loyalty;
      }

      resetTabsOption(resTabs, TabsOption.STORE, platformConfig, 'showStoreTab');
      resetTabsOption(resTabs, TabsOption.ORDER, platformConfig, 'showOrderTab');
      if (platformConfig.accountOptions.length > 0) {
        resetTabsOption(resTabs, TabsOption.ACCOUNT, platformConfig, 'showAccountTab');
      }
      resetTabsOption(resTabs, TabsOption.NEWS, platformConfig, 'showNewsTab');
      resetTabsOption(resTabs, TabsOption.ABOUT_US, platformConfig, 'showAboutUsTab');

      if (!platformConfig.showStoreTab) {
        resetTabsOption(resTabs, TabsOption.HOME, platformConfig, 'showHomeTab');
      }

      if (!platformConfig.showOrderTab) {
        resetTabsOption(resTabs, TabsOption.RECORDS, platformConfig, 'showRecordsTab');
      }
      resetTabsOption(resTabs, TabsOption.ESHOP, platformConfig, 'showEShopTab');
      if (!platformConfig.showNews) {
        resetTabsOption(resTabs, TabsOption.PROMOTIONS, platformConfig, 'showPromotionsTab');
      }
      resetTabsOption(resTabs, TabsOption.MEMBERS_AREA, platformConfig, 'showMembersAreaTab');
      resetTabsOption(resTabs, TabsOption.MY_ID, platformConfig, 'showMyIDTab');
      resetTabsOption(resTabs, TabsOption.BRANDS, platformConfig, 'showBrandsTab');
      resetTabsOption(resTabs, TabsOption.CATEGORY, platformConfig, 'showCategoryTab');

      platformConfig.showTabs = platformConfig.tabsOptions.length > 1;

      platformConfig.supportStoreCategory = appSettings.supportStoreCategory;

      platformConfig.showSideBar = settingData.sidebar;
      platformConfig.platformLogo = resizeImage({
        url: settingData.platform_logo_1,
        type: 'FILL',
        width: 750,
        height: 480,
      });
      const platformBanner: string[] = settingData.platform_banner || [];
      if (Array.isArray(platformBanner)) {
        const banner: string[] = [];
        platformBanner.forEach(item => {
          banner.push(
            resizeImage({
              url: item,
              type: 'FILL',
              width: 750,
              height: 480,
            })
          )
        });
        platformConfig.platformBanner = banner;
      }
      platformConfig.closeRegisterEmail = settingData.close_register_mail || false;
      platformConfig.bannerCarouselTime = settingData.banner_carousel_time || 0;
      platformConfig.abbreviation = settingData.abbreviation || '';
      platformConfig.birthdayHiddenDayEnable = settingData.birthday_hidden_day_enable || false;
      platformConfig.birthdayHiddenYearEnable = settingData.birthday_hidden_year_enable || false;
      platformConfig.hidePhoneNumberHyperlink = settingData.hide_phone_number_hyperlink || false;
      platformConfig.platformLogoSvg = settingData.platform_logo_svg || '';
      platformConfig.menuDisplayMethod = settingData.menu_display_method || '';
      platformConfig.enableNotes = settingData.enable_notes || false;
      platformConfig.feedbackEnable = settingData.feedback_enable || false;
      platformConfig.colorInversion = settingData.color_inversion || false;
      platformConfig.memberIconStyle = settingData.member_icon_style || 'PROGRESS_BAR';
      platformConfig.ecouponsRedemption = settingData.e_coupons_redemption || 'STORE_CREDIT';
      platformConfig.storeFunctionLabel = settingData.store_function_label;
      platformConfig.showStoreCreditBalance = settingData.show_store_credit_balance || false;
      platformConfig.hideMemberExpiryDate = settingData.hide_member_expiry_date || false;
      platformConfig.areaCodeId = settingData.area_code_id || '';
      platformConfig.areaCodeISO = settingData.iso || '';
    }

  }

  return appSettings;
}

const checkValidServiceType = (platformConfig: IPlatformConfig, platformAppSettings: IAppSettings) => {
  platformConfig.validServiceType = [];
  if (platformAppSettings.serviceTypes.includes('BROWSE')) {
    platformConfig.validServiceType.push('BROWSE'.toLocaleLowerCase());
  }
  platformAppSettings.serviceTypes.forEach(item => {
    if (ServiceTypeKey.DINE_IN === item) {
      if (platformConfig.shippingMethods.includes(ShippingMethod.DINE_IN) || platformConfig.shippingMethods.includes(ShippingMethod.DINE_IN_UNASSIGNED)) {
        platformConfig.validServiceType.push(item.toLocaleLowerCase());
      }
    } else if (ServiceTypeKey.TAKE_AWAY === item) {
      if (platformConfig.shippingMethods.includes(ShippingMethod.PICK_UP)) {
        platformConfig.validServiceType.push(item.toLocaleLowerCase());
      }
    } else if (ServiceTypeKey.BOOKING === item) {
      if (platformConfig.showBooking) {
        platformConfig.validServiceType.push(item.toLocaleLowerCase());
      }
    } else if (ServiceTypeKey.QUEUEING === item) {
      if (platformConfig.showQueueing) {
        platformConfig.validServiceType.push(item.toLocaleLowerCase());
      }
    } else if (ServiceTypeKey.IS_DELIVERY === item) {
      if (platformConfig.shippingMethods.includes(ShippingMethod.DELIVERY)) {
        platformConfig.validServiceType.push(item.toLocaleLowerCase());
      }
    } else if (ServiceTypeKey.FOOD_ORDERING === item) {
      platformConfig.validServiceType.push(item.toLocaleLowerCase());
    }
  });
}

// const appConfigmMaxStorageTime: number = 20 * 60 * 1000;

function* handleQueryAppConfig(params: IQueryAppConfig) {
  const {
    gatewayDomain,
    platformDomain,
    hideSplash,
    callback,
  } = params;
  const appState: IAppState = yield select((state: IReducersState) => state.app);

  let { deviceInfo } = appState;
  let gatewayAppSettings: IAppSettings = appState.gatewayAppSettings || initAppSettings();
  let platformAppSettings: IAppSettings = appState.platformAppSettings || initAppSettings();
  let appTheme: IAppTheme = appState.appTheme || {};
  let lessTheme: IObject = appState.lessTheme || {};
  let splashData: ISplash[] = appState.splashData || [];
  let platformStoreCategories: IStoreCategory[] = [];
  let platformCustomPages: IPlatformCustomPage[] = [];
  let platformStoreBrands: IStoreCategory[] = [];
  let platformStoreMembership: IPlatformStoreMembership[] = [];
  let appIcons: IAppIcons[] = [];
  let selectedStoreServiceType = '';
  let platformStoreLocations: IStoreLocation[] = [];
  const splashStorageData = getSplashStorageData({ deviceInfo, platformDomain });;
  let initializedSplash = false;
  let isGetSplash = true;
  let showSplash = false;
  let areaCodeDatas: IDialCodeGroupItem = appState.areaCodeDatas || initAreaCodeDatas();
  if (isSplashInitialized({ deviceInfo, platformDomain }) || hideSplash) {
    initializedSplash = true;
    setSplashInitialized({ deviceInfo, platformDomain });
  } else if (splashStorageData) {
    isGetSplash = false;
    splashData = splashStorageData.splashData;
    showSplash = splashStorageData.showSplash;
  }

  let platformConfig = createDefaultPlatformConfig();

  const weChatMiniProgram: boolean = yield isWeChatMiniProgram(appState.deviceInfo);

  const platformStorageKey = `${Constants.APP_CONFIG}-${platformDomain}`;

  try {
    if (!gatewayAppSettings.id || !platformAppSettings.id) {
      const appConfig = getLocalStorageJson(platformStorageKey);
      if (
        appConfig && appConfig.platformAppSettings && appConfig.platformAppSettings.id
      ) {
        platformAppSettings = appConfig.platformAppSettings;
        gatewayAppSettings = appConfig.gatewayAppSettings;
        platformConfig = appConfig.platformConfig;
        lessTheme = appConfig.lessTheme;
        appTheme = appConfig.appTheme;
        platformStoreCategories = appConfig.platformStoreCategories || [];
        platformCustomPages = appConfig.platformCustomPages || [];
        platformStoreMembership = appConfig.platformStoreMembership;
        appIcons = appConfig.appIcons;
        platformStoreBrands = appConfig.platformStoreBrands;
        platformStoreLocations = appConfig.platformStoreLocations;
        areaCodeDatas = appConfig.areaCodeDatas;

        setLocalStorageJson(platformStorageKey, {
          ...appConfig,
        });
      }
    }
    const localAppConfigHashData = getLocalStorageJson(`${Constants.APP_CONFIG_HASH_DATA}-${platformDomain}`) || {};
    let isSameDomain = false;
    const allReq: any = {};
    if (isGetSplash && !initializedSplash) {
      allReq['mobile_splash_list'] = {
        'get_enable': true,
        'hash': '',
      };
    }
    allReq['theme'] = {
      'get_enable': true,
      'hash': localAppConfigHashData.theme || '',
    };
    allReq['store_configures'] = {
      'get_enable': true,
      'hash': localAppConfigHashData.store_configures || '',
    };
    allReq['membership_levels'] = {
      'get_enable': true,
      'hash': localAppConfigHashData.membership_levels || '',
    };
    allReq['mobile_icons'] = {
      'get_enable': true,
      'hash': localAppConfigHashData.mobile_icons || '',
    };
    allReq['store_first_categories'] = {
      'get_enable': true,
      'hash': localAppConfigHashData.store_first_categories || '',
    };
    allReq['store_brands'] = {
      'get_enable': true,
      'hash': localAppConfigHashData.store_brands || '',
    };
    allReq['store_locations'] = {
      'get_enable': true,
      'hash': localAppConfigHashData.store_locations || '',
    };
    allReq['customs_pages'] = {
      'get_enable': true,
      'hash': localAppConfigHashData.customs_pages || '',
    };

    const appSettingsReqs: any[] = [];
    let reqIndex = -1;
    let gatewayReqIndex = -1;
    let platformReqIndex = -1;
    if (gatewayDomain === platformDomain) {
      isSameDomain = true;
      gatewayReqIndex = ++reqIndex;
      platformReqIndex = gatewayReqIndex;
      appSettingsReqs.push(getPlatform(gatewayDomain, allReq));
    } else {
      gatewayReqIndex = ++reqIndex;
      appSettingsReqs.push(getPlatform(gatewayDomain));
      platformReqIndex = ++reqIndex;
      if (window.location.hostname === gatewayDomain) { // 本平台到支付网关
        appSettingsReqs.push(getPlatform(platformDomain, {
          theme: {
            'get_enable': true,
            'hash': localAppConfigHashData.theme || '',
          },
        })); // 只需要请求平台信息和主题
      } else {
        appSettingsReqs.push(getPlatform(platformDomain, allReq));
      }
    }
    const token = getAccessToken();
    let meReqIndex = -1;
    /** 如果Token存在，获取用户信息 */
    if (token) {
      meReqIndex = ++reqIndex;
      appSettingsReqs.push(getGalaxyMe());
    }

    const respData = yield all(appSettingsReqs);
    let platformData = undefined;

    if (Array.isArray(respData) && respData.length > 0) {
      const gatewaySetting = respData[gatewayReqIndex].data.platform;
      if (gatewaySetting && gatewaySetting.id) {
        gatewayAppSettings = parseAppSettings(gatewaySetting);
      }
      if (isSameDomain) {
        platformAppSettings = cloneDeep(gatewayAppSettings);
      } else if (respData.length > 1) {
        platformData = respData[platformReqIndex].data;
        const platformSetting = platformData && platformData.platform;
        if (platformSetting && platformSetting.id) {
          platformAppSettings = parseAppSettings(platformSetting, platformConfig);
        }
      }

      if (platformAppSettings.name) {
        document.title = platformAppSettings.name;
      }
      if (!platformData && !isSameDomain) {
        platformAppSettings = initAppSettings();
        platformConfig = createDefaultPlatformConfig();
      }
      if ( respData.length > 0 && respData[platformReqIndex] && platformData &&  platformData.platform) {
        if (platformData.hash_data) {
          setLocalStorageJson(`${Constants.APP_CONFIG_HASH_DATA}-${platformDomain}`, platformData.hash_data);
        }
        const platformSetting = platformData.platform;
        const platform =  { 
          iosMetaAppId: platformSetting.ios_meta_app_id, 
          iosMetaAppClipBundleId: platformSetting.ios_meta_app_clip_bundle_id, 
          mapEnabled: platformSetting.map_enabled, 
          filterEnabled: platformSetting.filter_enabled,
          storeModeEnabled: platformSetting.store_mode_enabled,
          memberMigration: platformSetting.member_migration || false,
          storeSlug: platformSetting.store_slug || platformConfig.storeSlug || '',
          storeID: platformSetting.store_id || platformConfig.storeID || '',
        };
        platformConfig = {
          ...platformConfig,
          ...platform,
        }
      }
    }
    if (respData.length > 0 && platformData) {
      if (localAppConfigHashData.membership_levels !== platformData.hash_data.membership_levels) {
        platformStoreMembership = parseResStoreMembership(platformData['membership_levels']);
      }
  
      if (localAppConfigHashData.mobile_icons !== platformData.hash_data.mobile_icons) {
        appIcons = parseResAppIcons(platformData['mobile_icons']);
      }
  
      if (localAppConfigHashData.theme !==  platformData.hash_data.theme) {
        const newThemeData = parseThemeContent(platformData['theme'], deviceInfo);
        appTheme = newThemeData.appTheme;
        lessTheme = newThemeData.lessTheme;
      }
  
      if (platformData['mobile_splash_list']) {
        splashData = parseSplash(platformData['mobile_splash_list']);
        if (splashData.length > 0) {
          showSplash = true;
        }
  
        setSplashStorageData({ deviceInfo, platformDomain }, { splashData, showSplash });
      }
  
      if (meReqIndex > -1) {
        const userInfo = respData[meReqIndex];
        if (userInfo) {
          userInfo.avatarUrl = (userInfo.avatarUrl).replace(/\s+/g, '%20');
          localStorage.setItem(Constants.USER_INFO, JSON.stringify(userInfo));
          yield put<Action>({
            type: ActionType.SET_USER_INFO,
            userInfo,
          });
        }
      }
  
      if (platformData['store_configures'] && platformData['store_configures'].length > 0 && localAppConfigHashData.store_configures !==  platformData.hash_data.store_configures) {
        const storeConfigRes = platformData['store_configures'][0];
        platformConfig = {
          ...platformConfig,
          ...parseResStoreConfig(storeConfigRes, platformConfig.platformID),
        };
      }
  
      if (localAppConfigHashData.store_first_categories !==  platformData.hash_data.store_first_categories) {
        const storeCategoriesRes =  platformData['store_first_categories'];
        platformStoreCategories = parseResStoreCategories(storeCategoriesRes);
      }
      if (localAppConfigHashData.store_brands !==  platformData.hash_data.store_brands) {
        const storeBrandsRes = platformData['store_brands'];
        platformStoreBrands = parseResStoreCategories(storeBrandsRes);
      }
  
      if (localAppConfigHashData.store_locations !==  platformData.hash_data.store_locations) {
        const storeLocationRes = platformData['store_locations'];
        platformStoreLocations = parseStoreLocation(storeLocationRes);
      }
  
      if (localAppConfigHashData.customs_pages !== platformData.hash_data.customs_pages) {
        const customPagesRes = platformData['customs_pages'];
        platformCustomPages = parsePlatformCustomPages(customPagesRes);
      }

    }
    if (platformConfig.areaCodeISO && platformConfig.areaCodeId) {
      areaCodeDatas = getIso2ItemFromCode(platformConfig.areaCodeISO.toLowerCase(), getDefaultLanguage());
      areaCodeDatas.areaCodes = [platformConfig.areaCodeId];
    }

    checkValidServiceType(platformConfig, platformAppSettings);

    modifyFavicon(platformAppSettings.faviconUrl);

    if (platformConfig.validServiceType.length > 0) {
      selectedStoreServiceType = platformConfig.validServiceType[0];
    }

    if (statusBarHeight) {
      lessTheme = {
        ...lessTheme,
        '@bm-safe-area-top': statusBarHeight
      }
    }
    let devicePushToken = getDevicePushToken;
    let deviceID = getDeviceID;
    const bindoUtils: any = (window as any).bindo_utils;
    if (deviceInfo.isAndroid && bindoUtils && bindoUtils.getPushDeviceToken) {
      devicePushToken = bindoUtils.getPushDeviceToken();
      deviceID = bindoUtils.getDeviceId();
    }
    if ( deviceID && devicePushToken) {
      deviceInfo = {...deviceInfo, deviceID, devicePushToken }
    }
    yield put<Action>({
      type: ActionType.SET_APP_CONFIG,
      gatewayAppSettings,
      platformAppSettings,
      platformConfig,
      lessTheme,
      appTheme,
      splashData,
      showSplash,
      weChatMiniProgram,
      platformStoreCategories,
      platformCustomPages,
      platformStoreBrands,
      selectedStoreServiceType,
      platformStoreMembership,
      appIcons,
      platformStoreLocations,
      deviceInfo,
      areaCodeDatas,
    });

    if (
      gatewayAppSettings && gatewayAppSettings.id
      && platformAppSettings && platformAppSettings.id
    ) {
      setLocalStorageJson(platformStorageKey, {
        time: new Date().getTime(),
        gatewayAppSettings,
        platformAppSettings,
        platformConfig,
        platformStoreCategories,
        platformCustomPages,
        platformStoreBrands,
        lessTheme,
        appTheme,
        platformStoreMembership,
        appIcons,
        platformStoreLocations,
        areaCodeDatas,
      });
    }

    if (platformAppSettings) {
      localStorage.setItem(Constants.PLATFORM_ID, platformAppSettings.id);
    }
  } catch (error) {
    console.error(error);
  }

  // <meta name="apple-itunes-app" content="app-id=1529886767, app-clip-bundle-id=com.bindo.alpha.clip"/>
  const platformConfigData: IPlatformConfig = yield select((state: IReducersState) => state.app.platformConfig);
  const { iosMetaAppClipBundleId,  iosMetaAppId} = platformConfigData;
  if( iosMetaAppClipBundleId && iosMetaAppId) {
    let metaEl = document.querySelector('meta[name="apple-itunes-app"]');
    if (metaEl) {
      metaEl.setAttribute('content', `app-id=${iosMetaAppId}, app-clip-bundle-id=${iosMetaAppClipBundleId}`);
    } else {
      metaEl = document.createElement('meta');
      metaEl.setAttribute('name', 'apple-itunes-app');
      metaEl.setAttribute('content', `app-id=${iosMetaAppId}, app-clip-bundle-id=${iosMetaAppClipBundleId}`);
      const heads = document.getElementsByTagName('head');
      if(heads.length) {
        heads[0].appendChild(metaEl);
      }
    }
  }

  if (callback) {
    callback({
      gatewayAppSettings,
      platformAppSettings,
      appTheme,
      lessTheme,
    });
  }
}

function* handleLoadHomeData(params: ILoadHomeData) {
  const { callback } = params;
  const state: IReducersState = yield select((state: IReducersState) => state);
  const { typeRefStores } = state.store;
  const { selectedStoreServiceType } = state.app;

  let queryStores: IStore[] = [];
  let groupStores: IStore[] = [];
  let homeType = state.app.homeType;
  let menus: IFavoriteTab[] = [];
  let store: IStore | undefined;
  let storeConfig: IStoreConfig | undefined;

  try {
    if (state.app.platformAppSettings && state.app.platformAppSettings.firstScreenMenuLayout === 'BRANDS') {
      homeType = HomeType.BRANDS;
    } else if (state.app.platformAppSettings && state.app.platformAppSettings.firstScreenMenuLayout === 'CATEGORY') {
      homeType = HomeType.CATEGORY;
    } else if (state.app.platformAppSettings && state.app.platformAppSettings.firstScreenMenuLayout === 'MEMBER') {
      homeType = HomeType.MEMBER;
    } else if ((typeRefStores[selectedStoreServiceType] || []).length < 1) {
      queryStores = yield handleQueryStores({
        type: ActionType.QUERY_STORES,
        serviceType: selectedStoreServiceType,
        page: 1,
      });
    } else {
      queryStores = typeRefStores[selectedStoreServiceType];
    }

    if (queryStores.length === 1) {
      let firstStore = queryStores[0];
      if (firstStore.isGroup) {
        groupStores = yield handleQueryGroupStores({
          type: ActionType.QUERY_GROUP_STORES,
          storeSlug: firstStore.slug,
        });

        if (groupStores.length === 1) {
          firstStore = groupStores[0];
          store = firstStore;
        } else if (groupStores.length > 1) {
          homeType = HomeType.STORE_GROUP;
          store = firstStore;
        }
      }

      if (homeType !== HomeType.STORE_GROUP) {
        const menuData = yield handleQueryListings({
          type: ActionType.QUERY_LISTINGS,
          storeSlug: firstStore.slug,
        });

        store = menuData.store;
        menus = menuData.favoriteTabs;
        storeConfig = menuData.storeConfig;

        homeType = HomeType.MENU_LIST;
        if (state.app.platformAppSettings && state.app.platformAppSettings.firstScreenMenuLayout === 'MENU_GRID') {
          homeType = HomeType.MENU_GRID;
        }
      }

      yield put<Action>({
        type: ActionType.SET_HOME_TYPE,
        homeType,
      });
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback({
      homeType,
      store,
      menus,
      groupStores,
      storeConfig,
    })
  }
}

function* handleInitCoordinates() {
  const state: IReducersState = yield select((state: IReducersState) => state);
  let coordinates = state.app.coordinates;
  const { hostname } = window.location;
  try {
    if (!coordinates && hostname !== 'gateway.gobindo.com') {
      coordinates = yield getCoordinates();

      if (coordinates) {
        yield put<Action>({
          type: ActionType.SET_COORDINATES,
          coordinates,
        });
      }
    }
  } catch (error) {
    console.error(error);
  }
}

function* appSaga() {
  yield takeEvery(ActionType.QUERY_APP_CONFIG, handleQueryAppConfig);
  yield takeEvery(ActionType.LOAD_HOME_DATA, handleLoadHomeData);
  yield takeEvery(ActionType.INIT_COORDINATES, handleInitCoordinates);
}

export default appSaga();
