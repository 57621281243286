import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import withRoute from '@/pages/withRoute';
import './BrandsPage.less';
import BrandsPage from './BrandsPage';

export const prefix = 'bm-p-brands';

const RouteComponent: React.FC<RouteComponentProps> = props => withRoute({
  pageComponent: BrandsPage,
  routeProps: props,
  pageTitleI18nKey: '',
});

export default RouteComponent;
