import React from 'react';
import dayjs from 'dayjs';
import { BigNumber } from 'bignumber.js';
import { IPoint } from '@/actions/point-action';
import { langFormat } from '@/utils/app';
import { getIntl, getIntlByPath } from '@/components/App/App';

interface IPointProps {
  points: IPoint[];
  language: string;
  abbreviation: string;
}

const Point: React.FC<IPointProps> = props => {
  let date = '';
  const {
    points,
    language,
    abbreviation,
  } = props

  return (
    <div className="section points">
      {
        points.map(item => {
          if (item.amount !== 0) {
            if (!date || dayjs(item.createdAt).format('YYYY-MM-DD') !== dayjs(date).format('YYYY-MM-DD')) {
              date = item.createdAt;
              return (
                <div  key={item.id}>
                  <div className="div-block-545">
                    <div className="text-block-67">{dayjs(item.createdAt).format(language === 'zh-CN' ? 'YYYY-MM-DD' : 'DD/M/YYYY')}</div>
                    <div className="text-block-69">{langFormat(getIntl().page.pointsCustom, { abbreviation })}</div>
                  </div>
                  <div className="div-block-546">
                    <div className="text-block-67 _1">{langFormat(getIntlByPath(`page.${item.amount > 0 ? 'earnPointsCustom' : 'redemption'}`), { abbreviation })}</div>
                    <div className="text-block-69">{item.amount > 0 ? '+' : ''}{new BigNumber(item.amount).toFormat()}</div>
                  </div>
                </div>
              )
            } else {
              return (
                <div className="div-block-546"  key={item.id}>
                  <div className="text-block-67 _1">{langFormat(getIntlByPath(`page.${item.amount > 0 ? 'earnPointsCustom' : 'redemption'}`), { abbreviation })}</div>
                  <div className="text-block-69">{item.amount > 0 ? '+' : ''}{new BigNumber(item.amount).toFormat()}</div>
                </div>
              )
            }
          } else {
            return <div key={item.id}></div>
          }
        })
      }
    </div>
  );
}

export default Point;
