import { lineToHump } from '@/utils';
import { handleStoreServiceTypesScroll } from '@/utils/app';
import React from 'react';
import { getIntlByPath } from '@/components/App/App';
// import './ServiceTypes.less';

export const prefix = 'bm-c-service-types';

interface IServiceTypesProps {
  data: string[];
  selected: string;
  onItemClick?: (type: string) => void;
}

const ServiceTypes: React.FC<IServiceTypesProps> = props => {
  const {
    data,
    selected,
    onItemClick,
  } = props;

  const handleItemClick = (type: string) => {
    handleStoreServiceTypesScroll(type);
    if (onItemClick) {
      onItemClick(type);
    }
  }

  return (
    <div className="tabs-menu-6 w-tab-menu">
      <div className="tabs-flex">
        {
          data.map(item => (
            <div 
              key={item}
              id={`bm-store-service-types-${item}`}
              className={`tab-2 w-inline-block w-tab-link ${item === selected ? 'selected' : ''}`}
              onClick={() => handleItemClick(item)}
            >
              <div>{getIntlByPath(`page.${lineToHump(item.replace('is_', ''))}`)}</div>
            </div>
          ))
        }
      </div>
    </div>
  );
}

export default ServiceTypes;
