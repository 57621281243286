import React from 'react';

interface IStoreInfoProps {
  url: string;
  type: 'IMAGE' | 'VIDEO';
}

const CarouselItem: React.FC<IStoreInfoProps> = props => {
  const { url, type } = props;

  return (
    <div className="store-img-carousel-item">
      {
        type === 'IMAGE' &&
        <img src={url} alt={url} />
      }
      {
        type === 'VIDEO' &&
        <video autoPlay controls src={url} />
      }
    </div>
  );
}

export default CarouselItem;
