import styled from 'styled-components';
export const PaymentListPageStyled = styled.div`
  width: 85%;
  margin-top: 0;
  padding: 0;
  border-radius: 12px;
  background-color: var(--bm-light-background-color);
  margin-top: 70px;
  .payment-selection {
    .image-200 {
      .right-outlined {
        width: 30PX;
        height: 30PX;
      }
    }
  }
  .side-nav {
    position: relative;
    width: 20%;
    max-width: 20%;
    margin-right: 40px;
    margin-left: 0px;
  }
  .section.payment-cards {
    padding: 0px 80px;
  }
  .section {
    display: block;
    margin-top: auto;
    margin-bottom: 0px;
    padding: 100px 40px 40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .div-block-545 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 0px;
    padding: 20px 15px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-radius: 6px;
    background-color: var(--bm-dark-background-color);
    color: var(--bm-text-title-color);
  }
  .text-block-67 {
    margin-bottom: 0px;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: var(--bm-text-title-color);
    font-size: 18px;
    font-weight: 400;
  }
  .div-block-545 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 0px;
    padding: 20px 15px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-radius: 6px;
    background-color: var(--bm-dark-background-color);
    color: var(--bm-text-title-color);
  }
  .payment-selection {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 30px 15px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 1px solid #eaeaea;
    border-radius: 0px;
    background-color: rgba(255, 255, 255, 0);
    font-family: 'Sf pro display', sans-serif;
    color: var(--bm-text-title-color);
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
  }
  .div-block-547{
    padding: 80px 0px;
  }
  @media screen and (max-width: 991px) {
    margin-left: 40px;
    padding-bottom: 30px;
    .whole {
      padding-right: 30px;
      padding-left: 30px;
    }
    .div-block-547{
      padding: 0;
      padding-top: 40px;
      padding-bottom: 40px;
    }
    .member-wrapper {
      width: auto;
      max-width: none;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }
    .side-nav {
      width: 25%;
      max-width: none;
      margin-right: 20px;
      margin-left: 0px;
    }
    .section.payment-cards {
      padding-right: 30px;
      padding-left: 30px;
    }
    .section {
      margin-top: 0px;
      padding: 84px 35px 30px;
    }
    .div-block-545 {
      padding-top: 15px;
      padding-bottom: 15px;
    }
    .payment-selection  {
      padding-top: 25px;
      padding-bottom: 10px;
    }
  }

  @media screen and (max-width: 767px) {
      width: auto;
      margin-left: 0px;
      padding-top: 0px;
      padding-right: 20px;
      padding-left: 20px;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      margin-top: 0;
      background-color: rgba(242, 243, 248, 0);
    .div-block-547 {
      padding-top: 0;
    }
    .section.payment-cards {
      margin-right: 0px;
      margin-left: 0px;
      padding-top: 0px;
      padding-right: 0px;
      padding-left: 0px;
      background-color: var(--bm-light-background-color);
    }
    .side-nav {
      display: none;
    }
    .section {
      padding: 66px 25px 20px;
    }
    .div-block-545 {
      width: auto;
      margin-right: -20px;
      margin-left: -20px;
      padding-right: 40px;
      padding-left: 40px;
      border-radius: 0px;
      background-color: var(--bm-dark-background-color);
    }
  }

  @media screen and (max-width: 479px) {
    .whole {
      max-width: none;
      margin-right: 0px;
      margin-left: 0px;
      /* padding-top: 50px; */
    }
    .div-block-545 {
      margin-left: -20px;
      padding: 15px 27px;
    }
    .text-block-67 {
      margin-bottom: 0px;
      font-size: 14px;
    }
    .payment-selection {
      .image-200 {
        .right-outlined {
          width: 30PX;
          height: 26PX;
        }
      }
    }
    .payment-selection {
      padding: 20px 0px;
    }
  }

`;
