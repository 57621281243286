/**
 * wechat pay
 * auth: Joshua
 * 2020/07/16
*/
import { payInvoice } from '../../payment-data';
import {
  handleBuildOrderPayment,
} from '../../Utils';
import { IWechatPayMethod } from '../..';

interface IWechatPayParams {
  storeID: string;
  orderNumber: string;
  payData: IWechatPayMethod;
  payment: any;
}

const UseWechatMWebPay = (params: IWechatPayParams) => {
  const {
    storeID,
    orderNumber,
    payment,
    payData,
  } = params;

  const {
    amount,
    returnUrl,
  } = payData;

  const wechatPay = async () => {
    const newPayment = {
      wechatpay: [
        {
          amount,
          'in_web': {
            'return_url': returnUrl,
          },
        }
      ]
    };

    const order = handleBuildOrderPayment(newPayment, payment);
    try {
      const resp = await payInvoice(storeID, orderNumber, order);
      const {
        status,
        order: fulfilOrder,
        success,
      } = resp || {};

      if (success && status === 'ok' && fulfilOrder.sale_transactions && fulfilOrder.sale_transactions.length > 0) {
        let acquirer = '';
        fulfilOrder.sale_transactions.forEach((item: any) => {
          if (item.payment_method === 17 && item.acquirer_response_body) {
            if (typeof item.acquirer_response_body === 'object') {
              acquirer = item.acquirer_response_body.payinfo;
            } else {
              acquirer = JSON.parse(item.acquirer_response_body).payinfo
            }
          }
        });
        if(acquirer) {
          window.location.href = acquirer;
        }
      } else {
        return {
          status: 500,
        }
      }
    } catch (error) {
      throw new Error(error.message);
    }
  }
  return {
    wechatPay,
  }
}
export default UseWechatMWebPay;
