import React from 'react';
import dayjs from 'dayjs';
import { Modal } from 'antd-mobile';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import Icon, { IconType } from '@/components/Icon';
import { IObject } from '@/components/bm-common';
import { getAppTheme, setScreenColor, getIntl } from '@/components/App/App';
import Layout from '@/components/Layout';
import { IStore } from '@/actions/store-action';
import { IReceiveOrder } from '@/actions/good-received-note-actions';
import { formatCurrency } from '@/components/Payment';
import paths from '@/routes/paths';
import { prefix } from '.';
import ActionType from '@/actions/action-type';

export interface IGoodReceivedNoteDetailPageProps extends IBasePageProps { }
export interface IGoodReceivedNoteDetailPageState {
  store: IStore | undefined;
  goodReceivedNote: IReceiveOrder | undefined;
}

class GoodReceivedNoteDetailPage extends BasePage<IGoodReceivedNoteDetailPageProps, IGoodReceivedNoteDetailPageState> {
  routeParams: IObject = {};

  state: IGoodReceivedNoteDetailPageState = {
    store: undefined,
    goodReceivedNote: undefined,
  }

  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.darkBackgroundColor) {
      setScreenColor(appTheme.darkBackgroundColor);
    }

    super.componentDidMount();
    this.openLoading();
    this.getGoodReceivedNoteDetail();
  }

  getGoodReceivedNoteDetail = () => {
    const { routeParams, dispatch } = this.props;
    const { storeSlug, receiveOrderID } = routeParams;
    dispatch({
      type: ActionType.QUERY_STORE,
      storeSlug,
      callback: (store, storeConfig) => {
        if (store) {
          dispatch({
            type: ActionType.QUERY_GOOD_RECEIVED_NOTE,
            storeID: store.id,
            receiveOrderID,
            callback: goodReceivedNote => {
              this.setState({goodReceivedNote, store});
              this.closeLoading();
            }
          })
        } else {
          this.closeLoading()
        }
      }
    })
  }

  handleCancel = () => {
    const { dispatch } = this.props;
    const { goodReceivedNote, store } = this.state;
    if (goodReceivedNote && store) {
      Modal.alert(getIntl().page.alert, getIntl().page.goodReceivedNoteCancelTip, [
        {
          text: getIntl().common.no,
          onPress: () => {},
        },
        {
          text: getIntl().common.yes,
          onPress: () => {
            dispatch({
              type: ActionType.LOADING,
              showLoading: true,
              showLoadingBgc: true,
            });
            dispatch({
              type: ActionType.CANCEL_GOOD_RECEIVED_NOTE,
              storeID: store.id,
              receiveOrderID: goodReceivedNote.id,
              callback: goodReceivedNote => {
                this.setState({goodReceivedNote})
                this.closeLoading();
              }
            })
          },
        }
      ]);
    }
  }

  handleReceive = () => {
    const { dispatch } = this.props;
    const { goodReceivedNote, store } = this.state;
    if (goodReceivedNote && store) {
      Modal.alert(getIntl().page.alert, getIntl().page.goodReceivedNoteReceiveTip, [
        {
          text: getIntl().common.no,
          onPress: () => {},
        },
        {
          text: getIntl().common.yes,
          onPress: () => {
            dispatch({
              type: ActionType.LOADING,
              showLoading: true,
              showLoadingBgc: true,
            });
            dispatch({
              type: ActionType.RECEIVE_GOOD_RECEIVED_NOTE,
              storeID: store.id,
              receiveOrderID: goodReceivedNote.id,
              callback: goodReceivedNote => {
                this.setState({goodReceivedNote})
                this.closeLoading();
              }
            })
          },
        }
      ]);
    }
  }
  
  render() {
    const { goodReceivedNote, store } = this.state;
    return (
      <Layout
        {...this.props}
        className={prefix}
        navIcon={<Icon className="back-icon" type={IconType.LEFT_OUTLINED} />}
        onLeftClick={() => this.handleGoBack({ path: paths.GOOD_RECEIVED_NOTES, runGoBack: true })}
        navContent={getIntl().page.goodReceivedNotesTitle}
        bottomNav={
          goodReceivedNote && goodReceivedNote.state !== 'canceled' &&
          <div className="good-received-note-detail-bottom">
            <div className="good-received-note-detail-button cancel-button" onClick={this.handleCancel}>{getIntl().common.cancel}</div>
            {
              goodReceivedNote.state !== 'canceled' && goodReceivedNote.state !== 'received' &&
              <div className="good-received-note-detail-button receive-button" onClick={this.handleReceive}>{getIntl().page.receive}</div>
            }
          </div>
        }
      >
        {
          goodReceivedNote && store &&
          <div className="good-received-note-detail">
            <div className="good-received-note-detail-style-b-color"></div>
            <div className="good-received-note-detail-section">
              <div className="good-received-note-detail-content">
                <div className="content-panel-wrap">
                  <div className="good-received-note-detail-data">
                    <div className="good-received-note-detail-data-tab">
                      <div className="good-received-note-detail-data-tab-number-and-state">
                        <div className="good-received-note-detail-data-tab-number">{goodReceivedNote.number}</div>
                        {
                          goodReceivedNote.state === 'canceled' &&
                          <div className="good-received-note-detail-data-tab-information-line-data canceled">{getIntl().common.voided}</div>
                        }
                        {
                          goodReceivedNote.state === 'received' &&
                          <div className="good-received-note-detail-data-tab-information-line-data received">{getIntl().page.received}</div>
                        }
                        {
                          goodReceivedNote.state !== 'canceled' && goodReceivedNote.state !== 'received' &&
                          <div className="good-received-note-detail-data-tab-information-line-data deferred">{getIntl().page.created}</div>
                        }
                      </div>
                      <div className="good-received-note-detail-data-tab-information-line">
                        <div className="good-received-note-detail-data-tab-information-line-title">{getIntl().page.supplierName}</div>
                        <div className="good-received-note-detail-data-tab-information-line-data">{goodReceivedNote.supplierName}</div>
                      </div>
                      <div className="good-received-note-detail-data-tab-information-line">
                        <div className="good-received-note-detail-data-tab-information-line-title">{getIntl().page.deliveryReceived}</div>
                        <div className="good-received-note-detail-data-tab-information-line-data">{goodReceivedNote.expectedReceiveDate ? dayjs(goodReceivedNote.expectedReceiveDate).format('YYYY-MM-DD') : 'N/A'}</div>
                      </div>
                      <div className="good-received-note-detail-data-tab-information-line">
                        <div className="good-received-note-detail-data-tab-information-line-title">{getIntl().page.receivedBy}</div>
                        <div className="good-received-note-detail-data-tab-information-line-data">{goodReceivedNote.createdBy}</div>
                      </div>
                      {
                        goodReceivedNote.receiveDate &&
                        <div className="good-received-note-detail-data-tab-information-line">
                          <div className="good-received-note-detail-data-tab-information-line-title">{getIntl().page.receivedOn}</div>
                          <div className="good-received-note-detail-data-tab-information-line-data">{dayjs(goodReceivedNote.createdAt).format('YYYY-MM-DD h:mm A')}</div>
                        </div>
                      }
                      {
                        goodReceivedNote && goodReceivedNote.state !== 'canceled' &&
                        <div className="good-received-note-detail-data-tab-bottom">
                          <div className="good-received-note-detail-data-tab-button cancel-button" onClick={this.handleCancel}>{getIntl().common.cancel}</div>
                          {
                            goodReceivedNote.state !== 'canceled' && goodReceivedNote.state !== 'received' &&
                            <div className="good-received-note-detail-data-tab-button receive-button" onClick={this.handleReceive}>{getIntl().page.receive}</div>
                          }
                        </div>
                      }
                    </div>
                  </div>
                </div>

                <div className="good-received-note-detail-line-item-card">
                  <div className="good-received-note-detail-line-item-card-top-title">{getIntl().page.deliveredQuantity}</div>
                  {
                    goodReceivedNote.purchaseOrders.map(purchaseOrder => {
                      return purchaseOrder.lineItems.map(item => {
                        return (
                          <div className="good-received-note-detail-line-item" key={item.id}>
                            {
                              item.imageUrl &&
                              <div className="good-received-note-detail-line-item-img" style={{ backgroundImage: `$url(${item.imageUrl})`}}></div>
                            }
                            {
                              !item.imageUrl &&
                              <div className="good-received-note-detail-line-item-img"></div>
                            }
                            <div className="good-received-note-detail-line-item-info">
                              <div className="good-received-note-detail-line-item-info-line">
                                <div className="good-received-note-detail-line-item-info-line-name">{item.name}</div>
                                <div className="good-received-note-detail-line-item-info-line-quantity">{getIntl().page.qty} x{item.qtyReceive}</div>
                              </div>
                              <div className="good-received-note-detail-line-item-info-line">
                                <div className="good-received-note-detail-line-item-info-line-code">{getIntl().page.orderQuantity}: {item.remaining}</div>
                              </div>
                              <div className="good-received-note-detail-line-item-info-line">
                                <div className="good-received-note-detail-line-item-info-line-price">{formatCurrency(item.cost, purchaseOrder.currency)}</div>
                                <div className="good-received-note-detail-line-item-info-line-total">{formatCurrency(item.total, purchaseOrder.currency)}</div>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    })
                  }
                  <div className="good-received-note-detail-remarks-title">{getIntl().page.remark}</div>
                  <div className="good-received-note-detail-remarks-data">{goodReceivedNote.remarks}</div>
                  <div className="good-received-note-detail-line-item-card-information-line dividing">
                    <div className="good-received-note-detail-line-item-card-information-line-title">{'Total PO Quantity'}</div>
                    <div className="good-received-note-detail-line-item-card-information-line-data">{goodReceivedNote.qtyPOTotal}</div>
                  </div>
                  <div className="good-received-note-detail-line-item-card-information-line">
                    <div className="good-received-note-detail-line-item-card-information-line-title">{'GRN Quantity'}</div>
                    <div className="good-received-note-detail-line-item-card-information-line-data">{goodReceivedNote.qtyGRN}</div>
                  </div>
                  <div className="good-received-note-detail-line-item-card-information-line">
                    <div className="good-received-note-detail-line-item-card-information-line-title">{'Received Quantity'}</div>
                    <div className="good-received-note-detail-line-item-card-information-line-data">{goodReceivedNote.qtyGRNReceived}</div>
                  </div>
                  <div className="good-received-note-detail-line-item-card-information-line">
                    <div className="good-received-note-detail-line-item-card-information-line-title">{getIntl().common.subtotal}</div>
                    <div className="good-received-note-detail-line-item-card-information-line-data">{formatCurrency(goodReceivedNote.subtotalGRN, store.currencyCode)}</div>
                  </div>
                  <div className="good-received-note-detail-line-item-card-information-line bold">
                    <div className="good-received-note-detail-line-item-card-information-line-title">{getIntl().common.total}</div>
                    <div className="good-received-note-detail-line-item-card-information-line-data">{formatCurrency(goodReceivedNote.totalGRN, store.currencyCode)}</div>
                  </div>
                </div>
              </div>
            </div>
            {
              goodReceivedNote && goodReceivedNote.state !== 'canceled' &&
              <div className="good-received-note-detail-bottom-ipad">
                <div className="good-received-note-detail-button-ipad cancel-button" onClick={this.handleCancel}>{getIntl().common.cancel}</div>
                {
                  goodReceivedNote.state !== 'canceled' && goodReceivedNote.state !== 'received' &&
                  <div className="good-received-note-detail-button-ipad receive-button" onClick={this.handleReceive}>{getIntl().page.receive}</div>
                }
              </div>
            }
          </div>
        }
      </Layout>
    );
  }
}

export default GoodReceivedNoteDetailPage;
