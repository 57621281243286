import React from 'react';
import InputItem from 'antd-mobile/es/input-item';
import { PasswordStyled } from './styled';
import Icon, { IconType } from '@/components/Icon';
import { getIntl } from '@/components/App/App';

interface IPasswordProps {
  value: string;
  onChange?: (value: string) => void;
  forgotClick?: () => void;
}

const Password: React.FC<IPasswordProps> = props => {

  const {
    value,
    onChange = () => {},
    forgotClick= () => {},
  } = props;

  const extraComponent = () => {
    if (value) {
      return (
        <Icon
          type={IconType.CLOSE_OUTLINED}
          className="card-icon-close"
          onClick={() => onChange('')}
        />
      )
    } else {
      return (
        <div className="forgot" onClick={forgotClick}>
          {`${getIntl().page.forgot}?`}
        </div>
      )
    }
  }


  return (
    <PasswordStyled>
      <InputItem
        name="password"
        autoComplete="new-password"
        placeholder={getIntl().common.password}
        onChange={onChange}
        value={value}
        type="password"
        extra={
          extraComponent()
        }
        className="text-field"
      />
    </PasswordStyled>
  )
}
export default Password;
