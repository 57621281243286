/**
 * ali pay
 * auth: Joshua
 * 2020/07/16
*/
import { payInvoice } from '../payment-data';
import {
  handleBuildOrderPayment,
} from '../Utils';
import { IAlipayMethod, PayTypes } from '..';

interface IAlipayParams {
  storeID: string;
  orderNumber: string;
  payData: IAlipayMethod;
  payment: any;
  paymentTypes: PayTypes[];
}

const UseAlipay = (params: IAlipayParams) => {
  const {
    storeID,
    orderNumber,
    payData,
    paymentTypes,
    payment,
  } = params;

  const {
    returnUrl,
    amount,
  } = payData;

  const aliPay = async () => {
    let payKey = PayTypes.ALIPAYHK;
    if (paymentTypes.includes(PayTypes.ALIPAY_HK)) {
      payKey = PayTypes.ALIPAYHK;
    } else if (paymentTypes.includes(PayTypes.ALIPAY)) {
      payKey = PayTypes.ALIPAYCN;
    }
    const newPayment = {
      alipay: [
        {
          amount,
          'in_web': {
            'payment_inst': payKey,
            'return_url': returnUrl
          },
        }
      ],
    };

    const order = handleBuildOrderPayment(newPayment, payment);
    try {
      const resp = await payInvoice(storeID, orderNumber, order);
      const {
        status,
        order: fulfilOrder,
        success,
      } = resp || {};

      if (success && status === 'ok' && fulfilOrder.sale_transactions && fulfilOrder.sale_transactions.length > 0) {
        let acquirer = '';
        fulfilOrder.sale_transactions.forEach((item: any) => {
          if (item.payment_method === 19 && item.acquirer_response_body) {
            if (typeof item.acquirer_response_body === 'object') {
              acquirer = item.acquirer_response_body.payinfo;
            } else {
              acquirer = JSON.parse(item.acquirer_response_body).payinfo
            }
          }
        });

        if(acquirer) {
          window.location.href = acquirer;
        }
      } else {
        return {
          status: 500
        }
      }
    } catch (error) {
      throw new Error(error.message);
    }
  }
  return {
    aliPay,
  }
}
export default UseAlipay;
