import React from 'react';
import OrderEntity from '@/lib/order-builder/models/OrderEntity';
import ListingsItem from '@/components/ListingsPanel/ListingsItem';
import { IReducersState } from '@/reducers';
import { getIntl } from '@/components/App/App';

interface ISingleStoreOrderProps {
  data: OrderEntity;
  reducersState: IReducersState;
}

const SingleStoreOrder: React.FC<ISingleStoreOrderProps> = props => {
  const {
    data,
    reducersState,
  } = props;
  
  return (
    <div>
      <div className="check-out-info restaurant-info text-block-67 bold">
        <div>{data.storeTitle}</div>
      </div>
      {
        data.lineItems.map(item => {
          return (
            <div className="bm-c-listings-panel store-listings-panel" key={item.uuid || item.id || ''}>
              <ListingsItem
                data={item}
                showRightTopCount={true}
                reducersState={reducersState}
              />
            </div>
          );
        })
      }

      {
        data.note &&
        <div className="order-remark">
          <div className="order-remark-title">{getIntl().page.remark}</div>
          <div className="order-remark-note">{data.note}</div>
        </div>
      }

    </div>
  );
}

export default SingleStoreOrder;
