import React from 'react';
import Icon, { IconType } from '@/components/Icon';
import { IDispatch } from '@/actions';
import { IAddress } from '@/actions/user-action';
import SwipeAction from 'antd-mobile/es/swipe-action';
import { IReducersState } from '@/reducers';
import { useSelector } from 'react-redux';
import { getIntl } from '@/components/App/App';

interface IAddressItemProps {
  address: IAddress;
  dispatch?: IDispatch;
  onDeleteItemClick?: (addressID: string) => void;
  onEditItemClick?: (address: IAddress, addressID: string) => void;
  onSelectClick?: (address: IAddress) => void;
}

const AddressItem: React.FC<IAddressItemProps> = props => {
  const { address, onDeleteItemClick, onEditItemClick, onSelectClick } = props;

  const reducersState: IReducersState = useSelector<IReducersState, IReducersState>(state => state);

  const { deviceInfo } = reducersState.app;
  if (deviceInfo.inPad || deviceInfo.inPhone) {
    return (
      <SwipeAction
        right={[
          {
            text: getIntl().page.delete,
            onPress: () => {
              if (onDeleteItemClick) {
                onDeleteItemClick(address.id);
              }
            },
            style: { backgroundColor: '#F4333C', color: 'white' },
          },
        ]}
      >
        <div className="choose-existing-address _1">
          <Icon className="html-embed-25 w-embed" type={IconType.MAP_OUTLINED} />
          <div className="div-block-488" onClick={() => { onSelectClick && onSelectClick(address) }}>
            <div className="text-block-77">{`${address.city.replace(/\s*/g, '')}${address.address1}`}</div>
            <div className="text-block-69">{address.name}&nbsp;{address.phone}</div>
          </div>
          <div className="div-block-487 div-block-487-edit">
            <Icon className="html-embed-41 w-embed" 
              type={IconType.EDIT_ADDRESS_OUTLINED}
              onClick={() => onEditItemClick && onEditItemClick(address, address.id)}
            />
          </div>
        </div>
      </SwipeAction>
    );
  }

  return (
    <div className="choose-existing-address _1">
      <Icon className="html-embed-25 w-embed" type={IconType.MAP_OUTLINED} />
      <div className="div-block-488"  onClick={() => { onSelectClick && onSelectClick(address) }}>
        <div className="text-block-77">{`${address.city}${address.address1}`}</div>
        <div className="text-block-69">{address.name}&nbsp;{address.phone}</div>
      </div>
      <div className="div-block-487 div-block-487-edit">
        <Icon className="html-embed-41 w-embed" 
          type={IconType.EDIT_ADDRESS_OUTLINED}
          onClick={() => onEditItemClick && onEditItemClick(address, address.id)}
        />
      </div>
      <div className="div-block-487">
        <Icon className="html-embed-41 w-embed" 
          type={IconType.RECYCLE_BIN_OUTLINED} 
          onClick={() => onDeleteItemClick && onDeleteItemClick(address.id)}
        />
      </div>
    </div>
  );
}

export default AddressItem;
