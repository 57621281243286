enum TabsOption {
  STORE = 'STORE',
  ORDER = 'ORDER',
  ACCOUNT = 'ACCOUNT',
  NEWS = 'NEWS',
  ABOUT_US = 'ABOUT_US',
  HOME = 'HOME',
  MEMBERS_AREA = 'MEMBERS_AREA',
  RECORDS = 'RECORDS',
  ESHOP = 'ESHOP',
  PROMOTIONS = 'PROMOTIONS',
  MY_ID = 'MY_ID',
  BRANDS = 'BRANDS',
  CATEGORY = 'CATEGORY',
  EXPRESS_ORDER = 'EXPRESS_ORDER',

  // OTHER
  MENUS = 'MENUS',
}

export default TabsOption;
