import styled from 'styled-components';
export const StoresPageStyled = styled.div`
.w-tabs {
  position: relative;
}
.w-tab-menu {
  position: relative;
}
.tabs-menu-6 {
  position: -webkit-sticky;
  position: sticky;
  top: 70px;
  z-index: 8000;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 940px;
  height: 70px;
  min-height: 70px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--bm-light-background-color);
  box-sizing: border-box;
  overflow: hidden;
}
.tabs-flex {
  width: 100%;
  overflow: auto;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.w-tab-link {
  position: relative;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  padding: 9px 30px;
  text-align: left;
  cursor: pointer;
  color: var(--bm-text-title-color);
}
.tab-2 {
  white-space: pre;
  margin-right: 10px;
  background-color: hsla(0, 0%, 86.7%, 0);
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: var(--bm-text-color);
  font-size: 20px;
}
.tab-2.selected {
  margin-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 20px;
  background-color: var(--bm-primary-color);
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: var(--bm-light-background-color);
  font-size: 20px;
  font-weight: 700;
}
.div-block-631 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
  margin-bottom: 30px;
  padding-left: 15px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.w-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
}
.container {
  position: relative;
  display: block;
  overflow: visible;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  .bm-c-store-list {
    width: 100%;
    padding: 0;
    display: flex;
  }
  .bm-c-store-list-item {
    width: 47%;
  }
}
.section {
  display: block;
  margin-top: auto;
  margin-bottom: 0px;
  padding: 60px 40px 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--bm-dark-background-color);
}
.section._1 {
  padding-top: 100px;
}
.wf-sectionmargin._1  {
  padding-top: 40px;
}
.div-block-632 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.div-block-630 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 150px;
  height: 80px;
  margin-right: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 6px;
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  color: var(--bm-light-background-color);
  text-decoration: none;
}
.text-block-230 {
  font-family: 'Sf pro display', sans-serif;
  font-size: 18px;
  font-weight: 600;
}
.background-strip-white {
  position: fixed;
  z-index: 12;
  width: 100%;
  height: 70px;
  background-color:  var(--bm-light-background-color);
  overflow: scroll;
}
.panel-bar {
  padding: 10px 0;
  margin-top: 0;
}
.bm-p-stores {
  .bar-left {
    font-size: 22px;
  }
}



@media screen and (max-width: 991px) {
  .tabs-menu-6 {
    width: auto;
    max-width: 728px;
  }
  .section {
    margin-top: 0px;
    padding: 40px 35px 30px;
  }
  .w-container {
    max-width: 728px;
  }
}

@media screen and (max-width: 767px) {
  .tabs-menu-6 {
    top: 60px;
    height: 60px;
    max-width: none;
    min-height: 60px;
  }
  .tab-2 {
    margin-right: 0px;
    padding: 10px 12px;
    font-size: 16px;
  }
  .tab-2.selected {
    font-size: 16px;
  }
  .div-block-631 {
    margin-bottom: 20px;
    padding-left: 0px;
  }
  .section {
    padding: 83px 25px 20px;
  }
  .section._1 {
    padding-top: 100px;
  }
  .wf-sectionmargin._1  {
  padding-top: 40px;
}
  .div-block-630 {
    width: 140px;
    height: 80px;
  }
  .text-block-230 {
    font-size: 16px;
  }
  .background-strip-white {
    top: calc(var(--bm-safe-area-top) + 60px);
    height: 60px;
  }
  .bm-p-stores {
    .bar-left {
      font-size: 20px;
    }
  }

}

@media screen and (max-width: 479px) {
  .tabs-menu-6 {
  top: 50px;
    height: 50px;
    min-height: 50px;
  }
  .tab-2.selected {
    white-space: pre;
  }
  .section {
    padding: 68px 12px 15px;
  }
  .section._1 {
    padding-top: 60px;
  }
  .wf-sectionmargin._1 {
    padding-top: 20px;
  }
  .w-container {
    max-width: none;
  }
  .container {
    margin-top: 0px;
    margin-bottom: 0px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .div-block-631 {
    margin-bottom: 15px;
  }
  .text-block-230 {
    font-size: 14px;
  }
  .div-block-630 {
    width: 120px;
    height: 70px;
    margin-right: 15px;
  }
  .background-strip-white {
    top: calc(var(--bm-safe-area-top) + 50px);
    height: 50px;
  }
  .container {
    .bm-c-store-list-item {
      width: 100%;
    }
  }
  .bm-p-stores {
    .bar-left {
      font-size: 18px;
    }
  }
}
`;


export const PanelBarStyled = styled.div`
.bar-left {
  margin-bottom: 20px;
  margin-left: 10px;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: var(--bm-text-title-color);
  font-weight: 400;
  font-size: 24px;
  margin-top: 0px;
  line-height: 30px;
}
@media screen and (max-width: 479px) {
  .bar-left {
    margin-bottom: 10px;
    margin-left: 0px;
    font-size: 18px;
  }
}
`;

