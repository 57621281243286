import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import usePageProps from '@/routes/usePageProps';
import CheckoutPage from '@/pages/CheckoutPage';

const Checkout: React.FC<RouteComponentProps> = props => {
  const pageProps = usePageProps({ props });

  if (pageProps.reducersState.app.loadingPlatformConfig) {
    return null;
  }

  return <CheckoutPage {...pageProps} />
};

export default Checkout;
