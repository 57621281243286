import axios from 'axios';
import Constants from '@/constants';
import Config from '@/Config';
import Modal from 'antd-mobile/es/modal';
import { getDomain, getLocalStorageJson, createRouteUrl } from '@/utils';
import { getSplashStorageData, isSplashInitialized } from '@/utils/splash-util';
import { getDeviceInfo, getAccessToken, removeAccessToken } from '@/utils/app';
import { getIntl } from '@/components/App/App';
import { IObject } from '@/components/bm-common';
import paths from '@/routes/paths';

axios.defaults.baseURL = '/bindo-api';

axios.interceptors.request.use(
  config => {
    const { headers, url } = config;
    if (url && !url.includes('api/v5/galaxy3') && !url.includes('api.mailgun.net')) {
      headers['Content-Type'] = 'application/json';
      headers['X-USER-APP-TYPE'] = Config.userAppType;
      headers['X-USER-DEVICE-TYPE'] = Config.userDeviceType;
      headers['X_USER_DEVICE_ID'] = Config.userDeviceID;
      headers['X-USER-APP-VERSION'] = Config.userAppVersion;
      headers['X-I18N-ENABLED'] = true;
      if (!headers['X-I18N-LANG']) {
        headers['X-I18N-LANG'] = 'all';
      }
      const token = getAccessToken();

      if (token) {
        headers['X-USER-ACCESS-TOKEN'] = token;
        headers['AUTHORIZATION'] = `OAuth ${token}`;
      }
    }

    return config;
  },
  error => Promise.reject(error),
);

axios.interceptors.response.use(
  response => {
    const { headers } = response.config;
    if (headers && headers['X-USE-ALL-RESPONSE']) {
      return response;
    } else {
      return response.data;
    }
  },
  error => {
    // 401 错误全局处理
    const { response } = error || {};
    const { data, status } = response || {};
    const { meta } = data || {};
    const { code } = meta || {};
    
    const { verifyToken, platformDomain, platformHost, isLocalhost, gatewayDomain, platform } = getDomain();
    const protocol = `http${isLocalhost ? '' : 's'}:`;
    const deviceInfo = getDeviceInfo();
    const splashData = getSplashStorageData({ deviceInfo, platformDomain });

    const platformStorageKey = `${Constants.APP_CONFIG}-${platformDomain}`;
    const appConfig = getLocalStorageJson(platformStorageKey);
    let platformAppSettings: IObject = {};
    if (appConfig) {
      platformAppSettings = appConfig.platformAppSettings || {};
    }
    const { suppliers }= platformAppSettings || {};

    let splashDataObj;
    if (typeof splashData === 'string') {
      splashDataObj = JSON.parse(splashData);
    } else {
      splashDataObj = splashData;
    }
    if ((code === 401 || status ===401) && verifyToken && (isSplashInitialized({ deviceInfo, platformDomain }) || (splashDataObj && !splashDataObj.showSplash))) {

      const path= window.location.pathname;
      const invalidToken = getIntl().page.invalidToken;
      const signCancel = getIntl().page.signCancel;
      const logInAgain = getIntl().page.logInAgain;
      const tips = getIntl().page.tips;

      removeAccessToken();
      localStorage.removeItem(Constants.SUPPLIERS_SELECT_STORE_SLUG);
      localStorage.removeItem(Constants.SUPPLIERS_SELECT_STORE_ID);
      localStorage.removeItem(Constants.ADD_EXPRESS_ORDER);
      localStorage.removeItem(Constants.USER_INFO);
      localStorage.removeItem(Constants.IS_GUEST);
      localStorage.removeItem(Constants.PRIVILEGE_NUMBER);
      const SignInPaths = `${protocol}//${platformHost}${createRouteUrl(
        paths.SIGN_IN,
        {},
      )}`

      if(!(path === paths.SIGN_IN || path === `/p/${platform}${paths.SIGN_IN}` || path.includes('/checkout/pay-info/') || path.includes('/pay-bill'))){
        if (suppliers) {
          if(path === paths.SELECT_STORES){
            window.location.href = SignInPaths;
          } else {
            Modal.alert(tips, invalidToken, [
              {
                text: logInAgain,
                onPress: () => {
                  window.location.href = SignInPaths;
                },
              }
            ]);
          }
        } else {
          Modal.alert(tips, invalidToken, [
            {
              text: signCancel,
              onPress: () => {
                if (gatewayDomain === window.location.hostname) {
                  window.location.href = `${protocol}//${platformHost}`;
                } else {
                  window.location.reload();
                }
              },
            },
            {
              text: logInAgain,
              onPress: () => {
                window.location.href = SignInPaths;
              },
            }
          ]);
        }
      } 
      
    }
    // return Promise.reject(error.response)
    return error.response;
  }
)
