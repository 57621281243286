
import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import Icon, { IconType } from '@/components/Icon';
import { IObject } from '@/components/bm-common';
import { getAppTheme, setScreenColor, getIntl } from '@/components/App/App';
import { prefix } from '.';
import InputItem from 'antd-mobile/es/input-item';
import DatePicker from 'antd-mobile/es/date-picker';
import List from 'antd-mobile/es/list';
import ImagePicker from 'antd-mobile/es/image-picker';
import { PutMe } from '@/data/sign';
import ActionType from '@/actions/action-type';
import { Popup } from '@/components/Modal';
import SignUserName from '@/components/common/userComponents/NewUserName';
import { IDialCodeGroupItem } from '@/components/DialCodeGroup';
import dayjs from 'dayjs';
import Modal from 'antd-mobile/es/modal';
import paths from '@/routes/paths';
import Constants from '@/constants';
import BmNavBar from '@/components/SideNavBar/BmNavBar';
import { AccountEditPageStyled, AccountEditSyled } from './styled';

export interface IAccountEditPageProps extends IBasePageProps {}
export interface IAccountEditPageState {
  username: string;
  email: string;
  gender: string;
  genderText: string;
  phone: string;
  birthDay: string;
  showGenderList: boolean;
  areaCodeData: IDialCodeGroupItem;
  dialCode: string;
  date: Date | undefined;
  files: any;
  imgUrl: string;
}
// const nowTimeStamp = Date.now();
// const now = new Date(nowTimeStamp);
let dateOfBirth = '';
class AccountEditPage extends BasePage<IAccountEditPageProps, IAccountEditPageState> {
  routeParams: IObject = {};

  state: IAccountEditPageState = {
    username: '',
    email: '',
    gender: '',
    genderText: '',
    phone: '',
    birthDay: '',
    showGenderList: false,
    areaCodeData: {
      iso2: '',
      dialCode: '',
      priority: 0,
      areaCodes: [],
    },
    dialCode: '',
    date: undefined,
    files: [],
    imgUrl: '',
  }

  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.lightBackgroundColor) {
      setScreenColor(appTheme.lightBackgroundColor);
    }
    const { reducersState } = this.props;
    const { platformConfig } = reducersState.app;
    const { birthdayHiddenDayEnable } = platformConfig;
    const stringUserInfo = localStorage.getItem(Constants.USER_INFO);
    let userInfo = null;
    if (stringUserInfo) {
      userInfo = JSON.parse(stringUserInfo);
    }
    if (userInfo) {
      let genderText = '';
      if (userInfo.gender && Number(userInfo.gender) === 1) {
        genderText = getIntl().page.male;
      } else if (userInfo.gender && Number(userInfo.gender) === 2) {
        genderText = getIntl().page.female;
      } else if (userInfo.gender && Number(userInfo.gender) === 3) {
        genderText = getIntl().page.other;
      } else {
        genderText = '';
      }
      const { phone, name, email, gender } = userInfo;
      const dialCode =  phone ? phone.split(' ')[0] : '';
      dateOfBirth = userInfo.dateOfBirth || '';
      if (dateOfBirth && birthdayHiddenDayEnable) {
        const birthArray = dateOfBirth.split('-');
        if (birthArray.length === 3) {
          dateOfBirth = `${birthArray[0]}-${birthArray[1]}`;
        }
      }

      this.setState({
        username: name,
        email: email,
        gender: gender,
        genderText,
        phone: phone ? phone.split(' ')[1] : '',
        dialCode: dialCode ? dialCode.split('+')[1] : '',
        date: userInfo.dateOfBirth ? new Date(dateOfBirth) : this.state.date,
      })
    }
    
    super.componentDidMount();
  }

  gotoAccountPage = () => {
    const { routeParams } = this.props;
    let pathname: string = paths.HOME;
    if (routeParams.fromPage === 'account') {
      pathname = paths.ACCOUNT;
    } else if (routeParams.fromPage === 'membership') {
      pathname = paths.MEMBERSHIP;
    } else {
      pathname = paths.HOME;
    }
    this.handleGoBack({
      path: pathname,
      runGoBack: true,
    })
  }

  handleSave = async() => {
    const { dispatch } = this.props;
    const { username, email, gender, dialCode, areaCodeData, phone, date } = this.state;
    const code = dialCode ? dialCode : areaCodeData.dialCode;
    let veriftyRes = true;
    if (email) {
      const reg = /^([A-Za-z0-9_+-.])+@([A-Za-z0-9\-.])+\.([A-Za-z]{2,22})$/;
      veriftyRes = reg.test(email);
    }

    const phoneValue = phone.replace(/\s/g, '');
    const params = {
      name: username,
      email: email,
      gender: gender || undefined,
      phone: phoneValue ? `+${code} ${phoneValue}` : '',
      dateOfBirth: this.getShowDate(date),
    }
    if ((phoneValue && code === '852' && (phoneValue.length !== 8 )) || (phoneValue && code === '86' && phoneValue.length !== 11)) {
      Modal.alert(getIntl().common.tips, getIntl().page.errorPhoneTips, [
        {
          text: getIntl().common.confirm,
          onPress: () => {},
        }
      ]);
      return;
    }
    if (!username) {
      Modal.alert(getIntl().common.tips, getIntl().page.useNameInvalid, [
        {
          text: getIntl().common.confirm,
          onPress: () => {},
        }
      ]);
      return;
    } else if (!email || !veriftyRes) {
      Modal.alert(getIntl().common.tips, getIntl().page.emailInvalid, [
        {
          text: getIntl().common.confirm,
          onPress: () => {},
        }
      ]);
      return;
    } 
    dispatch({
      type: ActionType.LOADING,
      showLoading: true,
      showLoadingBgc: true,
    });
    const userInfo = await PutMe(params);
    if (userInfo) {
      userInfo.avatarUrl = (userInfo.avatarUrl).replace(/\s+/g, '%20');
      dispatch({
        type: ActionType.SET_USER_INFO,
        userInfo,
      });
      localStorage.setItem(Constants.USER_INFO, JSON.stringify(userInfo));
      dispatch({
        type: ActionType.LOADING,
        showLoading: false,
      });
      this.gotoAccountPage();
    }
    dispatch({
      type: ActionType.LOADING,
      showLoading: false,
    });
  }

  setShowGenderList = (type: boolean) => {
    this.setState({
      showGenderList: type,
    })
  }
  chooseGender = (value: string, text: string) => {
    this.setState({
      gender: value,
      genderText: text,
      showGenderList: false,
    })
  }
  handleItemClick = (data: IDialCodeGroupItem) => {
    this.setState({
      areaCodeData: data,
      dialCode: data.dialCode,
    })
  }
  getShowDate = (value: any) => {
    const { reducersState } = this.props;
    const { platformConfig } = reducersState.app;
    const { birthdayHiddenDayEnable } = platformConfig;
    const dateValue = birthdayHiddenDayEnable ? dayjs(value).format('YYYY-MM') : dayjs(value).format('YYYY-MM-DD');
    return dateValue;
  }

  onChange = (files: string | any[], type: string, index: any) => {
    const { dispatch } = this.props;
    if (type === 'add') {
      const file = files[files.length - 1].file;
      const formData = new FormData();
      formData.append('avatar', file);
      this.setState({files}, async() => {
        const params = {
          avatar: formData
        }
        dispatch({
          type: ActionType.LOADING,
          showLoading: true,
          showLoadingBgc: true,
        });
        const userInfo = await PutMe(params);
        if (userInfo) {
          userInfo.avatarUrl = (userInfo.avatarUrl).replace(/\s+/g, '%20');
          dispatch({
            type: ActionType.SET_USER_INFO,
            userInfo,
          });
          localStorage.setItem(Constants.USER_INFO, JSON.stringify(userInfo));
          dispatch({
            type: ActionType.LOADING,
            showLoading: false,
          });
        }
      })
    } else {
      this.setState({files});
    }
  }

  render() {
    const { reducersState } = this.props;
    const stringUserInfo = localStorage.getItem(Constants.USER_INFO);
    let userInfo = null;
    if (stringUserInfo) {
      userInfo = JSON.parse(stringUserInfo);
    }
    const { language, platformConfig } = reducersState.app;
    let areaCode = '';
    if (userInfo) {
      const code =  userInfo.phone ? userInfo.phone.split(' ')[0] : '';
      areaCode = code ? code.split('+')[1] : ''

    }
    const genderList = [
      {
        value: 1,
        text: getIntl().page.male,
      },
      {
        value: 2,
        text: getIntl().page.female
      },
      {
        value: 3,
        text: getIntl().page.other
      }
    ];

    const locale = {
      okText: getIntl().page.ok,
      dismissText: getIntl().common.cancel,
      extra: getIntl().page.selectDate,
      DatePickerLocale: {
        year: '',
        month: '',
        day: '',
        hour: '',
        minute: '',
        am: 'AM',
        pm: 'PM'
      },
    };

    const { birthdayHiddenDayEnable } = platformConfig;

    return (
      <AccountEditSyled>
        <BmNavBar
          onLeftClick={this.gotoAccountPage}
          navIcon={<Icon className="html-embed-7 w-embed" type={IconType.LEFT_OUTLINED} />}
          navContent={getIntl().page.accountTitle}
          rigthContent={getIntl().page.save}
          onRightClick={this.handleSave}
        ></BmNavBar>
        <AccountEditPageStyled>
          <div className={prefix}>
            <div className="section profile">
              <div className="container w-container">
                <div className="account-block">
                  <div className="div-block-555">
                    {
                      userInfo && userInfo.avatarUrl &&
                        <div className="avatar" style={{backgroundImage: `url(${userInfo.avatarUrl})`}}/>
                    }
                    {
                      userInfo && !userInfo.avatarUrl &&
                      <Icon
                        type={IconType.DEFAULT_AVATAR_OUTLINED}
                        className="avatar-icon"
                      />
                    }
                    <div className="edit-icon-wrap">
                      <Icon
                        type={IconType.CAMERA_OUTLINED}
                        className="edit-icon"
                      />
                    </div>
                    <ImagePicker
                      files={this.state.files}
                      length={1}
                      disableDelete={true}
                      onChange={this.onChange}
                      selectable={true}
                      multiple={false}
                    />
                  </div>
                  <div className="text-block-98">
                    {getIntl().page.username}
                  </div>
                  <div className="account-info">
                    <InputItem
                      name="username"
                      autoComplete="off"
                      maxLength={50}
                      placeholder={getIntl().page.usernamePlaceholder}
                      value={this.state.username}
                      onChange={value => this.setState({username: value})}
                    />
                  </div>
                  <div className="text-block-98">
                    {getIntl().page.email}
                  </div>
                  <div className="account-info">
                    {
                      ((userInfo && userInfo.phoneConfirmed) || (!userInfo || !userInfo.email)) &&
                      <InputItem
                        name="username"
                        autoComplete="off"
                        placeholder={getIntl().page.signInEmailPlacehodler}
                        value={this.state.email}
                        onChange={value => this.setState({email: value})}
                      />
                    }
                    {
                      (userInfo && !userInfo.phoneConfirmed) &&
                      <div className="text-block-214 secondary-color">
                        <div className="phone-msg">{userInfo.email}</div>
                      </div>
                    }
                  </div>
                  <div className="text-block-98">
                    {getIntl().page.gender}
                  </div>
                  <div className="account-info" onClick={() => this.setShowGenderList(true)}>
                    <div className="text-block-214">{this.state.genderText}</div>
                    <Icon
                      type={IconType.RIGHT_OUTLINED}
                      className="right-icon"
                    />
                  </div>
                  <div className="text-block-98">
                    {getIntl().page.phone}
                  </div>
                  <div className="account-info">
                    {
                      userInfo && userInfo.phoneConfirmed &&
                      <div className="text-block-98">
                        {userInfo.phone}
                      </div>
                    }
                    {
                      (!userInfo || !userInfo.phoneConfirmed) &&
                      <SignUserName
                        language={language}
                        value={this.state.phone}
                        onChange={value => this.setState({phone: value})}
                        onItemClick={(data: IDialCodeGroupItem) => this.handleItemClick(data)}
                        showIcon={true}
                        type="PHONE"
                        areaCode={areaCode}
                      />
                    }
                  </div>
                  <div className="text-block-98">
                    {getIntl().page.birthday}
                  </div>
                  <div className="account-info">
                    {
                      userInfo && userInfo.dateOfBirth &&
                      <div className="text-block-214 secondary-color">{dateOfBirth}</div>
                    }
                    {
                      userInfo && !userInfo.dateOfBirth &&
                      <div className="date-picker">
                        <DatePicker
                          mode={birthdayHiddenDayEnable ? 'month' : 'date'}
                          title={getIntl().page.selectDate}
                          extra={getIntl().page.birthday}
                          locale={locale}
                          value={this.state.date}
                          minDate={new Date(1900, 0, 1)}
                          maxDate={new Date()}
                          onChange={date => this.setState({ date })}
                          format={(value: Date) => {
                            return this.getShowDate(value);
                          }}
                        >
                          <List.Item></List.Item>
                        </DatePicker>
                      </div>
                    }
                  </div>
                  <div className="text-block-216">
                    {getIntl().page.birthdayTip}
                  </div>
                </div>
              </div>
            </div>
            {
              <Popup
                visible={this.state.showGenderList}
                title={getIntl().page.chooseGender}
                onClose={() => this.setShowGenderList(false)}
                className={`${prefix}-gender-list`}
              >
                <div className="gender-list">
                  {
                    genderList.map(item => (
                      <div className="gender-wrap" key={item.value} onClick={() => this.chooseGender(String(item.value), item.text)}>
                        <div className="gender">
                          {item.text}
                        </div>
                        {
                          this.state.gender && item.value === Number(this.state.gender) &&
                            <Icon
                              type={IconType.CHECKED_OUTLINED}
                              className="checked"
                            />
                        }
                      </div>
                    ))
                  }
                </div>
              </Popup>
            }
          </div>
        </AccountEditPageStyled>
      </AccountEditSyled>
    );
  }
}

export default AccountEditPage;
