import qrcodeShoppingRoutes from './qrcode-shopping';
import usePointsRoutes from './user-points';
import brandListRoutes from './brand-list';
import homeRoutes from './home-page';
import accountRoutes from './account-page';
import bookingRoutes from './booking-page';
import queueingRoutes from './queueing-page';
import CategoryRoutes from './category';
import memberRoutes from './member-page';
import gatewayRoutes from './gateway-page';
import blogsRoutes from './blogs-page';
import expressOrderRoutes from './express-order';
import orderRoutes from './order-page';
import purchaseOrderRoutes from './purchase-order';
import deferredBillingRoutes from './deferred-billing-page';

export interface IRoute {
  path: string;
  component: any;
  name?: string;
  exact?: boolean;
}

const routes: IRoute[] = [
  // account routes
  ...accountRoutes,
  ...bookingRoutes,
  ...queueingRoutes,
  ...CategoryRoutes,
  ...memberRoutes,
  ...gatewayRoutes,
  ...blogsRoutes,
  ...expressOrderRoutes,
  ...purchaseOrderRoutes,

  ...qrcodeShoppingRoutes,
  ...usePointsRoutes,
  ...brandListRoutes,
  ...homeRoutes,
  ...orderRoutes,
  ...deferredBillingRoutes,

  // {
  //   path: '(.*)',
  //   load: () => import('./not-found'),
  // },
];

export default routes;
