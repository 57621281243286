import React, { useState, ReactElement } from 'react';
import { IObject } from '@/components/bm-common';

import { prefix } from '.';
import './Swiper.less'

interface ISwiperProps {
  className?: string;
  leftSide: ReactElement;
  rightSide: ReactElement;
}

const Swiper: React.FC<ISwiperProps> = props => {
  const {
    className = '',
    leftSide,
    rightSide,
  } = props;

  const [startX, setStartX] = useState<number>(0);
  const [startY, setStartY] = useState<number>(0);
  const [moveX, setMoveX] = useState<number>(0);
  const [moveY, setMoveY] = useState<number>(0);

  const [hasTransition, setHasTransition] = useState<boolean>(false);

  const handleTouchStart = (evt: React.TouchEvent) => {
    evt.persist();
    setStartX(evt.touches[0].pageX);
    setStartY(evt.touches[0].pageY);
  }

  const handleTouchMove = (evt: React.TouchEvent) => {
    const tempMoveX = evt.touches[0].pageX - startX;
    const tempMoveY = evt.touches[0].pageY - startY;

    setMoveX(tempMoveX);
    setMoveY(tempMoveY);

    // 纵向移动时
    if (Math.abs(moveY) > Math.abs(moveX)) {
      return
    };

    // 滑动超过一定距离时，才触发
    if (Math.abs(moveX) < 10 ) {
      return
    } else {
      setHasTransition(true);
    }
  }

  const handleTouchEnd = () => {
    setHasTransition(true);
  }

  // 处理排他和动画
  const handleMoveStyle = () => {
    const moveStyle: IObject = {};
    const distance = moveX >= 0 ? 0 : -180;
    if (hasTransition) {
      moveStyle.transform = `translateX(${distance}px)`;
      moveStyle.WebkitTransform = `translateX(${distance}px)`;
      moveStyle.transition = 'transform 0.3s ease';
      moveStyle.WebkitTransform = 'transform 0.3s ease';
    };

    return moveStyle;
  }

  return (
    <div className={`${prefix} ${className}`}>
      <div className={`${prefix}-wrap`} style={{...handleMoveStyle()}}>
        <div className={`${prefix}-left`}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          {leftSide}
        </div>
        <div className={`${prefix}-right`}>
          {rightSide}
        </div>
      </div>
    </div>
  );
}

export default Swiper;
