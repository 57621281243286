import React, { useEffect, useState } from 'react';
import InputItem from 'antd-mobile/es/input-item';
import Icon, { IconType } from '@/components/Icon';
import { formatCurrency } from '@/components/Payment';
import { lineItem, IReceiveOrder } from '@/actions/good-received-note-actions';
import { IStore } from '@/actions/store-action';
import { getIntl } from '@/components/App/App';

interface INewGoodsReceivedNoteItemProps {
  lineItem: lineItem;
  goodReceivedNote: IReceiveOrder;
  store: IStore;
  handleSetQuantity: (id: string, value: number) => void;
}

const NewGoodsReceivedNoteItem: React.FC<INewGoodsReceivedNoteItemProps> = props => {
  const {
    lineItem,
    store,
    handleSetQuantity,
    goodReceivedNote
  } = props;

  useEffect(() => {
    setQuantity(lineItem.qtyReceive.toString());
  }, [lineItem.qtyReceive]);

  const handleMinusClick = (id: string) => {
    if (goodReceivedNote) {
      let qtyReceive = 0;
      goodReceivedNote.purchaseOrders.forEach(purchaseOrder => {
        purchaseOrder.lineItems.forEach(item => {
          if (item.id === id) {
            if (item.qtyReceive - 1 >= 0) {
              qtyReceive = item.qtyReceive - 1;
            } else {
              qtyReceive = item.qtyReceive;
            }
          }
        })
      })
      handleSetQuantity(id, qtyReceive);
    }
  }

  const handlePlusClick = (id: string) => {
    if (goodReceivedNote) {
      let qtyReceive = 0;
      goodReceivedNote.purchaseOrders.forEach(purchaseOrder => {
        purchaseOrder.lineItems.forEach(item => {
          if (item.id === id) {
            if (item.qtyReceive + 1 <= item.remaining) {
              qtyReceive = item.qtyReceive + 1;
            } else {
              qtyReceive = item.qtyReceive;
            }
          }
        })
      })
      handleSetQuantity(id, qtyReceive);
    }
  }

  const handleBlur = (id: string, value: string) => {
    let qtyReceive = parseInt(value);
    if (isNaN(qtyReceive) || qtyReceive > lineItem.remaining) {
      qtyReceive = lineItem.remaining;
    }
    handleSetQuantity(id, qtyReceive);
  }

  const [quantity, setQuantity] = useState<string>(lineItem.qtyReceive.toString());

  return (
    <div className="purchase-order-detail-line-item" key={lineItem.id}>
      <div className="purchase-order-detail-line-item-img" style={{ backgroundImage: `url(${lineItem.imageUrl})`}}></div>
      <div className="purchase-order-detail-line-item-info">
        <div className="purchase-order-detail-line-item-info-line">
          <div className="purchase-order-detail-line-item-info-line-name">{lineItem.name}</div>
          <div className="purchase-order-detail-line-item-info-line-quantity">
            <div className="item-count">
              <Icon
                className="item-minus"
                type={IconType.MINUS_CIRCLE_OUTLINED}
                onClick={() => handleMinusClick(lineItem.id)}
              />
              <div className="count">
                <InputItem
                  type="bankCard"
                  maxLength={4}
                  onChange={setQuantity}
                  value={quantity}
                  onBlur={value => handleBlur(lineItem.id, value || '0')}
                />
              </div>
              <Icon
                className="item-plus"
                type={IconType.PLUS_CIRCLE_OUTLINED}
                onClick={() => handlePlusClick(lineItem.id)}
              />
            </div>
          </div>
        </div>
        <div className="purchase-order-detail-line-item-info-line">
          <div className="purchase-order-detail-line-item-info-line-code">{getIntl().page.orderQuantity} : {lineItem.remaining}</div>
        </div>
        <div className="purchase-order-detail-line-item-info-line">
          <div className="purchase-order-detail-line-item-info-line-price">{formatCurrency(lineItem.cost, store.currencyCode)}</div>
          <div className="purchase-order-detail-line-item-info-line-total">{formatCurrency(lineItem.total, store.currencyCode)}</div>
        </div>
      </div>
    </div>
  );
}

export default NewGoodsReceivedNoteItem;
