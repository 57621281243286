import React from 'react';
import Icon, { IconType } from '@/components/Icon';
import { IStore } from '@/actions/store-action';

interface IGoogleMapMarkerProps {
  lat: string | number;
  lng: string | number;
  iconType: IconType;
  store?: IStore;
  icon?: React.ReactElement;
  className?: string;
  onClick?: Function;
  selectedStoreID?: string;
}

const GoogleMapMarker = (props: IGoogleMapMarkerProps) => {

  const {
    store,
    className,
    iconType,
    onClick = () => {},
    selectedStoreID = '',
  } = props;

  const clickMarker = () => {

    if (store) {
      onClick(store);
    }
  }

  const getClassName = () => {
    if (selectedStoreID && store && selectedStoreID === store.id) {
      return `${className} selected`
    }
    return className
  }

  return (
    <div
      onClick={clickMarker}
    >
      {
        <Icon
          type={iconType}
          className={getClassName()}
        />
      }
    </div>
  );
}

export default GoogleMapMarker;
