
import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import { IObject } from '@/components/bm-common';
import { getAppTheme, setScreenColor, getIntl } from '@/components/App/App';
import Layout from '@/components/Layout/Layout';
import { prefix } from '.';
import ActionType from '@/actions/action-type';
import { IStore } from '@/actions/store-action';
import paths from '@/routes/paths';
import Icon, { IconType } from '@/components/Icon';
// import StoreInfo from '@/pages/express-order/ExpressOrder/StoreInfo';
import dayjs from 'dayjs';
import Config from '@/Config';
import { findNode } from '@/utils';
import Modal from 'antd-mobile/es/modal';
import { formatCurrency } from '@/components/Payment';

export interface IAboutUsPageProps extends IBasePageProps { }
export interface IAboutUsPageState {
  loading: boolean;
  selectStore: IStore | undefined; //选择的store
  invoices: any;
  selectedID: number;
  dateStart: string;
  dateEnd: string;
  selectStoreSlug: string;
  order: any;
  timeList: IObject;
}
const today = dayjs(new Date()).format('YYYY-MM-DD');
const week = dayjs(new Date()).subtract(6, 'day').format('YYYY-MM-DD');
const month = dayjs(new Date()).startOf('month').format('YYYY-MM-DD');
const lastMonth = dayjs(month).subtract(1, 'day').format('YYYY-MM-DD');


class AboutUsPage extends BasePage<IAboutUsPageProps, IAboutUsPageState> {
  routeParams: IObject = {};

  state: IAboutUsPageState = {
    loading: true,
    selectStore: undefined,
    invoices: {},
    selectedID: 1,
    dateStart: '',
    dateEnd: '',
    selectStoreSlug: '',
    order: {},
    timeList: {
      'all': {
        tabName: getIntl().page.all,
        dateStart: '',
        dateEnd: '',
        timeType: 'all',
      },
      'today': {
        tabName: getIntl().common.today,
        dateStart: today,
        dateEnd: '',
        timeType: 'today',
      },
      'week': {
        tabName: getIntl().page.thisWeek,
        dateStart: week,
        dateEnd: today,
        timeType: 'week',
      },
      'month': {
        tabName: getIntl().page.thisMonth,
        dateStart: month,
        dateEnd: today,
        timeType: 'month',
      },
      'year': {
        tabName: getIntl().page.monthAgo,
        dateStart: '',
        dateEnd: lastMonth,
        timeType: 'year',
      }
    },
  }

  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.lightBackgroundColor) {
      setScreenColor(appTheme.lightBackgroundColor);
    }
    super.componentDidMount();
    const { timeList } = this.state;
    const { dispatch, reducersState, routeParams } = this.props;
    const { selectStoreId, selectStoreSlug } = reducersState.supplierInfo;
    if (selectStoreId) {
      this.setState({
        selectStoreSlug,
      })
      dispatch({
        type: ActionType.QUERY_DEFERRED_INVOICES,
        storeID: selectStoreId.toString(),
        timeType: routeParams.timeType,
        dateStart: timeList[routeParams.timeType].dateStart,
        dateEnd: timeList[routeParams.timeType].dateEnd,
        callback: (invoices: any) => {
          
          const order = findNode<any>(invoices[routeParams.storeID].orders, 'orderNumber', routeParams.orderNumber);
          this.setState({
            invoices: invoices[routeParams.storeID],
            order,
          })
        }
      });
      
    }
  }

  handlePay = () => {
    const { dispatch, reducersState, routeParams } = this.props;
    const { platformConfig } = reducersState.app;
    
    dispatch({
      type: ActionType.CREATE_DEFERRED_INVOICES,
      storeID: routeParams.orderNumber === 'all' ? platformConfig.storeID : this.state.order.storeID,
      storeSlug: routeParams.orderNumber === 'all' ? platformConfig.storeSlug : this.state.order.storeSlug,
      deferredIDs: routeParams.orderNumber === 'all' ? this.state.invoices.orderIDs : [],
      orderNumber: routeParams.orderNumber === 'all' ? '' : routeParams.orderNumber,
      callback: (message: string, invoices: any) => {
        if (message) {
          Modal.alert('', message, [
            {
              text: getIntl().common.confirm,
              onPress: () => {},
            }
          ]);
        } else {
          window.location.href = invoices;
        }
      }
    });
  }


  render() {
    const { routeParams } = this.props;
    const { invoices, selectStoreSlug } = this.state;
    return (
      <>
        <Layout
          {...this.props}
          className={prefix}
          navContent={getIntl().page.orderDetail}
          navIcon={
            <Icon className="back-icon" type={IconType.LEFT_OUTLINED} />
          }
          onLeftClick={() => {
            this.handleGotoRoute({
              path: paths.DEFERRED_INVOICES,
              pathParams: {
                storeSlug: selectStoreSlug
              }
            });
          }}
          isFooter={false}
        >
          <div className="background-top red" />
          <div className="section birdymart wf-section">
            <div className="container top w-container">
              <div className="div-block-485 _1">
                <div className="left-block _58">
                  {
                    routeParams.orderNumber === 'all' && invoices.orders && invoices.orders.map((item: any) => (
                      <div className="po-history-block gap deffered-order merge" key={item.supplierStoreID}>
                        <div className="div-block-652 head deffered-oder-detail merge">
                          <div className="div-block-652 _100 margin">
                            <div className="deffered-invoices-supplier black">{item.orderNumber}</div>
                            <div className="status margin">{item.state}</div>
                          </div>
                          <div className="div-block-653 _100 merge">
                            {/* <div className="div-block-484">
                              <div className="item-name">Account Manager</div>
                              <div className="text-block-68">Giang Ong</div>
                            </div> */}
                            <div className="div-block-484">
                              <div className="item-name">{getIntl().page.expectedDeliveryDate}</div>
                              <div className="text-block-68">{dayjs(item.deliveryDate).format('YYYY-MM-DD')}</div>
                            </div>
                            {/* <div className="div-block-484">
                              <div className="item-name">Item Quantity</div>
                              <div className="text-block-68">13</div>
                            </div> */}
                            <div className="div-block-484">
                              <div className="item-name">{getIntl().page.paymentMethod}</div>
                              <div className="text-block-68">{getIntl().pay.deferredBilling}</div>
                            </div>
                            <div className="div-block-484">
                              <div className="item-name">{getIntl().common.subtotal}</div>
                              <div className="text-block-68">{formatCurrency(item.subTotal, item.currencyCode)}</div>
                            </div>
                            <div className="div-block-484">
                              <div className="item-name">{getIntl().common.taxFee}</div>
                              <div className="text-block-68">{formatCurrency(item.initialTax, item.currencyCode)}</div>
                            </div>
                            <div className="div-block-484">
                              <div className="item-name">{getIntl().common.serviceFee}</div>
                              <div className="text-block-68">{formatCurrency(item.initialServiceFee, item.currencyCode)}</div>
                            </div>
                            <div className="div-block-484">
                              <div className="item-name bold">{getIntl().common.total}</div>
                              <div className="text-block-68 bold">{formatCurrency(item.initialTotal, item.currencyCode)}</div>
                            </div>
                          </div>
                        </div>
                        <div className="div-block-572 with-spacing deferred-order merge">
                          {
                            item.orderEntities[0].lineItems.map((jitem: any) => (
                              <div className="div-block-482-copy with-space" key={jitem.id}>
                                <div className="div-block-481-copy" style={{ backgroundImage: `url(${jitem.imageUrl ? jitem.imageUrl : Config.defaultLineItemImg })`}}></div>
                                <div className="div-block-578">
                                  <div className="item-info checkout-process">
                                    <div className="div-block-652">
                                      <h4 className="heading-4-1 checkout-process">{jitem.name}</h4>
                                      <div className="_107">X {jitem.quantity}</div>
                                    </div>
                                  </div>
                                  <div className="price-cart">
                                    <div className="text-block-223">{formatCurrency(jitem.price, jitem.currencyCode)}</div>
                                    <div className="text-block-6 _18 rightalign">{getIntl().common.total}:{formatCurrency(jitem.total, jitem.currencyCode)}</div>
                                  </div>
                                </div>
                              </div>
                            ))
                          }
                        </div>
                      </div>

                    ))
                  }
                  {
                    routeParams.orderNumber !== 'all' &&
                    <div className="po-history-block gap deffered-order merge">
                      <div className="div-block-572 with-spacing deferred-order merge">
                        {
                          this.state.order !== '{}' && this.state.order.orderEntities && this.state.order.orderEntities[0].lineItems.map((jitem: any) => (
                            <div className="div-block-482-copy with-space" key={jitem.id}>
                              <div className="div-block-481-copy" style={{ backgroundImage: `url(${jitem.imageUrl ? jitem.imageUrl : Config.defaultLineItemImg })`}}></div>
                              <div className="div-block-578">
                                <div className="item-info checkout-process">
                                  <div className="div-block-652">
                                    <h4 className="heading-4-1 checkout-process">{jitem.name}</h4>
                                    <div className="_107">X {jitem.quantity}</div>
                                  </div>
                                 
                                </div>
                                <div className="price-cart">
                                  <div className="text-block-223">{formatCurrency(jitem.price, jitem.currencyCode)}</div>
                                  <div className="text-block-6 _18 rightalign">{getIntl().common.total}:{formatCurrency(jitem.total, jitem.currencyCode)}</div>
                                </div>
                              </div>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  }

                </div>
                <div className="right-block-birdymart">
                  <div className="info-tool">
                    <div className="order-summary-block with-spacing">
                      <div className="div-block-506 _1">
                        <div className="text-block-101 red">{this.state.invoices.supplierStoreName}</div>
                      </div>
                      <div className="div-block-493 no-line less">
                        {
                          routeParams.orderNumber !== 'all' &&
                          <>
                            <div className="div-block-484">
                              <div className="text-block-98">{getIntl().page.status}</div>
                              <div className="status">{this.state.order.state}</div>
                            </div>
                            {/* <div className="div-block-484">
                              <div className="item-name">Account Manager</div>
                              <div className="text-block-68">Giang Ong</div>
                            </div> */}
                            <div className="div-block-484">
                              <div className="item-name">{getIntl().page.expectedDeliveryDate}</div>
                              <div className="text-block-68">{dayjs(this.state.order.deliveryDate).format('YYYY-MM-DD')}</div>
                            </div>
                            {/* <div className="div-block-484">
                              <div className="item-name">Item Quantity</div>
                              <div className="text-block-68">13</div>
                            </div> */}
                            <div className="div-block-484">
                              <div className="item-name">{getIntl().page.paymentMethod}</div>
                              <div className="text-block-68">{getIntl().pay.deferredBilling}</div>
                            </div>
                          </>
                        }
                        <div className="div-block-484 item">
                          <div className="item-name">{getIntl().common.subtotal}</div>
                          <div className="text-block-68">{formatCurrency(routeParams.orderNumber === 'all' ? this.state.invoices.subTotal : this.state.order.subTotal, this.state.invoices.currencyCode || this.state.order.currencyCode)}</div>
                        </div>
                        <div className="div-block-484">
                          <div className="item-name">{getIntl().common.taxFee}</div>
                          <div className="text-block-68">{formatCurrency(routeParams.orderNumber === 'all' ? this.state.invoices.initialTax : this.state.order.initialTax, this.state.invoices.currencyCode || this.state.order.currencyCode)}</div>
                        </div>
                        <div className="div-block-484">
                          <div className="item-name">{getIntl().common.serviceFee}</div>
                          <div className="text-block-68">{formatCurrency(routeParams.orderNumber === 'all' ? this.state.invoices.initialServiceFee : this.state.order.initialServiceFee, this.state.invoices.currencyCode || this.state.order.currencyCode)}</div>
                        </div>
                        <div className="div-block-484 item">
                          <div className="item-name bold">{getIntl().common.total}</div>
                          <div className="text-block-68 bold">{formatCurrency(routeParams.orderNumber === 'all' ? this.state.invoices.initialTotal : this.state.order.initialTotal, this.state.invoices.currencyCode || this.state.order.currencyCode)}</div>
                        </div>
                      </div>
                      <div className="div-block-507 with-space w-inline-block" onClick={this.handlePay}>
                        <div className="primary-payment-confirmation-buttons red">
                          <div className="text-block-17">{getIntl().page.payNow}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="make-payment">
            <div className="bottom-nav cart-process wf-section">
              <div className="container bottom _20px w-container">
                <div className="div-block-509 w-inline-block" onClick={this.handlePay}>
                  <div className="primary-payment-confirmation-buttons red">
                    <div className="text-block-17">{getIntl().page.payNow}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </>
    );
  }
}

export default AboutUsPage;
