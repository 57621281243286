import { takeEvery, put, select, call } from 'redux-saga/effects';
import { IReducersState } from '@/reducers';
import Action from '@/actions';
import ActionType from '@/actions/action-type';
import { IStore, IQueryDeferredInvoices, ICreateInvoices } from '@/actions/store-action';
import { IQueryuserAssociateStores } from '@/actions/supplier-info-action';
import { queryUserStoreAssociates } from '@/data/StoreSetting';
import { parseResStore } from '@/utils/store-util';
import axios from 'axios';
import Config from '@/Config';
import { parseDeferredInvoices } from '@/utils/deferred-invoices-util';
import { IAppState } from '@/reducers/app-reducer';
import { getDomain, IPayInfoParams, setPlatformPayInfo, createRouteUrl } from '@/utils';
import { getAccessToken } from '@/utils/app';
import paths from '@/routes/paths';
import Constants from '@/constants';

function* handleQueryUserAssociateStores (params: IQueryuserAssociateStores) {
  const state: IReducersState = yield select((state: IReducersState) => state);
  const { newRequest, callback } = params;
  let resStores: IStore[] = [];
  const associateTypesMap = new Map();

  try {
    const storeList = [...state.supplierInfo.userAssociateStoreMap.values()];
    if (storeList.length > 0 && !newRequest) {
      resStores = storeList;
    } else {
      const userStoreAssociates = yield queryUserStoreAssociates();
      if (Array.isArray(userStoreAssociates) && userStoreAssociates.length > 0) {
        userStoreAssociates.forEach(item => {
          if (item.store) {
            const store = parseResStore(item.store);
            if (store) {
              resStores.push(store);

              if (item.associateTypes) {
                associateTypesMap.set(store.slug, item.associateTypes);
              }
            }
          }
        });
      }

      yield put<Action>({
        type: ActionType.SET_SUPPLIER_INFO_STORES,
        stores: resStores,
      });
    }
    if (callback) callback(resStores, associateTypesMap);
  } catch (error) {
    console.error(error);
  }
}

function* handleQueryDeferredInvoices(params: IQueryDeferredInvoices) {
  const { storeID, dateStart = '', dateEnd = '', timeType, callback } = params;
  const appState: IAppState = yield select((state: IReducersState) => state.app);
  let invoices;
  let param = '';
  if (dateStart && dateEnd) {
    param = `?date_start=${dateStart}&date_end=${dateEnd}`;
  } else if (dateStart) {
    param = `?date_start=${dateStart}`
  } else if (dateEnd) {
    param = `?date_end=${dateEnd}`
  }
  try {
    if (appState.deferredInvoices[timeType]) {
      invoices = appState.deferredInvoices[timeType]
    } else {
      const res = yield call(
        axios.get,
        `${Config.urls.queryDeferredInvoices.replace('{storeID}', storeID)}${param}`,
      );
      invoices = parseDeferredInvoices(res);
      yield put<Action>({
        type: ActionType.SET_DEFERRED_INVOICES,
        timeType: timeType,
        deferredInvoices: invoices,
        storeID: '',
      });
    }
  } catch (error) {
    console.error(error);
  }
  if (callback) {
    callback(invoices)
  }
  return invoices;
}

function* handleCreateInvoices(params: ICreateInvoices) {
  const { storeID, storeSlug, deferredIDs, orderNumber, callback } = params;
  const appState: IAppState = yield select((state: IReducersState) => state.app);
  const state: IReducersState = yield select((state: IReducersState) => state);
  let invoices;
  // let ids = '?';
  // deferredIDs.forEach(v => {
  //   ids += `deferred_ids[]=${v}&`;
  // });
  try {
    // const customer = yield call(
    //   axios.get,
    //   Config.urls.customer.replace('{storeSlug}', storeSlug),
    // );
    let res;
    if (deferredIDs.length > 0 && !orderNumber) {
      res = yield call(
        axios.post,
        `${Config.urls.createInvoice.replace('{storeID}', storeID)}`,
        {
          'deferred_ids': deferredIDs,
        }
      );
    }
    const {
      platform,
      gatewayDomain,
      platformDomain,
      domain,
      isLocalhost,
      platformHost,
    } = getDomain();
    let gDomain = platform ? domain : gatewayDomain;
    if (isLocalhost) {
      gDomain = platformHost;
    }

    const payInfo: IPayInfoParams = {
      token: getAccessToken() || '',
      domain: platformDomain,
      lang: appState.language,
      storeID: storeID,
      storeSlug: storeSlug,
      routeStoreSlug: storeSlug, //下单时路由上的store slug
      paymentData: {
        deferredOrder: true,
        selectStoreSlug: state.supplierInfo.selectStoreSlug,
      },
      buyerStoreID: `${state.supplierInfo.selectStoreId}`,
    }

    const payInfoString = setPlatformPayInfo(payInfo);

    const payInfoUrl = createRouteUrl(
      paths.ORDER_CONFIRM,
      {
        orderNumber: orderNumber ? orderNumber : res,
        storeSlug,
        [Constants.PLATFORM]: platform,
        payInfo: payInfoString,
      }
    );

    const orderConfirmUrl = `http${isLocalhost ? '' : 's'}://${gDomain}` + payInfoUrl;
    let message = '';
    console.info('res', res);
    if (res && res.data && res.data.origin_error) {
      message = res.data.message
    } else if (res && res.data && res.data.message) {
      message = res.data.message;
    }
    if (callback) {
      callback(message, orderConfirmUrl)
    }
    // invoices = parseDeferredInvoices(res);
    // yield put<Action>({
    //   type: ActionType.SET_DEFERRED_INVOICES,
    //   timeType: timeType,
    //   deferredInvoices: invoices,
    //   storeID: '',
    // });
  } catch (error) {
    console.error(error);
  }
  // if (callback) {
  //   callback(invoices)
  // }
  return invoices;
}


function* supplierInfoSage() {
  yield takeEvery(ActionType.QUERY_USER_ASSOCIATE_STORES, handleQueryUserAssociateStores);
  yield takeEvery(ActionType.QUERY_DEFERRED_INVOICES, handleQueryDeferredInvoices);
  yield takeEvery(ActionType.CREATE_DEFERRED_INVOICES, handleCreateInvoices);
}
export default supplierInfoSage();
