import React, { useEffect, useState } from 'react';
import Icon, { IconType } from '../Icon';
import HistoryItem from './HistoryItem';
import { prefix } from '.';
import { getIntl } from '../App/App';

interface ISearchContentProps {
  historyData: string[];
  onHistoryItemClick: (searchKey: string) => void;
  onClear: () => void;
}

const SearchContent: React.FC<ISearchContentProps> = props => {

  const {
    historyData,
    onHistoryItemClick,
    onClear,
  } = props;

  const [values, setValues] = useState<string[]>([]);

  useEffect(() => {
    setValues(historyData);
  }, [historyData]);

  return (
    <div className={`${prefix}`}>
      <div className="tilte-row">
        <h2 className="h2 black-1">{getIntl().page.history}</h2>
        <div className="scanner_block">
          <Icon
            type={IconType.RECYCLE_BIN_OUTLINED}
            className="html-embed-32 w-embed"
            onClick={() => {
              onClear();
              setValues([]);
            }}
          />
        </div>
      </div>
      <div className="div-block-648">
        {
          values.map((item: string) => (
            <HistoryItem
              key={item}
              data={item}
              onItemClick={onHistoryItemClick}
            />
          ))
        }
      </div>
    </div>
  )
}
export default SearchContent;

