import React, { useEffect, useState } from 'react';
import { prefix } from '.';
import Icon, { IconType } from '@/components/Icon';
import Accordion from 'antd-mobile/es/accordion';
import List from 'antd-mobile/es/list';
import paths from '@/routes/paths';
import Constants from '@/constants';
import { goToRoute } from '@/utils/route-util';
import { createRouteUrl } from '@/utils';
import { TransitionKeys } from '../Transition';
import { useHistory } from 'react-router-dom';
import SideBarItem from './SideBarItem';
import { IReducersState } from '@/reducers';
import SidebarOption from '@/actions/sidebar-option';
import { useDispatch } from 'react-redux';
import { IDispatch } from '@/actions';
import ActionType from '@/actions/action-type';
import { getI18nText, getAccessToken, langFormat } from '@/utils/app';
import { IObject } from '@/components/bm-common';
import { getIntl } from '../App/App';

interface ISettingItemProps {
  onItemClick?: () => void;
  showSideBar: boolean;
  reducersState: IReducersState;
  currentData?: boolean;
  className?: string;
}

const SideBar: React.FC<ISettingItemProps> = props => {
  const dispatch = useDispatch<IDispatch>();
  const { onItemClick, showSideBar, reducersState, currentData = false, className } = props;
  const history = useHistory();
  const [membershipLevel, setMembershipLevel] = useState<string>('');
  const [membershipLevelColor, setMembershipLevelColor] = useState<string>('');
  const onSideBarItemClick = (pathname: string, pathParams?: IObject) => {
    if (onItemClick) {
      onItemClick();
    }
    pathname = createRouteUrl(
      pathname,
      pathParams,
      // props,
    );
    const currentPathname = window.location.pathname;
    if (((pathname === paths.BOOKINGS || pathname === paths.RECORDS || pathname === paths.ACCOUNT_EDIT || pathname === paths.POINTS) && !getAccessToken())) {
      goToRoute({
        history,
        pathname: paths.SIGN_IN,
        clearPaths: false,
        animationType: TransitionKeys.MOVE_FROM_RIGHT,
      });
    } else if (pathname !== currentPathname) {
      goToRoute({
        history,
        pathname,
        clearPaths: false,
        animationType: TransitionKeys.MOVE_FROM_RIGHT,
      });
    }
  }
  const handleGotoRoute = (path: string, pathParams: IObject) => {
    goToRoute({
      history,
      pathname: createRouteUrl(path, pathParams),
      clearPaths: false,
      animationType: TransitionKeys.MOVE_FROM_RIGHT,
    });
  }
  useEffect(() => {
    const { platformConfig } = reducersState.app;
    if (getAccessToken() && !currentData && platformConfig.showMembersAreaTab) {
      dispatch({
        type: ActionType.CANCEL_QUERY_CUSTOMER_GROWTH_TASK_SYNC,
      });
      handleloadMembership();
    }
  }, []);
  const handleloadMembership = () => {
    const { platformConfig, language } = reducersState.app;
    const { storeSlug, storeID, closeRegisterEmail } = platformConfig;
    dispatch({
      type: ActionType.QUERY_CUSTOMER,
      storeSlug,
      closeRegisterEmail,
      callback: customer => {
        if (customer) {
          dispatch({
            type: ActionType.QUERY_CUSTOMER_GROWTH,
            storeSlug,
            storeID,
            customerID: customer.id,
            callback: membershipData => {
              if (membershipData && membershipData.currentMembershipLevel) {
                setMembershipLevel(getI18nText(membershipData.currentMembershipLevel, 'abbreviation', language) || (getI18nText(membershipData.currentMembershipLevel, 'title', reducersState.app.language) || membershipData.currentMembershipLevel.title));
                setMembershipLevelColor(membershipData.currentMembershipLevel.membershipLevelColor)
              }
            }
          });
        }
      }
    });
  }
  const handleGotoAboutUs = () => {
    const { platformConfig } = reducersState.app;
    const { storeSlug } = platformConfig;
    dispatch({
      type: ActionType.LOADING,
      showLoading: true,
    })
    dispatch({
      type: ActionType.QUERY_BLOG_LIST,
      contentType: 'COMPANY_INFO',
      storeSlug,
      callback: blogs => {
        if (blogs.length === 1) {
          handleGotoRoute(
            paths.BLOG_DETAIL,
            {
              storeSlug,
              blogID: blogs[0].id,
              contentType: 'about-us',
            }
          )
        } else {
          onSideBarItemClick(paths.ABOUT_US);
        }
        dispatch({
          type: ActionType.LOADING,
          showLoading: false,
        })
      }
    })
  }
  const stringUserInfo = localStorage.getItem(Constants.USER_INFO);
  let userInfo = null;
  if (stringUserInfo) {
    userInfo = JSON.parse(stringUserInfo);
  }
  const { platformConfig, platformStoreCategories, language } = reducersState.app;
  const { selectStoreSlug } = reducersState.supplierInfo;
  const { sidebarOptions, abbreviation, showMembersAreaTab, storeSlug } = platformConfig;
  const findAppTabsIcon = (key: string) => {
    const { appIcons } = reducersState.app;
    if (appIcons && appIcons.length > 0) {
      const appIcon = appIcons.find(item => item.iconType === key);
      return appIcon;
    }
  }
  const memberIcon = findAppTabsIcon('MEMBERS_OUTLINED');
  const storeIcon = findAppTabsIcon('STORE_OUTLINED');
  const { slugRefCustomerGrowth } = reducersState.user;
  const membershipData = slugRefCustomerGrowth[storeSlug];
  let currenMembershipLevel = '';
  let currentMembershipLevelColor = '';
  if(currentData && membershipData) {
    currenMembershipLevel = getI18nText(membershipData.currentMembershipLevel, 'abbreviation', language) || getI18nText(membershipData.currentMembershipLevel, 'title', language) || membershipData.currentMembershipLevel.title;
    currentMembershipLevelColor = (membershipData.currentMembershipLevel.membershipLevelColor)
  }

  return (
    <div className={`${prefix} ${showSideBar ? 'show-side-bar-wrap' : ''} ${className ? className : ''}`}>
      <div className={`side-bar-mask ${showSideBar ? 'show-side-bar-mask' : ''}`} onClick={onItemClick} />
      <div className={`side-bar-content ${showSideBar ? 'show-side-bar-content' : ''}`} id="sideBar">
        <div className="w-container">
          <div className="user-msg-wrap">
            {
              !getAccessToken() &&
              <>
                <div className="avatar-icon-wrap" onClick={() => onSideBarItemClick(paths.SIGN_IN)}>
                  <Icon
                    type={IconType.DEFAULT_AVATAR_OUTLINED}
                    className="avatar-icon"
                  />
                </div>
                <div className="login-in-tips text-block-15" onClick={() => onSideBarItemClick(paths.SIGN_IN)}>
                  {getIntl().page.loginIn}
                </div>
              </>
            }
            {
              getAccessToken() &&
              <>
                <div className="avatar-icon-wrap">
                  {
                    userInfo.avatarUrl &&
                    <div className="avatar" style={{ backgroundImage: `url(${userInfo.avatarUrl})` }} />
                  }
                  {
                    !userInfo.avatarUrl &&
                    <Icon
                      type={IconType.DEFAULT_AVATAR_OUTLINED}
                      className="avatar-icon"
                    />
                  }
                </div>
                <div>
                  <div className="login-in-tips">{userInfo.name}</div>
                  <div className="membership-name" style={{ backgroundColor: membershipLevelColor || currentMembershipLevelColor }}>{membershipLevel || currenMembershipLevel}</div>
                </div>
              </>
            }
          </div>
          <div className="side-bar-list">
            {
              sidebarOptions && sidebarOptions.includes(SidebarOption.EXPRSESS_ORDER) &&
              <SideBarItem
                iconType={IconType.EXPRSESS_ORDER_OUTLINED}
                title={getIntl().page.expressOrderTitle}
                onClick={() => onSideBarItemClick(paths.EXPRESS_ORDER, { storeSlug })}
                appIcon={findAppTabsIcon('EXPRSESS_ORDER_OUTLINED')}
              />
            }
            {
              sidebarOptions && sidebarOptions.includes(SidebarOption.PURCHASE_ORDER) &&
              <SideBarItem
                iconType={IconType.RECORDS_OUTLINED}
                title={getIntl().page.purchaseOrderTitle}
                onClick={() => onSideBarItemClick(paths.PURCHASE_ORDER, { storeSlug: selectStoreSlug })}
                appIcon={findAppTabsIcon('RECORDS_OUTLINED')}
              />
            }
            {
              sidebarOptions && sidebarOptions.includes(SidebarOption.PURCHASE_ORDER) &&
              <SideBarItem
                iconType={IconType.DEFERRED_INVOICE_OUTLINED}
                title={getIntl().page.deferredInvoices}
                onClick={() => onSideBarItemClick(paths.DEFERRED_INVOICES, { storeSlug: selectStoreSlug })}
                appIcon={findAppTabsIcon('DEFERRED_INVOICE_OUTLINED')}
              />
            }
            {
              sidebarOptions && sidebarOptions.includes(SidebarOption.GOOD_RECEIVED_NOTES) &&
              <SideBarItem
                iconType={IconType.GRN_OUTLINED}
                title={getIntl().page.goodReceivedNotesTitle}
                onClick={() => onSideBarItemClick(paths.GOOD_RECEIVED_NOTES, { storeSlug: selectStoreSlug })}
                appIcon={findAppTabsIcon('GRN_OUTLINED')}
              />
            }
            {
              sidebarOptions && sidebarOptions.includes(SidebarOption.RETURN_NOTES) &&
              <SideBarItem
                iconType={IconType.RN_OUTLINED}
                title={getIntl().page.returnNotesTitle}
                onClick={() => onSideBarItemClick(paths.RETURN_NOTES, { storeSlug: selectStoreSlug })}
                appIcon={findAppTabsIcon('RN_OUTLINED')}
              />
            }
            <Accordion className="my-accordion" accordion openAnimation={{}}>
              {
                sidebarOptions && sidebarOptions.includes(SidebarOption.MEMBERSHIP) &&
                <Accordion.Panel
                  key="membership"
                  header={
                    <div className="my-accordion-list">
                      {
                        memberIcon && memberIcon.svg &&
                        <div className="bar-item-icon bm-icon" dangerouslySetInnerHTML={{ __html: memberIcon.svg || '' }} />
                      }
                      {
                        !memberIcon &&
                        <Icon
                          type={IconType.MEMBERS_OUTLINED}
                        />
                      }
                      <div>
                        {langFormat(getIntl().page.tggMembers, { abbreviation })}
                      </div>
                    </div>
                  }
                >
                  <List className="my-list">
                    {
                      sidebarOptions && sidebarOptions.includes(SidebarOption.ACCOUNT) &&
                      <List.Item onClick={() => onSideBarItemClick(showMembersAreaTab ? paths.MEMBERSHIP : paths.ACCOUNT)}>
                        {getIntl().page.myAccount}
                      </List.Item>
                    }
                    {
                      sidebarOptions && sidebarOptions.includes(SidebarOption.MY_PROFILE) &&
                      <List.Item onClick={() => onSideBarItemClick(paths.ACCOUNT_EDIT)}>
                        {getIntl().page.myProfile}
                      </List.Item>
                    }
                    {
                      sidebarOptions && sidebarOptions.includes(SidebarOption.PRIVILEGES) &&
                      <List.Item onClick={() => onSideBarItemClick(paths.PRIVILEGE)}>
                        {getIntl().page.myPrivilege}
                      </List.Item>
                    }
                    {
                      sidebarOptions && sidebarOptions.includes(SidebarOption.DOLLAR) &&
                      <List.Item onClick={() => onSideBarItemClick(paths.POINTS)}>
                        {langFormat(getIntl().page.tggDollars, { abbreviation })}
                      </List.Item>

                    }
                  </List>
                </Accordion.Panel>
              }
              {
                sidebarOptions && sidebarOptions.includes(SidebarOption.STORE) &&
                <Accordion.Panel
                  key="dine"
                  header={
                    <div className="my-accordion-list">
                      {
                        storeIcon && storeIcon.svg &&
                        <div className="bar-item-icon bm-icon" dangerouslySetInnerHTML={{ __html: storeIcon.svg || '' }} />
                      }
                      {
                        !storeIcon &&
                        <Icon
                          type={IconType.STORE_OUTLINED}
                        />
                      }
                      <div>
                        {getIntl().page.dine}
                      </div>
                    </div>
                  }
                >
                  <List className="my-list">
                    {
                      sidebarOptions && sidebarOptions.includes(SidebarOption.TABLE_BOOKING) &&
                      <List.Item onClick={() => {
                        if (platformStoreCategories[0]) {
                          handleGotoRoute(paths.BRAND_LIST, {byType: 'booking', categoryID: platformStoreCategories[0].id})
                        } else {
                          handleGotoRoute(paths.STORE_LIST_BY_CATEGORY, {byType: 'booking', categoryID: 0, brandID: 0, fromPage: 'brands'});
                        }
                      }}>
                        {getIntl().page.tableBooking}
                      </List.Item>
                    }
                    {
                      sidebarOptions && sidebarOptions.includes(SidebarOption.FOOD_ORDERING) &&

                      <List.Item onClick={() => {
                        if (platformStoreCategories[0]) {
                          handleGotoRoute(paths.BRAND_LIST, {byType: 'foodOrdering', categoryID: platformStoreCategories[0].id})
                        } else {
                          handleGotoRoute(paths.STORE_LIST_BY_CATEGORY, {byType: 'food_ordering', categoryID: 0, brandID: 0, fromPage: 'brands'})
                        }
                      }}>
                        {getIntl().page.foodOrdering}
                      </List.Item>
                    }
                  </List>
                </Accordion.Panel>
              }
            </Accordion>
            {
              sidebarOptions && sidebarOptions.includes(SidebarOption.PROMOTIONS) &&

              <SideBarItem
                iconType={IconType.PROMOTIONS_OUTLINED}
                title={getIntl().page.promotionsTitle}
                onClick={() => onSideBarItemClick(paths.NEWS)}
                appIcon={findAppTabsIcon('PROMOTIONS_OUTLINED')}
              />
            }
            {
              sidebarOptions && sidebarOptions.includes(SidebarOption.SHOPPING) &&
              <SideBarItem
                iconType={IconType.SHOPPING_OUTLINED}
                title={getIntl().page.shopping}
                onClick={() => onSideBarItemClick(paths.ESHOP)}
                appIcon={findAppTabsIcon('SHOPPING_OUTLINED')}
              />
            }
            {
              sidebarOptions && sidebarOptions.includes(SidebarOption.ABOUT_US) &&
              <SideBarItem
                iconType={IconType.ABOUT_US_OUTLINED}
                title={langFormat(getIntl().page.aboutTGG, { abbreviation })}
                onClick={() => handleGotoAboutUs()}
                appIcon={findAppTabsIcon('ABOUT_US_OUTLINED')}
              />
            }
            {
              sidebarOptions && sidebarOptions.includes(SidebarOption.SETTINGS) &&
              <SideBarItem
                iconType={IconType.SETTING_OUTLINED}
                title={getIntl().page.setting}
                onClick={() => onSideBarItemClick(paths.SETTING)}
                appIcon={findAppTabsIcon('SETTING_OUTLINED')}
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideBar;
