import React from 'react';
import Icon, { IconType } from '@/components/Icon';
import { PanelBarStyled } from './styled';
import { getIntl } from '@/components/App/App';

interface IPanelBarProps {
  title: React.ReactNode;
  showRight?: boolean;
  onClick?: () => void;
}

const PanelBar: React.FC<IPanelBarProps> = props => {
  const {
    title,
    showRight = false,
    onClick,
  } = props;

  return (
    <PanelBarStyled>
      <div className="panel-bar">
        <div className="bar-left">{title}</div>
        {
          showRight &&
          <div className="bar-right" onClick={onClick}>
            <div className="bar-right-text">
              {getIntl().page.viewAll}
            </div>
            <Icon type={IconType.RIGHT_OUTLINED} />
          </div>
        }
      </div>
    </PanelBarStyled>
  );
}

export default PanelBar;
