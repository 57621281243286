
import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import { IObject } from '@/components/bm-common';
import { getAppTheme, setScreenColor, getIntl } from '@/components/App/App';
import Layout from '@/components/Layout/Layout';
import { prefix } from '.';
import BmTabBar from '@/components/BmTabBar';
import ActionType from '@/actions/action-type';
import { IBlog } from '@/actions/store-action';
import NewsList from '@/components/NewsList';
import paths from '@/routes/paths';
import TabsOption from '@/actions/tabs-option';
import Icon, { IconType } from '@/components/Icon';

export interface IAboutUsPageProps extends IBasePageProps { }
export interface IAboutUsPageState {
  list: IBlog[];
  loading: boolean;
}

class AboutUsPage extends BasePage<IAboutUsPageProps, IAboutUsPageState> {
  routeParams: IObject = {};

  state: IAboutUsPageState = {
    list: [],
    loading: true,
  }

  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.lightBackgroundColor) {
      setScreenColor(appTheme.lightBackgroundColor);
    }
    super.componentDidMount();
    this.getBlogList('COMPANY_INFO');
  }

  getBlogList = (type: string) => {
    const { dispatch, reducersState } = this.props;
    const { platformAppSettings } = reducersState.app;
    if (platformAppSettings && platformAppSettings.storeSlug) {
      dispatch({
        type: ActionType.LOADING,
        showLoading: true,
      });
      dispatch({
        type: ActionType.QUERY_BLOG_LIST,
        contentType: type,
        storeSlug: platformAppSettings.storeSlug,
        callback: list => {
          this.setState({
            list,
            loading: false,
          });
          dispatch({
            type: ActionType.LOADING,
            showLoading: false,
          });
        }
      })
    }
  }

  handleGoDetail = (data: IBlog) => {
    const { reducersState } = this.props;
    const { platformAppSettings } = reducersState.app;
    if (platformAppSettings) {
      const { storeSlug } = platformAppSettings;
      this.handleGotoRoute({
        path: paths.BLOG_DETAIL,
        pathParams: {
          storeSlug,
          blogID: data.id,
          contentType: 'about-us',
        },
      });
    }

  }


  render() {
    const { reducersState } = this.props;
    const { platformConfig } = reducersState.app;
    const { list, loading } = this.state;
    return (
      <>
        <Layout
          {...this.props}
          className={prefix}
          navContent={getIntl().page.aboutUsTitle}
          navIcon={
            !platformConfig.showAboutUsTab &&
            <Icon className="back-icon" type={IconType.LEFT_OUTLINED} />
          }
          onLeftClick={() => {
            if (!platformConfig.showAboutUsTab) {
              this.handleGoBack({ path: paths.HOME, runGoBack: true });
            }
          }}
          isFooter={false}
        >
          {
            list.length > 0 &&
            <NewsList data={list} onItemClick={this.handleGoDetail} reducersState={reducersState} />
          }
          {
            !loading && list.length <= 0 &&
            <div className="no-content-panel">
              <Icon type={IconType.TAG_ITEM} />
              <div className="no-content-info">
                {getIntl().page.noDataTips}
              </div>
            </div>
          }
        </Layout>
        {
          platformConfig.showTabs &&
          platformConfig.showAboutUsTab &&
          <BmTabBar
            selectedKey={TabsOption.ABOUT_US}
            reducersState={reducersState}
          />
        }
      </>
    );
  }
}

export default AboutUsPage;
