import React, { useState } from 'react';
import { prefix } from '.';
import BmTabBarItem from './BmTabBarItem';
import { IconType } from '../Icon';
import { useHistory } from 'react-router-dom';
import paths from '@/routes/paths';
import HomeType from '@/actions/home-type';
import { createRouteUrl } from '@/utils';
import { IReducersState } from '@/reducers';
import { goToRoute, } from '@/utils/route-util';
import { TransitionKeys } from '../Transition';
import TabsOption from '@/actions/tabs-option';
import Constants from '@/constants';
import { getI18nText, getAccessToken } from '@/utils/app';
import { IObject } from '../bm-common';
import { getIntl } from '../App/App';

export interface IBmTabBarProps {
  selectedKey: TabsOption;
  reducersState: IReducersState;
  className?: string;
}

const BmTabBar: React.FC<IBmTabBarProps> = props => {
  const {
    selectedKey,
    reducersState,
    className,
  } = props;

  const history = useHistory();

  const [selectedCurrentKey, setSelectedKey] = useState<TabsOption>(selectedKey);

  const { platformConfig, homeType, deviceInfo, showSplash, splashData } = reducersState.app;

  const handleClick = (params: {
    path: string;
    tabOption: TabsOption;
    pathParams?: IObject;
  }) => {

    const { path, tabOption, pathParams } = params;

    setSelectedKey(tabOption);

    const pathname = createRouteUrl(
      path,
      pathParams,
      // props,
    );
    const currentPathname = window.location.pathname;
    if (pathname === paths.MY_ID && !getAccessToken()) {
      goToRoute({
        history,
        pathname: paths.SIGN_IN,
        clearPaths: false,
        animationType: TransitionKeys.NONE,
      });
    } else if (pathname !== currentPathname) {
      goToRoute({
        history,
        pathname,
        clearPaths: true,
        animationType: TransitionKeys.NONE,
      });
    }
  }
  const findAppTabsIcon = (key: string) => {
    const { appIcons } = reducersState.app;
    if (appIcons && appIcons.length > 0) {
      const appIcon = appIcons.find(item => item.iconType === key);
      return appIcon;
    }
  }

  const getIconName = (key: string) => {
    if (findAppTabsIcon(key) !== undefined) {
      const icon = findAppTabsIcon(key);
      const title = getI18nText(icon, 'icon_name', reducersState.app.language);
      return title;
    }
  }

  const isShowSplash = !deviceInfo.inPC && showSplash && splashData.length > 0;

  return (
    <div className={`${prefix} ${(deviceInfo.inPC || deviceInfo.inPad) ? 'web' : 'phone'} ${className ? className : ''}  ${isShowSplash ? 'show-splash' : ''}`}>
      <div className="w-container bm-c-tab-bar-container">
        {
          platformConfig.showBrandsTab &&
          <BmTabBarItem
            iconType={IconType.HOME_OUTLINED}
            title={getIconName('HOME_OUTLINED') || getIntl().page.homeTitle}
            hideTitle={false}
            selected={selectedCurrentKey === TabsOption.BRANDS}
            onClick={() => handleClick({
              path: paths.BRANDS,
              tabOption: TabsOption.BRANDS,
            })}
            appIcon={findAppTabsIcon('HOME_OUTLINED')}
          />
        }
        {
          platformConfig.showCategoryTab && localStorage.getItem(Constants.ADD_EXPRESS_ORDER) !== 'true' &&
          <BmTabBarItem
            iconType={IconType.EXPRSESS_ORDER_OUTLINED}
            title={getIconName('EXPRSESS_ORDER_OUTLINED') || getIntl().page.expressOrderTitle}
            hideTitle={false}
            selected={selectedCurrentKey === TabsOption.EXPRESS_ORDER}
            onClick={() => handleClick({
              path: paths.EXPRESS_ORDER,
              tabOption: TabsOption.EXPRESS_ORDER,
              pathParams: {
                storeSlug: platformConfig.storeSlug,
              }
            })}
            appIcon={findAppTabsIcon('EXPRSESS_ORDER_OUTLINED')}
          />
        }
        {
          platformConfig.showCategoryTab && localStorage.getItem(Constants.ADD_EXPRESS_ORDER) === 'true' &&
          <BmTabBarItem
            iconType={IconType.CATEGORY_OUTLINED}
            title={getIconName('CATEGORY_OUTLINED') || getIntl().page.categoryTitle}
            hideTitle={false}
            selected={selectedCurrentKey === TabsOption.CATEGORY}
            onClick={() => handleClick({
              path: paths.CATEGORY,
              tabOption: TabsOption.CATEGORY,
            })}
            appIcon={findAppTabsIcon('CATEGORY_OUTLINED')}
          />
        }
        {
          platformConfig.showStoreTab && [HomeType.STORE_LIST].includes(homeType) &&
          <BmTabBarItem
            iconType={IconType.ADDRESS_OUTLINED}
            title={getIconName('ADDRESS_OUTLINED') || getIntl().page.storesTitle}
            hideTitle={false}
            selected={selectedCurrentKey === TabsOption.STORE}
            onClick={() => handleClick({
              path: paths.STORE_LIST,
              tabOption: TabsOption.STORE,
            })}
            appIcon={findAppTabsIcon('ADDRESS_OUTLINED')}
          />
        }
        {
          platformConfig.showStoreTab && [HomeType.MENU_GRID, HomeType.MENU_LIST].includes(homeType) &&
          <BmTabBarItem
            iconType={IconType.STORE_OUTLINED}
            title={getIconName('STORE_OUTLINED') || getIntl().page.menusTitle}
            selected={selectedCurrentKey === TabsOption.MENUS}
            onClick={() => handleClick({
              path: paths.HOME,
              tabOption: TabsOption.MENUS,
            })}
            appIcon={findAppTabsIcon('STORE_OUTLINED')}
          />
        }
        {
          platformConfig.showHomeTab && !platformConfig.showBrandsTab &&
          <BmTabBarItem
            iconType={IconType.HOME_OUTLINED}
            title={getIconName('HOME_OUTLINED') || getIntl().page.homeTitle}
            hideTitle={false}
            selected={selectedCurrentKey === TabsOption.STORE}
            onClick={() => handleClick({
              path: paths.HOME,
              tabOption: TabsOption.STORE,
            })}
            appIcon={findAppTabsIcon('HOME_OUTLINED')}
          />
        }
        {
          platformConfig.showMembersAreaTab &&
          <BmTabBarItem
            iconType={IconType.MEMBERS_OUTLINED}
            title={getIconName('MEMBERS_OUTLINED') || getIntl().page.membersTitle}
            hideTitle={false}
            selected={selectedCurrentKey === TabsOption.MEMBERS_AREA}
            onClick={() => handleClick({
              path: paths.MEMBERSHIP,
              tabOption: TabsOption.MEMBERS_AREA,
            })}
            appIcon={findAppTabsIcon('MEMBERS_OUTLINED')}
          />
        }
        {
          platformConfig.showOrderTab &&
          <BmTabBarItem
            iconType={IconType.RECORDS_OUTLINED}
            title={getIconName('RECORDS_OUTLINED') || getIntl().page.myRecords}
            hideTitle={false}
            selected={selectedCurrentKey === TabsOption.RECORDS}
            onClick={() => handleClick({
              path: paths.RECORDS,
              tabOption: TabsOption.RECORDS,
            })}
            appIcon={findAppTabsIcon('RECORDS_OUTLINED')}
          />
        }
        {
          platformConfig.showRecordsTab &&
          <BmTabBarItem
            iconType={IconType.RECORDS_OUTLINED}
            title={getIconName('RECORDS_OUTLINED') || getIntl().page.myRecords}
            hideTitle={false}
            selected={selectedCurrentKey === TabsOption.RECORDS}
            onClick={() => handleClick({
              path: paths.RECORDS,
              tabOption: TabsOption.RECORDS,
            })}
            appIcon={findAppTabsIcon('RECORDS_OUTLINED')}
          />
        }
        {
          platformConfig.showMyIDTab &&
          <BmTabBarItem
            iconType={IconType.MY_ID_OUTLINED}
            title={getIconName('MY_ID_OUTLINED') || getIntl().page.myIDTitle}
            hideTitle={false}
            selected={selectedCurrentKey === TabsOption.MY_ID}
            onClick={() => handleClick({
              path: paths.MY_ID,
              tabOption: TabsOption.MY_ID,
            })}
            appIcon={findAppTabsIcon('MY_ID_OUTLINED')}
          />
        }
        {
          platformConfig.showAccountTab &&
          <BmTabBarItem
            iconType={IconType.NAV_ACCOUNT_OUTLINED}
            title={getIconName('NAV_ACCOUNT_OUTLINED') || getIntl().page.accountTitle}
            hideTitle={false}
            selected={selectedCurrentKey === TabsOption.ACCOUNT}
            onClick={() => handleClick({
              path: paths.ACCOUNT,
              tabOption: TabsOption.ACCOUNT,
            })}
            appIcon={findAppTabsIcon('NAV_ACCOUNT_OUTLINED')}
          />
        }
        {
          platformConfig.showEShopTab &&
          <BmTabBarItem
            iconType={IconType.SHOPPING_OUTLINED}
            title={getIconName('SHOPPING_OUTLINED') || getIntl().page.eshopTitle}
            hideTitle={false}
            selected={selectedCurrentKey === TabsOption.ESHOP}
            onClick={() => handleClick({
              path: paths.ESHOP,
              tabOption: TabsOption.ESHOP,
            })}
            appIcon={findAppTabsIcon('SHOPPING_OUTLINED')}
          />
        }
        {
          platformConfig.showNewsTab &&
          <BmTabBarItem
            iconType={IconType.NEWS_OUTLINED}
            title={getIconName('NEWS_OUTLINED') || getIntl().page.newsTitle}
            hideTitle={false}
            selected={selectedCurrentKey === TabsOption.NEWS}
            onClick={() => handleClick({
              path: paths.NEWS,
              tabOption: TabsOption.NEWS,
            })}
            appIcon={findAppTabsIcon('NEWS_OUTLINED')}
          />
        }
        {
          platformConfig.showPromotionsTab &&
          <BmTabBarItem
            iconType={IconType.PROMOTIONS_OUTLINED}
            title={getIconName('PROMOTIONS_OUTLINED') || getIntl().page.promotionsTitle}
            hideTitle={false}
            selected={selectedCurrentKey === TabsOption.NEWS}
            onClick={() => handleClick({
              path: paths.NEWS,
              tabOption: TabsOption.NEWS,
            })}
            appIcon={findAppTabsIcon('PROMOTIONS_OUTLINED')}
          />
        }
        {
          platformConfig.showAboutUsTab &&
          <BmTabBarItem
            iconType={IconType.ABOUT_US_OUTLINED}
            title={getIconName('ABOUT_US_OUTLINED') || getIntl().page.aboutUsTitle}
            hideTitle={false}
            selected={selectedCurrentKey === TabsOption.ABOUT_US}
            onClick={() => handleClick({
              path: paths.ABOUT_US,
              tabOption: TabsOption.ABOUT_US,
            })}
            appIcon={findAppTabsIcon('ABOUT_US_OUTLINED')}
          />
        }
      </div>
    </div>
  );
}

export default BmTabBar;
