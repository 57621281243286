import React from 'react';
import dayjs from 'dayjs';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import Layout from '@/components/Layout/Layout';
import Icon, { IconType } from '@/components/Icon';
import Config from '@/Config';
import { IOrderInfo } from '@/lib/order-builder/models/OrderEntity';
import { formatCurrency } from '@/components/Payment';
import { getAppTheme, setScreenColor, getIntl, getIntlByPath } from '@/components/App/App';
import { getI18nText, handleStoreServiceTypesScroll } from '@/utils/app';
import paths from '@/routes/paths';
import { getLocalStorageJson, setLocalStorageJson } from '@/utils';
import ActionType from '@/actions/action-type';
import { IStoreCategory } from '@/actions/store-action';
import Constants from '@/constants';
import SideBar from '@/components/SideBar';
import BmTabBar from '@/components/BmTabBar';
import TabsOption from '@/actions/tabs-option';
import { IStore } from '@/actions/store-action';
import { prefix } from '.';
import { CategoryPageStyled } from './styled';

interface ICategoryPageProps extends IBasePageProps {
  isHomePage?: boolean;
}

interface ICategoryPageState {
  selectedCategoryID: string;
  brands: IStoreCategory[];
  showSideBar: boolean;
  selectCategory: string;
  store: IStore | undefined;
  orders: IOrderInfo[];
}

let useLocal = false;

export const setUseLocalCategoryID = (use: boolean) => {
  useLocal = use || false;
}

class BrandListPage extends BasePage<ICategoryPageProps, ICategoryPageState> {
  selectedCategoryID = getLocalStorageJson(Constants.CATEGORYID) || '';
  constructor(props: ICategoryPageProps) {
    super(props);

    this.state = {
      selectedCategoryID: useLocal ? this.selectedCategoryID || '0' : '0',
      brands: [],
      showSideBar: false,
      selectCategory: '',
      store: undefined,
      orders: [],
    }
  }
  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.darkBackgroundColor && appTheme.primaryColor) {
      setScreenColor(appTheme.primaryColor, appTheme.darkBackgroundColor);
    }

    super.componentDidMount();
    this.autoCloseLoading = false;
    const { dispatch, reducersState } = this.props;
    const { platformStoreCategories, language, platformAppSettings } = reducersState.app;
    const { selectStoreSlug } = reducersState.supplierInfo;
    const { selectedCategoryID } = this.state;
    this.openLoading();
    dispatch({
      type: ActionType.QUERY_BRANDS_BY_CATEGORY,
      categoryID: selectedCategoryID === '0' ? platformStoreCategories[0] ? platformStoreCategories[0].id : '0' : selectedCategoryID,
      callback: brands => {
        let selectCategory = '';
        if (selectedCategoryID === '0') {
          selectCategory = getI18nText(platformStoreCategories[0], 'category_name', language)
        } else {
          platformStoreCategories.forEach(item => {
            if (item.id === selectedCategoryID){
              selectCategory = getI18nText(item, 'category_name', language);
            }
          })
        }
        if (platformAppSettings && platformAppSettings.suppliers) {
          dispatch({
            type: ActionType.QUERY_USER_ASSOCIATE_STORES,
            callback: (stores: IStore[]) => {
              let store: IStore | undefined = undefined;
              stores.forEach(item => {
                if (item.slug === selectStoreSlug) {
                  store = item;
                }
              })
              dispatch({
                type: ActionType.QUERY_ORDERS,
                page: 1,
                pageSize: 10,
                status: 'all',
                refresh: false,
                callback: orders => {
                  this.setState({
                    orders,
                    store,
                    brands,
                    selectCategory,
                  });
                  this.closeLoading();
                }
              });
            }
          })
        } else {
          this.setState({
            brands,
            selectCategory,
          });
          this.closeLoading();
        }
      }
    })
    handleStoreServiceTypesScroll(selectedCategoryID);
  }

  componentWillUnmount () {
    setUseLocalCategoryID(false);
  }

  handleShowSideBar = () => {
    this.setState({
      showSideBar: !this.state.showSideBar,
    });
  }

  onOpenChange = () => {
    const { reducersState } = this.props;
    const { platformConfig } = reducersState.app;
    if (platformConfig.showSideBar) {
      this.setState({
        showSideBar: !this.state.showSideBar,
      });
    }
  }

  handleClick = (type: string) => {
    const { selectedCategoryID } = this.state;
    handleStoreServiceTypesScroll(type);
    this.handleGotoRoute({
      path: paths.STORE_LIST_BY_BRAND,
      pathParams: {
        brandID: type,
        categoryID: selectedCategoryID,
      },
    });
  }

  handleNavBarButton = (categoryID: string, category: string) => {
    this.setState({selectCategory: ''});
    const { selectedCategoryID } = this.state;
    if (selectedCategoryID !== categoryID) {
      this.setState({selectedCategoryID: categoryID, brands: []})
      handleStoreServiceTypesScroll(categoryID);
      const { dispatch } = this.props;
      this.openLoading();
      dispatch({
        type: ActionType.QUERY_BRANDS_BY_CATEGORY,
        categoryID,
        callback: brands => {
          this.setState({
            brands,
            selectCategory: category,
          });
          setLocalStorageJson(Constants.CATEGORYID, categoryID);
          this.closeLoading();
        }
      })
    }
  }

  handleReOrder = (storeID: string, orderNumber: string, suborderNumber: string) => {
    const { dispatch } = this.props;

    if (storeID && orderNumber) {
      this.openLoading();
      dispatch({
        type: ActionType.ADD_TO_SHOPPING_CART_BY_ORDER,
        storeID,
        orderNumber,
        suborderNumber: orderNumber !== suborderNumber ? suborderNumber : '',
        callback: (storeID, shoppingCartData) => {
          if (shoppingCartData && storeID) {
            dispatch({
              type: ActionType.QUERY_STORE_BY_ID,
              storeID,
              callback: store => {
                this.closeLoading();
                if (store) {
                  this.handleGotoRoute({
                    path: paths.SHOPPING_CART,
                    pathParams: {
                      storeSlug: store.slug,
                    },
                  });
                }
              }
            });
          } else {
            this.closeLoading();
          }
        }
      })
    }
  }

  render () {
    const { reducersState } = this.props;
    const { platformStoreCategories, language, platformConfig } = reducersState.app;
    const { selectedCategoryID, brands, selectCategory, store, orders } = this.state;
    const { showSideBar } = platformConfig;
    const orderItems: any = [];
    if (orders.length > 0) {
      let times = 0;
      orders.forEach(orderInfo => {
        orderInfo.orderEntities.forEach(item => {
          if (times < 2) {
            times = times + 1;
            orderItems.push(
              <div className="div-block-561" key={item.orderNumber}>
                <div className="div-block-562">
                  <h4 className="heading-4 no-margin">{item.storeTitle}</h4>
                  <div className="text-block-120">{getIntlByPath(`common.${item.orderStatus}`)}</div>
                </div>
                <div className="div-block-564">
                  <div className="div-block-565">
                    <div className="div-block-563" style={{backgroundImage: `url(${item.storeLogoUrl || Config.defaultStoreImg})`}}></div>
                    <div className="div-block-566">
                      <div className="text-block-68">{dayjs(orderInfo.orderTime).format('YYYY-MM-DD h:mm A')}</div>
                      <div className="text-block-68"><span className="text-span-2">{getIntl().common.total}:</span>{formatCurrency(item.initialTotal)}</div>
                    </div>
                    <div className="text-block-220" onClick={() => this.handleReOrder(orderInfo.storeID, orderInfo.orderNumber, item.orderNumber)}>{getIntl().page.reOrder}</div>
                  </div>
                </div>
              </div>
            )
          }
        })
      })
    }
    return(
      <>
        <Layout
          {...this.props}
          className={prefix}
          navIcon={<Icon className="side-bar-icon" type={IconType.MENUS_OUTLINED} />}
          navContent={
            <div className="store-name" onClick={() => this.handleGotoRoute({path: paths.SELECT_STORES})}>
              { 
                store &&
                <div className="is-store-name">{store.name}</div>
              }
              {store && <Icon className="down html-embed-7 w-embed" type={IconType.DOWN_OUTLINED} />}
            </div>
          }
          onLeftClick={this.onOpenChange}
          // navRightContent={
          //   <div className="nav-div-block-592" onClick={() => this.handleGotoRoute({path: paths.SEARCH})}>
          //     <div className="search-2 saved-shortlists _1 w-form">
          //       <div className="search-placeholder">{getIntl().page.searchByNames}</div>
          //       <Icon type={IconType.SEARCH_OUTLINED} className="html-embed-51 grey w-embed" />
          //     </div>
          //   </div>
          // }
        >
          <CategoryPageStyled>
            <div className="section homepage-birdymart">
              <div className="div-block-570">
                <div className="see-all-wrap">
                  <div/>
                  <div
                    className="sell-all"
                    onClick={() => this.handleClick('0')}
                  >
                    {getIntl().page.seeAllSuppliers}
                    <Icon
                      className="viewmore-icon"
                      type={IconType.LEFT_ARROW_OUTLINED}
                    />
                  </div>
                </div>
                <div className="div-block-592" onClick={() => this.handleGotoRoute({path: paths.SEARCH})}>
                  <div className="search-2 saved-shortlists _1 w-form">
                    <div className="search-placeholder">{getIntl().page.searchByNames}</div>
                    <Icon type={IconType.SEARCH_OUTLINED} className="html-embed-51 grey w-embed" />
                  </div>
                </div>
                <div className="see-all-wrap">
                  <div className="h2 black no-space">{getIntl().page.recentOrders}</div>
                  <div
                    className="sell-all"
                    onClick={() => this.handleGotoRoute({path: paths.RECORDS})}>
                    {getIntl().page.seeAll}
                    <Icon
                      className="viewmore-icon"
                      type={IconType.LEFT_ARROW_OUTLINED}
                    />
                  </div>
                </div>
                <div className="recent-order-items">
                  {orderItems}
                </div>
              </div>
              <div className="div-block-567">
                <div className="div-block-569"></div>
                <div className="tabs-3 w-tabs">
                  <div className="tabs-menu-5 w-tab-menu">
                    {
                      platformStoreCategories.length > 0 &&
                      platformStoreCategories.map((item, index) => (
                        <div
                          className={`my-priviledge-tab-1 w-inline-block w-tab-link category${selectedCategoryID === item.id || (selectedCategoryID === '0' && index === 0) ? ' selected' : ''}`}
                          onClick={() => this.handleNavBarButton(item.id, getI18nText(item, 'category_name', language))}
                          id={`bm-store-service-types-${item.id}`}
                          key={item.id}
                        >
                          <div className="text-block-200">{getI18nText(item, 'category_name', language)}</div>
                        </div>
                      ))
                    }
                  </div>
                  <div className="tabs-content-1 w-tab-content">
                    <div className="tab-pane">
                      <h2 className="h2 black no-space">{selectCategory}</h2>
                      <div className="div-block-537 grid">
                        {
                          brands.map(item => (
                            <div className="div-block-568" key={item.id} onClick={() => this.handleClick(item.id)} style={{backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${item.categoryMediumImage})`}}>
                              <h2 className="heading-8">{getI18nText(item, 'category_name', language)}</h2>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CategoryPageStyled>
        </Layout>
       
        {
          platformConfig.showTabs &&
          <BmTabBar
            selectedKey={TabsOption.CATEGORY}
            reducersState={reducersState}
          />
        }
        {
          showSideBar &&
          <SideBar
            showSideBar={this.state.showSideBar}
            onItemClick={this.handleShowSideBar}
            reducersState={reducersState}
          />
        }
      </>
    )
  }
}

export default BrandListPage;
