import { all } from 'redux-saga/effects';
import app from './app-saga';
import store from './store-saga';
import user from './user-saga';
import pay from './pay-saga';
import cart from './shopping-cart-saga';
import order from './order-saga';
import listing from './listing-saga';
import favorite from './favorite-saga';
import feedback from './feedback-saga';
import queueing from './queueing-saga';
import booking from './booking-saga';
import point from './point-saga';
import qrcordShopping from './qrcord-shopping-saga';
import supplierInfo from './supplier-info-sage';
import purchaseOrder from './purchase-order-sage';
import goodReceivedNote from './good-received-note-sage';
import returnNote from './return-note-sage';

export default function* rootSaga() {
  yield all([
    app, store, user, pay, cart,
    order, listing, favorite, feedback, queueing,
    booking, point, qrcordShopping, supplierInfo,
    purchaseOrder, goodReceivedNote, returnNote,
  ]);
}
