import React from 'react';
import { prefix } from '.';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import { setScreenColor, getAppTheme, getIntl } from '@/components/App/App';
import Icon, { IconType } from '@/components/Icon';
import paths from '@/routes/paths';
import ActionType from '@/actions/action-type';
import CouponsItem from './CouponsItem';
import CouponDetails from './CouponDetails';
import { animateScroll } from 'react-scroll';
import { ICustomerPromotion } from '@/actions/user-action';
import { PrivilegePageStyled, PrivilegePageNavStyled } from './styled';
import SideNavOption from '@/actions/side-nav-option';
import Components from '@/components/SideNavBar/Components';
import Layout from '@/components/Layout';
import BigNumber from 'bignumber.js';
import Modal from 'antd-mobile/es/modal';
import { langFormat } from '@/utils/app';

interface IPrivilegePageState {
  loading: boolean;
  showCouponDeatails: boolean;
  coupon: ICustomerPromotion | undefined;
  lastPage: boolean;
  page: number;
  available: number;
}

interface IPrivilegePageProps extends IBasePageProps {

}

class PrivilegePage extends BasePage<IPrivilegePageProps, IPrivilegePageState> {
  loadingData = false;

  constructor(props: IPrivilegePageProps) {
    super(props);

    this.state = {
      loading: false,
      showCouponDeatails: false,
      coupon: undefined,
      lastPage: false,
      page: 1,
      available: 2,
    }
  }

  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.lightBackgroundColor) {
      setScreenColor(appTheme.lightBackgroundColor);
    }
    const { page, available } = this.state;
    const { dispatch, reducersState } = this.props;
    const { deviceInfo } = reducersState.app;
    dispatch({
      type: ActionType.LOADING,
      showLoading: true,
      showLoadingBgc: true,
    });
    if (available === 2) {
      this.getAllPromotion(page);
    } else {
      this.getCustomerPromotion(page, available);
    }
    this.autoCloseLoading = false;
    super.componentDidMount();
    const pageWrap = window.document.querySelector('.bm-p-privilege-p');
    const pageWrapPone = window.document.querySelector('.bm-p-privilege');
    if (deviceInfo && deviceInfo.inPC && pageWrap) {
      pageWrap.addEventListener('scroll', this.handleScroll, { passive: true });
    } else if (pageWrapPone) {
      window.addEventListener('scroll', this.handleScroll, { passive: true });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    const { dispatch } = this.props;
    dispatch({
      type: ActionType.CLEAR_CUSTOMER_PROMOTION,
    });
    dispatch({
      type: ActionType.CLEAR_ALL_PROMOTION,
    });
  }

  getAllPromotion = (page: number) => {
    const { dispatch, reducersState } = this.props;
    const { platformConfig } = reducersState.app;
    dispatch({
      type: ActionType.QUERY_ALL_PROMOTION,
      platformID: platformConfig.platformID,
      page,
      storeID: platformConfig.storeID,
      callback: (params, page, lastPage) => {
        this.closeLoading();
        this.loadingData = false;
        this.setState({ page, lastPage, loading: true });
      },
    });
  }

  getCustomerPromotion = (page: number, available: number) => {
    const { dispatch, reducersState } = this.props;
    const { platformConfig } = reducersState.app;
    dispatch({
      type: ActionType.QUERY_CUSTOMER_PROMOTION,
      platformID: platformConfig.platformID,
      available,
      page,
      storeSlug: platformConfig.storeSlug,
      callback: (params, page, lastPage) => {
        this.closeLoading();
        this.loadingData = false;
        this.setState({ page, lastPage, loading: true });
      },
    });
  }

  getScrollY = () => {
    const pageWrap = window.document.querySelector('.bm-p-privilege-p');
    let scrollY = window.scrollY;
    if (pageWrap) {
      scrollY = pageWrap.scrollTop;
    }

    return scrollY;
  }

  handleScroll = () => {
    const { page, available } = this.state;
    const scrollY = this.getScrollY();
    if (!this.state.lastPage && !this.loadingData) {
      const appPages = document.getElementsByClassName('bm-p-privilege');
      if (appPages && appPages.length) {
        const appPage = appPages[0];
        const scrollHeight = appPage.scrollHeight;
        const canScrollHeight = scrollHeight - window.innerHeight;

        if (canScrollHeight - scrollY < 5 * 200) {
          this.loadingData = true;
          if (available === 2) {
            this.getAllPromotion(page+1);
          } else {
            this.getCustomerPromotion(page+1, available);
          }
        }
      }
    }
  }

  handleTypeClick = (type: number) => {
    const { dispatch } = this.props;
    dispatch({
      type: ActionType.LOADING,
      showLoading: true,
      showLoadingBgc: true,
    });
    this.loadingData = true;
    this.setState({
      loading: false,
      lastPage: false,
      page: 1,
      available: type,
    });
    animateScroll.scrollTo(0, {
      duration: 200,
    });
    if (type === 2) {
      this.getAllPromotion(1);
    } else {
      this.getCustomerPromotion(1, type);
    }

  }

  handleClick = (record: any) => {
    this.setState({
      showCouponDeatails: true,
      coupon: record,
    })
  }

  handleGoBackList = () => {
    const { available } = this.state;
    const { dispatch } = this.props;
    dispatch({
      type: ActionType.CLEAR_CUSTOMER_PROMOTION,
    });
    if (available !== 2) {
      this.getCustomerPromotion(1, available);
    }
    this.setState({
      showCouponDeatails: false,
    });
  }

  handleRedeem = () => {
    const { available, coupon, page } = this.state;
    const { dispatch, reducersState } = this.props;
    const { platformConfig } = reducersState.app;
    const { storeID, storeSlug, platformID } = platformConfig;
    const customerID = reducersState.user.slugRefCustomer[storeSlug].id;
    if (available === 2 && coupon) {
      dispatch({
        type: ActionType.REDEEM_PROMOTION,
        customerID,
        storeID,
        couponID: coupon.promotionCodeID,
        platformID,
        callback: (message: any) => {
          const tips = getIntl().common.tips;
          const redeemTips = message && message === 'success' ? getIntl().page.redeemSucceeded : message;
          dispatch({
            type: ActionType.QUERY_CUSTOMER_GROWTH,
            storeSlug,
            storeID,
            customerID,
            updateLoyaltyStamp: true,
            callback: membershipData => {
            }
          });
          Modal.alert(tips, redeemTips, [
            {
              text: getIntl().common.confirm,
              onPress: () => {
                dispatch({
                  type: ActionType.CLEAR_ALL_PROMOTION,
                });
                dispatch({
                  type: ActionType.CLEAR_CUSTOMER_PROMOTION,
                });
                this.getAllPromotion(page);
                this.setState({
                  showCouponDeatails: false,
                })
              },
            }
          ]);
        }
      });
    }
  }

  handleConfirmRedeem = () => {
    const { reducersState } = this.props;
    const { abbreviation, ecouponsRedemption } = reducersState.app.platformConfig;
    const tips = langFormat(getIntl().page.confirmRedeemTips, { name : ecouponsRedemption === 'STORE_CREDIT' ? `${abbreviation} ${getIntl().page.dDollars}` : getIntl().page.points });
    Modal.alert(getIntl().common.tips, tips, [
      {
        text: getIntl().common.cancel,
        onPress: () => {},
      },
      {
        text: getIntl().common.confirm,
        onPress: () => {
          this.handleRedeem();
        },
      }
    ]);
  }

  render() {
    const {
      available,
      showCouponDeatails,
      coupon,
      loading,
    } = this.state;
    const { reducersState } = this.props;
    const { storeSlug, abbreviation, ecouponsRedemption } = reducersState.app.platformConfig;
    const { slugRefCustomerGrowth } = reducersState.user;
    const storeCredits = slugRefCustomerGrowth[storeSlug] ? slugRefCustomerGrowth[storeSlug].storeCredits :  0;
    const myLoyaltyStamp = slugRefCustomerGrowth[storeSlug] ? slugRefCustomerGrowth[storeSlug].myLoyaltyStamp : 0;

    const slugRefCustomerPromotion = reducersState.user.slugRefCustomerPromotion[available] || [];
    const allPromotions = reducersState.user.slugRefAllPromotion[storeSlug] || [];
    const { deviceInfo } = reducersState.app;

    return (
      <Layout
        {...this.props}
        isFooter={false}
        className={prefix}
      >
        <Components 
          {...this.props}
          reducersState={reducersState}
          onLeftClick={() => this.handleGoBack({
            path: paths.MEMBERSHIP,
            runGoBack: true,
          })}
          navContent={getIntl().page.myPrivilege}
          navIcon={<Icon type={IconType.LEFT_OUTLINED} className="html-embed-7 w-embed"/>}
          sideNavOption={SideNavOption.PRIVILEGE}
          whiteBanner={true}
          className={
            (deviceInfo && deviceInfo.inPC)? `${prefix}-p`: ''
          }
          whiteBannerContent={
            <PrivilegePageNavStyled>
              <div className="tabs-menu-4 w-tab-menu">
                <div
                  className={`my-priviledge-tab w-inline-block w-tab-link ${available === 2 ? ' w--current' : ''}`}
                  onClick={() => this.handleTypeClick(2)}
                >
                  <div className="text-block-200" >{getIntl().page.valid}</div>
                </div>
                <div
                  className={`my-priviledge-tab w-inline-block w-tab-link ${available === 1 ? ' w--current' : ''}`}
                  onClick={() => this.handleTypeClick(1)}
                >
                  <div className="text-block-200" >{getIntl().page.myCoupons}</div>
                </div>
                <div
                  className={`my-priviledge-tab w-inline-block w-tab-link ${available === 0 ? ' w--current' : ''}`}
                  onClick={() => this.handleTypeClick(0)}
                >
                  <div className="text-block-200" >{getIntl().page.used}</div>
                </div>
              </div>
            </PrivilegePageNavStyled>
          }
        >
          <PrivilegePageStyled className="tabs-2 w-tabs">
            {
              <PrivilegePageNavStyled className="privilege">
                <div className="tabs-menu-4 w-tab-menu">
                `<div
                    className={`my-priviledge-tab w-inline-block w-tab-link ${available === 2 ? ' w--current' : ''}`}
                    onClick={() => this.handleTypeClick(2)}
                  >
                    <div className="text-block-200" >{getIntl().page.valid}</div>
                  </div>
                  <div
                    className={`my-priviledge-tab w-inline-block w-tab-link ${available === 1 ? ' w--current' : ''}`}
                    onClick={() => this.handleTypeClick(1)}
                  >
                    <div className="text-block-200" >{getIntl().page.myCoupons}</div>
                  </div>
                  <div
                    className={`my-priviledge-tab w-inline-block w-tab-link ${available === 0 ? ' w--current' : ''}`}
                    onClick={() => this.handleTypeClick(0)}
                  >
                    <div className="text-block-200" >{getIntl().page.used}</div>
                  </div>
                </div>
              </PrivilegePageNavStyled>
            }
            <div className="tabs-content w-tab-content">
              {
                ((slugRefCustomerPromotion && slugRefCustomerPromotion.length > 0) || (allPromotions && allPromotions.length > 0)) && 
                <div className="tab-pane">
                  <div className="section cms">
                    <div className="div-block-537">
                      {
                        available === 2 && 
                        <div className="show-redeemable-wrap">
                          <div className="show-redeemable">{getIntl().page.showRedeemable}</div>
                          {
                            ecouponsRedemption === 'STORE_CREDIT' &&
                            <div className="dollars">{new BigNumber(storeCredits).toFormat()} {abbreviation} {getIntl().page.dDollars}</div>
                          }
                          {
                            ecouponsRedemption === 'LOYALTY_POINTS' &&
                            <div className="dollars">{new BigNumber(myLoyaltyStamp).toFormat()} {getIntl().page.points}</div>
                          }
                        </div>
                      }
                      {
                        available !== 2 && slugRefCustomerPromotion.map((item: any) => (
                          <CouponsItem
                            record={item}
                            key={item.promotionCodeID}
                            onClick={() => this.handleClick(item)}
                            reducersState={reducersState}
                            showStatus={true}
                            redeem={false}
                          />
                        ))
                      }
                      {
                        available === 2 && allPromotions.map((item: any) => (
                          <CouponsItem
                            record={item}
                            key={item.promotionCodeID}
                            onClick={() => this.handleClick(item)}
                            reducersState={reducersState}
                            showStatus={false}
                            redeem={true}
                          />
                        ))
                      }
                    </div>
                  </div>
                </div>
              }
              {
                loading && ((available !== 2 && slugRefCustomerPromotion.length === 0) || (available === 2 && allPromotions.length === 0))&&
                <div className={`${prefix}-no-coupons`}>
                  <div>
                    <div className={'img'}>
                      <Icon className={'img'} type={IconType.TAG_ITEM}/>
                    </div>
                  </div>
                </div>
              }
            </div>
            {
              showCouponDeatails && coupon && 
              <CouponDetails 
                record={coupon}
                reducersState={reducersState}
                onCancel={() => this.handleGoBackList()}
                showRedeem={available === 2 ? true : false}
                handleRedeem={this.handleConfirmRedeem}
                storeCredits={storeCredits}
                points={myLoyaltyStamp}
              />
            }
          </PrivilegePageStyled>
        </Components>
      </Layout>
    )
  }
}

export default PrivilegePage
