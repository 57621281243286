import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IReducersState } from '@/reducers';
import { IPayCard, IGiftCard } from '@/actions/pay-action';
import Icon, { IconType } from '../Icon';
import ActionType from '@/actions/action-type';
import SelectPayMethod from '@/components/SelectPayMethod';
import { IDispatch } from '@/actions';
import { PayTypes } from '@/lib/payment';
import { prefix } from '.';
import { getAccessToken } from '@/utils/app';
import { getIntl } from '../App/App';

interface IPayBillMethodProps {
  visible: boolean;
  className?: string;
  canPayMethods: PayTypes[];
  onCancel?: () => void;
  handleItemClick: (key: PayTypes, data?: IGiftCard | IPayCard) => void;
}

const PayBillMethod: React.FC<IPayBillMethodProps> = props => {

  const {
    visible,
    className,
    canPayMethods = [],
    onCancel = () => {},
    handleItemClick = () => {},
  } = props;

  const dispatch = useDispatch<IDispatch>();
  const reducersState: IReducersState = useSelector<IReducersState, IReducersState>(state => state);
  const { anonymousGiftCards } = reducersState.pay;

  const [payCards, setPayCards] = useState<IPayCard[]>([]);
  const [selectedMethod, setSelectedMethod] = useState<string>();

  useEffect(() => {
    if (getAccessToken()) {
      dispatch({
        type: ActionType.QUERY_PAY_CARDS,
        callback: payCards => {
          setPayCards(payCards);
        }
      });
    }

    const {payMethods } = reducersState.pay;
    let selectedMethod: string | undefined; // 当前选中的
    payMethods.forEach(item => {
      if (item.type === PayTypes.CREDIT_CARD) {
        selectedMethod = `${item.creditCardType}_${item.id}`;
      } else {
        selectedMethod = item.type;
      }
    });
    setSelectedMethod(selectedMethod);

  }, [visible]);

  return (
    <div className={`bm-p-pay-method ${className} ${prefix} ${visible ? 'visible' : 'hide'}`}>
      <div className="c-popup-close-2 close-modal" onClick={() => onCancel()}></div>
      <div className="flex-pop-up widen">
        <div className="top-header no-line">
          <h2 className="heading-2">{getIntl().page.payment}</h2>
          <div className="x-icon close-modal">
            <Icon className="w-embed" type={IconType.CLOSE_OUTLINED} onClick={() => onCancel()} />
          </div>
        </div>
        <div className="subtitle-popup">
          <div className="div-block-499">
            <div className="text-block-94">{getIntl().page.selectPaymentMethod}</div>
          </div>
        </div>
        <SelectPayMethod
          anonymousGiftCards={anonymousGiftCards}
          payCards={payCards}
          onItemClick={handleItemClick}
          canPayMethods={canPayMethods}
          selectedMethod={selectedMethod}
        />
      </div>
    </div>
    // <div className={`${className} ${prefix} ${visible ? 'visible' : 'hide'}`} >
    //   <div className={`${prefix}-nav`}>
    //     <div className={`${prefix}-nav-title`}>
    //       {getIntl().page.payment}
    //     </div>
    //     <div className={`${prefix}-nav-cancel`}>
    //       <Icon className="close-pay-method" type={IconType.CLOSE_OUTLINED} onClick={() => onCancel()} />
    //     </div>
    //   </div>
    //   <div className={`${prefix}-bar-describe`}>
    //     {getIntl().page.selectPaymentMethod}
    //   </div>
    //   <SelectPayMethod
    //     anonymousGiftCards={anonymousGiftCards}
    //     payCards={payCards}
    //     onItemClick={handleItemClick}
    //     canPayMethods={canPayMethods}
    //     selectedMethod={selectedMethod}
    //   />
    // </div>
  );
}
export default PayBillMethod;
