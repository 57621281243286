/**
 * auth: Joshua
 * 20201/03/11
 */

import {
  handleBuildOrderPayment,
} from '../Utils';
import { payInvoice } from '../payment-data';
import { IPayOrderResult, ICashPayMethod } from '..';

interface IUseCashParams {
  storeID: string;
  orderNumber: string;
  payData:  ICashPayMethod;
  payment: any;
}

const UseCashPay = (params: IUseCashParams) => {
  const {
    storeID,
    orderNumber,
    payment,
    payData,
  } = params;

  const {
    amount,
  } = payData;

  const cashPay = async (): Promise<IPayOrderResult> => {
    const newPayment = {
      'cashes': [
        {
          amount,
          'instrument_name': 'Cash',
        }
      ],
    };

    const order = handleBuildOrderPayment(newPayment, payment);
    try {

      const resp: any = await payInvoice(storeID, orderNumber, order);

      const {
        order: resOrder,
        data,
      } = resp || {};

      if (resOrder) {
        return {
          order: resOrder,
        }
      } else {
        return {
          status: 500,
          message: data.message || '',
          code: data.code,
        }
      }
    } catch (error) {
      throw new Error(error.message);
    }
  }
  return {
    cashPay,
  }
}
export default UseCashPay;
