import React from 'react';
import { IModifier } from '@/lib/order-builder/utils/api';
import Icon, { IconType } from '@/components/Icon';
import { IReducersState } from '@/reducers';
import { getI18nText, langFormat } from '@/utils/app';
import { getIntl } from '@/components/App/App';

interface IModifierHeaderProps {
  data: IModifier;
  onItemClick: (data: IModifier) => void;
  reducersState: IReducersState;
}

const ModifierHeader: React.FC<IModifierHeaderProps> = props => {
  const { data, onItemClick, reducersState } = props;
  const handleItemClick = () => {
    if (onItemClick) {
      onItemClick(data);
    }
  }

  let chooseMsg = null;
  if (data.chooseAtLeast !== 0 && data.chooseAtLeast === data.chooseUpTo) {
    chooseMsg = langFormat(getIntl().page.choose, { quanity: data.chooseAtLeast });
  }
  if (data.chooseAtLeast === 0 && data.chooseUpTo > 0) {
    chooseMsg = langFormat(getIntl().page.chooseUpTo, { quanity: data.chooseUpTo });
  }

  if (data.chooseAtLeast > 0 && data.chooseUpTo > 0 && data.chooseUpTo !== data.chooseAtLeast) {
    chooseMsg = langFormat(getIntl().page.chooseRange, { quanityAtLeast: data.chooseAtLeast, quanityUpTo: data.chooseUpTo });
  }
  
  if (data.chooseAtLeast > 0 && data.chooseUpTo === 0) {
    chooseMsg = langFormat(getIntl().page.chooseAtLeast, { quanity: data.chooseAtLeast });
  }
  const mustChoose = (data.chooseAtLeast > 0 && data.chooseUpTo > 0 && data.chooseUpTo !== data.chooseAtLeast) || (data.chooseAtLeast !== 0 && data.chooseAtLeast === data.chooseUpTo) || (data.chooseAtLeast > 0 && data.chooseUpTo === 0);

  return (
    <div className="modifier-title" onClick={handleItemClick} id={`bm-modifier-header-${data.id}`}>
      <div className="modifier-name">
        <div className="childen-modifier">
          {getI18nText(data, 'title', reducersState.app.language) || data.name}
        </div>
        <div className={mustChoose ? 'midifier-tip midifier-tip-warn' : 'midifier-tip'}>{chooseMsg}</div>
      </div>
      <Icon className="select-header-icon" type={IconType.DOWN_OUTLINED} />
    </div>
  );
}

export default ModifierHeader;
