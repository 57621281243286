import { RouteComponentProps } from 'react-router-dom';
import withRoute from '@/pages/withRoute';
import SignInPage from './SignInPage';
import './SignInPage.less';

export const prefix = 'bm-p-signin';

const RouteComponent: React.FC<RouteComponentProps> = props => withRoute({
  pageComponent: SignInPage,
  routeProps: props,
  pageTitleI18nKey: '',
});
export default RouteComponent;
