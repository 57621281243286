import { takeEvery, call } from 'redux-saga/effects';
import axios from 'axios';
import Config from '@/Config';
import ActionType from '@/actions/action-type';
import {
  IReceiveOrder,
  IQueryGoodReceivedNoteDetail,
  IQueryGoodReceivedNotes,
  ICancelGoodReceivedNote,
  ICreateGoodReceivedNote,
  IReceiveGoodReceivedNote,
  ICreateAndReceiveGoodReceivedNote,
} from '@/actions/good-received-note-actions';
import {
  parseGoodReceivedNote,
} from '@/utils/good-received-note-utill';


function* handleQueryGoodReceivedNotes(params: IQueryGoodReceivedNotes) {
  const { storeID, page, callback } = params;
  const goodReceivedNotes: IReceiveOrder[] = [];
  const listingIDs: string[] = [];
  const listings: {[id: string]: any} = {};
  let ids = '?';

  try {
    const res = yield call(
      axios.get,
      Config.urls.getReceiveOrders.replace('{storeID}', storeID).replace('{page}', page.toString()).replace('{noteType}', '1'),
    )
    if (res && res.receive_orders) {
      const receiveOrders = res.receive_orders;
      if (Array.isArray(receiveOrders)) {
        receiveOrders.forEach(receiveOrder => {
          if (receiveOrder['receive_order_items'] && Array.isArray(receiveOrder['receive_order_items'])) {
            receiveOrder['receive_order_items'].forEach(item => {
              if (item['receive_order_item'] && item['receive_order_item']['purchase_item'] && item['receive_order_item']['purchase_item']['source_type'] === 'Listing' && item['receive_order_item']['purchase_item']['source_id'] && !listingIDs.includes(item['receive_order_item']['purchase_item']['source_id'])) {
                listingIDs.push(item['receive_order_item']['purchase_item']['source_id']);
                ids += `ids[]=${item['receive_order_item']['purchase_item']['source_id']}&`;
              }
            })
          }
        })
        const listingInfo = yield call(
          axios.get,
          Config.urls.getListings.replace('{storeID}', storeID).replace('{ids}', ids),
        );
        if (listingInfo && Array.isArray(listingInfo.listings)) {
          listingInfo.listings.forEach((listing: any) => {
            if (listing['id']) {
              listings[listing['id']] = listing;
            }
          })
        }
        receiveOrders.forEach(receiveOrder => {
          if (receiveOrder['receive_order_items'] && Array.isArray(receiveOrder['receive_order_items'])) {
            receiveOrder['receive_order_items'].forEach(item => {
              if (item['receive_order_item'] && item['receive_order_item']['purchase_item'] && item['receive_order_item']['purchase_item']['source_id']) {
                item['receive_order_item']['purchase_item']['listing'] = listings[item['receive_order_item']['purchase_item']['source_id']]
              }
            })
            goodReceivedNotes.push(parseGoodReceivedNote(receiveOrder));
          }
        })
        
      }
      
    }
  } catch (error) {
    console.error(error);
  }
  if (callback) {
    callback(goodReceivedNotes)
  }
}

function* handleQueryGoodReceivedNote(params: IQueryGoodReceivedNoteDetail) {
  const { receiveOrderID, callback, storeID } = params;
  let goodReceivedNote: IReceiveOrder | undefined = undefined;
  const listingIDs: string[] = [];
  const listings: {[id: string]: any} = {};
  let ids = '?';
  try {
    const res = yield call(
      axios.get,
      Config.urls.getReceiveOrder.replace('{storeID}', storeID).replace('{receiveOrderID}', receiveOrderID),
    )
    if (res && res['receive_order']) {
      const receiveOrder = res['receive_order'];
      if (receiveOrder['receive_order_items'] && Array.isArray(receiveOrder['receive_order_items'])) {
        receiveOrder['receive_order_items'].forEach(item => {
          if (item['receive_order_item'] && item['receive_order_item']['purchase_item'] && item['receive_order_item']['purchase_item']['source_type'] === 'Listing' && item['receive_order_item']['purchase_item']['source_id'] && !listingIDs.includes(item['receive_order_item']['purchase_item']['source_id'])) {
            listingIDs.push(item['receive_order_item']['purchase_item']['source_id']);
            ids += `ids[]=${item['receive_order_item']['purchase_item']['source_id']}&`;
          }
        })
      }

      const listingInfo = yield call(
        axios.get,
        Config.urls.getListings.replace('{storeID}', storeID).replace('{ids}', ids),
      );
      if (listingInfo && Array.isArray(listingInfo.listings)) {
        listingInfo.listings.forEach((listing: any) => {
          if (listing['id']) {
            listings[listing['id']] = listing;
          }
        })
      }

      if (receiveOrder['receive_order_items'] && Array.isArray(receiveOrder['receive_order_items'])) {
        receiveOrder['receive_order_items'].forEach(item => {
          if (item['receive_order_item'] && item['receive_order_item']['purchase_item'] && item['receive_order_item']['purchase_item']['source_id']) {
            item['receive_order_item']['purchase_item']['listing'] = listings[item['receive_order_item']['purchase_item']['source_id']]
          }
        })
      }
      goodReceivedNote = parseGoodReceivedNote(receiveOrder);
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(goodReceivedNote);
  }
}

function* handleCancelGoodReceivedNote(params: ICancelGoodReceivedNote) {
  const { receiveOrderID, callback, storeID } = params;
  let goodReceivedNote: IReceiveOrder | undefined = undefined;
  const listingIDs: string[] = [];
  const listings: {[id: string]: any} = {};
  let ids = '?';
  try {
    const res = yield call(
      axios.put,
      Config.urls.cancelReceiveOrder.replace('{storeID}', storeID).replace('{receiveOrderID}', receiveOrderID),
    )
    if (res && res['receive_order']) {
      const receiveOrder = res['receive_order'];
      if (receiveOrder['receive_order_items'] && Array.isArray(receiveOrder['receive_order_items'])) {
        receiveOrder['receive_order_items'].forEach(item => {
          if (item['receive_order_item'] && item['receive_order_item']['purchase_item'] && item['receive_order_item']['purchase_item']['source_type'] === 'Listing' && item['receive_order_item']['purchase_item']['source_id'] && !listingIDs.includes(item['receive_order_item']['purchase_item']['source_id'])) {
            listingIDs.push(item['receive_order_item']['purchase_item']['source_id']);
            ids += `ids[]=${item['receive_order_item']['purchase_item']['source_id']}&`;
          }
        })
      }

      const listingInfo = yield call(
        axios.get,
        Config.urls.getListings.replace('{storeID}', storeID).replace('{ids}', ids),
      );
      if (listingInfo && Array.isArray(listingInfo.listings)) {
        listingInfo.listings.forEach((listing: any) => {
          if (listing['id']) {
            listings[listing['id']] = listing;
          }
        })
      }

      if (receiveOrder['receive_order_items'] && Array.isArray(receiveOrder['receive_order_items'])) {
        receiveOrder['receive_order_items'].forEach(item => {
          if (item['receive_order_item'] && item['receive_order_item']['purchase_item'] && item['receive_order_item']['purchase_item']['source_id']) {
            item['receive_order_item']['purchase_item']['listing'] = listings[item['receive_order_item']['purchase_item']['source_id']]
          }
        })
      }

      goodReceivedNote = parseGoodReceivedNote(receiveOrder);
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(goodReceivedNote);
  }
}

function* handleReceiveGoodReceivedNote(params: IReceiveGoodReceivedNote) {
  const { receiveOrderID, callback, storeID } = params;
  let goodReceivedNote: IReceiveOrder | undefined = undefined;
  const listingIDs: string[] = [];
  const listings: {[id: string]: any} = {};
  let ids = '?';
  try {
    const receiveOrders = yield call(
      axios.get,
      Config.urls.getReceiveOrder.replace('{storeID}', storeID).replace('{receiveOrderID}', receiveOrderID),
    )
    if (receiveOrders && receiveOrders['receive_order']) {
      const receiveItems = receiveOrders['receive_order']['receive_order_items'];
      const receiveOrderItems: any[] = [];
      if (Array.isArray(receiveItems)) {
        receiveItems.forEach(item => {
          if (item['receive_order_item']) {
            receiveOrderItems.push(item['receive_order_item'])
          }
        })
      }
      const receiveOrder = {
        'receive_order': receiveOrders['receive_order'],
        'receive_order_items': receiveOrderItems,
      }

      const res = yield axios({
        method: 'PUT',
        url: Config.urls.receiveReceiveOrder.replace('{storeID}', storeID).replace('{receiveOrderID}', receiveOrderID),
        data: receiveOrder,
        headers: {
          'x-user-device-type': 'pos',
        }
      })

      if (res && res['receive_order']) {
        const receiveOrder = res['receive_order'];
        if (receiveOrder['receive_order_items'] && Array.isArray(receiveOrder['receive_order_items'])) {
          receiveOrder['receive_order_items'].forEach(item => {
            if (item['receive_order_item'] && item['receive_order_item']['purchase_item'] && item['receive_order_item']['purchase_item']['source_type'] === 'Listing' && item['receive_order_item']['purchase_item']['source_id'] && !listingIDs.includes(item['receive_order_item']['purchase_item']['source_id'])) {
              listingIDs.push(item['receive_order_item']['purchase_item']['source_id']);
              ids += `ids[]=${item['receive_order_item']['purchase_item']['source_id']}&`;
            }
          })
        }
  
        const listingInfo = yield call(
          axios.get,
          Config.urls.getListings.replace('{storeID}', storeID).replace('{ids}', ids),
        );
        if (listingInfo && Array.isArray(listingInfo.listings)) {
          listingInfo.listings.forEach((listing: any) => {
            if (listing['id']) {
              listings[listing['id']] = listing;
            }
          })
        }
  
        if (receiveOrder['receive_order_items'] && Array.isArray(receiveOrder['receive_order_items'])) {
          receiveOrder['receive_order_items'].forEach(item => {
            if (item['receive_order_item'] && item['receive_order_item']['purchase_item'] && item['receive_order_item']['purchase_item']['source_id']) {
              item['receive_order_item']['purchase_item']['listing'] = listings[item['receive_order_item']['purchase_item']['source_id']]
            }
          })
        }
  
        goodReceivedNote = parseGoodReceivedNote(receiveOrder);
      }
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(goodReceivedNote);
  }
}

function* handleCreateGoodReceivedNote(params: ICreateGoodReceivedNote) {
  const { storeID, goodReceivedNote, callback } = params;
  let newGoodReceivedNote: IReceiveOrder | undefined = undefined;
  let returnReceiveOrder: any = '';
  try {
    const receiveOrderItems = getReceiveOrderItems(goodReceivedNote);
    const receiveOrder = {
      'receive_order': {
        'purchase_order_ids': goodReceivedNote.purchaseOrderIDs,
        'note_type': 1,
        'unit_enable': true,
        'store_id': storeID,
        'supplier_id': goodReceivedNote.supplierID,
        'supplier_name': goodReceivedNote.supplierName,
        'expected_receive_date': goodReceivedNote.expectedReceiveDate,
        'reference_number': goodReceivedNote.referenceNumber,
        'remarks': goodReceivedNote.remarks,
      },
      'receive_order_items': receiveOrderItems
    }
    const resp = yield axios({
      method: 'POST',
      url: Config.urls.createReceiveOrder.replace('{storeID}', storeID),
      data: receiveOrder,
      headers: {
        'x-user-device-type': 'pos',
      }
    })
    if (resp && resp['receive_order']) {
      const goodsReceiveOrderEdit = goodReceivedNote

      if (goodsReceiveOrderEdit.purchaseOrderIDs.length > 0) {
        const purchaseOrderIDs: string[] = [];
        goodsReceiveOrderEdit.purchaseOrderIDs.forEach(item => {
          purchaseOrderIDs.push(`${item}`)
        })
      }
      const newGoodsReceiveOrder = parseGoodReceivedNote(resp['receive_order']);
      const purchaseItemToReceiveOrderItemID: {[purchaseItemID: number]: {qtyReceived: number; qtyRemaining: number; qtyReceivedPO: number; qtyPO: number; qtyReceive: number}} = {};
      goodsReceiveOrderEdit.purchaseOrders.forEach(item => {
        item.lineItems.forEach(lineItem => {
          purchaseItemToReceiveOrderItemID[lineItem.purchaseItemID] = {
            qtyReceived: lineItem.qtyReceived,
            qtyRemaining: lineItem.qtyRemaining,
            qtyReceivedPO: lineItem.qtyReceivedPO,
            qtyPO: lineItem.qtyPO,
            qtyReceive: lineItem.qtyReceive,
          }
        })
      })

      newGoodsReceiveOrder.purchaseOrders.forEach(item => {
        item.lineItems.forEach(lineItem => {
          if (purchaseItemToReceiveOrderItemID[lineItem.purchaseItemID]) {
            const purchaseItem = purchaseItemToReceiveOrderItemID[lineItem.purchaseItemID];
            lineItem.qtyReceived = purchaseItem.qtyReceived
            lineItem.qtyRemaining = purchaseItem.qtyRemaining
            lineItem.qtyReceivedPO = purchaseItem.qtyReceivedPO
            lineItem.qtyReceive = purchaseItem.qtyReceive
            lineItem.qtyPO = purchaseItem.qtyPO
          }
        })
      })

      const receiveOrderItems = getReceiveOrderItems(newGoodsReceiveOrder);
      const newReceiveOrder = {
        'receive_order': {
          'id': resp['receive_order']['id'],
          'user_id': resp['receive_order']['user_id'],
          'created_by': resp['receive_order']['created_by'],
          'number': resp['receive_order']['number'],
          'reference_number': newGoodsReceiveOrder.referenceNumber,
          'receive_date': resp['receive_order']['receive_date'],
          'purchase_order_id': resp['receive_order']['purchase_order_id'],
          'state': newGoodsReceiveOrder.state,
          'remarks': newGoodsReceiveOrder.remarks,
          'created_at': newGoodsReceiveOrder.createdAt,
          'updated_at': resp['receive_order']['updated_at'],
          'canceled_at': resp['receive_order']['canceled_at'],
          'stock_transfers': resp['receive_order']['stock_transfers'],
          'state_for_display': resp['receive_order']['state_for_display'],
          'expected_receive_date': newGoodsReceiveOrder.expectedReceiveDate,
          'purchase_order_ids': newGoodsReceiveOrder.purchaseOrderIDs,
          'note_type': 1,
          'unit_enable': resp['receive_order']['unit_enable'],
          'store_id': storeID,
          'supplier_id': newGoodsReceiveOrder.supplierID,
          'supplier_name': newGoodsReceiveOrder.supplierName,
        },
        'receive_order_items': receiveOrderItems
      }
      
      const res = yield axios({
        method: 'PUT',
        url: Config.urls.getReceiveOrder.replace('{storeID}', storeID).replace('{receiveOrderID}', resp['receive_order']['id']),
        data: newReceiveOrder,
        headers: {
          'x-user-device-type': 'pos',
        }
      })

      if (res && res['receive_order']) {
        returnReceiveOrder = res['receive_order'];
        newGoodReceivedNote = parseGoodReceivedNote(res['receive_order']);
      }
    }
  } catch (error) {
    console.error(error);
  }
  if (callback) {
    callback(newGoodReceivedNote);
  }

  return returnReceiveOrder;
}

function* handleCreateAndReceiveGoodReceivedNote(params: ICreateAndReceiveGoodReceivedNote) {
  const { storeID, goodReceivedNote, callback } = params;
  let newGoodReceivedNote: IReceiveOrder | undefined = undefined;
  try {
    const result: any = yield handleCreateGoodReceivedNote({
      type: ActionType.CREATE_GOOD_RECEIVED_NOTE,
      storeID: storeID,
      goodReceivedNote,
    });

    if (result && result['id']) {
      const receiveItems = result['receive_order_items'];
      const receiveOrderItems: any[] = [];
      if (Array.isArray(receiveItems)) {
        receiveItems.forEach(item => {
          if (item['receive_order_item']) {
            receiveOrderItems.push(item['receive_order_item'])
          }
        })
      }
      const receiveOrder = {
        'receive_order': result,
        'receive_order_items': receiveOrderItems,
      }

      const res = yield axios({
        method: 'PUT',
        url: Config.urls.receiveReceiveOrder.replace('{storeID}', storeID).replace('{receiveOrderID}', result['id']),
        data: receiveOrder,
        headers: {
          'x-user-device-type': 'pos',
        }
      })
      if (res && res['receive_order']) {
        newGoodReceivedNote = parseGoodReceivedNote(res['receive_order']);
      }
    }
  } catch (error) {
    console.error(error);
  }
  if (callback) {
    callback(newGoodReceivedNote);
  }
}

const getReceiveOrderItems = (receiveOrder: IReceiveOrder) => {
  const receiveOrderItems: any[] = [];
  receiveOrder.purchaseOrders.forEach(item => {
    item.lineItems.forEach(lineItem => {
      if (lineItem.receiveOrderItemID) {
        receiveOrderItems.push({
          ...lineItem.purchaseItem,
          'purchase_order_id': lineItem.purchaseOrderID,
          'qty_received': lineItem.qtyReceived * lineItem.displayUnitRatio,
          'quantity': lineItem.qtyReceive * lineItem.displayUnitRatio,
          'quantity_in_display_unit': lineItem.qtyReceive,
          'qty_received_in_display_unit': lineItem.qtyReceivedPO,
          'purchase_item': {
            'name': lineItem.name,
            'purchase_order_id': lineItem.purchaseOrderID,
            'source_id': lineItem.id,
            'id': lineItem.purchaseItemID,
            'source_type': 'Listing',
            'qty_requested': lineItem.qtyReceive,
            'quantity_in_display_unit': lineItem.qtyReceive,
            'qty_received_in_display_unit': lineItem.qtyReceived,
          },
          'qty_remaining': lineItem.qtyRemaining * lineItem.displayUnitRatio,
          'qty_remaining_in_display_unit': lineItem.qtyRemaining,
          'poquantity': lineItem.qtyReceive,
          'qty_fulfilled': 0,
          'display_unit_ratio': lineItem.displayUnitRatio,
          'display_unit_id': lineItem.displayUnitID === 0 ? null : lineItem.displayUnitID,
        })
      } else {
        const receiveOrderItem = {
          ...lineItem.purchaseItem,
          'purchase_order_id': lineItem.purchaseOrderID,
          'quantity': lineItem.qtyReceive * lineItem.displayUnitRatio,
          'quantity_in_display_unit': lineItem.qtyReceive,
          'qty_requested': lineItem.qtyReceive * lineItem.displayUnitRatio,
          'qty_received': lineItem.qtyReceived * lineItem.displayUnitRatio,
          'qty_received_in_display_unit': lineItem.qtyReceivedPO,
          'purchase_item': {
            'name': lineItem.name,
            'purchase_order_id': lineItem.purchaseOrderID,
            'source_id': lineItem.id
          },
          'purchase_item_id': lineItem.purchaseItem['id'],
          'qty_remaining': lineItem.qtyRemaining * lineItem.displayUnitRatio,
          'qty_remaining_in_display_unit': lineItem.qtyRemaining,
          'display_unit_ratio': lineItem.displayUnitRatio,
          'display_unit_id': lineItem.displayUnitID === 0 ? null : lineItem.displayUnitID,
        }
        delete receiveOrderItem['id'];
        receiveOrderItems.push(receiveOrderItem)
      }
    })
  })

  return receiveOrderItems;
}

function* paySaga() {
  yield takeEvery(ActionType.QUERY_GOOD_RECEIVED_NOTES, handleQueryGoodReceivedNotes);
  yield takeEvery(ActionType.QUERY_GOOD_RECEIVED_NOTE, handleQueryGoodReceivedNote);
  yield takeEvery(ActionType.CANCEL_GOOD_RECEIVED_NOTE, handleCancelGoodReceivedNote);
  yield takeEvery(ActionType.RECEIVE_GOOD_RECEIVED_NOTE, handleReceiveGoodReceivedNote);
  yield takeEvery(ActionType.CREATE_GOOD_RECEIVED_NOTE, handleCreateGoodReceivedNote);
  yield takeEvery(ActionType.CREATE_AND_RECEIVE_GOOD_RECEIVED_NOTE, handleCreateAndReceiveGoodReceivedNote);
}

export default paySaga();
