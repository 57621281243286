import React from 'react';
import StoreList from '@/components/StoreList';
import { resultPrefix } from '.';
import { IStore } from '@/actions/store-action';
import { IReducersState } from '@/reducers';
import { getIntl } from '../App/App';

interface ISearchContentProps {
  data: IStore[];
  reducersState: IReducersState;
  loading: boolean;
  onItemClick?: (data: IStore) => void;
}

const SearchContent: React.FC<ISearchContentProps> = props => {

  const {
    data,
    reducersState,
    loading,
    onItemClick,
  } = props;


  return (
    <div className={`${resultPrefix}`}>
      <div className="tilte-row with-line">
        <h2 className="h2 black-1">{`${data.length} ${getIntl().page.results}`}</h2>
      </div>
      <StoreList
        data={data}
        reducersState={reducersState}
        loading={loading}
        onItemClick={onItemClick}
        className={`${resultPrefix}-store-list`}
        itemClassName={`${resultPrefix}-store-list-item`}
      />
    </div>
  )
}
export default SearchContent;

