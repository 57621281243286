import Config from '@/Config';
import { createRecord, queryRecords } from './mb-api';

export const getQueueings = async (formula: string, orderBy?: string, page = 1, perPage = 10) => {
  const result = await queryRecords({
    storeSlugs: [Config.bindoGatewayStoreSlug],
    moduleBundleID: 'bm-booking',
    search: {
      formulas:[{formula: formula}],
      orderBy: orderBy || 'id',
      page,
      perPage,
    },
  });

  return result.records;
}

export const getQueueingSettings = async (storeSlugs: string[]) => {
  const result = await queryRecords({
    moduleBundleID: 'party_size_segment_items',
    storeSlugs,
    search: {
      orderAsc: 'ASC',
      orderBy: 'maximum_party_size',
    }
  });

  return result.records;
}

export const createQueueing = async (cover: number, customerID: string, userID: string, storeID: string, storeSlug: string, id: string, uuid: string) => {
  const record = {
    'store_id': storeID,
    'people': cover,
    'status': 'waiting',
    'is_parent': true,
    'booking_type': '4',
    'customer_id': customerID ? {records: [{id: customerID || undefined}]} : undefined,
    'linked_source_id': userID || undefined,
    'src_type': 'phone',
    'party_size_segment_item_id': id,
    'device_uuid': uuid || undefined,
  }
  if (!customerID) {
    delete record['customer_id'];
    delete record['linked_source_id'];
  } else {
    delete record['device_uuid'];
  }

  const result = await createRecord({
    storeSlug,
    moduleBundleID: 'tesldtx7',
    record,
  });

  let newRecord = undefined;

  if (result.success && result.record) {
    newRecord = result.record;
  }

  return newRecord;
}
