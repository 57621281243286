import { RouteComponentProps } from 'react-router-dom';
import withRoute from '@/pages/withRoute';
import PurchaseOrderDetail from './PurchaseOrderDetail';
import './PurchaseOrderDetail.less';

export const prefix = 'bm-p-purchase-order-detail';

const RouteComponent: React.FC<RouteComponentProps> = props => withRoute({
  pageComponent: PurchaseOrderDetail,
  routeProps: props,
  pageTitleI18nKey: '',
});
export default RouteComponent;
