import React from 'react';
import { IPayCard, IGiftCard } from '@/actions/pay-action';
import { formatCurrency } from '../Payment';
import { checkIOSSafari } from '@/utils';
import { PayTypes } from '@/lib/payment';
import { prefix } from '.';
import SelectPayMethodItem from './SelectPayMethodItem';
import Icon, { IconType } from '../Icon';
import { IReducersState } from '@/reducers';
import { useSelector } from 'react-redux';
import { getIntl } from '../App/App';
import { langFormat } from '@/utils/app';

const payCardTypeIcons: {[key: string]: IconType} = {
  VISA: IconType.VISA_CARD,
  AMEX: IconType.AE_CARD,
  MASTER: IconType.MASTER_CARD,
};

interface ISelectPayMethodProps {
  payCards: IPayCard[];
  anonymousGiftCards?: IGiftCard[];
  storeGiftCards?: IGiftCard[];
  className?: string;
  selectedGiftCard?: IGiftCard | undefined;
  selectedMethod?: string | undefined;
  canPayMethods: PayTypes[];
  showWxMiniProgram?: boolean;
  currencyCode?: string;
  onItemClick?: (key: PayTypes, data?: IGiftCard | IPayCard) => void;
  allowCompanyAccount?: boolean;
  showGooglePay?: boolean;
}

const SelectPayMethod: React.FC<ISelectPayMethodProps> = props => {
  const {
    payCards,
    storeGiftCards,
    anonymousGiftCards,
    className = '',
    selectedGiftCard,
    selectedMethod,
    canPayMethods,
    showWxMiniProgram = false,
    currencyCode,
    onItemClick,
    showGooglePay,
  } = props;

  const { id: giftCardID } = selectedGiftCard || {};

  const handleItemClick = (key: PayTypes, data?: any) => {
    if (onItemClick) {
      onItemClick(key, data);
      
    }
  }

  const isIOSSafari = checkIOSSafari();
  const reducersState: IReducersState = useSelector<IReducersState, IReducersState>(state => state);
  const { deviceInfo, platformAppSettings } = reducersState.app;

  let isSuppliers = false;
  if (platformAppSettings) {
    isSuppliers = platformAppSettings.suppliers;
  }

  return (
    <div className={`${prefix} ${className} choose-payment-block`}>
      {
        !showWxMiniProgram &&
        <>
          {
            isSuppliers && canPayMethods.includes(PayTypes.DEFERRED_BILLING) &&
            <SelectPayMethodItem
              title={getIntl().pay.deferredBilling}
              checked={selectedMethod === PayTypes.DEFERRED_BILLING}
              onItemClick={() => handleItemClick(PayTypes.DEFERRED_BILLING)}
            />
          }
          {
            isSuppliers && canPayMethods.includes(PayTypes.CASH_ON_DELIVERY) &&
            <SelectPayMethodItem
              title={getIntl().pay.cashOnDelivery}
              checked={selectedMethod === PayTypes.CASH_ON_DELIVERY}
              onItemClick={() => handleItemClick(PayTypes.CASH_ON_DELIVERY)}
              icon={<Icon type={IconType.CASH_ON_DELIVERY} />}
            />
          }
          {
            canPayMethods.includes(PayTypes.VISA || PayTypes.MASTER || PayTypes.JCB || PayTypes.AMEX || PayTypes.UNION_PAY || PayTypes.STORE_CREDIT) &&
            <SelectPayMethodItem
              title={getIntl().page.paymentCardTitle}
              className="payment-cards"
              icon={
                <>
                  {
                    canPayMethods.includes(PayTypes.STORE_CREDIT) &&
                    <Icon type={IconType.GIFT_CARD} />
                  }
                  {
                    canPayMethods.includes(PayTypes.VISA) &&
                    <Icon type={IconType.VISA_CARD} />
                  }
                  {
                    canPayMethods.includes(PayTypes.MASTER) &&
                    <Icon type={IconType.MASTER_CARD} />
                  }
                  {
                    canPayMethods.includes(PayTypes.JCB) &&
                    <Icon type={IconType.JCB_CARD} />
                  }
                  {
                    canPayMethods.includes(PayTypes.AMEX) &&
                    <Icon type={IconType.AE_CARD} />
                  }
                  {
                    canPayMethods.includes(PayTypes.UNION_PAY) &&
                    <Icon type={IconType.UNION_PAY} />
                  }
                </>
              }
              onItemClick={() => handleItemClick(PayTypes.CREDIT_CARD)}
            />
          }
          {
            anonymousGiftCards && anonymousGiftCards.map(item => (
              <SelectPayMethodItem
                key={item.id}
                title={
                  <div className="gift-card-title">
                    {getIntl().pay.giftCard}
                    <span className="last-digits">
                      {item.number.length > 4 ? item.number.substr(item.number.length - 4) : item.number}
                    </span><br/>
                    <span className="card-balance">
                      {langFormat(getIntl().page.balanceNode, {balance: formatCurrency(item.balance, currencyCode)})}
                    </span>
                  </div>}
                icon={<Icon type={IconType.GIFT_CARD} />}
                checked={giftCardID === item.id}
                onItemClick={() => handleItemClick(PayTypes.ANONYMOUS_GIFT_CARD, item)}
              />
            ))
          }
          {
            canPayMethods.includes(PayTypes.STORE_CREDIT) && storeGiftCards && storeGiftCards.map(item => (
              <SelectPayMethodItem
                key={item.id}
                title={
                  <div className="gift-card-title">
                    {getIntl().pay.giftCard}
                    <span className="last-digits">
                      {item.number.length > 4 ? item.number.substr(item.number.length - 4) : item.number}
                    </span><br/>
                    <span className="card-balance">
                      {langFormat(getIntl().page.balanceNode, {balance: formatCurrency(item.balance, currencyCode)})}
                    </span>
                  </div>}
                icon={<Icon type={IconType.GIFT_CARD} />}
                checked={giftCardID === item.id}
                onItemClick={() => handleItemClick(PayTypes.STORE_CREDIT, item)}
              />
            ))
          }
          {
            canPayMethods.includes(PayTypes.VISA || PayTypes.MASTER || PayTypes.JCB || PayTypes.AMEX || PayTypes.UNION_PAY) && payCards.map(item => (
              <SelectPayMethodItem
                key={item.id}
                title={
                  <span>
                    {getIntl().pay.cardEnding}
                    <span className="last-digits">{item.last4Digits}</span>
                  </span>}
                icon={<Icon type={payCardTypeIcons[item.type] || IconType.VISA_CARD} />}
                checked={selectedMethod === `${item.type}_${item.id}`}
                onItemClick={() => handleItemClick(item.type, item)}
              />
            ))
          }
          {
            canPayMethods.includes(PayTypes.APPLE_PAY) && isIOSSafari &&
            <SelectPayMethodItem
              title={getIntl().pay.applePay}
              icon={<Icon type={IconType.APPLE_PAY} />}
              checked={selectedMethod === PayTypes.APPLE_PAY}
              onItemClick={() => handleItemClick(PayTypes.APPLE_PAY)}
            />
          }
          {
            canPayMethods.includes(PayTypes.GOOGLE_PAY) && showGooglePay &&
            <SelectPayMethodItem
              title={getIntl().pay.googlePay}
              icon={<Icon type={IconType.GOOGLE_PAY} />}
              checked={selectedMethod === PayTypes.GOOGLE_PAY}
              onItemClick={() => handleItemClick(PayTypes.GOOGLE_PAY)}
            />
          }
          {
            !deviceInfo.isWeChat && canPayMethods.includes(PayTypes.ALIPAY) &&
            <SelectPayMethodItem
              title={getIntl().pay.alipay}
              icon={<Icon type={IconType.ALIPAY} />}
              checked={selectedMethod === PayTypes.ALIPAY}
              onItemClick={() => handleItemClick(PayTypes.ALIPAY)}
            />
          }
          {
            !deviceInfo.isWeChat && canPayMethods.includes(PayTypes.ALIPAY_HK) &&
            <SelectPayMethodItem
              title={getIntl().pay.alipayHK}
              icon={<Icon type={IconType.ALIPAY_HK} />}
              checked={selectedMethod === PayTypes.ALIPAY_HK}
              onItemClick={() => handleItemClick(PayTypes.ALIPAY_HK)}
            />
          }
          {
            canPayMethods.includes(PayTypes.TAP_GO) &&
            <SelectPayMethodItem
              title={getIntl().pay.tapGoPay}
              icon={<Icon type={IconType.TAPGO_PAY} />}
              checked={selectedMethod === PayTypes.TAP_GO}
              onItemClick={() => handleItemClick(PayTypes.TAP_GO)}
            />
          }
          {
            deviceInfo.inPhone && canPayMethods.includes(PayTypes.WECHAT_PAY) &&
            <SelectPayMethodItem
              title={getIntl().pay.weChatPay}
              icon={<Icon type={IconType.WECHAT_PAY} />}
              checked={selectedMethod === PayTypes.WECHAT_PAY}
              onItemClick={() => handleItemClick(PayTypes.WECHAT_PAY)}
            />
          }
        </>
      }
      {
        showWxMiniProgram &&
        <SelectPayMethodItem
          title={getIntl().pay.weChatPay}
          icon={<Icon type={IconType.WECHAT_PAY} />}
          checked={selectedMethod === PayTypes.WECHAT_PAY}
          onItemClick={() => handleItemClick(PayTypes.WECHAT_PAY)}
        />
      }
    </div>
  );
}

export default SelectPayMethod;
