import React from 'react';
import AntdIcon from 'antd-mobile/es/icon';
import { formatCurrency } from '@/components/Payment';
import PayInfoItem from './PayInfoItem';
import Icon, { IconType } from '@/components/Icon';
import { IOrderInfo, ISaleTransaction } from '@/lib/order-builder/models/OrderEntity';
import { getIntl, getIntlByPath } from '@/components/App/App';

interface IPayInfoProps {
  title: React.ReactNode;
  showBarTotal?: boolean;
  className?: string;
  suppliers?: boolean;
  orderInfo?: IOrderInfo;
  saleTransactions?: ISaleTransaction[];
  showItemLeftIcon?: boolean;
  showBalance?: boolean;
  onItemClick?: () => void;
  onAddPayMethod?: () => void;
  onDeleteClick?: () => void;
  referenceID?: number;
  payResultsPage?: boolean;
  isPayBillPage?: boolean;
}

const PayInfo: React.FC<IPayInfoProps> = props => {
  const {
    title,
    orderInfo,
    saleTransactions,
    className = '',
    suppliers = false,
    showBarTotal = false,
    showItemLeftIcon = false,
    showBalance = false,
    referenceID,
    onItemClick,
    onAddPayMethod,
    onDeleteClick,
  } = props;

  return (
    <div className={`${className} ticket-wrapper-4`}>
      <div className="clipped-4" id="ticket">
        {
          referenceID && !suppliers && !orderInfo?.superOrder &&
          <div className="div-block-456">
            <div className="reference-id">
              <h1 className="heading_1"> {referenceID}</h1>
              <div className="text-block-226 less-margin"> {getIntl().page.referenceID}</div>
            </div>
          </div>
        }
        <div className="div-block-456">
          {
            title && !suppliers &&
            <div className="div-block-436">
              <div className="text-block-46">
                {getIntl().page.storeName}
              </div>
              <div className="text-block-46">
                {title}
              </div>
            </div>
          }
          {
            suppliers && title &&
            <div className="div-block-436">
              <div className="text-block-46">
                {getIntl().page.platformName}
              </div>
              <div className="text-block-46">
                {title}
              </div>
            </div>
          }
          {
            suppliers && orderInfo &&
            <div className="div-block-436">
              <div className="text-block-46">
                {getIntl().page.storeName}
              </div>
              <div className="text-block-46">
                {orderInfo.orderEntities[0].customerName}
              </div>
            </div>
          }
          {
            saleTransactions && saleTransactions.length > 0 && saleTransactions.map(item => {
              return (
                <PayInfoItem
                  key={item.id}
                  data={item}
                  showLeftIcon={showItemLeftIcon}
                  showBalance={showBalance}
                  onDelete={onDeleteClick}
                  onItemClick={onItemClick}
                />
              )
            })
          }
          {
            showBarTotal && orderInfo &&
            <>
              <div className="div-block-436">
                <div className="text-block-46">
                  {getIntl().common.invoiceState}
                </div>
                {
                  !suppliers &&
                  <div className="text-block-46">
                    {getIntlByPath(`common.${orderInfo.invoiceState === 'paid' && Number(orderInfo.initialTotal) === Number(orderInfo.paidTotal) ? 'fullPaid' : 'partialPaid'}`)}
                  </div>
                }
                {
                  suppliers &&
                  <div className="text-block-46">
                    {orderInfo.invoiceState === 'paid' && Number(orderInfo.initialTotal) === Number(orderInfo.paidTotal) ? getIntlByPath(`common.${'fullPaid'}`) : getIntl().pay.deferredBilling}
                  </div>
                }
              </div>
              <div className="div-block-436">
                <div className="text-block-46">
                  {getIntl().common.paidTotal}
                </div>
                <div className="text-block-46">
                  {formatCurrency(orderInfo.paidTotal, orderInfo.currencyCode)}
                </div>
              </div>
            </>
          }
          {
            saleTransactions && saleTransactions.length === 0 && onAddPayMethod &&
            <div className="add-pay-method" onClick={onAddPayMethod}>
              <Icon type={IconType.PLUS_OUTLINED}  />
              {getIntl().page.addPayMethod}
            </div>
          }
          {
            !saleTransactions &&
            true && 
            <div className="pi-loading">
              <AntdIcon type="loading" size="lg" color="red" />
            </div>
          }
        </div>
        <div className="html-embed-21 w-embed">
          <svg className="svg-4">
            <clipPath id="my-clip-path-4" clipPathUnits="objectBoundingBox"><path d="M0,0.026 C0,0.012,0.008,0,0.017,0 H0.983 C0.992,0,1,0.012,1,0.026 V0.397 C0.99,0.397,0.983,0.408,0.983,0.422 C0.983,0.437,0.99,0.448,1,0.448 V0.974 C1,0.988,0.992,1,0.983,1 H0.017 C0.008,1,0,0.988,0,0.974 V0.448 C0.01,0.448,0.017,0.437,0.017,0.422 C0.017,0.408,0.01,0.397,0,0.397 V0.026"></path></clipPath>
          </svg>
        </div>
      </div>
    </div>
  );
}

export default PayInfo;
