import queryString from 'query-string';
import trimStart from 'lodash/trimStart';
import isString from 'lodash/isString';
import * as H from 'history';
import { RouteComponentProps } from 'react-router-dom';
import { Base64 } from 'js-base64';
import { IObject } from '@/components/bm-common';
import Config from '@/Config';
import BigNumber from 'bignumber.js';
import { IWindowApple } from '@/lib/payment';
import Constants from '@/constants';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import trim from 'lodash/trim';
import  startsWith from 'lodash/startsWith';


export const modifySearch = (search: string) => {
  search = trimStart(search, '?');
  search = search.replace(/\?/g, '&');
  return search;
}

export const getPlatformFromPathname = () => {
  const { pathname } = window.location;
  const pathParams = pathname.split('/');

  let platform = '';
  if (pathParams.length > 2 && pathParams[1] === Constants.PLATFORM) {
    platform = pathParams[2];
  }

  return platform;
}

export const getDomain = (platformKey?: string) => {
  let platformDomain = Config.defaultPageDomain;
  let isLocalhost = false;
  let hideSplash = false;
  let firstVisited = false;
  let initCoordinates = false;
  let verifyToken = true;

  const { hostname, host } = window.location;
  const platform = getPlatformFromPathname();
  const ipRegExp = /^((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})(\.((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})){3}$/;
  if (hostname === 'localhost' || ipRegExp.test(hostname)) {
    isLocalhost = true;
  }

  if (platform || platformKey) {
    platformDomain = platformKey || `${platform}${Config.domainSuffix}`;
  } else {
    if (isLocalhost) {
      platformDomain = Config.defaultPageDomain;
    } else {
      platformDomain = hostname;
    }
  }

  const search: IObject = queryString.parse(modifySearch(window.location.search)) || {};
  if (search && search.domain) {
    platformDomain = search.domain;
  } else { // 如果以特殊标示的开头结尾的信息
    const { pathname } = window.location;
    const pathParams = pathname.split('/');
    const locationSearch = window.location.search.substr(1);

    if (pathParams.length > 2 && pathParams[pathParams.length -1].startsWith(Constants.PLATFORMPAYINFO)) {
      const payInfo = getPlatformPayInfo(pathParams[pathParams.length -1]);
      if (payInfo.domain) platformDomain = payInfo.domain;
    } else if (locationSearch.length > 0 && locationSearch.startsWith(Constants.PLATFORMPAYINFO)) {
      const payInfo = getPlatformPayInfo(locationSearch);
      if (payInfo.domain) platformDomain = payInfo.domain;
    }

  }

  if (search && (search.hideSplash === '1' || search.proc)) {
    hideSplash = true;
  }
  if (window.location.pathname.includes('/qrcode-shopping/')) { // 扫码点餐模式
    hideSplash = true; // 不显示splash页面
    initCoordinates = false; // 不初始化定位
    verifyToken = false; // 不验证token
  }

  if (Config.appMerchantDomain === hostname || window.location.pathname.includes('/pay-bill') || window.location.pathname.includes('/sc/')) {
    hideSplash = true; // 不显示splash页面
    initCoordinates = false; // 不初始化定位
  }
  if (window.location.pathname === '/stores' || window.location.pathname.includes('/stores/by/')) {
    initCoordinates = true;
  }

  if (search && search.mode === 'dedicated') { // dedicated 模式不显示启动页
    hideSplash = true;
  }

  if (search && search.firstVisited === '1') {
    firstVisited = true;
  }
  if (window.location.pathname.includes('/eshop')) {
    hideSplash = true; // 不显示splash页面
  }

  let platformHost = platformDomain;
  if (isLocalhost || platform) {
    platformHost = host;
  }

  return {
    gatewayDomain: Config.appMerchantDomain,
    platformDomain,
    platform,
    isLocalhost,
    domain: hostname,
    platformHost,
    hideSplash,
    firstVisited,
    initCoordinates,
    verifyToken,
  };
}

export const getSearchParams = () => {
  let search: IObject = queryString.parse(modifySearch(window.location.search)) || {};
  if (!search.token) {
    const { pathname } = window.location;
    const pathParams = pathname.split('/');
    const locationSearch = window.location.search.substr(1);

    if (pathParams.length > 2 && pathParams[pathParams.length -1].startsWith(Constants.PLATFORMPAYINFO)) {
      const payInfo = getPlatformPayInfo(pathParams[pathParams.length -1]);
      if (payInfo.domain) {
        search = {
          ...payInfo,
        }
      }
    } else if (locationSearch.length > 0 && locationSearch.startsWith(Constants.PLATFORMPAYINFO)) {
      const payInfo = getPlatformPayInfo(locationSearch);
      search = {
        ...payInfo,
      }
    }

  }
  return search;
}

export const parseSearch = (location: H.Location<H.History.PoorMansUnknown>) => {
  const query = {
    ...(queryString.parse(modifySearch(window.location.search)) || {}),
    ...location.state,
  };

  return query;
}

export interface IRouteParams extends IObject {
  routePath: string;
  storeSlug: string;
  listingID: string;
  orderNumber: string;
  modifierIDs: string[];
  modifierIDsStr: string;
  blogID: string;
  contentType: string;
  categoryID: string;
  authCode: string;
  confirmationMode: string;
}

/**
 * 从路由信息match中解析出params参数
 * @param {*} props
 */
export const parseRouteParams = (props: RouteComponentProps) => {
  const { location, match } = props;
  const query = parseSearch(location);
  const {
    params = {},
    path = '',
  } = match;

  const newParams: IRouteParams = {
    storeSlug: '',
    listingID: '',
    orderNumber: '',
    modifierIDsStr: '',
    modifierIDs: [],
    blogID: '',
    contentType: '',
    categoryID: '',
    authCode: '',
    confirmationMode: '',
    ...query,
    ...params,
    routePath: path,
  };

  if (isString(newParams.modifierIDs) && newParams.modifierIDs.length > 0) {
    newParams.modifierIDsStr = newParams.modifierIDs;
    newParams.modifierIDs = newParams.modifierIDs.split('-').filter(item => item);
  }

  if (!Array.isArray(newParams.modifierIDs)) {
    newParams.modifierIDs = [];
  }

  if (!newParams.payInfo) {
    const locationSearch = window.location.search.substr(1);
    if (locationSearch.length > 0 && locationSearch.startsWith(Constants.PLATFORMPAYINFO)) {
      newParams.payInfo = locationSearch;
    }
  }

  return newParams;
}

/**
 * 根据route和params创建url, 如果存在props, 缺省值从props中取
 * @param {*} route 路由地址
 * @param {*} params 路由参数
 * @param {*} props react对象的props
 */
export const createRouteUrl = (route: string, params: IObject = {}, props?: RouteComponentProps) => {
  route = route || '';

  const platform = getPlatformFromPathname();
  if (platform) {
    route = `/${Constants.PLATFORM}/${platform}${route}`
  }

  if (props && props.match) {
    const { params: matchParams = {}, path } = props.match;
    params = {
      ...matchParams,
      ...params,
    };

    if (route === '') {
      route = path;
    }
  }

  Object.keys(params).forEach(key => {
    if (key === 'p') {
      route = route.replace(`/:${key}/`, `/${params[key]}/`);
    } else {
      route = route.replace(`:${key}`, params[key]);
    }
  });

  const queryKvArr: string[] = [];
  if (params && params.query) {
    Object.keys(params.query).forEach(key => {
      queryKvArr.push(`${key}=${params.query[key]}`)
    });
  }

  if (queryKvArr.length > 0) {
    route = `${route}?${queryKvArr.join('&')}`
  }

  return route;
};

let htmlBackgroundColor = '';
let bcStyleTag: any;
export const setHtmlBackgroundColor = (color: string) => {
  if (htmlBackgroundColor !== color) {
    htmlBackgroundColor = color;
    const css = 'body { background: ' + htmlBackgroundColor + '; }';

    if (!bcStyleTag) {
      bcStyleTag = document.createElement('style');
      const head = document.head || document.getElementsByTagName('head')[0];
      head.appendChild(bcStyleTag);
    }

    if (bcStyleTag.styleSheet) {
      bcStyleTag.styleSheet.cssText = css;
    } else {
      bcStyleTag.innerHTML = css;
    }
  }
};

export const setBodyScrollable = (scroll: boolean) => {
  document.body.style.overflow = scroll ? 'auto' : 'hidden';
}

/**
 * 下划线转换驼峰
 * @param name
 * set_text_color --> setTextColor
 * _base_page --> BasePage
 */
export const lineToHump = (name: string) => {
  //eslint-disable-next-line no-useless-escape
  return name.replace(/\_(\w)/g, (all, letter) => letter.toUpperCase());
}

/**
 * 驼峰转换下划线
 * @param name
 * setTextColor --> set_text_color
 * BasePage --> _base_page
 */
export const humpToLine = (name: string) => {
  return name.replace(/([A-Z])/g, '_$1').toLowerCase();
}

/**
 * 根据Tree中对象属性key，找到对应的节点
 * @param {Array} list [{}, {}]
 * @param {String} key 对象属性key
 * @param {Any} value 对象属性key的值
 */
export function findNode<T>(list: any[] = [], key: string, value: any, childrenKey = 'children'): T | undefined {
  if (typeof key !== 'string' || !Array.isArray(list)) {
    return undefined;
  }

  for (let i = 0; i < list.length; i++) {
    const node = list[i];
    // eslint-disable-next-line
    if (node[key] == value) {
      return node;
    } else if (node[childrenKey] && node[childrenKey].length > 0) {
      const tempNode = findNode<T>(node[childrenKey], key, value, childrenKey);
      if (tempNode) {
        return tempNode;
      }
    }
  }

  return undefined;
}

/**
 * 判断是否支持apple pay
 */
export const checkIOSSafari = () => {

  const {
    ApplePaySession,
  } = window as IWindowApple

  return !!ApplePaySession
}

export const minus = (a: number, b: number) => {
  const c  = new BigNumber(a);
  return Number(c.minus(b));
}

/**
 * 在本地保存json对象
 * @param key 储存标识
 * @param json
 */
export const setLocalStorageJson = (key: string, json: any): void => {
  localStorage.setItem(key, JSON.stringify(json));
};

/**
 * 从本地获取json对象
 * @param key 储存标识
 */
export const getLocalStorageJson = (key: string): any => {
  let json: any = localStorage.getItem(key);

  if (json) {
    json = JSON.parse(json);
  }

  return json || undefined;
};

/**
 * 在本地保存json对象
 * @param key 储存标识
 * @param json
 */
export const setSessionStorageJson = (key: string, json: any): void => {
  sessionStorage.setItem(key, JSON.stringify(json));
};

/**
 * 从本地获取json对象
 * @param key 储存标识
 */
export const getSessionStorageJson = (key: string): any => {
  let json: any = sessionStorage.getItem(key);

  if (json) {
    json = JSON.parse(json);
  }

  return json || undefined;
};

/**
 * 将图片缩放成固定高度，宽度按比例处理
 */
interface IResizeImageByHeight {
  type: 'HEIGHT';
  url: string;
  height: number;
  defaultUrl?: string;
}

/**
 * 将图片缩放成固定宽度，高度按比例处理
 */
interface IResizeImageByWidth {
  type: 'WIDTH';
  url: string;
  width: number;
  defaultUrl?: string;
}

/**
 * 将图片长边限制为固定像素，短边按比例处理
 */
interface IResizeImageByLongSide {
  type: 'LONG_SIDE';
  url: string;
  longSide: number;
  defaultUrl?: string;
}

/**
 * 制定矩形框，按框内(lfit)框外(mfit)处理，另一边按比例处理
 */
interface IResizeImageByFit {
  type: 'LFIT' | 'MFIT';
  url: string;
  width: number;
  height: number;
  defaultUrl?: string;
}

/**
 * 固定宽高，自动裁剪
 */
interface IResizeImageByFILL {
  type: 'FILL';
  url: string;
  width: number;
  height: number;
  defaultUrl?: string;
}

/**
 * 固定宽高，缩放填充，填充颜色默认白色
 */
interface IResizeImageByPad {
  type: 'PAD';
  url: string;
  width: number;
  height: number;
  color?: string;
  defaultUrl?: string;
}

interface IResizeImageByDefault {
  type: 'DEFAULT';
  url: string;
  color?: string;
  defaultUrl?: string;
}

type IResizeImageParams = IResizeImageByWidth | IResizeImageByHeight | IResizeImageByLongSide
                          | IResizeImageByFit | IResizeImageByFILL | IResizeImageByPad | IResizeImageByDefault;

export const resizeImage = (params: IResizeImageParams) => {
  const {
    type,
    url,
    defaultUrl,
  } = params;

  let newUrl = url;

  if (url) {
    if (url.includes('bindo-images.s3.amazonaws.com')) {
      newUrl = url.replace('bindo-images.s3.amazonaws.com', 'cdn.bindo.co');
    } else if (url.includes('d1syi56bc9082w.cloudfront.net')) {
      newUrl = url.replace('d1syi56bc9082w.cloudfront.net', 'cdn.bindo.co');
    } else if (url.includes('bindo-images-dev.s3.amazonaws.com')) {
      newUrl = url.replace('bindo-images-dev.s3.amazonaws.com', 'cdn-stg.bindo.co');
    } else if (url.includes('d25i28iur6fb9a.cloudfront.net')) {
      newUrl = url.replace('d25i28iur6fb9a.cloudfront.net', 'cdn-stg.bindo.co');
    }
    if (newUrl.includes('product_graphics') && newUrl.includes('medium')) {
      newUrl = newUrl.replace('medium', 'big');
    }
  }

  let query = '';

  let urlHrefInfo: string[] = [];
  if (newUrl) {
    urlHrefInfo = newUrl.split('?');
  }
  let isConvertible = true;
  if (urlHrefInfo.length > 1 && urlHrefInfo[0].endsWith('.svg')) {
    isConvertible = false;
  }

  if (newUrl && isConvertible) {
    if (type === 'WIDTH') {
      const newParams = params as IResizeImageByWidth;
      query = `x-oss-process=image/resize,w_${newParams.width || 1}`
    } else if (type === 'HEIGHT') {
      const newParams = params as IResizeImageByHeight;
      query = `x-oss-process=image/resize,h_${newParams.height || 1}`
    } else if (type === 'LONG_SIDE') {
      const newParams = params as IResizeImageByLongSide;
      query = `x-oss-process=image/resize,l_${newParams.longSide || 1}`
    } else if (type === 'LFIT' || type === 'MFIT') {
      const newParams = params as IResizeImageByFit;
      query = `x-oss-process=image/resize,m_${type.toLocaleLowerCase()},h_${newParams.height},w_${newParams.width}`
    } else if (type === 'FILL') {
      const newParams = params as IResizeImageByFILL;
      query = `x-oss-process=image/resize,m_${type.toLocaleLowerCase()},h_${newParams.height},w_${newParams.width}`
    } else if (type === 'PAD') {
      const newParams = params as IResizeImageByPad;
      query = `x-oss-process=image/resize,m_${type.toLocaleLowerCase()},h_${newParams.height},w_${newParams.width}`

      if (newParams.color && newParams.color.length === 6) {
        query = `${query},color_${newParams.color}`
      }
    } else if(type === 'DEFAULT') {
      query = ''
    }

    if (newUrl.includes('?')) {
      newUrl = `${newUrl}&${query}`;
    } else {
      newUrl = `${newUrl}?${query}`;
    }
  }

  if (!newUrl && defaultUrl) {
    newUrl = defaultUrl;
  }

  return newUrl;
}

export const checkImgExists = (imgUrl: string, callback: (success: boolean, imgUrl: string) => void) => {
  const imgOjb = new Image();
  imgOjb.onload = () => {
    if (callback) {
      callback(true, imgUrl);
    }
  }

  imgOjb.onerror = () => {
    if (callback) {
      callback(false, imgUrl);
    }
  }

  imgOjb.src = imgUrl;
}

export const pxToRem = (px: number) => {
  const pxNumber = Number(px);
  const rem = pxNumber / 75;
  const fixed = Math.pow(10, 5);

  return `${Math.round(rem * fixed) / fixed}rem`
}

export const docPxToRem = (px: number) => {
  const pxNumber = Number(px);
  const rem = (pxNumber * 10) / window.innerWidth;
  const fixed = Math.pow(10, 5);

  return `${Math.round(rem * fixed) / fixed}rem`
}

export const scalePx = (px: number) => {
  const pxNumber = Number(px);
  const newPx = (window.innerWidth / 750) * pxNumber;

  return parseInt(`${newPx}`);
}

export const choiceStoreID = (storeID: string, groupStoreID: string) => {
  let newStoreID = storeID;
  let isSuperOrder = false;
  if (groupStoreID && storeID !== groupStoreID) {
    newStoreID = groupStoreID;
    isSuperOrder = true;
  }

  return {
    storeID: newStoreID,
    isSuperOrder,
  }
}

/**
 * 判断是否是苹果设备
 */
export const checkApple = () => {
  const ua = navigator.userAgent.toLowerCase();
  const ver = ua.match(/cpu iphone os (.*?) like mac os/) || ua.match(/version\/(.*?) safari\//);
  const isIOS = ver && ver[1];

  const {
    AppleID,
  } = window as IWindowApple;

  return !!isIOS && !!AppleID
}

export const modifyFavicon = (faviconUrl: string) => {
  if (faviconUrl && typeof faviconUrl === 'string' && faviconUrl.length > 0) {
    const links = document.getElementsByTagName('link');
    for (let i = 0; i < links.length; i++) {
      const link = links[i];
      if (link.rel === 'shortcut icon') {
        link.href = faviconUrl;
      }
    }
  }
}

interface IParseScanUrl {
  isHttp: boolean;
  protocol: string;
  host: string;
  platform: string;
  platformRoute: string;
  isBindoCo: boolean;
  isSamePlatform: boolean;
  isSameEvn: boolean;
  evn: 'staging' | 'alpha' | 'production' | '';
  url: string;
  search: string;
  searchParams: IObject;
  pathname: string;
}

export const parseScanUrl = (url: string, isHref = false): IParseScanUrl => {
  let isHttp = false;
  let protocol = '';
  let platform = '';
  // let hostname = Config.defaultPageDomain;
  // const href = window.location.href;
  // const hrefSplit = href.split('://');
  // let isBindoCo = false;
  // if (hrefSplit.length > 1) {
  //   const hrefPathParams = hrefSplit[1].split('/');
  //   if (hrefPathParams.length > 1 && hrefPathParams[1] === 'p') {
  //     isBindoCo = true;
  //   }
  // }

  // let route = '';
  // const { platform: cPlatform } = getDomain();

  let host = '';
  let platformRoute = '';
  let search = '';
  let pathname = '';
  let isBindoCo = false;
  let isSamePlatform = false;
  let isSameEvn = false;
  let evn: 'staging' | 'alpha' | 'production' | '' = '';
  if (url) {
    if (/^(https?:\/\/)/.test(url)) {
      isHttp = true;
    }

    let newUrl = '';
    let route = '';

    if (isHttp) {
      const searchSplit = url.split('?');
      if (searchSplit.length > 0) {
        newUrl = searchSplit.shift() || '';
      }

      if (searchSplit.length > 0 && searchSplit[0]) {
        search = `?${searchSplit.join('?')}`;
      }
    }

    if (isHttp && newUrl && newUrl.length > 0) {
      const urlSplit = newUrl.split('://');

      if (urlSplit.length > 0) {
        protocol = urlSplit[0];
      }

      let tempStr = '';
      if (urlSplit.length > 1) {
        tempStr = urlSplit[1]
      }

      const strSplit = tempStr.split('/');
      if (strSplit.length > 0) {
        host = strSplit.shift() || '';
      }

      route = strSplit.join('/');

      if (!route.startsWith('/')) route = `/${route}`;
    }

    if (host.endsWith('.bindo.co') && route.startsWith('/p/') && route.length > 3) {
      if (isHref) {
        isBindoCo = true;
      }
      const routeSplit = route.split('/');
      if (routeSplit.length > 2 && routeSplit[2]) {
        platform = routeSplit[2];
      }

      if (host === 'go.bindo.co') {
        evn = 'production';
      } else if (host === 'go-alpha.bindo.co') {
        evn = 'alpha';
      } else if (host === 'go-stg.bindo.co') {
        evn = 'staging';
      }
    }

    if (host.endsWith('.gobindo.com')) {
      const hostSplit = host.split('.');
      if (hostSplit[0]) {
        platform = hostSplit[0];
      }

      if (hostSplit.length > 1) {
        if (hostSplit[1] === 'alpha') {
          evn = 'alpha';
        } else if (hostSplit[1] === 'stg') {
          evn = 'staging';
        } else {
          evn = 'production';
        }
      }
    }

    let hrefInfo: IParseScanUrl | undefined = undefined;
    if (!!platform && !isHref) {
      let isLocalhost = false;
      const { href: lHref, hostname: lHostname, pathname: lPathname, search: lSearch } = window.location;
      const ipRegExp = /^((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})(\.((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})){3}$/;
      if (lHostname === 'localhost' || ipRegExp.test(lHostname)) {
        isLocalhost = true;
      }

      let isCurrentBindoCo = false;
      if (lPathname.startsWith('/p/')) {
        isCurrentBindoCo = true;
      }

      let newHref = '';
      if (isLocalhost) {
        if (isCurrentBindoCo) {
          let hrefDomain = 'go.bindo.co';
          if (Config.isStaging) {
            hrefDomain = 'go-stg.bindo.co';
          } else if (Config.isAlpha) {
            hrefDomain = 'go-alpha.bindo.co';
          }
          newHref = `https://${hrefDomain}${lPathname}${lSearch}`;
        } else {
          newHref = `https://${Config.defaultPageDomain}${lPathname}${lSearch}`;
        }
      } else {
        newHref = lHref;
      }

      hrefInfo = parseScanUrl(newHref, true);
    }

    if (hrefInfo) {
      isSamePlatform = hrefInfo.platform === platform;
      isBindoCo = hrefInfo.isBindoCo;
      isSameEvn = !!evn && hrefInfo.evn === evn;
    }

    if (isSameEvn && isSamePlatform && platform) {
      platformRoute = route.replace(`/p/${platform}`, '');
      if (!platformRoute.startsWith('/')) platformRoute = `/${platformRoute}`;
    }

    if (isSameEvn && isSamePlatform && platform && platformRoute && isBindoCo) {
      platformRoute = `/p/${platform}${platformRoute}`;
    }
    pathname = platformRoute;

    if (platformRoute.length > 0) {
      platformRoute = `${platformRoute}${search}`;
    }
  }

  const searchParams = queryString.parse(search) || {};

  return {
    isHttp,
    protocol,
    host,
    platform,
    platformRoute,
    isBindoCo,
    isSamePlatform,
    isSameEvn,
    evn,
    url,
    search,
    pathname,
    searchParams,
  }
}

interface ICopyToClipboardParams {
  txt: string;
  success: () => void;
  failure: () => void;
}

/**
 * 复制到剪贴板
 * @param params
 */
export const copyToClipboard = (params: ICopyToClipboardParams) => {
  const copyElement: any = document.getElementById('global-copy');

  if (document.execCommand && copyElement && copyElement.focus && copyElement.select) {
    copyElement.value = params.txt;
    copyElement.focus();
    copyElement.select();

    try {
      if (document.execCommand('copy', false, undefined)) {
        params.success();
      } else {
        params.failure();
      }
    } catch(err) {
      params.failure();
    }
  } else {
    params.failure();
  }
}

export interface IPayInfoParams {
  token: string;
  domain: string;
  lang: string;
  storeID: string;        //下单时真实的下单storeID
  storeSlug: string;      //下单时真实的下单storeSlug
  routeStoreSlug: string; //下单时路由上的store slug
  paymentData?: any;
  user?: any;
  isSplitOrder?: boolean; //是否在合单的情况下单独支付
  payBill?: boolean;
  buyerStoreID?: string;
  mode?: string;
}
/**
 * base 64 加密平台信息
 * @param params
 */
export const setPlatformPayInfo = (params: IPayInfoParams) => {
  const tokenEncry = encryAes(trim(params.token), 'tokenEncry');
  removeCookie(Constants.ACCESS_TOKEN);
  setCookie(Constants.ACCESS_TOKEN, tokenEncry);
  delete params.token;
  removeCookie(Constants.PAYMENT_DATA);
  if (params.paymentData) {
    const paymentDataEncry = encryAes(params.paymentData, 'tokenEncry');
    setCookie(Constants.PAYMENT_DATA, paymentDataEncry);
    delete params.paymentData;
  }

  // const tempUrl = Base64.encode(JSON.stringify(params));
  const tempUrl = encryAes(params, 'tokenEncry');
  removeCookie(Constants.PLATFORMPAYINFO);
  setCookie(Constants.PLATFORMPAYINFO, tempUrl);
  return `${Constants.PLATFORMPAYINFO}__TRUE`;
}

export const getPlatformPayInfo = (_payInfourl: string): IPayInfoParams | IObject => {
  // const parsePayInfoUrl = payInfourl.split('&')[0];
  // const payInfo = JSON.parse(Base64.decode(parsePayInfoUrl.split('__')[1])) || {};
  const tempUrl = getCookie(Constants.PLATFORMPAYINFO) || '';

  const tempDecrypt = decrypt(tempUrl, 'tokenEncry') || {};

  const payInfo = typeof tempDecrypt === 'object' ? tempDecrypt : {};

  const tokenCookie = getCookie(Constants.ACCESS_TOKEN);

  if (tokenCookie) {
    const tokenEncry = decrypt(tokenCookie, 'tokenEncry');

    payInfo.token = tokenEncry;
  }

  const paymentData = getCookie(Constants.PAYMENT_DATA);

  if (paymentData) {
    const paymentDataEncry = decrypt(paymentData, 'tokenEncry');
    payInfo.paymentData = paymentDataEncry;
  }
  const gatewayAppSettings = getCookie(payInfo.gatewayDomain);
  if (gatewayAppSettings) {
    payInfo.gatewayAppSettings = JSON.parse(gatewayAppSettings);
  }

  const platformConfig = getCookie(payInfo.domain);
  if (platformConfig) {
    payInfo.platformConfig = JSON.parse(platformConfig);
  }

  const platformAppSettings = getCookie(`platformAppSettings-${payInfo.domain}`);
  if (platformAppSettings) {
    payInfo.platformAppSettings = JSON.parse(platformAppSettings);
  }

  const lessTheme = getCookie(`lessTheme-${payInfo.domain}`);
  if (lessTheme) {
    payInfo.lessTheme = JSON.parse(lessTheme);
  }

  const appTheme = getCookie(`appTheme-${payInfo.domain}`);
  if (appTheme) {
    payInfo.appTheme = JSON.parse(appTheme);
  }
  
  

  return payInfo;
}

interface SameItemInMultipleArrayParams {

}
/**
 * 返回在多数组内, 相同的项
 */
export const sameItemInMultipleArray = <T extends any>(...params: T[][]): T[] => {
  const argsLeng = params.length;
  let tempArry: T[] = [];
  const result: T[] = [];
  const countMap = new Map();
  // 拼接成一维数组
  for (let i = 0; i < argsLeng; i++) {
    tempArry = [...tempArry, ...params[i]];
  }

  tempArry.forEach(item => {
    if (countMap.has(item)) { // 如果存在, 则count + 1
      countMap.set(item, countMap.get(item) + 1);
    } else { // 初始化设置
      countMap.set(item, 1);
    }
  });

  for (const [key, value] of countMap.entries()) {
    if (value === argsLeng) {
      result.push(key);
    }
  };
  return result;
}

export const setCookie = (
  key: string,
  token: string,
  time?: number,
) => {
  const nowDate = new Date();
  if (time) {
    nowDate.setTime(nowDate.getTime() + (time * 1000 * 60));
  } else {
    nowDate.setTime(nowDate.getTime() + (15 * 1000 * 60));
  }

  Cookies.set(key, token, {
    domain: Config.cookieDomain,
    expires: nowDate,
    path: '/',
  });

  Cookies.set(key, token, {
    expires: nowDate,
  });
}

export const getCookie = (key: string) => {
  return Cookies.get(key);
}

export const removeCookie = (key: string) => {
  return Cookies.remove(key);
}

/**
 * Aes 加密平台信息
 * @param params
 */
export const encryAes = (value: any, key: string): string => {

  let returnText = '';

  if (typeof value === 'object') {
    returnText = CryptoJS.AES.encrypt(JSON.stringify(value), key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    }).toString();
  }

  if (typeof value === 'string') {
    returnText = CryptoJS.AES.encrypt(value, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    }).toString();
  }

  const returnString = Base64.encode(returnText);
  return returnString;
}

export const decrypt = (value: string, key: string): null | IObject | string => {
  let returnData = null;
  const decodeValue = Base64.decode(value);

  const decrypt = CryptoJS.AES.decrypt(decodeValue, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  }).toString(CryptoJS.enc.Utf8);

  if (startsWith(decrypt, '{') || startsWith(decrypt, '[')) {
    returnData = JSON.parse(decrypt);
  } else {
    returnData = decrypt;
  }

  return returnData;
}
