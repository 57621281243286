enum StoreFunction {
  ORDER = 'ORDER',
  FEEDBACK = 'FEEDBACK',
  NEWS = 'NEWS',
  ABOUT_US = 'ABOUT_US',
  BOOKING = 'BOOKING',
  QUEUEING = 'QUEUEING',
  MENU = 'MENU',
}

export default StoreFunction;
