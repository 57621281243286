import { takeEvery, all } from 'redux-saga/effects';
import ActionType from '@/actions/action-type';
import { IStore } from '@/actions/store-action';
import { 
  handleQueryStore,
  handleQueryStoreByID,
} from './store-saga';
import {
  addFavoriteStore,
  getFavoriteStatus,
  getFavoriteList,
} from '@/data/StoreSetting';
import {
  IAddFavorite,
  IQueryFavoriteStatus,
  IQueryFavoriteList,
} from '@/actions/app-action';

function* handleAddFavorite(params: IAddFavorite) {
  const { storeSlug, platformID, userID, callback } = params;
  let res;
  try {
    let store: IStore | undefined = undefined;

    if (!store) {
      const queryStoreResult = yield handleQueryStore({
        type: ActionType.QUERY_STORE,
        storeSlug,
      });

      store = queryStoreResult.store;
    }

    if (store) {
      res = yield addFavoriteStore(platformID, store.id, userID);
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(res.id);
  }
}

function* handleGetFavoriteStatus(params: IQueryFavoriteStatus) {
  const { storeSlug, platformID, userID, callback } = params;
  let res;
  try {
    let store: IStore | undefined = undefined;

    if (!store) {
      const queryStoreResult = yield handleQueryStore({
        type: ActionType.QUERY_STORE,
        storeSlug,
      });

      store = queryStoreResult.store;
    }

    if (store) {
      res = yield getFavoriteStatus(platformID, store.id, userID);
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(res && res[0] && res[0].id);
  }
}

function* handleGetFavoriteList(params: IQueryFavoriteList) {
  const { platformID, userID, callback } = params;
  try {
    const res = yield getFavoriteList(platformID, userID);
    const storeIds = res.map((item: { store_id: string }) => {
      return {
        storeId: item.store_id,
      };
    });
    const favoriteData = yield all(
      storeIds.map((item: { storeId: string }) => {
        return handleQueryStoreByID({
          type: ActionType.QUERY_STORE_BY_ID,
          storeID: item.storeId,
        })
      })
    );
    const favoriteList: IStore[] = [];
    favoriteData.map((item: any) => {
      if (item.store) {
        favoriteList.push(item.store);
      }
      return favoriteList;
    });
    
    if (callback) {
      callback(favoriteList)
    }

  } catch (error) {
    console.error(error);
  }
}

function* storeSaga() {
  yield takeEvery(ActionType.ADD_FAVORITE, handleAddFavorite);
  yield takeEvery(ActionType.QUERY_FAVORITE_STATUS, handleGetFavoriteStatus);
  yield takeEvery(ActionType.QUERY_FAVORITE_LIST, handleGetFavoriteList);
}

export default storeSaga();
