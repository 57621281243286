import React from 'react';
import { formatCurrency } from '../Payment';
import { IListing } from '@/lib/order-builder/utils/api';
import { ListingCountRef } from '@/actions/shopping-cart-action';
import { IReducersState } from '@/reducers';
import { getI18nText } from '@/utils/app';
import { getIntl } from '../App/App';

export interface IMenuItemForRightProps {
  data: IListing;
  parentViewID: string;
  isOpen: boolean;
  listingCountRef: ListingCountRef;
  currencyCode?: string;
  className?: string;
  itemClassName?: string;
  onlyShow?: boolean; // 只用于显示菜单信息，屏蔽所有操作功能
  onPlusClick?: (data: IListing) => void;
  onMinusClick?: (data: IListing) => void;
  reducersState: IReducersState;
  showCode?: boolean;
}

const MenuItemForRight: React.FC<IMenuItemForRightProps> = props => {
  const {
    data,
    parentViewID,
    currencyCode,
    listingCountRef = {},
    // className = '',
    isOpen,
    onlyShow = false,
    onPlusClick,
    reducersState,
    showCode = false,
  } = props;

  const handlePlusClick = () => {
    if (onPlusClick) {
      onPlusClick(data);
    }
  }

  const soldOut = data.soldOut || false;

  return (
    <div className="item-wrapper cms" onClick={!onlyShow && isOpen && !soldOut && data.status !== 'Suspend' && data.status !== 'Deactivate' ? handlePlusClick : undefined}>
      <div className="container w-container">
        <div className="div-12 open-modal">
          <div className={`item-info-wrapper ${(!isOpen || soldOut || (data.status && data.status === 'Suspend') || (data.status && data.status === 'Deactivate')) ? 'sold-out' : ''}`}>
            <div className="item-info">
              <h4 className="heading-4-1">{data.aliasName || getI18nText(data, 'name', reducersState.app.language) || data.name}</h4>
              {
                data.description && !showCode &&
                <div className="paragraph-2" dangerouslySetInnerHTML={{ __html: getI18nText(data, 'description', reducersState.app.language) || data.description}}></div>
              }
            </div>
            <div className="price">
              <div className="text-block-6">{formatCurrency(data.price, currencyCode)}</div>
              {
                !isOpen &&
                <div className="text-block-6 sold-out">{getIntl().page.lineItemClosed}</div>
              }
              {
                isOpen && soldOut &&
                <div className="sold-out">{getIntl().page.soldOut}</div>
              }
              {
                isOpen && data.status && data.status === 'Suspend' &&
                <div className="sold-out">{getIntl().page.suspend}</div>
              }
              {
                isOpen && data.status && data.status === 'Deactivate' &&
                <div className="sold-out">{getIntl().page.deactivate}</div>
              }
            </div>
          </div>
          {
            data.imageUrl &&
            <div className="image-open" style={{ backgroundImage: `url("${data.imageUrl}")` }} ></div>
          }
          <div className="item-action">
            {
              !onlyShow && isOpen && !soldOut && data.status !== 'Suspend' && data.status !== 'Deactivate' && listingCountRef[data.id] && listingCountRef[data.id].quantity > 0 &&
              <div className={`count bm-test-count-${parentViewID}-${data.id}`}>
                {listingCountRef[data.id].quantity}
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default MenuItemForRight;
