import styled from 'styled-components';
export const MenuGridPageStyled = styled.div `
.section {
  display: block;
  margin-top: auto;
  margin-bottom: 0px;
  padding: 60px 40px 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--bm-dark-background-color);
}
.section._1 {
  padding-top: 25px;
}
.div-block-634 {
  max-width: 1000px;
  margin-right: auto;
  margin-left: auto;
}
.div-block-629 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.div-block-629.grid {
  width: auto;
  max-width: none;
  margin-right: auto;
  margin-left: auto;
  justify-items: center;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  display: grid;
}
.menu-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 230px;
  height: 230px;
  margin-right: 0px;
  margin-bottom: 30px;
  margin-left: 0px;
  padding-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-radius: 6px;
  background-color: var(--bm-light-background-color);
  color: var(--bm-text-title-color);
  text-decoration: none;
  cursor: pointer;
}
.image-card-upper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: auto;
  height: 70%;
  max-height: 70%;
  min-height: 70%;
  margin-bottom: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-radius: 0px;
  background-position: 50% 50%;
  background-size: cover;
}
.lazyload-wrapper {
  .image-card-upper {
    max-height: 100%;
    height: 100%;
  }
}
.div-block-99 {
  height: 30%;
  max-height: 30px;
  min-height: 30%;
  padding: 0px 15px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.heading-4 {
  font-family: 'Sf pro display', sans-serif;
  font-weight: 600;
  word-break: break-all;
}
@media screen and (max-width: 991px) {
  .section {
    margin-top: 0px;
    padding: 40px 35px 30px;
  }
  .div-block-629.grid {
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .menu-card {
    width: 210px;
    height: 210px;
    margin-right: 0px;
    margin-bottom: 20px;
  }
  .heading-4 {
    margin-bottom: 5px;
  }
}
@media screen and (max-width: 767px) {
  .section {
    padding: 83px 25px 20px;
  }
  .section._1 {
    padding-top: 0;
    padding-right: 30px;
    padding-left: 30px;
  }
  .div-block-629.grid {
    display: grid;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
  .menu-card {
    width: 200px;
    height: 220px;
    margin-bottom: 15px;
  }
  .lazyload-wrapper {
    .image-card-upper {
      height: 100%;
      max-height: 100%;
    }
  }
  .image-card-upper {
    height: 78%;
    border-radius: 0px;
  }
}

@media screen and (max-width: 479px) {
  .section {
    padding: 68px 12px 15px;
  }
  .section._1 {
    padding-top: 0;
  }
  .div-block-629.grid {
    display: -ms-grid;
    display: grid;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    grid-auto-columns: 1fr;
    grid-column-gap: 14px;
    grid-row-gap: 14px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  .menu-card {
    width: auto;
    height: 180px;
    margin-right: 0px;
    margin-bottom: 0px;
    justify-self: stretch;
  }
  .image-card-upper {
    height: 78%;
    margin-bottom: 0px;
    border-radius: 0px;
  }
  .lazyload-wrapper {
    .image-card-upper.menu-icon {
      max-height: 100%;
      min-height: 100%;
    }
  }
  .image-card-upper.menu-icon {
    max-height: 70%;
    min-height: 70%;
  }
  .div-block-99 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 23%;
    max-height: 30%;
    min-height: 30%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .heading-4 {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 16px;
    line-height: 22px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
  }
}
`;
