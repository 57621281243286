import styled from 'styled-components';

export const BookingsPageStyled = styled.div`
margin-top: 49px;
padding-top: 40px;
padding-bottom: 40px;
border-radius: 12px;
background-color:  var(--bm-border-color);
width: 85%;
.failure {
  color: var(--bm-error-color);
}
.section {
  display: block;
  margin-top: auto;
  margin-bottom: 0px;
  padding: 60px 40px 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0);
}
.section.booking-1 {
  padding-top: 0px;
  padding-bottom: 0px;
}
.div-block-625 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  margin-right: 0px;
  padding: 15px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: var(--bm-border-color);
  border-radius: 8px;
  background-color: var(--bm-light-background-color);
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  text-decoration: none;
  cursor: pointer;
}
.div-block-638 {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 14px;
  grid-row-gap: 14px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  .div-block-625 {
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }
}
.div-block-636 {
  width: 65px;
  height: 65px;
  margin-right: 10px;
  border-radius: 50%;
  background-position: 50% 50%;
  background-size: cover;
}
.div-block-637 {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.div-block-484 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 15px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.div-block-484.no-space {
  margin-bottom: 5px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: var(--bm-text-title-color);
}
.div-block-564 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.div-block-564.margin {
  margin-top: 10px;
}
.text-block-104 {
  margin-right: auto;
  margin-bottom: 10px;
  margin-left: auto;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
}
.text-block-104.no-space {
  margin-bottom: 0px;
  margin-left: 0px;
}
.div-block-635 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.div-block-635.confirmed {
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: var(--bm-text-title-color);
  font-size: 16px;
}
.dot {
  width: 6px;
  height: 8px;
  margin-right: 5px;
  border-radius: 50px;
  background-color:  var(--bm-text-title-color);
}
.div-block-544 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  color: var(--bm-text-color);
}
.text-block-67 {
  margin-bottom: 0px;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color:  var(--bm-text-title-color);
  font-size: 18px;
  font-weight: 400;
}
.line-separator {
  width: 1px;
  height: 18px;
  margin-right: 10px;
  margin-left: 10px;
  padding-right: 0px;
  background-color: var(--bm-border-color);
  font-size: 2px;
}
.text-span-4 {
  color: var(--bm-text-color);
}
.div-block-547 {
  width: 85%;
  margin-top: 30px;
  padding: 80px 0px;
  border-radius: 12px;
} 

@media screen and (max-width: 991px) {
  margin-left: 40px;
  padding-top: 30px;
  padding-bottom: 30px;
  .section {
    margin-top: 0px;
    padding: 40px 35px 30px;
  }
  .div-block-638 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .div-block-625 {
    padding: 16px;
  }
  .line-separator {
    margin-right: 15px;
  }
}

@media screen and (max-width: 767px) {
  background-color: var(--bm-dark-background-color);
  width: auto;
  margin-top: 0px;
  margin-left: 0px;
  padding-top: 0px;
  padding-right: 20px;
  padding-left: 20px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-radius: 0;
  .section {
    padding: 23px 25px 20px;
  }
  .div-block-625 {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .text-block-104 {
    font-size: 15px;
  }
  .section.booking-1 {
    padding-top: 20px;
  }
}
@media screen and (max-width: 479px) {
  padding-top: 7px;
  .section {
    padding: 30px 12px 15px;
  }
  .section.booking-1 {
    padding-right: 0px;
    padding-left: 0px;
  }
  .div-block-625 {
    padding: 11px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .div-block-636 {
    width: 40px;
    height: 40px;
  }
  .div-block-635.confirmed {
    font-size: 14px;
  }
  .text-block-104 {
    margin-bottom: 0px;
  }
  .text-block-104.no-space {
    white-space: pre;
  }
  .dot {
    width: 3px;
    height: 3px;
    margin-right: 3px;
  }
  .text-block-67 {
    margin-bottom: 0px;
    font-size: 14px;
  }
  .line-separator {
    margin-right: 10px;
    margin-left: 10px;
  }
  .no-bookings {
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-top: 356px;

    .img {
      svg {
        width: 300px;
        height: 300px;
      }
    }

    .no-bookings-tip {
      margin-top: 20px;
      font-weight: bold;
      font-size: 32px;
      line-height: 36px;
      color: var(--bm-text-color);
      text-align: center;
    }
  }
  .loading-data {
    text-align: center;
  }
}



`;
