import React from 'react';
import { prefix, FootStyle } from '.';
import StoreListItem from './StoreListItem';
import StoreGroupGridItem from './StoreGroupGridItem';
import StoreGroupListItem from './StoreGroupListItem';
import StoreListIconLeft from './StoreListIconLeft';
import { IStore, IStoreCategory } from '@/actions/store-action';
import AntdIcon from 'antd-mobile/es/icon';
import { IReducersState } from '@/reducers';
import { getFlatternDistance } from '@/utils/app';
import { findNode } from '@/utils';
import { getIntl } from '../App/App';

interface IStoreListProps {
  data: IStore[];
  reducersState: IReducersState;
  itemStyle?: 'LIST_ICON_TOP' | 'LIST_ICON_LEFT' | 'GROUP_GRID' | 'GROUP_LIST';
  footStyle?: FootStyle;
  loading?: boolean;
  className?: string;
  itemClassName?: string;
  lastPage?: boolean;
  onItemClick?: (data: IStore) => void;
}

const StoreList: React.FC<IStoreListProps> = props => {
  const {
    data,
    loading = false,
    itemStyle = 'LIST_ICON_TOP',
    footStyle = FootStyle.STORE_DEFAULT,
    itemClassName = '',
    reducersState,
    onItemClick,
  } = props;

  let { className = '' } = props;

  const { coordinates } = reducersState.app;

  let StoreItem = StoreListItem;
  if (itemStyle === 'GROUP_GRID') {
    StoreItem = StoreGroupGridItem;
    className = `${className} group-grid`;
  } else if (itemStyle === 'GROUP_LIST') {
    StoreItem = StoreGroupListItem;
  } else if (itemStyle === 'LIST_ICON_LEFT') {
    StoreItem = StoreListIconLeft;
  }

  const getStoreCategoryInfo = (store: IStore) => {
    const newStoreCategories: IStoreCategory[] = [];
    if (store && Array.isArray(store.storeCategories)) {
      store.storeCategories.forEach(item => {
        const categoryNode = findNode<IStoreCategory>(reducersState.app.platformStoreBrands, 'id', item);
        if (categoryNode) {
          newStoreCategories.push(categoryNode);
        }
      })
    }
    return newStoreCategories;
  }

  const getDistanceTitle = (store: IStore) => {
    let distanceTitle = '';
    if (coordinates && store.lat && store.lng) {
      const distanceObj = getFlatternDistance(coordinates.latitude, coordinates.longitude, store.lat, store.lng);
      if (distanceObj && distanceObj.distanceTitle) {
        distanceTitle = distanceObj.distanceTitle;
      }
    }
    return distanceTitle;
  }

  return (
    <div className={`${prefix} ${className} container _1 w-container`}>
      {
        data.map((item, index) => (
          <StoreItem
            key={`${item.id}_${index}`}
            store={item}
            reducersState={reducersState}
            className={`${itemClassName} bm-test-store-${item.slug}`}
            onClick={onItemClick}
            storeCategories={getStoreCategoryInfo(item)}
            distanceTitle={getDistanceTitle(item)}
            footStyle={footStyle}
          />
        ))
      }
      {
        loading &&
        <div className="loading-data">
          <AntdIcon type="loading" />
          <div className="loading-msg">
            {getIntl().common.loading}
          </div>
        </div>
      }
    </div>
  );
}

export default StoreList;
