import React from 'react';
import { BigNumber } from 'bignumber.js';
import { IReward } from '@/actions/point-action';
import { ICustomerGrowth } from '@/actions/user-action';
import Icon, { IconType } from '@/components/Icon';
import { getIntl } from '@/components/App/App';
import { langFormat } from '@/utils/app';

interface IUsePointsProps {
  membershipData: ICustomerGrowth;
  currencyCode: string;
  reward: IReward;
  abbreviation: string;
}

const RedeemSuccess: React.FC<IUsePointsProps> = props => {
  const {
    membershipData,
    // currencyCode = 'HKD',
    reward,
    abbreviation,
  } = props;

  return (
    <div className="redeem-success">
      <div className="redeem-success-icon"><Icon type={IconType.STATUS_SUCCESS} /></div>
      <div className="redeem-success-title">{getIntl().page.redeemSucceeded}</div>
      <div className="redeem-success-card">
        <div className="redeem-success-tip">
          {langFormat(getIntl().page.pointTipCustom, {loyalty: reward.loyalty, abbreviation: abbreviation})}
        </div>
        <div className="redeem-success-info">
          <div className="redeem-success-info-title">{getIntl().page.pointBalance}</div>
          <div className="redeem-success-info-data">{new BigNumber(membershipData.myLoyaltyStamp).toFormat()}</div>
        </div>
        <div className="redeem-success-info">
          <div className="redeem-success-info-title">{abbreviation} {getIntl().page.dollarsBalance}</div>
          <div className="redeem-success-info-data">
            {new BigNumber(membershipData.storeCredits).toFormat()}
          </div>
        </div>
        <div className="redeem-success-explain">{langFormat(getIntl().page.redeemTip, {abbreviation: abbreviation})}</div>
      </div>
    </div>
  );
}

export default RedeemSuccess;
