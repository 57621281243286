import { IInvoiceInfo } from '@/actions/qrcord-shopping-action';
import dayjs from 'dayjs';
import React from 'react';
import { getIntl } from '../App/App';

interface IScanCodeInfoProps {
  invoiceInfo: IInvoiceInfo;
}

const ScanCodeInfo: React.FC<IScanCodeInfoProps> = props => {
  const { invoiceInfo } = props;

  return (
    <div className="order-summary-block with-spacing">
      <div className="div-block-493 no-line _1">
        <div className="div-block-484">
          <div className="text-block-98">{getIntl().page.seatedTime}</div>
          <div className="text-block-68">{dayjs(invoiceInfo.seatedAt).format('YYYY-MM-DD HH:mm')}</div>
        </div>
        <div className="div-block-484">
          <div className="text-block-98">{getIntl().page.seatedTableNumber}</div>
          <div className="text-block-68">{invoiceInfo.tableName}</div>
        </div>
        <div className="div-block-484">
          <div className="text-block-98">{getIntl().page.headcount}</div>
          <div className="text-block-68">{invoiceInfo.numberOfPeople}</div>
        </div>
        <div className="div-block-484">
          <div className="text-block-98">{getIntl().page.referenceNumber}</div>
          <div className="text-block-68">{invoiceInfo.orderReferenceNumber}</div>
        </div>
      </div>
    </div>
  );
}

export default ScanCodeInfo;
