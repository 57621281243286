import React from 'react';
import Icon, { IconType } from '@/components/Icon';
import { IAppIcons } from '@/actions/store-action';



interface ISideBarItemProps {
  onClick?: () => void;
  iconType: IconType;
  title?: React.ReactNode;
  rightIcon?: boolean;
  appIcon?: IAppIcons;
}

const SideBarItem: React.FC<ISideBarItemProps> = props => {
  const { onClick, iconType, title = '', rightIcon = false, appIcon } = props;
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  }
  return (
    <div className="side-bar-item" onClick={handleClick}>
      <div className="bar-item">
        {
          appIcon && appIcon.svg &&
          <div className="bar-item-icon" dangerouslySetInnerHTML={{ __html: appIcon.svg }} />
        }
        {
          !appIcon &&
          <Icon
            className="bar-item-icon"
            type={iconType}
          />
        }
        <div>{title}</div>
      </div>
      {
        rightIcon &&
        <Icon
          className="right-icon"
          type={IconType.RIGHT_OUTLINED}
        />
      }
    </div>
  );
}

export default SideBarItem;
