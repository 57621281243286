import { v4 as uuidV1 } from 'uuid';
import { BigNumber } from 'bignumber.js';
import OrderEntity, { ITaxOption } from '../models/OrderEntity';
import LineItemEntity, { ITaxOptionEntrie } from '../models/LineItemEntity';

/**
 * 创建UUID
 */
export const createUuid = (): string => uuidV1();

export const plus = (num1: number, num2: number, decimal?: number | undefined) => {
  let num: any = new BigNumber(num1).plus(num2);

  if (decimal !== undefined && decimal !== null) {
    num = num.toFixed(decimal);
  }

  return Number(num);
}

export const minus = (num1: number, num2: number, decimal?: number | undefined) => {
  let num: any = new BigNumber(num1).minus(num2);

  if (decimal !== undefined && decimal !== null) {
    num = num.toFixed(decimal);
  }

  return Number(num);
}

export const times = (num1: number, num2: number, decimal?: number | undefined) => {
  let num: any = new BigNumber(num1).times(num2);

  if (decimal !== undefined && decimal !== null) {
    num = num.toFixed(decimal);
  }

  return Number(num);
}

export const dividedBy = (num1: number, num2: number, decimal?: number | undefined) => {
  let num: any = new BigNumber(num1).dividedBy(num2);

  if (decimal !== undefined && decimal !== null) {
    num = num.toFixed(decimal);
  }

  return Number(num);
}

export const orderEntityToShoppingCartData = (orderEntity: OrderEntity) => {
  return {
    orderData: orderEntity.toJson(),
    orderInfo: orderEntity.toOrderInfo(),
    listingCountRef: orderEntity.getListingCountRef(),
  }
}

export const taxOptionToTaxOptionEntrie = (option: ITaxOption, lineItemEntity: LineItemEntity) => {
  let taxOptionEntrie: ITaxOptionEntrie | undefined;

  if (option && lineItemEntity && lineItemEntity.id) {
    taxOptionEntrie = {
      id: option.id,
      name: option.name,
      amount: '0',
      'tax_rate': option.taxRate,
      'tax_option_id': option.id,
      'line_item_id': lineItemEntity.id,
      primary: option.primary,
      'tax_type': option.taxType,
      method: option.method,
      threshold: option.method,
      priority: option.priority,
      'included_in_price': option.includedInPrice,
      uuid: createUuid(),
      'tax_base': '0',
    };
  }

  return taxOptionEntrie;
}
