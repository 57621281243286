import React from 'react';
import Config from '@/Config';
import { ICustomerPromotion } from '@/actions/user-action';
import { stringToBase64QrCode, getI18nText } from '@/utils/app';
import dayjs from 'dayjs';
import { IReducersState } from '@/reducers';
import Icon, { IconType } from '@/components/Icon';
import { getIntl } from '@/components/App/App';
import Button from 'antd-mobile/es/button';

interface ICouponDetailsProps {
  record: ICustomerPromotion;
  reducersState: IReducersState;
  onCancel: () => void;
  showRedeem: boolean;
  storeCredits: number;
  points: number;
  handleRedeem?: () => void;
}

const CouponDetails: React.FC<ICouponDetailsProps> = props => {
  const {
    record,
    reducersState,
    onCancel,
    showRedeem,
    handleRedeem,
    storeCredits,
    points,
  } = props;
  const { platformConfig } = reducersState.app;
  const { ecouponsRedemption } = platformConfig;

  return (
    <div className="modal-wrapper">
      <div className="modal-body coupon">
        <div className="item-image-pop-up-copy" style={{backgroundImage: `url(${record.img ? record.img : Config.defaultCouponImg})`}}  >
          <div className="div-block-77">
            <div className="nav-button-1 close-modal"  onClick={onCancel}>
              <Icon type={IconType.LEFT_OUTLINED} className="html-embed-7 w-embed"/>
            </div>
          </div>
        </div>
        {
          !showRedeem &&
          <div className="popup-description coupon">
            <div className="div-block-540">
              <div className="qr-code-block">
                <div className="div-block-538" style={{ backgroundImage: `url(${stringToBase64QrCode(record.promotionCode)})`}}></div>
                {
                  (record.status === 'expired' || record.status === 'used') &&
                  <div className="qr-code-cover"><Icon type={IconType.SELECTED_OUTLINED} /></div>
                }
              </div>
              <div className="heading-4-1 bigger">{getI18nText(record, 'activity_name', reducersState.app.language) || getI18nText(record, 'name', reducersState.app.language) || record.activityName}</div>
            </div>
            <div className="div-block-540">
              <div className="div-block-484">
                <div className="text-block-98">{getIntl().page.couponExpiryDate}</div>
                {
                  record.status === 'created' &&
                  <div className="text-block-68">{dayjs(record.expiredAt).format('YYYY-MM-DD')}</div>
                }
                {
                  record.status === 'used' && 
                  <div className="text-block-68 bold color">{getIntl().page.couponUsed}</div>
                }
                {
                  record.status === 'expired' && 
                  <div className="text-block-68 bold color">{getIntl().page.couponExpired}</div>
                }
              </div>
            </div>
            {
              record && record.descriptionName &&
              <div className="div-block-540 no-space">
                <div className="rich-text-block w-richtext">
                  <h4>{getIntl().page.couponNote}</h4>
                  <ul>
                    <li>{getI18nText(record, 'description', reducersState.app.language) || record.descriptionName}</li>
                  </ul>
                </div>    
              </div>
            }
          </div>
        }
        {
          showRedeem &&
          <div className="popup-description show-redeem-wrap">
            <div className="div-block-540">
              <div className="heading-4-1 bigger">{getI18nText(record, 'activity_name', reducersState.app.language) || getI18nText(record, 'name', reducersState.app.language) || record.activityName}</div>
            </div>
            {
              record && record.descriptionName &&
              <div className="div-block-540 no-space">
                <div className="rich-text-block w-richtext">
                  <h4>{getIntl().page.couponNote}</h4>
                  <ul className="description-con">
                    <li>{getI18nText(record, 'description', reducersState.app.language) || record.descriptionName}</li>
                  </ul>
                </div>    
              </div>
            }
            <div className="div-block-540 redeem-btn-wrap">
              <Button
                type="primary"
                className="redeem-btn"
                onClick={handleRedeem}
                disabled={(ecouponsRedemption === 'LOYALTY_POINTS' && (points < record.points || record.points === 0)) ||
                (ecouponsRedemption === 'STORE_CREDIT' && (storeCredits < record.storeCredit || record.storeCredit === 0)) ? true : false}
              >
                <div>{getIntl().page.redeemNow}</div>

              </Button>
            </div>
          </div>
        }
      </div>
      <div className="c-popup-close close-modal" onClick={onCancel}></div>
    </div>
  )
}

export default CouponDetails
