/**
 * credit_card
 * auth: Joshua
 * 2020/07/16
*/
import {
  handleBuildOrderPayment,
} from '../Utils';
import { payInvoice } from '../payment-data';
import { IAmexMethod, ICreditCardMethod, IMasterMethod, IPayOrderResult, IUnionPayMethod, IVisaMethod } from '..';

interface ICreditCardPayParams {
  storeID: string;
  orderNumber: string;
  payData: ICreditCardMethod | IMasterMethod | IVisaMethod | IAmexMethod | IUnionPayMethod;
  payment: any;
}

const UseCreditCardPay = (params: ICreditCardPayParams) => {
  const {
    storeID,
    orderNumber,
    payment,
    payData,
  } = params;

  const {
    token,
    expYear,
    expMonth,
    cvv,
    holderName,
    amount,
    number,
  } = payData;

  const creditCardPay = async (): Promise<IPayOrderResult> => {
    const newPayment = {
      'credit_cards': [
        {
          amount,
          number,
          'card_reader_mode': 'manual',
          'token': token,
          'exp_year': expYear,
          'exp_month':expMonth,
          'cvv': cvv,
          'holder_name': holderName,
        }
      ],
    };

    const order = handleBuildOrderPayment(newPayment, payment);
    try {

      const resp = await payInvoice(storeID, orderNumber, order);

      const {
        order: resOrder,
        data,
      } = resp || {};

      if (resOrder) {
        return {
          order: resOrder,
        }
      } else {
        return {
          status: 500,
          message: data.message || '',
          code: data.code,
        }
      }
    } catch (error) {
      throw new Error(error.message);
    }
  }
  return {
    creditCardPay,
  }
}
export default UseCreditCardPay;
