import { takeEvery, select, put } from 'redux-saga/effects';
import omit from 'lodash/omit';
import ActionType from '@/actions/action-type';
import { IStore } from '@/actions/store-action';
import Config from '@/Config';
import axios from 'axios';
import Action from '@/actions';
import {
  IQueueing,
  IQueueingSetting,
  ICreateQueueing,
  IQueryQueueings,
  IQueryQueueingSetting,
} from '@/actions/queueing-action';
import { ICustomer } from '@/actions/user-action';
import { IQueueingState } from '@/reducers/queueing-reducer';
import { IReducersState } from '@/reducers';
import {
  getQueueings,
  // createQueueing,
  getQueueingSettings,
} from '@/data/Queueing';
import {
  parseResQueueing,
  parseResQueueingSetting,
} from '@/utils/queueing-util';
import { handleQueryStore, handleQueryStoreByID } from './store-saga';
import { handleQueryCustomer, handlePushNotification } from './user-saga';

function* handleCreateQueueing(params: ICreateQueueing) {
  const {
    cover,
    storeSlug,
    userID,
    callback,
    // id,
    uuid,
  } = params;

  let storeCustomer: ICustomer | undefined = undefined;
  let store: IStore | undefined = undefined;
  let record: IQueueing | undefined = undefined;
  let calling = '';

  try {
    if (userID) {
      const queryCustomerResult: any = yield handleQueryCustomer({
        type: ActionType.QUERY_CUSTOMER,
        storeSlug,
      });
      storeCustomer = queryCustomerResult.customer;
    }

    const queryStoreResult = yield handleQueryStore({
      type: ActionType.QUERY_STORE,
      storeSlug,
    });

    yield handlePushNotification({
      type: ActionType.PUSH_NOTIFICATION,
      customerID: storeCustomer ? storeCustomer.id : '',
    });

    store = queryStoreResult.store;
    if (store) {
      const data = {
        'number_of_people': cover,
        'status': 'waiting',
        'booking_type': '4',
        'customer_id': storeCustomer ? storeCustomer.id : undefined,
        'device_uuid': uuid || undefined,
      }
      const res = yield axios({
        method: 'post',
        url: Config.urls.createQueueing.replace('{storeID}', store.id),
        headers: {
          'Content-Type': 'application/json',
        },
        data: omit(data, userID ? ['device_uuid'] : ['customer_id']),
      });

      if (res && res.party && res.party.id) {
        const records = yield handleQueryQueueings({
          type: ActionType.QUERY_QUEUEINGS,
          id: res.id,
          page: 1,
          multi: false,
          isAdd: true,
          formula: `EQ("$.self.id", "${res.party.id}")`,
        })

        if (records && records.record) {
          record = records.record;
          calling = records.calling;
        }
      }
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(record, calling);
  }
}

function* handleQueryQueueings(params: IQueryQueueings) {
  const {
    formula,
    multi,
    callback,
    page,
    id,
    isAdd,
    perPage,
  } = params;

  let calling = '';
  let record: IQueueing | undefined = undefined;
  let queueings: IQueueing[] = [];
  const QueueingState: IQueueingState = yield select((state: IReducersState) => state.queueing);
  try {
    if (multi) {
      if (QueueingState.pageRefQueueings[page]) {
        queueings = QueueingState.pageRefQueueings[page];
      } else {
        const records = yield getQueueings(formula, 'id', page, perPage);
        if (Array.isArray(records) && records.length > 0) {
          records.forEach(item => {
            queueings.push(parseResQueueing(item));
          })
          if (!perPage) {
            yield put<Action>({
              type: ActionType.SET_QUEUEINGS,
              page,
              queueings,
            });
          }
        }
      }
    } else {
      if (QueueingState.IDRefQueueing[id]) {
        record = QueueingState.IDRefQueueing[id];
      } else {
        queueings = yield getQueueings(formula, 'id', page);
        if (Array.isArray(queueings) && queueings.length > 0) {
          record = parseResQueueing(queueings[0]);
          yield put<Action>({
            type: ActionType.SET_QUEUEINGS,
            isAdd,
            queueings: [record],
          });
        }
      }

      if (record) {
        const { store } = yield handleQueryStoreByID({
          type: ActionType.QUERY_STORE_BY_ID,
          storeID: record.storeID,
        });
        const {max, min} = yield handleQueryQueueingSetting({
          type: ActionType.QUERY_QUEUEING_SETTING,
          storeSlug: store.slug,
          cover: record.seats,
        })
        if (max > 0 && min > 0) {
          const callings = yield getQueueings(`GTE("$.self.people", "${min}").LTE("$.self.people", "${max}").EQ("$.self.status", "calling").EQ("$.self.store_id", "${record.storeID}").EQ("$.self.booking_type", "4")`, 'updated_at');
          if (Array.isArray(callings) && callings.length > 0) {
            calling = parseResQueueing(callings[0]).queueingNo;
          }
        }
      }
    }
  } catch (error) {
    console.error(error);
  }
  if (callback) {
    callback(queueings, record, calling);
  }
  return {
    queueings,
    record,
    calling,
  }
}

function* handleQueryQueueingSetting(params: IQueryQueueingSetting) {
  const {
    storeSlug,
    cover,
    callback,
  } = params;

  const QueueingState: IQueueingState = yield select((state: IReducersState) => state.queueing);
  let queueingSetting: IQueueingSetting[] = [];
  let min = 0;
  let max = 0;
  try {

    if (QueueingState.slugRefQueueingSetting[storeSlug]) {
      queueingSetting = QueueingState.slugRefQueueingSetting[storeSlug];
    } else {
      const records = yield getQueueingSettings([storeSlug]);
      queueingSetting = parseResQueueingSetting(records);
      yield put<Action>({
        type: ActionType.SET_QUEUEING_SETTING,
        storeSlug,
        queueingSetting,
      });
    }
  } catch (error) {
    console.error(error);
  }
  if (cover) {
    queueingSetting.forEach(item => {
      if (cover >= item.min && cover <= item.max) {
        min = item.min;
        max = item.max;
      }
    })
  }

  if (callback) {
    callback(queueingSetting);
  }

  return {max, min};
}

function* watchPay() {
  yield takeEvery(ActionType.CREATE_QUEUEING, handleCreateQueueing);
  yield takeEvery(ActionType.QUERY_QUEUEINGS, handleQueryQueueings);
  yield takeEvery(ActionType.QUERY_QUEUEING_SETTING, handleQueryQueueingSetting);
}

export default watchPay();
