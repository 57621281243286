import produce from 'immer';
import { handleActions } from 'redux-actions';
import ActionType from '@/actions/action-type';
import {
  IShoppingCartData,
  ISetStoreShoppingCart,
  IAddToShoppingCart,
  IClearStoresShoppingCart,
  IGetPreAddedModifiersCount,
  ISetPreAddedModifiers,
  IClearShoppingCart,
  ISetShoppingCart,
  ISetExpressOrderData,
} from '@/actions/shopping-cart-action';
import { setLocalStorageJson, getLocalStorageJson } from '@/utils';
import Constants from '@/constants';
import OrderBuilder from '@/lib/order-builder';
import { IOrderEntityJson } from '@/lib/order-builder/models/OrderEntity';

export interface IShoppingCartState {
  storeIDRefShoppingCartData: {
    [storeID: string]: IShoppingCartData;
  };
  storeIDRefExpressOrderData: {
    [storeID: string]: IShoppingCartData;
  };
  preAddedModifiers: {
    listingID: string;
    pathRefCount: {[path: string]: number}; // path由modifierID的深度连接 listingID-m1ID-m2ID-m3ID
  };
  stopRender: boolean;
}

const initState: IShoppingCartState = {
  storeIDRefShoppingCartData: {},
  storeIDRefExpressOrderData: {},
  preAddedModifiers: {
    listingID: '',
    pathRefCount: {},
  },
  stopRender: false
};

const actions: any = {};

const replaceLocalShoppingCart = (storeIDRefShoppingCartData: {[storeID: string]: IShoppingCartData}) => {
  const shoppingCartData: any = {};
  Object.keys(storeIDRefShoppingCartData).forEach(storeID => {
    if (storeID && storeIDRefShoppingCartData[storeID] && storeIDRefShoppingCartData[storeID].orderData) {
      shoppingCartData[storeID] = storeIDRefShoppingCartData[storeID].orderData;
    }
  });

  setLocalStorageJson(Constants.SHOPPING_CART_DATA, shoppingCartData);
}

actions[ActionType.SET_STORE_SHOPPING_CART] = (state: IShoppingCartState, action: ISetStoreShoppingCart) => produce(state, draft => {
  if (action.forExpressOrder) {
    if (action.shoppingCartData) {
      draft.storeIDRefExpressOrderData[action.storeID] = action.shoppingCartData;
    } else {
      delete draft.storeIDRefExpressOrderData[action.storeID];
    }
  } else {
    if (action.shoppingCartData) {
      draft.storeIDRefShoppingCartData[action.storeID] = action.shoppingCartData;
    } else {
      delete draft.storeIDRefShoppingCartData[action.storeID];
    }
    replaceLocalShoppingCart(draft.storeIDRefShoppingCartData);
  }
});

actions[ActionType.SET_EXPRESS_ORDER_DATA] = (state: IShoppingCartState, action: ISetExpressOrderData) => produce(state, draft => {
  draft.storeIDRefExpressOrderData= action.storeIDRefExpressOrderData;
});

actions[ActionType.ADD_TO_SHOPPING_CART] = (state: IShoppingCartState, action: IAddToShoppingCart) => produce(state, draft => {
  if (action.forExpressOrder) {
    draft.storeIDRefExpressOrderData[action.storeID] = action.shoppingCartData;
  } else {
    draft.storeIDRefShoppingCartData[action.storeID] = action.shoppingCartData;
    draft.preAddedModifiers.listingID = '';
    draft.preAddedModifiers.pathRefCount = {};
  
    replaceLocalShoppingCart(draft.storeIDRefShoppingCartData);
  }
});

actions[ActionType.CLEAR_PRE_ADD_MODIFIERS] = (state: IShoppingCartState) => produce(state, draft => {
  draft.preAddedModifiers.listingID = '';
  draft.preAddedModifiers.pathRefCount = {};
});

actions[ActionType.CLEAR_STORES_SHOPPING_CART] = (state: IShoppingCartState, action: IClearStoresShoppingCart) => produce(state, draft => {
  action.storeIDs.forEach(storeID => {
    delete draft.storeIDRefShoppingCartData[storeID];
    OrderBuilder.deleteFromCart(storeID);
  });

  if (action.storeIDs.length > 0) {
    replaceLocalShoppingCart(draft.storeIDRefShoppingCartData);
  }
});

actions[ActionType.GET_PRE_ADD_MODIFIERS_COUNT] = (state: IShoppingCartState, action: IGetPreAddedModifiersCount) => produce(state, draft => {
  const countRef: {[modifierID: string]: number} = {};
  const path = action.path;
  if (draft.preAddedModifiers.listingID === action.listingID) {
    if (path && path.length > 0) {
      const pathIDs = path.split('-');
      Object.keys(draft.preAddedModifiers.pathRefCount).forEach(key => {
        const keyIDs = key.split('-');
        const length = keyIDs.length;
        if (key.startsWith(path) && (pathIDs.length + 1 === keyIDs.length)) {
          countRef[keyIDs[length - 1]] = draft.preAddedModifiers.pathRefCount[key] || 0;
        }
      });
    } else {
      Object.keys(draft.preAddedModifiers.pathRefCount).forEach(key => {
        const keyIDs = key.split('-');
        if (keyIDs.length === 1 && key !== 'listingID') {
          countRef[key] = draft.preAddedModifiers.pathRefCount[key] || 0;
        }
      });
    }
  }
  action.callback(countRef);
});

actions[ActionType.SET_PRE_ADD_MODIFIERS] = (state: IShoppingCartState, action: ISetPreAddedModifiers) => produce(state, draft => {
  const path = action.path;

  // Listing不一致，清理数据
  if (draft.preAddedModifiers.listingID !== action.listingID) {
    draft.preAddedModifiers.pathRefCount = {};
  }

  // path如果存在 赋值默认为1， 并清理当前存在改路径下的值
  if (path && path.length > 0) {
    draft.preAddedModifiers.pathRefCount[path] = 1;

    const pathIDs = path.split('-');
    Object.keys(draft.preAddedModifiers.pathRefCount).forEach(key => {
      if (key.startsWith(path) && (pathIDs.length + 1 === key.split('-').length)) {
        delete draft.preAddedModifiers.pathRefCount[key];
      }
    });
  }

  // 重新写入当前路径下的路径所对应的数量
  const deletedKeys: string[] = [];
  Object.keys(action.modifierCountRef).forEach(key => {
    const value = Number(action.modifierCountRef[key]);
    let keyPath = `${key}`;
    if (path && path.length > 0) {
      keyPath = `${path}-${key}`;
    }
    if (value && value > 0 ) {
      draft.preAddedModifiers.pathRefCount[keyPath] = value;
    } else {
      deletedKeys.push(keyPath);
      delete draft.preAddedModifiers.pathRefCount[keyPath];
    }
  });

  deletedKeys.forEach(deletedKey => {
    Object.keys(draft.preAddedModifiers.pathRefCount).forEach(key => {
      if (key.startsWith(deletedKey)) {
        delete draft.preAddedModifiers.pathRefCount[key];
      }
    });
  })

  draft.preAddedModifiers.listingID = action.listingID;
});

actions[ActionType.CLEAR_SHOPPING_CART] = (state: IShoppingCartState, action: IClearShoppingCart) => produce(state, draft => {
  const {
    storeID,
    isSplitOrder,
    stopRender = false,
  } = action;

  const localShoppingCart: { [storeID: string]: IOrderEntityJson } = getLocalStorageJson(Constants.SHOPPING_CART_DATA);

  if (isSplitOrder) {
    if (localShoppingCart && Object.keys(localShoppingCart).length > 0 && storeID) {
      Object.keys(localShoppingCart).forEach( item => {
        if (item === storeID) {
          delete localShoppingCart[storeID];
          OrderBuilder.deleteFromCart(storeID);
          delete draft.storeIDRefShoppingCartData[storeID];
        }
      });
    }
    setLocalStorageJson(Constants.SHOPPING_CART_DATA, localShoppingCart);
  } else {
    localStorage.removeItem(Constants.SHOPPING_CART_DATA);
    Object.keys(draft.storeIDRefShoppingCartData).forEach(key => {
      OrderBuilder.deleteFromCart(key);
    });

    draft.storeIDRefShoppingCartData = {};
    
    Object.keys(draft.storeIDRefExpressOrderData).forEach(key => {
      OrderBuilder.deleteFromCart(key);
    });
    draft.storeIDRefExpressOrderData = {};
  }

  // Object.keys(draft.storeIDRefShoppingCartData).forEach(key => {
  //   OrderBuilder.deleteFromCart(key);
  // });

  // draft.storeIDRefShoppingCartData = {};
  draft.stopRender = stopRender;
});

actions[ActionType.SET_SHOPPING_CART] = (state: IShoppingCartState, action: ISetShoppingCart) => produce(state, draft => {
  if (action.shoppingCartData) {
    draft.storeIDRefShoppingCartData = action.shoppingCartData;
  }

  replaceLocalShoppingCart(draft.storeIDRefShoppingCartData);
});

export default handleActions(actions, initState);
