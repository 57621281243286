/**
 * credit_card
 * auth: Joshua
 * 2020/010/27
*/
import {
  handleBuildOrderPayment,
} from '../Utils';
import { payInvoice } from '../payment-data';
import { IAmexMethod, ICreditCardMethod, IMasterMethod, IUnionPayMethod, IVisaMethod } from '..';

interface ICreditCardPayParams {
  storeID: string;
  orderNumber: string;
  payData: ICreditCardMethod | IMasterMethod | IVisaMethod | IAmexMethod | IUnionPayMethod;
  payment: any;
}

const UseCreditCardPay3DS = (params: ICreditCardPayParams) => {
  const {
    storeID,
    orderNumber,
    payment,
    payData,
  } = params;

  const {
    token,
    expYear,
    expMonth,
    cvv,
    holderName,
    amount,
    number,
    returnUrl,
  } = payData;

  const creditCardPay = async (): Promise<any> => {
    const newPayment = {
      'credit_cards': [
        {
          amount,
          'number': number,
          'card_reader_mode': 'manual',
          'token': token,
          'exp_year': expYear,
          'exp_month':expMonth,
          'cvv': cvv,
          'holder_name': holderName,
          '3ds': {
            'enable': true,
            'success_url': returnUrl,
            'fail_url': returnUrl,
          }
        }
      ],
    };

    const order = handleBuildOrderPayment(newPayment, payment);

    try {
      const resp = await payInvoice(storeID, orderNumber, order);
      const {
        status,
        order: fulfilOrder,
        success,
      } = resp || {};
      if (fulfilOrder && success && status === 'ok' && fulfilOrder.sale_transactions && fulfilOrder.sale_transactions.length > 0) {
        if (fulfilOrder.state !== 'completed' && fulfilOrder.state !== 'voided') {
          let acquirer: any;
          fulfilOrder.sale_transactions.forEach((item: any) => {
            if (item.payment_method === 4 && item['3ds'] && item['3ds'].redirect_url) {
              acquirer = item['3ds'].redirect_url;
            }
          });
  
          console.info(acquirer, 'acquireracquireracquireracquireracquireracquireracquireracquirer')
  
          if(acquirer) {
            window.location.href = acquirer;
          }
        } else {
          return {
            order: fulfilOrder,
          }
        }

      } else {
        return {
          status: 500
        }
      }
    } catch (error) {
      throw new Error(error.message);
    }
  }
  return {
    creditCardPay,
  }
}
export default UseCreditCardPay3DS;
