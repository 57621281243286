import styled from 'styled-components';
export const AddCreditCardPageStyled = styled.div`
.cc-column {
  display: flex;
  .bm-c-data-entry {
    width: 50%;
  }
}
.cc-exp-date {
  padding-right: 10px;
}
.right {
  margin-right: unset;
}
@media screen and (max-width: 767px) {
  .cc-column {
    display: block;
    .bm-c-data-entry {
      width: 100%;
      left: auto;
      right: auto;
    }
  }
}
@media screen and (max-width: 479px) {
  .cc-column {
    .bm-c-data-entry {
      width: 100%;
    }
  }
}


`;
