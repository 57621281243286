import React from 'react';
import { formatCurrency } from '@/components/Payment';
import { IModifier, IListing } from '@/lib/order-builder/utils/api';
import { getI18nText } from '@/utils/app';
import { IReducersState } from '@/reducers';

interface IModifierListingProps {
  data: IListing | IModifier | any;
  reducersState: IReducersState;
  currencyCode: string;
}

const ModifierListing: React.FC<IModifierListingProps> = props => {
  const { data, reducersState, currencyCode } = props;
  const { name, price, description} = data || {};
  return (
    <div className="div-block-69 item">
      <div className="item-info">
        <div className="item-info-flex">
          <h4 className="heading-4-1"> {data && (data.aliasName || getI18nText(data, 'name', reducersState.app.language) || name)}</h4>
          <div className="left-data">
            {
              price &&
              <div className="text-block-6">+{formatCurrency(price, currencyCode)}</div>
            }
          </div>
        </div>
        <div className="describe describe-description"  dangerouslySetInnerHTML={{ __html: getI18nText(data, 'description', reducersState.app.language) || description}}></div>
        {/* <p className="paragraph-2-pop-up"></p> */}
      </div>
      
    </div>
  );
}

export default ModifierListing;
