import React from 'react';
import { IStore } from '@/actions/store-action';
import LazyLoad from 'react-lazyload';
import { getI18nText } from '@/utils/app';
import { IReducersState } from '@/reducers';

interface IStoreGroupListItemProps {
  store: IStore;
  className?: string;
  onClick?: (store: IStore) => void;
  reducersState: IReducersState;
}

const StoreGroupListItem: React.FC<IStoreGroupListItemProps> = props => {
  const { store, className = '', onClick, reducersState } = props;

  const handleClick = () => {
    if (onClick) {
      onClick(store);
    }
  }

  const { deviceInfo } = reducersState.app;
  return (
    <div className={`group-list-item ${className}`} onClick={handleClick}>
      <div className="store-img-wrapper">
        {
          deviceInfo.inPC &&
          <img src={store.imgUrl} alt=""/>
        }
        {
          !deviceInfo.inPC &&
          <LazyLoad once offset={100}>
            <img src={store.imgUrl} alt=""/>
          </LazyLoad>
        }
      </div>
      <div className="store-info">
        <div className="store-name">
          {getI18nText(store, 'title', reducersState.app.language) || store.name}
        </div>
      </div>
    </div>
  );
}

export default StoreGroupListItem;
