import { BigNumber } from 'bignumber.js'
import {
  IPurchaseOrder,
  IPurchaseOrderDetail,
  IPurchaseOrderDetailLineItem,
} from '@/actions/purchase-order-action';

export const parseResPurchaseOrders = (res: any) => {
  const purchaseOrders: IPurchaseOrder[] = []
  if (Array.isArray(res)) {
    res.forEach(item => {
      if (item && item.id) {
        const index = `${item.number}`.indexOf('(');
        purchaseOrders.push({
          id: item.id,
          number: index !== -1 ? `${item.number}`.slice(index, `${item.number}`.length) : item.number,
          referenceNumber: index !== -1 ? `${item.number}`.slice(0, index) : '',
          paymentState: item.payment_state,
          state: item.state,
          supplierName: item.supplier_name,
          total: new BigNumber(item.item_amount ? item.item_amount.replace(',', '') : 0).toNumber(),
          createAt: item.created_at,
        })
      }
    })
  }
  return purchaseOrders;
}

export const parseResPurchaseOrderDetail = (res: any, images: {[id: string]: any}, supplier: any) => {
  const lineItems: IPurchaseOrderDetailLineItem[] = [];
  const purchaseItems = res.purchase_items;
  let itemQuantity = new BigNumber(0);
  let subtotal = new BigNumber(0);
  if (Array.isArray(purchaseItems)) {
    purchaseItems.forEach(item => {
      if (item.source_type === 'Listing') {
        const codes: string[] = [];
        const referenceCodes = item.listing_reference_codes;
        if (referenceCodes && Array.isArray(referenceCodes)) {
          referenceCodes.forEach(referenceCode => {
            if (referenceCode && referenceCode.code) {
              codes.push(referenceCode.code);
            }
          })
        }
        const total = new BigNumber(item.cost_in_display_unit).times(new BigNumber(item.quantity_in_display_unit))
        lineItems.push({
          id: item.id,
          name: item.name,
          cost: item.cost_in_display_unit,
          code: codes,
          imageUrl: item.product_id && images[item.product_id] ? images[item.product_id] : '/images/default-line-item.png',
          unit: item.display_unit || '',
          quantity: new BigNumber(item.quantity_in_display_unit ? item.quantity_in_display_unit : 0).toNumber(),
          supplierPrice: new BigNumber(item.cost_in_display_unit).toNumber(),
          total: total.toNumber(),
        });
        itemQuantity = new BigNumber(itemQuantity).plus(new BigNumber(item.quantity_in_display_unit));
        subtotal = total.plus(new BigNumber(subtotal));
        
      }
    })
  }

  let currency = '';
  if (res && res['exchange_rate'] && res['exchange_rate']['currency_to']) {
    currency = res['exchange_rate']['currency_to']
  }
  const purchaseOrder: IPurchaseOrderDetail = {
    id: res.id,
    storeID: res.store_id,
    number: res.number,
    paymentState: res.payment_state,
    paymentMethod: res.payment_method,
    expectDeliveryDate: res.expect_delivery_date || '',
    state: res.state,
    supplierName: supplier ? supplier.name : '',
    supplierID: res.supplier_id,
    subtotal: subtotal.toNumber(),
    total: subtotal.toNumber(),
    serviceFee: 0,
    itemQuantity: itemQuantity.toNumber(),
    createAt: res.created_at,
    createBy: res.created_by,
    lineItems: lineItems,
    remarks: res.remarks,
    purchaseOrderRes: res,
    currency,
  }
  return purchaseOrder;
}
