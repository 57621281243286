import React from 'react';
import dayjs from 'dayjs';
import AntdIcon from 'antd-mobile/es/icon';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import { getAppTheme, setScreenColor, getIntl } from '@/components/App/App';
import Icon, { IconType } from '@/components/Icon';
import { IBooking } from '@/actions/booking-action';
import { IPoint } from '@/actions/point-action';
import { IOrderInfo } from '@/lib/order-builder/models/OrderEntity';
import ActionType from '@/actions/action-type';
import paths from '@/routes/paths';
import BmTabBar from '@/components/BmTabBar';
import TabsOption from '@/actions/tabs-option';
import BookingItem from './BookingItem';
import OrderItem from './OrderItem';
import Point from './Point';
import { prefix } from '.';
import RecordsOption from '@/actions/records-option';
import SideBar from '@/components/SideBar';
import { IObject } from '@/components/bm-common';
import Skeleton from './Skeleton';
import Components from '@/components/SideNavBar/Components';
import SideNavOption from '@/actions/side-nav-option';
import { RecordsPageStyled, OrderItemStyled, BookingItemStyled, PointStyled } from './styled';
import Layout from '@/components/Layout';
import { PrivilegePageNavStyled } from '../PrivilegePage/styled';
import { getAccessToken } from '@/utils/app';

interface IRecordsPageProps extends IBasePageProps {}

interface IRecordsPageState {
  selectedKey: string;
  loading: boolean;
  bookingPage: number;
  orderPage: number;
  pointPage: number;
  bookings: IBooking[] | undefined;
  orders: IOrderInfo[] | undefined;
  points: IPoint[] | undefined;
  showSideBar: boolean;
  loadingDate: boolean;
}

class RecordsPage extends BasePage<IRecordsPageProps, IRecordsPageState> {
  bookingLastPage = false;
  orderLastPage = false;
  pointLastPage = false;
  loadingData = false;
  failureStatus = ['cancelled', 'no_show'];
  token = getAccessToken();
  state: IRecordsPageState = {
    selectedKey: 'booking',
    loading: false,
    bookingPage: 1,
    orderPage: 1,
    pointPage: 1,
    bookings: undefined,
    orders: undefined,
    points: undefined,
    showSideBar: false,
    loadingDate: false,
  }

  componentDidMount() {
    
    const appTheme = getAppTheme();
    if (appTheme.lightBackgroundColor && appTheme.primaryColor) {
      setScreenColor(appTheme.primaryColor, appTheme.lightBackgroundColor);
    }

    super.componentDidMount();
    this.autoCloseLoading = false;
    const pageWrap = window.document.querySelector('.bm-p-records-p');
    if (pageWrap) {
      pageWrap.addEventListener('scroll', this.handleScroll, { passive: true });
    } else {
      window.addEventListener('scroll', this.handleScroll, { passive: true });
    }
    const { reducersState } = this.props;
    const { platformConfig } = reducersState.app;
    const { recordsOptions } = platformConfig;
    if (recordsOptions.includes(RecordsOption.BOOKING)) {
      this.getBookings(1);
      this.setState({
        selectedKey: 'booking',
      })
    } else if (recordsOptions.includes(RecordsOption.ORDER)) {
      this.getOrders(1);
      this.setState({
        selectedKey: 'order',
      })
    } else if (recordsOptions.includes(RecordsOption.LOYALTY)) {
      this.getPoints(1);
      this.setState({
        selectedKey: 'point',
      })
    }
  }

  componentWillUnmount() {
    const pageWrap = window.document.querySelector('.bm-p-records');
    if (pageWrap) {
      pageWrap.removeEventListener('scroll', this.handleScroll);
    } else {
      window.removeEventListener('scroll', this.handleScroll);
    }
  }

  getScrollY = () => {
    const pageWrap = window.document.querySelector('.bm-p-privilege-p');
    let scrollY = window.scrollY;
    if (pageWrap) {
      scrollY = pageWrap.scrollTop;
    }

    return scrollY;
  }

  handleScroll = () => {
    const { bookingPage, orderPage, pointPage, selectedKey } = this.state;
    if ((!this.bookingLastPage || !this.orderLastPage || !this.pointLastPage) && !this.loadingData) {

      const  appPages = document.getElementsByClassName('bm-p-records');
      if (appPages && appPages.length) {
        const appPage = appPages[0];
        const scrollHeight = appPage.scrollHeight;
        const canScrollHeight = scrollHeight - window.innerHeight;
        const scrollY = this.getScrollY();
        if (selectedKey === 'booking' && !this.bookingLastPage) {
          if (canScrollHeight - scrollY < 2 * 300) {
            this.loadingData = true
            this.setState({loading: true});
            this.getBookings(bookingPage + 1, true);
          }
        } else if (selectedKey === 'order' && !this.orderLastPage) {
          if (canScrollHeight - scrollY < 3 * 200) {
            this.loadingData = true
            this.setState({loading: true});
            this.getOrders(orderPage + 1, true);
          }
        } else if (selectedKey === 'point' && !this.pointLastPage) {
          if (canScrollHeight - scrollY < 10 * 120) {
            this.loadingData = true
            this.setState({loading: true});
            this.getPoints(pointPage + 1, true);
          }
        }
      }
    }
  }

  getBookings = (page: number, isAdd = false,) => {
    const {
      dispatch,
      reducersState,
    } = this.props;
    const { userInfo } = reducersState.user;
    if (!userInfo) {
      this.setState({
        bookings: [],
        loading: false,
      })
      return;
    }

    if (!isAdd) {
      this.setState({loadingDate: true})
    }

    if (userInfo) {
      dispatch({
        type: ActionType.QUERY_BOOKINGS,
        formula: `EQ("$.self.linked_source_id", "${userInfo.id}").EQ("$.self.booking_type", "2")`,
        page,
        multi: true,
        id: '',
        callback: newBookings => {
          if (newBookings) {
            let { bookings } = this.state;
            if (isAdd && bookings) {
              bookings = bookings.concat(newBookings);
            } else {
              bookings = newBookings;
            }
            if (newBookings.length < 10) this.bookingLastPage = true;
      
            if (newBookings) this.loadingData = false;
            this.setState({
              bookings,
              loading: false,
              bookingPage: page,
              loadingDate: false,
            })
          }
        }
      })
    }
  }

  getOrders = async (page: number, isAdd = false, refresh = false) => {
    const {
      dispatch,
      reducersState,
    } = this.props;

    const { userInfo } = reducersState.user;
    if (!userInfo) {
      this.setState({
        orders: [],
        loading: false,
      })
      return;
    }

    if (page === 1) {
      this.setState({loadingDate: true})
    }
    dispatch({
      type: ActionType.QUERY_ORDERS,
      page,
      pageSize: 10,
      status: 'all',
      refresh,
      callback: newOrders => {
        let { orders } = this.state;
        if (isAdd && orders) {
          orders = orders.concat(newOrders);
        } else {
          orders = newOrders;
        }
    
        if (newOrders.length < 10) this.orderLastPage = true;
    
        if (newOrders) this.loadingData = false;
        const newOrder:  IOrderInfo[] = [];
        orders.forEach(item => {
          if (!item.superOrder) {
            newOrder.push(item);
          }
        })

        // if (newOrder.length < 10 && isReqData) {
        //   this.getOrders(page+1, true);
        // } 
        this.setState({
          orders: newOrder,
          loading: false,
          orderPage: page,
          loadingDate: false,
        })
      }
    });
  }

  getPoints = (page: number, isAdd = false) => {
    const { dispatch } = this.props;
    this.setState({loadingDate: true})
    dispatch({
      type: ActionType.QUERY_POINTS,
      page,
      callback: records => {
        let { points } = this.state;
        if (isAdd && points) {
          points = points.concat(records);
        } else {
          points = records;
        }

        if (records.length < 20) this.pointLastPage = true;
        if (records) this.loadingData = false;

        this.setState({
          points,
          loading: false,
          pointPage: page,
          loadingDate: false,
        });
      }
    })
  }

  handleNavBarButton = (key: string) => {
    this.setState({
      selectedKey: key,
      bookings: undefined,
      orders: undefined,
      points: undefined,
    })
    if (key === 'booking') {
      this.getBookings(1);
    } else if (key === 'order') {
      this.getOrders(1);
    } else if (key === 'point') {
      this.getPoints(1);
    }
  }

  handleNewBooking = () => {
    this.handleGotoRoute({
      path: paths.STORE_LIST_BY_CATEGORY,
      pathParams: {
        byType: 'booking',
        categoryID: '0',
        brandID: '0',
        fromPage: 'brands',
      },
    });
  }

  handleGoDetail = (storeSlug: string, orderNumber: string, suborderNumber: string) => {
    this.handleGotoRoute({
      path: paths.ORDER_DETAIL,
      pathParams: {
        storeSlug,
        orderNumber,
        suborderNumber,
        from: 'records',
      }
    });
  }

  handleClick = (id: string) => {
    this.handleGotoRoute({
      path: paths.BOOKING_DETAIL,
      pathParams: {
        bookingID: id,
      },
    });
  }

  handleGoBackMember = () => {
    this.handleGoBack({
      path: paths.MEMBERSHIP,
      runGoBack: true,
    });
  }

  onOpenChange = () => {
    const { reducersState } = this.props;
    const { platformConfig } = reducersState.app;
    if (platformConfig.showSideBar) {
      this.setState({
        showSideBar: !this.state.showSideBar,
      });
    }
  }

  handleShowSideBar = () => {
    this.setState({
      showSideBar: !this.state.showSideBar,
    });
  }

  render () {
    const { reducersState, dispatch } = this.props;
    const { platformConfig, language } = reducersState.app;
    const { userInfo } = reducersState.user;
    const {
      selectedKey,
      bookings,
      orders,
      points,
      loading,
      loadingDate,
    } = this.state;
    const { showSideBar, abbreviation } = platformConfig;
    const { deviceInfo } = reducersState.app;
    const layoutProps: IObject = {};
    if (!platformConfig.showRecordsTab && !platformConfig.showOrderTab ) {
      layoutProps.navIcon = <Icon className="html-embed-7 w-embed" type={IconType.LEFT_OUTLINED} />;
      layoutProps.onLeftClick = () => {
        this.handleGoBackMember();
      }
    } else if (platformConfig.showRecordsTab && showSideBar) {
      layoutProps.navIcon = <Icon className="html-embed-7 w-embed side-bar-icon" type={IconType.MENUS_OUTLINED} />;
      layoutProps.onLeftClick = () => {
        this.onOpenChange();
      }
    }

    return(
      <Layout
        {...this.props}
        isFooter={false}
        className={prefix}
      >
        <Components 
          {...this.props}
          reducersState={reducersState}
          onLeftClick={layoutProps.onLeftClick}
          navContent={getIntl().page.myRecords}
          className={
            (deviceInfo && deviceInfo.inPC)? `${prefix}-p`: ''
          }
          navClassName={platformConfig.recordsOptions.length === 1? 'records-styled': ''}
          navIcon={layoutProps.navIcon}
          sideNavOption={SideNavOption.RECORDS}
          whiteBanner={platformConfig.recordsOptions.length > 1}
          whiteBannerContent = {
            platformConfig.recordsOptions.length > 1 && 
            <PrivilegePageNavStyled>
              <div className="tabs-menu-4 w-tab-menu">
                {
                  platformConfig.recordsOptions.includes(RecordsOption.BOOKING) &&
                  <div 
                    className={`my-priviledge-tab w-inline-block w-tab-link ${selectedKey === 'booking' ? 'w--current' : ''}`} 
                    onClick={() => this.handleNavBarButton('booking')}
                  >
                    <div className="text-block-200">{getIntl().page.bookings}</div>
                  </div>
                }
                {
                  platformConfig.recordsOptions.includes(RecordsOption.ORDER) &&
                  <div 
                    className={`my-priviledge-tab w-inline-block w-tab-link ${selectedKey === 'order' ? 'w--current' : ''}`} 
                    onClick={() => this.handleNavBarButton('order')}
                  >
                    <div className="text-block-200">{getIntl().page.ordersTitle}</div>
                  </div>
                }
                {
                  platformConfig.recordsOptions.includes(RecordsOption.LOYALTY) &&
                  <div 
                    className={`my-priviledge-tab w-inline-block w-tab-link ${selectedKey === 'point' ? 'w--current' : ''}`} 
                    onClick={() => this.handleNavBarButton('point')}
                  >
                    <div className="text-block-200">{getIntl().page.points}</div>
                  </div>
                }
              </div>
            </PrivilegePageNavStyled>
          }
        >
          <RecordsPageStyled className={`tabs-2 w-tabs ${selectedKey === 'point'? 'w-tabs-point-content': ''}`}>
            {
              platformConfig.recordsOptions.length > 1 &&
              <PrivilegePageNavStyled className="privilege">
                <div className="tabs-menu-4 w-tab-menu">
                  {
                    platformConfig.recordsOptions.includes(RecordsOption.BOOKING) &&
                    <div 
                      className={`my-priviledge-tab w-inline-block w-tab-link ${selectedKey === 'booking' ? 'w--current' : ''}`} 
                      onClick={() => this.handleNavBarButton('booking')}
                    >
                      <div className="text-block-200">{getIntl().page.bookings}</div>
                    </div>
                  }
                  {
                    platformConfig.recordsOptions.includes(RecordsOption.ORDER) &&
                    <div 
                      className={`my-priviledge-tab w-inline-block w-tab-link ${selectedKey === 'order' ? 'w--current' : ''}`} 
                      onClick={() => this.handleNavBarButton('order')}
                    >
                      <div className="text-block-200">{getIntl().page.ordersTitle}</div>
                    </div>
                  }
                  {
                    platformConfig.recordsOptions.includes(RecordsOption.LOYALTY) &&
                    <div 
                      className={`my-priviledge-tab w-inline-block w-tab-link ${selectedKey === 'point' ? 'w--current' : ''}`} 
                      onClick={() => this.handleNavBarButton('point')}
                    >
                      <div className="text-block-200">{getIntl().page.points}</div>
                    </div>
                  }
                </div>
              </PrivilegePageNavStyled>
            }
            <div className={`tabs-content w-tab-content ${platformConfig.recordsOptions.length > 1? 'nav-tabs-content': selectedKey === 'point'? 'point-content': ''}`}>
              <div className="tab-pane w-tab-pane">
                {
                  selectedKey === 'booking' && bookings &&
                  <BookingItemStyled>
                    <div className="div-block-542">
                      <h2 className="h2 black">{getIntl().page.upcomingBooking}</h2>
                      <div>
                        {
                          bookings.map(item => {
                            if (!this.failureStatus.includes(item.status) && dayjs().isBefore(dayjs(item.reservationTime))) {
                              return <BookingItem booking={item} key={item.id} goToDetailPage={this.handleClick} language={language} dispatch={dispatch} />
                            } else {
                              return <div key={`upcomingBooking-${item.id}`}></div>
                            }
                          })
                        }
                      </div>
                      {
                        bookings && bookings.length === 0 &&
                        <div className="text-block-91">{getIntl().page.noBooking}</div>
                      }
                      <div className="link-block half w-inline-block" onClick={() => this.handleNewBooking()}>
                        <div className="text-block-17">{getIntl().page.newBooking}</div>
                      </div>
                    </div>
                    <h2 className="h2 black">{getIntl().page.previousBooking}</h2>
                    {
                      bookings.map(item => {
                        if (this.failureStatus.includes(item.status) || !dayjs().isBefore(dayjs(item.reservationTime))) {
                          return <BookingItem booking={item} key={item.id} goToDetailPage={this.handleClick} language={language} dispatch={dispatch} />
                        } else {
                          return <div key={`previousBooking-${item.id}`}></div>
                        }
                      })
                    }
                  </BookingItemStyled>
                }
                {
                  selectedKey === 'order' && orders &&
                  <OrderItemStyled>
                    <div className="section cms">
                      <div className="div-block-537">
                        {
                          orders.map(item => {
                            return <OrderItem key={item.orderNumber} data={item} goToDetailPage={this.handleGoDetail} language={language} />
                          })
                        }
                      </div>
                    </div>
                  </OrderItemStyled>
                }
                {
                  selectedKey === 'point' && points &&
                  <PointStyled>
                    <Point points={points} language={language} abbreviation={abbreviation}/>
                  </PointStyled>
                }
                {
                  ((orders && orders.length === 0) || (bookings && bookings.length === 0) || (points && points.length === 0)) &&
                  <div className="no-orders">
                    <div>
                      <div className="img">
                        <Icon type={IconType.TAG_ITEM}/>
                      </div>
                      {
                        selectedKey === 'booking' &&
                        <div className="no-order-tip">
                          {getIntl().page.noBookingTip}
                          <br />
                          {
                            !userInfo &&
                            getIntl().page.pleaseSignIn
                          }
                        </div>
                      }
                      {
                        selectedKey === 'order' &&
                        <div>
                          <div className="no-order">
                            {getIntl().page.noOrders}
                          </div>
                          <div className="no-order-tip">
                            {getIntl().page.noOrdersTip}
                            <br />
                            {
                              !userInfo &&
                              getIntl().page.pleaseSignIn
                            }
                          </div>
                        </div>
                      }
                      {
                        selectedKey === 'point' &&
                        <div>
                          <div className="no-order-tip">
                            {getIntl().page.noPoint}
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                }
                {
                  loading && (bookings || orders || points) &&
                  <div className="loading-data">
                    <AntdIcon type="loading" />
                    <div className="loading-msg">
                      {getIntl().common.loading}
                    </div>
                  </div>
                }
              </div>
            </div>
          </RecordsPageStyled>
        </Components>
        {
          platformConfig.showTabs &&
          ( platformConfig.showRecordsTab || platformConfig.showOrderTab ) &&
          <BmTabBar
            selectedKey={TabsOption.RECORDS}
            reducersState={reducersState}
          />
        }
        {
          platformConfig.showRecordsTab && showSideBar &&
          <SideBar
            showSideBar={this.state.showSideBar}
            onItemClick={this.handleShowSideBar}
            reducersState={reducersState}
          />
        }
        {
          loadingDate &&
          <Skeleton  type={selectedKey}/>
        }
      </Layout>
    )
  }
}

export default RecordsPage;
