import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import Icon, { IconType } from '@/components/Icon';
import { prefix } from '.';
import paths from '@/routes/paths';
import { DeviceInfoPageStyled } from './styled';
import Layout from '@/components/Layout';

export interface IDeviceInfoPageProps extends IBasePageProps {}

export interface IDeviceInfoPageState {
  userAgent: string;
}

class DeviceInfoPage extends BasePage<IDeviceInfoPageProps, IDeviceInfoPageState> {

  constructor(props: IDeviceInfoPageProps) {
    super(props);

    this.state = {
      userAgent: navigator.userAgent,
    }
  }

  render () {
    const {
      reducersState,
    } = this.props;
    const {
      userAgent,
    } = this.state;

    const { deviceInfo } = reducersState.app;
    return (
      <Layout
        {...this.props}
        navIcon={<Icon className="html-embed-7 w-embed" type={IconType.LEFT_OUTLINED} />}
        navContent="Device Info"
        onLeftClick={() => this.handleGoBack({
          path: paths.HOME,
          runGoBack: true,
        })}
        className={prefix}
        isFooter={false}
      >
        <DeviceInfoPageStyled>
          <div className="section cms wf-section">
            <div className="container w-container">
              <div className="div-block-549">
                <div className="device-info">
                  <div className="text-block-214">User Agent: {userAgent}</div>
                </div>
                <div className="device-info">
                  <div className="text-block-214">Platform: {navigator.platform}</div>
                </div>
                <div className="device-info">
                  <div className="text-block-214">In PC: {String(deviceInfo.inPC)}</div>
                </div>
                <div className="device-info">
                  <div className="text-block-214">In Pad: {String(deviceInfo.inPad)}</div>
                </div>
                <div className="device-info">
                  <div className="text-block-214">In Phone: {String(deviceInfo.inPhone)}</div>
                </div>
                <div className="device-info">
                  <div className="text-block-214">iPhone: {String(deviceInfo.iPhone)}</div>
                </div>
                <div className="device-info">
                  <div className="text-block-214">iPad: {String(deviceInfo.iPad)}</div>
                </div>
                <div className="device-info">
                  <div className="text-block-214">iMac: {String(deviceInfo.iMac)}</div>
                </div>
                <div className="device-info">
                  <div className="text-block-214">Android Pad: {String(deviceInfo.androidPad)}</div>
                </div>
                <div className="device-info">
                  <div className="text-block-214">Android Phone: {String(deviceInfo.androidPhone)}</div>
                </div>
                <div className="device-info">
                  <div className="text-block-214">Windows: {String(deviceInfo.isWindows)}</div>
                </div>
                <div className="device-info">
                  <div className="text-block-214">Apple: {String(deviceInfo.isApple)}</div>
                </div>
                <div className="device-info">
                  <div className="text-block-214">Android: {String(deviceInfo.isAndroid)}</div>
                </div>
                <div className="device-info">
                  <div className="text-block-214">WeChat: {String(deviceInfo.isWeChat)}</div>
                </div>
                <div className="device-info">
                  <div className="text-block-214">WeChat MiniProgram: {String(deviceInfo.weChatMiniProgram)}</div>
                </div>
                <div className="device-info">
                  <div className="text-block-214">DingTalk: {String(deviceInfo.dingTalk)}</div>
                </div>
                <div className="device-info">
                  <div className="text-block-214">In Apple App: {String(deviceInfo.isAppleApp)}</div>
                </div>
              </div>
            </div>
          </div>
        </DeviceInfoPageStyled>
      </Layout>
    );
  }
}

export default DeviceInfoPage;
