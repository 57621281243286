import { IRoute } from '@/routes';
import paths from '@/routes/paths';
import AboutUsPage from './AboutUsPage';
import BlogDetailPage from './BlogDetailPage';
import NewsPage from './NewsPage';


export const mobileRoutes: IRoute[] = [
  {
    path: paths.ABOUT_US,
    component: AboutUsPage,
  },
  {
    path: paths.BLOG_DETAIL,
    component: BlogDetailPage,
  },
  {
    path: paths.NEWS,
    component: NewsPage,
  },
];

export default mobileRoutes;
