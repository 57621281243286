import React, {useState} from 'react';
import { prefix } from '.';
import StoreFilterModal from '@/components/StoreFilterModal';
import { isAlpha } from '@/Config'
import { getIntl } from '@/components/App/App';

interface IStoreMapfilterProps {
  handleGoStoreMap: () => void;
  onTypeClick: (type: string) => void;
  confirmStoreFilter: () => void;
  mapEnabled: boolean;
  filterEnabled: boolean;
}

const StoreMapfilter = (props: IStoreMapfilterProps) => {

  const {
    onTypeClick,
    handleGoStoreMap,
    confirmStoreFilter,
    mapEnabled = false,
    filterEnabled = false,
  } = props;

  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <>
      <div className={`${prefix}-map-filter`} style={{height: filterEnabled || (mapEnabled && !isAlpha)? '1.06667rem': '0'}}>
        <div className={`${prefix}-map-filter-wapper`}>
          {
            !isAlpha && mapEnabled &&
            <div className={`${prefix}-map-filter-wapper-item`} onClick={handleGoStoreMap}>
              {getIntl().page.map}
            </div>
          }
          {
            !isAlpha && mapEnabled && filterEnabled &&
            <div className={`${prefix}-map-filter-wapper-line`}></div>
          }
          {
            filterEnabled && 
            <div className={`${prefix}-map-filter-wapper-item`} onClick={() => setShowModal(true)}>
              {getIntl().page.filters}
            </div>
          }
        </div>
      </div>
      <StoreFilterModal
        visible={showModal}
        onTypeClick={onTypeClick}
        onCancel={() => setShowModal(false)}
        confirmStoreFilter={confirmStoreFilter}
      />
    </>
  );
}

export default StoreMapfilter;
