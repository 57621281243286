
import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import { IObject } from '@/components/bm-common';
import { getAppTheme, setScreenColor, getIntl } from '@/components/App/App';
import Layout from '@/components/Layout/Layout';
import { prefix } from '.';
import BmTabBar from '@/components/BmTabBar';
import ActionType from '@/actions/action-type';
import { IBlog } from '@/actions/store-action';
// import NewsList from '@/components/NewsList/NewsList';
import paths from '@/routes/paths';
import TabsOption from '@/actions/tabs-option';
import Icon, { IconType } from '@/components/Icon';
import SideBar from '@/components/SideBar/SideBar';
import { NewsPageStyled } from './styled';
import NewsPanel from '@/components/NewsList/NewsPanel';

export interface INewsPageProps extends IBasePageProps { }
export interface INewsPageState {
  list: IBlog[];
  loading: boolean;
  showSideBar: boolean;
  selectedKey: string;
}

class NewsPage extends BasePage<INewsPageProps, INewsPageState> {
  routeParams: IObject = {};

  state: INewsPageState = {
    list: [],
    loading: true,
    showSideBar: false,
    selectedKey: 'PROMOTIONS',
  }

  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.lightBackgroundColor) {
      setScreenColor(appTheme.lightBackgroundColor);
    }
    super.componentDidMount();
    this.getBlogList('PROMOTIONS');
  }

  getBlogList = (type: string) => {
    const { dispatch, reducersState } = this.props;
    const { platformAppSettings } = reducersState.app;
    if (platformAppSettings && platformAppSettings.storeSlug) {
      dispatch({
        type: ActionType.LOADING,
        showLoading: true,
      });
      dispatch({
        type: ActionType.QUERY_BLOG_LIST,
        contentType: type,
        storeSlug: platformAppSettings.storeSlug,
        callback: list => {
          this.setState({
            list,
            loading: false,
          });
          dispatch({
            type: ActionType.LOADING,
            showLoading: false,
          });
        }
      })
    }
  }

  handleGoDetail = (data: IBlog) => {
    const { reducersState } = this.props;
    const { platformAppSettings } = reducersState.app;
    const { selectedKey } = this.state;
    if (platformAppSettings) {
      const { storeSlug } = platformAppSettings;
      this.handleGotoRoute({
        path: paths.BLOG_DETAIL,
        pathParams: {
          storeSlug,
          blogID: data.id,
          contentType: selectedKey === 'BLOG' ? 'news' : 'promotions',
        },
      });
    }

  }

  onOpenChange = () => {
    const { reducersState } = this.props;
    const { platformConfig } = reducersState.app;
    if (platformConfig.showSideBar) {
      this.setState({
        showSideBar: !this.state.showSideBar,
      });
    }
  }

  handleShowSideBar = () => {
    this.setState({
      showSideBar: !this.state.showSideBar,
    });
  }

  handleNavBarButton = (selectedKey: string) => {
    this.setState({
      selectedKey
    })
    this.getBlogList(selectedKey);
  }

  render() {
    const { reducersState } = this.props;
    const { platformConfig } = reducersState.app;
    const { list, loading, selectedKey } = this.state;
    const { showSideBar } = platformConfig;

    const layoutProps: IObject = {
      className: prefix,
    };
    if (!platformConfig.showAboutUsTab && !platformConfig.showPromotionsTab) {
      layoutProps.navIcon = <Icon className="html-embed-7 w-embed" type={IconType.LEFT_OUTLINED} />;
      layoutProps.onLeftClick = () => {
        this.handleGoBack({ path: paths.ACCOUNT, runGoBack: true });
      }
    } else if (showSideBar) {
      layoutProps.navIcon = <Icon className="html-embed-7 w-embed side-bar-icon" type={IconType.MENUS_OUTLINED} />;
      layoutProps.onLeftClick = () => {
        this.onOpenChange();
      }
    }

    return (
      <>
        <Layout
          {...this.props}
          className={prefix}
          navContent={getIntl().page.promotionsTitle}
          {...layoutProps}
        >
          <NewsPageStyled>
            <div className="div-block-604">
              <div className="background-strip-white">
                <div className="tabs-menu-6 w-tab-menu">
                  <div 
                    className={`tab-2 w-inline-block w-tab-link ${selectedKey === 'PROMOTIONS' ? 'selected' : ''}`}
                    onClick={() => this.handleNavBarButton('PROMOTIONS')}>
                    <div>
                      {getIntl().page.promotionsTitle}
                    </div>
                  </div>
                  <div 
                    className={`tab-2 w-inline-block w-tab-link ${selectedKey === 'BLOG' ? 'selected' : ''}`}
                    onClick={() => this.handleNavBarButton('BLOG')}>
                    <div>
                      {getIntl().page.newsTitle}
                    </div>
                  </div>
                </div>  
              </div>
              <div className="w-tabs">
                
                {
                  list.length > 0 &&
                  <div className="w-tab-content">
                    <div className="w-tab-pane w--tab-active">
                      <div className="section _1 wf-section">
                        <div className="container _1 w-container">
                          {
                            list && list.length > 0 && list.map(item => (
                              <NewsPanel
                                data={item}
                                key={item.id}
                                onItemClick={this.handleGoDetail}
                                reducersState={reducersState}
                              />
                            ))
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </NewsPageStyled>
          {
            !loading && list.length <= 0 &&
            <div className="no-content-panel">
              <Icon type={IconType.TAG_ITEM} />
              <div className="no-content-info">
                {getIntl().page.noDataTips}
              </div>
            </div>
          }
        </Layout>
        {
          platformConfig.showTabs &&
          (platformConfig.showNewsTab || platformConfig.showPromotionsTab) &&
          <BmTabBar
            selectedKey={TabsOption.NEWS}
            reducersState={reducersState}
          />
        }
        {
          showSideBar &&
          <SideBar
            showSideBar={this.state.showSideBar}
            onItemClick={this.handleShowSideBar}
            reducersState={reducersState}
          />
        }
      </>
    );
  }
}

export default NewsPage;
