import {
  IStore,
  IOpeningHour,
  IStoreConfig,
  IPlatformConfig,
  IBlog,
  IStoreImage,
  IPlatformStore,
  IStoreCategory,
  IPlatformStoreMembership,
  IAppIcons,
  IStoreLocation,
} from '@/actions/store-action';
import { IObject } from '@/components/bm-common';
import Config from '@/Config';
import ShippingMethod from '@/lib/order-builder/enums/ShippingMethod';
import { resizeImage } from '.';
import StoreFunction from '@/actions/store-function';
import { ICustomerGrowth, IMembershipLevel, INextMembershipLevel } from '@/actions/user-action';
import { dividedBy } from '@/lib/order-builder/utils';
import { IPlatformCustomPage, ISplash } from '@/actions/app-action';

export const parseResStore = (resStore: any) => {
  let store: IStore | undefined = undefined;
  const openingHours: {[key: string]: IOpeningHour[]} = {};

  let resOpeningHours: IObject = {};
  if (resStore) {
    store = {
      id: `${resStore.id}`,
      name: resStore.store_name || resStore.title || '',
      logoUrl: resStore.logo_url || resStore.logoUrl ||'',
      originalUrl: resStore.original_url || resStore.originalUrl || '',
      imgUrl: resizeImage({
        url: resStore.original_url || resStore.originalUrl,
        type: 'HEIGHT',
        height: 680,
        defaultUrl: Config.defaultStoreImg,
      }),
      slug: resStore.slug,
      isGroup: resStore.group || false,
      groupParentID: String(resStore.group_parent_id || resStore.id || ''),
      openingHours,
      lat: resStore.lat || 0,
      lng: resStore.lng || 0,
      address: resStore.address1 || '',
      currencyCode: resStore.currency,
      phone: resStore.phone || 0,
      storeScore: resStore.store_score || 0,
      open: resStore.open || false,
      zipCode: resStore.zipcode || '',
      countryCode: resStore.country_code || '',
      callingCode: resStore.calling_code || '852',
      storeCategories: [],
      city: resStore.city || '',
      delivery: resStore.delivery || false,
      pickUp: resStore.pickup || false,
      dineIn: resStore.dine_in || false,
      dineInUnassigned: resStore.dine_in_unassigned || false,
      booking: resStore.bookings || false,
      queueing: resStore.queueings || false,
      i18n: resStore.translation_info || resStore.translationInfo,
      description: resStore.description,
    };

    if (typeof resStore.opening_hours === 'object') {
      resOpeningHours = resStore.opening_hours || {};
    }
    if (Array.isArray(resStore.store_category)) {
      store.storeCategories = resStore.store_category;
    }
  }

  if (store) {
    Object.keys(resOpeningHours).forEach(key => {
      const hours = resOpeningHours[key];
      if (Array.isArray(hours)) {
        openingHours[key] = hours;
      }
    })
  }

  return store;
}

export const createDefaultStoreConfig = () => {
  const storeConfig: IStoreConfig = {
    id: '',
    platformID: '',
    platformName: '',
    storeID: '',
    storeSlug: '',
    storeName: '',
    showOrder: false,
    showFeedback: false,
    showNews: false,
    showAboutUs: false,
    showBooking: false,
    showQueueing: false,
    showMenu: false,
    autoShowProcurementMethod: false,
    storeFunctions: [],
    shippingMethods: [],
    delivery: false,
    deliveryAdvanceOrderDate: 0,
    deliveryInterval: 0,
    deliveryFee: 0,
    deliveryMiles: 0,
    deliveryMinAmount: 0,
    deliveryTips: '',
    pickUp: false,
    enableSignInReminder: false,
    pickUpAdvanceOrderDate: 0,
    pickUpInterval: 0,
    delayedDeliveryTime: 0,
    deliveryTime: [],
    dineIn: false,
    dineInUnassigned: false,
    disableServiceCharge: false,
    fapiao: false,
    foodpanda: '',
    deliveroo: '',
    deliveryDesc: '',
    safetyVerification: false,
    safetyVerificationAmount: 0,
    storeUrl: '',
    hideSecondaryMenu: false,
    purchaseRegulatedGoods: false,
    purchaseRegulatedTips: '',
    bookingFee: false, // 是否需要支付预定费用
    bookingTips: '',
    i18n: {},
    payMethodKeys: [],
    payBill: false,
    storeModeEnabled: false,
    hideNowPickUpTime: false,
  };

  return storeConfig;
}

export const createDefaultPlatformConfig = () => {
  const platformConfig: IPlatformConfig = {
    ...createDefaultStoreConfig(),
    signInMethods: [],
    signUpMethods: [],
    accountOptions: [],
    tabsOptions: [],
    showTabs: false,
    showStoreTab: false,
    showOrderTab: false,
    showAccountTab: false,
    showNewsTab: false,
    showAboutUsTab: false,
    showHomeTab: false,
    showRecordsTab: false,
    showEShopTab: false,
    showPromotionsTab: false,
    showMembersAreaTab: false,
    showCategoryTab: false,
    validServiceType: [],
    showMyIDTab: false,
    supportStoreCategory: false,
    showSideBar: false,
    sidebarOptions: [],
    enableNotes: false,
    showBrandsTab: false,
    closeRegisterEmail: false,
    showEmailReceiptBtn: false,
    ecouponsRedemption: 'STORE_CREDIT',
    platformLogo: '',
    platformBanner: [],
    bannerCarouselTime: 0,
    abbreviation: '',
    birthdayHiddenDayEnable: false,
    birthdayHiddenYearEnable: false,
    hidePhoneNumberHyperlink: false,
    feedbackEnable: false,
    platformLogoSvg: '',
    menuDisplayMethod: '',
    filters: [],
    registerContent: [],
    registrationRequired: [],
    colorInversion: false,
    memberIconStyle: 'PROGRESS_BAR',
    recordsOptions: [],
    loyaltyOptions: [],
    storeFunctionLabel: true,
    payMethodKeys: [],
    mapEnabled: false,
    filterEnabled: false,
    memberMigration: false,
    showStoreCreditBalance: false,
    hideMemberExpiryDate: false,
    areaCodeId: '',
    areaCodeISO: '',
  };

  return platformConfig;
}

export const parseResStoreConfig = (resStoreConfig: any, platformID: string) => {
  const storeConfig: IStoreConfig = createDefaultStoreConfig();

  storeConfig.platformID = platformID || '';

  // let resStoreFunction: StoreFunction[] = [];
  if (resStoreConfig) {
    storeConfig.id = resStoreConfig.id || storeConfig.id;
    storeConfig.platformID = resStoreConfig.platform_id || storeConfig.platformID;
    storeConfig.platformName = resStoreConfig.platform_name || storeConfig.platformName;
    storeConfig.storeID = resStoreConfig.store_id || storeConfig.storeID;
    storeConfig.storeName = resStoreConfig.store_name || storeConfig.storeName;
    storeConfig.storeSlug = resStoreConfig.__store_slug || resStoreConfig.store_slug || storeConfig.storeSlug;
    storeConfig.autoShowProcurementMethod = resStoreConfig.auto_show_procurement_method || false;

    storeConfig.delivery = resStoreConfig.delivery || false;
    storeConfig.deliveryAdvanceOrderDate = Number(resStoreConfig.delivery_advance_order_date || 0);
    storeConfig.deliveryInterval = Number(resStoreConfig.delivery_interval || 0);
    storeConfig.deliveryFee = Number(resStoreConfig.delivery_fee || 0);
    storeConfig.deliveryMiles = Number(resStoreConfig.delivery_miles || 0);
    storeConfig.deliveryMinAmount = Number(resStoreConfig.delivery_min_amount || 0);
    storeConfig.deliveryTips = resStoreConfig.delivery_tips || '';
    storeConfig.pickUp = resStoreConfig.pickup || false;
    storeConfig.enableSignInReminder = resStoreConfig.enable_sign_in_reminder || false;
    storeConfig.pickUpAdvanceOrderDate = Number(resStoreConfig.pickup_advance_order_date || 0);
    storeConfig.pickUpInterval = Number(resStoreConfig.pickup_interval || 0);
    storeConfig.delayedDeliveryTime = Number(resStoreConfig.delayed_delivery_time || 0);
    storeConfig.deliveryTime = resStoreConfig.delivery_time || [];
    storeConfig.dineIn = resStoreConfig.dine_in || false;
    storeConfig.dineInUnassigned = resStoreConfig.dine_in_unassigned || false;
    storeConfig.disableServiceCharge = resStoreConfig.disable_service_charge || false;
    storeConfig.showAboutUs = resStoreConfig.about_us || false;
    storeConfig.showBooking = resStoreConfig.bookings || false;
    storeConfig.showQueueing = resStoreConfig.queueings || false;
    storeConfig.showOrder = resStoreConfig.order || false;
    storeConfig.showMenu = resStoreConfig.menu || false;
    storeConfig.showNews = resStoreConfig.news || false;
    storeConfig.showFeedback = resStoreConfig.feedback || false;
    storeConfig.fapiao = resStoreConfig.fapiao || false;
    storeConfig.foodpanda = resStoreConfig.foodpanda || '';
    storeConfig.deliveroo = resStoreConfig.deliveroo || '';
    storeConfig.deliveryDesc = resStoreConfig.delivery_desc || '';
    storeConfig.safetyVerification = resStoreConfig.safety_verification ||  false;
    storeConfig.safetyVerificationAmount = resStoreConfig.safety_verification_amount ||  0;
    storeConfig.hideSecondaryMenu = resStoreConfig.hide_secondary_menu || false;

    storeConfig.storeUrl = resStoreConfig.store_url || '';
    storeConfig.purchaseRegulatedGoods = resStoreConfig.purchase_regulated_goods || false;
    storeConfig.purchaseRegulatedTips = resStoreConfig.purchase_regulated_tips || '';
    storeConfig.bookingFee = resStoreConfig.booking_fee || false;
    storeConfig.bookingTips = resStoreConfig.booking_tips || '';
    storeConfig.i18n = resStoreConfig.__i18n || {};
    storeConfig.payMethodKeys = resStoreConfig.payment_methods || [];
    storeConfig.payBill = resStoreConfig.pay_bill || storeConfig.payBill;
    storeConfig.storeModeEnabled = resStoreConfig.store_mode_enabled || storeConfig.storeModeEnabled;
    storeConfig.hideNowPickUpTime = resStoreConfig.hide_now_pick_up_time || false;

    if (storeConfig.delivery) {
      storeConfig.shippingMethods.push(ShippingMethod.DELIVERY);
    }

    if (storeConfig.dineIn) {
      storeConfig.shippingMethods.push(ShippingMethod.DINE_IN);
    }

    if (storeConfig.dineInUnassigned) {
      storeConfig.shippingMethods.push(ShippingMethod.DINE_IN_UNASSIGNED);
    }

    if (storeConfig.pickUp) {
      storeConfig.shippingMethods.push(ShippingMethod.PICK_UP);
    }

    // if (Array.isArray(resStoreConfig.store_function)) {
    //   resStoreFunction = resStoreConfig.store_function;
    // }
  }

  if (storeConfig.showOrder) {
    storeConfig.storeFunctions.push(StoreFunction.ORDER);
  }

  if (storeConfig.showMenu) {
    storeConfig.storeFunctions.push(StoreFunction.MENU);
  }

  if (storeConfig.showNews) {
    storeConfig.storeFunctions.push(StoreFunction.NEWS);
  }

  if (storeConfig.showBooking) {
    storeConfig.storeFunctions.push(StoreFunction.BOOKING);
  }

  if (storeConfig.showQueueing) {
    storeConfig.storeFunctions.push(StoreFunction.QUEUEING);
  }

  if (storeConfig.showAboutUs) {
    storeConfig.storeFunctions.push(StoreFunction.ABOUT_US);
  }

  if (storeConfig.showFeedback) {
    storeConfig.storeFunctions.push(StoreFunction.FEEDBACK);
  }

  if (storeConfig.storeFunctions.length < 1) {
    storeConfig.storeFunctions.push(StoreFunction.ABOUT_US);
    storeConfig.showAboutUs = true;
    storeConfig.storeFunctions.push(StoreFunction.ORDER);
    storeConfig.showOrder = true;
  }

  return storeConfig;
}

export const parseResStoreCategories = (resStoreCategories: any[]) => {
  const storeCategories: IStoreCategory[] = [];
  if (Array.isArray(resStoreCategories)) {
    resStoreCategories.forEach(item => {
      storeCategories.push({
        id: item.id.toString(),
        platformID: item.platform_id,
        'category_name': item.category_name,
        categoryImage: resizeImage({
          url: item.category_image,
          type: 'FILL',
          width: 294,
          height: 192,
        }),
        categoryMediumImage: resizeImage({
          url: item.category_image,
          type: 'FILL',
          width: 686,
          height: 456,
        }),
        i18n: item.__i18n || {},
        brandsCategory: item.brands_category || '0',
      })
    })
  }

  return storeCategories;
}

export const parseSplash = (result: any[]) => {
  const splashData: ISplash[] = [];
  result.forEach((item: any) => {
    const splash: ISplash = {
      id: item.id,
      platformID: item.platform_id,
      storeID: item.store_id,
      title: item.title || '',
      describe: item.text || '',
      imageUrl: resizeImage({
        url: item.splash_image || '',
        type: 'DEFAULT',
      }),
      videoUrl: '',
      type: 'LOGO',
      i18n: item.__i18n || {},
      alignment: item.alignment || 'left',
    }

    if (item.style === 'IMAGE' && splash.imageUrl) {
      splash.type = 'IMAGE';
    }

    if (item.style === 'VIDEO') {
      if (Array.isArray(item.video) && item.video.length > 0) {
        splash.videoUrl = item.video[0];
        splash.type = 'VIDEO'
      } else if (splash.imageUrl) {
        splash.type = 'IMAGE';
      }
    }

    splashData.push(splash);
  });

  return splashData;
}

export const parseResBlog = (resBlog: any) => {
  let blog: IBlog | undefined = undefined;

  if (resBlog && resBlog.id) {
    blog = {
      id: resBlog.id || '',
      cover: resizeImage({
        url: resBlog.content_cover,
        type: 'HEIGHT',
        height: 656,
        defaultUrl: Config.defaultStoreImg,
      }) || '',
      title: resBlog.content_title || '',
      body: resBlog.content_body || '',
      type: resBlog.content_type || '',
      status: resBlog.status || '',
      storeId: resBlog.store_id || '',
      storeName: resBlog.store_name || '',
      storeSlug: resBlog.__store_slug || '',
      updatedAt: resBlog.updated_at || '',
      i18n: resBlog.__i18n,
    }
  }

  return blog;
}

export const parseResStoreImage = (resStoreImage: any) => {
  let storeImage: IStoreImage | undefined = undefined;

  if (resStoreImage && resStoreImage.id) {
    storeImage = {
      id: `${resStoreImage.id}`,
      primary: resStoreImage.primary || false,
      mediumUrl: resizeImage({
        url: resStoreImage.medium_image_url,
        type: 'HEIGHT',
        height: 680,
        defaultUrl: Config.defaultStoreImg,
      }),
      originalUrl: resizeImage({
        url: resStoreImage.original_image_url,
        type: 'HEIGHT',
        height: 680,
        defaultUrl: Config.defaultStoreImg,
      }),
      smallUrl: resizeImage({
        url: resStoreImage.small_image_url,
        type: 'HEIGHT',
        height: 680,
        defaultUrl: Config.defaultStoreImg,
      }),
      bigUrl: resizeImage({
        url: resStoreImage.big_image_url,
        type: 'HEIGHT',
        height: 680,
        defaultUrl: Config.defaultStoreImg,
      }),
      thumbUrl: resizeImage({
        url: resStoreImage.thumb_image_url,
        type: 'HEIGHT',
        height: 680,
        defaultUrl: Config.defaultStoreImg,
      }),
    };
  }

  return storeImage;
}

export const parseResPlatformStore = (resStore: any) => {
  let platformStore: IPlatformStore | undefined;
  if (resStore) {
    platformStore = {
      id: resStore.id || '',
      platformID: resStore.platform_id || '',
      storeID: resStore.store_id || '',
      status: resStore.store_audit || '',
      environment: resStore.environment || '',
    }
  }
  return platformStore;
}

export const parseResStoreMembership = (resStoreMembership: any[]) => {
  const storeMemberships: IPlatformStoreMembership[] = [];
  if (Array.isArray(resStoreMembership)) {
    resStoreMembership.forEach(item => {
      storeMemberships.push({
        id: item.id,
        isDefault: item.is_default,
        membershipLevelColor: item.membership_level_color,
        membershipLevelLightColor: item.membership_level_light_color,
        i18n: item.__i18n || {},
        title: item.title,
        sort: item.sort,
        imgUrl: resizeImage({
          url: item.image,
          type: 'HEIGHT',
          height: 396,
        }) || '',
      })
    })
    storeMemberships.sort((m1, m2) => m1.sort - m2.sort);
  }

  return storeMemberships;
}

export const parseResAppIcons = (resAppIcons: any[]) => {
  const appIcons: IAppIcons[] = [];
  if (Array.isArray(resAppIcons)) {
    resAppIcons.forEach(item => {
      appIcons.push({
        id: item.id,
        svg: item.icon,
        iconType: item.icon_type,
        iconName: item.icon_name,
        i18n: item.__i18n,
      })
    })
  }
  return appIcons;
}

export const parseResCustomerMembership = (resStoreMembership: any) => {
  let storeMemberships: ICustomerGrowth | undefined = undefined;
  if (resStoreMembership) {
    const currentLevel = resStoreMembership.curr_membership_level;
    const currentMembershipLevel = {
      id: currentLevel.id,
      title: currentLevel.title,
      abbreviation: currentLevel.abbreviation,
      image: resizeImage({
        url: currentLevel.image,
        type: 'DEFAULT',
      }) || `${Config.cdnUrl}/images/default-member.jpg`,
      maxGrowthValue: currentLevel.max_growth_value,
      minGrowthValue: currentLevel.min_growth_value,
      membershipLevelColor: currentLevel.membership_level_color,
      membershipLevelLightColor: currentLevel.membership_level_light_color,
      i18n: currentLevel.translation_info,
      isCurrentLevel: true,
      membershipExpire: currentLevel.membership_expire,
      icon: resizeImage({
        url: currentLevel.icon,
        type: 'DEFAULT',
      }),
      hideTheGrowthPoint: currentLevel.hide_the_growth_point || false,
    }
    const membershipList: IMembershipLevel[] = [];
    let nextLevelData: INextMembershipLevel | undefined = undefined;
    const membershipLevels: any[] = resStoreMembership.member_ship_levels;
    if (resStoreMembership.member_ship_levels.length > 0) {
      membershipLevels.forEach((item, index) => {
        const currentIndex = membershipLevels.findIndex(value => value.id === currentLevel.id);
        let percent = '100%';
        if (index < currentIndex) {
          percent = '100%';
        } else if (index > currentIndex) {
          percent = '0%';
        } else if (index === currentIndex) {
          const number = dividedBy(resStoreMembership.total_growth_value, item.max_growth_value, 1) * 100;
          percent = number > 100 ? 100 + '%' : number + '%';
        }
        membershipList.push({
          id: item.id,
          title: item.title,
          abbreviation: item.abbreviation,
          image: resizeImage({
            url: item.image,
            type: 'DEFAULT',
          }) || `${Config.cdnUrl}/images/default-member.jpg`,
          maxGrowthValue: item.max_growth_value,
          minGrowthValue: item.min_growth_value,
          membershipLevelColor: item.membership_level_color,
          membershipLevelLightColor: item.membership_level_light_color,
          i18n: item.translation_info,
          isCurrentLevel: item.id === currentLevel.id ? true : false,
          percent,
        })
        if (!resStoreMembership.is_max_level && item.id === resStoreMembership.next_level.level_id) {
          nextLevelData = {
            id: item.id,
            title: item.title,
            i18n: item.translation_info,
            spend: resStoreMembership.next_level.spend,
          }
        }
        if (item.id === currentLevel.id) {
          currentMembershipLevel.i18n = item.translation_info;
        }
      })
    }
    storeMemberships = {
      accumulatedSpending: resStoreMembership.accumulated_spending,
      customerCode: resStoreMembership.customer_code,
      customerID: resStoreMembership.customer_id,
      isMaxLevel: resStoreMembership.is_max_level,
      currentMembershipLevel: currentMembershipLevel,
      totalGrowthValue: resStoreMembership.total_growth_value,
      memberShipLevels: membershipList,
      nextLevel: nextLevelData,
      myLoyaltyStamp: resStoreMembership.my_score ? Number(resStoreMembership.my_score) : 0,
      storeCredits: resStoreMembership.store_credits ? Number(resStoreMembership.store_credits) : 0,
    }
    if (nextLevelData && storeMemberships) {
      storeMemberships.nextLevel = nextLevelData;
    }
  }

  return storeMemberships;
}

export const parseStoreLocation = (res: any[]) => {
  const storeLocations: IStoreLocation[] = [];
  if (Array.isArray(res)) {
    res.forEach(item => {
      storeLocations.push({
        id: item.id,
        district: item.district,
        platformID: item.platform_id,
        storeID: item.store_id,
        storeName: item.store_name,
        i18n: item.__i18n,
        platformName: item.platform_name,
        city: item.city,
      })
    });
  }
  return storeLocations;
}

export const parsePlatformCustomPages = (res: any[]) => {
  const platformCustomPages: IPlatformCustomPage[] = [];
  if (Array.isArray(res)) {
    res.forEach(item => {
      const platformCustomPage: IPlatformCustomPage = {
        id: item.id,
        type: item.type,
        platformID: item.platform_id,
        platformName: item.platform_name,
        storeID: item.store_id,
        storeSlug: item.store_slug,
        images: item.images || [],
        pageUrl: item.page_url || '',
        content: item.content || '',
        i18n: item.__i18n,
      }

      platformCustomPages.push(platformCustomPage);
    });
  }

  return platformCustomPages;
}
