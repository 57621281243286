import BigNumber from 'bignumber.js';
import {
  IReceiveOrder,
  purchaseOrder,
  // lineItem,
} from '@/actions/good-received-note-actions';

export const parseGoodReceivedNote = (record: any) => {
  const purchaseOrderObj: {[id: string]: purchaseOrder} = {};
  let goodReceivedNote: IReceiveOrder = {
    id: '',
    createdAt: '',
    createdBy: '',
    expectedReceiveDate: '',
    receiveDate: '',
    noteType: 1,
    number: '',
    purchaseOrders: [],
    purchaseOrderIDs: [],
    state: '',
    remarks: '',
    referenceNumber: '',
    supplierName: '',
    supplierID: '',
    qtyPOTotal: 0,
    qtyGRN: 0,
    qtyRN: 0,
    qtyGRNReceived: 0,
    subtotalGRN: 0,
    totalGRN: 0,
    serviceFee: 0,
    isInitial: false,
  };
  const purchaseOrders: purchaseOrder[] = []
  if (record) {
    let qtyPOTotal = new BigNumber(0);
    let qtyGRN = new BigNumber(0);
    let qtyRN = new BigNumber(0);
    let qtyGRNReceived = new BigNumber(0);
    let subtotalGRN = new BigNumber(0);
    let totalGRN = new BigNumber(0);
    if (record['purchase_orders'] && Array.isArray(record['purchase_orders'])) {
      record['purchase_orders'].forEach(item => {
        purchaseOrderObj[item['id']] = {
          id: item['id'],
          number: item['number'],
          state: item['state'],
          lineItems: [],
          currency: item['exchange_rate'] ? item['exchange_rate']['currency_to'] || 'HKD' : '',
        }
      })
    }
    if (record['receive_order_items'] && Array.isArray(record['receive_order_items'])) {
      record['receive_order_items'].forEach(item => {
        if (item['receive_order_item'] && item['receive_order_item']['purchase_item']) {
          const receiveOrderItem = item['receive_order_item'];
          const purchaseItem = receiveOrderItem['purchase_item'];
          if (purchaseOrderObj[purchaseItem['purchase_order_id']]) {
            const ratio = Number(receiveOrderItem['display_unit_ratio']);
            const qtyReceived = Number(receiveOrderItem['qty_received_in_display_unit']);
            const qtyRemaining = Number(receiveOrderItem['qty_remaining_in_display_unit']);
            const qtyPO = Number(purchaseItem['quantity_in_display_unit']);
            const qtyReceive = Number(receiveOrderItem['quantity_in_display_unit']);
            const qtyReceivedPO = Number(purchaseItem['qty_received_in_display_unit']);
            const cost = Number(receiveOrderItem['cost_in_display_unit']);
            const total = new BigNumber(qtyReceive).times(new BigNumber(cost)).toNumber();
            const remaining = qtyPO - qtyReceivedPO;
            const lineItem = {
              id: purchaseItem['source_id'],
              name: purchaseItem['name'],
              qtyReceived: !isNaN(qtyReceived) ? qtyReceived : 0,
              qtyRemaining: !isNaN(qtyRemaining) ? qtyRemaining : 0,
              remaining: !isNaN(remaining) ? remaining : 0,
              cost: !isNaN(cost) ? cost : 0,
              qtyReceivedPO: !isNaN(qtyReceivedPO) ? qtyReceivedPO : 0,
              qtyPO: !isNaN(qtyPO) ? qtyPO : 0,
              unit: receiveOrderItem['display_unit'],
              qtyReceive: !isNaN(qtyReceive) ? qtyReceive : 0,
              imageUrl: purchaseItem['listing'] ? purchaseItem['listing']['image_url'] : '',
              purchaseOrderID: purchaseOrderObj[purchaseItem['purchase_order_id']].id,
              receiveOrderItemID: receiveOrderItem['id'],
              purchaseItem: receiveOrderItem,
              displayUnitRatio: !isNaN(ratio) && ratio > 0 ? ratio : 1,
              displayUnitID: receiveOrderItem['display_unit_id'],
              purchaseItemID: receiveOrderItem['purchase_item_id'] || 0,
              total: !isNaN(total) ? total : 0,
            }
            purchaseOrderObj[purchaseItem['purchase_order_id']].lineItems.push(lineItem);
            qtyPOTotal = qtyPOTotal.plus(new BigNumber(lineItem.qtyPO));
            qtyGRN = qtyGRN.plus(new BigNumber(lineItem.qtyReceive));
            qtyRN = qtyRN.plus(new BigNumber(lineItem.qtyRemaining))
            qtyGRNReceived = qtyGRNReceived.plus(new BigNumber(lineItem.qtyReceived));
            subtotalGRN = subtotalGRN.plus(new BigNumber(lineItem.total));
            totalGRN = totalGRN.plus(new BigNumber(lineItem.total));
          }
        }
      })
    }

    Object.keys(purchaseOrderObj).forEach(item => {
      purchaseOrders.push(purchaseOrderObj[item]);
    })
    goodReceivedNote = {
      id: record['id'],
      createdAt: record['created_at'],
      createdBy: record['created_by'],
      expectedReceiveDate: record['expected_receive_date'],
      receiveDate: record['receive_date'],
      noteType: record['note_type'],
      number: record['number'],
      purchaseOrders: purchaseOrders,
      purchaseOrderIDs: record['purchase_order_ids'],
      state: record['state'] || '',
      remarks: record['remarks'] || '',
      referenceNumber: record['reference_number'] || '',
      supplierName: record['supplier_name'] || '',
      supplierID: record['supplier_id'],
      qtyPOTotal: qtyPOTotal.toNumber(),
      qtyGRN: qtyGRN.toNumber(),
      qtyRN: qtyRN.toNumber(),
      qtyGRNReceived: qtyGRNReceived.toNumber(),
      subtotalGRN: subtotalGRN.toNumber(),
      totalGRN: totalGRN.toNumber(),
      serviceFee: 0,
      isInitial: false,
    }
  }
  return goodReceivedNote;
}
