import React, { useState } from 'react';
import Button from 'antd-mobile/es/button';
import Toast from 'antd-mobile/es/toast';
import { useSelector, useDispatch } from 'react-redux';
import { IReducersState } from '@/reducers';
import { IDialCodeGroupItem } from '@/components/DialCodeGroup';
import { IDispatch } from '@/actions';
import ActionType from '@/actions/action-type';
import SignPassword from '../common/userComponents/Password';
import SignUserName from '../common/userComponents/NewUserName';
import SignBottom from './SignInBottom';
import { SignInMobileStyled } from './styled';
import { getIntl } from '../App/App';


interface ISignInMobileProps {
  signInClick?: () => void;
  className?: string;
  forgotClick?: () => void;
}

const SignInMobile: React.FC<ISignInMobileProps> = props => {

  const {
    signInClick = () => {},
    forgotClick = () => {},
  } = props;

  const dispatch = useDispatch<IDispatch>();

  const reducersState: IReducersState = useSelector<IReducersState, IReducersState>(state => state);

  const { language } = reducersState.app;

  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [areaCodeData, setAreaCodeData] = useState<IDialCodeGroupItem>();
  const [verfityPhone, setVerfityPhone] = useState<boolean>(false);

  const signInFlow = async () => {

    if (phoneNumber && password && areaCodeData) {

      // 验证手机号
      dispatch({
        type: ActionType.LOADING,
        showLoading: true,
        showLoadingBgc: true,
      });

      dispatch({
        type: ActionType.SIGN_IN,
        username: `+${areaCodeData.dialCode} ${phoneNumber}`,
        password: password,
        callback: (success: boolean) => {
          if (success) {
            signInClick();
          } else {
            Toast.info(getIntl().page.signInErrorMsg);
          };

          dispatch({
            type: ActionType.LOADING,
            showLoading: false,
          });
        }
      });
    }
  }

  const handleItemClick = (data: IDialCodeGroupItem) => {
    setAreaCodeData(data);
  }

  return (
    <SignInMobileStyled>
      <SignUserName
        language={language}
        value={phoneNumber}
        onChange={setPhoneNumber}
        onItemClick={(data: IDialCodeGroupItem) => handleItemClick(data)}
        showIcon={true}
        areaCodeData={areaCodeData}
        areaCode="852"
        type="PHONE"
        verfityPhone={(value: boolean) => setVerfityPhone(value)}
      />
      <SignPassword
        value={password}
        onChange={setPassword}
        forgotClick={forgotClick}
      />
      {
        <Button
          type="primary"
          className="signin-button"
          onClick={signInFlow}
          disabled={ !phoneNumber || !password || verfityPhone}
        >
          {getIntl().page.signin}
        </Button>
      }
      <SignBottom />
    </SignInMobileStyled>
  )
}
export default SignInMobile;
