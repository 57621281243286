import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';
import * as apiUtils from './utils/api';
import * as Data from './models/Data';
import { IObject } from '@/components/bm-common';
import { orderEntityToShoppingCartData } from './utils';
import OrderEntity, { ILineItemInfo, IModifierInfo, IBaseInfo, IOrderEntityJson, ISetShippingMethodParams, ITaxOption, IRoundingOption } from './models/OrderEntity';
import { IStoreShoppingCartNote, IStoreShoppingCartDeliveryDate, ISetDiscountValue } from '@/actions/shopping-cart-action';
// import { OrderBuilder as OrderBuilder2 } from '@bindo/order-builder';
import ShippingMethod from './enums/ShippingMethod';
import { IStorePickupLocation } from '@/actions/store-action';
import dayjs from 'dayjs';
import { findNode } from '@/utils';
import LineItemEntity from './models/LineItemEntity';

interface IAddToCartBase {
  modifiers: IAddToCartModifier[];
}

interface IAddToCartModifier extends IAddToCartBase {
  id: string;
  quantity: number;

}

interface IListingPaths extends IObject {
  listingID: string;
}

interface IIAddToCartBaseParams {
  storeID: string;
  currencyCode: string;
  taxOptions: ITaxOption[];
  roundingOption: IRoundingOption | any;
  isSuperOrder: boolean;
  quantity?: number;
  shippingMethod?: ShippingMethod;
  pickupLocation?: IStorePickupLocation;
  pickupLocationID?: string;
  deliveryDate?: dayjs.Dayjs;
  storePickupLocations?: IStorePickupLocation[];
  disableServiceCharge?: boolean;
}

interface IChangeShippingMethodParams extends IIAddToCartBaseParams {
  setShippingMethodParams: ISetShippingMethodParams;
}

interface IAddToCartParams extends IIAddToCartBaseParams {
  lineItemInfo: ILineItemInfo;
  listingStoreID: string;
}

interface IAddToCartForPathsParams extends IIAddToCartBaseParams {
  paths: IListingPaths;
  listingStoreID: string;
}

interface IInitStoreOrderEntityParams extends IIAddToCartBaseParams {
  orderEntityJson: IOrderEntityJson;
  storeIDRefTaxOptions: { [storeID: string]: ITaxOption[] };
}

interface IInitStoreOrderEntityByOrderApiDataParams {
  orderData: any;
  type: 'ORDER' | 'NEXT_ORDER';
  taxOptions: ITaxOption[];
  roundingOption: IRoundingOption;
}

export interface IChangeLineItemModifiersParams {
  storeID: string;
  listingID: string;
  listingUuids: string[];
  taxOptions: ITaxOption[];
  roundingOption: IRoundingOption;
  disableServiceCharge?: boolean;
  shippingMethod?: ShippingMethod;
  modifierCountRef: {[modifierID: string]: number};
}

/**
 * 订单构建
 */
class OrderBuilder {
  /**
   * 设置当前平台，必须在使用Order Builder之前调用
   */
  static setChannel = Data.setChannel;

  /**
   * 根据storeID，获取FavoriteTabs
   */
  static getFavoriteTabs = Data.getFavoriteTabs;

  /**
   * 获取所有店的FavoriteTabs，返回键值对
   */
  static getFavoriteTabsMap = Data.getFavoriteTabsMap;

  /**
   * 根据storeID，获取Modifiers
   */
  static getModifiers = Data.getStoreModifiers;

  /**
   * 根据storeID，获取Modifiers
   */
  static findModifiers = Data.findModifiers;

  /**
   * 根据storeID，获取listings
   */
  static findListing = Data.findListing;

  /**
   * 转换favorite_tabs接口数据，使用Order Builder必须使用转换后的数据
   */
  static convertFavoriteApiData = apiUtils.convertFavoriteApiData;

  /**
   * 转换modifier_sets接口数据，使用Order Builder必须使用转换后的数据
   */
  static convertModifierApiData = apiUtils.convertModifierApiData;

  static storeIDRefOrderEntity: {[storeID: string]: OrderEntity} = {};

  static initStoreOrderEntity = (params: IInitStoreOrderEntityParams) => {
    // const { storeID, groupStoreID, currencyCode, orderEntityJson, taxOptions, storeIDRefTaxOptions, isSuperOrder } = params;
    // let shoppingCartData: any;
    // const choiceResult = choiceStoreTypeInfo(storeID, groupStoreID);

    // for (let i = 0; i < orderEntityJson.line_items.length; i++) {
    //   const lineItems = orderEntityJson.line_items[i];
    //   if (String(lineItems.store_id) !== String(choiceResult.storeID)) {
    //     choiceResult.isSuperOrder = true;
    //     break;
    //   }
    // }
    const { storeID, currencyCode, orderEntityJson, taxOptions, storeIDRefTaxOptions, isSuperOrder, storePickupLocations, roundingOption, disableServiceCharge } = params;
    let shoppingCartData: any;

    if (storeID && orderEntityJson) {
      const orderEntity = new OrderEntity(storeID, isSuperOrder);
      if ((orderEntityJson.shipping_method !== ShippingMethod.DINE_IN && orderEntityJson.shipping_method !== ShippingMethod.QRCODE_SHOPPING)
      || (orderEntityJson.shipping_method === ShippingMethod.DINE_IN && disableServiceCharge)) {
        orderEntity.setTaxOptions(taxOptions.filter(item => Number(item.taxType) !== 2));
      } else {
        orderEntity.setTaxOptions(taxOptions);
      }
      orderEntity.setRoundingOptions(roundingOption);
      orderEntity.setCurrencyCode(currencyCode)
      orderEntity.initByOrderEntityJson(orderEntityJson, storeIDRefTaxOptions, storePickupLocations);
      orderEntity.checkLineItemsByShippingMethod();
      orderEntity.calculateDiscount();
      orderEntity.calculate();
      OrderBuilder.storeIDRefOrderEntity[storeID] = orderEntity;

      shoppingCartData = orderEntityToShoppingCartData(orderEntity);
    }

    return shoppingCartData;
  }

  static initStoreOrderEntityByOrderApiData = (params: IInitStoreOrderEntityByOrderApiDataParams) => {
    const { orderData, type, taxOptions, roundingOption } = params;
    let shoppingCartData: any;

    if (orderData && orderData.id && orderData.store_id) {
      const orderEntity = new OrderEntity(orderData.store_id, orderData.is_super_order || false);
      orderEntity.setTaxOptions(taxOptions);
      orderEntity.setRoundingOptions(roundingOption);
      orderEntity.initByOrderApiData(orderData, type);
      orderEntity.checkLineItemsByShippingMethod();
      orderEntity.calculate();
      if (orderEntity.shippingMethod === ShippingMethod.QRCODE_SHOPPING) {
        OrderBuilder.storeIDRefOrderEntity[`QRCODE_SHOPPING_${orderEntity.storeID}`] = orderEntity;
      } else {
        OrderBuilder.storeIDRefOrderEntity[orderEntity.storeID] = orderEntity;
      }

      shoppingCartData = orderEntityToShoppingCartData(orderEntity);
    }

    return shoppingCartData;
  }

  static getStoreIDsByOrderApiData = (invoice: any) => OrderEntity.getStoreIDsByOrderApiData(invoice)
  static getStoreIDsByOrderEntityJson = (orderEntityJson: IOrderEntityJson) => OrderEntity.getStoreIDsByOrderEntityJson(orderEntityJson)

  static addToCart = (params: IAddToCartParams) => {
    const {
      storeID,
      listingStoreID,
      currencyCode,
      lineItemInfo,
      taxOptions,
      roundingOption,
      quantity = 1,
      shippingMethod,
      disableServiceCharge,
      isSuperOrder,
      pickupLocation,
      pickupLocationID,
      deliveryDate,
    } = params;
    let orderEntity = OrderBuilder.storeIDRefOrderEntity[storeID];
    if (!orderEntity) {
      orderEntity = new OrderEntity(storeID, isSuperOrder);
      if (taxOptions) {
        if ((shippingMethod !== ShippingMethod.DINE_IN && shippingMethod !== ShippingMethod.QRCODE_SHOPPING)
        || (shippingMethod === ShippingMethod.DINE_IN && disableServiceCharge)) {
          orderEntity.setTaxOptions(taxOptions.filter(item => Number(item.taxType) !== 2));
        } else {
          orderEntity.setTaxOptions(taxOptions);
        }
      }
      orderEntity.setRoundingOptions(roundingOption);
      if (shippingMethod || shippingMethod === 0) {
        orderEntity.setShippingMethod({
          shippingMethod,
          pickupLocation,
          pickupLocationID,
          deliveryDate,
        });
      }
      orderEntity.setCurrencyCode(currencyCode)
    }

    if (lineItemInfo && lineItemInfo.listingID) {
      const listing = Data.getListing(listingStoreID, lineItemInfo.listingID);

      if (listing) {
        orderEntity.addLineItem({
          lineItemInfo,
          listing,
          quantity,
          storeID: listingStoreID,
          taxOptions,
          roundingOption,
        });
      }
    }
    if (taxOptions) {
      if ((shippingMethod !== ShippingMethod.DINE_IN && shippingMethod !== ShippingMethod.QRCODE_SHOPPING)
      || (shippingMethod === ShippingMethod.DINE_IN && disableServiceCharge)) {
        orderEntity.setTaxOptions(taxOptions.filter(item => Number(item.taxType) !== 2));
      } else {
        orderEntity.setTaxOptions(taxOptions);
      }
    }
    orderEntity.setRoundingOptions(roundingOption);
    orderEntity.checkLineItemsByShippingMethod();
    orderEntity.calculateDiscount();
    orderEntity.calculate();

    OrderBuilder.storeIDRefOrderEntity[storeID] = orderEntity;

    return orderEntityToShoppingCartData(orderEntity);
  }

  static addToCartForPaths = (params: IAddToCartForPathsParams) => {
    const { paths } = params;
    const data = cloneDeep(paths);
    const lineItemInfo: ILineItemInfo = {
      listingID: '',
      modifiersInfos: [],
    };

    if (data && data.listingID) {
      lineItemInfo.listingID = data.listingID;
      lineItemInfo.modifiersInfos = [];
      Object.keys(data).forEach(item => {
        if (item === 'listingID') return;

        const paths = item.split('-');
        let tempLineItemInfo: IBaseInfo = lineItemInfo;
        let path = '';
        paths.forEach(pathID => {
          if (path.length > 0) {
            path = `${path}-${pathID}`;
          } else {
            path = pathID;
          }
          const node = findNode<IModifierInfo>(tempLineItemInfo.modifiersInfos, 'id', pathID);
          if (!node) {
            const tempNode: IModifierInfo = {
              id: pathID,
              modifiersInfos: [],
              quantity: data[path] || 1,
            }
            tempLineItemInfo.modifiersInfos.push(tempNode);
            tempLineItemInfo = tempNode;
          } else {
            tempLineItemInfo = node;
          }
        })
      })
    }

    return OrderBuilder.addToCart({
      ...omit(params, ['paths']),
      lineItemInfo,
    });
  }

  static deleteFromCart = (storeID: string) => {
    delete OrderBuilder.storeIDRefOrderEntity[storeID];
  }

  static clearOrderEntities = () => {
    OrderBuilder.storeIDRefOrderEntity = {};
  }

  static addLineItemQuantity = (storeID: string, uuid: string, quantity: number, taxOptions: ITaxOption[], roundingOptions: IRoundingOption, disableServiceCharge?: boolean) => {
    let shoppingCartData: any;
    const orderEntity = OrderBuilder.storeIDRefOrderEntity[storeID];
    if (orderEntity) {
      orderEntity.addLineItemQuantity(uuid, quantity);
      orderEntity.checkLineItemsByShippingMethod();
      orderEntity.lineItems.forEach(lineItem => {
        lineItem.resetTaxOptionEntries();
      })
      if((orderEntity.shippingMethod !== ShippingMethod.DINE_IN && orderEntity.shippingMethod !== ShippingMethod.QRCODE_SHOPPING)
      || (orderEntity.shippingMethod === ShippingMethod.DINE_IN && disableServiceCharge)) {
        orderEntity.setTaxOptions(taxOptions.filter(item => Number(item.taxType) !== 2));
      } else {
        orderEntity.setTaxOptions(taxOptions);
      }
      orderEntity.setRoundingOptions(roundingOptions)
      orderEntity.calculateDiscount();
      orderEntity.calculate();
      OrderBuilder.storeIDRefOrderEntity[storeID] = orderEntity;

      shoppingCartData = orderEntityToShoppingCartData(orderEntity);
    }

    return shoppingCartData;
  }

  static changeLineItemModifiers = (params: IChangeLineItemModifiersParams) => {
    const {
      storeID,
      listingID,
      listingUuids,
      taxOptions,
      shippingMethod,
      roundingOption,
      disableServiceCharge,
      modifierCountRef,
    } = params;

    const orderEntity = OrderBuilder.storeIDRefOrderEntity[storeID];
    const { lineItems } = orderEntity;
    const newlLneItems = cloneDeep(lineItems);
    let lineItem: LineItemEntity | undefined = undefined;
    const index = newlLneItems.findIndex(item => `${item.id}` === `${listingID}`);

    if (index !== -1) {
      lineItem = newlLneItems[index];
    }
    const listing = Data.getListing(storeID, listingID);
    if (lineItem && listing) {
      const data = cloneDeep(modifierCountRef);
      const lineItemInfo: ILineItemInfo = {
        listingID: listingID,
        modifiersInfos: [],
      };
      Object.keys(data).forEach(item => {
        const paths = item.split('-');
        let tempLineItemInfo: IBaseInfo = lineItemInfo;
        let path = '';
        paths.forEach(pathID => {
          if (path.length > 0) {
            path = `${path}-${pathID}`;
          } else {
            path = pathID;
          }
          if (data[path] === 0) return;
          const node = findNode<IModifierInfo>(tempLineItemInfo.modifiersInfos, 'id', pathID);

          if (!node) {
            const tempNode: IModifierInfo = {
              id: pathID,
              modifiersInfos: [],
              quantity: data[path],
            }
            tempLineItemInfo.modifiersInfos.push(tempNode);
            tempLineItemInfo = tempNode;
          } else {
            tempLineItemInfo = node;
          }
        })
      });

      orderEntity.removeLineItems(listingUuids);
      
      const listing = Data.getListing(storeID, lineItemInfo.listingID);

      if (listing) {
        orderEntity.addLineItem({
          lineItemInfo,
          listing,
          quantity: 1,
          storeID: storeID,
          taxOptions,
          roundingOption,
        });
      };

      if (taxOptions) {
        if ((shippingMethod !== ShippingMethod.DINE_IN && shippingMethod !== ShippingMethod.QRCODE_SHOPPING)
        || (shippingMethod === ShippingMethod.DINE_IN && disableServiceCharge)) {
          orderEntity.setTaxOptions(taxOptions.filter(item => Number(item.taxType) !== 2));
        } else {
          orderEntity.setTaxOptions(taxOptions);
        }
      }
      orderEntity.setRoundingOptions(roundingOption);
      orderEntity.checkLineItemsByShippingMethod();
      orderEntity.calculateDiscount();
      orderEntity.calculate();
  
      OrderBuilder.storeIDRefOrderEntity[storeID] = orderEntity;
      return orderEntityToShoppingCartData(orderEntity);
    }
  }

  static changeOrderEntity = (params: ISetDiscountValue) => {
    const { storeID } = params
    let shoppingCartData: any;
    const orderEntity = OrderBuilder.storeIDRefOrderEntity[storeID];
    if (orderEntity) {
      orderEntity.setSmartDiscountInfo(params);
      orderEntity.calculateDiscount();
      orderEntity.calculate();
      OrderBuilder.storeIDRefOrderEntity[storeID] = orderEntity;
      shoppingCartData = orderEntityToShoppingCartData(orderEntity);
    }
    return shoppingCartData
  }

  static minusLineItemQuantity = (storeID: string, uuid: string, quantity: number, taxOptions: ITaxOption[], roundingOptions: IRoundingOption, forExpressOrder?: boolean, disableServiceCharge?: boolean) => {
    let shoppingCartData: any;
    const orderEntity = OrderBuilder.storeIDRefOrderEntity[storeID];
    if (orderEntity) {
      orderEntity.minusLineItemQuantity(uuid, quantity, forExpressOrder);
      orderEntity.checkLineItemsByShippingMethod();
      if((orderEntity.shippingMethod !== ShippingMethod.DINE_IN && orderEntity.shippingMethod !== ShippingMethod.QRCODE_SHOPPING)
      || (orderEntity.shippingMethod === ShippingMethod.DINE_IN && disableServiceCharge)) {
        orderEntity.setTaxOptions(taxOptions.filter(item => Number(item.taxType) !== 2));
      } else {
        orderEntity.setTaxOptions(taxOptions);
      }
      orderEntity.setRoundingOptions(roundingOptions)
      orderEntity.calculateDiscount();
      orderEntity.calculate();

      OrderBuilder.storeIDRefOrderEntity[storeID] = orderEntity;

      shoppingCartData = orderEntityToShoppingCartData(orderEntity);
    }

    return shoppingCartData;
  }

  static changeShippingMethod = (params: IChangeShippingMethodParams) => {
    const { storeID, currencyCode, setShippingMethodParams, taxOptions, isSuperOrder, roundingOption, disableServiceCharge } = params;

    let shoppingCartData: any;
    let orderEntity = OrderBuilder.storeIDRefOrderEntity[storeID];

    if (!orderEntity) {
      orderEntity = new OrderEntity(storeID, isSuperOrder);
      if ((setShippingMethodParams.shippingMethod !== ShippingMethod.DINE_IN && setShippingMethodParams.shippingMethod !== ShippingMethod.QRCODE_SHOPPING)
      || (setShippingMethodParams.shippingMethod === ShippingMethod.DINE_IN && disableServiceCharge)) {
        orderEntity.setTaxOptions(taxOptions.filter(item => Number(item.taxType) !== 2));
      } else {
        orderEntity.setTaxOptions(taxOptions);
      }
      orderEntity.setRoundingOptions(roundingOption);
      orderEntity.setCurrencyCode(currencyCode);
    }


    if (orderEntity) {
      if ((setShippingMethodParams.shippingMethod !== ShippingMethod.DINE_IN && setShippingMethodParams.shippingMethod !== ShippingMethod.QRCODE_SHOPPING)
      || (setShippingMethodParams.shippingMethod === ShippingMethod.DINE_IN && disableServiceCharge)) {
        orderEntity.setTaxOptions(taxOptions.filter(item => Number(item.taxType) !== 2));
      } else {
        orderEntity.setTaxOptions(taxOptions);
      }
      orderEntity.lineItems.forEach(lineItem => {
        lineItem.resetTaxOptionEntries();
      })
      orderEntity.setRoundingOptions(roundingOption);
      orderEntity.setShippingMethod(setShippingMethodParams);
      orderEntity.checkLineItemsByShippingMethod();
      orderEntity.calculateDiscount();
      orderEntity.calculate();

      OrderBuilder.storeIDRefOrderEntity[storeID] = orderEntity;

      shoppingCartData = orderEntityToShoppingCartData(orderEntity);
    }

    return shoppingCartData;
  }

  static setOrderNoteAndDeliveryDate = (notes: IStoreShoppingCartNote, dates: IStoreShoppingCartDeliveryDate) => {
    const NoteKeys = Object.keys(notes);
    const DateKeys = Object.keys(dates);
    NoteKeys.forEach(item => {
      const orderEntity = OrderBuilder.storeIDRefOrderEntity[item];
      if (orderEntity) {
        orderEntity.note = notes[item];
      }
    })
    DateKeys.forEach(item => {
      const orderEntity = OrderBuilder.storeIDRefOrderEntity[item];
      if (orderEntity) {
        orderEntity.deliveryDate = dayjs(dates[item]);
      }
    })
  }

  static zeroLineItemsQuantity = (storeID?: string) => { // 有storeID是当前店铺，没有storeID是所有店铺
    let shoppingCartData: any;
    const allShoppingCartData: {[key: string]: any} = {};
    Object.keys(OrderBuilder.storeIDRefOrderEntity).forEach(key => {
      if ((storeID && key === storeID) || !storeID) {
        const orderEntity = OrderBuilder.storeIDRefOrderEntity[key];
        if (orderEntity) {
          orderEntity.zeroLineItemsQuantity();
          orderEntity.checkLineItemsByShippingMethod();
          orderEntity.calculateDiscount();
          orderEntity.calculate();
          OrderBuilder.storeIDRefOrderEntity[key] = orderEntity;
          if (storeID) {
            shoppingCartData = orderEntityToShoppingCartData(orderEntity);
          } else {
            const newShoppingCartData = orderEntityToShoppingCartData(orderEntity);
            allShoppingCartData[key] = newShoppingCartData;
            shoppingCartData = allShoppingCartData;
          }
        }
      }
    });

    return shoppingCartData;
  }
}

export default OrderBuilder;
