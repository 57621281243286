import React from 'react';
import { prefix } from '.';
import Icon, { IconType } from '@/components/Icon';

interface SkeletonProps {
  onLeftClick: () => void;
}

const Skeleton: React.FC<SkeletonProps> = props => {
  const { onLeftClick } = props;
  return (
    <div className={`${prefix}-skeleton`}>
      <div className="skeleton-icon">
        <Icon type={IconType.LEFT_OUTLINED} onClick={onLeftClick}/>
      </div>
      <div className="skeleton-div"></div>
      <div className="skeleton-content">
        <div className="skeleton-main">
          <div className="main-cri">
            <div className="main-left"></div>
            <div className="mian-right">
              <div className="mian-right1"></div>
              <div className="main-right2">
                <div className="main-right2-left"></div>
                <div className="main-right2-right">
                  <div className="main-right2-right1"></div>
                  <div className="main-right2-right2"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="mian-div">
            <div className="mian-div-left"></div>
            <div className="mian-div-right">
              <div className="mian-div-right-left"></div>
              <div className="mian-div-right-right"></div>
            </div>
          </div>
          <div className="mian-div">
            <div className="mian-div-left"></div>
            <div className="mian-div-right">
              <div className="mian-div-right-left"></div>
              <div className="mian-div-right-right"></div>
            </div>
          </div>
          <div className="mian-div">
            <div className="mian-div-left"></div>
            <div className="mian-div-right">
              <div className="mian-div-right-left"></div>
              <div className="mian-div-right-right"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="skeleton-footer">
        <div className="skeleton-footer-icon">
          <div className="icon-item"></div>
          <div className="icon-item icon-item-title"></div>
        </div>
        <div className="skeleton-footer-icon">
          <div className="icon-item"></div>
          <div className="icon-item icon-item-title"></div>
        </div>
        <div className="skeleton-footer-icon">
          <div className="icon-item"></div>
          <div className="icon-item icon-item-title"></div>
        </div>
      </div>
      <div className="skeleton-footer-line"></div>
    </div>
  )
}
export default Skeleton;
