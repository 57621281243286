import { takeEvery, put, select, call, take, race, fork, all } from 'redux-saga/effects';
import axios from 'axios';
import md5 from 'blueimp-md5';
import Config from '@/Config';
import Action from '@/actions';
import ActionType from '@/actions/action-type';
import { IReducersState } from '@/reducers';
import { IUserState } from '@/reducers/user-reducer';
import {
  signIn,
  getGalaxyMe,
  signUp,
} from '@/data/sign';
import {
  ISignIn,
  IUserInfo,
  IQueryCustomer,
  ICustomer,
  ISignUpForGuest,
  IUpdataCustomer,
  IQueryCustomerGrowth,
  ICustomerGrowth,
  IQueryAddress,
  IAddress,
  IAddAddress,
  IDeleteAddress,
  IEditAddress,
  IQueryCustomerPromotion,
  ICustomerPromotion,
  IDevice,
  ISetNotification,
  IQueryAllPromotion,
  IRedeemPromotion,
} from '@/actions/user-action';
import Constants from '@/constants';
import { parseResCustomerMembership } from '@/utils/store-util';
import { IAppState } from '@/reducers/app-reducer';
import { getDeviceID, getIntl } from '@/components/App/App';
import AccountOption from '@/actions/account-option';
import Modal from 'antd-mobile/es/modal';
import paths from '@/routes/paths';
import { IPlatformConfig } from '@/actions/store-action';
import { removeAccessToken } from '@/utils/app';
import { resizeImage } from '@/utils';

export function* handleSignIn(params: ISignIn) {
  const {
    username,
    password,
    loginMethod = 'phone',
    grantType = 'password',
    platformID,
    callback,
  } = params;
  let userInfo: IUserInfo | undefined = undefined;
  let success = false;
  try {
    const user = yield signIn({
      username,
      password: password,
      loginMethod,
      grantType,
      platformID,
    });

    if (!user.error) {
      yield put<Action>({
        type: ActionType.CLEAR_CUSTOMER,
      });
      yield put<Action>({
        type: ActionType.CLEAR_CUSTOMER_GROWTH,
      });

      userInfo = yield getGalaxyMe();
      if (userInfo) {

        userInfo.avatarUrl = (userInfo.avatarUrl).replace(/\s+/g, '%20');

        const appState: IAppState = yield select((state: IReducersState) => state.app);
        const { deviceInfo } = appState;
        let { deviceID } = deviceInfo;
        if ( userInfo.id ) {
          if ( deviceInfo && !deviceID ) {
            yield put<Action>({
              type: ActionType.SET_APP_CONFIG_DEVICEINFO,
              deviceInfo: { ...deviceInfo, deviceID: getDeviceID}
            });
            deviceID = getDeviceID;
          }
          yield call(
            axios.put,
            Config.urls.deviceStatus.replace('{userID}', userInfo.id).replace('{deviceID}', deviceID),
          );
        }
        const state: IReducersState = yield select((state: IReducersState) => state);
        const { platformConfig } = state.app;
        const { accountOptions } = platformConfig;
        if (accountOptions.includes(AccountOption.PRIVILEGES)) {
          yield handleQueryCustomerPromotion({
            type: ActionType.QUERY_CUSTOMER_PROMOTION,
            platformID: platformConfig.platformID,
            storeSlug: platformConfig.storeSlug,
            perPage: 100,
            page: 1,
            available: 1,
          });
        }
        yield put<Action>({
          type: ActionType.SET_USER_INFO,
          userInfo,
        });
        localStorage.setItem(Constants.USER_INFO, JSON.stringify(userInfo));
      }
      success = true;
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(success, userInfo);
  }

  return userInfo;
}

export function* handleQueryCustomer(params: IQueryCustomer) {
  const { storeSlug, callback, closeRegisterEmail = false, suppliers = false } = params;
  let newCustomer: ICustomer | undefined = undefined;
  let status = 200;
  try {
    const userState: IUserState = yield select((state: IReducersState) => state.user);

    if (userState.slugRefCustomer[storeSlug]) {
      newCustomer = userState.slugRefCustomer[storeSlug];
    } else {
      let url = `${Config.urls.customer.replace('{storeSlug}', storeSlug)}?close_register_mail=${closeRegisterEmail}`;
      if (suppliers) {
        const buyerStoreID = localStorage.getItem(Constants.SUPPLIERS_SELECT_STORE_ID);
        url = `${Config.urls.customer.replace('{storeSlug}', storeSlug)}?source_type=Store&source_id=${buyerStoreID}&close_register_mail=${closeRegisterEmail}`
      }

      const res = yield call(
        axios.get,
        url,
      );
      const { customer } = res;
      if (customer) {
        newCustomer = {
          id: customer.id,
          name: customer.name,
          phone: customer.phone,
          email: customer.email,
          storeID: customer.store_id,
          storeSlug,
          membershipLevelID: customer.membership_level_id,
          customerCode: customer.customer_code,
          allowCompanyAccount: customer.allow_company_house_account,
        };
        yield handlePushNotification({
          type: ActionType.PUSH_NOTIFICATION,
          customerID: customer.id,
        });
        yield put<Action>({
          type: ActionType.SET_CUSTOMER,
          storeSlug,
          customer: newCustomer,
        });
      } else if (res && res.status === 401) {
        status = 401;
        yield put<Action>({
          type: ActionType.CANCEL_QUERY_CUSTOMER_GROWTH_TASK_SYNC,
        });
      }
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(newCustomer);
  }

  return {
    customer: newCustomer,
    status,
  };
}

export function* handleQueryCustomerGrowth(params: IQueryCustomerGrowth) {
  const { storeID, storeSlug, customerID, callback } = params;
  let loyaltyStamp: ICustomerGrowth | undefined = undefined;
  let status = 200;
  
  try {
    const res = yield call(
      axios.get,
      Config.urls.customerGrowth.replace('{storeID}', storeID).replace('{customerID}', customerID),
    );

    if (res && res.growthValues) {
      loyaltyStamp = parseResCustomerMembership(res.growthValues);
      if (loyaltyStamp) {
        yield put<Action>({
          type: ActionType.SET_CUSTOMER_GROWTH,
          storeSlug,
          storeID,
          loyaltyStamp,
        });
      }
    } else if (res && res.status === 401) {
      status = 401;
      yield put<Action>({
        type: ActionType.CANCEL_QUERY_CUSTOMER_GROWTH_TASK_SYNC,
      });
    } else if (res && res.status === 500) {
      status = 500;
      removeAccessToken();
      localStorage.removeItem(Constants.USER_INFO);
      localStorage.removeItem(Constants.IS_GUEST);
      localStorage.removeItem(Constants.PRIVILEGE_NUMBER);
      
      const tips = getIntl().page.tips;
      const loginFailed = getIntl().page.loginFailed;

      Modal.alert('', tips, [
        {
          text: loginFailed,
          onPress: () => {
            window.location.href=paths.SIGN_IN;
          },
        }
      ]);
      yield put<Action>({
        type: ActionType.CANCEL_QUERY_CUSTOMER_GROWTH_TASK_SYNC,
      });
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(loyaltyStamp);
  }

  return {
    loyaltyStamp,
    status,
  };
}

const delay = (millis: number) => new Promise(resolve => setTimeout(resolve, millis));

function* runCustomerGrowthTask() {
  while(true) {
    try {
      const state: IReducersState = yield select((state: IReducersState) => state);
      const { platformConfig } = state.app;
      const { storeID, storeSlug, accountOptions, closeRegisterEmail } = platformConfig;
      const queryCustomerResult: any = yield handleQueryCustomer({
        type: ActionType.QUERY_CUSTOMER,
        storeSlug,
        closeRegisterEmail,
      });
      const customer = queryCustomerResult.customer;
      if (customer) {
        const req: any = [];
        req.push(handleQueryCustomerGrowth({
          type: ActionType.QUERY_CUSTOMER_GROWTH,
          storeID,
          storeSlug,
          customerID: customer.id,
          updateLoyaltyStamp: true,
        }))
        if (accountOptions.includes(AccountOption.PRIVILEGES)) {
          req.push(handleQueryCustomerPromotion({
            type: ActionType.QUERY_CUSTOMER_PROMOTION,
            platformID: platformConfig.platformID,
            storeSlug: platformConfig.storeSlug,
            perPage: 100,
            page: 1,
            available: 1,
          }))
        }
        if (req.length > 0) {
          yield all(req);
        }
      }
      yield call(delay, 10000);
    } catch (error) {
      return;
    }
  }
}

export function* watchCustomerGrowthTask() {
  while (true) {
    yield take(ActionType.START_QUERY_CUSTOMER_GROWTH_TASK_SYNC);
    yield race([
      call(runCustomerGrowthTask),
      take(ActionType.CANCEL_QUERY_CUSTOMER_GROWTH_TASK_SYNC),
    ]);
  }
}

export function* handleSignUpForGuest(params: ISignUpForGuest) {
  const { dialCode, phoneNumber, callback } = params;
  let success = false;
  const phone = `+${dialCode} ${phoneNumber}`
  const password = md5(`${phone}${Date.now()}`);
  const fullName = `${dialCode} ${phoneNumber}`;
  const userEmail = `phone-bindo-${password}-${dialCode}-${phoneNumber}@HKT.com`;

  try {
    success = yield signUp({
      phone,
      password,
      fullName: fullName,
      userName: phone,
      userEmail,
      registerType: 1,
    });
  } catch (error) {
    console.error(error);
  }

  const resultParams = {
    success,
    dialCode,
    phoneNumber,
    password,
    userEmail
  };

  if (callback) {
    callback(resultParams);
  }

  return resultParams;
}

export function* handleUpdataCustomer(params: IUpdataCustomer){
  const {
    storeSlug,
    name,
    phone,
    email,
    callback,
  } = params;

  let customer: ICustomer | undefined = undefined;

  try {
    const res = yield axios({
      method: 'put',
      url: Config.urls.customer.replace('{storeSlug}', storeSlug),
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        customer: {
          name,
          email,
          phone,
        }
      }
    });

    if (res && res.customer) {
      customer = {
        id: res.customer.id,
        name: res.customer.name,
        phone: res.customer.phone,
        email: res.customer.email,
        storeID: res.customer.store_id,
        storeSlug,
        membershipLevelID: res.customer.membership_level_id,
        customerCode: res.customer.customer_code,
        allowCompanyAccount: res.allow_company_house_account,
      };
      yield put<Action>({
        type: ActionType.SET_CUSTOMER,
        storeSlug,
        customer,
      });
    }
  } catch (error) {
    console.error(error);
  }
  if (callback) {
    callback(customer);
  }

  return customer;
}

export function* handleQueryAddress(params: IQueryAddress){
  const {
    callback,
  } = params;

  let addressList: IAddress[] = [];

  try {
    const userState: IUserState = yield select((state: IReducersState) => state.user);
    if (userState.address.length > 0) {
      addressList = userState.address;
    } else {
      const res = yield call(
        axios.get,
        Config.urls.address,
      );
      if (res && res.data && Array.isArray(res.data.addresses)) {
        res.data.addresses.forEach((item: any) => {
          addressList.unshift({
            id: item.id || '',
            phone: item.phone || '',
            city: item.city || '',
            name: item.full_name || '',
            address1: item.address1.split(' ')[0] || '',
            lat: item.lat || '',
            lng: item.lng || '',
          });
        })
        yield put<Action>({
          type: ActionType.SET_ADDRESS,
          address: addressList,
        });
      }
    }
  } catch (error) {
    console.error(error);
  }
  if (callback) {
    callback(addressList);
  }

  return addressList;
}

export function* handleAddAddress(params: IAddAddress){
  const { name, phone, city, address, lat, lng, callback } = params;

  let newAddress: IAddress | undefined;

  const res = yield call(
    axios.post,
    Config.urls.address,
    {
      'full_name': name,
      phone,
      city,
      address1: address,
      lat,
      lng,
    },
  );


  if (res && res.data && res.data.address) {
    const item = res.data.address;
    newAddress = {
      id: item.id || '',
      phone: item.phone || '',
      city: item.city || '',
      name: item.full_name || '',
      address1: item.address1 || '',
      lat: item.lat || '',
      lng: item.lng || '',
    };
  }

  if (newAddress) {
    yield put<Action>({
      type: ActionType.PUSH_ADDRESS,
      address: newAddress,
    });
  }


  if (callback) {
    callback(newAddress);
  }
}

function* handleDeleteAddress(params: IDeleteAddress) {
  const { addressID, callback } = params;
  let address: IAddress | undefined;

  try {
    if (addressID) {
      yield call(
        axios.delete,
        `${Config.urls.address}/${addressID}`,
      );
      yield put<Action>({
        type: ActionType.REMOVE_ADDRESS,
        addressID,
      });
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(address);
  }
}

function* handleEditAddress(params: IEditAddress){
  const { name, phone, city, address, lat, lng, addressID, callback } = params;

  let newAddress: IAddress | undefined;

  const res = yield call(
    axios.put,
    `${Config.urls.address}/${addressID}`,
    {
      'full_name': name,
      phone,
      city,
      address1: address,
      lat,
      lng,
    },
  );


  if (res && res.data && res.data.address) {
    const item = res.data.address;
    newAddress = {
      id: item.id || '',
      phone: item.phone || '',
      city: item.city || '',
      name: item.full_name || '',
      address1: item.address1 || '',
      lat: item.lat || '',
      lng: item.lng || '',
    };
  }

  if (newAddress) {
    yield put<Action>({
      type: ActionType.MODIFY_ADDRESS,
      address: newAddress,
    });
  }

  if (callback) {
    callback(newAddress);
  }
}

export function* handleQueryCustomerPromotion(params: IQueryCustomerPromotion){
  const {
    platformID,
    available,
    storeSlug,
    callback,
    perPage,
  } = params;
  let { page } = params;
  let lastPage = false;
  if (page < 1) page = 1;
  let promotions: ICustomerPromotion[] = [];
  const userState: IUserState = yield select((state: IReducersState) => state.user);
  const slugRefCustomerPromotion = userState.slugRefCustomerPromotion[available] || [];
  const isExit = slugRefCustomerPromotion.length < ((page - 1) * 10 + 1);
  let storeCustomer: ICustomer | undefined = undefined;
  const platformConfig: IPlatformConfig = yield select((state: IReducersState) => state.app.platformConfig);

  try {
    if (isExit) {
      const queryCustomerResult: any = yield handleQueryCustomer({
        type: ActionType.QUERY_CUSTOMER,
        storeSlug,
        closeRegisterEmail: platformConfig.closeRegisterEmail,
      });
      storeCustomer = queryCustomerResult.customer;
      if (storeCustomer) {
        const res = yield call(
          axios.get,
          `${Config.urls.customerPromotion.replace('{customerID}', storeCustomer.id).replace('{platformID}', platformID)}&available=${available}&page=${page}${perPage? '&per_page='+perPage: ''}`,
        );
        if (perPage && res) {
          if (res.length === 0 && localStorage.getItem(Constants.PRIVILEGE_NUMBER)) {
            localStorage.removeItem(Constants.PRIVILEGE_NUMBER);
          } else if (res.length >0) {
            if (Number(localStorage.getItem(Constants.PRIVILEGE_NUMBER)) !== res.length || !localStorage.getItem(Constants.PRIVILEGE_NUMBER)) {
              localStorage.setItem(Constants.PRIVILEGE_NUMBER, res.length);
            }
          }
        }
        if (res && !perPage) {
          res.forEach((item: any) => {
            if (item.promotion_code && item.discount && item.customer_promotion) {
              const promotionCodeCon = item.promotion_code;
              const promotion = {
                promotionCodeID: promotionCodeCon.id,
                promotionCode: promotionCodeCon.promotion_code,
                createdAt: promotionCodeCon.created_at,
                expiredAt: promotionCodeCon.expired_at,
                status: promotionCodeCon.status,
                points: promotionCodeCon.score ? Number(promotionCodeCon.score) : 0,
                storeCredit: promotionCodeCon.tgg_amount ? Number(promotionCodeCon.tgg_amount) : 0,
                // lastRedeemedAt: item.customer_promotion.last_redeemed_at,
                // notes: item.discount.notes,
                descriptionName: promotionCodeCon.description,
                img: resizeImage({
                  url: promotionCodeCon.activities_image,
                  type: 'FILL',
                  width: 750,
                  height: 750,
                }),
                i18n: promotionCodeCon.translation_info || {},
                activityName: promotionCodeCon.activity_name,
              }
              promotions.push(promotion);
            }
          });
          if (res.length < 1) {
            lastPage = true;
          }
          yield put<Action>({
            type: ActionType.SET_CUSTOMER_PROMOTION,
            available,
            promotions,
          });
        }

      }
    } else {
      promotions = userState.slugRefCustomerPromotion[storeSlug];
    }
  } catch (error) {
    console.error(error);
  }
  if (callback) {
    callback(promotions, page, lastPage);
  }

  return promotions;
}

export function* handleQueryAllPromotion(params: IQueryAllPromotion){
  const {
    platformID,
    callback,
    storeID,
  } = params;
  let { page } = params;
  let lastPage = false;
  if (page < 1) page = 1;
  let promotions: ICustomerPromotion[] = [];
  const userState: IUserState = yield select((state: IReducersState) => state.user);
  const platformConfig: IPlatformConfig = yield select((state: IReducersState) => state.app.platformConfig);
  const { storeSlug } = platformConfig;

  const slugRefAllPromotion = userState.slugRefAllPromotion[storeSlug] || [];
  const isExit = slugRefAllPromotion.length < ((page - 1) * 10 + 1);
  
  try {
    if (isExit) {
      const res = yield call(
        axios.get,
        `${Config.urls.queryPromotions.replace('{storeID}', storeID).replace('{platformID}', platformID)}&page=${page}`,
      );
      if (Array.isArray(res.data)) {
        res.data.forEach((item: any) => {
          const promotionCodeCon = item.promotion_code;
          const promotion: ICustomerPromotion  = {
            promotionCodeID: promotionCodeCon.id,
            promotionCode: '',
            status: 'created',
            img: resizeImage({
              url: promotionCodeCon.image,
              type: 'FILL',
              width: 750,
              height: 750,
            }),
            points: promotionCodeCon.score ? Number(promotionCodeCon.score) : 0,
            storeCredit: promotionCodeCon.tgg_amount ? Number(promotionCodeCon.tgg_amount) : 0,
            activityName: promotionCodeCon.name,
            createdAt: promotionCodeCon.start_at,
            expiredAt: promotionCodeCon.end_at,
            descriptionName: promotionCodeCon.description,
            i18n: promotionCodeCon.translation_info || {},
          }
          promotions.push(promotion);
        })
        if (res.data.length < 1) {
          lastPage = true;
        }
        yield put<Action>({
          type: ActionType.SET_ALL_PROMOTION,
          storeSlug,
          promotions,
        });
      }

    } else {
      promotions = userState.slugRefAllPromotion[storeSlug];
    }
  } catch (error) {
    console.error(error);
  }
  if (callback) {
    callback(promotions, page, lastPage);
  }

  return promotions;
}

export function* handleRedeemPromotion(params: IRedeemPromotion){
  const state: IReducersState = yield select((state: IReducersState) => state);
  const {
    customerID,
    callback,
    couponID,
    storeID,
    platformID,
  } = params;
  let message;
  try {
    const res = yield call(
      axios.post,
      `${Config.urls.redeemPromotions.replace('{storeID}', storeID).replace('{customerID}', customerID)}`,
      {
        'exchange_coupons_id': couponID,
        'platform_id': platformID,
      },
      {
        headers: {
          'X-I18N-LANG': state.app.language,
        }
      }
    );
    if (res && res.message) {
      message = res.message
    }
  } catch (error) {
    console.error(error);
  }
  if (callback) {
    callback(message);
  }

  return message;
}

export function* handleDevice(params: IDevice){
  const { userID, deviceID} = params;
  try {
    yield call(
      axios.delete,
      Config.urls.deviceStatus.replace('{userID}', userID).replace('{deviceID}', deviceID),
    );
  } catch (error) {
    console.error(error);
  }
}

export function* handlePushNotification(params: ISetNotification){
  const state: IReducersState = yield select((state: IReducersState) => state);
  const { platformAppSettings, language, deviceInfo } = state.app;
  const { customerID } = params;
  try {
    if (platformAppSettings && deviceInfo.isNativeApp) {
      yield call(
        axios.post,
        Config.urls.postNotificationPlatform.replace('{storeID}', platformAppSettings && platformAppSettings.storeID),
        {
          'app_name': platformAppSettings && platformAppSettings.name,
          'app_version': Config.userAppVersion,
          'customer_id': customerID,
          language,
          'device_id': deviceInfo.deviceID,
          'mobile_type': deviceInfo.isNativeApp && deviceInfo.isAppleApp ? 'ios' : 'android',
          'push_token': deviceInfo.devicePushToken,
        }
      );
    }
  } catch (error) {
    console.error(error);
  }
}

function* userSaga() {
  yield takeEvery(ActionType.SIGN_IN, handleSignIn);
  yield takeEvery(ActionType.QUERY_CUSTOMER, handleQueryCustomer);
  yield takeEvery(ActionType.SIGN_UP_FOR_GUEST, handleSignUpForGuest);
  yield takeEvery(ActionType.UPDATE_CUSTOMER, handleUpdataCustomer);
  yield takeEvery(ActionType.QUERY_CUSTOMER_GROWTH, handleQueryCustomerGrowth);
  yield takeEvery(ActionType.QUERY_ALL_PROMOTION, handleQueryAllPromotion);
  yield takeEvery(ActionType.REDEEM_PROMOTION, handleRedeemPromotion);
  yield takeEvery(ActionType.QUERY_ADDRESS, handleQueryAddress);
  yield takeEvery(ActionType.ADD_ADDRESS, handleAddAddress);
  yield takeEvery(ActionType.DELETE_ADDRESS, handleDeleteAddress);
  yield takeEvery(ActionType.EDIT_ADDRESS, handleEditAddress);
  yield takeEvery(ActionType.QUERY_CUSTOMER_PROMOTION, handleQueryCustomerPromotion);
  yield takeEvery(ActionType.DELETE_DEVICE, handleDevice);
  yield takeEvery(ActionType.PUSH_NOTIFICATION, handlePushNotification);
  yield fork(watchCustomerGrowthTask);
}

export default userSaga();
