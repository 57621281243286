import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import { prefix } from '.';
import ActionType from '@/actions/action-type';
import StoreList from '@/components/StoreList';
import { getAppTheme, setScreenColor, getIntl, getIntlByPath } from '@/components/App/App';
import { IStore } from '@/actions/store-action';
import paths from '@/routes/paths';
import Icon, { IconType } from '@/components/Icon';
import Status from '@/components/Status';
import CategoryTypes from './CategoryTypes';
import { animateScroll } from 'react-scroll';
import { handleStoreServiceTypesScroll } from '@/utils/app';
import { setCanAutoProcurementMethodPopup } from '../MenuListPage/MenuListPage';
import { parseRouteParams, getLocalStorageJson, setLocalStorageJson } from '@/utils';
import Constants from '@/constants';
import { setUseLocalCategoryID } from '../brand-list/BrandListPage/BrandListPage';
import StoreFilterModal from '@/components/StoreFilterModal';
import { StoreFilterModalContentType, StoreFilterMode } from '@/actions/store-action';
import { handleStoreCategoryTypesScroll } from '@/utils/app';
import Skeleton from './Skeleton';
import Layout from '@/components/Layout/Layout';

export interface IStoresByCategoryPageProps extends IBasePageProps {
}

export interface IStoresByCategoryPageState {
  pageInfo: {[categoryID: string]: {page: number; lastPage: boolean}};
  stores?: IStore[];
  loadingStores: boolean;
  loadingFirstPage: boolean;
  showQRCodeScan: boolean;
  selectedCategoryID: string;
  byType: string;
  fromPage: string;
  showModal: boolean;
  mode: string;
}

let useLocal = false;

export const setUseLocalBrandID = (use: boolean) => {
  useLocal = use || false;
}

class StoresByCategoryPage extends BasePage<IStoresByCategoryPageProps, IStoresByCategoryPageState> {
  loadingData = false;
  lastPage = false;
  selectedCategoryID = getLocalStorageJson(Constants.BRANDID) || '0';

  constructor(props: IStoresByCategoryPageProps) {
    super(props);
    const routeParams = parseRouteParams(props);

    this.state = {
      pageInfo: {},
      loadingStores: true,
      loadingFirstPage: true,
      showQRCodeScan: false,
      selectedCategoryID: useLocal ? this.selectedCategoryID : routeParams.brandID || '',
      fromPage: routeParams.fromPage || '',
      byType: routeParams.byType || 'all',
      showModal: false,
      mode: '',
    }
  }

  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.darkBackgroundColor) {
      setScreenColor(appTheme.darkBackgroundColor);
    }

    super.componentDidMount();

    const { dispatch } = this.props;
    const { pageInfo, selectedCategoryID, byType } = this.state;
    const { page = 1 } = pageInfo[selectedCategoryID] || {};
    const pageCategoryType = this.getPageCategoryType();

    dispatch({
      type: ActionType.QUERY_STORES_BY_CATEGORY,
      categoryID: Number(selectedCategoryID) === 0 ? '' : selectedCategoryID,
      page,
      pageType: pageCategoryType,
      storeType: byType,
      callback: () => {
        this.setState({loadingStores: false, loadingFirstPage: false, mode: ''});
        this.closeLoading();
      }
    });

    handleStoreServiceTypesScroll(selectedCategoryID);

    const pageWrap = window.document.querySelector('.page-content-wrapper-web');
    if (pageWrap) {
      pageWrap.addEventListener('scroll', this.handleScroll, { passive: true });
    } else {
      window.addEventListener('scroll', this.handleScroll, { passive: true });
    }
  }
  
  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleScroll);
    setUseLocalBrandID(false);
    const pageWrap = window.document.querySelector('.page-content-wrapper-web');
    if (pageWrap) {
      pageWrap.removeEventListener('scroll', this.handleScroll);
    } else {
      window.removeEventListener('scroll', this.handleScroll);
    }
  }

  getScrollY = () => {
    const pageWrap = window.document.querySelector('.page-content-wrapper-web');
    let scrollY = window.scrollY;
    if (pageWrap) {
      scrollY = pageWrap.scrollTop;
    }

    return scrollY;
  }

  handleScroll = () => {
    const { pageInfo, selectedCategoryID, mode, byType } = this.state;
    const newCategoryID = Number(selectedCategoryID) === 0 ? '' : selectedCategoryID;

    const { page = 1 } = pageInfo[newCategoryID] || {};
    const { dispatch } = this.props;
    if (!this.lastPage && !this.loadingData) {
      const appPages = document.getElementsByClassName('bm-p-stores-by-category');
      if (appPages && appPages.length) {
        const appPage = appPages[0];
        const scrollHeight = appPage.scrollHeight;
        const canScrollHeight = scrollHeight - window.innerHeight;

        if (canScrollHeight - this.getScrollY() < 5 * 200) {
          this.loadingData = true;
          this.setState({loadingStores: true});
          if (mode === 'BYFILTER') {
            dispatch({
              type: ActionType.QUERY_STORES_BY_FILTER,
              mode: StoreFilterMode.CATEGORY,
              categoryID: newCategoryID,
              page: page + 1,
              storeType: byType,
              callback: (_stores, page, lastPage) => {
                this.loadingData = false;
                this.lastPage = lastPage;
                this.setState({
                  loadingStores: false,
                  pageInfo: {
                    ...pageInfo,
                    [newCategoryID || '']: {
                      page,
                      lastPage,
                    }
                  },
                  mode: 'BYFILTER',
                });

                this.closeLoading();
                dispatch({
                  type: ActionType.LOADING,
                  showLoading: false,
                });
              }
            });
          } else {
            const pageCategoryType = this.getPageCategoryType();
            dispatch({
              type: ActionType.QUERY_STORES_BY_CATEGORY,
              page: page + 1,
              categoryID: newCategoryID,
              pageType: pageCategoryType,
              storeType: byType,
              callback: (_stores, page, lastPage) => {
                this.loadingData = false;
                this.lastPage = lastPage;
                this.setState({
                  loadingStores: false,
                  pageInfo: {
                    ...pageInfo,
                    [newCategoryID]: {
                      page,
                      lastPage,
                    }
                  },
                });
              }
            });
          }
        }
      }
    }
  }

  handleItemClick = (data: IStore) => {
    const { byType, selectedCategoryID } = this.state;
    if (data.isGroup) {
      this.handleGotoRoute({
        path: paths.STORE_GROUP,
        pathParams: {
          storeSlug: data.slug,
        },
      });
    } else {
      let path = paths.MENU_LIST
      if (byType === 'booking') {
        path = paths.BOOKING_NEW;
      } else if (byType === 'queueing') {
        path = paths.QUEUEING_NEW;
      }

      setCanAutoProcurementMethodPopup(true);
      setLocalStorageJson(Constants.BRANDID, selectedCategoryID);
      this.handleGotoRoute({
        path,
        pathParams: {
          storeSlug: data.slug,
        },
      });
    }
  }

  handleCategoryClick = (categoryID: string, isQuery = true) => {
    const { pageInfo, selectedCategoryID, byType } = this.state;
    const { dispatch } = this.props;

    if (selectedCategoryID !== categoryID) {
      animateScroll.scrollTo(0, {
        duration: 200,
      });
      this.setState({ selectedCategoryID: categoryID })
    } else { return }

    if (isQuery) {
      this.loadingData = true;
      this.lastPage = false;
      const newCategoryID = Number(categoryID) === 0 ? '' : categoryID;

      this.setState({
        loadingStores: true,
        pageInfo: {
          ...pageInfo,
          [categoryID]: {
            lastPage: false,
            page: 1,
          }
        },
      });
      const pageCategoryType = this.getPageCategoryType();
      setLocalStorageJson(Constants.BRANDID, categoryID);
      dispatch({
        type: ActionType.QUERY_STORES_BY_CATEGORY,
        page: 1,
        categoryID: newCategoryID,
        pageType: pageCategoryType,
        storeType: byType,
        callback: (_stores, page, lastPage) => {
          this.loadingData = false;
          this.lastPage = lastPage;
          this.setState({
            loadingStores: false,
            pageInfo: {
              ...pageInfo,
              [newCategoryID]: {
                page,
                lastPage,
              }
            },
            mode: '',
          });
        }
      });
    }
    if (Number(categoryID) === 0) {
      const { initData } = this.handleStoreFilterInitData();
      if (initData && initData[StoreFilterModalContentType.TYPE]) {
        if (initData[StoreFilterModalContentType.TYPE] === 'brands') {
          dispatch({
            type: ActionType.SET_STORES_FILTER,
            params: [
              {
                // category: new Set(),
                brands: new Set(),
              }
            ]
          });
        } else {
          dispatch({
            type: ActionType.SET_STORES_FILTER,
            params: [
              {
                category: new Set(),
                // brands: new Set(),
              }
            ]
          });
        }
      }
    }
  }

  handleStoreFilter = () => {
    this.loadingData = true;
    this.lastPage = false;

    const { selectedCategoryID, pageInfo, byType } = this.state;

    this.setState({
      loadingStores: true,
    });


    let newCategoryID = Number(selectedCategoryID) === 0 ? '' : selectedCategoryID;

    const { dispatch, reducersState } = this.props;
    const { filterRefStores } = reducersState.store;
    const { initData } = this.handleStoreFilterInitData();
    const { brands, category } = filterRefStores;

    if (initData && initData[StoreFilterModalContentType.TYPE]) {
      if (initData[StoreFilterModalContentType.TYPE] === 'brands') {
        if (brands.size === 1) {
          newCategoryID = [...brands][0];
        } else {
          newCategoryID = '';
        }
      } else {
        if (category.size === 1) {
          newCategoryID = [...category][0];
        } else {
          newCategoryID = '';
        }
      }
    }

    dispatch({
      type: ActionType.LOADING,
      showLoading: true,
    });

    dispatch({
      type: ActionType.QUERY_STORES_BY_FILTER,
      mode: StoreFilterMode.CATEGORY,
      categoryID: newCategoryID,
      replace: true,
      page: 1,
      storeType: byType,
      callback: (_stores, page, lastPage) => {
        this.loadingData = false;
        this.lastPage = lastPage;
        this.setState({
          loadingStores: false,
          pageInfo: {
            ...pageInfo,
            [newCategoryID]: {
              page,
              lastPage,
            }
          },
          mode: 'BYFILTER',
        });
        if (initData && initData[StoreFilterModalContentType.TYPE]) {
          this.handleCategoryClick(newCategoryID || '0', false);
          handleStoreCategoryTypesScroll(newCategoryID || '0');
        }
        this.closeLoading();
        dispatch({
          type: ActionType.LOADING,
          showLoading: false,
        });
      }
    });
  }

  handleStoreFilterInitData = () => {
    const {
      fromPage,
      byType,
      selectedCategoryID,
    } = this.state;

    const newCategoryID = Number(selectedCategoryID) === 0 ? '' : selectedCategoryID;
    let initData;
    let typeData = '';
    if (fromPage === 'brands') {
      if (byType === 'booking') {
        typeData = 'booking';
      } else if (byType === 'food_ordering') {
        typeData = 'food_ordering';
      } else if (byType === 'queueing') {
        typeData = 'queueing';
      } else {
        typeData = 'brands';
      }
      initData = {
        [StoreFilterModalContentType.TYPE]: typeData,
        categoryID: newCategoryID
      }
    }
    return { initData };
  }

  getPageCategoryType = (): string => {
    const { fromPage, byType } = this.state;
    let resString = '';
    if (fromPage === 'brands') {
      if (
        byType !== 'booking' &&
        byType !== 'food_ordering' &&
        byType !== 'queueing'
      )
      {
        resString = 'brands';
      }
    }
    return resString;
  }

  getStoreFilterMark = () => {
    let markNumber = 0;
    const { reducersState } = this.props;
    const { filterRefStores } = reducersState.store;
    if (filterRefStores.category.size > 0) { markNumber++ }
    if (filterRefStores.brands.size > 0) { markNumber++ }
    if (filterRefStores.district.size > 0) { markNumber++ }
    if (filterRefStores.prices.length > 0) { markNumber++ }
    if (filterRefStores.radius > 0) { markNumber++ }

    return markNumber;
  }

  render() {
    const { reducersState, routeParams } = this.props;
    const {
      loadingStores,
      loadingFirstPage,
      pageInfo,
      selectedCategoryID,
      fromPage,
      byType,
      showModal,
    } = this.state;
    const { platformConfig, platformStoreBrands } = reducersState.app;
    const { typeRefStoresByCategory } = reducersState.store;
    const newCategoryID = Number(selectedCategoryID) === 0 ? '' : selectedCategoryID;
    const stores = typeRefStoresByCategory[byType] ? typeRefStoresByCategory[byType][newCategoryID] || [] : [];
    const { lastPage = false } = pageInfo[newCategoryID] || {};
    const hasStores = stores.length > 0;
    const { validServiceType = [] } = platformConfig;
    let title = 'page.categoryTitle';
    if (fromPage === 'brands') {
      if (byType === 'booking') {
        title = 'page.booking';
      } else if (byType === 'food_ordering') {
        title = 'page.foodOrdering';
      } else if (byType === 'queueing') {
        title = 'page.queueing';
      } else {
        title = 'page.brands';
      }
    }

    const markNumber = this.getStoreFilterMark();

    return (
      <Layout
        {...this.props}
        className={`bm-p-stores ${prefix} ${validServiceType.length > 1 ? 'has-service-types' : ''}`}
        navContent={<span>{getIntlByPath(title)}</span>}
        navIcon={routeParams.mode !== 'dedicated' && <Icon className="html-embed-7 w-embed" type={IconType.LEFT_OUTLINED} />}
        onLeftClick={() => {
          if (routeParams.mode !== 'dedicated') {
            setUseLocalCategoryID(true);
            this.handleGoBack({
              path: paths.HOME,
              runGoBack: true,
            });
          }
        }}
        navRightContent={
          <div className="nav-right-content">
            <div onClick={() => this.setState({ showModal: true })}>
              <Icon className="html-embed-7 w-embed" type={IconType.SCREEN_OUTLINED} />
              {
                markNumber > 0 &&
                <div className="mark">
                  {/* {markNumber} */}
                </div>
              }
            </div>
          </div>
        }
      >
        <div className="nav_bg nav-blur colored w-nav category-onself">
          {
            platformStoreBrands.length > 0 &&
            <CategoryTypes
              brandsCategory={byType === 'all' ? '2' : '1'}
              data={platformStoreBrands}
              selected={selectedCategoryID}
              className={`${prefix}-service-types`}
              reducersState={reducersState}
              onItemClick={this.handleCategoryClick}
            />
          }
        </div>
        {
          !hasStores &&  loadingStores &&
          <Skeleton/>
        }
        {
          !loadingFirstPage &&
          <>
            {
              hasStores &&
              <>
                <div className="space-1">
                  <div className="section">
                    <StoreList
                      data={stores}
                      reducersState={reducersState}
                      loading={loadingStores}
                      className={`${prefix}-store-list`}
                      lastPage={lastPage}
                      onItemClick={this.handleItemClick}
                    />
                    {
                      lastPage &&
                      <div className="no-more-content">
                        <span>{getIntl().page.noMoreContent}</span>
                      </div>
                    }
                  </div>
                </div>
              </>
            }
            {
              !loadingStores && platformConfig.id && !hasStores &&
              <div className="no-stores">
                <Status
                  iconType={IconType.TAG_STORE}
                  title="There are no shops in the city."
                  describe="We are working hard to invite you to join us, please look forward to it!"
                />
              </div>
            }
          </>
        }
        {
          <StoreFilterModal
            visible={showModal}
            // initData={{
            //   [StoreFilterModalContentType.TYPE]: byType,
            // }}
            onCancel={() => this.setState({ showModal: false })}
            confirmStoreFilter={this.handleStoreFilter}
            {...this.handleStoreFilterInitData()}
          />
        }
      </Layout>
    );
  }
}

export default StoresByCategoryPage;
