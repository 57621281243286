import React from 'react';
import SubMenus from './SubMenus';
import { IFavoriteTab, IListing } from '@/lib/order-builder/utils/api';
import { ListingCountRef } from '@/actions/shopping-cart-action';
import { IReducersState } from '@/reducers';
import { getI18nText } from '@/utils/app';

interface IMenuPanelProps {
  data: IFavoriteTab;
  listingCountRef: ListingCountRef;
  isOpen: boolean;
  isGrid?: boolean;
  currentDay: number;
  currentHHmmss: string;
  className?: string;
  onlyShow?: boolean; // 只用于显示菜单信息，屏蔽所有操作功能
  onPlusClick?: (data: IListing, quantity?: number, action?: string) => void;
  onMinusClick?: (data: IListing) => void;
  hideSubTitle?: boolean;
  reducersState: IReducersState;
  menuDisplayMethod?: string;
  currencyCode?: string;
  showCode?: boolean;
  handleChangeQuantityTo?: (data: IListing, quantity: number) => void;
  leftSwipe?: boolean;
  onPopupClick?: (data: IListing) => void;
}

const MenuPanel: React.FC<IMenuPanelProps> = props => {
  const {
    data,
    listingCountRef,
    isOpen,
    currentDay,
    currentHHmmss,
    className = '',
    onlyShow = false,
    isGrid,
    onPlusClick,
    onMinusClick,
    hideSubTitle,
    reducersState,
    menuDisplayMethod = '',
    currencyCode,
    showCode = false,
    handleChangeQuantityTo,
    leftSwipe,
    onPopupClick,
  } = props;

  let sectionOpen = false;

  if (
    isOpen
    && Array.isArray(data.availableDays)
    && data.availableDays.includes(currentDay)
    && data.availableTimeFrom <= currentHHmmss
    && currentHHmmss <= data.availableTimeTo
  ) {
    sectionOpen = true;
  }

  return (
    <div id={`bm-menu-panel-${data.id}`} className={`menu-panel ${className} ${menuDisplayMethod && menuDisplayMethod === 'RIGHT_PICTURE' ? 'menu-panel-special' : ''}`}>
      <div className="section tinted grey">
        <div className="w-container">
          <div className="div-block-4">
            <div className="text-block-5">{getI18nText(data, 'name', reducersState.app.language) || data.name}</div>
            {/* <div className="text-block-5---subtitle">
              All Burritos come in our freshly baked 12 inch flour tortilla with pico de gallo, our special blended cheese, sour cream, beans and rice. Burrito Bowls come with everything included in the burritos less the tortilla and served in a bowl.<br />
            </div> */}
          </div>
        </div>
      </div>
      {
        data.favoriteSections.map(item => (
          <SubMenus
            key={item.id}
            data={item}
            parentViewID={item.id}
            isOpen={sectionOpen}
            listingCountRef={listingCountRef}
            onlyShow={onlyShow}
            isGrid={isGrid}
            onPlusClick={onPlusClick}
            onMinusClick={onMinusClick}
            hideSubTitle={hideSubTitle}
            reducersState={reducersState}
            menuDisplayMethod={menuDisplayMethod}
            currencyCode={currencyCode}
            showCode={showCode}
            handleChangeQuantityTo={handleChangeQuantityTo}
            leftSwipe={leftSwipe}
            onPopupClick={onPopupClick}
          />
        ))
      }
    </div>
  );
}

export default MenuPanel;
