enum SidebarOption {
  MEMBERSHIP = 'MEMBERSHIP',
  ACCOUNT = 'ACCOUNT',
  PRIVILEGES = 'PRIVILEGES',
  DOLLAR = 'DOLLAR',
  STORE = 'STORE',
  TABLE_BOOKING = 'TABLE_BOOKING',
  FOOD_ORDERING = 'FOOD_ORDERING',
  PROMOTIONS = 'PROMOTIONS',
  SHOPPING = 'SHOPPING',
  ABOUT_US = 'ABOUT_US',
  SETTINGS = 'SETTINGS',
  MY_PROFILE = 'MY_PROFILE',
  EXPRSESS_ORDER = 'EXPRSESS_ORDER',
  PURCHASE_ORDER = 'PURCHASE_ORDER',
  GOOD_RECEIVED_NOTES = 'GOOD_RECEIVED_NOTES',
  RETURN_NOTES = 'RETURN_NOTES',
  DEFERRED_INVOICES = 'DEFERRED_INVOICES',
}

export default SidebarOption;
