import createSagaMiddleware from 'redux-saga';
import Redux, { createStore, applyMiddleware } from 'redux';
import reducers from '@/reducers';
import sagas from '@/sagas';
import { name, version } from '../package.json';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export default () => {
  const isDebug = process.env.NODE_ENV === 'development';
  const sagaMiddleware = createSagaMiddleware();
  const middleware: Redux.Middleware[] = [sagaMiddleware];

  let enhancer;

  if (isDebug) {
    const { createLogger } = require('redux-logger');
    const { composeWithDevTools } = require('redux-devtools-extension/developmentOnly');

    middleware.push(
      createLogger({
        collapsed: true,
      })
    );

    const composeEnhancers = composeWithDevTools({
      name: `${name}@${version}`,
    });

    enhancer = composeEnhancers(applyMiddleware(...middleware));
  } else {
    enhancer = applyMiddleware(...middleware);
  }

  const store = createStore(reducers, {}, enhancer);

  if (isDebug && module.hot) {
    module.hot.accept('@/reducers', () => store.replaceReducer(require('@/reducers').default()));
  }

  sagaMiddleware.run(sagas);

  return store;
};
