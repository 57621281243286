
import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import Icon, { IconType } from '@/components/Icon';
import { IObject } from '@/components/bm-common';
import { getAppTheme, setScreenColor, getIntl } from '@/components/App/App';
import { IPayCard } from '@/actions/pay-action';
import ActionType from '@/actions/action-type';
import { PayTypes } from '@/lib/payment';
import { SelectPayMethodItem, prefix } from '@/components/SelectPayMethod';
import paths from '@/routes/paths';
import SideNavOption from '@/actions/side-nav-option';
import { PaymentListPageStyled } from './styled';
import AddCreditCardPage from '../AddCreditCardPage/AddCreditCardPage';
import Layout from '@/components/Layout';
import Components from '@/components/SideNavBar/Components';
const payCardTypeIcons: {[key: string]: IconType} = {
  VISA: IconType.VISA_CARD,
  AMEX: IconType.AE_CARD,
  MASTER: IconType.MASTER_CARD,
};

export interface IPaymentListPageProps extends IBasePageProps {}
export interface IPaymentListPageState {
  payCards: IPayCard[];
  addCard: boolean;
  data?: any;
}

class PaymentListPage extends BasePage<IPaymentListPageProps, IPaymentListPageState> {
  routeParams: IObject = {};

  state: IPaymentListPageState = {
    payCards: [],
    addCard: false,
  }

  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.lightBackgroundColor) {
      setScreenColor(appTheme.lightBackgroundColor);
    }
    super.componentDidMount();
    this.loadingPayCards();
  }
  loadingPayCards = () => {
    const { dispatch } = this.props;
    dispatch({
      type: ActionType.QUERY_PAY_CARDS,
      callback: payCards => {
        this.setState({ payCards });
      }
    });
    this.setState({addCard: false})
  }
  handleItemClick = (method: string, data?: IPayCard) => {
    if (method === 'ADD_CREDIT_CARD') {
      this.handleGotoRoute({
        path: paths.ADD_CREDIT_CARD,
      });
    }
    if (method === 'EDIT_CREDIT_CARD' && data) {
      this.handleGotoRoute({
        path: paths.EDIT_CREDIT_CARD,
        pathParams: {
          cardID: data.id,
        },
      });
    }
  }

  render() {
    const { reducersState } = this.props;
    const { platformAppSettings } = reducersState.app;
    const { payCards, addCard, data } = this.state;
    let canPayMethods: PayTypes[] = [];
    if (platformAppSettings) {
      canPayMethods = platformAppSettings.payMethodKeys;
    }

    return (
      <Layout
        {...this.props}
        isFooter={false}
        className={prefix}
      >
        <Components
          reducersState={reducersState}
          onLeftClick={() => this.handleGoBack({
            path: paths.ACCOUNT,
            runGoBack: true,
          })}
          navIcon={<Icon className="html-embed-7 w-embed" type={IconType.LEFT_OUTLINED} />}
          navContent={getIntl().page.paymentMethod}
          sideNavOption={SideNavOption.PAYMENT_LIST}
          
        >
          <PaymentListPageStyled>
            <div className="div-block-547 payment">
              <div className="section payment-cards">
                <div className="div-block-545"><div className="text-block-67">{getIntl().page.paymentMethod}</div></div>
                <SelectPayMethodItem
                  showCheckIcon={false}
                  title={getIntl().page.paymentCardTitle}
                  className="payment-cards"
                  icon={
                    <>
                      {
                        canPayMethods.includes(PayTypes.VISA) &&
                        <Icon type={IconType.VISA_CARD} />
                      }
                      {
                        canPayMethods.includes(PayTypes.MASTER) &&
                        <Icon type={IconType.MASTER_CARD} />
                      }
                      {
                        canPayMethods.includes(PayTypes.JCB) &&
                        <Icon type={IconType.JCB_CARD} />
                      }
                      {
                        canPayMethods.includes(PayTypes.AMEX) &&
                        <Icon type={IconType.AE_CARD} />
                      }
                      {
                        canPayMethods.includes(PayTypes.UNION_PAY) &&
                        <Icon type={IconType.UNION_PAY} />
                      }
                    </>
                  }
                  onItemClick={() => this.setState({addCard: true, data: ''})}
                />
                {
                  canPayMethods.includes(PayTypes.VISA || PayTypes.MASTER || PayTypes.JCB || PayTypes.AMEX || PayTypes.UNION_PAY) && payCards.map(item => (
                    <SelectPayMethodItem
                      showCheckIcon={false}
                      key={item.id}
                      title={
                        <span>
                          {getIntl().pay.cardEnding}
                          <span className="last-digits">{item.last4Digits}</span>
                        </span>}
                      icon={<Icon type={payCardTypeIcons[item.type] || IconType.VISA_CARD} />}
                      onItemClick={() => this.setState({addCard: true, data: item})}
                    />
                  ))
                }
              </div>
            </div>
            {
              addCard &&
              <AddCreditCardPage
                {...this.props}
                onCancel={() => this.setState({ addCard: false })}
                cardID = {data && data.id}
                handleData = {this.loadingPayCards}  
              />
            }
          </PaymentListPageStyled>
        </Components>
      </Layout>
    );
  }
}

export default PaymentListPage;
