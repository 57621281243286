import styled from 'styled-components';

export const ModifierPageStyled = styled.div`
position: relative;
z-index: 10;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
overflow: scroll;
width: 600px;
height: 100%;
padding: 0px;
-webkit-box-orient: vertical;
-webkit-box-direction: normal;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
-webkit-box-pack: start;
-webkit-justify-content: flex-start;
-ms-flex-pack: start;
justify-content: flex-start;
border-radius: 12px;
background-color: var(--bm-light-background-color);
box-shadow: 0 9px 50px -14px transparent;

* {
  box-sizing: border-box;
}
.item-info-flex {
  display: flex;
  justify-content: space-between;
}
.item-image-pop-up {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 800000;
  width: 100%;
  /* height: 400px; */
  height: 35%;
  max-width: none;
  min-width: auto;
  background-color: transparent;
  background-position: 50% 50%;
  background-size: cover;
}
.popup-description--item {
  position: relative;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  z-index: 9999999;
  overflow: scroll;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  /* display: flex; */
  display: block;
  height: 65px;
  min-height: 65%;
  margin-top: auto;
  padding: 20px 0px 100px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 16px;
  background-color: var(--bm-light-background-color);
}
.popup-description--item.popup-item-top {
  margin-top: unset;
  height: 100%;
}
.div-block-69 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  padding-bottom: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid var(--bm-border-color);
}
.div-block-69.item {
  margin-right: 20px;
  margin-bottom: 0px;
  margin-left: 20px;
}
.item-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 100%;
  width: 100%;
  margin-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.heading-4-1 {
  margin-top: 0px;
  margin-bottom: 10px;
  font-family: 'Sf pro display', sans-serif;
  color:  var(--bm-text-title-color);
  font-size: 22px;
  font-weight: 600;
  text-align: left;
}
.left-data {
  max-width: 100%;
}
.text-block-6 {
  max-width: none;
  margin-top: 0px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  font-family: 'Sf pro display', sans-serif;
  color: var(--bm-text-title-color);
  font-size: 18px;
  font-weight: 600;
}
/* .div-block-553 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
} */
.div-block-557 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  padding: 15px 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--bm-checkout-backgroud-color);
}
.text-block-67 {
  margin-bottom: 0px;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: var(--bm-text-title-color);
  font-size: 18px;
  font-weight: 400;
}
.text-block-67.bold {
  margin-bottom: 0px;
  padding: 0px;
  font-family: 'Sf pro display', sans-serif;
  font-weight: 600;
  text-transform: none;
}
.midifier-tip {
  margin-top: 5px;
}
.midifier-tip.midifier-tip-warn {
  color: var(--bm-error-color);
}
.dropdown-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30px;
  height: 30px;
  padding: 0;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: var(--bm-border-color);
  border-radius: 50%;
  color: var(--bm-text-color);
  cursor: pointer;
  svg {
    width: 100%;
    height: 100%;
  }
}
.dropdown-icon.select-icon-hide {
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(180deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
}
.q-dec {
  opacity: .6;
}
.q-dec.disable-minus {
  opacity: .6;
}
.dropdown-list-1 {
  overflow: hidden;
}
.modifier-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 70px;
  max-height: 70px;
  margin: 0px 20px 20px;
  padding: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-style: solid;
  border-width: 1px;
  border-color: var(--bm-border-color);
  border-radius: 8px;
  font-size: 18px;
  color: var(--bm-text-title-color);
}
.modifier-block.modifier-wrapper-selected {
  border-color: var(--bm-primary-color);
}
.sub-price {
  color: var(--bm-text-secondary-color);
}
.text-block-218 {
  padding: 10px 20px;
  border-radius: 4px;
  background-color: var(--bm-primary-color);
  color: var(--bm-light-background-color);
  cursor: pointer;
}
.empty-panel {
  height: 100px;
}
.add-to-cart {
  position: -webkit-sticky;
  position: fixed;
  left: 0;
  /* top: auto; */
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-right: 0px;
  margin-left: 0px;
  padding: 20px 20px 40px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  background-color: var(--bm-light-background-color);
}
.q-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: auto;
  min-width: auto;
  margin-right: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}
.q-dec,
.q-inc,
.select-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 45px;
  height: 45px;
  margin-right: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  /* border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 69, 144, 0.6);
  border-radius: 60px; */
  background-color: var(--bm-light-background-color);
  color: var(--bm-primary-color);
  padding: 0;
  svg {
    width: 100%;
    height: 100%;
  }
}
.q-dec-cart.q-dec, 
.q-inc.q-inc-cart,
.select-icon {
  width: 38px;
  height: 38px;
  margin-right: 10px;
}
.select-icon {
  margin-right: 0;
}
.q-inc {
  margin-left: 10px;
  margin-right: 0;
}
.q-num {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  height: unset;
  margin-bottom: 0px;
  padding-right: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-width: 0px;
  background-color: transparent;
  font-family: 'Sf pro display', sans-serif;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}
.add-to-cart-button {
  position: relative;
  z-index: 99;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 0px;
  padding-bottom: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--bm-light-background-color);
  cursor: pointer;
  width: 100%;
  height: 50px;
  justify-content: center;
  background-color: var(--bm-primary-color);
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  font-size: 18px;
  text-align: center;
  display: block;
}
.nav-button-1 {
  display: none;
  max-height: 32px;
  max-width: 32px;
  min-height: 32px;
  min-width: 32px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: var(--bm-border-color);
  border-radius: 80px;
  color: var(--bm-text-color); 
}
.nav-button-1.close-modal-color {
  border-color: hsla(0, 0%, 100%, 0.4);
  background-color: hsla(0, 0%, 86.3%, 0.8);
}
.text-block-12 {
  color: var(--bm-light-background-color);
  line-height: 50px;
}
.html-embed-7 {
  width: 100%;
  height: 100%;
  padding: 0;
  color: var(--bm-text-title-color);
  svg {
    width: 100%;
    height: 100%;
  }
}
.div-block-77 {
  display: block;
  margin: 0;
}
.q-flex-cart {
  display: flex;
  .q-dec-cart {
    margin-right: 0;
  }
  .q-inc-cart {
    margin-left: 0;
  }
}
.sold-out {
  padding-right: 12px;
}
.text-block-214 {
  flex: 1; 
}

@media screen and (max-width: 991px) {
  .heading-4-1 {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .text-block-6 {
    font-size: 14px;
  }
  
}

@media screen and (max-width: 767px) {
  width: auto;
  height: 100vh;
  min-height: 100vh;
  border-radius: 0px;
  .item-image-pop-up {
    z-index: auto;
    height: 30%;
  }
  .popup-description--item {
    z-index: 99;
    padding-right: 0px;
    padding-left: 0px;
    border-radius: 0px;
    height: 70%;
  }
  .div-block-69 {
    padding-right: 20px;
    padding-left: 20px;
  }
  .div-block-69.item {
    padding-right: 0px;
    padding-left: 0px;
  }
  .heading-4-1 {
    margin-bottom: 10px;
    font-size: 18px;
  }
  .text-block-6 {
    font-size: 16px;
  }
  .text-block-67.bold {
    font-size: 18px;
  }
  .midifier-tip {
    font-size: 16px;
  }
  .modifier-block {
    font-size: 16px;
    height: 60px;
    max-height: 60px;
  }
  .q-inc,
  .q-dec{
    width: 40px;
    height: 40px;
  }
  .q-dec-cart.q-dec, 
  .q-inc.q-inc-cart,
  .select-icon {
    width: 36px;
    height: 36px;
  }
  .q-num {
    font-size: 16px;
  }
  .add-to-cart-button {
    border-radius: 8px;
  }
  .div-block-77 {
    display: block;
    margin-right: 21.6px;
    margin-left: 21.6px;
    border-radius: 0px;
    margin-top: calc(var(--bm-safe-area-top) + 16px);
  }
  .nav-button-1.close-modal {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
  }

}

@media screen and (max-width: 479px) {
  width: auto;
  height: 100%;
  min-height: auto;
  min-width: auto;
  border-radius: 0px;
  .item-image-pop-up {
    height: 30%;
  }
  .popup-description--item {
    width: 100vw;
    height: 70%;
    min-height: 70%;
    padding-right: 0px;
    padding-left: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .div-block-69 {
    margin-right: 12px;
    margin-left: 12px;
    padding-right: 0px;
    padding-bottom: 15px;
    padding-left: 0px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .div-block-69.item {
    margin-right: 15px;
    margin-left: 15px;
  }
  .item-info {
    overflow: visible;
    max-height: none;
  }
  .heading-4-1 {
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 16px;
  }
  .text-block-6 {
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
    font-family: 'Sf pro display', sans-serif;
    font-size: 14px;
    font-weight: 600;
  }
  .text-block-67 {
    margin-bottom: 0px;
    font-size: 14px;
  }
  .text-block-67.bold {
    font-size: 14px;
  }
  .midifier-tip {
    font-size: 12px;
  }
  .modifier-block {
    font-size: 16px;
  }
  .q-flex {
    margin-right: 10px;
  }
  .q-dec,
  .q-inc  {
    width: 32px;
    height: 32px;
    margin-right: 5px;
  }
  .q-dec-cart.q-dec, 
  .q-inc.q-inc-cart,
  .select-icon {
    width: 32px;
    height: 32px;
  }
  .q-num {
    width: 30px;
    padding-right: 0px;
    padding-left: 0px;
    font-size: 14px;
  }
  .add-to-cart-button {
    height: 45px;
  }
  .div-block-77 {
    margin-right: 17px;
    margin-left: 17px;
    line-height: 45px;
    margin-top: calc(var(--bm-safe-area-top) + 15px);
  }
  .nav-button-1 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
  }
  .text-block-12 {
    line-height: 45px;
  }
}
`;
