import React from 'react';
import MenuItem from './MenuItem';
import MenuItemForItem from './MenuItemForItem';
import { IFavoriteSection, IListing } from '@/lib/order-builder/utils/api';
import { ListingCountRef } from '@/actions/shopping-cart-action';
import { getI18nText } from '@/utils/app';
import { IReducersState } from '@/reducers';
import MenuItemForRight from './MenuItemForRight';
import Swiper from '@/components/SwiperAction';
import RightSide from './RightSideContent';

interface ISubMenusProps {
  data: IFavoriteSection;
  parentViewID: string;
  listingCountRef: ListingCountRef;
  isOpen: boolean;
  isGrid?: boolean;
  className?: string;
  itemClassName?: string;
  onlyShow?: boolean; // 只用于显示菜单信息，屏蔽所有操作功能
  onPlusClick?: (data: IListing, quantity?: number, action?: string) => void;
  onMinusClick?: (data: IListing) => void;
  hideSubTitle?: boolean;
  reducersState: IReducersState;
  menuDisplayMethod?: string;
  currencyCode?: string;
  showCode?: boolean;
  handleChangeQuantityTo?: (data: IListing, quantity: number) => void;
  leftSwipe?: boolean;
  onPopupClick?: (data: IListing) => void;
}

const SubMenus: React.FC<ISubMenusProps> = props => {
  const {
    data,
    parentViewID,
    listingCountRef,
    className = '',
    isOpen,
    onlyShow = false,
    isGrid = false,
    onPlusClick,
    onMinusClick,
    hideSubTitle,
    reducersState,
    menuDisplayMethod = '',
    currencyCode,
    showCode,
    handleChangeQuantityTo,
    leftSwipe = false,
    onPopupClick,
  } = props;

  return (
    <div className={`submenus ${className}`}>
      {
        !hideSubTitle &&
        <div className="menu-panel-bc">
          <div className="container w-container">
            <div className="submenus-title-wrapper">
              <div className="submenus-title">
                {getI18nText(data, 'name', reducersState.app.language) || data.name}
              </div>
              <div className="submenus-title-line"></div>
            </div>
          </div>
        </div>
      }
      {/* {
        !hideSubTitle &&
        <div className="text-block-5---subtitle">
          {getI18nText(data, 'name', reducersState.app.language) || data.name}
        </div>
      } */}
      <div className={`submenus-content-wrapper eshop w-container-container ${isGrid ? 'content-grid' : 'content-special'} ${menuDisplayMethod && menuDisplayMethod === 'RIGHT_PICTURE' ? 'content-special' : ''}`}>
        {
          data.listings.map((item, index) => {
            if (isGrid) {
              return (
                <MenuItemForItem
                  key={`${item.id}-${index}`}
                  data={item}
                  parentViewID={`${parentViewID}-${item.id}`}
                  isOpen={isOpen}
                  listingCountRef={listingCountRef}
                  onlyShow={onlyShow}
                  onPlusClick={onPlusClick}
                  onMinusClick={onMinusClick}
                  reducersState={reducersState}
                  currencyCode={currencyCode}
                  onPopupClick={onPopupClick}
                  handleChangeQuantityTo={handleChangeQuantityTo}
                />
              );
            } else if (menuDisplayMethod && menuDisplayMethod === 'RIGHT_PICTURE') {
              if (leftSwipe) {
                return (
                  <Swiper
                    key={`${item.id}-${index}`}
                    leftSide={
                      <MenuItemForRight
                        key={`${item.id}-${index}`}
                        data={item}
                        parentViewID={`${parentViewID}-${item.id}`}
                        isOpen={isOpen}
                        listingCountRef={listingCountRef}
                        onlyShow={onlyShow}
                        onPlusClick={onPlusClick}
                        onMinusClick={onMinusClick}
                        reducersState={reducersState}
                        currencyCode={currencyCode}
                        showCode={showCode}
                        className={'left-swipe'}
                      />
                    }
                    rightSide={
                      <RightSide
                        data={item}
                        isOpen={isOpen}
                        listingCountRef={listingCountRef}
                        onPlusClick={onPlusClick}
                        onMinusClick={onMinusClick}
                        reducersState={reducersState}
                        handleChangeQuantityTo={handleChangeQuantityTo}
                      />
                    }
                  />
                )
              } else {
                return (
                  <MenuItemForRight
                    key={`${item.id}-${index}`}
                    data={item}
                    parentViewID={`${parentViewID}-${item.id}`}
                    isOpen={isOpen}
                    listingCountRef={listingCountRef}
                    onlyShow={onlyShow}
                    onPlusClick={onPlusClick}
                    onMinusClick={onMinusClick}
                    reducersState={reducersState}
                    currencyCode={currencyCode}
                    showCode={showCode}
                  />
                )
              }
            } else {
              return (
                <MenuItem
                  key={`${item.id}-${index}`}
                  data={item}
                  parentViewID={`${parentViewID}-${item.id}`}
                  isOpen={isOpen}
                  listingCountRef={listingCountRef}
                  onlyShow={onlyShow}
                  onPlusClick={onPlusClick}
                  onMinusClick={onMinusClick}
                  reducersState={reducersState}
                  currencyCode={currencyCode}
                />
              )
            }
          })
        }
      </div>
    </div>
  );
}

export default SubMenus;
