import React from 'react';
import { storeprefix } from '..';
import { MapTypes } from './StoreGoogleMap';
import { getIntl } from '@/components/App/App';
interface IStoreGoogleMapRouteProps {
  handleOpenOtherMap: (type: MapTypes) => void;
  onCancel: () => void;
}

const StoreGoogleMapRoute = (props: IStoreGoogleMapRouteProps) => {

  const prefix = `${storeprefix}-route`;

  const {
    onCancel,
    handleOpenOtherMap
  } = props;

  return (
    <div
      className={`${prefix}`}
    >
      <div className={`${prefix}-item`} onClick={() => handleOpenOtherMap(MapTypes.APPLEMAP)}>
        {getIntl().page.applemap}
      </div>
      <div className={`${prefix}-item`} onClick={() => handleOpenOtherMap(MapTypes.GAODEMAP)}>
        {getIntl().page.gaodemap}
      </div>
      <div className={`${prefix}-item`} onClick={() => handleOpenOtherMap(MapTypes.GOOGLEMAP)}>
        {getIntl().page.googlemap}
      </div>
      <div className={`${prefix}-item`} onClick={() => handleOpenOtherMap(MapTypes.CURRENTMAP)}>
        {getIntl().page.current}
      </div>

      <div className={`${prefix}-item-cancel`} onClick={onCancel}>
        {getIntl().common.cancel}
      </div>
    </div>
  );
}

export default StoreGoogleMapRoute;
