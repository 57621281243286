import { IRoute } from '@/routes';
import paths from '@/routes/paths';
import PayMethodPage from './PayMethodPage';
import PayResultsPage from './PayResultsPage';
import PayCardPage from './PayCardPage';
import ConfirmOrderPage from './ConfirmOrderPage';
import PayBillPage from './PayBillPage';
import PayResultsWithNoOrderInfoPage from './PayResultsWithNoOrderInfoPage';
import PickupLocationsPage from './PickupLocationsPage';
import BillPayPage from './BillPayPage';

export const mobileRoutes: IRoute[] = [
  {
    path: paths.ORDER_PAY_METHOD,
    component: PayMethodPage,
  },
  {
    path: paths.PAY_RESULTS,
    component: PayResultsPage,
  },
  {
    path: paths.PAY_CARD,
    component: PayCardPage,
  },
  {
    path: paths.ORDER_CONFIRM,
    component: ConfirmOrderPage,
  },
  {
    path: paths.PAY_BILL,
    component: PayBillPage,
  },
  {
    path: paths.PLATFORM_PAY_BILL,
    component: BillPayPage,
  },
  {
    path: paths.GATEWAY_PAY_BILL,
    component: PayBillPage,
  },
  {
    path: paths.PAY_NOORDER_RESULTS,
    component: PayResultsWithNoOrderInfoPage,
  },
  {
    path: paths.PICKUP_LOCATIONS,
    component: PickupLocationsPage,
  },
];

export default mobileRoutes;
