import React from 'react';
import dayjs from 'dayjs';
import AntdIcon from 'antd-mobile/es/icon';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import Icon, { IconType } from '@/components/Icon';
import { IObject } from '@/components/bm-common';
import { getAppTheme, setScreenColor, getIntl } from '@/components/App/App';
import Layout from '@/components/Layout';
import paths from '@/routes/paths';
import ActionType from '@/actions/action-type';
import { IReceiveOrder } from '@/actions/good-received-note-actions';
import { prefix } from '.';


export interface IGoodReceivedNotesPageProps extends IBasePageProps { }
export interface IGoodReceivedNotesPageState {
  goodReceivedNotes: IReceiveOrder[] | undefined;
  page: number;
  loading: boolean;
}

class GoodReceivedNotesPage extends BasePage<IGoodReceivedNotesPageProps, IGoodReceivedNotesPageState> {
  routeParams: IObject = {};
  lastPage = false;
  loadingData = false;
  state: IGoodReceivedNotesPageState = {
    goodReceivedNotes: undefined,
    page: 1,
    loading: false,
  }

  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.darkBackgroundColor) {
      setScreenColor(appTheme.darkBackgroundColor);
    }

    super.componentDidMount();
    this.openLoading();
    this.getGoodReceivedNotes();
    const pageWrap = window.document.querySelector('.page-content-wrapper-web');
    if (pageWrap) {
      pageWrap.addEventListener('scroll', this.handleScroll, { passive: true });
    } else {
      window.addEventListener('scroll', this.handleScroll, { passive: true });
    }
  }

  componentWillUnmount() {
    const pageWrap = window.document.querySelector('.page-content-wrapper-web');
    if (pageWrap) {
      pageWrap.removeEventListener('scroll', this.handleScroll);
    } else {
      window.removeEventListener('scroll', this.handleScroll);
    }
  }

  getScrollY = () => {
    const pageWrap = window.document.querySelector('.page-content-wrapper-web');
    let scrollY = window.scrollY;
    if (pageWrap) {
      scrollY = pageWrap.scrollTop;
    }

    return scrollY;
  }

  handleScroll = () => {
    const { page } = this.state;
    if (!this.lastPage && !this.loadingData) {
      const appPages = document.getElementsByClassName('bm-p-good-received-notes');
      if (appPages && appPages.length) {
        const appPage = appPages[0];
        const scrollHeight = appPage.scrollHeight;
        const canScrollHeight = scrollHeight - window.innerHeight;
        if (canScrollHeight - this.getScrollY() < 3 * 100) {
          this.loadingData = true
          this.setState({loading: true});
          this.getGoodReceivedNotes(page + 1, true);
        }
      }
    }
  }

  getGoodReceivedNotes = (page = 1, isAdd = false) => {
    const { dispatch, routeParams } = this.props;
    const { goodReceivedNotes } = this.state;
    const { storeSlug } = routeParams;
    dispatch({
      type: ActionType.QUERY_STORE,
      storeSlug: storeSlug,
      callback: (store, storeConfig) => {
        if (store) {
          dispatch({
            type: ActionType.QUERY_GOOD_RECEIVED_NOTES,
            storeID: store.id,
            page: page,
            callback: records => {
              let newGoodReceivedNotes: IReceiveOrder[] = [];
              if (isAdd && goodReceivedNotes) {
                newGoodReceivedNotes = goodReceivedNotes.concat(records);
              } else {
                newGoodReceivedNotes = records;
              }
              if (records.length < 10) this.lastPage = true;
  
              if (records) this.loadingData = false;
              this.setState({
                goodReceivedNotes: newGoodReceivedNotes,
                loading: false,
                page,
              });
              this.closeLoading();
            }
          })
        } else {
          this.closeLoading();
        }
      }
    })
  }

  handleGoDetail = (receiveOrderID: string) => {
    this.handleGotoRoute({
      path: paths.GOOD_RECEIVED_NOTE_DETAIL,
      pathParams: {
        receiveOrderID: receiveOrderID,
      },
    });
  }
  
  render() {
    const { goodReceivedNotes, loading } = this.state;
    return (
      <Layout
        {...this.props}
        className={prefix}
        navIcon={<Icon className="back-icon" type={IconType.LEFT_OUTLINED} />}
        onLeftClick={() => this.handleGoBack({ path: paths.HOME, runGoBack: true })}
        navContent={getIntl().page.goodReceivedNotesTitle}
      >
        {
          goodReceivedNotes &&
          goodReceivedNotes.map(item => {
            return (
              <div className="good-received-note-item" key={item.id} onClick={() => this.handleGoDetail(item.id)}>
                <div className="good-received-note-item-line number-and-state">
                  <div className="good-received-note-item-line-number">{item.number}</div>
                  {
                    item.state === 'received' &&
                    <div className="good-received-note-item-line-state received">{getIntl().page.received}</div>
                  }
                  {
                    item.state === 'canceled' &&
                    <div className="good-received-note-item-line-state cancelled">{getIntl().common.voided}</div>
                  }
                  {
                    item.state !== 'canceled' && item.state !== 'received' &&
                    <div className="good-received-note-item-line-state">{getIntl().page.created}</div>
                  }
                </div>
                <div className="good-received-note-item-line">
                  <div className="good-received-note-item-line-title">{getIntl().page.supplierName}</div>
                  <div className="good-received-note-item-line-data">{item.supplierName}</div>
                </div>
                {
                  item.state === 'received' &&
                  <div className="good-received-note-item-line">
                    <div className="good-received-note-item-line-title">{getIntl().page.receivedOn}</div>
                    <div className="good-received-note-item-line-data">{dayjs(item.receiveDate).format('YYYY-MM-DD')}</div>
                  </div>
                }
                {
                  item.state !== 'received' &&
                  <div className="good-received-note-item-line">
                    <div className="good-received-note-item-line-title">{getIntl().page.deliveryReceived}</div>
                    <div className="good-received-note-item-line-data">{item.expectedReceiveDate ? dayjs(item.expectedReceiveDate).format('YYYY-MM-DD') : 'N/A'}</div>
                  </div>
                }
                <div className="good-received-note-item-line">
                  <div className="good-received-note-item-line-title">{getIntl().page.receivedBy}</div>
                  <div className="good-received-note-item-line-data">{item.createdBy}</div>
                </div>
              </div>
            )
          })
        }
        {
          goodReceivedNotes && loading &&
          <div className="loading-data">
            <AntdIcon type="loading" />
            <div className="loading-msg">
              {getIntl().common.loading}
            </div>
          </div>
        }
        {
          goodReceivedNotes && goodReceivedNotes.length === 0 &&
          <div className={'no-data'}>
            <div>
              <Icon className="img" type={IconType.TAG_ITEM}/>
              <div className="no-data-tip">
                {getIntl().page.noPurchasrOrderTip}
              </div>
            </div>
          </div>
        }
      </Layout>
    );
  }
}

export default GoodReceivedNotesPage;
