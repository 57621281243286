import React, { useState, useEffect } from 'react';
import { prefix } from '.';
import { ISplash } from '@/actions/app-action';
import { IDispatch } from '@/actions';
import { createView } from './SplashViews';
import { IReducersState } from '@/reducers';
import ActionType from '@/actions/action-type';
import { useRouteMatch, useHistory } from 'react-router-dom';
import paths, { platformRoutePrefix } from '@/routes/paths';
import { setBodyScrollable } from '@/utils';
import DotsAndBtn from './DotsAndBtn';
import { setSplashInitialized } from '@/utils/splash-util';

interface ISplashProps {
  data: ISplash[];
  reducersState: IReducersState;
  dispatch: IDispatch;
}

let splashTimout: any;

const noSplashPaths: string[] = [
  paths.CHECKOUT,
  paths.ORDER_CONFIRM,
  paths.ORDER_PAY_METHOD,
  paths.PAY_RESULTS,
  paths.PAY_CARD,
  paths.PAY_BILL,
  paths.PAY_NOORDER_RESULTS,
  `${platformRoutePrefix}${paths.CHECKOUT}`,
  `${platformRoutePrefix}${paths.ORDER_CONFIRM}`,
  `${platformRoutePrefix}${paths.ORDER_PAY_METHOD}`,
  `${platformRoutePrefix}${paths.PAY_RESULTS}`,
  `${platformRoutePrefix}${paths.PAY_CARD}`,
  `${platformRoutePrefix}${paths.PAY_BILL}`,
  `${platformRoutePrefix}${paths.PAY_NOORDER_RESULTS}`,
];

let innerWidthTimeout: any = null;

interface IToucheData {
  startPageX: number;
  movePageX: number;
}

const Splash: React.FC<ISplashProps> = props => {
  const {
    data,
    reducersState,
    dispatch,
  } = props;

  const match = useRouteMatch();
  const history = useHistory().location.pathname;
  const {
    platformAppSettings,
    platformDomain,
    showSplash,
    deviceInfo,
  } = reducersState.app;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showItemBtns] = useState(false);
  const [toucheData, setToucheData] = useState<IToucheData>({ startPageX: 0, movePageX: 0 });
  const [isDone, setIsDone] = useState(false);
  const [innerWidth, setInnerWidth] = useState<number>(window.innerWidth);
  const [splashTime] = useState(() => {
    let time = 0;
    if (platformAppSettings) {
      time = platformAppSettings.splashTime || 0;
    }

    return time;
  });

  let autoplay = false;
  if (splashTime >= 3) {
    autoplay = true;
  }

  const handleNextClick = () => {
    if (selectedIndex < data.length - 1) {
      setSelectedIndex(selectedIndex + 1);
    }
  }

  const handlePrevClick = () => {
    if (selectedIndex > 0) {
      setSelectedIndex(selectedIndex - 1);
    }
  }

  const handleDoneClick = () => {
    setIsDone(true);
    setTimeout(() => {
      setBodyScrollable(true);
      setSplashInitialized({ deviceInfo, platformDomain });
      dispatch({
        type: ActionType.SET_SHOW_SPLASH,
        showSplash: false,
      });
    }, 300)
  }

  useEffect(() => {
    const showSplashByPath = !noSplashPaths.includes(match.path);
    if (showSplashByPath) {
      if (showSplash) {
        setBodyScrollable(false);
      }
    } else {
      setSplashInitialized({ deviceInfo, platformDomain });
      dispatch({
        type: ActionType.SET_SHOW_SPLASH,
        showSplash: false,
      })
    }
  }, [showSplash]);

  useEffect(() => {
    if (autoplay) {
      if (splashTimout) {
        clearTimeout(splashTimout);
        splashTimout = null;
      }

      splashTimout = setTimeout(() => {
        splashTimout = null;
        if (selectedIndex === data.length - 1) {
          handleDoneClick();
        } else {
          handleNextClick();
        }
      }, splashTime * 1000)
    }
  }, [selectedIndex]);

  useEffect(() => {
    if ( history === paths.PRIVACY || history === paths.TERMS ) {
      dispatch({
        type: ActionType.SET_SHOW_SPLASH,
        showSplash: false,
      })
    }
  }, [history])

  const handleTouchStart = (e: any) => {
    const touch = e.touches[0];
    const pageX = touch.pageX;

    toucheData.startPageX = pageX;
    toucheData.movePageX = pageX;
    setToucheData({...toucheData});
  }
  const handleTouchMove = (e: any) => {
    const touch = e.touches[0];
    let pageX = touch.pageX;

    if (Math.abs(pageX) > innerWidth) {
      pageX = innerWidth;
    }

    if (
      Math.abs(pageX) > innerWidth ||
      (selectedIndex < 1 && pageX - toucheData.startPageX > 0) ||
      (selectedIndex >= data.length - 1 && pageX - toucheData.startPageX < 0)
    ) {
      return;
    }

    toucheData.movePageX = pageX;
    setToucheData({...toucheData});
  }
  const handleTouchEnd = () => {
    if (toucheData.startPageX < toucheData.movePageX) {
      handlePrevClick();
    } else if (toucheData.startPageX > toucheData.movePageX) {
      handleNextClick();
    }
    toucheData.startPageX = 0;
    toucheData.movePageX = 0;
    setToucheData({...toucheData});
  }

  const pageSize = data.length;
  window.onresize = function () {
    if (innerWidthTimeout) {
      clearTimeout(innerWidthTimeout);
      innerWidthTimeout = null;
    }

    innerWidthTimeout = setTimeout(() => {
      innerWidthTimeout = null;
      setInnerWidth(window.innerWidth);
    }, 50);
  }

  let tx = selectedIndex * innerWidth + (toucheData.startPageX - toucheData.movePageX);
  if (tx > (data.length - 1) * innerWidth) {
    tx = (data.length - 1) * innerWidth;
  }

  return (
    <div className={prefix} style={{ opacity: isDone ? 0 : 1 }}>
      <div className="splash-nav-bar"></div>
      <div className="splash-slider">
        <ul
          className="slider-list"
          style={{
            // transform: `translate3d(-${selectedIndex * innerWidth}px, 0px, 0px)`,
            transform: `translate3d(-${Math.round(tx)}px, 0px, 0px)`,
            width: `${innerWidth * pageSize}px`
          }}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          {
            data.map((item, index) => (
              <li
                key={item.id}
                className="slider-slide"
                style={{left: `${index * innerWidth}px`, width: `${innerWidth}px`}}
              >
                {
                  createView({
                    data: item,
                    count: data.length,
                    index,
                    deviceInfo,
                    showItemBtns,
                    onDoneClick: handleDoneClick,
                    onNextClick: handleNextClick,
                    language: reducersState.app.language,
                  })
                }
              </li>
            ))
          }
        </ul>
      </div>
      {
        !showItemBtns &&
        <DotsAndBtn
          count={data.length}
          index={selectedIndex}
          onNextClick={handleNextClick}
          onDoneClick={handleDoneClick}
        />
      }
    </div>
  );
}

export default Splash;
