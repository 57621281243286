import common from './common';
import invoice from './invoice';
import page from './page';
import pay from './pay';

const locales = {
  common,
  invoice,
  page,
  pay,
}

export default locales;
