import React from 'react';
import { IModifier } from '@/lib/order-builder/utils/api';
import Icon, { IconType } from '@/components/Icon';
import { formatCurrency } from '@/components/Payment';
import { IReducersState } from '@/reducers';
import { getI18nText } from '@/utils/app';
import { getIntl } from '@/components/App/App';

interface IModifierProps {
  data: IModifier;
  chooseSameOption: boolean;
  chooseUpTo: number;
  optionHasSubOption: boolean;
  clickable: boolean;
  listing: IModifier;
  onClick?: (listing: IModifier, data: IModifier, action: 'PLUS' | 'MINUS' | 'OPTIONS' | 'SINGLE') => void;
  countRef: {[modifierID: string]: number};
  reducersState: IReducersState;
  currencyCode: string;
}

const Modifier: React.FC<IModifierProps> = props => {
  const { listing, data, chooseSameOption, chooseUpTo, optionHasSubOption, onClick, countRef, clickable, reducersState, currencyCode } = props;

  const isExist = (data.id in countRef) && countRef[data.id] > 0;

  const handleMinusClick = () => {
    if (onClick) {
      onClick(listing, data, 'MINUS');
    }
  }
  const handlePlusClick = () => {
    if (onClick && !clickable) {
      onClick(listing, data, 'PLUS');
    }
  }
  const handleOptionsClick = () => {
    if (onClick && !clickable) {
      onClick(listing, data, 'OPTIONS');
    }
  }
  const handleSingleClick = () => {
    if (onClick) {
      onClick(listing, data, 'SINGLE');
    }
  }
  const ITEM_TYPE_SINGE = 1;
  const ITEM_TYPE_MULTIPLE = 2;
  const ITEM_TYPE_OPTIONS = 3;
  const ITEM_TYPE_C_OPTION = 4;
  let itemType = ITEM_TYPE_MULTIPLE;
  if (optionHasSubOption) { // 有二级modifier， options
    itemType = ITEM_TYPE_OPTIONS;
  } else if (!chooseSameOption && chooseUpTo === 1) { // 没有勾选不限数量，并且最多选择1，单选
    itemType = ITEM_TYPE_SINGE;
  } else if (!chooseSameOption) { // 没有勾选不限数量,就只能选1， 单选
    itemType = ITEM_TYPE_C_OPTION;
  } else if (chooseSameOption && chooseUpTo === 1) { // 勾选不限数量 但是必须选择1 ，最多选择1 单选
    itemType = ITEM_TYPE_SINGE;
  } else { // 多选 显示加减
    itemType = ITEM_TYPE_MULTIPLE;
  }
  let buttonsOneOptionModeView;
  let buttonsMultipleModeView;
  let buttonsHasSubOptionModeView;
  let wrapperOnClick: any;
  switch (itemType) {
  case ITEM_TYPE_OPTIONS:
    buttonsHasSubOptionModeView = (
      <div className="item-count">
        {
          isExist &&
            <>
              <Icon
                className="item-minus"
                type={IconType.MINUS_CIRCLE_OUTLINED}
                onClick={handleMinusClick}
              />
              <div className="count">{countRef[data.id]}</div>
              <Icon
                className="item-plus"
                type={IconType.PLUS_CIRCLE_OUTLINED}
                onClick={handlePlusClick}
              />
            </>      
        }
        {
          !isExist &&
          <div className="item-options" onClick={handleOptionsClick}>
            {getIntl().page.option}
          </div>
        }
      </div>
    );
    break;
  case ITEM_TYPE_SINGE:
    wrapperOnClick = handleSingleClick;
    buttonsOneOptionModeView = (
      <div className="item-count">
        {
          isExist &&
          <>
            <Icon className="select-icon checked" type={IconType.CHECKED_OUTLINED}/>
          </>
        }
      </div>
    );
    break;
  case ITEM_TYPE_C_OPTION:
    wrapperOnClick = handleSingleClick;
    buttonsMultipleModeView = (
      <div className="item-count">
        {
          isExist &&
          <>
            <Icon className="select-icon checked" type={IconType.CHECKED_OUTLINED}/>
          </>
        }
      </div>
    );
    break;
  default:
    buttonsMultipleModeView = (
      <div className="item-count">
        {
          isExist &&
          <>
            <Icon
              className="item-minus"
              type={IconType.MINUS_CIRCLE_OUTLINED}
              onClick={handleMinusClick}
            />
            <div className="count">{countRef[data.id]}</div>
          </>
        }
        <Icon
          className="item-plus"
          type={IconType.PLUS_CIRCLE_OUTLINED}
          onClick={handlePlusClick}
        />
      </div>
    );
  }
  const price = Number(data.price) || 0;
  return (
    <div className={`${isExist ? 'modifier-wrapper modifier-wrapper-selected' : 'modifier-wrapper'} ${data.soldOut ? 'sold-out-modifier' : ''}`} onClick={wrapperOnClick}>
      <div className="m">
        <div className="sub-name">
          {getI18nText(data, 'option_name', reducersState.app.language) || getI18nText(data, 'name', reducersState.app.language) || data.name}
        </div>
        {
          price !== 0 &&
          <div className="sub-price">+{formatCurrency(data.price, currencyCode)}</div>
        }
      </div>
      {
        !data.soldOut &&
        <>
          {buttonsOneOptionModeView}
          {buttonsMultipleModeView}
          {buttonsHasSubOptionModeView}
        </>
      }
      {
        data.soldOut &&
        <div className="item-count">
          {getIntl().page.soldOut}
        </div>
      }
    </div>
  );
}

export default Modifier;
