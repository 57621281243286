import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import withRoute from '@/pages/withRoute';
import CategoryPage from './CategoryPage';
// import './CategoryPage.less';

export const prefix = 'bm-p-category';

const RouteComponent: React.FC<RouteComponentProps> = props => withRoute({
  pageComponent: CategoryPage,
  routeProps: props,
  pageTitleI18nKey: '',
});

export default RouteComponent;
