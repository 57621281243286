import React from 'react';
import InputItem from 'antd-mobile/es/input-item';
import Layout from '@/components/Layout';
import Icon, { IconType } from '@/components/Icon';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import { getAppTheme, setScreenColor, getIntl } from '@/components/App/App';
import { createRouteUrl, parseRouteParams } from '@/utils';
import CurrencyKV from '@/components/Payment/CurrencyKV';
import ActionType from '@/actions/action-type';
import { IStore, IStoreConfig } from '@/actions/store-action';
import paths from '@/routes/paths';
import { goToRoute } from '@/utils/route-util';
import { prefix } from '.';

export interface IStoreConfiguresPageProps extends IBasePageProps {}
export interface IStoreConfiguresPageState {
  showInformation: boolean;
  showOtherOrders: boolean;
  showSettings: boolean;
  showOrder: boolean;
  showFeedback: boolean;
  showNews: boolean;
  showAboutUs: boolean;
  showSelectProcedureMethod: boolean;
  showMenu: boolean;
  showFapiao: boolean;
  foodpanda: string;
  deliveroo: string;
  store: IStore | undefined;
  storeConfig: IStoreConfig | undefined;
  showPickUp: boolean;
  showDelivery: boolean;
  pickupAdvanceOrderDate: string;
  deliveryAdvanceOrderDate: string;
  pickupInterval: string;
  deliveryInterval: string;
  deliveryMiles: string;
  deliveryFee: string;
  deliveryMinAmount: string;
  deliveryDesc: string;
}

class StoreConfiguresPage extends BasePage<IStoreConfiguresPageProps, IStoreConfiguresPageState> {
  state: IStoreConfiguresPageState = {
    showInformation: true,
    showOtherOrders: true,
    showSettings: true,
    showOrder: false,
    showFeedback: false,
    showNews: false,
    showAboutUs: false,
    showSelectProcedureMethod: false,
    showMenu: false,
    showFapiao: false,
    foodpanda: '',
    deliveroo: '',
    store: undefined,
    storeConfig: undefined,
    showPickUp: true,
    showDelivery: true,
    pickupAdvanceOrderDate: '',
    deliveryAdvanceOrderDate: '',
    pickupInterval: '',
    deliveryInterval: '',
    deliveryMiles: '',
    deliveryFee: '',
    deliveryMinAmount: '',
    deliveryDesc: '',
  }
  componentDidMount () {
    const appTheme = getAppTheme();
    if (appTheme.lightBackgroundColor) {
      setScreenColor(appTheme.lightBackgroundColor);
    }
    super.componentDidMount();
    const routeParams = parseRouteParams(this.props);
    const { dispatch, reducersState, history } = this.props;
    const { userInfo } = reducersState.user;
    if (userInfo && userInfo.staff) {
      dispatch({
        type: ActionType.LOADING,
        showLoading: true
      })
      dispatch({
        type: ActionType.QUERY_STORE,
        storeSlug: routeParams.storeSlug,
        callback: (store, storeConfig) => {
          if (store) {
            if (storeConfig) {
              this.setState({
                showOrder: storeConfig.showOrder,
                showFeedback: storeConfig.showFeedback,
                showNews: storeConfig.showNews,
                showAboutUs: storeConfig.showAboutUs,
                showSelectProcedureMethod: storeConfig.autoShowProcurementMethod,
                showMenu: storeConfig.showMenu,
                showFapiao: storeConfig.fapiao,
                foodpanda: storeConfig.foodpanda,
                deliveroo: storeConfig.deliveroo,
                pickupAdvanceOrderDate: storeConfig.pickUpAdvanceOrderDate !== 0 ? storeConfig.pickUpAdvanceOrderDate.toString() : '',
                deliveryAdvanceOrderDate: storeConfig.deliveryAdvanceOrderDate !== 0 ? storeConfig.deliveryAdvanceOrderDate.toString() : '',
                pickupInterval: storeConfig.pickUpInterval !== 0 ? storeConfig.pickUpInterval.toString() : '',
                deliveryInterval: storeConfig.deliveryInterval !== 0 ? storeConfig.deliveryInterval.toString() : '',
                deliveryMiles: storeConfig.deliveryMiles !== 0 ? storeConfig.deliveryMiles.toString() : '',
                deliveryFee: storeConfig.deliveryFee !== 0 ? storeConfig.deliveryFee.toString() : '',
                deliveryDesc: storeConfig.deliveryDesc,
                deliveryMinAmount: storeConfig.deliveryMinAmount !== 0 ? storeConfig.deliveryMinAmount.toString() : '',
                store,
                storeConfig,
              })
            } else {
              this.setState({store})
            }
            dispatch({
              type: ActionType.LOADING,
              showLoading: false
            })
          }
        }
      })
    } else {
      goToRoute({
        history,
        pathname: createRouteUrl(
          paths.ACCOUNT,
        ),
      });
    }
  }

  handleLeftClick = () => {
    const { history } = this.props;
    const routeParams = parseRouteParams(this.props);
    goToRoute({
      history,
      pathname: createRouteUrl(
        paths.ADMIN_STORE_EDIT,
        {
          storeSlug: routeParams.storeSlug,
        }
      ),
    });
  }

  handleSave = () => {
    const {
      showOrder,
      showFeedback,
      showNews,
      showAboutUs,
      showSelectProcedureMethod,
      showMenu,
      showFapiao,
      foodpanda,
      deliveroo,
      pickupAdvanceOrderDate,
      pickupInterval,
      deliveryMiles,
      deliveryFee,
      deliveryDesc,
      deliveryAdvanceOrderDate,
      deliveryInterval,
      storeConfig,
      store,
      deliveryMinAmount,
    } = this.state;
    const { dispatch, reducersState, history } = this.props;
    const { platformConfig } = reducersState.app;
    const routeParams = parseRouteParams(this.props);
    if (store) {
      dispatch({
        type: ActionType.UPDATE_STORE_CONFIGS,
        showFeedback,
        showNews,
        showAboutUs,
        showSelectProcedureMethod,
        showMenu,
        showFapiao,
        foodpanda,
        deliveroo,
        pickupAdvanceOrderDate,
        pickupInterval,
        deliveryMiles,
        deliveryFee,
        deliveryDesc,
        deliveryAdvanceOrderDate,
        deliveryInterval,
        showOrder,
        storeID: store.id,
        platformID: platformConfig.platformID,
        storeConfig,
        deliveryMinAmount,
        callback: storesConfig => {
          if (storesConfig && storesConfig.id) {
            goToRoute({
              history,
              pathname: createRouteUrl(
                paths.ADMIN_STORE_EDIT,
                {
                  storeSlug: routeParams.storeSlug,
                }
              ),
            });
          }
        }
      })
    }
  }

  render() {
    const {
      showInformation,
      showOtherOrders,
      showSettings,
      showPickUp,
      showDelivery,
      showOrder,
      showFeedback,
      showNews,
      showAboutUs,
      showSelectProcedureMethod,
      showMenu,
      showFapiao,
      store,
      foodpanda,
      deliveroo,
      pickupAdvanceOrderDate,
      pickupInterval,
      deliveryMiles,
      deliveryFee,
      deliveryDesc,
      deliveryMinAmount,
      deliveryAdvanceOrderDate,
      deliveryInterval,
    } = this.state;

    const { reducersState } = this.props;
    const { platformConfig } = reducersState.app;

    return (
      <Layout
        {...this.props}
        className={prefix}
        navIcon={<Icon className="back-icon" type={IconType.LEFT_OUTLINED} />}
        navContent={getIntl().page.storeConfigures}
        onLeftClick={this.handleLeftClick}
        isFooter={false}
      >
        {
          store &&
          <div>
            <div className="category">
              <div className="category-title">{getIntl().page.information}</div>
              <div className="fold-icon">
                {
                  showInformation &&
                  <Icon type={IconType.DOWN_OUTLINED} onClick={() => this.setState({showInformation: false})} />
                }
                {
                  !showInformation &&
                  <Icon type={IconType.RIGHT_OUTLINED} onClick={() => this.setState({showInformation: true})} />
                }
              </div>
            </div>
            {
              showInformation &&
              <div className="information">
                <div className="information-line">
                  <div className="information-line-title">{getIntl().page.platformID}</div>
                  <div className="information-line-data">{platformConfig.platformID}</div>
                </div>
                <div className="information-line">
                  <div className="information-line-title">{getIntl().page.storeID}</div>
                  <div className="information-line-data">{store.id}</div>
                </div>
                <div className="information-line">
                  <div className="information-line-title">{getIntl().page.storeName}</div>
                  <div className="information-line-data">{store.name}</div>
                </div>
              </div>
            }
            <div className="category">
              <div className="category-title">{getIntl().page.otherOrders}</div>
              <div className="fold-icon">
                {
                  showOtherOrders &&
                  <Icon type={IconType.DOWN_OUTLINED} onClick={() => this.setState({showOtherOrders: false})} />
                }
                {
                  !showOtherOrders &&
                  <Icon type={IconType.RIGHT_OUTLINED} onClick={() => this.setState({showOtherOrders: true})} />
                }
              </div>
            </div>
            {
              showOtherOrders &&
              <div className="other-orders">
                <div className="input-line">
                  <div className="title">Foodpanda{getIntl().page.url}</div>
                  <div className="other-orders-input">
                    <InputItem
                      value={foodpanda}
                      onChange={value => this.setState({foodpanda: value.trim()})}
                    />
                  </div>
                </div>
                <div className="input-line">
                  <div className="title">Deliveroo{getIntl().page.url}</div>
                  <div className="other-orders-input">
                    <InputItem
                      value={deliveroo}
                      onChange={value => this.setState({deliveroo: value.trim()})}
                    />
                  </div>
                </div>
              </div>
            }
            <div className="category">
              <div className="category-title">{getIntl().page.setting}</div>
              <div className="fold-icon">
                {
                  showSettings &&
                  <Icon type={IconType.DOWN_OUTLINED} onClick={() => this.setState({showSettings: false})} />
                }
                {
                  !showSettings &&
                  <Icon type={IconType.RIGHT_OUTLINED} onClick={() => this.setState({showSettings: true})} />
                }
              </div>
            </div>
            {
              showSettings &&
              <div className="settings">
                <div className={`settings-option${showOrder ? ' select' : ''}`} onClick={() => this.setState({showOrder: !showOrder})}>
                  <div className="settings-option-title">{getIntl().page.orderFunc}</div>
                  <div className="settings-option-select">
                    {
                      showOrder &&
                      <Icon type={IconType.CHECKED_OUTLINED} />
                    }
                    {
                      !showOrder &&
                      <Icon type={IconType.UN_SELECTED_OUTLINED} />
                    }
                  </div>
                </div>
                <div className={`settings-option${showFeedback ? ' select' : ''}`} onClick={() => this.setState({showFeedback: !showFeedback})}>
                  <div className="settings-option-title">{getIntl().page.feedback}</div>
                  <div className="settings-option-select">
                    {
                      showFeedback &&
                      <Icon type={IconType.CHECKED_OUTLINED} />
                    }
                    {
                      !showFeedback &&
                      <Icon type={IconType.UN_SELECTED_OUTLINED} />
                    }
                  </div>
                </div>
                <div className={`settings-option${showNews ? ' select' : ''}`} onClick={() => this.setState({showNews: !showNews})}>
                  <div className="settings-option-title">{getIntl().page.newsFunc}</div>
                  <div className="settings-option-select">
                    {
                      showNews &&
                      <Icon type={IconType.CHECKED_OUTLINED} />
                    }
                    {
                      !showNews &&
                      <Icon type={IconType.UN_SELECTED_OUTLINED} />
                    }
                  </div>
                </div>
                <div className={`settings-option${showAboutUs ? ' select' : ''}`} onClick={() => this.setState({showAboutUs: !showAboutUs})}>
                  <div className="settings-option-title">{getIntl().page.aboutUsFunc}</div>
                  <div className="settings-option-select">
                    {
                      showAboutUs &&
                      <Icon type={IconType.CHECKED_OUTLINED} />
                    }
                    {
                      !showAboutUs &&
                      <Icon type={IconType.UN_SELECTED_OUTLINED} />
                    }
                  </div>
                </div>
                <div className={`settings-option${showSelectProcedureMethod ? ' select' : ''}`} onClick={() => this.setState({showSelectProcedureMethod: !showSelectProcedureMethod})}>
                  <div className="settings-option-title">{getIntl().page.selectProcedureMethod}</div>
                  <div className="settings-option-select">
                    {
                      showSelectProcedureMethod &&
                      <Icon type={IconType.CHECKED_OUTLINED} />
                    }
                    {
                      !showSelectProcedureMethod &&
                      <Icon type={IconType.UN_SELECTED_OUTLINED} />
                    }
                  </div>
                </div>
                <div className={`settings-option${showMenu ? ' select' : ''}`} onClick={() => this.setState({showMenu: !showMenu})}>
                  <div className="settings-option-title">{getIntl().page.menusTitle}</div>
                  <div className="settings-option-select">
                    {
                      showMenu &&
                      <Icon type={IconType.CHECKED_OUTLINED} />
                    }
                    {
                      !showMenu &&
                      <Icon type={IconType.UN_SELECTED_OUTLINED} />
                    }
                  </div>
                </div>
                <div className={`settings-option${showFapiao ? ' select' : ''}`} onClick={() => this.setState({showFapiao: !showFapiao})}>
                  <div className="settings-option-title">{getIntl().page.invoice}</div>
                  <div className="settings-option-select">
                    {
                      showFapiao &&
                      <Icon type={IconType.CHECKED_OUTLINED} />
                    }
                    {
                      !showFapiao &&
                      <Icon type={IconType.UN_SELECTED_OUTLINED} />
                    }
                  </div>
                </div>
              </div>
            }
            {
              store.pickUp &&
              <div>
                <div className="category">
                  <div className="category-title">{getIntl().page.takeAwaySetup}</div>
                  <div className="fold-icon">
                    {
                      showPickUp &&
                      <Icon type={IconType.DOWN_OUTLINED} onClick={() => this.setState({showPickUp: false})} />
                    }
                    {
                      !showPickUp &&
                      <Icon type={IconType.RIGHT_OUTLINED} onClick={() => this.setState({showPickUp: true})} />
                    }
                  </div>
                </div>
                <div className="input-line">
                  <div className="title">{getIntl().page.deliveryAdvanceOrderDays}</div>
                  <div className="other-orders-input">
                    <InputItem
                      value={pickupAdvanceOrderDate}
                      onChange={value => this.setState({pickupAdvanceOrderDate: value})}
                      type={'number'}
                    />
                  </div>
                </div>
                <div className="input-line">
                  <div className="title">{getIntl().page.PickUpInterval}</div>
                  <div className="time-select">
                    <div className={`${pickupInterval === '5'? 'select': ''}`} onClick={() => this.setState({pickupInterval: '5'})}>5m</div>
                    <div className={`${pickupInterval === '10'? 'select': ''}`} onClick={() => this.setState({pickupInterval: '10'})}>10m</div>
                    <div className={`${pickupInterval === '15'? 'select': ''}`} onClick={() => this.setState({pickupInterval: '15'})}>15m</div>
                    <div className={`${pickupInterval === '30'? 'select': ''}`} onClick={() => this.setState({pickupInterval: '30'})}>30m</div>
                    <div className={`${pickupInterval === '45'? 'select': ''}`} onClick={() => this.setState({pickupInterval: '45'})}>45m</div>
                    <div className={`${pickupInterval === '60'? 'select': ''}`} onClick={() => this.setState({pickupInterval: '60'})}>60m</div>
                  </div>
                </div>
              </div>
            }
            {
              store.delivery &&
              <div>
                <div className="category">
                  <div className="category-title">{getIntl().page.deliverySetup}</div>
                  <div className="fold-icon">
                    {
                      showDelivery &&
                      <Icon type={IconType.DOWN_OUTLINED} onClick={() => this.setState({showDelivery: false})} />
                    }
                    {
                      !showDelivery &&
                      <Icon type={IconType.RIGHT_OUTLINED} onClick={() => this.setState({showDelivery: true})} />
                    }
                  </div>
                </div>
                <div className="input-line">
                  <div className="title">{getIntl().page.deliveryAdvanceOrderDays}</div>
                  <div className="other-orders-input">
                    <InputItem
                      value={deliveryAdvanceOrderDate}
                      onChange={value => this.setState({deliveryAdvanceOrderDate: value})}
                      type={'number'}
                    />
                  </div>
                </div>
                <div className="input-line">
                  <div className="title">{getIntl().page.PickUpInterval}</div>
                  <div className="time-select">
                    <div className={`${deliveryInterval === '5'? 'select': ''}`} onClick={() => this.setState({deliveryInterval: '5'})}>5m</div>
                    <div className={`${deliveryInterval === '10'? 'select': ''}`} onClick={() => this.setState({deliveryInterval: '10'})}>10m</div>
                    <div className={`${deliveryInterval === '15'? 'select': ''}`} onClick={() => this.setState({deliveryInterval: '15'})}>15m</div>
                    <div className={`${deliveryInterval === '30'? 'select': ''}`} onClick={() => this.setState({deliveryInterval: '30'})}>30m</div>
                    <div className={`${deliveryInterval === '45'? 'select': ''}`} onClick={() => this.setState({deliveryInterval: '45'})}>45m</div>
                    <div className={`${deliveryInterval === '60'? 'select': ''}`} onClick={() => this.setState({deliveryInterval: '60'})}>60m</div>
                  </div>
                </div>
                <div className="input-line">
                  <div className="title">{getIntl().page.deliveryMiles}</div>
                  <div className="other-orders-input">
                    <InputItem
                      value={deliveryMiles}
                      onChange={value => {this.setState({deliveryMiles: value.replace(/[^0-9.]/g, '')})}}
                      onBlur={value => this.setState({deliveryMiles: value ? value.match(/^(0|([1-9]\d*))(\.\d+)?$/g)?.join('') || '': ''})}
                      extra={'km'}
                    />
                  </div>
                </div>
                <div className="input-line">
                  <div className="title">{getIntl().page.deliveryFee}</div>
                  <div className="other-orders-input">
                    <InputItem
                      value={deliveryFee}
                      onChange={value => {this.setState({deliveryFee: value.replace(/[^0-9.]/g, '')})}}
                      onBlur={value => this.setState({deliveryFee: value ? value.match(/^(0|([1-9]\d*))(\.\d+)?$/g)?.join('') || '': ''})}
                      extra={CurrencyKV[store.currencyCode]}
                    />
                  </div>
                </div>
                <div className="input-line">
                  <div className="title">{getIntl().page.deliveryMinAmount}</div>
                  <div className="other-orders-input">
                    <InputItem
                      value={deliveryMinAmount}
                      onChange={value => {this.setState({deliveryMinAmount: value.replace(/[^0-9.]/g, '')})}}
                      onBlur={value => this.setState({deliveryMinAmount: value ? value.match(/^(0|([1-9]\d*))(\.\d+)?$/g)?.join('') || '': ''})}
                      extra={CurrencyKV[store.currencyCode]}
                    />
                  </div>
                </div>
                <div className="input-line">
                  <div className="title">{getIntl().page.deliveryDesc}</div>
                  <div className="other-orders-input">
                    <InputItem
                      value={deliveryDesc}
                      onChange={value => {this.setState({deliveryDesc: value})}}
                    />
                  </div>
                </div>
              </div>
            }
            <div className="bottom-botton">
              <div className="activated-botton" onClick={() => this.handleSave()}>
                {getIntl().common.save}
              </div>
            </div>
          </div>
        }
      </Layout>
    )
  }
}

export default StoreConfiguresPage;
