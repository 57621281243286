import React, { useState, useEffect } from 'react';
import InputItem from 'antd-mobile/es/input-item';
import ImagePicker from 'antd-mobile/es/image-picker';
import Icon, { IconType } from '@/components/Icon';
import SignUserName from '@/components/common/userComponents/NewUserName';
import CountryFlag from '@/components/common/CountryFlags';
import CountryCodeModal from '@/components/CountryCode/CountryCodeModal';
import { getDialCodeGroup, IDialCodeGroupItem, getIso2ItemFromCode } from '@/components/DialCodeGroup';
import { Popup } from '@/components/Modal';
import GoogleMap, { IGoogleMapAdress } from '@/lib/google-map';
import { getIntl } from '@/components/App/App';

interface IEditPageProps {
  prefix: string;
  storeName: string;
  areaCode: string;
  language: string;
  phoneNumber: string;
  zipCode: string;
  areaCodeData: IDialCodeGroupItem | undefined;
  country: string;
  storeAddress: string;
  edit: boolean;
  files: any[];
  delivery: boolean;
  pickUp: boolean;
  dineIn: boolean;
  dineInUnassigned: boolean;
  booking: boolean;
  queueing: boolean;
  handleEditInput: (data: EditInput) => void;
  handleEditSelect: (data: EditSelect) => void;
}

export interface EditInput {
  inputPhone?: string;
  inputStoreName?: string;
  inputStoreAddress?: string;
  inputZipCode?: string;
  inputAreaCodeData?: IDialCodeGroupItem;
  inputCountryAreaCodeData?: IDialCodeGroupItem;
  inputCity?: string;
  inputLat?: number | string;
  inputLng?: number | string;
  inputImage?: any[];
}

export interface EditSelect {
  delivery: boolean;
  pickUp: boolean;
  dineIn: boolean;
  dineInUnassigned: boolean;
  booking: boolean;
  queueing: boolean;
}

const EditPage: React.FC<IEditPageProps> = props => {
  const {
    prefix,
    storeName,
    language,
    phoneNumber,
    areaCodeData,
    zipCode,
    country,
    areaCode,
    storeAddress,
    edit,
    files,
    delivery,
    pickUp,
    dineIn,
    dineInUnassigned,
    booking,
    queueing,
    handleEditInput,
    handleEditSelect,
  } = props;

  const { dialCodeGroup, defaultDialCodeItem } = getDialCodeGroup(language);
  const [countryAreaCodeData, setCountryAreaCodeData] = useState<IDialCodeGroupItem>();
  const [showAreaCode, setShowAreaCode] = useState<boolean>(false);
  const [showAddress, setShowAddress] = useState(false);
  const [googleMapAdress, setGoogleMapAdress] = useState<IGoogleMapAdress>();
  const [suite, setSuite] = useState('');
  const [method, setMethod] = useState(true);

  useEffect(() => {
    if (country) {
      const data = getIso2ItemFromCode(country, language);
      setCountryAreaCodeData(data);
      handleEditInput({inputCountryAreaCodeData: data});
    } else {
      setCountryAreaCodeData(defaultDialCodeItem);
    }
  }, []);

  const handleItemClick = (data: IDialCodeGroupItem) => {
    setCountryAreaCodeData(data);
    handleEditInput({inputCountryAreaCodeData: data});
    setShowAreaCode(false);
  }

  const handleSave = () => {
    if (googleMapAdress && suite) {
      handleEditInput({
        inputStoreAddress: `${googleMapAdress.vicinity}${googleMapAdress.name}${suite}`,
        inputCity: googleMapAdress.cityInfo.long_name,
        inputLat: googleMapAdress.lat,
        inputLng: googleMapAdress.lng,
      })
      setShowAddress(false);
    }
  }

  return (
    <div>
      <div className="input-line">
        <div className="title">{getIntl().page.phone}</div>
        <div className="phone-input">
          <SignUserName
            language={language}
            value={phoneNumber}
            disabled={!edit}
            onChange={value => handleEditInput({inputPhone: value})}
            onItemClick={(data: IDialCodeGroupItem) => handleEditInput({inputAreaCodeData: data})}
            showIcon={true}
            type="PHONE"
            areaCode={areaCode}
            areaCodeData={areaCodeData || {areaCodes: null, dialCode: areaCode, iso2: '', name: '', priority: 0}}
          />
        </div>
      </div>
      <div className="input-line">
        <div className="title">{getIntl().page.storeName}</div>
        <div className="store-name-input">
          <InputItem
            value={storeName}
            disabled={!edit}
            onChange={value => handleEditInput({inputStoreName: value})}
            extra={
              storeName && edit &&
              <div>
                <Icon
                  type={IconType.CLOSE_OUTLINED}
                  className="card-icon-close"
                  onClick={() => handleEditInput({inputStoreName: ''})}
                />
              </div>
            }
          />
        </div>
      </div>
      <div className="input-line">
        <div className="title">{getIntl().page.country}</div>
        <div className="country-input" onClick={() => {if (edit)setShowAreaCode(true)}}>
          <CountryFlag
            type={`${countryAreaCodeData? countryAreaCodeData.iso2 : 'hk'}`}
          />
          <div className={`areacode-name ${edit ? '' : 'disable'}`}>
            {countryAreaCodeData && countryAreaCodeData.name}
          </div>
          {
            edit &&
            <Icon
              type={IconType.RIGHT_OUTLINED}
              className="down-icon"
            />
          }
        </div>
        <CountryCodeModal
          data={dialCodeGroup}
          visible={showAreaCode}
          onItemClick={handleItemClick}
          onCancel={() => setShowAreaCode(false)}
        />
      </div>
      <div className="input-line">
        <div className="title">{getIntl().page.storeAddress}</div>
        <div className={`shop-type-input ${edit ? '': 'disable'}`} onClick={() => edit && setShowAddress(true)}>
          <div className="shop-type">{storeAddress}</div>
          {
            edit &&
            <Icon
              type={IconType.RIGHT_OUTLINED}
              className="right-icon"
            />
          }
        </div>
      </div>
      <div className="input-line">
        <div className="title">{getIntl().page.zipCode}</div>
        <div className="zip-code-input">
          <InputItem
            value={zipCode}
            disabled={!edit}
            onChange={value => handleEditInput({inputZipCode: value})}
            extra={
              zipCode && edit &&
              <div>
                <Icon
                  type={IconType.CLOSE_OUTLINED}
                  className="card-icon-close"
                  onClick={() => handleEditInput({inputZipCode: ''})}
                />
              </div>
            }
          />
        </div>
      </div>
      <div className="input-line">
        <div className="title">{getIntl().page.storeImages}</div>
        <ImagePicker
          files={files}
          length={1}
          className="input-image"
          onChange={files => edit && handleEditInput({inputImage: files})}
          selectable={files.length < 1}
          multiple={false}
          disableDelete={!edit}
        />
      </div>
      <div className="category">
        <div className="category-title">{getIntl().page.setting}</div>
        <div className="fold-icon">
          {
            method &&
            <Icon type={IconType.DOWN_OUTLINED} onClick={() => setMethod(false)} />
          }
          {
            !method &&
            <Icon type={IconType.RIGHT_OUTLINED} onClick={() => setMethod(true)} />
          }
        </div>
      </div>
      {
        method &&
        <div className="settings">
          <div className={`settings-option${dineIn ? ' select' : ''}`} onClick={() => edit && handleEditSelect({dineIn: !dineIn, dineInUnassigned, pickUp, delivery, booking, queueing})}>
            <div className="settings-option-title">{getIntl().page.dineIn}</div>
            <div className="settings-option-select">
              {
                dineIn &&
                <Icon type={IconType.CHECKED_OUTLINED} />
              }
              {
                !dineIn &&
                <Icon type={IconType.UN_SELECTED_OUTLINED} />
              }
            </div>
          </div>
          <div className={`settings-option${dineInUnassigned ? ' select' : ''}`} onClick={() => edit && handleEditSelect({dineIn, dineInUnassigned: !dineInUnassigned, pickUp, delivery, booking, queueing})}>
            <div className="settings-option-title">{getIntl().page.dineInUnassigned}</div>
            <div className="settings-option-select">
              {
                dineInUnassigned &&
                <Icon type={IconType.CHECKED_OUTLINED} />
              }
              {
                !dineInUnassigned &&
                <Icon type={IconType.UN_SELECTED_OUTLINED} />
              }
            </div>
          </div>
          <div className={`settings-option${pickUp ? ' select' : ''}`} onClick={() => edit && handleEditSelect({dineIn, dineInUnassigned, pickUp: !pickUp, delivery, booking, queueing})}>
            <div className="settings-option-title">{getIntl().page.takeAway}</div>
            <div className="settings-option-select">
              {
                pickUp &&
                <Icon type={IconType.CHECKED_OUTLINED} />
              }
              {
                !pickUp &&
                <Icon type={IconType.UN_SELECTED_OUTLINED} />
              }
            </div>
          </div>
          <div className={`settings-option${delivery ? ' select' : ''}`} onClick={() => edit && handleEditSelect({dineIn, dineInUnassigned, pickUp, delivery: !delivery, booking, queueing})}>
            <div className="settings-option-title">{getIntl().page.delivery}</div>
            <div className="settings-option-select">
              {
                delivery &&
                <Icon type={IconType.CHECKED_OUTLINED} />
              }
              {
                !delivery &&
                <Icon type={IconType.UN_SELECTED_OUTLINED} />
              }
            </div>
          </div>
          <div className={`settings-option${booking ? ' select' : ''}`} onClick={() => edit && handleEditSelect({dineIn, dineInUnassigned, pickUp, delivery, booking: !booking, queueing})}>
            <div className="settings-option-title">{getIntl().page.booking}</div>
            <div className="settings-option-select">
              {
                booking &&
                <Icon type={IconType.CHECKED_OUTLINED} />
              }
              {
                !booking &&
                <Icon type={IconType.UN_SELECTED_OUTLINED} />
              }
            </div>
          </div>
          <div className={`settings-option${queueing ? ' select' : ''}`} onClick={() => edit && handleEditSelect({dineIn, dineInUnassigned, pickUp, delivery, booking, queueing: !queueing})}>
            <div className="settings-option-title">{getIntl().page.queueing}</div>
            <div className="settings-option-select">
              {
                queueing &&
                <Icon type={IconType.CHECKED_OUTLINED} />
              }
              {
                !queueing &&
                <Icon type={IconType.UN_SELECTED_OUTLINED} />
              }
            </div>
          </div>
        </div>
      }
      <Popup
        visible={showAddress}
        title={
          <div className="title">
            <div className="title-left"><Icon type={IconType.LEFT_OUTLINED} onClick={() => setShowAddress(false)} /></div>
            <div className="title-centre">{getIntl().page.address}</div>
            <div className="title-right"></div>
          </div>
        }
        onClose={() => setShowAddress(false)}
        className={`${prefix}-google-map`}
      >
        <GoogleMap
          detailAddress={
            <div>
              <div className="place">
                <div className="place-name">{googleMapAdress?.name}</div>
                <div className="place-vicinity">{googleMapAdress?.vicinity}</div>
              </div>
              <div className="dividing"></div>
              <div className="apt-suite">
                <div className="apt-suite-title">{getIntl().page.aptSuite}</div>
                <div className="apt-suite-input">
                  <InputItem
                    value={suite}
                    onChange={value => setSuite(value)}
                    extra={
                      suite &&
                      <div>
                        <Icon
                          type={IconType.CLOSE_OUTLINED}
                          className="card-icon-close"
                          onClick={() => setSuite('')}
                        />
                      </div>
                    }
                  />
                </div>
              </div>
              <div className={`save-button ${suite ? '' : 'disable'}`} onClick={() => handleSave()}>{getIntl().common.save}</div>
            </div>
          }
          savePlace={(data: IGoogleMapAdress) => {
            setGoogleMapAdress(data);
          }}
        />
      </Popup>
    </div>
  );
}

export default EditPage;
