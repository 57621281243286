import React from 'react';
import Icon, { IconType } from '@/components/Icon';
import { getIntl } from '@/components/App/App';

interface ICheckoutMethodButtonProps {
  title?: React.ReactNode;
  selected?: boolean;
  type?: IconType;
  onClick?: (selected: boolean) => void;
  // icon: React.ReactNode;
}

const CheckoutMethodButton: React.FC<ICheckoutMethodButtonProps> = props => {
  const {
    type,
    title,
    onClick,
    // icon = 1,
  } = props;

  const handleClick = (selected: boolean) => {
    if (onClick) onClick(selected);
  }

  return (
    <div className="div-block-525 _500px">
      <div className="mobile-signin-noti-block no-space">
        { type && <Icon className="html-embed-43 w-embed" type={type} /> }
        <div className="text-block-105">{title}</div>
      </div>
      <div className="alternative-block" onClick={() => handleClick(false)}>
        <div className="text-block-105">{getIntl().common.back}</div>
      </div>
    </div>
  );
}

export default CheckoutMethodButton;
