import Config from '@/Config';

/* eslint-disable */
let FBSDKInstance: any = null;
export const initFBSDK = () => {
  const FBWindow: any = window;
  if (!FBSDKInstance) {
    FBWindow.fbAsyncInit = function () {
      FBSDKInstance = FB;
      FB.init({
        appId: Config.faceBookAppID,
        xfbml: true,
        version: 'v8.0',
      });
      FB.AppEvents.logPageView();
    };

    (function (d, s, id) {
      let js: any;
      const fjs: any = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      if (fjs) {
        fjs.parentNode.insertBefore(js, fjs);
      }
    }(document, 'script', 'facebook-jssdk'));
  }
  return FBSDKInstance;
};

const CHECK_MAX = 5;

export const checkFBSDK = async (loadCount = 0): Promise<fb.FacebookStatic> => {
  const initFB = initFBSDK() || {};

  if (Object.keys(initFB).length > 0) {
    return initFB;
  }

  return new Promise<fb.FacebookStatic>(resolve => {
    if (loadCount >= CHECK_MAX) {
      resolve();
      return;
    };
    setTimeout(async () => {
      const result = await checkFBSDK(loadCount + 1);
      resolve(result);
    }, 1000);
  });
};

/* eslint-enable */

export const facebookLogin = async () => {
  const FB: fb.FacebookStatic = await checkFBSDK();
  if (!FB) return;
  try {
    return new Promise<fb.StatusResponse>(resolve => {
      FB.login(resp => {
        if (resp.authResponse && resp.authResponse.accessToken) {
          resolve(resp);
        }
      }, { scope: 'public_profile,email' });
      resolve();
    });
  } catch (e) {}
}

export const faceBookLogout = async () => {
  const FB: fb.FacebookStatic = await checkFBSDK();

  try {
    return new Promise(resolve => {
      FB.logout(resp => {
        resolve(resp);
      });
    })
  } catch (e) {}
}
