import styled from 'styled-components';
export const PrivilegePageNavStyled = styled.div`
    position: -webkit-sticky;
    position: sticky;
    z-index: 8;
  .tabs-menu-4 {
    top: 40px;
    position: -webkit-sticky;
    position: sticky;
    z-index: 6;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    max-height: none;
    max-width: 200px;
    margin-top: 0px;
    margin-right: 40px;
    margin-left: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .my-priviledge-tab {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20px;
    padding-right: 20px;
    padding-left: 20px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    background-color: hsla(0, 0%, 86.7%, 0);
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: var(--bm-text-color);
    font-size: 16px;
  }
  .my-priviledge-tab.w--current {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    border-radius: 40px;
    background-color: var(--bm-primary-color);
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: var(--bm-light-background-color);
    font-size: 16px;
    font-weight: 700;
  }
  .w-tab-link {
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-decoration: none;
    padding: 9px 30px;
    text-align: left;
    cursor: pointer;
    color: var(--bm-text-color);
  }
  .text-block-200 {
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
  }

@media screen and (max-width: 991px) {
  .tabs-menu-4 {
    top: 70px;
    width: auto;
    max-width: none;
    min-width: 0px;
    margin-right: 30px;
    margin-left: 0px;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .my-priviledge-tab.w--current {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
  }
  .my-priviledge-tab {
    padding-right: 20px;
    padding-left: 20px;
  }
}
@media screen and (max-width: 767px) {
  .tabs-menu-4 {
    /* top: 60px; */
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-height: 50px;
    max-width: 727px;
    min-height: 50px;
    margin-right: 20px;
    margin-left: 20px;
    padding-top: 0px;
    padding-bottom: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .show-redeemable-wrap {
    margin-bottom: 0;
  }
  .my-priviledge-tab.w--current {
    margin-bottom: 0px;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
    font-size: 16px;
  }
  .my-priviledge-tab {
    margin-bottom: 0px;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
    font-size: 18px;
  }
}
@media screen and (max-width: 479px) {
  .tabs-menu-4 {
    /* top: 50px; */
    position: -webkit-sticky;
    position: sticky;
    max-height: 50px;
    max-width: none;
    margin-right: 0px;
    margin-left: 0px;
    padding-top: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  /* .tabs-content {
    padding-top: 50px;;
  } */
  .my-priviledge-tab.w--current {
    padding: 5px 15px;
    font-size: 14px;
  }
  .my-priviledge-tab {
    padding-right: 15px;
    padding-left: 15px;
    font-size: 16px;
  }
}

`;

export const PrivilegePageStyled = styled.div`
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 85%;
  max-width: 85%;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 0px 30px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-radius: 12px;
  background-color: var(--bm-dark-background-color);

.w-tab-content {
  position: relative;
  display: block;
  overflow: hidden;
}
.tabs-content {
  margin-top: 30px;
  margin-bottom: 20px;
  margin-left: auto;
  padding: 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-radius: 0px;
  background-color: hsla(0, 0%, 100%, 0);
  /* padding-top: 70px; */
}
.section.cms {
  display: block;
  margin-top: 0px;
  margin-bottom: 40px;
  padding: 0px;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}
.section {
  display: block;
  margin-top: auto;
  margin-bottom: 0px;
  padding: 100px 40px 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0);
}
.div-block-537 {
  display: block;
  max-width: none;
  margin-right: auto;
  margin-left: auto;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}
.show-redeemable-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  .show-redeemable {
    padding: 8px 12px;
    border: 1px solid var(--bm-text-title-color);
    border-radius: 8px;
  }
}
.item-wrapper.coupon {
  margin-bottom: 20px;
}
.item-wrapper {
  display: block;
  border-radius: 8px;
  background-color: var(--bm-light-background-color);
}
.coupon-block.open-modal {
  overflow: hidden;
  margin-bottom: 0px;
  padding: 20px 20px 20px 0px;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  border-bottom-width: 0px;
  border-radius: 8px;
  background-color: #fff;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
 background-color: var(--bm-light-background-color);
}
.coupon-block {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  padding-top: 40px;
  padding-bottom: 40px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #f5f5f5;
  cursor: pointer;
}
.image-block.coupon {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  min-width: 140px;
  margin-top: -20px;
  margin-right: 15px;
  margin-bottom: -20px;
  padding-top: 0px;
  background-repeat: no-repeat;
}
.image-block {
  overflow: hidden;
  width: 120px;
  height: 120px;
  min-width: 120px;
  border-radius: 6px;
  background-position: 50% 50%;
  background-size: cover;
}
.status-veil {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 30%;
  margin-top: auto;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 69, 144, 0.6);
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: var(--bm-light-background-color);
  font-size: 22px;
}
.item-info-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 70%;
  min-width: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.item-info.with-space {
  margin-bottom: 10px;
}
.item-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 100%;
  margin-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.price-cart {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 100%;
  margin-top: auto;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.heading-4-1 {
  margin-top: 0px;
  margin-bottom: 10px;
  font-family: 'Sf pro display', sans-serif;
  color: var(--bm-text-title-color);
  font-size: 18px;
  font-weight: 600;
  text-align: left;
}
h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
}
.paragraph-2.less-space {
  margin-bottom: 5px;
}
.paragraph-2 {
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: var(--bm-text-title-color);
  font-size: 14px;
  line-height: 18px;
}
p {
  margin-bottom: 10px;
  font-size: 16px;
}
.text-block-91 {
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: var(--bm-text-title-color);
  font-size: 18px;
}
.text-block-6 {
  max-width: none;
  margin-top: 0px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  font-family: 'Sf pro display', sans-serif;
  color: var(--bm-text-title-color);
  font-size: 18px;
  font-weight: 600;
}
.nav-button-1 {
  display: none;
  max-height: 32px;
  max-width: 32px;
  min-height: 32px;
  min-width: 32px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(0, 0%, 100%, 0.4);
  border-radius: 80px;
  background-color: var(--bm-light-background-color);
  color: var(--bm-text-title-color);
  div,
  svg {
    width: 100%;
    height: 100%;
    color: var(--bm-text-title-color);
  }
}
.modal-body.coupon {
  overflow: scroll;
}
.item-image-pop-up-copy {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 6;
  width: 100%;
  height: 300px;
  max-width: none;
  min-width: auto;
  background-color: transparent;
  background-position: 50% 50%;
  background-size: 100% 100%;
}
.popup-description.coupon.coupon {
  height: auto;
  margin-top: 290px;
  border-radius: 0px;
  background-color: var(--bm-dark-background-color);
}
.popup-description.coupon {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.popup-description {
  position: relative;
  box-sizing: border-box;
  z-index: 6;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  margin-top: 280px;
  padding: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 16px;
  background-color:  var(--bm-light-background-color);
}
.popup-description.show-redeem-wrap {
  // position: absolute;
  // top: 350px;
  border-radius: 16px;
  justify-content: flex-start;
  height: 65%;
}
.popup-description .redeem-btn-wrap {
  position: absolute;
  bottom: 60px;
  width: 93%;
  padding: 0 20px;
  box-sizing: border-box;
}
.popup-description .redeem-btn {
  height: 50px;
  line-height: 50px;
}
.div-block-540 .description-con {
  overflow: auto;
  height: 60%;
}
.div-block-540 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  padding: 21px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-radius: 8px;
  background-color:  var(--bm-light-background-color);
}
.div-block-538 {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: var(--bm-light-background-color);
  background-position: 50% 50%;
  background-size: 100%;
  background-repeat: no-repeat;
}
.qr-code-block {
  position: relative;
  top: 0px;
  width: 250px;
  height: 250px;
  min-height: 250px;
  min-width: 250px;
  margin: -237px auto 30px;
  padding: 8px;
  border-style: solid;
  border-width: 2px;
  border-color: #eaeaea;
  border-radius: 8px;
}
.qr-code-cover {
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 8px;
  font-size: 32px;
  background-color: rgba(0,0,0,.35);
  text-align: center;
  align-items: center;
  .selected-outlined {
    background-color: var(--bm-light-background-color);
    border-radius: 50%;
    padding: 0;
    font-size: 70px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.heading-4-1.bigger {
  margin-right: auto;
  margin-left: auto;
  font-size: 23px;
}
.div-block-484 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.text-block-68.bold {
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  font-weight: 700;
}
.text-block-68.bold.color {
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: var(--bm-error-color);
  font-size: 18px;
}
.text-block-98 {
  color: var(--bm-text-title-color);
}
.div-block-540 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  padding: 21px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-radius: 8px;
  background-color: var(--bm-light-background-color);
}
.rich-text-block {
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
}
h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
}
p {
  margin-top: 0;
  margin-bottom: 10px;
}
.w-richtext ol, .w-richtext ul {
  overflow: hidden;
}
.c-popup-close.close-modal {
  background-color: var(--bm-modal-b-color);
}
.modal-body {
  position: relative;
  z-index: 6;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 600px;
  height: 90%;
  padding: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border-radius: 12px;
  background-color: var(--bm-light-background-color);
  box-shadow: 0 9px 50px -14px transparent;
  transform: unset;
}
.modal-wrapper {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 10;
  display: flex;
  padding: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-perspective: 850px;
  perspective: 850px;
  cursor: default;
  opacity: 1;
}
.modal-body.coupon {
  opacity: 1;
  background-color: var(--bm-dark-background-color);
}
.text-block-98 {
  margin-right: auto;
  margin-bottom: 0px;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  font-size: 18px;
  font-weight: 400;
}
.privilege {
  display: flex;
}

.bm-p-privilege-no-coupons {
  margin-top: 180px;
  display: flex;
    align-items: center;
    justify-content: center;

    .img {
      text-align: center;

      svg {
        height: 220px;
        width: 220px;
      }
    }
}


@media screen and (max-width: 991px) {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  max-width: 74%;
  margin-bottom: 0px;
  padding-right: 20px;
  padding-left: 20px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  .tabs-content {
    overflow: auto;
    margin-top: 40px;
    margin-left: 0px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .show-redeemable-wrap {
    margin-bottom: 4px;
  }
  .section.cms {
    width: auto;
    min-width: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .section {
    margin-top: 0px;
    padding: 84px 35px 30px;
  }
  .div-block-537 {
    display: -ms-grid;
    display: grid;
    overflow: visible;
    margin-right: 0px;
    margin-left: 0px;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  .item-wrapper.coupon {
    margin-bottom: 0px;
  }
  .coupon-block.open-modal {
    margin-bottom: 0px;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
  }
  .image-block.coupon {
    width: 25%;
    min-width: auto;
    margin-top: -10px;
    margin-right: 10px;
    margin-bottom: -10px;
  }
  .image-block {
    width: 80px;
    height: 80px;
    min-width: 80px;
  }
  .status-veil {
    font-size: 14px;
  }
  .item-info-wrapper {
    max-width: 80%;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .item-info {
    max-width: 90%;
  }
  .heading-4-1 {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .text-block-6 {
    font-size: 14px;
  }
  .popup-description.coupon.coupon {
    border-radius: 0px;
  }
  .div-block-540.no-space {
    margin-bottom: 0px;
  }
  .heading-4-1 {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .div-block-540.no-space {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) {
  padding-top: 50px;
  display: block;
  width: 100%;
  max-width: none;
  margin-top: 0px;
  border-radius: 0px;
  .privilege {
    display: none;
  }
  .tabs-content {
    margin-bottom: 0px;
    margin-top: 0;
  }
  .tab-pane {
    padding-top: 0px;
  }
  .section.cms {
    margin-right: 0px;
    padding-right: 30px;
    padding-left: 30px;
  }
  .section {
    padding: 66px 25px 20px;
  }
  .div-block-537 {
    margin-right: 0px;
    margin-left: 0px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .item-wrapper {
    width: 100%;
  }
  .coupon-block.open-modal {
    border-bottom-width: 1px;
  }
  .coupon-block {
    margin-bottom: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .paragraph-2 {
    font-size: 14px;
  }
  .heading-4-1 {
    margin-bottom: 10px;
    font-size: 18px;
  }
  h4 {
    font-family: 'Sf pro display', sans-serif;
    font-weight: 600;
  }
  .text-block-6 {
    font-size: 14px;
  }
  .item-image-pop-up-copy {
    z-index: auto;
  }
  .nav-button-1.close-modal {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
  }
  .popup-description.coupon {
    height: auto;
    padding-right: 20px;
    padding-left: 20px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .popup-description {
    z-index: 6;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    border-radius: 0px;
  }
  .heading-4-1 {
    margin-bottom: 10px;
    font-size: 18px;
  }
  .modal-body.coupon {
    overflow: visible;
    opacity: 1;
    width: 100%;
  }
  .modal-body {
    width: auto;
    height: 100vh;
    min-height: 100vh;
    border-radius: 0px;
  }
  .modal-wrapper {
    padding: 0px;
  } 

}

@media screen and (max-width: 479px) {
  padding-right: 12px;
  padding-left: 12px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  box-sizing: border-box;
  background-color: var(--bm-dark-background-color);
  margin-top: 0;
  .phone {
    .div-block-537 {
      margin-top: 68px;
    }
  }
  .tabs-content {
    padding-top: 0;
    margin-top: 40px;
  }
  .show-redeemable-wrap {
    margin-bottom: 0;
  }
  .modal-body {
    width: auto;
    height: 100%;
    min-height: auto;
    min-width: auto;
    padding: 20px 0px 0px;
    border-radius: 0px;
  }
  .whole {
    max-width: none;
    margin-right: 0px;
    margin-left: 0px;
    /* padding-top: 50px; */
  }
  .white-banner {
    position: fixed;
    top: 50px;
    display: block;
    height: 50px;
    max-height: 50px;
  }
  .section.cms {
    padding-right: 0px;
    padding-left: 0px;
  }
  .section {
    padding: 68px 12px 15px;
  }
  .coupon-block {
    margin-bottom: 0px;
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .image-block.coupon {
    width: 35%;
  }
  .image-block {
    width: 72px;
    height: 72px;
    min-width: auto;
  }
  .status-veil {
    text-align: center;
  }
  .item-info-wrapper {
    max-width: 73%;
    min-width: auto;
  }
  .item-info {
    overflow: visible;
    max-height: none;
    max-width: 90%;
  }
  .price-cart {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .heading-4-1 {
    margin-top: 0px;
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 16px;
  }
  h4 {
    font-size: 16px;
  }
  .paragraph-2.less-space {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }
  .paragraph-2 {
    margin-bottom: 5px;
    font-size: 12px;
    line-height: 14px;
  }
  p {
    font-size: 14px;
  }
  .text-block-91 {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    font-size: 14px;
  }
  .text-block-6 {
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
    font-family: 'Sf pro display', sans-serif;
    font-size: 14px;
    font-weight: 600;
  }
  .div-block-77 {
    margin-top: calc(var(--bm-safe-area-top) + 15px);
    padding-top: 10px;
    margin-right: 17px;
    margin-left: 17px;
  }
  .popup-description {
    position: relative;
    z-index: 6;
    width: 100vw;
    height: 100%;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .div-block-540 {
    margin-bottom: 15px;
    padding: 15px;
  }
  .qr-code-block {
    width: 210px;
    height: 210px;
    min-height: 210px;
    min-width: 210px;
    margin-top: -165px;
    margin-bottom: 20px;
  }
  .heading-4-1.bigger {
    margin-bottom: 10px;
    font-size: 18px;
  }
  .heading-4-1 {
    margin-top: 0px;
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 16px;
  }
  h4 {
    font-size: 16px;
  }
  .text-block-98 {
    font-size: 14px;
  }
  .text-block-68 {
    font-size: 14px;
  }
  .div-block-540 {
    margin-bottom: 15px;
    padding: 15px;
  }
  .modal-wrapper {
    display: flex;
    height: 100%;
    padding: 0px;
  }
  .popup-description .redeem-btn-wrap {
    position: absolute;
    bottom: 60px;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }
}
`;


