
/**
 * apple pay
 * auth: Joshua
 * 2020/07/16
 */
import {
  payInvoice,
  getApplePaySession,
} from '../payment-data';
import {
  handleBuildOrderPayment,
} from '../Utils';
import { IApplePayMethod, IPayOrderResult, IExtraActionParam, IWindowApple } from '..';


interface IApplePayParams {
  storeID: string;
  orderNumber: string;
  payData: IApplePayMethod;
  payment: any;
  extraAction?: IExtraActionParam;
}

const UseApplePay = (params: IApplePayParams) => {
  const {
    storeID,
    orderNumber,
    payment,
    payData,
    extraAction,
  } = params;

  const {
    amount,
    countryCode,
    currencyCode,
    label,
    supportedNetworks,
    appMerchantId,
    appMerchantDomain,
    appName = 'Bindo Gateway',
    merchantCapabilities = ['supports3DS', 'supportsEMV'],
  } = payData;


  const {
    ApplePaySession,
  } = window as IWindowApple;

  const applePay = async (): Promise<IPayOrderResult> => {
    if (!ApplePaySession) return;
    return new Promise((resolve, reject) => {

      const request = {
        total: {
          // 代表付款总额的订单项
          label,
          amount,
        },
        countryCode: countryCode, // 商家的双字母ISO 3166国家代码
        currencyCode: currencyCode, // 付款的三字母ISO 4217货币代码
        merchantCapabilities, // 商家支持的支付功能
        supportedNetworks, // 商家支持的支付网络
      };

      try {
        const session = new ApplePaySession(2, request);

        session.onvalidatemerchant = async (event: any) => {
          try {
            const response = await getApplePaySession(
              storeID,
              {
                validationURL: 'https://apple-pay-gateway.apple.com/paymentservices/startSession',
                appName,
                appMerchantId,
                appMerchantDomain,
              },
            );

            session.completeMerchantValidation(response);
          } catch (err) {
            console.error('商家验证失败', err);
            // 中止当前的Apple Pay会话
            session.abort();
          }
        };

        // 用户通过Touch ID，Face ID或密码授权Apple Pay付款时自动调用的回调函数
        session.onpaymentauthorized = async (event: any) => {
          session.completePayment(ApplePaySession.STATUS_SUCCESS);
          if (event.payment.token.paymentData) {
            const paymentDataStr = JSON.stringify(
              event.payment.token.paymentData,
            );
            try {
              const resp = await pay(window.btoa(paymentDataStr));

              resolve(resp);
            } catch (e) {
              session.abort();
              reject({
                message: e.message,
              });
              session.abort();
            }
            // 用结果完成支付授权
            session.completePayment(ApplePaySession.STATUS_SUCCESS);
          }
        };

        // 付款界面被取消时自动调用的回调函数
        session.oncancel = () => {
          if (extraAction && extraAction.onCancel) {
            extraAction.onCancel();
          }
        };

        session.begin();

      } catch (error) {
        console.error(error, 'apple pay error message')
      }
    });
  };

  const pay = async (paymentData: any) => {
    const newPayment = {
      'apple_pay': [
        {
          amount,
          'token_base64': paymentData,
          'merchant_identifier': appMerchantId,
        }
      ],
    };
    const order = handleBuildOrderPayment(newPayment, payment);
    try {
      const resp = await payInvoice(storeID, orderNumber, order);

      return resp;
    } catch (error) {
      throw(error);
    }
  }

  return {
    applePay,
  }
}

export default UseApplePay;
