import { IObject } from '@/components/bm-common';
import { ICreditCardMethod, IMasterMethod, IVisaMethod, IAmexMethod, IUnionPayMethod } from '.';

const isNumber = (val: any) => {
  if (parseFloat(val).toString() === 'NaN') {
    return false;
  } else {
    return true;
  }
}

export const handleBuildOrderPayment = (newPayment: IObject, payment?: IObject,) => {
  if (!payment) payment = {};
  return {
    order: {
      payment: {
        ...payment,
        ...newPayment,
      }
    },
    'auto_void_other_payments_when_fail': true,
  }
}

export const handle3DSMethod = (payCreditCardData: ICreditCardMethod | IAmexMethod | IVisaMethod | IMasterMethod | IUnionPayMethod) => {
  const {
    amount,
    getawapSafetyVerification,
    appSafetyVerification,
    safetyVerification,
    safetyVerificationAmount,
    appSafetyVerificationAmount,
  } = payCreditCardData;

  let use3DSAmount: string | boolean = false;
  let store3DSAmount: string | boolean = false;
  let app3DSAmount: string | boolean = false;

  if (safetyVerificationAmount !== undefined) {
    store3DSAmount = isNumber(parseFloat(safetyVerificationAmount.toString())) ? safetyVerificationAmount.toString() : false;
  }

  if (appSafetyVerificationAmount !== undefined) {
    app3DSAmount = isNumber(parseFloat(appSafetyVerificationAmount.toString())) ? appSafetyVerificationAmount.toString() : false;
  }

  const use3DS = getawapSafetyVerification && appSafetyVerification && safetyVerification;
  use3DSAmount = store3DSAmount || app3DSAmount;

  return use3DS && use3DSAmount && amount >= parseFloat(use3DSAmount);
}
