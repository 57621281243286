import React, { useState } from 'react';
import { IPlatformStoreMembership } from '@/actions/store-action';
import Icon, { IconType } from '@/components/Icon';
import CurrencyKV from '@/components/Payment/CurrencyKV';
import { getI18nText } from '@/utils/app';
import { getIntl } from '@/components/App/App';

interface IAboutPointsProps {
  platformStoreMembership: IPlatformStoreMembership[];
  language: string;
  abbreviation: string;
  platformLogoSvg: string;
  currencyCode: string;
}

const AboutPoints: React.FC<IAboutPointsProps> = props => {
  const [silver, setSilver] = useState(false);
  const [gold, setGold] = useState(false);
  const [black, setBlack] = useState(false);
  const {
    platformStoreMembership,
    language,
    abbreviation,
    platformLogoSvg,
    currencyCode,
  } = props;

  return (
    <div>
      {
        platformStoreMembership.length > 0 &&
        <div>
          {
            platformStoreMembership[0] &&
            <div className="member-card">
              <div className="member-card-top" style={{backgroundColor: platformStoreMembership[0].membershipLevelColor}}></div>
              <div className="member-card-content">
                <div className="member-card-content-left">
                  <div className="member-card-grade">{abbreviation} {getI18nText(platformStoreMembership[0], 'abbreviation', language)}</div>
                  <div className="member-card-upgrade-rules">
                    <div className="member-card-upgrade-rules-title">{getIntl().page.registeredAndSpent}</div>
                  </div>
                  <div className="member-card-view" onClick={() => setSilver(!silver)}>
                    {getIntl().page.viewDetail}
                    {silver && <Icon type={IconType.RIGHT_OUTLINED} />}
                    {!silver && <Icon type={IconType.DOWN_OUTLINED} />}
                  </div>
                </div>
                <div className="member-card-content-right">
                  <div className="member-card-icon">
                    <Icon
                      type={IconType.MEMBER_OUTLINED}
                      className="sliver-icon"
                      lightColor={platformStoreMembership[0].membershipLevelLightColor}
                      darkColor={platformStoreMembership[0].membershipLevelColor}
                      tag={platformStoreMembership[0].id}
                      logoSvg={platformLogoSvg}
                    />
                  </div>
                </div>
              </div>
              <div className={`member-card-detail ${silver ? 'show' : 'hide'}`}>{getIntl().page.pointDetail}</div>
            </div>
          }
          {
            platformStoreMembership[1] &&
            <div className="member-card">
              <div className="member-card-top" style={{backgroundColor: platformStoreMembership[1].membershipLevelColor}}></div>
              <div className="member-card-content">
                <div className="member-card-content-left">
                  <div className="member-card-grade">{abbreviation} {getI18nText(platformStoreMembership[1], 'abbreviation', language)}</div>
                  <div className="member-card-upgrade-rules">
                    <div className="member-card-upgrade-rules-title">{getIntl().page.spentYear}</div>
                    <div className="member-card-upgrade-rules-amount">{CurrencyKV[currencyCode]}15000</div>
                  </div>
                  <div className="member-card-view" onClick={() => setGold(!gold)}>
                    {getIntl().page.viewDetail}
                    {gold && <Icon type={IconType.RIGHT_OUTLINED} />}
                    {!gold && <Icon type={IconType.DOWN_OUTLINED} />}
                  </div>
                </div>
                <div className="member-card-content-right">
                  <div className="member-card-icon">
                    <Icon
                      type={IconType.MEMBER_OUTLINED}
                      className="sliver-icon"
                      lightColor={platformStoreMembership[1].membershipLevelLightColor}
                      darkColor={platformStoreMembership[1].membershipLevelColor}
                      tag={platformStoreMembership[1].id}
                      logoSvg={platformLogoSvg}
                    />
                  </div>
                </div>
              </div>
              <div className={`member-card-detail ${gold ? 'show' : 'hide'}`}>{getIntl().page.pointDetail}</div>
            </div>
          }
          {
            platformStoreMembership[2] &&
            <div className="member-card">
              <div className="member-card-top" style={{backgroundColor: platformStoreMembership[2].membershipLevelColor}}></div>
              <div className="member-card-content">
                <div className="member-card-content-left">
                  <div className="member-card-grade">{abbreviation} {getI18nText(platformStoreMembership[2], 'abbreviation', language)}</div>
                  <div className="member-card-upgrade-rules">
                    <div className="member-card-upgrade-rules-title">{getIntl().page.spentYear}</div>
                    <div className="member-card-upgrade-rules-amount">{CurrencyKV[currencyCode]}40000</div>
                  </div>
                  <div className="member-card-view" onClick={() => setBlack(!black)}>
                    {getIntl().page.viewDetail}
                    {black && <Icon type={IconType.RIGHT_OUTLINED} />}
                    {!black && <Icon type={IconType.DOWN_OUTLINED} />}
                  </div>
                </div>
                <div className="member-card-content-right">
                  <div className="member-card-icon">
                    <Icon
                      type={IconType.MEMBER_OUTLINED}
                      className="sliver-icon"
                      lightColor={platformStoreMembership[2].membershipLevelLightColor}
                      darkColor={platformStoreMembership[2].membershipLevelColor}
                      tag={platformStoreMembership[2].id}
                      logoSvg={platformLogoSvg}
                    />
                  </div>
                </div>
              </div>
              <div className={`member-card-detail ${black ? 'show' : 'hide'}`}>{getIntl().page.pointDetail}</div>
            </div>
          }
        </div>
      }
    </div>
  );
}

export default AboutPoints;
