import { IRoute } from '@/routes';
import paths from '@/routes/paths';
import HomePage from './HomePage';
import IconsPage from './IconsPage';
import DeviceInfoPage from './DeviceInfoPage';

export const mobileRoutes: IRoute[] = [
  {
    path: paths.HOME,
    component: HomePage,
  },
  {
    path: paths.ICON_LIST,
    component: IconsPage,
  },
  {
    path: paths.DEVICE_INFO,
    component: DeviceInfoPage,
  },
];

export default mobileRoutes;
