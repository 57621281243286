/**
 * tap go pay
 * auth: Joshua
 * 2020/07/16
*/
import {
  handleBuildOrderPayment,
} from '../Utils';
import { payInvoice } from '../payment-data';
import { IObject } from '@/components/bm-common';
import { ITapGoMethod } from '..';

interface ITapGoPayParams {
  storeID: string;
  orderNumber: string;
  payData: ITapGoMethod;
  payment: any;
}

const UseTapGoPay = (params: ITapGoPayParams) => {
  const {
    storeID,
    orderNumber,
    payment,
    payData,
  } = params;

  const {
    amount,
    returnUrl,
  } = payData;

  const tapGoPay = async () => {
    const newPayment = {
      'tap_go': [
        {
          amount,
          'in_web': {
            'return_url': returnUrl
          },
        }
      ],
    };

    const order = handleBuildOrderPayment(newPayment, payment);
    const resp = await payInvoice(storeID, orderNumber, order);
    const tapgoRes = tapGoWebPayDone(resp);
    return tapgoRes;
  }

  const tapGoWebPayDone = (resp: any) => {

    const {
      status,
      order: fulfilOrder,
      success,
    } = resp || {};

    if (fulfilOrder.sale_transactions.length !== 0) {
      try {
        let payinfo;

        if (success && status === 'ok' && fulfilOrder.sale_transactions && fulfilOrder.sale_transactions.length > 0) {
          fulfilOrder.sale_transactions.forEach((item: any) => {
            if (item.payment_method === 21 && item.acquirer_response_body) {
              if (typeof item.acquirer_response_body === 'object') {
                payinfo = item.acquirer_response_body.payinfo;
              } else {
                payinfo = JSON.parse(item.acquirer_response_body).payinfo;
              }
            }
          });
        }

        if (payinfo) {
          const payInfoDic = getParamFromPayInfo(payinfo);
          if (payInfoDic) {
            getTapGoPaySession(payInfoDic.url, {
              appId: payInfoDic.appId,
              merTradeNo: payInfoDic.merTradeNo,
              payload: payInfoDic.payload,
              paymentType: payInfoDic.paymentType,
              sign: payInfoDic.sign,
            });
          }
        }
        else {
          return{
            status: 500,
          };
        }
      } catch (error) {
        return{
          status: 500,
        };
      }
    } else {
      return {
        status: 500,
      };
    }
  }

  const getParamFromPayInfo = (url: any) => {
    let params: any = {};
    if (url.split('?').length > 1) {
      params['url'] = url.split('?')[0];
      url
        .split(/\?|&/)
        .filter((str: string) => str.indexOf('=') > -1)
        .map((str: string) => str.split('='))
        .forEach((arr: string[]) => {
          const temp = arr.shift() || '';
          params[temp.trim()] = arr.join('=').trim();
        });
    } else {
      params = undefined;
    }
    return params;
  }

  const getTapGoPaySession = (url: string, PARAMTERS: IObject) => {
    // 创建form表单
    const tempForm = document.createElement('form');
    tempForm.action = url;
    // 如需打开新窗口，form的target属性要设置为'_blank'
    tempForm.target = '_self';
    tempForm.method = 'POST';
    tempForm.style.display = 'none';
    tempForm.accept = 'application/json';
    // 添加参数
    for (const item in PARAMTERS) {
      const opt = document.createElement('input');
      opt.name = item;
      opt.type = 'hidden';
      opt.value = decodeURIComponent(PARAMTERS[item]);
      tempForm.appendChild(opt);
    }
    document.body.appendChild(tempForm);
    // 提交数据
    tempForm.submit();
  }

  return {
    tapGoPay,
  }
}
export default UseTapGoPay;
