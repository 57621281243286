import React, { useEffect, useState } from 'react';
import InputItem from 'antd-mobile/es/input-item';

import Icon, { IconType } from '../Icon';
import { ListingCountRef } from '@/actions/shopping-cart-action';
import { IReducersState } from '@/reducers';
import { IListing } from '@/lib/order-builder/utils/api';

export interface IRightSideContentProps {
  data: IListing;
  isOpen: boolean;
  listingCountRef: ListingCountRef;
  onPlusClick?: (data: IListing, quantity?: number, action?: string) => void;
  onMinusClick?: (data: IListing) => void;
  reducersState: IReducersState;
  handleChangeQuantityTo?: (data: IListing, quantity: number) => void;
}

const prefix = 'right-side-wrap';

const RightSideContent: React.FC<IRightSideContentProps> = props => {
  const {
    data,
    isOpen,
    onPlusClick,
    onMinusClick,
    handleChangeQuantityTo,
    listingCountRef = {},
  } = props;

  const soldOut = data.soldOut || false;
  const disable = soldOut || !isOpen || (data.status === 'Suspend') || (data.status === 'Deactivate');

  let initQuantity = 0;

  if (listingCountRef[data.id] && listingCountRef[data.id].quantity > 0) {
    initQuantity = listingCountRef[data.id].quantity;
  }

  const [quantity, setQuantity] = useState<string>('0');

  useEffect(() => {
    setQuantity(initQuantity.toString());
  }, [initQuantity]);

  const handleMinusClick = (evt: React.MouseEvent) => {
    evt.stopPropagation();
    if (disable) return;
    if (onMinusClick) {
      onMinusClick(data);
    }
  }

  const handlePlusClick = (evt: React.MouseEvent) => {
    evt.stopPropagation();
    if (disable) return;
    if (onPlusClick) {
      onPlusClick(data, 1, 'QUICK_ADD');
    }
  }

  // const handlePressEnter = (evt: React.KeyboardEvent) => {
  //   const code = evt.charCode || evt.keyCode;
  //   if (code === 13) { // 点击回车或者在搜索
  //     const changeQuantity = parseInt(quantity);
  //     if (!isNaN(changeQuantity) && changeQuantity > 0 && handleChangeQuantityTo) {
  //       handleChangeQuantityTo(data, changeQuantity);
  //     }
  //   }
  // }
  
  const handleCloseKeyboard = () => {
    let changeQuantity = parseInt(quantity);
    if (handleChangeQuantityTo) {
      if (isNaN(changeQuantity)) {
        changeQuantity = 0;
      }
      handleChangeQuantityTo(data, changeQuantity);
    }
  }

  return (
    <div className={`${prefix}`}>
      <div className={`${prefix}-left`}
        onClick={handleMinusClick}
      >
        <Icon
          className={`${prefix}-icon`}
          type={IconType.MINUS_OUTLINED}
        />
      </div>

      <div className={`${prefix}-mid`}>
        <InputItem
          type="bankCard"
          maxLength={4}
          disabled={disable}
          value={quantity}
          //onKeyUp={handlePressEnter}
          onBlur={handleCloseKeyboard}
          onChange={setQuantity}
        />
      </div>

      <div className={`${prefix}-right`}
        onClick={handlePlusClick}
      >
        <Icon
          className={`${prefix}-icon`}
          type={IconType.PLUS_OUTLINED}
        />
      </div>
    </div>
  );
}

export default RightSideContent;
