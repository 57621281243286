import styled from 'styled-components';
export const OrderDetailStyled = styled.div`
  .bm-p-order-detail-new {
    overflow: scroll;
    background-color: var(--bm-primary-color);
    .nav_bg.streched {
      z-index: 99;
      .open-modal {
        color: var(--bm-light-background-color);
      }
    }
    .primary-payment-confirmation-buttons {
      position: relative;
      left: auto;
      top: 0px;
      right: 0%;
      bottom: auto;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: auto;
      margin-right: 20px;
      padding: 15px;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      border-radius: 8px;
      color: var(--bm-primary-color);
      cursor: pointer;
      border-style: solid;
      border-width: 1px;
      border-color: var(--bm-primary-color);
    } 
  .section {
    .send-email {
      padding: 20px 0;
      background-color: var(--bm-primary-color);
      margin: 0;
      align-items: center;
      font-family: 'Fontsfree net sfprodisplay', sans-serif;
      font-size: 25PX;
      font-weight: 700;
      border-radius: 8PX;
      box-sizing: border-box;
      display: none;
      box-shadow: 0 1PX 7PX 0 var(--bm-shadow-color-t50);
      height: unset;
      text-align: center;
    }
    .right-block {
      .send-email {
        display: block;
      }
    }
  }
  .div-block-534 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .div-block-533 {
    width: 170px;
    height: 170px;
    margin-left: 40px;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .div-block-530 {
    position: relative;
    width: 70%;
    margin-bottom: 0px;
  }
  .am-steps-item-icon {
    .am-steps-icon {
      .bm-icon {
        width: 20px;
        height: 20px;
        border-radius: 40px;
        color: var(--bm-primary-color);
        padding: 0;
        background-color: var(--bm-primary-color);
        opacity: .4;
        svg {
          width: 100%;
          height: 100%;
        }
        }
      }
    }
  }
  .background-top.with-bg-v2 {
    height: 260px;
    background-color: var(--bm-primary-color);
  }
  .am-steps-item-title {
    color: var(--bm-text-title-color);
    font-size: 18px;
    font-weight: 400;
    left: -6px;
  }
  .remark-block-tablet {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    height: auto;
    max-width: 100%;
    min-width: 100%;
    margin-right: 0px;
    margin-bottom: 0px;
    padding: 13px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    border-radius: 12px;
    background-color: var(--bm-light-background-color);
    box-shadow: none;
    box-sizing: border-box;
  }
  .text-block-90 {
    margin-bottom: 10px;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: var(--bm-text-title-color);
    font-size: 18px;
    font-weight: 700;
  }
  .div-block-502 {
    margin-top: 0px;
    padding: 14px;
    border-radius: 8px;
  }
  .remark-note {
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: var(--bm-text-title-color);
    font-size: 16px;
  }
  .am-steps-label-vertical {
    .am-steps-item-icon {
      margin-left: 0;
      .am-steps-icon {
        width: 20px;
        height: 20px;
        padding: 0;
        font-size: 34px;
        top: unset;
      }
    }
  }
  .am-steps-item-content {
    width: unset;
    text-align: unset;
  }
  .am-steps-item-tail {
    padding: 0;
    margin: 0;
    width: 100%;
    padding-left: 20px;
    top: 17px;
  }
  .am-steps-item-tail::after  {
    content: '';
    display: inline-block;
    background: #ddd;
    height: 3px;
    width: 100%;
    transition: background 0.3s;
    position: relative;
    background-color: #ddd;
    left: 0;
    background: var(--bm-primary-color);
    opacity: .4;
  }
  .order-summary-block {
    margin-bottom: 20px;
  }
  .div-block-493 {
    padding-top: 20px;
  }
  .address-fields.no-line {
    padding: 20px 0px 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom-width: 0px;
    color: var(--bm-text-title-color);
    cursor: pointer;
    background-color: rgba(246, 246, 246, 0.4);
    padding-left: 20px;
    padding-top: 0;
    margin-top: 10px;
  }
  .address-fields {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 20px 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: solid;
    border-width: 0px 0px 1px;
    border-color: #000 #000 #eaeaea;
    color: var(--bm-text-title-color);
    margin: 0px -20px 20px;
    cursor: auto;
    .bm-icon {
      width: 29px;
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .text-block-69 {
    margin-right: 0px;
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    font-size: 16px;
  }
  .div-block-508 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  @media screen and (max-width: 991px) {
    .background-top.with-bg-v2 {
      height: 200px;
    }
    .primary-payment-confirmation-buttons {
      position: static;
      top: 0px;
      right: 0%;
      margin-right: 20px;
    }
    .right-block {
      margin-bottom: 0;
    }
    .bm-p-order-detail-new {
      .right-block.rounded-all {
        margin-bottom: 0;
      }
      .section {
        .send-email {
          display: block;
        }
        .right-block  {
          .send-email {
            display: none;
          }
        }
        .send-email {
          margin-top: 20PX;
          border-radius: 8PX;
        }
      }
      .order-block {
        margin-bottom: 0;
      }
    }

    .div-block-534 {
      margin-bottom: 30px;
    }
    .div-block-530 {
      margin-bottom: 0px;
    }
    .remark-block-tablet {
      display: block;
      margin-bottom: 20px;
      padding: 0px;
      border-radius: 0px;
      box-shadow: none;
    }
    .remark-note {
      font-size: 16px;
    }
  }
  @media screen and (max-width: 767px) {
    .bm-p-order-detail-new {
      .address-fields.no-line {
        padding-top: 10px;
        padding-left: 10px;
        cursor: pointer;
      }
    }
    .primary-payment-confirmation-buttons {
      left: auto;
      top: -22%;
      right: -5%;
      bottom: 0%;
    }
    .div-block-534 {
      margin-bottom: 20px;
    }
    .div-block-533 {
      margin-left: 20px;
    }
    .section {
      .send-email {
        font-size: 22px;
      }
    }
  }

  @media screen and (max-width: 479px) {
    .background-top.with-bg-v2 {
      height: 100px;
    }
    .bm-p-order-detail-new {
      .primary-payment-confirmation-buttons {
        left: auto;
        top: 0%;
        right: 0%;
        bottom: 0%;
        width: auto;
        height: auto;
        padding-top: 10px;
        padding-bottom: 10px;
        -webkit-align-self: center;
        -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
        margin-right: 15px;
      }
      .div-block-533 {
        width: 100px;
        height: 100px;
        margin-left: 30px;
      }
      .am-steps-item-icon {
        .am-steps-icon {
          .bm-icon {
            width: 15px;
            height: 15px;
            color: var(--bm-primary-color);
          }
        }
      }
      .section {
        .send-email {
          padding: 13px 0;
          margin-bottom: 10px;
          font-size: 16px;
          margin-top: 10px;
        }
      }
      .address-fields.no-line {
        margin-right: -13px;
        margin-left: -13px;
        padding-right: 13px;
        padding-bottom: 0px;
        padding-left: 13px;
      }
      .address-fields {
        padding: 10px 13px;
        .bm-icon {
          width: 22px;
          height: 29px;
        }
      }
      .text-block-69 {
        margin-right: 0px;
        font-size: 14px;
      }
    }
    .am-steps-item-tail {
      padding: 0;
      margin: 0;
      width: 100%;
      padding-left: 14px;
      top: 19px;
    }
    .secondary-payment-confirmation-buttons-copy {
      padding: 11px 20px;
    }

    .div-block-530 {
      width: 80%;
    }
    .am-steps-item-icon {
      .am-steps-icon {
        .bm-icon {
          width: 15px;
          height: 15px;
          color: var(--bm-primary-color);
        }
      }
    }
    .am-steps-item-title {
      font-size: 14px;
      font-weight: 400;
    }
    .div-block-502 {
      padding: 13px;
    }
    .remark-note {
      font-size: 14px;
    }
    .order-summary-block {
      margin-bottom: 10px;
    }
  }
`;
