import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IconType } from '../Icon';
import { IReducersState } from '@/reducers';
import SignMethodType from '../common/userComponents/MethodType';
import { SignMethod, IWantSignInStyled } from '../common/userComponents';
import supportMethod from '@/actions/sign-in-method';
import SignInMobile from './SignInMobile';
import SignInEmail from './SignInEmail';
import OtherSignIn from './OtherSignIn';
import { getIntl } from '../App/App';

interface ISignInProps {
  signInClick?: () => void;
  className?: string;
  forgotClick?: () => void;
  setSignMethod?: (data: SignMethodType) => void;
}

const SignIn: React.FC<ISignInProps> = props => {
  const {
    className = '',
    signInClick,
    forgotClick = () => {},
    setSignMethod = () => {},
    children,
  } = props;

  const reducersState: IReducersState = useSelector<
    IReducersState,
    IReducersState
  >(state => state);
  const supportSignMethod = reducersState.app.platformConfig.signInMethods;

  let signTypeInit: SignMethodType = SignMethodType.INIT;

  if (supportSignMethod.length === 1) {
    if (supportSignMethod.includes(supportMethod.PHONE)) {
      signTypeInit = SignMethodType.MOBILE;
    } else if (supportSignMethod.includes(supportMethod.EMAIL)) {
      signTypeInit = SignMethodType.EMAIL;
    }
  }

  const [signType, setSignType] = useState<SignMethodType>(signTypeInit);

  const handleSignType = (type: SignMethodType) => {
    setSignType(type);
    setSignMethod(type);
  };

  return (
    <IWantSignInStyled>
      <div className={`i-want-sign-in ${className}`}>
        <div className="div-block-520">
          <div>
            {(signType === SignMethodType.MOBILE ||
              signType === SignMethodType.INIT) &&
              supportSignMethod.includes(supportMethod.PHONE) &&
              supportSignMethod.length !== 1 && (
              <SignMethod
                label={getIntl().page.mobileSignin}
                iconType={IconType.PHONE_OUTLINED}
                signType={signType}
                onClick={() => handleSignType(SignMethodType.MOBILE)}
                onBack={() => handleSignType(SignMethodType.INIT)}
              />
            )}
            {(signType === SignMethodType.EMAIL ||
              signType === SignMethodType.INIT) &&
              supportSignMethod.includes(supportMethod.EMAIL) &&
              supportSignMethod.length !== 1 && (
              <SignMethod
                label={getIntl().page.emailSignin}
                iconType={IconType.EMAIL_OUTLINED}
                signType={signType}
                onClick={() => handleSignType(SignMethodType.EMAIL)}
                onBack={() => handleSignType(SignMethodType.INIT)}
              />
            )}
            {signType === SignMethodType.MOBILE && (
              <SignInMobile
                signInClick={signInClick}
                forgotClick={() => forgotClick()}
              />
            )}
            {signType === SignMethodType.EMAIL && (
              <SignInEmail
                signInClick={signInClick}
                forgotClick={() => forgotClick()}
              />
            )}
            {children}
          </div>
          {signType === SignMethodType.INIT && (
            <OtherSignIn signInClick={signInClick} />
          )}
        </div>
      </div>
    </IWantSignInStyled>
  );
};
export default SignIn;
