import React from 'react';
import * as H from 'history';
import { prefix } from '.';
import { goToRoute } from '@/utils/route-util';
import Icon, { IconType } from '../Icon';
import { parseScanUrl, setBodyScrollable } from '@/utils';

interface IQRCodeScanProps {
  history?: H.History<H.History.PoorMansUnknown>;
  prohibitHyperlink?: boolean;
  onSuccess?: (value: string) => void;
  onFail?: () => void;
}

class QRCodeScan extends React.Component<IQRCodeScanProps> {
  scannerRef: any;
  scanner: any;

  componentDidMount() {
    setBodyScrollable(false);
    const { onFail } = this.props;
    const Instascan = (window as any).Instascan;
    if (this.scannerRef && Instascan) {
      this.scanner = new Instascan.Scanner({
        video: this.scannerRef,
        continuous: true,
        mirror: false,
        captureImage: false,
        backgroundScan: true,
        refractoryPeriod: 5000,
        scanPeriod: 1,
      });
      this.scanner.addListener('scan', this.handleScan);
      this.scanner.stop();
      Instascan.Camera.getCameras().then((cameras: any) => {
        if (cameras.length > 0) {
          this.scanner.start(cameras[cameras.length - 1]);
        } else if (onFail) {
          onFail();
        }
      }).catch((e: any) => {
        if (onFail) {
          onFail();
        }
      });
    }
  }

  componentWillUnmount() {
    setBodyScrollable(true);
    if (this.scanner) {
      this.scanner.removeListener('scan', this.handleScan);
      this.scanner.stop();
      this.scanner = undefined;
    }
  }

  handleScan = (value = '') => {
    const { prohibitHyperlink = false, history, onSuccess } = this.props;
    const info = parseScanUrl(value);
    if (!prohibitHyperlink && value.startsWith('http') && !info.searchParams.order_number) {
      if (info.isSameEvn && info.isSamePlatform && info.pathname !== window.location.pathname && info.platformRoute && history && !info.search) {
        goToRoute({
          history,
          pathname: info.platformRoute,
        });
      } else {
        window.location.href = value;
      }
    }
    
    if (onSuccess) {
      onSuccess(value || '');
    }
  }

  render() {
    const { onFail } = this.props;
    return (
      <div className={prefix}>
        <video playsInline autoPlay controls={false} muted loop ref={(scanner: any) => this.scannerRef = scanner} />
        <div className="scan-bar">
          <Icon type={IconType.LEFT_OUTLINED} onClick={onFail} />
        </div>
      </div>
    );
  }
}

export default QRCodeScan;
