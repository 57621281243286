import { IFavoriteListing, IFavoriteListingInfo, IIQueryModifierListingsResult } from '@/actions/listing-action';
import { IObject } from '@/components/bm-common';

export const parseListring = (res: any[]) => {
  let appIcons: IIQueryModifierListingsResult | undefined = undefined;
  const storeIDRefListings: {[storeID: string]: IIQueryModifierListingsResult } = {};
  if (Array.isArray(res)) {
    res.forEach(item => {
      appIcons = {
        listingID: item.id,
        soldOut: item.sold_out,
      }
      storeIDRefListings[item.id] = appIcons;
    })
  }
  return storeIDRefListings;
}

export const initFavoriteListingInfo = (): IFavoriteListingInfo => ({
  loaded: false,
  selectStoreId: '',
  storeIDs: [],
  storeSlugs: [],
  favoriteListings: [],
  refFavoriteIDs: {},
  listingDefaultCountData: {},
  storesFavoriteListingTabs: [],
  platformStore: undefined,
});

export const parseFavoriteListingRes = (res: IObject) => {
  let favoriteListing: IFavoriteListing | undefined = undefined;

  if (res && res.store_id && res.store_slug && res.favorite_tab_id && res.favorite_section_id && res.favorite_id) {
    favoriteListing = {
      id: res.id,
      platformID: res.platform_id,
      favoriteID: `${res.favorite_id}`,
      favoriteSectionID: `${res.favorite_section_id}`,
      favoriteTabID: `${res.favorite_tab_id}`,
      storeID: `${res.store_id}`,
      storeSlug: `${res.store_slug}`,
    };

    if (res.cart_date && res.cart_date.count) {
      favoriteListing.cartData = {
        count: Number(res.cart_date.count) || 0
      }
    }
  }

  return favoriteListing;
}

export const parseFavoriteListingsRes = (favoriteListingsRes: IObject[]) => {
  const favoriteListingInfo = initFavoriteListingInfo();

  favoriteListingsRes.forEach(item => {
    const favoriteListing = parseFavoriteListingRes(item);
    if (favoriteListing) {
      favoriteListingInfo.favoriteListings.push(favoriteListing);
      if (!favoriteListingInfo.storeIDs.includes(favoriteListing.storeID)) {
        favoriteListingInfo.storeIDs.push(favoriteListing.storeID);
        favoriteListingInfo.storeSlugs.push(favoriteListing.storeSlug);
      }
      const key = `${favoriteListing.storeID}.${favoriteListing.favoriteTabID}.${favoriteListing.favoriteSectionID}`;

      if (!favoriteListingInfo.refFavoriteIDs[key]) {
        favoriteListingInfo.refFavoriteIDs[key] = [];
      }

      favoriteListingInfo.refFavoriteIDs[key].push(favoriteListing.favoriteID);
      if (favoriteListing.cartData && favoriteListing.cartData.count > 0) {
        favoriteListingInfo.listingDefaultCountData[`${key}.${favoriteListing.favoriteID}`] = favoriteListing.cartData.count;
      }
    }
  });

  return favoriteListingInfo;
}
