import React from 'react';
import { Player } from 'video-react';
import { prefix } from './index';

interface IVideoViewProps {
  src: string;
  className?: string;
}

class VideoPlayer extends React.Component<IVideoViewProps> {
  player: any;

  componentDidMount() {
    if (this.player && this.player.play) {
      this.player.play();
    }
  }

  render() {
    const {
      src,
      className = '',
    } = this.props;

    return (
      <Player
        className={`${prefix} ${className}`}
        autoPlay={true}
        playsInline={true}
        preload="auto"
        loop={true}
        muted={true}
        controls={false}
        ref={(player: any) => {
          this.player = player;
        }}
      >
        <source src={src} />
      </Player>
      // <video
      //   src={src}
      //   className={`${prefix} ${className}`}
      //   autoPlay={true}
      //   muted={true}
      //   controls={false}
      //   loop={true}
      //   playsInline={true}
      //   ref={(player: any) => {
      //     this.player = player;
      //   }}
      // />
    );
  }
  
}

export default VideoPlayer;
