import React from 'react';
import Icon, { IconType } from '@/components/Icon';
import SignMethodType from './MethodType';
import { SignMethodStyled } from './styled';
import { getIntl } from '@/components/App/App';

interface ISignMethodProps {
  iconType: IconType;
  label: string;
  signType: SignMethodType;
  className?: string;
  onClick?: () => void;
  onBack?: () => void;
}

const SignMethod: React.FC<ISignMethodProps> = props => {

  const {
    label,
    iconType,
    signType,
    onClick = () => {},
    onBack= () => {},
  } = props;

  const initType = signType === SignMethodType.INIT;

  return (
    <SignMethodStyled>
      <div className={`item-button-main ${initType ? 'init' : 'selected'}`} onClick={onClick} >
        <div className="item-button-main-icon">
          <Icon className={`bm-icon-${initType ? 'init' : 'selected'}`} type={iconType}/>
        </div>
        <div className="item-button-main-text">
          {label}
        </div>
      </div>
      {
        !initType &&
        <div className="button-back" onClick={onBack}>
          {getIntl().common.back}
        </div>
      }
    </SignMethodStyled>
  )
}
export default SignMethod;
