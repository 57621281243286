import React from 'react';
import Icon, { IconType } from '@/components/Icon';

interface IGoogleMapListProps {
  places: any[];
  editSuiteClick: (editPlace: any) => void;
}

const GoogleMapList = (props: IGoogleMapListProps) => {

  const {
    places=[],
    editSuiteClick = () => {},
  } = props;

  return (
    <div className="div-block-497">
      {
        places.length > 0 &&
        places.map((place: any) => (
          <div
            key={place.place_id}
            className="choosing-address"
            onClick={() => {
              editSuiteClick(place);
            }}
          >
            <Icon
              type={IconType.MAP_OUTLINED}
              className="html-embed-25 w-embed"
            />
            <div className="div-block-488">
              <div className="text-block-77">{place.name}</div>
              <div className="text-block-49">{place.formatted_address || place.vicinity}</div>
            </div>
          </div>
        ))
      }
    </div>
  );
}

export default GoogleMapList;
