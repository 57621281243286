import { RouteComponentProps } from 'react-router-dom';
import withRoute from '@/pages/withRoute';
import ForgotPasswordPage from './ForgotPasswordPage';
import './ForgotPasswordPage.less';

export const prefix = 'bm-p-forgot';

const RouteComponent: React.FC<RouteComponentProps> = props => withRoute({
  pageComponent: ForgotPasswordPage,
  routeProps: props,
  pageTitleI18nKey: '',
});
export default RouteComponent;
