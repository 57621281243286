import React from 'react';
import Icon, { IconType } from '@/components/Icon';
import { IObject } from '../bm-common';
import { IAppIcons } from '@/actions/store-action';
import Badge from 'antd-mobile/es/badge';
import Constants from '@/constants';
import { SettingItemStyled } from './styled';
import { getAccessToken, langFormat } from '@/utils/app';
import { getIntlByPath } from '../App/App';
 
interface ISettingItemProps {
  msg: string;
  onItemClick?: () => void;
  noBorder?: boolean;
  values?: IObject;
  iconType?: IconType;
  appIcon?: IAppIcons;
}

const SettingItem: React.FC<ISettingItemProps> = props => {
  const { iconType, msg, onItemClick, noBorder, values, appIcon } = props;
  return (
    <SettingItemStyled>
      <div  className={ `div-block-546 setting w-inline-block ${noBorder ? 'no-line' : ''}`} onClick={onItemClick}>
        <div className="text-block-67 _1">
          {
            appIcon && appIcon.svg &&
            <div className="html-embed-34 w-embed" dangerouslySetInnerHTML={{ __html: appIcon.svg }} />
          }
          {
            !appIcon && iconType &&
            <Icon
              type={iconType}
              className="html-embed-34 w-embed"
            />
          }
          {langFormat(getIntlByPath(msg), values || {})}
          {
            iconType && iconType === IconType.PRIVILEGE_OUTLINED && getAccessToken() &&
            <Badge text={Number(localStorage.getItem(Constants.PRIVILEGE_NUMBER))} overflowCount={99}/>
          }
        </div>
        <div className="div-block-487">
          <Icon
            type={IconType.RIGHT_OUTLINED}
            className="html-embed-34 w-embed"
          />
        </div>
      </div>
    </SettingItemStyled>
  );
}

export default SettingItem;
