import styled from 'styled-components';
export const PopupStyled = styled.div`
  overflow: auto;
  height: 100%;
  /* margin-bottom: 20px; */
.section {
  display: block;
  margin-top: auto;
  margin-bottom: 0px;
  padding: 100px 40px 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.container {
  position: relative;
  display: block;
  overflow: visible;
  margin-left: auto;
}
.div-block-549 {
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: #888;
}
table {
  max-width: 100% !important;
}
@media screen and (max-width: 991px) {
  .section {
    margin-top: 0px;
    padding: 84px 35px 30px;
  }
}
@media screen and (max-width: 767px) {
  .section {
      padding: 66px 25px 20px;
  }
}

@media screen and (max-width: 479px) {
  .section {
    padding: 68px 12px 15px;
  }
  .container {
    margin-top: 0px;
    margin-bottom: 0px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
}

`;
