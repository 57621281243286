import React from 'react';
import Icon, { IconType } from '@/components/Icon';
import { getPayIcon, formatCurrency } from '@/components/Payment';
import { ISaleTransaction } from '@/lib/order-builder/models/OrderEntity';
import { PayTypes } from '@/lib/payment';
import { getIntl } from '@/components/App/App';
import { langFormat } from '@/utils/app';

interface IPayInfoItemProps {
  data: ISaleTransaction;
  showLeftIcon?: boolean;
  showBalance?: boolean;
  onDelete?: () => void;
  onItemClick?: () => void;
}

const PayInfoItem: React.FC<IPayInfoItemProps> = props => {
  const { data, showLeftIcon, showBalance, onDelete, onItemClick } = props;
  const { paymentMethod, last4Digits, balance = 0, currencyCode, id } = data;
  let { creditCardType } = data;
  if (!creditCardType && [PayTypes.MASTER, PayTypes.VISA, PayTypes.AMEX].includes(id as any)) {
    creditCardType = id;
  }
  const { payIcon, payTitle } = getPayIcon(paymentMethod, creditCardType, 'pi-card', id);

  let balanceNode: React.ReactNode | undefined = undefined;
  if (showBalance && paymentMethod === 16) {
    balanceNode = langFormat(getIntl().page.balanceNode, {balance: formatCurrency(balance < 0 ? 0 : balance, currencyCode)})
  }

  return (
    <>
      <div className="div-block-436 no-space" onClick={onItemClick}>
        <div className="text-block-67">
          {getIntl().page.paymentMethod}
          {/* {formatCurrency(amount, currencyCode)} */}
        </div>
        <div className="text-block-46 third">
          <div className="image-199">{payIcon}</div>
          {
            paymentMethod === 16 &&
          <>
            <div className="item-title text-block-67 gift-card-title">{payTitle} {last4Digits}
              <div className="gift-card-balance">{balanceNode}</div>
            </div>
          </>
          }
          {
            paymentMethod !== 16 &&
            <div className={`text-block-67 ${data.id === PayTypes.DEFERRED_BILLING ? 'no-item-icon' : '' }`}>{payTitle} {balanceNode} {last4Digits}</div>
          }
        </div>

        {
          showLeftIcon &&
          <Icon className="pi-loading" type={paymentMethod !== 16 ? IconType.RIGHT_OUTLINED : IconType.CLOSE_OUTLINED} onClick={paymentMethod !== 16 ? onItemClick : onDelete}/>
        }
      </div>
      {
        data.brn &&
        <div className="div-block-436">
          <div className="text-block-67 third">
            BRN
          </div>
          <div className="text-block-67">
            {data.brn}
          </div>
        </div>
      }
    </>
  );
}

export default PayInfoItem;
