import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import Layout from '@/components/Layout';
import Icon, { IconType } from '@/components/Icon';
import { getAppTheme, setScreenColor, getIntl, getIntlByPath } from '@/components/App/App';
import { getI18nText, handleStoreServiceTypesScroll } from '@/utils/app';
import paths from '@/routes/paths';
import { parseRouteParams, getLocalStorageJson, setLocalStorageJson } from '@/utils';
import ActionType from '@/actions/action-type';
import { IStoreCategory } from '@/actions/store-action';
import Constants from '@/constants';
import { prefix } from '.';

interface IBrandListPageProps extends IBasePageProps {}

interface IBrandListPageState {
  selectedCategoryID: string;
  byType: string;
  brands: IStoreCategory[];
}

let useLocal = false;

export const setUseLocalCategoryID = (use: boolean) => {
  useLocal = use || false;
}

class BrandListPage extends BasePage<IBrandListPageProps, IBrandListPageState> {
  selectedCategoryID = getLocalStorageJson(Constants.CATEGORYID) || '';
  constructor(props: IBrandListPageProps) {
    super(props);
    const routeParams = parseRouteParams(props);

    this.state = {
      selectedCategoryID: useLocal ? this.selectedCategoryID : routeParams.categoryID || '',
      byType: routeParams.byType || 'all',
      brands: [],
    }
  }
  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.darkBackgroundColor && appTheme.primaryColor) {
      setScreenColor(appTheme.primaryColor, appTheme.darkBackgroundColor);
    }

    super.componentDidMount();
    this.autoCloseLoading = false;
    const { dispatch } = this.props;
    const { selectedCategoryID } = this.state;
    this.openLoading();
    dispatch({
      type: ActionType.QUERY_BRANDS_BY_CATEGORY,
      categoryID: selectedCategoryID,
      callback: brands => {
        this.setState({
          brands,
        });
        setLocalStorageJson(Constants.CATEGORYID, selectedCategoryID);
        this.closeLoading();
      }
    })
    handleStoreServiceTypesScroll(selectedCategoryID);
  }

  componentWillUnmount () {
    setUseLocalCategoryID(false);
  }

  handleGoBackMember = () => {
    this.handleGoBack({
      path: paths.HOME,
      runGoBack: true,
    });
  }

  handleClick = (type: string, fromPage: string) => {
    handleStoreServiceTypesScroll(type);
    this.handleGotoRoute({
      path: paths.STORE_LIST_BY_CATEGORY,
      pathParams: {
        brandID: type,
        fromPage: fromPage,
      },
    });
  }

  handleNavBarButton = (categoryID: string) => {
    const { selectedCategoryID } = this.state;
    if (selectedCategoryID !== categoryID) {
      this.setState({selectedCategoryID: categoryID, brands: []})
      handleStoreServiceTypesScroll(categoryID);
      const { dispatch } = this.props;
      this.openLoading();
      dispatch({
        type: ActionType.QUERY_BRANDS_BY_CATEGORY,
        categoryID,
        callback: brands => {
          this.setState({
            brands,
          });
          setLocalStorageJson(Constants.CATEGORYID, categoryID);
          this.closeLoading();
        }
      })
    }
  }

  render () {
    const { reducersState } = this.props;
    const { platformStoreCategories, language } = reducersState.app;
    const { selectedCategoryID, brands, byType } = this.state;
    let title = 'page.allBrands';
    if (byType === 'foodOrdering') {
      title = 'page.foodOrdering';
    } else if (byType === 'booking') {
      title = 'page.booking';
    } else if (byType === 'queueing') {
      title = 'page.queueing';
    }
    return(
      <Layout
        {...this.props}
        className={prefix}
        navBar={
          <div className="bm-navbar-fixed">
            <div className="navbar-top">
              <div className="navbar-left" onClick={this.handleGoBackMember}>
                <Icon className="back-icon" type={IconType.LEFT_OUTLINED} />
              </div>
              <div className="navbar-title">{getIntlByPath(title)}</div>
              <div className="navbar-right"></div>
            </div>
            <div id="bm-store-service-types-box">
              <div id="bm-store-service-types" className="navbar-buttom">
                {
                  platformStoreCategories.length > 0 &&
                  platformStoreCategories.map(item => (
                    <div
                      className="order-type"
                      onClick={() => this.handleNavBarButton(item.id)}
                      id={`bm-store-service-types-${item.id}`}
                      key={item.id}
                    >
                      <div className={`category${selectedCategoryID === item.id ? ' selected' : ''}`}>{getI18nText(item, 'category_name', language)}</div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        }
      >
        <div className="brands-all">
          <div className="brands-all-rectangle"></div>
          <span className="brands-all-title">{getIntl().page.allBrands}</span>
        </div>
        <div className="brands">
          {
            brands.map(item => (
              <div className="brands-item" key={item.id} onClick={() => this.handleClick(item.id, 'brands')}>
                <div className="brands-item-img" style={{backgroundImage: `url(${item.categoryMediumImage})`}}></div>
                <div className="brands-item-title">{getI18nText(item, 'category_name', language)}</div>
              </div>
            ))
          }
        </div>
      </Layout>
    )
  }
}

export default BrandListPage;
