import React from 'react';
import AntdIcon from 'antd-mobile/es/icon';
import Icon, { IconType } from '@/components/Icon';
import { IFeedback, IFeedbackTags } from '@/data/Feedback';
import StarShow from '../StarShow';
import FeedbackItem from './FeedbackItem';
import { prefix } from '.';
import { getIntl } from '../App/App';

interface IFeedbackListProps {
  className?: string;
  feedbacks: IFeedback[] | undefined;
  feedbackTags: IFeedbackTags;
  storeScore: number;
  total: number;
  loading: boolean;
}

const FeedbackList: React.FC<IFeedbackListProps> = props => {
  const {
    className = '',
    feedbacks,
    feedbackTags,
    storeScore,
    total,
    loading,
  } = props;

  const score = Number((storeScore || 5).toFixed(1));

  return (
    <div className={`${prefix} ${className} section tinted white statistics`}>
      <div className="container w-container">
        <div className="statistics">
          <div className="statistics-total-score">{score}</div>
          <div className="statistics-y-axis"></div>
          <div className="statistics-number-star">
            <div className="statistics-feedback-number">{getIntl().page.averageFeedback} ({total > 99 ? '99+' : total})</div>
            <div className="statistics-star">
              <StarShow
                score={score}
                starItem={'statistics-star-item'}
                halfStar={'statistics-half-star'}
                isWholeShow={true}
              />
            </div>
          </div>
        </div>
        {
          feedbacks && feedbacks.map(item => {
            return <FeedbackItem key={item.id} feedback={item} feedbackTags={feedbackTags} />
          })
        }
        {
          loading &&
          <div className="loading-data">
            <AntdIcon type="loading" />
            <div className="loading-msg">
              {getIntl().common.loading}
            </div>
          </div>
        }
        {
          feedbacks && feedbacks.length === 0 &&
          <div className={'no-feedbacks'}>
            <div>
              <Icon className="img" type={IconType.TAG_ITEM}/>
              <div className="no-feedbacks-tip">
                {getIntl().page.feedbackTip}
              </div>
            </div>
          </div>
        }
      </div>

    </div>
  );
}

export default FeedbackList;
