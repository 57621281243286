import styled from 'styled-components';
export const QrcodeShoppingPageStyled = styled.div`
.background-top {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  height: 260px;
  background-color: var(--bm-primary-color);
}
.slider.am-carousel {
  position: absolute !important;
  img {
    width: 100%;
    height: 350px !important;
  }
}
.background-top.with-image {
  height: 350px;
  background-position: 50% 50%;
  background-size: cover;
}
.section {
  display: block;
  margin-top: auto;
  margin-bottom: 0px;
  padding: 60px 40px 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0);
}
.section.dine-ine {
  padding-top: 180px;
}
.div-block-620 {
  position: relative;
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
}
.div-block-623 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 60px;
}
.div-block-615 {
  margin-top: 40px;
  padding: 30px;
  border-radius: 4px;
  background-color: var(--bm-light-background-color);
  /* box-shadow: 1px 1px 10px 0 var(--bm-shadow-color-t50); */
  box-shadow: 1px 1px 10px 0 var(--bm-modal-b-color);
}
.div-block-621 {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
}
.div-block-622 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: none;
  margin-right: 0px;
  margin-left: 0px;
  padding: 36px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}
.div-block-622.top {
  position: relative;
  border-bottom: 1px solid var(--bm-border-color-t60);
}
.divider-line {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 1px;
  height: 80px;
  margin-top: auto;
  margin-bottom: auto;
  background-color: var(--bm-border-color-t60);
}
.text-block-98 {
  margin-right: 0px;
  color: var(--bm-text-color);
  margin-bottom: 0px;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  font-size: 18px;
  font-weight: 400;
}
.text-block-67 {
  margin-bottom: 0px;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: var(--bm-text-title-color);
  font-size: 18px;
  font-weight: 400;
}
.text-block-67.bold {
  margin-bottom: 0px;
  padding: 0px;
  font-family: 'Sf pro display', sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-transform: none;
}
.start-ordering {
  position: relative;
  left: auto;
  top: 0px;
  right: 0%;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: 55px;
  margin-right: 0px;
  padding: 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-radius: 8px;
  background-color: var(--bm-primary-color);
  color: var(--bm-light-background-color);
  text-decoration: none;
  cursor: pointer;
  box-sizing: border-box;
}
.start-ordering.disable-btn {
  opacity: 0.4;
} 
.secondary-order-more {
  background-color: var(--bm-light-background-color);
  color: var(--bm-primary-color);
  border-style: solid;
  border-width: 1px;
  border-color: var(--bm-primary-color);
  margin-right: 20px;
}
.text-block-98.with-space {
  margin-bottom: 5px;
}
.text-block-17 {
  font-family: 'Sf pro display', sans-serif;
  font-size: 18px;
  font-weight: 600;
}
.background-top {
  background-color: unset;
}

@media screen and (max-width: 991px) {
  .section {
    margin-top: 0px;
    padding: 40px 35px 30px;
  }
  .div-block-615 {
    padding-top: 20px;
    padding-bottom: 10px;
  }
  .div-block-623 {
    margin-top: 40px;
  }
  .div-block-622 {
    display: block;
    overflow: visible;
    margin-right: 0px;
    margin-left: 0px;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  .start-ordering {
    position: static;
    top: 0px;
    right: 0%;
    margin-right: 0px;
  }
  .secondary-order-more {
    margin-right: 20px;
  }
}

@media screen and (max-width: 767px) {
  .section {
    padding: 83px 25px 20px;
  }
  .section.dine-ine {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100vh;
    min-height: 100vh;
    padding: 208px 0px 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .div-block-620 {
    z-index: 99;
    max-width: 100vw;
    margin-right: 0px;
    margin-left: 0px;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: var(--bm-light-background-color);
  }
  .div-block-623 {
    margin-right: 30px;
    margin-left: 30px;
  }
  .div-block-615 {
    margin-top: 20px;
    padding-bottom: 20px;
  }
  .div-block-615.dine-in {
    box-shadow: none;
  }
  .div-block-622 {
    margin-right: 0px;
    margin-left: 0px;
    padding: 21px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .text-block-98 {
    margin-right: 0px;
    max-width: 40%;
  }
  .text-block-67.bold {
    font-size: 18px;
  }
  .start-ordering {
    left: auto;
    top: -22%;
    right: -5%;
    bottom: 0%;
  }
  .text-block-17 {
    font-size: 18px;
  }
  .secondary-order-more {
    margin-right: 20px;
  }
}

@media screen and (max-width: 479px) {
  .background-top {
    height: 180px;
  }
  .slider.am-carousel {
    img {
      height: 180px !important;
    }
  }
  .section {
    padding: 68px 12px 15px;
  }
  .section.dine-ine {
    padding-top: 198px;
  }
  .div-block-615 {
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .div-block-615.dine-in {
    padding-right: 10px;
    padding-left: 10px;
  }
  .div-block-623 {
    margin-top: 30px;
    margin-right: 20px;
    margin-left: 20px;
  }
  .div-block-622 {
    padding: 10px;
  }
  .text-block-67 {
    margin-bottom: 0px;
    font-size: 14px;
  }
  .text-block-67.bold {
    font-size: 16px;
  }
  .text-block-98 {
    font-size: 14px;
    text-align: left;
  }
  .text-block-98.with-space {
    white-space: nowrap;
  }
  .start-ordering {
    left: auto;
    top: 0%;
    right: 0%;
    bottom: 0%;
    width: auto;
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
  .text-block-17 {
    font-size: 16px;
  }
  .secondary-order-more {
    margin-right: 15px;
  }
  .background-top.with-image {
    height: 210px;
    width: 100%;
  }
  .slider.am-carousel {
    img {
      height: 210px !important;
    }
  }
}
`;
