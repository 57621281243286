import styled from 'styled-components';

export const SelectStoresStyled = styled.div`
  background-color: var(--bm-dark-background-color);
* {
  box-sizing: border-box;
}
.w-nav {
  position: relative;
  z-index: 100;
}
.nav_bg {
  position: -webkit-sticky;
  position: sticky;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  height: 52px;
}
.nav_bg.streched {
  position: fixed;
  z-index: 9800000;
  height: auto;
  max-height: unset;
  min-height: unset;
  border-bottom: 0px solid var(--bm-border-color);
  background-color: rgba(80, 61, 61, 0);
  background-image: none;
  background-size: auto;
}
.nav_bg.streched.with-space {
  position: -webkit-sticky;
  position: sticky;
  background-color: var(--bm-primary-color);
  height: 80px;
  padding-top: var(--bm-safe-area-top);
  box-sizing: content-box;
}
.container.fixed-nav {
  position: absolute;
  left: 0%;
  top: 0px;
  right: 0%;
  bottom: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-height: 70px;
  min-height: 70px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: var(--bm-text-title-color);
}
.container {
  position: relative;
  display: block;
  overflow: visible;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
}
.w-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
}
.heading-6 {
  margin: 0px auto 0px 0px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: var(--bm-light-background-color);
  font-size: 22px;
  font-weight: 400;
}
h6 {
  font-size: 12px;
  line-height: 18px;
  margin-top: 10px;
}
.profile-img.top {
  width: 50px;
  height: 50px;
  min-height: 50px;
  min-width: 50px;
  margin: auto 0px auto auto;
}
.profile-img {
  width: 60px;
  height: 60px;
  margin-right: 10px;
  border-radius: 40px;
  background-position: 50% 50%;
  background-size: cover;
}
.section._1 {
  padding-top: 40px;
  .container._1 {
    display: grid;
    grid-auto-columns: 1fr;
    gap: 16px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
  }
  .default-bg-wrap {
    display: flex;
    justify-content: center;
    padding-top: 100px;
    .default-bg {
      width: 130px;
      height: 166px;
    }
  }
  .default-con {
    margin-top: 40px;
    padding: 0 20px;
    text-align: center;
    .user-id {
      font-size: 18px;
      margin-bottom: 18px;
      span {
        color: var(--bm-primary-color);
        font-weight: 500;
      }
    }
    .user-tips {
      font-size: 16px;
      line-height: 24px;
      span {
        color: var(--bm-primary-color);
        font-weight: 500;
      }
    }
  }
}
.section {
  display: block;
  margin-top: auto;
  margin-bottom: 0px;
  padding: 100px 40px 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--bm-dark-background-color);
}
.bm-c-store-list {
  >div {
    box-shadow: 1px 1px 10px -3px #9e9e9e;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    overflow: hidden;
    width: 100%;
    margin-right: auto;
    margin-bottom: 0;
    margin-left: auto;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    border-radius: 6px;
    background-color: var(--bm-light-background-color);
    color: var(--bm-text-title-color);
    text-decoration: none;
    cursor: pointer;
    padding-bottom: 0;
  }
  >div.loading-data {
    background-color: var(--bm-dark-background-color);
    box-shadow: none;
  }
  .bm-c-store-list-item.branch-card:last-child {
    margin-left: auto;
  }
}

@media screen and (max-width: 991px) {
  .w-container {
    max-width: 728px;
  }
  .profile-img {
    width: 60px;
    height: 60px;
    min-height: 60px;
    min-width: 60px;
    margin-right: 10px;
  }
  .section {
    margin-top: 0px;
    padding: 84px 35px 30px;
  }
}


@media screen and (max-width: 767px) {
  .nav_bg.streched {
    max-height: none;
    max-width: none;
    min-height: 60px;
  }
  .nav_bg.streched.with-space {
    position: fixed;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-left: 56px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .container {
    position: relative;
    display: block;
    overflow: visible;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
  }
  .container.fixed-nav {
    top: 0px;
    bottom: 0px;
    max-height: 60px;
    min-height: 60px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .container.fixed-nav._1 {
    top: auto;
    bottom: auto;
    padding-right: 30px;
    padding-left: 30px;
  }
  .heading-6 {
    font-size: 22px;
  }
  .profile-img {
    width: 60px;
    height: 60px;
  }
  .section {
    padding: 66px 25px 20px;
  }
  .section._1 {
    padding-top: 120px;
    .container._1 {
      grid-template-columns: 1fr;
    }
  }
  .bm-c-store-list {
    >div {
      -webkit-transition: none 0ms ease;
      transition: none 0ms ease;
      width: 100%;
    }
  }
}

@media screen and (max-width: 479px) {
  .w-container {
    max-width: none;
  }
  .container {
    margin-top: 0px;
    margin-bottom: 0px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .container.fixed-nav {
    max-height: 50px;
    min-height: 50px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .container.fixed-nav._1 {
    padding-right: 13px;
    padding-left: 13px;
  }
  .heading-6 {
    font-size: 16px;
  }
  .profile-img {
    width: 55px;
    height: 55px;
    margin-right: 15px;
  }
  .profile-img.top {
    width: 50px;
    height: 50px;
    min-height: 50px;
    min-width: 50px;
  }
  .section._1 {
    padding-top: 20px;
    .bm-c-store-list-item.branch-card {
      margin-bottom: 0;
    }
  }
  .section {
    padding: 68px 12px 15px;
  }
  .bm-c-store-list {
    >div {
      width: 100%;
      margin-right: auto;
    }
  }
}

`;
