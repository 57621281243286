import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import ActionType from '@/actions/action-type';
import SelectStores from '@/components/SelectStores';
import { IStore } from '@/actions/store-action';
import Constants from '@/constants';
import paths from '@/routes/paths';
import { IObject } from '@/components/bm-common';
import Layout from '@/components/Layout';
import { prefix } from '.';
export interface ISelectStoresPageProps extends IBasePageProps {

}
export interface ISelectStoresPageState {
  userAssociateStores: IStore[];
  loadingStores: boolean;
  associateTypesMap?: Map<string, IObject[]>;
}

class SelectStoresPage extends BasePage<ISelectStoresPageProps, ISelectStoresPageState> {

  state: ISelectStoresPageState = {
    userAssociateStores: [],
    loadingStores: false,
  }

  componentDidMount () {
    super.componentDidMount();
    const { dispatch } = this.props;
    this.setState({ loadingStores: true });
    dispatch({
      type: ActionType.QUERY_USER_ASSOCIATE_STORES,
      newRequest: true,
      callback: (stores: IStore[], associateTypesMap?: Map<string, IObject[]>) => {
        this.setState({
          userAssociateStores: stores,
          loadingStores: false,
          associateTypesMap,
        });
        if (stores.length === 1) {
          this.handleItemClick(stores[0]);
        }
      }
    })
  }

  handleItemClick = (data: IStore) => {
    const { dispatch, reducersState } = this.props;
    const { platformConfig } = reducersState.app;
    const { associateTypesMap } = this.state;
    dispatch({
      type: ActionType.SET_SUPPLIERS_SELECT_KEY,
      selectStoreSlug: data.slug,
      selectStoreId: data.id,
    });
    dispatch({
      type: ActionType.CLEAR_SHOPPING_CART,
    });
    localStorage.setItem(Constants.SUPPLIERS_SELECT_STORE_SLUG, data.slug);
    localStorage.setItem(Constants.SUPPLIERS_SELECT_STORE_ID, data.id);
    let showAddExpressOrder = false;
    if (associateTypesMap) {
      const associateTypes = associateTypesMap.get(data.slug);
      if (associateTypes && associateTypes.length > 0) {
        for (let i = 0; i < associateTypes.length; i++) {
          const tempAssociateType = associateTypes[i];
          if (tempAssociateType.functionSwitchForStore.addExpressOrder === true) {
            showAddExpressOrder = true;
            break;
          }
        }
      }
      if (showAddExpressOrder) {
        localStorage.setItem(Constants.ADD_EXPRESS_ORDER, 'true');
        this.handleGotoRoute({
          path: paths.CATEGORY,
        })
      } else {
        localStorage.setItem(Constants.ADD_EXPRESS_ORDER, 'false');
        this.handleGotoRoute({
          path: paths.EXPRESS_ORDER,
          pathParams: {
            storeSlug: platformConfig.storeSlug,
          }
        })
      }
    }
  }

  render () {
    const { userAssociateStores, loadingStores } = this.state;

    return (
      <Layout 
        { ...this.props}
        isFooter={false}
        className={prefix}
      >
        <SelectStores
          data={userAssociateStores}
          loading={loadingStores}
          reducersState={this.props.reducersState}
          onItemClick={this.handleItemClick}
        />
      </Layout>
    );
  }
}
export default SelectStoresPage;
