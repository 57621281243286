import React from 'react';
import dayjs from 'dayjs';
import { Modal } from 'antd-mobile';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import Icon, { IconType } from '@/components/Icon';
import { IObject } from '@/components/bm-common';
import { getAppTheme, setScreenColor, getIntl } from '@/components/App/App';
import Layout from '@/components/Layout';
import { IStore } from '@/actions/store-action';
import { IReceiveOrder } from '@/actions/good-received-note-actions';
import { formatCurrency } from '@/components/Payment';
import paths from '@/routes/paths';
import { prefix } from '.';
import ActionType from '@/actions/action-type';

export interface IReturnNoteDetailPageProps extends IBasePageProps { }
export interface IReturnNoteDetailPageState {
  store: IStore | undefined;
  returnNote: IReceiveOrder | undefined;
}

class ReturnNoteDetailPage extends BasePage<IReturnNoteDetailPageProps, IReturnNoteDetailPageState> {
  routeParams: IObject = {};

  state: IReturnNoteDetailPageState = {
    store: undefined,
    returnNote: undefined,
  }

  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.darkBackgroundColor) {
      setScreenColor(appTheme.darkBackgroundColor);
    }

    super.componentDidMount();
    this.openLoading();
    this.getGoodReceivedNoteDetail();
  }

  getGoodReceivedNoteDetail = () => {
    const { routeParams, dispatch } = this.props;
    const { storeSlug, receiveOrderID } = routeParams;
    dispatch({
      type: ActionType.QUERY_STORE,
      storeSlug,
      callback: (store, storeConfig) => {
        if (store) {
          dispatch({
            type: ActionType.QUERY_RETURN_NOTE,
            storeID: store.id,
            receiveOrderID,
            callback: returnNote => {
              this.setState({returnNote, store});
              this.closeLoading();
            }
          })
        } else {
          this.closeLoading()
        }
      }
    })
  }

  handleCancel = () => {
    const { dispatch } = this.props;
    const { returnNote, store } = this.state;
    if (returnNote && store) {
      Modal.alert(getIntl().page.alert, getIntl().page.returnNoteCancelTip, [
        {
          text: getIntl().common.no,
          onPress: () => {},
        },
        {
          text: getIntl().common.yes,
          onPress: () => {
            this.openLoading();
            dispatch({
              type: ActionType.CANCEL_RETURN_NOTE,
              storeID: store.id,
              receiveOrderID: returnNote.id,
              callback: returnNote => {
                this.setState({returnNote})
                this.closeLoading();
              }
            })
          },
        }
      ]);
    }
  }

  handleReceive = () => {
    const { dispatch } = this.props;
    const { returnNote, store } = this.state;
    if (returnNote && store) {
      Modal.alert(getIntl().page.alert, getIntl().page.returnNoteReceiveTip, [
        {
          text: getIntl().common.no,
          onPress: () => {},
        },
        {
          text: getIntl().common.yes,
          onPress: () => {
            this.openLoading();
            dispatch({
              type: ActionType.RECEIVE_RETURN_NOTE,
              storeID: store.id,
              receiveOrderID: returnNote.id,
              callback: returnNote => {
                this.setState({returnNote})
                this.closeLoading();
              }
            })
          },
        }
      ]);
    }
  }
  
  render() {
    const { returnNote, store } = this.state;

    return (
      <Layout
        {...this.props}
        className={prefix}
        navIcon={<Icon className="back-icon" type={IconType.LEFT_OUTLINED} />}
        onLeftClick={() => this.handleGoBack({ path: paths.RETURN_NOTES, runGoBack: true })}
        navContent={getIntl().page.returnNotesTitle}
        bottomNav={
          returnNote && returnNote.state !== 'canceled' &&
          <div className="return-note-detail-bottom">
            <div className="return-note-detail-button cancel-button" onClick={this.handleCancel}>{getIntl().common.cancel}</div>
            {
              returnNote.state !== 'canceled' && returnNote.state !== 'received' &&
              <div className="return-note-detail-button receive-button" onClick={this.handleReceive}>{getIntl().page.return}</div>
            }
          </div>
        }
      >
        {
          returnNote && store &&
          <>
            <div className="return-note-detail-data">
              <div className="style-b-color"></div>
              <div className="return-note-detail-data-tab">
                <div className="return-note-detail-data-tab-number-and-state">
                  <div className="return-note-detail-data-tab-number">{returnNote.number}</div>
                  {
                    returnNote.state === 'canceled' &&
                    <div className="return-note-detail-data-tab-information-line-data canceled">{getIntl().common.voided}</div>
                  }
                  {
                    returnNote.state === 'received' &&
                    <div className="return-note-detail-data-tab-information-line-data received">{getIntl().page.returned}</div>
                  }
                  {
                    returnNote.state !== 'canceled' && returnNote.state !== 'received' &&
                    <div className="return-note-detail-data-tab-information-line-data deferred">{getIntl().page.created}</div>
                  }
                </div>
                <div className="return-note-detail-data-tab-information-line">
                  <div className="return-note-detail-data-tab-information-line-title">{getIntl().page.supplierName}</div>
                  <div className="return-note-detail-data-tab-information-line-data">{returnNote.supplierName}</div>
                </div>
                <div className="return-note-detail-data-tab-information-line">
                  <div className="return-note-detail-data-tab-information-line-title">{getIntl().page.deliveryReceived}</div>
                  <div className="return-note-detail-data-tab-information-line-data">{returnNote.expectedReceiveDate ? dayjs(returnNote.expectedReceiveDate).format('YYYY-MM-DD') : 'N/A'}</div>
                </div>
                <div className="return-note-detail-data-tab-information-line">
                  <div className="return-note-detail-data-tab-information-line-title">{getIntl().page.receivedBy}</div>
                  <div className="return-note-detail-data-tab-information-line-data">{returnNote.createdBy}</div>
                </div>
                {
                  returnNote.receiveDate &&
                  <div className="return-note-detail-data-tab-information-line">
                    <div className="return-note-detail-data-tab-information-line-title">{getIntl().page.receivedOn}</div>
                    <div className="return-note-detail-data-tab-information-line-data">{dayjs(returnNote.receiveDate).format('YYYY-MM-DD')}</div>
                  </div>
                }
              </div>
            </div>
            <div className="return-note-detail-line-item-card">
              <div className="return-note-detail-line-item-card-top-title">{getIntl().page.deliveredQuantity}</div>
              {
                returnNote.purchaseOrders.map(purchaseOrder => {
                  return purchaseOrder.lineItems.map(item => {
                    return (
                      <div className="return-note-detail-line-item" key={item.id}>
                        {
                          item.imageUrl &&
                          <div className="return-note-detail-line-item-img" style={{ backgroundImage: `$url(${item.imageUrl})`}}></div>
                        }
                        {
                          !item.imageUrl &&
                          <div className="return-note-detail-line-item-img"></div>
                        }
                        <div className="return-note-detail-line-item-info">
                          <div className="return-note-detail-line-item-info-line">
                            <div className="return-note-detail-line-item-info-line-name">{item.name}</div>
                            <div className="return-note-detail-line-item-info-line-quantity">{getIntl().page.qty} x{item.qtyRemaining}</div>
                          </div>
                          <div className="return-note-detail-line-item-info-line">
                            <div className="return-note-detail-line-item-info-line-code">{getIntl().page.orderQuantity}: {item.qtyReceivedPO}</div>
                          </div>
                          <div className="return-note-detail-line-item-info-line">
                            <div className="return-note-detail-line-item-info-line-price">{formatCurrency(item.cost, purchaseOrder.currency)}</div>
                            <div className="return-note-detail-line-item-info-line-total">{formatCurrency(Math.abs(item.total), purchaseOrder.currency)}</div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                })
              }
              <div className="return-note-detail-remarks-title">{getIntl().page.remark}</div>
              <div className="return-note-detail-remarks-data">{returnNote.remarks}</div>
              <div className="return-note-detail-line-item-card-information-line dividing">
                <div className="return-note-detail-line-item-card-information-line-title">{'Total PO Quantity'}</div>
                <div className="return-note-detail-line-item-card-information-line-data">{returnNote.qtyPOTotal}</div>
              </div>
              <div className="return-note-detail-line-item-card-information-line">
                <div className="return-note-detail-line-item-card-information-line-title">{'Returned Note Quantity'}</div>
                <div className="return-note-detail-line-item-card-information-line-data">{returnNote.qtyRN}</div>
              </div>
              <div className="return-note-detail-line-item-card-information-line">
                <div className="return-note-detail-line-item-card-information-line-title">{'Returned Quantity'}</div>
                <div className="return-note-detail-line-item-card-information-line-data">{Math.abs(returnNote.qtyGRNReceived)}</div>
              </div>
              <div className="return-note-detail-line-item-card-information-line">
                <div className="return-note-detail-line-item-card-information-line-title">{getIntl().common.subtotal}</div>
                <div className="return-note-detail-line-item-card-information-line-data">{formatCurrency(Math.abs(returnNote.subtotalGRN), store.currencyCode)}</div>
              </div>
              <div className="return-note-detail-line-item-card-information-line bold">
                <div className="return-note-detail-line-item-card-information-line-title">{getIntl().common.total}</div>
                <div className="return-note-detail-line-item-card-information-line-data">{formatCurrency(Math.abs(returnNote.totalGRN), store.currencyCode)}</div>
              </div>
            </div>
          </>
        }
      </Layout>
    );
  }
}

export default ReturnNoteDetailPage;
