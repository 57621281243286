import React from 'react';
import dayjs from 'dayjs'
import { BigNumber } from 'bignumber.js';
import { IPoint, IReward } from '@/actions/point-action';
import { ICustomerGrowth } from '@/actions/user-action';
import Icon, { IconType } from '@/components/Icon';
import { IPlatformStoreMembership } from '@/actions/store-action';
import { getI18nText, langFormat } from '@/utils/app';
import { IPlatformCustomPage } from '@/actions/app-action';
import { IObject } from '@/components/bm-common';
import { IReducersState } from '@/reducers';
import { getIntl } from '@/components/App/App';

interface IUsePointsProps {
  membershipData: ICustomerGrowth;
  rewards: IReward[];
  points: IPoint[] | undefined;
  currencyCode: string;
  reward: IReward | undefined;
  handleClick: (amount: IReward) => void;
  handlePayPoint: () => void;
  platformStoreMembership: IPlatformStoreMembership[];
  language: string;
  abbreviation: string;
  records: IPlatformCustomPage[];
  reducersState: IReducersState;
}

const UsePoints: React.FC<IUsePointsProps> = props => {
  const {
    membershipData,
    rewards,
    // currencyCode = 'HKD',
    reward,
    handleClick,
    points,
    handlePayPoint,
    platformStoreMembership,
    language,
    abbreviation,
    records,
    reducersState,
  } = props;

  return (
    <div>
      <div className="card">
        <div className="use-point">
          <div className="available-points-wrap">
            <div className="available-balance">
              <div>{abbreviation} {getIntl().page.dollarsBalance}</div>
              <div>{new BigNumber(membershipData.storeCredits).toFormat()}</div>
            </div>
            <div className="available-points">
              <div>{getIntl().page.currentAmount}</div>
              <div>{new BigNumber(membershipData.myLoyaltyStamp).toFormat()}</div>
            </div>
          </div>
         
          {
            records && records.length > 0 && records.map((item: IObject) => {
              if ((item.content && item.content.length > 0) || ( item.i18n && JSON.stringify(item.i18n) !== '{}')) {
                return (
                  <div style={{width: '100%'}} className="reward-p"  dangerouslySetInnerHTML={{
                    __html: getI18nText(item, 'content', reducersState.app.language) || (item.content) || '',
                  }} />
                )
              } else {
                return (
                  <>
                    <div className="reward-status">{getIntl().page.rewardStatus}</div>
                    <div className="reward-content">
                      <div className="reward-content-item">
                        <div className="reward-content-item-title">
                          {getIntl().page.members}
                        </div>
                        {
                          platformStoreMembership.length > 0 && platformStoreMembership.map((item, index) => (
                            <div key={item.id} className="reward-item">
                              {abbreviation} {getI18nText(platformStoreMembership[index], 'abbreviation', language)}
                            </div>
                          ))
                        }
                      </div>
                      <div className="reward-content-item">
                        <div className="reward-content-item-title">
                          {getIntl().page.everySpending}
                        </div>
                        {
                          ['HK$20', 'HK$20', 'HK$20'].map((item, index) => (
                            <div key={`${item+index}`} className="reward-item">
                              {item}
                            </div>
                          ))
                        }
                      </div>
                      <div className="reward-content-item">
                        <div className="reward-content-item-title">
                          {getIntl().page.point}
                        </div>
                        {
                          [1, 2, 3].map(item => (
                            <div key={item} className="reward-item">
                              {item}
                            </div>
                          ))
                        }
                      </div>
                    </div>
                    <div className="reward-rule">{langFormat(getIntl().page.pointsEqDollar, { dollar: `${abbreviation} Dollar` })}</div>
                    <div className="reward-rule">{langFormat(getIntl().page.dollarEqPoints, { dollar: `${abbreviation} Dollar` })}</div>
                    <div className="reward">{getIntl().page.Reward}</div>
                    <div className="reward-select-point">{getIntl().page.enterPoint}</div>
                    <div className="reward-select-point">{langFormat(getIntl().page.minimumSpending, { dollar: `${abbreviation} Dollar` })}</div>
                  </>
                )
              }
            })
          }
          { 
            records && records.length === 0 &&
            <>
              <div className="reward-status">{getIntl().page.rewardStatus}</div>
              <div className="reward-content">
                <div className="reward-content-item">
                  <div className="reward-content-item-title">
                    {getIntl().page.members}
                  </div>
                  {
                    platformStoreMembership.length > 0 && platformStoreMembership.map((item, index) => (
                      <div key={item.id} className="reward-item">
                        {abbreviation} {getI18nText(platformStoreMembership[index], 'abbreviation', language)}
                      </div>
                    ))
                  }
                </div>
                <div className="reward-content-item">
                  <div className="reward-content-item-title">
                    {getIntl().page.everySpending}
                  </div>
                  {
                    ['HK$20', 'HK$20', 'HK$20'].map((item, index) => (
                      <div key={`${item+index}`} className="reward-item">
                        {item}
                      </div>
                    ))
                  }
                </div>
                <div className="reward-content-item">
                  <div className="reward-content-item-title">
                    {getIntl().page.point}
                  </div>
                  {
                    [1, 2, 3].map(item => (
                      <div key={item} className="reward-item">
                        {item}
                      </div>
                    ))
                  }
                </div>
              </div>
              <div className="reward-rule">{langFormat(getIntl().page.pointsEqDollar, { dollar: `${abbreviation} Dollar` })}</div>
              <div className="reward-rule">{langFormat(getIntl().page.dollarEqPoints, { dollar: `${abbreviation} Dollar` })}</div>
              <div className="reward">{getIntl().page.Reward}</div>
              <div className="reward-select-point">{getIntl().page.enterPoint}</div>
              <div className="reward-select-point">{langFormat(getIntl().page.minimumSpending, { dollar: `${abbreviation} Dollar` })}</div>
            </>
          }

          <div className="reward-input">
            <div className="currency-symbol">{`${abbreviation} Dollar`}</div>
            {
              Number( membershipData.myLoyaltyStamp ) === 0 &&
              <div className="total-points">{getIntl().page.noPointsAvailable}</div>
            }
            {
              reward &&
              <div className="amount">{reward.loyalty}</div>
            }
          </div>
          <div className="choose">
            {
              rewards.map(item => {
                return (
                  <div className="choose-item" key={item.id} onClick={() => handleClick(item)}>{item.loyalty}</div>
                )
              })
            }
          </div>
        </div>
      </div>
      {
        points &&
        <div className="card">
          <div className="point-history">
            <div className="recent-acitivities">{getIntl().page.recentAcitivities}</div>
            {
              points.map(item => {
                return (
                  <div className="points-item" key={item.id}>
                    <div className="points-item-date"><Icon type={IconType.DATE_OUTLINED} />{dayjs(item.createdAt).format('DD/MM/YYYY')}</div>
                    <div>{item.amount > 0 ? '+': ''}{item.amount}&nbsp;{getIntl().page.points}</div>
                  </div>
                )
              })
            }
          </div>
        </div>
      }
      
      <div className="bottom-botton">
        <div className={`pay-with-point ${reward ? '' : 'disabled'}`} onClick={() => handlePayPoint()}>
          {getIntl().page.payPoint}
        </div>
      </div>
    </div>
  );
}

export default UsePoints;
