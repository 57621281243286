import React, { useState } from 'react';
import InputItem from 'antd-mobile/es/input-item';
import Button from 'antd-mobile/es/button';
import Modal from 'antd-mobile/es/modal';
import {
  useSelector,
} from 'react-redux';
import { IReducersState } from '@/reducers';
import SignUserName from '@/components/common/userComponents/NewUserName';
import { restPasswordEmail, sendEmailVertifyCode } from '@/data/sign';
import { prefix } from '.'
import Icon, { IconType } from '../Icon';
import { getIntl } from '../App/App';


interface IEmailRetrieveProps {
  restInClick?: () => void;
  className?: string;
}

const EmailRetrieve: React.FC<IEmailRetrieveProps> = props => {

  const {
    className = '',
    restInClick = () => {},
  } = props;

  const reducersState: IReducersState = useSelector<IReducersState, IReducersState>(state => state);

  const { language } = reducersState.app;

  const [email, setEmail] = useState<string>('');

  const [verifyCode, setVerifyCode] = useState<string>('');

  const [showCountDown, setShowCountDown] = useState<boolean>(false);

  const [verifyCodeCountdown, setVerifyCodeCountdown] = useState<number>(60);

  const [verfityEmail, setVerfityEmail] = useState<boolean>(false);

  let timeLoop: any;
  const SETVERIFYCODETIME = 'VERIFY_CODE_EMAIL_RETRIEVE';

  const restPasswprd = async () => {
    try {
      const resp = await restPasswordEmail({
        email,
        authCode: verifyCode,
        language,
      });

      if (resp && !resp.success) {
        Modal.alert('', resp.message, [
          {
            text: getIntl().common.confirm,
            onPress: () => {},
          }
        ]);
      }
      if (resp && resp.success){
        const message = <Icon type={IconType.STATUS_SUCCESS} />
        const info = getIntl().page.changePasswordSuccessfully;
        Modal.alert('', <>{message}<br/>{info}</>, [
          {
            text: getIntl().common.confirm,
            onPress: () => {
              restInClick();
            },
          }
        ]);
      }
    } catch (error) {}

  }

  const sendVerifyCode = async () => {
    if (!email || !verfityEmail) return;
    try {
      sendVerifyCodeClick();
      const resp = await sendEmailVertifyCode({
        email: email,
        language: language,
        action: 'pwdreset',
      });

      if (resp && !resp.success) {
        clearCountDown();
        Modal.alert('', resp.message, [
          {
            text: getIntl().common.confirm,
            onPress: () => { },
          }
        ]);
      }

    } catch (error) {
      console.error(error)
    }
  }

  const clearCountDown = () => {
    clearInterval(timeLoop);
    localStorage.removeItem(SETVERIFYCODETIME);
    setShowCountDown(false);
  }

  const sendVerifyCodeClick = () => {
    setShowCountDown(true);
    const nowTime = new Date();
    localStorage.setItem(SETVERIFYCODETIME, `${nowTime.getTime()}`);
    let countDown = verifyCodeCountdown;
    timeLoop = setInterval(() => {
      if (countDown < 1) {
        clearCountDown();
        setVerifyCodeCountdown(60);
      } else {
        countDown -= 1;
        setVerifyCodeCountdown(countDown);
      }
    }, 1000);
  }

  const extraVerifyComponent = () => {
    return (
      <div className={`${prefix}-mobile-verify-send`}>
        {
          !showCountDown &&
          <div onClick={sendVerifyCode}>
            {getIntl().common.send}
          </div>
        }
        {
          showCountDown &&
          <div>
            {`${verifyCodeCountdown}s`}
          </div>
        }
      </div>
    )
  }

  return (
    <div className={`${prefix}-email ${className}`} >
      <SignUserName
        language={language}
        value={email}
        onChange={setEmail}
        verfityEmail={(value: boolean) => setVerfityEmail(value)}
      />
      <div className={`${prefix}-mobile-verify`}>
        <InputItem
          name="verify"
          autoComplete="off"
          placeholder={getIntl().page.verifyCode}
          onChange={setVerifyCode}
          value={verifyCode}
          type="number"
          extra={
            extraVerifyComponent()
          }
          maxLength={6}
        />
      </div>
      <div className={`${prefix}-email-text`}>
        {getIntl().page.emailRetrieveText}
      </div>
      {
        <Button
          type="primary"
          className={`${prefix}-signin-button`}
          onClick={restPasswprd}
          disabled={ !email || !verifyCode}
        >
          {getIntl().common.send}
        </Button>
      }
    </div>
  )
}
export default EmailRetrieve;
