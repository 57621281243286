import { Base64 } from 'js-base64';
import Constants from '@/constants';
import { IPayMethod, PayTypes } from '@/lib/payment';
import { IGiftCard, IPayCard } from '@/actions/pay-action';
import { minus } from '@/utils';
import { IOrderInfo } from '@/lib/order-builder/models/OrderEntity';
import { IObject } from '@/components/bm-common';

export const giftCardToPayMethod = (giftCard: IGiftCard, orderInfo: IOrderInfo, balance: number) => {
  const payMethod: IPayMethod = {
    id: giftCard.id,
    currencyCode: orderInfo.currencyCode,
    type: giftCard.type,
    amount: balance || orderInfo.initialTotal,
    printedPin: giftCard.pin,
    number: giftCard.number,
    balance: giftCard.balance,
    last4Digits: giftCard.last4Digits,
  }

  return payMethod;
}

export const giftCardToPayData = (giftCard: IGiftCard, initialTotal: number, balance: number) => {
  const payMethod: IObject = {
    type: PayTypes.ANONYMOUS_GIFT_CARD,
    paymentMethod: 16,
    amount: balance || initialTotal,
    printedPin: giftCard.pin,
    number: giftCard.number,
    balance: giftCard.balance,
    last4Digits: giftCard.last4Digits,
  }

  return payMethod;
}

export const creditCardToPayMethod = (creditCard: IPayCard, orderInfo: IOrderInfo, balance: number) => {
  const payMethod: IPayMethod = {
    id: creditCard.id,
    currencyCode: orderInfo.currencyCode,
    type: PayTypes.CREDIT_CARD,
    token: creditCard.token,
    expYear: creditCard.expYear,
    expMonth: creditCard.expMonth,
    number: creditCard.number,
    cvv: creditCard.cvv || '',
    holderName: creditCard.holder,
    amount: minus(orderInfo.initialTotal, balance),
    creditCardType: creditCard.type,
    last4Digits: creditCard.last4Digits,
  }

  return payMethod;
}

export const parseGiftCardRes = (res: any, number: string, pin: string) => {
  let giftCard: IGiftCard | undefined;
  if (res && res.gift_card_id) {
    giftCard = {
      id: res.gift_card_id,
      balance: res.balance || '',
      number,
      pin,
      expiredAt: res.expired_at || '',
      isGiftCard: res.is_gift_card || false,
      type: PayTypes.ANONYMOUS_GIFT_CARD,
      last4Digits: number.substring(number.length - 4),
    }
  }

  return giftCard;
}

interface IAnonymousGiftCardInfo {
  number: string;
  pin: string;
}

const aGiftCardKey = Base64.encode(Constants.ANONYMOUS_GIFT_CARD_KEY);
const maxStorageTime: number = 2 * 60 * 60 * 1000;

export const getLocalAnonymousGiftCards = () => {
  const aGiftCards: IAnonymousGiftCardInfo[] = [];

  let giftCardsWrapperStr: string | null = localStorage.getItem(aGiftCardKey);
  let giftCardsWrapper: IObject | undefined = undefined;
  let giftCardArr: any[] | undefined = undefined;
  if (giftCardsWrapperStr) {
    giftCardsWrapperStr = Base64.decode(giftCardsWrapperStr);

    if (giftCardsWrapperStr) {
      giftCardsWrapper = JSON.parse(giftCardsWrapperStr);
    }

    if (giftCardsWrapper && giftCardsWrapper.time && giftCardsWrapper.data) {
      const nowTime = new Date().getTime();
      if (nowTime - giftCardsWrapper.time <= maxStorageTime) {
        giftCardArr = JSON.parse(Base64.decode(giftCardsWrapper.data));
      }
    }
  }

  if (!giftCardArr) return aGiftCards;
  giftCardArr.forEach(item => {
    if (item.number && item.pin) {
      aGiftCards.push(item)
    }
  })

  return aGiftCards;
}

export const pushAnonymousGiftCardToLocal = (data: IAnonymousGiftCardInfo) => {
  let giftCards = getLocalAnonymousGiftCards();

  giftCards = giftCards.filter(item => item.number !== data.number)

  if (data.number && data.pin) {
    giftCards.push(data);
  }

  let aGiftCardString: string = JSON.stringify(giftCards);
  aGiftCardString = Base64.encode(aGiftCardString);
  const giftCardsWrapper: any = {
    time: new Date().getTime(),
    data: aGiftCardString,
  }

  const giftCardsWrapperStr = Base64.encode(JSON.stringify(giftCardsWrapper));

  localStorage.setItem(aGiftCardKey, giftCardsWrapperStr);
}

export const removeAnonymousGiftCards = () => {
  localStorage.removeItem(aGiftCardKey);
}
