import './PayBillPage.less';
import { RouteComponentProps } from 'react-router-dom';
import withRoute from '@/pages/withRoute';
import PayBillPage from './PayBillPage';

export const prefix = 'bm-p-pay-bill';

const RouteComponent: React.FC<RouteComponentProps> = props => withRoute({
  pageComponent: PayBillPage,
  routeProps: props,
  pageTitleI18nKey: '',
});
export default RouteComponent;

