import React from 'react';
import Icon, { IconType } from '@/components/Icon';
import { getPayIcon, formatCurrency } from '../Payment';
import { ISaleTransaction } from '@/lib/order-builder/models/OrderEntity';
import { PayTypes } from '@/lib/payment';
import { getIntl } from '../App/App';
import { langFormat } from '@/utils/app';

interface IPayInfoItemProps {
  data: ISaleTransaction;
  showLeftIcon?: boolean;
  showBalance?: boolean;
  onDelete?: () => void;
  onItemClick?: () => void;
}

const PayInfoItem: React.FC<IPayInfoItemProps> = props => {
  const { data, showLeftIcon, showBalance, onDelete, onItemClick } = props;
  const { paymentMethod, last4Digits, balance = 0, amount, currencyCode, id } = data;
  let { creditCardType } = data;
  if (!creditCardType && [PayTypes.MASTER, PayTypes.VISA, PayTypes.AMEX, PayTypes.UNION_PAY].includes(id as any)) {
    creditCardType = id;
  }
  const { payIcon, payTitle } = getPayIcon(paymentMethod, creditCardType, 'pi-card', id);

  let balanceNode: React.ReactNode | undefined = undefined;
  if (showBalance && paymentMethod === 16) {
    balanceNode = langFormat(getIntl().page.balanceNode, {balance: formatCurrency(balance < 0 ? 0 : balance, currencyCode)})
  }

  return (
    <div className="check-out-info no-line" onClick={onItemClick}>
      <div className="image-199">{payIcon}</div>
      {
        paymentMethod === 16 &&
        <>
          <div className="item-title text-block-67 gift-card-title">{payTitle} {last4Digits}
            <div className="gift-card-balance">{balanceNode}</div>
          </div>
        </>
      }
      {
        paymentMethod !== 16 &&
        <div className={`item-title text-block-67 ${data.id === PayTypes.DEFERRED_BILLING ? 'no-item-icon' : '' }`}>{payTitle}{balanceNode} {last4Digits}</div>
      }
      <div className="div-block-487 open-modal">
        <div className="text-block-69">{formatCurrency(amount, currencyCode)}</div>
        {
          showLeftIcon &&
          <Icon className="pi-loading html-embed-34 w-embed" type={paymentMethod !== 16 ? IconType.RIGHT_OUTLINED : IconType.CLOSE_OUTLINED} onClick={paymentMethod !== 16 ? onItemClick : onDelete}/>
        }
      </div>
    </div>
  );
}

export default PayInfoItem;
