import React, {useState, useEffect} from 'react';
import dayjs from 'dayjs';
import { getI18nText } from '@/utils/app';
import { IBooking } from '@/actions/booking-action';
import { IDispatch } from '@/actions';
import ActionType from '@/actions/action-type';
import { getIntl } from '@/components/App/App';

interface IBookingItemProps {
  booking: IBooking;
  goToDetailPage: (id: string) => void;
  dispatch: IDispatch;
  language: string;
}

const BookingItem: React.FC<IBookingItemProps> = props => {
  const {
    booking,
    goToDetailPage,
    language,
    dispatch,
  } = props;

  const [storeName, setStoreName] = useState('');

  useEffect(() => {
    dispatch({
      type: ActionType.QUERY_STORE_BY_ID,
      storeID: booking.storeID,
      callback: store => {
        setStoreName(getI18nText(store, 'title', language) || booking.storeName);
      }
    }); 
  }, []);

  return (
    <>
      {
        storeName &&
        <div className="past-booking-info-block w-inline-block"  onClick={() => goToDetailPage(booking.id)}>
          <div className="div-block-457 with-line">
            <div>
              <div className="text-block-48">{storeName}</div>
            </div>
          </div>
          <div className="div-block-444 _2">
            <div className="div-block-445">
              <div className="div-block-446">
                <div className="text-block-60">
                  {
                    language === 'en-US' && dayjs(booking.reservationTime).format('MMM DD')
                  }
                  {
                    language !== 'en-US' && 
                    <>
                      {dayjs(booking.reservationTime).format('M')}
                      {getIntl().common.month}
                      {dayjs(booking.reservationTime).format('D')}
                      {getIntl().common.day}
                    </>
                  }
                </div>
                <div className="text-block-49">{getIntl().page.date}</div>
              </div>
              <div className="div-block-446">
                <div className="text-block-60">
                  {dayjs(booking.reservationTime).format('HH:mm')}
                </div>
                <div className="text-block-49">{getIntl().page.time}</div>
              </div>
              <div className="div-block-446">
                <div className="text-block-60">
                  {booking.people}
                </div>
                <div className="text-block-49">{getIntl().page.seats}</div>
              </div>
            </div>
            <div className="div-block-447">
              <div className="div-block-436">
                <div className="text-block-46 third">{getIntl().page.bookingNo}</div>
                <div className="text-block-46">{booking.bookingNo}</div>
              </div>
              <div className="div-block-436">
                <div className="text-block-46 third">{getIntl().page.name}</div>
                <div className="text-block-46">{booking.customerName}</div>
              </div>
              <div className="div-block-436">
                <div className="text-block-46 third">{getIntl().page.status}</div>
                <div className="text-block-46">
                  {
                    booking.status === 'waiting' &&
                    getIntl().page.booked
                  }
                  {
                    booking.status === 'arrived' &&
                    getIntl().page.arrived
                  }
                  {
                    booking.status === 'cancelled' &&
                    getIntl().page.cancelled
                  }
                  {
                    booking.status === 'confirmed' &&
                    getIntl().page.confirmed
                  }
                  {
                    booking.status === 'noSshow' &&
                    getIntl().page.noShow
                  }
                  {
                    booking.status === 'voicemail' &&
                    getIntl().page.voicemail
                  }
                  {
                    booking.status === 'partiallyArrived' &&
                    getIntl().page.partiallyArrived
                  }
                  {
                    booking.status === 'late' &&
                    getIntl().page.late
                  }
                  {
                    booking.status === 'waitingForCon' &&
                    getIntl().page.waitingForCon
                  }
                </div>
              </div>
              <div className="div-block-436">
                <div className="text-block-46 third">{getIntl().page.email}</div>
                <div className="text-block-46">{booking.email}</div>
              </div>
              <div className="div-block-436">
                <div className="text-block-46 third">{getIntl().page.phoneNumber}</div>
                <div className="text-block-46">{booking.phone}</div>
              </div>
              <div className="div-block-436">
                <div className="text-block-46 third">{getIntl().page.createAt}</div>
                <div className="text-block-46">{dayjs(booking.createAt).format('YYYY-MM-DD HH:mm:ss')}</div>
              </div>
              <div className="div-block-436">
                <div className="text-block-46 third">{getIntl().page.note}</div>
                <div className="text-block-46">{booking.note}</div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default BookingItem;
