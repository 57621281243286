import React from 'react';
import { prefix } from '.';
import DownIcon from './DownIcon';
import Modal from 'antd-mobile/es/modal';
import { IReducersState } from '@/reducers';
import { useSelector } from 'react-redux';
import { PopupStyled } from './styled';
interface IPopupProps {
  title?: React.ReactNode;
  visible: boolean;
  className?: string;
  contentClassName?: string;
  wrapClassName?: string;
  onClose?: () => void;
  showIcon?: boolean;
}

const Popup: React.FC<IPopupProps> = props => {

  const {
    title,
    className = '',
    // contentClassName = '',
    wrapClassName = '',
    visible = false,
    children,
    onClose,
    showIcon = true,
  } = props;
  const reducersState: IReducersState = useSelector<IReducersState, IReducersState>(state => state);
  const { deviceInfo } = reducersState.app;

  return (
    <Modal
      popup
      visible={visible}
      animationType="slide-up"
      className={`${prefix}-popup ${className}`}
      wrapClassName={`${prefix}-popup-wrapper ${wrapClassName} ${(deviceInfo.inPC || deviceInfo.inPad) ? 'web' : 'phone'}`}
      onClose={onClose}
    >
      <div className={`${prefix}-popup-header`}>
        { showIcon && <DownIcon className="header-icon" onClick={onClose} />}
        {title && title}
      </div>
      <PopupStyled>
        <div className="section">
          <div className="container w-container"> {children}</div>
        </div>
      </PopupStyled>
    </Modal>
  )
}
export default Popup;
