import produce from 'immer';
import { handleActions } from 'redux-actions';
import ActionType from '@/actions/action-type';
import {
  IClearFavoriteListingInfo,
  IFavoriteListing,
  IFavoriteListingInfo,
  IPutFavoriteListing,
  ISetFavoriteListingInfo,
  ISetFavoriteTabs,
  ISetModifiers,
  ISetALLStoreFavoriteTabsAndModifiers,
} from '@/actions/listing-action';
import {
  IFavoriteSection,
  IFavoriteTab,
  IListing,
  IModifier,
} from '@/lib/order-builder/utils/api';
import { initFavoriteListingInfo } from '@/utils/listing-util';
import { findNode } from '@/utils';

export interface IListingState {
  slugRefFavoriteTabs: {[storeSlug: string]: IFavoriteTab[]};
  slugRefModifiers: {[storeSlug: string]: IModifier[]};
  favoriteListingInfo: IFavoriteListingInfo;
}

const initState: IListingState = {
  slugRefFavoriteTabs: {},
  slugRefModifiers: {},
  favoriteListingInfo: initFavoriteListingInfo(),
};

const actions: any = {};

actions[ActionType.SET_FAVORITE_TABS] = (state: IListingState, action: ISetFavoriteTabs) => produce(state, draft => {
  draft.slugRefFavoriteTabs[action.storeSlug] = action.favoriteTabs;
});

actions[ActionType.SET_ALL_STORE_FAVORITE_TABS_AND_MODIFIERS] = (state: IListingState, action: ISetALLStoreFavoriteTabsAndModifiers) => produce(state, draft => {
  Object.keys(action.allStoreFavoriteTabs).forEach(key => {
    draft.slugRefFavoriteTabs[key] = action.allStoreFavoriteTabs[key];
  })
  Object.keys(action.allStoreModifiers).forEach(key => {
    draft.slugRefModifiers[key] = action.allStoreModifiers[key];
  })
});

actions[ActionType.SET_MODIFIERS] = (state: IListingState, action: ISetModifiers) => produce(state, draft => {
  draft.slugRefModifiers[action.storeSlug] = action.modifiers;
});

actions[ActionType.SET_FAVORITE_LISTING_INFO] = (state: IListingState, action: ISetFavoriteListingInfo) => produce(state, draft => {
  draft.favoriteListingInfo = action.favoriteListingInfo;
});

actions[ActionType.PUT_FAVORITE_LISTING] = (state: IListingState, action: IPutFavoriteListing) => produce(state, draft => {
  const { favoriteListing, store, storeConfig, favoriteTab, favoriteSection, listing } = action;
  draft.favoriteListingInfo.favoriteListings.push(favoriteListing);
  const key = `${favoriteListing.storeID}.${favoriteListing.favoriteTabID}.${favoriteListing.favoriteSectionID}`;
  if (!Array.isArray(draft.favoriteListingInfo.refFavoriteIDs[key])) {
    draft.favoriteListingInfo.refFavoriteIDs[key] = [];
  }
  draft.favoriteListingInfo.refFavoriteIDs[key].push(favoriteListing.favoriteID);
  if (!draft.favoriteListingInfo.storeIDs.includes(favoriteListing.storeID)) {
    draft.favoriteListingInfo.storeIDs.push(favoriteListing.storeID);
  }
  if (!draft.favoriteListingInfo.storeSlugs.includes(favoriteListing.storeSlug)) {
    draft.favoriteListingInfo.storeSlugs.push(favoriteListing.storeSlug);
  }

  if (favoriteListing && favoriteListing.cartData && favoriteListing.cartData.count > 0) {
    draft.favoriteListingInfo.listingDefaultCountData[`${key}.${favoriteListing.favoriteID}`] = favoriteListing.cartData.count;
  }

  let putDone = false;
  draft.favoriteListingInfo.storesFavoriteListingTabs.forEach(item => {
    if (item.store.id === store.id) {
      let tabDone = false;
      item.favoriteTabs.forEach(favoriteTabsItem => {
        if (favoriteTabsItem.id === favoriteTab.id) {
          tabDone = true;
          let listingDone = false;
          favoriteTabsItem.favoriteSections.forEach(favoriteSectionsItem => {
            if (favoriteSectionsItem.id === favoriteSection.id) {
              const listingNode = findNode<IListing>(favoriteSectionsItem.listings, 'id', listing);
              if (listingNode) {
                listingDone = true;
                putDone = true;
              } else {
                listingDone = true;
                putDone = true;
                favoriteSectionsItem.listings.push(listing);
              }
            }
          });

          if (!listingDone) {
            putDone = true;
            favoriteSection.listings = [listing];
            favoriteTabsItem.favoriteSections.push(favoriteSection);
          }
        }
      });

      if (!tabDone) {
        putDone = true;
        favoriteSection.listings = [listing];
        favoriteTab.favoriteSections = [favoriteSection];
        item.favoriteTabs.push(favoriteTab);
        item.favoriteCount = item.favoriteCount + 1;
      }
    }
  });

  if (!putDone) {
    favoriteSection.listings = [listing];
    favoriteTab.favoriteSections = [favoriteSection];
    draft.favoriteListingInfo.storesFavoriteListingTabs.push({
      store,
      storeConfig,
      favoriteTabs: [favoriteTab],
      favoriteCount: 1,
    })
  }
});

actions[ActionType.CLEAR_FAVORITE_LISTING_INFO] = (state: IListingState, action: IClearFavoriteListingInfo) => produce(state, draft => {
  const favoriteListings: IFavoriteListing[] = [];
  const deletedFavoriteListings: IFavoriteListing[] = [];
  draft.favoriteListingInfo.favoriteListings.forEach(item => {
    // eslint-disable-next-line
    if (item.id == action.id) {
      deletedFavoriteListings.push(item);
    } else {
      favoriteListings.push(item);
    }
  });

  if (deletedFavoriteListings.length > 0) {
    const refFavoriteIDs = {...draft.favoriteListingInfo.refFavoriteIDs};
    const storesFavoriteListingTabs = [...draft.favoriteListingInfo.storesFavoriteListingTabs];
    let refChanged = false;
    let tabsChanged = false;
    deletedFavoriteListings.forEach(item => {
      const key = `${item.storeID}.${item.favoriteTabID}.${item.favoriteSectionID}`;
      // 移除favoriteID映射关系
      if (Array.isArray(refFavoriteIDs[key]) && refFavoriteIDs[key].includes(item.favoriteID)) {
        refChanged = true;
        const favoriteIDSet = new Set(refFavoriteIDs[key]);
        favoriteIDSet.delete(item.favoriteID);
        refFavoriteIDs[key] = [...favoriteIDSet];

        if (refFavoriteIDs[key].length < 1) {
          delete refFavoriteIDs[key];
        }
      }

      delete draft.favoriteListingInfo.listingDefaultCountData[`${key}.${item.favoriteID}`];

      storesFavoriteListingTabs.forEach(sflt => {
        if (`${sflt.store.id}` === item.storeID) {
          sflt.favoriteCount = sflt.favoriteCount - 1;
          const newFavoriteTabs: IFavoriteTab[] = [];
          let tabChanged = false;
          sflt.favoriteTabs.forEach(favoriteTab => {
            if (`${favoriteTab.id}` === item.favoriteTabID) {
              const newFavoriteSections: IFavoriteSection[] = [];
              let sectionChanged = false;
              favoriteTab.favoriteSections.forEach(favoriteSection => {
                if (`${favoriteSection.id}` === item.favoriteSectionID) {
                  let listingChanged = false;
                  const newListings: IListing[] = [];
                  favoriteSection.listings.forEach(listing => {
                    if (`${listing.favoriteID}` !== item.favoriteID) {
                      newListings.push(listing);
                    }
                  });
                  if (newListings.length !== favoriteSection.listings.length) {
                    listingChanged = true;
                  }

                  if (listingChanged) {
                    sectionChanged = true;
                    favoriteSection = {
                      ...favoriteSection,
                      listings: newListings,
                    };
                  }
  
                  if (favoriteSection.listings.length > 0) {
                    newFavoriteSections.push(favoriteSection);
                  } else {
                    sectionChanged = true;
                  }
                }
              });

              if (sectionChanged) {
                tabChanged = true;
                favoriteTab = {
                  ...favoriteTab,
                  favoriteSections: newFavoriteSections,
                }
              }
            }

            if (favoriteTab.favoriteSections.length > 0) {
              newFavoriteTabs.push(favoriteTab);
            } else {
              tabChanged = true;
            }
          });

          if (tabChanged) {
            tabsChanged = true;
            sflt.favoriteTabs = newFavoriteTabs;
          }
        }
      })
    });

    draft.favoriteListingInfo.favoriteListings = favoriteListings;
    if (refChanged) {
      draft.favoriteListingInfo.refFavoriteIDs = refFavoriteIDs;
    }
    if (tabsChanged) {
      draft.favoriteListingInfo.storesFavoriteListingTabs = storesFavoriteListingTabs;
    }
  }
});

export default handleActions(actions, initState);
