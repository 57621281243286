import produce from 'immer';
import { handleActions } from 'redux-actions';
import ActionType from '@/actions/action-type';
import {
  IBooking,
  ISetBookings,
} from '@/actions/booking-action';

export interface IBookingState {
  IDRefBooking: {[id: string]: IBooking};
  pageRefBookings: {[page: number]: string[]};
}

const initState: IBookingState = {
  IDRefBooking: {},
  pageRefBookings: {},
};

const actions: any = {};

actions[ActionType.SET_BOOKINGS] = (state: IBookingState, action: ISetBookings) => produce(state, draft => {
  if (action.page) {
    const page = action.page
    draft.pageRefBookings[page] = [];
    action.bookings.forEach(item => {
      draft.IDRefBooking[item.id] = item;
      draft.pageRefBookings[page].push(item.id);
    })
  } else if (action.isAdd) {
    draft.IDRefBooking[action.bookings[0].id] = action.bookings[0];
    if (draft.pageRefBookings[1]) {
      draft.pageRefBookings[1].unshift(action.bookings[0].id);
    }
  } else {
    draft.IDRefBooking[action.bookings[0].id] = action.bookings[0];
  }
});

actions[ActionType.CLEAR_BOOKING] = (state: IBookingState) => produce(state, draft => {
  draft.pageRefBookings = {};
  draft.IDRefBooking = {};
});

export default handleActions(actions, initState);
