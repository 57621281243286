import cloneDeep from 'lodash/cloneDeep';
import Channels from '../enums/Channels';
import { IFavoriteTab, IModifier, IListing } from '../utils/api';
import { findNode } from '@/utils';

let channel: Channels | undefined = undefined;
const favoriteTabsMap: {[storeID: string]: IFavoriteTab[]} = {};
const modifiersMap: {[storeID: string]: IModifier[]} = {};

export const setChannel = (c: Channels) => {
  channel = c;
}

export const getChannel = () => channel;

export const findFavoriteTabs = (storeID: string) => {
  return favoriteTabsMap[`${storeID}`] || [];
}

export const getFavoriteTabs = (storeID: string) => {
  return cloneDeep(findFavoriteTabs(storeID));
}

export const getFavoriteTabsMap = () => cloneDeep(favoriteTabsMap);

export const setFavoriteTabs = (storeID: string, favoriteTabs: IFavoriteTab[]) => {
  if (storeID && Array.isArray(favoriteTabs)) {
    favoriteTabsMap[`${storeID}`] = favoriteTabs;
    connectListingAndModifier(storeID);
  }
}

export const getStoreModifiers = (storeID: string) => {
  return modifiersMap[storeID] || [];
}

export const getListing = (storeID: string, key: string) => {
  let listing: IListing | undefined = undefined;
  const favoriteTabs = findFavoriteTabs(storeID);

  for (let ftIndex = 0; ftIndex < favoriteTabs.length; ftIndex++) {
    const favoriteTab = favoriteTabs[ftIndex];
    
    for (let fsIndex = 0; fsIndex < favoriteTab.favoriteSections.length; fsIndex++) {
      const favoriteSection = favoriteTab.favoriteSections[fsIndex];
      
      for (let fIndex = 0; fIndex < favoriteSection.listings.length; fIndex++) {
        const listingTemp = favoriteSection.listings[fIndex];
        // eslint-disable-next-line
        if (listingTemp.id == key || listingTemp.favoriteID == key) {
          listing = listingTemp;
        }

        if (listing) break;
      }

      if (listing) break;
    }

    if (listing) break;
  }

  return listing;
}

const getFavoriteModifiers = (data: IListing, modifiers: IModifier[]) => {
  const newModifiers: IModifier[] = [];

  if (data && Array.isArray(modifiers)) {
    modifiers.forEach(item => {
      if (
        item.departmentIDs.includes(data.departmentID)
        || item.productIDs.includes(data.productID)
        || data.modifierIDs.includes(item.id)
      ) {
        newModifiers.push(item);
      }
    });
  }

  return newModifiers;
};

export const findModifiers = (storeID: string, listingID: string, modifierID?: string) => {
  let modifiers: IModifier[] = [];
  const storeModifiers = getStoreModifiers(storeID);
  let listing: IListing | undefined = undefined;
  let modifier: IModifier | undefined = undefined;

  if (storeModifiers.length > 0) {
    listing = getListing(storeID, listingID);
  }

  if (listing) {
    modifiers = getFavoriteModifiers(listing, storeModifiers);
  }

  if (modifierID) {
    modifier = findNode<IModifier>(modifiers, 'id', modifierID);
    modifiers = [];
    if (modifier) {
      modifiers = modifier.children;
    }
  }

  return cloneDeep({
    modifiers,
    listing,
    modifier,
  });
}

export const findListing = (storeID: string, listingID: string) => {
  let listing: IListing | undefined = undefined;

  listing = getListing(storeID, listingID);

  return cloneDeep({
    listing,
  });
}

export const connectListingAndModifier = (storeID: string) => {
  const favoriteTabs = findFavoriteTabs(storeID);
  const storeModifiers = getStoreModifiers(storeID);

  if (favoriteTabs.length > 0 && storeModifiers.length > 0) {
    for (let ftIndex = 0; ftIndex < favoriteTabs.length; ftIndex++) {
      const favoriteTab = favoriteTabs[ftIndex];
      
      for (let fsIndex = 0; fsIndex < favoriteTab.favoriteSections.length; fsIndex++) {
        const favoriteSection = favoriteTab.favoriteSections[fsIndex];
        
        for (let fIndex = 0; fIndex < favoriteSection.listings.length; fIndex++) {
          const favorite = favoriteSection.listings[fIndex];
          const modifiers = getFavoriteModifiers(favorite, storeModifiers);
          favorite.modifiers = modifiers;
        }
      }
    }
  }
}

export const setModifiers = (storeID: string, modifiers: IModifier[]) => {
  if (storeID && Array.isArray(modifiers)) {
    modifiersMap[storeID] = modifiers;
    connectListingAndModifier(storeID);
  }
}
