import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import Layout from '@/components/Layout';
import { IObject } from '@/components/bm-common';
import { parseRouteParams } from '@/utils';
import Icon, { IconType } from '@/components/Icon';
import { prefix } from '.';
import { getIntl } from '@/components/App/App';

export interface IPayResultsWithNoOrderInfoPageProps extends IBasePageProps {}

interface IPayResultsWithNoOrderInfoPageState {
  status: string;
}

class PayResultsWithNoOrderInfoPage extends BasePage<IPayResultsWithNoOrderInfoPageProps, IPayResultsWithNoOrderInfoPageState> {

  routeParams: IObject = {};

  state = {
    status: '',
  }

  componentDidMount () {
    super.componentDidMount();
    this.routeParams = parseRouteParams(this.props);
    const {
      status = 'success',
    } = this.routeParams;
    this.setState({ status });
  }

  render () {
    const { status } = this.state;

    return (
      <>
        <Layout
          {...this.props}
          className={prefix}
          navBar={<div></div>}
          isFooter={false}
        >
          <div className={`${prefix}-wapper`}>
            {
              status === 'success' &&
              <>
                <Icon type={IconType.STATUS_SUCCESS} className={`${prefix}-wapper-logo`}/>
                {getIntl().page.paymentSuccessful}
              </>
            }
            {
              status === 'failed' &&
              <>
                <Icon type={IconType.STATUS_FAILURE} className={`${prefix}-wapper-logo`}/>
                {getIntl().page.paymentFailed}
              </>
            }
          </div>
        </Layout>
      </>
    );
  }
}
export default PayResultsWithNoOrderInfoPage;
