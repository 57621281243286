import React from 'react';
import dayjs from 'dayjs';
import AntdIcon from 'antd-mobile/es/icon';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import Icon, { IconType } from '@/components/Icon';
import { IObject } from '@/components/bm-common';
import { getAppTheme, setScreenColor, getIntl } from '@/components/App/App';
import Layout from '@/components/Layout';
import { formatCurrency } from '@/components/Payment';
// import StoreInfo from '@/pages/express-order/NewExpressOrder/StoreInfo';
import { IStore } from '@/actions/store-action';
import { IPurchaseOrder } from '@/actions/purchase-order-action';
import ActionType from '@/actions/action-type';
import { getI18nText } from '@/utils/app';
import { prefix } from '.';
import paths from '@/routes/paths';

export interface IPurchaseOrderPageProps extends IBasePageProps { }
export interface IPurchaseOrderPageState {
  selectStore: IStore | undefined; //选择的store;
  purchaseOrders: IPurchaseOrder[] | undefined;
  page: number;
  loading: boolean;
  time: string;
  status: string;
}

class PurchaseOrderPage extends BasePage<IPurchaseOrderPageProps, IPurchaseOrderPageState> {
  lastPage = false;
  loadingData = false;
  constructor(props: IPurchaseOrderPageProps) {
    super(props);
    this.state = {
      selectStore: undefined,
      purchaseOrders: undefined,
      page: 1,
      loading: false,
      time: 'all',
      status: 'all',
    }
  }
  routeParams: IObject = {};

  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.darkBackgroundColor) {
      setScreenColor(appTheme.darkBackgroundColor);
    }

    super.componentDidMount();
    const { reducersState, dispatch } = this.props;
    const { selectStoreId } = reducersState.supplierInfo;
    // const { suppliers } = reducersState.app.platformAppSettings || {};
    dispatch({
      type: ActionType.LOADING,
      showLoading: true,
      showLoadingBgc: true,
    });
    if (selectStoreId) {
      dispatch({
        type: ActionType.QUERY_STORE_BY_ID,
        storeID: selectStoreId.toString(),
        callback: (store, storeConfig) => {
          if (store) {
            this.setState({selectStore: store})
            this.getPurchaseOrders(store.slug, 1);
          }
        }
      });
    }
    const pageWrap = window.document.querySelector('.page-content-wrapper-web');
    if (pageWrap) {
      pageWrap.addEventListener('scroll', this.handleScroll, { passive: true });
    } else {
      window.addEventListener('scroll', this.handleScroll, { passive: true });
    }
  }

  componentWillUnmount() {
    const pageWrap = window.document.querySelector('.page-content-wrapper-web');
    if (pageWrap) {
      pageWrap.removeEventListener('scroll', this.handleScroll);
    } else {
      window.removeEventListener('scroll', this.handleScroll);
    }
  }

  getScrollY = () => {
    const pageWrap = window.document.querySelector('.page-content-wrapper-web');
    let scrollY = window.scrollY;
    if (pageWrap) {
      scrollY = pageWrap.scrollTop;
    }

    return scrollY;
  }

  handleScroll = () => {
    const { page, selectStore, time, status } = this.state;
    if (!this.lastPage && !this.loadingData) {
      const appPages = document.getElementsByClassName('bm-p-purchase-order');
      if (appPages && appPages.length && selectStore) {
        const appPage = appPages[0];
        const scrollHeight = appPage.scrollHeight;
        const canScrollHeight = scrollHeight - window.innerHeight;
        if (canScrollHeight - this.getScrollY() < 3 * 100) {
          this.loadingData = true
          this.setState({loading: true});
          this.getPurchaseOrders(selectStore.slug, page + 1, true, time, status);
        }
      }
    }
  }

  getPurchaseOrders = (storeSlug: string, page: number, isAdd = false, time = 'all', status = 'all') => {
    const { dispatch } = this.props;
    const { purchaseOrders } = this.state;
    let formula = 'IS_NOT_NULL("$.self.payment_state").NEQ("$.self.payment_state", "unpaid")';
    if (['submitted', 'fulfilled', 'partially_fulfilled', 'canceled'].includes(status)) {
      formula = `${formula}.EQ("$.self.state", "${status}")`;
    } else if (status === 'paid'){
      formula = `${formula}.EQ("$.self.payment_state", "${status}")`;
    } else if (status === 'deferred') {
      formula = `${formula}.NEQ("$.self.payment_state", "paid")`;
    }
    const today = dayjs();
    const timeZone = today.format('Z');
    if (time === 'today') {
      const date = today.format('YYYY-MM-DD');
      formula = `${formula}.BETWEEN("$.self.created_at", "${date}T00:00:00${timeZone}", "${date}T23:59:59${timeZone}")`;
    } else if (time === 'week') {
      formula = `${formula}.BETWEEN("$.self.created_at", "${today.startOf('week').format('YYYY-MM-DD')}T00:00:00${timeZone}", "${today.endOf('week').format('YYYY-MM-DD')}T23:59:59${timeZone}")`;
    } else if (time === 'month') {
      formula = `${formula}.BETWEEN("$.self.created_at", "${today.startOf('month').format('YYYY-MM-DD')}T00:00:00${timeZone}", "${today.endOf('month').format('YYYY-MM-DD')}T23:59:59${timeZone}")`;
    } else if (time === 'more') {
      const date = today.subtract(1, 'month').format('YYYY-MM-DD');
      formula = `${formula}.LT("$.self.created_at", "${date}T00:00:00${timeZone}")`;
    }

    dispatch({
      type: ActionType.QUERY_PURCHASE_ORDERS,
      storeSlug: storeSlug,
      page: page,
      formula,
      callback: records => {
        let newPurchaseOrders: IPurchaseOrder[] = []; 
        if (isAdd && purchaseOrders) {
          newPurchaseOrders = purchaseOrders.concat(records);
        } else {
          newPurchaseOrders = records;
        }
        if (records.length < 10) this.lastPage = true;
  
        if (records) this.loadingData = false;
        this.setState({
          purchaseOrders: newPurchaseOrders,
          loading: false,
          page,
          time,
          status,
        })
        dispatch({
          type: ActionType.LOADING,
          showLoading: false,
        })
      }
    })
  }

  handleGoDetail = (purchaseOrderID: string) => {
    this.handleGotoRoute({
      path: paths.PURCHASE_ORDER_DETAIL,
      pathParams: {
        purchaseOrderID: purchaseOrderID,
      },
    });
  }

  handleSearch = (newTime: string, newStatus: string) => {
    const { selectStore, time, status } = this.state;
    if (selectStore && (time !== newTime || status !== newStatus)) {
      this.lastPage = false;
      this.getPurchaseOrders(selectStore.slug, 1, false, newTime, newStatus);
    }
  }
  
  render() {
    const { reducersState } = this.props;
    const { 
      selectStore,
      purchaseOrders,
      loading,
      time,
      status,
    } = this.state;
    return (
      <Layout
        {...this.props}
        className={prefix}
        navIcon={<Icon className="back-icon" type={IconType.LEFT_OUTLINED} />}
        onLeftClick={() => this.handleGoBack({ path: paths.HOME, runGoBack: true })}
        navContent={getIntl().page.purchaseOrderTitle}
      >
        <div className="purchase-order">
          <div className="purchase-order-style-b-color"></div>
          <div className="purchase-order-section">
            <div className="purchase-order-content">
              <div className="content-panel-wrap">
                {
                  selectStore &&
                  <div className="store-card">
                    <div className="store-info">
                      <div className="store-info-logo" style={{ backgroundImage: `url(${selectStore.imgUrl})`}}></div>
                      <div className="store-info-details">
                        <div className="store-info-details-title">{getI18nText(selectStore, 'title', reducersState.app.language) || selectStore.name}</div>
                        <div className="store-info-details-address">
                          <Icon className="address-icon" type={IconType.MAP_OUTLINED} />
                          <div className="store-info-details-address-data">{getI18nText(selectStore, 'address1', reducersState.app.language) || selectStore.address}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
              <div className="purchase-order-items">
                <div className="purchase-order-items-search">
                  <div className="purchase-order-items-search-type">
                    <div className="purchase-order-items-search-type-title">{getIntl().page.time}</div>
                    <div className="purchase-order-items-search-type-options">
                      <div className={`purchase-order-items-search-type-option${time === 'all' ? ' select' : ''}`} onClick={() => this.handleSearch('all', status)}>{getIntl().page.all}</div>
                      <div className={`purchase-order-items-search-type-option${time === 'today' ? ' select' : ''}`} onClick={() => this.handleSearch('today', status)}>{getIntl().common.today}</div>
                      <div className={`purchase-order-items-search-type-option${time === 'week' ? ' select' : ''}`} onClick={() => this.handleSearch('week', status)}>{getIntl().page.thisWeek}</div>
                      <div className={`purchase-order-items-search-type-option${time === 'month' ? ' select' : ''}`} onClick={() => this.handleSearch('month', status)}>{getIntl().page.thisMonth}</div>
                      <div className={`purchase-order-items-search-type-option${time === 'more' ? ' select' : ''}`} onClick={() => this.handleSearch('more', status)}>{getIntl().page.monthAgo}</div>
                    </div>
                  </div>
                  <div className="purchase-order-items-search-type">
                    <div className="purchase-order-items-search-type-title">{getIntl().page.status}</div>
                    <div className="purchase-order-items-search-type-options">
                      <div className={`purchase-order-items-search-type-option${status === 'all' ? ' select' : ''}`} onClick={() => this.handleSearch(time, 'all')}>{getIntl().page.all}</div>
                      <div className={`purchase-order-items-search-type-option${status === 'paid' ? ' select' : ''}`} onClick={() => this.handleSearch(time, 'paid')}>{getIntl().common.paid}</div>
                      <div className={`purchase-order-items-search-type-option${status === 'deferred' ? ' select' : ''}`} onClick={() => this.handleSearch(time, 'deferred')}>{getIntl().common.deferred}</div>
                      <div className={`purchase-order-items-search-type-option${status === 'submitted' ? ' select' : ''}`} onClick={() => this.handleSearch(time, 'submitted')}>{getIntl().page.accept}</div>
                      <div className={`purchase-order-items-search-type-option${status === 'fulfilled' ? ' select' : ''}`} onClick={() => this.handleSearch(time, 'fulfilled')}>{getIntl().common.fulfilled}</div>
                      <div className={`purchase-order-items-search-type-option${status === 'partially_fulfilled' ? ' select' : ''}`} onClick={() => this.handleSearch(time, 'partially_fulfilled')}>{getIntl().common.partiallyFulfilled}</div>
                      <div className={`purchase-order-items-search-type-option${status === 'canceled' ? ' select' : ''}`} onClick={() => this.handleSearch(time, 'canceled')}>{getIntl().page.poCancelled}</div>
                    </div>
                  </div>
                </div>
                <div className="purchase-order-items-content">
                  {
                    selectStore && purchaseOrders && purchaseOrders.map(item => {
                      return (
                        <div className="purchase-order-item" key={item.id} onClick={() => this.handleGoDetail(item.id)}>
                          <div className="purchase-order-item-number-and-state">
                            <div className="purchase-order-item-number"><div className="purchase-order-item-number-reference">{item.referenceNumber}</div><div>{item.number}</div></div>
                            <div className="purchase-order-item-state">
                              {
                                item.state !== 'voided' && item.state !== 'canceled' &&
                                <div className={`purchase-order-item-state pay ${item.paymentState}`}>
                                  {item.paymentState === 'paid' && getIntl().common.paid}
                                  {item.paymentState !== 'paid' && getIntl().common.deferred}
                                </div>
                              }
                              {
                                item.state === 'pending' &&
                                <div className="purchase-order-item-state arrival created">{getIntl().page.created}</div>
                              }
                              {
                                item.state === 'submitted' &&
                                <div className="purchase-order-item-state arrival accepted">{getIntl().page.accept}</div>
                              }
                              {
                                (item.state === 'voided' || item.state === 'canceled') &&
                                <div className="purchase-order-item-state arrival canceled">{getIntl().common.voided}</div>
                              }
                              {
                                item.state === 'fulfilled' &&
                                <div className="purchase-order-item-state arrival fulfilled">{getIntl().common.fulfilled}</div>
                              }
                              {
                                item.state === 'partially_fulfilled' &&
                                <div className="purchase-order-item-state arrival partially_fulfilled">{getIntl().common.partiallyFulfilled}</div>
                              }
                            </div>
                          </div>
                          <div className="purchase-order-item-supplier-name">{item.supplierName}</div>
                          <div className="purchase-order-item-info">
                            <div className="purchase-order-item-info-total-date">
                              <div className="purchase-order-item-info-total">{formatCurrency(item.total, selectStore.currencyCode)}</div>
                              <div className="purchase-order-item-info-dividing"></div>
                              <div className="purchase-order-item-info-date">
                                {dayjs(item.createAt).format('DD MMM YYYY')}
                              </div>
                            </div>
                            <div className="purchase-order-item-info-view-details">
                              <div className="purchase-order-item-info-view-details-title">{getIntl().page.viewDetail}</div>
                              <Icon className="purchase-order-item-info-view-details-icon" type={IconType.DOWN_OUTLINED} />
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
                {
                  purchaseOrders && loading &&
                  <div className="loading-data">
                    <AntdIcon type="loading" />
                    <div className="loading-msg">
                      {getIntl().common.loading}
                    </div>
                  </div>
                }
              </div>
              {
                purchaseOrders && purchaseOrders.length === 0 &&
                <div className={'no-data'}>
                  <div>
                    <Icon className="img" type={IconType.TAG_ITEM}/>
                    <div className="no-data-tip">
                      {getIntl().page.noPurchasrOrderTip}
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default PurchaseOrderPage;
