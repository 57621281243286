import { combineReducers } from 'redux';
import app, { IAppState } from './app-reducer';
import user, { IUserState } from './user-reducer';
import store, { IStoreState } from './store-reducer';
import pay, { IPayState } from './pay-reducer';
import order, { IOrderState } from './order-reducer';
import cart, { IShoppingCartState } from './shopping-cart-reducer';
import listing, { IListingState } from './listing-reducer';
import queueing, { IQueueingState } from './queueing-reducer';
import booking, { IBookingState } from './booking-reducer';
import qrcordShopping, { IQrcordShoppingState } from './qrcord-shopping-reducer';
import supplierInfo, { ISupplierInfoState } from './supplier-info-reducer';

export interface IReducersState {
  app: IAppState;
  user: IUserState;
  store: IStoreState;
  pay: IPayState;
  order: IOrderState;
  cart: IShoppingCartState;
  listing: IListingState;
  queueing: IQueueingState;
  booking: IBookingState;
  qrcordShopping: IQrcordShoppingState;
  supplierInfo: ISupplierInfoState;
}

const reducers = combineReducers({
  app,
  user,
  store,
  pay,
  order,
  cart,
  listing,
  queueing,
  booking,
  qrcordShopping,
  supplierInfo,
});

export default reducers;
