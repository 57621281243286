import React from 'react';
import { prefix } from '../';
// import { resizeImage } from '@/utils';
import { ICreateViewParams } from '.';
import DotsAndBtn from '../DotsAndBtn';
import { getI18nText } from '@/utils/app';

interface IImageViewProps extends ICreateViewParams {
}

const ImageView: React.FC<IImageViewProps> = props => {
  const {
    data,
    count,
    index,
    showItemBtns,
    onDoneClick,
    onNextClick,
    language,
  } = props;

  // const imgUrl = resizeImage({
  //   url: data.imageUrl,
  //   type: 'WIDTH',
  //   width: window.innerWidth < 1080 ? 1080 : window.innerWidth,
  // });

  return (
    <div className={`${prefix}-item`}>
      <div className="image-item-img-wrapper">
        {
          data.imageUrl &&
          <div className="image-item-img" style={{backgroundImage: `url(${data.imageUrl})`}} />
        }
      </div>
      <div className="image-item-content-wrapper">
        <div className="itme-space">&nbsp;</div>
        <div className="image-item-content" style={{ textAlign: data.alignment }}>
          {
            data.title &&
            <div className="image-item-title">{getI18nText(data, 'title', language) || data.title}</div>
          }
          {
            data.describe &&
            <div className="image-item-describe">{getI18nText(data, 'text', language) || data.describe}</div>
          }
        </div>
      </div>
      {
        showItemBtns &&
        <DotsAndBtn
          count={count}
          index={index}
          onNextClick={onNextClick}
          onDoneClick={onDoneClick}
        />
      }
    </div>
  );
}

export default ImageView;
