import React from 'react';
import { IStore } from '@/actions/store-action';
import { getI18nText } from '@/utils/app';
import { IReducersState } from '@/reducers';
import Icon, { IconType } from '@/components/Icon';

interface IStoreInfoProps {
  store: IStore;
  reducersState: IReducersState;
  handleSelectStore?: () => void;
  canChangeStore?: boolean;
}

const StoreInfo: React.FC<IStoreInfoProps> = props => {
  const { store, reducersState, handleSelectStore, canChangeStore } = props;

  return (
    <div className="div-block-510" onClick={handleSelectStore}>
      <div className="div-block-514">
        <div className="div-block-511"  style={{ backgroundImage: `url(${store.imgUrl})`}}></div>
        <div className="div-block-515">
          <div className="text-block-104">
            <div>{getI18nText(store, 'title', reducersState.app.language) || store.name}</div>
            {
              canChangeStore &&
              <Icon className="down" type={IconType.DOWN_OUTLINED} />
            }
          </div>
          <div className="_107">
            <Icon className="address-icon" type={IconType.MAP_OUTLINED} />
            {store && (getI18nText(store, 'address1', reducersState.app.language) || store.address)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default StoreInfo;
