import { IInvoiceInfo } from '@/actions/qrcord-shopping-action';
import { IShoppingCartData } from '@/actions/shopping-cart-action';
import { formatCurrency } from '@/components/Payment';
import { IOrderInfo } from '@/lib/order-builder/models/OrderEntity';
import { plus } from '@/lib/order-builder/utils';

export const parseShoppingCartData = (shoppingCartData?: IShoppingCartData) => {
  let lineItemCount = 0;
  let invalidLineItemCount = 0;
  let initialTotal = 0;

  if (shoppingCartData) {
    const {
      orderInfo,
    } = shoppingCartData;

    initialTotal = orderInfo.initialTotal;

    orderInfo.orderEntities.forEach(orderEntity => {
      orderEntity.lineItems.forEach(item => {
        lineItemCount += item.quantity;
      });
      orderEntity.invalidLineItems.forEach(item => {
        invalidLineItemCount += item.quantity;
      });
    });
  }

  return {
    initialTotal,
    lineItemCount,
    invalidLineItemCount,
  };
}

export const parseQrcodeShoppingOrderInfosCount = (invoiceInfo: IInvoiceInfo, submittedOrderInfo?: IOrderInfo, pendingOrderInfo?: IOrderInfo, cartOrderInfo?: IOrderInfo) => {
  let lineItemCount = 0;
  let invalidLineItemCount = 0;
  let initialTotal = 0;
  let subTotal = 0;
  let discountTotal = 0;
  let serviceFee = 0;
  let taxFee = 0;

  const parseOrderInfosCount = (orderInfo?: IOrderInfo) => {
    let hasItems = false;
    if (orderInfo) {
      initialTotal = plus(initialTotal, orderInfo.initialTotal);
      subTotal = plus(subTotal, orderInfo.subTotal);
      discountTotal = plus(discountTotal, orderInfo.discountTotal);
      serviceFee = plus(serviceFee, orderInfo.initialServiceFee);
      taxFee = plus(taxFee, orderInfo.initialTax);
      orderInfo.orderEntities.forEach(orderEntity => {
        orderEntity.lineItems.forEach(item => {
          lineItemCount += item.quantity;
        });
        hasItems = orderEntity.lineItems.length > 0;
        orderEntity.invalidLineItems.forEach(item => {
          invalidLineItemCount += item.quantity;
        });
      });
    }

    return hasItems;
  }

  const hasSubmittedItems = parseOrderInfosCount(submittedOrderInfo);
  const hasPendingItems = parseOrderInfosCount(pendingOrderInfo);
  const hasNewItems = parseOrderInfosCount(cartOrderInfo);

  return {
    subTotal: formatCurrency(subTotal, invoiceInfo.currencyCode),
    initialTotal: formatCurrency(initialTotal, invoiceInfo.currencyCode),
    discountTotal: formatCurrency(discountTotal, invoiceInfo.currencyCode),
    serviceFee: formatCurrency(serviceFee, invoiceInfo.currencyCode),
    taxFee: formatCurrency(taxFee, invoiceInfo.currencyCode),
    lineItemCount,
    invalidLineItemCount,
    hasNewItems,
    hasPendingItems,
    hasSubmittedItems,
    discountTotalNumber: discountTotal,
    serviceFeeNumber: serviceFee,
    taxFeeNumber: taxFee,
    isPaid: invoiceInfo.state === 'completed',
  };
}
