import React from 'react';

interface ISearchHistoryItemtProps {
  data: string;
  onItemClick: (searchKey: string) => void;
}

const HistoryItem: React.FC<ISearchHistoryItemtProps> = props => {
  const { data, onItemClick } = props;

  return (
    <div className="search-history-keywords" onClick={() => onItemClick(data)}>
      <div>{data}</div>
    </div>
  )
}
export default HistoryItem;
