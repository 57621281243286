import React, { useState } from 'react';
import Button from 'antd-mobile/es/button';
import { useSelector, useDispatch } from 'react-redux';
import { IReducersState } from '@/reducers';
import Toast from 'antd-mobile/es/toast';
import { prefix } from '.';
import { IDispatch } from '@/actions';
import ActionType from '@/actions/action-type';
import SignPassword from '../common/userComponents/Password';
import SignUserName from '../common/userComponents/NewUserName';
import SignBottom from './SignInBottom';
import { getIntl } from '../App/App';


interface ISignInMobileProps {
  signInClick?: () => void;
  className?: string;
  forgotClick?: () => void;
}

const SignInEmail: React.FC<ISignInMobileProps> = props => {

  const {
    className = '',
    signInClick = () => {},
    forgotClick = () => {},
  } = props;

  const dispatch = useDispatch<IDispatch>();

  const reducersState: IReducersState = useSelector<IReducersState, IReducersState>(state => state);

  const { language } = reducersState.app;

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const signInFlow = async () => {
    if (email && password) {
      dispatch({
        type: ActionType.LOADING,
        showLoading: true,
        showLoadingBgc: true,
      });
      dispatch({
        type: ActionType.SIGN_IN,
        username: `${email}`,
        password: password,
        loginMethod: 'email',
        callback: (success, userInfo) => {
          if (success) {
            signInClick();
          } else {
            Toast.info(getIntl().page.signInErrorMsg);
          };

          dispatch({
            type: ActionType.LOADING,
            showLoading: false,
          });
        }
      });
    }
  }

  return (
    <div className={`${prefix}-email ${className}`} >
      <SignUserName
        language={language}
        value={email}
        onChange={setEmail}
      />
      <SignPassword
        value={password}
        onChange={setPassword}
        forgotClick={forgotClick}
      />
      {
        <Button
          type="primary"
          className={`${prefix}-signin-button`}
          onClick={signInFlow}
          disabled={ !email || !password }
        >
          {getIntl().page.signin}
        </Button>
      }
      <SignBottom />
    </div>
  )
}
export default SignInEmail;
