import React from 'react';
import { IStore, IStoreCategory, IStoreConfig } from '@/actions/store-action';
import { prefix, FootStyle } from '.';
import StoreTags from './StoreTags';
import Icon, { IconType } from '../Icon';
import { IReducersState } from '@/reducers';
import { getI18nText } from '@/utils/app';

interface IStoreListItemProps {
  store: IStore;
  reducersState: IReducersState;
  storeCategories: IStoreCategory[];
  distanceTitle: string;
  className?: string;
  onClick?: (store: IStore) => void;
  footStyle?: FootStyle;
}

const StoreListItem: React.FC<IStoreListItemProps> = props => {
  const {
    store,
    className = '',
    reducersState,
    storeCategories,
    distanceTitle,
    onClick,
    footStyle = FootStyle.STORE_DEFAULT,
  } = props;

  const storeConfig: IStoreConfig = reducersState.store.slugRefStoreConfig[store.slug];
  const { platformConfig } = reducersState.app;

  const handleClick = () => {
    if (onClick) {
      onClick(store);
    }
  }

  return (
    <div className={`${prefix}-item ${className} branch-card w-inline-block branch-card-width`} onClick={handleClick}>
      <div className="image-card-upper bgimg-size">
        <div className="store-img" style={{backgroundImage: `url(${store.imgUrl})`}} />
      </div>
      {
        footStyle === FootStyle.STORE_DEFAULT &&
        <div className="div-block-99">
          <div className="div-heading">
            <h4 className="heading-4">{getI18nText(store, 'title', reducersState.app.language) || store.name}</h4>
          </div>
          <div className="store-other-info">
            {
              platformConfig.feedbackEnable &&
              <div className="store-score">
                <Icon type={IconType.STAR_OUTLINED} />
                <span>{(Number(store.storeScore) || 5).toFixed(1)}</span>
                <div className="splace-line" />
              </div>
            }
            {
              storeCategories && storeCategories.length > 0 &&
              storeCategories.map((item, index) => {
                return (
                  <>
                    <div key={`category-${item.id}`} className="store-category text-block-5---subtitle">
                      {getI18nText(item, 'category_name', reducersState.app.language)}
                    </div>
                    {
                      index !== storeCategories.length - 1 &&
                      <div className="splace-line" />
                    }
                  </>
                )
              })
            }
            {
              distanceTitle && false &&
              <>
                {
                  storeCategories && storeCategories.length > 0 &&
                  <div className="splace-line" />
                }
                <div className="store-category text-block-5---subtitle">{distanceTitle}</div>
              </>
            }
          </div>
          {
            platformConfig.storeFunctionLabel && 
            <StoreTags
              store={store}
              storeConfig={storeConfig}
            />
          }
        </div>
      }
      {
        footStyle === FootStyle.STORE_ADDRESS &&
        <div className="store-info">
          <div className="store-name">
            {getI18nText(store, 'title', reducersState.app.language) || store.name}
          </div>
          <div className="store-other-info">
            <div className="store-address">
              {store && (getI18nText(store, 'address1', reducersState.app.language) || store.address)}
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default StoreListItem;
