enum SignInMethod {
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
  GUEST = 'GUEST',
  FACEBOOK = 'FACEBOOK',
  APPLE = 'APPLE',
  WECHAT = 'WECHAT',
}

export default SignInMethod;
