import React from 'react';
import dayjs from 'dayjs';
import { getIntl, getIntlByPath } from '@/components/App/App';

interface IChooseDateItemProps {
  className: string;
  day: number;
  language: string;
  showMonth: boolean;
  onClick: (day: string, week: string) => void;
}

const ChooseDateItem: React.FC<IChooseDateItemProps> = props => {
  const {
    className,
    day,
    language,
    showMonth,
    onClick,
  } = props;

  const showDate = dayjs().add(day, 'day');
  let week = getIntlByPath(`common.${showDate.format('ddd')}`);
  if (day === 0) {
    week = getIntl().common.today
  }

  return (
    <div className="radio-block">
      <div className="date-info">
        {week}
      </div>
      <label className="radio-button-date w-radio" onClick={() => onClick(showDate.format('YYYY-MM-DD'), showDate.format('d'))}>
        <span className={`label w-form-label ${className}${day === 0 || showMonth ? ' month' : ''}`}>
          {
            (day === 0 || showMonth) &&
            <div>
              {
                language === 'en-US' &&
                <>
                  {showDate.format('D')} {getIntlByPath(`common.${showDate.format('MMM')}`)}
                </>
              }
              {
                language !== 'en-US' &&
                <>
                  {showDate.format('M')}{getIntl().common.month}{showDate.format('D')}{getIntl().common.day}
                </>
              }
            </div>
          }
          {
            !(day === 0 || showMonth) &&
            showDate.format('D')
          }
        </span>
      </label>
    </div>
  );
}

export default ChooseDateItem;
