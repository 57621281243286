import React from 'react';
import { IFavoriteTab } from '@/lib/order-builder/utils/api';
import Icon, { IconType } from '@/components/Icon';
import './MenuNav.less';
import { getI18nText } from '@/utils/app';
import { IReducersState } from '@/reducers';

interface IMenuNavProps {
  data: IFavoriteTab[];
  itemClassName?: string;
  isFixed?: boolean;
  showMenuNavLeftTag: boolean;
  showMenuNavRightTag: boolean;
  selectedKey?: string;
  onItemClick?: (data: IFavoriteTab) => void;
  onScroll?: (data: any) => void;
  reducersState: IReducersState;
}

const MenuNav: React.FC<IMenuNavProps> = props => {
  const {
    data,
    // isFixed,
    selectedKey,
    showMenuNavLeftTag,
    showMenuNavRightTag,
    onItemClick,
    onScroll,
    reducersState,
  } = props;

  return (
    <>
      <div id="bm-store-menus-nav" className="overlay-nav w-container">
        <div className="div-block-88-left">
          <div className={`menu-nav-left-tag ${showMenuNavLeftTag ? 'show-tag' : ''}`}>
            <Icon type={IconType.LEFT_OUTLINED} />
          </div>
        </div>
        <div id="bm-store-menus-nav-box" className="drag-nav-bar w-container" onScroll={onScroll}>
          <div id="bm-store-menus-nav-content" className="div-content">
            {
              data.map(item => item && item.favoriteSections.length > 0 && (
                <div
                  key={item.id}
                  id={`bm-store-menus-nav-item-${item.id}`}
                  onClick={() => onItemClick && onItemClick(item)}
                  className={`in-page-link menu-nav-item ${selectedKey === item.id ? 'selected' : ''}`}
                >
                  {getI18nText(item, 'name', reducersState.app.language) || item.name}
                </div>
              ))
            }
          </div>
        </div>
        <div className="div-content">
          <div className={`menu-nav-right-tag ${showMenuNavRightTag ? 'show-tag' : ''}`}>
            <Icon type={IconType.RIGHT_OUTLINED} />
          </div>
        </div>
      </div>
    </>
  );
}

export default MenuNav;
