import ShippingMethod from '@/lib/order-builder/enums/ShippingMethod';
import ActionType from './action-type';
import StoreFunction from './store-function';
import { IObject } from '@/components/bm-common';
import SignInMethod from './sign-in-method';
import SignUpMethod from './sign-up-method';
import TabsOption from './tabs-option';
import AccountOption from './account-option';
import SidebarOption from './sidebar-option';
import RegisterContent from './register-content';
import RecordsOption from './records-option';
import LoyaltyOption from './loyalty-option';
import { PayTypes } from '@/lib/payment';

export interface IOpeningHour {
  start: string;
  end: string;
}

export interface IStore {
  id: string;
  name: string;
  logoUrl: string;
  originalUrl: string;
  imgUrl: string;
  slug: string;
  isGroup: boolean;
  groupParentID: string;
  openingHours: {[key: string]: IOpeningHour[]};
  lat: number;
  lng: number;
  currencyCode: string;
  address: string;
  phone: string;
  storeScore: number;
  open: boolean;
  zipCode: string;
  countryCode: string;
  callingCode: string;
  storeCategories: string[];
  city: string;
  delivery: boolean;
  pickUp: boolean;
  dineIn: boolean;
  dineInUnassigned: boolean;
  booking: boolean;
  queueing: boolean;
  i18n: IObject;
  description: string;
}

export interface IStoreConfig extends IObject {
  id: string;
  platformID: string;
  platformName: string;
  storeID: string;
  storeSlug: string;
  storeName: string;
  showOrder: boolean;
  showFeedback: boolean;
  showNews: boolean;
  showAboutUs: boolean;
  showBooking: boolean;
  showQueueing: boolean;
  showMenu: boolean;
  autoShowProcurementMethod: boolean;
  storeFunctions: StoreFunction[];
  shippingMethods: ShippingMethod[];
  delivery: boolean;
  deliveryAdvanceOrderDate: number; // 速递提前预定天数
  deliveryInterval: number; // 速递时间间隔
  deliveryFee: number; // 速递费用
  deliveryMiles: number; // 速递公里数
  deliveryMinAmount: number; // 最少消费金额
  deliveryTips: string; // 最少消费金额提示文案
  pickUp: boolean;
  pickUpAdvanceOrderDate: number; // 外卖最多提前预约多少天
  pickUpInterval: number; // 外卖可选择的时间间隔
  enableSignInReminder: boolean; // 是否开启登录提示
  delayedDeliveryTime: number; // 延迟交货时间
  deliveryTime: string[]; // 配送时间
  dineIn: boolean;
  dineInUnassigned: boolean;
  disableServiceCharge: boolean;
  fapiao: boolean;
  foodpanda: string;
  deliveroo: string;
  deliveryDesc: string;
  safetyVerification: boolean;  //3ds开启
  safetyVerificationAmount: number; //3ds金额
  storeUrl: string;
  hideSecondaryMenu: boolean;
  purchaseRegulatedGoods: boolean; // 购买受管制的商品
  purchaseRegulatedTips: string; // 购买受管制的商品提示
  bookingFee: boolean; // 是否需要支付预定费用
  bookingTips: string;
  i18n: IObject;
  payMethodKeys: PayTypes[];
  payBill: boolean;
  hideNowPickUpTime: boolean;
}

export interface IStoreCategory {
  id: string;
  platformID: string;
  category_name: string;
  categoryImage: string;
  i18n: IObject;
  categoryMediumImage: string;
  brandsCategory: string;
}

export interface IStoreLocation {
  id: string;
  district: string;
  platformID: string;
  storeID: string;
  storeName: string;
  i18n: IObject;
  platformName: string;
  city: string;
}

export interface IPlatformConfig extends IStoreConfig {
  signInMethods: SignInMethod[];
  signUpMethods: SignUpMethod[];
  tabsOptions: TabsOption[];
  accountOptions: AccountOption[];
  showTabs: boolean;
  showStoreTab: boolean;
  showOrderTab: boolean;
  showAccountTab: boolean;
  showNewsTab: boolean;
  showAboutUsTab: boolean;
  showHomeTab: boolean;
  showRecordsTab: boolean;
  showEShopTab: boolean;
  showPromotionsTab: boolean;
  showCategoryTab: boolean;
  showMembersAreaTab: boolean;
  validServiceType: string[];
  showMyIDTab: boolean;
  showSideBar: boolean;
  sidebarOptions: SidebarOption[];
  enableNotes: boolean;
  showBrandsTab: boolean;
  closeRegisterEmail: boolean;
  showEmailReceiptBtn: boolean;
  ecouponsRedemption: 'LOYALTY_POINTS' | 'STORE_CREDIT';
  platformLogo: string;
  platformBanner: string[];
  bannerCarouselTime: number;
  abbreviation: string;
  birthdayHiddenDayEnable: boolean;
  birthdayHiddenYearEnable: boolean;
  hidePhoneNumberHyperlink: boolean;
  feedbackEnable: boolean;
  platformLogoSvg: string;
  menuDisplayMethod: string;
  filters: StoreFilterModalContentType[];
  registerContent: RegisterContent[];
  registrationRequired: RegisterContent[];
  colorInversion: boolean;
  memberIconStyle: 'PROGRESS_BAR' | 'MEMBER_ICON' | 'GRADE_TEXT';
  recordsOptions: RecordsOption[];
  loyaltyOptions: LoyaltyOption[];
  storeFunctionLabel: boolean;
  mapEnabled: boolean;
  filterEnabled: boolean;
  memberMigration: boolean;
  showStoreCreditBalance: boolean;
  hideMemberExpiryDate: boolean;
  areaCodeId: string;
  areaCodeISO: string;
}

export interface IPlatformStore {
  id: string;
  platformID: string;
  storeID: string;
  status: string;
  environment: string;
}

export interface IPlatformStoreMembership {
  id: string;
  imgUrl: string;
  isDefault: boolean;
  membershipLevelColor: string;
  i18n: IObject;
  title: string;
  membershipLevelLightColor: string;
  sort: number;
}

export interface IAppIcons {
  id: string;
  svg: string;
  iconType: string;
  iconName: string;
  i18n: IObject;
}

export interface IQueryStore {
  type: ActionType.QUERY_STORE;
  storeSlug: string;
  currentPlatformID?: string;
  notRequestStoreConfig?: boolean;
  callback?: (store?: IStore, storeConfig?: IStoreConfig) => void;
}

export interface IQueryStoreByID {
  type: ActionType.QUERY_STORE_BY_ID;
  storeID: string;
  notRequestStoreConfig?: boolean;
  callback?: (store?: IStore, storeConfig?: IStoreConfig) => void;
}

export interface ISetStore {
  type: ActionType.SET_STORE;
  storeSlug: string;
  store: IStore;
}

export interface ISetAllStoresInfo {
  type: ActionType.SET_ALL_STORES_INFO;
  allStores: IStore[];
  allStoresImages: {[key: string]: IStoreImage[]};
  allStorePickupLocations: {[key: string]: IStorePickupLocation[]};
  storesConfigs: IStoreConfig[];
}

export interface IClearStore {
  type: ActionType.CLEAR_STORE;
}

export interface ISetStoreConfig {
  type: ActionType.SET_STORE_CONFIG;
  storeSlug: string;
  storeConfig: IStoreConfig;
}

export interface ISetStoresConfigs {
  type: ActionType.SET_STORES_CONFIGS;
  storesConfigs: IStoreConfig[];
}

export interface IQueryStores {
  type: ActionType.QUERY_STORES;
  page: number;
  serviceType?: string;
  callback?: (stores: IStore[], page: number, lastPage: boolean) => void;
}

export interface IQueryStoresByCategory {
  type: ActionType.QUERY_STORES_BY_CATEGORY;
  page: number;
  categoryID: string;
  pageType?: string;
  storeType?: string;
  callback?: (stores: IStore[], page: number, lastPage: boolean) => void;
}

export interface IQueryBrandsByCategory {
  type: ActionType.QUERY_BRANDS_BY_CATEGORY;
  categoryID: string;
  callback?: (brands: IStoreCategory[]) => void;
}

export interface IQueryStoresConfigs {
  type: ActionType.QUERY_STORES_CONFIGS;
  stores: IStore[];
}

export interface IPushStores {
  type: ActionType.PUSH_STORES;
  serviceType: string;
  stores: IStore[];
  mode?: string;
}

export interface IPushStoresByCategory {
  type: ActionType.PUSH_STORES_BY_CATEGORY;
  categoryID: string;
  storeType?: string;
  stores: IStore[];
  mode?: string;
}

export interface IPushBrandsByCategory {
  type: ActionType.PUSH_BRANDS_BY_CATEGORY;
  categoryID: string;
  brands: IStoreCategory[];
}

export interface IQueryGroupStores {
  type: ActionType.QUERY_GROUP_STORES;
  storeSlug: string;
  callback?: (stores: IStore[], groupStore: IStore | undefined) => void;
}

export interface ISetGroupStores {
  type: ActionType.SET_GROUP_STORES;
  storeSlug: string;
  stores: IStore[];
}

export interface IQueryRecommendedStores {
  type: ActionType.QUERY_RECOMMENDED_STORES;
  callback?: (recommendedStores: IStore[]) => void;
}

export interface ISetRecommendedStores {
  type: ActionType.SET_RECOMMENDED_STORES;
  stores: IStore[];
}

export interface ISearchStores {
  type: ActionType.SEARCH_STORES;
  searchKey: string;
  callback: (stores: IStore[]) => void;
}

export interface ISetSearchStores {
  type: ActionType.SET_SEARCH_STORES;
  key: string;
  stores: IStore[];
}

export interface IStoreTable {
  uuid: string;
  id: string;
  name: string;
  numberOfPeople: number;
  turnTime: number;
}

export interface IQueryStoreTables {
  type: ActionType.QUERY_STORE_TABLES;
  storeSlug: string;
  callback?: (storeTables: IStoreTable[]) => void;
}

export interface ISetStoreTables {
  type: ActionType.SET_STORE_TABLES;
  storeSlug: string;
  tables: IStoreTable[];
}

export interface IBlog {
  id: string;
  cover: string;
  title: string;
  body: string;
  type: string;
  status: string;
  storeId: string;
  storeName: string;
  storeSlug: string;
  updatedAt: string;
  i18n: IObject;
}

export interface IStoreImage {
  id: string;
  primary: boolean;
  mediumUrl: string;
  originalUrl: string;
  smallUrl: string;
  thumbUrl: string;
  bigUrl: string;
  storeSlug?: string;
}

export interface IStorePickupLocation {
  id: string;
  name: string;
  i18n?: IObject;
}

export interface IQueryStorePickupLocations {
  type: ActionType.QUERY_STORE_PICKUP_LOCATIONS;
  storeSlug: string;
  callback?: (storePickupLocations: IStorePickupLocation[]) => void;
}

export interface ISetStorePickupLocations {
  type: ActionType.SET_STORE_PICKUP_LOCATIONS;
  storeSlug: string;
  storePickupLocations: IStorePickupLocation[];
}

export interface IQueryStoreSlugByRandomCode {
  type: ActionType.QUERY_STORE_SLUG_BY_RANDOM_CODE;
  number: string;
  callback?: (isActive: boolean, storeSlug: string, recordID: string) => void;
}

export interface IStoreMsg {
  storeSlug: string;
  status: string;
  recordID: string;
}
export interface ISetStoreSlugByRandomCode {
  type: ActionType.SET_STORE_SLUG_BY_RANDOM_CODE;
  number: string;
  storeMsg: IStoreMsg;
}
export interface ISetStoreBlogs {
  type: ActionType.SET_STORE_BLOGS;
  storeSlug: string;
  blogs: IBlog[];
}

export interface ISetStoreCompanyInfo {
  type: ActionType.SET_STORE_COMPANY_INFO;
  storeSlug: string;
  companyInfo: IBlog[];
}

export interface ISetStorePromotions {
  type: ActionType.SET_STORE_PROMOTIONS;
  storeSlug: string;
  companyInfo: IBlog[];
}

export interface ISetStoreImages {
  type: ActionType.SET_STORE_IMAGES;
  storeSlug: string;
  storeImages: IStoreImage[];
}

export interface IQueryStoreImages {
  type: ActionType.QUERY_STORE_IMAGES;
  storeSlug: string;
  callback?: (storeImages: IStoreImage[]) => void;
}

export interface IQueryBlogList {
  type: ActionType.QUERY_BLOG_LIST;
  contentType: string;
  storeSlug: string;
  callback?: (blogs: IBlog[]) => void;
}

export interface IQueryBlog {
  type: ActionType.QUERY_BLOG;
  id: string;
  contentType: string;
  storeSlug: string;
  callback?: (blog?: IBlog) => void;
}

export interface IStoreInput {
  title: string;
  businessMode: number;
  countryCode: string;
  callingCode: string;
  phone: string;
  zipcode: string;
  address1: string;
  city: string;
  lat?: number | string;
  lng?: number | string;
}

export interface ICreateStore {
  type: ActionType.CREATE_STORE;
  title: string;
  businessMode: number;
  countryCode: string;
  callingCode: string;
  phone: string;
  zipcode: string;
  address1: string;
  city: string;
  userID: string;
  storeID: string;
  password: string;
  fullName: string;
  email: string;
  lat: number | string;
  lng: number | string;
  files: any[];
  callback?: (userID: string, storeID: string, record: any) => void;
}

export interface IQueryStoresByUser {
  type: ActionType.QUERY_STORES_BY_USER;
  userID: string;
  page: number;
  perPage: number;
  storeName?: string;
  callback?: (storeIDs: string[]) => void;
}

export interface IUpdateStore {
  type: ActionType.UPDATE_STORE;
  storeID: string;
  callingCode: string;
  phone: string;
  storeName: string;
  countryCode: string;
  address: string;
  zipcode: string;
  open: boolean;
  city: string;
  lat: number | string;
  lng: number | string;
  delivery: boolean;
  pickUp: boolean;
  dineIn: boolean;
  dineInUnassigned: boolean;
  booking: boolean;
  queueing: boolean;
  callback?: (id: string) => void;
}

export interface IQueryPlatformStoreAssociations {
  type: ActionType.QUERY_PLATFORM_STORE_ASSOCIATIONS;
  platformID: string;
  storeID: string;
  callback?: (record: IPlatformStore | undefined) => void;
}

export interface IADDPlatformStoreAssociations {
  type: ActionType.ADD_PLATFORM_STORE_ASSOCIATIONS;
  platformID: string;
  storeID: string;
  status: string;
  environment: string;
  callback?: (record: IPlatformStore | undefined) => void;
}

export interface IUpdatePlatformStoreAssociations {
  type: ActionType.UPDATE_PLATFORM_STORE_ASSOCIATIONS;
  id: string;
  status: string;
  environment: string;
  callback?: (record: IPlatformStore | undefined) => void;
}

export interface IUpdateStoreImages {
  type: ActionType.UPDATE_STORE_IMAGES;
  storeID: string;
  originalUrl: string;
  files: any[];
  notRequestStoreConfig?: boolean;
  callback?: (store: IStore | undefined) => void;
}

export interface IUpdateStoreConfigs {
  type: ActionType.UPDATE_STORE_CONFIGS;
  storeID: string;
  platformID: string;
  storeConfig?: IStoreConfig;
  showOrder: boolean;
  showFeedback: boolean;
  showNews: boolean;
  showAboutUs: boolean;
  showSelectProcedureMethod: boolean;
  showMenu: boolean;
  showFapiao: boolean;
  foodpanda: string;
  deliveroo: string;
  pickupAdvanceOrderDate: string | undefined;
  deliveryAdvanceOrderDate: string;
  pickupInterval: string | undefined;
  deliveryInterval: string;
  deliveryMiles: string;
  deliveryFee: string;
  deliveryDesc: string;
  deliveryMinAmount: string;
  callback?: (storesConfig: IStoreConfig | undefined) => void;
}

export interface IAdvancePlaceStores {
  type: ActionType.SEARCH_STORES_LOCATION;
  lat: string | number;
  lng: string | number;
  radius?: string;
  callback: (stores: IStore[]) => void;
}

export interface ISetStoreFilter {
  type: ActionType.SET_STORES_FILTER;
  params: any[];
}

export interface IQueryStoresByFilter {
  type: ActionType.QUERY_STORES_BY_FILTER;
  page: number;
  mode?: StoreFilterMode;
  categoryID?: string;
  replace?: boolean;
  storeType?: string;
  callback?: (stores: IStore[], page: number, lastPage: boolean) => void;
}
export interface IQueryuserAssociateStores {
  type: ActionType.QUERY_USER_ASSOCIATE_STORES;
};

export interface IQueryDeferredInvoices {
  type: ActionType.QUERY_DEFERRED_INVOICES;
  storeID: string;
  dateStart?: string;
  dateEnd?: string;
  timeType: string;
  callback?: (invoices: any[]) => void;
};

export interface ISetDeferredInvoices {
  type: ActionType.SET_DEFERRED_INVOICES;
  storeID: string;
  timeType: string;
  deferredInvoices: any[];
  callback?: (invoices: any[]) => void;
};

export interface ICreateInvoices {
  type: ActionType.CREATE_DEFERRED_INVOICES;
  storeID: string;
  storeSlug: string;
  deferredIDs: string[];
  orderNumber?: string;
  callback?: (message: string, invoices: any) => void;
};

export enum StoreFilterModalContentType {
  TYPE = 'TYPE',
  BRANDS = 'BRANDS',
  CATEGORY = 'CATEGORY',
  DISTRICT = 'DISTRICT',
  PRICE = 'PRICE',
  DISTANCE = 'DISTANCE',
  SORT = 'SORT'
}

export enum StoreFilterMode {
  CATEGORY = 'CATEGORY',
}

export interface IQueryStoresTotalByFilter {
  type: ActionType.QUERY_STORES_TOTAL_BY_FILTER;
  page: number;
  callback?: (total: number) => void;
}

type StoreAction = IQueryStore | IQueryStoreByID | ISetStore | IQueryStores | IPushStores | IQueryStorePickupLocations | ISetStorePickupLocations
                    | IQueryGroupStores | ISetGroupStores | IQueryRecommendedStores
                    | ISetRecommendedStores | ISearchStores
                    | ISetSearchStores | IQueryStoreTables | ISetStoreTables | ISetStoreBlogs
                    | IQueryBlogList | ISetStoreCompanyInfo | ISetStoreConfig | IQueryBlog
                    | ISetStoreImages | IQueryStoreImages | ICreateStore | IQueryStoresByUser
                    | IUpdateStore | IQueryPlatformStoreAssociations | IADDPlatformStoreAssociations
                    | IUpdatePlatformStoreAssociations | IQueryStoresConfigs | ISetStoresConfigs | ISetAllStoresInfo
                    | IClearStore | IUpdateStoreImages | IQueryStoresByCategory |IPushStoresByCategory
                    | IAdvancePlaceStores | IUpdateStoreConfigs | ISetStorePromotions | IQueryBrandsByCategory
                    | IPushBrandsByCategory | IQueryuserAssociateStores | IQueryDeferredInvoices | ISetDeferredInvoices | ICreateInvoices | ISetStoreFilter | IQueryStoresByFilter
                    | IQueryStoresTotalByFilter | IQueryStoreSlugByRandomCode | ISetStoreSlugByRandomCode;

export default StoreAction;
