import produce from 'immer';
import { handleActions } from 'redux-actions';
import ActionType from '@/actions/action-type';
import HomeType from '@/actions/home-type';
import { IObject } from '@/components/bm-common';
import {
  ISplash,
  IAppSettings,
  IAppTheme,
  ISetAppConfig,
  ISetNewAppConfig,
  ISetHomeType,
  ISetDomain,
  ISetShowSplash,
  ILoading,
  IPageLoading,
  ISetScannableQRCode,
  IClearAppConfig,
  ISetCoordinates,
  ISetSelectedServiceType,
  IPlatformCustomPage,
  ISetLoadingPlatformConfig,
  ISetAppConfigAreaCodeData,
  ISetLanguage,
} from '@/actions/app-action';
import { setBodyScrollable, getLocalStorageJson, getDomain } from '@/utils';
import { IAppIcons, IPlatformConfig, IPlatformStoreMembership, IStoreCategory, IStoreLocation, ISetDeferredInvoices } from '@/actions/store-action';
import { createDefaultPlatformConfig } from '@/utils/store-util';
import { IDeviceInfo, getDeviceInfo, getDefaultLanguage } from '@/utils/app';
import Constants from '@/constants';
import { IDialCodeGroupItem } from '@/components/DialCodeGroup';
import { setIntl } from '@/components/App/App';

export interface IAppState {
  language: string;
  showLoading: boolean; // 普通的数据请求
  showPageLoading: boolean; // 加载页面数据
  showLoadingBgc: boolean; //普通的数据请求（是否更换背景颜色）
  loadingPlatformConfig: boolean;
  homeType: HomeType;
  platformDomain: string;
  gatewayDomain: string;
  gatewayAppSettings?: IAppSettings;
  platformAppSettings?: IAppSettings;
  platformConfig: IPlatformConfig;
  platformStoreCategories: IStoreCategory[];
  platformCustomPages: IPlatformCustomPage[];
  platformStoreBrands: IStoreCategory[];
  appTheme?: IAppTheme;
  lessTheme: IObject;
  showSplash: boolean;
  splashData: ISplash[];
  coordinates?: Coordinates;
  deviceInfo: IDeviceInfo;
  scannableQRCode: boolean;
  selectedStoreServiceType: string;
  platformStoreMembership: IPlatformStoreMembership[];
  appIcons: IAppIcons[];
  platformStoreLocations: IStoreLocation[];
  areaCodeDatas?: IDialCodeGroupItem;
  deferredInvoices: {[storeID: string]: any[]};
}

const deviceInfo = getDeviceInfo();
const root = document.getElementById('root');
if (root) {
  root.className = (deviceInfo.inPC || deviceInfo.inPad) ? 'web' : 'phone';
}
const { platformDomain } = getDomain();
const platformStorageKey = `${Constants.APP_CONFIG}-${platformDomain}`;
const appConfig = getLocalStorageJson(platformStorageKey);
let lessTheme: IObject = {};
let appTheme: IAppTheme | undefined = undefined;
let firstScreenMenuLayout = undefined;
if (appConfig) {
  lessTheme = appConfig.lessTheme || {};
  appTheme = appConfig.appTheme;
  firstScreenMenuLayout = appConfig.platformAppSettings.firstScreenMenuLayout || undefined
}

const initState: IAppState = {
  language: getDefaultLanguage(),
  showLoading: false,
  showPageLoading: true,
  showLoadingBgc: false,
  loadingPlatformConfig: true,
  homeType: firstScreenMenuLayout === HomeType.MENU_GRID? firstScreenMenuLayout: HomeType.STORE_LIST,
  platformDomain: '',
  gatewayDomain: '',
  gatewayAppSettings: undefined,
  platformAppSettings: undefined,
  platformConfig: createDefaultPlatformConfig(),
  appTheme,
  lessTheme,
  showSplash: true,
  splashData: [],
  coordinates: undefined,
  deviceInfo,
  scannableQRCode: false,
  platformStoreCategories: [],
  platformCustomPages: [],
  platformStoreBrands: [],
  selectedStoreServiceType: '',
  platformStoreMembership: [],
  appIcons: [],
  platformStoreLocations: [],
  areaCodeDatas: undefined,
  deferredInvoices: {},
};

setBodyScrollable(!initState.showLoading)

const actions: any = {};

actions[ActionType.SET_LANGUAGE] = (state: IAppState, action: ISetLanguage) => produce(state, draft => {
  const { language } = action;
  setIntl(language);
  draft.language = language;
  localStorage.setItem(Constants.LANGUAGE, language);
});

actions[ActionType.LOADING] = (state: IAppState, action: ILoading) => produce(state, draft => {
  if (state.platformAppSettings && state.platformAppSettings.id) {
    setBodyScrollable(!action.showLoading)
  }
  draft.showLoading = action.showLoading;
  draft.showLoadingBgc = action.showLoadingBgc || false;
});

actions[ActionType.PAGE_LOADING] = (state: IAppState, action: IPageLoading) => produce(state, draft => {
  if (state.platformAppSettings && state.platformAppSettings.id) {
    setBodyScrollable(!action.showPageLoading)
  }
  draft.showPageLoading = action.showPageLoading;
});

actions[ActionType.SET_APP_CONFIG] = (state: IAppState, action: ISetAppConfig) => produce(state, draft => {
  draft.gatewayAppSettings = action.gatewayAppSettings;
  draft.platformAppSettings = action.platformAppSettings;
  draft.platformConfig = action.platformConfig;
  draft.appTheme = action.appTheme;
  draft.lessTheme = action.lessTheme;
  draft.coordinates = action.coordinates;
  draft.splashData = action.splashData;
  draft.showSplash = action.showSplash;
  draft.deviceInfo.weChatMiniProgram = action.weChatMiniProgram;
  draft.platformStoreCategories = action.platformStoreCategories;
  draft.platformCustomPages = action.platformCustomPages;
  draft.platformStoreBrands = action.platformStoreBrands;
  draft.selectedStoreServiceType = action.selectedStoreServiceType;
  draft.platformStoreMembership = action.platformStoreMembership;
  draft.appIcons = action.appIcons;
  draft.platformStoreLocations = action.platformStoreLocations;
  draft.deviceInfo = action.deviceInfo;
  draft.areaCodeDatas = action.areaCodeDatas;
});

actions[ActionType.SET_NEW_APP_CONFIG] = (state: IAppState, action: ISetNewAppConfig) => produce(state, draft => {
  draft.gatewayAppSettings = action.gatewayAppSettings;
  draft.platformAppSettings = action.platformAppSettings;
  // draft.platformConfig = action.platformConfig;
  draft.appTheme = action.appTheme;
  draft.lessTheme = action.lessTheme;
});

actions[ActionType.SET_LOADING_PLATFORM_CONFIG] = (state: IAppState, action: ISetLoadingPlatformConfig) => produce(state, draft => {
  draft.loadingPlatformConfig = action.loadingPlatformConfig;
});

actions[ActionType.SET_HOME_TYPE] = (state: IAppState, action: ISetHomeType) => produce(state, draft => {
  draft.homeType = action.homeType;
});

actions[ActionType.SET_DOMAIN] = (state: IAppState, action: ISetDomain) => produce(state, draft => {
  draft.platformDomain = action.platformDomain;
  draft.gatewayDomain = action.gatewayDomain;
});

actions[ActionType.SET_SCANNABLE_QRCODE] = (state: IAppState, action: ISetScannableQRCode) => produce(state, draft => {
  draft.scannableQRCode = action.scannableQRCode;
});

actions[ActionType.SET_SHOW_SPLASH] = (state: IAppState, action: ISetShowSplash) => produce(state, draft => {
  draft.showSplash = action.showSplash;
});

actions[ActionType.CLEAR_APP_CONFIG] = (state: IAppState, action: IClearAppConfig) => produce(state, draft => {
  const platformStorageKey = `${Constants.APP_CONFIG}-${draft.platformDomain}`;
  localStorage.removeItem(platformStorageKey);
  localStorage.removeItem(`${Constants.APP_CONFIG_HASH_DATA}-${draft.platformDomain}`)
});

actions[ActionType.SET_COORDINATES] = (state: IAppState, action: ISetCoordinates) => produce(state, draft => {
  draft.coordinates = action.coordinates;
});

actions[ActionType.SET_SELECTED_SERVICE_TYPE] = (state: IAppState, action: ISetSelectedServiceType) => produce(state, draft => {
  draft.selectedStoreServiceType = action.selectedServiceType;
});

actions[ActionType.SET_APP_CONFIG_DEVICEINFO] = (state: IAppState, action: ISetAppConfig) => produce(state, draft => {
  draft.deviceInfo = action.deviceInfo;
});

actions[ActionType.AREACO_DEATA] = (state: IAppState, action: ISetAppConfigAreaCodeData) => produce(state, draft => {
  draft.areaCodeDatas = action.areaCodeDatas;
});

actions[ActionType.SET_DEFERRED_INVOICES] = (state: IAppState, action: ISetDeferredInvoices) => produce(state, draft => {
  draft.deferredInvoices[action.timeType] = action.deferredInvoices;
});

export default handleActions(actions, initState);
