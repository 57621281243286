import React, { useState, useEffect } from 'react';
import Config from '@/Config';
import Icon, { IconType } from '@/components/Icon';
import { IDispatch } from '@/actions';
import { checkImgExists } from '@/utils';
import { IStore } from '@/actions/store-action';
import ActionType from '@/actions/action-type';
import { getIntl } from '@/components/App/App';

interface IStoreItemProps {
  storeID: string;
  dispatch: IDispatch;
  onClick: (storeSlug: string) => void;
}

const StoreItem: React.FC<IStoreItemProps> = props => {
  const [store, setStore] = useState<IStore>();
  const [storeLogo, setStoreLogo] = useState('');
  const {
    onClick,
    storeID,
    dispatch,
  } = props;

  useEffect(() => {
    dispatch({
      type: ActionType.QUERY_STORE_BY_ID,
      storeID,
      callback: store => {
        setStore(store);
        if (store) {
          checkImgExists(store.imgUrl, (success, url) => {
            if (success) {
              setStoreLogo(url);
            } else {
              setStoreLogo(Config.defaultStoreImg);
            }
          })
        }
      }
    })
  }, [])

  return (
    <div>
      {
        store &&
        <div className="store-item" onClick={() => onClick(store.slug)}>
          <div className="store-logo" style={{backgroundImage: `url(${storeLogo})`}}>
            {
              !store.open &&
              <div>
                <div className="inactive">{getIntl().page.inactive}</div>
                <div className="inactive-background"></div>
              </div>
            }
          </div>
          <div className="store-name">{store.name}</div>
          <div className="store-phone">
            <Icon className="store-item-icon" type={IconType.STAFF_ID_OUTLINED} />
            <div>{store.phone}</div>
          </div>
          <div className="store-address">
            <Icon className="store-item-icon" type={IconType.MAP_OUTLINED} />
            <div>{store.address}</div>
          </div>
        </div>
      }
    </div>
  );
}

export default StoreItem;
