import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import Icon, { IconType } from '@/components/Icon';
import Layout from '@/components/Layout';
import { IDialCodeGroupItem } from '@/components/DialCodeGroup';
import { getAppTheme, setScreenColor, getIntl } from '@/components/App/App';
import { goToRoute } from '@/utils/route-util';
import { createRouteUrl, parseRouteParams } from '@/utils';
import ActionType from '@/actions/action-type';
import paths from '@/routes/paths';
import { IStore } from '@/actions/store-action';
import EditPage, { EditInput, EditSelect } from './EditPage';
import { prefix } from '.';

export interface IStoreEditPageProps extends IBasePageProps {}
export interface IStoreEditPageState {
  phoneNumber: string;
  areaCodeData: IDialCodeGroupItem | undefined;
  pageNext: boolean;
  storeName: string;
  country: string;
  storeAddress: string;
  zipCode: string;
  storeLogo: string[];
  emailError: boolean;
  phoneError: boolean;
  next: boolean;
  areaCode: string;
  edit: boolean;
  store: IStore | undefined;
  city: string;
  lat: number | string;
  lng: number | string;
  files: any[];
  settled: boolean;
  delivery: boolean;
  pickUp: boolean;
  dineIn: boolean;
  dineInUnassigned: boolean;
  booking: boolean;
  queueing: boolean;
}

class StoreEditPage extends BasePage<IStoreEditPageProps, IStoreEditPageState> {
  state: IStoreEditPageState = {
    phoneNumber: '',
    areaCodeData: undefined,
    pageNext: false,
    storeName: '',
    country: '',
    storeAddress: '',
    zipCode: '',
    storeLogo: [],
    emailError: true,
    phoneError: true,
    next: false,
    areaCode: '852',
    edit: false,
    store: undefined,
    city: '',
    lat: '',
    lng: '',
    files: [],
    settled: false,
    delivery: false,
    pickUp: false,
    dineIn: false,
    dineInUnassigned: false,
    booking: false,
    queueing: false,
  }

  componentDidMount () {
    const appTheme = getAppTheme();
    if (appTheme.lightBackgroundColor) {
      setScreenColor(appTheme.lightBackgroundColor);
    }
    super.componentDidMount();
    const routeParams = parseRouteParams(this.props);
    const { dispatch, reducersState, history } = this.props;
    const { userInfo } = reducersState.user;
    const { platformConfig } = reducersState.app;
    if (userInfo && userInfo.staff) {
      dispatch({
        type: ActionType.LOADING,
        showLoading: true
      })
      dispatch({
        type: ActionType.QUERY_STORE,
        storeSlug: routeParams.storeSlug,
        callback: store => {
          if (store) {
            dispatch({
              type: ActionType.QUERY_PLATFORM_STORE_ASSOCIATIONS,
              storeID: store.id,
              platformID: platformConfig.platformID,
              callback: record => {
                this.setState({
                  storeName: store.name,
                  storeAddress: store.address,
                  zipCode: store.zipCode,
                  areaCodeData: {areaCodes: null, dialCode: store.callingCode, iso2: '', name: '', priority: 0},
                  phoneNumber: this.numberArrange(store.phone, ' ', 4),
                  areaCode: store.callingCode,
                  country: store.countryCode.toLowerCase(),
                  store: store,
                  files: store.originalUrl ? [{url: store.originalUrl}] : [],
                  settled: record ? true : false,
                  delivery: store.delivery,
                  pickUp: store.pickUp,
                  dineIn: store.dineIn,
                  dineInUnassigned: store.dineInUnassigned,
                  booking: store.booking,
                  queueing: store.queueing,
                })
              }
            })
          }
          dispatch({
            type: ActionType.LOADING,
            showLoading: false,
          })
        }
      })
    } else {
      goToRoute({
        history,
        pathname: createRouteUrl(
          paths.ACCOUNT,
        ),
      });
    }
  }

  numberArrange = (number: string, symbol = '-', interval = 4) => {
    let newnumber = '';
    let count = 0;
    if (number) {
      if (number.length % interval === 0) {
        count = number.length / interval;
      } else {
        count = number.length / interval + 1;
      }
      for (let index = 0; index < count; index++) {
        if ((index + 1) * interval < number.length) {
          newnumber = `${newnumber + number.slice(index * interval, (index + 1) * interval)  }${symbol}`;
        } else {
          newnumber += number.slice(index * interval, number.length);
        }
      }
    }
    
    return newnumber;
  };

  onLeftClick = () => {
    this.handleGoBack({
      path: paths.ADMIN_STORES,
      runGoBack: true,
    })
  }

  handleEditInput = (data: EditInput) => {
    const {
      storeName,
      country,
      storeAddress,
      zipCode,
      areaCodeData,
      phoneError,
      phoneNumber,
      areaCode,
      city,
      lat,
      lng,
      files,
    } = this.state;

    const {
      inputStoreAddress,
      inputStoreName,
      inputZipCode,
      inputPhone,
      inputAreaCodeData,
      inputCountryAreaCodeData,
      inputCity,
      inputLat,
      inputLng,
      inputImage,
    } = data;

    const newStoreName = inputStoreName || inputStoreName === '' ? inputStoreName : storeName;
    const newCountry = inputCountryAreaCodeData ? inputCountryAreaCodeData.iso2 : country;
    const newStoreAddress = inputStoreAddress || inputStoreAddress === '' ? inputStoreAddress : storeAddress;
    const newZipCode = inputZipCode || inputZipCode === '' ? inputZipCode : zipCode;
    const newAreaCodeData = inputAreaCodeData ? inputAreaCodeData : areaCodeData;
    const newAreaCode = inputAreaCodeData ? inputAreaCodeData.dialCode : areaCode;
    const newCity = inputCity ? inputCity : city;
    const newLat = inputLat ? inputLat : lat;
    const newLng = inputLng ? inputLng : lng;
    const newFiles = inputImage ? inputImage : files;
    let newPhone = '';
    let newPhoneError = phoneError;
    if (inputPhone || inputPhone === '') {
      const value = inputPhone.replace(/\s*/g, '');
      newPhone = inputPhone;
      if (newAreaCode === '852') {
        newPhoneError = value.length !== 8;
      } else if (newAreaCode === '86') {
        newPhoneError = value.length !== 11;
      }
    } else {
      newPhone = phoneNumber;
    }

    this.setState({
      storeName: newStoreName,
      country: newCountry,
      storeAddress: newStoreAddress,
      zipCode: newZipCode,
      phoneError: newPhoneError,
      phoneNumber: newPhone,
      areaCodeData: newAreaCodeData,
      areaCode: newAreaCode,
      city: newCity,
      lat: newLat,
      lng: newLng,
      files: newFiles,
    })
  }

  handleLeftClick = () => {
    const {
      store,
      edit,
    } = this.state;
    const {
      history,
    } = this.props;

    if (store && edit) {
      this.setState({
        storeName: store.name,
        storeAddress: store.address,
        zipCode: store.zipCode,
        areaCodeData: {areaCodes: null, dialCode: store.callingCode, iso2: '', name: '', priority: 0},
        phoneNumber: this.numberArrange(store.phone, ' ', 4),
        areaCode: store.callingCode,
        country: store.countryCode.toLowerCase(),
        edit: false,
        city: store.city,
        lat: store.lat,
        lng: store.lng,
        files: [{url: store.originalUrl}],
        delivery: store.delivery,
        pickUp: store.pickUp,
        dineIn: store.dineIn,
        dineInUnassigned: store.dineInUnassigned,
        booking: store.booking,
        queueing: store.queueing,
      })
    } else {
      goToRoute({
        history,
        pathname: createRouteUrl(
          paths.ADMIN_STORES,
        ),
      });
    }
  }

  handleEdit = () => {
    this.setState({
      edit: true
    })
  }

  handleSave = (open = false) => {
    const {
      phoneNumber,
      country,
      zipCode,
      storeAddress,
      storeName,
      areaCode,
      store,
      city,
      lat,
      lng,
      files,
      dineIn,
      dineInUnassigned,
      pickUp,
      delivery,
      booking,
      queueing,
      settled,
    } = this.state;

    const { dispatch } = this.props;
    if (store && phoneNumber && country && zipCode && storeAddress && storeName && areaCode) {
      dispatch({
        type: ActionType.LOADING,
        showLoading: true,
      })
      dispatch({
        type: ActionType.UPDATE_STORE,
        storeID: store.id,
        callingCode: areaCode,
        phone: phoneNumber.replace(/\s*/g, ''),
        storeName,
        countryCode: country.toUpperCase(),
        address: storeAddress,
        zipcode: zipCode,
        open,
        city,
        lat,
        lng,
        dineIn,
        dineInUnassigned,
        pickUp,
        delivery,
        booking,
        queueing,
        callback: id => {
          if (id) {
            dispatch({
              type: ActionType.UPDATE_STORE_IMAGES,
              storeID: store.id,
              originalUrl: store.originalUrl,
              files,
              notRequestStoreConfig: !settled,
              callback: newStore => {
                if (newStore) {
                  this.setState({
                    storeName: newStore.name,
                    storeAddress: newStore.address,
                    zipCode: newStore.zipCode,
                    areaCodeData: {areaCodes: null, dialCode: newStore.callingCode, iso2: '', name: '', priority: 0},
                    phoneNumber: this.numberArrange(newStore.phone, ' ', 4),
                    areaCode: newStore.callingCode,
                    country: newStore.countryCode.toLowerCase(),
                    edit: false,
                    store: newStore,
                    city: newStore.city,
                    lat: newStore.lat,
                    lng: newStore.lng,
                    dineIn: newStore.dineIn,
                    dineInUnassigned: newStore.dineInUnassigned,
                    pickUp: newStore.pickUp,
                    delivery: newStore.delivery,
                    booking: newStore.booking,
                    queueing: newStore.queueing,
                  })
                }
                dispatch({
                  type: ActionType.LOADING,
                  showLoading: false,
                })
              }
            })
          } else {
            dispatch({
              type: ActionType.LOADING,
              showLoading: false,
            })
          }
        }
      })
    }
  }

  handleStoreAssociad = () => {
    const { history } = this.props;
    const routeParams = parseRouteParams(this.props);
    goToRoute({
      history,
      pathname: createRouteUrl(
        paths.STORE_ASSOCIATION,
        {
          storeSlug: routeParams.storeSlug,
        }
      ),
    });
  }

  handleStoreConfigures = () => {
    const { history } = this.props;
    const routeParams = parseRouteParams(this.props);
    goToRoute({
      history,
      pathname: createRouteUrl(
        paths.STORE_SETTING,
        {
          storeSlug: routeParams.storeSlug,
        }
      ),
    });
  }

  handleEditSelect = (data: EditSelect) => {
    const {
      dineIn,
      dineInUnassigned,
      pickUp,
      delivery,
      booking,
      queueing,
    } = data;
    this.setState({
      dineIn,
      dineInUnassigned,
      pickUp,
      delivery,
      booking,
      queueing,
    })
  }

  render() {
    const {
      reducersState,
    } = this.props;

    const {
      phoneNumber,
      storeName,
      country,
      storeAddress,
      zipCode,
      areaCodeData,
      areaCode,
      edit,
      store,
      files,
      settled,
      delivery,
      pickUp,
      dineIn,
      dineInUnassigned,
      booking,
      queueing,
    } = this.state;

    const { language } = reducersState.app;
    return(
      <Layout
        {...this.props}
        className={prefix}
        navIcon={<Icon className="back-icon" type={IconType.LEFT_OUTLINED} />}
        navContent={getIntl().page.storeInfo}
        onLeftClick={this.handleLeftClick}
        navRightContent={
          <div>
            {
              !edit &&
              <div onClick={() => this.handleEdit()}>
                {getIntl().common.edit}
              </div>
            }
            {
              edit &&
              <div onClick={() => this.handleSave()}>
                {getIntl().common.save}
              </div>
            }
          </div>
        }
        isFooter={false}
      >
        {
          store &&
          <EditPage
            prefix={prefix}
            storeName={storeName}
            areaCodeData={areaCodeData}
            areaCode={areaCode}
            phoneNumber={phoneNumber}
            storeAddress={storeAddress}
            country={country}
            zipCode={zipCode}
            language={language}
            edit={edit}
            files={files}
            delivery={delivery}
            pickUp={pickUp}
            dineIn={dineIn}
            dineInUnassigned={dineInUnassigned}
            booking={booking}
            queueing={queueing}
            handleEditInput={this.handleEditInput}
            handleEditSelect={this.handleEditSelect}
          />
        }
        {
          !edit && store && !store.open &&
          <div className="bottom-botton">
            <div className="activated-botton" onClick={() => this.handleSave(true)}>
              {getIntl().common.activated}
            </div>
          </div>
        }
        {
          !edit && store && store.open &&
          <div className="bottom-botton">
            <div className="activated-botton" onClick={() => this.handleStoreAssociad()}>
              {getIntl().page.storeAssociad}
            </div>
            {
              settled &&
              <div className="activated-botton settled" onClick={() => this.handleStoreConfigures()}>
                {getIntl().page.storeConfigures}
              </div>
            }
          </div>
        }
      </Layout>
    )
  }
}

export default StoreEditPage;
