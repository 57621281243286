let timeout: any = null;
export const debounce = (cb: any, wait = 0) => {
  if (timeout !== null) clearTimeout(timeout);
  timeout = setTimeout(() => {
    if (cb) {
      cb();
    }
    timeout = null;
  }, wait);
};

