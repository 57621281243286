import common from '../common';
import invoice from '../invoice';
import page from '../page';
import pay from '../pay';

export default {
  common: common,
  invoice: invoice,
  page: page,
  pay: pay,
}
