import React, { useEffect, useState } from 'react';
import {
  getDialCodeGroup,
  IDialCodeGroupItem,
  getDialCodeItemFromCode,
} from '@/components/DialCodeGroup';
import Icon, { IconType } from '@/components/Icon';
import CountryCodeModal from './CountryCodeModal';
import CountryFlag from '@/components/common/CountryFlags';
import { prefix } from '.';
import { IReducersState } from '@/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { IDispatch } from '@/actions';
import ActionType from '@/actions/action-type';

interface ICountryCodeProps {
  language?: string;
  className?: string;
  onChange?: (areaData: IDialCodeGroupItem) => void;
  areaCode?: string;
  disabled?: boolean;
}

const CountryCode: React.FC<ICountryCodeProps> = props => {
  const { language, onChange = () => {}, areaCode, disabled } = props;

  const { dialCodeGroup, defaultDialCodeItem } = getDialCodeGroup(language);

  const [areaCodeData, setAreaCodeData] = useState<IDialCodeGroupItem>();
  const [showAreaCode, setShowAreaCode] = useState<boolean>(false);
  const reducersState: IReducersState = useSelector<IReducersState, IReducersState>(state => state);
  const dispatch = useDispatch<IDispatch>();
  const { areaCodeDatas, platformConfig } = reducersState.app;
  const { areaCodeId } = platformConfig;


  useEffect(() => {
    if (areaCode) {
      const data = getDialCodeItemFromCode(areaCode, language);
      setAreaCodeData(data);
      onChange(areaCodeId && areaCodeDatas ? areaCodeDatas : data);
    } else {
      setAreaCodeData(defaultDialCodeItem);
      onChange(areaCodeId && areaCodeDatas ? areaCodeDatas: defaultDialCodeItem);
    }
  }, []);

  const handleItemClick = (data: IDialCodeGroupItem) => {
    handleAreaCodeData(data);
    onChange(data);
    setAreaCodeData(data);
    setShowAreaCode(false);
  };
  const handleAreaCodeData = (data: IDialCodeGroupItem) => {
    dispatch({ type: ActionType.AREACO_DEATA, areaCodeDatas: data });
  };

  return (
    <div>
      <div className={`${prefix}`} onClick={() => setShowAreaCode(!disabled)}>
        {<CountryFlag type={`${areaCodeId ? areaCodeDatas && areaCodeDatas.iso2 : areaCodeData ? areaCodeData.iso2 : 'hk'}`} />}
        <div className={`${prefix}-areacode-dialcode`}>
          {`+ ${areaCodeId ? areaCodeDatas && areaCodeDatas.dialCode : areaCodeData ? areaCodeData.dialCode : '852'}`}
        </div>
        <Icon type={IconType.DOWN_OUTLINED} className={`${prefix}-down-icon`} />
      </div>
      {
        <CountryCodeModal
          data={dialCodeGroup}
          visible={showAreaCode}
          onItemClick={handleItemClick}
          onCancel={() => setShowAreaCode(false)}
        />
      }
    </div>
  );
};
export default CountryCode;
