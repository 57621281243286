import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import withRoute from '@/pages/withRoute';
import QrcodeShoppingResultPage from './QrcodeShoppingResultPage';
import './QrcodeShoppingResultPage.less';

export const prefix = 'bm-p-qrcode-shopping-result';

const RouteComponent: React.FC<RouteComponentProps> = props => withRoute({
  pageComponent: QrcodeShoppingResultPage,
  routeProps: props,
  pageTitleI18nKey: '',
});

export default RouteComponent;
