import React from 'react';
import Icon, { IconType } from '@/components/Icon';
import { IObject } from '../bm-common';
import { getIntlByPath } from '../App/App';

const payIconKeys: IObject = {
  PAY_1: IconType.CASH_ON_DELIVERY,
  PAY_3: IconType.OE_PAY,
  PAY_4: IconType.CREDIT_CARD,
  PAY_4_VISA: IconType.VISA_CARD,
  PAY_4_MASTER_CARD: IconType.MASTER_CARD,
  PAY_4_MASTER: IconType.MASTER_CARD,
  PAY_4_AMEX: IconType.AE_CARD,
  PAY_4_UNION_PAY: IconType.UNION_PAY,
  PAY_8: IconType.CREDIT_CARD,
  PAY_16: IconType.GIFT_CARD,
  PAY_16_ANONYMOUS_GIFT_CARD: IconType.GIFT_CARD,
  PAY_16_STORE_CREDIT: IconType.GIFT_CARD,
  PAY_17: IconType.WECHAT_PAY,
  PAY_18: IconType.APPLE_PAY,
  PAY_19: IconType.ALIPAY,
  PAY_19_HK: IconType.ALIPAY_HK,
  PAY_21: IconType.TAPGO_PAY,
  PAY_23: IconType.GOOGLE_PAY,
};

const payTitleKeys: IObject = {
  PAY_1: 'pay.cashOnDelivery',
  PAY_3: 'pay.oePay',
  PAY_4: 'pay.creditCard',
  PAY_4_VISA: 'pay.cardEnding',
  PAY_4_MASTER_CARD: 'pay.cardEnding',
  PAY_4_AMEX: 'pay.cardEnding',
  PAY_4_UNION_PAY: 'pay.cardEnding',
  PAY_8: 'pay.creditCard',
  PAY_16: 'pay.giftCard',
  PAY_16_ANONYMOUS_GIFT_CARD: 'pay.giftCard',
  PAY_16_STORE_CREDIT: 'pay.giftCard',
  PAY_17: 'pay.weChatPay',
  PAY_18: 'pay.applePay',
  PAY_19: 'pay.alipay',
  PAY_19_HK: 'pay.alipayHK',
  PAY_21: 'pay.tapGoPay',
  PAY_22: 'pay.deferredBilling',
  PAY_23: 'pay.googlePay',
};

export const getPayIcon = (payMethod: number, tag: string, iconClassName?: string, id?: string) => {
  let payIcon: React.ReactNode = <span />;
  let payTitle: React.ReactNode = '';

  let key = `PAY_${payMethod}`;
  tag = (tag || '').trim().replace(/\s+/ig, '_').toLocaleUpperCase();

  if (tag && (payMethod === 4 || payMethod === 16)) {
    key += `_${tag}`;
  }
  if (id === 'ALIPAY_HK') {
    key += '_HK';
  }

  if (payIconKeys[key]) {
    payIcon = <Icon className={iconClassName} type={payIconKeys[key]} />
  }

  if (payTitleKeys[key]) {
    payTitle = getIntlByPath(payTitleKeys[key]);
  }

  return {
    payIcon,
    payTitle,
  }
}
