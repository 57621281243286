import axios from 'axios';
import Config from '@/Config';
import { parseResBooking } from '@/utils/booking-util'
import { IBooking } from '@/actions/booking-action';
import { createRecord, queryRecords, updateRecord } from './mb-api';

export interface IQueueingSetting {
  max: number;
  mini: number;
  type: string;
}

export const getBookings = async (formula: string, page = 1, perPage = 10) => {
  const result = await queryRecords({
    storeSlugs: [Config.bindoGatewayStoreSlug],
    moduleBundleID: 'bm-booking',
    search: {
      formulas:[{formula: formula}],
      orderBy: 'reservation_time',
      page,
      perPage,
    },
  });

  const bookings: IBooking[] = [];
  result.records.forEach((record: any) => {
    bookings.push(parseResBooking(record));
  });

  return bookings;
}

export const getSettings = async (storeSlug: string) => {

  const res = await axios.post(Config.galaxy, {
    'operationName': null,
    'query': `
      query ($storeSlug: String!, $moduleBundleID: String!) {
        setting(storeSlug: $storeSlug, moduleBundleID: $moduleBundleID)
      }
    `,
    'variables': {
      moduleBundleID: 'reservation_quota',
      storeSlug,
    },
  });

  if (res && res.data && res.data.setting) {
    
  };
  return res.data.setting;
}

export const getRecords = async (storeSlugs: string, moduleBundleID: string, page = 1, formula?: string ) => {
  const result = await queryRecords({
    storeSlugs: [storeSlugs],
    moduleBundleID,
    search: {
      formulas:formula?[{formula: formula}]:[],
      page,
      perPage: 50,
    },
  });

  return result.records;
}

export const createBooking = async (storeSlug: string, cover: number, customerID: string, date: string, storeID: string, note: string, duration: number) => {
  const result = await createRecord({
    storeSlug,
    moduleBundleID: 'tesldtx7',
    record: {
      'people': cover,
      'status': 'booked',
      'reservation_time': date,
      'turn_time': duration,
      'customer_id': {
        records: [{id: customerID}]
      },
      'store_id': {
        records: [{id: storeID}]
      },
      'is_parent': true,
      'booking_type': '2',
      'note': note,
      'src_type': 'phone',
    },
  }, true);

  let booking: IBooking | undefined = undefined;

  if (result.success && result.record) {
    booking = parseResBooking(result.record);
  }

  return booking;
}

export const CancelledBooking = async (storeSlug: string, id: string) => {
  const result = await updateRecord({
    id,
    storeSlug,
    moduleBundleID: 'tesldtx7',
    record: {
      'status': 'cancelled',
    },
  }, true);

  let booking: IBooking | undefined = undefined;

  if (result.success && result.record) {
    booking = parseResBooking(result.record);
  }

  return booking;
}

export const createCustomer = async (storeSlug: string, storeID: string, name: string, phone: string, email: string, userID: string) => {
  const result = await createRecord({
    storeSlug,
    moduleBundleID: '0au3vz8j',
    record: {
      'name': name,
      'phone': phone,
      'email': email,
      'store_id': {
        records: [{id: storeID}]
      },
      'linked_source_id': userID,
    },
  });

  let customer = undefined;

  if (result.success && result.record) {
    const record = result.record;
    customer = {
      id: record.id,
      name: record.name,
      phone: record.phone,
      email: record.email,
      storeID: record.store_id,
    };
  }

  return customer;
}
