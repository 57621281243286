
/**
 * apple pay
 * auth: Joshua
 * 2021/03/23
 */
import {
  payInvoice,
  // getApplePaySession,
} from '../payment-data';
import {
  handleBuildOrderPayment,
} from '../Utils';
import { IApplePayMethod, IExtraActionParam, IWindowApple } from '..';
import { getAccessToken } from '@/utils/app';

interface IApplePayParams {
  storeID: string;
  orderNumber: string;
  payData: IApplePayMethod;
  payment: any;
  extraAction?: IExtraActionParam;
}

interface IAppleNativePayResultParams {
  success: boolean;
  message: string;
  isReturn: boolean;
  paymentData: string;
}

let isGolbalReturn = false;
let successGolbal = false;
let messageGolbal = '';
let paymentDataGolbal = '';

(window as any).setAppleNativePayResult = (params: IAppleNativePayResultParams) => {
  const { isReturn, success, message, paymentData } = params;
  isGolbalReturn = isReturn;
  successGolbal = success;
  messageGolbal = message;
  paymentDataGolbal = paymentData;
}

const UseAppleNativePay = (params: IApplePayParams) => {
  const {
    storeID,
    orderNumber,
    payData,
    payment,
  } = params;

  const {
    amount,
    appMerchantId,
  } = payData;

  const {
    ApplePaySession,
  } = window as IWindowApple;

  let timeLoop: any = null

  const appleNativePay = async (): Promise<any> => {
    if (!ApplePaySession) return;
    if ((window as any).webkit && (window as any).webkit.messageHandlers && (window as any).webkit.messageHandlers.callApplePayment) {
      (window as any).webkit.messageHandlers.callApplePayment.postMessage({
        orderNumber,
        token: getAccessToken(),
        storeID,
        ...payData,
      });
    }

    try {
      const result = await getResult();
      return result;
    } catch (error) {
      throw(error);
    }
  };

  const getResult = () => {
    return new Promise((resolve, reject) => {
      timeLoop = setInterval( async () => {
        if (isGolbalReturn) {
          clearInterval(timeLoop);

          if (successGolbal && paymentDataGolbal) { // 验证成功
            try {
              const resp = await pay(window.btoa(paymentDataGolbal));
              resolve(resp);
            } catch (error) {
              resolve({
                status: 500,
                message: messageGolbal,
              });
            }
          }

          if (!successGolbal || !paymentDataGolbal) {
            resolve({
              message: messageGolbal,
              status: 400,
            });
          }

          isGolbalReturn = false;
          successGolbal = false;
          messageGolbal = '';
          paymentDataGolbal = '';
        }
      }, 1000);
    });
  }

  const pay = async (paymentData: any) => {
    const newPayment = {
      'apple_pay': [
        {
          amount,
          'token_base64': paymentData,
          'merchant_identifier': appMerchantId,
        }
      ],
    };
    const order = handleBuildOrderPayment(newPayment, payment);
    try {
      const resp = await payInvoice(storeID, orderNumber, order);

      return resp;
    } catch (error) {
      throw(error);
    }
  }

  return {
    appleNativePay,
  }
}

export default UseAppleNativePay;
