import { IRoute } from '@/routes';
import paths from '@/routes/paths';
import FavoritePage from './FavoritePage';
import MembershipPage from './MembershipPage';
import MyIDPage from './MyIDPage';
import SettingPage from './SettingPage';
import RecordsPage from './RecordsPage';
import TermsPage from './TermsPage';
import PrivacyPage from './PrivacyPage';
import PrivilegePage from './PrivilegePage';
import UsePointsPage from './UsePointsPage';

export const mobileRoutes: IRoute[] = [
  {
    path: paths.FAVORITE,
    component: FavoritePage,
  },
  {
    path: paths.MEMBERSHIP,
    component: MembershipPage,
  },
  
  {
    path: paths.MY_ID,
    component: MyIDPage,
  },
  {
    path: paths.SETTING,
    component: SettingPage,
  },
  {
    path: paths.RECORDS,
    component: RecordsPage,
  },
  {
    path: paths.TERMS,
    component: TermsPage,
  },
  {
    path: paths.PRIVACY,
    component: PrivacyPage,
  },
  {
    path: paths.PRIVILEGE,
    component: PrivilegePage,
  },
  {
    path: paths.NEW_POINTS,
    component: UsePointsPage,
  },
];

export default mobileRoutes;
