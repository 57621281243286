import React, {useState, useEffect} from 'react';
import dayjs from 'dayjs';
import { IQueueing } from '@/actions/queueing-action';
import { checkImgExists } from '@/utils';
import Config from '@/Config';
import { IDispatch } from '@/actions';
import ActionType from '@/actions/action-type';
import { getIntl } from '@/components/App/App';

interface IQueueingItemProps {
  data: IQueueing;
  goToDetailPage: (id: string) => void;
  dispatch: IDispatch;
}

const QueueingItem: React.FC<IQueueingItemProps> = props => {

  const [imgUrl, setImgUrl] = useState('');
  const [storeName, setStoreName] = useState('');

  const {
    data,
    goToDetailPage,
    dispatch,
  } = props

  useEffect(() => {
    dispatch({
      type: ActionType.QUERY_STORE_BY_ID,
      storeID: data.storeID,
      callback: store => {
        if (store) {
          checkImgExists(store.logoUrl, (success, url) => {
            if (success) {
              setImgUrl(url);
            } else {
              setImgUrl(Config.defaultStoreImg);
            }
          })
          setStoreName(store.name);
        }
      }
    }); 
    
  }, []);
  

  return (
    <div className="queueing-item" onClick={() => goToDetailPage(data.id)}>
      <div className="queueing-item-store-logo">
        <div className="image" style={{backgroundImage: `url(${imgUrl})`}} />
      </div>
      <div className="queueing-item-store">
        <div className="queueing-item-store-title">{storeName}</div>
        <div className="queueing-item-time">{dayjs(data.queueTime).format('YYYY.MM.DD HH:mm:ss')}</div>
      </div>
      <div className={`queueing-item-information ${data.status === 'no_show' ? 'opacity' : ''}`}>
        <div className="queueing-item-number">{data.queueingNo}</div>
        <div className="queueing-item-number-tip">{getIntl().page.queueNumber}</div>
      </div>
    </div>
  );
}

export default QueueingItem;
