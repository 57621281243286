import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IDispatch } from '@/actions';
import { IReducersState } from '@/reducers';
import { getDomain, parseRouteParams } from '@/utils';
import Constants from '@/constants';
import { IBasePageProps } from './BasePage';
import paths, { platformRoutePrefix } from '@/routes/paths';

export interface IWithRouteParams {
  pageComponent: any;
  routeProps: RouteComponentProps;
  pageTitleI18nKey: string;
}

interface ISsePagePropsParams {
  props: RouteComponentProps;
}

const exceptionRoutes = [
  paths.SIGN_IN,
  paths.SIGN_UP,
  paths.FORGOT_PASSWORD,
  paths.PAY_BILL,
  paths.PAY_NOORDER_RESULTS,
  paths.SELECT_SIGN_IN,
  paths.SELECT_STORES,
  paths.ICON_LIST,
  paths.PRIVACY,
  `${platformRoutePrefix}${paths.SIGN_IN}`,
  `${platformRoutePrefix}${paths.SIGN_UP}`,
  `${platformRoutePrefix}${paths.FORGOT_PASSWORD}`,
  `${platformRoutePrefix}${paths.PAY_BILL}`,
  `${platformRoutePrefix}${paths.PAY_NOORDER_RESULTS}`,
  `${platformRoutePrefix}${paths.SELECT_SIGN_IN}`,
  `${platformRoutePrefix}${paths.SELECT_STORES}`,
  `${platformRoutePrefix}${paths.PRIVACY}`,
];

const RouteComponent: React.FC<IWithRouteParams> = props => {
  const {
    pageComponent: PageComponent,
    routeProps,
  } = props;

  const dispatch = useDispatch<IDispatch>();
  const reducersState: IReducersState = useSelector<IReducersState, IReducersState>(state => state);
  const routeParams = parseRouteParams(routeProps);

  const pageProps: IBasePageProps = {
    ...routeProps,
    dispatch,
    reducersState,
    routeParams,
  };

  const { routePath } = routeParams;
  const { suppliers, showSelectionPage, storeSlug } = reducersState.app.platformAppSettings || {};
  const selectStoreSlug = localStorage.getItem(Constants.SUPPLIERS_SELECT_STORE_SLUG) || '';

  // 平台配置加载完成
  if (reducersState.app.loadingPlatformConfig) {
    return null;
  }
  const {
    gatewayDomain,
    domain,
    platform,
  } = getDomain();

  // 判断登录状态
  const { pathname: lPathname, hash } = window.location;
  let isCurrentBindoCo = false;
  if (lPathname.startsWith('/p/')) {
    isCurrentBindoCo = true;
  }
  if (!reducersState.user.userInfo && !exceptionRoutes.includes(routePath) && suppliers) {
    window.location.href = isCurrentBindoCo ? `/p/${platform}/sign-in` : '/sign-in';
    return null;
  } else if (!reducersState.user.userInfo && !exceptionRoutes.includes(routePath) && showSelectionPage && domain !== gatewayDomain) {
    window.location.href = isCurrentBindoCo ? `/p/${platform}/select-sign-in` : '/select-sign-in';
    return null;
  }

  if (!exceptionRoutes.includes(routePath) && suppliers && domain !== gatewayDomain && !selectStoreSlug) {
    window.location.href = isCurrentBindoCo ? `/p/${platform}/select-stores` : '/select-stores';
    return null;
  }

  if (lPathname === '/' && hash.includes('#/params')) {
    if (hash.includes('table_number')) {
      const hashParams = hash.split('?');
      const tableMsg = hashParams[1].split('=');
      window.location.href = isCurrentBindoCo ? `/p/${platform}/stores/${storeSlug}/menus?uuid=${tableMsg[1]}` : `/stores/${storeSlug}/menus?uuid=${tableMsg[1]}`;
    }
  }

  return (
    <PageComponent
      {...pageProps}
    />
  );
};

const withRoute = (params: IWithRouteParams) => {
  return (
    <RouteComponent
      {...params}
    />
  );
}

export default withRoute;
