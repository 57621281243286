import React, { useState } from 'react';
import { prefix } from '.';
import ShareType from './ShareType';
import Icon, { IconType } from '@/components/Icon';
import { IShareCartInfo, shareShoppingCartInfo, getTemplate, ShareToEmail } from '@/utils/share-util';
import { useDispatch } from 'react-redux';
import { IDispatch } from '@/actions';
import ActionType from '@/actions/action-type';
import Toast from 'antd-mobile/es/toast';
import { IReducersState } from '@/reducers';
import { IStore } from '@/actions/store-action';
import { Popup } from '../Modal';
import { getIntl } from '../App/App';
import InputItem from 'antd-mobile/es/input-item';
import { langFormat } from '@/utils/app';
import TextareaItem from 'antd-mobile/es/textarea-item';

interface IBottomBarProps {
  showBottomBar: boolean;
  reducersState: IReducersState;
  storeName?: string;
  onClick: () => void;
  data?: IShareCartInfo | undefined;
  selectStore?: IStore | undefined;
}

const BottomBar: React.FC<IBottomBarProps> = props => {
  const {
    showBottomBar,
    onClick,
    storeName,
    data,
    reducersState,
    selectStore,
  } = props;

  const dispatch = useDispatch<IDispatch>();
  const content = data ? decodeURIComponent(getTemplate(ShareType.Email, data, reducersState, selectStore)) : '';

  const shareIcons: any = {
    [ShareType.WHTAS_APP]: IconType.WHATS_APP_OUTLINED,
    [ShareType.Email]: IconType.APPLE_EMAIL_OUTLINED,
  }
  const [showShareEmail, handleShowEmail] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [emailErr, setEmailErr] = useState<boolean>(false);
  const [emailContent, setEmailContent] = useState<string>(content);

  const handleShare = async (type: string) => {
    if (!data) return;
   

    // if (type === ShareType.WHTAS_APP || type === ShareType.Email) {
    //   shareShoppingCartInfo(data, type, reducersState, selectStore);
    // }
   
    if (type === ShareType.WHTAS_APP) {
      dispatch({
        type: ActionType.LOADING,
        showLoading: true,
      });
      shareShoppingCartInfo(data, type, reducersState, selectStore);
      dispatch({
        type: ActionType.LOADING,
        showLoading: false,
      });
      onClick();
    } else {
      handleShowEmail(true);
    }
  }

  const handleShareEmail = () => {
    if (!data || !email) return;
    if (emailErr) return;
    data.email = email;
    // shareShoppingCartInfo(data, ShareType.Email, reducersState, selectStore);
    ShareToEmail(decodeURIComponent(emailContent), data.referenceID ? data.referenceID : '', email);
    onClick();
    Toast.info(getIntl().common.emailSent);
  }
  const checkEmail = (value: string | undefined) => {
    if (value) {
      const valueArr = value.split(',');
      const reg = /^([A-Za-z0-9_+-.])+@([A-Za-z0-9\-.])+\.([A-Za-z]{2,22})$/;
      valueArr.forEach(item => {
        const veriftyRes = reg.test(item);
        setEmailErr(!veriftyRes);
      })
    } else {
      setEmailErr(true);
    }
  }

  const handleBlur = (value: string | undefined) => {
    checkEmail(value);
    // verfityEmail(veriftyRes);
  };

  const handleCopy = () => {
    if (!data) return;
    const content = decodeURIComponent(getTemplate('copy', data, reducersState, selectStore));
    const oInput = document.createElement('input');
    oInput.value = content;
    document.body.appendChild(oInput);
    oInput.select();
    document.execCommand('Copy');
    oInput.style.display='none';
    Toast.info(getIntl().page.copySuccessMsg);
  }


  return (
    <Popup
      visible={showBottomBar}
      className={`${prefix}`}
      onClose={onClick}
    >
      {/* <div className={`${prefix}-mask ${showBottomBar ? 'show-bottom-bar-mask' : ''}`} onClick={onClick} /> */}
      <div className={`${prefix}-content ${showBottomBar ? 'show-bottom-bar-content' : ''}`}>
        <div className={`${prefix}-content-header`}>
          <div className={`${prefix}-content-title`}>
            {getIntl().page.shareVia}
          </div>
          <div className={`${prefix}-content-content`}>
            <div className={`${prefix}-content-storeName`}>
              {storeName || ''}
            </div>
            <div className={`${prefix}-content-copy`} onClick={() => handleCopy()}>
              <Icon className={`${prefix}-content-copy-icon`} type={IconType.COPY_OUTLINED} />
              <div className={`${prefix}-content-copy-word`} >{getIntl().page.copy}</div>
            </div>
          </div>
        </div>
        <div className={`${prefix}-content-wrap`}>
          {
            Object.keys(shareIcons).map(key => (
              <div key={key} className={`${prefix}-content-wrap-item`} onClick={() => handleShare(key)}>
                <Icon type={shareIcons[key]} />
                <div>{key}</div>
              </div>
            ))
          }
        </div>
      </div>
      <Popup
        visible={showShareEmail}
        className={`${prefix} share-email-content-wrap`}
        onClose={onClick}
      >
        <div className={`${prefix}-content share-email-content ${showShareEmail ? 'show-bottom-bar-content' : ''}`}>
          <div className="email-header">
            <div className="share-btn-wrap">
              <div className="btn-cancel" onClick={() => onClick()}>{getIntl().common.cancel}</div>
              <div className="btn-send" onClick={() => handleShareEmail()}>{getIntl().common.send}</div>
            </div>
            <div className="email-msg">
              <div className="email-title">{getIntl().common.sendTo}</div>
              <InputItem
                name="verify"
                autoComplete="off"
                onChange={value => {
                  setEmail(value);
                  checkEmail(value);
                }}
                onBlur={value => {
                  handleBlur(value);
                }}
                error={emailErr}
                value={email}
                className="share-email"
              />
            </div>
            <div className="email-msg">
              <div className="email-title">{getIntl().common.subject}</div>
              <div className="msg">
                {data?.referenceID ? langFormat(getIntl().common.subjectCon, { referenceID: data?.referenceID }) : getIntl().common.subjectTo}
              </div>
            </div>
          </div>
          <div className="email-content">
            <TextareaItem
              defaultValue={emailContent}
              autoHeight
              className="edit-email"
              onChange={value => { value && setEmailContent(value) }}
            />
          </div>
        </div>
      </Popup>
    </Popup>
  );
}

export default BottomBar;
