import { IStore, IStoreConfig } from '@/actions/store-action';
import ShippingMethod from '@/lib/order-builder/enums/ShippingMethod';
import React from 'react';
import { prefix } from '.';
import Icon, { IconType } from '../Icon';
import { getIntl } from '../App/App';

interface IStoreTagsProps {
  store: IStore;
  storeConfig?: IStoreConfig;
}

interface IStoreTagProps {
  className: string;
  tag: React.ReactNode;
  iconType: IconType;
}

const StoreTag: React.FC<IStoreTagProps> = props => {
  const { tag, iconType, className } = props;
  return (
    <div className={`${prefix}-store-tag ${className}`}>
      <div>{tag}</div>
      <Icon type={iconType} />
    </div>
  );
}

const StoreTags: React.FC<IStoreTagsProps> = props => {
  const {
    storeConfig,
  } = props;

  const {
    showBooking = false,
    shippingMethods = [],
  } = storeConfig || {};

  return (
    <div className={`${prefix}-store-tag-box`}>
      {
        showBooking &&
        <StoreTag
          className="booking"
          tag={getIntl().page.booking}
          iconType={IconType.BOOK_OUTLINED}
        />
      }
      {
        shippingMethods && shippingMethods.includes(ShippingMethod.PICK_UP) &&
        <StoreTag
          className="take-away"
          tag={getIntl().page.takeAway}
          iconType={IconType.PICK_UP_OUTLINED}
        />
      }
      {
        shippingMethods && shippingMethods.includes(ShippingMethod.DELIVERY) &&
        <StoreTag
          className="quick"
          tag={getIntl().page.delivery}
          iconType={IconType.QUICK_OUTLINED}
        />
      }
    </div>
  );
}

export default StoreTags;
