import styled from 'styled-components';
export const SideNavStyled = styled.div`
  position: relative;
  width: 20%;
  max-width: 20%;
  margin-right: 40px;
  margin-left: 0px;
.side-nav {
  position: relative;
  width: 20%;
  max-width: 20%;
  margin-right: 40px;
  margin-left: 0px;
}
.sticky-member-side-nav {
  position: -webkit-sticky;
  position: fixed;
  top: 70px;
  margin-top: 30px;
  overflow: scroll;
}
.profile-block-side-nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 30px;
  padding: 20px 0px 20px 20px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--bm-text-title-color);
  text-align: center;
  .profile-member {
    max-width: 56%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.profile-img {
  width: 60px;
  height: 60px;
  margin-right: 20px;
  border-radius: 40px;
  background-position: 50% 50%;
  background-size: cover;
  background-color: var(--bm-primary-color-t12);
}
.text-block-15 {
  margin-bottom: 10px;
  font-family: 'Sf pro display', sans-serif;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  word-break: break-all;
  max-width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.member-status {
  padding: 5px 20px;
  border-radius: 20px;
  font-family: 'Sf pro display', sans-serif;
  color: var(--bm-light-background-color);
  font-size: 12px;
}
.link-block-8.w--current {
  border-radius: 12px;
  background-color: var(--bm-light-background-color);
  font-size: 16px;
}
.link-block-8 {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  padding: 20px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color:  #000;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
}
.html-embed-30 {
  width: 30px;
  height: 25px;
  margin-right: 10px;
  svg {
    width: 100%;
    height: 100%;
  }
}
.avatar-icon-wrap {
  background-color: var(--bm-primary-color-t12);
  width: 80PX;
  height: 80PX;
  margin-right: 20PX;
  border-radius: 40PX;
  background-position: 50% 50%;
  background-size: cover;
}
.avatar-icon {
  color: var(--bm-light-background-color);
  font-size: 34PX;
  top: 50%;
  transform: translateY(-50%);
}
@media screen and (max-width: 991px) {
  width: 25%;
  max-width: none;
  margin-right: 20px;
  margin-left: 0px;
  .side-nav {
    width: 25%;
    max-width: none;
    margin-right: 20px;
    margin-left: 0px;
  }
  .profile-block-side-nav {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 0px;
  }
  .profile-img {
    width: 60px;
    height: 60px;
    min-height: 60px;
    min-width: 60px;
    margin-right: 10px;
  }
  .text-block-15 {
    text-align: center;
  }
  .link-block-8.w--current {
    padding: 15px;
  }
}
@media screen and (max-width: 767px) {
  display: none;
  .side-nav {
    display: none;
  }
  .profile-block-side-nav {
    margin-bottom: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
  }
  .profile-img {
    width: 60px;
    height: 60px;
  }
  .text-block-15 {
    font-size: 15px;
    text-align: left;
  }
  .avatar-icon-wrap {
    width: 75PX;
    height: 75PX;
  }
}
`;

export const NavbarStyled = styled.div`
  position: static;
  z-index: 10;
  height: auto;
  max-height: 70px;
  min-height: 70px;
  border-bottom: 0px solid rgba(0, 0, 0, 0.1);
  background-color: rgba(80, 61, 61, 0);
  background-image: none;
  background-size: auto;
  .bm-p-menu-banner-bgcolor {
    background-color: transparent !important;
  }
.w-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
}
.nav_bg {
  left: unset;
  top: 0;
  bottom: unset;
  width: 100%;
  right: unset;
}
.nav_bg.streched.without-line {
  z-index: 6;
  border-bottom-width: 0px;
  border-bottom-color: #eaeaea;
  background-color: var(--bm-primary-color);
  color: var(--bm-light-background-color);
  padding-top: calc(var(--bm-safe-area-top));
}
.nav_bg.streched {
  position: fixed;
  z-index: 6;
  height: auto;
  max-height: 70px;
  min-height: 70px;
  border-bottom: 0px solid rgba(0, 0, 0, 0.1);
  background-color: rgba(80, 61, 61, 0);
  background-image: none;
  background-size: auto;
}
.div-block-7.open-modal {
  position: relative;
  top: 0px;
  z-index: 8;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  cursor: pointer;
}
.html-embed-7 {
  width: 45px;
  height: 45px;
  color: var(--bm-light-background-color);
  padding: 0;
  svg {
    width: 100%;
    height: 100%;
  }
}
.down.html-embed-7 {
  height: 44px;
}
.html-embed-7.search-outlined {
  margin-left: 15px;
}
.html-embed-7.scan-outlined,
.html-embed-7.search-outlined {
  width: 31px;
  height: 31px;
}
.html-embed-7.recycle-bin-outlined {
  width: 38px;
}
.side-bar-icon {
  width: 38px;
  height: 28px;
  svg {
    width: 100%;
    height: 100%;
  }
}
.left-circle {
  background-color: var(--bm-light-background-color);
  color: var(--bm-text-title-color);
  box-shadow: 0 0 2px rgb(0, 0, 0, .15);
  border-radius: 50%;
  width: 38px;
  height: 38px;
}
.container.fixed-nav {
  position: fixed;
  left: 0%;
  padding-top: var(--bm-safe-area-top);
  right: 0%;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-height: 70px;
  min-height: 70px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.nav-bar-title {
  z-index: 999;
  margin: 0px auto;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: var(--bm-light-background-color);
  font-size: 28px;
  font-weight: 400;
}
h1 {
  font-size: 38px;
  line-height: 44px;
  margin-top: 20px;
}
.scanner_block {
  z-index: 6;
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: flex;
  margin-left: 10px;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  align-self: center;
  -webkit-box-flex: 0;
  cursor: pointer;
  padding: 0px;
  flex: 0 1 auto;
}
.text-block-215 {
  font-family: "Fontsfree net sfprodisplay", sans-serif;
  color: var(--bm-light-background-color);
  font-size: 18px;
}
.container-stretched_1 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  max-height: 70px;
  max-width: none;
  min-height: 70px;
  margin-right: 60px;
  margin-left: 60px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.store-name {
  display: flex;
}
@media screen and (max-width: 991px) {
  .w-container {
    max-width: 728px;
  }
  .container-stretched_1 {
    margin-right: 30px;
    margin-left: 30px;
    max-width: none;
  }
}
@media screen and (max-width: 767px) {
  max-height: none;
  max-width: none;
  min-height: 60px;
  .nav_bg.streched.without-line.blue {
    position: fixed;
  }
  .nav_bg.streched.without-line {
    z-index: 6;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .nav_bg.streched {
    max-height: none;
    max-width: none;
    min-height: 60px;
  }
  .container-stretched_1.both {
    width: auto;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .container-stretched_1 {
    max-height: 60px;
    min-height: 60px;
  }
  .html-embed-7 {
    width: 37px;
    height: 34px;
    color: var(--bm-light-background-color);
  }
  .down.html-embed-7 {
    height: 44px;
  }
  .html-embed-7.scan-outlined,
  .html-embed-7.search-outlined {
    width: 26px;
    height: 26px;
  }
  .side-bar-icon {
    width: 30px;
    height: 22px;
  }
  .left-circle {
    color: var(--bm-text-title-color);
    width: 36px;
    height: 36px;
  }
  .container.fixed-nav {
    max-height: 60px;
    min-height: 60px;
  }
  .nav-bar-title {
    font-size: 24px;
  }
  .scanner_block {
    left: auto;
    top: 0%;
    right: 0%;
    bottom: 0%;
  }
}
@media screen and (max-width: 479px) {
  max-height: 50px;
  min-height: 50px;
  .nav_bg.streched.without-line.blue {
    position: fixed;
  }
  .nav_bg.streched {
    max-height: 50px;
    min-height: 50px;
  }
  .container-stretched_1 {
    max-height: 50px;
    min-height: 50px;
    margin-right: 16px;
    margin-left: 17px;
  }
  .w-container {
    max-width: none;
  }
  .div-block-7.open-modal {
    position: relative;
    top: 0px;
    z-index: 999;
    margin-left: 0px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  .html-embed-7 {
    width: 30px;
    height: 30px;
  }
  .html-embed-7.recycle-bin-outlined {
    width: 28px;
    height: 28px;
  }
  .down.html-embed-7 {
    height: 44px;
  }
  .html-embed-7.search-outlined {
    margin-left: 8px;
  }
  .html-embed-7.scan-outlined,
  .html-embed-7.search-outlined {
    width: 24px;
    height: 24px;
  }
  .side-bar-icon {
    width: 32px;
    height: 22px;
  }
  .container.fixed-nav {
    max-height: 50px;
    min-height: 50px;
  }
  .container {
    margin-top: 0px;
    margin-bottom: 0px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .nav-bar-title {
    font-size: 22px;
  }
}
`;
export const ComponentsStyled = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 70px;
  bottom: 0;
  z-index: 6;
  background-color: var(--bm-dark-background-color);
  overflow: scroll;
  >div:first-child {
    height: 100%;
  }
  .nav-bar { 
    position: fixed;
  }
  .records-styled {
    height: 0;
    max-height: 0;
    min-height: 0;
  }
  .bm-p-records,
  .bm-p-privilege {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: scroll;
    position: absolute;
  }
  .bm-p-privilege-p,
  .bm-p-records-p {
    overflow: scroll;
    position: absolute;
    inset: 0px;
    background-color: var(--bm-dark-background-color);
  }
  .bm-p-records-p {
    padding-top: var(--bm-safe-area-top);
  }
  .bm-p-use-points {
    height: 100%;
  }
  .bm-p-records,
  .bm-p-bookings {
    background-color: var(--bm-dark-background-color);
  }
  .bm-p-privilege {
    background-color: var(--bm-border-color);
  }
  .whole {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 1600px;
    margin-right: auto;
    margin-left: auto;
    /* padding-top: 70px; */
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .member-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 1200px;
    max-width: 1200px;
  }
  .white-banner {
    position: fixed;
    z-index: 6;
    display: none;
    width: 100%;
    height: 70px;
    max-height: 70px;
    border-bottom: 1px solid var(--bm-border-color);
    background-color: var(--bm-light-background-color);
  }
@media screen and (max-width: 991px) {
  .whole {
    padding-right: 30px;
    padding-left: 30px;
  }
  .member-wrapper {
    width: auto;
    max-width: none;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .bm-p-records {
    background-color: var(--bm-dark-background-color);
  }
}

@media screen and (max-width: 767px) {
  .whole {
    background-color: var(--bm-light-background-color);
    /* padding-top: 60px; */
    padding-right: 0px;
    padding-left: 0px;
  }
  .white-banner {
    top: 60px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 50px;
    max-height: 50px;
  }
  .bm-p-favorite {
    .member-wrapper {
      background-color: var(--bm-dark-background-color);
    }
  }
}
@media screen and (max-width: 479px) {
  .whole {
    max-width: none;
    margin-right: 0px;
    margin-left: 0px;
    /* padding-top: 50px; */
  }
  .white-banner {
    position: fixed;
    top: calc(var(--bm-safe-area-top) + 50PX);
    display: block;
    height: 50px;
    max-height: 50px;
    color: var(--bm-light-background-color);
    >div:first-child {
      padding-left: 15px;
    }
  }
  .bm-p-privilege {
    background-color: var(--bm-dark-background-color);
  }
  .bm-p-privilege-p {
    .tabs-content {
      margin-top: -30px;
    }
  } 

}

`;


