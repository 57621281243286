import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Script from 'react-load-script';
import { ThemeProvider } from 'styled-components';
import routes, { IRoute } from '@/routes';
import { IReducersState } from '@/reducers';
import Config, { isUpgrade, upgradeToV } from '@/Config';
import { prefix } from '.';
import { setHtmlBackgroundColor, getDomain, getSearchParams, setBodyScrollable, pxToRem } from '@/utils';
import ActionType from '@/actions/action-type';
import { IDispatch } from '@/actions';
import { IAppTheme } from '@/actions/app-action';
import Constants from '@/constants';
import Status from '../Status';
import { PageTransition } from '../Transition';
import { platformRoutePrefix } from '@/routes/paths';
import Icon, { IconType } from '../Icon';
import { setAccessToken, getLangPacks, getDefaultLanguage } from '@/utils/app';

let currentAppTheme: IAppTheme = {
  primaryColor: '#4C95FF',
  primaryTapColor: '#387EF2',
  darkBackgroundColor: '#F2F3F8',
  lightBackgroundColor: '#FFFFFF',
  textColor: '#666666',
  secondaryTextColor: '#999999',
  titleColor: '#333333',
  disabledTextColor: '#EAEAEA',
  errorColor: '#FF5D5D',
  warningColor: '#FFC617',
  borderColor: '#EBEFF2',
  bookColor: '#A88AEC',
  deliveryColor: '#86CC7A',
}

let isChangeBackground = true;

export const getAppTheme = () => currentAppTheme;

let screenTopColor = currentAppTheme.primaryColor || '';
let screenBottomColor = currentAppTheme.primaryColor || '';

/**
 * 根据页面滚动距离，设置不同的背景颜色
 * @param topColor
 * @param bottomColor
 */
export const setScreenColor = (topColor: string, bottomColor?: string) => {
  setHtmlBackgroundColor(topColor);
  screenTopColor = topColor;
  screenBottomColor = bottomColor || '';
  let isChange = false;
  if (bottomColor && topColor !== bottomColor) {
    isChange = true;
  }
  isChangeBackground = isChange;
}

export const changeBackground = (isChange: boolean) => isChangeBackground = isChange;

const getScrollY = () => {
  const pageWrap = window.document.querySelector('.page-content-wrapper-web');
  let scrollY = window.scrollY;
  if (pageWrap) {
    scrollY = pageWrap.scrollTop;
  }

  return scrollY;
}

export const handleScroll = () => {
  const lastScrollY = getScrollY();
  const { pathname } = window.location;
  pageRecoverableData[pathname] = {
    ...(pageRecoverableData[pathname] || {}),
    scrollY: lastScrollY,
  };
  if (!isChangeBackground) return;

  if (!screenTopColor && !screenBottomColor) return;

  let halfScrollHeight: number | undefined = undefined;
  const appPages = document.getElementsByClassName('bm-c-layout');
  if (appPages && appPages.length) {
    const appPage = appPages[0];
    const innerHeight = window.innerHeight;
    halfScrollHeight = (appPage.scrollHeight - innerHeight) / 2
  }

  if (halfScrollHeight === undefined) return;

  if (lastScrollY < halfScrollHeight) {
    setHtmlBackgroundColor(screenTopColor);
  } else {
    setHtmlBackgroundColor(screenBottomColor);
  }
}

window.addEventListener('scroll', handleScroll, { passive: true });

let scannerInterval: any = undefined;
let checkScannerCount = 0;

interface IRecoverableData {
  scrollY: number;
}

export let pageRecoverableData: {[path: string]: IRecoverableData} = {};

export const getPageRecoverableData = (path: string) => {
  return pageRecoverableData[path] || {};
}

export const clearPageRecoverableData = () => {
  pageRecoverableData = {};
}

export let pageBeingAnimated = false;

export const setPageBeingAnimated = (a: boolean) =>  pageBeingAnimated = a;
export const getPageBeingAnimated = () =>  pageBeingAnimated;

export let statusBarHeight = '';
export let getDeviceID = '';
export let getDevicePushToken = '';

export const getIntl = () => getLangPacks(getDefaultLanguage());
export const setIntl = (lang: string) => getLangPacks(lang);

export const getIntlByPath = (path: string) => {
  const pathSlic = path.split('.');
  let intlValue = '';

  let cLocales: any = getIntl();
  for (let i = 0; i < pathSlic.length; i++) {
    const pathKey = pathSlic[i];

    if (i + 1 === pathSlic.length) {
      if (cLocales[pathKey]) {
        intlValue = cLocales[pathKey];
      }
    } else if (cLocales[pathKey]) {
      cLocales = cLocales[pathKey];
    }
  }

  return intlValue;
}

const App: React.FC = () => {
  const reducersState: IReducersState = useSelector<IReducersState, IReducersState>(state => state);
  const {
    showLoading,
    showPageLoading,
    loadingPlatformConfig,
    deviceInfo,
    showLoadingBgc,
  } = reducersState.app;
  const dispatch = useDispatch<IDispatch>();

  const [hasPlatform, setHasPlatform] = useState(false);

  
  useEffect(() => {
    const locallanguage = localStorage.getItem(Constants.LANGUAGE);
    if (locallanguage) {
      setIntl(locallanguage)
    }

    if (!deviceInfo.isWeChat && !deviceInfo.isQQBrowser) {
      sendInteractiveReq();
    }
    const w: any = window;
    if(w && w.webkit && w.webkit.messageHandlers && w.webkit.messageHandlers.getDeviceToken) {
      w.webkit.messageHandlers.getDeviceToken.postMessage('deviceID');
    }
    scannerInterval = setInterval(() => {
      checkScannerCount++;
      const Instascan = (window as any).Instascan;
      if (Instascan && Instascan.Scanner && Instascan.Camera) {
        clearInterval(scannerInterval);
        scannerInterval = undefined;
        dispatch({
          type: ActionType.SET_SCANNABLE_QRCODE,
          scannableQRCode: true,
        });
      } else if (checkScannerCount > 10 * 60) {
        clearInterval(scannerInterval);
        scannerInterval = undefined;
      }
    }, 1000);

    const search = getSearchParams();
    const {
      platformDomain,
      gatewayDomain,
      hideSplash,
      firstVisited,
      initCoordinates,
      isLocalhost,
    } = getDomain();
    if (!isLocalhost && gatewayDomain !== platformDomain) {
      dispatch({
        type: ActionType.SET_NEW_APP_CONFIG,
        gatewayAppSettings: search.gatewayAppSettings,
        platformAppSettings: search.platformAppSettings,
        platformConfig: search.platformConfig,
        appTheme: search.appTheme,
        lessTheme: search.lessTheme,
      }); 
    }
    let isGuest = false;
    if (search) {
      if (search.token && search.token.length > 0) {
        setAccessToken(search.token);
      }

      if (search.user === 'guest') {
        isGuest = true;
      }

      if (deviceInfo.weChatMiniProgram && !localStorage.getItem(Constants.MINI_PROGRAM_TIPS)) {
        localStorage.setItem(Constants.MINI_PROGRAM_TIPS, String(firstVisited));
      }
    }

    if (search && search.paymentData && isGuest) {
      try {
        if (Array.isArray(search.paymentData)) {
          dispatch({
            type: ActionType.SET_FROM_URL_PAY_DATA,
            fromUrlPayData: search.paymentData,
          });
        }
      } catch (error) {
        console.error(error)
      }
    }

    dispatch({
      type: ActionType.SET_DOMAIN,
      gatewayDomain,
      platformDomain,
    });

    dispatch({
      type: ActionType.SET_IS_GUEST,
      isGuest,
    });

    dispatch({
      type: ActionType.QUERY_APP_CONFIG,
      gatewayDomain,
      platformDomain,
      hideSplash,
      callback: async params => {
        if (params.lessTheme && Object.keys(params.lessTheme).length > 0) {
          try {
            await less.modifyVars(params.lessTheme);
            await less.refresh(true, params.lessTheme);
          } catch (error) {
            console.error(error)
          }
          currentAppTheme = {
            ...currentAppTheme,
            ...params.appTheme,
          }
        }

        if (params.platformAppSettings.id && params.gatewayAppSettings.id) {
          setHasPlatform(true);
        } else {
          setBodyScrollable(false);
        }
        if (initCoordinates) {
          dispatch({
            type: ActionType.INIT_COORDINATES,
          });
        }

        dispatch({
          type: ActionType.SET_LOADING_PLATFORM_CONFIG,
          loadingPlatformConfig: false,
        });
      }
    });
  }, []);

  (window as any).getDeviceStatusBarHeight = (height: number) => {
    statusBarHeight = pxToRem(height);
  }
  const bindoUtils: any = (window as any).bindo_utils;
  if (deviceInfo.isAndroid && bindoUtils && bindoUtils.getPushDeviceToken) {
    getDevicePushToken = bindoUtils.getPushDeviceToken();
    getDeviceID = bindoUtils.getDeviceId();
  } else  {
    (window as any).getPushDeviceToken = (deviceInfo: { deviceID: string; devicePushToken: string }) => {
      if (deviceInfo && deviceInfo.deviceID) {
        getDevicePushToken = deviceInfo.devicePushToken;
        getDeviceID = deviceInfo.deviceID;
      }
    }
  } 
  const sendInteractiveReq = () => {
    const w: any = window;
    if (w && w.webkit && w.webkit.messageHandlers && w.webkit.messageHandlers.getTopBarHeight) {
      w.webkit.messageHandlers.getTopBarHeight.postMessage('height');
    }
  }

  let prePath = '';
  if (isUpgrade) {
    prePath = `/${upgradeToV}`;
  }

  let instascanUrl = `${Config.cdnUrl}/js/instascan.min.js`;
  if (deviceInfo.isApple) {
    instascanUrl = `${Config.cdnUrl}/js/instascan-for-ios.min.js`;
  }

  return (
    <ThemeProvider theme={currentAppTheme}>
      {/* <div className="root-space" /> */}
      <PageTransition className={(deviceInfo.inPC || deviceInfo.inPad) ? 'web' : 'phone'}>
        <Switch>
          {routes.map((route: IRoute) => {
            const path = `${prePath}${route.path}`;
            const platformPath = `${prePath}${platformRoutePrefix}${route.path}`;
            const routeComponents: any[] = [
              <Route
                path={path}
                key={path}
                exact={route.exact === undefined || route.exact}
                component={route.component}
              />,
              <Route
                path={platformPath}
                key={platformPath}
                exact={route.exact === undefined || route.exact}
                component={route.component}
              />,
            ];

            return routeComponents;
          })}
        </Switch>
      </PageTransition>
      {
        showPageLoading &&
        <div className={`${prefix}-page-loading`}>
          <div className="loading-data-img">
            <img src={`${Config.cdnUrl}/images/loading-data.gif`} alt="" />
          </div>
          <div className="loading-data-text">
            {getIntl().common.loading}
          </div>
        </div>
      }
      {/* <div className="root-space" /> */}
      {
        hasPlatform &&
        <Script url={instascanUrl} />
      }
      {
        showLoading &&
        <div className={`${prefix}-loading ${showLoadingBgc? prefix+'-loading-bgc': ''}`} >
          <div className="loading-img">
            <Icon type={IconType.LOADING} />
            <div className="loading-text">
              {getIntl().common.loading}
            </div>
          </div>
        </div>
      }
      {
        !hasPlatform && !loadingPlatformConfig &&
        <div className={`${prefix}-no-stores`}>
          <Status
            describe={getIntl().page.noPlatformDescribe}
            imgUrl={`${Config.cdnUrl}/images/404.png`}
          />
        </div>
      }
    </ThemeProvider>
  );
};

export default App;
