import React from 'react';
import './index.less';

interface ISpinnerLoadingProps {
  className?: string;
}

const SpinnerLoading: React.FC<ISpinnerLoadingProps> = props => {
  const { className = '' } = props;

  return (
    <div className={`lds-spinner ${className}`}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

export default SpinnerLoading;
