import React, { ReactElement } from 'react';
import { prefix } from '..';


interface IGoogleMapEditSuiteProps {
  // editPlace: any;
  // savePlace: Function;
  detailAddress: ReactElement;
}

const GoogleMapEditSuite = (props: IGoogleMapEditSuiteProps) => {

  const {
    detailAddress,
  } = props;

  // const [aptSuite, setAptSuite] = useState<string>('');

  // const setGoogleMapAddress = () => {
  //   const googleMapAddress: IGoogleMapAdress = {
  //     aptSuite: aptSuite,
  //     name: editPlace.name,
  //     vicinity: editPlace.formatted_address || editPlace.vicinity,
  //     lat: editPlace.geometry.location.lat(),
  //     lng: editPlace.geometry.location.lng(),
  //   }
  //   savePlace(googleMapAddress);
  // }

  return (
    <div
      className={`${prefix}-edit-suite`}
    >
      {/* <div className={`${prefix}-edit-suite-placename`} >{editPlace.name}</div>
      <div className={`${prefix}-edit-suite-vicinity`} >{editPlace.formatted_address || editPlace.vicinity}</div>
      <div className={`${prefix}-edit-suite-space`} />
      <TextareaItem
        className={`${prefix}-edit-suite-textarea`}
        title={getIntl().page.aptSuite}
        autoHeight
        onChange={(value: string | undefined) => value && setAptSuite(value)}
      />
      {
        <InputItem
          type="bankCard"
        >
          {'title'}
        </InputItem>
      }
      <div
        className={`${prefix}-edit-suite-button`}
        onClick={setGoogleMapAddress}
      >
        {getIntl().common.save}
      </div> */}
      {detailAddress}
    </div>
  );
}

export default GoogleMapEditSuite;
