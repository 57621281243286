
import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import Icon, { IconType } from '@/components/Icon';
// import { checkImgExists } from '@/utils';
import { IObject } from '@/components/bm-common';
import { getAppTheme, setScreenColor, getIntl } from '@/components/App/App';
import paths from '@/routes/paths';
import Layout from '@/components/Layout';
import { prefix } from '.';
import SettingItem from '@/components/SettingItem';
import BmTabBar from '@/components/BmTabBar';
import ActionType from '@/actions/action-type';
import AccountOption from '@/actions/account-option';
import { toWxMiniProgram, getAccessToken } from '@/utils/app';
import Constants from '@/constants';
import { TransitionKeys } from '@/components/Transition';
import TabsOption from '@/actions/tabs-option';

export interface IAccountPageProps extends IBasePageProps { }
export interface IAccountPageState {
}

class AccountPage extends BasePage<IAccountPageProps, IAccountPageState> {
  routeParams: IObject = {};

  state: IAccountPageState = {
  }

  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.lightBackgroundColor) {
      setScreenColor(appTheme.lightBackgroundColor);
    }
    super.componentDidMount();
  }

  SignIn = () => {
    const { reducersState } = this.props;
    const { deviceInfo } = reducersState.app;
    if (deviceInfo.weChatMiniProgram) {
      toWxMiniProgram({
        miniProgramPath: '/pages/authorization/authorization',
        data: {
          wxPageType: 'ACCOUNT',
        },
      });
    } else {
      this.handleGotoRoute({
        path: paths.SIGN_IN,
        animationType: TransitionKeys.FADE_FROM_BOTTOM,
      })
    }
  }

  signOut = () => {
    const { dispatch, reducersState } = this.props;
    const { userInfo } = reducersState.user;
    const { deviceInfo } = reducersState.app;
    if ( userInfo && userInfo.id && deviceInfo && deviceInfo.deviceID  ) {
      dispatch({
        type: ActionType.DELETE_DEVICE,
        userID: userInfo.id,
        deviceID: deviceInfo.deviceID
      });
    }
    dispatch({
      type: ActionType.CLEAR_USER_INFO,
    });
    dispatch({
      type: ActionType.CLEAR_CUSTOMER,
    });
    dispatch({
      type: ActionType.CLEAR_IS_GUEST,
    });
    dispatch({
      type: ActionType.CLEAR_QUEUEING,
    });
    dispatch({
      type: ActionType.CLEAR_BOOKING,
    });
    dispatch({
      type: ActionType.CLEAR_CUSTOMER_GROWTH,
    });
    dispatch({
      type: ActionType.CLEAR_CUSTOMER_PROMOTION,
    });
  }
  handleGoAccountEdit = () => {
    const token = getAccessToken();
    if (!token) {
      this.SignIn();
      return;
    }

    this.handleGotoRoute({
      path: paths.ACCOUNT_EDIT_BY_FROM,
      pathParams: {
        fromPage: 'account',
      },
    });
  }
  handleItemClick = (pathname: string) => {
    const token = getAccessToken();
    if (!token && pathname !== paths.SETTING && pathname !== paths.NEWS && pathname !== paths.ABOUT_US) {
      this.SignIn();
      return;
    }
    this.handleGotoRoute({ path: pathname });
  }

  findAppTabsIcon = (key: string) => {
    const { reducersState } = this.props;
    const { appIcons } = reducersState.app;
    if (appIcons && appIcons.length > 0) {
      const appIcon = appIcons.find(item => item.iconType === key);
      return appIcon;
    }
  }

  handleGotoAboutUs = () => {
    const { reducersState, dispatch } = this.props;
    const { platformAppSettings } = reducersState.app;
    if (platformAppSettings && platformAppSettings.storeSlug) {
      this.openLoading()
      dispatch({
        type: ActionType.QUERY_BLOG_LIST,
        contentType: 'COMPANY_INFO',
        storeSlug: platformAppSettings.storeSlug,
        callback: blogs => {
          if (blogs.length === 1) {
            this.handleGotoRoute({
              path: paths.BLOG_DETAIL,
              pathParams: {
                storeSlug: platformAppSettings.storeSlug,
                blogID: blogs[0].id,
                contentType: 'about-us',
              }
            })
          } else {
            this.handleGotoRoute({ path: paths.ABOUT_US });
          }
          dispatch({
            type: ActionType.LOADING,
            showLoading: false,
          })
        }
      })

    }
  }


  render() {
    const { reducersState } = this.props;
    const { platformConfig, deviceInfo } = reducersState.app;
    const { accountOptions } = platformConfig;
    const stringUserInfo = localStorage.getItem(Constants.USER_INFO);
    let userInfo = null;
    if (stringUserInfo) {
      userInfo = JSON.parse(stringUserInfo);
    }
    const token = getAccessToken();
    return (
      <>
        <Layout
          {...this.props}
          className={prefix}
          isFooter={false}
        >
          <div className="account-page-content">
            {
              accountOptions.includes(AccountOption.INFOTRMATION) &&
              <div className="personal-msg">
                <div className="avatar-wrap">
                  <div className="avatar-b" onClick={this.handleGoAccountEdit}>
                    {
                      token && userInfo && userInfo.avatarUrl &&
                      <div className="avatar" style={{ backgroundImage: `url(${userInfo.avatarUrl})` }} />
                    }
                    {
                      (!token || !userInfo.avatarUrl) &&
                      <Icon
                        type={IconType.DEFAULT_AVATAR_OUTLINED}
                        className="avatar-icon"
                      />
                    }
                    <div className="edit-icon-wrap">
                      <Icon
                        type={IconType.EDIT_OUTLINED}
                        className="edit-icon"
                      />
                    </div>
                  </div>
                </div>
                <div className="sign-btn-wrap">
                  {
                    token && userInfo &&
                    <div>{userInfo.name}</div>
                  }
                  {
                    (!token || !userInfo) &&
                    <div className="sign-btn" onClick={this.SignIn}>
                      {getIntl().page.signin}
                    </div>
                  }
                </div>
              </div>
            }
            <div className="personal-setting">
              {
                accountOptions.includes(AccountOption.BOOKING) &&
                <SettingItem
                  iconType={IconType.BOOKING_SETTING}
                  msg="page.myBookings"
                  onItemClick={() => this.handleItemClick(paths.BOOKINGS)}
                  appIcon={this.findAppTabsIcon('BOOKING_SETTING')}
                />
              }
              {
                accountOptions.includes(AccountOption.QUEUING) &&
                <SettingItem
                  iconType={IconType.QUEUEING_SETTING}
                  msg="page.myQueueings"
                  onItemClick={() => this.handleItemClick(paths.QUEUEINGS)}
                  appIcon={this.findAppTabsIcon('QUEUEING_SETTING')}
                />
              }
              {
                accountOptions.includes(AccountOption.RECORDS) &&
                <SettingItem
                  iconType={IconType.RECORDS_OUTLINED}
                  msg="page.myRecords"
                  onItemClick={() => this.handleItemClick(paths.RECORDS)}
                  appIcon={this.findAppTabsIcon('RECORDS_OUTLINED')}
                />
              }
              {
                accountOptions.includes(AccountOption.FAVORITE) &&
                <SettingItem
                  iconType={IconType.FAVORITE_SETTING}
                  msg="page.myFavorites"
                  onItemClick={() => this.handleItemClick(paths.FAVORITE)}
                  appIcon={this.findAppTabsIcon('FAVORITE_SETTING')}
                />
              }
              {
                accountOptions.includes(AccountOption.ADDRESS) &&
                <SettingItem
                  iconType={IconType.ADDRESS_OUTLINED}
                  msg="page.myAddress"
                  appIcon={this.findAppTabsIcon('ADDRESS_OUTLINED')}
                  onItemClick={() => this.handleItemClick(paths.ADDRESS_LIST)}
                />
              }
              {
                !deviceInfo.weChatMiniProgram && accountOptions.includes(AccountOption.PAYMENT) &&
                <SettingItem
                  iconType={IconType.PAYMENT_SETTING}
                  msg="page.myPayments"
                  onItemClick={() => this.handleItemClick(paths.PAYMENT_LIST)}
                  appIcon={this.findAppTabsIcon('PAYMENT_SETTING')}
                />
              }
              {
                accountOptions.includes(AccountOption.NEWS) &&
                <SettingItem
                  iconType={IconType.NEWS_OUTLINED}
                  msg="page.newsTitle"
                  onItemClick={() => this.handleItemClick(paths.NEWS)}
                  appIcon={this.findAppTabsIcon('NEWS_OUTLINED')}
                />
              }
              {
                accountOptions.includes(AccountOption.ABOUT_US) &&
                <SettingItem
                  iconType={IconType.ABOUT_US_OUTLINED}
                  msg="page.aboutUsTitle"
                  onItemClick={() => this.handleGotoAboutUs()}
                  appIcon={this.findAppTabsIcon('ABOUT_US_OUTLINED')}
                />
              }
              {
                accountOptions.includes(AccountOption.ADMIN) && userInfo && userInfo.staff === true &&
                <SettingItem
                  iconType={IconType.BINDO_SETTING}
                  msg="page.bindo"
                  onItemClick={() => this.handleItemClick(paths.ADMIN_STORES)}
                  appIcon={this.findAppTabsIcon('BINDO_SETTING')}
                />
              }
              {
                accountOptions.includes(AccountOption.SETTING) &&
                <SettingItem
                  iconType={IconType.SETTING_OUTLINED}
                  msg="page.setting"
                  onItemClick={() => this.handleItemClick(paths.SETTING)}
                  appIcon={this.findAppTabsIcon('SETTING_OUTLINED')}
                />
              }
            </div>
            {
              token &&
              <div className="sign-out-btn" onClick={this.signOut}>
                {getIntl().page.signOut}
              </div>
            }
          </div>
        </Layout>
        {
          platformConfig.showTabs &&
          <BmTabBar
            selectedKey={TabsOption.ACCOUNT}
            reducersState={reducersState}
          />
        }
      </>
    );
  }
}

export default AccountPage;
