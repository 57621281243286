import styled from 'styled-components';
export const RecordsPageStyled = styled.div`
background-color: var(--bm-dark-background-color);
position: relative;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
width: 85%;
max-width: 85%;
margin-top: 30px;
margin-bottom: 30px;
padding: 0px 30px;
-webkit-box-pack: start;
-webkit-justify-content: flex-start;
-ms-flex-pack: start;
justify-content: flex-start;
-webkit-box-flex: 0;
-webkit-flex: 0 auto;
-ms-flex: 0 auto;
flex: 0 auto;
border-radius: 12px;
box-sizing: border-box;
padding-bottom: 100px;
.loading-data {
  text-align: center;
  margin: auto;
}
.loading-msg {
  font-size: 16px;
}
.tabs-menu-4 {
  position: -webkit-sticky;
  position: sticky;
  top: 40px;
  z-index: 9900000000;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  max-height: none;
  max-width: 200px;
  margin-top: 0px;
  margin-right: 40px;
  margin-left: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  /* -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1; */

  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;

}
.my-priviledge-tab.w--current {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  border-radius: 40px;
  background-color: var(--bm-primary-color);
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: var(--bm-light-background-color);
  font-size: 16px;
  font-weight: 700;
}
.w-inline-block {
  max-width: 100%;
  display: inline-block;
}
.w-tab-link {
  position: relative;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  padding: 9px 30px;
  text-align: left;
  cursor: pointer;
  background-color: unset;
}
.my-priviledge-tab {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  padding-right: 20px;
  padding-left: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: var(--bm-text-title-color);
  font-size: 16px;
}
.no-orders {
  text-align: center;
}
.bm-icon.tag-item {
  width: 40%;
  height: 100%;
  svg {
    width: 100%;
    height: 100%;
  }
}
.no-order {
  font-size: 18px;
}
.no-order-tip {
  font-size: 18px;
  color: var(--bm-text-secondary-color);
}
.privilege {
  display: flex;
}
.tabs-content {
  padding-top: 30px;;
}
&-skeleton {
  position: absolute;
  left: 0;
  top: calc(var(--bm-safe-area-top) + 80PX);
  right: 0;
  bottom: 0;
  z-index: 8;
  background-color: var(--bm-dark-background-color);
  .order {
    .order-item {
      border-radius: 8px;
      margin: 32px;
      background-color: var(--bm-light-background-color);
      display: flex;
    }
    .item-logo {
      width: 216px;
      height: 176px;
      margin-right: 28px;
      display: flex;
      .skeleton-color;
      border-radius: 0;
    }
    .item-info {
      max-width: unset;
      flex: 1;
      .top {
        display: flex;
        height: 28px;
        div {
          .skeleton-color;
        }
        .topbox {
          width: 125px;
        }
        .bar {
          width: 3px;
          margin: 0 20px;
        }
      }
      .center {
        margin: 25px 0;
        height: 28px;
        width: 400px;
        .skeleton-color;
      }
      .bottom {
        display: flex;
        margin-top: 16px;
        margin-right: 24px;
        height: 28px;
        justify-content: space-between;
        div {
          .skeleton-color;
        }
        .bottom-total {
          width: 100px;
        }
        .bottom-button {
          width: 110px;
        }
      }
    }
  }
  .point {
    background-color: #fff;
    height: 100%;
    .box1 {
      padding: 15px 28px;
      display: flex;
      justify-content: space-between;
      background-color: var(--bm-dark-background-color);
      div {
        width: 180px;
        height: 30px;
        .skeleton-color;
      }
    }
    .box2 {
      padding: 0 28px;
      .left {
        width: 400px;
      }
      .right {
        width: 120px;
        height: 28px;
      }
    }
    .box2>div {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #e8e8e8;
      padding: 45px  0;
      div {
        .skeleton-color;
      }
    }
  }
  .booking {
    padding: 48px 32px;
    height: 100%;
    background-color: #fff;
    .box1 {
      width: 70%;
      height: 45px;
      .skeleton-color;
    }
    .box2 {
      width: 75%;
      height: 23px;
      margin: 64px 0 52px 0;
      .skeleton-color;
    }
    .box3 {
      border-radius: 12px;
      height: 102px;
      .skeleton-color;
      width: 100%;
    }
    .box4 {
      .skeleton-color;
      margin: 80px 0 40px 0 ;
      height: 48px;
      width: 70%;
    }
    .box5 {
      border-radius: 12px;
      border: 1px solid #e6e6e6;
      box-shadow: 0 0 20spx #e6e6e6;
      padding: 28px;
      .box5-top {
        padding: 5px 0 30px 0;
        div {
          width: 150px;
          height: 25px;
          .skeleton-color;
        }
      }
      .box5-center {
        display: flex;
        border-bottom: 1px solid #e6e6e6;
        border-top: 1px solid #e6e6e6;
        justify-content: center;
      }
      .box5-center>div {
        padding: 30px 0 20px 0;
        margin-right: 45px;
      }
      .center-top {
        width: 55px;
        height: 32px;
        margin-bottom: 25px;
        .skeleton-color;
      }
      .center-bottom {
        width: 38px;
        height: 20px;
        .skeleton-color;
        margin: auto;
      }
    }
    .box5-buttom-box {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      div {
        .skeleton-color;
        width: 100px;
        height: 22px;
      }
    }
    .box5-buttom-box.top {
      margin-top: 28px;
    }
  }
}
@media screen and (max-width: 767px) {
  &-skeleton {
    position: absolute;
    left: 0;
    top: calc(var(--bm-safe-area-top) + 60PX);
  }
}
@media screen and (max-width: 479px) {
  &-skeleton {
    position: absolute;
    left: 0;
    top: calc(var(--bm-safe-area-top) + 50PX);
  }
}
@media screen and (max-width: 991px) {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  max-width: 74%;
  margin-bottom: 0px;
  padding-right: 20px;
  padding-left: 20px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: var(--bm-dark-background-color); 
  .tabs-content {
    overflow: auto;
    margin-top: 0px;
    margin-left: 0px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding-top: 12px;
  }

  .tabs-menu-4 {
    top: 40px;
    width: auto;
    max-width: none;
    min-width: 0px;
    margin-right: 30px;
    margin-left: 0px;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .my-priviledge-tab.w--current {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
  }
  .my-priviledge-tab {
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media screen and (max-width: 767px) {
  display: block;
  width: 100%;
  max-width: none;
  margin-top: 0px;
  border-radius: 0px;
  .privilege {
    display: none;
  }
  .tabs-content-more {
    padding-top: 50px;
  }
  .tabs-menu-4 {
    top: 60px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-height: 50px;
    max-width: 727px;
    min-height: 50px;
    margin-right: 20px;
    margin-left: 20px;
    padding-top: 0px;
    padding-bottom: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .my-priviledge-tab.w--current {
    margin-bottom: 0px;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
    font-size: 16px;
  }
  .my-priviledge-tab {
    margin-bottom: 0px;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
    font-size: 18px;
  }
  .tab-pane {
    padding-top: 0px;
  }
  .no-order-tip,
  .no-order {
    font-size: 16px;
  }
  .tabs-content {
    padding-top: 60px;
  }
}

@media screen and (max-width: 479px) {
  padding-right: 12px;
  padding-left: 12px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  .tabs-content {
    padding-top: 0;
  }
  .nav-tabs-content {
    padding-top: 50px;
  }
  .point-content {
    padding-top: 30px;
  }
  .tabs-menu-4 {
    position: -webkit-sticky;
    position: sticky;
    top: 50px;
    max-height: 50px;
    max-width: none;
    margin-right: 0px;
    margin-left: 0px;
    padding-top: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .my-priviledge-tab.w--current {
    padding: 5px 15px;
    font-size: 14px;
  }
  .my-priviledge-tab {
    padding-right: 15px;
    padding-left: 15px;
    font-size: 16px;
  }
  .no-order {
    font-size: 14px;
  }
  .no-order-tip {
    font-size: 14px;
    margin-top: 6px;
  }
}
`;


export const BookingItemStyled = styled.div`
  display: block;
  margin-top: 0px;
  margin-bottom: 40px;
  padding: 0px;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  .div-block-542 {
    margin-bottom: 40px;
  }
  .h2.black {
    margin-bottom: 20px;
    color: var(--bm-text-title-color);
  }
  .h2 {
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: 'Sf pro display', sans-serif;
    font-size: 24px;
    font-weight: 600;
  }
  .text-block-91 {
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: var(--bm-text-title-color);
    font-size: 18px;
  }
  .link-block.half {
    width: 50%;
    margin-right: auto;
    margin-left: 0px;
  }
  .w-inline-block {
    max-width: 100%;
    display: inline-block;
    box-sizing: border-box;
    text-align: center;
  }
  .link-block {
    position: relative;
    left: auto;
    top: 0px;
    right: 0%;
    bottom: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 55px;
    margin-top: 20px;
    margin-right: 0px;
    margin-left: auto;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 8px;
    background-color: var(--bm-primary-color);
    color: var(--bm-light-background-color);
    text-decoration: none;
  }
  .text-block-17 {
    font-family: 'Sf pro display', sans-serif;
    font-size: 18px;
    font-weight: 600;
  }
  .section {
    background-color: unset;
  }
  .past-booking-info-block {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    height: auto;
    min-height: auto;
    min-width: auto;
    margin-bottom: 40px;
    padding: 22px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    border-radius: 8px;
    text-decoration: none;
    background-color: var(--bm-light-background-color);
  }
  .div-block-457.with-line {
    margin-bottom: 25px;
    border-bottom-width: 2px;
  }
  .div-block-444._2 {
    top: 0px;
  }
  .div-block-444 {
    position: relative;
    top: -23px;
    padding-bottom: 0px;
  }
  .div-block-457 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    margin-bottom: 20px;
    padding-bottom: 20px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 0px dashed var(--bm-border-color);
    text-align: center;
  }
  .text-block-48 {
    margin-bottom: 10px;
    font-family: 'Sf pro display', sans-serif;
    color: var(--bm-text-title-color);
    font-size: 18px;
    text-align: left;
    font-weight: 600;
  }
  .div-block-445 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 20px;
    padding-bottom: 25px;
    padding-left: 10px;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    border-bottom: 2px dashed var(--bm-border-color);
  }
  .div-block-447 {
    padding-top: 25px;
  }
  .div-block-446 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .text-block-49 {
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: var(--bm-text-secondary-color);
    font-size: 18px;
    text-align: left;
  }
  .text-block-60 {
    margin-bottom: 15px;
    font-family: 'Sf pro display', sans-serif;
    color: var(--bm-text-title-color);
    font-size: 30px;
    text-align: left;
  }
  .div-block-436 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    padding-bottom: 0px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .text-block-46 {
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: var(--bm-text-title-color);
    font-size: 18px;
  }
  .text-block-46.third {
    font-family: 'Fontsfree net sfprodisplay', sans-serif;
    color: var(--bm-text-color);
    font-size: 18px;
  }

@media screen and (max-width: 991px) {
  width: auto;
  min-width: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  .link-block.half {
    width: 100%;
    margin-top: 20px;
  }
  .link-block {
    position: static;
    top: 0px;
    right: 0%;
    width: 200px;
    margin-top: 0px;
    margin-right: 0px;
    padding: 20px;
    color: var(--bm-light-background-color);
  }
  .past-booking-info-block {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 767px) {
  margin-right: 0px;
  padding-right: 30px;
  padding-left: 30px;
  .h2.black {
    font-size: 20px;
  }
  .link-block {
    left: auto;
    top: -22%;
    right: -5%;
    bottom: 0%;
  }
  .text-block-17 {
    font-size: 18px;
  }
  .past-booking-info-block {
    width: 450px;
    height: auto;
    min-height: auto;
    min-width: auto;
  }
  .text-block-46.third {
    font-size: 16px;
  }
  .text-block-46 {
    font-size: 18px;
  }
}
@media screen and (max-width: 479px) {
  padding-right: 0px;
  padding-left: 0px;
  .h2.black {
    margin-bottom: 10px;
    font-size: 18px;
  }
  .text-block-91 {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    font-size: 14px;
  }
  .link-block {
    left: auto;
    top: 0%;
    right: 0%;
    bottom: 0%;
    width: auto;
  }
  .text-block-17 {
    font-size: 14px;
  }
  .h2.black {
    margin-bottom: 10px;
    font-size: 18px;
  }
  .past-booking-info-block {
    width: 100%;
    height: auto;
    min-height: auto;
    min-width: 280px;
  }
  .div-block-457 {
    padding-bottom: 10px;
  }
  .div-block-444 {
    top: -11px;
  }
  .text-block-48 {
    margin-bottom: 5px;
    font-size: 16px;
  }
  .text-block-60 {
    margin-bottom: 5px;
    font-size: 16px;
  }
  .text-block-49 {
    font-size: 14px;
  }
  .text-block-46 {
    font-size: 14px;
  }
}
`;
export const OrderItemStyled = styled.div`

.section.cms {
  display: block;
  margin-top: 0px;
  margin-bottom: 40px;
  padding: 0px;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}
.order-status-prev {
  position: absolute;
  left: auto;
  top: 20px;
  right: 20px;
  bottom: 0%;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
  color: #1ac51a;
  font-size: 14px;
}
.section {
  display: block;
  margin-top: auto;
  margin-bottom: 0px;
  padding: 100px 40px 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.order-preview-block.open-modal {
  overflow: hidden;
  margin-bottom: 20px;
  padding: 20px 20px 20px 0px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  border-bottom-width: 0px;
  border-radius: 8px;
  background-color: var(--bm-light-background-color);
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}
.order-preview-block {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  padding-top: 40px;
  padding-bottom: 40px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  cursor: pointer;
}
.image-block.restaurant {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  min-width: 140px;
  margin-top: -20px;
  margin-right: 15px;
  margin-bottom: -20px;
  padding-top: 0px;
  background-size: cover;
  background-repeat: no-repeat;
}
.image-block {
  overflow: hidden;
  width: 120px;
  height: 120px;
  min-width: 120px;
  border-radius: 6px;
  background-position: 50% 50%;
  background-size: cover;
}
.order-detail-preview {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 70%;
  min-width: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}
.item-info.with-space {
   margin-bottom: 10px;
}
.item-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 100%;
  margin-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.div-block-544 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  color: var(--bm-warning-color);
}
.text-block-212 {
  font-family: 'Sf pro display', sans-serif;
  color: #605b5b;
  font-weight: 600;
  margin-bottom: 5px;
  font-size: 14px;
}
.heading-4-1 {
  margin-top: 0px;
  margin-bottom: 10px;
  font-family: 'Sf pro display', sans-serif;
  color: var(--bm-text-title-color);
  font-size: 22px;
  font-weight: 600;
  text-align: left;
}
h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
}
.text-block-213 {
  font-family: 'Sf pro display', sans-serif;
  font-weight: 600;
  font-size: 18px;
}
.line-separator {
  width: 1px;
  height: 18px;
  margin-right: 20px;
  margin-left: 15px;
  padding-right: 0px;
  background-color: #dadada;
  font-size: 2px;
}
/* .div-block-537 {
  background-color: var(--bm-dark-background-color);
} */
.section.cms {
  background-color: var(--bm-dark-background-color);
}
.order-preview-block.open-modal {
  margin-bottom: 15px;
}

@media screen and (max-width: 991px) {
  .section.cms {
    width: auto;
    min-width: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .section {
    margin-top: 0px;
    padding: 84px 35px 30px;
  }
  .order-preview-block.open-modal {
    overflow: visible;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
  }
  .image-block.restaurant {
    width: 25%;
    min-width: auto;
    margin-top: -10px;
    margin-right: 10px;
    margin-bottom: -10px;
  }
  .image-block {
    width: 80px;
    height: 80px;
    min-width: 80px;
  }
  .order-detail-preview {
    max-width: 80%;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .item-info {
    max-width: 90%;
  }
  .heading-4-1 {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .line-separator {
    margin-right: 15px;
  }
  .order-status-prev {
    top: 10px;
    right: 10px;
  }
}

@media screen and (max-width: 767px) {
  .section.cms {
    margin-right: 0px;
    padding-right: 30px;
    padding-left: 30px;
  }
  .section {
    padding: 66px 25px 20px;
  }
  .order-preview-block {
    margin-bottom: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .image-block.restaurant {
    margin-top: -20px;
    margin-right: 20px;
    margin-bottom: -20px;
  }
  .heading-4-1 {
    margin-bottom: 10px;
    font-size: 18px;
  }
  h4 {
    font-family: 'Sf pro display', sans-serif;
    font-weight: 600;
  }
  .order-preview-block.open-modal {
    padding-top: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    border-bottom-width: 1px;
  }
  .order-status-prev {
    top: 15px;
    right: 15px;
  }
}

@media screen and (max-width: 479px) {
  .section.cms {
    padding-right: 0px;
    padding-left: 0px;
  }
  .section {
    padding: 68px 12px 15px;
  }
  /* .order-preview-block.open-modal {
    padding-top: 15px;
    padding-bottom: 15px;
  } */
  .order-preview-block {
    margin-bottom: 0px;
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .image-block.restaurant {
    width: 30%;
    margin-top: -15px;
    margin-right: 15px;
    margin-bottom: -15px;
    background-size: cover;
  }
  .image-block {
    width: 72px;
    height: 72px;
    min-width: auto;
  }
  .order-detail-preview {
    max-width: 73%;
    min-width: auto;
  }
  .item-info {
    overflow: visible;
    max-height: none;
    max-width: 90%;
  }
  .heading-4-1 {
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 16px;
  }
  h4 {
    font-size: 16px;  
  }  
  .line-separator {
    margin-right: 10px;
    margin-left: 10px;
  }
}
`;
export const PointStyled = styled.div`
position: relative;
left: 0;
top: 0;
bottom: 0;
right: 0;
box-sizing: border-box;
.section.points {
   padding-top: 0px;
   background-color: unset;
}
.section {
  display: block;
  margin-top: auto;
  margin-bottom: 0px;
  padding: 100px 40px 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.div-block-545 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  padding: 20px 15px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 6px;
  background-color: var(--bm-light-background-color);
  color: var(--bm-text-secondary-color);
}
.div-block-546 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 30px 15px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid var(--bm-border-color);
  border-radius: 0px;
  font-family: 'Sf pro display', sans-serif;
  color: var(--bm-text-title-color);
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  background-color: var(--bm-dark-background-color);
}
.div-block-546:last-child {
  border-bottom: none;
}
.text-block-69 {
  margin-right: 0px;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  font-size: 16px;
}
.text-block-67 {
  margin-bottom: 0px;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: var(--bm-text-title-color);
  font-size: 18px;
  font-weight: 400;
}
.text-block-67 {
  margin-bottom: 0px;
  font-family: 'Fontsfree net sfprodisplay', sans-serif;
  color: var(--bm-text-title-color);
  font-size: 18px;
  font-weight: 400;
}


@media screen and (max-width: 991px) {
  .section.points {
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 0;
  }
  .section {
    margin-top: 0px;
    padding: 84px 35px 30px;
  }
  .div-block-546 {
    padding-top: 25px;
    padding-bottom: 10px;
    background-color: var(--bm-dark-background-color);
  }
  .div-block-545 {
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: var(--bm-light-background-color);
  }
}

@media screen and (max-width: 767px) {
  .section.points {
    margin-right: -20px;
    margin-left: -20px;
    padding-top: 0px;
  }
  .section {
    padding: 66px 25px 20px;
  }
  .div-block-545 {
    width: auto;
    margin-right: -20px;
    margin-left: -20px;
    padding-right: 40px;
    padding-left: 40px;
    border-radius: 0px;
    background-color: var(--bm-dark-background-color);
  }
  .div-block-546 {
    padding-top: 25px;
    padding-bottom: 10px;
    background-color: var(--bm-light-background-color);
  }
}

@media screen and (max-width: 479px) {
  .section.points {
    padding-top: 0px;
  }
  .section {
    padding: 68px 12px 15px;
  }
  .div-block-545 {
    margin-left: -20px;
    padding: 15px 27px;
  }
  .div-block-546 {
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--bm-light-background-color);
  }
  .text-block-67 {
    margin-bottom: 0px;
    font-size: 14px;
  }
  .text-block-69 {
    margin-right: 0px;
    font-size: 14px;
  }
  .text-block-67._1 {
    font-size: 16px;
  }
  .text-block-67 {
    margin-bottom: 0px;
    font-size: 14px;
  }
}

`;





